import { useEffect, useMemo, useState } from 'react'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import {
  filterDataChart,
  formatDataChartBySecurities,
  formatDataTableBySecurities,
  getFormatDataByDataKey,
  getTimeRangeTimeFrequencyDashboardView,
} from '../../common/helpers'
import { useGetNameSecuritiesIndicator } from '../common/useGetNameSecuritiesIndicator'
import { TIME_RANGE, VIEW_TYPE } from '../constants'

export const useSettingChartData = ({
  chartSettings,
  dataChartByTime = [],
  dataChartBySecurities = {},
  dataChartEquation = {},
  isDashboardView = false,
}) => {
  const {
    viewType,
    timeRange,
    timeRangeDate,
    equations,
    treeSecuritiesIndicator,
    listLegendCustom,
    indicatorSelected,
  } = chartSettings
  const timeZone = UseTimeZone()

  const { getSecuritiesName, getIndicatorNameTimeFrequency } =
    useGetNameSecuritiesIndicator()

  const [dataByTime, setDataByTime] = useState([])
  const [fullDataByTime, setFullDataByTime] = useState([])
  const [fullDataByTimeDataKey, setFullDataByTimeDataKey] = useState({})
  const [chartDataBySecurities, setChartDataBySecurities] = useState([])
  const [tableDataBySecurities, setTableDataBySecurities] = useState([])

  const getListTimeFrequency = () => {
    const listTimeFrequencyEquation = Object.values(equations).reduce(
      (accumulator, currentValue) => {
        return accumulator.concat(
          Object.values(currentValue.equationIndicators).map(
            (item) => item.timeFrequency,
          ),
        )
      },
      [],
    )
    const listTimeFrequencyEconomy = Object.values(
      treeSecuritiesIndicator.economy,
    ).map((item) => item.indicatorTimeFrequency)
    const listTuneFrequencyOther = Object.values(indicatorSelected)
      .flat()
      .map((item) => item.timeFrequency)
    return listTuneFrequencyOther
      .concat(listTimeFrequencyEconomy)
      .concat(listTimeFrequencyEquation)
  }

  useEffect(() => {
    if (viewType === VIEW_TYPE.BY_TIME) {
      const data = filterDataChart(
        [
          ...dataChartByTime,
          ...Object.values(dataChartEquation).reduce(
            (arr, values) => [...arr, ...values],
            [],
          ),
        ],
        isDashboardView
          ? getTimeRangeTimeFrequencyDashboardView(
              getListTimeFrequency(),
              timeRange,
            )
          : timeRange,
        timeRangeDate,
      )

      const fullData = filterDataChart(
        [
          ...dataChartByTime,
          ...Object.values(dataChartEquation).reduce(
            (arr, values) => [...arr, ...values],
            [],
          ),
        ],
        TIME_RANGE.ALL_TIME,
      )
      setFullDataByTime(fullData)
      setFullDataByTimeDataKey(getFormatDataByDataKey(fullData))

      if (JSON.stringify(data) !== JSON.stringify(dataByTime)) {
        setDataByTime(data)
      }
    }
  }, [
    JSON.stringify(dataChartByTime),
    JSON.stringify(dataChartEquation),
    timeRange,
    timeRangeDate,
  ])

  useEffect(() => {
    if (viewType === VIEW_TYPE.BY_SECURITIES) {
      const data = formatDataChartBySecurities(
        dataChartBySecurities,
        dataChartEquation,
        equations,
        treeSecuritiesIndicator,
        listLegendCustom,
        getSecuritiesName,
        getIndicatorNameTimeFrequency,
      )

      if (JSON.stringify(data) !== JSON.stringify(chartDataBySecurities)) {
        setChartDataBySecurities(data)
      }
    }
  }, [
    JSON.stringify(dataChartBySecurities),
    JSON.stringify(dataChartEquation),
    JSON.stringify(treeSecuritiesIndicator),
    equations,
    listLegendCustom,
    getIndicatorNameTimeFrequency,
  ])

  useEffect(() => {
    if (viewType === VIEW_TYPE.BY_SECURITIES) {
      const data = formatDataTableBySecurities(
        dataChartBySecurities,
        dataChartEquation,
        timeZone,
      )

      if (JSON.stringify(data) !== JSON.stringify(tableDataBySecurities)) {
        setTableDataBySecurities(data)
      }
    }
  }, [
    JSON.stringify(dataChartBySecurities),
    JSON.stringify(dataChartEquation),
    timeZone,
  ])

  const getChartData = useMemo(() => {
    if (viewType === VIEW_TYPE.BY_TIME) {
      return [...dataByTime]
    } else {
      return chartDataBySecurities
    }
  }, [
    viewType,
    JSON.stringify(dataByTime),
    JSON.stringify(chartDataBySecurities),
  ])

  const getTableData = useMemo(() => {
    if (viewType === VIEW_TYPE.BY_TIME) {
      return [...dataByTime]
    } else {
      return tableDataBySecurities
    }
  }, [
    viewType,
    JSON.stringify(dataByTime),
    JSON.stringify(tableDataBySecurities),
  ])

  return {
    chartData: getChartData,
    tableData: getTableData,
    fullDataByTime,
    fullDataByTimeDataKey,
  }
}
