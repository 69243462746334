import { createSlice } from '@reduxjs/toolkit'
import { ALL_TYPE, TIME } from '../../../../common/news/constants'
import { keyBy } from '../../../../utils/Common'
import { FORMAT, getCurrentDateTime } from '../../../../utils/Datetime'
import { register } from '../../../../utils/ReducerRegistry'
import {
  getLatestNews,
  getListNewsBusinessActivities,
  getListNewsEarning,
  getListNewsTransactions,
  getListReport,
  getNewsById,
  getNewsCategories,
  getNewsOther,
  getNewsSources,
  getReportTypesAndSources,
} from './thunk'

const slice = createSlice({
  name: 'sector/sectorConstituents/newsReport',
  initialState: {
    reportIds: [],
    reportsById: {},
    reCalcWidths: false,
    earnings: { data: [] },
    transactions: { data: [] },
    businesses: { data: [] },
    other: [],
    latest: {},
    newsById: {},
    announcement: {
      sources: [],
      categories: [],
      moreFilterData: {
        newsType: ALL_TYPE,
        category: ALL_TYPE,
        source: ALL_TYPE,
        time: TIME.All,
        startDate: getCurrentDateTime(FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
        search: '',
      },
    },
    analytic: {
      sources: [],
      categories: [],
      filterData: {
        category: ALL_TYPE,
        source: ALL_TYPE,
        time: TIME.All,
        startDate: getCurrentDateTime(FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
        page: 1,
        totalPage: 1,
      },
    },
    newsEarningLoading: true,
    newsBusinessLoading: true,
    newsTransactionLoading: true,
    latestLoading: true,
    reportLoading: true,
    newsOtherLoading: true,
  },
  reducers: {
    changeAnalyticFilter: (state, action) => {
      state.analytic.filterData = {
        ...state.analytic.filterData,
        ...action.payload,
      }
    },
    changeMoreFilter: (state, action) => {
      if (action.payload.newsType) {
        state.announcement.moreFilterData.category = ALL_TYPE
      }
      state.announcement.moreFilterData = {
        ...state.announcement.moreFilterData,
        ...action.payload,
      }
    },
    changePage: (state, action) => {
      state.analytic.filterData.page = action.payload
    },
    resetMore: (state) => {
      state.announcement.sources = []
      state.announcement.categories = []
    },
    resetReport: (state) => {
      state.analytic.sources = []
      state.analytic.categories = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListReport.pending, (state) => {
      state.reportLoading = true
    })
    builder.addCase(getListReport.fulfilled, (state, action) => {
      state.reportLoading = false
      const { data, totalPage, page } = action.payload

      state.analytic.filterData.page = page
      state.analytic.filterData.totalPage = totalPage
      state.reportIds = data.map((item) => item.reportId)
      state.reportsById = keyBy(data, 'reportId')
      state.reCalcWidths = !state.reCalcWidths
    })
    builder.addCase(getListReport.rejected, (state) => {
      state.reportLoading = false
    })

    builder.addCase(getListNewsEarning.pending, (state) => {
      state.newsEarningLoading = true
    })
    builder.addCase(getListNewsEarning.fulfilled, (state, action) => {
      state.newsEarningLoading = false
      state.earnings = action.payload
    })
    builder.addCase(getListNewsEarning.rejected, (state) => {
      state.newsEarningLoading = false
    })

    builder.addCase(getListNewsTransactions.pending, (state) => {
      state.newsTransactionLoading = true
    })
    builder.addCase(getListNewsTransactions.fulfilled, (state, action) => {
      state.newsTransactionLoading = false
      state.transactions = action.payload
    })
    builder.addCase(getListNewsTransactions.rejected, (state) => {
      state.newsTransactionLoading = false
    })

    builder.addCase(getListNewsBusinessActivities.pending, (state) => {
      state.newsBusinessLoading = true
    })
    builder.addCase(
      getListNewsBusinessActivities.fulfilled,
      (state, action) => {
        state.newsBusinessLoading = false
        state.businesses = action.payload
      },
    )
    builder.addCase(getListNewsBusinessActivities.rejected, (state) => {
      state.newsBusinessLoading = false
    })

    builder.addCase(getNewsOther.pending, (state) => {
      state.newsOtherLoading = true
    })
    builder.addCase(getNewsOther.fulfilled, (state, action) => {
      state.newsOtherLoading = false
      state.other = action.payload
    })
    builder.addCase(getNewsOther.rejected, (state) => {
      state.newsOtherLoading = false
    })

    builder.addCase(getLatestNews.pending, (state) => {
      state.latestLoading = true
    })
    builder.addCase(getLatestNews.fulfilled, (state, action) => {
      state.latestLoading = false
      const [latest = {}] = action.payload
      state.latest = latest
    })
    builder.addCase(getLatestNews.rejected, (state) => {
      state.latestLoading = false
    })

    builder.addCase(getNewsById.pending, (state) => {
      state.newsByIdLoading = true
    })
    builder.addCase(getNewsById.fulfilled, (state, action) => {
      state.newsByIdLoading = false
      state.newsById = action.payload
    })
    builder.addCase(getNewsById.rejected, (state) => {
      state.newsByIdLoading = false
    })

    builder.addCase(getReportTypesAndSources.pending, (state) => {
      state.reportTypesAndSourcesLoading = true
    })
    builder.addCase(getReportTypesAndSources.fulfilled, (state, action) => {
      state.reportTypesAndSourcesLoading = false
      state.analytic.sources = action.payload.sources
      state.analytic.categories = action.payload.types
    })
    builder.addCase(getReportTypesAndSources.rejected, (state) => {
      state.reportTypesAndSourcesLoading = false
    })

    builder.addCase(getNewsSources.pending, (state) => {
      state.newsSourcesLoading = true
    })
    builder.addCase(getNewsSources.fulfilled, (state, action) => {
      state.newsSourcesLoading = false
      state.announcement.sources = action.payload
    })
    builder.addCase(getNewsSources.rejected, (state) => {
      state.newsSourcesLoading = false
    })

    builder.addCase(getNewsCategories.pending, (state) => {
      state.newsCategoriesLoading = true
    })
    builder.addCase(getNewsCategories.fulfilled, (state, action) => {
      state.newsCategoriesLoading = false
      state.announcement.categories = action.payload
    })
    builder.addCase(getNewsCategories.rejected, (state) => {
      state.newsCategoriesLoading = false
    })
  },
})

export const selectReportIds = (state) => state[slice.name].reportIds
export const selectReport = (rowId, colId) => (state) =>
  state[slice.name].reportsById[rowId][colId]
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths
export const selectNewsEarnings = (state) => state[slice.name].earnings
export const selectNewsTransactions = (state) => state[slice.name].transactions
export const selectNewsBusinesses = (state) => state[slice.name].businesses
export const selectNewsOther = (state) => state[slice.name].other
export const selectLatest = (state) => state[slice.name].latest
export const selectNewsById = (state) => state[slice.name].newsById
export const selectNewsBusinessLoading = (state) =>
  state[slice.name].newsBusinessLoading
export const selectReportLoading = (state) => state[slice.name].reportLoading
export const selectNewsEarningLoading = (state) =>
  state[slice.name].newsEarningLoading
export const selectNewsTransactionLoading = (state) =>
  state[slice.name].newsTransactionLoading
export const selectNewsOtherLoading = (state) =>
  state[slice.name].newsOtherLoading
export const selectLatestLoading = (state) => state[slice.name].latestLoading
export const selectNewsSourcesLoading = (state) =>
  state[slice.name].newsSourcesLoading
export const selectNewsCategoriesLoading = (state) =>
  state[slice.name].newsCategoriesLoading
export const selectReportTypesAndSourcesLoading = (state) =>
  state[slice.name].reportTypesAndSourcesLoading
export const selectNewsByIdLoading = (state) =>
  state[slice.name].newsByIdLoading
export const selectAnalyticFilterData = (state) =>
  state[slice.name].analytic.filterData
export const selectMoreFilterData = (state) =>
  state[slice.name].announcement.moreFilterData
export const selectAnnouncementSources = (state) =>
  state[slice.name].announcement.sources
export const selectAnnouncementCategories = (state) =>
  state[slice.name].announcement.categories
export const selectAnalyticSources = (state) =>
  state[slice.name].analytic.sources
export const selectAnalyticCategories = (state) =>
  state[slice.name].analytic.categories

export const {
  changeAnalyticFilter,
  changeMoreFilter,
  changePage,
  resetMore,
  resetReport,
} = slice.actions

register(slice.name, slice.reducer)
