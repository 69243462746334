import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import IndexStatisticService from '../../../../../../../core/services/market/marketInDepth/IndexStatisticService'
import { handleExport } from '../../../../../../utils/Export'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'

export const getIndexStatisticsByInvestorOverview = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/statisticInvest/GET_INDEX_STATISTICS_BY_INVESTOR_OVERVIEW',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsByInvestorOverview(params)
      if (response.success) {
        if (params.TimeFilter === PRICE_DATA_TIME_SELECT.CUSTOM) {
          dispatch(getIndexStatisticsByInvestorOverviewTotalAvg(params))
        }
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndexStatisticsByInvestorDetail = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/statisticInvest/GET_INDEX_STATISTICS_BY_INVESTOR_DETAIL',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsByInvestorDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadIndexStatisticsByInvestorOverview = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/statisticInvest/DOWNLOAD_INDEX_STATISTICS_BY_INVESTOR_OVERVIEW',
  async (data) => {
    const response =
      await IndexStatisticService.downloadIndexStatisticsByInvestorOverview(
        data,
      )
    handleExport(response.data, response.filename)
  },
)

export const downloadIndexStatisticsByInvestorDetail = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/statisticInvest/DOWNLOAD_INDEX_STATISTICS_BY_INVESTOR_DETAIL',
  async (data) => {
    const response =
      await IndexStatisticService.downloadIndexStatisticsByInvestorDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const getIndexStatisticsByInvestorOverviewTotalAvg = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/statisticInvest/GET_INDEX_STATISTICS_BY_INVESTOR_OVERVIEW_TOTAL',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsByInvestorOverviewTotalAvg(
          params,
        )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
