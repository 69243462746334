import { useSelector } from 'react-redux'
import LinkTicker from '../../../../common/dropdownLink/LinkTicker'
import { selectStockInfoById } from '../../../../common/masterData/store/slice'
import TextEllipsis from '../../../../common/textEllipsis'
import style from './index.module.css'

const TickerName = ({ organizationId }) => {
  const ticker = useSelector(selectStockInfoById(organizationId, 'ticker'))
  const taxCode = useSelector(selectStockInfoById(organizationId, 'taxCode'))

  return (
    <div className={style.tickerName}>
      <LinkTicker organizationId={+organizationId}>
        <TextEllipsis isI18n={false} text={ticker || taxCode || ''} />
      </LinkTicker>
    </div>
  )
}

export default TickerName
