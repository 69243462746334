import PropTypes from 'prop-types'

const RESPONSIVE_WIDTH = 1440

export const DetailContainer = ({ width, children }) => {
  const getStyle = () => {
    const style = {
      marginTop: 5,
      height: 'calc(100% - 5px)',
    }

    return width > RESPONSIVE_WIDTH
      ? { ...style, marginLeft: '20%', marginRight: '20%' }
      : style
  }

  return (
    <div className="d-flex" style={getStyle()}>
      {children}
    </div>
  )
}

DetailContainer.propTypes = {
  width: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}
