import topMoverProxy from '../../proxies/market/TopMoverProxy'
import { ServiceBase } from '../ServiceBase'

class TopMoverService extends ServiceBase {
  getTopMover(params) {
    return this.applyMemoryCache(
      'TopMoverService.getTopMover',
      params,
    )(() => topMoverProxy.getTopMover(params))
  }

  downloadTopMover(params) {
    return this.getFileDownload(() => topMoverProxy.downloadTopMover(params))
  }
}

export default new TopMoverService()
