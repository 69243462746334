import { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { dashboardTypes } from '../../constants'
import PopupDeleteDashboard from '../../popup/PopupDeleteDashboard'

export const DeleteBtn = ({ data, dashboardType }) => {
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false)

  const showPopupDelete = () => {
    setIsShowPopupDelete(true)
  }

  return (
    <>
      {dashboardType === dashboardTypes.CHART_OF_THE_DAY ? (
        <i
          className="icon-trash"
          onClick={() => {}}
          style={{ fontSize: 15, opacity: 0.4 }}
        />
      ) : (
        <i
          className="icon-trash cursor-pointer"
          style={{ fontSize: 15 }}
          title={I18n.t('financialChart.DELETE_DASHBOARD')}
          onClick={showPopupDelete}
        />
      )}
      {isShowPopupDelete && (
        <PopupDeleteDashboard
          handleShow={setIsShowPopupDelete}
          dashboard={data}
        />
      )}
    </>
  )
}
