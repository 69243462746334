import { useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import { getFromDate, getTimeRange, getToDate } from './store/slice'
import { downloadOpenMarketOperations } from './store/thunk'
import TableOpenMarket from './TableOpenMarket'

const OpenMarket = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeRange = useSelector(getTimeRange)
  const fromDate = useSelector(getFromDate)
  const toDate = useSelector(getToDate)
  const timeZone = UseTimeZone()
  const previousYear = new Date()
  previousYear.setFullYear(previousYear.getFullYear() - 1)

  const downloadCsv = () => {
    const params = {
      TimeFrequency: timeRange,
      DateFrom: fromDate
        ? getISOStartOrEndOfDay(fromDate, timeZone, true)
        : previousYear,
      DateTo: toDate
        ? getISOStartOrEndOfDay(toDate, timeZone, true)
        : getISOStartOrEndOfDay(new Date(), timeZone, true),
    }

    return downloadOpenMarketOperations(params)
  }

  return (
    <Panel
      title={'economy.monetary.monetary.OPEN_MARKET_OPERATIONS'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <TableOpenMarket
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default OpenMarket
