import moment from 'moment'
import {
  DEFAULT_PRE_VALUE_KEY,
  DEFAULT_XAXIS_DATA_KEY,
} from '../../../../../common/chart/basicChart/constants'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { VALUE_TYPES, X_AXIS_KEY } from '../../constants'

export const formatDataApi = ({ data, ids, locale }) => {
  if (!data.length) return []

  const dataByEndDateId = {}

  data.forEach((item) => {
    if (dataByEndDateId[item.endDateId]) {
      dataByEndDateId[item.endDateId] = {
        ...dataByEndDateId[item.endDateId],
        [DEFAULT_PRE_VALUE_KEY + (ids.indexOf(item.organizationId) + 1)]:
          item.naVperShareAdjusted,
      }
    } else {
      dataByEndDateId[item.endDateId] = {
        endDateId: item.endDateId,
        [DEFAULT_PRE_VALUE_KEY + (ids.indexOf(item.organizationId) + 1)]:
          item.naVperShareAdjusted,
      }
    }
  })

  const sortData = Object.values(dataByEndDateId).sort(
    (a, b) => moment(a.endDateId).unix() - moment(b.endDateId).unix(),
  )
  const firstItem = sortData[0]
  const otherItems = sortData.slice(1)

  return otherItems.map((item) => {
    const formatItem = Object.keys(item).reduce((obj, key) => {
      if (key === X_AXIS_KEY) {
        return {
          ...obj,
          [DEFAULT_XAXIS_DATA_KEY]: formatDateTime(
            item[key],
            FORMAT.DATE,
            locale,
          ),
          endDateId: item[key],
        }
      } else {
        return {
          ...obj,
          [`${key}_${VALUE_TYPES.ABSOLUTE}`]: item[key],
          [`${key}_${VALUE_TYPES.RELATIVE}`]:
            (item[key] - firstItem[key]) / firstItem[key],
        }
      }
    }, {})

    return formatItem
  })
}
