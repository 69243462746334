import SelectCustom from '../../../../../common/selectCustom'
import { LASTEST_YEARS, QUARTERS } from '../../constant'

const QuarterlyPoint = ({ value, onChange }) => {
  const handleChangeYear = (year) => {
    onChange({ ...value, year })
  }

  const handleChangeQuarter = (quarter) => {
    onChange({ ...value, quarter })
  }

  return (
    <div className={`mt-8 h-20 d-flex ali-center w-100`} style={{ gap: 8 }}>
      <div className="w-50">
        <SelectCustom
          isI18n={true}
          selectData={QUARTERS}
          value={value.quarter}
          handleChange={handleChangeQuarter}
          fontSizeItem={10}
        />
      </div>

      <div className="w-50">
        <SelectCustom
          isI18n={false}
          selectData={LASTEST_YEARS}
          value={value.year}
          handleChange={handleChangeYear}
          fontSizeItem={10}
        />
      </div>
    </div>
  )
}

export default QuarterlyPoint
