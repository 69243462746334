import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { PieChart as Pie } from 'react-minimal-pie-chart'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { selectZoom } from '../../../layout/header/fontsize/slice'
import { isWindowsOs } from '../../../utils/Device'
import { getFontSize } from '../../../utils/FontSize'
import { formatVal } from '../../../utils/Value'
import { roundData } from './helper'
import styles from './index.module.css'

const MIN_PERCENT_DISPLAY = 5
const defaultWidthLabel = 60

const PieChart = ({
  style,
  data,
  activeSegmentIndex,
  labelPercent,
  labelPosition,
  lineWidth,
  radius,
  renderTooltip,
  animate,
  animationDuration,
  animationEasing,
  arrowColorTooltip,
  backgroundColorTooltip,
  labelStyle,
  onMouseOver,
  onMouseOut,
  onHoveredChange,
  themeDownloadPDF,
  customLabel,
}) => {
  const defaultFontSize = getFontSize(12)
  const zoom = useSelector(selectZoom)

  const [hovered, setHovered] = useState(null)

  const { formatData, decimalLength } = roundData(data)

  useEffect(() => {
    onHoveredChange && onHoveredChange(hovered)
  }, [hovered])

  const widthHeight = Math.min(
    style.width || Number.POSITIVE_INFINITY,
    style.height || Number.POSITIVE_INFINITY,
  )

  const calcRadius = () => {
    const widthLabel =
      (defaultWidthLabel / defaultFontSize) *
      (style.fontSize || defaultFontSize)
    const halfWidthChart =
      (widthHeight / 2 - widthLabel) / (labelPosition / 100)
    return (halfWidthChart / (widthHeight / 2)) * 50
  }

  const handleMouseOver = (e, index) => {
    setHovered(index)
    onMouseOver && onMouseOver(data[index])
  }

  const handleMouseOut = () => {
    setHovered(null)
    onMouseOut && onMouseOut()
  }

  const getPosition = (position, currentEvent, _, refNode) => {
    const scale = zoom / currentEvent.view.devicePixelRatio
    return (currentEvent.view.devicePixelRatio !== 1 || zoom !== 1) &&
      isWindowsOs()
      ? {
          left: currentEvent.clientX / scale - refNode.clientWidth / 2,
          top: currentEvent.clientY / scale - refNode.clientHeight,
        }
      : position
  }

  const renderLabel = (data) => {
    let value = labelPercent
      ? labelPercent(data)
      : formatVal(data.dataEntry.percentage)

    return value < MIN_PERCENT_DISPLAY ? '' : value + '%'
  }

  return (
    <div data-tip="" data-for="chart" style={{ height: style.height }}>
      <Pie
        style={{
          fill: themeDownloadPDF ? '#000000' : '#ececec',
          ...style,
          fontSize: ((style.fontSize || defaultFontSize) * 100) / widthHeight,
        }}
        data={formatData}
        lineWidth={lineWidth}
        label={customLabel ? customLabel : renderLabel}
        labelPosition={labelPosition}
        startAngle={270}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        animate={animate}
        animationDuration={animationDuration}
        animationEasing={animationEasing}
        labelStyle={{ fontWeight: 600, ...labelStyle }}
        radius={radius || calcRadius()}
        segmentsStyle={(index) =>
          (activeSegmentIndex || activeSegmentIndex === 0) &&
          index !== activeSegmentIndex
            ? { opacity: 0.3 }
            : {}
        }
      />
      {!themeDownloadPDF && renderTooltip && (
        <ReactTooltip
          id="chart"
          className={styles.tooltip}
          arrowColor={arrowColorTooltip}
          backgroundColor={backgroundColorTooltip}
          getContent={() => {
            if (typeof hovered === 'number') {
              return renderTooltip(data[hovered], { decimalLength })
            }
          }}
          overridePosition={getPosition}
        />
      )}
    </div>
  )
}

PieChart.propTypes = {
  style: PropTypes.shape({
    fontSize: PropTypes.number,
    height: PropTypes.number.isRequired,
    fill: PropTypes.string,
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      isI18n: PropTypes.bool,
    }),
  ).isRequired,
  labelPercent: PropTypes.func,
  labelPosition: PropTypes.number,
  lineWidth: PropTypes.number,
  radius: PropTypes.number,
  renderTooltip: PropTypes.func,
  animate: PropTypes.bool,
  animationDuration: PropTypes.number,
  animationEasing: PropTypes.string,
  onMouseOut: PropTypes.func,
  onHoveredChange: PropTypes.func,
  themeDownloadPDF: PropTypes.bool,
}

PieChart.defaultProps = {
  animate: true,
  animationDuration: 500,
  animationEasing: 'ease-out',
  arrowColorTooltip: 'rgba(52, 64, 83, 0.8)',
  backgroundColorTooltip: 'rgba(52, 64, 83, 0.8)',
  lineWidth: 40,
  labelPosition: 115,
  themeDownloadPDF: false,
}

export default PieChart
