import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import {
  getAccMonthAndYearKey,
  getDataByMonthAndYear,
  getDataByYear,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../../../common/helper'
import { getMaxDateOverview } from '../../store/thunk'
import {
  NUM_OF_PERIOD,
  NUM_OF_PERIOD_FIRST_FETCH,
  TIME_TYPE,
} from '../constants'
import {
  addColorIdToData,
  addedGrowthChildren,
  getUniqueCategories,
  keepCurrentListByIndex,
  reOrderByMonthAndYear,
} from '../helper'
import { balanceOfTradeTableContent } from './thunk'

const slice = createSlice({
  name: 'economy/overview/balanceOfTradeTable',
  initialState: {
    idsCategory: [],
    dataById: {},
    isLoading: false,
    isContentLoading: false,
    listCategory: [],
    isGetData: true,
    data: [],
    groupColumns: [],
    isYearly: false,
    currentMinMonth: null,
    currentMinYear: null,
    currentMaxMonth: null,
    currentMaxYear: null,
    periodNum: NUM_OF_PERIOD_FIRST_FETCH,
    countPeriodNum: NUM_OF_PERIOD_FIRST_FETCH,
    isChangingTab: false,
    locale: null,
    isChangeLocale: false,
    isAcc: false,
    currentAddedItems: [],
    dataWithColorId: [],
    currentTimeType: TIME_TYPE.MONTHLY,
    maxDate: null,
  },
  reducers: {
    addCategoryToList: (state, action) => {
      state.isGetData = true
      state.listCategory = [...state.listCategory, action.payload]
    },
    removeCategoryFromList: (state, action) => {
      state.isGetData = false
      state.listCategory = state.listCategory.filter(
        (category) => category.id !== action.payload,
      )
    },
    changeCurrentTimeType: (state, action) => {
      if (state.currentTimeType !== action.payload) {
        state.currentTimeType = action.payload
      }
    },
    changeIsYearly: (state, action) => {
      state.isYearly = action.payload
    },
    changeCurrentMinMonth: (state, action) => {
      state.currentMinMonth = action.payload
      state.currentMinYear = action.payload
    },
    changePeriodNum: (state, action) => {
      state.periodNum = action.payload
    },
    changeTab: (state, action) => {
      state.isChangingTab = action.payload
    },
    changeLocale: (state, action) => {
      state.locale = action.payload
      state.isChangeLocale = true
    },
    changeIsAcc: (state, action) => {
      state.isAcc = action.payload
    },
    keepCurrentItems: (state, action) => {
      state.currentAddedItems = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(balanceOfTradeTableContent.pending, (state) => {
      state.isContentLoading = true
    })
    builder.addCase(balanceOfTradeTableContent.fulfilled, (state, action) => {
      state.isContentLoading = false
      state.isChangingTab = false

      if (action.meta.arg.Limit === NUM_OF_PERIOD_FIRST_FETCH) {
        state.countPeriodNum = NUM_OF_PERIOD_FIRST_FETCH
        state.currentMaxMonth = action.meta.arg.Month
        state.currentMaxYear = action.meta.arg.Year
      } else {
        state.countPeriodNum += NUM_OF_PERIOD
      }

      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          id: item.vnTypeId || 1,
          name: item.vnTypeName,
          year: item.realYear,
          month: item.realMonth,
        })) || []

      const data = payloadData.length
        ? fullDataWithVal(
            addedGrowthChildren(payloadData),
            state.isYearly,
            state.isAcc,
            state.currentMinMonth,
            state.data,
            state.isChangeLocale,
          )
        : [...state.data]

      state.idsCategory = data?.map((v) => v.id)
      state.payloadData = payloadData
      state.data = data
      state.dataById = keyBy(data, 'id')
      state.isChangeLocale = false

      const dataWithColorId = addColorIdToData(
        data.filter((item) => !item.isSubCategory),
      )
      state.dataWithColorId = dataWithColorId

      state.listCategory = !state.currentMinMonth
        ? keepCurrentListByIndex(state.currentAddedItems, dataWithColorId)
        : [...state.listCategory].map((item) => ({
            ...item,
            name: state.dataById[item.id].name,
          }))

      const getGroupColumns = data.length
        ? data
            ?.map((item) => Object.keys(item))[0]
            .filter((item) => /\d/.test(item))
        : []

      const monthInColKey = (colKey) => {
        return Number(colKey.slice(0, 2)) < 10
          ? Number(colKey.slice(1, 2))
          : Number(colKey.slice(0, 2))
      }
      const yearInColKey = (colKey) => {
        return colKey.slice(3, 7)
      }

      const getFilterGroupColumns = () => {
        if (state.isYearly) {
          return getGroupColumns.map((item) =>
            getYearKey(Number(item), state.maxDate, state.locale),
          )
        } else if (state.isAcc) {
          return reOrderByMonthAndYear(getGroupColumns)?.map((col) =>
            getAccMonthAndYearKey(
              monthInColKey(col),
              yearInColKey(col),
              state.locale,
            ),
          )
        } else {
          return reOrderByMonthAndYear(getGroupColumns)
        }
      }

      const groupColumns = []
      getFilterGroupColumns()?.forEach((item) => {
        groupColumns.push({
          key: getGroupColumns[getFilterGroupColumns().indexOf(item)],
          title: item,
        })
      })

      state.groupColumns = [
        {
          key: 'name',
          title: 'economy.importExport.importExport.overview.NAME',
        },
        ...groupColumns,
      ]

      if (state.isYearly) {
        state.currentMinMonth = 13 // Cách để fix bug load thêm data ở yearly
        state.currentMinYear = groupColumns[0]?.key
      } else {
        state.currentMinMonth = groupColumns[0]?.key.slice(0, 2)
        state.currentMinYear = groupColumns[0]?.key.slice(3, 7)
      }
    })

    builder.addCase(balanceOfTradeTableContent.rejected, (state) => {
      state.isContentLoading = false
    })

    builder.addCase(getMaxDateOverview.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxDateOverview.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxDateOverview.rejected, (state) => {
      state.maxDate = null
    })
  },
})

export const selectLoadingTableCPI = (state) => state[slice.name].isLoading
export const selectLoadingTableContentCPI = (state) =>
  state[slice.name].isContentLoading
export const selectCategoryIds = (state) => state[slice.name].idsCategory
export const selectCategoryDataTableById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectListCategory = (state) => state[slice.name].listCategory
export const selectDataTable = (state) => state[slice.name].data
export const selectPayloadData = (state) => state[slice.name].payloadData
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectMinCurrentMonth = (state) =>
  state[slice.name].currentMinMonth
export const selectMinCurrentYear = (state) => state[slice.name].currentMinYear
export const selectMaxCurrentMonth = (state) =>
  state[slice.name].currentMaxMonth
export const selectMaxCurrentYear = (state) => state[slice.name].currentMaxYear
export const selectPeriodNum = (state) => state[slice.name].periodNum
export const selectCountPeriodNum = (state) => state[slice.name].countPeriodNum
export const selectIsChangingTab = (state) => state[slice.name].isChangingTab
export const selectIsYearly = (state) => state[slice.name].isYearly
export const selectIsAcc = (state) => state[slice.name].isAcc
export const selectDataWithColorId = (state) =>
  state[slice.name].dataWithColorId
export const selectCurrentTimeType = (state) =>
  state[slice.name].currentTimeType
export const selectMaxDate = (state) => state[slice.name].maxDate

export const {
  addCategoryToList,
  removeCategoryFromList,
  changeIsYearly,
  changeCurrentMinMonth,
  changePeriodNum,
  changeTab,
  changeLocale,
  changeIsAcc,
  keepCurrentItems,
  changeCurrentTimeType,
} = slice.actions

register(slice.name, slice.reducer)

const fullDataWithVal = (
  dataContainVal,
  isYearly,
  isAcc,
  currentMinMonth,
  dataWithoutVal,
  isChangeLocale,
  locale,
) => {
  //convert months and years to date keys
  const getDataKeyFromRange = !isYearly
    ? getMonthAndYearInDataRange(dataContainVal).map((item) =>
        getMonthAndYearKey(item.month, item.year),
      )
    : isAcc
    ? getMonthAndYearInDataRange(dataContainVal).map((item) =>
        getAccMonthAndYearKey(item.month, item.year, locale),
      )
    : getYearInDataRange(dataContainVal).map((item) => getYearKey(item.year))

  const getDataValueFromRange = !isYearly
    ? getMonthAndYearInDataRange(dataContainVal).map((item) => [
        ...getDataByMonthAndYear(dataContainVal, item.month, item.year),
      ])
    : getYearInDataRange(dataContainVal).map((item) => [
        ...getDataByYear(dataContainVal, item.year),
      ])

  const getCorrespondingDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.id === item.id))]?.value ??
      EMPTY_VALUE
    )
  }

  //put values of all date keys into original CPI data
  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )

    return dataKeyAndValRange
  }

  return !currentMinMonth || isChangeLocale
    ? getUniqueCategories(dataContainVal).map((item) => ({
        ...item,
        ...getKeyAndValRange(item),
      }))
    : dataWithoutVal.map((item) => ({
        ...item,
        ...getKeyAndValRange(item),
      }))
}
