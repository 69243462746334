export const primaryMarket = {
  TITLE: 'Primary Market',
  OVERVIEW: 'Overview',
  MARKET_STATISTICS: 'Market Statistics',
  ALL: 'All',
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
  MONTHLY_OPTION: {
    MONTH_1: 'Jan',
    MONTH_2: 'Feb',
    MONTH_3: 'Mar',
    MONTH_4: 'Apr',
    MONTH_5: 'May',
    MONTH_6: 'Jun',
    MONTH_7: 'Jul',
    MONTH_8: 'Aug',
    MONTH_9: 'Sep',
    MONTH_10: 'Oct',
    MONTH_11: 'Nov',
    MONTH_12: 'Dec',
  },
  AVERAGE_MONTH: 'Avg',
  AVERAGE_QUARTER: 'Avg',
  AVERAGE_YEAR: 'Avg',
  MTD: '(MTD)',
  QTD: '(QTD)',
  YTD: '(YTD)',
  MONTH: '',
  ONE_TWO_THREE_YEARS: 'Tenor 1-3 years',
  THREE_TWO_FIVE_YEARS: 'Tenor 3-5 years',
  FIVE_TO_SEVEN_YEARS: 'Tenor 5-7 years',
  OVER_SEVEN_YEAR: 'Tenor ≥ 7 years',
  FLOAT: 'Float',
  ZERO: 'Zero',
  MIXED: 'Mixed',
  FIXED: 'Fixed',
  OTHERS: 'Others',
}

export const issuanceValue = {
  TITLE: 'Issuance Value',
  NOTE: 'Value: Billion VND. Duration: Years',
  SUMMARY: 'All',
  FIXED: 'Fixed',
  FLOATING_AND_COMBINE: 'Float & Mixed',
  OTHER: 'Others',
  INDUSTRY: 'Industry',
  RELEASE_VALUE: 'Value',
  RELEASE_DURATION: 'Avg Tenor',
  COUPON: 'Avg Coupon',
}

export const issuanceValueBySector = {
  TITLE: 'Issuance Value By Sector',
  NET_VALUE: 'Value (Bil. VND)',
  MARKET: 'Market',
  UNIT: ' Bil. VND',
}

export const issuanceValueByCouponType = {
  TITLE: 'Issuance Value By Coupon Type',
  OTHERS: 'Others',
  BILLION: 'B',
}

export const averageCouponBySector = {
  TITLE: 'Average Coupon By Sector',
  PERCENT: 'Unit (%)',
}

export const averageDurationBySector = {
  TITLE: 'Average Tenor by sector',
  DURATION: 'Release duration (Years)',
  YEAR: 'years',
}

export const issuers = {
  TITLE: 'Issuers',
  SYNTHETIC: "Company's Bonds",
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
  MTD: '(MTD)',
  QTD: '(QTD)',
  YTD: '(YTD)',
  PRIVATE: 'Private',
  PUBLIC: 'Public',
  DEBT_LOAN: 'Debt and loan',
}

export const bondIssueInformation = {
  TITLE: 'Bond Issue Information',
  BOND_STATUS: 'Status',
  BOND_EVENT: 'Credit Event',
  ALL: 'All',
  NORMAL: 'Normal',
  CANCELLATION_DUE_TO_MATURITY: 'Cancellation Due To Maturity',
  CANCELED_DUE_TO_REDEMPTION: 'Canceled Due To Buyback',
  DUE_BUT_BOT_FULLY_PAID: 'Due But Not Fully Paid',
  COLLATERAL: 'Collateral',
  RELATED: 'Bonds Of Related Companies',
  YES: 'Yes',
  NO: 'No',
  BOND_TICKER: 'Bond Ticker',
  ORGANIZATION_NAME: 'Organization Name',
  RELATIONSHIP: 'Relationship',
  TICKER: 'Ticker',
  ISSUE_METHOD: 'Issue Method',
  NEXT_INTEREST_PAYMENT_DATE: 'Next IP Date',
  ISSUE_DATE: 'Issue Date',
  MATURITY_DATE: 'Maturity Date',
  COUPON: 'Coupon',
  PERCENT: '(%)',
  ISSUE_VALUE: 'Issue Value',
  VALUE: '(Bil. VND)',
  OUTSTANDING_VALUE: 'Outstanding Value',
  DURATION: 'Time to Maturity',
  MONTH: '(Months)',
  COLLATERAL_VALUE: 'Collateral Value',
  INTEREST_PAID: 'Interest Paid',
  PRINCIPAL_PAID: 'Principal Paid',
  ATTACHED_DOCUMENT: 'Attached Document',
  OVERDUE_COUPON: 'Overdue Coupon',
  OVERDUE_PRINCIPAL: 'Overdue Principal',
  VIEW: 'View',
  SUMMARY: 'TOTAL',
}

export const relativeToEquity = {
  TITLE: 'Bond Outstanding Amount',
  VALUE: 'Value (Bil. VND)',
  VALUE_OF_OUTSTANDING_BOND: 'Outstanding Bond',
  VALUE_OF_ISSUE_BOND: 'Issue Value',
  VALUE_OF_MATURITY_BOND: 'Maturity Value',
  VALUE_OF_REDEEMED_BOND: 'Buyback Value',
  ENDING_EQUITY: 'Ending Equity',
  OUTSTANDING_BOND: 'Outstanding Bond to Equity',
  ISSUE_BOND: 'Issue Bonds',
  MATURITY_BOND: 'Maturing Bonds',
  REDEEMED_BOND: 'Redemption Bonds',
  EQUITY: 'Equity',
  UNIT: 'bil. VND',
  TIME: 'time',
}

export const CouponInterestRateChart = {
  OUTSTANDING_BOND_VALUE: 'Outstanding bond:',
  AVG_COUPON: 'Avg remaining duration:',
  AVG_COUPON_ALL: 'Avg remaining duration of all coupon range:',
  VALUE_OF_OUTSTANDING_BONDS_LEGEND: 'Outstanding Bond Value',
  AVG_COUPON_LEGEND: 'Avg remaining duration',
  AVG_COUPON_ALL_LEGEND: 'Avg remaining duration of all coupon range',
  COUPON: 'Duration (year)',
}

export const remainingMaturities = {
  TITLE: 'Outstanding Bond Value by Time to Maturity and Coupon',
  REMAINING_MATURITIES:
    'Value Of Outstanding Bonds According To Remaining Maturities',
  COUPON_INTEREST_RATE: 'Value Of Bonds Outstanding By Coupon Interest Rate',
  DURATION: 'Duration',
  COUPON: 'Coupon',
  REMAINING_DURATION: 'Remaining Duration',
  UNDER_ONE_YEAR: '<1 year',
  ONE_TWO_THREE_YEARS: '1-3 years',
  THREE_TWO_FIVE_YEARS: '3-5 years',
  FIVE_TO_SEVEN_YEARS: '5-7 years',
  OVER_SEVEN_YEARS: '>=7 years',
  VALUE: 'Value (billion VND)',
  PERCENT: 'Coupon (%)',
  COUPON_AVERAGE: 'Coupon Average',
  COUPON_AVERAGE_DURATION: 'LS Coupon Average Duration',
  UNIT: 'bil. VND',
  OUTSTANDING_BONDS: 'Outstanding Bonds:',
  COUPON_AVERAGE_2: 'Coupon Average:',
  COUPON_AVERAGE_DURATION_2: 'Coupon Average Duration:',
  DURATION_VALUE: 'Coupon (%)',
  INTEREST_RATE: 'Interest Rate',
  UNDER_SIX_PERCENTS: '<6%',
  SIX_TO_NINE_PERCENTS: '6-9%',
  NINE_TO_TWELVE_PERCENTS: '9-12%',
  TWELVE_TO_FIFTEEN_PERCENTS: '12-15%',
  OVER_FIFTEEN_PERCENTS: '>15%',
  AVERAGE_DURATION_FOR_INTEREST_RATE: 'Average Duration For Interest Rate',
  AVERAGE_DURATION_OF_ALL_INTEREST_RATES:
    'Average Duration Of All Interest Rates',
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  WEEKLY: 'Weekly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
  VALUE_OF_OUTSTANDING_BONDS: 'Outstanding bond:',
  AVG_COUPON: 'Avg coupon:',
  AVG_COUPON_ALL_PERIOD: 'Avg coupon all duration:',
  VALUE_OF_OUTSTANDING_BONDS_LEGEND: 'Outstanding Bond Value',
  AVG_COUPON_LEGEND: 'Avg coupon',
  AVG_COUPON_ALL_PERIOD_LEGEND: 'Avg coupon all period',
  MATCHED_1: 'Match:',
  DEAL_1: 'Deal:',
  AVERAGE_1: 'Avg trading value per session:',
  MATCHED: 'Match',
  DEAL: 'Deal',
  AVERAGE: 'Avg trading value per session',
}

export const interestAndPrincipalPaid = {
  TITLE: 'Bond Repayment Schedule',
  PRINCIPAL_PAID: 'Principal payable',
  COUPON_PAID: 'Interest Coupon payable',
  VALUE: 'Value (billion VND)',
  UNIT: 'bil. VND',
  EXPECT: 'Expect',
  TOOLTIP:
    'The expected cash outflows already include the debt restructuring of the issuers based on the latest updated information.',
}

export const valueChart = {
  '3M': '3M',
  '6M': '6M',
  YTD: 'YTD',
  '1Y': '1Y',
  DURATION: 'Duration',
  YTM: 'YTM',
  VALUE: 'Trading Value',
  AVERAGE_VALUE: 'Avg Matched Duration',
  DURATION_AVERAGE: 'Average Matched Duration',
  AVERAGE_YTM: 'Average YTM',
  MONTH: 'years',
  DURATION_YEAR: 'Duration (Years)',
  AVG_YTM: 'Avg YTM',
}

export const secondaryMarket = {
  TITLE: 'Secondary Market',
  OVERVIEW: 'Overview',
  MARKET_STATISTICS: 'Market Statistics',
  BOARD: 'Price Board',
  TRADE_STATISTICS: 'Trading Statistics',
  MONTH: '(months)',
  LIQUIDITY: 'Market Liquidity',
  VALUE: 'Top 5 issuers by trading value',
}

export const priceBoard = {
  filterTCPH: 'Search Issuers',
  TITLE: 'Price Board',
  ORDER_MATCHING: 'Match',
  PUT_THROUGH: 'Deal',
  DIRTY_PRICE: 'Dirty Price',
  CLEAN_PRICE: 'Clean Price',
  LISTED: 'Listed',
  PUBLIC: 'Public Offering',
  PRIVATE: 'Private Placement',
  BOND_WITH_TRADING: 'Actively quoted bonds',
  EXCHANGE_TRANSACTION_PRICE: 'Trading Price Converted',
  BOND_WITH_TRADING_TOOLTIP: 'Bonds with orders to buy, sell or trade',
  NOTE: 'Price: At Par Value With Base Point Of 100. Volume X 1 Bonds. Value X 1,000,000 VND',
  NOTE_2: 'Price x 1 VND. Volume x 1 Bonds. Value x 1,000,000 VND ',
  NOTE_3: 'Price x1. Volume x1. Value x 1,000,000.',
  ALL: 'All',
  UNDER_SIX_MONTHS: '<6M',
  FROM_SIX_TO_TWELVE_MONTHS: '6-12M',
  FROM_TWELVE_TO_EIGHTEEN_MONTHS: '12-18M',
  FROM_EIGHTEEN_TO_TWENTY_FOUR_MONTHS: '18-24M',
  FROM_TWENTY_FOUR_TO_THIRTY_SIX_MONTHS: '24-36M',
  FROM_THREE_TO_FIVE_YEARS: '3-5Y',
  FROM_FIVE_TO_SEVEN_YEARS: '5-7Y',
  OVER_SEVEN_YEARS: '>7Y',
  UNDER_ONE_THOUSANDS: '<100 thousand',
  FROM_ONE_THOUSANDS_TO_ONE_MILLION: '100 thousand - 1 mil.',
  FROM_ONE_MILLION_TO_ONE_HUNDRED_MILLION: '1 - 100 mil.',
  FROM_ONE_HUNDRED_MILLION_TO_ONE_BILLION: '100 mil. - 1 bil.',
  OVER_ONE_BILLION: '>1 bil.',
  RESET: 'Reset',
  SAVE: 'Save Watchlist',
  BID: 'Bid',
  MATCHED: 'Matched',
  ASK: 'Ask',
  TRADING_PRICE: 'Trading Price',
  INFORMATION: 'Information',
  BOND_CODE: 'Ticker',
  TICKER: 'Bond Ticker',
  ISSUER: 'Issuers',
  COUPON_TYPE_2: 'Coupon Type',
  COUPON: 'Coupon',
  PRICE_1: 'Price 1',
  PRICE_2: 'Price 2',
  PRICE_3: 'Price 3',
  VOLUME_1: 'Volume 1',
  VOLUME_2: 'Volume 2',
  VOLUME_3: 'Volume 3',
  YTM: '+/- YTM',
  PRICE_YTM: 'Price',
  DURATION_TEXT: 'Time to Maturity (month)',
  MATCHED_VOLUME: 'Volume',
  MATCHED_VALUE: 'Value',
  HIGH: 'High',
  LOW: 'Low',
  AVG: 'Avg',
  TOTAL_VALUE: 'Total Value',
  COLLATERAL: 'Collateral',
  REMAINING_DURATION: 'Time to Maturity',
  ISSUE_METHOD: 'Issue',
  ISSUE_METHOD_2: 'Method',
  RANGE_YTM: 'Value input is incorrect, please re-input value',
  YES: 'Yes',
  NO: 'No',
  SORT_BY_PRICE: 'Sort by Price',
  SORT_BY_YTM: 'Sort by YTM',
  INDUSTRY: 'Industry',
  ISSUE_DATE: 'Issue Date',
  DUE_DATE: 'Maturity Date',
  INTEREST_PAYMENT_PERIOD: 'Interest Payment Period (month/time)',
  CREDIT_EVENT: 'Credit Event',
  DURATION: 'Remaining Duration',
  COUPON_TYPE: 'Coupon Type',
  EXPECTED_COUPON: 'Expected Coupon',
  COUPON_BENCHMARK: 'Coupon Benchmark',
  CAUTION:
    "Caution: The current coupon benchmark is different from the issuer's announced",
  TRADING_DATE: 'Trading Date',
  PAYMENT_DATE: 'Payment Date',
  EXRIGHT_DATE: 'Exright Date',
  VOLUME: 'Trading Volume (Bond)',
  TOTAL_PAR_VALUE: 'Total Par Value',
  YIELD_TO_MATURITY: 'Yield To Maturity (YTM)',
  DIRTY: 'Dirty Price (VND)',
  CLEAN: 'Clean Price (VND)',
  PLACEHOLDER: 'Enter Value',
  PLACEHOLDER_2: 'Enter Volume',
  BID_2: 'Bid',
  MATCHED_2: 'Deal',
  ASK_2: 'Ask',
  TRADING_PRICE_2: 'Trading Summary',
  PRICE_5: 'Best Bid',
  PRICE_6: 'Price',
  PRICE_7: 'Best Offer',
  PRICE_8: 'Price',
  VOLUME_5: 'Volume at Best',
  VOLUME_6: 'Bid Price',
  VOLUME_7: 'Volume at Best',
  VOLUME_8: 'Offer Price',
  HIGH_2: 'Total Bid',
  HIGH_3: 'Volume',
  LOW_2: 'Total Offer',
  LOW_3: 'Volume',
  AVG_2: 'Total Deal Volume',
  TOTAL_VALUE_2: 'Total Deal Value',
  MATCHED_VOLUME_2: 'Volume',
  MATCHED_VALUE_2: 'Value',
  PRICE_NOTE:
    'The Yield to Maturity (YTM) is calculated based on the expected cash flows according to the information of the Bond; however, the result can be affected by changes such as updating the floating interest rate or other changes of the Issuer. Please carefully check the information before trading.',
  MY_WATCHLIST: 'My Watchlist',
  SEARCH_PLACEHOLDER: 'Search Bond Ticker or Issuers',
  SEARCH_PLACEHOLDER_STATUS: 'Search',
  ALL_SECTOR: 'All sectors',
  ALL_PRICE: 'All Price',
  SELECTED: 'Selected %{number}',
  ALL_SEARCH: 'All',
}

export const tradingStatistics = {
  DATE: 'Date',
  TICKER: 'Bond Code',
  ORGANIZATION_NAME: 'Issuer',
  COUPON: 'Coupon',
  YTM: '+/-YTM',
  OPEN_PRICE: 'Open',
  HIGHEST_PRICE: 'High',
  LOWEST_PRICE: 'Low',
  CLOSE_PRICE: 'Close',
  MATCHED_VOLUME: 'Match Volume',
  MATCHED_VALUE: 'Match Value',
  DEAL_VOLUME: 'Deal Volume',
  DEAL_VALUE: 'Deal Value',
  TOTAL_VOLUME: 'Total Volume',
  TOTAL_VALUE: 'Total Value',
}

export const benchmarkingSecondary = {
  D: 'D',
  WTD: 'WTD',
  MTD: 'MTD',
  QTD: 'QTD',
  YTD: 'YTD',
  W: 'Week',
  M: 'Month',
  Q: 'Quarter',
  Y: 'Year',
}

export const sectorStatistic = {
  TITLE: 'Sector statistics',
  TOTAL_VALUE: 'Total value',
  TOTAL_VALUE_OF_PUBLIC_BOND: 'Total value of public bond',
  TOTAL_VALUE_OF_PRIVATE_BOND: 'Total value of private bond',
  AVG_REMAIN_DURATION: 'Average remain duration',
  AVG_YTM: 'Average YTM',
  YEAR: '(year)',
  UNIT: '(bil. VND)',
  PERCENT: '(%)',
}
export const correlationOfSecondaryMarket = {
  TITLE:
    'Correlation of secondary market interest rates and remaining duration',
  SECTOR: 'Sector',
  ORGANIZATION: 'Issuer',
  AVG_YTM: 'YTM average',
  REMAINING_DURATION: 'Remaining duration average',
  REMAINING: 'Remaining duration',
  TOTAL_TRADING_VALUE: 'Total trading value',
  YEAR: 'Unit (years)',
  MONTH: 'Month',
}

export const marketTradingValue = {
  TITLE: 'Market trading value',
  ISSUE_METHOD: 'Issue method',
  TRADING_METHOD: 'Trading method',
  PUBLIC: "Public bond's value",
  PRIVATE: "Private bond's value",
  MATCHED_VALUE: 'Matched value',
  PUT_THROUGH_VALUE: 'Put through value',
  TOTAL: 'Total',
}
export const marketTradingValueBySector = {
  TITLE: 'Market trading value by sector',
  ALL: 'All',
  LessThan1Year: 'Less than 1 year',
  From1To3Years: 'From 1 to 3 years',
  From3To5Years: 'From 3 to 5 years',
  From5To7Years: 'From 5 to 7 years',
  AboveThan7Years: 'Above than 7 years',
}
export const debtRelationInBalance = {
  TITLE: 'Debt relation in balance sheet',
  UNIT: 'Unit (Time)',
  DEBT: 'Total debt',
  OWNER: "Owner's equity",
  RATIO: "Total debt / Owner's equity",
  TTS: 'Total debt / Total assets',
  QUARTER: 'Quarter',
  YEAR: 'Year',
}
export const scaleOfDebtByObjects = {
  TITLE: 'Scale of Debts by objects',
  DEBT: 'Total debt',
  OWNER: 'Total bond issue value',
  RATIO: 'Bank loans',
  OTHER: 'Other Loans',
}
export const debtDetails = {
  TITLE: 'Debt Details by object',
  BOND: 'Total bond issue value',
  SHORT_TERM: 'Short term Debt',
  LONG_TERM: 'Long term debt',
}
export const creditRatio = {
  TITLE: 'Credit Ratio',
  TOTAL_DEBT: 'Total debt/ EBITDA',
  NET_DEBT: 'Net debt / EBITDA',
  DEBT_EXPENSE: 'EBITDA / Debt expense',
  DEBT_EXPENSE_EDIT: 'EBIT / Debt expense',
}
export const debtStatistic = {
  TITLE: 'Scale of Debts',
  BOND: 'Total bond issue value',
  OTHER: 'Other',
}
export const lendingCom = {
  LOAN_NAME: 'Lender',
  NAME: 'Borrower',
  DEBT_VALUE: 'Debt value',
  DEBT_VALUE_1: 'Debt value',
  UNIT: '(Bil. VND)',
  INTEREST: 'Interest rate',
  PAYMENT: 'Duration',
  PAYMENT_1: 'Description',
  NAME_COM: 'Lender',
  DURATION: 'Duration',
  PERIOD: 'Period',
  TYPE: 'Type',
}
export const topLiquidity = {
  TITLE: 'Top liquidity',
  ISSUER: 'Issuer',
  TP: 'Bond Ticker',
  ALL: 'All',
  MATCH: 'Match',
  DEAL: 'Deal',
  NOTE: 'Data is calculated on %{now}',
  NOTE_2: 'Data is calculated from %{from} to %{to}',
  TRADING_VALUE: 'Trading Value',
  AVERAGE_REMAIN_LENGTH: 'Time To Maturity',
  CHANGE_OF_YTM: 'YTM',
  CHANGE_OF_YTM_2: 'Avg YTM',
  CLEAN_PRICE: 'Clean Price',
  CLEAN_PRICE_2: 'Avg Clean Price',
  DIRTY_PRICE: 'Dirty Price',
  DIRTY_PRICE_2: 'Avg Dirty Price',
  CHANGE_CLEAN_PRICE: 'Change of Clean Price',
  CHANGE_DIRTY_PRICE: 'Change of Dirty Price',
  PERCENT: '(%)',
  PUBLIC: 'Public',
  PRIVATE: 'Private',
}
export const marketLiquidity = {
  TITLE: 'Market Liquidity',
  TOTAL_TODAY: 'Total Trading Value %{time}',
  ONE_WEEK: '1 week',
  ONE_MONTH: '1 month',
  THREE_MONTHS: '3 months',
  TOTAL_YESTERDAY: 'Total Trading Value last period',
  LABEL: 'Value (bil. VND)',
  ISSUER: 'Issuer',
}
export const averageYtmInterest = {
  TITLE: 'Average Yield to Maturity (YTM) of issuers',
  YTM_CHANGE: '+/- YTM Change',
  REAL: 'Real interest rate',
  NOT_REAL: 'Interpolated interest rate',
  outstandingValue: 'Outstanding Value',
  maturityDateId: 'Avg YTM',
  remainingLength: 'Avg Time To Maturity',
  UNIT: '(bil. VND)',
  VND: '(VND)',
  PERCENT: '(%)',
  MONTH: '(Month)',
  ISSUER: 'Issuer',
}
export const topBidAsk = {
  TITLE_BID: 'Top bid interest rate',
  TITLE_ASK: 'Top ask interest rate',
  VND: '(VND)',
  BOND: '(Bond)',
  PERCENT: '(%)',
  MONTH: '(Month)',
  KHCL: 'Remain Length',
  BestBid: 'Price',
  BestAsk: 'Price',
  YTM: 'YTM',
  VOL: 'Volume',
  VOL_ASK: 'Volume',
  TICKER: 'Ticker',
  ISSUER: 'Issuer',
}
