import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import { TABLE_TABS } from './constants'
import { Filters } from './filters'
import { selectTableTab } from './store/slice'
import Table from './tables'

export const Content = ({ width, height }) => {
  const tableTab = useSelector(selectTableTab)

  return (
    <SizeTracker key={tableTab}>
      {(size) => (
        <>
          <Filters />
          {tableTab === TABLE_TABS.SECTOR_ALLOCATION && (
            <div className="mb-8">
              <Span style={{ fontSize: 11 }} className="t-italic opacity-5">
                <Translate
                  value={'fund.fundCenter.statistic.NOTE_UNIT_BILLION_VND'}
                />
              </Span>
            </div>
          )}
          {size.height && (
            <div style={{ height: height - size.height }}>
              <Table />
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}
