import PropTypes from 'prop-types'
import ThePagination from '../../../../../common/pagination/ThePagination'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { PRICE_DATA_TIME_SELECT } from '../../constants'
import { CalendarSearch } from './CalendarSearch'
import { PriceRate } from './PriceRate'
import { Transaction } from './Transaction'

export const SummaryFilter = ({
  width,
  activeTab,
  page,
  totalPage,
  dateFrom,
  dateTo,
  transactionType,
  isTransactionType,
  onChangePage,
  onChangeTab,
  onChangeTransactionType,
  onDateFromChange,
  onDateToChange,
  onOpenDateFrom,
  onOpenDateTo,
  limitDatepicker,
}) => {
  return (
    <>
      <div className="table-info">
        <div className="table-info-left d-flex align-center">
          <DispatchActionTab
            data={Object.keys(PRICE_DATA_TIME_SELECT).map((key) => {
              return {
                title: `market.equityTrading.priceStatistics.priceData.${key}`,
                value: PRICE_DATA_TIME_SELECT[key],
              }
            })}
            activeTab={activeTab}
            itemStyle={{ fontWeight: 600, padding: '0 16px' }}
            onChangeTab={onChangeTab}
          />
        </div>
        <div className="table-info-right align-center w-100 ml-20">
          <CalendarSearch
            dateFrom={dateFrom}
            dateTo={dateTo}
            limitDatepicker={limitDatepicker}
            onDateFromChange={onDateFromChange}
            onDateToChange={onDateToChange}
            onOpenDateFrom={onOpenDateFrom}
            onOpenDateTo={onOpenDateTo}
          />
        </div>
      </div>
      <div className="table-info mb-0">
        <div className="table-info-left mb-8 d-flex align-center">
          {isTransactionType && (
            <Transaction
              value={transactionType}
              onChange={onChangeTransactionType}
            />
          )}
          <PriceRate />
        </div>
        <div className="table-info-right">
          <ThePagination
            currentPage={page}
            totalPages={totalPage}
            changeCurrentPage={onChangePage}
          />
        </div>
      </div>
    </>
  )
}

SummaryFilter.propTypes = {
  width: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  activeTab: PropTypes.string.isRequired,
  transactionType: PropTypes.string,
  isTransactionType: PropTypes.bool,
  onChangePage: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onDateFromChange: PropTypes.func.isRequired,
  onDateToChange: PropTypes.func.isRequired,
  onOpenDateFrom: PropTypes.func.isRequired,
  onOpenDateTo: PropTypes.func.isRequired,
  onChangeTransactionType: PropTypes.func,
  limitDatepicker: PropTypes.object,
}

SummaryFilter.defaultProps = {
  onChangeTransactionType: () => {},
}
