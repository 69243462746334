import CorporateProxy from '../../../proxies/tool/dataExplorer/CorporateProxy'
import { ServiceBase } from '../../ServiceBase'

class CorporateService extends ServiceBase {
  getIndicator(params) {
    return this.applyMemoryCache(
      'tool.dataExplorer.corporate.getIndicator',
      params,
    )(() => CorporateProxy.getIndicator(params))
  }

  getShareIssueMethod(params) {
    return this.applyMemoryCache(
      'tool.dataExplorer.corporate.getShareIssueMethod',
      params,
    )(() => CorporateProxy.getShareIssueMethod(params))
  }

  getActionType(params) {
    return this.applyMemoryCache(
      'tool.dataExplorer.corporate.getActionType',
      params,
    )(() => CorporateProxy.getActionType(params))
  }

  getResult(params) {
    return this.getData(() => CorporateProxy.getResult(params))
  }

  exportExcel(params) {
    return this.getFileDownload(() => CorporateProxy.exportExcel(params))
  }

  getFiinXTemplateResult(params) {
    return this.getData(() => CorporateProxy.getFiinXTemplateResult(params))
  }
}

export default new CorporateService()
