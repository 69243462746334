export const penetrationAnalysis = {
  PENETRATION_ANALYSIS: 'Penetration Analysis',
  FILTER: 'Filter',
  MARGIN_LENDING: 'Margin lending',
  PROPRIETARY: 'Proprietary',
  TREASURY: 'Treasury',
  QUARTER_1: 'Quarter 1',
  QUARTER_2: 'Quarter 2',
  QUARTER_3: 'Quarter 3',
  QUARTER_4: 'Quarter 4',
  ANNUAL: 'Annual',
  DETAIL_METRICS: 'Detail Metrics',
  MARKET_SHARE: 'Market share',
  LABEL_CHART_CHANGE: 'Change in Market Share YTD',
  TIME: 'Time',
  MILLION: '(Million)',
  BILLION: '(Billion VND)',
  BILLION_TOOLTIP: 'Bil VND',
  YOY_GROWTH: 'YoY Growth',
  LABEL_CHART_MARGIN_LENDING: 'Margin Lending Value & YoY Growth',
  LABEL_CHART_PROPRIETARY: 'Proprietary Portfolio Value & YoY Growth',
  LABEL_CHART_TREASURY: 'Treasury Portfolio Value & YoY Growth',
  MARGIN_LENDING_OWNER_EQUITY: "Margin Lending/ Owner's Equity",
  MARGIN_LENDING_TOTAL_ASSETS: 'Margin Lending/ Total Assets',
  YOY_GROWTH_PERCENT: 'YoY Growth (%) ',
  OTHERS: 'Others',
  MARGIN_LENDING_MARKET_SHARE: 'Margin Lending Market Share',
  PROPRIETARY_MARKET_SHARE: 'Proprietary Market Share',
  TREASURY_MARKET_SHARE: 'Treasury Market Share',
  MARGIN_LENDING_CHANGE_IN_MARKET_SHARE:
    'Change in Margin Lending Market Share YTD',
  PROPRIETARY_CHANGE_IN_MARKET_SHARE: 'Change in Proprietary Market Share YTD',
  TREASURY_CHANGE_IN_MARKET_SHARE: 'Change in Treasury Market Share YTD',
  billionUnit: '(bn. VND)',
  //Corporate
  REVENUE_BREAKDOWN: 'Revenue Breakdown',
  REVENUE: 'Revenue',
  GROWTH: 'Growth YoY',
  REVENUE_SECTOR: 'Revenue of',
  COMPANY: 'Company',
  corpTable: {
    iS3: 'Net Revenue (bn. VND)',
    growth: 'YoY Growth',
    rB1: 'Revenue Breakdown',
    marketShare: 'Market Share',
  },
  //Insurance
  INSU_REVENUE_CLAIM: 'Revenue & Claim expenses',
  INSU_REVENUE: 'Revenue from insurance premium',
  INSU_CLAIM_EXPENSES: 'Claim Expenses',
  INSU_CLAIM_COMBINED: 'Claim Ratio & Combined Ratio',
  INSU_CLAIM_RATIO: 'Claim Ratio',
  INSU_COMBINED_RATIO: 'Combined Ratio',
  insuTable: {
    TPIS1: 'Market Share',
    YoYGrowthIS1: 'Change in Market Share YTD (%)',
    NI153: 'Gross Written Premium (bn. VND)',
    GWP: 'Gross Written Premium Growth (%)',
    NI154: 'Life Insurance (bn. VND)',
    LIG: 'Life Insurance Growth (%YoY)',
    NI155: 'Non-Life Insurance (bn. VND)',
    NLIG: 'Non-Life Insurance Growth (%YoY)',
    IS73: 'Claim Expenses (bn. VND)',
    RT0338: 'Claim Ratio',
    RT0339: 'Combined Ratio',
  },
  //Bank
  LOAN: 'LOAN',
  DEPOSIT: 'DEPOSIT',
  LOAN_TO_CUSTOMER_TITLE: 'Loan to customers & Non - performing loan',
  LOAN_TO_CUSTOMER: 'Loan to customers',
  LOAN_TO_CUSTOMER_LABEL: 'Loan to customers (Bil. VND)',
  LOAN_MARKET_SHARE: 'Loan market share ',
  LOAN_CHART_CHANGE: 'Change in Loan Market Share YTD',
  DEPOSIT_CHART_CHANGE: 'Change in Deposit Market Share YTD',
  LOAN_NON_PERFORMING: 'Non-performing Loan',
  LOAN_NON_PERFORMING_LABEL: 'Non-performing Loan (Bil. VND)',
  LOAN_BY_SECTOR: 'Loan by sector',
  loanSector: {
    trading: 'Trading',
    agricultureForestry: 'Agriculture and forestry',
    manufacturing: 'Manufacturing',
    construction: 'Construction',
    personalPublicServices: 'Personal and public services',
    warehousing: 'Warehousing, transportation and communication',
    trainingEducation: 'Training and education',
    estateAgent: 'Estate agents and consultants',
    hotelRestaurants: 'Hotels and restaurants',
    financialServices: 'Financial services',
    others: 'Others',
  },
  MARKET_SHARE_BY_LOAN_SECTOR: 'Market share by loan sector',
  DEPOSIT_FROM_CUSTOMER_TITLE: 'Deposit from customers & Loan to Deposit Ratio',
  DEPOSIT_FROM_CUSTOMER: 'Deposit from customers',
  LOAN_TO_DEPOSIT_RATIO: 'Loan to Deposit Ratio',
  DEPOSIT_MARKET_SHARE: 'Deposit market share',
  CASA_RATIO_TITLE: 'CASA & CASA Ratio',
  CASA: 'CASA',
  CASA_RATIO: 'CASA Ratio',
  CASA_MARKET_SHARE: 'CASA Market Share',
  depositRow: {
    deposit: 'Deposit from customers',
    depositYoYGrowth: 'YoY Growth',
    depositMarketShare: 'Deposit Market Share',
    depositChangeInMarketShare: 'Change in Deposit Market Share YTD',
    casa: 'CASA',
    casaYoYGrowth: 'YoY Growth',
    casaMarketShare: 'CASA Market Share',
    casaChangeInMarketShare: 'Change in CASA Market Share YTD',
  },
}

export const segmentalAnalysis = {
  SEGMENTAL_ANALYSIS: 'Segmental Analysis',
  INCOME_STRUCTURE: 'Income Structure',
  GROWTH_TIMELINE: 'Growth timeline',
  PEERS_COMPARISON: 'Peers Comparison',
  OF: 'of',
  BILLION_VND: '(Billion VND)',
  UNIT_BILLION_VND: 'Unit: Billion VND',
  VALUE: 'Value',
  VALUE_PERCENT: '%VALUE',
  PROPORTION: '% PROPORTION',
  IN: 'IN',
  FOR_THE_FIRST: 'For the First',
  MONTH_OF: 'Months of',
  TOTAL_OPERATING_INCOME: 'Total Operating Income',
  ANNUAL: 'Annual',
}

export const costOfOperation = {
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  BILLION: '(Billion VND)',
  REVENUE_COST_TITLE: 'Revenue & Cost of Operation',
  COST_OF_OPERATION: 'Cost of Operation',
  REVENUE: 'Revenue',
  COST_PER_REVENUE: 'Cost/ Revenue',
  COST_STRUCTURE: 'Cost Structure',
  DETAIL_METRICS: 'Detail Metrics',
  DETAIL_METRIC_UNIT: 'Unit: Billion VND',
  PARTICULAR: 'Growth Timeline',
  indicators: {
    rawMaterials: 'Raw materials',
    labourCosts: 'Labour costs',
    depreciation: 'Depreciation expenses',
    outsideService: 'Outside service expenses',
    otherCash: 'Other cash expenses',
  },
  GROWTH_PERCENT: 'YoY Growth (%)',
  PORTION_PERCENT: 'Portion (%)',
  GROWTH: 'YoY Growth',
  CHART_BILLION_VND: 'Bil VND',
}
