import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  selectDataCostStructure,
  selectLoadingCostStructure,
} from '../store/slice'
import { getCostStructure } from '../store/thunk'
import ChartComponent from './ChartComponent'

const ChartContainer = ({ width, height }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const dataChart = useSelector(selectDataCostStructure)
  const loading = useSelector(selectLoadingCostStructure)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getCostStructure({
          OrganizationId: basicInfo.organizationId,
        }),
      )
    }
  }, [basicInfo.organizationId])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div style={{ paddingBottom: 24 }}></div>
            {size.height && (
              <div
                style={{ position: 'relative', height: height - size.height }}
              >
                {loading ? (
                  <Loading />
                ) : dataChart.length === 0 ? (
                  <NoData />
                ) : (
                  <ChartComponent
                    data={dataChart}
                    width={width}
                    height={height - size.height}
                  />
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartContainer
