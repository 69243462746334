import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import {
  CartesianGrid,
  ComposedChart,
  Customized,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { GROUP_BY_YEAR } from '../../../financialChart/common/helpers/chartSchemaByTimeHelpers'
import { getFontSize } from '../../../utils/FontSize'
import UseFontSize from '../../hooks/useFontSize'
import UseTimeZone from '../../hooks/useTimeZone'
import { getTextWidth } from '../helper'
import CrossHairCursorX from './CrossHairCursorX'
import CrossHairCursorY from './CrossHairCursorY'
import MouseCoordinateY from './MouseCoordinateY'
import ReferenceLabel from './ReferenceLabel'
import { XAxisVerticalLabel } from './XAxisVerticalLabel'
import {
  CHART_TYPES,
  CHART_TYPE_PRIORITY,
  HEIGHT_XAXIS,
  MARGIN_CHART,
} from './constant'
import {
  formatterByName,
  getAxisProps,
  labelFormatterByTimeRange,
  renderChartComponent,
} from './helper'
import style from './index.module.css'
import TooltipTwoColumn from './tooltipCustom/TooltipTwoColumn'

export const FinancialChart = ({
  children,
  width,
  height,
  data,
  schema,
  marginChart,
  isShowYAxisLegend,
  isHideTooltip,
  yAxisLabelStyle,
  onClick,
  styleSetting,
  yAxisSettingStyle,
  typeLegendYAxis,
  formatXAxis,
}) => {
  const typeFontSize = UseFontSize()
  const timeZone = UseTimeZone()
  const locale = useSelector((state) => state.i18n.locale)

  const { xAxis, yAxisLabel, yAxisPosition } = styleSetting

  const { xAxisProps, yAxisProps, totalLeftYAxisWidth, totalRightYAxisWidth } =
    getAxisProps({
      width,
      height,
      data,
      schema,
      typeFontSize,
      timeZone,
      locale,
      styleXAxis: xAxis,
      styleYAxis: yAxisPosition,
      styleYAxisLabel: yAxisLabel,
      isShowYAxisLegend,
      formatXAxis,
    })

  const checkExistYAxis = (orientation) => {
    const arrayOrientationYAxis = yAxisProps.map((item) => item.orientation)
    return !arrayOrientationYAxis.includes(orientation)
  }

  const getMargin = () => {
    const margin = {
      ...MARGIN_CHART,
      ...marginChart,
      left: checkExistYAxis('left') ? 0 : marginChart?.left,
      right: checkExistYAxis('right') ? 0 : marginChart?.right,
    }
    if ((!margin.bottom || margin.bottom < 16) && xAxisProps.length > 1) {
      margin.bottom = 16
    }
    return margin
  }

  const getXAxisHeight = (props) => {
    const tickFontSize = getFontSize(props.fontSize, typeFontSize)

    if (props.isVerticalLabel) {
      let maxWidth = Math.max(
        ...data.map((item) =>
          Math.ceil(getTextWidth(item[props.dataKey], tickFontSize)),
        ),
      )
      const XAXIS_BOTTOM_SPACE =
        props.xAxisAngle >= 45 && props.xAxisAngle <= 135 ? 0 : 10
      const xAxisHeight =
        Math.sin((props.xAxisAngle * Math.PI) / 180) * maxWidth +
        XAXIS_BOTTOM_SPACE

      if (xAxisHeight > HEIGHT_XAXIS) {
        return xAxisHeight
      }
    }
  }

  const renderXAxis = () => {
    return xAxisProps.map((props) => {
      const xAxisHeight = getXAxisHeight(props)
      let tick = props.tick

      if (props.isVerticalLabel && props.xAxisAngle) {
        tick = <XAxisVerticalLabel angle={props.xAxisAngle} />
      }

      if (props.xAxisId === GROUP_BY_YEAR) {
        tick = props.calcTickByWidth(
          width - totalLeftYAxisWidth - totalRightYAxisWidth,
        )
      }

      if (xAxisHeight) {
        props.height = xAxisHeight
      }

      return <XAxis key={props.xAxisId} {...props} tick={tick} />
    })
  }

  const renderYAxis = () => {
    return yAxisProps.map((props) => <YAxis key={props.yAxisId} {...props} />)
  }

  const renderCharts = () => {
    const numberBar = {}
    schema.charts.forEach((item) => {
      if (item.chartType === CHART_TYPES.BAR) {
        numberBar[item.dataKey] = item.dataKey
      }

      if (item.chartType === CHART_TYPES.STACK_BAR) {
        numberBar[item.stackId] = item.dataKey
      }
    })

    return schema.charts
      .sort((a, b) => {
        if (
          CHART_TYPE_PRIORITY[a.chartType] < CHART_TYPE_PRIORITY[b.chartType]
        ) {
          return -1
        } else if (
          CHART_TYPE_PRIORITY[a.chartType] > CHART_TYPE_PRIORITY[b.chartType]
        ) {
          return 1
        } else {
          if (a.chartOrder < b.chartOrder) return -1
          else if (a.chartOrder < b.chartOrder) return 1
          else return 0
        }
      })
      .map((config, index, arr) => {
        let lastChartIndex = 0
        let isLastTypeChart = true

        arr.forEach((item, itemIndex) => {
          if (item.chartType === config.chartType) {
            lastChartIndex = itemIndex
          }
        })

        if (
          [CHART_TYPES.STACK_AREA, CHART_TYPES.STACK_BAR].includes(
            config.chartType,
          )
        ) {
          isLastTypeChart = lastChartIndex === index
        }

        return (
          <Fragment key={index}>
            {renderChartComponent({
              ...config,
              index,
              isLastChart: isLastTypeChart,
              width: width - totalLeftYAxisWidth - totalRightYAxisWidth,
              numberCategory: data.length,
              numberBar: Object.keys(numberBar).length,
            })}
          </Fragment>
        )
      })
  }

  const renderTooltip = () => {
    const labelFormatter = labelFormatterByTimeRange(schema.xAxis[0]?.timeRange)
    const formatter = formatterByName(schema.tooltips)

    return (
      <Tooltip
        cursor={false}
        isAnimationActive={false}
        labelFormatter={labelFormatter}
        formatter={formatter}
        wrapperStyle={{ zIndex: 7 }}
        content={
          <TooltipTwoColumn
            timeRange={schema.xAxis[0]?.timeRange}
            tooltipSchema={schema?.tooltips}
            locale={locale}
            timeZone={timeZone}
            isShowLabel={!schema.xAxis[0]?.isVerticalLabel}
          />
        }
      />
    )
  }

  return (
    <div className={style.container} style={{ width, height }}>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <ComposedChart
          data={data}
          margin={getMargin()}
          barGap={0}
          onClick={onClick}
          stackOffset="sign"
        >
          {schema.hasCartesianGrid && (
            <CartesianGrid
              stroke="#999"
              strokeWidth={1}
              vertical={false}
              opacity={0.5}
            />
          )}
          {renderXAxis()}
          {renderYAxis()}
          {renderCharts()}
          {children}
          {schema.hasCartesianGrid && (
            <CartesianGrid
              stroke="#999"
              strokeWidth={1}
              vertical={false}
              opacity={0.5}
            />
          )}
          <Customized component={CrossHairCursorX} />
          <Customized component={CrossHairCursorY} />
          {schema.hasMouseCoordinateY && (
            <Customized component={MouseCoordinateY} />
          )}
          {yAxisLabelStyle && <Customized component={yAxisLabelStyle()} />}
          {yAxisSettingStyle && <Customized component={yAxisSettingStyle()} />}
          {!isHideTooltip && renderTooltip()}
          {isShowYAxisLegend && schema.hasReferenceLabel && (
            <Customized
              component={ReferenceLabel}
              charts={schema.charts}
              typeFontSize={typeFontSize}
              typeLegendYAxis={typeLegendYAxis}
              yAxisLabel={yAxisLabel}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

FinancialChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  schema: PropTypes.shape({
    xAxis: PropTypes.arrayOf(
      PropTypes.shape({
        dataKey: PropTypes.string.isRequired,
        timeRange: PropTypes.string,
        fontSize: PropTypes.number,
        // All rechart xAxis props
      }).isRequired,
    ).isRequired,
    yAxis: PropTypes.arrayOf(
      PropTypes.shape({
        yAxisId: PropTypes.string.isRequired,
        dataKeys: PropTypes.array.isRequired,
        fontSize: PropTypes.number,
        isGroupBar: PropTypes.bool,
        isStackedBar: PropTypes.bool,
        isLineChart: PropTypes.bool,
        isBarChart: PropTypes.bool,
        labelText: PropTypes.string,
        labelPosition: PropTypes.string,
        labelFontSize: PropTypes.number,
        // All rechart yAxis props
      }),
    ).isRequired,
    charts: PropTypes.arrayOf(
      PropTypes.shape({
        chartType: PropTypes.string,
        yAxisId: PropTypes.string,
        dataKey: PropTypes.string,
        color: PropTypes.string,
      }),
    ).isRequired,
    tooltips: PropTypes.arrayOf(
      PropTypes.shape({
        dataKey: PropTypes.string.isRequired,
        formatName: PropTypes.string,
        decimalLength: PropTypes.number,
        dataUnit: PropTypes.string,
      }),
    ).isRequired,
    hasMouseCoordinateY: PropTypes.bool,
    hasCartesianGrid: PropTypes.bool,
    hasReferenceLabel: PropTypes.bool,
    marginChart: PropTypes.object,
  }),
  isShowYAxisLegend: PropTypes.bool,
  isHideTooltip: PropTypes.bool,
  styleSetting: PropTypes.object,
  onClick: PropTypes.func,
  typeLegendYAxis: PropTypes.string,
}

FinancialChart.defaultProps = {
  width: 0,
  height: 0,
  data: [],
  schema: {},
  hasReferenceLabel: false,
  isShowYAxisLegend: true,
  isHideTooltip: false,
  styleXAxis: {},
  styleYAxis: {},
  onClick: () => {},
  styleSetting: {
    xAxis: {},
    yAxisLabel: {},
    yAxisPosition: {},
  },
  typeLegendYAxis: 'TYPE_1',
}
