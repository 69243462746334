import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import style from '../../../common/table/index.module.css'
import { TABLE_ROW_LEVEL } from './constants'
import { selectGroupColumns, selectItemData } from './store/slice'

export const OtherColumnsCell = ({
  val,
  rowId,
  colIndex,
  defaultActiveRowId,
  numCol,
  props,
}) => {
  const item = useSelector(selectItemData(rowId))
  const groupColumns = useSelector(selectGroupColumns)
  const tdRef = useRef()

  useEffect(() => {
    if (rowId === defaultActiveRowId) {
      if (tdRef.current) {
        tdRef.current.classList.add(style.active)
      }
    } else {
      if (tdRef.current) {
        tdRef.current.classList.remove(style.active)
      }
    }
  }, [groupColumns])

  if (item.level === TABLE_ROW_LEVEL.LEVEL_1) {
    if (colIndex === 1) {
      return <td {...props} ref={tdRef} colSpan={numCol - 1} />
    }
    return null
  }
  if (item.level === TABLE_ROW_LEVEL.LEVEL_3) {
    return (
      <td
        {...props}
        ref={tdRef}
        style={{
          ...props.style,
          color: '#ececec66',
          fontStyle: 'italic',
          textAlign: 'right',
        }}
      >
        {val}
      </td>
    )
  }

  return (
    <td {...props} ref={tdRef} style={{ ...props.style, textAlign: 'right' }}>
      {val}
    </td>
  )
}

OtherColumnsCell.propTypes = {
  val: PropTypes.string.isRequired,
  rowId: PropTypes.string.isRequired,
  colIndex: PropTypes.number.isRequired,
  defaultActiveRowId: PropTypes.string.isRequired,
  numCol: PropTypes.number.isRequired,
  props: PropTypes.object.isRequired,
}
