export const sectorInDepth = {
  NAME_SCREEN: 'Ngành chuyên sâu',
  TAB_NAME: 'Tổng giá trị theo ngành',
  CHART_VALUE_RELATIVE_WITH_INDEX: 'Giá trị tương đối với chỉ số',
  CHART_VALUE: 'Giá trị',
  CHART_PERCENT_VALUE: '% Giá trị',
  SECTOR_STATISTIC: 'THỐNG KÊ NGÀNH',
  SECTOR: 'NGÀNH',
  NOTE1: 'Các ngành được phân loại bởi Phân loại Công nghiệp Benchmark (ICB),',
  NOTE2: 'một tiêu chuẩn để phân loại ngành.',
  CHANGE1D: '% THAY ĐỔI 1D',
  CHANGE1W: '% THAY ĐỔI 1W',
  CHANGE1M: '% THAY ĐỔI 1M',
  CHANGE3M: '% THAY ĐỔI 3M',
  CHANGE6M: '% THAY ĐỔI 6M',
  CHANGE1Y: '% THAY ĐỔI 1Y',
  AVG_TRADING_VALUE1D: 'TỔNG GTGD TB 1D',
  AVG_TRADING_VALUE1W: 'TỔNG GTGD TB 1W',
  AVG_TRADING_VALUE1M: 'TỔNG GTGD TB 1M',
  AVG_TRADING_VALUE3M: 'TỔNG GTGD TB 3M',
  AVG_TRADING_VALUE6M: 'TỔNG GTGD TB 6M',
  AVG_TRADING_VALUE1Y: 'TỔNG GTGD TB 1Y',
  AVG_TRADING_VOLUME1D: 'TỔNG KLGD TB 1D',
  AVG_TRADING_VOLUME1W: 'TỔNG KLGD TB 1W',
  AVG_TRADING_VOLUME1M: 'TỔNG KLGD TB 1M',
  AVG_TRADING_VOLUME3M: 'TỔNG KLGD TB 3M',
  AVG_TRADING_VOLUME6M: 'TỔNG KLGD TB 6M',
  AVG_TRADING_VOLUME1Y: 'TỔNG KLGD TB 1Y',
  CHANGE_VALUE1D: '% THAY ĐỔI GTGD 1D',
  CHANGE_VALUE1W: '% THAY ĐỔI GTGD 1W',
  CHANGE_VALUE1M: '% THAY ĐỔI GTGD 1M',
  CHANGE_VALUE3M: '% THAY ĐỔI GTGD 3M',
  CHANGE_VALUE6M: '% THAY ĐỔI GTGD 6M',
  CHANGE_VALUE1Y: '% THAY ĐỔI GTGD 1Y',
  CHANGE_VOLUME1D: '% THAY ĐỔI KLGD 1D',
  CHANGE_VOLUME1W: '% THAY ĐỔI KLGD 1W',
  CHANGE_VOLUME1M: '% THAY ĐỔI KLGD 1M',
  CHANGE_VOLUME3M: '% THAY ĐỔI KLGD 3M',
  CHANGE_VOLUME6M: '% THAY ĐỔI KLGD 6M',
  CHANGE_VOLUME1Y: '% THAY ĐỔI KLGD 1Y',
  TRADING_VALUE: 'TỔNG GTGD',
  VALUE: '% GTGD',
  TRADING_VOLUME: 'TỔNG KLGD',
  VOLUME: '% KLGD',
  ICB_LEVEL: 'Cấp',
  TITLE_CHART: 'TỔNG GIÁ TRỊ THEO NGÀNH',
  TAB_VALUE: 'GIÁ TRỊ',
  TAB_PERCENT_VALUE: '% GIÁ TRỊ',
  RELATIVE_INDEX: 'Tương đối với chỉ số',
  POINT: '(Điểm)',
  VALUE_CONTRIBUTION: 'Tỷ trọng giá trị',
  TOTAL_VALUE: 'Tổng giá trị',
  BILLION: '(Tỷ VND)',
  TITLE_TOP_VALUE: 'TOP GIÁ TRỊ',
  NO: 'STT',
  TICKER: 'MÃ',
  PRICE: 'GIÁ',
  CHANGE: '% THAY ĐỔI',
  LOCAL_INDIVIDUAL: 'CÁ NHÂN\nTRONG NƯỚC',
  LOCAL_INSTITUTION: 'TỔ CHỨC\nTRONG NƯỚC',
  FOREIGN: 'NƯỚC\nNGOÀI',
  PROPRIETARY: 'TỰ\nDOANH',
  RELATIVE_VALUE: 'Giá trị (Tỷ VND)',
  RELATIVE_BILLION: 'Tỷ VND',
  TOTAL: 'Tổng',
  LAST: 'CHỈ SỐ',
  ONE_DAY_CHANGE: 'THAY ĐỔI',
  ONE_DAY_PERCENT_CHANGE: '% THAY ĐỔI 1D',
  ONE_DAY_VALUE: 'TỔNG GTGD',
  ONE_DAY_VOLUMN: 'TỔNG KLGD',
  FOREIGN_VALUE: 'TỔNG GTNN RÒNG',
  FOREIGN_VOLUMN: 'TỔNG KLNN RÒNG',
  VALUE_BILLION: 'Tỷ',
}
