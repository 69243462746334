import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import SelectCustom from '../../../../../common/selectCustom'
import VerticalLine from '../../../../../common/topInfo/components/VerticalLine'
import CalendarFilter from '../../common/CalendarFilter'
import style from '../../index.module.css'
import { SearchDropdown } from './SearchDropdown'
import { DROP_LIST_EXCHANGE_STATUS } from './constants'
import {
  changeCurrentIssuerId,
  changeCurrentUnderlyingId,
  changeDateFrom,
  changeDateTo,
  changeStatus,
} from './store/slice'

const RESPONSIVE_WIDTH_RATIO = 0.6

export const FilterList = ({
  filterData,
  underlyingsList,
  issuerOrganizationsList,
  width,
}) => {
  const dispatch = useDispatch()

  const [responsiveWidthRatioSmall, setResponsiveWidthRatioSmall] =
    useState(false)

  const handleChangeStatus = (item) => {
    dispatch(changeStatus(item))
  }

  const selectionListWidth =
    document.getElementById('searchDropdownUnderlying')?.getBoundingClientRect()
      .width +
      document.getElementById('searchDropdownIssuer')?.getBoundingClientRect()
        .width +
      document.getElementById('droplistStatus')?.getBoundingClientRect()
        .width ?? width

  useEffect(() => {
    if (selectionListWidth / width < RESPONSIVE_WIDTH_RATIO) {
      setResponsiveWidthRatioSmall(true)
    } else {
      setResponsiveWidthRatioSmall(false)
    }
  }, [selectionListWidth / width])

  return responsiveWidthRatioSmall ? (
    <div className="row" style={{ marginBottom: 8 }}>
      <div className="row" id="searchDropdownUnderlying">
        <Span className={style.ticker}>
          <Translate value="market.coveredWarrant.cwStatistics.overview.UNDERLYING_ASSET" />
        </Span>
        <SearchDropdown
          listItems={underlyingsList}
          selectedItem={filterData?.currentUnderlyingId}
          changeCurrentSelectedItem={changeCurrentUnderlyingId}
          currentIds={underlyingsList.map((item) => item.value)}
        />
      </div>
      <div className="row" style={{ marginLeft: 20 }} id="searchDropdownIssuer">
        <Span className={style.droplist}>
          <Translate value="market.coveredWarrant.cwStatistics.overview.ISSUER" />
        </Span>
        <SearchDropdown
          listItems={issuerOrganizationsList}
          selectedItem={filterData?.currentIssuerId}
          changeCurrentSelectedItem={changeCurrentIssuerId}
          currentIds={issuerOrganizationsList.map((item) => item.value)}
        />
      </div>
      <div className="row" style={{ marginLeft: 20 }} id="droplistStatus">
        <Span className={style.droplist}>
          <Translate value="market.coveredWarrant.cwStatistics.overview.TRADING_STATUS" />
        </Span>
        <div className={`${style.inputDropdown}`} style={{ width: 150 }}>
          <SelectCustom
            value={filterData?.status}
            isI18n={true}
            selectData={DROP_LIST_EXCHANGE_STATUS}
            handleChange={handleChangeStatus}
          />
        </div>
      </div>
      <VerticalLine styleProps={{ height: 20 }} ml={10} />
      <CalendarFilter
        changeDateFrom={changeDateFrom}
        changeDateTo={changeDateTo}
        filterData={filterData}
      />
    </div>
  ) : (
    <>
      <div className="row" style={{ marginBottom: 8 }}>
        <div className="row" id="searchDropdownUnderlying">
          <Span className={style.ticker}>
            <Translate value="market.coveredWarrant.cwStatistics.overview.UNDERLYING_ASSET" />
          </Span>
          <SearchDropdown
            listItems={underlyingsList}
            selectedItem={filterData?.currentUnderlyingId}
            changeCurrentSelectedItem={changeCurrentUnderlyingId}
            currentIds={underlyingsList.map((item) => item.value)}
          />
        </div>
        <div
          className="row"
          style={{ marginLeft: 20 }}
          id="searchDropdownIssuer"
        >
          <Span className={style.droplist}>
            <Translate value="market.coveredWarrant.cwStatistics.overview.ISSUER" />
          </Span>
          <SearchDropdown
            listItems={issuerOrganizationsList}
            selectedItem={filterData?.currentIssuerId}
            changeCurrentSelectedItem={changeCurrentIssuerId}
            currentIds={issuerOrganizationsList.map((item) => item.value)}
          />
        </div>
        <div className="row" style={{ marginLeft: 20 }} id="droplistStatus">
          <Span className={style.droplist}>
            <Translate value="market.coveredWarrant.cwStatistics.overview.TRADING_STATUS" />
          </Span>
          <div className={`${style.inputDropdown}`} style={{ width: 150 }}>
            <SelectCustom
              value={filterData?.status}
              isI18n={true}
              selectData={DROP_LIST_EXCHANGE_STATUS}
              handleChange={handleChangeStatus}
            />
          </div>
        </div>
      </div>
      <div className={style.calendarFilterSmall}>
        <CalendarFilter
          changeDateFrom={changeDateFrom}
          changeDateTo={changeDateTo}
          filterData={filterData}
        />
      </div>
    </>
  )
}
