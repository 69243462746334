import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../configs/Global'
import { Loading } from '../../../common/loading'
import { Realtime } from '../../../common/Realtime'
import { SizeTracker } from '../../../common/sizeTracker'
import { formatVal } from '../../../utils/Value'
import {
  selectIndicator,
  selectLoadingLatestPrice,
  selectPriceData,
  subscribeBidAsk,
  subscribeDerivative,
} from '../summary/store/slice'
import ChartContent from './chart/ChartContent'
import ChartFooter from './chart/ChartFooter'
import ChartHeader from './chart/ChartHeader'

const PriceDepthPanel = ({ height }) => {
  const data = useSelector(selectPriceData)
  const isLoading = useSelector(selectLoadingLatestPrice)
  const derivativeActive = useSelector(selectIndicator)

  const getTotalBuyVolume = () => {
    const bestBidVol = [...Array(10)].map(
      (_, index) => `best${index + 1}BidVolume`,
    )
    return bestBidVol
      .map((key) => (data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0))
      .reduce((prev, curr) => prev + curr, 0)
  }

  const getTotalSellVolume = () => {
    const bestAskVol = [...Array(10)].map(
      (_, index) => `best${index + 1}OfferVolume`,
    )
    return bestAskVol
      .map((key) => (data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0))
      .reduce((prev, curr) => prev + curr, 0)
  }

  const getMaxBuyVolume = () => {
    const bestBidVol = [...Array(10)].map(
      (_, index) => `best${index + 1}BidVolume`,
    )
    return Math.max(
      ...bestBidVol.map((key) =>
        data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0,
      ),
    )
  }

  const getMaxSellVolume = () => {
    const bestAskVol = [...Array(10)].map(
      (_, index) => `best${index + 1}OfferVolume`,
    )
    return Math.max(
      ...bestAskVol.map((key) =>
        data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0,
      ),
    )
  }

  const getMaxBuySellVolume = useMemo(() => {
    return Math.max(getMaxBuyVolume(), getMaxSellVolume())
  }, [data])

  return (
    <div className="h-100" id="derivativePriceDepth">
      {isLoading ? (
        <Loading />
      ) : (
        <SizeTracker>
          {(size) => (
            <>
              <ChartHeader />
              {size.height && (
                <div style={{ height: height - size.height }}>
                  <ChartContent
                    totalBuyVolume={getMaxBuySellVolume}
                    totalSellVolume={getMaxBuySellVolume}
                    data={data}
                    numberBar={10}
                  />
                </div>
              )}
              <ChartFooter
                totalBuyVolume={formatVal(getTotalBuyVolume())}
                totalSellVolume={formatVal(getTotalSellVolume())}
                totalMatchVolume={
                  data.priceInfo
                    ? formatVal(data.priceInfo.totalMatchVolume)
                    : '0.00'
                }
              />
              <Realtime
                channel={
                  ChannelConfig.DerivativeChannel + derivativeActive.futureId
                }
                action={subscribeDerivative}
              >
                <Realtime
                  channel={
                    ChannelConfig.DerivativeBidAskChannel +
                    derivativeActive.futureId
                  }
                  action={subscribeBidAsk}
                >
                  <></>
                </Realtime>
              </Realtime>
            </>
          )}
        </SizeTracker>
      )}
    </div>
  )
}

export default PriceDepthPanel
