import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { INDICATORS, ITEM_TYPE, TIME_FRAME } from '../../constant'
import { valDivTrillion } from './../../../../../utils/Value'
import { getLineChart } from './thunk'

const initialState = {
  timeFrame: TIME_FRAME.ONE_YEAR.value,
  loading: true,
  data: [],
  hoveredItem: null,
}

const slice = createSlice({
  name: 'sector/sectorConstituents/valuation/valuationChart',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeTimeFrame: (state, action) => {
      state.timeFrame = action.payload
    },
    changeHoveredItem: (state, action) => {
      state.hoveredItem = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLineChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getLineChart.fulfilled, (state, action) => {
      const data = {}
      action.payload.forEach((item) => {
        if (!data[item.tradingDateId]) {
          data[item.tradingDateId] = { tradingDateId: item.tradingDateId }
        }
        if (item.organizationId) {
          Object.values(INDICATORS).forEach((value) => {
            data[item.tradingDateId][
              `${ITEM_TYPE.TICKER}-${item.organizationId}-${value}`
            ] = [INDICATORS.ev, INDICATORS.marketCap].includes(value)
              ? valDivTrillion(item[value])
              : item[value]
          })
        } else {
          Object.values(INDICATORS).forEach((value) => {
            data[item.tradingDateId][
              `${ITEM_TYPE.SECTOR}-${item.icbId}-${value}`
            ] = [INDICATORS.ev, INDICATORS.marketCap].includes(value)
              ? valDivTrillion(item[value])
              : item[value]
          })
        }
      })

      state.data = Object.values(data).sort(
        (a, b) => new Date(a.tradingDateId) - new Date(b.tradingDateId),
      )
      state.loading = false
    })
    builder.addCase(getLineChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectTimeFrame = (state) => state[slice.name].timeFrame
export const selectLoading = (state) => state[slice.name].loading
export const selectData = (state) => state[slice.name].data
export const selectHoveredItem = (state) => state[slice.name].hoveredItem

export const { resetStore, changeTimeFrame, changeHoveredItem } = slice.actions

register(slice.name, slice.reducer)
