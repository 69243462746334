import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import style from '../style.module.css'
import Content from './Content'
import { keepListCheckbox } from './growthIndex/store/slice'
import { changeIsFirstTimeRenderChart } from './growthYear/store/slice'
import { tabOptions } from './table/constants'
import { changeTimeType, selectTimeType } from './table/store/slice'

const Consumption = () => {
  const dispatch = useDispatch()

  const timeType = useSelector(selectTimeType)

  const onChangeTimeType = (data) => {
    dispatch(changeTimeType(data.value))
    dispatch(changeIsFirstTimeRenderChart(true))
    dispatch(keepListCheckbox(true))
  }

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="top-info">
                <Title
                  title={
                    'economy.productionAndConsumption.consumption.CONSUMPTION'
                  }
                />
                <VerticalLine ml={12} mr={12} styleProps={{ height: 20 }} />
                <ul className={`list-check d-flex mt-0`}>
                  {tabOptions.map((item) => (
                    <li key={item.value} className="mb-0">
                      <label>
                        <input
                          type="radio"
                          className="radiobox radiobox2"
                          checked={item.value === timeType}
                          onChange={() => onChangeTimeType(item)}
                        />
                        <Span
                          style={{ fontSize: 12 }}
                          className={
                            item.value === timeType ? style.active : ''
                          }
                        >
                          <Translate value={item.title} />
                        </Span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              {size.height && (
                <div
                  className={`summary-page`}
                  style={{
                    height: `calc(100% - ${size.height}px)`,
                    position: 'relative',
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}

export default Consumption
