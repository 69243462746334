import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { COMPONENT } from '../../../../../configs/Layout'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { withWrapper } from '../../../../common/withWrapper'
import { PANEL_PADDING } from '../../../../constants/Common'
import Filter from './Filter'
import PanelFive from './PanelFive'
import PanelFour from './PanelFour'
import PanelOne from './PanelOne'
import PanelSix from './PanelSix'
import PanelThree from './PanelThree'
import PanelTwo from './PanelTwo'
import { resetStore as resetStoreDeposit } from './deposit/store/slice'
import { resetStore as resetStoreLoan } from './loan/store/slice'
import { resetStore as resetStoreFilter } from './store/slice'
import { getBankMaxPeriod } from './store/thunk'

const HEIGHT_HEADER_WRAPPER = 60
const HEADER_PANEL_HEIGHT = 49
const MIN_HEIGHT = 290

const MAP_KEY = {
  WRAPPER: 'WRAPPER',
  PANEL_ONE: 'PANEL_ONE',
  PANEL_TWO: 'PANEL_TWO',
  PANEL_THREE: 'PANEL_THREE',
  PANEL_FOUR: 'PANEL_FOUR',
  PANEL_FIVE: 'PANEL_FIVE',
  PANEL_SIX: 'PANEL_SIX',
}

const MAP_SIZE = {
  [`${MAP_KEY.WRAPPER}`]: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    minTop: 0,
    minLeft: 0,
    minHeight: HEIGHT_HEADER_WRAPPER + 2 * MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    minWidth:
      COMPONENT.MIN_SIZE.WIDTH * 3 + PANEL_PADDING + 4 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PANEL_ONE}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    left: PANEL_PADDING / 2,
    minLeft: PANEL_PADDING / 2,
    minTop: HEIGHT_HEADER_WRAPPER,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.PANEL_TWO,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.PANEL_TWO,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.PANEL_THREE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.PANEL_FOUR,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.PANEL_FIVE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.PANEL_SIX,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.PANEL_TWO}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    left: `calc((100% - ${PANEL_PADDING}px) / 3 + ${
      COMPONENT.MARGIN + PANEL_PADDING / 2
    }px)`,
    minLeft:
      PANEL_PADDING / 2 + COMPONENT.MIN_SIZE.WIDTH + 2 * COMPONENT.MARGIN,
    minTop: HEIGHT_HEADER_WRAPPER,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.PANEL_THREE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PANEL_THREE}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    left: `calc((100% - ${PANEL_PADDING}px) / 3 * 2 + ${
      COMPONENT.MARGIN + PANEL_PADDING / 2
    }px)`,
    minLeft:
      PANEL_PADDING / 2 + 2 * COMPONENT.MIN_SIZE.WIDTH + 4 * COMPONENT.MARGIN,
    minTop: HEIGHT_HEADER_WRAPPER,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PANEL_FOUR}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 + ${
      HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN
    }px)`,
    left: PANEL_PADDING / 2,
    minLeft: PANEL_PADDING / 2,
    minTop: HEIGHT_HEADER_WRAPPER + MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.PANEL_FIVE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PANEL_FIVE}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 + ${
      HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN
    }px)`,
    left: `calc((100% - ${PANEL_PADDING}px) / 3 + ${
      COMPONENT.MARGIN + PANEL_PADDING / 2
    }px)`,
    minLeft:
      PANEL_PADDING / 2 + COMPONENT.MIN_SIZE.WIDTH + 2 * COMPONENT.MARGIN,
    minTop: HEIGHT_HEADER_WRAPPER + MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.PANEL_SIX,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PANEL_SIX}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 + ${
      HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN
    }px)`,
    left: `calc((100% - ${PANEL_PADDING}px) / 3 * 2 + ${
      COMPONENT.MARGIN + PANEL_PADDING / 2
    }px)`,
    minLeft:
      PANEL_PADDING / 2 + 2 * COMPONENT.MIN_SIZE.WIDTH + 4 * COMPONENT.MARGIN,
    minTop: HEIGHT_HEADER_WRAPPER + MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const BankContent = ({ sizes, setSizes }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(getBankMaxPeriod({ OrganizationId: basicInfo.organizationId }))
    }
  }, [basicInfo?.organizationId])

  useEffect(() => {
    return () => {
      dispatch(resetStoreFilter())
      dispatch(resetStoreLoan())
      dispatch(resetStoreDeposit())
    }
  }, [])

  return (
    <>
      <Panel
        title="corporate.businessModel.penetrationAnalysis.MARKET_SHARE"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.WRAPPER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter />
      </Panel>

      <PanelOne
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PANEL_ONE}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.PANEL_ONE].width - PANEL_PADDING}
        height={sizes[MAP_KEY.PANEL_ONE].height - HEADER_PANEL_HEIGHT}
      />
      <PanelTwo
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PANEL_TWO}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.PANEL_TWO].width - PANEL_PADDING}
        height={sizes[MAP_KEY.PANEL_TWO].height - HEADER_PANEL_HEIGHT}
      />
      <PanelThree
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PANEL_THREE}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.PANEL_THREE].width - PANEL_PADDING}
        height={sizes[MAP_KEY.PANEL_THREE].height - HEADER_PANEL_HEIGHT}
      />
      <PanelFour
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PANEL_FOUR}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.PANEL_FOUR].width - PANEL_PADDING}
        height={sizes[MAP_KEY.PANEL_FOUR].height - HEADER_PANEL_HEIGHT}
      />
      <PanelFive
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PANEL_FIVE}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.PANEL_FIVE].width - PANEL_PADDING}
        height={sizes[MAP_KEY.PANEL_FIVE].height - HEADER_PANEL_HEIGHT}
      />
      <PanelSix
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PANEL_SIX}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(BankContent)
