import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import Unit from '../../common/Unit'
import { INDIVIDUAL_OPTIONS } from '../../constants'
import style from './index.module.css'
import SearchBank from './SearchBank'
import SelectDate from './SelectDate'
import { getTab, handleTab } from './store/slice'

const Header = () => {
  const dispatch = useDispatch()
  const tab = useSelector(getTab)

  const onChangeTab = (tab) => {
    dispatch(handleTab(tab.value))
  }

  return (
    <div>
      <div className="d-flex align-center j-b">
        <div className="d-flex align-center">
          <DispatchActionTab
            data={INDIVIDUAL_OPTIONS}
            activeTab={tab}
            onChangeTab={onChangeTab}
            containerStyle={{ marginRight: 8 }}
          />
          <SearchBank />
        </div>
        <SelectDate />
      </div>
      <div className={style.wapUnitHeader}>
        <Unit title={'economy.monetary.monetary.UNIT_PERCENT'} />
        <Unit title={'economy.monetary.monetary.HISTORICAL_DATA_INFO'} />
      </div>
    </div>
  )
}

export default Header
