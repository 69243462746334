import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { COMPOSITION_SECTOR_VALUE, HEADER_VALUE } from '../../../constants'
import { getNominalRealValue } from '../../store/slice'
import {
  getDataTypeNameLoading,
  getLimitTimeLoading,
  getPieChartLoading,
  getRadioValue,
  getStackedBarChartData,
  getStackedBarChartLoading,
  getTimeRangeAccValue,
  getTimeRangeValue,
  getTop5Indicator,
  getYear,
} from '../store/slice'
import { changesInGDPCompositionData } from '../store/thunk'
import ChartComponent from './ChartComponent'
import { GDP_COMPOSITION_RIGHT_CHILD } from './PanelGDPCompositionRightChild'

const ANNUAL = '5'

const GDPCompositionRightChild = ({ width, height }) => {
  const dispatch = useDispatch()
  const { NOMINAL } = HEADER_VALUE
  const { VALUE } = COMPOSITION_SECTOR_VALUE

  const stackedBarChartLoading = useSelector(getStackedBarChartLoading)
  const pieChartLoading = useSelector(getPieChartLoading)
  const stackedBarChartData = useSelector(getStackedBarChartData)
  const timeRangeValue = useSelector(getTimeRangeValue)
  const timeRangeAccValue = useSelector(getTimeRangeAccValue)
  const year = useSelector(getYear)
  const top5Indicator = useSelector(getTop5Indicator)
  const nominalRealValue = useSelector(getNominalRealValue)
  const radioValue = useSelector(getRadioValue)
  const GDPTypeNameLoading = useSelector(getDataTypeNameLoading)
  const limitTimeLoading = useSelector(getLimitTimeLoading)

  const timeRange = radioValue === VALUE ? timeRangeValue : timeRangeAccValue

  useEffect(() => {
    if (top5Indicator.length && !limitTimeLoading && !pieChartLoading) {
      const ids = top5Indicator.map((item) => item.nationalAccountVNTypeId)
      dispatch(
        changesInGDPCompositionData({
          nationalAccountVNTypeIds: ids,
          quarter: timeRange,
          year,
          isNominal: nominalRealValue === NOMINAL,
          isValue: radioValue === VALUE,
        }),
      )
    }
  }, [
    timeRangeValue,
    timeRangeAccValue,
    top5Indicator,
    nominalRealValue,
    radioValue,
    limitTimeLoading,
  ])

  if (pieChartLoading || GDPTypeNameLoading || limitTimeLoading) {
    return <Loading />
  }

  if (!top5Indicator.length) {
    return <NoData />
  }

  return (
    <div className="h-100">
      {stackedBarChartLoading && <Loading />}
      {!stackedBarChartLoading && (
        <>
          {stackedBarChartData.length === 0 && <NoData />}
          {stackedBarChartData.length > 0 && (
            <SizeTracker>
              {(size) => {
                return (
                  <div id={GDP_COMPOSITION_RIGHT_CHILD}>
                    {(size.height || size.height === 0) && (
                      <ChartComponent
                        data={stackedBarChartData}
                        width={width}
                        height={height - size.height}
                        keyXAxis={
                          radioValue === VALUE && timeRange === ANNUAL
                            ? 'year'
                            : 'quarterYear'
                        }
                        top5Indicator={top5Indicator}
                      />
                    )}
                  </div>
                )
              }}
            </SizeTracker>
          )}
        </>
      )}
    </div>
  )
}

export default GDPCompositionRightChild
