import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { LayoutWithMenu } from '../common/layout/LayoutWithMenu'
import { resetSecurities } from '../popup/common/store/slice'
import { resetIndicators } from '../popup/popupAddIndicator/store/slice'
import { PreviewChart } from './PreviewChart'
import { Switch } from './Switch'

export const Dashboard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetSecurities())
    dispatch(resetIndicators())
  }, [])

  return (
    <PreviewChart>
      {(handlePreviewChart, isHaveDataChartPreview) => {
        return (
          <LayoutWithMenu>
            <Switch
              handlePreviewChart={handlePreviewChart}
              isHaveDataChartPreview={isHaveDataChartPreview}
            />
          </LayoutWithMenu>
        )
      }}
    </PreviewChart>
  )
}
