import { valDivThousand } from '../../../../../utils/Value'
import { SEARCH_INDICATOR } from '../../constants'

export const NUM_ITEM_MAX_IN_COL = 8

export const formatValuationValue = (data, valuationValue) => {
  return data.map((item) => ({
    ...item,
    [valuationValue]: valDivThousand(item[valuationValue]),
  }))
}

export const formatDataChart = (
  data,
  typeCurrentInfo,
  valuationValue,
  rightLineKeys,
  stateIndicator,
) => {
  let newData = typeCurrentInfo
    ? formatValuationValue(data, valuationValue)
    : data

  const indexItemHaveData = data.findIndex(
    (item) =>
      (stateIndicator === SEARCH_INDICATOR.priceToEarnings
        ? item['pe']
        : item['pb']) && item[valuationValue],
  )

  if (rightLineKeys.length && indexItemHaveData !== -1) {
    return newData.slice(indexItemHaveData)
  } else {
    return newData
  }
}

export const chunkArray = (myArray, chunk_size) => {
  const results = []
  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size))
  }
  return results
}

export const convertDataHoverTooltip = (arrayData) => {
  const result = []
  if (arrayData.length < NUM_ITEM_MAX_IN_COL) {
    return arrayData.map((item) => {
      return {
        idFirst: item.id,
        nameCompareFirst: item.nameCompare,
      }
    })
  } else {
    const newArray = chunkArray(arrayData, Math.ceil(arrayData.length / 2))
    let i = 0
    while (i < newArray[0].length) {
      result.push({
        idFirst: newArray[0][i].id,
        nameCompareFirst: newArray[0][i].nameCompare,
        idSecond: newArray[1][i]?.id,
        nameCompareSecond: newArray[1][i]?.nameCompare,
      })
      i++
    }
    return result
  }
}
