import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../common/loading'
import { selectLoading } from '../slice'
import { getProfile } from '../thunk'
import Profile from './Profile'
import Subscription from './Subscription'

const SettingProfile = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading)

  const [widthInput, setWidthInput] = useState(0)

  useEffect(() => {
    dispatch(getProfile())
  }, [])

  return (
    <div id="profile" className="tabcontent">
      {!isLoading && (
        <>
          <Profile setWidthInput={setWidthInput} />
          <Subscription widthInput={widthInput} />
        </>
      )}

      {isLoading && <Loading />}
    </div>
  )
}

export default SettingProfile
