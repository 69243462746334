import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { SizeTrackerContainer } from '../../../../common/sizeTracker/SizeTrackerContainer'
import TextEllipsis from '../../../../common/textEllipsis'
import { MAX_TICKER, VIEW_BY } from '../constant'
import {
  changeCheckAllTicker,
  changeCheckTickerItem,
  removeTicker,
  selectListTickersById,
  selectLoading,
  selectTickersById,
  selectTickersId,
  selectViewBy,
} from '../store/slice'
import { CheckboxAll } from './CheckboxAll'
import { SelectedListItem } from './SelectedListItem'
import { COLUMN_KEYS } from './constants'
import style from './style.module.css'

export const SelectedOther = () => {
  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const viewBy = useSelector(selectViewBy)
  const tickerById = useSelector(selectListTickersById(viewBy))
  const tickersId = useSelector(selectTickersId(viewBy))
  const addTickerLoading = useSelector(selectLoading('addTicker'))

  // Get data
  const numberCheckedTicker = useMemo(() => {
    return Object.values(tickerById).filter((item) => item.isCheck).length
  }, [tickerById])

  const getWarningText = useMemo(() => {
    const type = I18n.t(
      `tool.dataExplorer.tradingData.${
        viewBy === VIEW_BY.CORPORATE
          ? 'VIEW_TYPE_COMPANIES'
          : 'VIEW_TYPE_SECTORS'
      }`,
    )
    return I18n.t('tool.dataExplorer.tradingData.MAX_TICKER_WARNING').replace(
      '[viewType]',
      type,
    )
  }, [locale, viewBy])

  return (
    <SizeTrackerContainer>
      <SizeTracker>
        {(size) => (
          <>
            <div className="d-flex j-b">
              <Span style={{ fontSize: 12 }} className={style.title}>
                {viewBy === VIEW_BY.CORPORATE ? (
                  <Translate value="tool.dataExplorer.tradingData.LB_COMPANIES" />
                ) : (
                  <Translate value="tool.dataExplorer.tradingData.LB_INDICES_AND_SECTORS" />
                )}
              </Span>
              <CheckboxAll
                dataId={tickersId}
                dataById={tickerById}
                onRemoveItem={removeTicker}
                onCheckAllItem={changeCheckAllTicker}
                isMaxCheck={numberCheckedTicker === MAX_TICKER}
              />
            </div>
            <div className="d-flex j-b mb-20">
              <Span
                style={{
                  marginRight: 8,
                  whiteSpace: 'nowrap',
                }}
              >
                <Translate value="tool.dataExplorer.tradingData.SELECTED" />
                {`${numberCheckedTicker}/${MAX_TICKER}`}
              </Span>
              <TextEllipsis
                text={getWarningText}
                isI18n={false}
                appendStyle={{
                  opacity: 0.4,
                  fontStyle: 'italic',
                }}
              />
            </div>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <SelectedListItem
                  ids={tickersId}
                  columnKeys={COLUMN_KEYS[viewBy]}
                  selectDataById={selectTickersById}
                  onChangeCheck={changeCheckTickerItem}
                  checkboxKey="isCheck"
                  loading={addTickerLoading}
                  isMaxCheck={numberCheckedTicker === MAX_TICKER}
                />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </SizeTrackerContainer>
  )
}
