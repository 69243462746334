import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { TimeSale } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { PreOpenMarket } from '../../../../common/preOpenMarket/PreOpenMarket'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import {
  timeSaleChartType,
  timeSaleChartTypeName,
  timeSaleDateFilter,
} from './constants'

export const PanelTimeSale = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)

  const [chartType, setChartType] = useState(timeSaleChartType.AREA_LINE)
  const [dateType, setDateType] = useState(timeSaleDateFilter.ONE_DAY)

  return (
    <Panel
      title="market.equityTrading.deepTransaction.TIME_SALE"
      panelRefs={panelRefs}
      panelKey={mapKey.TIME_SALE}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={UseI18n('market.equityTrading.deepTransaction.TIME_SALE')}
      downloadJpgParams={{
        domId: 'timeSale',
        nameScreen: UseI18n(
          'market.equityTrading.deepTransaction.DEEP_TRANSACTION',
        ),
        chartName: UseI18n('market.equityTrading.deepTransaction.TIME_SALE'),
        tabName: timeSaleChartTypeName[chartType] + '_' + dateType,
        tickerName: basicInfo.ticker,
      }}
    >
      <PreOpenMarket>
        <TimeSale
          width={formatNumber(sizes[mapKey.TIME_SALE].width - PANEL_PADDING)}
          height={formatNumber(
            sizes[mapKey.TIME_SALE].height - HEADER_PANEL_HEIGHT,
          )}
          onChangeChart={setChartType}
          onChangeDate={setDateType}
        />
      </PreOpenMarket>
    </Panel>
  )
}

PanelTimeSale.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
