import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Table } from '../../../../common/table'
import {
  selectDataFDF,
  selectFinancialRatiosValue,
} from '../../filter/store/slice'
import style from '../../style.module.css'

const FinancialRatiosTable = () => {
  const data = useSelector(selectDataFDF)

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  return (
    <div
      className={style.financialRatiosTable}
      style={{ height: dimensions.height, paddingBottom: 24 }}
    >
      {data.financialRatios.ids.length ? (
        <Table
          ids={data.financialRatios.ids}
          columnDraggable={false}
          getDataFromRedux={selectFinancialRatiosValue}
          isLoading={false}
          rowDraggable={false}
          schema={data.financialRatios.groupColumns.map((item, index) => {
            const title = item.title.toString()
            const colId = item.key
            const result = {
              colId,
              title,
              tdStyle: {
                textAlign: 'right',
                color: '#000000',
                opacity: 0.7,
              },
              thStyle: {
                textAlign: 'right',
                color: '#000000',
              },
            }
            if (index === 0) {
              return {
                ...result,
                tdStyle: {
                  textAlign: 'left',
                },
                thStyle: {
                  textAlign: 'left',
                  fontWeight: 600,
                  fontSize: 12,
                },
                canCustomTd: true,
                render: (val) => (
                  <td>
                    <Translate value={val} dangerousHTML />
                  </td>
                ),
              }
            }
            return result
          })}
          isPagination={false}
          hasFooter={false}
          resizable={false}
          dimensionsTable={setDimensions}
          hasTooltip={false}
        />
      ) : null}
    </div>
  )
}

export default FinancialRatiosTable
