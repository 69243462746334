export const chartFillColor = {
  ml: '#45b29d',
  fvtplyoy: '#facc5c',
}

export const chartBarKeys = ['ml']

export const chartLineKeys = ['fvtplyoy']

export const chartMappingDisplayName = {
  ml: 'sector.financialAnalysis.security.assetStructure.MARGIN_LOAN',
  fvtplyoy:
    'sector.financialAnalysis.security.assetStructure.MARGIN_AND_OWNER_PERCENT',
}

export const chartFormat = {
  ml: 10 ** 9,
  fvtplyoy: 0.01,
}
