import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class StatisticProxy extends ProxyBase {
  getTradingStatistics(params) {
    return this.post('TradingStatistic', params)
  }
  getLiquidityData(params) {
    return this.post('LiquidityChart', params)
  }
  getValueChartData(params) {
    return this.post('ValueChart', params)
  }
  downloadStatisticInformation(params) {
    return this.post('TradingStatisticExport', params, {}, 'download')
  }
}

export default new StatisticProxy(ServiceProxyConfig.Bond.Statistic.ServiceUrl)
