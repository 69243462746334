import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import Header from './Header'
import { getDateRange, getDefaultRangeYear, handlePages } from './helper'
import {
  getCurrentPage,
  getFromDate,
  getGroupColumns,
  getIsScrollRight,
  getPreviousColumns,
  getTableData,
  getTableDataLoading,
  getTableMapping,
  getTableMappingLoading,
  getTimeRange,
  getToDate,
} from './store/slice'
import {
  getOpenMarketOperations,
  getOpenMarketOperationsTitle,
} from './store/thunk'
import TableComponent from './TableComponent'

const TableOpenMarket = ({ height }) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()
  const previousYear = new Date()

  const locale = useSelector((state) => state.i18n.locale)
  const timeRange = useSelector(getTimeRange)
  const fromDate = useSelector(getFromDate)
  const toDate = useSelector(getToDate)
  const tableDataLoading = useSelector(getTableDataLoading)
  const tableData = useSelector(getTableData)
  const tableMapping = useSelector(getTableMapping)
  const tableMappingLoading = useSelector(getTableMappingLoading)
  const groupColumns = useSelector(getGroupColumns)
  const currentPage = useSelector(getCurrentPage)
  const isScrollRight = useSelector(getIsScrollRight)
  const previousColumns = useSelector(getPreviousColumns)
  const pages = handlePages(groupColumns)

  previousYear.setFullYear(
    previousYear.getFullYear() - getDefaultRangeYear(timeRange),
  )

  useEffect(() => {
    if (timeRange === 'Daily') {
      dispatch(
        getOpenMarketOperations({
          TimeFrequency: timeRange,
          ...getDateRange(fromDate, toDate, timeZone, previousYear),
        }),
      )
      return
    }
    dispatch(
      getOpenMarketOperations({
        TimeFrequency: timeRange,
        DateFrom: fromDate
          ? getISOStartOrEndOfDay(fromDate, timeZone, true)
          : previousYear,
        DateTo: toDate
          ? getISOStartOrEndOfDay(toDate, timeZone, true)
          : getISOStartOrEndOfDay(new Date(), timeZone, true),
      }),
    )
  }, [locale, timeRange, fromDate, toDate])

  useEffect(() => {
    dispatch(getOpenMarketOperationsTitle())
  }, [locale])

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <Header />
            {(tableDataLoading || tableMappingLoading) && <Loading />}
            {!tableDataLoading && !tableMappingLoading && (
              <>
                {(Object.keys(tableData).length === 0 ||
                  tableMapping.length === 0) && <NoData />}
                {Object.keys(tableData).length !== 0 &&
                  tableMapping.length !== 0 && (
                    <>
                      {typeof sizes.height === 'number' && (
                        <TableComponent
                          height={height - sizes.height}
                          groupColumns={groupColumns}
                          tableMapping={tableMapping}
                          currentPage={currentPage}
                          pages={pages}
                          isScrollRight={isScrollRight}
                          previousColumns={previousColumns}
                          timeRange={timeRange}
                        />
                      )}
                    </>
                  )}
              </>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TableOpenMarket
