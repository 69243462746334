import { useSelector } from 'react-redux'
import LinkTicker from '../../../../common/dropdownLink/LinkTicker'
import { selectValueById } from './store/slice'

export const TOP_NET_VALUE_TABLE_ID = 'topNetValueTableId'

const TickerCell = ({ val, rowId }) => {
  const organizationId = useSelector(selectValueById(rowId, 'organizationId'))

  return <LinkTicker organizationId={organizationId}>{val}</LinkTicker>
}

export default TickerCell
