import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { EMPTY_VALUE } from '../../constants/Common'
import { getUnique } from '../../utils/Common'
import { Row } from './Row'

export const TableBody = ({
  ids,
  idsDisplay,
  getDataFromRedux,
  schema,
  rowDraggable,
  reorder,
  changeActiveRow,
  defaultActiveRowId,
  disableClickGroups,
  isCollapse,
  rowsCollapse,
  levelCollapse,
  onRowCollapse,
  onRowDoubleClick,
  onRowClick,
  widths,
  stickies,
  stickyFirstColumn,
  rowHighlightLines,
  stickyBottomRowCount,
  disableRowHovered,
  setVerticalTrackBottom,
  idRowCustomStyle,
  rowCustomStyle,
  stickyBgColor,
  multipleActiveRowsIds,
  stickyBottomRLeft,
}) => {
  const [rowRefs, setRowRefs] = useState({})

  useEffect(() => {
    if (stickyBottomRowCount) {
      setVerticalTrackBottom(
        ids
          .slice(-stickyBottomRowCount)
          .reduce((prev, cur) => prev + (rowRefs[cur]?.height || 0), 0),
      )
    }
  }, [ids, rowRefs, setVerticalTrackBottom])

  const expandAll = () => {
    ids.forEach((id) => {
      const el = document.getElementById(id)
      if (!el) {
        return
      } else {
        el.style.display = ''
      }
    })
  }

  useEffect(() => {
    if (isCollapse) {
      expandAll()
      rowsCollapse.forEach((id) => {
        const el = document.getElementById(id)
        const level = levelCollapse[id] ? levelCollapse[id].level : EMPTY_VALUE
        let nextRow = el?.nextElementSibling
        while (nextRow && level && levelCollapse[nextRow.id]?.level > level) {
          nextRow.style.display = 'none'
          nextRow = nextRow.nextElementSibling
        }
      })
    }
  }, [rowsCollapse])

  return (
    <tbody>
      {ids.map((id, rowIndex) => {
        if (idsDisplay && idsDisplay.length > 0 && !idsDisplay.includes(id)) {
          return <tr id={id}></tr>
        }

        const isSticky =
          stickyBottomRowCount && rowIndex >= ids.length - stickyBottomRowCount
        const stickyBottom = isSticky
          ? ids
              .slice(rowIndex + 1)
              .reduce((prev, cur) => prev + (rowRefs[cur]?.height || 0), 0)
          : undefined

        return (
          <Row
            key={id}
            schema={schema}
            rowId={id}
            setRowRefs={setRowRefs}
            getDataFromRedux={getDataFromRedux}
            rowIndex={rowIndex}
            rowDraggable={rowDraggable}
            reorder={reorder}
            changeActiveRow={changeActiveRow}
            defaultActiveRowId={defaultActiveRowId}
            disableClickGroups={disableClickGroups}
            isCollapse={isCollapse}
            rowLevelCollapse={levelCollapse[id]}
            onRowCollapse={onRowCollapse}
            onRowDoubleClick={onRowDoubleClick}
            onRowClick={onRowClick}
            widths={widths}
            stickies={stickies}
            stickyFirstColumn={stickyFirstColumn}
            isHighlightLine={
              rowHighlightLines && rowHighlightLines.includes(rowIndex)
            }
            stickyBottom={stickyBottom}
            disableRowHovered={disableRowHovered}
            rowCustomStyle={idRowCustomStyle.includes(id) ? rowCustomStyle : {}}
            stickyBgColor={stickyBgColor}
            tableId={getUnique()}
            multipleActiveRowsIds={multipleActiveRowsIds}
            stickyBottomRLeft={stickyBottomRLeft}
          />
        )
      })}
    </tbody>
  )
}

TableBody.propTypes = {
  schema: PropTypes.array.isRequired,
  ids: PropTypes.array.isRequired,
  idsDisplay: PropTypes.array,
  getDataFromRedux: PropTypes.func.isRequired,
  rowDraggable: PropTypes.bool.isRequired,
  reorder: PropTypes.func.isRequired,
  changeActiveRow: PropTypes.func,
  defaultActiveRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableClickGroups: PropTypes.array.isRequired,
  isCollapse: PropTypes.bool,
  rowsCollapse: PropTypes.array,
  levelCollapse: PropTypes.object,
  onRowCollapse: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowClick: PropTypes.func,
  rowHighlightLines: PropTypes.array,
  stickyBottomRowCount: PropTypes.number,
  idRowCustomStyle: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  rowCustomStyle: PropTypes.object,
  stickyBgColor: PropTypes.string,
}
