export const getYearInDataRange = (data) => {
  const allYears = data?.map((item) => item.year)
  const uniqueYears = [...new Set(allYears)]

  return uniqueYears.map((item) => ({ year: item }))
}

export const getQuarterInDataRange = (data) => {
  const allMonths = data?.map((item) => item.quarter)
  const uniqueMonths = [...new Set(allMonths)]

  return uniqueMonths.map((item) => ({ quarter: item }))
}

export const getQuarterAndYearInDataRange = (dataRange) => {
  const allMonthsAndYears = dataRange?.map((item) => ({
    quarter: item.quarter,
    year: item.year,
  }))

  const uniqueMonthsAndYears = Object.values(
    allMonthsAndYears.reduce((acc, curr) => {
      acc[curr.quarter + '|' + curr.year] = curr
      return acc
    }, {}),
  )

  return uniqueMonthsAndYears
}

export const getDataByYear = (data, year) => {
  return data
    ?.filter((item) => item.year === year)
    ?.map((item) => ({
      value: item.value ?? 0,
      year: item.year ?? 0,
      id: item.id ?? 0,
    }))
}

export const getDataByQuarterAndYear = (data, quarter, year) => {
  return data
    ?.filter((item) => item.year === year && item.quarter === quarter)
    ?.map((item) => ({
      value: item.value ?? 0,
      year: item.year ?? 0,
      quarter: item.quarter ?? 0,
      id: item.id ?? 0,
    }))
}

export const getYearKey = (year) => {
  return `${year}`
}

export const getQuarterAndYearKey = (quarter, year) => {
  return `Q${quarter}-${year}`
}
