import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import { Input } from '../../common/html/Input'
import { Span } from '../../common/html/Span'
import Popup from '../../common/popup'
import { lengthChartInLayout } from '../chart/common/useGetLayoutDashboard'
import { postCharts } from '../chart/store/thunk'
import { dashboardTypes, screenTypes } from '../constants'
import { changeDashboard } from '../store/slice'
import { getCharts, postDashboard } from '../store/thunk'
import { getPopupSaveDashboardSuccess } from './helper'
import style from './style.module.css'
import styles from './styleTheme.module.css'

const PopupDuplicateDashboard = ({
  isShow,
  name,
  charts,
  dashboard,
  handleShow,
}) => {
  const dispatch = useDispatch()

  const [dashboardName, setDashboardName] = useState('')

  const onClose = () => {
    handleShow(false)
  }

  const handleCreateDashboardNew = async () => {
    if (!dashboardName.trim()) return

    const dashboardParams = {
      name: dashboardName,
      isGroup: false,
      layout: 'layout_1',
      maxChartCount: lengthChartInLayout.layout_1,
      cancelBackToDB: true,
    }

    if (dashboard.parentId && !dashboard.isTemplate) {
      dashboardParams.parentId = dashboard.parentId
    }

    await dispatch(postDashboard(dashboardParams))
      .unwrap()
      .then(async (data) => {
        let listChart = []
        if (charts && charts.length) {
          listChart = [...charts]
        } else {
          await dispatch(
            getCharts({
              dashboardId: dashboard.id,
              isTemplate: false,
            }),
          )
            .unwrap()
            .then((dashboardCharts) => {
              if (dashboardCharts && dashboardCharts.length) {
                listChart = dashboardCharts
              }
            })
        }
        const listParams = listChart.map((chart) => ({
          dashboardId: data.id,
          name: chart.name,
          order: chart.order,
          linked: false,
          detail: chart.detail,
        }))
        await dispatch(postCharts(listParams))
          .unwrap()
          .then(() => {
            dispatch(
              changeDashboard({
                screenType: screenTypes.DASHBOARD,
                dashboardType: dashboardTypes.MY_DASHBOARD,
                data: data,
              }),
            )
            getPopupSaveDashboardSuccess(dashboardName)
          })
      })
  }

  const onApply = async () => {
    await handleCreateDashboardNew()
    handleShow(false)
  }

  useEffect(() => {
    setDashboardName(name)
  }, [name])

  if (isShow) {
    return (
      <Popup overlayCustom={styles.overlayCustom}>
        <div className={`modal modal-small ${styles.modal}`}>
          <div className={`modal-title ${styles.titleStyle}`}>
            <h3>
              <Span>
                <Translate value="financialChart.DUPLICATE_DASHBOARD" />
              </Span>
            </h3>
            <a onClick={onClose} className="close-modal">
              <i className="icon-delete-stroke" style={{ color: '#646464' }} />
            </a>
          </div>
          <div className={`modal-content ${styles.modalContentStyle}`}>
            <div className={style.contentDuplicateDashboard}>
              <Span className={styles.colorText}>
                <Translate value="financialChart.NEW_DASHBOARD_1" />
              </Span>
              <Input
                className={styles.inputStyle}
                value={dashboardName}
                onChange={(e) => setDashboardName(e.target.value)}
              />
            </div>
            <div
              className="group-btn justify-content-end"
              style={{ marginTop: 69 }}
            >
              <Button
                className={`btn w-80 h-20 ${styles.buttonGrayStyle}`}
                style={{ fontSize: 11 }}
                onClick={onClose}
              >
                <Translate value="common.button.BUTTON_CANCEL" />
              </Button>
              <Button
                className={`btn w-80 h-20 ${styles.buttonBlueStyle}`}
                style={{ fontSize: 11 }}
                onClick={onApply}
              >
                <Translate value="common.button.BUTTON_OK" />
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    )
  }

  return null
}

export default PopupDuplicateDashboard
