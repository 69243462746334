import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { BANK_TAB } from './constant'
import DepositMarketShare from './deposit/marketShare'
import style from './index.module.css'
import LoanMarketShare from './loan/marketShare'
import { selectBankTab } from './store/slice'

const CHART_DOM_ID = 'BankPanelTwo'

const PaneTwo = ({ panelRefs, panelKey, sizes, setSizes, width, height }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const bankTab = useSelector(selectBankTab)

  const nameScreen = UseI18n(
    'constants.route.corporate.businessModel.penetrationAnalysis.LABEL',
  )
  const chartName = UseI18n(
    `corporate.businessModel.penetrationAnalysis.${
      bankTab === BANK_TAB.LOAN ? 'LOAN_MARKET_SHARE' : 'DEPOSIT_MARKET_SHARE'
    }`,
  )
  const tickerName = basicInfo.ticker

  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowClass={style.windowClass}
      headerClass={style.panelHeader}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: CHART_DOM_ID,
        nameScreen,
        chartName,
        tickerName,
      }}
    >
      <div className="h-100" id={CHART_DOM_ID}>
        {bankTab === BANK_TAB.LOAN ? (
          <LoanMarketShare width={width} height={height} />
        ) : (
          <DepositMarketShare width={width} height={height} />
        )}
      </div>
    </Panel>
  )
}

export default PaneTwo
