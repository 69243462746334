export const FILTER_LIST = [
  {
    label: 'sector.sectorSpecific.fishery.overview.FILTER_RADIO_NEWS',
    value: true,
  },
  {
    label: 'sector.sectorSpecific.fishery.overview.FILTER_RADIO_REPORTS',
    value: false,
  },
]
