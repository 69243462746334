import moment from 'moment'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import FooterWithScroll from '../../../../common/chart/footer/footerWithScroll'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { FIXED_ROW_ID } from '../panelDepositRateCommercial/config'
import {
  getCheckboxChecked,
  getIdWithTitles,
  getLineChartColor,
  handleCheckboxChecked,
  handleLineChartColor,
} from '../panelDepositRateCommercial/store/slice'
import Tooltip from './Tooltip'
import { MONOTONE_COLORS } from './config'
import {
  handleLineChartHasAuthor,
  handleTooltipTwoColumn,
  removeColor,
} from './helper'
import style from './index.module.css'
import { getActiveLine, handleActiveLine } from './store/slice'

const YAXIS_LEFT = 'left'

const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 1.5,
  strokeActiveWidth: 2.5,
  normalOrHover: 1,
  blur: 0.1,
}

const LineChart = ({
  data,
  width,
  height,
  keyXAxis,
  lineKey,
  monotoneKey,
  isFullScreen,
}) => {
  const dispatch = useDispatch()
  const legendPanel = useRef()
  const timeZone = UseTimeZone()
  const [heightListIndicator, setHeightListIndicator] = useState(0)

  const activeLine = useSelector(getActiveLine)
  const lineChartColor = useSelector(getLineChartColor)
  const checkboxChecked = useSelector(getCheckboxChecked)
  const idsWithInfo = useSelector(getIdWithTitles)
  const locale = useSelector((state) => state.i18n.locale)

  // Get data
  const lineChartHasAuthor = handleLineChartHasAuthor(
    lineChartColor,
    idsWithInfo,
  )
  const monotoneHasAuthor = monotoneKey.map((item) => ({
    color: MONOTONE_COLORS[item],
    author: item,
    indicatorName: idsWithInfo[item],
  }))

  const dataFormat = data
    .map((item) => ({
      ...item,
      day: formatDateTime(item.day, FORMAT.DATE, locale, timeZone),
    }))
    .sort((a, b) =>
      moment(a.day, FORMAT.DATE[locale]).diff(
        moment(b.day, FORMAT.DATE[locale]),
      ),
    )

  const { leftColumn, rightColumn } = handleTooltipTwoColumn([
    ...lineChartHasAuthor,
    ...monotoneHasAuthor,
  ])

  const keyYAxis = [
    {
      id: YAXIS_LEFT,
      keys: [...lineKey, ...monotoneKey],
      orientation: YAXIS_LEFT,
      isLineChart: true,
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: SETTINGS.yTickNum,
      unitYAxis: '%',
    },
  ]

  // Action
  const handleDelete = (item) => {
    const newCheckboxChecked = checkboxChecked.filter(
      (val) => val !== item.author,
    )
    dispatch(handleCheckboxChecked(newCheckboxChecked))
    if (!FIXED_ROW_ID.includes(item.author)) {
      dispatch(handleLineChartColor(removeColor(lineChartColor, item.author)))
    }
    dispatch(handleActiveLine(undefined))
  }

  const handleHover = (item) => {
    dispatch(handleActiveLine(item.author))
  }

  const handleUnHover = () => {
    dispatch(handleActiveLine(undefined))
  }

  // Render
  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        <div className="d-flex">
          <div className={style.tooltipMaxWidth}>
            {leftColumn.length > 0 &&
              leftColumn.map((item, index) => {
                return <Tooltip key={index} item={item} payload={payload} />
              })}
          </div>
          <div className={`${style.tooltipMaxWidth} ml-8`}>
            {rightColumn.length > 0 &&
              rightColumn.map((item, index) => {
                return <Tooltip key={index} item={item} payload={payload} />
              })}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <ChartContainer
        data={dataFormat}
        height={height - heightListIndicator}
        width={width}
        keyXAxis={keyXAxis}
        yAxis={keyYAxis}
        renderCustomTooltip={renderTooltip}
        margin={{ ...MARGIN_RECHARTS, left: 12, right: 46 }}
        timeFrame={TIME_RANGES.CUSTOM}
        isNotFormatXAxis
      >
        {lineChartHasAuthor.map((item) => {
          return (
            <Line
              key={item.author}
              isAnimationActive={false}
              yAxisId={YAXIS_LEFT}
              dataKey={item.author}
              stroke={item.color}
              strokeWidth={
                activeLine === item.author && activeLine
                  ? SETTINGS.strokeActiveWidth
                  : SETTINGS.strokeWidth
              }
              dot={false}
              strokeOpacity={
                activeLine !== item.author && activeLine
                  ? SETTINGS.blur
                  : SETTINGS.normalOrHover
              }
              activeDot={false}
              connectNulls={true}
            />
          )
        })}
        {monotoneHasAuthor.map((item) => {
          return (
            <Line
              key={item.author}
              isAnimationActive={false}
              yAxisId={YAXIS_LEFT}
              dataKey={item.author}
              stroke={item.color}
              strokeWidth={
                activeLine === item.author && activeLine
                  ? SETTINGS.strokeActiveWidth
                  : SETTINGS.strokeWidth
              }
              dot={false}
              strokeOpacity={
                activeLine !== item.author && activeLine
                  ? SETTINGS.blur
                  : SETTINGS.normalOrHover
              }
              activeDot={false}
              strokeDasharray="5 5"
              connectNulls={true}
            />
          )
        })}
      </ChartContainer>
      <FooterWithScroll
        width={width}
        data={[...lineChartHasAuthor, ...monotoneHasAuthor]}
        idKey="author"
        nameKey="indicatorName"
        color={(item) => item.color}
        legendPanel={legendPanel}
        isDashed={(item) => monotoneKey.includes(item.author)}
        isFullScreen={isFullScreen}
        setHeightFooter={setHeightListIndicator}
        onDelete={handleDelete}
        onHover={handleHover}
        onUnHover={handleUnHover}
      />
    </>
  )
}

LineChart.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  keyXAxis: PropTypes.string.isRequired,
  lineKey: PropTypes.array,
  monotoneKey: PropTypes.array,
}

export default LineChart
