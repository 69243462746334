import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import AverageYtm from './averageYtm/AverageYtm'
import MarketLiquidity from './marketLiquidity'
import TopAskInterest from './topAskInterest/TopAskInterest'
import TopBidInterest from './topBidInterest/TopBidInterest'
import TopLiquidity from './topLiquidity/TopLiquidity'

const MAP_KEY = {
  TOP_LIQUIDITY: 'TOP_LIQUIDITY',
  AVERAGE_YTM: 'AVERAGE_YTM',
  TOP_BID_INTEREST: 'TOP_BID_INTEREST',
  TOP_ASK_INTEREST: 'TOP_ASK_INTEREST',
  MARKET_LIQUIDITY: 'MARKET_LIQUIDITY',
}

const MAP_SIZE = {
  [`${MAP_KEY.TOP_LIQUIDITY}`]: {
    width: `calc(100% / 2 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    disableVerticalResize: false,
    disableHorizontalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.AVERAGE_YTM,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.AVERAGE_YTM,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.TOP_BID_INTEREST,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.TOP_ASK_INTEREST,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.MARKET_LIQUIDITY,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [`${MAP_KEY.AVERAGE_YTM}`]: {
    width: `calc(100% / 2 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 'calc(100% / 2)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TOP_BID_INTEREST}`]: {
    width: `calc(100% / 3 - (${2 * COMPONENT.MARGIN}px))`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: `calc(50%)`,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.TOP_ASK_INTEREST,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [`${MAP_KEY.TOP_ASK_INTEREST}`]: {
    width: `calc(100% / 3 - (${2 * COMPONENT.MARGIN}px))`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: `calc(50%)`,
    left: `calc(100% / 3)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.MARKET_LIQUIDITY,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [`${MAP_KEY.MARKET_LIQUIDITY}`]: {
    width: `calc(100% / 3 - (${2 * COMPONENT.MARGIN}px))`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: `calc(50%)`,
    left: `calc(100% * 2 / 3)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <TopLiquidity
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOP_LIQUIDITY}
        sizes={sizes}
        setSizes={setSizes}
      />
      <AverageYtm
        panelRefs={panelRefs}
        panelKey={MAP_KEY.AVERAGE_YTM}
        sizes={sizes}
        setSizes={setSizes}
      />
      <TopBidInterest
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOP_BID_INTEREST}
        sizes={sizes}
        setSizes={setSizes}
      />
      <TopAskInterest
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOP_ASK_INTEREST}
        sizes={sizes}
        setSizes={setSizes}
      />
      <MarketLiquidity
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MARKET_LIQUIDITY}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
