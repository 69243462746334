import { Overview } from '.'
import { Panel } from '../../../../../common/panel'

export const PanelOverview = ({ panelRefs, sizes, setSizes, panelKey }) => {
  return (
    <Panel
      title="sector.sectorSpecific.overview.OVERVIEW"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
    >
      <Overview />
    </Panel>
  )
}
