import SectorStatisticsOverviewProxy from '../../../proxies/sector/sectorStatistics/OverviewProxy'
import { ServiceBase } from '../../ServiceBase'

export class SectorStatisticsOverviewService extends ServiceBase {
  getSectorsReport(params) {
    return this.getData(() =>
      SectorStatisticsOverviewProxy.getSectorsReport(params),
    )
  }

  getMarketCap(params) {
    return this.getData(() =>
      SectorStatisticsOverviewProxy.getMarketCap(params),
    )
  }

  getValuation(params) {
    return this.getData(() =>
      SectorStatisticsOverviewProxy.getValuation(params),
    )
  }

  pricePerformanceAbsoluteChart(params) {
    return this.getData(() =>
      SectorStatisticsOverviewProxy.pricePerformanceAbsoluteChart(params),
    )
  }

  pricePerformance(params) {
    return this.getData(() =>
      SectorStatisticsOverviewProxy.pricePerformance(params),
    )
  }
}

export default new SectorStatisticsOverviewService()
