import SecondaryMarketStatisticsProxy from '../../../../proxies/bond/corporateBond/secondaryMarket/SecondaryMarketStatisticsProxy'
import { ServiceBase } from '../../../ServiceBase'

export class SecondaryMarketStatisticsService extends ServiceBase {
  getSectorStatistics(params) {
    return this.getData(() =>
      SecondaryMarketStatisticsProxy.getSectorStatistics(params),
    )
  }
  getCorrelationOfMarketBySector(params) {
    return this.getData(() =>
      SecondaryMarketStatisticsProxy.getCorrelationOfMarketBySector(params),
    )
  }
  getCorrelationOfMarketByOrganization(params) {
    return this.getData(() =>
      SecondaryMarketStatisticsProxy.getCorrelationOfMarketByOrganization(
        params,
      ),
    )
  }
  getMarketTradingValueByIssueMethod(params) {
    return this.getData(() =>
      SecondaryMarketStatisticsProxy.getMarketTradingValueByIssueMethod(params),
    )
  }
  getMarketTradingValueByTradingMethod(params) {
    return this.getData(() =>
      SecondaryMarketStatisticsProxy.getMarketTradingValueByTradingMethod(
        params,
      ),
    )
  }
  getMarketTradingValueBySector(params) {
    return this.getData(() =>
      SecondaryMarketStatisticsProxy.getMarketTradingValueBySector(params),
    )
  }

  downloadIssuanceValue(params) {
    return this.getFileDownload(() =>
      SecondaryMarketStatisticsProxy.downloadIssuanceValue(params),
    )
  }
}

export default new SecondaryMarketStatisticsService()
