import Charting from '.'
import { Panel } from '../../../../common/panel'

const PanelCharting = ({ panelRefs, sizes, setSizes, panelKey }) => {
  return (
    <Panel
      title="market.coveredWarrant.CHARTING"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
    >
      <Charting />
    </Panel>
  )
}

export default PanelCharting
