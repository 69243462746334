import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import VerticalLine from '../../../../../common/topInfo/components/VerticalLine'
import style from '../../common/tableStatistics/index.module.css'
import {
  changeTypeDataChart,
  changeTypeStatisticsChart,
  setLoadingChart,
} from '../priceGrowth/store/slice'
import {
  LIST_RADIO,
  NUM_OF_PERIOD_FIRST_FETCH,
  TYPE_STATISTICS_FILTER,
} from './constants'
import {
  changeCurrentMinMonth,
  changeCurrentTypeStatistic,
  changePeriodNum,
  changeTypeData,
  keepCurrentItems,
} from './store/slice'

export const FilterList = ({
  typeData,
  listCheckedItems,
  data,
  currentTypeStatistics,
}) => {
  const dispatch = useDispatch()

  const addedItemsIndexes = listCheckedItems?.map((item) =>
    data?.findIndex((elem) => elem.id === item.id),
  )

  const handleChangeTypeStatistics = (item) => {
    dispatch(changeCurrentTypeStatistic(item.value))
    dispatch(changePeriodNum(NUM_OF_PERIOD_FIRST_FETCH))
    dispatch(changeCurrentMinMonth(null))
    dispatch(keepCurrentItems(addedItemsIndexes))
    dispatch(changeTypeStatisticsChart(item.value))
    dispatch(setLoadingChart(true))
  }

  const handleChangeRadio = (val) => {
    dispatch(changeTypeData(val))
    dispatch(changePeriodNum(NUM_OF_PERIOD_FIRST_FETCH))
    dispatch(keepCurrentItems(addedItemsIndexes))
    dispatch(changeTypeDataChart(val))
    dispatch(setLoadingChart(true))
  }

  return (
    <div className="d-flex">
      <ul className={`list-check d-flex mt-0`}>
        {LIST_RADIO.map((item) => (
          <li key={item.value} className="mb-0">
            <label>
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={item.value === typeData}
                onChange={() => handleChangeRadio(item.value)}
              />
              <Span
                style={{ fontSize: 12 }}
                className={item.value === typeData ? style.active : ''}
              >
                <Translate value={item.label} />
              </Span>
            </label>
          </li>
        ))}
      </ul>
      <VerticalLine mr={12} ml={12} />
      <div style={{ marginTop: '3px' }}>
        <DispatchActionTab
          data={TYPE_STATISTICS_FILTER}
          activeTab={currentTypeStatistics}
          onChangeTab={handleChangeTypeStatistics}
        />
      </div>
    </div>
  )
}
