import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Panel } from '../../../common/panel'
import { SizeTracker } from '../../../common/sizeTracker'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { BOND_TABS, BOND_VALUES } from '../constants'
import {
  keys,
  selectDataBondInformation,
  selectFilterPortfolioIssuer,
  selectLoading,
} from '../store/slice'
import CorporateChart from './CorporateChart'
import Filter from './Filter'
import GovernmentChart from './GovernmentChart'

const PortfolioSizeByIssuer = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.BOND_INFORMATION))
  const { data } = useSelector(selectDataBondInformation)
  const { activeTab } = useSelector(selectFilterPortfolioIssuer)
  const locale = useSelector((state) => state.i18n.locale)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const renderTitle = useMemo(() => {
    let type = BOND_TABS.find((item) => item.value === activeTab)

    return type
      ? `${I18n.t('bond.portfolio.common.PORTFOLIO_SIZE_BY_ISSUER')}-${I18n.t(
          type.title,
        )}`
      : I18n.t('bond.portfolio.common.PORTFOLIO_SIZE_BY_ISSUER')
  }, [locale, activeTab])

  return (
    <Panel
      title="bond.portfolio.common.PORTFOLIO_SIZE_BY_ISSUER"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={renderTitle}
      downloadJpgParams={{
        domId: 'portfolioSizeByIssuerId',
        nameScreen: I18n.t('bond.portfolio.common.TITLE'),
        chartName: I18n.t('bond.portfolio.common.PORTFOLIO_SIZE_BY_ISSUER'),
        tickerName: '',
      }}
    >
      {isLoading ? (
        <Loading />
      ) : !!data.length ? (
        <SizeTracker>
          {(size) => (
            <>
              <Filter />
              <div id="portfolioSizeByIssuerId">
                {size.height && (
                  <div style={{ height: height - size.height }}>
                    {(() => {
                      switch (activeTab) {
                        case BOND_VALUES.GOVERNMENT_BOND:
                          return (
                            <GovernmentChart
                              data={data.filter((item) => item?.id !== 'total')}
                              height={height - size.height}
                              width={width}
                            />
                          )
                        case BOND_VALUES.CORPORATE_BOND:
                          return (
                            <CorporateChart
                              data={data.filter((item) => item?.id !== 'total')}
                              height={height - size.height}
                              width={width}
                            />
                          )

                        default:
                          return <></>
                      }
                    })()}
                  </div>
                )}
              </div>
            </>
          )}
        </SizeTracker>
      ) : (
        <NoData />
      )}
    </Panel>
  )
}

export default PortfolioSizeByIssuer
