import { memo, useEffect, useState } from 'react'
import { Chart } from '../Chart'
import { Footer } from '../Footer'

export const ChartFooterScroll = memo(
  ({
    data,
    width,
    height,
    colors,
    schema,
    chartProps,
    footerProps,
    tooltipProps,
  }) => {
    const [heightSizeTracker, setHeightSizeTracker] = useState()
    const [heightFooter, setHeightFooter] = useState(0)
    const [hoverActiveItem, setHoverActiveItem] = useState(null)

    const handleDelete = (item) => {
      setHoverActiveItem(null)
      footerProps.onDelete && footerProps.onDelete(item)
    }

    const handleHover = (item) => {
      setHoverActiveItem(item.id)
    }

    const handleUnHover = () => {
      setHoverActiveItem(null)
    }

    // Use effect
    useEffect(() => {
      if (heightFooter) setHeightSizeTracker(height - heightFooter)
    }, [height, width, JSON.stringify(schema), heightFooter])

    return (
      <>
        <Chart
          data={data}
          width={width}
          height={heightSizeTracker}
          hoverActiveItem={hoverActiveItem}
          colors={colors}
          schema={schema}
          chartProps={chartProps}
          tooltipProps={tooltipProps}
        />
        <Footer
          width={width}
          colors={colors}
          schema={schema}
          footerProps={footerProps}
          setHeightFooter={setHeightFooter}
          onDelete={handleDelete}
          onHover={handleHover}
          onUnHover={handleUnHover}
        />
      </>
    )
  },
)
