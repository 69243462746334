export const EXCHANGE_CODE = {
  ALL_EXCHANGE: 'All',
  HOSE: 'VNIndex',
  HNX: 'HNXIndex',
  UPCOM: 'UPCOMIndex',
  OTC: 'OTC',
  PRIVATE: 'Private',
}

export const TABLE_TYPE = {
  YEAR: 5,
  Q1: 1,
  Q2: 2,
  Q3: 3,
  Q4: 4,
}

export const DATA_TYPE = {
  STOCK: 'STOCK',
  BANK: 'BANK',
  REAL_ESTATE: 'REAL_ESTATE',
  ENTER_PRISE: 'ENTER_PRISE',
}

export const YEAR_OPTIONS = (function () {
  const MIN_YEAR_OPTION = 2000
  const year = new Date().getFullYear()
  const range = year - MIN_YEAR_OPTION + 1
  return [...Array(range).keys()].map((item) => {
    return {
      name: String(year - item),
      value: String(year - item),
    }
  })
})()

export const COLUMNS_KEYS = {
  delete: 'delete',
  ticker: 'ticker',
  companyName: 'organizationShortName',
  bS64: 'bS64',
  rT0027: 'rT0027',
  rT0037: 'rT0037',
  rT0328: 'rT0328',
  rT0046: 'rT0046',
  rT0372: 'rT0372',
  // rT0378: 'rT0378',
  rT0075: 'rT0075',
  rT0093: 'rT0093',
}
