import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class TopReleaseProxy extends ProxyBase {
  getDataTopRealease(params) {
    return this.get('Overview/TopIssuerRelease', params)
  }
  exportDataTopRealease(params) {
    return this.post('Overview/TopIssuerReleaseExport', params, {}, 'download')
  }
  getDataTopCirculate(params) {
    return this.get('Overview/TopIssuerCirculate', params)
  }
  exportDataTopCirculate(params) {
    return this.post(
      'Overview/TopIssuerCirculateExport',
      params,
      {},
      'download',
    )
  }
}

export default new TopReleaseProxy(
  ServiceProxyConfig.Bond.PrimaryMarket.ServiceUrl,
)
