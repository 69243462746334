import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig, groupHOHAUP } from '../../../../../configs/Global'
import { Realtime } from '../../../../common/Realtime'
import { Table } from '../../../../common/table'
import { TextBlink } from '../../../../common/table/TextBlink'
import {
  formatVal,
  valDivMillion,
  valDivThousand,
  valToPercent,
} from '../../../../utils/Value'
import { keySchemaSector, keyTableSector } from '../constans'
import style from '../index.module.css'
import {
  changeSectorId,
  keys,
  selectLoading,
  selectLocale,
  selectSectorCell,
  selectSectorId,
  sortSector,
  subscribeSector,
} from '../store/slice'
import { getSectorThunk } from '../store/thunk'
import TdColor from '../TdColor'
import TdSector from './TdSector'

const TableSector = () => {
  const dispatch = useDispatch()

  const tableRef = useRef()

  const sectorId = useSelector(selectSectorId)
  const loading = useSelector(selectLoading(keys.SECTOR))
  const locale = useSelector(selectLocale)

  useEffect(() => {
    dispatch(getSectorThunk({ IcbLevel: 2 }))
  }, [locale])

  return (
    <div ref={tableRef} className="h-100">
      <Table
        ids={sectorId}
        columnDraggable={false}
        getDataFromRedux={selectSectorCell}
        isLoading={loading}
        changeIds={changeSectorId}
        rowDraggable={false}
        hasFooter={true}
        hasTooltip={false}
        resizable={false}
        stickyFirstColumn={true}
        sort={sortSector}
        schema={Object.keys(keyTableSector).map((key) => {
          const title = `market.marketInDepth.indices.${key}`

          const colId = keyTableSector[key]
          const result = {
            colId,
            title,
            disableSort: false,
            thStyle: {
              textAlign: key === keySchemaSector.NAME ? 'left' : 'right',
            },
            tdStyle: {
              height: ![
                keySchemaSector.NAME,
                keySchemaSector.TOTAL_VALUE,
                keySchemaSector.TOTAL_VOLUME,
              ].includes(key)
                ? '100%'
                : undefined,
              textAlign: key !== keySchemaSector.NAME ? 'right' : undefined,
            },
          }

          return {
            ...result,
            isI18n: false,
            canCustomTd: [
              keySchemaSector.CLOSE_PRICE,
              keySchemaSector.PRICE_CHANGE,
              keySchemaSector.PERCENT_PRICE_CHANGE,
              keySchemaSector.TOTAL_VOLUME,
              keySchemaSector.TOTAL_VALUE,
            ].includes(key),
            render: [
              keySchemaSector.CLOSE_PRICE,
              keySchemaSector.PRICE_CHANGE,
              keySchemaSector.PERCENT_PRICE_CHANGE,
            ].includes(key)
              ? (value, colId, { style, className }) => (
                  <TdColor
                    value={
                      key === keySchemaSector.PERCENT_PRICE_CHANGE
                        ? valToPercent(value)
                        : value
                    }
                    colId={colId}
                    keyColor="priceChange"
                    selectCell={selectSectorCell}
                    isPercent={
                      key === keySchemaSector.PERCENT_PRICE_CHANGE
                        ? true
                        : false
                    }
                    style={style}
                    className={className}
                  />
                )
              : key === keySchemaSector.NAME
              ? (value, colId) => (
                  <TdSector value={value} colId={colId} tableRef={tableRef} />
                )
              : key === keySchemaSector.TOTAL_VOLUME
              ? (value) => (
                  <td className={style.textRight}>
                    <TextBlink value={value}>
                      {formatVal(valDivThousand(value))}
                    </TextBlink>
                  </td>
                )
              : key === keySchemaSector.TOTAL_VALUE
              ? (value) => (
                  <td className={style.textRight}>
                    <TextBlink value={value}>
                      {formatVal(valDivMillion(value))}
                    </TextBlink>
                  </td>
                )
              : undefined,
          }
        })}
      />
      {sectorId.map((id) => (
        <Realtime
          channel={ChannelConfig.SectorChannel + id + '-' + groupHOHAUP}
          action={subscribeSector}
          key={id}
        >
          <></>
        </Realtime>
      ))}
    </div>
  )
}

export default TableSector
