import style from '../index.module.css'
import GroupStock from './GroupStock'
import SelectedItem from './SelectedItem'

const Container = () => {
  return (
    <div className={style.containerCompany}>
      <GroupStock />
      <SelectedItem />
    </div>
  )
}

export default Container
