import { Area, Bar, Line } from 'recharts'
import { EMPTY_VALUE } from '../../../constants/Common'
import { emptyInList } from '../../../utils/Common'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { getFontSize } from '../../../utils/FontSize'
import { formatVal, valToPercent } from '../../../utils/Value'
import { TIME_RANGES } from '../../tabs/DispatchActionTab'
import { AXIS_LABEL_POSITION, MARGIN } from '../constants'
import {
  getDecimalLengthYAxis,
  getRoundedTickValues,
  getTextWidth,
  getTickValues,
  yExtentsCharts,
} from '../helper'
import { getPropLabelPosition, getYAxisWidth } from '../rechart/helper'
import {
  CHART_TYPES,
  DOT_TYPES,
  HEIGHT_XAXIS,
  MAX_BAR_SIZE,
  REF_LABEL_WIDTH,
  TICK_MARGIN_XAXIS,
  TICK_MARGIN_YAXIS,
  TIME_FREQUENCY,
} from './constant'
import { Oval } from './dotTypes/Oval'
import { Rectangle } from './dotTypes/Rectangle'
import { Rhombus } from './dotTypes/Rhombus'

export const getAxisProps = ({
  width,
  height,
  data,
  schema,
  typeFontSize,
  timeZone,
  locale,
  styleXAxis,
  styleYAxis,
  styleYAxisLabel,
  isShowYAxisLegend,
  formatXAxis,
}) => {
  const yAxisProps = getYAxisProps({
    data,
    yAxis: schema.yAxis,
    hasReferenceLabel: isShowYAxisLegend && schema.hasReferenceLabel,
    typeFontSize,
    styleYAxis,
    styleYAxisLabel,
  })

  const totalLeftYAxisWidth = yAxisProps
    .filter((yAxis) => yAxis.orientation === 'left')
    .reduce((total, current) => total + current.width, 0)

  const totalRightYAxisWidth = yAxisProps
    .filter((yAxis) => yAxis.orientation === 'right')
    .reduce((total, current) => total + current.width, 0)

  const xAxisProps = getXAxisProps({
    xAxis: schema.xAxis,
    data,
    width: width - totalRightYAxisWidth - totalLeftYAxisWidth,
    locale: locale,
    timeZone: timeZone,
    typeFontSize: typeFontSize,
    styleXAxis,
    formatXAxis,
  })

  return { yAxisProps, xAxisProps, totalLeftYAxisWidth, totalRightYAxisWidth }
}

const getXAxisProps = ({
  data,
  width,
  xAxis,
  locale,
  timeZone,
  typeFontSize,
  styleXAxis,
  formatXAxis,
}) => {
  return xAxis.map(
    ({ dataKey, timeRange, ticks, tickFormatter, ...others }) => {
      const props = {
        dataKey,
        timeRange,
        xAxisId: 0,
        tickCount: 5,
        type: 'category',
        axisLine: false,
        tickSize: 0,
        fontSize: styleXAxis?.fontSize || 12,
        tickMargin: TICK_MARGIN_XAXIS,
        tick: {
          fill: '#4e4e4e',
          textDecoration: styleXAxis?.underline ? 'underline' : 'initial',
          fontStyle: styleXAxis?.italic ? 'italic' : 'normal',
          fontWeight: styleXAxis?.bold ? 600 : 400,
        },
        ticks,
        tickFormatter,
        height: HEIGHT_XAXIS,
        interval: 'preserveStartEnd',
        allowDataOverflow: false,
        ...others,
      }

      const tickFontSize = getFontSize(props.fontSize, typeFontSize)

      if (timeRange) {
        const { tickValues } = getXAxisTicks({
          data,
          keyXAxis: dataKey,
          timeRange,
          tickNum: props.tickCount,
          width,
          locale,
          fontSize: styleXAxis?.fontSize || tickFontSize,
          margin: { left: 0, right: 0 },
          timeZone,
        })
        const format = formatXAxis || getFormat(timeRange)

        const xAxisDateTickFormatter = (d) =>
          formatDateTime(new Date(d), format, locale, timeZone)

        if (!ticks) {
          props.ticks = tickValues
        }

        if (!tickFormatter) {
          props.tickFormatter = xAxisDateTickFormatter
        }
      }

      return {
        ...props,
        fontSize: tickFontSize,
      }
    },
  )
}

const getYAxisProps = ({
  data,
  yAxis,
  hasReferenceLabel,
  typeFontSize,
  styleYAxis,
  styleYAxisLabel,
}) => {
  const yAxisProps = yAxis.map((config) => {
    const props = {
      orientation: 'left',
      type: 'number',
      tickCount: 5,
      tick: {
        fill: '#4e4e4e',
        textDecoration: styleYAxis?.underline ? 'underline' : 'initial',
        fontStyle: styleYAxis?.italic ? 'italic' : 'normal',
        fontWeight: styleYAxis?.bold ? 600 : 400,
      },
      axisLine: false,
      tickSize: 0,
      fontSize: styleYAxis?.fontSize || 12,
      labelFontSize: styleYAxisLabel?.fontSize || 10,
      labelTextDecoration: styleYAxisLabel?.underline ? 'underline' : 'initial',
      labelFontStyle: styleYAxisLabel?.italic ? 'italic' : 'normal',
      labelFontWeight: styleYAxisLabel?.bold ? 600 : 400,
      labelOpacity: 1,
      tickMargin: TICK_MARGIN_YAXIS,
      hasReferenceLabel,
      ...config,
    }

    const tickFontSize = getFontSize(props.fontSize, typeFontSize)
    const tickValues = getNiceTicks(data, props)
    const decimalLength =
      props.decimalLength || getDecimalLengthYAxis(tickValues)

    if (!props.tickFormatter) {
      props.tickFormatter = (val) => formatVal(val, decimalLength)
    }

    if (props.labelText) {
      if (!props.labelPosition) {
        props.labelPosition =
          props.orientation === 'left'
            ? AXIS_LABEL_POSITION.LEFT
            : AXIS_LABEL_POSITION.RIGHT
      }

      props.label = getPropLabelPosition({
        labelText: props.labelText,
        labelPosition: props.labelPosition,
        labelColor: props.labelColor,
        fontSize: props.labelFontSize,
        textDecoration: props.labelTextDecoration,
        fontStyle: props.labelFontStyle,
        fontWeight: props.labelFontWeight,
        labelOpacity: props.labelOpacity,
        typeFontSize,
      })
    }

    if (props.width === undefined) {
      props.width = getYAxisWidth({
        tickValues,
        labelText: props.labelText,
        labelFontSize: getFontSize(props.labelFontSize, typeFontSize),
        labelPosition: props.labelPosition,
        decimalLength,
        fontSize: tickFontSize,
        hasReferenceLabel:
          props.hasReferenceLabel && props.orientation === 'right',
        referenceLabelWidth: REF_LABEL_WIDTH,
      })
    }

    return {
      ...props,
      ticks: tickValues,
      domain: [(tickValues[0], tickValues[tickValues.length - 1])],
      fontSize: tickFontSize,
      decimalLength,
    }
  })

  return yAxisProps
}

const getNiceTicks = (data, props) => {
  let {
    dataKeys,
    isGroupBar = false,
    isStackedBar = false,
    isLineChart = false,
    isBarChart = false,
    isScatterChart = false,
    isStackArea = false,
    tickCount = 5,
  } = props

  let [minVal, maxVal] = yExtentsCharts(
    data,
    isLineChart ? dataKeys.flat() : dataKeys,
    isStackedBar || isStackArea,
    isLineChart,
    isScatterChart,
  )

  if (isGroupBar) {
    let [yMin, yMax] = [0, 0]
    dataKeys.forEach((keyStack) => {
      const [newMin, newMax] = emptyInList(data, keyStack)
        ? [0, 0]
        : yExtentsCharts(
            data,
            keyStack,
            isStackedBar || isStackArea,
            false,
            false,
            true,
          )
      ;[yMin, yMax] = [Math.min(newMin, yMin), Math.max(newMax, yMax)]
    })
    ;[minVal, maxVal] = [yMin, yMax]
  }

  if (!minVal && !maxVal) {
    ;[minVal, maxVal] = [0, 100]
  }

  const ticks = getRoundedTickValues([minVal, maxVal], tickCount, isBarChart)

  return ticks
}

export const renderChartComponent = (config) => {
  switch (config.chartType) {
    case CHART_TYPES.LINE:
    case CHART_TYPES.DOT:
      return getLineChart(config)
    case CHART_TYPES.BAR:
    case CHART_TYPES.STACK_BAR:
      return getBarChart(config)
    case CHART_TYPES.AREA:
    case CHART_TYPES.STACK_AREA:
      return getAreaChart(config)
    default:
      return getLineChart(config)
  }
}

const CustomizedDataLabel = (props) => {
  const {
    x,
    y,
    width,
    stroke,
    value,
    decimalLength,
    dataUnit,
    isLastChart,
    isBarChart,
  } = props
  const formatValue =
    dataUnit === '%'
      ? valToPercent(value, true, false, decimalLength)
      : formatVal(value, decimalLength)

  if (formatValue === EMPTY_VALUE) return null

  const textWidth = getTextWidth(formatValue, 12)
  let dx = 0
  let dy = 0

  if (isBarChart) {
    if (textWidth - width >= 0) {
      dx = width / 2
    } else {
      dx = textWidth / 2
    }
  } else {
    dx = (textWidth - width) / 2
  }

  if (value >= 0) {
    if (isLastChart) {
      dy = -10
    } else {
      dy = 10
    }
  } else {
    if (!isLastChart) {
      dy = -10
    } else {
      dy = 15
    }
  }

  return (
    <text
      x={x}
      y={y}
      dx={dx || 0}
      dy={dy || 0}
      fill={stroke}
      fontSize={12}
      textAnchor="middle"
    >
      {formatValue}
    </text>
  )
}

const getLineChart = (config) => {
  let {
    color,
    dot = false,
    type = 'linear',
    dotType,
    dotWidth,
    strokeWidth,
    isDisplayDataLabel,
    decimalLength,
    dataUnit,
    ...others
  } = config
  let lineDot = false
  let label = false

  if (config.chartType === CHART_TYPES.DOT) {
    strokeWidth = 0
  }

  if (config.chartType === CHART_TYPES.DOT || dot) {
    dotWidth = config.chartType === CHART_TYPES.DOT ? dotWidth : 6.5
    switch (dotType) {
      case DOT_TYPES.RECTANGLE:
        lineDot = (props) => (
          <Rectangle color={color} dotWidth={dotWidth} {...props} />
        )
        break
      case DOT_TYPES.RHOMBUS:
        lineDot = (props) => (
          <Rhombus color={color} dotWidth={dotWidth} {...props} />
        )
        break
      default:
        lineDot = (props) => (
          <Oval color={color} dotWidth={dotWidth} {...props} />
        )
        break
    }
  }

  if (isDisplayDataLabel) {
    label = (
      <CustomizedDataLabel
        decimalLength={decimalLength}
        dataUnit={dataUnit}
        isLastChart={true}
      />
    )
  }

  return (
    <Line
      key={config.dataKey}
      type={type}
      dot={lineDot || dot}
      activeDot={{ r: 4 }}
      isAnimationActive={false}
      stroke={color}
      strokeWidth={strokeWidth}
      connectNulls
      label={label}
      {...others}
    />
  )
}

const getBarChart = (config) => {
  let {
    color,
    barSize,
    isDisplayDataLabel,
    decimalLength,
    dataUnit,
    chartType,
    isLastChart,
    width,
    numberCategory,
    numberBar,
    ...others
  } = config
  let label = false

  if (isDisplayDataLabel) {
    label = (
      <CustomizedDataLabel
        decimalLength={decimalLength}
        dataUnit={dataUnit}
        isLastChart={chartType !== CHART_TYPES.STACK_BAR || isLastChart}
        isBarChart={[CHART_TYPES.BAR, CHART_TYPES.STACK_BAR].includes(
          chartType,
        )}
      />
    )
  }

  if (!barSize && typeof barSize !== 'number') {
    const RANGE_SPACE_CATEGORY_PERCENT = 0.65
    width = width * RANGE_SPACE_CATEGORY_PERCENT
    barSize = width / (numberCategory * numberBar)
  }

  return (
    <Bar
      key={config.dataKey}
      isAnimationActive={false}
      fill={color}
      barSize={barSize && barSize < MAX_BAR_SIZE ? barSize : MAX_BAR_SIZE}
      label={label}
      {...others}
    />
  )
}

const getAreaChart = (config) => {
  const {
    color,
    isDisplayDataLabel,
    decimalLength,
    dataUnit,
    chartType,
    isLastChart,
    ...others
  } = config
  let label = false

  if (isDisplayDataLabel) {
    label = (
      <CustomizedDataLabel
        decimalLength={decimalLength}
        dataUnit={dataUnit}
        isLastChart={chartType !== CHART_TYPES.STACK_AREA || isLastChart}
      />
    )
  }

  return (
    <Area
      key={config.dataKey}
      activeDot={{ r: 4 }}
      isAnimationActive={false}
      fill={color}
      stroke={color}
      connectNulls
      label={label}
      {...others}
    />
  )
}

export const labelFormatterByTimeRange =
  (timeRange, locale, timeZone) => (value) => {
    return timeRange
      ? formatDateTime(value, FORMAT.DATE, locale, timeZone)
      : value
  }

export const formatterByName = (tooltips) => (value, name) => {
  const itemTooltip = tooltips.find((tooltip) => tooltip.dataKey === name)
  const decimalLength =
    typeof itemTooltip?.decimalLength === 'number'
      ? itemTooltip?.decimalLength
      : 2
  let formattedValue = formatVal(value, decimalLength)

  if (itemTooltip?.dataUnit) {
    formattedValue += ' ' + itemTooltip?.dataUnit
  }

  return itemTooltip?.formatName
    ? [formattedValue, itemTooltip.formatName]
    : formattedValue
}

export const getXAxisTicks = ({
  timeRange,
  margin = MARGIN,
  fontSize = 12,
  ...args
}) => {
  switch (timeRange) {
    case TIME_RANGES['1M']:
    case TIME_RANGES['3M']:
    case '6M':
    case TIME_RANGES['9M']:
    case 'MTD':
    case 'QTD':
    case 'YTD':
      return {
        tickValues: getTickValues({
          format: FORMAT.DATE,
          margin,
          fontSize,
          ...args,
        }),
      }

    case TIME_RANGES['1Y']:
    case TIME_RANGES['3Y']:
      return {
        tickValues: getTickValues({
          format: FORMAT.MONTH_YEAR_SLASH,
          margin,
          fontSize,
          ...args,
        }),
      }
    case TIME_RANGES['5Y']:
    case '10Y':
    case TIME_RANGES.All:
      return {
        tickValues: getTickValues({
          format: FORMAT.YEAR,
          margin,
          fontSize,
          isNotFormatXAxis: true,
          ...args,
        }),
      }
    default:
      throw Error('Pls set timeRange correctly!')
  }
}

export const getTimeFrequencyMax = (arrayTimeFrequency) => {
  if (Array.isArray(arrayTimeFrequency)) {
    if (arrayTimeFrequency.includes('yearly')) {
      return TIME_FREQUENCY.YEARLY
    } else if (
      arrayTimeFrequency.includes('quarterly') ||
      arrayTimeFrequency.includes('ttm') ||
      arrayTimeFrequency.includes('monthly')
    ) {
      return TIME_FREQUENCY.QUARTERLY
    } else {
      return TIME_FREQUENCY.DAILY
    }
  }

  return TIME_FREQUENCY.DAILY
}

export const getFormat = (timeRange) => {
  switch (timeRange) {
    case TIME_RANGES['1M']:
    case TIME_RANGES['3M']:
    case '6M':
    case TIME_RANGES['9M']:
    case 'MTD':
    case 'QTD':
    case 'YTD':
      return FORMAT.DATE

    case TIME_RANGES['1Y']:
    case TIME_RANGES['3Y']:
      return FORMAT.MONTH_YEAR_SLASH

    case TIME_RANGES['5Y']:
    case '10Y':
      return FORMAT.QUARTER_YEAR

    case TIME_RANGES.All:
      return FORMAT.YEAR

    default:
      throw Error('Pls set timeRange correctly!')
  }
}
