import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { DATE_TYPES } from '../constants'
import {
  formatDataTable,
  formatDataTableAverageYtm,
  formatDataTableByBon,
  formatDataTableTopBid,
  formatPrice,
} from '../helper'
import {
  getAverageYTMofIssuer,
  getMarketLiquidityChartData,
  getTopInterestRate,
  getTopInterestRateAsk,
  getTopInterestRateAskByReload,
  getTopInterestRateByReload,
  getTopLiquidityDataByBond,
  getTopLiquidityDataByBondByReload,
  getTopLiquidityDataByIssuer,
  getTopLiquidityDataByIssuerByReload,
} from './thunk'

export const keys = {
  TOP_LIQUIDITY: 'TOP_LIQUIDITY',
  AVERAGE_YTM: 'AVERAGE_YTM',
  TOP_BID_INTEREST: 'TOP_BID_INTEREST',
  TOP_ASK_INTEREST: 'TOP_ASK_INTEREST',
  MARKET_LIQUIDITY: 'MARKET_LIQUIDITY',
}

export const TOTAL_VALUE_TYPE = {
  TotalValue: 'TotalValue',
  TotalMatchValue: 'TotalMatchValue',
  TotalDealValue: 'TotalDealValue',
}

const initialState = {
  loading: {
    [keys.TOP_LIQUIDITY]: true,
    [keys.AVERAGE_YTM]: true,
    [keys.TOP_BID_INTEREST]: true,
    [keys.TOP_ASK_INTEREST]: true,
    [keys.MARKET_LIQUIDITY]: true,
  },
  filter: {
    [keys.TOP_LIQUIDITY]: {
      TimeRange: DATE_TYPES.WEEK_DAY,
      TotalValueType: TOTAL_VALUE_TYPE.TotalValue,
      TCPH_TP: 'TCPH',
      StartDate: new Date(),
      EndDate: new Date(),
      Top: 10,
    },
    [keys.AVERAGE_YTM]: {
      ytmChange: false,
      interest: 'real',
      TimeRange: 'OneDay',
      StartDate: new Date(),
      EndDate: new Date(),
    },
    [keys.TOP_BID_INTEREST]: {
      TotalValueType: TOTAL_VALUE_TYPE.TotalValue,
    },
    [keys.TOP_ASK_INTEREST]: {
      TotalValueType: TOTAL_VALUE_TYPE.TotalValue,
    },
    [keys.MARKET_LIQUIDITY]: {
      TimeRange: 'OneWeek',
      GroupId: 150,
    },
  },
  data: {
    [keys.TOP_LIQUIDITY]: {
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
      rowsCollapse: [],
      tickers: [],
      organizationIds: [],
    },
    [keys.AVERAGE_YTM]: {
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
      rowsCollapse: [],
    },
    [keys.TOP_BID_INTEREST]: {
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
      rowsCollapse: [],
      tickers: [],
    },
    [keys.TOP_ASK_INTEREST]: {
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
      rowsCollapse: [],
      tickers: [],
    },

    [keys.MARKET_LIQUIDITY]: [],
  },
}

export const slice = createSlice({
  name: 'bond/corporateBond/secondaryMarketOverView',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    sortTopLiquidity: (state, action) => {
      const idsSort = getIdsFromProps(
        state.data[keys.TOP_LIQUIDITY].ids,
        state.data[keys.TOP_LIQUIDITY].dataById,
        action.payload,
        state.data[keys.TOP_LIQUIDITY].initialIds,
      )
      state.data[keys.TOP_LIQUIDITY].ids = idsSort
    },
    sortAverageYtm: (state, action) => {
      const idsSort = getIdsFromProps(
        state.data[keys.AVERAGE_YTM].ids,
        state.data[keys.AVERAGE_YTM].dataById,
        action.payload,
        state.data[keys.AVERAGE_YTM].initialIds,
      )
      state.data[keys.AVERAGE_YTM].ids = idsSort
    },
    sortTopBid: (state, action) => {
      const idsSort = getIdsFromProps(
        state.data[keys.TOP_BID_INTEREST].ids,
        state.data[keys.TOP_BID_INTEREST].dataById,
        action.payload,
        state.data[keys.TOP_BID_INTEREST].initialIds,
      )
      state.data[keys.TOP_BID_INTEREST].ids = idsSort
    },
    sortTopAsk: (state, action) => {
      const idsSort = getIdsFromProps(
        state.data[keys.TOP_ASK_INTEREST].ids,
        state.data[keys.TOP_ASK_INTEREST].dataById,
        action.payload,
        state.data[keys.TOP_ASK_INTEREST].initialIds,
      )
      state.data[keys.TOP_ASK_INTEREST].ids = idsSort
    },
    changeFilter: (state, action) => {
      state.filter[action.payload.label] = {
        ...state.filter[action.payload.label],
        [action.payload.key]: action.payload.value,
      }
      if (action.payload.label === keys.MARKET_TRADING_VALUE_BY_SECTOR) {
        state.loadingIcbIds = false
      }
    },
    subscribeBondId: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      if (state.filter[keys.TOP_LIQUIDITY].TimeRange !== 'OneDay') return

      const index = state.data[keys.TOP_LIQUIDITY].data.findIndex(
        (item) => item.ticker === realtimeData?.[1],
      )

      if (index !== -1) {
        const newData = [
          ...state.data[keys.TOP_LIQUIDITY].data.slice(0, index),
          state.filter[keys.TOP_LIQUIDITY].TotalValueType ===
          TOTAL_VALUE_TYPE.TotalValue
            ? {
                ...state.data[keys.TOP_LIQUIDITY].data[index],
                averageYTM:
                  parseFloat(realtimeData?.[8] ?? realtimeData?.[24] ?? 0) -
                  parseFloat(realtimeData?.[4] ?? 0),
                changeofCleanPrice: parseFloat(
                  realtimeData?.[8] ?? realtimeData?.[24] ?? 0,
                ),
                changeofDirtyPrice: parseFloat(
                  realtimeData?.[8] ?? realtimeData?.[24] ?? 0,
                ),
                averageDirtyPrice: formatPrice(
                  parseFloat(realtimeData?.[7] ?? realtimeData?.[23]),
                  state.data[keys.TOP_LIQUIDITY].data[index]?.rbD0002,
                  false,
                ),
                averageCleanPrice: formatPrice(
                  parseFloat(realtimeData?.[7] ?? realtimeData?.[23]),
                  state.data[keys.TOP_LIQUIDITY].data[index]?.rbD0002,
                  true,
                ),
                totalValue: parseFloat(
                  realtimeData?.[21] ?? realtimeData?.[27] ?? 0,
                ),
              }
            : state.filter[keys.TOP_LIQUIDITY].TotalValueType ===
              TOTAL_VALUE_TYPE.TotalMatchValue
            ? {
                ...state.data[keys.TOP_LIQUIDITY].data[index],
                averageYTM:
                  parseFloat(realtimeData?.[8] ?? 0) -
                  parseFloat(realtimeData?.[4] ?? 0),
                changeofCleanPrice: parseFloat(realtimeData?.[8] ?? 0),
                changeofDirtyPrice: parseFloat(realtimeData?.[8] ?? 0),
                averageDirtyPrice: formatPrice(
                  parseFloat(realtimeData?.[7]),
                  state.data[keys.TOP_LIQUIDITY].data[index]?.rbD0002,
                  false,
                ),
                averageCleanPrice: formatPrice(
                  parseFloat(realtimeData?.[7]),
                  state.data[keys.TOP_LIQUIDITY].data[index]?.rbD0002,
                  true,
                ),
                totalValue: parseFloat(realtimeData?.[21] ?? 0),
              }
            : {
                ...state.data[keys.TOP_LIQUIDITY].data[index],
                averageYTM:
                  parseFloat(realtimeData?.[24] ?? 0) -
                  parseFloat(realtimeData?.[4] ?? 0),
                changeofCleanPrice: parseFloat(realtimeData?.[24] ?? 0),
                changeofDirtyPrice: parseFloat(realtimeData?.[24] ?? 0),
                averageDirtyPrice: formatPrice(
                  parseFloat(realtimeData?.[23]),
                  state.data[keys.TOP_LIQUIDITY].data[index]?.rbD0002,
                  false,
                ),
                averageCleanPrice: formatPrice(
                  parseFloat(realtimeData?.[23]),
                  state.data[keys.TOP_LIQUIDITY].data[index]?.rbD0002,
                  true,
                ),
                totalValue: parseFloat(realtimeData?.[27] ?? 0),
              },
          ...state.data[keys.TOP_LIQUIDITY].data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data[keys.TOP_LIQUIDITY] = {
          ...state.data[keys.TOP_LIQUIDITY],
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
    subscribeBondBidMatch: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data[keys.TOP_BID_INTEREST].data.findIndex(
        (item) => item.ticker === realtimeData?.[1],
      )

      if (index !== -1) {
        const newData = [
          ...state.data[keys.TOP_BID_INTEREST].data.slice(0, index),
          {
            ...state.data[keys.TOP_BID_INTEREST].data[index],
            price: formatPrice(parseFloat(realtimeData?.[3]), undefined, false),
            volume: parseFloat(realtimeData?.[5]),
            ytm: parseFloat(realtimeData?.[4]),
          },
          ...state.data[keys.TOP_BID_INTEREST].data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data[keys.TOP_BID_INTEREST] = {
          ...state.data[keys.TOP_BID_INTEREST],
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
    subscribeBondBidDeal: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data[keys.TOP_BID_INTEREST].data.findIndex(
        (item) => item.ticker === realtimeData?.[1],
      )

      if (index !== -1) {
        const newData = [
          ...state.data[keys.TOP_BID_INTEREST].data.slice(0, index),
          {
            ...state.data[keys.TOP_BID_INTEREST].data[index],
            price: formatPrice(parseFloat(realtimeData?.[3]), undefined, false),
            volume: parseFloat(realtimeData?.[5]),
            ytm: parseFloat(realtimeData?.[4]),
          },
          ...state.data[keys.TOP_BID_INTEREST].data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data[keys.TOP_BID_INTEREST] = {
          ...state.data[keys.TOP_BID_INTEREST],
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
    subscribeBondAskMatch: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data[keys.TOP_ASK_INTEREST].data.findIndex(
        (item) => item.ticker === realtimeData?.[1],
      )

      if (index !== -1) {
        const newData = [
          ...state.data[keys.TOP_ASK_INTEREST].data.slice(0, index),
          {
            ...state.data[keys.TOP_ASK_INTEREST].data[index],
            price: formatPrice(
              parseFloat(realtimeData?.[12]),
              undefined,
              false,
            ),
            volume: parseFloat(realtimeData?.[14]),
            ytm: parseFloat(realtimeData?.[13]),
          },
          ...state.data[keys.TOP_ASK_INTEREST].data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data[keys.TOP_ASK_INTEREST] = {
          ...state.data[keys.TOP_ASK_INTEREST],
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
    subscribeBondAskDeal: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data[keys.TOP_ASK_INTEREST].data.findIndex(
        (item) => item.ticker === realtimeData?.[1],
      )

      if (index !== -1) {
        const newData = [
          ...state.data[keys.TOP_ASK_INTEREST].data.slice(0, index),
          {
            ...state.data[keys.TOP_ASK_INTEREST].data[index],
            price: formatPrice(
              parseFloat(realtimeData?.[12]),
              undefined,
              false,
            ),
            volume: parseFloat(realtimeData?.[14]),
            ytm: parseFloat(realtimeData?.[13]),
          },
          ...state.data[keys.TOP_ASK_INTEREST].data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data[keys.TOP_ASK_INTEREST] = {
          ...state.data[keys.TOP_ASK_INTEREST],
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
    subscribeOrganizationId: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data[keys.TOP_LIQUIDITY].data.findIndex(
        (item) => item.organizationId?.toString() === realtimeData?.[0],
      )

      if (index !== -1) {
        const newData = [
          ...state.data[keys.TOP_LIQUIDITY].data.slice(0, index),
          {
            ...state.data[keys.TOP_LIQUIDITY].data[index],
            averageYTM:
              parseFloat(realtimeData?.[11] ?? 0) !== 0
                ? (parseFloat(realtimeData?.[10] ?? 0) -
                    parseFloat(realtimeData?.[11] ?? 0)) /
                  parseFloat(realtimeData?.[11] ?? 0)
                : 0,
            changeofCleanPrice:
              parseFloat(realtimeData?.[6] ?? 0) !== 0
                ? (parseFloat(realtimeData?.[7] ?? 0) -
                    parseFloat(realtimeData?.[6] ?? 0)) /
                  parseFloat(realtimeData?.[6] ?? 0)
                : 0,
            changeofDirtyPrice:
              parseFloat(realtimeData?.[2] ?? 0) !== 0
                ? (parseFloat(realtimeData?.[3] ?? 0) -
                    parseFloat(realtimeData?.[2] ?? 0)) /
                  parseFloat(realtimeData?.[2] ?? 0)
                : 0,
            averageDirtyPrice: parseFloat(realtimeData?.[3]) ?? 0,
            averageCleanPrice: parseFloat(realtimeData?.[7]) ?? 0,
            totalValue: parseFloat(
              state.filter[keys.TOP_LIQUIDITY].TotalValueType ===
                TOTAL_VALUE_TYPE.TotalValue
                ? parseFloat(realtimeData?.[14] ?? 0) +
                    parseFloat(realtimeData?.[18] ?? 0)
                : state.filter[keys.TOP_LIQUIDITY].TotalValueType ===
                  TOTAL_VALUE_TYPE.TotalMatchValue
                ? parseFloat(realtimeData?.[14] ?? 0)
                : parseFloat(realtimeData?.[18] ?? 0),
            ),
          },
          ...state.data[keys.TOP_LIQUIDITY].data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data[keys.TOP_LIQUIDITY] = {
          ...state.data[keys.TOP_LIQUIDITY],
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
  },

  extraReducers: (builder) => {
    //getTopLiquidityDataByIssuer
    builder.addCase(getTopLiquidityDataByIssuer.pending, (state) => {
      state.loading[keys.TOP_LIQUIDITY] = true
    })
    builder.addCase(getTopLiquidityDataByIssuer.fulfilled, (state, action) => {
      const data = formatDataTable(action.payload)

      state.data[keys.TOP_LIQUIDITY] = {
        ...state.data[keys.TOP_LIQUIDITY],
        data,
        ids: data.map((item) => item.id),
        initialIds: data.map((item) => item.id),
        dataById: keyBy(data, 'id'),
        levels: data,
        organizationIds: data.map((item) => item.organizationId),
      }
      state.loading[keys.TOP_LIQUIDITY] = false
    })
    builder.addCase(getTopLiquidityDataByIssuer.rejected, (state, action) => {
      state.loading[keys.TOP_LIQUIDITY] = action.payload
    })
    builder.addCase(getTopLiquidityDataByIssuerByReload.pending, (state) => {})
    builder.addCase(
      getTopLiquidityDataByIssuerByReload.fulfilled,
      (state, action) => {
        const data = formatDataTable(action.payload)

        state.data[keys.TOP_LIQUIDITY] = {
          ...state.data[keys.TOP_LIQUIDITY],
          data,
          ids: data.map((item) => item.id),
          initialIds: data.map((item) => item.id),
          dataById: keyBy(data, 'id'),
          levels: data,
          organizationIds: data.map((item) => item.organizationId),
        }
      },
    )
    builder.addCase(
      getTopLiquidityDataByIssuerByReload.rejected,
      (state, action) => {},
    )
    //getTopLiquidityDataByBond
    builder.addCase(getTopLiquidityDataByBond.pending, (state) => {
      state.loading[keys.TOP_LIQUIDITY] = true
    })
    builder.addCase(getTopLiquidityDataByBond.fulfilled, (state, action) => {
      const data = formatDataTableByBon(action.payload)

      state.data[keys.TOP_LIQUIDITY] = {
        ...state.data[keys.TOP_LIQUIDITY],
        data,
        ids: data.map((item) => item.id),
        initialIds: data.map((item) => item.id),
        dataById: keyBy(data, 'id'),
        levels: data,
        tickers: data.map((item) => item.ticker),
      }
      state.loading[keys.TOP_LIQUIDITY] = false
    })
    builder.addCase(getTopLiquidityDataByBond.rejected, (state, action) => {
      state.loading[keys.TOP_LIQUIDITY] = action.payload
    })
    builder.addCase(getTopLiquidityDataByBondByReload.pending, (state) => {})
    builder.addCase(
      getTopLiquidityDataByBondByReload.fulfilled,
      (state, action) => {
        const data = formatDataTableByBon(action.payload)

        state.data[keys.TOP_LIQUIDITY] = {
          ...state.data[keys.TOP_LIQUIDITY],
          data,
          ids: data.map((item) => item.id),
          initialIds: data.map((item) => item.id),
          dataById: keyBy(data, 'id'),
          levels: data,
          tickers: data.map((item) => item.ticker),
        }
      },
    )
    builder.addCase(
      getTopLiquidityDataByBondByReload.rejected,
      (state, action) => {},
    )
    //get Average YTM
    builder.addCase(getAverageYTMofIssuer.pending, (state) => {
      state.loading[keys.AVERAGE_YTM] = true
    })
    builder.addCase(getAverageYTMofIssuer.fulfilled, (state, action) => {
      const data = formatDataTableAverageYtm(action.payload)

      state.data[keys.AVERAGE_YTM] = {
        ...state.data[keys.AVERAGE_YTM],
        data,
        ids: data.map((item) => item.id),
        initialIds: data.map((item) => item.id),
        dataById: keyBy(data, 'id'),
        levels: data,
      }
      state.loading[keys.AVERAGE_YTM] = false
    })
    builder.addCase(getAverageYTMofIssuer.rejected, (state, action) => {
      state.loading[keys.TOP_LIQUIDITY] = action.payload
    })
    //top BID
    builder.addCase(getTopInterestRate.pending, (state) => {
      state.loading[keys.TOP_BID_INTEREST] = true
    })
    builder.addCase(getTopInterestRate.fulfilled, (state, action) => {
      const data = formatDataTableTopBid(action.payload)

      state.data[keys.TOP_BID_INTEREST] = {
        ...state.data[keys.TOP_BID_INTEREST],
        data,
        ids: data.map((item) => item.id),
        initialIds: data.map((item) => item.id),
        dataById: keyBy(data, 'id'),
        levels: data,
        tickers: data.map((item) => item.ticker),
      }
      state.loading[keys.TOP_BID_INTEREST] = false
    })
    builder.addCase(getTopInterestRate.rejected, (state, action) => {
      state.loading[keys.TOP_LIQUIDITY] = action.payload
    })
    builder.addCase(getTopInterestRateByReload.pending, (state) => {})
    builder.addCase(getTopInterestRateByReload.fulfilled, (state, action) => {
      const data = formatDataTableTopBid(action.payload)

      state.data[keys.TOP_BID_INTEREST] = {
        ...state.data[keys.TOP_BID_INTEREST],
        data,
        ids: data.map((item) => item.id),
        initialIds: data.map((item) => item.id),
        dataById: keyBy(data, 'id'),
        levels: data,
        tickers: data.map((item) => item.ticker),
      }
    })
    builder.addCase(getTopInterestRateByReload.rejected, (state, action) => {})
    //top ASK
    builder.addCase(getTopInterestRateAsk.pending, (state) => {
      state.loading[keys.TOP_ASK_INTEREST] = true
    })
    builder.addCase(getTopInterestRateAsk.fulfilled, (state, action) => {
      const data = formatDataTableTopBid(action.payload)

      state.data[keys.TOP_ASK_INTEREST] = {
        ...state.data[keys.TOP_ASK_INTEREST],
        data,
        ids: data.map((item) => item.id),
        initialIds: data.map((item) => item.id),
        dataById: keyBy(data, 'id'),
        levels: data,
        tickers: data.map((item) => item.ticker),
      }
      state.loading[keys.TOP_ASK_INTEREST] = false
    })
    builder.addCase(getTopInterestRateAsk.rejected, (state, action) => {
      state.loading[keys.TOP_LIQUIDITY] = action.payload
    })
    builder.addCase(getTopInterestRateAskByReload.pending, (state) => {})
    builder.addCase(
      getTopInterestRateAskByReload.fulfilled,
      (state, action) => {
        const data = formatDataTableTopBid(action.payload)

        state.data[keys.TOP_ASK_INTEREST] = {
          ...state.data[keys.TOP_ASK_INTEREST],
          data,
          ids: data.map((item) => item.id),
          initialIds: data.map((item) => item.id),
          dataById: keyBy(data, 'id'),
          levels: data,
          tickers: data.map((item) => item.ticker),
        }
      },
    )
    builder.addCase(
      getTopInterestRateAskByReload.rejected,
      (state, action) => {},
    )
    //liquidity data chart
    builder.addCase(getMarketLiquidityChartData.pending, (state) => {
      state.loading[keys.MARKET_LIQUIDITY] = true
    })
    builder.addCase(getMarketLiquidityChartData.fulfilled, (state, action) => {
      state.data[keys.MARKET_LIQUIDITY] = action.payload
      state.loading[keys.MARKET_LIQUIDITY] = false
    })
    builder.addCase(getMarketLiquidityChartData.rejected, (state, action) => {
      state.loading[keys.MARKET_LIQUIDITY] = action.payload
    })
  },
})
// Loading
export const selectLoading = (key) => (state) => state[slice.name].loading[key]

// Top Liquidity
export const selectFilterTopLiquidity = (state) =>
  state[slice.name].filter[keys.TOP_LIQUIDITY]
export const selectTopLiquidityData = (state) =>
  state[slice.name].data[keys.TOP_LIQUIDITY]
export const selectDataTableByIdTopLiquidity = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data[keys.TOP_LIQUIDITY].dataById[id], attr)

// Average YTM
export const selectFilterAverageYtm = (state) =>
  state[slice.name].filter[keys.AVERAGE_YTM]
export const selectAverageYtmData = (state) =>
  state[slice.name].data[keys.AVERAGE_YTM]
export const selectDataTableByIdAverageYtm = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data[keys.AVERAGE_YTM].dataById[id], attr)
export const selectDataTableAverageYtm = (state) =>
  state[slice.name].data[keys.AVERAGE_YTM].dataById

// Top BID
export const selectFilterTopBid = (state) =>
  state[slice.name].filter[keys.TOP_BID_INTEREST]
export const selectTopBidData = (state) =>
  state[slice.name].data[keys.TOP_BID_INTEREST]
export const selectDataTableByIdTopBid = (id, attr) => (state) =>
  valByKeyWithDot(
    state[slice.name].data[keys.TOP_BID_INTEREST].dataById[id],
    attr,
  )

// Top ASK
export const selectFilterTopAsk = (state) =>
  state[slice.name].filter[keys.TOP_ASK_INTEREST]
export const selectTopAskData = (state) =>
  state[slice.name].data[keys.TOP_ASK_INTEREST]
export const selectDataTableByIdTopAsk = (id, attr) => (state) =>
  valByKeyWithDot(
    state[slice.name].data[keys.TOP_ASK_INTEREST].dataById[id],
    attr,
  )

// Market Liquidity
export const selectFilterMarketLiquidity = (state) =>
  state[slice.name].filter[keys.MARKET_LIQUIDITY]
export const selectMarketLiquidityData = (state) =>
  state[slice.name].data[keys.MARKET_LIQUIDITY]

export const {
  resetStore,
  changeFilter,
  sort,
  sortTopBid,
  sortTopAsk,
  sortTopLiquidity,
  sortAverageYtm,
  subscribeBondId,
  subscribeBondBidMatch,
  subscribeBondBidDeal,
  subscribeBondAskMatch,
  subscribeBondAskDeal,
  subscribeOrganizationId,
} = slice.actions

register(slice.name, slice.reducer)
