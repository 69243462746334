import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../../common/loading'
import { NoData } from '../../../../../../common/noData'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import { ID_DOM_PANEL_PRODUCTION } from '../../../chartFirst/constants'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'
import { getLimitTimeLoading } from '../../../store/slice'
import { getMaxDate } from '../../../store/thunk'
import { TAB_TYPE } from '../../../table/constants'
import {
  selectTypeDataTime,
  selectTypePeriod,
} from '../../../table/store/slice'
import { QuarterSelectOption } from '../headerSelectOption/QuarterSelectOption'
import { YearSelectOption } from '../headerSelectOption/YearSelectOption'
import { encodeSizePanel } from '../helper'
import style from '../index.module.css'
import {
  getRawData,
  selectContribution,
  selectLoading,
  selectMonthActive,
  selectQuarterActive,
  selectYearActive,
} from '../store/slice'
import { getDataChartTopChange } from '../store/thunk'
import ContentContribution from './ContentContribution'

const { MONTHLY } = PRICE_DATA_TIME_SELECT

const Contribution = ({ width, height, activeTab }) => {
  const dispatch = useDispatch()

  const typeDataTime = useSelector(selectTypeDataTime)
  const typePeriod = useSelector(selectTypePeriod)
  const quarterActive = useSelector(selectQuarterActive)
  const yearActive = useSelector(selectYearActive)
  const monthActive = useSelector(selectMonthActive)
  const contributionData = useSelector(selectContribution)
  const rawData = useSelector(getRawData)
  const loading = useSelector(selectLoading)
  const limitTimeLoading = useSelector(getLimitTimeLoading)
  const locale = useSelector((state) => state.i18n.locale)

  const sizePanel = encodeSizePanel(width, height)

  useEffect(() => {
    dispatch(
      getMaxDate({
        IIVNType: activeTab,
      }),
    )
  }, [activeTab, typeDataTime, typePeriod])

  useEffect(() => {
    if (!limitTimeLoading) {
      dispatch(
        getDataChartTopChange({
          FrequencyType: typePeriod,
          DataType: activeTab !== TAB_TYPE.III ? typeDataTime : MONTHLY,
          Year: yearActive,
          Month: typeDataTime === MONTHLY ? monthActive : quarterActive,
          activeTab: activeTab,
        }),
      )
    }
  }, [
    activeTab,
    quarterActive,
    yearActive,
    typeDataTime,
    typePeriod,
    monthActive,
    limitTimeLoading,
    locale,
  ])

  return (
    <SizeTracker>
      {(size) => (
        <div className="h-100 d-flex flex-column">
          <div className={`w-100 ${style.pb15px} d-flex`}>
            <QuarterSelectOption
              typeDataTime={typeDataTime}
              pageActive={activeTab}
            />
            <YearSelectOption typeDataTime={typeDataTime} />
          </div>
          {(loading || limitTimeLoading) && (
            <div style={{ flex: 1 }}>
              <Loading />
            </div>
          )}
          {!loading && !limitTimeLoading && (
            <>
              {rawData.length === 0 && (
                <div style={{ flex: 1 }}>
                  <NoData />
                </div>
              )}
              {rawData.length !== 0 && (
                <>
                  {typeof size.height === 'number' && (
                    <div
                      id={ID_DOM_PANEL_PRODUCTION.SECOND_CHART}
                      className={`${style.plr25px} h-100`}
                      style={{ height: `calc(100% - ${size.height}px)` }}
                    >
                      <ContentContribution
                        sizes={sizePanel}
                        tabActive={quarterActive}
                        pageActive={activeTab}
                        data={contributionData}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </SizeTracker>
  )
}

export default Contribution
