import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class CostOfOperationProxy extends ProxyBase {
  getRevenueCost(params) {
    return this.get('CostofOperation/RevenueCostOfOperation', params)
  }

  getCostStructure(params) {
    return this.get('CostofOperation/CostStructure', params)
  }

  getDetailMetrics(params) {
    return this.get('CostofOperation/DetailMetrics', params)
  }

  getDownloadDetailMetrics(params) {
    return this.get('CostofOperation/DownloadDetailMetrics', params, 'download')
  }
}

export default new CostOfOperationProxy(ServiceProxyConfig.Corporate.ServiceUrl)
