import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../common/table'
import TextEllipsis from '../../../common/textEllipsis'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import { TabContainer } from '../common/TabContainer'
import { NoData } from './../../../common/noData'
import TableCell from './TableCell'
import { associateAttr } from './constants'
import {
  changeIds,
  selectAssociateIds,
  selectAssociateValue,
  selectLoading,
  selectShowTable,
  sort,
} from './store/slice'
import { getAssociate } from './store/thunk'

const Associate = () => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const associateIds = useSelector(selectAssociateIds)
  const isLoading = useSelector(selectLoading)
  const showTable = useSelector(selectShowTable)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(getAssociate({ OrganizationId: basicInfo.organizationId }))
    }
  }, [basicInfo.organizationId, locale])

  if (!showTable && !isLoading) {
    return <NoData />
  }

  return (
    <TabContainer>
      <Table
        ids={associateIds}
        columnDraggable={false}
        getDataFromRedux={selectAssociateValue}
        stickyFirstColumn={true}
        isLargeHead={true}
        hasFooter={false}
        resizable={false}
        changeIds={changeIds}
        rowDraggable={false}
        hasTooltip={false}
        isLoading={isLoading}
        sort={sort}
        schema={Object.keys(associateAttr).map((key, index) => {
          const colId = associateAttr[key]
          const title = `corporate.ownership.associate.${key}`
          const result = {
            colId,
            title,
          }

          if (index === 1 || index === 2) {
            return {
              ...result,
              isI18n: false,
              thStyle: {
                textAlign: 'left',
                verticalAlign: 'top',
                textTransform: 'uppercase',
              },
              tdStyle: {
                textAlign: 'left',
              },
              render: (val) => {
                return <TableCell val={val} colId={index} />
              },
            }
          }

          if (index === 0) {
            return {
              ...result,
              isI18n: false,
              thStyle: {
                textAlign: 'left',
                verticalAlign: 'top',
                textTransform: 'uppercase',
              },
              tdStyle: {
                textAlign: 'left',
                whiteSpace: 'nowrap',
              },
              render: (val) => {
                return <TextEllipsis isI18n={false} text={val} />
              },
            }
          }

          if ([3, 4, 5].includes(index)) {
            return {
              ...result,
              isI18n: false,
              thStyle: {
                textAlign: 'right',
                verticalAlign: 'top',
              },
              tdStyle: {
                textAlign: 'right',
              },
              render: (val) => {
                return <TableCell val={val} colId={index} />
              },
            }
          }

          return {
            ...result,
            thStyle: {
              textAlign: 'right',
              verticalAlign: 'top',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => {
              return <TableCell val={val} colId={index} />
            },
          }
        })}
      />
    </TabContainer>
  )
}

export default Associate
