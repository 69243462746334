import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import Popup from '../../../../common/popup'
import SelectCustom from '../../../../common/selectCustom'
import { SAVE_TEMPLATE_RADIOS, SAVE_TEMPLATE_TYPES } from '../constants'
import style from './index.module.css'

const PopupSaveTemplate = ({
  handleClose,
  templates,
  handleSubmit,
  isShow,
  error,
  setError,
}) => {
  const locale = useSelector((state) => state.i18n.locale)

  const [type, setType] = useState(SAVE_TEMPLATE_TYPES.SAVE)
  const [templateId, setTemplateId] = useState(null)
  const [templateName, setTemplateName] = useState('')

  const placeholder = useMemo(
    () => I18n.t('bond.bondScreening.filter.PLACEHOLDER'),
    [locale],
  )

  const placeholderSelect = useMemo(
    () => I18n.t('bond.bondScreening.filter.PLACEHOLDER_SELECT'),
    [locale],
  )

  const handleChangeRadio = (value) => {
    setType(value)
    setTemplateName('')
  }

  const handleChangeSelect = (value) => {
    setTemplateId(value)
    setTemplateName(
      templates.find((item) => item.templateId === value)?.templateName ?? '',
    )
  }

  useEffect(() => {
    if (templateId != null) {
      setError('')
    }
  }, [templateId])

  useEffect(() => {
    setError('')
  }, [type])
  useEffect(() => {
    if (templateName) {
      setError('')
    }
  }, [templateName])

  return (
    <Popup>
      <div className="modal modal-small" style={{ width: 364 }}>
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="bond.bondScreening.filter.SAVE_TEMPLATE_TITLE" />
            </Span>
          </h3>
          <a onClick={handleClose} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content" style={{ padding: 0 }}>
          <div className="content" style={{ padding: 8 }}>
            <div className="flex-column" style={{ gap: 12 }}>
              {SAVE_TEMPLATE_RADIOS.map(({ value, name }) => {
                return (
                  <div key={value}>
                    <div
                      className="d-flex align-center cursor-pointer"
                      onClick={() => handleChangeRadio(value)}
                    >
                      <input
                        type="radio"
                        className="radiobox radiobox2"
                        checked={value === type}
                        readOnly={true}
                      />

                      <Span style={{ fontSize: 12, fontWeight: 340 }}>
                        <Translate value={name} />
                      </Span>
                    </div>
                    {value === SAVE_TEMPLATE_TYPES.SAVE && value === type && (
                      <div style={{ paddingLeft: 21, marginTop: 4 }}>
                        <Input
                          className={style.input}
                          placeholder={placeholder}
                          value={templateName}
                          onChange={(e) =>
                            setTemplateName(e?.target?.value ?? '')
                          }
                        />
                        {isShow && type === SAVE_TEMPLATE_TYPES.SAVE && (
                          <div style={{ marginTop: 2 }}>
                            <Span
                              style={{
                                fontStyle: 'italic',
                                fontWeight: 600,
                                color: '#ff2b66',
                              }}
                            >
                              <Translate value={error} />
                            </Span>
                          </div>
                        )}
                      </div>
                    )}

                    {value === SAVE_TEMPLATE_TYPES.UPDATE && (
                      <div style={{ paddingLeft: 21, marginTop: 4 }}>
                        {value === type && (
                          <SelectCustom
                            value={templateId}
                            selectData={
                              templates?.map((item) => ({
                                value: item.templateId,
                                name: item.templateName,
                              })) ?? []
                            }
                            handleChange={handleChangeSelect}
                            appendStyle={{ fontWeight: 340 }}
                            isDisable={!templates.length}
                            textPlaceholder={placeholderSelect}
                            isI18n
                          />
                        )}

                        {isShow && type === SAVE_TEMPLATE_TYPES.UPDATE && (
                          <div style={{ marginTop: 2 }}>
                            <Span
                              style={{
                                fontStyle: 'italic',
                                fontWeight: 600,
                                color: '#ff2b66',
                              }}
                            >
                              <Translate value={error} />
                            </Span>
                          </div>
                        )}

                        <div style={{ marginTop: 4 }}>
                          <Span
                            style={{
                              fontSize: 10,
                              fontStyle: 'italic',
                              opacity: 0.5,
                              fontWeight: 400,
                            }}
                          >
                            <Translate value="bond.bondScreening.filter.NOTE" />
                          </Span>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className={style.groupBtn}>
            <div className="d-flex ali-center" style={{ gap: 8 }}>
              <Button
                onClick={handleClose}
                className={`btn normal w-80 h-20`}
                style={{ fontWeight: 600 }}
              >
                <Translate value="common.button.BUTTON_CANCEL" />
              </Button>
              <Button
                onClick={() =>
                  handleSubmit({
                    name: templateName,
                    id: templateId,
                    type,
                  })
                }
                className={`btn btn-blue w-80 h-20 `}
                style={{ fontWeight: 600 }}
              >
                <Translate value="common.button.BUTTON_SAVE" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupSaveTemplate
