import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import AverageCouponBySector from './averageCouponBySector'
import AverageDurationBySector from './averageDurationBySector'
import IssuanceValue from './issuanceValue'
import IssuanceValueByCouponType from './issuanceValueByCoupon'
import IssuanceValueBySector from './issuanceValueBySector'

const MAP_KEY = {
  ISSUANCE_VALUE: 'ISSUANCE_VALUE',
  AVERAGE_COUPON_BY_SECTOR: 'AVERAGE_COUPON_BY_SECTOR',
  ISSUANCE_VALUE_BY_SECTOR: 'ISSUANCE_VALUE_BY_SECTOR',
  ISSUANCE_VALUE_BY_COUPON: 'ISSUANCE_VALUE_BY_COUPON',
  AVERAGE_DURATION_BY_SECTOR: 'AVERAGE_DURATION_BY_SECTOR',
}

const MAP_SIZE = {
  [`${MAP_KEY.ISSUANCE_VALUE}`]: {
    width: `calc(100% * 2 / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 2 - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.AVERAGE_COUPON_BY_SECTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.AVERAGE_COUPON_BY_SECTOR,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.ISSUANCE_VALUE_BY_SECTOR,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.ISSUANCE_VALUE_BY_COUPON,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.AVERAGE_DURATION_BY_SECTOR,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.AVERAGE_COUPON_BY_SECTOR}`]: {
    width: `calc(100% / 3)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 'calc(100% * 2 / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.ISSUANCE_VALUE_BY_SECTOR}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.ISSUANCE_VALUE_BY_COUPON,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.ISSUANCE_VALUE_BY_COUPON}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 'calc(100% / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    verticalResize: [
      {
        key: MAP_KEY.AVERAGE_DURATION_BY_SECTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.AVERAGE_DURATION_BY_SECTOR}`]: {
    width: `calc(100% / 3)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 'calc(100% * 2 / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <IssuanceValue
        panelRefs={panelRefs}
        panelKey={MAP_KEY.ISSUANCE_VALUE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <AverageCouponBySector
        panelRefs={panelRefs}
        panelKey={MAP_KEY.AVERAGE_COUPON_BY_SECTOR}
        sizes={sizes}
        setSizes={setSizes}
      />
      <IssuanceValueBySector
        panelRefs={panelRefs}
        panelKey={MAP_KEY.ISSUANCE_VALUE_BY_SECTOR}
        sizes={sizes}
        setSizes={setSizes}
      />
      <IssuanceValueByCouponType
        panelRefs={panelRefs}
        panelKey={MAP_KEY.ISSUANCE_VALUE_BY_COUPON}
        sizes={sizes}
        setSizes={setSizes}
      />
      <AverageDurationBySector
        panelRefs={panelRefs}
        panelKey={MAP_KEY.AVERAGE_DURATION_BY_SECTOR}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
