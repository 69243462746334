import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { MARGIN_RECHARTS } from '../../../../../common/chart/constants'
import { StackedBarLineChart } from '../../../../../common/chart/customCharts/StackedBarLineChart'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { COLOR_STRUCTURE_CHART, FOOTER_NAME_BARKEYS } from './constants'

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 85 }
const MARGIN_ONLY_BAR_CHART = {
  top: 20,
  left: 70,
  right: 50,
}

export const ValueInvestor = ({
  width,
  height,
  dataChart,
  isLoading,
  filterData,
  getNetValueByInvestorChartTicker,
  getNetValueByInvestorChartOverview,
  notIncludeTicker,
}) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)

  const barKeys = [
    'localInstitution',
    'prorprietary',
    'foreign',
    'localIndividual',
  ]
  const lineKeys =
    filterData?.currentCWId && !notIncludeTicker ? ['closePriceAdjusted'] : []

  const allKeys = [...barKeys, ...lineKeys]
  const fillColor = {}
  const mappingDisplayName = {}

  for (const key of allKeys) {
    if (notIncludeTicker) {
      fillColor[key] = COLOR_STRUCTURE_CHART[barKeys.indexOf(key)]
      mappingDisplayName[key] = FOOTER_NAME_BARKEYS[barKeys.indexOf(key)]
    } else {
      fillColor[key] = COLOR_STRUCTURE_CHART[allKeys.indexOf(key)]
      mappingDisplayName[key] = barKeys.includes(key)
        ? I18n.t(FOOTER_NAME_BARKEYS[barKeys.indexOf(key)])
        : filterData?.currentCW.name
    }
  }

  const [listCheckbox, setListCheckbox] = useState(allKeys)

  useEffect(() => {
    if (filterData?.currentCWId && !notIncludeTicker) {
      dispatch(
        getNetValueByInvestorChartTicker({
          CoveredWarrantId: filterData?.currentCWId,
        }),
      )
    } else {
      dispatch(getNetValueByInvestorChartOverview({}))
    }
  }, [locale, filterData?.currentCWId])

  useEffect(() => {
    if (notIncludeTicker) {
      dispatch(getNetValueByInvestorChartOverview({}))
    }
  }, [locale])

  return isLoading ? (
    <Loading />
  ) : !dataChart.length ? (
    <NoData />
  ) : (
    <div id="valueInvestor">
      <StackedBarLineChart
        data={dataChart}
        width={width}
        height={height}
        keyXAxis={'time'}
        barKeys={barKeys}
        lineKeys={lineKeys}
        decimalLength={2}
        leftLabel={I18n.t('market.coveredWarrant.cwStatistics.VAL_BILLION')}
        rightLabel={
          filterData?.currentCWId && !notIncludeTicker
            ? I18n.t('market.coveredWarrant.cwStatistics.PRICE_THOUSAND')
            : ''
        }
        fillColor={fillColor}
        mappingDisplayName={mappingDisplayName}
        margin={
          filterData?.currentCWId && !notIncludeTicker
            ? MARGIN
            : MARGIN_ONLY_BAR_CHART
        }
        timeFrame={TIME_RANGES['1W_DATE']}
        isFooterCheckbox
        unit={I18n.t('market.coveredWarrant.cwStatistics.BILLION')}
        lineUnit={I18n.t('market.coveredWarrant.cwStatistics.THOUSAND_VND')}
        listCheckbox={listCheckbox}
        setListCheckbox={setListCheckbox}
        valueKeysColor={allKeys}
      />
    </div>
  )
}
