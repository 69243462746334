import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class FundCenterProxy extends ProxyBase {
  getTopNetAssetValue(params) {
    return this.get('TopNetAssetValue', params)
  }

  getFundsOverview12Months(params) {
    return this.get('FundsOverview12Months', params)
  }

  getListNAVCertificate(params) {
    return this.get('GetListNAVCertificate', params)
  }

  getListFundFlow(params) {
    return this.get('GetListFundFlow', params)
  }
}

export default new FundCenterProxy(
  ServiceProxyConfig.Fund.FundCenter.FundOverview.ServiceUrl,
)
