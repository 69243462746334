export const tableConstants = [
  {
    id: 'NB38',
    name: 'sector.financialAnalysis.bank.assetQuality.TRADING',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB38',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'NB39',
    name: 'sector.financialAnalysis.bank.assetQuality.AGRICULTURE_FORESTRY',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB39',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'NB40',
    name: 'sector.financialAnalysis.bank.assetQuality.MANUFACTURING',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB40',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'NB45',
    name: 'sector.financialAnalysis.bank.assetQuality.CONSTRUCTION',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB45',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'NB46',
    name: 'sector.financialAnalysis.bank.assetQuality.PERSONAL_PUBLIC_SERVICE',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB46',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'NB55',
    name: 'sector.financialAnalysis.bank.assetQuality.WAREHOUSING',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB55',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'NB58',
    name: 'sector.financialAnalysis.bank.assetQuality.TRAINING_EDUCATION',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB58',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'NB61',
    name: 'sector.financialAnalysis.bank.assetQuality.ESTATE_AGENT',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB61',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'NB62',
    name: 'sector.financialAnalysis.bank.assetQuality.HOTEL_RESTAURANT',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB62',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'NB63',
    name: 'sector.financialAnalysis.bank.assetQuality.FINANCIAL_SERVICE',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB63',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'NB64',
    name: 'sector.financialAnalysis.bank.assetQuality.OTHER',
    isYoyGrowth: false,
  },
  {
    id: 'GrowthNB64',
    name: 'sector.financialAnalysis.bank.assetQuality.YOY_GROWTH',
    isYoyGrowth: true,
  },
]

export const tableFormat = {
  NB38: 10 ** 9,
  GrowthNB38: 1,
  NB39: 10 ** 9,
  GrowthNB39: 1,
  NB40: 10 ** 9,
  GrowthNB40: 1,
  NB45: 10 ** 9,
  GrowthNB45: 1,
  NB46: 10 ** 9,
  GrowthNB46: 1,
  NB55: 10 ** 9,
  GrowthNB55: 1,
  NB58: 10 ** 9,
  GrowthNB58: 1,
  NB61: 10 ** 9,
  GrowthNB61: 1,
  NB62: 10 ** 9,
  GrowthNB62: 1,
  NB63: 10 ** 9,
  GrowthNB63: 1,
  NB64: 10 ** 9,
  GrowthNB64: 1,
}
