import SmartScreeningProxy from '../../proxies/tool/SmartScreeningProxy'
import { ServiceBase } from '../ServiceBase'

class SmartScreeningService extends ServiceBase {
  getQuickSelectIndicators(params) {
    return this.applyMemoryCache(
      'SmartScreeningService.getQuickSelectIndicators',
      params,
    )(() => SmartScreeningProxy.getQuickSelectIndicators(params))
  }

  getRatioIndicators(params) {
    return this.applyMemoryCache(
      'SmartScreeningService.getRatioIndicators',
      params,
    )(() => SmartScreeningProxy.getRatioIndicators(params))
  }

  getFinancialStatementIndicators(params) {
    return this.applyMemoryCache(
      'SmartScreeningService.getFinancialStatementIndicators',
      params,
    )(() => SmartScreeningProxy.getFinancialStatementIndicators(params))
  }

  getTemplates(params) {
    return this.getData(() => SmartScreeningProxy.getTemplates(params))
  }

  renameTemplate(params) {
    return this.getData(() => SmartScreeningProxy.renameTemplate(params))
  }

  getScreeningDate(params) {
    return this.getData(() => SmartScreeningProxy.getScreeningDate(params))
  }

  getTemplate(params) {
    return this.getData(() => SmartScreeningProxy.getTemplate(params))
  }

  getTypeInterim(params) {
    return this.applyMemoryCache(
      'SmartScreeningService.getTypeInterim',
      params,
    )(() => SmartScreeningProxy.getTypeInterim(params))
  }

  getResult(params) {
    return this.getData(() => SmartScreeningProxy.getResult(params))
  }

  getCount(params) {
    return this.getData(() => SmartScreeningProxy.getCount(params))
  }

  saveTemplate(params) {
    return this.getData(() => SmartScreeningProxy.saveTemplate(params))
  }

  updateTemplate(params) {
    return this.getData(() => SmartScreeningProxy.updateTemplate(params))
  }

  deleteTemplate(params) {
    return this.getData(() => SmartScreeningProxy.deleteTemplate(params))
  }

  exportResult(params) {
    return this.getFileDownload(() => SmartScreeningProxy.exportResult(params))
  }

  saveWorkSpace(params) {
    return this.getData(() => SmartScreeningProxy.saveWorkSpace(params))
  }
}

export default new SmartScreeningService()
