import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

class SegmentationProxy extends ProxyBase {
  getIndicators(params) {
    return this.get('Indicators', params)
  }

  getPointInTime(params) {
    return this.post('PointInTime', params)
  }

  getTimeSeries(params) {
    return this.post('TimeSeries', params)
  }

  getGeneralData(params) {
    return this.post('GeneralData', params)
  }

  getTemplates(params) {
    return this.get('Templates', params)
  }

  getTemplateDetail(params) {
    return this.get('TemplateDetail', params)
  }

  getTemplateByShareId(params) {
    return this.get('Templates/ByShareId', params)
  }

  addTemplate(params) {
    return this.post('Templates', params)
  }

  updateTemplate(params) {
    return this.put('Templates', params)
  }

  deleteTemplate(params) {
    return this.delete('Templates', params)
  }
}

export default new SegmentationProxy(
  ServiceProxyConfig.Sector.SectorConstituents.Segmentation.ServiceUrl,
)
