import PropsType from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../../common/selectCustom'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'
import {
  changeMonthActive,
  getQuarterActive,
  selectListTimeQuarter,
  selectMonthActive,
  selectQuarterActive,
} from '../store/slice'

const { YEARLY, MONTHLY } = PRICE_DATA_TIME_SELECT

export const QuarterSelectOption = ({ typeDataTime }) => {
  const dispatch = useDispatch()
  const quarterActive = useSelector(selectQuarterActive)
  const monthActive = useSelector(selectMonthActive)

  const listTimeQuarter = useSelector(selectListTimeQuarter)

  const onChangeQuarter = (val) => {
    dispatch(getQuarterActive(val))
  }

  const onChangeMonth = (val) => {
    dispatch(changeMonthActive(val))
  }

  if (typeDataTime === YEARLY) {
    return <></>
  }

  return (
    <div className={'w-80'}>
      {typeDataTime === MONTHLY ? (
        <SelectCustom
          isI18n
          value={monthActive}
          selectData={listTimeQuarter.map((month) => ({
            name: month.name,
            value: month.value,
          }))}
          handleChange={onChangeMonth}
        />
      ) : (
        <SelectCustom
          isI18n
          value={quarterActive}
          selectData={listTimeQuarter.map((quarter) => ({
            name: quarter.name,
            value: quarter.value,
          }))}
          handleChange={onChangeQuarter}
        />
      )}
    </div>
  )
}

QuarterSelectOption.propTypes = {
  typeDataTime: PropsType.string,
}
