import { createSlice } from '@reduxjs/toolkit'
import { Translate } from 'react-redux-i18n'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import { register } from '../../../../utils/ReducerRegistry'
import { changeValueWithDot } from '../../../../utils/Value'

const valByKeyWithDot = (data, keyWithDot) => {
  return keyWithDot.split('.').reduce((result, key) => {
    return result?.[key] === undefined || result?.[key] === null
      ? ''
      : result?.[key]
  }, data)
}

const initialState = {
  popupTemplate: {
    ticker: [],
    tickerById: {},
    detail: {},
  },
  customParams: {},
}

export const slice = createSlice({
  name: 'dataExplorer/popupTemplate',
  initialState,
  reducers: {
    resetPopupTemplate(state) {
      state.popupTemplate = initialState.popupTemplate
    },
    addTickerPopupTemplate(state, action) {
      let everyExist = true
      action.payload.forEach((ticker) => {
        if (!state.popupTemplate.tickerById[ticker.organizationId]) {
          state.popupTemplate.ticker.push(ticker.organizationId)
          state.popupTemplate.tickerById[ticker.organizationId] = {
            check: false,
            ...ticker,
          }
          everyExist = false
        }
      })
      if (everyExist) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          <Translate value="tool.dataExplorer.corporate.EXIST_TICKER" />,
        ])
      }
    },
    checkAllPopupTemplate(state) {
      state.popupTemplate.ticker.forEach(
        (id) => (state.popupTemplate.tickerById[id].check = true),
      )
    },
    uncheckAllPopupTemplate(state) {
      state.popupTemplate.ticker.forEach(
        (id) => (state.popupTemplate.tickerById[id].check = false),
      )
    },
    checkPopupTemplate(state, action) {
      state.popupTemplate.tickerById[action.payload].check = true
    },
    uncheckPopupTemplate(state, action) {
      state.popupTemplate.tickerById[action.payload].check = false
    },
    removeTickerPopupTemplate(state) {
      const newTicker = []
      const newTickerById = { ...state.popupTemplate.tickerById }
      state.popupTemplate.ticker.forEach((id) => {
        if (newTickerById[id].check) {
          delete newTickerById[id]
        } else {
          newTicker.push(id)
        }
      })
      state.popupTemplate.ticker = newTicker
      state.popupTemplate.tickerById = newTickerById
    },
    changeValueDetailPopupTemplate(state, action) {
      const { key, value } = action.payload
      state.popupTemplate.detail = changeValueWithDot(
        state.popupTemplate.detail,
        key,
        value,
      )
    },
    changeCustomParams(state, action) {
      const { key, value } = action.payload
      state.customParams[key] = value
    },
  },
})

export const {
  resetPopupTemplate,
  checkAllPopupTemplate,
  uncheckAllPopupTemplate,
  checkPopupTemplate,
  uncheckPopupTemplate,
  removeTickerPopupTemplate,
  changeValueDetailPopupTemplate,
  changeCustomParams,
  addTickerPopupTemplate,
} = slice.actions

export const selectTickerPopupTemplate = (state) =>
  state[slice.name].popupTemplate.ticker
export const selectTickerByIdPopupTemplate = (state) =>
  state[slice.name].popupTemplate.tickerById
export const selectTickerPopupTemplateCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].popupTemplate.tickerById[id], attr)
export const selectValueDetailPopupTemplate = (key) => (state) =>
  valByKeyWithDot(state[slice.name].popupTemplate.detail, key)
export const selectPopupTemplate = (state) => state[slice.name].popupTemplate
export const selectCustomParams = (state) => state[slice.name].customParams

register(slice.name, slice.reducer)
