import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import Dropdown from '../../../../common/dropdown'
import UseI18n from '../../../../common/hooks/useI18n'
import { Input } from '../../../../common/html/Input'
import { Loading } from '../../../../common/loading'
import Tree from '../../../../common/tree'
import { getAllParentChecked } from '../../../../common/tree/helper'
import { Z_INDEX } from '../../../../constants/Common'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../../utils/EventEmitter'
import style from '../../index.module.css'
import {
  changeCheckedExchange,
  changeParentCheckedExchange,
} from '../../store/slice'
import NameChecked from './NameChecked'

const DropdownExchange = ({
  exchanges,
  exchangesTree,
  listChecked,
  listParentChecked,
  loading,
}) => {
  const dispatch = useDispatch()

  const parentRef = useRef()

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [valueSearch, setValueSearch] = useState('')

  const handleChangeInput = (e) => setValueSearch(e.target.value)

  const handleDropdown = () => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setIsShowDropdown(!isShowDropdown)
  }

  const handleHideDropdown = () => setIsShowDropdown(false)

  const handleChangeExchangeChecked = (values) => {
    const parentExchanges = Object.values(exchangesTree).map(
      (item) => item.groupId,
    )
    const parentChecked = getAllParentChecked(exchangesTree, 'groupId', values)
    const listChildChecked = values.filter(
      (id) => !(parentExchanges.includes(id) && !parentChecked.includes(id)),
    )
    dispatch(changeCheckedExchange(listChildChecked))
  }

  const handleChangeListParentChecked = (values) => {
    dispatch(changeParentCheckedExchange(values))
  }

  const placeHolder = UseI18n('common.SEARCH')

  return (
    <>
      <span
        ref={parentRef}
        onClick={handleDropdown}
        className={`cursor-pointer h-20 ${style['span-input']}`}
        style={{ borderRadius: isShowDropdown ? '4px 4px 0px 0px' : undefined }}
      >
        <NameChecked
          exchanges={exchanges}
          listParentChecked={listParentChecked}
          exchangeTreeLength={Object.keys(exchangesTree).length}
        />{' '}
        <i className={!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown isShow={true} parentRef={parentRef}>
        <HandleClickOutside
          handleClickOutside={handleHideDropdown}
          exceptRef={parentRef}
        >
          <div
            className={`wrapper-tool ${style.mt4}`}
            style={{
              width: `${parentRef.current?.offsetWidth}px`,
              display: isShowDropdown ? 'block' : 'none',
            }}
          >
            <div className="input-dropdown mb-8">
              <div
                className={`dropdown-container active w-100 ${style.filterDropdown}`}
              >
                <div className={`form-search-inbox m-0 ${style.pb5}`}>
                  <Input
                    type="text"
                    className={`search-inbox bg-grey h-20 ${style.search}`}
                    placeholder={placeHolder}
                    value={valueSearch}
                    onChange={handleChangeInput}
                  />
                  <button className={style.btnSearch} type="button">
                    <i className="icon-search-2" />
                  </button>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <Tree
                    valueSearch={valueSearch}
                    treeData={exchangesTree}
                    idKey="groupId"
                    nameKey="groupName"
                    listChecked={listChecked}
                    setListChecked={handleChangeExchangeChecked}
                    setListParentChecked={handleChangeListParentChecked}
                    titleCheckboxAll="tool.smartScreening.ALL_EXCHANGE"
                    isHaveCheckboxAll={true}
                    isHaveCheckbox={true}
                    isDefaultCheckAll={false}
                    isActiveSelected={false}
                    autoHeight={true}
                    autoHeightMin={160}
                    autoHeightMax={240}
                    sortKey="groupOrder"
                    isCheckedWhenClickText
                    zIndexTextEllipsis={Z_INDEX.DROPDOWN + 1}
                    isCheckAllByValueSearch={true}
                  />
                )}
              </div>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </>
  )
}

export default DropdownExchange
