export const GROUP_COLUMNS_OVERVIEW = [
  {
    key: 'tradingDateId',
    title: 'market.derivative.DATE',
  },
  {
    key: 'unmatchedBuyVolume',
    title: 'market.derivative.UNMATCHED_BUY',
  },
  {
    key: 'unmatchedSellVolume',
    title: 'market.derivative.UNMATCHED_SELL',
  },
  {
    key: 'buyOrders',
    title: 'market.derivative.BUY_ORDERS',
  },
  {
    key: 'buyVolume',
    title: 'market.derivative.BUY_VOLUME',
  },
  {
    key: 'avgBuyVolumePerOrder',
    title: 'market.derivative.AVG_BUY',
  },
  {
    key: 'sellOrders',
    title: 'market.derivative.SELL_ORDERS',
  },
  {
    key: 'sellVolume',
    title: 'market.derivative.SELL_VOLUME',
  },
  {
    key: 'avgSellVolumePerOrder',
    title: 'market.derivative.AVG_SELL',
  },
  {
    key: 'netBuySellVolume',
    title: 'market.derivative.NET_BUY_SELL',
  },
]

export const GROUP_COLUMNS_DETAIL = [
  {
    key: 'tradingDateId',
    title: 'market.derivative.DATE',
  },
  {
    key: 'ticker',
    title: 'market.derivative.TICKER',
  },
  {
    key: 'unmatchedBuyVolume',
    title: 'market.derivative.UNMATCHED_BUY',
  },
  {
    key: 'unmatchedSellVolume',
    title: 'market.derivative.UNMATCHED_SELL',
  },
  {
    key: 'buyOrders',
    title: 'market.derivative.BUY_ORDERS',
  },
  {
    key: 'buyVolume',
    title: 'market.derivative.BUY_VOLUME',
  },
  {
    key: 'avgBuyVolumePerOrder',
    title: 'market.derivative.AVG_BUY',
  },
  {
    key: 'sellOrders',
    title: 'market.derivative.SELL_ORDERS',
  },
  {
    key: 'sellVolume',
    title: 'market.derivative.SELL_VOLUME',
  },
  {
    key: 'avgSellVolumePerOrder',
    title: 'market.derivative.AVG_SELL',
  },
  {
    key: 'netBuySellVolume',
    title: 'market.derivative.NET_BUY_SELL',
  },
]

export const TABLE_TYPE = {
  OVERVIEW: 'overview',
  DETAIL: 'detail',
  TICKER: 'ticker',
}

export const LIST_RADIO = [
  {
    label: 'market.derivative.OVERVIEW',
    value: TABLE_TYPE.OVERVIEW,
  },
  {
    label: 'market.derivative.DETAIL',
    value: TABLE_TYPE.DETAIL,
  },
]
