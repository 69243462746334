import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PriceBoardService from '../../../../../../core/services/bond/corporateBond/secondaryMarket/PriceBoardService'
import StatisticService from '../../../../../../core/services/bond/corporateBond/secondaryMarket/StatisticService'
import InfoSec from '../../../../../../core/services/bond/valuation/infoSec'
import InfoValuation from '../../../../../../core/services/bond/valuation/infoValuation'
import SimpleSearchService from '../../../../../../core/services/common/SimpleSearchService'
import { handleExport } from '../../../../../utils/Export'
import { MARKET_STATISTIC } from '../constants'

export const getTradingStatistics = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/trading/GET_TRADING_STATISTIC',
  async (params, { rejectWithValue }) => {
    try {
      const { ...restParams } = params

      const response = await StatisticService.getTradingStatistics(restParams)

      if (response.success) {
        return { ...response }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const downloadStatisticInformationData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/DOWNLOAD_STATISTIC_INFORMATION',
  async (data) => {
    const response = await StatisticService.downloadStatisticInformation(data)
    handleExport(response.data, response.filename)
  },
)
export const getLiquidityData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_LIQUIDITY',
  async (params, { rejectWithValue }) => {
    try {
      const response = await StatisticService.getLiquidityData(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getValueChartData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_VALUE_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response = await StatisticService.getValueChartData(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getPriceBoardData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_PRICE_BOARD',
  async (params, { rejectWithValue }) => {
    try {
      const { type, ...restParams } = params

      const response =
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? await PriceBoardService.getPriceBoardMatched(restParams)
          : await PriceBoardService.getPriceBoardDeal(restParams)

      if (response.success) {
        return { ...response, type }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const fetchMorePriceBoardData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/FETCH_MORE_PRICE_BOARD',
  async (params, { rejectWithValue }) => {
    try {
      const { type, ...restParams } = params

      const response =
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? await PriceBoardService.getPriceBoardMatched(restParams)
          : await PriceBoardService.getPriceBoardDeal(restParams)

      if (response.success) {
        return { ...response, type }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondListData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_BOND_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchBond(params)

      if (response.success) {
        return { data: response.data, hasNextPage: response.hasNextPage }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const fetchMoreBondListData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/FETCH_MORE_BOND_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchBond(params)

      if (response.success) {
        return { data: response.data, hasNextPage: response.hasNextPage }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondDetailData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_BOND_DETAIL',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InfoSec.getBondInfo(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondCashFlowScenarioData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_BOND_CASH_FLOW_SCENARIO',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InfoSec.getBondCashFlowScenarioApi(params)

      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getPriceYtmData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_PRICE_YTM',
  async (params, { rejectWithValue }) => {
    try {
      const { type, ...restParams } = params

      const response = await InfoValuation.getPriceBond(restParams)

      if (response.success) {
        return { data: response.data, type }
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getListCompany = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/getListCompany',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchCompany(data)
      if (response.success) {
        const data = response?.data
          ? response?.data.map((e) => ({
              value: e?.organizationId || '',
              label1: e?.tickerOrTaxCode || '',
              label2: e?.organizationShortName || '',
            }))
          : []
        return data
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const fetchListCompany = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/fetchMoreListCompany',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchCompany(params)
      if (response.success) {
        const data = response?.data
          ? response?.data.map((e) => ({
              value: e?.organizationId || '',
              label1: e?.tickerOrTaxCode || '',
              label2: e?.organizationShortName || '',
            }))
          : []
        return { data, page: params.page }
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
