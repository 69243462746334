import { TYPE } from '../../../../common/chart/footer/Item'
import { STACK_COLORS, YEAR_OPTIONS } from '../../constants'

export const getTooltipContentData = (data, stackKey, GDPTypeName) => {
  return stackKey?.map((item) => {
    const indicator = GDPTypeName.find(
      (element) => element.nationalAccountVNTypeId === item,
    )
    return {
      label: indicator?.nationalAccountVNTypeName || '',
      value: data[item],
    }
  })
}

export const renderListFooter = (stackKey, GDPTypeName) => {
  const MAX_STACK = 6
  const dataLength = stackKey.length > MAX_STACK ? MAX_STACK : stackKey.length
  return stackKey.map((item, index) => {
    const indicator = GDPTypeName.find(
      (element) => element.nationalAccountVNTypeId === item,
    )
    return {
      text: indicator?.nationalAccountVNTypeName || '',
      type: TYPE.SQUARE,
      before: {
        bgColor: STACK_COLORS?.[dataLength]?.[index],
      },
      isI18n: false,
    }
  })
}

export const handleStackedChartData = (payload) => {
  if (!payload.length) {
    return []
  }

  const years = [...new Set(payload.map((item) => item.year))]
  return years.map((year) => {
    const dataWithYear = payload.filter((item) => item.year === year)

    return dataWithYear.reduce((previous, current) => {
      return {
        ...previous,
        [current.nationalAccountVNTypeId]: current.value * 100,
        year,
      }
    }, {})
  })
}

export const getStackKey = (payload) => {
  if (!payload.length) {
    return []
  }

  return [...new Set(payload.map((item) => item.nationalAccountVNTypeId))]
}

export const handleRangeYear = (payload) => {
  if (!Object.keys(payload).length) {
    return YEAR_OPTIONS
  }

  const { minYear, maxYear } = payload
  const range = maxYear - minYear + 1
  return [...Array(range).keys()].map((item) => {
    return {
      name: String(maxYear - item),
      value: String(maxYear - item),
    }
  })
}
