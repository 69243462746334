import { ChannelConfig } from '../../configs/Global'

const mapStock = {
  0: { key: 'totalMatchVolume', type: 'number' },
  1: { key: 'marketStatus', type: 'string' },
  2: { key: 'tradingDate', type: 'string' },
  3: { key: 'matchType', type: 'string' },
  4: { key: 'comGroupCode', type: 'string' },
  5: { key: 'organizationId', type: 'string' },
  6: { key: 'ticker', type: 'string' },
  7: { key: 'referencePrice', type: 'number' },
  8: { key: 'openPrice', type: 'number' },
  9: { key: 'closePrice', type: 'number' },
  10: { key: 'ceilingPrice', type: 'number' },
  11: { key: 'floorPrice', type: 'number' },
  12: { key: 'highPrice', type: 'number' },
  13: { key: 'lowPrice', type: 'number' },
  14: { key: 'matchPrice', type: 'number' },
  15: { key: 'priceChange', type: 'number' },
  16: { key: 'percentPriceChange', type: 'number' },
  17: { key: 'matchVolume', type: 'number' },
  18: { key: 'matchValue', type: 'number' },
  19: { key: 'totalMatchValue', type: 'number' },
  20: { key: 'totalBuyTradeVolume', type: 'number' },
  21: { key: 'totalSellTradeVolume', type: 'number' },
  22: { key: 'dealPrice', type: 'number' },
  23: { key: 'totalDealVolume', type: 'number' },
  24: { key: 'totalDealValue', type: 'number' },
  25: { key: 'foreignBuyVolumeTotal', type: 'number' },
  26: { key: 'foreignBuyValueTotal', type: 'number' },
  27: { key: 'foreignSellVolumeTotal', type: 'number' },
  28: { key: 'foreignSellValueTotal', type: 'number' },
  29: { key: 'foreignTotalRoom', type: 'number' },
  30: { key: 'foreignCurrentRoom', type: 'number' },
}

const mapIndex = {
  0: { key: 'groupId', type: 'string' },
  1: { key: 'totalMatchVolume', type: 'number' },
  2: { key: 'marketStatus', type: 'string' },
  3: { key: 'tradingDate', type: 'string' },
  4: { key: 'index', type: 'string' },
  5: { key: 'referencePrice', type: 'number' }, // referenceIndex
  6: { key: 'openPrice', type: 'number' }, // openIndex
  7: { key: 'closeIndex', type: 'number' },
  8: { key: 'highPrice', type: 'number' }, // highIndex
  9: { key: 'lowPrice', type: 'number' }, // lowIndex
  10: { key: 'matchPrice', type: 'number' }, // indexValue
  11: { key: 'priceChange', type: 'number' }, // indexChange
  12: { key: 'percentPriceChange', type: 'number' }, // percentIndexChange
  13: { key: 'matchVolume', type: 'number' },
  14: { key: 'matchValue', type: 'number' },
  15: { key: 'totalMatchValue', type: 'number' },
  16: { key: 'totalDealVolume', type: 'number' },
  17: { key: 'totalDealValue', type: 'number' },
  18: { key: 'totalStockUpPrice', type: 'number' },
  19: { key: 'totalStockDownPrice', type: 'number' },
  20: { key: 'totalStockNoChangePrice', type: 'number' },
  21: { key: 'totalStockOverCeiling', type: 'number' },
  22: { key: 'totalStockUnderFloor', type: 'number' },
  23: { key: 'foreignBuyVolumeTotal', type: 'number' },
  24: { key: 'foreignBuyValueTotal', type: 'number' },
  25: { key: 'foreignSellVolumeTotal', type: 'number' },
  26: { key: 'foreignSellValueTotal', type: 'number' },
}

const mapBidAsk = {
  0: { key: 'comGroupCode', type: 'string' },
  1: { key: 'organizationId', type: 'string' },
  2: { key: 'tradingDate', type: 'string' },
  3: { key: 'best1Bid', type: 'number' },
  4: { key: 'best1BidVolume', type: 'number' },
  5: { key: 'best2Bid', type: 'number' },
  6: { key: 'best2BidVolume', type: 'number' },
  7: { key: 'best3Bid', type: 'number' },
  8: { key: 'best3BidVolume', type: 'number' },
  9: { key: 'best4Bid', type: 'number' },
  10: { key: 'best4BidVolume', type: 'number' },
  11: { key: 'best5Bid', type: 'number' },
  12: { key: 'best5BidVolume', type: 'number' },
  13: { key: 'best6Bid', type: 'number' },
  14: { key: 'best6BidVolume', type: 'number' },
  15: { key: 'best7Bid', type: 'number' },
  16: { key: 'best7BidVolume', type: 'number' },
  17: { key: 'best8Bid', type: 'number' },
  18: { key: 'best8BidVolume', type: 'number' },
  19: { key: 'best9Bid', type: 'number' },
  20: { key: 'best9BidVolume', type: 'number' },
  21: { key: 'best10Bid', type: 'number' },
  22: { key: 'best10BidVolume', type: 'number' },
  23: { key: 'best11Bid', type: 'number' },
  24: { key: 'best11BidVolume', type: 'number' },
  25: { key: 'best12Bid', type: 'number' },
  26: { key: 'best12BidVolume', type: 'number' },
  27: { key: 'best13Bid', type: 'number' },
  28: { key: 'best13BidVolume', type: 'number' },
  29: { key: 'best14Bid', type: 'number' },
  30: { key: 'best14BidVolume', type: 'number' },
  31: { key: 'best15Bid', type: 'number' },
  32: { key: 'best15BidVolume', type: 'number' },
  33: { key: 'best1Offer', type: 'number' },
  34: { key: 'best1OfferVolume', type: 'number' },
  35: { key: 'best2Offer', type: 'number' },
  36: { key: 'best2OfferVolume', type: 'number' },
  37: { key: 'best3Offer', type: 'number' },
  38: { key: 'best3OfferVolume', type: 'number' },
  39: { key: 'best4Offer', type: 'number' },
  40: { key: 'best4OfferVolume', type: 'number' },
  41: { key: 'best5Offer', type: 'number' },
  42: { key: 'best5OfferVolume', type: 'number' },
  43: { key: 'best6Offer', type: 'number' },
  44: { key: 'best6OfferVolume', type: 'number' },
  45: { key: 'best7Offer', type: 'number' },
  46: { key: 'best7OfferVolume', type: 'number' },
  47: { key: 'best8Offer', type: 'number' },
  48: { key: 'best8OfferVolume', type: 'number' },
  49: { key: 'best9Offer', type: 'number' },
  50: { key: 'best9OfferVolume', type: 'number' },
  51: { key: 'best10Offer', type: 'number' },
  52: { key: 'best10OfferVolume', type: 'number' },
  53: { key: 'best11Offer', type: 'number' },
  54: { key: 'best11OfferVolume', type: 'number' },
  55: { key: 'best12Offer', type: 'number' },
  56: { key: 'best12OfferVolume', type: 'number' },
  57: { key: 'best13Offer', type: 'number' },
  58: { key: 'best13OfferVolume', type: 'number' },
  59: { key: 'best14Offer', type: 'number' },
  60: { key: 'best14OfferVolume', type: 'number' },
  61: { key: 'best15Offer', type: 'number' },
  62: { key: 'best15OfferVolume', type: 'number' },
}

const mapSector = {
  0: { key: 'icbId', type: 'string' },
  1: { key: 'groupId', type: 'string' },
  2: { key: 'icbCode', type: 'string' },
  3: { key: 'comGroupCode', type: 'string' },
  4: { key: 'tradingDate', type: 'string' },
  5: { key: 'closeIndex', type: 'number' },
  6: { key: 'referenceIndex', type: 'number' },
  7: { key: 'openIndex', type: 'number' },
  8: { key: 'indexChange', type: 'number' },
  9: { key: 'percentIndexChange', type: 'number' },
  10: { key: 'matchVolume', type: 'number' },
  11: { key: 'matchValue', type: 'number' },
  12: { key: 'totalMatchVolume', type: 'number' },
  13: { key: 'totalMatchValue', type: 'number' },
  14: { key: 'totalDealVolume', type: 'number' },
  15: { key: 'totalDealValue', type: 'number' },
  16: { key: 'totalVolume', type: 'number' },
  17: { key: 'totalValue', type: 'number' },
  18: { key: 'dealVolume', type: 'number' },
  19: { key: 'dealValue', type: 'number' },
  20: { key: 'foreignBuyVolumeTotal', type: 'number' },
  21: { key: 'foreignBuyValueTotal', type: 'number' },
  22: { key: 'foreignSellVolumeTotal', type: 'number' },
  23: { key: 'foreignSellValueTotal', type: 'number' },
  24: { key: 'totalStockUpPrice', type: 'number' },
  25: { key: 'totalStockDownPrice', type: 'number' },
  26: { key: 'totalStockNoChangePrice', type: 'number' },
  27: { key: 'totalStockUpCelling', type: 'number' },
  28: { key: 'totalStockDownFloor', type: 'number' },
  29: { key: 'highestIndex', type: 'number' },
  30: { key: 'lowestIndex', type: 'number' },
}

export const mapDerivative = {
  0: { key: 'totalMatchVolume', type: 'number' },
  1: { key: 'marketStatus', type: 'string' },
  2: { key: 'tradingDate', type: 'string' },
  3: { key: 'matchType', type: 'string' },
  4: { key: 'comGroupCode', type: 'string' },
  5: { key: 'futureId', type: 'string' },
  6: { key: 'referencePrice', type: 'number' },
  7: { key: 'openPrice', type: 'number' },
  8: { key: 'closePrice', type: 'number' },
  9: { key: 'ceilingPrice', type: 'number' },
  10: { key: 'floorPrice', type: 'number' },
  11: { key: 'highPrice', type: 'number' },
  12: { key: 'lowPrice', type: 'number' },
  13: { key: 'matchPrice', type: 'number' },
  14: { key: 'priceChange', type: 'number' },
  15: { key: 'percentPriceChange', type: 'number' },
  16: { key: 'matchVolume', type: 'number' },
  17: { key: 'matchValue', type: 'number' },
  18: { key: 'totalMatchValue', type: 'number' },
  19: { key: 'totalBuyTradeVolume', type: 'number' },
  20: { key: 'totalSellTradeVolume', type: 'number' },
  21: { key: 'dealPrice', type: 'number' },
  22: { key: 'totalDealVolume', type: 'number' },
  23: { key: 'totalDealValue', type: 'number' },
  24: { key: 'foreignBuyVolumeTotal', type: 'number' },
  25: { key: 'foreignBuyValueTotal', type: 'number' },
  26: { key: 'foreignSellVolumeTotal', type: 'number' },
  27: { key: 'foreignSellValueTotal', type: 'number' },
  28: { key: 'foreignTotalRoom', type: 'number' },
  29: { key: 'foreignCurrentRoom', type: 'number' },
  30: { key: 'openInterest', type: 'number' },
}

export const mapCoveredWarrant = {
  0: { key: 'totalMatchVolume', type: 'number' },
  1: { key: 'marketStatus', type: 'string' },
  2: { key: 'tradingDate', type: 'string' },
  3: { key: 'matchType', type: 'string' },
  4: { key: 'comGroupCode', type: 'string' },
  5: { key: 'organizationId', type: 'string' },
  6: { key: 'ticker', type: 'string' },
  7: { key: 'referencePrice', type: 'number' },
  8: { key: 'openPrice', type: 'number' },
  9: { key: 'closePrice', type: 'number' },
  10: { key: 'ceilingPrice', type: 'number' },
  11: { key: 'floorPrice', type: 'number' },
  12: { key: 'highPrice', type: 'number' },
  13: { key: 'lowPrice', type: 'number' },
  14: { key: 'matchPrice', type: 'number' },
  15: { key: 'priceChange', type: 'number' },
  16: { key: 'percentPriceChange', type: 'number' },
  17: { key: 'matchVolume', type: 'number' },
  18: { key: 'matchValue', type: 'number' },
  19: { key: 'totalMatchValue', type: 'number' },
  20: { key: 'totalBuyTradeVolume', type: 'number' },
  21: { key: 'totalSellTradeVolume', type: 'number' },
  22: { key: 'dealPrice', type: 'number' },
  23: { key: 'totalDealVolume', type: 'number' },
  24: { key: 'totalDealValue', type: 'number' },
  25: { key: 'foreignBuyVolumeTotal', type: 'number' },
  26: { key: 'foreignBuyValueTotal', type: 'number' },
  27: { key: 'foreignSellVolumeTotal', type: 'number' },
  28: { key: 'foreignSellValueTotal', type: 'number' },
  29: { key: 'foreignTotalRoom', type: 'number' },
  30: { key: 'foreignCurrentRoom', type: 'number' },
}

const mapData = (data, map) => {
  return data.map((item) => {
    const tmp = item.split('|')
    const obj = {}
    tmp.forEach((value, index) => {
      if (map[index]) {
        obj[map[index].key] = map[index].type === 'number' ? +value : value
      }
    })

    return obj
  })
}

export const formatData = (data, key, map) => {
  return unique(mapData(data, map), key)
}

const unique = (items, field) => {
  if (!Array.isArray(items)) {
    return []
  }

  return items.reduce((result, item) => {
    const existIndex = result.findIndex((it) => it[field] === item[field])
    if (existIndex !== -1) {
      result[existIndex] = item

      return result
    }
    result.push(item)

    return result
  }, [])
}

export const formatMessageData = (channel, data) => {
  const map = [
    { key: 'Ticker', channel: ChannelConfig.StockChannel, map: mapStock },
    { key: 'GroupId', channel: ChannelConfig.IndexChannel, map: mapIndex },
    {
      key: 'ComGroupCode',
      channel: ChannelConfig.BidAskChannel,
      map: mapBidAsk,
    },
    {
      key: 'DerivativeCode',
      channel: ChannelConfig.DerivativeChannel,
      map: mapDerivative,
    },
    {
      key: 'Sector',
      channel: ChannelConfig.SectorChannel,
      map: mapSector,
    },
    {
      key: 'CoveredWarrant',
      channel: ChannelConfig.CoveredWarrantChannel,
      map: mapCoveredWarrant,
    },
    {
      key: 'CoveredWarrantBidAsk',
      channel: ChannelConfig.CoveredWarrantBidAskChannel,
      map: mapBidAsk,
    },
    {
      key: 'DerivativeBidAskChannel',
      channel: ChannelConfig.DerivativeBidAskChannel,
      map: mapBidAsk,
    },
  ]

  const items = map.filter((item) => {
    return channel.startsWith(item.channel)
  })

  if (items.length > 0) {
    return formatData(data, items[0].key, items[0].map)
  }

  return data
}
