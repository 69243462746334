import { useEffect, useRef, useState } from 'react'
import { debounce } from '../utils/Common'
import EventEmitter, { COMPONENT_RESIZE } from '../utils/EventEmitter'

export const CalcResize = ({ children, initSize }) => {
  const calcResizeRef = useRef()

  const [size, setSize] = useState(initSize)

  useEffect(() => {
    setSize(initSize)
  }, [initSize])

  const calcSize = () => {
    if (!calcResizeRef.current) {
      return
    }
    setSize({
      width: calcResizeRef.current.clientWidth,
      height: calcResizeRef.current.clientHeight,
    })
  }

  useEffect(() => {
    let timeOut = null
    const resizeWindowDebounce = debounce(() => {
      timeOut = setTimeout(() => {
        calcSize()
      }, 0)
    })
    window.addEventListener('resize', resizeWindowDebounce)
    return () => {
      clearTimeout(timeOut)
      window.removeEventListener('resize', resizeWindowDebounce)
    }
  }, [])

  useEffect(() => {
    EventEmitter.subscribe(COMPONENT_RESIZE, calcSize)
    return () => EventEmitter.unsubscribe(COMPONENT_RESIZE, calcSize)
  }, [])

  return <div ref={calcResizeRef}>{children(size)}</div>
}
