import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { FONT_SIZE_CONFIG } from '../../../../../../configs/Layout'
import PopupAlert from '../../../../../common/popup/PopupAlert'
import { Table } from '../../../../../common/table'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { selectTypeFontSize } from '../../../../../setting/slice'
import { keyBy } from '../../../../../utils/Common'
import { valToPercent } from '../../../../../utils/Value'
import { COLORS_LINE_CHART } from '../../chartFirst/constants'
import {
  ORIGINAL_NUMBER,
  ORIGINAL_NUMBER_FIRST,
  ORIGINAL_NUMBER_FIRST_NOT_YEARLY,
  PRICE_DATA_TIME_SELECT,
} from '../../constants'
import { TAB_TYPE } from '../constants'
import { convertTitleHeader, getItemParent } from '../helper'
import {
  changeCountLimit,
  changeMonth,
  changeYear,
  selectCountLimit,
  selectTypeDataTime,
  selectTypePeriod,
} from '../store/slice'
import {
  addSelectListTableIII,
  getIsShowAlert,
  handleIsShowAlert,
  removeSelectListTableIII,
  resetDataIII,
  selectCellValueDataTableIII,
  selectDataColorIdIII,
  selectDataTableIII,
  selectGroupColumnTableIII,
  selectIdIII,
  selectListSelectRowIII,
  selectLoadingTableIII,
  selectMaxTimeCallApi,
  selectMinTimeCallApi,
  sortTableIII,
} from './store/slice'
import { getDataTableIIICompareByTimeRange } from './store/thunk'
import TdSelect from './tdSelect'

const HORIZONTAL_TRACK_LEFT = 28

const TableIII = () => {
  const dispatch = useDispatch()

  const typeFontSize = useSelector(selectTypeFontSize)
  const typePeriod = useSelector(selectTypePeriod)
  const typeDataTime = useSelector(selectTypeDataTime)
  const isLoading = useSelector(selectLoadingTableIII)
  const ids = useSelector(selectIdIII)
  const dataTable = useSelector(selectDataTableIII)
  const groupColumns = useSelector(selectGroupColumnTableIII)
  const listSelectRowIII = useSelector(selectListSelectRowIII)
  const listDataColorById = useSelector(selectDataColorIdIII)
  const minTimeCallApi = useSelector(selectMinTimeCallApi)
  const maxTimeCallApi = useSelector(selectMaxTimeCallApi)
  const locale = useSelector((state) => state.i18n.locale)
  const isShowAlert = useSelector(getIsShowAlert)

  const countLimit = useSelector(selectCountLimit)

  const [rowsCollapse, setRowsCollapse] = useState([])
  const [isFirstRender, setIsFirstRender] = useState(true)

  const onRowCollapse = (rowId, isCollapse) => {
    if (isCollapse) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        setRowsCollapse((prev) => [...prev, rowId])
      }
    } else {
      setRowsCollapse((prev) => prev?.filter((item) => item !== rowId))
    }
  }
  const listDataItemCollapse = dataTable?.map((item) => ({
    ...item,
    isCollapsible:
      getItemParent(dataTable)?.includes(item) || item.iivnTypeLevel === 1
        ? true
        : false,
  }))

  const levelCollapse = keyBy(
    listDataItemCollapse?.map((item) => ({
      id: item.iivnTypeId,
      level: item.iivnTypeLevel,
      isCollapsible: item.isCollapsible,
    })),
    'id',
  )

  const getIdColor = (id) => {
    const listIdColorSelected = listSelectRowIII?.map((item) => item.colorId)
    const listIdsTotal = COLORS_LINE_CHART?.map((item, index) => index)
    const listIdCanSelected = listIdsTotal.filter(
      (val) => !listIdColorSelected.includes(val),
    )
    if (
      listDataColorById?.find((item) => item.iivnTypeId === id).colorId > 20
    ) {
      return listIdCanSelected[0]
    }
    return listDataColorById?.find((item) => item.iivnTypeId === id).colorId
  }

  useEffect(() => {
    setIsFirstRender(true)
    if (typeDataTime !== PRICE_DATA_TIME_SELECT.YEARLY) {
      dispatch(
        getDataTableIIICompareByTimeRange({
          FrequencyType: typePeriod,
          DataType: PRICE_DATA_TIME_SELECT.MONTHLY,
          Limit: ORIGINAL_NUMBER_FIRST_NOT_YEARLY,
          IIVNType: TAB_TYPE.III,
        }),
      )
      dispatch(changeCountLimit(ORIGINAL_NUMBER_FIRST_NOT_YEARLY))
    } else {
      dispatch(
        getDataTableIIICompareByTimeRange({
          FrequencyType: typePeriod,
          DataType: PRICE_DATA_TIME_SELECT.MONTHLY,
          Limit: ORIGINAL_NUMBER_FIRST,
          IIVNType: TAB_TYPE.III,
        }),
      )
      dispatch(changeCountLimit(ORIGINAL_NUMBER_FIRST))
    }

    return () => {
      dispatch(resetDataIII())
    }
  }, [typePeriod, locale])

  useEffect(() => {
    dispatch(changeYear(maxTimeCallApi.year))
    dispatch(changeMonth(maxTimeCallApi.month))
  }, [maxTimeCallApi])

  const onRowClick = (data) => {
    if (listSelectRowIII.length <= 20) {
      if (listSelectRowIII.some((item) => item.iivnTypeId === data)) {
        dispatch(removeSelectListTableIII(data))
      } else {
        const props = {
          iivnTypeId: data,
          iivnTypeName: dataTable?.find((item) => item?.iivnTypeId === data)
            .iivnTypeName,
          colorId: getIdColor(data),
        }
        dispatch(addSelectListTableIII(props))
      }
    } else {
      dispatch(handleIsShowAlert(true))
    }
  }

  const scrollToLeftCallback = () => {
    if (!minTimeCallApi.year || !minTimeCallApi.month) {
      return
    }

    setIsFirstRender(false)
    dispatch(
      getDataTableIIICompareByTimeRange({
        FrequencyType: typePeriod,
        DataType: PRICE_DATA_TIME_SELECT.MONTHLY,
        Month: minTimeCallApi.month,
        Year: minTimeCallApi.year,
        IIVNType: TAB_TYPE.III,
        Limit: ORIGINAL_NUMBER,
      }),
    )
    dispatch(changeCountLimit(countLimit + ORIGINAL_NUMBER))
  }

  return (
    <div className="h-100">
      <Table
        ids={ids}
        getDataFromRedux={selectCellValueDataTableIII}
        isLoading={isLoading}
        onRowClick={onRowClick}
        horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
        schema={groupColumns.map((item, index) => {
          const title = convertTitleHeader(item.title, typeDataTime)
          const colId = item.key
          let result = {
            colId,
            title,
            isI18n: false,
            thStyle: {
              textAlign: 'right',
            },
            tdStyle: {
              textAlign: 'right',
            },
          }
          if (index === 0) {
            result = {
              ...result,
              isI18n: false,
              tdStyle: {
                textAlign: 'left',
                minWidth: 280,
              },
              disableSort: true,
              canCustomTd: true,
              renderTh: () => <></>,
              render: (val, rowId, props) => {
                return (
                  <TdSelect
                    val={val}
                    rowId={rowId}
                    dataTable={dataTable}
                    onRowCollapse={onRowCollapse}
                    listDataItemCollapse={listDataItemCollapse}
                    props={props}
                    listSelectRowIII={listSelectRowIII}
                  />
                )
              },
            }
          } else {
            result = {
              ...result,
              thStyle: {
                textAlign: 'right',
                minWidth:
                  typeFontSize === Object.keys(FONT_SIZE_CONFIG)[2] ? 95 : 80,
              },
              render: (val) => {
                return (
                  <p style={val < 0 ? { color: '#ff4752' } : {}}>
                    {val === EMPTY_VALUE ? EMPTY_VALUE : valToPercent(val)}
                  </p>
                )
              },
            }
          }
          return result
        })}
        columnDraggable={false}
        hasFooter={false}
        stickyFirstColumn={true}
        scrollToLeftCallback={scrollToLeftCallback}
        resizable={false}
        isCollapse={true}
        rowsCollapse={rowsCollapse}
        levelCollapse={levelCollapse}
        sort={sortTableIII}
        defaultScrollToRight={isFirstRender}
        isLazyLoadLeft={true}
      />
      <PopupAlert
        message={<Translate value={'economy.gdp.gdp.MAX_ITEM'} />}
        isShow={isShowAlert}
        handleClose={() => {
          dispatch(handleIsShowAlert(false))
        }}
      />
    </div>
  )
}
export default TableIII
