import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DailyReportService from '../../../../../../../core/services/newsReport/DailyReportService'

export const getNews = createAsyncThunk(
  'newsReport/dailyReport/setting/companyNews/marketNews/getNews',
  async (params, { rejectWithValue }) => {
    try {
      const res = await DailyReportService.getNews(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
