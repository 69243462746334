import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OverviewService from '../../../../../../core/services/economy/importExport/OverviewService'

export const getImportContributeByLocation = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportContributeByLocation',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getImportContributeByLocation(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getExportContributeByLocation = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportContributeByLocation',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getExportContributeByLocation(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getMaxDateDetail = createAsyncThunk(
  'economy/importExport/importExportByCountry/exportStructure/getMaxDateDetail',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getMaxDateDetail(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
