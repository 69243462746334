import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../html/Button'
import {
  resetStore,
  selectPopupIndicator,
} from '../../popupIndicator/store/slice'
import style from '../index.module.css'
import { selectPopupTemplate, selectTickerPopupTemplate } from '../store/slice'
import { maxTicker } from './Count'

const ButtonSubmit = ({
  submit,
  isNotHaveTicker,
  errors,
  isHasDetailIndicator,
}) => {
  const dispatch = useDispatch()

  const dataSubmit = useSelector(selectPopupTemplate)
  const dataIndicator = useSelector(selectPopupIndicator)
  const ticker = useSelector(selectTickerPopupTemplate)

  useEffect(() => {
    return () => {
      if (isHasDetailIndicator) {
        dispatch(resetStore)
      }
    }
  }, [])

  const totalTicker = ticker.length
  const isDisable =
    (totalTicker > maxTicker || totalTicker === 0 || errors?.length) &&
    !isNotHaveTicker

  const handleSubmit = () => {
    if (isHasDetailIndicator) {
      submit({ ...dataSubmit, detail: dataIndicator.detail })
    } else {
      submit(dataSubmit)
    }
  }

  return (
    <Button
      onClick={handleSubmit}
      style={{ fontSize: 11 }}
      className={`btn btn-blue w-80 h-20 ${isDisable ? style.disabled : ''}`}
      disabled={isDisable}
    >
      <Translate value="tool.smartScreening.APPLY" />
    </Button>
  )
}

ButtonSubmit.propTypes = {
  submit: PropTypes.func.isRequired,
  isNotHaveTicker: PropTypes.bool,
  isHasDetailIndicator: PropTypes.bool,
}

ButtonSubmit.defaultProps = {
  isNotHaveTicker: false,
  isHasDetailIndicator: false,
}

export default ButtonSubmit
