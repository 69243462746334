import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { RENDER_CONTENT_TYPE, Table } from '../../../common/table'
import {
  formatVal,
  valDivBillion,
  valDivMillion,
  valDivThousand,
} from '../../../utils/Value'
import { MAP_LIST_TICKER, SIZE_BYES } from '../constants'
import { selectCriteria, selectSizeBy, selectValueTicker } from '../store/slice'
import style from './listTicker.module.css'
import { CriteriaCell } from './CriteriaCell'

export const ListTicker = forwardRef(
  ({ sectorName, appendStyle, data, hoverData }, ref) => {
    const criteria = useSelector(selectCriteria)
    const sizeBy = useSelector(selectSizeBy)

    const getTitle = (key) => {
      if (MAP_LIST_TICKER[key] === MAP_LIST_TICKER.CRITERIA) {
        return 'market.heatmap.listTickerHeaderTable.' + criteria
      }

      if (MAP_LIST_TICKER[key] === MAP_LIST_TICKER.SIZE_BY) {
        return 'market.heatmap.listTickerHeaderTable.' + sizeBy
      }

      return 'market.heatmap.listTickerHeaderTable.' + key
    }

    return (
      <div style={appendStyle} className={style.listTicker} ref={ref}>
        <div className={style.headerTitle}>
          <span className={style.headerTitleText}>{sectorName}</span>
        </div>
        <Table
          schema={Object.keys(MAP_LIST_TICKER).map((key) => {
            const result = {
              colId: MAP_LIST_TICKER[key],
              title: getTitle(key),
            }

            if (MAP_LIST_TICKER[key] === MAP_LIST_TICKER.PRICE) {
              return {
                ...result,
                thStyle: {
                  textAlign: 'right',
                },
                tdStyle: {
                  textAlign: 'right',
                },
                render: (val) => {
                  return formatVal(valDivThousand(val))
                },
              }
            }

            if (MAP_LIST_TICKER[key] === MAP_LIST_TICKER.CRITERIA) {
              return {
                ...result,
                thStyle: {
                  textAlign: 'right',
                },
                tdStyle: {
                  textAlign: 'right',
                },
                render: (val, rowId) => {
                  return (
                    <CriteriaCell
                      val={val}
                      rowId={rowId}
                      hoverCode={hoverData.code}
                    />
                  )
                },
              }
            }

            if (MAP_LIST_TICKER[key] === MAP_LIST_TICKER.SIZE_BY) {
              return {
                ...result,
                thStyle: {
                  textAlign: 'right',
                },
                tdStyle: {
                  textAlign: 'right',
                },
                render: (val) => {
                  const MAP = {
                    [SIZE_BYES.MARKET_CAPITALIZATION]: (val) =>
                      formatVal(valDivBillion(val)),
                    [SIZE_BYES.TOTAL_VOLUME]: (val) =>
                      formatVal(valDivThousand(val)),
                    [SIZE_BYES.TOTAL_VALUE]: (val) =>
                      formatVal(valDivMillion(val)),
                    [SIZE_BYES.FOREIGN_BUY_VALUE]: (val) =>
                      formatVal(valDivMillion(val)),
                    [SIZE_BYES.FOREIGN_SELL_VALUE]: (val) =>
                      formatVal(valDivMillion(val)),
                    [SIZE_BYES.FOREIGN_BUY_VOLUME]: (val) =>
                      formatVal(valDivThousand(val)),
                    [SIZE_BYES.FOREIGN_SELL_VOLUME]: (val) =>
                      formatVal(valDivThousand(val)),
                  }
                  return MAP[sizeBy](val)
                },
              }
            }

            return result
          })}
          ids={data
            .filter((item) => !!item.organizationId)
            .map((item) => item.organizationId)}
          getDataFromRedux={selectValueTicker}
          hasFooter={false}
          renderContentType={RENDER_CONTENT_TYPE.FIT_CONTENT}
          hasTooltip={false}
          idRowCustomStyle={[hoverData.code]}
          rowCustomStyle={{ backgroundColor: hoverData.bgColor }}
        />
      </div>
    )
  },
)

ListTicker.propTypes = {
  sectorName: PropTypes.string.isRequired,
  appendStyle: PropTypes.object,
  data: PropTypes.array.isRequired,
}

ListTicker.defaultProps = {
  appendStyle: {},
}
