import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot, valDivBillion } from '../../../../../../utils/Value'
import { LOAN_SECTOR } from '../../constant'
import { formatDataDetailMetrics } from '../detailMetrics/helper'
import {
  getBankLoanBySector,
  getBankLoanChangeInMarketShare,
  getBankLoanDetailMetrics,
  getBankLoanMarketShare,
  getBankLoanMarketShareByLoanSector,
  getBankLoanToCustomer,
} from './thunk'

const initialState = {
  loanSector: LOAN_SECTOR.trading,
  loading: {
    loanToCustomer: true,
    marketShare: true,
    changeInMarketShare: true,
    loanBySector: true,
    marketShareByLoanSector: true,
    detailMetrics: true,
  },
  data: {
    loanToCustomer: [],
    marketShare: [],
    changeInMarketShare: [],
    loanBySector: [],
    marketShareByLoanSector: [],
    detailMetricsData: {},
    detailMetricsRowIds: [],
  },
  reCalcWiths: false,
  listCheckboxLoan: ['bS159', 'npl'],
}

const slice = createSlice({
  name: 'corporate/businessModel/penetration/bank/loan',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeLoanSector: (state, action) => {
      state.loanSector = action.payload
    },
    handleListCheckboxLoan: (state, action) => {
      state.listCheckboxLoan = action.payload
    },
  },
  extraReducers: (builder) => {
    //LoanToCustomer
    builder.addCase(getBankLoanToCustomer.pending, (state) => {
      state.loading.loanToCustomer = true
    })
    builder.addCase(getBankLoanToCustomer.fulfilled, (state, action) => {
      state.data.loanToCustomer =
        action.payload?.map((item) => ({
          ...item,
          bS159: valDivBillion(item.bS159),
          npl: valDivBillion(item.npl),
        })) || []
      state.loading.loanToCustomer = false
    })
    builder.addCase(getBankLoanToCustomer.rejected, (state, action) => {
      state.loading.loanToCustomer = action.payload.loading
    })
    //MarketShare
    builder.addCase(getBankLoanMarketShare.pending, (state) => {
      state.loading.marketShare = true
    })
    builder.addCase(getBankLoanMarketShare.fulfilled, (state, action) => {
      state.data.marketShare = action.payload || []
      state.loading.marketShare = false
    })
    builder.addCase(getBankLoanMarketShare.rejected, (state, action) => {
      state.loading.marketShare = action.payload.loading
    })
    //ChangeInMarketShare
    builder.addCase(getBankLoanChangeInMarketShare.pending, (state) => {
      state.loading.changeInMarketShare = true
    })
    builder.addCase(
      getBankLoanChangeInMarketShare.fulfilled,
      (state, action) => {
        state.data.changeInMarketShare =
          action.payload?.map((item) => ({
            ...item,
            c4: item.c4 * 100,
          })) || []
        state.loading.changeInMarketShare = false
      },
    )
    builder.addCase(
      getBankLoanChangeInMarketShare.rejected,
      (state, action) => {
        state.loading.changeInMarketShare = action.payload.loading
      },
    )
    //LoanBySector
    builder.addCase(getBankLoanBySector.pending, (state) => {
      state.loading.loanBySector = true
    })
    builder.addCase(getBankLoanBySector.fulfilled, (state, action) => {
      const fieldRequest = action.meta.arg.Field
      state.data.loanBySector =
        action.payload?.map((item) => ({
          ...item,
          [fieldRequest]: valDivBillion(item[fieldRequest]),
        })) || []
      state.loading.loanBySector = false
    })
    builder.addCase(getBankLoanBySector.rejected, (state, action) => {
      state.loading.loanBySector = action.payload.loading
    })
    //MarketShareByLoanSector
    builder.addCase(getBankLoanMarketShareByLoanSector.pending, (state) => {
      state.loading.marketShareByLoanSector = true
    })
    builder.addCase(
      getBankLoanMarketShareByLoanSector.fulfilled,
      (state, action) => {
        state.data.marketShareByLoanSector = action.payload || []
        state.loading.marketShareByLoanSector = false
      },
    )
    builder.addCase(
      getBankLoanMarketShareByLoanSector.rejected,
      (state, action) => {
        state.loading.marketShareByLoanSector = action.payload.loading
      },
    )
    //DetailMetrics
    builder.addCase(getBankLoanDetailMetrics.pending, (state) => {
      state.loading.detailMetrics = true
    })
    builder.addCase(getBankLoanDetailMetrics.fulfilled, (state, action) => {
      const requestIndicator = action.meta.arg.Indicator
      const { data, rowIds } = formatDataDetailMetrics(
        action.payload || [],
        requestIndicator.toUpperCase(),
      )
      state.data.detailMetricsData = data
      state.data.detailMetricsRowIds = rowIds
      state.reCalcWiths = !state.reCalcWiths
      state.loading.detailMetrics = false
    })
    builder.addCase(getBankLoanDetailMetrics.rejected, (state, action) => {
      state.loading.detailMetrics = action.payload.loading
    })
  },
})

export const selectLoadingLoanToCustomer = (state) =>
  state[slice.name].loading.loanToCustomer
export const selectLoadingMarketShare = (state) =>
  state[slice.name].loading.marketShare
export const selectLoadingChangeInMarketShare = (state) =>
  state[slice.name].loading.changeInMarketShare
export const selectLoadingLoanBySector = (state) =>
  state[slice.name].loading.loanBySector
export const selectLoadingMarketShareByLoanSector = (state) =>
  state[slice.name].loading.marketShareByLoanSector
export const selectLoadingDetailMetrics = (state) =>
  state[slice.name].loading.detailMetrics

export const selectDataLoanToCustomer = (state) =>
  state[slice.name].data.loanToCustomer
export const selectDataMarketShare = (state) =>
  state[slice.name].data.marketShare
export const selectDataChangeInMarketShare = (state) =>
  state[slice.name].data.changeInMarketShare
export const selectDataLoanBySector = (state) =>
  state[slice.name].data.loanBySector
export const selectDataMarketShareByLoanSector = (state) =>
  state[slice.name].data.marketShareByLoanSector
export const selectDetailMetricsRowIds = (state) =>
  state[slice.name].data.detailMetricsRowIds
export const selectDataDetailMetrics = (state) =>
  state[slice.name].data.detailMetricsData
export const selectDataDetailMetricsById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.detailMetricsData[id], attr)

export const selectLoanSector = (state) => state[slice.name].loanSector
export const selectReCalcWiths = (state) => state[slice.name].reCalcWiths
export const selectListCheckboxLoan = (state) =>
  state[slice.name].listCheckboxLoan

export const { resetStore, changeLoanSector, handleListCheckboxLoan } =
  slice.actions

register(slice.name, slice.reducer)
