import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import style from '../index.module.css'

export const PriceRate = ({ priceRateText }) => {
  return (
    <div className={`table-info-left ${style.priceRate} ml-8`}>
      <Span style={{ fontSize: 11 }}>
        <Translate
          value={
            priceRateText ||
            'market.marketInDepth.indexStatistics.priceData.PRICE_RATE_PRICE_VOLUME_VALUE'
          }
        />
      </Span>
    </div>
  )
}
