import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { DEFAULT_CHECKED } from '../config'
import {
  handleHeaderDate,
  handleIds,
  handleParentIdWithTitle,
  initialCheckboxChecked,
  initLineColor,
  keyById,
} from '../helper'
import { getCurrencyExchangeRate } from './thunk'

const initialState = {
  from: '',
  fromISOMinDateParam: '',
  fromISOMaxDateParam: '',
  fromISODateView: '',
  allPageSorted: [],
  timeRange: 'Daily',
  limitTime: {
    minDate: '',
    maxDate: '',
  },
  tableDataLoading: true,
  tableData: {},
  checkboxChecked: [],
  ids: [],
  headerDate: [],
  sliceValue: undefined,
  isRecalculateSliceValue: true,
  isDecrease: true,
  isShowAlert: false,
  parentIdWithTitle: {},
  lineChartColor: [],
  indexChecked: DEFAULT_CHECKED,
  currentPage: [],
}

const slice = createSlice({
  name: 'economy/monetary/exchangeRate/panelExchangeRate',
  initialState,
  reducers: {
    handleFromDate: (state, action) => {
      state.from = action.payload
    },
    handleTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
    handleLimitTime: (state, action) => {
      state.limitTime = action.payload
    },
    handleCheckboxChecked: (state, action) => {
      state.checkboxChecked = action.payload
    },
    handleSliceValue: (state, action) => {
      state.sliceValue = action.payload
    },
    handleFromISOMinDateParam: (state, action) => {
      state.fromISOMinDateParam = action.payload
    },
    handleFromISOMaxDateParam: (state, action) => {
      state.fromISOMaxDateParam = action.payload
    },
    handleFromISODateView: (state, action) => {
      state.fromISODateView = action.payload
    },
    handleIsRecalculateSliceValue: (state, action) => {
      state.isRecalculateSliceValue = action.payload
    },
    handleIsDecrease: (state, action) => {
      state.isDecrease = action.payload
    },
    handleAllPageSorted: (state, action) => {
      state.allPageSorted = action.payload
    },
    handleIsShowAlert: (state, action) => {
      state.isShowAlert = action.payload
    },
    handleLineChartColor: (state, action) => {
      state.lineChartColor = action.payload
    },
    handleIndexChecked: (state, action) => {
      state.indexChecked = action.payload
    },
    handleCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrencyExchangeRate.pending, (state) => {
      state.tableDataLoading = true
    })
    builder.addCase(getCurrencyExchangeRate.fulfilled, (state, action) => {
      const data = action.payload || []
      state.tableDataLoading = false
      state.tableData = keyById(data)
      state.ids = handleIds(data)
      state.parentIdWithTitle = handleParentIdWithTitle(data)
      state.headerDate = handleHeaderDate(data)
      state.checkboxChecked = initialCheckboxChecked(
        state.ids,
        state.indexChecked,
      )
      state.lineChartColor = initLineColor(state.checkboxChecked)
      if (data?.length) {
        state.limitTime = {
          minDate: data[0]?.minDate,
          maxDate: data[0]?.maxDate,
        }
      }
    })
    builder.addCase(getCurrencyExchangeRate.rejected, (state, action) => {
      state.tableDataLoading = action.payload.isLoading
    })
  },
})

export const {
  handleFromDate,
  handleTimeRange,
  handleLimitTime,
  handleCheckboxChecked,
  handleSliceValue,
  handleFromISOMinDateParam,
  handleFromISOMaxDateParam,
  handleFromISODateView,
  handleIsRecalculateSliceValue,
  handleIsDecrease,
  handleAllPageSorted,
  handleIsShowAlert,
  handleLineChartColor,
  handleIndexChecked,
  handleCurrentPage,
} = slice.actions

export const getFromDate = (state) => state[slice.name].from
export const getTimeRange = (state) => state[slice.name].timeRange
export const getLimitTime = (state) => state[slice.name].limitTime
export const getTableDataLoading = (state) => state[slice.name].tableDataLoading
export const getTableData = (state) => state[slice.name].tableData
export const getCheckboxChecked = (state) => state[slice.name].checkboxChecked
export const getExchangeRateValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].tableData[id], attr)
export const getIds = (state) => state[slice.name].ids
export const getHeaderDate = (state) => state[slice.name].headerDate
export const getSliceValue = (state) => state[slice.name].sliceValue
export const getFromISOMinDateParam = (state) =>
  state[slice.name].fromISOMinDateParam
export const getFromISOMaxDateParam = (state) =>
  state[slice.name].fromISOMaxDateParam
export const getFromISODateView = (state) => state[slice.name].fromISODateView
export const getIsRecalculateSliceValue = (state) =>
  state[slice.name].isRecalculateSliceValue
export const getIsDecrease = (state) => state[slice.name].isDecrease
export const getAllPageSorted = (state) => state[slice.name].allPageSorted
export const getIsShowAlert = (state) => state[slice.name].isShowAlert
export const getParentIdWithTitle = (state) =>
  state[slice.name].parentIdWithTitle
export const getLineChartColor = (state) => state[slice.name].lineChartColor
export const getIndexChecked = (state) => state[slice.name].indexChecked
export const getCurrentPage = (state) => state[slice.name].currentPage

register(slice.name, slice.reducer)
