import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import {
  formatVal,
  valDivBillion,
  valToPercent,
} from '../../../../../utils/Value'
import {
  selectFilter,
  selectRevenueTypeDataById,
} from '../marketShare/store/slice'
import TickerCell from './TickerCell'
import { INDICATORS } from './constant'
import {
  resetStore,
  selectData,
  selectLoading,
  selectReCalcWiths,
  selectRowIds,
  selectYears,
} from './store/slice'
import { getCorpDetailMetrics } from './store/thunk'

const TableDetailMetrics = () => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const filter = useSelector(selectFilter)
  const revenueTypeName = useSelector(
    selectRevenueTypeDataById(filter.revenueTypeId, 'revenueTypeName'),
  )
  const years = useSelector(selectYears)
  const rowIds = useSelector(selectRowIds)
  const loading = useSelector(selectLoading)
  const reCalcWidths = useSelector(selectReCalcWiths)

  const billionUnit = UseI18n(
    'corporate.businessModel.penetrationAnalysis.billionUnit',
  )

  useEffect(() => {
    if (filter.revenueTypeId) {
      dispatch(
        getCorpDetailMetrics({
          RevenueTypeId: filter.revenueTypeId,
        }),
      )
    } else {
      dispatch(resetStore())
    }
  }, [filter.revenueTypeId])

  const getRowHightlight = () => {
    const indicatorLength = Object.keys(INDICATORS).length
    return Array(rowIds.length / indicatorLength - 1)
      .fill(0)
      .map((_, index) => indicatorLength * (index + 1) - 1)
  }

  const getSortRowId = () => {
    const currentRowIds = []
    const otherRowIds = []

    rowIds.forEach((rowId) => {
      if (rowId.startsWith(basicInfo.ticker)) {
        currentRowIds.push(rowId)
      } else {
        otherRowIds.push(rowId)
      }
    })

    return [...currentRowIds, ...otherRowIds]
  }

  return (
    <div className="h-100">
      {loading ? (
        <Loading />
      ) : years.length && rowIds.length ? (
        <Table
          ids={getSortRowId()}
          getDataFromRedux={selectData}
          isLoading={loading}
          schema={['ticker', 'indicator', ...years].map((year, index) => {
            const colId = year
            return {
              colId,
              isI18n: [0, 1].includes(index) ? false : true,
              title:
                index === 0
                  ? 'corporate.businessModel.penetrationAnalysis.COMPANY'
                  : index === 1
                  ? 'corporate.businessModel.penetrationAnalysis.DETAIL_METRICS'
                  : colId,
              thStyle: {
                textAlign: [0, 1].includes(index) ? 'left' : 'right',
              },
              tdStyle: {
                textAlign: [0, 1].includes(index) ? 'left' : 'right',
              },
              canCustomTd: index === 0 ? true : false,
              render: (val, rowId, rest) => {
                if (val === 0) return EMPTY_VALUE

                if (index === 0) {
                  return rowId.endsWith(INDICATORS.iS3) ? (
                    <TickerCell
                      rowId={rowId}
                      style={rest.style}
                      className={rest.className}
                    />
                  ) : (
                    <></>
                  )
                }

                if (index === 1) {
                  const key = rowId.split('-')[1]
                  if (key === INDICATORS.rB1) {
                    return revenueTypeName + ' ' + billionUnit
                  }
                  return (
                    <Translate
                      value={`corporate.businessModel.penetrationAnalysis.corpTable.${key}`}
                    />
                  )
                }

                switch (rowId.split('-')[1]) {
                  case INDICATORS.iS3:
                  case INDICATORS.rB1:
                    return formatVal(valDivBillion(val))
                  case INDICATORS.growth:
                  case INDICATORS.marketShare:
                    return valToPercent(val)
                  default:
                    return val
                }
              },
            }
          })}
          isLargeHead
          stickies={{ ticker: true, indicator: true }}
          rowHighlightLines={getRowHightlight()}
          reCalcWidths={reCalcWidths}
          hasFooter={false}
          hasTooltip={false}
        />
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default TableDetailMetrics
