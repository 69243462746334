import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import TopMoverService from '../../../../core/services/market/TopMoverService'
import WatchlistService from '../../../../core/services/watchlist/WatchlistService'
import { handleExport } from '../../../utils/Export'

export const getTopMover = createAsyncThunk(
  'market/topMover/GET_TOP_MOVER',
  async (params, { rejectWithValue }) => {
    try {
      const response = await TopMoverService.getTopMover(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const refreshTopMover = createAsyncThunk(
  'market/topMover/REFRESH_TOP_MOVER',
  async (params, { rejectWithValue }) => {
    const response = await TopMoverService.getTopMover(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadTopMover = createAsyncThunk(
  'market/topMover/DOWNLOAD_TOP_MOVER',
  async (data) => {
    const response = await TopMoverService.downloadTopMover(data)
    handleExport(response.data, response.filename)
  },
)

export const getWatchListThunk = createAsyncThunk(
  'market/topMover/GET_WATCH_LIST',
  async (params, { rejectWithValue }) => {
    const response = await WatchlistService.getWatchlist(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
