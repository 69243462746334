import PropTypes from 'prop-types'
import { Span } from '../../../../common/html/Span'
import { formatVal, valDivTrillion } from '../../../../utils/Value'
import { EMPTY_ROW } from './helper'
import style from './index.module.css'

export const CASH_TOTAL_ROW_ID = '13-true'

const OtherCellColumn = ({ props, isValue, val, rowId, isLastCell }) => {
  const isEmptyRow = EMPTY_ROW.includes(rowId)

  return (
    <td
      {...props}
      className={style.borderDefault}
      style={{
        borderRightColor: isEmptyRow && !isLastCell && '#1e242e',
        textAlign: 'right',
      }}
    >
      <Span
        className={`${typeof val === 'number' && val < 0 && style.redColor}`}
      >
        {isEmptyRow
          ? ''
          : isValue && rowId !== CASH_TOTAL_ROW_ID
          ? formatVal(valDivTrillion(val))
          : formatVal(val * 100)}
        {typeof val === 'number' &&
          (!isValue || rowId === CASH_TOTAL_ROW_ID) &&
          '%'}
      </Span>
    </td>
  )
}

OtherCellColumn.propTypes = {
  props: PropTypes.object,
  isValue: PropTypes.bool,
  rowId: PropTypes.string.isRequired,
}

export default OtherCellColumn
