import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import authProvider from '../../core/AuthenticationProvider'
import { Button } from '../common/html/Button'
import { Z_INDEX } from '../constants/Common'
import style from './index.module.css'
import { selectIsShow, setIsShow } from './slice'

export const Logout = () => {
  const dispatch = useDispatch()
  const isShow = useSelector(selectIsShow)

  const closePopup = () => {
    dispatch(setIsShow(false))
  }

  const logout = () => {
    authProvider.signout()
  }

  return (
    isShow && (
      <div className="overlay" style={{ zIndex: Z_INDEX.MODAL }}>
        <div className="modal-wrapper">
          <div className="modal" style={{ width: 400 }}>
            <div className="modal-title">
              <h3 className="">
                <Translate value={'logout.LOG_OUT_TITLE'} />
              </h3>
              <a className="close-modal" onClick={closePopup}>
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div className="modal-content">
              <Translate value={'logout.DESC'} />
              <div className={style.groupBtn}>
                <Button
                  style={{ fontSize: 10 }}
                  className="btn bg-grey-1 w-80 h-20 mr-8"
                  onClick={logout}
                >
                  <Translate value={'logout.LOG_OUT_BTN'} />
                </Button>
                <Button
                  style={{ fontSize: 10 }}
                  className="btn btn-blue w-80 h-20"
                  onClick={closePopup}
                >
                  <Translate value={'common.button.BUTTON_CANCEL'} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
