import PropTypes from 'prop-types'
import { COMPONENT } from '../../../configs/Layout'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import More from './more'

export const NewsReportMore = ({ defaultType, detailRoute }) => {
  return (
    <div
      className="w-100 position-relative"
      style={{
        marginTop: COMPONENT.MARGIN_TOP,
        height: `calc(100% - ${COMPONENT.MARGIN_TOP}px)`,
      }}
    >
      <ScrollGroupComponent>
        <More defaultType={defaultType} detailRoute={detailRoute} />
      </ScrollGroupComponent>
    </div>
  )
}

NewsReportMore.propTypes = {
  defaultType: PropTypes.number,
  detailRoute: PropTypes.string,
}
