import { Foreign } from './foreign'
import { HistoricalStatistics } from './historicalPrice'
import { OrderedStatistics } from './orderedStatistics'
import { Proprietary } from './proprietary'

export const TABLE_TYPE = {
  OVERVIEW: 'overview',
  DETAIL: 'detail',
  TICKER: 'ticker',
}

export const LIST_RADIO = [
  {
    label: 'market.derivative.OVERVIEW',
    value: TABLE_TYPE.OVERVIEW,
  },
  {
    label: 'market.derivative.DETAIL',
    value: TABLE_TYPE.DETAIL,
  },
]

export const CW_STATISTICS_TAB = {
  HISTORICAL: 'historical',
  FOREIGN: 'foreign',
  ORDER: 'order',
  PROPRIETARY: 'proprietary',
}

export const NAVIGATION_CW_STATISTICS = [
  {
    title: 'market.derivative.HISTORICAL_PRICE',
    component: <HistoricalStatistics />,
    value: CW_STATISTICS_TAB.HISTORICAL,
  },
  {
    title: 'market.derivative.FOREIGN',
    component: <Foreign />,
    value: CW_STATISTICS_TAB.FOREIGN,
  },
  {
    title: 'market.derivative.ORDER_STATISTICS',
    component: <OrderedStatistics />,
    value: CW_STATISTICS_TAB.ORDER,
  },
  {
    title: 'market.derivative.PROPRIETARY',
    component: <Proprietary />,
    value: CW_STATISTICS_TAB.PROPRIETARY,
  },
]
