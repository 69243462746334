import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { sortObjByQuarterAndYear, sortObjByYear } from '../../../common/helper'
import { TIME_RANGE } from '../constants'
import {
  getAccQuarterAndYearKey,
  getDataByQuarterAndYear,
  getQuarterAndYearInDataRange,
  getQuarterAndYearKey,
} from '../helper'
import { getTotalSocialInvesmentCapital } from './thunk'

const initialState = {
  timeRange: TIME_RANGE.YEARLY,
  ids: [],
  dataById: {},
  isLoading: true,
  data: [],
  groupColumns: [],
  payloadData: [],
  initialIds: [],
  levels: [],
  minYear: moment().year(),
  minQuarter: moment().quarter(),
}

const slice = createSlice({
  name: 'economy/fiscal/stateBudget/socialInvestment',
  initialState,
  reducers: {
    handleTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
        0,
        state.levels,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTotalSocialInvesmentCapital.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getTotalSocialInvesmentCapital.fulfilled,
      (state, action) => {
        state.isLoading = false

        const payloadNameData =
          action.payload?.nameData?.map((item) => ({
            ...item,
            id: `${item.investmentConstructionVNTypeId}_3`,
            name: item.investmentConstructionVNTypeName ?? '',
            level: item.investmentConstructionVNTypeLevel,
            parentId: `${item.parentInvestmentConstructionVNTypeId}_3`,
          })) || []

        let minQuarter = moment().quarter()
        let minYear = moment().year()
        const payloadData =
          action.payload?.data?.map((item) => {
            if (item.year < minYear) {
              minQuarter = item.quarter
              minYear = item.year
            } else if (item.year === minYear && item.quarter < minQuarter) {
              minQuarter = item.quarter
            }

            return {
              ...item,
              id: `${item.investmentConstructionVNTypeId}_3`,
              name: item.investmentConstructionVNTypeName ?? '',
              parentId: `${item.parentInvestmentConstructionVNTypeId}_3`,
              value: item.value / 1000000000,
            }
          }) || []

        const data = fullDataWithVal(
          payloadData,
          payloadNameData,
          state.timeRange,
        )
        state.ids = state.initialIds = data?.map((v) => v.id)
        state.payloadData = payloadData
        state.data = data
        state.dataById = keyBy(data, 'id')
        state.levels = payloadNameData
        state.minQuarter = minQuarter
        state.minYear = minYear

        const getGroupColumns = data?.map((item) => Object.keys(item))[0]
        const filterGroupColumns = getGroupColumns
          ?.filter((item) => item.includes('20'))
          .sort((prevYear, currYear) => currYear - prevYear)

        const groupColumns = []
        filterGroupColumns?.forEach((item) => {
          groupColumns.unshift({
            key: item,
            title: item,
          })
        })
        state.groupColumns = [{ key: 'name', title: '' }, ...groupColumns]
      },
    )
    builder.addCase(getTotalSocialInvesmentCapital.rejected, (state) => {
      state.isContentLoading = false
    })
  },
})

export const { handleTimeRange, sort } = slice.actions

export const selectLoadingTable = (state) => state[slice.name].isLoading
export const selectIds = (state) => state[slice.name].ids
export const selectDataTableById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectDataTable = (state) => state[slice.name].data
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectTimeRange = (state) => state[slice.name].timeRange
export const selectMinYear = (state) => state[slice.name].minYear
export const selectMinQuarter = (state) => state[slice.name].minQuarter

register(slice.name, slice.reducer)

const fullDataWithVal = (dataContainVal, dataWithOnlyTitle, timeRange) => {
  const isYearly = timeRange === TIME_RANGE.YEARLY
  const isAccMonthly = timeRange === TIME_RANGE.ACC_QUARTER

  const reOrderedTimeStamp = sortObjByQuarterAndYear(
    getQuarterAndYearInDataRange(dataContainVal),
  )
  const reOrderedYear = sortObjByYear(dataContainVal.map((item) => item.year))

  const getDataKeyFromRange = isYearly
    ? reOrderedYear
    : isAccMonthly
    ? reOrderedTimeStamp.map((item) =>
        getAccQuarterAndYearKey(item.quarter, item.year),
      )
    : reOrderedTimeStamp.map((item) =>
        getQuarterAndYearKey(item.quarter, item.year),
      )

  const getDataByYear = (data, year) => {
    return data
      ?.filter((item) => item.year === year)
      ?.map((item) => ({
        value: item.value ?? 0,
        year: item.year ?? 0,
        id: item.id ?? 0,
      }))
  }

  const getDataValueFromRange = isYearly
    ? reOrderedYear.map((item) => [...getDataByYear(dataContainVal, item)])
    : reOrderedTimeStamp.map((item) => [
        ...getDataByQuarterAndYear(dataContainVal, item.quarter, item.year),
      ])

  const getCorrespondingDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.id === item.id))]?.value ??
      EMPTY_VALUE
    )
  }

  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  return dataWithOnlyTitle.map((item) => ({
    ...item,
    childrenIds: getChildrenIds(dataWithOnlyTitle, item.id),
    ...getKeyAndValRange(item),
  }))
}

const getChildrenIds = (data, id) => {
  return data
    .filter((item) => item.level > 3 && item.parentId === id)
    .map((item) => item.id)
}
