import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import Dropdown from '../../../common/dropdown'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { removeAccents } from '../../../utils/Search'
import { TYPE_IMPORT_EXPORT } from './constants'
import { LIMIT_FIRST_FETCH } from './exportImportTable/constants'
import {
  changeCommodity,
  changeKeySearch,
  handleIsFirstTime,
  selectActiveId,
  selectDataTable,
  selectImportExportDataType,
  selectIsFirstTime,
  selectKeySearch,
  selectLimitQuarter,
  selectRowsCollapse,
  selectTypeFilter,
  selectUnCollapseIds,
  setRowsCollapse,
  setUnCollapseIds,
} from './exportImportTable/store/slice'
import {
  getExportByProductChildren,
  getImportByProductChildren,
} from './exportImportTable/store/thunk'
import { changeActiveCategory } from './exportStructure/growthChart/store/slice'
import style from './index.module.css'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}
const PARENT_COLLAPSE_LEVEL = 1
export const TABLE_ID = 'exportImportTable'

export const CommoditiesSearch = () => {
  const inputRef = useRef()
  const exceptOutsideRef = useRef()

  const dispatch = useDispatch()

  const data = useSelector(selectDataTable)
  const activeId = useSelector(selectActiveId)
  const rowsCollapse = useSelector(selectRowsCollapse)
  const keySearch = useSelector(selectKeySearch)
  const isFirstTime = useSelector(selectIsFirstTime)

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [eventPressKey, setEventPressKey] = useState()

  const listCategory = data
    ?.filter((item) => !item.locationId)
    .map((item) => ({
      ...item,
      locationNameWithoutAccent: removeAccents(item.name),
    }))

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  const setKeySearch = (val) => {
    dispatch(changeKeySearch(val))
  }

  const handleChangeSearch = (e) => {
    setKeySearch(e.target.value)
    if (!e.target.value && activeId) {
      dispatch(
        setRowsCollapse(
          data
            ?.filter((item) => item.level === PARENT_COLLAPSE_LEVEL)
            .map((item) => item.id),
        ),
      )
    }
  }

  const filterListCategory = (list) => {
    const keySearchWithoutAccent = removeAccents(keySearch)
    return list.filter((item) =>
      item.locationNameWithoutAccent
        ?.toLowerCase()
        .includes(keySearchWithoutAccent?.toLowerCase()),
    )
  }

  const getActiveNameById = (id) => {
    return data?.find((item) => item.vnTypeId === id)?.name ?? ''
  }

  useEffect(() => {
    if (isFirstTime) {
      dispatch(changeKeySearch(''))
    } else {
      dispatch(changeKeySearch(getActiveNameById(activeId)))
    }
  }, [getActiveNameById(activeId)])

  return (
    <div
      ref={exceptOutsideRef}
      className={`form-search-inbox ${style.inputSearch}`}
    >
      <Input
        style={{
          fontSize: 11,
          backgroundColor: '#3c424b',
        }}
        type="text"
        className="search-inbox bg-grey"
        placeholder={getActiveNameById(activeId) || listCategory[0]?.name}
        onChange={handleChangeSearch}
        onFocus={handleShow}
        onKeyDown={handlePressKey}
        ref={inputRef}
        value={keySearch}
      />
      <button type="button">
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <DropdownCommodities
        isShowDropdown={isShowDropdown}
        parentRef={inputRef}
        handleHide={handleHide}
        exceptOutsideRef={exceptOutsideRef}
        listCommodities={filterListCategory(listCategory)}
        setKeySearch={setKeySearch}
        eventPressKey={eventPressKey}
        rowsCollapse={rowsCollapse}
      />
    </div>
  )
}

const DropdownCommodities = ({
  isShowDropdown,
  parentRef,
  handleHide,
  eventPressKey,
  listCommodities,
  exceptOutsideRef,
  setKeySearch,
  rowsCollapse,
}) => {
  const dispatch = useDispatch()

  const limitQuarter = useSelector(selectLimitQuarter)
  const unCollapseIds = useSelector(selectUnCollapseIds)
  const importExportDataType = useSelector(selectImportExportDataType)
  const currentTypeFilter = useSelector(selectTypeFilter)

  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    const lengthCommodities = listCommodities.length
    if (eventPressKey?.keyCode === keyCode.ENTER) {
      if (activeIndex || activeIndex === 0)
        handleClickItem(listCommodities[activeIndex])
      else if (listCommodities[0]) handleClickItem(listCommodities[0])
      eventPressKey.preventDefault()
      eventPressKey.target.blur()
    }
    if (
      eventPressKey?.keyCode === keyCode.DOWN_ARROW &&
      lengthCommodities > 0
    ) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
      else if (activeIndex < lengthCommodities - 1)
        setActiveIndex(activeIndex + 1)
    }
    if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthCommodities > 0) {
      if (!activeIndex && activeIndex !== 0)
        setActiveIndex(lengthCommodities - 1)
      else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
    }
  }, [eventPressKey])

  const handleClickItem = (value) => {
    dispatch(changeCommodity(value.id))
    dispatch(changeActiveCategory(value.id))
    dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== value.id)))

    const scrollTable = document.querySelector(
      `#${TABLE_ID} .scrollbars div:first-child`,
    )
    const headerTable = scrollTable?.querySelector('thead')
    const activeRow = scrollTable?.querySelector(`[id="${value.id}"]`)
    if (headerTable && scrollTable && activeRow) {
      dispatch(handleIsFirstTime(false))
      setTimeout(() => {
        scrollTable.scrollTo({
          top: activeRow.offsetTop - headerTable.offsetHeight,
          behavior: 'smooth',
        })
      })
    }

    if (!unCollapseIds.includes(value.id)) {
      dispatch(setUnCollapseIds([value.id, ...unCollapseIds]))
      if (importExportDataType === TYPE_IMPORT_EXPORT.IMPORT) {
        dispatch(
          getImportByProductChildren({
            DataType: currentTypeFilter,
            Limit: limitQuarter
              ? limitQuarter + LIMIT_FIRST_FETCH
              : LIMIT_FIRST_FETCH,
            vnTypeIds: value.id,
          }),
        )
      } else {
        dispatch(
          getExportByProductChildren({
            DataType: currentTypeFilter,
            Limit: limitQuarter
              ? limitQuarter + LIMIT_FIRST_FETCH
              : LIMIT_FIRST_FETCH,
            vnTypeIds: value.id,
          }),
        )
      }
    }

    handleHide()
    setKeySearch(value.name)
  }

  return (
    <Dropdown isShow={isShowDropdown} parentRef={parentRef}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOutsideRef}
      >
        <div
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownFilter}
          id="search-ticker"
        >
          {listCommodities.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="common.NO_DATA" />
              </Span>
            </div>
          ) : (
            <ScrollComponent>
              {listCommodities.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={`${style.contentFilter} ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onClick={() => handleClickItem(item)}
                  >
                    <ItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter}`}>
                      <div className={style.textEllipsis}>{item.name}</div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

const ItemScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#search-ticker .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}
