import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class PenetrationAnalysisProxy extends ProxyBase {
  //Securities
  getSecMarginLendingMaxPeriod(params) {
    return this.get('Secu/MarginLending_GetMaxPeriod', params)
  }

  getSecProprietaryTreasuryMaxPeriod(params) {
    return this.get('Secu/ProprietaryAndTreasury_GetMaxPeriod', params)
  }

  getSecMarketShareChartData(params) {
    return this.get('Securities/MarketShareChartData', params)
  }

  getSecValueAndGrowthChartData(params) {
    return this.get('Securities/ValueAndGrowthChartData', params)
  }

  getSecChangeInMarketShareChartData(params) {
    return this.get('Securities/ChangeInMarketShareChartData', params)
  }

  getSecDetailMetrics(params) {
    return this.get('Securities/DetailMetrics', params)
  }

  getDownloadSecDetailMetrics(params) {
    return this.get('Securities/DownloadDetailMetrics', params, 'download')
  }

  //Corporate
  getCorpMaxPeriod(params) {
    return this.get('Corp/GetMaxPeriod', params)
  }

  getCorpRevenueBreakdown(params) {
    return this.get('Corp/RevenueBreakdown', params)
  }

  getCorpMarketShare(params) {
    return this.get('Corp/MarketShare', params)
  }

  getCorpRevenueType(params) {
    return this.get('Corp/GetRevenueType', params)
  }

  getCorpRevenueChart(params) {
    return this.get('Corp/RevenueChart', params)
  }

  getCorpDetailMetrics(params) {
    return this.get('Corp/DetailMetrics', params)
  }

  downloadCorpDetailMetrics(params) {
    return this.get('Corp/DownloadDetailMetrics', params, 'download')
  }

  //Insurance
  getInsuMaxPeriod(params) {
    return this.get('Insu/GetMaxPeriod', params)
  }

  getInsuRevenue(params) {
    return this.get('Insu/Revenue', params)
  }

  getInsuMarketShare(params) {
    return this.get('Insu/MarketShare', params)
  }

  getInsuChangeInMarketShare(params) {
    return this.get('Insu/ChangeInMarketShare', params)
  }

  getInsuClaimRatio(params) {
    return this.get('Insu/ClaimRatio', params)
  }

  getInsuDetailMetrics(params) {
    return this.get('Insu/DetailMetrics', params)
  }

  downloadInsuDetailMetrics(params) {
    return this.get('Insu/DownloadDetailMetrics', params, 'download')
  }

  //Bank
  getBankMaxPeriod(params) {
    return this.get('Bank/GetMaxPeriod', params)
  }

  getBankLoanToCustomer(params) {
    return this.get('Bank/Loan/LoanToCustomer', params)
  }

  getBankLoanMarketShare(params) {
    return this.get('Bank/Loan/LoanMarketShare', params)
  }

  getBankLoanChangeInMarketShare(params) {
    return this.get('Bank/Loan/LoanChangeInMarketShare', params)
  }

  getBankLoanBySector(params) {
    return this.get('Bank/Loan/LoanBySector', params)
  }

  getBankLoanMarketShareByLoanSector(params) {
    return this.get('Bank/Loan/MarketShareByLoanSector', params)
  }

  getBankLoanDetailMetrics(params) {
    return this.get('Bank/Loan/DetailMetrics', params)
  }

  downloadBankLoanDetailMetrics(params) {
    return this.get('Bank/Loan/DownloadDetailMetrics', params, 'download')
  }

  getBankDepositFromCustomer(params) {
    return this.get('Bank/Deposit/DepositFromCustomer', params)
  }

  getBankDepositMarketShare(params) {
    return this.get('Bank/Deposit/DepositMarketShare', params)
  }

  getBankDepositChangeInMarketShare(params) {
    return this.get('Bank/Deposit/DepositChangeInMarketShare', params)
  }

  getBankDepositCasa(params) {
    return this.get('Bank/Deposit/Casa', params)
  }

  getBankDepositCasaMarketShare(params) {
    return this.get('Bank/Deposit/CasaMarketShare', params)
  }

  getBankDepositDetailMetrics(params) {
    return this.get('Bank/Deposit/DetailMetrics', params)
  }

  downloadBankDepositDetailMetrics(params) {
    return this.get('Bank/Deposit/DownloadDetailMetrics', params, 'download')
  }
}

export default new PenetrationAnalysisProxy(
  ServiceProxyConfig.Corporate.PenetrationAnalysis.ServiceUrl,
)
