import { useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
// import { NoData } from '../../../../../common/noData'

import BodyBottomLeft from './bodyBottomLeft'
import BodyBottomRight from './bodyBottomRight'
import BodyTopLeft from './bodyTopLeft'
import BodyTopRight from './bodyTopRight'
import { selectLoading } from './store/slice'

const Content = ({ width }) => {
  const loading = useSelector(selectLoading)
  // const data = useSelector(selectData)

  const widthTopLeft = width * 0.175
  const widthTopCenter = width * 0.1
  const widthTopRightLabel = width * 0.325
  const widthTopRight = width * 0.225
  const widthBottomRight = width * 0.14
  const widthBottomLabelRight = width * 0.22

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <div className="d-flex mb-8">
        <BodyTopLeft widthTopLeft={widthTopLeft} />
        <div style={{ width: widthTopCenter, flexShrink: 0 }} />
        <BodyTopRight
          widthTopRightLabel={widthTopRightLabel}
          widthTopRight={widthTopRight}
        />
      </div>
      <div style={{ borderTop: '1px solid #31404F', marginBottom: '10px' }} />
      <div className="d-flex">
        <BodyBottomLeft widthTopLeft={widthTopLeft} />
        <div style={{ width: widthTopCenter, flexShrink: 0 }} />
        <BodyBottomRight
          width={width}
          widthBottomLabelRight={widthBottomLabelRight}
          widthBottomRight={widthBottomRight}
        />
      </div>
    </div>
  )
}

export default Content
