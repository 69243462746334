import { useSelector } from 'react-redux'
import { OutstandingTarget } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { selectTypeChartVal } from './commodityChart/store/slice'

const TYPE_CHART_COMMODITIES_VAL = 0
const TYPE_CHART_PARTNERS_VAL = 1

export const PanelOutstandingTarget = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const typeChartVal = useSelector(selectTypeChartVal)

  const nameScreen = UseI18n('economy.importExport.importExport.IMPORT_EXPORT')

  const getChartName = (typeChart) => {
    return typeChart === TYPE_CHART_COMMODITIES_VAL
      ? UseI18n('economy.importExport.importExport.overview.COMMODITIES_TOP_10')
      : typeChart === TYPE_CHART_PARTNERS_VAL
      ? UseI18n('economy.importExport.importExport.overview.PARTNERS_TOP_10')
      : UseI18n('economy.importExport.importExport.overview.EXCHANGE_RESERVE')
  }
  const tabName = UseI18n('economy.importExport.importExport.OVERVIEW')

  return (
    <Panel
      title="economy.importExport.importExport.overview.OUTSTANDING_TARGET"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      downloadJpgParams={{
        domId: 'outstandingTarget',
        nameScreen: nameScreen,
        chartName: getChartName(typeChartVal),
        tabName: tabName,
      }}
    >
      <OutstandingTarget
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
