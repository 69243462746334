import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { LIST_TYPE, TIME_SELECT_VALUE } from '../constants'
import { checkHnxGroup, checkIpadScreen, getNetValueChartData } from '../helps'
import { ChartContent } from './ChartContent'
import { ChartFilter } from './ChartFilter'
import style from './index.module.css'
import {
  selectData,
  selectDateTime,
  selectLoading,
  selectType,
} from './store/slice'
import {
  getNetValueForeignChart,
  getNetValueIndividualChart,
  getNetValueInstitutionChart,
  getNetValueProprietaryChart,
} from './store/thunk'

export const NetValue = ({ width, height }) => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const data = useSelector(selectData)
  const dateType = useSelector(selectDateTime)
  const type = useSelector(selectType)
  const isLoading = useSelector(selectLoading)

  // Check value
  const isHnxGroup = useMemo(() => {
    return checkHnxGroup(basicInfo.exchangeCode)
  }, [basicInfo.exchangeCode])

  const isHideChart = useMemo(
    () =>
      isHnxGroup &&
      [
        LIST_TYPE.localIndividual.value,
        LIST_TYPE.localInstitution.value,
        LIST_TYPE.proprietary.value,
      ].includes(type),
    [isHnxGroup, type],
  )

  // Get data
  const getNetValueData = useMemo(() => {
    return getNetValueChartData(data, type, dateType)
  }, [data, type, dateType])

  // Use effect
  useEffect(() => {
    if (basicInfo.organizationId) {
      switch (type) {
        case LIST_TYPE.foreign.value:
          dispatch(
            getNetValueForeignChart({
              OrganizationId: basicInfo.organizationId,
              Period: TIME_SELECT_VALUE[dateType],
            }),
          )
          break
        case LIST_TYPE.localIndividual.value:
          dispatch(
            getNetValueIndividualChart({
              OrganizationId: basicInfo.organizationId,
              Period: TIME_SELECT_VALUE[dateType],
            }),
          )
          break
        case LIST_TYPE.localInstitution.value:
          dispatch(
            getNetValueInstitutionChart({
              OrganizationId: basicInfo.organizationId,
              Period: TIME_SELECT_VALUE[dateType],
            }),
          )
          break
        case LIST_TYPE.proprietary.value:
          dispatch(
            getNetValueProprietaryChart({
              OrganizationId: basicInfo.organizationId,
              Period: TIME_SELECT_VALUE[dateType],
            }),
          )
          break
        default:
          break
      }
    }
  }, [dateType, type, basicInfo.organizationId])

  return (
    <div className={style['net-value-container']}>
      {isHnxGroup ? (
        <NoData text="common.DATA_NOT_AVAILABLE" />
      ) : (
        <SizeTracker>
          {(size) => (
            <>
              <ChartFilter
                isIpadScreen={checkIpadScreen(width, 500)}
                dateType={dateType}
              />
              {isLoading ? (
                <Loading />
              ) : (
                size.height && (
                  <div
                    id="netValue"
                    className="position-relative"
                    style={{ height: height - size.height || 0 }}
                  >
                    {getNetValueData.length && !isHideChart ? (
                      <ChartContent
                        data={getNetValueData}
                        width={width}
                        height={height - size.height}
                        dateType={dateType}
                      />
                    ) : (
                      <NoData />
                    )}
                  </div>
                )
              )}
            </>
          )}
        </SizeTracker>
      )}
    </div>
  )
}

NetValue.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
