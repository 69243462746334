import SectorInDepthProxy from '../../proxies/market/SectorInDepthProxy'
import { ServiceBase } from '../ServiceBase'

export class SectorInDepthService extends ServiceBase {
  getSectorInDepth(params) {
    return this.getData(() => SectorInDepthProxy.getSectorInDepth(params))
  }

  getTotalValueBySector(params) {
    return this.getData(() => SectorInDepthProxy.getTotalValueBySector(params))
  }

  getTopValue(params) {
    return this.getData(() => SectorInDepthProxy.getTopValue(params))
  }

  downloadSectorInDepth(params) {
    return this.getFileDownload(() =>
      SectorInDepthProxy.downloadSectorInDepth(params),
    )
  }

  downloadTopValue(params) {
    return this.getFileDownload(() =>
      SectorInDepthProxy.downloadTopValue(params),
    )
  }
}

export default new SectorInDepthService()
