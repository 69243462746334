import facebookIcon from './icon-facebook.png'
import style from '../index.module.css'
import { FacebookShareButton } from 'react-share'
import PropTypes from 'prop-types'
import { IconWrapper } from '../IconWrapper'

export const FacebookIcon = ({ url }) => {
  return (
    <IconWrapper>
      <FacebookShareButton url={url}>
        <div className={style.img}>
          <img src={facebookIcon} alt="Fac" width={72} height={72} />
          <span>Facebook</span>
        </div>
      </FacebookShareButton>
    </IconWrapper>
  )
}

FacebookIcon.propTypes = {
  url: PropTypes.string.isRequired,
}
