import * as businessModel from './businessModel'
import { common } from './common'
import { companySnapshot } from './companySnapshot'
import * as financialData from './financialData'
import { news } from './news'
import * as newsEvents from './newsEvents'
import * as overview from './overview'
import * as ownership from './ownership'
import * as peerValuation from './peerValuation'

const corporate = {
  common,
  financialData,
  overview,
  ownership,
  newsEvents,
  news,
  peerValuation,
  businessModel,
  companySnapshot,
}

export default corporate
