import { createAsyncThunk } from '@reduxjs/toolkit'
import PeerValuationService from '../../../../../../core/services/corporate/PeerValuationService'

export const getTargetPrice = createAsyncThunk(
  'corporate/peerValuation/consensus/consensusTargetPrice/GET_TARGET_PRICE',
  async (data, { rejectWithValue }) => {
    const response = await PeerValuationService.getTargetPrice(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
