import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PenetrationAnalysisService from '../../../../../../../core/services/corporate/PenetrationAnalysisService'
import { handleExport } from '../../../../../../utils/Export'

export const getCorpDetailMetrics = createAsyncThunk(
  'corporate/businessModel/penetration/corp/detailMetrics/getCorpDetailMetrics',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PenetrationAnalysisService.getCorpDetailMetrics(
        params,
      )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadCorpDetailMetrics = createAsyncThunk(
  'corporate/businessModel/penetration/corp/detailMetrics/downloadCorpDetailMetrics',
  async (data) => {
    const response = await PenetrationAnalysisService.downloadCorpDetailMetrics(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
