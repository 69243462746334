import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class EventProxy extends ProxyBase {
  getEventIds(params) {
    return this.get('EventIds', params)
  }

  getTargetIds(params) {
    return this.get('TargetIds', params)
  }

  getRelateNews(params) {
    return this.post('RelateNews', params)
  }

  getUpcomingEvents(data) {
    return this.get('UpcomingEvents', data)
  }

  getEventsDate(data) {
    return this.get('EventsDate', data)
  }
}

export default new EventProxy(ServiceProxyConfig.Economy.Event.ServiceUrl)
