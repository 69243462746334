import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import { selectValueById } from '../store/slice'
import style from './index.module.css'

export const OtherColumnCell = ({ val, rowId, className }) => {
  const level = useSelector(selectValueById(rowId, 'level'))
  const multiplier = useSelector(selectValueById(rowId, 'multiplier'))

  return (
    <td
      className={`${className} ${level === 1 ? style.borderTopTd : ''}`}
      style={{ textAlign: 'right' }}
    >
      {level === 1
        ? ''
        : !multiplier
        ? val
        : multiplier === 100
        ? valToPercent(val)
        : formatVal(val * multiplier)}
    </td>
  )
}

OtherColumnCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
}
