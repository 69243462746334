import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import Popup from '../../../../common/popup'
import { selectAddInformatics, selectFilterParams } from '../../store/slice'
import style from './index.module.css'

const PopupChangeColumn = ({ handleClose, handleSubmit }) => {
  const addedInformatics = useSelector(selectAddInformatics)
  const { addedInformations } = useSelector(selectFilterParams)

  const [keySearch, setKeySearch] = useState('')
  const [information, setInformation] = useState([])

  const [ids, setIds] = useState([])
  const [childIds, setChildIds] = useState([])
  const [initialIds, setInitialIds] = useState([])

  const handleClick = (id) => {
    setIds(!ids.includes(id) ? [...ids, id] : ids.filter((item) => item !== id))
  }

  const handleClickItem = (parentId, childId) => {
    if (!parentId || !childId) return

    const parentIndex = information.findIndex(
      (item) => item.indicatorId === parentId,
    )

    if (parentIndex === -1) return

    const childIndex = information[parentIndex].children?.findIndex(
      (child) => child.indicatorId === childId,
    )

    if (childIndex === -1) return
    setInformation([
      ...information.slice(0, parentIndex),
      {
        ...information[parentIndex],
        children: [
          ...information[parentIndex].children.slice(0, childIndex),
          {
            ...information[parentIndex].children[childIndex],
            isDefault:
              !information[parentIndex].children[childIndex]?.isDefault,
          },
          ...information[parentIndex].children.slice(childIndex + 1),
        ],
      },
      ...information.slice(parentIndex + 1),
    ])
  }

  const handleClickAllItem = (parentId) => {
    if (!parentId) return

    const parentIndex = information.findIndex(
      (item) => item.indicatorId === parentId,
    )

    if (parentIndex === -1) return

    const isCheckAll = information[parentIndex].children.every(
      (child) => child.isDefault,
    )

    setInformation([
      ...information.slice(0, parentIndex),
      {
        ...information[parentIndex],
        children: information[parentIndex].children.map((child) => ({
          ...child,
          isDefault: !isCheckAll,
        })),
      },
      ...information.slice(parentIndex + 1),
    ])
  }

  useEffect(() => {
    if (!!addedInformatics.length) {
      const parent = addedInformatics.filter(
        (item) =>
          item.indicatorId === item.parentIndicatorId ||
          !item.parentIndicatorId,
      )

      const data = parent.map((item) => {
        const children = addedInformatics.filter(
          (child) =>
            item.indicatorId === child.parentIndicatorId &&
            child.indicatorId !== child.parentIndicatorId,
        )

        const newChildren = children.map((child) => ({
          ...child,
          isDefault: addedInformations.find(
            (item) => item.indicatorId === child.indicatorId,
          ),
        }))

        return {
          ...item,
          children: newChildren,
        }
      })
      const newIds = data.map((item) => item.indicatorId)

      setInformation(data)
      setIds(newIds)
      setInitialIds(newIds)
    }
  }, [addedInformatics])

  useEffect(() => {
    if (!!information?.length) {
      if (!keySearch.length) {
        setChildIds([])
        return
      }

      const indicatorIds = []

      information.forEach((item) => {
        item?.children?.forEach((child) => {
          const indicatorNameArray = child?.indicatorName.split(' ')

          const check = indicatorNameArray.some((key) =>
            key
              ?.normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              ?.toLowerCase()
              ?.trim()
              .startsWith(
                keySearch
                  ?.normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  ?.toLowerCase()
                  ?.trim(),
                0,
              ),
          )

          const checkAll = child?.indicatorName
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            ?.toLowerCase()
            ?.trim()
            .startsWith(
              keySearch
                ?.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                ?.toLowerCase()
                ?.trim(),
              0,
            )

          if (check || checkAll) {
            indicatorIds.push(child.indicatorId)
          }
        })
      })

      setChildIds(indicatorIds)
    }
  }, [keySearch, information?.length])

  return (
    <Popup>
      <div className="modal modal-large" style={{ width: 780 }}>
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="bond.bondScreening.bondList.OPTION_PANEL" />
            </Span>
          </h3>
          <a onClick={handleClose} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content" style={{ padding: 0 }}>
          <div className="content" style={{ padding: '8px 12px' }}>
            <div
              className="form-search-inbox"
              style={{ margin: 0, marginBottom: 12 }}
            >
              <div
                style={{
                  width: 200,
                  position: 'relative',
                  boxSizing: 'border-box',
                }}
              >
                <Input
                  style={{
                    fontSize: 12,
                    backgroundColor: '#50545f ',
                    padding: '0 20px 0 8px',
                    boxSizing: 'border-box',
                  }}
                  type="text"
                  className="search-inbox"
                  placeholder={I18n.t('common.button.BUTTON_SEARCH')}
                  onChange={(e) => {
                    setKeySearch(e.target.value)
                    setIds(initialIds)
                  }}
                  value={keySearch}
                />
                <button type="button" style={{ top: 2, right: 0 }}>
                  <i className={`icon-search-2 ${style.colorIcon}`} />
                </button>
              </div>
            </div>
            <div
              className="d-flex ali-center"
              style={{
                rowGap: 24,
                columnGap: 40,
                flexWrap: 'wrap',
                alignItems: 'flex-start',
              }}
            >
              {!!information.length &&
                information.map((item) => {
                  const isCheckAll = item?.children?.some(
                    (child) => child.isDefault,
                  )

                  return (
                    <div
                      key={item.indicatorId}
                      style={{
                        flexShrink: 0,
                        width: 224,
                        minHeight: 282,
                      }}
                    >
                      <div
                        style={{
                          gap: 6,
                        }}
                        className="d-flex ali-center"
                      >
                        <div className={style.btnMinus}>
                          <button
                            onClick={() => handleClickAllItem(item.indicatorId)}
                            className="cursor-pointer"
                          >
                            {isCheckAll && '-'}
                          </button>
                        </div>
                        <i
                          className={`${style.i} ${
                            ids.includes(item.indicatorId)
                              ? 'icon-caret-down'
                              : 'icon-caret-right'
                          } cursor-pointer`}
                          onClick={() => handleClick(item.indicatorId)}
                          style={{ marginTop: 1 }}
                        />
                        <Span
                          style={{
                            color: '#FFFFFF',
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                          className="cursor-pointer"
                          onClick={() => handleClick(item.indicatorId)}
                        >
                          {item?.indicatorName ?? ''}
                        </Span>
                      </div>
                      {!!item?.children?.length &&
                        ids.includes(item.indicatorId) && (
                          <div
                            className="flex-column"
                            style={{ gap: 8, marginTop: 8 }}
                          >
                            {item?.children?.map((child) => (
                              <label
                                key={child.indicatorId}
                                className="d-flex ali-center"
                                style={{
                                  cursor: 'pointer',
                                  gap: 4,
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  checked={child?.isDefault ?? false}
                                  onChange={() =>
                                    handleClickItem(
                                      item.indicatorId,
                                      child.indicatorId,
                                    )
                                  }
                                />
                                <Span
                                  style={{
                                    color: childIds.includes(child.indicatorId)
                                      ? '#FFFFFF'
                                      : '#85939C',
                                    fontSize: 12,
                                    fontWeight: 340,
                                    opacity: childIds.includes(
                                      child.indicatorId,
                                    )
                                      ? 1
                                      : !!keySearch.length
                                      ? 0.5
                                      : 1,
                                  }}
                                >
                                  {child?.indicatorName ?? ''}
                                </Span>
                              </label>
                            ))}
                          </div>
                        )}
                    </div>
                  )
                })}
            </div>
          </div>
          <div
            className={style.groupBtn}
            style={{ marginTop: 4, padding: '0 16px 8px 16px' }}
          >
            <div className="d-flex ali-center" style={{ gap: 8 }}>
              <Button
                onClick={handleClose}
                className={`btn normal w-80 h-20`}
                style={{ fontWeight: 600 }}
              >
                <Translate value="common.button.BUTTON_CANCEL" />
              </Button>
              <Button
                onClick={() => handleSubmit(information)}
                className={`btn btn-blue w-80 h-20 `}
                style={{ fontWeight: 600 }}
              >
                <Translate value="common.button.BUTTON_APPLY" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupChangeColumn
