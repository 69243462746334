export const COMPANY_GROWTH_LINE_KEYS = [
  'productionHotCoilGrowth',
  'productionCoolCoilGrowth',
  'totalProductionGrowth',

  'consumptionHotCoilGrowth',
  'consumptionCoolCoilGrowth',
  'totalConsumptionGrowth',

  'inventoryHotCoilGrowth',
  'inventoryCoolCoilGrowth',
  'totalInventoryGrowth',
]

export const TITLE_LINE_KEYS = [
  'sector.sectorSpecific.steelCoil.HOT_ROLL',
  'sector.sectorSpecific.steelCoil.COOL_ROLL',
  'sector.sectorSpecific.steelCoil.TOTAL',
]

export const LINE_COLORS = {
  WHITE_GREEN: '#ecffdf',
  PURPLE: '#9c54e4',
  GREEN: '#c7ff58',
  ORANGE: '#f57f07',
}
