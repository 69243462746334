import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import style from './index.module.css'

const PinTooltipModal = ({ isPinTooltip, children, listKey }) => {
  const [modalRootNode, setModalRootNode] = useState()

  useEffect(() => {
    if (isPinTooltip) {
      const newModalRoot = document.createElement('div')
      document.body.appendChild(newModalRoot)
      setModalRootNode(newModalRoot)
      return () => {
        document.body.removeChild(newModalRoot)
      }
    }
  }, [isPinTooltip, listKey])

  return (
    <>
      {modalRootNode &&
        isPinTooltip &&
        listKey.length &&
        createPortal(
          <div className={style.overlayTooltip}>{children}</div>,
          modalRootNode,
        )}
    </>
  )
}

PinTooltipModal.propTypes = {
  isPinTooltip: PropTypes.bool.isRequired,
}

export default PinTooltipModal
