import LinkTicker from '../../../../common/dropdownLink/LinkTicker'

export const getTableSchema = ({ dataById }) => {
  return ['ticker', 'organizationShortName'].map((key) => {
    if (key === 'ticker') {
      return {
        colId: key,
        isI18n: false,
        tdStyle: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
        },
        render: (value, rowId) => (
          <LinkTicker organizationId={dataById[rowId]?.organizationId}>
            {value}
          </LinkTicker>
        ),
      }
    }

    return {
      colId: key,
      isI18n: false,
      tdStyle: {
        textAlign: 'left',
        whiteSpace: 'nowrap',
      },
    }
  })
}
