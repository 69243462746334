import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FundProfileService from '../../../../../../core/services/fund/FundProfileService'
import { handleExport } from '../../../../../utils/Export'

export const getBasicInformationThunk = createAsyncThunk(
  'fund/fundProfile/profile/GET_BASIC_INFORMATION',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FundProfileService.getBasicInformation(params)

      if (response.success) {
        return {
          data: response.data,
        }
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadBasicInformation = createAsyncThunk(
  'fund/fundProfile/profile/DOWNLOAD_BASIC_INFORMATION',
  async (data) => {
    const response = await FundProfileService.downloadBasicInformation(data)
    handleExport(response.data, response.filename)
  },
)
