import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { PanelConsensusHistory } from './consensusHistory/PanelConsensusHistory'
import { PanelConsensusTargetPrice } from './consensusTargetPrice/PanelConsensusTargetPrice'
import { PanelRecommendation } from './recommendation/PanelRecommendation'

const MAP_KEY = {
  RECOMMENDATION: 'RECOMMENDATION',
  CONSENSUS_TARGET_PRICE: 'CONSENSUS_TARGET_PRICE',
  CONSENSUS_HISTORY: 'CONSENSUS_HISTORY',
}

const MIN_WIDTH = 350

const MAP_SIZE = {
  [`${MAP_KEY.RECOMMENDATION}`]: {
    width: MIN_WIDTH - COMPONENT.MARGIN,
    height: `calc(70% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: MIN_WIDTH - COMPONENT.MARGIN,
    minHeight: (COMPONENT.MIN_SIZE.HEIGHT * 2.5 * 70) / 100 - COMPONENT.MARGIN,
    verticalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.CONSENSUS_TARGET_PRICE,
        ratioWidth: -1,
        ratioLeft: 0,
      },
      {
        key: MAP_KEY.CONSENSUS_HISTORY,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.CONSENSUS_TARGET_PRICE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.CONSENSUS_TARGET_PRICE}`]: {
    width: MIN_WIDTH - COMPONENT.MARGIN,
    height: `calc(30% - ${COMPONENT.MARGIN}px)`,
    top: `calc(70% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: MIN_WIDTH - COMPONENT.MARGIN,
    minHeight: (COMPONENT.MIN_SIZE.HEIGHT * 2.5 * 30) / 100 - COMPONENT.MARGIN,
    minTop: (COMPONENT.MIN_SIZE.HEIGHT * 2.5 * 70) / 100 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CONSENSUS_HISTORY}`]: {
    width: `calc(100% - ${MIN_WIDTH + COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: MIN_WIDTH + COMPONENT.MARGIN,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 2 + COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2.5,
    minLeft: MIN_WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelRecommendation
        panelRefs={panelRefs}
        panelKey={MAP_KEY.RECOMMENDATION}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.RECOMMENDATION].width - PANEL_PADDING}
        height={sizes[MAP_KEY.RECOMMENDATION].height - HEADER_PANEL_HEIGHT}
      />
      <PanelConsensusTargetPrice
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CONSENSUS_TARGET_PRICE}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.CONSENSUS_TARGET_PRICE].width - PANEL_PADDING}
        height={
          sizes[MAP_KEY.CONSENSUS_TARGET_PRICE].height - HEADER_PANEL_HEIGHT
        }
      />
      <PanelConsensusHistory
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CONSENSUS_HISTORY}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.CONSENSUS_HISTORY].width - PANEL_PADDING}
        height={sizes[MAP_KEY.CONSENSUS_HISTORY].height - HEADER_PANEL_HEIGHT}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
