import { useSelector } from 'react-redux'
import Segmentation from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import useSectorGroup from '../../../../common/hooks/useSectorGroup'
import { Panel } from '../../../../common/panel'
import { selectIndexSectorTickerInfo } from '../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { COM_GROUP } from '../../../../constants/Group'
import { formatNumber } from '../../../../utils/Common'
import { selectViewBy } from './store/slice'

const PanelSegmentation = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const viewBy = useSelector(selectViewBy)
  const sectorInfo = useSelector(selectSectorInfo)
  const typeSector = useSectorGroup(sectorInfo.icbId)
  const indexSectorTickerInfo = useSelector(selectIndexSectorTickerInfo)
  const nameScreen = UseI18n(
    typeSector === COM_GROUP.CORPORATE
      ? 'sector.sectorConstituents.overview.SEGMENTATION_REVENUE'
      : 'sector.sectorConstituents.overview.SEGMENTATION',
  )
  const chartName = UseI18n('sector.sectorConstituents.overview.SEGMENTATION')
  const tabName = UseI18n(`sector.sectorConstituents.overview.${viewBy}`)

  return (
    <Panel
      title={
        typeSector === COM_GROUP.CORPORATE
          ? 'sector.sectorConstituents.overview.SEGMENTATION_REVENUE'
          : 'sector.sectorConstituents.overview.SEGMENTATION'
      }
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg={true}
      titleJpg={UseI18n(
        typeSector === COM_GROUP.CORPORATE
          ? 'sector.sectorConstituents.overview.SEGMENTATION_REVENUE'
          : 'sector.sectorConstituents.overview.SEGMENTATION',
      )}
      downloadJpgParams={{
        domId: 'panelEarningGrowthId',
        nameScreen,
        chartName,
        tabName,
        tickerName:
          indexSectorTickerInfo.groupName ||
          indexSectorTickerInfo.icbName ||
          indexSectorTickerInfo.ticker,
      }}
    >
      <Segmentation
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default PanelSegmentation
