import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import LinkTicker from '../../../../common/dropdownLink/LinkTicker'
import { Span } from '../../../../common/html/Span'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { formatVal, valDivBillion } from '../../../../utils/Value'
import { selectTopCompaniesValue } from './store/slice'
import styles from './style.module.css'

const CellMedian = ({ val, rowId, index }) => {
  const organizationId = useSelector(
    selectTopCompaniesValue(rowId, 'organizationId'),
  )

  const value =
    index === 0 ? (
      organizationId !== EMPTY_VALUE ? (
        <LinkTicker organizationId={organizationId}>{val}</LinkTicker>
      ) : (
        val
      )
    ) : (index === 1 || index === 2) && val === EMPTY_VALUE ? (
      ''
    ) : index === 2 ? (
      formatVal(valDivBillion(val))
    ) : (
      val
    )

  if (rowId === I18n.t('sector.sectorConstituents.overview.MEDIAN')) {
    return (
      <div
        className={`${styles.cellMedian} ${
          index === 0 && styles.textBoldItalic
        }`}
      >
        <Span style={{ fontSize: 12 }}>{value}</Span>
      </div>
    )
  }
  if (
    rowId === I18n.t('sector.sectorConstituents.overview.MEAN') ||
    rowId === I18n.t('sector.sectorConstituents.overview.SECTOR')
  ) {
    return (
      <div className={`${index === 0 && styles.textBoldItalic}`}>
        <Span style={{ fontSize: 12 }}>{value}</Span>
      </div>
    )
  }
  if (index === 1 && val !== EMPTY_VALUE) {
    return <TextEllipsisStaticLine val={value} isI18n={false} />
  }
  return <Span style={{ fontSize: 12 }}>{value}</Span>
}

export default CellMedian
