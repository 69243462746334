import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { changeValueWithDot } from '../../../../utils/Value'
import { getWatchListThunk } from './thunk'

const valByKeyWithDot = (data, keyWithDot) => {
  return keyWithDot.split('.').reduce((result, key) => {
    return result?.[key]
  }, data)
}

export const keys = {
  WATCHLIST: 'watchlist',
}

const initialState = {
  loading: {
    [keys.WATCHLIST]: true,
  },
  data: {
    watchlist: [],
  },
  popupIndicator: {
    indicator: [],
    indicatorById: {},
    detail: {},
    isGroup: true,
    isEdit: false,
    mainIndicator: '',
  },
}

export const slice = createSlice({
  name: 'popup/popupIndicator',
  initialState,
  reducers: {
    resetStore(state) {
      state.popupIndicator = initialState.popupIndicator
    },
    // popup indicator
    checkAllPopupIndicator(state) {
      state.popupIndicator.indicator.forEach(
        (id) => (state.popupIndicator.indicatorById[id].check = true),
      )
    },
    uncheckAllPopupIndicator(state) {
      state.popupIndicator.indicator.forEach(
        (id) => (state.popupIndicator.indicatorById[id].check = false),
      )
    },
    checkPopupIndicator(state, action) {
      state.popupIndicator.indicatorById[action.payload].check = true
    },
    uncheckPopupIndicator(state, action) {
      state.popupIndicator.indicatorById[action.payload].check = false
    },
    removeIndicator(state) {
      const newIndicator = []
      const newIndicatorById = { ...state.popupIndicator.indicatorById }
      state.popupIndicator.indicator.forEach((id) => {
        if (newIndicatorById[id].check) {
          delete newIndicatorById[id]
        } else {
          newIndicator.push(id)
        }
      })
      state.popupIndicator.indicator = newIndicator
      state.popupIndicator.indicatorById = newIndicatorById
    },
    changeValueDetailPopupIndicator(state, action) {
      const { key, value } = action.payload
      state.popupIndicator.detail = changeValueWithDot(
        state.popupIndicator.detail,
        key,
        value,
      )
    },
    addIndicator(state, action) {
      const indicatorIds = action.payload.map(
        (indicator) => indicator.indicatorId,
      )

      state.popupIndicator.indicator = state.popupIndicator.indicator.filter(
        (id) => !indicatorIds?.includes(id),
      )

      action.payload.forEach((indicator) => {
        state.popupIndicator.indicator.push(indicator.indicatorId)
        if (!state.popupIndicator.indicatorById[indicator.indicatorId]) {
          state.popupIndicator.indicatorById[indicator.indicatorId] = {
            check: false,
            ...indicator,
          }
        }
      })
    },
    changeIsGroup(state) {
      state.popupIndicator.isGroup = !state.popupIndicator.isGroup
    },
    addDefaultDetail(state, action) {
      state.popupIndicator = { ...state.popupIndicator, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    // get exchange
    builder.addCase(getWatchListThunk.pending, (state) => {
      state.loading[keys.WATCHLIST] = true
    })
    builder.addCase(getWatchListThunk.fulfilled, (state, action) => {
      state.data.watchlist = action.payload || []
      state.loading[keys.WATCHLIST] = false
    })
    builder.addCase(getWatchListThunk.rejected, (state) => {
      state.loading[keys.WATCHLIST] = false
    })
  },
})

export const {
  resetStore,
  checkAllPopupIndicator,
  uncheckAllPopupIndicator,
  checkPopupIndicator,
  uncheckPopupIndicator,
  removeIndicator,
  changeValueDetailPopupIndicator,
  addIndicator,
  changeIsGroup,
  addDefaultDetail,
} = slice.actions

export const selectLoading = (key) => (state) => state[slice.name].loading[key]

export const selectWatchlist = (state) => state[slice.name].data.watchlist

export const selectIndicatorPopupIndicator = (state) =>
  state[slice.name].popupIndicator.indicator
export const selectIndicatorByIdPopupIndicator = (state) =>
  state[slice.name].popupIndicator.indicatorById
export const selectIndicatorPopupIndicatorCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].popupIndicator.indicatorById[id], attr)
export const selectValueDetailPopupIndicator = (key) => (state) =>
  valByKeyWithDot(state[slice.name].popupIndicator.detail, key)
export const selectPopupIndicator = (state) => state[slice.name].popupIndicator
export const selectIsGroup = (state) => state[slice.name].popupIndicator.isGroup
export const selectIsEdit = (state) => state[slice.name].popupIndicator.isEdit
export const selectMainIndicator = (state) =>
  state[slice.name].popupIndicator.mainIndicator

register(slice.name, slice.reducer)
