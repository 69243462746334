import PropTypes from 'prop-types'
import { DiamondIcon } from './DiamondIcon'

export const ScatterDiamondShape = (props) => {
  const { x, y, dataKey, color, style } = props

  if (dataKey) {
    return props[dataKey] ? (
      <DiamondIcon x={x} y={y} color={color} style={style} />
    ) : null
  }

  return <DiamondIcon color={color} style={style} />
}

ScatterDiamondShape.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  dataKey: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
}

ScatterDiamondShape.defaultProps = {
  color: '#228a9f',
  style: {},
}
