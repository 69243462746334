import MasterDataProxy from '../../../proxies/bond/comparable/masterDataProxy'

import { ServiceBase } from '../../ServiceBase'

export class MasterData extends ServiceBase {
  getIcbIdSv(params) {
    return this.getData(() => MasterDataProxy.getDataProxy(params))
  }
}

export default new MasterData()
