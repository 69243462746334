import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import Popup from '../../../common/popup'
import { debounce } from '../../../utils/Common'
import {
  changeChartTabSecurities,
  changeTreeSecuritiesIndicator,
  selectEquations,
  selectTreeSecuritiesIndicator,
  selectTypeChart,
  selectViewType,
} from '../../chart/store/slice'
import { MAX_SECURITIES_ADD, SEC_GROUP_INDICATOR } from '../../common/constants'
import { getUsedColorsBySecuritiesAndEquations } from '../../common/helpers/colorHelper'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import { selectIndicatorByTypeSecurities } from '../../store/slice'
import { changeFullSecurities, selectSecurities } from '../common/store/slice'
import { getWatchList } from '../common/store/thunk'
import { selectIndicatorSelected } from '../popupAddIndicator/store/slice'
import styles from '../styleTheme.module.css'
import {
  checkOrientationLeft,
  getSecuritiesIndicatorDataOnApply,
} from './helpers'
import ListSecurities from './listSecurities/ListSecurities'
import SecuritiesSelected from './sercuritiesSelected/SecuritiesSelected'
import style from './style.module.css'

const PopupAddSecurities = ({ setIsShow }) => {
  const dispatch = useDispatch()

  const { favoriteColors } = useGetUserSettings()

  const viewType = useSelector(selectViewType)
  const typeChart = useSelector(selectTypeChart)
  const securities = useSelector(selectSecurities)
  const treeSecuritiesIndicator = useSelector(selectTreeSecuritiesIndicator)
  const equations = useSelector(selectEquations)
  const listSecuritiesEconomy = useSelector(
    selectIndicatorByTypeSecurities(SEC_GROUP_INDICATOR.ECONOMY),
  )
  const indicatorSelected = useSelector(selectIndicatorSelected)

  const [valueSearch, setValueSearch] = useState('')
  const [securitiesState, setSecuritiesState] = useState(securities)
  const { companies, indices, sector, economy } = securitiesState

  const getUsedColors = useMemo(() => {
    return getUsedColorsBySecuritiesAndEquations(
      treeSecuritiesIndicator,
      equations,
    )
  }, [treeSecuritiesIndicator, equations])

  const haveOrientationLeft = useMemo(() => {
    return checkOrientationLeft(treeSecuritiesIndicator)
  }, [treeSecuritiesIndicator])

  const checkIsOverMaxTicker = () => {
    let count = 0
    const newArray = [companies, indices, sector, economy]
    newArray.forEach((arr) => (count += arr.length))
    return count
  }

  const handleCancel = () => {
    setIsShow(false)
  }

  const handleApply = () => {
    const result = getSecuritiesIndicatorDataOnApply({
      treeSecuritiesIndicator,
      securities,
      securitiesState,
      indicatorSelected,
      listSecuritiesEconomy,
      typeChart,
      haveOrientationLeft,
      viewType,
      usedColors: getUsedColors,
      favoriteColors,
    })

    dispatch(changeTreeSecuritiesIndicator(result))
    dispatch(changeChartTabSecurities(securitiesState))
    setIsShow(false)
    dispatch(changeFullSecurities(securitiesState))
  }

  useEffect(() => {
    dispatch(getWatchList())
  }, [])

  return (
    <Popup overlayCustom={styles.overlayCustom}>
      <div
        className={`modal modal-medium ${styles.modal}`}
        style={{ minWidth: 1012 }}
      >
        <div className={`modal-title ${styles.titleStyle}`}>
          <h3 className="text-up">
            <Span>
              <Translate value="financialChart.ADD_SECURITIES" />
            </Span>
          </h3>
          <a onClick={handleCancel} className="close-modal">
            <i className="icon-delete-stroke" style={{ color: '#8B8B8B' }} />
          </a>
        </div>
        <div
          className={`modal-content ${styles.modalContentStyle}`}
          style={{ paddingTop: 8 }}
        >
          <div className={style.searchInput}>
            <div className="form-search-inbox m-0">
              <Input
                autoFocus
                type="text"
                className={`search-inbox ${styles.inputStyle}`}
                placeholder={I18n.t('financialChart.SEARCH_SECURITIES')}
                onChange={debounce((e) => setValueSearch(e.target.value))}
              />
              <button type="button">
                <i className="icon-search-2" style={{ color: '#8b8b8b' }} />
              </button>
            </div>
          </div>
          <div className={style.modalContent}>
            <ListSecurities
              valueSearch={valueSearch}
              securitiesState={securitiesState}
              setSecuritiesState={setSecuritiesState}
            />
            <SecuritiesSelected
              securitiesState={securitiesState}
              setSecuritiesState={setSecuritiesState}
            />
          </div>
          <div className={style.selectedCount}>
            <Translate value="financialChart.SELECTED" />
            {checkIsOverMaxTicker()}/{MAX_SECURITIES_ADD}
          </div>
          {checkIsOverMaxTicker() > MAX_SECURITIES_ADD && (
            <div className={style.maxItemText}>
              <Translate value="financialChart.WANNING_ADD_SECURITIES" />
            </div>
          )}
          <div className={style.groupButtonModal}>
            <Button
              className={`btn w-100px h-20 ${styles.buttonGrayStyle}`}
              onClick={handleCancel}
            >
              <Translate value={'common.button.BUTTON_CANCEL'} />
            </Button>
            <Button
              className={`btn w-100px h-20 ${
                checkIsOverMaxTicker() > MAX_SECURITIES_ADD
                  ? style.disable
                  : undefined
              } ${styles.buttonBlueStyle}`}
              onClick={handleApply}
            >
              <Translate value={'common.button.BUTTON_APPLY'} />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupAddSecurities
