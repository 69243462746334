import { useSelector } from 'react-redux'
import NavDataTable from '.'
import { Panel } from '../../../../common/panel'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { getParams } from './helpers'
import { selectFilters } from './store/slice'
import { downloadNAVDataExport } from './store/thunk'

export const PanelNavDataTable = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const titlePanel = `fund.fundProfile.navData.${panelKey}`

  const filters = useSelector(selectFilters)
  const fundInfo = useSelector(selectFundInfo)

  const handleDownload = () => {
    const params = getParams({
      organizationId: fundInfo.organizationId,
      filters,
      isDownload: true,
    })

    return downloadNAVDataExport(params)
  }
  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={handleDownload}
    >
      <NavDataTable
        id={panelKey}
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
