import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import { listSelectIndicator } from '../constants'
import style from '../style.module.css'
import { changeStateIndicator, selectFilter } from './store/slice'

export const DropdownSearchIndicator = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilter)

  const setStateStatus = (data) => {
    dispatch(changeStateIndicator(data))
  }

  return (
    <div className={style.select}>
      <SelectCustom
        value={filter.stateIndicator}
        isI18n
        selectData={listSelectIndicator.map((item) => ({
          name: item.label,
          value: item.value,
        }))}
        handleChange={setStateStatus}
      />
    </div>
  )
}

export default DropdownSearchIndicator
