import { getLanguage } from '../../../../utils/Language'
import {
  CHART_ID,
  CHART_TYPE,
  FIRST_COL_ID,
  SECTOR_COLOR,
  TOTAL_ID,
  VALUES_TYPE,
} from './constants'

export const getSectorIds = (data, currentDate) => {
  return data
    .filter((item) => currentDate === item.date)
    .sort((a, b) => a.icbName.localeCompare(b.icbName, getLanguage()))
    .map((item) => item.icbId)
}

export const getValueById = (data, sectorIds, dates, currentDate) => {
  const result = {}

  sectorIds.forEach((sectorId) => {
    result[sectorId] = {}
    data.forEach((item) => {
      const { icbId, icbName, buyValue, sellValue, netValue, date } = item
      if (icbId === sectorId) {
        result[sectorId].icbId = icbId
        result[sectorId].icbName = icbName
        result[sectorId][date] = { buyValue, sellValue, netValue }
        result[sectorId][FIRST_COL_ID] = sectorId
      }
    })
  })

  result.total = {
    [FIRST_COL_ID]: TOTAL_ID,
    ...getTotalByCol(data, dates),
  }

  result[CHART_ID] = getChartData(data, dates, currentDate)

  return result
}

export const getTotalByCol = (data, dates) => {
  const result = {}

  dates.forEach((date) => {
    let [buyVal, sellVal, netVal] = [0, 0, 0]

    data.forEach((item) => {
      if (item.date === date) {
        buyVal += item.buyValue
        sellVal += item.sellValue
        netVal += item.netValue
      }
    })

    result[date] = {}
    result[date][VALUES_TYPE.BUY_VAL] = Math.round(buyVal * 100) / 100
    result[date][VALUES_TYPE.SELL_VAL] = Math.round(sellVal * 100) / 100
    result[date][VALUES_TYPE.NET_VAL] = Math.round(netVal * 100) / 100
  })

  return result
}

export const getChartData = (data, dates, currentDate) => {
  const result = {}

  const { topSectorIdBuy, topSectorIdSell } = getTopSectorIds(data, currentDate)

  dates.forEach((date) => {
    const dataByDay = data.filter((item) => item.date === date)

    if (dataByDay.length) {
      result[date] = {}
      result[date][VALUES_TYPE.BUY_VAL] = {}
      result[date][VALUES_TYPE.BUY_VAL][CHART_TYPE.BUY] = getTopSectors(
        dataByDay,
        topSectorIdBuy,
        CHART_TYPE.BUY,
      )
      result[date][VALUES_TYPE.BUY_VAL][CHART_TYPE.SELL] = getTopSectors(
        dataByDay,
        topSectorIdSell,
        CHART_TYPE.SELL,
      )
    }
  })

  return result
}

export const getTopSectorIds = (data, currentDate, max = 5) => {
  const dataCurrentDate = data.filter((item) => item.date === currentDate)

  const topSectorIdBuy = dataCurrentDate
    .sort((a, b) => b[CHART_TYPE.BUY] - a[CHART_TYPE.BUY])
    .slice(0, max)
    .map((sector) => sector.icbId)

  const topSectorIdSell = dataCurrentDate
    .sort((a, b) => b[CHART_TYPE.SELL] - a[CHART_TYPE.SELL])
    .slice(0, max)
    .map((sector) => sector.icbId)

  return { topSectorIdBuy, topSectorIdSell }
}

export const getSectorColor = (sectorId) => SECTOR_COLOR[sectorId] || '#fff'

export const getTopSectors = (data, topIds, type) => {
  const totalValue = Object.values(data).reduce(
    (total, current) => total + current[type],
    0,
  )

  const sortedSectors = data.sort((a, b) => b[type] - a[type])

  const topSectors = sortedSectors
    .filter((sector) => topIds.includes(sector.icbId))
    .map((sector) => ({
      icbId: sector.icbId,
      icbName: sector.icbName,
      value: sector[type],
      percent: Math.round((sector[type] / totalValue) * 10000) / 100,
      color: getSectorColor(sector.icbId),
      date: sector.date,
    }))

  const othersSectorValue =
    totalValue - topSectors.reduce((total, current) => total + current.value, 0)

  const othersSectorPercent =
    100 - topSectors.reduce((total, current) => total + current.percent, 0)

  return [
    ...topSectors,
    {
      icbId: 'others',
      icbName: 'others',
      value: Math.round(othersSectorValue * 100) / 100,
      percent: othersSectorPercent,
      color: '#ffffff',
      date: data[0].date,
    },
  ]
}
