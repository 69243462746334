import PropTypes from 'prop-types'
import { COMPONENT } from '../../../configs/Layout'
import { withWrapper } from '../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../constants/Common'
import ChartValueBySector from './chartValueBySector'
import TableSector from './tableSector'
import TableTopValue from './tableTopValue'

const MAP_KEY = {
  SECTOR_STATISTIC: 'SECTOR_STATISTIC',
  TOTAL_VALUE_BY_SECTOR: 'TOTAL_VALUE_BY_SECTOR',
  TOP_20_TOTAL_VALUE: 'TOP_20_TOTAL_VALUE',
}

const MAP_SIZE = {
  [`${MAP_KEY.SECTOR_STATISTIC}`]: {
    width: `calc(66% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(100%)`,
    top: 0,
    left: 0,
    minWidth: 660,
    minHeight: 600,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.TOTAL_VALUE_BY_SECTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.TOP_20_TOTAL_VALUE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TOTAL_VALUE_BY_SECTOR}`]: {
    width: `calc(34%)`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 'calc(66%)',
    minWidth: 340,
    minHeight: 300,
    minTop: 0,
    minLeft: 660 + COMPONENT.MARGIN,
    horizontalResize: [
      {
        key: MAP_KEY.TOP_20_TOTAL_VALUE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.TOP_20_TOTAL_VALUE}`]: {
    width: `calc(34%)`,
    height: `calc(50%)`,
    top: `calc(50%)`,
    left: 'calc(66%)',
    minWidth: 340,
    minHeight: 300,
    minTop: 300 + COMPONENT.MARGIN,
    minLeft: 660 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <TableSector
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SECTOR_STATISTIC}
        sizes={sizes}
        setSizes={setSizes}
      />
      <ChartValueBySector
        widthChart={sizes[MAP_KEY.TOTAL_VALUE_BY_SECTOR].width - PANEL_PADDING}
        heightChart={
          sizes[MAP_KEY.TOTAL_VALUE_BY_SECTOR].height - HEADER_PANEL_HEIGHT
        }
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOTAL_VALUE_BY_SECTOR}
        sizes={sizes}
        setSizes={setSizes}
      />
      <TableTopValue
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOP_20_TOTAL_VALUE}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
  ICBs: PropTypes.array.isRequired,
  loadingICB: PropTypes.bool.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
