import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import TextEllipsis from '../../textEllipsis'
import style from './index.module.css'

export const TYPE = {
  SQUARE: 'SQUARE',
  SQUARE_TWO_COLOR: 'SQUARE_TWO_COLOR',
  SQUARE_MARKER_LINE: 'SQUARE_MARKER_LINE',
  LINE: 'LINE',
  CIRCLE_MARKER_LINE: 'CIRCLE_MARKER_LINE',
  TRIANGLE_MARKER_LINE: 'TRIANGLE_MARKER_LINE',
  TRIANGLE: 'TRIANGLE',
  CIRCLE: 'CIRCLE',
  LINE_STROKE_DOT: 'LINE_STROKE_DOT',
  RECTANGLE: 'RECTANGLE',
  RECTANGULAR: 'RECTANGULAR',
  DASHED_LINE: 'DASHED_LINE',
}

export const Item = ({
  text,
  idKey,
  isOneRow,
  isI18n,
  fixedFontSize,
  isDownTheLine,
}) => {
  return (
    <div
      id={idKey}
      className={isDownTheLine ? '' : isOneRow ? style.textEllipsis : ''}
      style={{ fontSize: fixedFontSize }}
    >
      {isOneRow ? (
        <>
          {isDownTheLine ? (
            <Translate value={text} className="canvas-chart-footer" />
          ) : (
            <TextEllipsis
              isI18n={isI18n}
              text={text}
              className={[style.itemLineHeight, 'canvas-chart-footer'].join(
                ' ',
              )}
            />
          )}
        </>
      ) : isI18n ? (
        <Translate value={text} className="canvas-chart-footer" />
      ) : (
        text
      )}
    </div>
  )
}

Item.propTypes = {
  text: PropTypes.string,
  idKey: PropTypes.string.isRequired,
  isOneRow: PropTypes.bool.isRequired,
  isI18n: PropTypes.bool,
  isDownTheLine: PropTypes.bool,
}

Item.defaultProps = {
  isI18n: true,
  text: '',
}
