import deepTransactionProxy from '../../../proxies/market/equityTrading/DeepTransactionProxy'
import { ServiceBase } from '../../ServiceBase'

class DeepTransactionService extends ServiceBase {
  getDeepTransactionSummary(params) {
    return this.applyMemoryCache(
      'DeepTransactionService.getDeepTransactionSummary',
      params,
    )(() => deepTransactionProxy.getDeepTransactionSummary(params))
  }

  getDeepTransactionPricePerformance(params) {
    return this.applyMemoryCache(
      'DeepTransactionService.getDeepTransactionPricePerformance',
      params,
    )(() => deepTransactionProxy.getDeepTransactionPricePerformance(params))
  }

  getDeepTransactionMatchingOrders(params) {
    return this.getData(() =>
      deepTransactionProxy.getDeepTransactionMatchingOrders(params),
    )
  }

  getDeepTransactionTimeSaleBarChart(params) {
    return this.applyMemoryCache(
      'DeepTransactionService.getDeepTransactionTimeSaleBarChart',
      params,
    )(() => deepTransactionProxy.getDeepTransactionTimeSaleBarChart(params))
  }

  getDeepTransactionTimeSaleAreaChart(params) {
    return this.applyMemoryCache(
      'DeepTransactionService.getDeepTransactionTimeSaleAreaChart',
      params,
    )(() => deepTransactionProxy.getDeepTransactionTimeSaleAreaChart(params))
  }
}

export default new DeepTransactionService()
