import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CountDown from './CountDown'
import { selectSecondsToOpenMarket } from './store/slice'

export const PreOpenMarket = ({ children, disabled }) => {
  const secondsToOpenMarket = useSelector(selectSecondsToOpenMarket)

  if (secondsToOpenMarket === 0 || disabled) {
    return children
  }

  return <CountDown secondsToOpenMarket={secondsToOpenMarket} />
}

PreOpenMarket.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
}

PreOpenMarket.defaultProps = {
  disabled: false,
}
