import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class FundPortfolioProxy extends ProxyBase {
  getAssetAllocation(params) {
    return this.get('GetAssetAllocation', params)
  }

  getSectorAllocation(params) {
    return this.get('GetSectorAllocation', params)
  }

  getStockAllocation(params) {
    return this.get('GetStockAllocation', params)
  }

  getAssetAllocationOverTime(params) {
    return this.get('GetAssetAllocationOverTime', params)
  }

  getSectorAllocationOverTime(params) {
    return this.get('GetSectorAllocationOverTime', params)
  }

  getStockCurrentSecurityHoldings(params) {
    return this.get('GetStockCurentecurityHoldings', params)
  }

  getStockSecurityHoldingsHistory(params) {
    return this.post('GetStockSecurityHoldingsHistory', params)
  }

  downloadStockCurrentSecurityHoldings(params) {
    return this.get('GetExcelStockCurentecurityHoldings', params, 'download')
  }

  downloadStockSecurityHoldingsHistory(params) {
    return this.post(
      'GetExcelStockSecurityHoldingsHistory',
      params,
      {},
      'download',
    )
  }
}

export default new FundPortfolioProxy(
  ServiceProxyConfig.Fund.FundProfile.FundPortfolio.ServiceUrl,
)
