import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../core/services/corporate/OverviewService'
import { handleExport } from '../../../../utils/Export'

export const getSummaryInfo = createAsyncThunk(
  'corporate/overview/summary/GET_SUMMARY_INFO',
  async (data, { rejectWithValue }) => {
    const response = await OverviewService.getSummaryInfo(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadSummaryInfo = createAsyncThunk(
  'corporate/overview/summary/DOWNLOAD_SUMMARY_INFO',
  async (data) => {
    const response = await OverviewService.downloadSummaryInfo(data)
    handleExport(response.data, response.filename)
  },
)
