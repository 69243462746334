import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../utils/Common'
import { FORMAT, getCurrentDateTime } from '../../../../utils/Datetime'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import {
  BUBBLE_COLOR_TYPE,
  BUBBLE_SIZE_TYPE,
  EXCHANGE_CODE,
  INDICATOR_TYPE,
  LIST_TYPE,
  MAX_TICKER,
  PLOT_TYPE,
} from '../constant'
import { randomColor } from '../filter/company/helper'
import { handleTreeIndicators } from '../helper'
import { ICB_LEVEL, PERIOD_TYPE, TIME_SERIES_TYPE } from './../constant'
import {
  getGeneralData,
  getIndicators,
  getOrganizationsByExchange,
  getOrganizationsByIcb,
  getPointInTime,
  getSearchCompanies,
  getTemplateByShareId,
  getTemplateDetail,
  getTemplates,
  getTickerByWLId,
  getTimeSeries,
  getWatchList,
} from './thunk'

export const LOADING_TYPE = {
  CHOOSE_ITEM: 'CHOOSE_ITEM',
  ADD_GROUP: 'ADD_GROUP',
  INDICATORS: 'INDICATORS',
  CHART: 'CHART',
  GENERAL_DATA: 'GENERAL_DATA',
  TEMPLATE: 'TEMPLATE',
  DEFAULT: 'DEFAULT',
}

const currentDate = getCurrentDateTime(FORMAT.DATE)
const currentQuarter = +getCurrentDateTime(FORMAT.QUARTER)
const currentYear = +getCurrentDateTime(FORMAT.YEAR)

const initialState = {
  loading: {
    [LOADING_TYPE.CHOOSE_ITEM]: true,
    [LOADING_TYPE.ADD_GROUP]: false,
    [LOADING_TYPE.INDICATORS]: true,
    [LOADING_TYPE.CHART]: false,
    [LOADING_TYPE.GENERAL_DATA]: false,
    [LOADING_TYPE.TEMPLATE]: true,
    [LOADING_TYPE.DEFAULT]: false,
  },
  companies: [],
  watchlist: [],
  watchlistInfoById: {},
  selectedOrganizations: {},
  selectedOrganizationGroups: [],
  filteredOrganizationIds: [],
  filterIcbLevel: ICB_LEVEL[1].value,
  filterExchanges: Object.values(EXCHANGE_CODE),
  filterMarketCap: { from: null, to: null },
  isShowPopupIndicator: false,
  popupIndicatorType: INDICATOR_TYPE.X,
  indicatorInfoById: {},
  indicatorTrees: {},
  deepestIndicators: {},
  xIndicatorCategory: null,
  xIndicator: null,
  yIndicatorCategory: null,
  yIndicator: null,
  plotType: PLOT_TYPE.POINT_IN_TIME,
  timeSeriesType: TIME_SERIES_TYPE.RANGE,
  pointInTime: {
    [INDICATOR_TYPE.X]: {
      [PERIOD_TYPE.DAILY]: currentDate,
      [PERIOD_TYPE.QUARTERLY]: { quarter: currentQuarter, year: currentYear },
      [PERIOD_TYPE.YEARLY]: currentYear,
    },
    [INDICATOR_TYPE.Y]: {
      [PERIOD_TYPE.DAILY]: currentDate,
      [PERIOD_TYPE.QUARTERLY]: { quarter: currentQuarter, year: currentYear },
      [PERIOD_TYPE.YEARLY]: currentYear,
    },
  },
  timeSeries: {
    startDate: currentDate,
    endDate: currentDate,
    year: currentYear,
  },
  bubbleSize: BUBBLE_SIZE_TYPE.TOTAL_ASSETS,
  bubbleColor: BUBBLE_COLOR_TYPE.COMPANY,
  pointInTimeData: [],
  timeSeriesData: [],
  generalData: {},
  messageData: null,
  allowCallData: true,
  templates: [],
  invalidShareTemplate: false,
  selectedTemplateId: null,
  shareTemplate: null,
  loadShareTemplate: false,
  isShowTicker: true,
}

export const slice = createSlice({
  name: 'sector/sectorConstituents/segmentation',
  initialState,
  reducers: {
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeIcbLevel: (state, action) => {
      state.filterIcbLevel = action.payload
    },
    changeFilterExchanges: (state, action) => {
      state.filterExchanges = action.payload
    },
    changeFilterMarketCap: (state, action) => {
      state.filterMarketCap = { ...state.filterMarketCap, ...action.payload }
    },
    changeSelectedOrganizations: (state, action) => {
      state.selectedOrganizations = action.payload
    },
    changeSelectedOrganizationGroups: (state, action) => {
      state.selectedOrganizationGroups = action.payload
    },
    changeFilteredOrganizationIds: (state, action) => {
      state.filteredOrganizationIds = action.payload
    },
    changeShowPopupIndicator: (state, action) => {
      const { isShow, popupType } = action.payload
      state.isShowPopupIndicator = isShow
      if (popupType) {
        state.popupIndicatorType = popupType
      }
    },
    changeXIndicatorCategory: (state, action) => {
      state.xIndicatorCategory = action.payload
    },
    changeXIndicator: (state, action) => {
      state.xIndicator = action.payload
    },
    changeYIndicatorCategory: (state, action) => {
      state.yIndicatorCategory = action.payload
    },
    changeYIndicator: (state, action) => {
      state.yIndicator = action.payload
    },
    changePlotType: (state, action) => {
      state.plotType = action.payload
    },
    changeTimeSeriesType: (state, action) => {
      state.timeSeriesType = action.payload
    },
    changePointInTime: (state, action) => {
      const { indicatorType, data } = action.payload
      state.pointInTime[indicatorType] = {
        ...state.pointInTime[indicatorType],
        ...data,
      }
    },
    changeTimeSeries: (state, action) => {
      state.timeSeries = { ...state.timeSeries, ...action.payload }
    },
    changeBubbleSize: (state, action) => {
      state.bubbleSize = action.payload
    },
    changeBubbleColor: (state, action) => {
      state.bubbleColor = action.payload
    },
    changeAllowCallData: (state, action) => {
      state.allowCallData = action.payload
    },
    loadTemplate: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key]
      })
    },
    changeSlectedTemplateId: (state, action) => {
      state.selectedTemplateId = action.payload
    },
    changeIsShowTicker: (state, action) => {
      state.isShowTicker = action.payload
    },
  },
  extraReducers: (builder) => {
    //GetCompanies
    builder.addCase(getSearchCompanies.pending, (state) => {
      state.loading[LOADING_TYPE.CHOOSE_ITEM] = true
    })
    builder.addCase(getSearchCompanies.fulfilled, (state, action) => {
      state.loading[LOADING_TYPE.CHOOSE_ITEM] = false
      state.companies = action.payload
    })
    builder.addCase(getSearchCompanies.rejected, (state, action) => {
      state.loading[LOADING_TYPE.CHOOSE_ITEM] = action.payload.loading
    })
    //GetWatchList
    builder.addCase(getWatchList.pending, (state) => {
      state.loading[LOADING_TYPE.CHOOSE_ITEM] = true
    })
    builder.addCase(getWatchList.fulfilled, (state, action) => {
      state.loading[LOADING_TYPE.CHOOSE_ITEM] = false
      state.watchlist = action.payload
      state.watchlistInfoById = keyBy(action.payload, 'watchListId')
    })
    builder.addCase(getWatchList.rejected, (state, action) => {
      state.loading[LOADING_TYPE.CHOOSE_ITEM] = action.payload.loading
    })
    //GetTickerByWatchList
    builder.addCase(getTickerByWLId.pending, (state) => {
      state.loading[LOADING_TYPE.ADD_GROUP] = true
    })
    builder.addCase(getTickerByWLId.fulfilled, (state, action) => {
      state.loading[LOADING_TYPE.ADD_GROUP] = false
    })
    builder.addCase(getTickerByWLId.rejected, (state, action) => {
      state.loading[LOADING_TYPE.ADD_GROUP] = action.payload.loading
    })
    //GetTickerByIndex
    builder.addCase(getOrganizationsByExchange.pending, (state) => {
      state.loading[LOADING_TYPE.ADD_GROUP] = true
    })
    builder.addCase(getOrganizationsByExchange.fulfilled, (state, action) => {
      state.loading[LOADING_TYPE.ADD_GROUP] = false
    })
    builder.addCase(getOrganizationsByExchange.rejected, (state, action) => {
      state.loading[LOADING_TYPE.ADD_GROUP] = action.payload.loading
    })
    //GetTickerByICB
    builder.addCase(getOrganizationsByIcb.pending, (state, action) => {
      const { isSetDefault, isReset } = action.meta.arg
      if (isSetDefault) {
        state.loading[LOADING_TYPE.DEFAULT] = true
        state.selectedOrganizationGroups = []
        state.selectedOrganizations = {}
        state.filteredOrganizationIds = []
        state.filterExchanges = Object.values(EXCHANGE_CODE)
        state.filterMarketCap = { from: null, to: null }
        state.selectedTemplateId = null
        state.shareTemplate = null
        state.loadShareTemplate = false

        if (isReset) {
          state.xIndicatorCategory = null
          state.xIndicator = null
          state.yIndicatorCategory = null
          state.yIndicator = null
          state.plotType = PLOT_TYPE.POINT_IN_TIME
          state.timeSeriesType = TIME_SERIES_TYPE.RANGE
          state.pointInTime = {
            [INDICATOR_TYPE.X]: {
              [PERIOD_TYPE.DAILY]: currentDate,
              [PERIOD_TYPE.QUARTERLY]: {
                quarter: currentQuarter,
                year: currentYear,
              },
              [PERIOD_TYPE.YEARLY]: currentYear,
            },
            [INDICATOR_TYPE.Y]: {
              [PERIOD_TYPE.DAILY]: currentDate,
              [PERIOD_TYPE.QUARTERLY]: {
                quarter: currentQuarter,
                year: currentYear,
              },
              [PERIOD_TYPE.YEARLY]: currentYear,
            },
          }
          state.timeSeries = {
            startDate: currentDate,
            endDate: currentDate,
            year: currentYear,
          }
          state.bubbleSize = BUBBLE_SIZE_TYPE.TOTAL_ASSETS
          state.bubbleColor = BUBBLE_COLOR_TYPE.GROUP
          state.pointInTimeData = []
          state.timeSeriesData = []
          state.generalData = {}
        }
      } else {
        state.loading[LOADING_TYPE.ADD_GROUP] = true
      }
    })
    builder.addCase(getOrganizationsByIcb.fulfilled, (state, action) => {
      const { ICBId, isSetDefault } = action.meta.arg
      if (isSetDefault) {
        const orgs = action.payload
        const color = randomColor()
        const newGroup = orgs.length
          ? [
              {
                type: LIST_TYPE.SECTOR,
                id: ICBId,
                color,
              },
            ]
          : []
        const newOrganizations = {}
        orgs.forEach((org) => {
          newOrganizations[org.organizationId] = {
            type: LIST_TYPE.SECTOR,
            typeId: ICBId,
            organizationId: org.organizationId,
            color,
            prevGroups: [],
            marketCap: org.marketCap || 0,
            exchange: org.exchangeCode,
          }
        })
        const filteredOrgId = orgs
          .map((org) => org.organizationId)
          .slice(0, MAX_TICKER)

        state.selectedOrganizationGroups = newGroup
        state.selectedOrganizations = newOrganizations
        state.filteredOrganizationIds = filteredOrgId
        state.loading[LOADING_TYPE.DEFAULT] = false
      } else {
        state.loading[LOADING_TYPE.ADD_GROUP] = false
      }
    })
    builder.addCase(getOrganizationsByIcb.rejected, (state, action) => {
      const { isSetDefault } = action.meta.arg
      if (isSetDefault) {
        state.loading[LOADING_TYPE.DEFAULT] = false
      } else {
        state.loading[LOADING_TYPE.ADD_GROUP] = action.payload.loading
      }
    })
    //Get Indicators
    builder.addCase(getIndicators.pending, (state) => {
      state.loading[LOADING_TYPE.INDICATORS] = true
    })
    builder.addCase(getIndicators.fulfilled, (state, action) => {
      state.loading[LOADING_TYPE.INDICATORS] = false
      state.indicatorInfoById = keyBy(action.payload, 'indicatorId')
      const { data, deepestIndicators } = handleTreeIndicators(action.payload)
      state.indicatorTrees = data
      state.deepestIndicators = deepestIndicators
    })
    builder.addCase(getIndicators.rejected, (state, action) => {
      state.loading[LOADING_TYPE.INDICATORS] = action.payload.loading
    })
    //Get PointInTime
    builder.addCase(getPointInTime.pending, (state) => {
      state.loading[LOADING_TYPE.CHART] = true
      state.invalidShareTemplate = false
    })
    builder.addCase(getPointInTime.fulfilled, (state, action) => {
      state.loading[LOADING_TYPE.CHART] = false
      state.pointInTimeData = action.payload.data
      state.messageData = action.payload.message
    })
    builder.addCase(getPointInTime.rejected, (state, action) => {
      state.pointInTimeData = []
      state.loading[LOADING_TYPE.CHART] = action.payload.loading
    })
    //Get TimeSeries
    builder.addCase(getTimeSeries.pending, (state) => {
      state.loading[LOADING_TYPE.CHART] = true
      state.invalidShareTemplate = false
    })
    builder.addCase(getTimeSeries.fulfilled, (state, action) => {
      state.loading[LOADING_TYPE.CHART] = false
      state.timeSeriesData = action.payload.data
      state.messageData = action.payload.message
    })
    builder.addCase(getTimeSeries.rejected, (state, action) => {
      state.timeSeriesData = []
      state.loading[LOADING_TYPE.CHART] = action.payload.loading
    })
    //Get GeneralData
    builder.addCase(getGeneralData.pending, (state) => {
      state.loading[LOADING_TYPE.GENERAL_DATA] = true
    })
    builder.addCase(getGeneralData.fulfilled, (state, action) => {
      state.loading[LOADING_TYPE.GENERAL_DATA] = false
      state.generalData = keyBy(action.payload, 'organizationId')
    })
    builder.addCase(getGeneralData.rejected, (state, action) => {
      state.loading[LOADING_TYPE.GENERAL_DATA] = action.payload.loading
    })
    //Get Templates
    builder.addCase(getTemplates.pending, (state) => {
      state.loading[LOADING_TYPE.TEMPLATE] = true
    })
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.templates = action.payload
      state.loading[LOADING_TYPE.TEMPLATE] = false
    })
    builder.addCase(getTemplates.rejected, (state, action) => {
      state.loading[LOADING_TYPE.TEMPLATE] = action.payload.loading
    })
    //Get TemplateDetail
    builder.addCase(getTemplateDetail.pending, (state) => {
      state.loading[LOADING_TYPE.CHART] = true
    })
    builder.addCase(getTemplateDetail.fulfilled, (state, action) => {
      const templateParameters = JSON.parse(
        action.payload.templateParameters || '{}',
      )
      Object.keys(templateParameters).forEach((key) => {
        state[key] = templateParameters[key]
      })
      state.loading[LOADING_TYPE.CHART] = false
    })
    builder.addCase(getTemplateDetail.rejected, (state, action) => {
      state.loading[LOADING_TYPE.CHART] = action.payload.loading
    })
    //Get TemplateByShareId
    builder.addCase(getTemplateByShareId.pending, (state) => {
      state.loading[LOADING_TYPE.TEMPLATE] = true
      state.loadShareTemplate = false
    })
    builder.addCase(getTemplateByShareId.fulfilled, (state, action) => {
      state.shareTemplate = action.payload
      if (action.payload) {
        state.invalidShareTemplate = false
        const templateParameters = JSON.parse(action.payload.templateParameters)
        if (templateParameters)
          Object.keys(templateParameters).forEach((key) => {
            state[key] = templateParameters[key]
          })
      } else {
        state.invalidShareTemplate = true
      }
      state.loadShareTemplate = true
      state.loading[LOADING_TYPE.TEMPLATE] = false
    })
    builder.addCase(getTemplateByShareId.rejected, (state, action) => {
      state.loadShareTemplate = true
      state.invalidShareTemplate = true
      state.loading[LOADING_TYPE.TEMPLATE] = action.payload.loading
    })
  },
})

export const selectLoading = (type) => (state) =>
  state[slice.name].loading[type]

export const selectCompanies = (state) => state[slice.name].companies
export const selectWatchlist = (state) => state[slice.name].watchlist
export const selectWatchListInfoById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].watchlistInfoById[id], attr)

export const selectSelectedOrganizations = (state) =>
  state[slice.name].selectedOrganizations
export const selectSelectedOrganizationGroups = (state) =>
  state[slice.name].selectedOrganizationGroups
export const selectFilteredOrganizationIds = (state) =>
  state[slice.name].filteredOrganizationIds

export const selectFilterIcbLevel = (state) => state[slice.name].filterIcbLevel
export const selectFilterExchanges = (state) =>
  state[slice.name].filterExchanges
export const selectFilterMarketCap = (state) =>
  state[slice.name].filterMarketCap

export const selectFullIndicatorInfoById = (state) =>
  state[slice.name].indicatorInfoById
export const selectIndicatorInfoById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].indicatorInfoById[id], attr)
export const selectIndicatorTrees = (state) => state[slice.name].indicatorTrees
export const selectIndicatorsByCategory = (categoryId) => (state) =>
  categoryId
    ? state[slice.name].deepestIndicators[categoryId]
    : state[slice.name].deepestIndicators
export const selectIsShowPopupIndicator = (state) =>
  state[slice.name].isShowPopupIndicator
export const selectPopupIndicatorType = (state) =>
  state[slice.name].popupIndicatorType
export const selectXIndicatorCategory = (state) =>
  state[slice.name].xIndicatorCategory
export const selectXIndicator = (state) => state[slice.name].xIndicator
export const selectYIndicatorCategory = (state) =>
  state[slice.name].yIndicatorCategory
export const selectYIndicator = (state) => state[slice.name].yIndicator

export const selectPlotType = (state) => state[slice.name].plotType
export const selectTimeSeriesType = (state) => state[slice.name].timeSeriesType
export const selectPointInTime = (state) => state[slice.name].pointInTime
export const selectTimeSeries = (state) => state[slice.name].timeSeries
export const selectBubbleSize = (state) => state[slice.name].bubbleSize
export const selectBubbleColor = (state) => state[slice.name].bubbleColor

export const selectPointInTimeData = (state) =>
  state[slice.name].pointInTimeData
export const selectTimeSeriesData = (state) => state[slice.name].timeSeriesData
export const selectGeneralData = (state) => state[slice.name].generalData
export const selectMessageData = (state) => state[slice.name].messageData

export const selectShareTemplate = (state) => state[slice.name].shareTemplate
export const selectTemplates = (state) => state[slice.name].templates
export const selectSelectedTemplateId = (state) =>
  state[slice.name].selectedTemplateId
export const selectInvalidShareTemplate = (state) =>
  state[slice.name].invalidShareTemplate
export const selectLoadShareTemplate = (state) =>
  state[slice.name].loadShareTemplate

export const selectAllowCallData = (state) => state[slice.name].allowCallData
export const selectIsShowTicker = (state) => state[slice.name].isShowTicker

export const {
  resetStore,
  changeIcbLevel,
  changeFilterExchanges,
  changeFilterMarketCap,
  changeSelectedOrganizations,
  changeFilteredOrganizationIds,
  changeSelectedOrganizationGroups,
  changeShowPopupIndicator,
  changeXIndicatorCategory,
  changeXIndicator,
  changeYIndicatorCategory,
  changeYIndicator,
  changePlotType,
  changeTimeSeriesType,
  changePointInTime,
  changeTimeSeries,
  changeBubbleSize,
  changeBubbleColor,
  changeAllowCallData,
  loadTemplate,
  changeSlectedTemplateId,
  changeIsShowTicker,
} = slice.actions

register(slice.name, slice.reducer)
