import { I18n, Translate } from 'react-redux-i18n'
import { uuid } from '../../../utils/Common'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { DateTimeSpan } from '../../DateTimeSpan'
import { INDICATOR_TYPE, typeData } from '../constant'
import { deepCopy } from '../popupIndicator/helper'
import { typeIndexSector, typePopupIndicator } from './constant'
import IndicatorName from './popupIndicator/IndicatorName'

export const convertDataColumns = (conditionType, data, allIndicatorById) => {
  const indicators = []
  switch (conditionType) {
    case typePopupIndicator.DE02_SC09:
    case typePopupIndicator.DE02_SC11:
      data.indicator.forEach((id) => {
        const stringCheckDuplicate = JSON.stringify({ id, ...data.detail })
        indicators.push({
          alias: uuid(),
          ...data.indicatorById[id],
          stringCheckDuplicate,
        })
      })
      break
    case typePopupIndicator.DE02_SC10: {
      const conditionCheck = deepCopy(data.detail)
      delete conditionCheck?.ttmSettings?.years
      delete conditionCheck?.ttmSettings?.quarters
      delete conditionCheck?.yearlySettings?.years

      data.indicator.forEach((id) => {
        const pairIndicatorId = data.indicatorById[id]?.pairIndicator
        const yearlyPairIndicator = allIndicatorById[pairIndicatorId]
        const ttmSettings = data.detail.ttmSettings

        if (ttmSettings) {
          if (!ttmSettings.years) {
            const stringCheckDuplicate = JSON.stringify({
              id,
              ...conditionCheck.ttmSettings,
              isTtm: true,
            })

            indicators.push({
              alias: uuid(),
              indicatorType: INDICATOR_TYPE.TTM,
              ...data.indicatorById[id],
              stringCheckDuplicate,
              title: {
                date: ttmSettings?.date,
                indicatorType: INDICATOR_TYPE.TTM,
              },
            })
          } else {
            ttmSettings.years.forEach((year) => {
              ttmSettings.quarters.forEach((quarter) => {
                const condition = {
                  year,
                  quarter,
                  indicatorType: INDICATOR_TYPE.TTM,
                }
                const stringCheckDuplicate = JSON.stringify({
                  id,
                  ...condition,
                  ...conditionCheck.ttmSettings,
                  isTtm: true,
                })

                indicators.push({
                  alias: uuid(),
                  indicatorType: INDICATOR_TYPE.TTM,
                  condition,
                  ...data.indicatorById[id],
                  stringCheckDuplicate,
                  title: condition,
                })
              })
            })
          }
        }
        const yearlySettings = data.detail.yearlySettings
        if (yearlySettings) {
          if (!yearlySettings.years) {
            const stringCheckDuplicate = JSON.stringify({
              id: pairIndicatorId || id,
              ...conditionCheck.yearlySettings,
              isYearly: true,
            })

            indicators.push({
              alias: uuid(),
              indicatorType: INDICATOR_TYPE.YEARLY,
              ...(yearlyPairIndicator || data.indicatorById[id]),
              stringCheckDuplicate,
              title: { indicatorType: INDICATOR_TYPE.YEARLY },
            })
          } else {
            yearlySettings.years.forEach((year) => {
              const condition = { year, indicatorType: INDICATOR_TYPE.YEARLY }
              const stringCheckDuplicate = JSON.stringify({
                id: pairIndicatorId || id,
                ...condition,
                ...conditionCheck.yearlySettings,
                isYearly: true,
              })

              indicators.push({
                alias: uuid(),
                indicatorType: INDICATOR_TYPE.YEARLY,
                condition,
                ...(yearlyPairIndicator || data.indicatorById[id]),
                stringCheckDuplicate,
                title: condition,
              })
            })
          }
        }
      })
      break
    }
    case typePopupIndicator.DE02_SC12: {
      const conditionCheck = deepCopy(data.detail)
      delete conditionCheck?.years
      delete conditionCheck?.quarters

      data.indicator.forEach((id) => {
        if (!data.detail.years) {
          const stringCheckDuplicate = JSON.stringify({
            id,
            ...conditionCheck,
          })

          indicators.push({
            alias: uuid(),
            ...data.indicatorById[id],
            stringCheckDuplicate,
          })
        } else {
          data.detail.years.forEach((year) => {
            data.detail.quarters.forEach((quarter) => {
              const condition = { year, quarter }
              const stringCheckDuplicate = JSON.stringify({
                id,
                ...condition,
                ...conditionCheck,
              })

              indicators.push({
                alias: uuid(),
                condition,
                ...data.indicatorById[id],
                stringCheckDuplicate,
                title: condition,
              })
            })
          })
        }
      })
      break
    }
    default:
      break
  }
  return indicators
}

export const convertParamGetMostRecent = (indicator) => {
  const indicators = []
  indicator.forEach(({ indicatorId, conditionType }) => {
    if (
      ![typePopupIndicator.DE02_SC10, typePopupIndicator.DE02_SC12].includes(
        conditionType,
      )
    ) {
      indicators.push({ indicatorId, dataType: typeIndexSector.INDEX })
    } else {
      indicators.push({
        indicatorId,
        indicatorType: INDICATOR_TYPE.TTM,
        dataType: typeIndexSector.INDEX,
      })
      indicators.push({
        indicatorId,
        indicatorType: INDICATOR_TYPE.YEARLY,
        dataType: typeIndexSector.INDEX,
      })
    }
  })
  return {
    indicatorGroup: typeData.INDEX_SECTOR,
    indicators,
  }
}

export const handleNameIndicator = (
  indicator = {},
  detail,
  conditionType,
  condition,
  mostRecent,
) => {
  switch (conditionType) {
    case typePopupIndicator.DE02_SC09:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.DATE" />:{' '}
            {detail.tradingDate ? (
              <DateTimeSpan date={detail.tradingDate} />
            ) : detail.time === 'MostRecent' ? (
              <DateTimeSpan date={mostRecent.date} />
            ) : (
              <Translate value={`tool.dataExplorer.corporate.${detail.time}`} />
            )}
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE02_SC10:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            {condition?.indicatorType === INDICATOR_TYPE.YEARLY ? (
              <Translate value="tool.dataExplorer.corporate.YEARLY_RATIO" />
            ) : (
              <Translate value="tool.dataExplorer.corporate.TTM_RATIO" />
            )}
          </div>
          {condition?.year && !condition?.quarter && (
            <div>
              <Translate value="tool.dataExplorer.corporate.YEAR" />:{' '}
              {condition.year}
            </div>
          )}
          {condition?.year && condition?.quarter && (
            <div>
              <Translate value="tool.dataExplorer.corporate.QUARTER" />: Q
              {condition.quarter}.{condition.year}
            </div>
          )}
          {!condition?.year && !condition?.quarter && (
            <div>
              {condition?.indicatorType === INDICATOR_TYPE.YEARLY ? (
                <>
                  <Translate value="tool.dataExplorer.corporate.YEAR" />:{' '}
                  {mostRecent.year}
                </>
              ) : (
                <>
                  {condition?.date || mostRecent.date ? (
                    <>
                      <Translate value="tool.dataExplorer.corporate.DATE" />:{' '}
                      <DateTimeSpan date={condition?.date || mostRecent.date} />
                    </>
                  ) : (
                    <>
                      <Translate value="tool.dataExplorer.corporate.QUARTER" />:{' '}
                      Q{mostRecent.quarter}.{mostRecent.year}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE02_SC11:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.DATE" />:{' '}
            {detail.mostRecent ? (
              <DateTimeSpan date={mostRecent.date} />
            ) : (
              <DateTimeSpan date={detail.date} />
            )}
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE02_SC12:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          {detail.period === 'MostRecentQuarter' && (
            <div>
              <Translate value="tool.dataExplorer.corporate.QUARTER" />:{' '}
              {mostRecent.quarter}
            </div>
          )}
          {['MostRecentQuarter', 'MostRecentYear'].includes(detail.period) && (
            <div>
              <Translate value="tool.dataExplorer.corporate.YEAR" />:{' '}
              {mostRecent.year}
            </div>
          )}
          {condition?.quarter && (
            <div>
              <Translate value="tool.dataExplorer.corporate.QUARTER" />:{' '}
              <Translate
                value={`tool.dataExplorer.corporate.${condition.quarter}`}
              />
            </div>
          )}
          {condition?.year && (
            <div>
              <Translate value="tool.dataExplorer.corporate.YEAR" />:{' '}
              {typeof condition.year === 'number' ? (
                condition.year
              ) : (
                <Translate
                  value={`tool.dataExplorer.corporate.${condition.year}`}
                />
              )}
            </div>
          )}
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    default:
      return ''
  }
}

const newLine = '\n'
const space = ': '

export const getHeaderIndicator = (
  indicator,
  detail,
  conditionType,
  condition,
  indicatorById,
  _,
  __,
  locale,
  timezone,
  mostRecent,
) => {
  switch (conditionType) {
    case typePopupIndicator.DE02_SC09:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.DATE') +
        space +
        (detail.time === 'MostRecent'
          ? formatDateTime(mostRecent.date, FORMAT.DATE, locale, timezone)
          : !detail.tradingDate
          ? I18n.t(`tool.dataExplorer.corporate.${detail.time}`)
          : formatDateTime(detail.tradingDate, FORMAT.DATE, locale, timezone)) +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE02_SC10:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        (condition?.indicatorType === INDICATOR_TYPE.YEARLY
          ? I18n.t('tool.dataExplorer.corporate.YEARLY_RATIO')
          : I18n.t('tool.dataExplorer.corporate.TTM_RATIO')) +
        newLine +
        (condition?.year && condition?.quarter
          ? I18n.t('tool.dataExplorer.corporate.QUARTER') +
            space +
            'Q' +
            condition.quarter +
            '.' +
            condition.year +
            newLine
          : '') +
        (condition?.year && !condition?.quarter
          ? I18n.t('tool.dataExplorer.corporate.YEAR') +
            space +
            condition.year +
            newLine
          : '') +
        (!condition?.year && !condition?.quarter
          ? condition?.indicatorType === INDICATOR_TYPE.YEARLY
            ? I18n.t('tool.dataExplorer.corporate.YEAR') +
              space +
              mostRecent.year +
              newLine
            : condition?.date || mostRecent.date
            ? I18n.t('tool.dataExplorer.corporate.DATE') +
              space +
              formatDateTime(
                condition?.date || mostRecent.date,
                FORMAT.DATE,
                locale,
                timezone,
              ) +
              newLine
            : I18n.t('tool.dataExplorer.corporate.QUARTER') +
              space +
              'Q' +
              mostRecent.quarter +
              '.' +
              mostRecent.year +
              newLine
          : '') +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE02_SC11:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.DATE') +
        space +
        (detail.mostRecent
          ? formatDateTime(mostRecent.date, FORMAT.DATE, locale, timezone)
          : formatDateTime(detail.date, FORMAT.DATE, locale, timezone)) +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE02_SC12:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        (detail.period === 'MostRecentQuarter'
          ? I18n.t('tool.dataExplorer.corporate.QUARTER') +
            space +
            mostRecent.quarter +
            newLine
          : '') +
        (['MostRecentQuarter', 'MostRecentYear'].includes(detail.period)
          ? I18n.t('tool.dataExplorer.corporate.YEAR') +
            space +
            mostRecent.year +
            newLine
          : '') +
        (condition?.quarter
          ? I18n.t('tool.dataExplorer.corporate.QUARTER') +
            space +
            I18n.t(`tool.dataExplorer.corporate.${condition.quarter}`) +
            newLine
          : '') +
        (condition?.year
          ? I18n.t('tool.dataExplorer.corporate.YEAR') +
            space +
            (typeof condition.year === 'number'
              ? condition.year
              : I18n.t(`tool.dataExplorer.corporate.${condition.year}`)) +
            newLine
          : '') +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    default:
      return ''
  }
}

const spaceCharacter = '_'

export const encodeId = (id, isIndex) => {
  if (isIndex) {
    return id + spaceCharacter + 'Index'
  }
  return id + spaceCharacter + 'Sector'
}

export const decodeId = (encodeId) => {
  const [id, type] = encodeId.split(spaceCharacter)
  return {
    type,
    id,
  }
}

export const convertDataId = (id) => {
  const { type, id: indexSectorId } = decodeId(id)
  return { id: indexSectorId, dataType: type }
}
