import { createAsyncThunk } from '@reduxjs/toolkit'
import SimpleSearchService from '../../../../core/services/common/SimpleSearchService'

export const searchCompanyThunk = createAsyncThunk(
  'common/simpleSearchBond/SEARCH_COMPANY',
  async (data, { rejectWithValue }) => {
    const response = await SimpleSearchService.searchCompany(data.data)
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const searchCompanyOrIndexThunk = createAsyncThunk(
  'common/simpleSearchBond/SEARCH_COMPANY_OR_INDEX',
  async (data, { rejectWithValue }) => {
    const response = await SimpleSearchService.searchCompanyOrIndex(data.data)
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)
