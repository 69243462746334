import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import VerticalLine from '../../../../common/topInfo/components/VerticalLine'
import SelectYear from '../../common/SelectYear'
import {
  getLocationId,
  getRangeYear,
  getYear,
  handleLocationId,
  handleYear,
  selectLocationName,
} from './store/slice'

const SelectGroup = () => {
  const dispatch = useDispatch()

  const year = useSelector(getYear)
  const locationId = useSelector(getLocationId)
  const locationName = useSelector(selectLocationName)
  const rangeYear = useSelector(getRangeYear)

  const selectData = locationName.map((item) => {
    return {
      name: item.locationName,
      value: item.locationId,
    }
  })

  const changeYear = (year) => {
    dispatch(handleYear(year))
  }

  const changeLocation = (locationId) => {
    dispatch(handleLocationId(locationId))
  }

  return (
    <div className="d-flex align-center">
      <div style={{ width: 182 }}>
        <SelectCustom
          isI18n={true}
          selectData={selectData}
          value={locationId}
          handleChange={changeLocation}
          appendStyle={{ fontWeight: 'normal' }}
        />
      </div>
      <VerticalLine ml={16} mr={16} styleProps={{ height: 20 }} />
      <SelectYear
        year={year}
        changeYear={changeYear}
        isFlexEnd={false}
        yearOptions={rangeYear}
      />
    </div>
  )
}

export default SelectGroup
