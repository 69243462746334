export const overview = {
  SECTOR_SUMMARY: 'OVERVIEW',
  SECTOR: 'SECTOR',
  ICB_LEVEL: 'ICB Level',
  PERCENT_MARKET_CAP: '% MARKET CAP',
  PERCENT_INDEX_CHANGE: '%1D CHANGE',
  VALUATION: 'VALUATION',
  ANNUAL: 'Annual',
  MARKET_CAP: 'MARKET CAP',
  SECTORS_REPORT: 'SECTORS REPORT',
  PRICE_PERFORMANCE: 'Price Performance',
  ANNOUNCEMENT_DATE: 'Public Date',
  TICKER: 'Ticker',
  COMPANY_NAME: 'Issuer',
  TITLE: 'Title',
  RELATIVE: 'Relative',
  ABSOLUTE: 'Absolute',
  MESS_WARNING_LIMIT_ADD_SECTOR:
    'The number of selected items is no more than 20',
  RANGE_PE: 'P/E Range',
  RANGE_PB: 'P/B Range',
  RANGE_EV_EBITDA: 'EV/EBITDA Range',
  AVERAGE_PE: 'P/E Average',
  AVERAGE_PB: 'P/B Average',
  AVERAGE_EV_EBITDA: 'EV/EBITDA Average',
  CURRENT_PE: 'Current P/E',
  CURRENT_PB: 'Current P/B',
  CURRENT_EV_EBITDA: 'Current EV/EBITDA',
  MIN_PE: 'P/E Min',
  MIN_PB: 'P/B Min',
  MIN_EV_EBITDA: 'EV/EBITDA Min',
  MAX_PE: 'P/E Max',
  MAX_PB: 'P/B Max',
  MAX_EV_EBITDA: 'EV/EBITDA Max',
  OTHERS: 'Others',
  All: 'ALL',
  MARKET_CAP_CHART: 'MARKET CAP OF SECTOR L',
  CHART_POINT: 'Point',
}

export const ratio = {
  INCOME_PERFORMANCE: 'INCOME PERFORMANCE',
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  REVENUE: 'REVENUE GROWTH',
  EBIT: 'EBIT GROWTH',
  LNST: 'PAT GROWTH',
  EPS: 'EPS GROWTH',
  DETAIL: 'DETAIL',
  LISTED: 'Listed',
  ALL: 'All',
  FILTER: 'Filter',
  SEARCH: 'Search',
  ICB_LEVEL: 'Level',
  SECTOR: 'SECTOR',
  LEVEL: 'LEVEL',
  LEVEL_2: 'Level',
  PRIVATE: 'Private',
  SECTOR_EXIST: 'Sector is already existed.',
  ANNUAL: 'Annual',
}
