import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getCoveredWarrantBreakEvenPoint } from './thunk'

const initialState = {
  data: [],
  loading: true,
}

export const slice = createSlice({
  name: 'market/coveredWarrant/breakEvenPoint',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCoveredWarrantBreakEvenPoint.pending, (state) => {
      state.loading = true
      state.data = []
    })
    builder.addCase(
      getCoveredWarrantBreakEvenPoint.fulfilled,
      (state, action) => {
        state.loading = false
        const data = [...(action.payload.data || [])]
          .sort(
            (a, b) =>
              new Date(a.tradingDateId).getTime() -
              new Date(b.tradingDateId).getTime(),
          )
          .map((item) => {
            const objData = {}
            Object.keys(item).forEach((key) => {
              objData[key] = item[key]
            })
            return objData
          })
        state.data = data.map((item) => ({
          ...item,
        }))
      },
    )
    builder.addCase(
      getCoveredWarrantBreakEvenPoint.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
  },
})

export const { changeTimeRange } = slice.actions
export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
