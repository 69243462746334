import PropTypes from 'prop-types'
import { Fragment, useMemo } from 'react'
import { ChartContainer } from '../rechart/ChartContainer'
import { Tooltip } from './Tooltip'
import { YAXIS_ORIENTATION } from './constants'
import { getCharts, getXAxis, getYAxis } from './helpers'
import { chartProps, schemaProps } from './props'

export const Chart = ({
  data,
  width,
  height,
  hoverActiveItem,
  colors,
  schema,
  chartProps,
  tooltipProps,
}) => {
  const { layout, xAxis, yAxis, isUseXAxisDiv, showAllTick } = chartProps

  const yAxisProps = getYAxis({ yAxis, schema })
  const xAxisProps = getXAxis({ xAxis, schema })

  const isYAxisLeft = useMemo(() => {
    if (chartProps?.yAxis) {
      return chartProps?.yAxis.some(
        (item) =>
          !item.orientation || item.orientation === YAXIS_ORIENTATION.LEFT,
      )
    }
    return true
  }, [chartProps?.yAxis])

  const isYAxisRight = useMemo(() => {
    if (chartProps?.yAxis) {
      return chartProps?.yAxis.some(
        (item) => item.orientation === YAXIS_ORIENTATION.RIGHT,
      )
    }
    return false
  }, [chartProps?.yAxis])

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height}
      layout={layout}
      keyXAxis={xAxisProps.dataKey}
      isUseXAxisDiv={isUseXAxisDiv}
      showAllTick={showAllTick}
      xTickNum={xAxisProps.tickCount}
      xAxisLabel={xAxisProps.label}
      xAxisPosition={xAxisProps.labelPosition}
      yAxis={yAxisProps}
      tickFormatter={xAxisProps.tickFormat}
      margin={{
        left: isYAxisLeft ? 12 : 48,
        right: isYAxisRight ? 12 : 48,
        bottom: 8,
      }}
      renderCustomTooltip={(value) => (
        <Tooltip
          data={value}
          schema={schema}
          xAxis={xAxisProps}
          tooltipProps={tooltipProps}
        />
      )}
      interval={0}
    >
      {({ chartContentWidth }) => {
        const charts = getCharts({
          colors,
          schema,
          yAxis: yAxisProps,
          data,
          chartContentWidth,
          hoverActiveItem,
        })
        return charts.map((chart, index) => (
          <Fragment key={index}>{chart}</Fragment>
        ))
      }}
    </ChartContainer>
  )
}

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  hoverActiveItem: PropTypes.string,
  schema: schemaProps,
  chartProps: chartProps,
}
