import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { FORMAT, formatDateTimeLocal } from '../../../utils/Datetime'
import { Span } from '../../html/Span'
import { placeholderDateCalendar } from '../helper'
import style from '../index.module.css'

const ButtonDateCalendar = forwardRef(({ date, onClick }, ref) => {
  const locale = useSelector((state) => state.i18n.locale)

  return (
    <button onClick={onClick} className={style.buttonDateCalendar} ref={ref}>
      <div className={style.inputDate}>
        <Span style={{ fontSize: 12 }}>
          {date
            ? formatDateTimeLocal(date, FORMAT.CALENDAR, locale)
            : placeholderDateCalendar(locale)}
        </Span>
      </div>
      <i className={`icon-date ${style.iconCalendar}`} />
    </button>
  )
})

export default ButtonDateCalendar
