import { useMemo, useState } from 'react'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { TabLink } from '../../../common/tabs/TabLink'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { OVERVIEW_TABS } from './constants'
import Performance from './performance'

export const FundCenterOverview = () => {
  const [tab, setTab] = useState(OVERVIEW_TABS.PERFORMANCE)

  const renderComponent = useMemo(() => {
    switch (tab) {
      case OVERVIEW_TABS.ASSET_ALLOCATION:
        return null
      case OVERVIEW_TABS.PERFORMANCE:
        return <Performance />
      default:
        return null
    }
  }, [tab])

  return (
    <div className="h-100 position-relative">
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="fund.fundCenter.OVERVIEW" />
              <VerticalLine />
              <div style={{ marginTop: 6 }}>
                <TabLink
                  defaultTab={OVERVIEW_TABS.PERFORMANCE}
                  value={tab}
                  tabs={Object.keys(OVERVIEW_TABS).map((key) => ({
                    title: `fund.fundCenter.overview.${key}`,
                    value: OVERVIEW_TABS[key],
                  }))}
                  is18n
                  onClick={(item) => setTab(item.value)}
                />
              </div>
            </div>
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <ScrollGroupComponent>
                  <div className="position-relative w-100 h-100">
                    {renderComponent}
                  </div>
                </ScrollGroupComponent>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}
