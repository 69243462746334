import { Panel } from '../../../../common/panel'
import GDPGrowthForecast from './GDPGrowthForecast'
import { downloadGDPGrowthForecast } from './store/thunk'

const PanelGDPGrowthForecast = ({ panelRefs, panelKey, sizes, setSizes }) => {
  return (
    <Panel
      title={'economy.gdp.gdp.GDP_GROWTH_FORECAST'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadGDPGrowthForecast}
    >
      <GDPGrowthForecast />
    </Panel>
  )
}

export default PanelGDPGrowthForecast
