import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialDataService from '../../../../../../core/services/corporate/FinancialDataService'

export const getBalanceSheetStructureChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_BALANCE_SHEET_STRUCTURE_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialDataService.getBalanceSheetStructureChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getDGAndCGChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_DG_AND_CG_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getDGAndCGChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTOIStructureChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_TOI_STRUCTURE_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getTOIStructureChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getProfitAndLossChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_PROFIT_AND_LOSS_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getProfitAndLossChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getCashFlowStructureChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_CASH_FLOW_STRUCTURE_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getCashFlowStructureChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOperatingCashFlowChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_OPERATING_CASH_FLOW_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getOperatingCashFlowChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getLoansByIndustryChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_LOANS_BY_INDUSTRY_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getLoansByIndustryChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getLoansByTermChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_LOANS_BY_TERM_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getLoansByTermChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFinancialRatioChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_FINANCIAL_RATIO_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getFinancialRatioChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getPeersComparisonChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_PEERS_COMPARISON_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getPeersComparisonChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getAssetBreakdownChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_ASSET_BREAKDOWN_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getAssetBreakdownChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOperatingSalesStructureChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_OPERATING_SALES_STRUCTURE_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialDataService.getOperatingSalesStructureChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOperatingExpensesStructureChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_OPERATING_EXPENSES_STRUCTURE_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialDataService.getOperatingExpensesStructureChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getMarginLendingChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_MARGIN_LENDING_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getMarginLendingChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFinancialAssetsStructureChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_FINANCIAL_ASSETS_STRUCTURE_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialDataService.getFinancialAssetsStructureChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getSTAndLTBorrowingChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_ST_AND_LT_BORROWING_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getSTAndLTBorrowingChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getRevenueAndProfitChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_REVENUE_AND_PROFIT_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getRevenueAndProfitChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getProfitabilityChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_PROFITABILITY_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getProfitabilityChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getInventoryStructureChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_INVENTORY_STRUCTURE_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialDataService.getInventoryStructureChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFinancialExpensesChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_FINANCIAL_EXPENSES_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getFinancialExpensesChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getReservesChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_RESERVES_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getReservesChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTICSEChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_TICSE_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getTICSEChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getInsurancePremiumBreakdownChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_INSURANCE_PREMIUM_BREAKDOWN_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialDataService.getInsurancePremiumBreakdownChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFinancialIncomeChartData = createAsyncThunk(
  'corporate/financialData/financialStatement/chart/GET_FINANCIAL_INCOME_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getFinancialIncomeChartData(
        data,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
