import { COMPONENT } from '../../../configs/Layout'
import { withWrapper } from '../../common/withWrapper'
import History from './history'
import InfoSec from './infoSec'
import InfoValuation from './infoValuation'
import SameIndusdyBond from './sameIndustryBond'

const MAP_KEY = {
  INFO_SEC: 'INFO_SEC',
  INFO_VALUATION: 'INFO_VALUATION',
  SAME_INDUSTRY_BOND: 'SAME_INDUSTRY_BOND',
  HISTORY: 'HISTORY',
}

const MAP_SIZE = {
  [`${MAP_KEY.INFO_SEC}`]: {
    width: `calc(30%  - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: 400,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    disableVerticalResize: false,
    disableHorizontalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.INFO_VALUATION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.HISTORY,
        ratioWidth: -1,
        ratioLeft: 0,
      },
    ],

    horizontalResize: [
      {
        key: MAP_KEY.HISTORY,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [`${MAP_KEY.INFO_VALUATION}`]: {
    width: `calc(40% - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(100% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 'calc(30%)',
    minWidth: 500,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2 - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 400 + 3.5 * COMPONENT.MARGIN,
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.SAME_INDUSTRY_BOND,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [`${MAP_KEY.SAME_INDUSTRY_BOND}`]: {
    width: `calc(30%)`,
    height: `calc(100% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 'calc(70%)',
    minWidth: 400,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2 - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 900 + 2 * 3.5 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.HISTORY}`]: {
    width: `calc(30% - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: `calc(50%)`,
    left: 0,
    minWidth: 400,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - 3.5 * COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.INFO_VALUATION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.HISTORY,
        ratioWidth: -1,
        ratioLeft: 0,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.INFO_SEC,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <InfoSec
        panelRefs={panelRefs}
        panelKey={MAP_KEY.INFO_SEC}
        sizes={sizes}
        setSizes={setSizes}
      />
      <InfoValuation
        panelRefs={panelRefs}
        panelKey={MAP_KEY.INFO_VALUATION}
        sizes={sizes}
        setSizes={setSizes}
      />
      <SameIndusdyBond
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SAME_INDUSTRY_BOND}
        sizes={sizes}
        setSizes={setSizes}
      />
      <History
        panelRefs={panelRefs}
        panelKey={MAP_KEY.HISTORY}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
