import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import {
  defineGroupColumns,
  findMinMonthYear,
  handleIds,
  keyById,
} from '../helper'
import {
  getMaxDate,
  getStatisticStateBank,
  getStatisticStateBankName,
} from './thunk'

export const CURRENT_YEAR = new Date().getFullYear()
export const CURRENT_MONTH = new Date().getMonth() + 1

const initialState = {
  fromMonth: undefined,
  fromYear: undefined,
  toMonth: undefined,
  toYear: undefined,
  tableDataLoading: true,
  tableMappingLoading: true,
  tableData: {},
  ids: [],
  groupColumns: [],
  isAllowCallApi: true,
  isFirstTime: true,
  limitDate: {
    minDate: '',
    maxDate: '',
    minYear: '',
    maxYear: '',
    minMonth: '',
    maxMonth: '',
  },
  minMonthYear: { minMonth: 0, minYear: 0 },
}

const slice = createSlice({
  name: 'economy/monetary/interestRate/panelDepositRateVietNam',
  initialState,
  reducers: {
    handleFromMonth: (state, action) => {
      state.fromMonth = action.payload
    },
    handleFromYear: (state, action) => {
      state.fromYear = action.payload
    },
    handleToMonth: (state, action) => {
      state.toMonth = action.payload
    },
    handleToYear: (state, action) => {
      state.toYear = action.payload
    },
    handleGroupColumns: (state, action) => {
      state.groupColumns = action.payload
    },
    handleIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload
    },
    handleIsAllowCallApi: (state, action) => {
      state.isAllowCallApi = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatisticStateBank.pending, (state) => {
      state.tableDataLoading = true
    })
    builder.addCase(getStatisticStateBank.fulfilled, (state, action) => {
      const data = action.payload || []
      state.tableDataLoading = false
      state.tableData = keyById(data, state.tableData, state.isFirstTime)
      state.groupColumns = defineGroupColumns(
        data,
        state.groupColumns,
        state.isFirstTime,
      )
      state.minMonthYear = findMinMonthYear(data)
      if (!data?.length) {
        state.isAllowCallApi = false
      }
    })
    builder.addCase(getStatisticStateBank.rejected, (state, action) => {
      state.tableDataLoading = action.payload.isLoading
    })
    builder.addCase(getStatisticStateBankName.pending, (state) => {
      state.tableMappingLoading = true
    })
    builder.addCase(getStatisticStateBankName.fulfilled, (state, action) => {
      state.tableMappingLoading = false
      state.ids = handleIds(action.payload || [])
    })
    builder.addCase(getStatisticStateBankName.rejected, (state, action) => {
      state.tableMappingLoading = action.payload.isLoading
    })
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.limitDate = action.payload
    })
  },
})

export const {
  handleFromMonth,
  handleFromYear,
  handleToMonth,
  handleToYear,
  handleGroupColumns,
  handleIsFirstTime,
  handleIsAllowCallApi,
} = slice.actions

export const getFromMonth = (state) => state[slice.name].fromMonth
export const getFromYear = (state) => state[slice.name].fromYear
export const getToMonth = (state) => state[slice.name].toMonth
export const getToYear = (state) => state[slice.name].toYear
export const getTableDataLoading = (state) => state[slice.name].tableDataLoading
export const getTableMappingLoading = (state) =>
  state[slice.name].tableMappingLoading
export const getTableData = (state) => state[slice.name].tableData
export const getDepositRateVietNamValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].tableData[id], attr)
export const getIds = (state) => state[slice.name].ids
export const getIsAllowCallApi = (state) => state[slice.name].isAllowCallApi
export const getIsFirsTime = (state) => state[slice.name].isFirstTime
export const getMinMonthYear = (state) => state[slice.name].minMonthYear
export const getGroupColumns = (state) => state[slice.name].groupColumns
export const getLimitDate = (state) => state[slice.name].limitDate

register(slice.name, slice.reducer)
