import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import {
  applyTemplateSetting,
  handleCheckedAllItem,
  handleIdWithAllChild,
  initChecked,
  keyById,
  resetNewsChecked,
  sortIdByLevel,
} from '../../helper'
import { getNews } from './thunk'

const initialState = {
  corporateNews: {},
  initCorporateNews: {},
  loading: true,
  corporateIds: [],
  idWidthAllChildren: {},
  checkedAll: false,
  listChecked: {},
}

const slice = createSlice({
  name: 'newsReport/dailyReport/setting/companyNews/corporateNews',
  initialState,
  reducers: {
    handleCollapse: (state, action) => {
      const { listChild, id } = action.payload

      listChild.forEach((childId) => {
        state.corporateNews[childId].listDisable = state.corporateNews[
          childId
        ].listDisable.includes(id)
          ? state.corporateNews[childId].listDisable.filter(
              (item) => item !== id,
            )
          : [...state.corporateNews[childId].listDisable, id]
      })

      state.corporateNews[id].isOpen = !state.corporateNews[id].isOpen
    },
    handleCheckedAll: (state, action) => {
      state.checkedAll = action.payload
    },
    handleTickById: (state, action) => {
      const { listChild, id, isChecked, listParent, hasCollapse } =
        action.payload
      listChild.forEach((childId) => {
        state.listChecked[childId] = !isChecked
      })

      state.listChecked[id] = !isChecked

      listParent.forEach((parentId) => {
        const allChildWithoutCurrentChild = state.idWidthAllChildren[
          parentId
        ].listChild.filter((childId) =>
          hasCollapse
            ? !state.idWidthAllChildren[id].listChild.includes(childId)
            : childId !== id,
        )
        const checkedState = allChildWithoutCurrentChild.map(
          (id) => state.listChecked[id],
        )
        if (checkedState.every((item) => item) && !isChecked) {
          state.listChecked[parentId] = true
        } else {
          state.listChecked[parentId] = false
        }
      })
    },
    checkAllItem: (state, action) => {
      state.listChecked = handleCheckedAllItem(
        state.listChecked,
        action.payload,
      )
    },
    resetCorporateNews: (state) => {
      state.listChecked = resetNewsChecked(state.listChecked)
      state.corporateNews = state.initCorporateNews
    },
    applyCorporateSetting: (state, action) => {
      state.listChecked = applyTemplateSetting(
        state.listChecked,
        action.payload,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNews.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getNews.fulfilled, (state, action) => {
      state.loading = false
      state.corporateNews = state.initCorporateNews = keyById(action.payload)
      state.corporateIds = sortIdByLevel(action.payload)
      state.idWidthAllChildren = handleIdWithAllChild(
        state.corporateIds,
        state.corporateNews,
      )
      state.listChecked = initChecked(state.corporateIds, state.corporateNews)
    })
    builder.addCase(getNews.rejected, (state, action) => {
      state.loading = action.payload.isLoading
    })
  },
})

export const {
  handleCollapse,
  handleCheckedAll,
  handleTickById,
  checkAllItem,
  resetCorporateNews,
  applyCorporateSetting,
} = slice.actions

export const getLoading = (state) => state[slice.name].loading
export const getDataById = (id) => (state) =>
  state[slice.name].corporateNews[id]
export const getCorporateIds = (state) => state[slice.name].corporateIds
export const getIdWithAllChild = (id) => (state) =>
  state[slice.name].idWidthAllChildren[id]
export const getCheckedAll = (state) => state[slice.name].checkedAll
export const getCheckedById = (id) => (state) =>
  state[slice.name].listChecked[id]
export const getListChecked = (state) => state[slice.name].listChecked

register(slice.name, slice.reducer)
