import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import UseFontSize from '../hooks/useFontSize'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'

export const SizeTrackerContainer = ({ children }) => {
  const { devicePixelRatio, zoom } = useWindowDevicePixelRatio()
  const typeFontSize = UseFontSize()
  const [remount, setRemount] = useState(false)

  useEffect(() => {
    setRemount(!remount)
  }, [typeFontSize, devicePixelRatio, zoom])

  return (
    <div className="w-100 h-100" key={remount}>
      {children}
    </div>
  )
}

SizeTrackerContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
