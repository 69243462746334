import { useCallback, useEffect, useState } from 'react'
import { debounce } from '../../utils/Common'

const withWrapperTree = (Tree) => (props) => {
  const [search, setSearch] = useState('')

  useEffect(() => {
    handleChangeSearch(props.valueSearch)
  }, [props.valueSearch])

  const handleChangeSearch = useCallback(
    debounce((value) => {
      setSearch(value)
    }, 400),
    [],
  )

  const handleDoubleClickItem = useCallback((item) => {
    if (doubleClickItem) {
      doubleClickItem(item)
    }
  }, [])

  const { valueSearch, doubleClickItem, ...otherProps } = props

  return (
    <Tree
      {...otherProps}
      valueSearch={search}
      doubleClickItem={handleDoubleClickItem}
    />
  )
}

export default withWrapperTree
