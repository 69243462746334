import { Translate } from 'react-redux-i18n'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import Tree from '../../../../common/tree'
import style from './style.module.css'

const Category = ({ treeData, category, setCategory, keyword }) => {
  const loading = false

  const handleClick = (item) => {
    if (!item) {
      setCategory(null)
    } else if (Object.keys(item.children).length === 0) {
      setCategory(item.id)
    }
  }

  return (
    <div className={style.category}>
      <div className={style.categoryList}>
        {loading ? (
          <Loading isBlack />
        ) : Object.keys(treeData).length ? (
          <>
            {!!keyword.trim() && (
              <div
                style={{
                  padding: '5px 18px',
                  color: category ? '#4E4E4E' : '#2f9aee',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick()}
              >
                <Translate value="common.ALL" />
              </div>
            )}
            <Tree
              defaultOpenLevel1
              treeData={treeData}
              idKey={'id'}
              nameKey="name"
              listHightlight={[category]}
              itemDefaultOpen={[category]}
              disabledCheckDbClick
              onClickItem={handleClick}
              doubleClickItem={undefined}
              bgColorScroll="#D9D9D9"
              classItemStyle={style.itemStyle}
              zIndexTextEllipsis={12}
            />
          </>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  )
}

export default Category
