import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { keyTableResult } from '../constant'
import style from '../index.module.css'
import { selectResultKeyTableByIds } from '../store/slice'

const ThTable = ({ keyTh }) => {
  const thInformation = useSelector(selectResultKeyTableByIds(keyTh))

  return (
    <>
      {keyTh === keyTableResult.NO ? (
        <Translate value="tool.smartScreening.0" />
      ) : [keyTableResult.TICKER, keyTableResult.EXCHANGE].includes(keyTh) ? (
        <>{thInformation?.indicatorName.toUpperCase()}</>
      ) : [keyTableResult.COMPANY_NAME, keyTableResult.SECTOR_L2].includes(
          keyTh,
        ) ? (
        <div className={style.longTextTh}>
          {thInformation?.indicatorName.toUpperCase()}
        </div>
      ) : (
        <>
          <div>{thInformation?.indicatorName}</div>
          <div>
            {thInformation?.periodValue &&
              thInformation?.periodType + ': ' + thInformation?.periodValue}
          </div>
          <div>
            {(thInformation?.additionalPeriodValue
              ? thInformation?.additionalPeriodValue + ': '
              : '') +
              (thInformation?.additionalPeriodType
                ? thInformation?.additionalPeriodType
                : '')}
          </div>
          <div>
            {thInformation?.unit && (
              <>
                <Translate value="tool.smartScreening.UNIT" />:{' '}
                {thInformation?.unit}
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

ThTable.propTypes = {
  keyTh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default ThTable
