import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import DataExplorerService from '../../../../../core/services/tool/dataExplorer/DataExplorerService'
import { maxSheet } from '../../../../common/dataExplorer/Sheet'
import {
  maxIndicator,
  typeData,
} from '../../../../common/dataExplorer/constant'
import { convertParamGetResult } from '../../../../common/dataExplorer/helper'
import BoardOfDirectors from '../../../../common/dataExplorer/popupTemplate/BoardOfDirectors'
import BondIssue from '../../../../common/dataExplorer/popupTemplate/BondIssue'
import CashDividendPayout from '../../../../common/dataExplorer/popupTemplate/CashDividendPayout'
import FinancialRatio from '../../../../common/dataExplorer/popupTemplate/FinancialRatio'
import FinancialStatement from '../../../../common/dataExplorer/popupTemplate/FinancialStatement'
import IPO from '../../../../common/dataExplorer/popupTemplate/IPO'
import MajorShareholder from '../../../../common/dataExplorer/popupTemplate/MajorShareholder'
import Performance from '../../../../common/dataExplorer/popupTemplate/Performance'
import StockIssue from '../../../../common/dataExplorer/popupTemplate/StockIssue'
import { keyBy } from '../../../../utils/Common'
import EventEmitter, {
  LOADING_TABLE_DE,
  SHOW_POPUP_ERROR,
} from '../../../../utils/EventEmitter'
import { typePopupIndicator, typePopupTemplate } from '../constant'
import {
  convertDataColumns,
  convertDataId,
  convertParamGetMostRecent,
} from '../helper'
import {
  addColumn,
  addFiinXTemplate,
  addIndicatorById,
  addTicker,
  closePopupTemplate,
  selectActiveSheet,
  selectColumnById,
  selectDetailFiinXTemplate,
  selectFiinXTemplateShareIssueMethod,
  selectGroupColumnById,
  selectIndicatorById,
  selectIsLoadFiinXTemplate,
  selectListSheet,
  selectNewGroupId,
  selectNewTickerId,
} from '../store/slice'
import { getFiinXTemplateResultThunk, getMostRecentThunk } from '../store/thunk'

export const GET_RESULT_TEMPLATE_FIINX = 'GET_RESULT_TEMPLATE_FIINX'

const PopupTemplate = () => {
  const dispatch = useDispatch()

  const detailFiinXTemplate = useSelector(selectDetailFiinXTemplate)
  const listSheet = useSelector(selectListSheet)
  const activeSheet = useSelector(selectActiveSheet)
  const locale = useSelector((state) => state.i18n.locale)
  const newTickerId = useSelector(selectNewTickerId(activeSheet))
  const newGroupId = useSelector(selectNewGroupId(activeSheet))
  const groupColumnById = useSelector(selectGroupColumnById(activeSheet))
  const columnById = useSelector(selectColumnById(activeSheet))
  const isLoadFiinXTemplate = useSelector(
    selectIsLoadFiinXTemplate(activeSheet),
  )
  const listMethod = useSelector(selectFiinXTemplateShareIssueMethod)
  const indicatorById = useSelector(selectIndicatorById)

  const [el, setEl] = useState(<></>)

  const detailSheet = listSheet.find((sheet) => sheet.value === activeSheet)

  useEffect(() => {
    const asyncFn = async () => {
      if (detailSheet?.templateGroup) {
        const response = await DataExplorerService.getIndicatorFiinXTemplate({
          DeIndicatorGroup: detailSheet.templateGroup,
        })

        if (response.success) {
          let indicators = response?.data.slice(0, maxIndicator)
          dispatch(addIndicatorById(keyBy(indicators, 'indicatorId')))
        }
      }
    }

    asyncFn()
  }, [activeSheet, locale])

  useEffect(() => {
    if (detailSheet?.templateGroup && !isLoadFiinXTemplate) {
      EventEmitter.subscribe(GET_RESULT_TEMPLATE_FIINX, getResult)
    }
    return () => EventEmitter.unsubscribe(GET_RESULT_TEMPLATE_FIINX, getResult)
  }, [newTickerId])

  useEffect(() => {
    if (detailSheet?.templateGroup && isLoadFiinXTemplate) {
      getResult()
    }
  }, [locale])

  const getResult = () => {
    const paramResult = convertParamGetResult(
      newTickerId,
      newGroupId,
      groupColumnById,
      columnById,
      detailSheet.templateGroup?.includes('FS')
        ? detailSheet.templateGroup?.replace('FS', '')
        : typeData.CORPORATE,
      convertDataId,
    )

    if (paramResult) {
      dispatch(getFiinXTemplateResultThunk(paramResult))
    }
  }

  useEffect(() => {
    renderPopup()
  }, [detailFiinXTemplate])

  const handleCancel = () => dispatch(closePopupTemplate())

  const handleSubmit = async (data, indicators) => {
    EventEmitter.dispatch(LOADING_TABLE_DE, true)
    handleCancel()

    if (
      [
        typePopupTemplate.OVERVIEW,
        typePopupTemplate.VALUATION,
        typePopupTemplate.CREDIT,
        typePopupTemplate.PERFORMANCE,
      ].includes(detailFiinXTemplate.templateGroup)
    ) {
      dispatch(
        getMostRecentThunk(
          convertParamGetMostRecent(
            indicators,
            detailFiinXTemplate.templateGroup,
          ),
        ),
      )
    }

    let responseTicker = {}
    if (
      [
        typePopupTemplate.CORPORATE_BOND_ISSUANCE,
        typePopupTemplate.INITIAL_PUBLIC_OFFERING,
      ].includes(detailFiinXTemplate.templateGroup)
    ) {
      responseTicker = await DataExplorerService.getTickerFiinXTemplate({
        parameter: {
          indicatorGroup: detailFiinXTemplate.templateGroup,
          fromYear: data.detail.fromYear,
          toYear: data.detail.toYear,
        },
      })
    }

    const { detail } = data

    let templateGroup = detailFiinXTemplate.templateGroup

    if (detailFiinXTemplate.templateGroup === typePopupTemplate.FS_COMPANY) {
      templateGroup = detail.companyType
      const response = await DataExplorerService.getIndicatorFiinXTemplate({
        DeIndicatorGroup: templateGroup,
      })
      indicators = response.success ? response.data.slice(0, maxIndicator) : []
    }

    dispatch(addIndicatorById(keyBy(indicators, 'indicatorId')))

    const conditionTypeIndicator = indicators?.[0]?.conditionType

    indicators = convertDataColumns(
      conditionTypeIndicator,
      {
        ...data,
        indicator: indicators.map((indicator) => indicator.indicatorId),
        indicatorById: keyBy(indicators, 'indicatorId'),
      },
      indicatorById,
    )

    const dataSubmit = {
      detail: {
        conditionType: conditionTypeIndicator,
        condition: detail,
      },
    }

    if (listSheet.length >= maxSheet) {
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <Translate value="tool.dataExplorer.corporate.MAX_SHEET_1" />,
        <Translate value="tool.dataExplorer.corporate.MAX_SHEET_2" />,
      ])
    } else {
      dispatch(addFiinXTemplate({ ...detailFiinXTemplate, templateGroup }))
      if (detailFiinXTemplate.templateGroup === typePopupTemplate.FS_COMPANY) {
        dispatch(
          addColumn({
            indicators: indicators.filter(
              (item) => item.conditionType === typePopupIndicator.DE01_SC26,
            ),
            ...dataSubmit,
          }),
        )
        const sc21DataSubmit = {
          detail: {
            conditionType: typePopupIndicator.DE01_SC21,
            condition: { years: [detail.targetYear] },
          },
        }
        const conditionIndicatorSc21 = {
          year: detail.targetYear,
        }
        dispatch(
          addColumn({
            indicators: indicators
              .filter(
                (item) => item.conditionType === typePopupIndicator.DE01_SC21,
              )
              .map((item) => ({
                ...item,
                condition: conditionIndicatorSc21,
                title: conditionIndicatorSc21,
              })),
            ...sc21DataSubmit,
          }),
        )
      } else {
        dispatch(addColumn({ indicators, ...dataSubmit }))
      }
      dispatch(
        addTicker(
          responseTicker.success
            ? responseTicker.data.dataIds.map((item) => {
                return { organizationId: item.id, check: false }
              })
            : data.ticker.map((tickerId) => {
                return { ...data.tickerById[tickerId], check: false }
              }),
        ),
      )
    }

    EventEmitter.dispatch(LOADING_TABLE_DE, false)
  }

  const renderPopup = async () => {
    if (detailFiinXTemplate) {
      EventEmitter.dispatch(LOADING_TABLE_DE, true)

      const responseIndicator =
        await DataExplorerService.getIndicatorFiinXTemplate({
          DeIndicatorGroup: detailFiinXTemplate.templateGroup,
        })
      if (responseIndicator.success) {
        const indicators = responseIndicator?.data.slice(0, maxIndicator) || []
        let responseMaxDate = {}
        if (
          [
            typePopupTemplate.OVERVIEW,
            typePopupTemplate.VALUATION,
            typePopupTemplate.CREDIT,
            typePopupTemplate.PERFORMANCE,
            typePopupTemplate.FS_COMPANY,
          ].includes(detailFiinXTemplate.templateGroup)
        ) {
          responseMaxDate = await DataExplorerService.getMostRecentDate({
            ConditionType: indicators?.[0]?.conditionType,
          })
        }

        dispatch(addIndicatorById(keyBy(indicators, 'indicatorId')))

        const props = {
          handleCancel,
          handleSubmit: (data) => handleSubmit(data, indicators),
        }

        const {
          maxDate,
          maxYear,
          minDate,
          minYear,
          ttmMaxQuarter,
          ttmMaxYear,
          ttmMinYear,
        } = responseMaxDate.success ? responseMaxDate.data : {}

        switch (detailFiinXTemplate.templateGroup) {
          case typePopupTemplate.FS_COMPANY:
            setEl(
              <FinancialStatement
                {...props}
                ttmMaxQuarter={ttmMaxQuarter}
                ttmMaxYear={ttmMaxYear}
                ttmMinYear={ttmMinYear}
              />,
            )
            break
          case typePopupTemplate.SHARE_ISSUANCE:
            setEl(<StockIssue {...props} listMethod={listMethod} />)
            break
          case typePopupTemplate.CASH_DIVIDEND_PAYOUT:
            setEl(<CashDividendPayout {...props} />)
            break
          case typePopupTemplate.OVERVIEW:
          case typePopupTemplate.VALUATION:
          case typePopupTemplate.CREDIT:
            setEl(
              <FinancialRatio
                {...props}
                title={I18n.t(
                  `tool.dataExplorer.${detailFiinXTemplate.templateGroup}`,
                )}
                maxDate={maxDate}
                maxYear={maxYear}
                minDate={minDate}
                minYear={minYear}
                ttmMaxQuarter={ttmMaxQuarter}
                ttmMaxYear={ttmMaxYear}
                ttmMinYear={ttmMinYear}
              />,
            )
            break
          case typePopupTemplate.PERFORMANCE:
            setEl(
              <Performance {...props} minDate={minDate} maxDate={maxDate} />,
            )
            break
          case typePopupTemplate.CORPORATE_BOND_ISSUANCE:
            setEl(<BondIssue {...props} />)
            break
          case typePopupTemplate.INITIAL_PUBLIC_OFFERING:
            setEl(<IPO {...props} />)
            break
          case typePopupTemplate.BOARD_OF_DIRECTORS:
            setEl(<BoardOfDirectors {...props} />)
            break
          case typePopupTemplate.MAJOR_SHAREHOLDERS:
            setEl(<MajorShareholder {...props} />)
            break
          default:
            setEl(<></>)
            break
        }
      }

      EventEmitter.dispatch(LOADING_TABLE_DE, false)
    } else {
      setEl(<></>)
    }
  }

  return <>{el}</>
}

export default PopupTemplate
