import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { getLatestPrice, getSummary } from './thunk'

const initialState = {
  isLoading: true,
  isLoadingLatestPrice: true,
  ids: [],
  dataById: {},
  reCalcWidths: false,
  indicator: null,
  priceData: {},
  underlyingIds: [],
}

const slice = createSlice({
  name: 'market/derivative/summary',
  initialState,
  reducers: {
    setDataById: (state, action) => {
      state.dataById = action.payload
    },
    changeIndicator: (state, action) => {
      const rowId = action.payload
      const rowData = state.dataById[rowId]

      if (rowData.childrenId) {
        state.indicator = state.dataById[rowId]
      } else if (rowData.parentId) {
        const parentId = rowData.parentId
        state.indicator = state.dataById[parentId]
      } else {
        state.indicator = state.dataById[rowId]
      }
    },
    subscribeDerivative: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.priceInfo = action.payload[0]
      }
      const derivative = action.payload[0]
      const id = derivative.futureId
      state.dataById[id].referencePrice = derivative.referencePrice
      state.dataById[id].matchPrice = derivative.matchPrice
      state.dataById[id].priceChange = derivative.priceChange
      state.dataById[id].percentPriceChange = derivative.percentPriceChange
      state.dataById[id].totalMatchVolume = derivative.totalMatchVolume
      state.dataById[id].openInterest = derivative.openInterest
    },
    subscribeBidAsk: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.bidAskInfo = action.payload[0]
      }
    },
    subscribeIndex: (state, action) => {
      const index = action.payload.data[0]
      state.dataById[action.payload?.derivativeId].basic =
        index.closeIndex -
        state.dataById[action.payload?.derivativeId].matchPrice
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSummary.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSummary.fulfilled, (state, action) => {
      state.ids = action.payload.map((e) => e.futureId)
      state.dataById = keyBy(action.payload, 'futureId')
      state.isLoading = false
      state.reCalcWidths = !state.reCalcWidths
      state.indicator = state.indicator || action.payload[0]
      state.underlyingIds = action.payload.map((e) => e.underlyingId)
    })
    builder.addCase(getSummary.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getLatestPrice.pending, (state) => {
      state.isLoadingLatestPrice = true
      state.priceData = {}
    })
    builder.addCase(getLatestPrice.fulfilled, (state, action) => {
      state.isLoadingLatestPrice = false
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData = action.payload[0]
      }
    })
    builder.addCase(getLatestPrice.rejected, (state) => {
      state.isLoadingLatestPrice = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectIds = (state) => state[slice.name].ids
export const selectSummaryValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataById[id], attr)
export const selectReCalcWidthsTable = (state) => state[slice.name].reCalcWidths
export const selectIndicator = (state) => state[slice.name].indicator
export const selectPriceData = (state) => state[slice.name].priceData
export const selectLoadingLatestPrice = (state) =>
  state[slice.name].isLoadingLatestPrice
export const selectUnderlyingIds = (state) => state[slice.name].underlyingIds

export const {
  setDataById,
  changeIndicator,
  subscribeDerivative,
  subscribeBidAsk,
  subscribeIndex,
} = slice.actions

register(slice.name, slice.reducer)
