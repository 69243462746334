import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { TIME_FRAME_TOP_NET_VALUE, VALUE_TYPE } from '../constants'
import { getTopNetValue } from './thunk'

const initialState = {
  type: VALUE_TYPE.BUY,
  timeFrame: TIME_FRAME_TOP_NET_VALUE.ONE_DAY.value,
  loading: true,
  ids: [],
  initialIds: [],
  valueById: {},
  reCalcWidths: false,
}

export const slice = createSlice({
  name: 'market/marketInDepth/foreign/topNetValue',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeType: (state, action) => {
      state.type = action.payload
    },
    changeTimeFrame: (state, action) => {
      state.timeFrame = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.valueById,
        action.payload,
        state.initialIds,
      )
    },
    subscribeStock: (state, action) => {
      const stock = action.payload[0]
      if (state.valueById[stock.organizationId]) {
        state.valueById[stock.organizationId] = {
          ...state.valueById[stock.organizationId],
          closePriceAdjusted: stock.matchPrice,
          totalVolume: stock.totalMatchVolume,
          foreignBuyValueTotal: stock.foreignBuyValueTotal,
          foreignSellValueTotal: stock.foreignSellValueTotal,
          totalNetValue:
            stock.foreignBuyValueTotal - stock.foreignSellValueTotal,
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopNetValue.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTopNetValue.fulfilled, (state, action) => {
      const orgIds = action.payload.map((item) => item.organizationId)
      state.reCalcWidths = !state.reCalcWidths
      state.loading = false
      state.ids = orgIds
      state.initialIds = orgIds
      state.valueById = keyBy(action.payload, 'organizationId')
    })
    builder.addCase(getTopNetValue.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectType = (state) => state[slice.name].type
export const selectTimeFrame = (state) => state[slice.name].timeFrame
export const selectIds = (state) => state[slice.name].ids
export const selectValueById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].valueById[id], attr)
export const selectLoading = (state) => state[slice.name].loading
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths

export const { resetStore, changeType, changeTimeFrame, sort, subscribeStock } =
  slice.actions

register(slice.name, slice.reducer)
