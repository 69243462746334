import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { ReactComponent as IconRename } from '../../icon/icon-rename.svg'
import { Title } from '../Title'
import style from './index.module.css'

export const Item = ({
  text,
  idKey,
  isI18n,
  styleSetting,
  isEditLegend,
  updateListLegendCustom,
}) => {
  const textEditorRef = useRef()
  const [isShow, setIsShow] = useState(false)

  const renderTitle = () => {
    return (
      <>
        <div className={`align-center ${style.labelItem}`}>
          <Title
            appendStyle={{
              fontWeight: styleSetting.bold ? 600 : '400',
              transform: styleSetting.italic ? 'skew(-20deg)' : '',
              textDecoration: styleSetting.underline ? 'underline' : '',
            }}
            data={{ name: text, key: idKey }}
            fontSize={styleSetting?.fontSize}
            isEdit={isShow}
            maxWidth={`calc(100%)`}
            setIsEdit={setIsShow}
            isI18n={isI18n}
            exceptRef={textEditorRef}
            onSubmit={updateListLegendCustom}
          />
          {isEditLegend && (
            <div
              className={`align-center ${style.editContent} ${
                isShow ? style.visibilityVisible : ''
              }`}
            >
              <IconRename
                className="cursor-pointer"
                onClick={() => setIsShow(true)}
              />
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <div
      id={idKey}
      className={style.titleChart}
      style={{ justifyContent: styleSetting.position }}
    >
      {renderTitle()}
    </div>
  )
}

Item.propTypes = {
  text: PropTypes.string,
  idKey: PropTypes.string.isRequired,
  isOneRow: PropTypes.bool.isRequired,
  isI18n: PropTypes.bool,
  styleSetting: PropTypes.object,
}

Item.defaultProps = {
  isI18n: true,
  text: '',
  styleSetting: {
    name: '',
    fontSize: 11,
    bold: false,
    italic: false,
    underline: false,
  },
}
