import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import SectorTopInfo from '../../../common/topInfo/sectorTopInfo'
import Content from './Content'

export const Ranking = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <SectorTopInfo levelSector={[3]} />
              {size.height && (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: `calc(100% - ${size.height}px)`,
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
