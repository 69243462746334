import ownershipProxy from '../../proxies/corporate/OwnerShipProxy'
import { ServiceBase } from '../ServiceBase'

class OwnershipService extends ServiceBase {
  getAssociate(params) {
    return this.applyMemoryCache(
      'OwnershipService.getAssociate',
      params,
    )(() => ownershipProxy.getAssociate(params))
  }

  getSubsidiaries(params) {
    return this.applyMemoryCache(
      'OwnershipService.getSubsidiaries',
      params,
    )(() => ownershipProxy.getSubsidiaries(params))
  }

  getMajorOwnership(params) {
    return this.applyMemoryCache(
      'OwnershipService.getMajorOwnership',
      params,
    )(() => ownershipProxy.getMajorOwnership(params))
  }

  getOverviewOwnershipChart(params) {
    return this.applyMemoryCache(
      'OwnershipService.getOverviewOwnershipChart',
      params,
    )(() => ownershipProxy.getOverviewOwnershipChart(params))
  }

  getMajorOwnershipChart(params) {
    return this.applyMemoryCache(
      'OwnershipService.getMajorOwnershipChart',
      params,
    )(() => ownershipProxy.getMajorOwnershipChart(params))
  }

  getManagementAndDirectors(params) {
    return this.applyMemoryCache(
      'OwnershipService.getManagementAndDirectors',
      params,
    )(() => ownershipProxy.getManagementAndDirectors(params))
  }

  getHistoricalTooltip(params) {
    return this.applyMemoryCache(
      'OwnershipService.getHistoricalTooltip',
      params,
    )(() => ownershipProxy.getHistoricalTooltip(params))
  }

  getOwnershipTooltip(params) {
    return this.applyMemoryCache(
      'OwnershipService.getOwnershipTooltip',
      params,
    )(() => ownershipProxy.getOwnershipTooltip(params))
  }

  downloadMajorShareHolders(params) {
    return this.getFileDownload(() =>
      ownershipProxy.downloadMajorShareHolders(params),
    )
  }

  downloadManagementAndDirectors(params) {
    return this.getFileDownload(() =>
      ownershipProxy.downloadManagementAndDirectors(params),
    )
  }

  downloadSubsidiaries(params) {
    return this.getFileDownload(() =>
      ownershipProxy.downloadSubsidiaries(params),
    )
  }

  downloadAssociates(params) {
    return this.getFileDownload(() => ownershipProxy.downloadAssociates(params))
  }

  getDirectorDeals(params) {
    return this.getData(() => ownershipProxy.getDirectorDeals(params))
  }

  downloadShareHolderTooltip_Historical(params) {
    return this.getFileDownload(() =>
      ownershipProxy.downloadShareHolderTooltip_Historical(params),
    )
  }

  downloadShareHolderTooltip_Ownership(params) {
    return this.getFileDownload(() =>
      ownershipProxy.downloadShareHolderTooltip_Ownership(params),
    )
  }
}

export default new OwnershipService()
