import { maxBy, uniq } from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { selectFullColumnId } from '../BondList/Table/store/slice'
import { getDataBondList } from '../BondList/Table/store/thunk'
import { TABLE_FIELD_CODES } from '../Screening/constants'
import { INDICATOR_GROUPS, TAB_TYPES } from '../constants'
import { formatAddInformation } from '../helper'
import {
  changeDataRaw,
  changeTemplate,
  changeTemplateList,
  changeTemplateMoreList,
  selectAddInformatics,
  selectFilterParams,
  selectInitialParentSectors,
  selectInitialSectors,
  selectTemplate,
  setFilterParameters,
} from '../store/slice'

export const withFilterSectorActions = (Component) => (props) => {
  const dispatch = useDispatch()

  const { indicatorGroup, addedInformations, ...restParams } =
    useSelector(selectFilterParams)
  const initialSectors = useSelector(selectInitialSectors)
  const templates = useSelector(selectTemplate)
  const initialParentSectors = useSelector(selectInitialParentSectors)
  const addInformatics = useSelector(selectAddInformatics)
  const listIds = useSelector(selectFullColumnId(1))

  // Use state
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [list, setList] = useState([])
  const [parentList, setParentList] = useState([])

  const handleChange = () => {
    setIsShowDropdown(false)

    const raw = templates.find((item) => item.templateId === TAB_TYPES.RAW)

    const newSectors =
      !!list.length &&
      uniq([...parentList, ...list]).length !==
        uniq([...initialSectors, ...initialParentSectors]).length
        ? uniq([...parentList, ...list])
        : []
    const newList = newSectors.filter((item) => !parentList.includes(item))

    const information = addInformatics.find(
      (item) =>
        item.tableFieldCode === TABLE_FIELD_CODES.INDUSTRY ||
        item.tableFieldCode === TABLE_FIELD_CODES.EN_INDUSTRY,
    )
    const indexInformation = addedInformations.findIndex(
      (item) =>
        item.tableFieldCode === TABLE_FIELD_CODES.INDUSTRY ||
        item.tableFieldCode === TABLE_FIELD_CODES.EN_INDUSTRY,
    )

    if (raw) {
      dispatch(
        setFilterParameters({
          key: 'sectors',
          value: list,
        }),
      )

      dispatch(changeDataRaw({ key: 'sectors', value: newSectors }))

      if (!information) return

      const { indicatorId, tableCode, tableFieldCode } = information

      let newAddedInformatics = addedInformations

      if (
        (!newSectors?.length ||
          newSectors?.length === initialSectors?.length ||
          newList?.length === initialSectors?.length) &&
        indexInformation !== -1 &&
        !information?.isDefault
      ) {
        newAddedInformatics = newAddedInformatics.filter(
          (item) =>
            item.tableFieldCode !== TABLE_FIELD_CODES.INDUSTRY &&
            item.tableFieldCode !== TABLE_FIELD_CODES.EN_INDUSTRY,
        )
      } else if (
        !!newSectors?.length &&
        uniq(newSectors)?.length !== uniq(initialSectors)?.length &&
        indexInformation === -1
      ) {
        const max = maxBy(newAddedInformatics, (e) => e.order)

        newAddedInformatics = [
          ...newAddedInformatics,
          {
            id: uuidv4(),
            order: (max?.order ?? 0) + 1,
            indicatorId,
            tableCode,
            tableFieldCode,
          },
        ]
      }

      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: newAddedInformatics,
        }),
      )

      const newParams = {
        ...restParams,
        sectors: newSectors,
        conditions: restParams.conditions,
        addedInformations: formatAddInformation(newAddedInformatics, listIds),
        indicatorGroup,
        pageSize: 300,
        pageIndex: 1,
      }

      dispatch(
        getDataBondList({
          params: newParams,
          redirectToBondIssuer: props.redirectToBondIssuer,
        }),
      )
    } else {
      const data = {
        templateId: TAB_TYPES.RAW,
        templateName:
          indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
            ? 'bond.bondScreening.allIssuer.TEMPLATE_NAME'
            : 'bond.bondScreening.filter.TEMPLATE_NAME',
        templateType: indicatorGroup,
      }

      const newTemplateIds = [
        ...templates.slice(0, 6),
        data,
        ...templates.slice(6),
      ]

      dispatch(changeTemplateList(newTemplateIds))
      dispatch(changeTemplateMoreList(newTemplateIds))

      let newAddedInformatics = addedInformations

      if (
        (!newSectors?.length ||
          newSectors?.length === initialSectors?.length ||
          newList?.length === initialSectors?.length) &&
        indexInformation !== -1 &&
        !information?.isDefault
      ) {
        newAddedInformatics = newAddedInformatics.filter(
          (item) =>
            item.tableFieldCode !== TABLE_FIELD_CODES.INDUSTRY &&
            item.tableFieldCode !== TABLE_FIELD_CODES.EN_INDUSTRY,
        )
      } else if (
        !!newSectors?.length &&
        uniq(newSectors)?.length !== uniq(initialSectors)?.length &&
        indexInformation === -1
      ) {
        const max = maxBy(newAddedInformatics, (e) => e.order)

        if (!information) return

        const { indicatorId, tableCode, tableFieldCode } = information

        newAddedInformatics = [
          ...newAddedInformatics,
          {
            id: uuidv4(),
            order: (max?.order ?? 0) + 1,
            indicatorId,
            tableCode,
            tableFieldCode,
          },
        ]
      }

      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: newAddedInformatics,
        }),
      )

      dispatch(
        changeTemplate({
          ...data,
          parameter: {
            ...restParams,
            addedInformations: formatAddInformation(
              newAddedInformatics,
              listIds,
            ),
            indicatorGroup,
          },
        }),
      )

      dispatch(
        setFilterParameters({
          key: 'sectors',
          value: list,
        }),
      )

      const newParams = {
        ...restParams,
        sectors: newSectors,
        addedInformations: formatAddInformation(newAddedInformatics, listIds),
        indicatorGroup,
        pageSize: 300,
        pageIndex: 1,
      }

      dispatch(
        getDataBondList({
          params: newParams,
          redirectToBondIssuer: props.redirectToBondIssuer,
        }),
      )
    }
  }

  return (
    <Component
      {...props}
      list={list}
      parentList={parentList}
      isShowDropdown={isShowDropdown}
      setList={setList}
      setParentList={setParentList}
      setIsShowDropdown={setIsShowDropdown}
      handleChange={handleChange}
    />
  )
}
