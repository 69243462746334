import { useSelector } from 'react-redux'
import { Panel } from '../../../common/panel'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { PANEL_LAYOUTS } from '../../../constants/Layout'
import { formatNumber } from '../../../utils/Common'
import { Content } from './Content'
import { TABLE_TABS } from './constants'
import { getParams } from './helpers'
import { selectFilters, selectTableTab } from './store/slice'
import {
  downloadAllFundHolding,
  downloadDetailFundHolding,
} from './store/thunk'

const panelKey = 'HOLDING_TABLE'
const panelRefs = {}

const PanelHolding = ({ sizes, setSizes }) => {
  const titlePanel = `fund.fundCenter.holding.TABLE`

  const filters = useSelector(selectFilters)
  const tableTab = useSelector(selectTableTab)

  const handleDownload = () => {
    const params = getParams(filters, tableTab, true)
    switch (tableTab) {
      case TABLE_TABS.ALL:
        return downloadAllFundHolding(params)
      case TABLE_TABS.DETAIL:
        return downloadDetailFundHolding(params)
      default:
        return
    }
  }

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      isDownloadCsv
      downloadCsv={handleDownload}
    >
      <Content
        id={panelKey}
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
export default withWrapper(PANEL_LAYOUTS[1](panelKey), panelRefs)(PanelHolding)
