import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { selectScreeningCount } from '../store/slice'
import { getTotalRecord } from '../store/thunk'

const TotalRecord = ({ resultThunkParams }) => {
  const dispatch = useDispatch()
  const screeningCount = useSelector(selectScreeningCount)

  useEffect(() => {
    if (Object.keys(resultThunkParams).length) {
      dispatch(getTotalRecord(resultThunkParams))
    }
  }, [resultThunkParams])

  return (
    <>
      <Translate value="tool.smartScreening.NUMBER_OF_RESULT" />
      {`: ${screeningCount}`}&nbsp;
      <Translate value="tool.smartScreening.RESULT" />
    </>
  )
}

TotalRecord.propTypes = {
  resultThunkParams: PropTypes.object.isRequired,
}

export default TotalRecord
