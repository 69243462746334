import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../common/html/Span'
import { TOTAL_ID } from './constants'
import { changeCurrentCompaniesIds, selectIds } from './store/slice'

export const DeleteCol = ({ id }) => {
  const dispatch = useDispatch()

  const ids = useSelector(selectIds)

  const handleClick = () => {
    dispatch(
      changeCurrentCompaniesIds(
        ids.filter((item) => ![id, TOTAL_ID].includes(item)),
      ),
    )
  }

  return (
    <div className="cursor-pointer">
      <Span onClick={handleClick} style={{ fontSize: 7, color: '#e5e5e5' }}>
        <i className="icon-delete-stroke" />
      </Span>
    </div>
  )
}
