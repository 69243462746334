import PropTypes from 'prop-types'
import style from './index.module.css'

export const DropdownItem = ({ value, isCurrentItem }) => {
  return (
    <div
      className={`${style.dropdownItem} ${isCurrentItem && style.currentItem}`}
    >
      <span className={style.mr20}>{value.groupCode}</span>
      <span className={style.textLongW50}>
        {value.groupName} / {value.exchangeCode}
      </span>
    </div>
  )
}

DropdownItem.propTypes = {
  value: PropTypes.object.isRequired,
  isCurrentItem: PropTypes.bool.isRequired,
}
