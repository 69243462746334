import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { PriceEarningChart } from './PriceEarningChart'

export const PanelPriceEarningChart = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
}) => {
  const basicInfo = useSelector(selectBasicInfo)

  return (
    <Panel
      title="corporate.financialData.financialDividend.PRICE_EARNING_RATIO"
      panelRefs={panelRefs}
      panelKey={mapKey.FINANCIAL_CHART_PRICE_EARNING}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      downloadJpgParams={{
        domId: 'priceEarningChart',
        nameScreen: UseI18n('corporate.financialData.common.FINANCIAL_DATA'),
        chartName: UseI18n(
          'corporate.financialData.financialDividend.DIVIDEND',
        ),
        tabName: UseI18n(
          'corporate.financialData.financialDividend.PRICE_EARNING_RATIO',
        ),
        tickerName: basicInfo.ticker,
      }}
      titleJpg={UseI18n(
        'corporate.financialData.financialDividend.PRICE_EARNING_RATIO',
      )}
    >
      <PriceEarningChart
        width={formatNumber(
          sizes[mapKey.FINANCIAL_CHART_PRICE_EARNING].width - PANEL_PADDING,
        )}
        height={formatNumber(
          sizes[mapKey.FINANCIAL_CHART_PRICE_EARNING].height -
            HEADER_PANEL_HEIGHT,
        )}
      />
    </Panel>
  )
}

PanelPriceEarningChart.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
