import { useSelector } from 'react-redux'
import { TYPE_STOCK_INDICES_SECTOR } from '../../../../common/hooks/useBasicInfo'
import { usePackagePermission } from '../../../../common/hooks/usePackagePermission'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import {
  MONTH_OPTIONS,
  QUARTER_OPTIONS,
  VALUE_TIME,
  YEAR_OPTIONS,
} from './constants'
import { selectActiveMethodType } from './store/slice'
import { valueTimefrequency } from './topIssuerContent/constants'
import { Content } from './topIssuerContent/index'
import {
  selectFilterTopRelease,
  selectTimeFilter,
} from './topIssuerContent/store/slice'
import {
  getCirculateExport,
  getTopIssuerReleaseExport,
} from './topIssuerContent/store/thunk'

const TopIssuerRelease = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const { isTrial } = usePackagePermission(TYPE_STOCK_INDICES_SECTOR.BOND)

  const tab = useSelector(selectTimeFilter)
  const filter = useSelector(selectFilterTopRelease)
  const radioValue = useSelector(selectActiveMethodType)

  const exportFile = () => {
    if (tab === valueTimefrequency.ISSUE) {
      return getTopIssuerReleaseExport({
        TopValue: null,
        Industry: filter.career || null,
        TimeFrequency: radioValue,
        Month:
          radioValue === VALUE_TIME.MONTHLY
            ? filter.month || MONTH_OPTIONS[0].value
            : null,
        Quarter:
          radioValue === VALUE_TIME.QUARTERLY
            ? filter.quarter || QUARTER_OPTIONS[0].value
            : null,
        Year: filter.year || YEAR_OPTIONS[0].value,
      })
    } else {
      return getCirculateExport({
        Industry: filter.career || null,
        DateCheck: filter.DateCheck,
      })
    }
  }

  return (
    <Panel
      title="bond.overview.topIssuerRelease.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={!isTrial}
      downloadCsv={() => !isTrial && exportFile()}
    >
      <Content
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default TopIssuerRelease
