import {
  DATA_GROUP,
  DEFAULT_CONFIG,
  FIELD_NAME,
  ORDER_CONFIG,
  RELATIVE_FIELD_NAME,
  TYPE_CONFIG,
} from './configs'

const { DATA_GROUP_1, DATA_GROUP_2 } = DATA_GROUP
const { EA_G, IBL_G, NNII_G, RD } = FIELD_NAME
const { CTNIM, CTNIM1, CTNIM2, CTNIM4, CTNIM5 } = RELATIVE_FIELD_NAME
const { ORDER_HEADER_CONFIG, ORDER_FOOTER_CONFIG } = ORDER_CONFIG
const { IND } = TYPE_CONFIG
const { DEFAULT_TIME_RANGE } = DEFAULT_CONFIG

export const getTooltipContentData = (data, key) => {
  const result = data?.map((item) => {
    return {
      label: data[key],
      value: data.value1,
    }
  })

  return result
}

export const getI18nTooltipLabel = (I18n, formatIndicatorData) => {
  return {
    indicatorName: formatIndicatorData.name
      ? I18n.t(formatIndicatorData.name)
      : '',
    subIndicatorName: formatIndicatorData.subName
      ? I18n.t(formatIndicatorData.subName)
      : '',
  }
}

const mergeData = (leftKey, rightKey) => {
  return {
    // Left data
    leftTitle: leftKey?.title || '',
    leftLevel: leftKey?.level,
    leftId: leftKey?.id,
    isDropdownLeft: leftKey?.isDropdown || false,
    isActiveLeft: leftKey?.isActive,
    isContributionToNIMLeft: leftKey?.isContributionToNIM,
    leftDisabled: leftKey?.disabled,
    leftGroup: leftKey?.group,
    leftValue: leftKey?.value,
    leftRelativeValue: leftKey?.relativeValue,
    leftAlias: leftKey?.alias,
    leftValue1: leftKey?.value1,
    leftRelativeValue1: leftKey?.relativeValue1,
    leftAlias1: leftKey?.alias1,
    leftRelativeIndicator: leftKey?.relativeIndicator,
    leftRelativeIndicator1: leftKey?.relativeIndicator1,
    leftData: leftKey,
    // Right data
    rightTitle: rightKey?.title || '',
    rightLevel: rightKey?.level,
    rightId: rightKey?.id,
    isDropdownRight: rightKey?.isDropdown || false,
    isActiveRight: rightKey?.isActive,
    isContributionToNIMRight: rightKey?.isContributionToNIM,
    rightDisabled: rightKey?.disabled,
    rightGroup: rightKey?.group,
    rightValue: rightKey?.value,
    rightRelativeValue: rightKey?.relativeValue,
    rightAlias: rightKey?.alias,
    rightValue1: rightKey?.value1,
    rightRelativeValue1: rightKey?.relativeValue1,
    rightAlias1: rightKey?.alias1,
    rightRelativeIndicator: rightKey?.relativeIndicator,
    rightRelativeIndicator1: rightKey?.relativeIndicator1,
    rightData: rightKey,
  }
}

export const handleChildrenTableData = (
  leftTableData,
  rightTableData,
  isRight1TableData = false,
) => {
  const cloneRightTableData = JSON.parse(JSON.stringify(rightTableData))
  const retailDepositIndex = cloneRightTableData.findIndex(
    (item) => item.fieldName === RD,
  )

  if (isRight1TableData && retailDepositIndex) {
    const retailDepositItem = cloneRightTableData[retailDepositIndex]
    const emptyData = Object.keys(retailDepositItem).reduce(
      (prev, current) => ({ ...prev, [current]: undefined }),
      {},
    )
    cloneRightTableData.splice(retailDepositIndex + 1, 0, {
      ...emptyData,
      disabled: retailDepositItem.disabled,
    })
  }

  const filterLeftTableData = leftTableData.filter((item) => !item.disabled)
  const filterRightTableData = cloneRightTableData.filter(
    (item) => !item.disabled,
  )
  const leftLastIndex = filterLeftTableData.length - 1
  const rightLastIndex = filterRightTableData.length - 1
  // Add empty column for right sub table
  if (filterLeftTableData.length > filterRightTableData.length) {
    return filterLeftTableData.map((item, index) => {
      if (index > rightLastIndex - 1 && index < leftLastIndex) {
        return mergeData(item, {})
      }
      if (index === leftLastIndex) {
        return mergeData(item, filterRightTableData[rightLastIndex])
      }
      return mergeData(item, filterRightTableData[index])
    })
  }
  // Add empty column for left sub table
  return filterRightTableData.map((item, index) => {
    if (index > leftLastIndex - 1 && index < rightLastIndex) {
      return mergeData({}, item)
    }
    if (index === rightLastIndex) {
      return mergeData(filterLeftTableData[leftLastIndex], item)
    }
    return mergeData(filterLeftTableData[index], item)
  })
}

export const collapseHelper = (colId, data) => {
  const current = data.findIndex((item) => item?.id === colId)
  // Calculate the first index whose level is equal to or less than the current index
  let firstIndexLevelEqualOrLess = current
  for (let i = current + 1; i < data.length - 1; i++) {
    if (data[i].level > data[current].level) {
      firstIndexLevelEqualOrLess = i + 1
    } else {
      break
    }
  }

  const filterPrevDisabled = (prevDisabled, id) => {
    const isInsidePrevDisabled = prevDisabled.includes(id)
    if (isInsidePrevDisabled) {
      return prevDisabled.filter((item) => item !== id)
    }
    return prevDisabled
  }

  return data.map((item, index) => {
    // Rotate dropdown icon
    if (item?.id === colId && item.isDropdown && item.id !== undefined) {
      return {
        ...item,
        isActive: !item.isActive,
        prevActive: !item.prevActive,
      }
    }
    // Handle rows between current and firstIndexLevelEqualOrLess
    if (index > current && index < firstIndexLevelEqualOrLess) {
      // Collapse is opening
      if (data[current].isActive) {
        return {
          ...item,
          disabled: true,
          prevDisabled: [...item.prevDisabled, colId],
        }
      }
      // Collapse is closing
      else {
        return {
          ...item,
          disabled: !!filterPrevDisabled(item.prevDisabled, colId).length,
          prevDisabled: filterPrevDisabled(item.prevDisabled, colId),
        }
      }
    }
    return item
  })
}

export const chooseDataByGroup = (group) => {
  switch (group) {
    case 'left1':
      return 'left1TableData'
    case 'left2':
      return 'left2TableData'
    case 'left3':
      return 'left3TableData'
    case 'right1':
      return 'right1TableData'
    case 'right2':
      return 'right2TableData'
    default:
      return 'right3TableData'
  }
}

const handleColSpanToiTable = (order, colOrder) => {
  switch (order) {
    case 0:
    case 1:
      return 5
    case 2:
    case 3:
    case 4: {
      if (colOrder === 0) {
        return 2
      }
      return 3
    }
    default:
      return 1
  }
}

const filterByDataGroup = (data, dataGroup) => {
  return data.filter((item) => item?.dataGroup === dataGroup)
}

export const handleTOIRowDataByOrder = (data) => {
  const dataGroup1 = filterByDataGroup(data, DATA_GROUP_1)
  const checked = []
  const toiTableData = []

  dataGroup1.map((item) => {
    const isCheckOrder = checked.includes(item?.order)
    if (!isCheckOrder) {
      checked.push(item?.order)
      const newRow = dataGroup1
        .filter((col) => col.order === item?.order)
        .sort((a, b) => a - b)
        .map((col) => {
          return {
            ...col,
            colSpan: handleColSpanToiTable(col?.order, col?.colOrder),
          }
        })
      toiTableData.push(newRow)
    }

    return true
  })

  return toiTableData
}

export const initialCollapseToiTable = (data) => {
  const dataGroup1 = filterByDataGroup(data, DATA_GROUP_1)
  const expandDefault = dataGroup1.find(
    (item) => item?.expandDefault && item?.expandable,
  )
  return expandDefault?.fieldName
}

export const handleNonNiiData = (data) => {
  const dataGroup2 = filterByDataGroup(data, DATA_GROUP_2)
  const headerNonNiiTable = dataGroup2.filter(
    (item) => item?.parentField === NNII_G,
  )
  const bodyNonNiiTable = headerNonNiiTable.map((item) => {
    return dataGroup2.find((col) => col?.parentField === item.fieldName)
  })
  return [headerNonNiiTable, bodyNonNiiTable]
}

export const filterChildrenTableDataGroup2 = (data) => {
  const dataGroup2 = filterByDataGroup(data, DATA_GROUP_2)
  const nonNiiTableData = handleNonNiiData(data).flat()
  return dataGroup2.filter((item) => !nonNiiTableData.includes(item))
}

export const handleLayoutChildrenTableData = (data, orderConfig) => {
  const childrenTableData = filterChildrenTableDataGroup2(data)
  const layoutData = orderConfig.map((order) => {
    return childrenTableData.filter((item) => item?.order === order)
  })
  return layoutData
}

export const handleCollapseTableData = (data) => {
  const childrenTableData = filterChildrenTableDataGroup2(data)
  const ORDER_FOOTER_HEADER_CONFIG = [
    ...ORDER_FOOTER_CONFIG,
    ...ORDER_HEADER_CONFIG,
  ]
  return childrenTableData.filter((item) => {
    return !ORDER_FOOTER_HEADER_CONFIG.includes(item?.order)
  })
}

export const findAllChildrenByFieldName = (fieldName, collapseData) => {
  let allChildren = []
  let newChildren = []
  let firstTime = 1

  do {
    if (firstTime === 1) {
      newChildren = [
        ...collapseData.filter((item) => item?.parentField === fieldName),
      ]
      newChildren = [...newChildren].map((item) => ({
        ...item,
        disabled: false,
        prevActive: item?.expandDefault,
        prevDisabled: [],
      }))
      allChildren = [...newChildren]
      firstTime++
    } else {
      allChildren = [...allChildren, ...newChildren]
      let findChildren = []
      newChildren.map((children) => {
        if (
          children.disabled ||
          (!children?.expandDefault && children?.expandable)
        ) {
          const findNewChildren = [
            ...collapseData.filter(
              (item) => item?.parentField === children.fieldName,
            ),
          ].map((item) => {
            return {
              ...item,
              disabled: true,
              prevActive: item?.expandDefault,
              prevDisabled:
                !children?.expandDefault &&
                item?.parentField === children.fieldName
                  ? [...children?.prevDisabled, children?.indicatorMappingId]
                  : [...children?.prevDisabled],
            }
          })
          findChildren = [...findChildren, ...findNewChildren]
        } else {
          findChildren = [
            ...[
              ...collapseData.filter(
                (item) => item?.parentField === children.fieldName,
              ),
            ].map((item) => ({
              ...item,
              disabled: false,
              prevActive: item?.expandDefault,
              prevDisabled: [],
            })),
            ...findChildren,
          ]
        }
        return true
      })
      newChildren = findChildren
    }
  } while (newChildren.length > 0)

  return handleDuplicate(allChildren)
}

const handleDuplicate = (allChildren) => {
  const childrenNotDuplicate = []
  const checked = []
  allChildren
    .sort((a, b) => a?.order - b?.order)
    .map((item, _, arr) => {
      const isChecked = checked.includes(item?.fieldName)
      if (!isChecked) {
        checked.push(item?.fieldName)
        const checkOnlyValue = arr.filter(
          (col) => col?.fieldName === item?.fieldName,
        )
        const newRow =
          checkOnlyValue.length === 1
            ? item
            : {
                ...item,
                relative: checkOnlyValue[1],
              }
        childrenNotDuplicate.push(newRow)
      }
      return true
    })
  return childrenNotDuplicate
}

export const initialCollapseTable = (data) => {
  const collapseData = handleCollapseTableData(data)
  const leftData = findAllChildrenByFieldName(EA_G, collapseData)
  const rightData = findAllChildrenByFieldName(IBL_G, collapseData)
  const findIndexByRelativeFieldName = (relativeFieldName, data) => {
    return data.findIndex((item) => item?.relativeField === relativeFieldName)
  }
  const tableData = {
    left1TableData: formatDataByGroup(
      leftData.slice(0, findIndexByRelativeFieldName(CTNIM1, leftData) + 1),
      'left1',
    ),
    left2TableData: formatDataByGroup(
      leftData.slice(
        findIndexByRelativeFieldName(CTNIM1, leftData) + 1,
        findIndexByRelativeFieldName(CTNIM2, leftData) + 1,
      ),
      'left2',
    ),
    left3TableData: formatDataByGroup(
      leftData.slice(findIndexByRelativeFieldName(CTNIM2, leftData) + 1),
      'left3',
    ),
    right1TableData: formatDataByGroup(
      rightData.slice(0, findIndexByRelativeFieldName(CTNIM4, rightData) + 1),
      'right1',
    ),
    right2TableData: formatDataByGroup(
      rightData.slice(
        findIndexByRelativeFieldName(CTNIM4, rightData) + 1,
        findIndexByRelativeFieldName(CTNIM5, rightData) + 1,
      ),
      'right2',
    ),
    right3TableData: formatDataByGroup(
      rightData.slice(findIndexByRelativeFieldName(CTNIM5, rightData) + 1),
      'right3',
    ),
  }

  return tableData
}

const formatDataByGroup = (data, group) => {
  const SERVER_LEVEL_DIFFERENCE = 4
  return data.map((item) => {
    return {
      ...item,
      title: item?.indicator,
      level: item?.relativeField.includes(CTNIM)
        ? 1
        : item?.level - SERVER_LEVEL_DIFFERENCE,
      id: item?.indicatorMappingId,
      isDropdown: item?.expandable,
      isActive: item?.expandDefault,
      isContributionToNIM: item?.relativeField.includes(CTNIM),
      disabled: item?.disabled,
      group: group,
    }
  })
}

export const mappingData = (tableUIData, incomeStructureData) => {
  return tableUIData.map((row) => {
    return mappingDataCollapseTable(row, incomeStructureData)
  })
}

export const mappingDataCollapseTable = (row, incomeStructureData) => {
  return row.map((col) => {
    if (col?.type === IND) {
      const val = incomeStructureData.find(
        (item) => item?.fieldName === col?.fieldName,
      )
      const relativeVal = incomeStructureData.find(
        (item) => item?.fieldName === col?.relativeField,
      )
      if (col?.fieldName_1 && col?.relativeField_1) {
        const val1 = incomeStructureData.find(
          (item) => item?.fieldName === col?.fieldName_1,
        )
        const relativeVal1 = incomeStructureData.find(
          (item) => item?.fieldName === col?.relativeField_1,
        )
        return {
          ...col,
          value: val?.value,
          relativeValue: relativeVal?.value,
          value1: val1?.value,
          relativeValue1: relativeVal1?.value,
          alias: val?.fieldName,
          alias1: val1?.fieldName,
          relativeIndicator1: val1?.relativeIndicator,
        }
      }
      return {
        ...col,
        value: val?.value,
        relativeValue: relativeVal?.value,
        alias: val?.fieldName,
      }
    }
    return col
  })
}

export const getTimeFrame = (val) => {
  if (!val) {
    return DEFAULT_TIME_RANGE
  }

  switch (val) {
    case 'Quarterly':
      return 'ThreeMonths'
    case 'SixMonths':
      return 'SixMonths'
    case 'NineMonths':
      return 'NineMonths'
    default:
      return DEFAULT_TIME_RANGE
  }
}
