import { useSelector } from 'react-redux'
import { selectChartTabActiveData } from '../../store/slice'

const useIsShowChart = () => {
  const chartTabActiveData = useSelector(selectChartTabActiveData)
  if (!chartTabActiveData) return false

  const { indicatorSelected, treeSecuritiesIndicator, equations } =
    chartTabActiveData
  const { companies, economy, indices, sector } = treeSecuritiesIndicator || {}
  const hasIndicator =
    !!indicatorSelected && Object.values(indicatorSelected).flat().length > 0
  const hasSecurities =
    (!!companies && Object.keys(companies).length > 0) ||
    (!!indices && Object.keys(indices).length > 0) ||
    (!!sector && Object.keys(sector).length > 0)
  const hasEconomy = !!economy && Object.keys(economy).length > 0
  const hasEquations = !!equations && Object.keys(equations).length > 0

  return hasEconomy || (hasIndicator && hasSecurities) || hasEquations
}

export default useIsShowChart
