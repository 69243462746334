import PropTypes from 'prop-types'
import { PopupHeaderCommon } from '../popup/PopupHeaderCommon'
import { EmailIcon } from './emailIcon'
import { FacebookIcon } from './facebookIcon'
import style from './index.module.css'
import { LinkIcon } from './linkIcon'
import { SkypeIcon } from './skypeIcon'
import { ZaloIcon } from './zaloIcon'

export const SharePopup = ({ setIsShow, url }) => {
  return (
    <PopupHeaderCommon setIsShow={setIsShow} title="common.shareButton.SHARE">
      <div>
        <div className={style.popupFirstRowContent}>
          <FacebookIcon url={url} />
          <SkypeIcon url={url} />
          <ZaloIcon url={url} />
          <EmailIcon url={url} />
        </div>
        <div className={style.popupSecondRowContent}>
          <LinkIcon url={url} />
        </div>
      </div>
    </PopupHeaderCommon>
  )
}

SharePopup.propTypes = {
  setIsShow: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
}
