import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import TradingDataService from '../../../../../../core/services/tool/dataExplorer/TradingDataService'
import { handleExport } from '../../../../../utils/Export'

export const getCorporateResultThunk = createAsyncThunk(
  'dataExplorer/tradingData/GET_CORPORATE_RESULT',
  async (params, { rejectWithValue }) => {
    try {
      const response = await TradingDataService.getCorporateResult(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndexSectorResultThunk = createAsyncThunk(
  'dataExplorer/tradingData/GET_INDEX_SECTOR_RESULT',
  async (params, { rejectWithValue }) => {
    try {
      const response = await TradingDataService.getIndexSectorResult(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadExportExcel = createAsyncThunk(
  'dataExplorer/tradingData/DOWNLOAD_EXPORT_EXCEL',
  async (data) => {
    const response = await TradingDataService.downloadExportExcel(data)
    handleExport(response.data, response.filename)
  },
)
