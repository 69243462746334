export const externalLink = {
  contact:
    'https://docs.google.com/forms/d/e/1FAIpQLSdlX9CCslQU8nnHwY_FNYiPPf9yDDIZoVacECpAVDAgQPo9_Q/viewform',
  help: 'https://docs.fiinx.vn/',
  upgrade: 'https://web.fiintrade.vn/chinh-sach-gia/',
  renew: 'https://web.fiintrade.vn/chinh-sach-gia/',
  tradingViewSnapshotUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/project/v1/ChartLayout/ExtractChartLayoutContent`,
  rankingSeeMore:
    'https://web.fiintrade.vn/media/esmng1p2/fiintrade_ranking-methodology_v1-0.pdf',
}
