import OverviewProxy from '../../../proxies/economy/fdi/OverviewProxy'
import { ServiceBase } from '../../ServiceBase'

export class OverviewService extends ServiceBase {
  getTotalRegisteredFDICapital(params) {
    return this.getData(() =>
      OverviewProxy.getTotalRegisteredFDICapital(params),
    )
  }

  downloadTotalRegisteredFDICapital(params) {
    return this.getFileDownload(() =>
      OverviewProxy.downloadTotalRegisteredFDICapital(params),
    )
  }

  getTotalRegisteredFDICapitalChart(params) {
    return this.getData(() =>
      OverviewProxy.getTotalRegisteredFDICapitalChart(params),
    )
  }

  getKeyIndicatorCountry(params) {
    return this.getData(() => OverviewProxy.getKeyIndicatorCountry(params))
  }

  getKeyIndicatorProvince(params) {
    return this.getData(() => OverviewProxy.getKeyIndicatorProvince(params))
  }

  getFDIIndustryRequest(params) {
    return this.getData(() => OverviewProxy.getFDIIndustryRequest(params))
  }

  getFDIStructureRequest(params) {
    return this.getData(() => OverviewProxy.getFDIStructureRequest(params))
  }
}

export default new OverviewService()
