import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { formatVal, valToPercent } from '../../../../utils/Value'

const formatToValueWithFeeTypeId = 7

export const getTableSchema = ({ dataById }) => {
  return ['durationName', 'feePercentValue'].map((key) => {
    if (key === 'durationName') {
      return {
        colId: key,
        isI18n: false,
        canCustomTd: true,
        render: (value, rowId, { style, className }) => (
          <td
            className={className}
            style={
              dataById[rowId]?.children
                ? { ...style, backgroundColor: '#273040', fontWeight: 600 }
                : { ...style, paddingLeft: 24 }
            }
          >
            <div
              style={{
                textAlign: 'left',
                whiteSpace: 'nowrap',
              }}
            >
              {value}
            </div>
          </td>
        ),
      }
    }

    return {
      colId: key,
      isI18n: false,
      canCustomTd: true,
      render: (value, rowId, { style, className }) => (
        <td
          className={className}
          style={
            dataById[rowId]?.children
              ? { ...style, backgroundColor: '#273040', fontWeight: 600 }
              : style
          }
        >
          <div
            style={{
              textAlign: 'right',
              whiteSpace: 'nowrap',
            }}
          >
            {dataById[rowId]?.children ? (
              ''
            ) : value === 0 ? (
              <Translate value="common.FREE" />
            ) : dataById[rowId]?.feeTypeId === formatToValueWithFeeTypeId ? (
              <Span>
                {formatVal(value)} <Translate value="common.UNIT.VND" />
              </Span>
            ) : (
              valToPercent(value)
            )}
          </div>
        </td>
      ),
    }
  })
}

export const formatApiData = (data) => {
  const dataByFeeTypeId = {}
  data.forEach((item) => {
    if (!dataByFeeTypeId[item.feeTypeId]) {
      dataByFeeTypeId[item.feeTypeId] = {
        id: `${item.feeTypeId}_${item.feeTypeName}`,
        feeTypeId: item.feeTypeId,
        durationName: item.feeTypeName,
        feePercentValue: '',
        children: [{ ...item, id: `${item.feeTypeId}_${item.durationName}` }],
      }
    } else {
      dataByFeeTypeId[item.feeTypeId].children.push({
        ...item,
        id: `${item.feeTypeId}_${item.durationName}`,
      })
    }
  })
  return Object.values(dataByFeeTypeId).reduce(
    (arr, item) => [...arr, item, ...item.children],
    [],
  )
}
