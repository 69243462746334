import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTimeSpan } from '../../common/DateTimeSpan'
import { Table } from '../../common/table'
import { indices, tableKeys } from '../constants'
import style from '../index.module.css'
import {
  addChecked,
  changeWatchlistIds,
  selectChecked,
  selectLoading,
  selectWatchlistIds,
  selectWatchlistsCell,
  sortWatchlist,
  unchecked,
  uncheckedAll,
} from '../store/slice'
import GroupButton from './GroupButton'
import WatchlistButtonMore from './WatchlistButtonMore'
import WatchlistCheckbox from './WatchlistCheckbox'
import WatchlistEdit from './WatchlistEdit'
import WatchlistFavorite from './WatchlistFavorite'

const WatchlistTable = ({ handleShowDetail }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading('watchlist'))
  const watchlistIds = useSelector(selectWatchlistIds)
  const checkedList = useSelector(selectChecked)

  useEffect(() => {
    return () => dispatch(uncheckedAll())
  }, [dispatch])

  const handleClickCreate = () => {
    handleShowDetail(null)
  }

  const handleDbClickRow = (id) => handleShowDetail(id)

  const onRowClick = (id) => {
    const condition = checkedList.some((item) => item === id)
    if (!condition) dispatch(addChecked(id))
    else dispatch(unchecked(id))
  }

  return (
    <div className="modal-content">
      <GroupButton handleClickCreate={handleClickCreate} />
      <div
        id="table-watchlist"
        className={`${style.table} ${style.tableWatchlist} ${style.tableNoneScrollHorizon}`}
      >
        <Table
          ids={watchlistIds}
          columnDraggable={false}
          getDataFromRedux={selectWatchlistsCell}
          isLoading={isLoading}
          changeIds={changeWatchlistIds}
          rowDraggable={false}
          hasFooter={false}
          hasTooltip={false}
          resizable={false}
          onRowDoubleClick={handleDbClickRow}
          onRowClick={onRowClick}
          schema={Object.keys(indices.index).map((key) => {
            const title = `watchlistBond.watchlistTable.indices.${key}`
            const colId = indices.index[key]
            const result = {
              colId,
              title,
              disableSort: true,
              thStyle: {
                height: '36px',
                textAlign: [
                  tableKeys.CHECKBOX,
                  tableKeys.REFRESH,
                  tableKeys.MORE,
                  tableKeys.FAVORITE,
                ].includes(key)
                  ? 'center'
                  : 'left',
                textTransform: key === tableKeys.MORE ? 'unset' : 'uppercase',
              },
              tdStyle: {
                margin: [tableKeys.NAME, tableKeys.DESCRIPTION].includes(key)
                  ? '0px -8px'
                  : undefined,
                height: key === tableKeys.NAME ? '100%' : undefined,
              },
            }

            return {
              ...result,
              isI18n: false,
              render:
                key === tableKeys.CHECKBOX
                  ? (_, colId) => {
                      return <WatchlistCheckbox id={colId} />
                    }
                  : key === tableKeys.MORE
                  ? (_, colId) => {
                      return <WatchlistButtonMore id={colId} />
                    }
                  : key === tableKeys.NAME || key === tableKeys.DESCRIPTION
                  ? (value, colId) => {
                      return (
                        <WatchlistEdit
                          value={value}
                          tableKey={key}
                          id={colId}
                        />
                      )
                    }
                  : key === tableKeys.FAVORITE
                  ? (value, colId) => {
                      return <WatchlistFavorite value={value} id={colId} />
                    }
                  : (value) => {
                      return <DateTimeSpan date={value} />
                    },
              renderTh:
                key === tableKeys.CHECKBOX
                  ? () => {
                      return <WatchlistCheckbox />
                    }
                  : undefined,
            }
          })}
          sort={sortWatchlist}
        />
      </div>
      <div id="footer-table-watchlist" />
    </div>
  )
}

WatchlistTable.propTypes = {
  handleShowDetail: PropTypes.func.isRequired,
}

export default WatchlistTable
