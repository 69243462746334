import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import Dropdown, { positionDropdown } from '../../../../common/dropdown'
import { Loading } from '../../../../common/loading'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { debounce } from '../../../../utils/Common'
import { LIST_TYPE } from '../../../common/constants'
import InputSearch from '../InputSearch'
import { listTab } from '../constants'
import { selectEquationLoading } from '../store/slice'
import CompanyList from './CompanyList'
import WatchList from './WatchList'
import style from './style.module.css'

const PopupSecurities = ({ isShow, onClose, parentRef }) => {
  const securitiesRef = useRef()
  const selectExchangeRef = useRef()
  const selectSectorRef = useRef()

  const equationLoading = useSelector(selectEquationLoading)

  const [keyword, setKeyword] = useState('')
  const [listType, setListType] = useState(LIST_TYPE.COMPANIES)

  useEffect(() => {
    if (!isShow) {
      setKeyword('')
      setListType(LIST_TYPE.COMPANIES)
    }
  }, [isShow])

  const renderItemList = () => {
    const props = {}

    switch (listType) {
      case LIST_TYPE.WATCH_LIST:
        return <WatchList {...props} valueSearch={keyword} />
      default:
        return (
          <CompanyList
            {...props}
            selectExchangeRef={selectExchangeRef}
            selectSectorRef={selectSectorRef}
            valueSearch={keyword}
          />
        )
    }
  }

  return (
    <Dropdown
      parentRef={parentRef}
      isShow={isShow}
      position={positionDropdown.BOTTOM_RIGHT_RIGHT}
    >
      <HandleClickOutside
        handleClickOutside={onClose}
        exceptRef={[
          parentRef,
          securitiesRef,
          selectExchangeRef,
          selectSectorRef,
        ]}
      >
        <div className={style.securitiesContainer}>
          <SizeTracker>
            {(size) => (
              <>
                <InputSearch
                  onChange={debounce((value) => {
                    setKeyword(value)
                  }, 500)}
                  placeholder="financialChart.SEARCH_SECURITIES"
                  height={24}
                  appendStyle={{
                    margin: 0,
                    width: '100%',
                  }}
                />
                <div className="mt-8 mb-15">
                  <DispatchActionTab
                    data={listTab}
                    activeTab={listType}
                    onChangeTab={({ value }) => setListType(value)}
                    containerStyle={{
                      height: 24,
                      backgroundColor: '#ffffff',
                    }}
                    itemClass={style.customTab}
                    itemActiveClass={style.customTabActive}
                  />
                </div>
                {size.height && (
                  <div
                    className="position-relative"
                    style={{ height: `calc(100% - ${size.height}px)` }}
                  >
                    {equationLoading && <Loading isBlack />}
                    {renderItemList()}
                  </div>
                )}
              </>
            )}
          </SizeTracker>
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

PopupSecurities.propTypes = {
  isShow: PropTypes.bool,
  parentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}

export default PopupSecurities
