import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { TAB_TYPE } from '../table/constants'
import { getMaxDate } from './thunk'

const INIT_LIMIT_TIME = {
  minMonth: 0,
  minYear: 0,
}

const slice = createSlice({
  name: 'economy/productionAndConsumption/productionStore',
  initialState: {
    tab: TAB_TYPE.GENERAL_INDEX,
    limitTime: INIT_LIMIT_TIME,
    limitTimeLoading: true,
  },
  reducers: {
    handleTab: (state, action) => {
      state.tab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMaxDate.pending, (state) => {
      state.limitTimeLoading = true
    })
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.limitTimeLoading = false
      state.limitTime = handleLimitTime(action.payload || {})
    })
    builder.addCase(getMaxDate.rejected, (state, action) => {
      state.limitTimeLoading = action.payload.isLoading
    })
  },
})

export const { handleTab } = slice.actions

export const getTab = (state) => state[slice.name].tab
export const getLimitTime = (state) => state[slice.name].limitTime
export const getLimitTimeLoading = (state) => state[slice.name].limitTimeLoading

register(slice.name, slice.reducer)

const handleLimitTime = (payload) => {
  if (!Object.keys(payload).length) {
    return INIT_LIMIT_TIME
  }

  return {
    minMonth: payload.realMonth,
    minYear: payload.realYear,
  }
}
