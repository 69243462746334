import { chartAttr, colorArray } from './constants'

export const getColorPieChart = (data, keyOthers, type) => {
  let index = 0
  const sliceOthers = data.find((i) => i.ownershipGroup === keyOthers)
  const newArr = data.filter((i) => i.ownershipGroup !== keyOthers)
  newArr.sort((firstItem, secondItem) => {
    return secondItem.percentage - firstItem.percentage
  })

  if (sliceOthers) {
    newArr.push(sliceOthers)
  }

  return newArr.map((item) => {
    const result = {
      text: item.displayName || chartAttr[type][item.ownershipGroup].name,
      group: item.ownershipGroup,
      value: item.percentage,
      isI18n: !item.displayName,
    }
    if (item.ownershipGroup !== keyOthers) {
      result.color = colorArray[index]
      index++
    } else {
      result.color = colorArray[colorArray.length - 1]
    }
    return result
  })
}
