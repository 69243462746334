import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OpenMarketService from '../../../../../../core/services/economy/OpenMarketService'

export const getOutstandingVolume = createAsyncThunk(
  'economy/monetary/openMarket/panelShareOutstanding/getOutstandingVolume',
  async (params, { rejectWithValue }) => {
    try {
      const res = await OpenMarketService.getOutstandingVolume(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
