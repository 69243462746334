import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getOverview } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/steel/overviewSteel/overview',
  initialState: {
    isLoading: false,
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOverview.pending, (state) => {
      state.isLoading = true
      state.data = {}
    })
    builder.addCase(getOverview.fulfilled, (state, action) => {
      state.isLoading = false
      const data = action.payload || {}
      state.data = data
    })
    builder.addCase(getOverview.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectData = (state) => state[slice.name].data

register(slice.name, slice.reducer)
