import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { getMultiplierText } from '../helpers'
import {
  dataFormatTypes,
  multiplierTypes,
} from '../menuChartSettings/constants'
import { selectColumnByIdCell } from './store/slice'
import style from './style.module.css'

const ThTable = ({ colId, thData, listLegendCustom }) => {
  const column = useSelector(selectColumnByIdCell(colId))

  const getTitle = () => {
    let title = thData ? thData.title : column?.title
    const fieldId = column?.fieldIdBySecurities || column?.fieldId

    if (
      listLegendCustom &&
      thData.colId === column?.fieldId &&
      listLegendCustom[fieldId]
    ) {
      title = listLegendCustom[fieldId]
    }

    return title
  }

  const getMultiplier = () => {
    const unit =
      column.chartAttributes.indicatorUnit ||
      column.chartAttributes.equationUnit

    if (
      column.chartAttributes.dataFormatType === dataFormatTypes.PERCENT ||
      column.chartAttributes.dataFormatType === dataFormatTypes.PREV_PERCENT
    ) {
      return ''
    }

    if (
      column.chartAttributes.multiplier &&
      column.chartAttributes.multiplier !== multiplierTypes.RAW &&
      unit !== '%'
    ) {
      return (
        <span style={{ marginRight: 4 }}>
          <Translate
            value={`financialChart.${getMultiplierText(
              column.chartAttributes.multiplier,
            )}`}
          />
        </span>
      )
    }

    return ''
  }

  const getUnit = () => {
    if (
      column.chartAttributes.dataFormatType === dataFormatTypes.PERCENT ||
      column.chartAttributes.dataFormatType === dataFormatTypes.PREV_PERCENT
    ) {
      return '%'
    }

    return thData?.unit ? thData.unit : column?.unit
  }

  return (
    <div style={thData?.thStyle || column?.thStyle || {}}>
      <div
        className="d-flex ali-center"
        style={{
          justifyContent:
            thData?.thStyle.textAlign === 'right' ? 'right' : 'left',
        }}
      >
        {colId === thData.colId && (
          <div
            style={{
              backgroundColor: column.chartAttributes.color,
              width: 12,
              height: 6,
              marginRight: 8,
            }}
          />
        )}
        <Span
          className={[
            style.theadContent,
            thData?.rowIndex === 0 && style.firstRow,
          ].join(' ')}
          style={{ fontSize: 10, width: 'fit-content' }}
        >
          {getTitle()}
        </Span>
      </div>
      {thData.colId === column?.fieldId && (!!thData?.unit || !!column?.unit) && (
        <Span className={style.additionText} style={{ fontSize: 10 }}>
          <Translate value="financialChart.table.UNIT" />
          {getMultiplier()}
          {getUnit()}
        </Span>
      )}
    </div>
  )
}

export default ThTable
