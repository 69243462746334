import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { INDICATOR_TYPE } from '../../../../common/dataExplorer/constant'
import { idScroll } from '../../../../common/dataExplorer/table'
import { MOUSE_OUT_TH } from '../../../../common/dataExplorer/table/Thead'
import Dropdown, { positionDropdown } from '../../../../common/dropdown'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { Span } from '../../../../common/html/Span'
import EventEmitter from '../../../../utils/EventEmitter'
import { typePopupIndicator } from '../constant'
import { handleNameIndicator } from '../helper'
import style from '../index.module.css'
import {
  clearGroup,
  editIndicator,
  removeColumn,
  selectActiveSheet,
  selectColumnCell,
  selectConditionGroup,
  selectIndicatorById,
  selectMostRecent,
} from '../store/slice'

const TitleColumn = ({ title, colId }) => {
  const activeSheet = useSelector(selectActiveSheet)
  const column = useSelector(selectColumnCell(activeSheet)(colId))
  const conditionGroup = useSelector(
    selectConditionGroup(activeSheet)(column.groupId),
  )
  const indicatorById = useSelector(selectIndicatorById)

  const indicatorType =
    column.conditionType === typePopupIndicator.DE01_SC20
      ? conditionGroup.period === 'MostRecentQuarter'
        ? INDICATOR_TYPE.TTM
        : INDICATOR_TYPE.YEARLY
      : column.indicatorType

  const mostRecent = useSelector(
    selectMostRecent(column.indicatorId, indicatorType),
  )

  return (
    <div className={style.titleColumn}>
      {handleNameIndicator(
        indicatorById[column.indicatorId],
        conditionGroup,
        column.conditionType,
        title,
        mostRecent || {},
      )}
    </div>
  )
}

const ThTable = ({ title, colId, column, isFiinXTemplate }) => {
  const dispatch = useDispatch()

  const parentRef = useRef()

  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  useEffect(() => {
    const closeTooltip = () => {
      setIsOpenTooltip(false)
    }
    const firstChildOfScroll = document.querySelector(
      `#${idScroll} .scrollbars div:first-child`,
    )

    if (isOpenTooltip && firstChildOfScroll) {
      firstChildOfScroll.addEventListener('scroll', closeTooltip)
    }

    return () => {
      if (firstChildOfScroll) {
        firstChildOfScroll.removeEventListener('scroll', closeTooltip)
      }
    }
  }, [isOpenTooltip])

  const handleTooltip = (e) => {
    e.stopPropagation()
    setIsOpenTooltip(!isOpenTooltip)
  }

  const handleClickOutside = () => {
    setIsOpenTooltip(false)
  }

  const handleRemoveColumn = (e) => {
    e.stopPropagation()
    dispatch(removeColumn([colId]))
    setIsOpenTooltip(false)
    EventEmitter.dispatch(MOUSE_OUT_TH)
  }

  const handleRemoveGroup = (e) => {
    e.stopPropagation()
    dispatch(clearGroup(column.groupId))
    setIsOpenTooltip(false)
    EventEmitter.dispatch(MOUSE_OUT_TH)
  }

  const handleSettingColumn = (e) => {
    e.stopPropagation()
    dispatch(editIndicator(colId))
    setIsOpenTooltip(false)
    EventEmitter.dispatch(MOUSE_OUT_TH)
  }

  return (
    <div className="d-flex">
      <div
        className={`${
          !isFiinXTemplate ? style.contentTh : ''
        } position-relative`}
      >
        <div
          className={style.colorTh}
          style={{ backgroundColor: column.color }}
        />
        <Span style={{ fontSize: 10 }}>
          <TitleColumn title={title} colId={colId} />
        </Span>
      </div>
      {!isFiinXTemplate && (
        <button
          onClick={handleTooltip}
          ref={parentRef}
          className={`${style.btnTh} ${isOpenTooltip ? style.btnThActive : ''}`}
        >
          <i className="icon-menu-dot-hor" />
        </button>
      )}
      <Dropdown
        parentRef={parentRef}
        isShow={isOpenTooltip}
        position={positionDropdown.BOTTOM_RIGHT_LEFT}
      >
        <HandleClickOutside
          handleClickOutside={handleClickOutside}
          exceptRef={parentRef}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={`box-notice ${style.tooltip}`}
          >
            <div onClick={handleSettingColumn} className={style.itemTooltip}>
              <Span>
                <Translate value="tool.dataExplorer.corporate.SETTING" />
              </Span>
            </div>
            <div onClick={handleRemoveColumn} className={style.itemTooltip}>
              <Span>
                <Translate value="tool.dataExplorer.corporate.REMOVE_ONLY" />
              </Span>
            </div>
            <div onClick={handleRemoveGroup} className={style.itemTooltip}>
              <Span>
                <Translate value="tool.dataExplorer.corporate.REMOVE_GROUP" />
              </Span>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

export default ThTable
