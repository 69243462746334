import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { range } from '../../../../utils/Common'
import style from '../index.module.css'
import ItemSelect from './ItemSelect'

const TTM = ({
  keyQuarter,
  keyYear,
  disabled,
  ttmMaxQuarter,
  ttmMaxYear,
  ttmMinYear,
  isFinancialStatement,
}) => {
  const selectYear = range(ttmMinYear, ttmMaxYear)
    .reverse()
    .map((year) => {
      return { value: year, name: year }
    })

  let selectQuarter = [
    { value: 1, name: 'Q1' },
    { value: 2, name: 'Q2' },
    { value: 3, name: 'Q3' },
    { value: 4, name: 'Q4' },
  ]

  if (isFinancialStatement) {
    selectQuarter = selectQuarter.concat([
      { value: 6, name: I18n.t('common.popup.SIX_MONTH') },
      { value: 9, name: I18n.t('common.popup.NINE_MONTH') },
      { value: 5, name: I18n.t('common.popup.ANNUAL') },
    ])
  }

  return (
    <div className="d-flex">
      <div className={style.ttmSelect}>
        <ItemSelect
          defaultValue={ttmMaxQuarter}
          keySelect={keyQuarter}
          selectData={selectQuarter}
          disabled={disabled}
        />
      </div>
      <div className={style.ttmSelect}>
        <ItemSelect
          defaultValue={ttmMaxYear}
          keySelect={keyYear}
          selectData={selectYear}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

TTM.propTypes = {
  keyQuarter: PropTypes.string.isRequired,
  keyYear: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isFinancialStatement: PropTypes.bool,
}

TTM.defaultProps = {
  disabled: false,
  isFinancialStatement: false,
}

export default TTM
