import priceDataProxy from '../../proxies/common/PriceDataProxy'
import { ServiceBase } from '../ServiceBase'

class PriceDataService extends ServiceBase {
  getLatestPrice(params) {
    return this.applyMemoryCache(
      'PriceDataService.getLatestPrice',
      params,
    )(() => priceDataProxy.getLatestPrice(params))
  }

  getLatestPriceWithCancelToken(params, cancelTokenParams) {
    return this.applyMemoryCache(
      'PriceDataService.getLatestPriceWithCancelToken',
      params,
    )(() =>
      priceDataProxy.getLatestPriceWithCancelToken(params, cancelTokenParams),
    )
  }

  getLatestIndices(params) {
    return this.getData(() => priceDataProxy.getLatestIndices(params))
  }

  getLatestSectors(params) {
    return this.getData(() => priceDataProxy.getLatestSectors(params))
  }
}

export default new PriceDataService()
