import { ReactComponent as IconDownloadBlack } from '../../icon/icon-download-black.svg'
import { ReactComponent as IconShareBlack } from '../../icon/icon-share.svg'
import style from './Header.module.css'

export const Header = () => {
  return (
    <div className="w-100">
      <div className={style.toolHeader}>
        <div className={`${style.groupBtnTool} ${style.disable}`}>
          <i className="icon-setting-bold" />
          <IconDownloadBlack />
          <IconShareBlack />
        </div>
      </div>
    </div>
  )
}
