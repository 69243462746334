import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class CPIAndInflationProxy extends ProxyBase {
  getCPIAndInflationTable(params) {
    return this.get('CPIVNTypes', params)
  }

  getCPIAndInflationTableContent(params) {
    return this.get('CPIAndInflation', params, null, true)
  }

  downloadCPIAndInflationTableContent(params) {
    return this.get('DownloadCPIAndInflation', params, 'download')
  }

  getGrowthOverYears(params) {
    return this.get('CPIAndInflationGrowthOverYears', params)
  }

  getContribution(params) {
    return this.get('ContributionToCPI', params)
  }

  getGrowthSector(params) {
    return this.post('CPIAndInflationGrowth', params)
  }
}

export default new CPIAndInflationProxy(
  ServiceProxyConfig.Economy.CPIAndInflation.ServiceUrl,
)
