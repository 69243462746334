export const chartFillColor = {
  rtD0022: '#3fbcb7',
  rtD0045: '#ffbbd6',
  rtD0011: '#0096eb',
  rtD0044: '#ffe3df',
  rtD0046: '#eb84ad',
}

export const priceEarningMappingDisplay = {
  rtD0022: 'corporate.financialData.financialDividend.PE',
  rtD0045: 'corporate.financialData.financialDividend.DIV_YIELD',
}

export const earningAgainstMappingDisplay = {
  rtD0011: 'corporate.financialData.financialDividend.EPS',
  rtD0044: 'corporate.financialData.financialDividend.DPS',
  rtD0046: 'corporate.financialData.financialDividend.DIVIDEND_PAYOUT_RATIO',
}

export const chartSettings = {
  fontSize: 10,
}
