import newsReportsProxy from '../../../proxies/sector/sectorConstituents/NewsReportsProxy'
import { ServiceBase } from '../../ServiceBase'

class NewsReportsService extends ServiceBase {
  getListReport(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getListReport',
      params,
    )(() => newsReportsProxy.getListReport(params))
  }

  getListNews(params) {
    return this.getData(() => newsReportsProxy.getListNews(params))
  }

  getNewsSources(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getNewsSources',
      params,
    )(() => newsReportsProxy.getNewsSources(params))
  }

  getReportSources(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getReportSources',
      params,
    )(() => newsReportsProxy.getReportSources(params))
  }

  getNewsCategories(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getNewsCategories',
      params,
    )(() => newsReportsProxy.getNewsCategories(params))
  }

  getReportTypes(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getReportTypes',
      params,
    )(() => newsReportsProxy.getReportTypes(params))
  }

  getLatestNews(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getLatestNews',
      params,
    )(() => newsReportsProxy.getLatestNews(params))
  }

  getNewsById(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getNewsById',
      params,
    )(() => newsReportsProxy.getNewsById(params))
  }

  getListNewsOverview(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getListNewsOverview',
      params,
    )(() => newsReportsProxy.getListNewsOverview(params))
  }
}

export default new NewsReportsService()
