import { useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import style from '../index.module.css'
import RemoveAll from './RemoveAll'
import { getListDisplay, getTickerIds } from './store/slice'
import TickerItemSelected from './TickerItemSelected'

const SelectedItem = () => {
  const tickerIds = useSelector(getTickerIds)
  const listDisplay = useSelector(getListDisplay)

  const itemsDisplayed = Object.keys(listDisplay).filter(
    (id) => listDisplay[id].isDisplay && listDisplay[id].isChecked,
  )
  const itemSorted = tickerIds.filter((id) => itemsDisplayed.includes(`${id}`))

  return (
    <div className={style.selectedItem}>
      <RemoveAll />
      <div style={{ flex: 1 }}>
        <ScrollComponent>
          {itemSorted.length > 0 &&
            itemSorted.map((tickerId, index) => (
              <TickerItemSelected
                key={tickerId}
                tickerId={tickerId}
                index={index}
              />
            ))}
        </ScrollComponent>
      </div>
    </div>
  )
}

export default SelectedItem
