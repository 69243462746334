import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  getEarningsAgainstDividendsChartData,
  getPriceEarningRatioDividendYieldChartData,
} from './thunk'

const initialState = {
  priceEarningChartData: [],
  priceEarningChartLoading: true,
  earningAgainstChartData: [],
  earningAgainstChartLoading: true,
}

export const slice = createSlice({
  name: 'corporate/financialData/dividend/chart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getPriceEarningRatioDividendYieldChartData
    builder.addCase(
      getPriceEarningRatioDividendYieldChartData.pending,
      (state) => {
        state.priceEarningChartLoading = true
      },
    )
    builder.addCase(
      getPriceEarningRatioDividendYieldChartData.fulfilled,
      (state, action) => {
        state.priceEarningChartLoading = false
        state.priceEarningChartData =
          action.payload.data?.map((item) => ({
            ...item,
            label:
              item.quarter === 5
                ? item.year
                : `Q${item.quarter}-${item.year}\n(TTM)`,
            rtD0045: item.rtD0045 * 100,
          })) || []
      },
    )
    builder.addCase(
      getPriceEarningRatioDividendYieldChartData.rejected,
      (state, action) => {
        state.priceEarningChartLoading = action.payload.loading
      },
    )
    // getEarningsAgainstDividendsChartData
    builder.addCase(getEarningsAgainstDividendsChartData.pending, (state) => {
      state.earningAgainstChartLoading = true
    })
    builder.addCase(
      getEarningsAgainstDividendsChartData.fulfilled,
      (state, action) => {
        state.earningAgainstChartLoading = false
        state.earningAgainstChartData =
          action.payload.data?.map((item) => ({
            ...item,
            label:
              item.quarter === 5
                ? item.year
                : `Q${item.quarter}-${item.year}\n(TTM)`,
            rtD0046: item.rtD0046 * 100,
          })) || []
      },
    )
    builder.addCase(
      getEarningsAgainstDividendsChartData.rejected,
      (state, action) => {
        state.earningAgainstChartLoading = action.payload.loading
      },
    )
  },
})

export const selectPriceEarningChartLoading = (state) =>
  state[slice.name].priceEarningChartLoading
export const selectPriceEarningChartData = (state) =>
  state[slice.name].priceEarningChartData
export const selectEarningAgainstChartLoading = (state) =>
  state[slice.name].earningAgainstChartLoading
export const selectEarningAgainstChartData = (state) =>
  state[slice.name].earningAgainstChartData

register(slice.name, slice.reducer)
