import PropTypes from 'prop-types'
import { COMPONENT } from '../../../configs/Layout'
import { withWrapper } from '../../common/withWrapper'
import { PanelContribution } from './contribution/PanelContribution'
import { PanelCpiAndInflationTable } from './cpiAndInflationTable/PanelCpiAndInflationTable'
import { PanelGrowthSector } from './growthSector/PanelGrowthSector'
import { PanelGrowthYearly } from './growthYearly/PanelGrowthYearly'

const MAP_KEY = {
  CPI_INFLATION: 'CPI & INFLATION',
  GROWTH_SECTOR: 'GROWTH SECTOR',
  GROWTH_YEARLY: 'GROWTH YEARLY',
  CONTRIBUTION: 'CONTRIBUTION',
}

const MIN_SIZE = {
  [`${MAP_KEY.CPI_INFLATION}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6.2) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.GROWTH_SECTOR}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.8) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.GROWTH_YEARLY}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.CONTRIBUTION}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.CPI_INFLATION}`]: {
    width: `calc(62% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.CPI_INFLATION}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.CPI_INFLATION}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.GROWTH_SECTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.GROWTH_SECTOR,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.GROWTH_YEARLY,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.CONTRIBUTION,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.GROWTH_SECTOR}`]: {
    width: `calc(38% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(62% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.GROWTH_SECTOR}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.GROWTH_SECTOR}`].minHeight,
    minTop: 0,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6.2) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.GROWTH_YEARLY}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - 2*${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.GROWTH_YEARLY}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.GROWTH_YEARLY}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.CONTRIBUTION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CONTRIBUTION}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - 2*${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(50% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.CONTRIBUTION}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.CONTRIBUTION}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.5 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelCpiAndInflationTable
          panelRefs={panelRefs}
          panelKey={MAP_KEY.CPI_INFLATION}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelGrowthSector
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GROWTH_SECTOR}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelGrowthYearly
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GROWTH_YEARLY}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelContribution
          panelRefs={panelRefs}
          panelKey={MAP_KEY.CONTRIBUTION}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
