import { I18n } from 'react-redux-i18n'

export const isTaxCode = (str) => {
  return /^\d+$/.test(str)
}

export const removeSecuritiesFromStr = (str) => {
  const securitiesStr = I18n.t('market.marginLoans.SECURITIES')

  return str?.length && str?.replace(securitiesStr, '')
}
