import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class HeatmapProxy extends ProxyBase {
  getHeatMapChart(params) {
    return this.get('GetHeatMapChart', params, null, true)
  }
}

export default new HeatmapProxy(
  ServiceProxyConfig.Market.MarketInDepth.ServiceUrl,
)
