import { createAsyncThunk } from '@reduxjs/toolkit'
import SteelService from '../../../../../../../core/services/sector/sectorSpecific/SteelService'

export const getSteelPipeCompanyGrowth = createAsyncThunk(
  'sector/sectorSpecific/steel/steelPipe/companyGrowth',
  async (params, { rejectWithValue }) => {
    const response = await SteelService.getSteelPipeCompanyGrowth(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
