import { maxBy } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { v4 as uuidv4 } from 'uuid'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../common/ScrollComponent'
import Dropdown from '../../../common/dropdown'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { TABLE_FIELD_CODES } from '../Screening/constants'
import {
  selectAddInformatics,
  selectFilterParams,
  selectWatchlist,
  setFilterParameters,
} from '../store/slice'
import style from './index.module.css'

export const FilterWatchlist = ({ width }) => {
  const dispatch = useDispatch()

  const dropdownRef = useRef()

  const { watchListId, addedInformations } = useSelector(selectFilterParams)
  const locale = useSelector((state) => state.i18n.locale)
  const initialWatchlist = useSelector(selectWatchlist)
  const addInformatics = useSelector(selectAddInformatics)

  const [watchlistIds, setWatchlistIds] = useState([])

  const [keySearch, setKeySearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
    setKeySearch('')
  }

  const handleClickItem = (id) => {
    const newWatchlistIds = !watchListId.includes(id)
      ? [...watchListId, id]
      : watchListId.filter((item) => item !== id)

    dispatch(
      setFilterParameters({
        key: 'watchListId',
        value: newWatchlistIds,
      }),
    )

    const information = addInformatics.find(
      (item) =>
        item.tableFieldCode === TABLE_FIELD_CODES.ISSUERS ||
        item.tableFieldCode === TABLE_FIELD_CODES.EN_ISSUERS,
    )
    const indexInformation = addedInformations.findIndex(
      (item) =>
        item.tableFieldCode === TABLE_FIELD_CODES.ISSUERS ||
        item.tableFieldCode === TABLE_FIELD_CODES.EN_ISSUERS,
    )

    if (!information) return

    const { indicatorId, tableCode, tableFieldCode } = information

    if (
      !newWatchlistIds.length &&
      indexInformation !== -1 &&
      !information?.isDefault
    ) {
      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: addedInformations.filter(
            (item) =>
              item.tableFieldCode !== TABLE_FIELD_CODES.ISSUERS &&
              item.tableFieldCode !== TABLE_FIELD_CODES.EN_ISSUERS,
          ),
        }),
      )
      return
    }

    if (!!newWatchlistIds.length && indexInformation === -1) {
      const max = maxBy(addedInformations, (e) => e.order)

      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: [
            ...addedInformations,
            {
              id: uuidv4(),
              order: (max?.order ?? 0) + 1,
              indicatorId,
              tableCode,
              tableFieldCode,
            },
          ],
        }),
      )
    }
  }

  useEffect(() => {
    if (isShowDropdown) {
      if (!keySearch.length) {
        setWatchlistIds(initialWatchlist)
        return
      }

      const newWatchListIds = initialWatchlist.filter((item) =>
        item?.watchListName?.toLowerCase()?.includes(keySearch?.toLowerCase()),
      )

      setWatchlistIds(newWatchListIds)
    }
  }, [keySearch.length, initialWatchlist, isShowDropdown])

  const placeholderInput = useMemo(
    () => I18n.t('bond.bondScreening.bondList.WATCHLIST'),
    [locale],
  )

  return (
    <div
      ref={dropdownRef}
      className={`form-search-inbox ${style.inputBondSearch}`}
      style={{ width }}
    >
      <Input
        style={{
          fontSize: 12,
          backgroundColor: '#50545f ',
          padding: '0 20px 0px 8px',
        }}
        type="text"
        className="search-inbox"
        placeholder={placeholderInput}
        onChange={(e) => setKeySearch(e.target.value)}
        onFocus={handleShow}
        value={keySearch}
      />
      <button type="button" style={{ top: 2 }}>
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <Dropdown isShow={isShowDropdown} parentRef={dropdownRef}>
        <HandleClickOutside
          handleClickOutside={handleHide}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width }}
            >
              {!!watchlistIds?.length ? (
                <ScrollComponent autoHeight={true} autoHeightMax={200}>
                  <ul className="list-check">
                    {watchlistIds.map((item, index) => {
                      return (
                        <li key={`key-${index}`}>
                          <a
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <label className="d-flex ali-center">
                              <input
                                type="checkbox"
                                className="checkbox mr-8"
                                checked={
                                  watchListId?.includes(item?.watchListId) ??
                                  false
                                }
                                onChange={() =>
                                  handleClickItem(item?.watchListId)
                                }
                              />
                              <TextEllipsis
                                text={`${item?.watchListName ?? ''}`}
                                zIndexTooltip={999}
                                isI18n={false}
                              />
                            </label>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </ScrollComponent>
              ) : (
                <div className={style.noData}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="common.NO_DATA" />
                  </Span>
                </div>
              )}
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}
