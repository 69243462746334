import PropTypes from 'prop-types'
import { Separator } from '../../../../../common/separator'
import { BusinessActivities } from './BusinessActivities'
import { Earning } from './Earning'
import { Transactions } from './Transactions'

const RESPONSIVE_MIN_WIDTH = 1000

export const Grid = ({ width, height }) => {
  return (
    <>
      {width > RESPONSIVE_MIN_WIDTH && (
        <div className="d-flex" style={{ width, height }}>
          <Earning marginRight={10} />
          <Separator />
          <Transactions marginLeft={5} marginRight={5} offsetWidth={2} />
          <Separator />
          <BusinessActivities marginLeft={10} />
        </div>
      )}
      {width <= RESPONSIVE_MIN_WIDTH && (
        <>
          <div
            className="d-flex"
            style={{
              width,
              height: `calc((2 * ${height}) / 3 - 10px)`,
              marginBottom: 10,
            }}
          >
            <Earning numColumn={2} marginRight={10} />
            <Transactions numColumn={2} marginLeft={10} />
          </div>
          <Separator isVertical={false} />
          <div
            className="d-flex"
            style={{
              width,
              height: `calc(${height} / 3 - 10px)`,
              marginTop: 10,
            }}
          >
            <BusinessActivities
              numColumn={1}
              childNumColumn={2}
              childMargin={10}
            />
          </div>
        </>
      )}
    </>
  )
}

Grid.propTypes = {
  width: PropTypes.number.isRequired,
}
