import { useEffect, useState } from 'react'

export const useResize = (myRef) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const handleResize = () => {
    const timeout = setTimeout(() => {
      setWidth(myRef.current?.offsetWidth)
      setHeight(myRef.current?.offsetHeight)
      clearTimeout(timeout)
    }, 100)
  }

  useEffect(() => {
    handleResize()
  }, [])

  useEffect(() => {
    window.addEventListener('load', handleResize)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('load', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef.current?.offsetHeight, myRef.current?.offsetWidth])

  return { width, height }
}
