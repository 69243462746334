import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import { YEAR_OPTIONS } from '../constants'
import style from '../index.module.css'
import { handleChangeYear, selectYear } from '../store/slice'

const SelectYearCustom = () => {
  const dispatch = useDispatch()

  const year = useSelector(selectYear)

  const changeYear = (year) => {
    dispatch(handleChangeYear(year))
  }

  return (
    <div className="d-flex justify-content-end">
      <div className={style.selectYearCustom}>
        <SelectCustom
          isI18n={true}
          selectData={YEAR_OPTIONS}
          value={year}
          handleChange={changeYear}
          appendStyle={{ fontWeight: 'normal' }}
        />
      </div>
    </div>
  )
}

export default SelectYearCustom
