import { positionXAxis, positionYAxis } from '../../../constants'

export const listPosition = [
  {
    name: 'financialChart.LEFT',
    value: positionXAxis.LEFT,
    showOption: true,
  },
  {
    name: 'financialChart.RIGHT',
    value: positionXAxis.RIGHT,
    showOption: true,
  },
  {
    name: 'financialChart.CUSTOM',
    value: positionXAxis.CUSTOM,
    showOption: false,
  },
]

export const listPositionLabel = [
  {
    name: 'financialChart.NEXT_TO_Y_AXIS',
    value: positionYAxis.NEXT_TO_Y_AXIS,
  },
  {
    name: 'financialChart.ABOVE_Y_AXIS',
    value: positionYAxis.ABOVE_Y_AXIS,
  },
]
