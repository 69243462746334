import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { formatVal } from '../../../../../utils/Value'
import { STACK_COLORS, STACK_VALUE_KEYS } from '../../constants'
import style from '../../index.module.css'
import {
  handleStackChartKey,
  selectCurrentStackKey,
  selectDataMapping,
  selectStackChartKey,
} from '../../panelTable/store/slice'
import {
  findFooterStackChart,
  formatDataStacked,
  getTooltipContentData,
  renderListFooter,
} from './helper'

const SETTINGS = {
  yTickNum: 5,
}

const ChartComponent = ({ data, width, height, keyXAxis, activeTab }) => {
  const dispatch = useDispatch()
  const isValue = activeTab === 'value'
  const formatData = formatDataStacked(data, isValue)
  const { VALUE, PERCENT_VALUE } = STACK_VALUE_KEYS

  const dataMapping = useSelector(selectDataMapping)
  const currentStackKey = useSelector(selectCurrentStackKey)
  const stackChartKey = useSelector(selectStackChartKey)

  useEffect(() => {
    if (currentStackKey && dataMapping.length) {
      dispatch(
        handleStackChartKey(findFooterStackChart(currentStackKey, dataMapping)),
      )
    }
  }, [currentStackKey, dataMapping])

  const renderTooltip = (payload) => {
    return (
      <>
        {stackChartKey.length > 0 && (
          <>
            <div style={{ fontStyle: 'italic' }}>
              <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
            </div>
            {getTooltipContentData(payload, stackChartKey, activeTab).map(
              (item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: 10,
                    }}
                  >
                    <Span className={style.tooltipContentKey}>
                      {item.label ? <Translate value={item.label} /> : ''}:
                    </Span>
                    <Span
                      style={{ marginLeft: 25 }}
                      className={style.toolTipContentValue}
                    >
                      {formatVal(item.value)}
                    </Span>
                  </div>
                )
              },
            )}
          </>
        )}
      </>
    )
  }

  return (
    <SizeTracker className={style.heightAuto}>
      {(size) => {
        return (
          <>
            {size.height && (
              <ChartContainer
                data={formatData}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                isUseXAxisDiv
                showAllTick
                margin={{
                  ...MARGIN_RECHARTS,
                  left: !isValue ? 12 : 0,
                }}
                yAxis={[
                  {
                    id: 'left',
                    keys: isValue ? VALUE : PERCENT_VALUE,
                    orientation: 'left',
                    isStackedBar: true,
                    label: isValue
                      ? I18n.t(
                          'corporate.businessModel.segmentalAnalysis.BILLION_VND',
                        )
                      : '',
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                    tickNum: SETTINGS.yTickNum,
                    unitYAxis: !isValue ? '%' : '',
                  },
                ]}
                renderCustomTooltip={renderTooltip}
              >
                {stackChartKey.length > 0 &&
                  stackChartKey.map((item, index) => {
                    return (
                      <Bar
                        key={index}
                        barSize={getColumnSizeInBarChart(
                          width,
                          formatData.length,
                        )}
                        stackId="a"
                        dataKey={
                          isValue ? `value${index + 1}` : `value${index + 1}_P`
                        }
                        fill={STACK_COLORS[stackChartKey.length][index]}
                        yAxisId="left"
                        isAnimationActive={false}
                      />
                    )
                  })}
              </ChartContainer>
            )}
            <Footer key={width} list={renderListFooter(stackChartKey)} />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
