import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../../common/chart/constants'
import { Footer } from '../../../../../../common/chart/footer'
import { TYPE } from '../../../../../../common/chart/footer/Item'
import { getColumnSizeInBarChart } from '../../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../../common/html/Span'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import { formatVal } from '../../../../../../utils/Value'
import { handleListCheckboxLoan, selectListCheckboxLoan } from '../store/slice'

const SETTINGS = {
  yTickNum: 5,
}

const BAR_COLORS = ['#185999', '#d2e5ff']
const YAXIS_LEFT = 'left'
const YAXIS_RIGHT = 'right'
const MARGIN = { ...MARGIN_RECHARTS, right: 16 }

const ChartComponent = ({ data, width, height, keyXAxis, barKeys }) => {
  const dispatch = useDispatch()
  const listCheckbox = useSelector(selectListCheckboxLoan)

  const setListCheckbox = (newListCheckbox) => {
    dispatch(handleListCheckboxLoan(newListCheckbox))
  }

  const renderTooltip = (tooltipData, decimalLengths) => {
    const contentTooltip = [
      {
        label: I18n.t(
          'corporate.businessModel.penetrationAnalysis.LOAN_TO_CUSTOMER',
        ),
        value: formatVal(
          tooltipData[barKeys[0]],
          decimalLengths[YAXIS_LEFT] || 2,
        ),
      },
      {
        label: I18n.t(
          'corporate.businessModel.penetrationAnalysis.LOAN_NON_PERFORMING',
        ),
        value: formatVal(
          tooltipData[barKeys[1]],
          decimalLengths[YAXIS_RIGHT] || 2,
        ),
      },
    ]
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData[keyXAxis]}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {el.value}
                &nbsp;
                <Translate
                  value={
                    'corporate.businessModel.penetrationAnalysis.BILLION_TOOLTIP'
                  }
                />
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  const getYAxis = () => {
    const result = []

    const yAxisLeft = {
      id: YAXIS_LEFT,
      keys: [barKeys[0]],
      orientation: 'left',
      isBarChart: true,
      label: I18n.t(
        'corporate.businessModel.penetrationAnalysis.LOAN_TO_CUSTOMER_LABEL',
      ),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: SETTINGS.yTickNum,
    }
    const yAxisRight = {
      id: YAXIS_RIGHT,
      keys: [barKeys[1]],
      orientation: 'right',
      isBarChart: true,
      label: I18n.t(
        'corporate.businessModel.penetrationAnalysis.LOAN_NON_PERFORMING_LABEL',
      ),
      labelPosition: AXIS_LABEL_POSITION.RIGHT,
      tickNum: SETTINGS.yTickNum,
    }

    if (listCheckbox.includes(barKeys[0])) {
      result.push(yAxisLeft)
    }

    if (listCheckbox.includes(barKeys[1])) {
      result.push(yAxisRight)
    }

    if (result.length === 0) {
      return [yAxisLeft, yAxisRight]
    }

    return result
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {size.height && (
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                tickFormatter={(value) => value}
                isUseXAxisDiv
                showAllTick
                yAxis={getYAxis()}
                margin={MARGIN}
                renderCustomTooltip={renderTooltip}
              >
                {({ chartContentWidth }) => (
                  <>
                    {listCheckbox.includes(barKeys[0]) ? (
                      <Bar
                        isAnimationActive={false}
                        yAxisId={YAXIS_LEFT}
                        dataKey={barKeys[0]}
                        barSize={getColumnSizeInBarChart(
                          chartContentWidth,
                          data.length,
                        )}
                        fill={BAR_COLORS[0]}
                      />
                    ) : (
                      ''
                    )}
                    {listCheckbox.includes(barKeys[1]) ? (
                      <Bar
                        isAnimationActive={false}
                        yAxisId={YAXIS_RIGHT}
                        dataKey={barKeys[1]}
                        barSize={getColumnSizeInBarChart(
                          chartContentWidth,
                          data.length,
                        )}
                        fill={BAR_COLORS[1]}
                      />
                    ) : (
                      ''
                    )}
                  </>
                )}
              </ChartContainer>
            )}
            <Footer
              key={width}
              list={[
                {
                  text: 'corporate.businessModel.penetrationAnalysis.LOAN_TO_CUSTOMER',
                  dataKey: barKeys[0],
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLORS[0],
                  },
                },
                {
                  text: 'corporate.businessModel.penetrationAnalysis.LOAN_NON_PERFORMING',
                  dataKey: barKeys[1],
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLORS[1],
                  },
                },
              ]}
              listCheckbox={listCheckbox}
              setListCheckbox={setListCheckbox}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
