import {
  QUARTERLY,
  QUARTERLY_TIME_FRAME_OPTIONS,
  YEAR_TIME_FRAME_OPTIONS,
} from './constants'

export const filterLineChartData = (
  data,
  timeRangeYearly,
  timeRangeQuarterly,
  yearlyQuarterlyOption,
  isPercentFormat,
) => {
  const isAccQuarterly = yearlyQuarterlyOption === 'AccQuarterly'
  if (yearlyQuarterlyOption === 'Quarterly' || isAccQuarterly) {
    return filterQuarterData(
      data,
      QUARTERLY_TIME_FRAME_OPTIONS,
      timeRangeQuarterly,
      isAccQuarterly,
      isPercentFormat,
    )
  }

  return filterYearData(
    data,
    YEAR_TIME_FRAME_OPTIONS,
    timeRangeYearly,
    isPercentFormat,
  )
}

const getAllYear = (rangeYear, data) => {
  const allYear = data.map((item) => item.year)
  const maxYear = Math.max(...allYear)
  if (rangeYear === 0) {
    return [...new Set(allYear)]
  }

  const years = []
  let count = rangeYear
  do {
    years.push(maxYear - count)
    count--
  } while (count >= 1)
  return [...years, maxYear]
}

const getRangeValue = (timeFrameOptions, strValue) => {
  return timeFrameOptions.find((item) => item.value === strValue).num
}

export const filterYearData = (
  data,
  timeFrameOptions,
  strValue,
  isPercentFormat,
) => {
  const rangeYear = getRangeValue(timeFrameOptions, strValue)
  const years = getAllYear(rangeYear, data)
  const dataFilter = []
  years.forEach((year) => {
    const dataByYear = data
      .filter((item) => item.year === year)
      .reduce((prev, cur) => {
        return {
          ...prev,
          year: cur?.year,
          [cur.nationalAccountVNTypeId]: isPercentFormat
            ? cur.value * 100
            : cur.value / 1000000000000,
        }
      }, {})
    dataFilter.push(dataByYear)
  })
  return dataFilter
}

const filterQuarterData = (
  data,
  timeFrameOptions,
  strValue,
  isAccQuarterly,
  isPercentFormat,
) => {
  const rangeYear = getRangeValue(timeFrameOptions, strValue)
  const years = getAllYear(rangeYear, data)

  let dataFilter = []
  years.forEach((year) => {
    const dataByYear = data.filter((item) => item.year === year)
    let dataByQuarter = []
    QUARTERLY.forEach((quarter) => {
      const dataByYearQuarter = dataByYear.filter(
        (item) => item.quarter === quarter,
      )
      if (dataByYearQuarter.length) {
        const objYearQuarter = dataByYearQuarter.reduce((prev, cur) => {
          const quarterName =
            !isAccQuarterly || cur.quarter === 1
              ? `Q${cur.quarter}`
              : `${cur.quarter}Q`
          return {
            ...prev,
            yearQuarter: `${quarterName}-${cur?.year}`,
            [cur.nationalAccountVNTypeId]: isPercentFormat
              ? cur.value * 100
              : cur.value / 1000000000000,
          }
        }, {})
        dataByQuarter = [...dataByQuarter, objYearQuarter]
      }
    })

    dataFilter = [...dataFilter, ...dataByQuarter]
  })

  return dataFilter
}

export const getTooltipContentData = (ids, dataTypeName) => {
  return ids.map((id) => {
    const currentItem = dataTypeName.find(
      (item) => item.nationalAccountVNTypeId === id,
    )
    return {
      id: currentItem.nationalAccountVNTypeId,
      title: currentItem.nationalAccountVNTypeName,
    }
  })
}

export const handleTooltipTwoColumn = (ids, dataTypeName) => {
  const MAX_ITEM_OF_COLUMN = 8
  const tooltipData = getTooltipContentData(ids, dataTypeName)
  if (tooltipData.length < MAX_ITEM_OF_COLUMN) {
    return { leftColumn: tooltipData, rightColumn: [] }
  }

  const leftColumnLength = Math.ceil(tooltipData.length / 2)
  return {
    leftColumn: tooltipData.concat().splice(0, leftColumnLength),
    rightColumn: tooltipData.concat().splice(leftColumnLength),
  }
}

export const getIndicatorName = (data, id) => {
  return data.find((item) => item.nationalAccountVNTypeId === id)
    ?.nationalAccountVNTypeName
}
