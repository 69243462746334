export const MAP_TIME_FILTER = {
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
}

export const YEAR_OPTIONS = () => {
  const MIN_YEAR_OPTION = 2000
  const year = new Date().getFullYear()
  const range = year - MIN_YEAR_OPTION + 1
  return [...Array(range).keys()].map((item) => {
    return {
      name: String(year - item),
      value: String(year - item),
    }
  })
}

export const yearOption = (minYear, maxYear) => {
  const currentYear = new Date().getFullYear()
  minYear = minYear || 2000
  maxYear = maxYear || currentYear
  const years = []
  for (let year = minYear; year <= maxYear; year++) {
    years.push({ name: year.toString(), value: year })
  }

  return years
}

export const MONTH_OPTIONS = [
  { name: 'economy.monetary.monetary.TH1', value: 1 },
  { name: 'economy.monetary.monetary.TH2', value: 2 },
  { name: 'economy.monetary.monetary.TH3', value: 3 },
  { name: 'economy.monetary.monetary.TH4', value: 4 },
  { name: 'economy.monetary.monetary.TH5', value: 5 },
  { name: 'economy.monetary.monetary.TH6', value: 6 },
  { name: 'economy.monetary.monetary.TH7', value: 7 },
  { name: 'economy.monetary.monetary.TH8', value: 8 },
  { name: 'economy.monetary.monetary.TH9', value: 9 },
  { name: 'economy.monetary.monetary.TH10', value: 10 },
  { name: 'economy.monetary.monetary.TH11', value: 11 },
  { name: 'economy.monetary.monetary.TH12', value: 12 },
]

export const QUARTER_OPTIONS = [
  { value: 1, name: 'Q1' },
  { value: 2, name: 'Q2' },
  { value: 3, name: 'Q3' },
  { value: 4, name: 'Q4' },
]

export const VALUE_TIME = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
}

export const MONTH_QUARTER_YEAR_RADIO = [
  {
    label: 'bond.overview.primaryMarket.MONTHLY',
    value: VALUE_TIME.MONTHLY,
  },
  {
    label: 'bond.overview.primaryMarket.QUARTERLY',
    value: VALUE_TIME.QUARTERLY,
  },
  {
    label: 'bond.overview.primaryMarket.YEARLY',
    value: VALUE_TIME.YEARLY,
  },
]

export const VALUE_release_methot = {
  ALL: 'All',
  PRIVATE_PLACEMENT: 'PrivatePlacement',
  PUBLIC_OFFERING: 'PublicOffering',
}

export const OPTION_RELEASR_METHOD = [
  {
    label: 'bond.overview.optionMethod.all',
    value: VALUE_release_methot.ALL,
  },
  {
    label: 'bond.overview.optionMethod.private',
    value: VALUE_release_methot.PUBLIC_OFFERING,
  },
  {
    label: 'bond.overview.optionMethod.PublicL',
    value: VALUE_release_methot.PRIVATE_PLACEMENT,
  },
]
