import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import {
  selectIdsCompany,
  selectListIdSave,
} from '../../corporateInfo/store/slice'
import { listPosition } from './constants'
import { selectPosition, updatePosition } from './store/slice'

const Position = ({ openPopup }) => {
  const dispatch = useDispatch()
  const listCheck = useSelector(selectPosition)
  const idCompany = useSelector(selectIdsCompany)
  const idCompnaySave = useSelector(selectListIdSave)

  const handleClickItem = (value) => {
    if (idCompany.length !== idCompnaySave.length) {
      openPopup({ value: true, cb: () => changeCheckbox(value) })
    } else {
      changeCheckbox(value)
    }
  }

  const changeCheckbox = (value) => {
    const index = listCheck.indexOf(value)
    if (index === -1) {
      const data = [...listCheck, value]
      dispatch(updatePosition(data))
    } else {
      const data = [...listCheck].filter((e) => e !== value)
      dispatch(updatePosition(data))
    }
  }
  return (
    <>
      <div className="mb-8">
        <Span style={{ fontSize: 12, fontWeight: 340, color: '#85939C' }}>
          <Translate value="bond.interconnectionMap.filter.Position.tilte" />
        </Span>
      </div>
      <div className="mb-8">
        <ul className="list-check">
          {listPosition.map((e, index) => (
            <li key={`key-${index}`}>
              <a
                style={{
                  cursor: 'pointer',
                }}
              >
                <label className="d-flex ali-center">
                  <input
                    type="checkbox"
                    className="checkbox mr-8"
                    checked={listCheck.includes(e.values)}
                    onChange={() => handleClickItem(e.values)}
                  />
                  <Span style={{ fontSize: 12, fontWeight: 340 }}>
                    <TextEllipsis
                      text={`${e?.label ?? ''}`}
                      zIndexTooltip={999}
                    />
                  </Span>
                </label>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default Position
