import FundCenterProxy from '../../proxies/fund/FundCenterProxy'
import { ServiceBase } from '../ServiceBase'

class FundCenterService extends ServiceBase {
  getTopNetAssetValue(params) {
    return this.applyMemoryCache(
      'FundCenterService.getTopNetAssetValue',
      params,
    )(() => FundCenterProxy.getTopNetAssetValue(params))
  }

  getFundsOverview12Months(params) {
    return this.applyMemoryCache(
      'FundCenterService.getFundsOverview12Months',
      params,
    )(() => FundCenterProxy.getFundsOverview12Months(params))
  }

  getListNAVCertificate(params) {
    return this.applyMemoryCache(
      'FundCenterService.getListNAVCertificate',
      params,
    )(() => FundCenterProxy.getListNAVCertificate(params))
  }

  getListFundFlow(params) {
    return this.applyMemoryCache(
      'FundCenterService.getListFundFlow',
      params,
    )(() => FundCenterProxy.getListFundFlow(params))
  }
}

export default new FundCenterService()
