import { useSelector } from 'react-redux'
import { selectShareIssueMethod } from '../store/slice'

const IssueMethodName = ({ issueMethodId }) => {
  const issueMethod = useSelector(selectShareIssueMethod)
  return (
    <>
      {
        issueMethod.find((item) => item.shareIssueMethodId === issueMethodId)
          ?.shareIssueMethodName
      }
    </>
  )
}

export default IssueMethodName
