export const getMonthAndYearInDataRange = (dataRange) => {
  const allMonthsAndYears = dataRange?.map((item) => ({
    month: item.month,
    year: item.year,
  }))

  const uniqueMonthsAndYears = Object.values(
    allMonthsAndYears.reduce((acc, curr) => {
      acc[curr.month + '|' + curr.year] = curr
      return acc
    }, {}),
  )

  return uniqueMonthsAndYears
}

export const getMonthInDataRange = (data) => {
  const allMonths = data?.map((item) => item.month)
  const uniqueMonths = [...new Set(allMonths)]

  return uniqueMonths.map((item) => ({ month: item }))
}

export const getYearInDataRange = (data) => {
  const allYears = data?.map((item) => item.year)
  const uniqueYears = [...new Set(allYears)]

  return uniqueYears.map((item) => ({ year: item }))
}

export const getDataByMonthAndYear = (data, month, year) => {
  return data
    ?.filter((item) => item.month === month && item.year === year)
    ?.map((item) => ({
      value: item.value ?? 0,
      cpivnTypeId: item.cpivnTypeId ?? 0,
    }))
}

export const getDataByMonth = (data, month) => {
  return data
    ?.filter((item) => item.month === month)
    ?.map((item) => ({
      value: item.value ?? 0,
      year: item.year ?? 0,
    }))
}

export const getMonthAndYearKey = (month, year) => {
  return month < 10 ? `0${month}-${year}` : `${month}-${year}`
}

export const getMonthKey = (month) => {
  return month < 10 ? `0${month}` : `${month}`
}

export const getYearKey = (year) => {
  return `${year}`
}
