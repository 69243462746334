import React from 'react'
import { uuid } from '../../../../utils/Common'
import { ID_CHART_DUMMY } from '../../../common/helpers/chartSchemaByTimeHelpers'
import style from './style.module.css'

const YAxisSettingStyle = (props) => {
  const { yAxisMap, handleClick } = props
  return (
    <React.Fragment key={uuid()}>
      {Object.values(yAxisMap).map(
        ({ x, y, width, height, labelText, yAxisId, orientation }, index) =>
          yAxisId !== ID_CHART_DUMMY.DUMMY_RIGHT_ID &&
          yAxisId !== ID_CHART_DUMMY.DUMMY_LEFT_ID ? (
            <g key={index}>
              <foreignObject x={x} y={y} height={height} width={width}>
                <div
                  className={`h-100 w-100 cursor-pointer ${style.axis}`}
                  style={{
                    position: 'relative',
                    boxSizing: 'border-box',
                  }}
                  onClick={() =>
                    handleClick({ labelText, yAxisId, orientation })
                  }
                />
              </foreignObject>
            </g>
          ) : null,
      )}
    </React.Fragment>
  )
}

YAxisSettingStyle.propTypes = {}

export default YAxisSettingStyle
