import { useSelector } from 'react-redux'
import { ConsensusHistory } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'

export const PanelConsensusHistory = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
  width,
  height,
}) => {
  const basicInfo = useSelector(selectBasicInfo)
  const chartName = UseI18n(
    'corporate.peerValuation.consensus.CONSENSUS_HISTORY',
  )
  const nameScreen = UseI18n('corporate.peerValuation.consensus.NAME')

  return (
    <Panel
      title="corporate.peerValuation.consensus.CONSENSUS_HISTORY"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={UseI18n('corporate.peerValuation.consensus.CONSENSUS_HISTORY')}
      downloadJpgParams={{
        domId: 'consensusHistoryChart',
        nameScreen,
        chartName,
        tabName: '',
        tickerName: basicInfo.ticker,
      }}
    >
      {/* 10 is padding top + bottom of headerClass */}
      <ConsensusHistory width={width} height={height - 10} />
    </Panel>
  )
}
