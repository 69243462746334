import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Switch } from '../../../../common/switch'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import { MAX_TEMPLATE } from '../constant'
import style from '../index.module.css'
import {
  changeIsShowTicker,
  selectIsShowTicker,
  selectTemplates,
} from '../store/slice'
import PopupSaveTemplate from './PopupSaveTemplate'

const ButtonSave = () => {
  const dispatch = useDispatch()

  const templates = useSelector(selectTemplates)
  const isShowTicker = useSelector(selectIsShowTicker)

  const [isShowPopupSaveAs, setIsShowPopupSaveAs] = useState(false)

  const handleHideSaveAs = () => setIsShowPopupSaveAs(false)

  const handleShowSaveAs = () => {
    if (templates.length >= MAX_TEMPLATE) {
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <Translate value="sector.sectorConstituents.segmentation.MAX_TEMPLATE_1" />,
        <Translate value="sector.sectorConstituents.segmentation.MAX_TEMPLATE_2" />,
      ])
    } else {
      setIsShowPopupSaveAs(true)
    }
  }

  const handleShowTicker = (isCheck) => {
    dispatch(changeIsShowTicker(isCheck))
  }

  return (
    <>
      <div className={`d-flex j-b ${style.pb16}`}>
        <Switch
          isI18n={true}
          title={'sector.sectorConstituents.segmentation.SHOW_TICKER'}
          style={{ opacity: 0.5 }}
          checked={isShowTicker}
          onChange={handleShowTicker}
        />
        <Button
          onClick={handleShowSaveAs}
          style={{ fontSize: 11 }}
          className="btn btn-blue w-80 h-20"
        >
          <Translate value="common.button.BUTTON_SAVE" />
        </Button>
      </div>
      {isShowPopupSaveAs && (
        <PopupSaveTemplate handleHideSaveAs={handleHideSaveAs} />
      )}
    </>
  )
}

export default ButtonSave
