import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SecondaryMarketStatisticsService from '../../../../../../core/services/bond/corporateBond/secondaryMarket/SecondaryMarketStatisticsService'
import { handleExport } from '../../../../../utils/Export'

export const getSectorStatistics = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_SECTOR_STATISTICS',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SecondaryMarketStatisticsService.getSectorStatistics(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getCorrelationOfMarket = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_CORRELATION_OF_MARKET',
  async (params, { rejectWithValue }) => {
    try {
      const response = params.isSector
        ? await SecondaryMarketStatisticsService.getCorrelationOfMarketBySector(
            params,
          )
        : await SecondaryMarketStatisticsService.getCorrelationOfMarketByOrganization(
            params,
          )

      if (response.success) {
        return { data: response.data, timeType: params.TimeRange }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getMarketTradingValueData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_MARKET_TRADING_VALUE_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = params.isTradingMethod
        ? await SecondaryMarketStatisticsService.getMarketTradingValueByTradingMethod(
            params,
          )
        : await SecondaryMarketStatisticsService.getMarketTradingValueByIssueMethod(
            params,
          )

      if (response.success) {
        return {
          data: response.data,
          timeType: params.TimeRange,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getMarketTradingValueBySectorData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_MARKET_TRADING_VALUE_BY_SECTOR',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SecondaryMarketStatisticsService.getMarketTradingValueBySector(
          params,
        )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const downloadIssuanceValueData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/DOWNLOAD_SECTOR_STATISTICS_DATA',
  async (data) => {
    const response =
      await SecondaryMarketStatisticsService.downloadIssuanceValue(data)
    handleExport(response.data, response.filename)
  },
)
