import { titleData } from '../../common/helper'

export const convertData = (arr, timeFilter) => {
  let objList = {}
  const result = []
  arr.forEach(
    (item) =>
      (objList[titleData(item.month, item.year, timeFilter)] = {
        ...(objList[titleData(item.month, item.year, timeFilter)] || {}),
        [`fdiTypeId_${item.fdiTypeId}`]: item.value,
      }),
  )
  Object.keys(objList).map((key) =>
    result.push({
      year: key,
      ...objList[key],
    }),
  )
  return result
}
