import { forwardRef, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import Popup from '../../../common/popup'
import { lengthChartInLayout } from '../../chart/common/useGetLayoutDashboard'
import { postCharts } from '../../chart/store/thunk'
import {
  selectDashboardSelectedDetailPopup,
  selectDashboardSelectedPopup,
} from '../../common/dropdownMoreDashboard/store/slice'
import { dashboardTypes, screenTypes } from '../../constants'
import { changeDashboard, selectChartByDashboardId } from '../../store/slice'
import { getDashboard, postDashboard } from '../../store/thunk'
import { getPopupSaveDashboardSuccess } from '../helper'

export const PopupGroupButtonActionSave = forwardRef(
  (
    {
      isAddNewDashboard,
      newDashboardName,
      chartTitle,
      chartTabActive,
      chartTabs,
      handleShow,
      isSaveAsChart,
    },
    ref,
  ) => {
    const dispatch = useDispatch()

    const dashboardSelected = useSelector(selectDashboardSelectedPopup)
    const chartByDashboardId = useSelector(selectChartByDashboardId)
    const dashboardSelectedDetail = useSelector(
      selectDashboardSelectedDetailPopup,
    )

    const [isSaveLoading, setIsSaveLoading] = useState(false)

    useImperativeHandle(ref, () => ({
      async handleSave() {
        setIsSaveLoading(true)

        try {
          if (isAddNewDashboard) {
            if (isSaveAsChart) {
              await handleSaveAsNewDashboard()
            } else {
              await handleSaveNewDashboard()
              await dispatch(getDashboard())
            }
          } else {
            await handleSaveExistDashboard()
          }

          handleShow(false)
        } catch (error) {}

        setIsSaveLoading(false)
      },
    }))

    const handleSaveAsNewDashboard = async () => {
      if (!newDashboardName.trim()) return

      await dispatch(
        postDashboard({
          name: newDashboardName,
          isGroup: false,
          layout: 'layout_1',
          maxChartCount: lengthChartInLayout.layout_1,
          cancelBackToDB: true,
        }),
      )
        .unwrap()
        .then(async (data) => {
          const params = {
            dashboardId: data.id,
            name: chartTitle,
            linked: false,
            detail: JSON.stringify(chartTabs[chartTabActive]),
          }
          await dispatch(postCharts(params))
        })

      dispatch(
        changeDashboard({
          screenType: screenTypes.DASHBOARD,
          dashboardType: dashboardTypes.MY_DASHBOARD,
        }),
      )
    }

    const handleSaveNewDashboard = async () => {
      if (!newDashboardName.trim()) return

      await dispatch(
        postDashboard({
          name: newDashboardName,
          isGroup: false,
          layout: 'layout_1',
          maxChartCount: lengthChartInLayout.layout_1,
          cancelBackToDB: true,
        }),
      )
        .unwrap()
        .then(async (data) => {
          const tabs = Object.values(chartTabs)
          const params = tabs.map((item, i) => ({
            dashboardId: data.id,
            name: tabs[i].nameChart.name,
            order: i + 1,
            linked: false,
            detail: JSON.stringify(item),
          }))

          await dispatch(postCharts(params))
            .unwrap()
            .then(async () => {
              dispatch(
                changeDashboard({
                  screenType: screenTypes.DASHBOARD,
                  dashboardType: dashboardTypes.MY_DASHBOARD,
                  data: data,
                }),
              )
              getPopupSaveDashboardSuccess(newDashboardName)
            })
        })
    }

    const handleSaveExistDashboard = async () => {
      if (!chartTitle.trim() || !dashboardSelected) return

      let order = 1
      const data = chartByDashboardId[dashboardSelected]

      if (data && data?.length) {
        let lastOrder = 0
        data.forEach((item) => {
          if (item.order > lastOrder) {
            lastOrder = item.order
          }
        })
        order = lastOrder + 1
      } else {
        order = null
      }
      const params = {
        dashboardId: dashboardSelected,
        name: chartTitle.trim(),
        order,
        linked: false,
        detail: JSON.stringify({ ...chartTabs[chartTabActive], order: order }),
      }
      dispatch(postCharts(params))
        .unwrap()
        .then(async () => {
          getPopupSaveDashboardSuccess(dashboardSelectedDetail?.name)
        })
    }

    return isSaveLoading ? (
      <Popup>
        <Loading isBlack />
      </Popup>
    ) : (
      <></>
    )
  },
)
