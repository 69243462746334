import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGE } from '../socialInvestment/constants'
import { selectTimeRange } from '../socialInvestment/store/slice'
import ChartComponent from './ChartComponent'
import {
  QUARTER_SELECTION_MONTHLY,
  TYPE_CHART_SOCIAL_INVESTMENT,
} from './constants'
import Header from './Header'
import {
  getRadioValue,
  getStackChartData,
  getStackChartLoading,
  getTimeFrame,
  getTimeFrameYearly,
  getTypeChartValue,
  getYearDropdownValue,
  selectCurrentQuarter,
  selectIds,
  selectMaxDate,
} from './store/slice'
import {
  getMaxPeriod,
  getTotalInvesmentCapitalOfTheStateSectorChart,
  getTotalInvesmentCapitalUnderTheStateBudgetChart,
  getTotalSocialInvesmentCapitalChart,
} from './store/thunk'

const YEARLY_SELECTION_VAL = 4

const Container = ({ height, width }) => {
  const dispatch = useDispatch()

  const stackChartData = useSelector(getStackChartData)
  const stackChartLoading = useSelector(getStackChartLoading)
  const typeChartValue = useSelector(getTypeChartValue)
  const radioValue = useSelector(getRadioValue)
  const timeFrame = useSelector(getTimeFrame)
  const timeFrameYearly = useSelector(getTimeFrameYearly)
  const ids = useSelector(selectIds)
  const currentQuarter = useSelector(selectCurrentQuarter)
  const currentYear = useSelector(getYearDropdownValue)
  const currentTimeRange = useSelector(selectTimeRange)
  const maxDate = useSelector(selectMaxDate)
  const locale = useSelector((state) => state.i18n.locale)

  const currentSelectedYear = currentYear ?? maxDate?.year
  const currentSelectedQuarter = currentQuarter ?? maxDate?.quarter

  const isTotalSocialChart =
    typeChartValue === TYPE_CHART_SOCIAL_INVESTMENT.TOTAL_SOCIAL
  const isBudgetProvinceChart =
    typeChartValue === TYPE_CHART_SOCIAL_INVESTMENT.BUDGET_PROVINCES
  const isYearly = currentTimeRange === TIME_RANGE.YEARLY
  const isYearlyTabSelection =
    currentQuarter === QUARTER_SELECTION_MONTHLY[0].value

  useEffect(() => {
    dispatch(getMaxPeriod({ TimeFrequency: currentTimeRange }))
  }, [currentTimeRange])

  useEffect(() => {
    if (maxDate) {
      if (isTotalSocialChart) {
        dispatch(
          getTotalSocialInvesmentCapitalChart({
            TimeFrequency: currentTimeRange,
            TimeRange: isYearly ? timeFrameYearly : timeFrame,
          }),
        )
      } else if (isBudgetProvinceChart) {
        dispatch(
          getTotalInvesmentCapitalUnderTheStateBudgetChart({
            TimeFrequency: isYearlyTabSelection
              ? TIME_RANGE.ACC_QUARTER
              : currentTimeRange,
            Quarter: isYearlyTabSelection
              ? YEARLY_SELECTION_VAL
              : currentSelectedQuarter,
            Year: currentSelectedYear,
          }),
        )
      } else {
        dispatch(
          getTotalInvesmentCapitalOfTheStateSectorChart({
            TimeFrequency: isYearlyTabSelection
              ? TIME_RANGE.ACC_QUARTER
              : currentTimeRange,
            Quarter: isYearlyTabSelection
              ? YEARLY_SELECTION_VAL
              : currentSelectedQuarter,
            Year: currentSelectedYear,
          }),
        )
      }
    }
  }, [
    timeFrame,
    timeFrameYearly,
    currentQuarter,
    currentYear,
    typeChartValue,
    radioValue,
    currentTimeRange,
    locale,
    maxDate,
  ])

  const keyXAxis = isTotalSocialChart ? 'time' : 'name'

  return stackChartLoading ? (
    <Loading />
  ) : (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <Header isTotalSocialChart={isTotalSocialChart} width={width} />
            {typeof sizes.height === 'number' &&
              (stackChartData.length ? (
                <div id="INVESTMENT_STRUCTURE">
                  <ChartComponent
                    width={width}
                    height={height - sizes.height}
                    data={stackChartData}
                    keyXAxis={keyXAxis}
                    typeChartValue={typeChartValue}
                    radioValue={radioValue}
                    ids={ids}
                  />
                </div>
              ) : (
                <div style={{ height: height - sizes.height }}>
                  <NoData />
                </div>
              ))}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Container
