import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Loading } from '../../../../common/loading'
import Popup from '../../../../common/popup'
import { FIINX_EVENT_TYPE } from '../../constant'
import {
  changeEventDetail,
  changeEventDetailId,
  selectEventDetailId,
  selectEvents,
  selectFilter,
  selectLoadingEventDetail,
} from '../../store/slice'
import { CashDividendForm } from './CashDividendForm'
import { DirectorDealForm } from './DirectorDealForm'
import { ShareIssueForm } from './ShareIssueForm'
import { SummaryForm } from './SummaryForm'
import style from './index.module.css'

const PopupDetail = () => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const events = useSelector(selectEvents)
  const eventId = useSelector(selectEventDetailId)
  const data = events.find((el) => el.corporateEventId === eventId)
  const loading = useSelector(selectLoadingEventDetail)
  const filter = useSelector(selectFilter)
  const [isShow, setIsShow] = useState(false)

  const selectFormByEventType = (data) => {
    if (
      filter.fiinXEventTypeIds.length > 1 ||
      filter.fiinXEventTypeIds.length === 0
    ) {
      return <SummaryForm data={data} />
    } else {
      const eventTypeId = filter.fiinXEventTypeIds[0]
      switch (eventTypeId) {
        case FIINX_EVENT_TYPE.cashDividend.id:
          return <CashDividendForm data={data} />
        case FIINX_EVENT_TYPE.shareIssue.id:
          return <ShareIssueForm data={data} />
        case FIINX_EVENT_TYPE.directorDeal.id:
        case FIINX_EVENT_TYPE.directorDealIndividualTransactions.id:
        case FIINX_EVENT_TYPE.directorDealInstitutionalTransactions.id:
        case FIINX_EVENT_TYPE.directorDealRelatedPersonTransactions.id:
          return <DirectorDealForm data={data} />
        default:
          return <SummaryForm data={data} />
      }
    }
  }

  const handleClosePopup = () => {
    setIsShow(false)
    dispatch(changeEventDetailId(null))
  }

  useEffect(() => {
    if (eventId) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }

    if (eventId && typeof eventId === 'string') {
      const event = events.find((e) => e.corporateEventId === eventId)
      const eventDetail = {
        title: event?.description,
        date: event?.date,
        filing: event?.source,
      }
      dispatch(changeEventDetail(eventDetail))
    }
  }, [eventId, events, locale])

  if (isShow)
    return (
      <Popup>
        <div className="modal modal-medium">
          <div className="modal-title">
            <h3>
              <Translate value="market.event.EVENT" />
            </h3>
            <a
              onClick={handleClosePopup}
              className={['close-modal', style.link].join(' ')}
            >
              <i className="icon-delete-stroke" />
            </a>
          </div>
          <div className={`modal-content ${style.modalContent}`}>
            {loading ? <Loading /> : selectFormByEventType(data)}
          </div>
        </div>
      </Popup>
    )

  return null
}

export default PopupDetail
