import { CalcResize } from '../../../../common/CalcResize'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'

export const ChartContainer = ({
  id,
  children,
  data,
  width,
  height,
  isLoading,
}) => {
  return (
    <div id={id} className="h-100">
      {isLoading ? (
        <Loading />
      ) : !data || !data.length ? (
        <NoData />
      ) : (
        <SizeTracker>
          {(size) => {
            return (
              <>
                <CalcResize
                  initSize={{ width: width, height: height - size.height }}
                >
                  {(resizeSize) => (
                    <div style={{ width: resizeSize.width, margin: '0 auto' }}>
                      {children(resizeSize)}
                    </div>
                  )}
                </CalcResize>
              </>
            )
          }}
        </SizeTracker>
      )}
    </div>
  )
}

ChartContainer.propTypes = {}
