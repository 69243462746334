import { useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import Container from './Container'
import { getCurrentPage, getTimeRange } from './store/slice'
import { downloadCurrencyExchangeRate } from './store/thunk'

const PanelExchangeRate = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeRange = useSelector(getTimeRange)
  const currentPage = useSelector(getCurrentPage)
  const [firstDate, , lastDate] = currentPage
  const timeZone = UseTimeZone()

  const downloadCsv = () => {
    const params = {
      TimeFrequency: timeRange,
      From: firstDate
        ? firstDate.date
        : getISOStartOrEndOfDay(new Date(), timeZone, true),
      To: lastDate
        ? lastDate.date
        : getISOStartOrEndOfDay(new Date(), timeZone, true),
    }

    return downloadCurrencyExchangeRate(params)
  }

  return (
    <Panel
      title={'economy.monetary.monetary.EXCHANGE_RATE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <Container
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelExchangeRate
