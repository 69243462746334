import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useGetICB from '../../../common/masterData/useGetICB'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import { typeRowGroup } from '../constant'
import {
  changeSectorInDepthById,
  handleLevelSort,
  handleSortSectorInDepthId,
  keys,
  selectLoading,
  selectSectorInDepthById,
} from '../store/slice'
import { idVnIndex, smallMidLargeCap } from '../store/thunk'
import { resetDefaultActiveRow, useActiveSector } from './ContentTableSector'

const HandleStateTableSector1D = ({
  levelCollapse,
  level,
  setDefaultActiveRow,
  setLevelCollapse,
  setRowsCollapse,
}) => {
  const dispatch = useDispatch()
  const { ICBs, ICBById } = useGetICB(true)

  const loading = useSelector(selectLoading(keys.SECTOR_IN_DEPTH))
  const sectorInDepthById = useSelector(selectSectorInDepthById)
  const indexInfo = useSelector(selectIndexInfo)

  useActiveSector(ICBById, levelCollapse)

  useEffect(() => {
    if (!loading) {
      let lastId = ''
      const newSectorInDepthId = []
      const newLevelCollapse = {}
      const newRowsCollapse = []
      const newSectorInDepthById = {}
      const newLevelSort = []
      ICBs.forEach((sector, index) => {
        const id = `${typeRowGroup.ICB}-${sector.icbId}`
        if (sectorInDepthById[id]?.closePrice === undefined) {
          newSectorInDepthById[id] = {
            id: sector.icbId,
            rowGroup: typeRowGroup.ICB,
            name: sector.icbName,
          }
        }
        if (sector.icbLevel >= level) {
          newSectorInDepthId.push(id)
          newLevelSort.push({
            id,
            level: sector.icbLevel,
            parentId: `${typeRowGroup.ICB}-${sector.parentICBId}`,
          })
          newLevelCollapse[id] = {
            index: id,
            level: sector.icbLevel,
            isRowCollapse: false,
            isLastChild: false,
          }
          if (
            lastId &&
            newLevelCollapse[lastId]?.level >= newLevelCollapse[id].level
          ) {
            newLevelCollapse[lastId].isLastChild = true
          } else if (lastId) {
            newRowsCollapse.push(lastId)
          }
          lastId = id
        }
        if (lastId && index === ICBs.length - 1) {
          newLevelCollapse[lastId].isLastChild = true
        }
      })
      setDefaultActiveRow(resetDefaultActiveRow) // reset to default to reactive default row
      setLevelCollapse(newLevelCollapse)
      dispatch(
        handleSortSectorInDepthId(
          newSectorInDepthId.concat(
            indexInfo.groupId === idVnIndex
              ? smallMidLargeCap.map((id) => typeRowGroup.INDEX + '-' + id)
              : [],
          ),
        ),
      )
      dispatch(changeSectorInDepthById(newSectorInDepthById))
      setRowsCollapse(newRowsCollapse)
      dispatch(handleLevelSort(newLevelSort))
    } else {
      setLevelCollapse({})
      dispatch(handleSortSectorInDepthId([]))
      setRowsCollapse([])
    }
  }, [ICBs, loading])

  return <></>
}

export default HandleStateTableSector1D
