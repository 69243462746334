import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getInvestmentStructure, getVSICName } from './thunk'

const initialState = {
  isLoading: true,
  dataChart: [],
  listVSICName: [],
  filter: undefined,
}

const slice = createSlice({
  name: 'economy/fdi/fdiByIndustries/barChartData',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInvestmentStructure.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getInvestmentStructure.fulfilled, (state, action) => {
      state.dataChart = action.payload || []
      state.isLoading = false
    })
    builder.addCase(getInvestmentStructure.rejected, (state, action) => {
      state.isLoading = action.payload
    })
    builder.addCase(getVSICName.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getVSICName.fulfilled, (state, action) => {
      const data = action.payload || []
      state.listVSICName = data
      state.filter = data.find((i) => i.vsicId === state.filter?.vsicId)
      state.isLoading = false
    })
    builder.addCase(getVSICName.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].dataChart
export const selectListVSICName = (state) => state[slice.name].listVSICName
export const selectFilter = (state) => state[slice.name].filter

export const { changeFilter } = slice.actions

register(slice.name, slice.reducer)
