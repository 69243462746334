import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { Panel } from '../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../constants/Common'
import { formatNumber } from '../../utils/Common'
import { selectTimeFilter } from './financialMetric/store/slice'
import { selectBasicInfoOverview } from './store/slice'
import Toi from './toi/Toi'

const DOWNLOAD_ID = 'corporate/overview/toi'

export const PanelToi = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfoOverview)
  const timeFilter = useSelector(selectTimeFilter)
  const nameScreen = UseI18n('constants.route.corporate.overview.LABEL')
  const chartName = UseI18n('corporate.overview.financial.GROWTH_TIMELINE')
  const tabName = UseI18n(timeFilter.label)

  return (
    <Panel
      title={'corporate.overview.financial.GROWTH_TIMELINE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: DOWNLOAD_ID,
        nameScreen,
        chartName,
        tabName,
        tickerName: basicInfo.ticker,
      }}
    >
      <Toi
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        downloadId={DOWNLOAD_ID}
      />
    </Panel>
  )
}

PanelToi.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
