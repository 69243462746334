import { corporate } from './corporate'
import { economy } from './economy'
import { indicesSector } from './indicesSector'
import { tradingData } from './tradingData'

export const dataExplorer = {
  corporate,
  indicesSector,
  economy,
  tradingData,
  TICKER: 'Ticker',
  SEARCH: 'Search',
  GROUP_STOCK: 'Group Stock',
  INDEX_SECTOR: 'Index/sector',
  EXCHANGE: 'Exchange',
  SECTOR: 'Sector',
  WATCHLIST: 'Watchlist',
  INDICATOR: 'INDICATOR',
  TEMPLATE: 'TEMPLATE',
  SORT_COLUMN: 'Re-arrange table',
  EXPORT: 'Export Excel',
  ASK_EXPORT: 'Do you want to export:',
  SINGLE_EXPORT: 'Single sheet (current active sheet)',
  MULTI_EXPORT: 'Multiple sheet:',
  ALL: 'All',
  SETTING: 'SETTING',
  SINGLE: 'Single Reporting',
  MULTIPLE: 'Multiple Reporting',
  DAILY: 'Daily',
  DATE: 'Date',
  FROM: 'From',
  TO: 'to',
  WEEKLY: 'Weekly',
  WEEK: 'Week',
  MONTHLY: 'Monthly',
  MONTH: 'Month',
  QUARTERLY: 'Quarterly',
  QUARTER: 'Quarter',
  YEARLY: 'Yearly',
  YEAR: 'Year',
  ALL_SETTING: 'All',
  EDIT: 'Edit',
  PERSONAL: 'Personal',
  FIINX_TEMPLATE: "FiinProX's Template",
  LOAD: 'Load Data',
  SHEET: 'Sheet',
  NO: 'NO',
  NOTICE: 'NOTICE',
  ADD_ALL: 'Add all',
  ADD_ONE: 'Only add this level',
  INDICATOR_LABEL: 'Indicator',
  FS: 'FINANCIAL STATEMENT',
  FS2: 'Financial Statement',
  FINANCIAL_TARGET: 'Financial Target',
  REPORT_TYPE: 'Report Type',
  REPORT_FORM: 'Report Form',
  AUDIT_STATUS: 'Audit Status',
  CURRENCY: 'Currency',
  UNIT: 'Unit',
  SELECTED_ITEM: 'Selected items',
  SELECTED: 'Selected:',
  BILLION_VND: 'Billions VND',
  MILLION_VND: 'Millions VND',
  THOUSAND_VND: 'Thousand VND',
  COMPANY: 'Company',
  BANK: 'Bank',
  SERCURITY: 'Securities',
  NONLIFE_INSURANCE: 'Nonlife Insurance',
  LIFE_INSURANCE: 'Life Insuarance',
  MAX_TICKER: 'The number of tickers is no more than 4000.',
  CREATE_TEMPLATE_SUCCESS: 'Your template has been successfully created.',
  UPDATE_TEMPLATE_SUCCESS: 'Your template has been successfully updated.',
  COPY_TEMPLATE_SUCCESS: 'Template copied.',
  ISSUE_BOND: 'CORPORATE BOND ISSUANCE',
  YEAR_REVIEW: 'Year of review',
  CASH_DIVIDEND_PAYOUT: 'CASH DIVIDEND PAYOUT',
  BOD: 'BOARD OF DIRECTORS',
  MAJOR_SHAREHOLDER: 'MAJOR SHAREHOLDER',
  ISSUE_SHARE: 'ISSUE SHARE',
  ISSUE_METHOD: 'Issue Method',
  Overview: 'OVERVIEW',
  Valuation: 'VALUATION',
  Credit: 'CREDIT',
  PERFORMANCE: 'PERFORMANCE',
  IPO_YEAR: 'IPO year',
  NOTE_DETAIL_TEMPLATE:
    'All companies in iemplate that are not in the current industry will be excluded. Are you sure to apply?',
  OK: 'Ok',
  SHARE_TEMPLATE: 'Copy link',
}
