export const COLOR_STRUCTURE_CHART = [
  '#185999',
  '#40abff',
  '#fff6df',
  '#fe7e6d',
  '#facc5c',
  '#45b29d',
]

export const COMPONENTS_FILTER = [
  {
    name: 'economy.paymentBalance.paymentBalance.CURRENT_ACC',
    value: 'CurrentAccount',
  },
  {
    name: 'economy.paymentBalance.paymentBalance.CAPITAL_ACC',
    value: 'CapitalAccount',
  },
  {
    name: 'economy.paymentBalance.paymentBalance.FINANCIAL_ACC',
    value: 'FinancialAccount',
  },
  {
    name: 'economy.paymentBalance.paymentBalance.ERROR_OMISSION',
    value: 'ErrorAndOmissions',
  },
  {
    name: 'economy.paymentBalance.paymentBalance.OVERALL_BALANCE',
    value: 'OverallBalance',
  },
  {
    name: 'economy.paymentBalance.paymentBalance.RESERVED_RELATED',
    value: 'ReservedAndRelatedItems',
  },
]
