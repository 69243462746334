import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { EMPTY_VALUE } from '../../../../../../constants/Common'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import {
  getDataByMonthAndYear,
  getDataByYear,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
  reOrderByMonthAndYear,
} from '../../../common/helper'
import {
  LIMIT_FETCH,
  LIMIT_FIRST_FETCH,
  TIME_FREQ,
  TYPE_VAL_GROWTH,
} from '../constants'
import { getExporterStatistics, getMaxDate } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/fishery/corporateExport/exportStatisticTable',
  initialState: {
    idsItem: [],
    dataById: {},
    isLoading: true,
    isContentLoading: true,
    listItems: [],
    isGetData: true,
    data: [],
    groupColumns: [],
    activeItem: null,
    isValOrGrowth: TYPE_VAL_GROWTH.VAL,
    maxMonth: null,
    maxYear: null,
    frequencyType: TIME_FREQ.MONTHLY,
    initialIds: [],
    reCalcWidths: false,
    isChangeLocale: false,
    currentMinMonth: null,
    currentMinYear: null,
    isChangeFilter: false,
    isFirstTime: true,
    locale: 'en',
    countLimit: 0,
  },
  reducers: {
    addItemToList: (state, action) => {
      state.isGetData = true
      state.listItems = [...state.listItems, action.payload]
    },
    removeItemFromList: (state, action) => {
      state.isGetData = false
      state.listItems = state.listItems.filter(
        (item) => item.id !== action.payload,
      )
    },
    changeActiveItem: (state, action) => {
      const rowId = action.payload
      state.activeItem = state.dataById[rowId]
    },
    changeIsValOrGrowth: (state, action) => {
      state.isValOrGrowth = action.payload
      state.isChangeFilter = true
    },
    changeFrequencyType: (state, action) => {
      state.frequencyType = action.payload
    },
    sort: (state, action) => {
      state.idsItem = getIdsFromProps(
        state.idsItem,
        state.dataById,
        action.payload,
        state.initialIds,
      )
    },
    changeCurrentMinMonth: (state, action) => {
      state.currentMinMonth = action.payload
    },
    changeLocale: (state, action) => {
      state.isChangeLocale = true
      state.currentMinMonth = null
      state.locale = action.payload
    },
    setMaxMonthToNullExporter: (state) => {
      state.maxMonth = null
      state.maxYear = null
    },
    setIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExporterStatistics.pending, (state) => {
      state.isContentLoading = true
    })
    builder.addCase(getExporterStatistics.fulfilled, (state, action) => {
      state.isContentLoading = false
      state.isChangeFilter = false

      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          id: item.organizationId,
          name: item.organizationShortName,
          fullName: item.organizationName,
          month: item.realMonth,
          year: item.realYear,
          value:
            state.isValOrGrowth === TYPE_VAL_GROWTH.VAL
              ? item.accumulatedValue / 1000000
              : item.accumulatedValueYoY,
        })) || []

      if (LIMIT_FIRST_FETCH === action.meta.arg.Limit) {
        state.countLimit = action.meta.arg.Limit
      } else {
        state.countLimit += LIMIT_FETCH
      }

      const data = fullDataWithVal(
        payloadData,
        state.frequencyType,
        state.currentMinMonth,
        state.data,
        state.isChangeLocale,
        state.isValOrGrowth,
      )
      const oldData = [...state.data]

      state.data = data
      state.idsItem = state.initialIds = data?.map((v) => v.id)
      state.payloadData = payloadData
      state.dataById = keyBy(data, 'id')
      state.listItems =
        !oldData.length && !state.listItems.length
          ? data.slice(0, 5)
          : [...state.listItems].map((item) => ({
              ...item,
              ...state.dataById[item.id],
            }))

      state.isChangeLocale = false

      const getGroupColumns = data?.map((item) => Object.keys(item))[0]
      const isYearly = state.frequencyType === TIME_FREQ.YEARLY
      const isAcc = state.frequencyType === TIME_FREQ.ACC_MONTHLY

      const filterGroupColumns = isYearly
        ? getGroupColumns?.filter((item) => /\d/.test(item))
        : reOrderByMonthAndYear(
            getGroupColumns?.filter((item) => item.includes('-')),
          )

      const groupColumns = [
        { key: 'name', title: '' },
        {
          key: 'ticker',
          title: 'sector.sectorSpecific.fishery.exporters.TICKER',
        },
      ]

      const getTitleForColKey = (colKey) => {
        const month = colKey.slice(0, 2)
        const year = colKey.slice(3, 7)
        if (Number(month) === 1) {
          return `${month}-${year}`
        } else
          return state.locale === 'vi'
            ? `${Number(month)}Th-${year}`
            : `${Number(month)}M-${year}`
      }

      filterGroupColumns?.forEach((item) => {
        groupColumns.push({
          key: item,
          title: isAcc ? getTitleForColKey(item) : item,
        })
      })
      state.groupColumns = groupColumns

      state.currentMinMonth = payloadData.map((item) => item.month).reverse()[0]
      state.currentMinYear = payloadData.map((item) => item.year).reverse()[0]

      state.reCalcWidths = !state.reCalcWidths
    })
    builder.addCase(getExporterStatistics.rejected, (state) => {
      state.isContentLoading = false
    })

    builder.addCase(getMaxDate.pending, (state) => {
      state.isContentLoading = true
    })
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.isContentLoading = false
      const payloadData = action.payload
      state.maxMonth = payloadData?.realMonth
      state.maxYear = payloadData?.realYear
    })
    builder.addCase(getMaxDate.rejected, (state) => {
      state.isContentLoading = false
    })
  },
})

export const selectLoadingTable = (state) => state[slice.name].isLoading
export const selectLoadingTableContent = (state) =>
  state[slice.name].isContentLoading
export const selectItemIds = (state) => state[slice.name].idsItem
export const selectItemDataTableById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectListItems = (state) => state[slice.name].listItems
export const selectDataTable = (state) => state[slice.name].data
export const selectPayloadData = (state) => state[slice.name].payloadData
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectActiveItem = (state) => state[slice.name].activeItem
export const selectIsValOrGrowth = (state) => state[slice.name].isValOrGrowth
export const selectMaxMonth = (state) => state[slice.name].maxMonth
export const selectMaxYear = (state) => state[slice.name].maxYear
export const selectFrequencyType = (state) => state[slice.name].frequencyType
export const selectMinCurrentMonth = (state) =>
  state[slice.name].currentMinMonth
export const selectMinCurrentYear = (state) => state[slice.name].currentMinYear
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths
export const selectIsFirstTime = (state) => state[slice.name].isFirstTime
export const selectCountLimit = (state) => state[slice.name].countLimit

export const {
  addItemToList,
  removeItemFromList,
  changeActiveItem,
  changeIsValOrGrowth,
  changeFrequencyType,
  sort,
  changeCurrentMinMonth,
  changeLocale,
  setMaxMonthToNullExporter,
  setIsFirstTime,
} = slice.actions

register(slice.name, slice.reducer)

const fullDataWithVal = (
  dataContainVal,
  frequencyType,
  currentMinMonth,
  dataAfterFirstRender,
  isChangeLocale,
  isValOrGrowth,
) => {
  const isYearlyData = frequencyType === TIME_FREQ.YEARLY

  const getDataKeyFromRange = isYearlyData
    ? getYearInDataRange(dataContainVal).map((item) => getYearKey(item.year))
    : getMonthAndYearInDataRange(dataContainVal).map((item) =>
        getMonthAndYearKey(item.month, item.year),
      )

  const getDataValueFromRange = isYearlyData
    ? getYearInDataRange(dataContainVal).map((item) => [
        ...getDataByYear(dataContainVal, item.year),
      ])
    : getMonthAndYearInDataRange(dataContainVal).map((item) => [
        ...getDataByMonthAndYear(dataContainVal, item.month, item.year),
      ])

  const getCorrespondingDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.id === item.id))]?.value ??
      EMPTY_VALUE
    )
  }

  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  const uniqueCompany = Object.values(
    dataContainVal.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  )

  return !currentMinMonth || isChangeLocale
    ? uniqueCompany.map((item) => ({
        ...item,
        ...getKeyAndValRange(item),
        type: isValOrGrowth,
      }))
    : dataAfterFirstRender.map((item) => ({
        ...item,
        ...getKeyAndValRange(item),
        type: isValOrGrowth,
      }))
}
