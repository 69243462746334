import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../../../configs/Global'
import { BondRealtime } from '../../../../../common/BondRealtime'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { Span } from '../../../../../common/html/Span'
import { Table as TableContent } from '../../../../../common/table'
import { TextBlink } from '../../../../../common/table/TextBlink'
import TextEllipsis from '../../../../../common/textEllipsis'
import { selectReCalcWidths } from '../../../../../market/equityTrading/priceStatistics/summary/overview/store/slice'
import { getColorOverview } from '../../../../../utils/Color'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import {
  changeBasicInfo,
  changeBondTicker,
} from '../../../bondSecurity/bondSecurity/Filter/store/slice'
import {
  keys,
  selectDataTableByIdTopAsk,
  selectLoading,
  selectTopAskData,
  sortTopAsk,
  subscribeBondAskDeal,
  subscribeBondAskMatch,
} from '../store/slice'
import TableHeader from './TableHeader'
import { GROUP_COLUMNS } from './constants'
import style from './index.module.css'

const Table = () => {
  const dispatch = useDispatch()

  const navigate = useCustomNavigate()

  const isLoading = useSelector(selectLoading(keys.TOP_ASK_INTEREST))
  const { data, ids, tickers } = useSelector(selectTopAskData)
  const reCalcWidths = useSelector(selectReCalcWidths)

  const redirectToSecurityMaster = (bond) => {
    dispatch(changeBondTicker(bond))
    dispatch(changeBasicInfo(null))
    navigate('/bond/corporate-bond/security-master')
  }

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  return (
    <>
      <TableContent
        isLoading={isLoading}
        ids={ids}
        getDataFromRedux={selectDataTableByIdTopAsk}
        renderHeader={(stateSort, sortColumn) => (
          <TableHeader
            tableHeaderColAttr={GROUP_COLUMNS}
            stateSort={stateSort}
            sortColumn={sortColumn}
          />
        )}
        schema={GROUP_COLUMNS.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }

          switch (index) {
            case 0:
              return {
                ...result,
                tdStyle: {
                  minWidth: '20%',
                  padding: 0,
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props} className={style.firstCellColumn}>
                    <div
                      style={{
                        padding: '4px 8px',
                        boxSizing: 'border-box',
                        height: 28,
                      }}
                      className="d-flex ali-center"
                    >
                      <Span onClick={() => redirectToSecurityMaster(rowId)}>
                        <a className="cursor-pointer">{value}</a>
                      </Span>
                    </div>
                  </td>
                ),
              }

            case 1:
              return {
                ...result,
                tdStyle: {
                  minWidth: '20%',
                  maxWidth: 120,
                  padding: 0,
                },
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const row = data.find((item) => item.bondId === rowId)

                  return (
                    <td {...props}>
                      <div
                        style={{
                          minWidth: 100,
                          padding: '4px 8px',
                          overflow: 'hidden',
                          boxSizing: 'border-box',
                        }}
                      >
                        <a
                          className="cursor-pointer"
                          onClick={() =>
                            redirectToBondIssuer(row?.organizationId)
                          }
                        >
                          <TextEllipsis text={value} isI18n={false} />
                        </a>
                      </div>
                    </td>
                  )
                },
              }

            case 2:
              return {
                ...result,
                tdStyle: {
                  minWidth: '15%',
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td {...props}>
                      <TextBlink value={value}>
                        <Span>{valToPercent(value)}</Span>
                      </TextBlink>
                    </td>
                  )
                },
              }

            case 3:
              return {
                ...result,
                tdStyle: {
                  minWidth: '15%',
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const row = data.find((item) => item.bondId === rowId)

                  return (
                    <td {...props}>
                      <TextBlink value={value}>
                        <div>
                          <Span
                            style={{
                              color: getColorOverview(
                                value,
                                row?.referencePrice,
                              ),
                              cursor: 'pointer',
                            }}
                          >
                            {formatVal(value, 0)}
                          </Span>
                        </div>
                      </TextBlink>
                    </td>
                  )
                },
              }

            case 4:
              return {
                ...result,
                tdStyle: {
                  minWidth: '15%',
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>{formatVal(value, 2)}</Span>
                  </td>
                ),
              }

            case 5:
              return {
                ...result,
                tdStyle: {
                  minWidth: '15%',
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <TextBlink value={value}>
                      <Span>{formatVal(value, 0)}</Span>
                    </TextBlink>
                  </td>
                ),
              }

            default:
              return {}
          }
        })}
        stickies={{
          name: true,
        }}
        sort={sortTopAsk}
        stickyFirstColumn
        isLargeHead={true}
        hasFooter={false}
        resizable={false}
        isHighlightLine={true}
        reCalcWidths={reCalcWidths}
      />

      {!!tickers.length &&
        tickers.map((ticker) => (
          <BondRealtime
            channel={ChannelConfig.BidAskBondChannel + ticker}
            action={subscribeBondAskMatch}
            key={ticker}
          >
            <></>
          </BondRealtime>
        ))}
      {!!tickers.length &&
        tickers.map((ticker) => (
          <BondRealtime
            channel={ChannelConfig.BidAskBondDealChannel + ticker}
            action={subscribeBondAskDeal}
            key={ticker}
          >
            <></>
          </BondRealtime>
        ))}
    </>
  )
}

export default Table
