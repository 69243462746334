import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { ICB_LEVELS, SEARCH_ITEM_TYPE } from '../../../constants'

const initialState = {
  compareTab: SEARCH_ITEM_TYPE.ALL,
  ICBLevelData: ICB_LEVELS.ICB_LEVEL_2,
  listCompareChecked: [],
  keySearch: '',
}

export const slice = createSlice({
  name: 'market/marketInDepth/valuation/popup',
  initialState,
  reducers: {
    changeCompareTab: (state, action) => {
      state.compareTab = action.payload
    },
    addCompareChecked: (state, action) => {
      state.listCompareChecked = [...state.listCompareChecked, action.payload]
    },
    removeCompareChecked: (state, action) => {
      state.listCompareChecked = state.listCompareChecked.filter(
        (element) => element.id !== action.payload.id,
      )
    },
    setCompareChecked: (state, action) => {
      state.listCompareChecked = [...action.payload]
    },
    searchData: (state, action) => {
      state.keySearch = action.payload?.toLowerCase()
    },
    changeICBLevel: (state, action) => {
      state.ICBLevelData = action.payload
    },
    clearCompareChecked: (state) => {
      state.listCompareChecked = []
    },
  },
})

export const selectCompareTab = (state) => state[slice.name].compareTab
export const selectListCompareChecked = (state) =>
  state[slice.name].listCompareChecked
export const selectICBLevelData = (state) => state[slice.name].ICBLevelData
export const selectKeySearch = (state) => state[slice.name].keySearch

export const {
  changeCompareTab,
  clearCompareChecked,
  searchData,
  addCompareChecked,
  removeCompareChecked,
  setCompareChecked,
  changeICBLevel,
} = slice.actions

register(slice.name, slice.reducer)
