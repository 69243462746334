import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import {
  keys,
  selectFilterBondIssueInformation,
  selectLoading,
} from '../store/slice'
import { downloadBondIssueInformationData } from '../store/thunk'
import Filter from './Filter'
import TableContent from './Table'

const BondIssueInformation = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.BOND_ISSUE_INFORMATION))
  const basicInfo = useSelector(selectBasicInfo)
  const { CompanyStatus, CompanyCollateral, Related } = useSelector(
    selectFilterBondIssueInformation,
  )

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const handleExportData = () => {
    if (!basicInfo) return

    const params = {
      companyStatus: !!CompanyStatus?.length
        ? CompanyStatus.toString().replace('All,', '')
        : null,
      companyCollateral: CompanyCollateral,
      related: Related,
      organizationId: basicInfo.organizationId,
    }

    return downloadBondIssueInformationData(params)
  }

  return (
    <Panel
      title="bond.corporateBond.bondIssueInformation.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => handleExportData()}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div style={{ height: height - size.height, width: width }}>
                {isLoading ? <Loading /> : <TableContent />}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default BondIssueInformation
