import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { ScrollComponent } from '../../../common/ScrollComponent'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../utils/EventEmitter'
import { changeTreeSecuritiesIndicator } from '../../chart/store/slice'
import { LIST_TYPE, MAX_SECURITIES_ADD } from '../../common/constants'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import {
  changeSecurities,
  selectIsLoading,
  selectWatchList,
} from '../common/store/slice'
import { getTickerByWLId, getWatchList } from '../common/store/thunk'
import { getSecuritiesIndicatorDataOnApply } from '../popupAddSecurities/helpers'
import ItemSecurities from './ItemSecurities'
import withWrapperData from './withWrapperData'

const ListWatchList = ({
  valueSearch,
  onClose,
  securities,
  treeSecuritiesIndicator,
  indicatorSelected,
  listSecuritiesEconomy,
  typeChart,
  haveOrientationLeft,
  usedColors,
}) => {
  const dispatch = useDispatch()

  const { favoriteColors } = useGetUserSettings()

  const watchList = useSelector(selectWatchList)
  const loading = useSelector(selectIsLoading('watchList'))

  const { companies, indices, sector, economy } = securities

  const checkDisable = (organizationCount) => {
    let count = 0
    const newArray = [companies, indices, sector, economy]
    newArray.forEach((arr) => (count += arr.length))
    return !organizationCount || organizationCount + count > MAX_SECURITIES_ADD
      ? true
      : false
  }

  const addSecuritiesCompany = (arrayId = []) => {
    const securityCount = Object.values(securities).reduce(
      (arr, ids) => arr.concat(ids),
      [],
    ).length
    if (securityCount + arrayId.length >= MAX_SECURITIES_ADD) {
      return EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        I18n.t('financialChart.WANNING_ADD_SECURITIES'),
      ])
    }

    const arrNew = [...companies].concat(
      arrayId.filter((item) => [...companies].indexOf(item) < 0),
    )
    const result = getSecuritiesIndicatorDataOnApply({
      treeSecuritiesIndicator,
      securities,
      indicatorSelected,
      listSecuritiesEconomy,
      typeChart,
      haveOrientationLeft,
      securitiesState: {
        ...securities,
        [LIST_TYPE.COMPANIES]: arrNew,
      },
      typeSecuritiesReplace: LIST_TYPE.COMPANIES,
      usedColors,
      favoriteColors,
    })
    dispatch(changeTreeSecuritiesIndicator(result))
    dispatch(
      changeSecurities({
        typeSecurities: LIST_TYPE.COMPANIES,
        value: arrNew,
      }),
    )
  }

  const addSecurities = (watchListId) => {
    if (watchListId) {
      const params = { WatchListId: watchListId, WithMarketCap: true }
      dispatch(getTickerByWLId(params))
        .unwrap()
        .then((data) => {
          const { details: orgs } = data
          const validOrgIds = orgs.map((org) => org.organizationId)
          addSecuritiesCompany(validOrgIds)
        })
    }
    onClose()
  }

  const filterWatchList = useCallback(() => {
    return [...watchList]
      .filter((i) => i.watchListName?.toLowerCase().includes(valueSearch))
      .sort((a, b) =>
        valueSearch
          ? b.watchListName?.toLowerCase().indexOf(valueSearch.toLowerCase()) -
            a.watchListName?.toLowerCase().indexOf(valueSearch.toLowerCase())
          : 1,
      )
  }, [valueSearch, watchList])

  const renderListWatchList = () => (
    <ScrollComponent bgColor="#D9D9D9">
      {filterWatchList().map((item) => {
        return (
          <ItemSecurities
            key={item.watchListId}
            securities={securities.watchList}
            id={item.watchListId}
            text={item.watchListName}
            onClick={() => addSecurities(item.watchListId)}
            disableClick={checkDisable(item.organizationCount)}
          />
        )
      })}
    </ScrollComponent>
  )

  useEffect(() => {
    dispatch(getWatchList())
  }, [])

  return (
    <>
      {loading ? (
        <Loading isBlack />
      ) : filterWatchList().length === 0 ? (
        <NoData />
      ) : (
        <>{renderListWatchList()}</>
      )}
    </>
  )
}

ListWatchList.propTypes = {}

export default withWrapperData(ListWatchList)
