import InformationProxy from '../../../proxies/bond/bondSecurityProxy/InformationProxy'
import { ServiceBase } from '../../ServiceBase'

export class Information extends ServiceBase {
  getDataSecurity(params) {
    return this.getData(() => InformationProxy.getDataProxy(params))
  }
  getDataFile(params) {
    return this.getData(() => InformationProxy.getDatafileProxy(params))
  }

  // exportFile(params) {
  //   return this.getFileDownload(() => HistoryProxy.exportFileProxy(params))
  // }
}

export default new Information()
