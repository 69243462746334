import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThCellWithIconInfo } from '../../../../../common/table/ThCellWithIConInfo'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { MONTH_QUARTER_YEAR_TABS } from '../constants'
import style from '../index.module.css'
import {
  changeFilter,
  keys,
  selectFilterInterestAndPrincipalPaid,
} from '../store/slice.js'
import { getInterestAndPrincipalPaidData } from '../store/thunk'

const Filter = () => {
  const dispatch = useDispatch()

  const { timeType } = useSelector(selectFilterInterestAndPrincipalPaid)
  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)

  const handleChangeTimeType = (item) => {
    dispatch(
      changeFilter({
        label: keys.INTEREST_AND_PRINCIPAL_PAID,
        key: 'timeType',
        value: item.value,
      }),
    )
  }

  useEffect(() => {
    if (basicInfo.organizationId && timeType) {
      const payload = {
        TimeRange: timeType,
        OrganizationId: basicInfo.organizationId,
      }

      dispatch(getInterestAndPrincipalPaidData(payload))
    }
  }, [locale, timeType, basicInfo.organizationId])

  return (
    <div className="d-flex justify-content-space-between mb-8">
      <ThCellWithIconInfo
        isI18n={true}
        tooltipCustom={'bond.corporateBond.interestAndPrincipalPaid.TOOLTIP'}
        styleIcon={{
          fontSize: '15px',
        }}
        styleTextTooltip={{
          fontSize: 10,
          fontWeight: 400,
          fontStyle: 'italic',
          color: '#ECECEC',
          letterSpacing: '0.25px',
        }}
      >
        <></>
      </ThCellWithIconInfo>
      <div className={`${style.menuContainer}`}>
        <DispatchActionTab
          data={MONTH_QUARTER_YEAR_TABS}
          activeTab={timeType}
          onChangeTab={handleChangeTimeType}
          containerStyle={{ width: '100%' }}
          itemStyle={{
            width: '50%',
            fontWeight: 600,
          }}
        />
      </div>
    </div>
  )
}

export default Filter
