import PropTypes from 'prop-types'
import { Span } from '../../../html/Span'
import { formatterByName } from '../helper'
import style from './index.module.css'

const Container = ({ items, tooltipSchema, isLeft }) => {
  if (!items.length) return null

  return (
    <div className={[style.container, isLeft && style.left].join(' ')}>
      {items.map((item) => {
        const { value, name } = item

        if (typeof value !== 'number' || isNaN(value)) return <></>

        const formattedValue = formatterByName(tooltipSchema)(value, name)

        return Array.isArray(formattedValue) ? (
          <div key={item.dataKey} className={style.marginTopBottom4}>
            <Span>{formattedValue[1]}</Span>
            <Span style={{ minWidth: 16 }}>:</Span>
            <Span style={{ color: value < 0 ? '#ff4752' : '' }}>
              {formattedValue[0]}
            </Span>
          </div>
        ) : (
          formattedValue
        )
      })}
    </div>
  )
}

Container.propTypes = {
  items: PropTypes.array.isRequired,
  tooltipSchema: PropTypes.array.isRequired,
  isLeft: PropTypes.bool,
}

export default Container
