export const handleCollapseLevel = (data) => {
  if (!data.length) {
    return {}
  }

  return data.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.id]: { level: cur.level, isRowCollapse: cur.level === 1 },
    }
  }, {})
}

export const getQuarterAndYearInDataRange = (dataRange) => {
  const allQuartersAndYears = dataRange?.map((item) => ({
    quarter: item.quarter,
    year: item.year,
  }))

  const uniqueQuartersAndYears = Object.values(
    allQuartersAndYears.reduce((acc, curr) => {
      acc[curr.quarter + '|' + curr.year] = curr
      return acc
    }, {}),
  ).sort((a, b) => b.quarter - a.quarter)

  return uniqueQuartersAndYears
}

export const getDataByQuarterAndYear = (data, quarter, year) => {
  return data
    ?.filter((item) => item.year === year && item.quarter === quarter)
    ?.map((item) => ({
      value: item.value ?? 0,
      year: item.year ?? 0,
      quarter: item.quarter ?? 0,
      id: item.id ?? 0,
    }))
}

export const getAccQuarterAndYearKey = (quarter, year) => {
  const QUARTER_ONE = 1
  return quarter === QUARTER_ONE ? `Q${quarter}-${year}` : `${quarter}Q-${year}`
}

export const getQuarterAndYearKey = (quarter, year) => {
  return `Q${quarter}-${year}`
}
