import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BalanceOfPaymentService from '../../../../../core/services/economy/BalanceOfPaymentService'

export const getGrowthByTimeRange = createAsyncThunk(
  'economy/paymentBalance/getGrowthByTimeRange',
  async (params, { rejectWithValue }) => {
    try {
      const response = await BalanceOfPaymentService.getGrowthByTimeRange(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
