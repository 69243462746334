import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  DEFAULT_ORGANIZATION,
  DEFAULT_ORGANIZATION_ID,
} from '../../../configs/Common'
import { COM_GROUP, EXCHANGE_CODE_OTHER_HOHAUP } from '../../constants/Group'
import CurrentState from '../../utils/CurrentState'
import EventEmitter, { UPDATE_CURRENT_STATE } from '../../utils/EventEmitter'
import useGetFund from '../masterData/useGetFund'
import useGetICB from '../masterData/useGetICB'
import useGetStock from '../masterData/useGetStock'
import {
  changeFundInfo as changeBasicInfoFund,
  defaultState as defaultStateFund,
  resetDefault as resetDefaultFund,
  resetStore as resetStoreFund,
} from '../topInfo/fundTopInfo/store/slice'
import {
  changeIndexSectorTickerInfo as changeBasicInfoIndexSectorTicker,
  resetDefault as resetDefaultIndexSectorTicker,
  resetStore as resetStoreIndexSectorTicker,
} from '../topInfo/indexSectorTickerTopInfo/store/slice'
import { DEFAULT_SEARCH_INFO } from '../topInfo/indexTickerTopInfo/constants'
import {
  changeCurrentInfo as changeBasicInfoIndexTicker,
  resetDefault as resetDefaultIndexTicker,
  resetStore as resetStoreIndexTicker,
} from '../topInfo/indexTickerTopInfo/store/slice'
import { DEFAULT_INDEX } from '../topInfo/indexTopInfo/constants'
import {
  changeIndexInfo as changeBasicInfoIndex,
  resetDefault as resetDefaultIndex,
  resetStore as resetStoreIndex,
} from '../topInfo/indexTopInfo/store/slice'
import {
  changeSector as changeBasicInfoSector,
  defaultState as defaultStateSector,
  resetDefault as resetDefaultSector,
  resetStore as resetStoreSector,
} from '../topInfo/sectorTopInfo/store/slice'
import {
  changeBasicInfo as changeBasicInfoStock,
  defaultState as defaultStateStock,
  resetDefault as resetDefaultStock,
  resetStore as resetStoreStock,
} from '../topInfo/stockTopInfo/store/slice'
import { DEFAULT_WARRANT } from '../topInfo/warrantTopInfo/constants'
import {
  changeWarrantInfo as changeBasicInfoWarrant,
  resetDefault as resetDefaultWarrant,
  resetStore as resetStoreWarrant,
} from '../topInfo/warrantTopInfo/store/slice'
import { ICB_ID } from './../../constants/Group'
import useCustomNavigate from './useCustomNavigate'

const findItem = (array, id, keyId) => {
  return array.find((item) => item?.[keyId] === id)
}

export const TYPE_HEADER = {
  STOCK: 'stock',
  INDEX: 'index',
  INDEX_TICKER: 'indexTicker',
  INDEX_SECTOR_TICKER: 'indexSectorTicker',
  SECTOR: 'sector',
  WARRANT: 'warrant',
  FUND: 'fund',
}

const useHeaderSearch = (
  type,
  isOnlyHOHAUP = true,
  filterId = [],
  customSelect,
  comTypeIds,
  keepSearchParams,
) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useCustomNavigate(keepSearchParams)
  const { ICBs } = useGetICB()
  const { stocks, stockById } = useGetStock()
  const { fundById } = useGetFund()

  const locale = useSelector((state) => state.i18n.locale)

  const [sector, setSector] = useState([])
  const [stock, setStock] = useState([])
  const [resetBasicInfo, setResetBasicInfo] = useState(false)

  useEffect(() => {
    setSector(
      ICBs.map((item) => ({
        icbNameCustom: item.icbName + ' L' + item.icbLevel,
        ...item,
      })),
    )
  }, [ICBs])

  useEffect(() => {
    setStock(
      stocks.map((item) => ({
        ...item,
        companyName: item.organizationShortName,
        exchange: item.exchangeCode,
      })),
    )
  }, [stocks])

  useEffect(() => {
    if (!location.state?.headerSearch) {
      navigate(
        location.pathname,
        CurrentState.getCurrentState().data?.headerSearch ||
          DEFAULT_ORGANIZATION,
      )
    }

    return () => {
      dispatch(resetStoreIndex())
      dispatch(resetStoreStock())
      dispatch(resetStoreIndexSectorTicker())
      dispatch(resetStoreIndexTicker())
      dispatch(resetStoreSector())
      dispatch(resetStoreWarrant())
      dispatch(resetStoreFund())
    }
  }, [])

  useEffect(() => {
    if (sector.length && stock.length) {
      setResetBasicInfo(!resetBasicInfo)
    }
  }, [stock, sector])

  useEffect(() => {
    let basicInfo =
      location.state?.headerSearch ||
      CurrentState.getCurrentState().data?.headerSearch ||
      {}

    if (
      stock.length &&
      basicInfo?.organizationId &&
      stockById[basicInfo?.organizationId]
    ) {
      basicInfo = findItem(stock, basicInfo.organizationId, 'organizationId')
    } else if (
      sector.length &&
      basicInfo?.icbId &&
      !basicInfo?.organizationId
    ) {
      basicInfo = findItem(sector, basicInfo.icbId, 'icbId')
    }
    if (basicInfo) {
      changeBasicInfo(basicInfo)
    }
  }, [location, resetBasicInfo])

  const changeBasicInfo = (basicInfoOrigin) => {
    let basicInfo = JSON.parse(JSON.stringify(basicInfoOrigin))

    const isFund =
      basicInfo.organizationId && !!fundById[basicInfo.organizationId]
    const isStock =
      basicInfo.organizationId && !fundById[basicInfo.organizationId]
    const isIndex = basicInfo.groupId
    const isSector = basicInfo.icbId && !basicInfo.organizationId
    const isHOHAUP =
      basicInfo.exchangeCode &&
      !EXCHANGE_CODE_OTHER_HOHAUP.includes(basicInfo.exchangeCode)
    const isWarrant = basicInfo.coveredWarrantId

    switch (type) {
      case TYPE_HEADER.STOCK:
        if (comTypeIds) {
          if (comTypeIds.includes(COM_GROUP.CORPORATE)) {
            const otherComGroupIcbIds = Object.values(COM_GROUP)
              .filter((value) => !comTypeIds.includes(value))
              .reduce((prev, curr) => [...prev, ...ICB_ID[curr]], [])
            if (otherComGroupIcbIds.includes(basicInfo.icbId)) {
              navigate(
                location.pathname,
                {
                  organizationId: DEFAULT_ORGANIZATION_ID[comTypeIds[0]],
                },
                '',
                true,
              )
              break
            }
          } else {
            const comGroupIcbIds = Object.values(COM_GROUP)
              .filter((value) => comTypeIds.includes(value))
              .reduce((prev, curr) => [...prev, ...ICB_ID[curr]], [])
            if (
              !basicInfo.organizationId ||
              !comGroupIcbIds.includes(basicInfo.icbId)
            ) {
              navigate(
                location.pathname,
                {
                  organizationId: DEFAULT_ORGANIZATION_ID[comTypeIds[0]],
                },
                '',
                true,
              )
              break
            }
          }
        }

        if (!isStock || (isOnlyHOHAUP && !isHOHAUP)) {
          basicInfo = defaultStateStock.basicInfo[locale || 'en']
          dispatch(resetDefaultStock(locale || 'en'))
        } else {
          dispatch(changeBasicInfoStock(basicInfo))
        }
        break
      case TYPE_HEADER.INDEX:
        if (
          !isIndex ||
          filterId.includes(isIndex) ||
          (customSelect &&
            !customSelect?.some((indice) => indice.groupId === isIndex))
        ) {
          basicInfo = DEFAULT_INDEX
          dispatch(resetDefaultIndex())
        } else {
          dispatch(changeBasicInfoIndex(basicInfo))
        }
        break
      case TYPE_HEADER.INDEX_TICKER:
        if ((!isIndex && !isStock) || (isStock && isOnlyHOHAUP && !isHOHAUP)) {
          basicInfo = DEFAULT_SEARCH_INFO
          dispatch(resetDefaultIndexTicker())
        } else {
          dispatch(changeBasicInfoIndexTicker(basicInfo))
        }
        break
      case TYPE_HEADER.INDEX_SECTOR_TICKER:
        if (
          (!isIndex && !isStock && !isSector) ||
          (isStock && isOnlyHOHAUP && !isHOHAUP)
        ) {
          basicInfo = DEFAULT_INDEX
          dispatch(resetDefaultIndexSectorTicker())
        } else {
          dispatch(changeBasicInfoIndexSectorTicker(basicInfo))
        }
        break
      case TYPE_HEADER.SECTOR:
        if (!isSector) {
          basicInfo = defaultStateSector.topInfo[locale || 'en']
          dispatch(resetDefaultSector(locale || 'en'))
        } else {
          dispatch(changeBasicInfoSector(basicInfo))
        }
        break
      case TYPE_HEADER.WARRANT:
        if (!isWarrant) {
          basicInfo = DEFAULT_WARRANT
          dispatch(resetDefaultWarrant())
        } else {
          dispatch(changeBasicInfoWarrant(basicInfo))
        }
        break
      case TYPE_HEADER.FUND:
        if (!isFund) {
          basicInfo = defaultStateFund.fundInfo[locale || 'en']
          dispatch(resetDefaultFund(locale || 'en'))
        } else {
          dispatch(changeBasicInfoFund(fundById[basicInfo.organizationId]))
        }
        break
      default:
        break
    }
    CurrentState.updateCurrentState({
      headerSearch: !basicInfo?.isDisableRedirect ? basicInfo : {},
    })
    EventEmitter.dispatch(UPDATE_CURRENT_STATE)
  }
}

export default useHeaderSearch
