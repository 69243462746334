import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { typeLegendYAxis } from '../../../constants'
import style from './style.module.css'
import Switch from './Switch'
import styles from './yAxisLegend.module.css'

const YAxisLegend = ({ checked, onChange, legendXAxis, changeLegendXAxis }) => {
  return (
    <div className="mb-8 mt-8">
      <div
        className="d-flex align-center justify-content-space-between"
        style={{ marginBottom: 4 }}
      >
        <Translate
          style={{ fontWeight: 500 }}
          value="financialChart.LEGEND_Y_AXIS"
        />
        <Switch checked={checked} onChange={onChange} />
      </div>
      <OptionLegend
        disable={!checked}
        legendXAxis={legendXAxis}
        changeLegendXAxis={changeLegendXAxis}
      />
    </div>
  )
}

export default YAxisLegend

const OptionLegend = ({ disable, legendXAxis, changeLegendXAxis }) => {
  const listPositionLegend = [
    {
      value: typeLegendYAxis.TYPE_1,
      content: (
        <div className={styles.option1}>
          <Span style={{ fontSize: 10 }}>DHG</Span>
          <Span style={{ fontSize: 10 }}>36.69</Span>
        </div>
      ),
    },
    {
      value: typeLegendYAxis.TYPE_2,
      content: (
        <div className={styles.option2}>
          <div className="align-center justify-content-center">
            <Span style={{ fontSize: 10 }}>DHG</Span>
          </div>
          <div className="align-center justify-content-center">
            <Span style={{ fontSize: 10 }}>36.69</Span>
          </div>
        </div>
      ),
    },
    {
      value: typeLegendYAxis.TYPE_3,
      content: (
        <div className={styles.option3}>
          <Span style={{ fontSize: 10 }}>DHG</Span>
        </div>
      ),
    },
    {
      value: typeLegendYAxis.TYPE_4,
      content: (
        <div className={styles.option4}>
          <Span style={{ fontSize: 10 }}>36.69</Span>
        </div>
      ),
    },
    {
      value: typeLegendYAxis.TYPE_5,
      content: (
        <div className={styles.option5}>
          <Span style={{ fontSize: 10 }}>36.69</Span>
        </div>
      ),
    },
  ]

  return (
    <>
      <ul
        className={`list-check ${style.radioCheckbox} ${styles.optionLegend} ${
          disable && style.disableSetting
        }`}
      >
        {listPositionLegend.map((item, index) => (
          <li key={index}>
            <label>
              <input
                type="radio"
                className={`radiobox ${style.radioBox}`}
                value={item.value}
                checked={legendXAxis === item.value}
                onChange={() => changeLegendXAxis(item.value)}
                readOnly
              />
              {item.content}
            </label>
          </li>
        ))}
      </ul>
    </>
  )
}
