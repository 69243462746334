import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { EMPTY_VALUE } from '../../../constants/Common'
import { convertNameSectorsByLevel } from '../../../market/marketInDepth/valuation/helper'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import useHeaderSearch, { TYPE_HEADER } from '../../hooks/useHeaderSearch'
import useWrapper from '../../hooks/useWrapper'
import useGetICB from '../../masterData/useGetICB'
import useGetIndices from '../../masterData/useGetIndices'
import useGetStockHOHAUP from '../../masterData/useGetStockHOHAUP'
import SearchBox from '../components/SearchBox'
import Title from '../components/Title'
import VerticalLine from '../components/VerticalLine'
import { DropdownItem } from './DropdownItem'
import { PriceData } from './PriceData'
import { transformToDescription } from './helper'
import { selectDisplayInfo, selectIndexSectorTickerInfo } from './store/slice'

export const IndexSectorTickerTopInfo = ({
  title,
  levelSector = [],
  onSelect,
}) => {
  const location = useLocation()
  const navigate = useCustomNavigate()

  const { ICBs } = useGetICB()
  const { indices } = useGetIndices()
  const { stocksHohaup } = useGetStockHOHAUP()

  const { width: wrapperWidth } = useWrapper()
  const displayInfo = useSelector(selectDisplayInfo)
  const indexSectorTickerInfo = useSelector(selectIndexSectorTickerInfo)

  const [listDataSearch, setListDataSearch] = useState({
    indices: [],
    sector: [],
    ticker: [],
  })

  useEffect(() => {
    setListDataSearch({
      sector: ICBs.map((item) => ({
        icbNameCustom: convertNameSectorsByLevel(item),
        ...item,
      })),
      ticker: stocksHohaup.map((item) => ({
        ...item,
        companyName: item.organizationShortName,
        exchange: item.exchangeCode,
      })),
      indices: indices,
    })
  }, [ICBs, stocksHohaup, indices])

  useHeaderSearch(TYPE_HEADER.INDEX_SECTOR_TICKER)

  const [keySearch, setKeySearch] = useState('')

  const handleFocusInputSearch = () => {
    setKeySearch('')
  }

  const handleSelect = (data, replace) => {
    if (data) {
      navigate(location.pathname, data, '', replace)
      onSelect && onSelect(data)
    }
  }

  const getSectorLevel = () => {
    let result = []
    if (levelSector.length) {
      levelSector.forEach((level) => {
        result = [
          ...result,
          ...listDataSearch.sector.filter((item) => item.icbLevel === level),
        ]
      })
      return result
    }
    return listDataSearch.sector
  }

  const handleSearch = () => {
    return [
      ...listDataSearch.indices,
      ...getSectorLevel(),
      ...listDataSearch.ticker,
    ]
      .filter(
        (item) =>
          item.groupName?.toLowerCase().includes(keySearch) ||
          item.icbNameCustom?.toLowerCase().includes(keySearch) ||
          item.ticker?.toLowerCase().includes(keySearch) ||
          item.companyName?.toLowerCase().includes(keySearch),
      )
      .sort(
        (a, b) =>
          a.ticker?.localeCompare(b.ticker) ||
          a.groupName?.localeCompare(b.groupName) ||
          a.icbNameCustom?.localeCompare(b.icbNameCustom),
      )
      .slice(0, 50)
  }

  const options = handleSearch().map((index) => ({
    id: index.groupId || index.organizationId || index.icbId,
    value: { ...index },
  }))

  const getTitle = () =>
    displayInfo
      ? indexSectorTickerInfo?.groupName ||
        indexSectorTickerInfo?.icbName ||
        indexSectorTickerInfo?.ticker
      : EMPTY_VALUE

  const getDescription = () =>
    displayInfo ? transformToDescription(indexSectorTickerInfo) : EMPTY_VALUE

  return (
    <div className="top-info" style={{ width: wrapperWidth || '100%' }}>
      {title && (
        <>
          <Title title={title} />
          <VerticalLine mr={12} />
        </>
      )}
      <SearchBox
        title={getTitle()}
        description={getDescription()}
        onFocus={handleFocusInputSearch}
        onTextChange={(e) => setKeySearch(e?.toLowerCase())}
        options={options}
        loading={false}
        onSelect={handleSelect}
        renderOption={(value, isCurrentItem) => (
          <DropdownItem value={value} isCurrentItem={isCurrentItem} />
        )}
      />
      <PriceData />
    </div>
  )
}

IndexSectorTickerTopInfo.propTypes = {
  title: PropTypes.string,
  levelSector: PropTypes.array,
  onSelect: PropTypes.func,
}

export default IndexSectorTickerTopInfo
