import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'

const initialState = {
  month: null,
  year: null,
}

export const slice = createSlice({
  name: 'economy/fdi/overview/keyIndicator',
  initialState,
  reducers: {
    changeFilterMonth: (state, action) => {
      state.month = action.payload
    },
    changeFilterYear: (state, action) => {
      state.year = action.payload
    },
  },
})

export const selectFilterMonth = (state) => state[slice.name].month
export const selectFilterYear = (state) => state[slice.name].year

export const { changeFilterMonth, changeFilterYear } = slice.actions

register(slice.name, slice.reducer)
