import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import ProductionService from '../../../../../../../core/services/economy/productionAndConsumption/ProductionService'

export const getGeneralProductionIndexName = createAsyncThunk(
  'economy/productionAndConsumption/production/generalIndex/getGeneralProductionIndexName',
  async (params, { rejectWithValue }) => {
    try {
      const res = await ProductionService.getGeneralProductionIndexName(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getGeneralProductionIndex = createAsyncThunk(
  'economy/productionAndConsumption/production/generalIndex/getGeneralProductionIndex',
  async (params, { rejectWithValue }) => {
    try {
      const res = await ProductionService.getGeneralProductionIndex(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
