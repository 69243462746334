import PropTypes from 'prop-types'
import LinkSector from '../../../../common/dropdownLink/LinkSector'
import TextEllipsis from '../../../../common/textEllipsis'

const TdSector = ({ value, colId, tableRef }) => {
  return (
    <>
      <div
        style={{
          width: tableRef.current?.offsetWidth < 500 ? '110px' : '100%',
        }}
      >
        <LinkSector icbId={colId}>
          <TextEllipsis isI18n={false} text={value} />
        </LinkSector>
      </div>
    </>
  )
}

TdSector.propTypes = {
  value: PropTypes.string.isRequired,
  colId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tableRef: PropTypes.object.isRequired,
}

export default TdSector
