import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { COM_GROUP } from '../../../../../constants/Group'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import {
  TOP_COMPANIES_TAB_BANK,
  TOP_COMPANIES_TAB_CORPORATE,
  TOP_COMPANIES_TAB_SECURITIES,
} from '../constants'
import { getTopPerformance } from './thunk'

const initialState = {
  loading: true,
  filter: {
    [COM_GROUP.BANK]: TOP_COMPANIES_TAB_BANK.PROFITABILITY,
    [COM_GROUP.SECURITIES]: TOP_COMPANIES_TAB_SECURITIES.PROFITABILITY,
    [COM_GROUP.CORPORATE]: TOP_COMPANIES_TAB_CORPORATE.PROFITABILITY,
  },

  ids: [],
  initialIds: [],
  valueById: {},
}

export const slice = createSlice({
  name: 'sector/sectorConstituents/overview/topCompanies',
  initialState,
  reducers: {
    changeTabFilter: (state, action) => {
      const payload = action.payload
      state.filter[payload.key] = payload.val
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.valueById,
        action.payload,
        state.initialIds,
        3,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopPerformance.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTopPerformance.fulfilled, (state, action) => {
      const dataPayload = action.payload
      state.ids = state.initialIds = dataPayload.map((e) => e.shortName)
      state.valueById = keyBy(dataPayload, 'shortName')
      state.loading = false
    })
    builder.addCase(getTopPerformance.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectFilter = (state) => state[slice.name].filter
export const selectLoading = (state) => state[slice.name].loading
export const selectCompaniesIds = (state) => state[slice.name].ids
export const selectTopCompaniesValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].valueById[id], attr)
}

export const { changeTabFilter, sort } = slice.actions

register(slice.name, slice.reducer)
