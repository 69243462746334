import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { FORMAT } from '../../../../src/components/utils/Datetime.js'
import { useFormatDateTime } from '../../common/hooks/useDateTime.js'
import { Input } from '../../common/html/Input.js'
import { Span } from '../../common/html/Span.js'
import { valByKeyWithDot } from '../../utils/Value'
import { ACCOUNT_TYPE, MAP_INFO_USER } from '../constants'
import { selectDataProfile } from '../slice'
import { getRequestRenewUpgradeAccount } from '../thunk.js'
import style from './index.module.css'

const Subscription = ({ widthInput }) => {
  const dispatch = useDispatch()

  const userInfo = useSelector(selectDataProfile)
  const inputValue = useFormatDateTime(
    valByKeyWithDot(userInfo, MAP_INFO_USER.expiryDate),
    FORMAT.DATE,
  )

  const convertAccountType = (typeAccount) => {
    if (typeAccount === ACCOUNT_TYPE.TRIAL_ACCOUNT) {
      return 'Trial Account'
    } else {
      return 'Paid Account'
    }
  }

  const handleUpgradeAccount = (type) => {
    dispatch(getRequestRenewUpgradeAccount({ type }))
  }

  return (
    <div className="box-tab">
      <h5>
        <Span>
          <Translate value="setting.settingProfile.MY_SUBSCRIPTION" />
        </Span>
      </h5>
      <form>
        <div className={['form-control', 'j-b', style.row].join(' ')}>
          <label className={style.label}>
            <Span>
              <Translate value="setting.settingProfile.ACCOUNT_TYPE" />
            </Span>
          </label>
          <Input
            style={{ fontSize: 11, width: widthInput }}
            type="text"
            className="bg-grey-2 h-20 disabled"
            value={convertAccountType(
              valByKeyWithDot(userInfo, MAP_INFO_USER.accountType),
            )}
            disabled
          />
        </div>
        <div className={['form-control', 'j-b', style.row].join(' ')}>
          <label className={style.label}>
            <Span>
              <Translate value="setting.settingProfile.EXPIRY_DATE" />
            </Span>
          </label>
          <Input
            style={{ fontSize: 11, width: widthInput }}
            type="text"
            className="bg-grey-2 h-20 disabled"
            value={inputValue}
            disabled
          />
        </div>
        <div className={['group-btn', style.row].join(' ')}>
          <a
            className="btn bg-grey-3 w-100px h-20"
            onClick={() => handleUpgradeAccount('Renew')}
            rel="noopener noreferrer"
          >
            <Span>
              <Translate value="setting.settingProfile.BUTTON_RENEW" />
            </Span>
          </a>
          <a
            className="btn bg-yellow w-100px h-20"
            onClick={() => handleUpgradeAccount('Upgrade')}
            rel="noopener noreferrer"
          >
            <Span>
              <Translate value="setting.settingProfile.BUTTON_UPGRADE" />
            </Span>
          </a>
        </div>
      </form>
    </div>
  )
}

export default Subscription
