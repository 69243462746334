import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { TIME_FRAME_OWNERSHIP } from './constants'
import { changeTimeFrame, selectTimeFrame } from './store/slice'

const TimeFrame = () => {
  const dispatch = useDispatch()
  const timeFrame = useSelector(selectTimeFrame)

  const handleChangeTimeFrame = ({ value }) => {
    dispatch(changeTimeFrame(value))
  }

  return (
    <div className="d-flex justify-content-end">
      <DispatchActionTab
        data={Object.keys(TIME_FRAME_OWNERSHIP).map((key) => {
          return {
            title: 'market.marketInDepth.foreign.' + TIME_FRAME_OWNERSHIP[key],
            value: TIME_FRAME_OWNERSHIP[key],
          }
        })}
        activeTab={timeFrame}
        onChangeTab={handleChangeTimeFrame}
      />
    </div>
  )
}

export default TimeFrame
