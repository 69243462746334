import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { EXCHANGE_CODE } from '../../constant'
import { getOrgIdsByIcb, getTickerByWLId, getWatchList } from './thunk'

const initialState = {
  tickers: [],
  selectedExchange: EXCHANGE_CODE.ALL_EXCHANGE,
  listSectorChecked: [],
  listParentChecked: [],
  watchlist: [],
  selectedWL: null,
  orgIds: [],
  loading: true,
  isShowChart: true,
  firstMountPopup: true,
}

const slice = createSlice({
  name: 'corporate/peerValuation/overview/comparisonHeader',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeSelectedExchange: (state, action) => {
      state.selectedExchange = action.payload
    },
    changeSectorChecked: (state, action) => {
      state.listSectorChecked = action.payload
    },
    changeListParentChecked: (state, action) => {
      state.listParentChecked = action.payload
    },
    changeSelectedWL: (state, action) => {
      state.selectedWL = action.payload
    },
    changeOrgIds: (state, action) => {
      state.orgIds = action.payload
    },
    addOrgIds: (state, action) => {
      const newOrgIds = [...state.orgIds]
      action.payload.forEach((id) => {
        if (!newOrgIds.includes(id)) {
          newOrgIds.push(id)
        }
      })
      state.orgIds = newOrgIds
    },
    changeShowChart: (state, action) => {
      state.isShowChart = action.payload
    },
    changeFirstMountPopup: (state, action) => {
      state.firstMountPopup = action.payload
    },
  },
  extraReducers: (builder) => {
    //GetOrgIdsByIcb
    builder.addCase(getOrgIdsByIcb.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getOrgIdsByIcb.fulfilled, (state, action) => {
      state.loading = false
      const data = action.payload || []
      state.tickers = [
        ...data
          .filter((item) => !!item.ticker)
          .sort((a, b) => a.ticker?.localeCompare(b.ticker)),
        ...data
          .filter((item) => !item.ticker)
          .sort((a, b) => a.taxCode?.localeCompare(b.taxCode)),
      ]
    })
    builder.addCase(getOrgIdsByIcb.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    //GetWatchList
    builder.addCase(getWatchList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getWatchList.fulfilled, (state, action) => {
      state.loading = false
      state.watchlist = action.payload || []
    })
    builder.addCase(getWatchList.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    //GetTickerByWatchList
    builder.addCase(getTickerByWLId.fulfilled, (state, action) => {
      const currentOrgId = action.meta.arg.currentOrgId
      const newOrgIds =
        action.payload?.details?.map((ticker) => ticker.organizationId) || []
      if (!newOrgIds.includes(currentOrgId)) {
        newOrgIds.push(currentOrgId)
      }

      state.orgIds = newOrgIds
      state.selectedWL = action.payload?.watchListId || null
    })
  },
})

export const selectOrgIds = (state) => state[slice.name].orgIds
export const selectTickers = (state) => state[slice.name].tickers
export const selectWatchlist = (state) => state[slice.name].watchlist
export const selectSelectedWL = (state) => state[slice.name].selectedWL
export const selectLoading = (state) => state[slice.name].loading
export const selectSelectedExchange = (state) =>
  state[slice.name].selectedExchange
export const selectListSectorChecked = (state) =>
  state[slice.name].listSectorChecked
export const selectListParentChecked = (state) =>
  state[slice.name].listParentChecked
export const selectFirstMountPopup = (state) =>
  state[slice.name].firstMountPopup

export const selectIsShowChart = (state) => state[slice.name].isShowChart

export const {
  resetStore,
  changeSelectedExchange,
  changeSectorChecked,
  changeListParentChecked,
  changeSelectedWL,
  addOrgIds,
  changeOrgIds,
  changeShowChart,
  changeFirstMountPopup,
} = slice.actions

register(slice.name, slice.reducer)
