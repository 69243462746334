export const event = {
  COMPANY: 'Company',
  EXCHANGE: 'Exchange',
  SECTOR: 'Sector',
  ALL_SECTOR: 'All sectors',
  All_EXCHANGE: 'All exchanges',
  ALL_COMPANY: 'All companies',
  ALL_EVENT_TYPE: 'All event types',
  ANNOUNCEMENT_DATE: 'Announcement Date',
  RECORD_DATE: 'Record Date',
  EXRIGHT_DATE: 'Ex-right Date',
  EXCERCISE_DATE: 'Exercise Date',
  FILTER: 'FILTER',
  EVENT_TYPES: 'Event types',
  SHOW_ALL: 'Show All',
  EVENT: 'EVENT',
  WATCHLIST: 'Watchlist',
  NO_WATCHLIST: 'No watchlist',
  GO_TO: 'Go to',
  CREATE_WATCHLIST: 'Create Watchlist',
  SELECT_WL_PLH: 'Choose a watchlist',
  SEARCH_PLH: 'Search',
  doubleClickText: '(click to view detail)',
  TABLE_NOTE: 'Volume: x1,000',
  TOTAL_RESULT: 'Total results : %{totalRecords} results',
  ATTACHMENT: 'Attached file: ',
  MILLION_VND: 'Mil. VND',
  indicators: {
    date: 'AN. DATE',
    ticker: 'TICKER',
    event: 'EVENT',
    description: 'DESCRIPTION',
    exrightDate: 'EXRIGHT DATE',
    recordDate: 'RECORD DATE',
    payoutDate: 'PAYOUT DATE',
    issueDate: 'ISSUE DATE',
    valuePerShare: 'VALUE PER SHARE',
    exerciseRate: 'EXERCISE RATE',
    dividendYear: 'DIVIDEND YEAR',
    duration: 'DURATION',
    source: 'SOURCE',
    period: 'PERIOD',
    netRevenue: 'NET REVENUE (Bil. VND)',
    revenueGrowth: 'REVENUE GROWTH',
    netProfit: 'NET PROFIT (Bil. VND)',
    profitGrowth: 'PROFIT GROWTH',
    listingDate: 'LISTING DATE',
    method: 'METHOD',
    issueVolume: 'ISSUE VOLUME',
    totalValue: 'ISSUE VALUE',
    year: 'YEAR',
    status: 'STATUS',
    address: 'ADDRESS',
    meetingDate: 'MEETING DATE',
    symbol: 'SYMBOL',
    growth: 'GROWTH',
    type: 'TYPE',
    futureName: 'FUTURE NAME',
    owner: 'OWNER',
    position: 'POSITION',
    ownedPercentage: 'OWNERSHIP AFTER TRADING',
    exerciseDate: 'EXERCISE DATE',
    exerciseRatio: 'EXERCISE RATIO',
    city: 'CITY',
    time: 'EXPECTED TIME',
    organizationName: 'ORGAN NAME',
    roleOwner: 'ROLE FULLNAME',
    shareBeforeTrade: 'VOL. BEFORE',
    shareRegister: 'VOL. REGISTER',
    actionTypeName: 'ACTION TYPE',
    shareAcquire: 'VOL. EXECUTED',
    shareAfterTrade: 'VOL. AFER',
    ownershipAfterTrade: 'OWNERSHIP AFTER TRADING',
    relative: 'ROLE NAME',
    endDateId: 'END DATE',
    oldExchange: 'OLD EXCHANGE',
    newExchange: 'NEW EXCHANGE',
    startDateId: 'START DATE',
  },
  hyperlink: {
    COMPANY_OVERVIEW: 'Company Overview',
    COMPANY_NEWS: 'Company News',
    FINANCIAL_DATA: 'Financial Data',
    BUSINESS_MODAL: 'Business Model',
    SECTOR_OVERVIEW: 'Sector Overview',
    TECHNICAL_CHART: 'Technical Chart',
  },
  cashDividendForm: {
    TITLE:
      '%{ticker} -Pays %{durationName}, %{sourceDividendYear}  cash dividend at VND %{valuePerShare}/share ',
    HEADER:
      '%{organizationName} pays %{durationName}, %{sourceDividendYear} Cash Dividend: ',
    AN_DATE: 'An. Date : ',
    EXRIGHT_DATE: 'Ex-right date : ',
    RECORD_DATE: 'Record Date : ',
    PAYOUT_DATE: 'Payout Date : ',
    EXERCISE_RATIO:
      'Exercise ratio : %{exerciseRatio}/parvalue (VND %{valuePerShare}/Share)',
  },
  shareIssueForm: {
    TITLE: '%{ticker} - List %{planVolumn} additional shares',
    HEADER: '%{organizationName} list addition shares : ',
    ISSUE_METHOD: 'Issue method : ',
    NUMBER_SHARES_PLANNED: 'Number of additional shares planned : ',
    ISSUE_PRICE: 'Issue Price : VND %{issuePrice} /Share',
    EXERCISE_RATIO: 'Exercise Ratio : ',
    ISSUE_VOLUME: 'Issue volume : ',
    TOTAL_VALUE: 'Issue value : ',
    STATUS: 'Status : ',
    EXRIGHT_DATE: 'Ex-right Date : ',
    ISSUE_DATE: 'Issue Date : ',
    LISTING_DATE: 'Listing Date : ',
  },
  directorDealForm: {
    AN_DATE: 'An. Date : ',
    START_DATE: 'Start Date : ',
    END_DATE: 'End Date : ',
    VOL_BEFORE: 'Vol.Before : ',
    VOL_REGISTER: 'Vol. Register : ',
    ACTION_TYPE: 'Action Type : ',
    VOL_EXECUTED: 'Vol. Executed : ',
    VOL_AFTER: 'Vol. After : ',
    OWNERSHIP_AFTER_TRADING: 'Ownership After Trading : ',
    STATUS: 'Status : ',
    POSITION_NAME: 'Position Name : ',
    RELATED_PERSON: 'Related Person : ',
    RELATIVE: 'Relative : ',
    EVENT_TYPE: 'Event Type : ',
  },
}
