import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import UseI18n from '../../../common/hooks/useI18n'
import { Input } from '../../../common/html/Input'
import { Loading } from '../../../common/loading'
import PopupEvent from '../../../common/popup/PopupEvent'
import { SizeTracker } from '../../../common/sizeTracker'
import Tree from '../../../common/tree'
import { getAllChildrenItem } from '../../../common/tree/helper'
import style from '../index.module.css'
import { addArrayCondition, addCondition } from '../store/slice'

const checkIsCanAddArrayItem = (item) => {
  if (!item.children) return false
  return !Object.keys(item.children).some(
    (keyChild) => Object.keys(item.children[keyChild].children).length > 0,
  )
}

const Indicator = ({
  ratioIndicatorsTree,
  loadingRatio,
  financialStatementIndicatorsTree,
  loadingFinancialStatement,
}) => {
  const dispatch = useDispatch()

  const [valueInput, setValueInput] = useState('')
  const [indicatorTree, setIndicatorTree] = useState({})
  const [isShowPopupNotice, setIsShowPopupNotice] = useState(false)
  const [indicatorAdded, setIndicatorAdded] = useState({})

  const ratioName = UseI18n('tool.smartScreening.RATIO')
  const financialStatementName = UseI18n('tool.smartScreening.FINANCIAL')

  useEffect(() => {
    if (!loadingRatio && !loadingFinancialStatement) {
      setIndicatorTree({
        ratio: {
          orderNumber: 1,
          indicatorName: ratioName,
          children: ratioIndicatorsTree,
        },
        financialStatement: {
          orderNumber: 2,
          indicatorName: financialStatementName,
          children: financialStatementIndicatorsTree,
        },
      })
    }
  }, [
    ratioIndicatorsTree,
    financialStatementIndicatorsTree,
    loadingRatio,
    loadingFinancialStatement,
  ])

  const handleChangeInput = (e) => setValueInput(e.target.value)

  const handleDoubleClickItem = (item) => {
    if (Object.keys(item.children).length === 0) {
      dispatch(addCondition({ ...item, children: undefined }))
    } else if (checkIsCanAddArrayItem(item)) {
      setIndicatorAdded(item)
      setIsShowPopupNotice(true)
    }
  }

  const submitAddCondition = () => {
    handleClosePopup()
    addConditions(indicatorAdded)
  }

  const handleClosePopup = () => setIsShowPopupNotice(false)

  const addConditions = (indicator) => {
    dispatch(
      addArrayCondition(
        getAllChildrenItem(indicator).map((item) => {
          return { ...item, children: undefined }
        }),
      ),
    )
  }

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="form-search-inbox mb-10">
                <Input
                  type="text"
                  className={`search-inbox bg-grey h-20 ${style.search}`}
                  placeholder={I18n.t('tool.smartScreening.SEARCH')}
                  value={valueInput}
                  onChange={handleChangeInput}
                />
                <button className={style.btnSearch} type="button">
                  <i className="icon-search-2" />
                </button>
              </div>
              {size.height && (
                <div
                  className="box_indicator"
                  style={{ height: `calc(100% - ${size.height}px` }}
                >
                  {!Object.keys(ratioIndicatorsTree)?.length ||
                  !Object.keys(financialStatementIndicatorsTree)?.length ? (
                    <div className={`${style.relative} h-100`}>
                      <Loading />
                    </div>
                  ) : (
                    <Tree
                      valueSearch={valueInput}
                      treeData={indicatorTree}
                      nameKey="indicatorName"
                      doubleClickItem={handleDoubleClickItem}
                      isSearchAbsolute={false}
                      sortKey="orderNumber"
                      defaultOpenLevel1={true}
                    />
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
      <PopupEvent
        title={<Translate value="tool.smartScreening.NOTICE" />}
        message={
          <div className={style.textJustify}>
            <Translate value="tool.smartScreening.CONTENT_NOTICE" />
          </div>
        }
        isShow={isShowPopupNotice}
        handleClose={handleClosePopup}
        btnCancel={{
          btnName: <Translate value="common.button.BUTTON_NO" />,
          event: handleClosePopup,
        }}
        btnSubmit={{
          btnName: <Translate value="common.button.BUTTON_YES" />,
          event: submitAddCondition,
        }}
      />
    </>
  )
}

Indicator.propTypes = {
  ratioIndicatorsTree: PropTypes.object.isRequired,
  loadingRatio: PropTypes.bool.isRequired,
  financialStatementIndicatorsTree: PropTypes.object.isRequired,
  loadingFinancialStatement: PropTypes.bool.isRequired,
}

export default Indicator
