import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { ThCellWithIconInfo } from '../../../../common/table/ThCellWithIConInfo'
import { Z_INDEX } from '../../../../constants/Common'
import { sortStateMap } from '../constant'
import Sort from './Sort'
import style from './index.module.css'

const TableHeader = ({ tableHeaderColAttr, stateSort, sortColumn }) => {
  const renderTitle = (text) => {
    return (
      <Span className={style.theadContent} style={{ fontSize: 10 }}>
        <Translate value={text} />
      </Span>
    )
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      {tableHeaderColAttr.map((row, index) => {
        return (
          <tr key={index}>
            {row.map((col) => {
              return (
                <th
                  onClick={
                    col.disableSort
                      ? undefined
                      : () =>
                          sortColumn({
                            [col.keySort]: sortStateMap[stateSort[col.keySort]],
                          })
                  }
                  key={col.name}
                  {...col?.attrs}
                  className={`${!col.value?.length && style.borderBottomNone} ${
                    !col.additionText && style.verticalAlignInitial
                  }`}
                >
                  <div
                    className={`d-flex ${
                      col.justifyContent || 'justify-content-end'
                    }`}
                  >
                    <div
                      className={stateSort[col?.keySort] ? 'mr-5' : undefined}
                    >
                      {col.value.map((text, i) => (
                        <React.Fragment key={i}>
                          {col.info && (
                            <ThCellWithIconInfo
                              isI18n={true}
                              tooltipCustom={col.info}
                            >
                              {renderTitle(text)}
                            </ThCellWithIconInfo>
                          )}
                          {!col.info && renderTitle(text)}
                        </React.Fragment>
                      ))}
                      {col.additionText && (
                        <Span
                          className={style.additionText}
                          style={{ fontSize: 10 }}
                        >
                          <Translate value={col.additionText} />
                        </Span>
                      )}
                    </div>
                    {!col?.disableSort && !col?.hideIconSort && (
                      <Sort type={stateSort[col.keySort]} />
                    )}
                  </div>
                </th>
              )
            })}
          </tr>
        )
      })}
    </thead>
  )
}

export default TableHeader
