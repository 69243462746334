import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class FinancialDataProxy extends ProxyBase {
  getFinancialDataBalanceSheet(params) {
    return this.get('GetFinancialDataBalanceSheet', params)
  }

  getFinancialDataIncomeStatement(params) {
    return this.get('GetFinancialDataIncomeStatement', params)
  }

  getFinancialDataCashFlow(params) {
    return this.get('GetFinancialDataCashFlow', params)
  }

  getFinancialDataNote(params) {
    return this.get('GetFinancialDataNote', params)
  }

  getFinancialDataRatio(params) {
    return this.get('GetFinancialDataRatio', params)
  }

  getFinancialDataDividendChart(params) {
    return this.get('GetFinancialDataDividendChart', params)
  }

  getFinancialDataDividendTable(params) {
    return this.get('GetFinancialDataDividendTable', params)
  }

  getIndicatorMapping(params) {
    return this.get('GetIndicatorMapping', params)
  }

  downloadFinancialData(params) {
    return this.get('DownloadFinancialData', params, 'download')
  }

  downloadRatio(params) {
    return this.get('DownloadRatio', params, 'download')
  }

  downloadDividendHistory(params) {
    return this.get('DownloadDividendHistory', params, 'download')
  }

  getBalanceSheetStructureChartData(params) {
    return this.get('GetBalanceSheetStructureChartData', params)
  }

  getDGAndCGChartData(params) {
    return this.get('GetDGAndCGChartData', params)
  }

  getTOIStructureChartData(params) {
    return this.get('GetTOIStructureChartData', params)
  }

  getProfitAndLossChartData(params) {
    return this.get('GetProfitAndLossChartData', params)
  }

  getCashFlowStructureChartData(params) {
    return this.get('GetCashFlowStructureChartData', params)
  }

  getOperatingCashFlowChartData(params) {
    return this.get('GetOperatingCashFlowChartData', params)
  }

  getLoansByIndustryChartData(params) {
    return this.get('GetLoansByIndustryChartData', params)
  }

  getLoansByTermChartData(params) {
    return this.get('GetLoansByTermChartData', params)
  }

  getFinancialRatioChartData(params) {
    return this.get('GetFinancialRatioChartData', params)
  }

  getPeersComparisonChartData(params) {
    return this.get('GetPeersComparisonChartData', params)
  }

  getAssetBreakdownChartData(params) {
    return this.get('GetAssetBreakdownChartData', params)
  }

  getOperatingSalesStructureChartData(params) {
    return this.get('GetOperatingSalesStructureChartData', params)
  }

  getOperatingExpensesStructureChartData(params) {
    return this.get('GetOperatingExpensesStructureChartData', params)
  }

  getMarginLendingChartData(params) {
    return this.get('GetMarginLendingChartData', params)
  }

  getFinancialAssetsStructureChartData(params) {
    return this.get('GetFinancialAssetsStructureChartData', params)
  }

  getSTAndLTBorrowingChartData(params) {
    return this.get('GetSTAndLTBorrowingChartData', params)
  }

  getRevenueAndProfitChartData(params) {
    return this.get('GetRevenueAndProfitChartData', params)
  }

  getProfitabilityChartData(params) {
    return this.get('GetProfitabilityChartData', params)
  }

  getInventoryStructureChartData(params) {
    return this.get('GetInventoryStructureChartData', params)
  }

  getFinancialExpensesChartData(params) {
    return this.get('GetFinancialExpensesChartData', params)
  }

  getReservesChartData(params) {
    return this.get('GetReservesChartData', params)
  }

  getTICSEChartData(params) {
    return this.get('GetTICSEChartData', params)
  }

  getInsurancePremiumBreakdownChartData(params) {
    return this.get('GetInsurancePremiumBreakdownChartData', params)
  }

  getFinancialIncomeChartData(params) {
    return this.get('GetFinancialIncomeChartData', params)
  }

  getPriceEarningRatioDividendYieldChartData(params) {
    return this.get('GetPriceEarningRatioDividendYieldChartData', params)
  }

  getEarningsAgainstDividendsChartData(params) {
    return this.get('GetEarningsAgainstDividendsChartData', params)
  }
}

export default new FinancialDataProxy(ServiceProxyConfig.Corporate.ServiceUrl)
