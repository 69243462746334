import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { SearchCollapseBlock } from '../SearchCollapseBlock'
import { selectSearchBond } from '../store/slice'
import { getSearchBondData } from '../store/thunk'
import { BondOverviewItem } from './BondOverviewItem'

export const BondOverview = (props) => {
  const { searchStr, open, onClickCollapse, getBasicInfo } = props
  const dispatch = useDispatch()
  const bonds = useSelector(selectSearchBond)

  const onClickPrevPage = () => {
    if (bonds.data.page === 1) {
      return
    } else {
      dispatch(
        getSearchBondData({
          Term: searchStr,
          Page: bonds.data.page - 1,
          PageSize: bonds.data.pageSize,
        }),
      )
    }
  }
  const onClickNextPage = () => {
    if (bonds.data.page === bonds.data.totalPage) {
      return
    } else {
      dispatch(
        getSearchBondData({
          Term: searchStr,
          Page: bonds.data.page + 1,
          PageSize: bonds.data.pageSize,
        }),
      )
    }
  }

  return (
    <SearchCollapseBlock
      id="bond-over"
      title={`${UseI18n('search.fullSearch.BO_BOND')} (${
        bonds.data.totalRecords
      })`}
      blockData={bonds}
      open={open}
      onClickCollapse={onClickCollapse}
      onClickNextPage={onClickNextPage}
      onClickPrevPage={onClickPrevPage}
    >
      {!!bonds.data.items.length &&
        bonds.data.items.map((item) => (
          <BondOverviewItem
            key={item.bondId}
            searchStr={searchStr}
            data={item}
            getBasicInfo={getBasicInfo}
          />
        ))}
    </SearchCollapseBlock>
  )
}

BondOverview.propTypes = {
  searchStr: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClickCollapse: PropTypes.func.isRequired,
}
