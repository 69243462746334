import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab.js'
import { LIQUIDITY_CHART_TABS } from '../constants.js'
import style from '../index.module.css'
import { selectidsCompany } from '../tradingStatistics/store/slice'
import {
  changeFilterLiquidity,
  selectFilterLiquidity,
  selectFilterPriceBoard,
} from '../tradingStatistics/store/slice.js'
import { getLiquidityData } from '../tradingStatistics/store/thunk.js'

const Filter = () => {
  const dispatch = useDispatch()
  const activeTab = useSelector(selectFilterLiquidity)
  const filter = useSelector(selectFilterPriceBoard)
  const OrganizationIds = useSelector(selectidsCompany)
  const { tradingStatusId, issueMethodId, icbIds } = filter
  const locale = useSelector((state) => state.i18n.locale)

  const handleChangeActiveTab = (item) => {
    dispatch(
      changeFilterLiquidity({
        value: item.value,
      }),
    )
  }

  useEffect(() => {
    const payload = {
      TimeFrequency: activeTab,
      TradingStatusIds: tradingStatusId.toString(),
      IssueMethodIds: issueMethodId.toString(),
      IcbIds: icbIds.toString(),
      OrganizationIds: OrganizationIds,
    }

    dispatch(getLiquidityData(payload))
  }, [
    locale,
    activeTab,
    tradingStatusId.length,
    issueMethodId.length,
    icbIds.length,
    OrganizationIds,
  ])

  return (
    <div className="d-flex mb-8 justify-content-end">
      <div className={`${style.menuContainer}`}>
        <DispatchActionTab
          data={LIQUIDITY_CHART_TABS}
          activeTab={activeTab}
          onChangeTab={handleChangeActiveTab}
          containerStyle={{ width: '100%' }}
          itemStyle={{
            width: '50%',
            fontWeight: 600,
          }}
        />
      </div>
    </div>
  )
}

export default Filter
