import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PriceStatisticService from '../../../../../../../core/services/market/equityTrading/PriceStatisticService'
import { handleExport } from '../../../../../../utils/Export'

export const getOrderStatistic = createAsyncThunk(
  'market/equityTrading/priceStatistics/summary/orderStatistic/GET_ORDER_STATISTIC',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PriceStatisticService.getOrderStatistic(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadOrderStatistic = createAsyncThunk(
  'market/equityTrading/priceStatistics/summary/orderStatistic/DOWNLOAD_ORDER_STATISTIC',
  async (data) => {
    const response = await PriceStatisticService.downloadOrderStatistic(data)
    handleExport(response.data, response.filename)
  },
)
