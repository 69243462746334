import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { screens } from '../../../constants/Common'
import { throttle } from '../../../utils/Common'
import Dropdown from '../../dropdown'
import { HandleClickOutside } from '../../HandleClickOutside'
import UseI18n from '../../hooks/useI18n'
import { Input } from '../../html/Input'
import { Span } from '../../html/Span'
import { ScrollComponent } from '../../ScrollComponent'
import useSearchCompany from '../../simpleSearch/useSearchCompany'
import style from './index.module.css'

const PageSize = 100

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

const CompanyItemScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#search-ticker .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}

CompanyItemScroll.propTypes = {
  isActive: PropTypes.bool.isRequired,
}

const DropdownTicker = ({
  isShow,
  parentRef,
  handleHide,
  inputRef,
  companies,
  eventPressKey,
  exceptOusideRef,
  handleAddTicker,
  loading,
  isThemeWhite,
}) => {
  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    if (!loading) {
      const lengthCompanies = companies.length
      if (eventPressKey?.keyCode === keyCode.ENTER) {
        if (activeIndex || activeIndex === 0)
          handleClickItem(companies[activeIndex])
        else if (companies[0]) handleClickItem(companies[0])
      }
      if (
        eventPressKey?.keyCode === keyCode.DOWN_ARROW &&
        lengthCompanies > 0
      ) {
        if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
        else if (activeIndex < lengthCompanies - 1)
          setActiveIndex(activeIndex + 1)
      }
      if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthCompanies > 0) {
        if (!activeIndex && activeIndex !== 0)
          setActiveIndex(lengthCompanies - 1)
        else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
      }
    }
  }, [eventPressKey])

  useEffect(() => {
    setActiveIndex(null)
  }, [companies])

  const handleClickItem = throttle((ticker) => {
    inputRef.current.focus()
    handleAddTicker([ticker])
  }, 250)

  return (
    <Dropdown isShow={isShow} parentRef={parentRef}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOusideRef}
      >
        <div
          onClick={() => inputRef.current.focus()}
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={`${style.dropdownFilter} ${
            isThemeWhite && style.dropdownFilterThemeWhite
          }`}
          id="search-ticker"
        >
          {companies.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="watchlist.watchlistDetail.NO_RESULT" />
              </Span>
            </div>
          ) : (
            <ScrollComponent>
              {companies.map((ticker, index) => {
                return (
                  <div
                    key={ticker.organizationId}
                    className={`${style.contentFilter} ${
                      isThemeWhite && style.itemThemeWhite
                    } ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onClick={() => handleClickItem(ticker)}
                  >
                    <CompanyItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter} ${style.pointer}`}>
                      <div
                        className={style.textEllipsis}
                        style={{ opacity: isThemeWhite ? 1 : 0.5 }}
                      >
                        {ticker.tickerOrTaxCode}
                      </div>
                      <div
                        className={style.textEllipsis}
                        style={{ opacity: isThemeWhite ? 1 : 0.5 }}
                      >
                        {ticker.organizationShortName}
                      </div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

DropdownTicker.propTypes = {
  isShow: PropTypes.bool.isRequired,
  parentRef: PropTypes.object.isRequired,
  handleHide: PropTypes.func.isRequired,
  inputRef: PropTypes.object.isRequired,
  exceptOusideRef: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  eventPressKey: PropTypes.object,
  setValueSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

const SearchTicker = ({
  isHideTitle,
  inputStyle,
  handleAddTicker,
  isThemeWhite,
}) => {
  const inputRef = useRef()
  const exceptOusideRef = useRef()

  const [searchParams, setSearchParams] = useState()
  const [valueSearch, setValueSearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [eventPressKey, setEventPressKey] = useState()
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)

  const { companies, loading } = useSearchCompany(
    screens.DE_CORPORATE,
    searchParams,
  )

  useEffect(() => {
    if (valueSearch.trim())
      setSearchParams({
        Term: valueSearch.trim(),
        Page: 0,
        PageSize: PageSize,
      })
    else
      setSearchParams({
        Page: 0,
        PageSize: PageSize,
      })
  }, [valueSearch])

  useEffect(() => {
    if (!loading) {
      setIsLoadingSearch(false)
    }
  }, [loading])

  const handleChangeInput = (e) => {
    setIsLoadingSearch(true)
    setValueSearch(e.target.value)
  }

  const handleHide = () => setIsShowDropdown(false)

  const handleShow = () => setIsShowDropdown(true)

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  const search = UseI18n('tool.dataExplorer.SEARCH')

  return (
    <div
      className={
        !isHideTitle ? `d-flex ali-center ${style.searchTickerContainer}` : ''
      }
    >
      <div className="w-100">
        {!isHideTitle && (
          <Span className={style.titleSearch} style={{ fontSize: 10 }}>
            <Translate value="tool.dataExplorer.TICKER" />
          </Span>
        )}
        <div
          ref={exceptOusideRef}
          className={`form-search-inbox ${!isHideTitle ? style.search : ''}`}
        >
          <Input
            type="text"
            style={{ height: 20, ...inputStyle }}
            className={`search-inbox  ${
              isThemeWhite ? style.inputThemeWhite : 'bg-grey'
            }`}
            placeholder={search}
            ref={inputRef}
            onChange={handleChangeInput}
            onFocus={handleShow}
            onKeyDown={handlePressKey}
          />
          <button type="button">
            <i className="icon-search-2" />
          </button>
        </div>
        <DropdownTicker
          isShow={isShowDropdown}
          parentRef={inputRef}
          handleHide={handleHide}
          inputRef={inputRef}
          exceptOusideRef={exceptOusideRef}
          companies={companies}
          eventPressKey={eventPressKey}
          setValueSearch={setValueSearch}
          handleAddTicker={handleAddTicker}
          loading={isLoadingSearch}
          isThemeWhite={isThemeWhite}
        />
      </div>
    </div>
  )
}

export default SearchTicker
