import { useSelector } from 'react-redux'
import { FinancialRatioTable } from '.'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { selectFinancialRatioFilter } from '../store/slice'
import { downloadRatio } from '../store/thunk'

export const PanelTable = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const filter = useSelector(selectFinancialRatioFilter)

  // Actions
  const downloadCsv = () => {
    const params = {
      OrganizationId: basicInfo.organizationId,
      TimeFilter: filter.viewBy.value,
      NumberOfPeriod: filter.numberOfPeriod.value,
      LatestYear: filter.lastestYear.value,
      Consolidated: filter.type.value,
    }

    return downloadRatio(params)
  }

  return (
    <Panel
      title="corporate.financialData.financialRatio.FINANCIAL_RATIOS"
      panelRefs={panelRefs}
      panelKey={mapKey.FINANCIAL_TABLE}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={downloadCsv}
    >
      <FinancialRatioTable />
    </Panel>
  )
}
