import { useDispatch } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Input } from '../../common/html/Input'
import { debounce } from '../../utils/Common'
import style from '../index.module.css'
import { changeTickerTableFilter } from '../store/slice'

const THeadFilter = () => {
  const dispatch = useDispatch()

  const handleChangeInput = (e) => {
    handleFilter(e.target.value)
  }

  const handleFilter = debounce((value) => {
    dispatch(changeTickerTableFilter(value))
  }, 300)

  return (
    <div className="d-flex j-b ali-center">
      <Translate value="watchlist.watchlistDetail.indices.CODE" />
      <div className="form-search-inbox m-0">
        <Input
          onChange={handleChangeInput}
          type="text"
          className={`search-inbox ${style.inputSearchTicker}`}
          placeholder={I18n.t('watchlist.watchlistDetail.SEARCH_PLH')}
        />
        <button type="button">
          <i className="icon-search-2" />
        </button>
      </div>
    </div>
  )
}

export default THeadFilter
