import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataExplorerService from '../../../../../core/services/tool/dataExplorer/DataExplorerService'
import SettingEconomy from '../../../../common/dataExplorer/popupIndicator/SettingEconomy'
import {
  addConditionTypeEconomy,
  cancelSetting,
  changeCondition,
  handlePopupSetting,
  selectActiveSheet,
  selectCondition,
  selectConditionTypeEconomy,
  selectIsOpenSetting,
} from '../store/slice'

const PopupIndicator = () => {
  const dispatch = useDispatch()

  const activeSheet = useSelector(selectActiveSheet)
  const isOpenSetting = useSelector(selectIsOpenSetting)
  const condition = useSelector(selectCondition(activeSheet))
  const conditionTypeEconomy = useSelector(selectConditionTypeEconomy)

  const [mostRecentDate, setMostRecentDate] = useState(null)

  useEffect(() => {
    const asyncFn = async () => {
      const response = await DataExplorerService.getMostRecentDate({
        ConditionType: 'Daily',
      })
      if (response.success) {
        setMostRecentDate(response.data)
      }
    }
    asyncFn()
  }, [])

  const handleCancel = () => {
    if (!condition) {
      dispatch(cancelSetting())
    }
    dispatch(handlePopupSetting())
    dispatch(addConditionTypeEconomy(null))
  }

  const handleSubmit = (data) => {
    dispatch(changeCondition(data))
    dispatch(handlePopupSetting())
    dispatch(addConditionTypeEconomy(null))
  }

  const renderPopup = () => {
    if (isOpenSetting) {
      const { minDate, maxDate } = mostRecentDate
      const defaultProps = {
        handleSubmit,
        handleCancel,
        minDate: minDate,
        maxDate: maxDate,
        defaultDetail: condition
          ? { detail: condition }
          : { detail: { period: conditionTypeEconomy } },
      }
      return <SettingEconomy {...defaultProps} />
    }
  }

  return <>{renderPopup()}</>
}

export default PopupIndicator
