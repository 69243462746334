import PropTypes from 'prop-types'
import { Span } from '../../../html/Span'
import style from './index.module.css'

export const Tag = ({ text }) => {
  if (!text) {
    return null
  }

  return (
    <Span className={style.tag} style={{ fontSize: 10 }}>
      {text}
    </Span>
  )
}

Tag.propTypes = {
  text: PropTypes.string,
}
