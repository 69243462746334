import { useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import {
  selectIndicatorInfoById,
  selectPlotType,
  selectXIndicator,
  selectYIndicator,
} from '../store/slice'
import { getTooltipContent } from './helper'

const ChartTooltip = ({
  data,
  yAxisDecimalLength,
  xAxisDecimalLength,
  xLabel,
  yLabel,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const plotType = useSelector(selectPlotType)
  const xIndicatorId = useSelector(selectXIndicator)
  const yIndicatorId = useSelector(selectYIndicator)
  const xPeriodType = useSelector(
    selectIndicatorInfoById(xIndicatorId, 'periodType'),
  )
  const yPeriodType = useSelector(
    selectIndicatorInfoById(yIndicatorId, 'periodType'),
  )

  const dataContent = getTooltipContent({
    data,
    plotType,
    xPeriodType,
    yPeriodType,
    xAxisDecimalLength,
    yAxisDecimalLength,
    xLabel,
    yLabel,
    locale,
  })

  return (
    <div>
      <div style={{ marginBottom: 5 }}>
        <Span
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: '#fdfdfd',
          }}
        >
          {data.ticker} - {data.organizationShortName}
        </Span>
      </div>
      {dataContent.map((item, index) => (
        <div key={index} className="d-flex j-b">
          <div>
            <Span style={{ fontWeight: 400 }}>{item.name}:</Span>
          </div>
          <div className="ml-8">
            <Span
              style={{
                fontWeight: 500,
                color: item.value < 0 ? '#eb505a' : 'inherit',
              }}
            >
              {item.value}
            </Span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ChartTooltip
