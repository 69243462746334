import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import {
  defineGroupColumns,
  defineIds,
  handleMaxMinValueByColId,
  keyByArray,
} from '../helper'
import { getGDPGrowthForecast } from './thunk'

const initialState = {
  forecastLoading: true,
  forecastData: [],
  forecastDataById: {},
  ids: {},
  groupColumns: [],
  minMaxValueByColId: {},
}

const slice = createSlice({
  name: 'economy/gdp/totalGDP/panelGDPGrowthForecast',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGDPGrowthForecast.pending, (state) => {
      state.forecastLoading = true
    })
    builder.addCase(getGDPGrowthForecast.fulfilled, (state, action) => {
      const data = action.payload || []
      state.forecastLoading = false
      state.forecastData = data
      state.forecastDataById = keyByArray(data, 'nationalAccountVNTypeId')
      state.ids = defineIds(data)
      state.groupColumns = defineGroupColumns(data)
      state.minMaxValueByColId = handleMaxMinValueByColId(data)
    })
    builder.addCase(getGDPGrowthForecast.rejected, (state, action) => {
      state.forecastLoading = action.payload.isLoading
    })
  },
})

export const getForecastLoading = (state) => state[slice.name].forecastLoading
export const getForecastData = (state) => state[slice.name].forecastData
export const getIds = (state) => state[slice.name].ids
export const getGroupColumns = (state) => state[slice.name].groupColumns
export const getMinMaxValue = (state) => state[slice.name].minMaxValue
export const selectForecastValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].forecastDataById[id], attr)
}
export const getMinMaxValueByColId = (colId) => (state) =>
  state[slice.name].minMaxValueByColId[colId]

register(slice.name, slice.reducer)
