import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Loading } from '../../../../common/loading'
import { SizeTracker } from '../../../../common/sizeTracker'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { BUBBLE_SIZE_TYPE, CHART_DOM_ID, PLOT_TYPE } from '../constant'
import {
  LOADING_TYPE,
  selectBubbleColor,
  selectBubbleSize,
  selectFilteredOrganizationIds,
  selectGeneralData,
  selectIndicatorInfoById,
  selectInvalidShareTemplate,
  selectLoading,
  selectMessageData,
  selectPlotType,
  selectPointInTimeData,
  selectSelectedOrganizations,
  selectTimeSeriesData,
  selectXIndicator,
  selectYIndicator,
} from '../store/slice'
import ButtonSave from './ButtonSave'
import Chart from './Chart'
import { formatChartData } from './helper'

const ScatterPlot = ({ width, height }) => {
  const loading = useSelector(selectLoading(LOADING_TYPE.CHART))
  const loadingGeneral = useSelector(selectLoading(LOADING_TYPE.GENERAL_DATA))
  const plotType = useSelector(selectPlotType)
  const selectedOrganizations = useSelector(selectSelectedOrganizations)
  const filteredOrgIds = useSelector(selectFilteredOrganizationIds)
  const pointInTimeData = useSelector(selectPointInTimeData)
  const timeSeriesData = useSelector(selectTimeSeriesData)
  const generalData = useSelector(selectGeneralData)
  const bubbleSize = useSelector(selectBubbleSize)
  const bubbleColor = useSelector(selectBubbleColor)

  const invalidShareTemplate = useSelector(selectInvalidShareTemplate)
  const messageData = useSelector(selectMessageData)
  const xIndicator = useSelector(selectXIndicator)
  const xName = useSelector(
    selectIndicatorInfoById(xIndicator, 'indicatorName'),
  )
  const xMultiplier = useSelector(
    selectIndicatorInfoById(xIndicator, 'multiplier'),
  )
  const xUnit = useSelector(selectIndicatorInfoById(xIndicator, 'unit'))
  const yIndicator = useSelector(selectYIndicator)
  const yName = useSelector(
    selectIndicatorInfoById(yIndicator, 'indicatorName'),
  )
  const yMultiplier = useSelector(
    selectIndicatorInfoById(yIndicator, 'multiplier'),
  )
  const yUnit = useSelector(selectIndicatorInfoById(yIndicator, 'unit'))

  const xFormatPercent = xUnit === '%' ? 100 : 1
  const yFormatPercent = yUnit === '%' ? 100 : 1

  const data = formatChartData({
    filteredOrgIds,
    data: plotType === PLOT_TYPE.TIME_SERIES ? timeSeriesData : pointInTimeData,
    generalData: plotType === PLOT_TYPE.TIME_SERIES ? generalData : {},
    organizations: selectedOrganizations,
    bubbleColor,
    bubbleSize,
    xMultiplier,
    xFormatPercent,
    yMultiplier,
    yFormatPercent,
  })

  const zAxisRange =
    bubbleSize === BUBBLE_SIZE_TYPE.CONSTRAINT ? [300, 300] : [50, 10000]

  if (loading || loadingGeneral) {
    return <Loading />
  }

  if (invalidShareTemplate) {
    return (
      <div className="h-100 d-flex justify-content-center align-center">
        <Translate value="sector.sectorConstituents.segmentation.INVALID_TEMPLATE" />
      </div>
    )
  }

  if (messageData)
    return (
      <div className="h-100 d-flex justify-content-center align-center">
        {messageData}
      </div>
    )

  return (
    <SizeTracker>
      {(size) => (
        <>
          <ButtonSave />
          {size.height && (
            <div
              className="position-relative"
              style={{ height: `calc(100% - ${size.height}px)` }}
              id={CHART_DOM_ID}
            >
              <Chart
                width={width}
                height={height - size.height}
                data={data}
                xAxisId="xValue"
                yAxisId="yValue"
                zAxisId={bubbleSize}
                zAxisRange={zAxisRange}
                xLabel={xName !== EMPTY_VALUE ? `${xName} (${xUnit})` : '(--)'}
                yLabel={yName !== EMPTY_VALUE ? `${yName} (${yUnit})` : '(--)'}
                xUnit={xUnit === '%' ? xUnit : undefined}
                yUnit={yUnit === '%' ? yUnit : undefined}
              />
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}

export default ScatterPlot
