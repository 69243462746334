import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { convertSortKeyToNumber } from '../../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../../utils/Datetime'
import { TRANSACTION_TYPES } from '../../constants'
import { checkHnxGroup } from '../../helps'
import { SummaryFilter } from '../SummaryFilter'
import { TAB_TYPE } from '../constants'
import { priceDataTableCol, tableHeadRowTop, tableHeadTab } from './constants'
import { getTableRowSpanSchema, getTableSchema } from './helps'
import {
  changeFilter,
  changeReCalcWidth,
  resetData,
  selectDataDateRange,
  selectDataStatisticsInvest,
  selectFilter,
  selectIds,
  selectLoading,
  selectReCalcWidths,
  selectThTab,
  selectTotalPage,
  sort,
} from './store/slice'
import { getByInvestor } from './store/thunk'

export const StatisticsInvest = ({ width, tabType, onFilterChange }) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const ids = useSelector(selectIds)
  const filter = useSelector(selectFilter)
  const isLoading = useSelector(selectLoading)
  const totalPage = useSelector(selectTotalPage)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const dataDateRange = useSelector(selectDataDateRange)
  const thTab = useSelector(selectThTab)

  // Use state
  const [isOpenDateFrom, setIsOpenDateFrom] = useState(false)
  const [isOpenDateTo, setIsOpenDateTo] = useState(false)
  const [transactionType, setTransactionType] = useState(TRANSACTION_TYPES.ALL)

  const isHnxGroup = useMemo(() => {
    return checkHnxGroup(basicInfo.exchangeCode)
  }, [basicInfo.exchangeCode])

  // Actions
  const onChangeTimeFilter = (time) => {
    dispatch(resetData())
    dispatch(changeFilter({ TimeFilter: time.value, Page: 1 }))
  }

  const onChangePage = (page) => {
    dispatch(changeFilter({ Page: page }))
  }

  const onChangeDateFrom = (date) => {
    dispatch(changeFilter({ StartDate: date }))
  }

  const onChangeDateTo = (date) => {
    dispatch(changeFilter({ EndDate: date }))
  }

  const onChangeSortField = (data) => {
    if (Object.values(data)[0]) {
      dispatch(
        changeFilter({
          SortField: Object.keys(data)[0],
          SortOrder: convertSortKeyToNumber(Object.values(data)[0]),
        }),
      )
    } else {
      dispatch(changeFilter({ SortField: null, SortOrder: null }))
    }
  }

  const handleChangeTransactionType = (val) => {
    setTransactionType(val)
    dispatch(changeReCalcWidth())
  }

  // Use effect
  useEffect(() => {
    onFilterChange({ ...filter, TransactionType: transactionType })
  }, [filter, transactionType])

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        changeFilter({
          OrganizationId: basicInfo.organizationId,
          StartDate: undefined,
          EndDate: undefined,
        }),
      )
      dispatch(resetData())
    }
  }, [basicInfo.organizationId])

  useEffect(() => {
    if (tabType === TAB_TYPE.STATISTIC_INVEST && filter.OrganizationId) {
      dispatch(
        getByInvestor({
          ...filter,
          StartDate: filter.StartDate
            ? getISOStartOrEndOfDay(filter.StartDate, timeZone, true)
            : undefined,
          EndDate: filter.EndDate
            ? getISOStartOrEndOfDay(filter.EndDate, timeZone, false)
            : undefined,
        }),
      )
    }
  }, [tabType, filter])

  if (isHnxGroup) {
    return <NoData text="common.DATA_NOT_AVAILABLE" />
  }

  return (
    <>
      <SummaryFilter
        width={width}
        page={filter.Page}
        totalPage={totalPage}
        activeTab={filter.TimeFilter}
        dateFrom={
          isOpenDateFrom
            ? filter.StartDate
            : filter.StartDate || dataDateRange.startDate
        }
        dateTo={
          isOpenDateTo
            ? filter.EndDate
            : filter.EndDate || dataDateRange.endDate
        }
        limitDatepicker={dataDateRange}
        transactionType={transactionType}
        isTransactionType
        onChangeTab={onChangeTimeFilter}
        onChangePage={onChangePage}
        onDateFromChange={onChangeDateFrom}
        onDateToChange={onChangeDateTo}
        onOpenDateFrom={setIsOpenDateFrom}
        onOpenDateTo={setIsOpenDateTo}
        onChangeTransactionType={handleChangeTransactionType}
      />
      <Table
        ids={ids}
        isLoading={isLoading}
        getDataFromRedux={selectDataStatisticsInvest}
        schema={getTableSchema(filter, thTab, transactionType)}
        rowSpanSchema={getTableRowSpanSchema(
          filter,
          thTab,
          width,
          transactionType,
        )}
        stickies={{
          [priceDataTableCol[thTab].DATE]: true,
          [tableHeadTab.LOCAL_INDIVIDUAL]: true,
          [tableHeadTab.FOREIGN_INDIVIDUAL]: true,
          [tableHeadTab.LOCAL_INSTITUTION]: true,
          [tableHeadTab.FOREIGN_INSTITUTION]: true,
        }}
        defaultSort={{ tradingdateId: 'desc' }}
        sort={sort}
        isLargeHead={true}
        hasFooter={false}
        hasTooltip={false}
        resizable={false}
        tableHeadRowTop={tableHeadRowTop[thTab]}
        handleSortField={onChangeSortField}
        reCalcWidths={reCalcWidths}
      />
    </>
  )
}

StatisticsInvest.propTypes = {
  width: PropTypes.number.isRequired,
  tabType: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
}
