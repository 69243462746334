import moment from 'moment'
import { COMPONENT } from '../../../../configs/Layout'
import {
  getChartMargin,
  getDecimalLengthYAxis,
  getRoundedTickValues,
  yExtentsCharts,
} from '../../../common/chart/helper'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { formatVal } from '../../../utils/Value'
import {
  NET_VALUE_CHART_FORMAT_VALUE,
  NET_VALUE_CHART_KEY,
  NUMBER_COLUMN_CHART,
  PRICE_DATA_TIME_SELECT,
  TIME_SELECT,
  VALUE_INVESTOR_CHART_FORMAT_VALUE,
  VALUE_TYPE,
} from './constants'

export const formatTooltipTitle = (
  data,
  dateType,
  dateKeys,
  xAxisKey,
  locale,
  timezone,
) => {
  const date = data[dateKeys[0]]
  const minDate = data[dateKeys[1]]
  switch (dateType) {
    case TIME_SELECT.ONE_WEEK:
    case TIME_SELECT.ONE_MONTH:
    case TIME_SELECT.THREE_MONTH:
      return formatDateTime(new Date(date), FORMAT.DATE, locale, timezone)
    case TIME_SELECT.SIX_MONTH: {
      const startWeek = moment(date).isoWeekday(1).toDate()
      const endWeek = moment(date).isoWeekday(5).toDate()
      return (
        formatDateTime(minDate || startWeek, FORMAT.DATE, locale, timezone) +
        ' - ' +
        formatDateTime(date || endWeek, FORMAT.DATE, locale, timezone)
      )
    }
    case TIME_SELECT.ONE_YEAR:
    case TIME_SELECT.THREE_YEAR:
      return formatDateTime(new Date(date), FORMAT.MONTH, locale, timezone)
    case TIME_SELECT.FIVE_YEAR:
      return formatDateTime(
        new Date(date),
        FORMAT.QUARTER_YEAR,
        locale,
        timezone,
      )
    default:
      return data[xAxisKey]
  }
}

export const checkHnxGroup = (groupCode) => {
  return (
    groupCode &&
    ['HNXINDEX', 'HNX30', 'HNXLCAP', 'HNXMSCAP', 'UPCOMINDEX'].includes(
      groupCode.toUpperCase(),
    )
  )
}

export const formatValNumber = (value, number = 4) => {
  return formatVal(value)
}

export const checkIpadScreen = (
  width,
  compareWidth = COMPONENT.MIN_SIZE.WIDTH,
) => {
  return width < compareWidth
}

export const getFormatDateTime = (val, type, locale, timeZone) => {
  switch (type) {
    case PRICE_DATA_TIME_SELECT.DAILY:
    case PRICE_DATA_TIME_SELECT.WEEKLY:
      return formatDateTime(val, FORMAT.DATE, locale, timeZone)
    case PRICE_DATA_TIME_SELECT.MONTHLY:
      return formatDateTime(val, FORMAT.MONTH_YEAR_NUMBER, locale, timeZone)
    case PRICE_DATA_TIME_SELECT.QUARTERLY:
      return formatDateTime(val, FORMAT.QUARTER_YEAR, locale, timeZone)
    case PRICE_DATA_TIME_SELECT.YEARLY:
      return formatDateTime(val, FORMAT.YEAR, locale, timeZone)
    default:
      return formatDateTime(val, FORMAT.DATE, locale, timeZone)
  }
}

export const getNetValueChartData = (data, type, dateType) => {
  if (!data) {
    return []
  }

  return data
    .map((v) => {
      return {
        date: v.tradingDateId,
        minDate: v.minTradingDateId,
        x: v.tradingDateId,
        buy:
          v[NET_VALUE_CHART_KEY[type].buy] /
            NET_VALUE_CHART_FORMAT_VALUE[NET_VALUE_CHART_KEY[type].buy] || 0,
        sell:
          Math.abs(
            v[NET_VALUE_CHART_KEY[type].sell] /
              NET_VALUE_CHART_FORMAT_VALUE[NET_VALUE_CHART_KEY[type].sell] || 0,
          ) * -1,
        net:
          v[NET_VALUE_CHART_KEY[type].net] /
            NET_VALUE_CHART_FORMAT_VALUE[NET_VALUE_CHART_KEY[type].net] || 0,
      }
    })
    .reverse()
    .filter(
      (value, index, arr) =>
        index === arr.findIndex((item) => item.x === value.x),
    )
    .filter((_, index) => index < NUMBER_COLUMN_CHART[dateType])
    .reverse()
}

export const getValueInvestorNetValueChartData = (
  data,
  valueType,
  dateType,
  isHnxGroup,
) => {
  if (valueType !== VALUE_TYPE.NET_VALUE) {
    return
  }

  return data
    .map((v) => {
      return {
        date: v.tradingDateId,
        minDate: v.minTradingDateId,
        x: v.tradingDateId,
        localInstitution: !isHnxGroup
          ? v.localInstitutionalNetValue /
              VALUE_INVESTOR_CHART_FORMAT_VALUE.localInstitutionalNetValue || 0
          : 0,
        proprietary: !isHnxGroup
          ? v.proprietaryNetValue /
              VALUE_INVESTOR_CHART_FORMAT_VALUE.proprietaryNetValue || 0
          : 0,
        foreign:
          v.foreignNetValueTotal /
            VALUE_INVESTOR_CHART_FORMAT_VALUE.foreignNetValueTotal || 0,
        localIndividual: !isHnxGroup
          ? v.localIndividualNetValue /
              VALUE_INVESTOR_CHART_FORMAT_VALUE.localIndividualNetValue || 0
          : 0,
        closeIndex:
          (!isHnxGroup &&
            v.closeIndex &&
            v.closeIndex / VALUE_INVESTOR_CHART_FORMAT_VALUE.closeIndex) ||
          0,
      }
    })
    .reverse()
    .filter(
      (value, index, arr) =>
        index === arr.findIndex((item) => item.x === value.x),
    )
    .filter((_, index) => index < NUMBER_COLUMN_CHART[dateType])
    .reverse()
}

const getFormatValue = (value) => {
  return Math.round(value * 10000) / 100
}

export const getValueInvestorPercentValueChartData = (
  data,
  valueType,
  dateType,
  isHnxGroup,
) => {
  if (valueType !== VALUE_TYPE.PERCENT_VALUE) {
    return []
  }

  return (
    data
      .filter((_, index) => index < NUMBER_COLUMN_CHART[dateType])
      // Format chart data
      .map((v) => {
        return {
          date: v.tradingDateId,
          minDate: v.minTradingDateId,
          x: v.tradingDateId,
          localInstitution: !isHnxGroup
            ? getFormatValue(v.localInstitutionalPercentage)
            : 0,
          proprietary: !isHnxGroup
            ? getFormatValue(v.proprietaryPercentage)
            : 0,
          foreign: getFormatValue(v.foreignPercentage),
          localIndividual: !isHnxGroup
            ? Math.round(
                (100 -
                  getFormatValue(v.localInstitutionalPercentage) -
                  getFormatValue(v.foreignPercentage) -
                  getFormatValue(v.proprietaryPercentage)) *
                  100,
              ) / 100
            : 0,
          closeIndex:
            (!isHnxGroup &&
              v.closeIndex &&
              v.closeIndex / VALUE_INVESTOR_CHART_FORMAT_VALUE.closeIndex) ||
            0,
        }
      })
  )
}

const getEachChartMargin = (data, barKeys, lineKeys, fontSize, margin) => {
  if (data.length && barKeys.length) {
    // Get left tick and decimal
    const [leftMin, leftMax] = yExtentsCharts(data, barKeys, true)
    const leftTickValues = getRoundedTickValues([leftMin, leftMax], 6)
    const leftDecimalLength = getDecimalLengthYAxis(leftTickValues)

    // Get right tick and decimal
    let rightTickValues = []
    let rightDecimalLength = 0
    if (lineKeys && lineKeys.length) {
      const [rightMin, rightMax] = yExtentsCharts(data, lineKeys)
      rightTickValues = getRoundedTickValues([rightMin, rightMax], 6)
      rightDecimalLength = getDecimalLengthYAxis(rightTickValues)
    }

    // Calculate chart margin
    return getChartMargin(
      leftTickValues,
      rightTickValues,
      leftDecimalLength,
      rightDecimalLength,
      fontSize,
      margin,
    )
  }

  return margin
}

export const getCompareChartMargin = (
  data,
  otherData,
  barKeys,
  otherBarKeys,
  lineKeys,
  otherLineKeys,
  fontSize,
  margin,
) => {
  const chartMargin = getEachChartMargin(
    data,
    barKeys,
    lineKeys,
    fontSize,
    margin,
  )
  const otherChartMargin = getEachChartMargin(
    otherData,
    otherBarKeys,
    otherLineKeys,
    fontSize,
    margin,
  )
  // Compare two chart margin
  return {
    left: Math.max(chartMargin?.left || 0, otherChartMargin?.left || 0),
    right: Math.max(chartMargin?.right || 0, otherChartMargin?.right || 0),
  }
}
