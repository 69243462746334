import { createAsyncThunk } from '@reduxjs/toolkit'
import StateBudgetService from '../../../../../../core/services/economy/fiscal/StateBudgetService'

export const getTotalSocialInvesmentCapitalChart = createAsyncThunk(
  'economy/fiscal/stateBudget/getTotalSocialInvesmentCapitalChart',
  async (params, { rejectWithValue }) => {
    const response =
      await StateBudgetService.getTotalSocialInvesmentCapitalChart(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getTotalInvesmentCapitalUnderTheStateBudgetChart =
  createAsyncThunk(
    'economy/fiscal/stateBudget/getTotalInvesmentCapitalUnderTheStateBudgetChart',
    async (params, { rejectWithValue }) => {
      const response =
        await StateBudgetService.getTotalInvesmentCapitalUnderTheStateBudgetChart(
          params,
        )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    },
  )

export const getTotalInvesmentCapitalOfTheStateSectorChart = createAsyncThunk(
  'economy/fiscal/stateBudget/getTotalInvesmentCapitalOfTheStateSectorChart',
  async (params, { rejectWithValue }) => {
    const response =
      await StateBudgetService.getTotalInvesmentCapitalOfTheStateSectorChart(
        params,
      )
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getMaxPeriod = createAsyncThunk(
  'economy/fiscal/stateBudget/getMaxPeriod',
  async (params, { rejectWithValue }) => {
    const response = await StateBudgetService.getMaxPeriod(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
