import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import TextEllipsis from '../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  FORMAT_DATA_BY_UNIT,
  INDICATOR_FIX_UNIT_INT,
  TABLE_ROW_LEVEL,
  UNIT_CONSTANTS,
} from '../constants'
import { getStyle } from '../helpers'
import {
  selectExchangeRates,
  selectFilters,
  selectIndicator,
} from '../store/slice'
import styles from './index.module.css'

export const OtherColumnsCell = ({ val, rowId, className, columnItem }) => {
  const item = useSelector(selectIndicator(rowId))
  const filters = useSelector(selectFilters)
  const exchangeRates = useSelector(selectExchangeRates)

  // Get data
  const getFormatDataByUnit = () => {
    return INDICATOR_FIX_UNIT_INT.includes(item.key.toUpperCase()) &&
      item.fixUnit
      ? 1
      : FORMAT_DATA_BY_UNIT[filters.unit]
  }

  const getFormatVal = useMemo(() => {
    if (val === 0) return EMPTY_VALUE

    if (item.isFormatValToPercent) {
      return valToPercent(val)
    }

    if (item.fixUnit) {
      return formatVal(val)
    }

    if (
      [
        UNIT_CONSTANTS.USD,
        UNIT_CONSTANTS.THOUSAND_USD,
        UNIT_CONSTANTS.MILLION_USD,
      ].includes(filters.unit)
    ) {
      return !isNaN(val)
        ? formatVal(
            val /
              (exchangeRates[`${columnItem.quarter}-${columnItem.year}`] || 1) /
              getFormatDataByUnit(),
          )
        : EMPTY_VALUE
    } else {
      return !isNaN(val)
        ? formatVal(
            val / getFormatDataByUnit(),
            filters.unit === UNIT_CONSTANTS.VND ? 0 : 2,
          )
        : EMPTY_VALUE
    }
  }, [filters.unit])

  const getColorVal = useMemo(() => {
    let isLessThanZero = false

    if (String(val).includes('%')) {
      const newValue = +val.replace('%', '')
      const isNumber = typeof newValue === 'number'
      isLessThanZero = isNumber && newValue < 0
    }

    return val < 0 || isLessThanZero ? '#ff5661' : ''
  }, [val])

  if (item.level === TABLE_ROW_LEVEL.LEVEL_0) {
    return (
      <td style={getStyle(item)} className={`${className} ${styles.rowLv0}`}>
        <div className={styles.otherCell}>
          <TextEllipsis text={val} isI18n={false} />
        </div>
      </td>
    )
  } else {
    return (
      <td
        className={className}
        style={{ textAlign: 'right', color: getColorVal, ...getStyle(item) }}
      >
        {getFormatVal}
      </td>
    )
  }
}

OtherColumnsCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  columnItem: PropTypes.object,
}
