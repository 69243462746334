import EventProxy from '../../proxies/economy/EventProxy'
import { ServiceBase } from '../ServiceBase'

class EventService extends ServiceBase {
  getEventIds(params) {
    return this.applyMemoryCache(
      'EventService.getEventIds',
      params,
    )(() => EventProxy.getEventIds(params))
  }

  getTargetIds(params) {
    return this.getData(() => EventProxy.getTargetIds(params))
  }

  getRelateNews(params) {
    return this.applyMemoryCache(
      'EventService.getRelateNews',
      params,
    )(() => EventProxy.getRelateNews(params))
  }

  getUpcomingEvents(params) {
    return this.getData(() => EventProxy.getUpcomingEvents(params))
  }

  getEventsDate(params) {
    return this.getData(() => EventProxy.getEventsDate(params))
  }
}

export default new EventService()
