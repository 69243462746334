import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class SteelProxy extends ProxyBase {
  getOverview(params) {
    return this.get('Overview', params)
  }

  getNews(params) {
    return this.get('News', params)
  }

  getReports(params) {
    return this.get('Reports', params)
  }

  getSteelTypes(params) {
    return this.get('SteelTypes', params)
  }

  getProductionAndConsumption(params) {
    return this.get('ProductionAndConSumption', params)
  }

  getInventory(params) {
    return this.get('Inventory', params)
  }

  getExport(params) {
    return this.get('Export', params)
  }

  getConstructionStatistics(params) {
    return this.get('ConstructionStatistic', params)
  }

  getDownloadConstructionStatistic(params) {
    return this.get('DownloadConstructionStatistic', params, 'download')
  }

  getConstructionPeersComparison(params) {
    return this.post('ConstructionPeerComparison', params)
  }

  getConstructionCompanyGrowth(params) {
    return this.get('ConstructionCompanyGrowth', params)
  }

  getOrganizations(params) {
    return this.get('Organizations', params)
  }

  getSteelPipeStatistics(params) {
    return this.get('PipeStatistic', params)
  }

  getDownloadSteelPipeStatistic(params) {
    return this.get('DownloadPipeStatistic', params, 'download')
  }

  getSteelPipePeersComparison(params) {
    return this.post('PipePeerComparison', params)
  }

  getSteelPipeCompanyGrowth(params) {
    return this.get('PipeCompanyGrowth', params)
  }

  getSteelCoatStatistics(params) {
    return this.get('CoatStatistic', params)
  }

  getDownloadSteelCoatStatistic(params) {
    return this.get('DownloadCoatStatistic', params, 'download')
  }

  getSteelCoatPeersComparison(params) {
    return this.post('CoatPeerComparison', params)
  }

  getSteelCoatCompanyGrowth(params) {
    return this.get('CoatCompanyGrowth', params)
  }

  getSteelCoilStatistics(params) {
    return this.get('CoilStatistic', params)
  }

  getDownloadSteelCoilStatistic(params) {
    return this.get('DownloadCoilStatistic', params, 'download')
  }

  getSteelCoilPeersComparison(params) {
    return this.post('CoilPeerComparison', params)
  }

  getSteelCoilCompanyGrowth(params) {
    return this.get('CoilCompanyGrowth', params)
  }

  getPriceStatistics(params) {
    return this.get('PriceStatistic', params)
  }

  getPriceGrowth(params) {
    return this.post('PriceGrowth', params)
  }

  getDownloadPriceStatistics(params) {
    return this.get('DownloadPriceStatistic', params, 'download')
  }

  getLatestPeriods(params) {
    return this.get('LatestPeriods', params, null, true)
  }
}

export default new SteelProxy(
  ServiceProxyConfig.Sector.SectorSpecific.Steel.ServiceUrl,
)
