import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import LinkTicker from '../../../../../common/dropdownLink/LinkTicker'
import { Span } from '../../../../../common/html/Span'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { Table } from '../../../../../common/table'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import style from '../index.module.css'
import { changeLocaleGrowthChart } from '../exportGrowth/store/slice'
import {
  HORIZONTAL_TRACK_LEFT,
  ID_TABLE,
  LIMIT_FETCH,
  LIMIT_FIRST_FETCH,
  TIME_FREQ,
  TYPE_VAL_GROWTH,
} from './constants'
import { FilterList } from './FilterList'
import {
  addItemToList,
  changeActiveItem,
  changeLocale,
  removeItemFromList,
  selectActiveItem,
  selectDataTable,
  selectFrequencyType,
  selectGroupColumns,
  selectIsFirstTime,
  selectIsValOrGrowth,
  selectItemDataTableById,
  selectItemIds,
  selectListItems,
  selectLoadingTableContent,
  selectMaxMonth,
  selectMaxYear,
  selectMinCurrentMonth,
  selectMinCurrentYear,
  selectReCalcWidths,
  setIsFirstTime,
  sort,
} from './store/slice'
import { getExporterStatistics, getMaxDate } from './store/thunk'
import { TdSelect } from './TdSelect'

const PADDING_TOP_TABLE = 10

export const ExportStatisticTable = ({ width, height }) => {
  const dispatch = useDispatch()

  const dataTable = useSelector(selectDataTable)
  const groupColumns = useSelector(selectGroupColumns)
  const itemIds = useSelector(selectItemIds)
  const isContentLoading = useSelector(selectLoadingTableContent)
  const listItems = useSelector(selectListItems)
  const activeItem = useSelector(selectActiveItem)
  const isValOrGrowth = useSelector(selectIsValOrGrowth)
  const maxMonth = useSelector(selectMaxMonth)
  const maxYear = useSelector(selectMaxYear)
  const minCurrentMonth = useSelector(selectMinCurrentMonth)
  const minCurrentYear = useSelector(selectMinCurrentYear)
  const currentTimeFrequency = useSelector(selectFrequencyType)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const isFirstRender = useSelector(selectIsFirstTime)
  const locale = useSelector((state) => state.i18n.locale)

  const [newMaxMonth, setNewMaxMonth] = useState(minCurrentMonth)
  const [newMaxYear, setNewMaxYear] = useState(minCurrentYear)

  const isYearly = currentTimeFrequency === TIME_FREQ.YEARLY

  const onRowClick = (rowId) => {
    if (!listItems?.some((item) => item.id === rowId)) {
      const item = {
        id: rowId,
        name: dataTable?.find((item) => item.id === rowId).name,
      }
      dispatch(addItemToList(item))
    } else {
      dispatch(removeItemFromList(rowId))
    }
  }

  const scrollToLeftCallback = () => {
    if (isYearly) {
      setNewMaxYear(minCurrentYear)
    } else {
      setNewMaxMonth(minCurrentMonth)
    }
    dispatch(setIsFirstTime(false))
  }

  useEffect(() => {
    dispatch(getMaxDate({ FisheryConst: 'Exporter' }))
  }, [])

  useEffect(() => {
    if (maxMonth && maxYear) {
      dispatch(setIsFirstTime(true))
      dispatch(
        getExporterStatistics({
          Year: maxYear,
          Month: maxMonth,
          TimeFrequency: currentTimeFrequency,
          Limit: LIMIT_FIRST_FETCH,
        }),
      )
    }
  }, [locale, isValOrGrowth, maxMonth, currentTimeFrequency])

  useEffect(() => {
    setNewMaxMonth(null)
    setNewMaxYear(null)
    dispatch(changeLocale(locale))
    dispatch(changeLocaleGrowthChart(locale))
  }, [locale])

  //called when is Monthly, scroll to left and fetch 5 more periods each time
  useEffect(() => {
    if (newMaxMonth && !isFirstRender && !isYearly) {
      dispatch(
        getExporterStatistics({
          Year: minCurrentYear,
          Month: minCurrentMonth,
          TimeFrequency: currentTimeFrequency,
          Limit: LIMIT_FETCH,
        }),
      )
    }
  }, [newMaxMonth, isFirstRender])

  //called when is Yearly, scroll to left and fetch 5 more periods each time
  useEffect(() => {
    if (newMaxYear && !isFirstRender && isYearly) {
      dispatch(
        getExporterStatistics({
          Year: minCurrentYear,
          Month: null,
          TimeFrequency: currentTimeFrequency,
          Limit: LIMIT_FETCH,
        }),
      )
    }
  }, [newMaxYear, isFirstRender])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <FilterList
              width={width}
              currentTimeFrequency={currentTimeFrequency}
            />
            <Span style={{ color: '#75797f', fontStyle: 'italic' }}>
              {isValOrGrowth === TYPE_VAL_GROWTH.VAL
                ? `${I18n.t('sector.sectorSpecific.fishery.UNIT')}: ${I18n.t(
                    'sector.sectorSpecific.fishery.MILLION_USD',
                  )}`
                : `${I18n.t('sector.sectorSpecific.fishery.UNIT')}: ${I18n.t(
                    'sector.sectorSpecific.fishery.PERCENT',
                  )}`}
            </Span>
            {size.height && (
              <div
                id={ID_TABLE}
                style={{
                  paddingTop: PADDING_TOP_TABLE,
                  height: height - size.height - PADDING_TOP_TABLE,
                }}
              >
                <Table
                  ids={itemIds}
                  getDataFromRedux={selectItemDataTableById}
                  isLoading={isContentLoading}
                  columnDraggable={false}
                  horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
                  hasTooltip={false}
                  hasFooter={false}
                  resizable={false}
                  sort={sort}
                  onRowClick={onRowClick}
                  stickies={{
                    name: true,
                    ticker: true,
                  }}
                  schema={groupColumns.map((item, index) => {
                    const title = item.title
                    const colId = item.key
                    let result = {
                      colId,
                      title,
                      isI18n: false,
                      thStyle: {
                        textAlign: 'right',
                      },
                      tdStyle: {
                        textAlign: 'right',
                      },
                    }
                    if (index === 0) {
                      result = {
                        ...result,
                        isI18n: false,
                        thStyle: {
                          textAlign: 'left',
                        },
                        tdStyle: {
                          textAlign: 'left',
                        },
                        render: (val, rowId) => {
                          return (
                            <TdSelect
                              val={val}
                              rowId={rowId}
                              data={dataTable}
                            />
                          )
                        },
                      }
                    }
                    if (index === 1) {
                      result = {
                        ...result,
                        isI18n: false,
                        thStyle: {
                          textAlign: 'left',
                        },
                        tdStyle: {
                          textAlign: 'left',
                        },
                        render: (val) => {
                          const getOrganizationId =
                            dataTable?.find((item) => item.ticker === val)
                              ?.organizationId ?? EMPTY_VALUE

                          return getOrganizationId ? (
                            <LinkTicker organizationId={getOrganizationId}>
                              {val}
                            </LinkTicker>
                          ) : (
                            val
                          )
                        },
                      }
                    }
                    if (index !== 0 && index !== 1) {
                      result = {
                        ...result,
                        render: (val) => {
                          const isGrowth =
                            dataTable[0]?.type &&
                            dataTable[0]?.type === TYPE_VAL_GROWTH.GROWTH
                          return (
                            <span
                              className={val < 0 ? style.negativeValColor : ''}
                            >
                              {isGrowth ? valToPercent(val) : formatVal(val)}
                            </span>
                          )
                        },
                      }
                    }
                    return result
                  })}
                  isCollapse={true}
                  changeActiveRow={changeActiveItem}
                  defaultActiveRowId={activeItem?.id ?? listItems[0]?.id}
                  isLazyLoadLeft={true}
                  scrollToLeftCallback={scrollToLeftCallback}
                  defaultScrollToRight={isFirstRender}
                  reCalcWidths={reCalcWidths}
                />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    )
  )
}
