export const chartFillColor = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#ecffdf',
]

export const chartPieKeys = ['lsfvtpl', 'usfvtpl', 'bfvtpl', 'cdfvtpl']

export const chartOtherPieKeys = 'ofvtpl'

export const chartMappingDisplayName = {
  lsfvtpl: 'sector.financialAnalysis.security.assetStructure.LISTED_SHARE',
  usfvtpl: 'sector.financialAnalysis.security.assetStructure.UNLISTED_SHARE',
  bfvtpl: 'sector.financialAnalysis.security.assetStructure.BONDS',
  cdfvtpl:
    'sector.financialAnalysis.security.assetStructure.CERTIFICATE_DEPOSIT',
  ofvtpl: 'sector.financialAnalysis.security.assetStructure.OTHER',
}

export const chartFormat = {
  lsfvtpl: 0.01,
  usfvtpl: 0.01,
  bfvtpl: 0.01,
  cdfvtpl: 0.01,
  ofvtpl: 0.01,
}
