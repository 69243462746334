import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableSector from '.'
import { Panel } from '../../../../../common/panel'
import { PreOpenMarket } from '../../../../../common/preOpenMarket/PreOpenMarket'
import style from '../style.module.css'
import { changeSectorDefault, selectSectorLever } from './store/slice'
import { pricePerformance } from './store/thunk'

const PanelTableSector = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const levelSector = useSelector(selectSectorLever)

  useEffect(() => {
    dispatch(changeSectorDefault(false))
  }, [locale])

  useEffect(() => {
    dispatch(changeSectorDefault(true))
  }, [levelSector])

  useEffect(() => {
    dispatch(
      pricePerformance({
        IcbLevel: levelSector,
      }),
    )
  }, [levelSector, locale])

  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      headerClass={style.panelHeader}
      windowClass={style.windowClass}
    >
      <PreOpenMarket>
        <TableSector />
      </PreOpenMarket>
    </Panel>
  )
}

export default PanelTableSector
