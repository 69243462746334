import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import AverageFundingCost from './panelAverageFundingCost'
import DepositRateCommercial from './panelDepositRateCommercial'
import DepositRateVietNam from './panelDepositRateVietNam'
import StatisticalInterestRate from './panelStatisticalInterestRate'

const MAP_KEY = {
  DEPOSIT_RATE_COMMERCIAL: 'DEPOSIT_RATE_COMMERCIAL',
  STATISTICAL_INTEREST_RATE: 'STATISTICAL_INTEREST_RATE',
  DEPOSIT_RATE_VIET_NAM: 'DEPOSIT_RATE_VIET_NAM',
  AVERAGE_FUNDING_COST: 'AVERAGE_FUNDING_COST',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.DEPOSIT_RATE_COMMERCIAL]: {
    minWidth: 760,
    minHeight: 300,
  },
  [MAP_KEY.STATISTICAL_INTEREST_RATE]: {
    minWidth: 410,
    minHeight: 300,
  },
  [MAP_KEY.DEPOSIT_RATE_VIET_NAM]: {
    minWidth: 760,
    minHeight: 300,
  },
  [MAP_KEY.AVERAGE_FUNDING_COST]: {
    minWidth: 410,
    minHeight: 300,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.DEPOSIT_RATE_COMMERCIAL}`]: {
    width: `calc(65%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.DEPOSIT_RATE_COMMERCIAL],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.STATISTICAL_INTEREST_RATE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.STATISTICAL_INTEREST_RATE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.DEPOSIT_RATE_VIET_NAM,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.AVERAGE_FUNDING_COST,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.STATISTICAL_INTEREST_RATE}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.STATISTICAL_INTEREST_RATE],
    minTop: 0,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.DEPOSIT_RATE_COMMERCIAL].minWidth +
      2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.DEPOSIT_RATE_VIET_NAM}`]: {
    width: `calc(65%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.DEPOSIT_RATE_VIET_NAM],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.DEPOSIT_RATE_COMMERCIAL].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.AVERAGE_FUNDING_COST,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.AVERAGE_FUNDING_COST}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.AVERAGE_FUNDING_COST],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.DEPOSIT_RATE_COMMERCIAL].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.DEPOSIT_RATE_COMMERCIAL].minWidth +
      2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <DepositRateCommercial
          panelRefs={panelRefs}
          panelKey={MAP_KEY.DEPOSIT_RATE_COMMERCIAL}
          sizes={sizes}
          setSizes={setSizes}
        />
        <StatisticalInterestRate
          panelRefs={panelRefs}
          panelKey={MAP_KEY.STATISTICAL_INTEREST_RATE}
          sizes={sizes}
          setSizes={setSizes}
        />
        <DepositRateVietNam
          panelRefs={panelRefs}
          panelKey={MAP_KEY.DEPOSIT_RATE_VIET_NAM}
          sizes={sizes}
          setSizes={setSizes}
        />
        <AverageFundingCost
          panelRefs={panelRefs}
          panelKey={MAP_KEY.AVERAGE_FUNDING_COST}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }

  return renderListPanel()
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
