import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterWithScroll from '../../../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import {
  TIME_FREQUENCY,
  TYPE_STATISTIC,
} from '../importStatisticTable/constants'
import {
  removeItemFromList,
  selectListItems,
  selectTimeFrequencyType,
} from '../importStatisticTable/store/slice'
import { ChartComponent } from './ChartComponent'
import { COLORS_LINE_CHART, TIME_RANGE } from './constants'
import { getItemName } from './helper'
import {
  changeActiveItem,
  changeCurrentDateType,
  changeCurrentDateTypeYearly,
  selectCurrentDateType,
  selectCurrentDateTypeYearly,
  selectDataChart,
  selectIsValueOrGrowthChart,
  selectLoading,
} from './store/slice'
import { getImportGrowthByProduct } from './store/thunk'

export const ImportGrowth = ({ width, height, isFullScreen }) => {
  const headerRef = useRef()
  const legendPanel = useRef()

  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)
  const listItems = useSelector(selectListItems)
  const timeFrequencyType = useSelector(selectTimeFrequencyType)
  const isValueOrGrowthChart = useSelector(selectIsValueOrGrowthChart)
  const currentDateType = useSelector(selectCurrentDateType)
  const currentDateTypeYearly = useSelector(selectCurrentDateTypeYearly)

  const isYearly = timeFrequencyType === TIME_FREQUENCY.YEARLY

  const [heightSizeTracker, setHeightSizeTracker] = useState()
  const [heightListItems, setHeightListItems] = useState(0)

  const getSeafoodIdSelected = listItems.map((item) => item.productId)
  const getLocationIdSelected = listItems.map((item) => item.locationId)

  const lineKeys = listItems.map(
    (item) => `${item.productId}_${item.locationId}`,
  )

  // Actions
  const handleChangeDateType = (item) => {
    return isYearly
      ? dispatch(changeCurrentDateTypeYearly(item.value))
      : dispatch(changeCurrentDateType(item.value))
  }

  const handleDelete = (item) => {
    dispatch(removeItemFromList(item.id))
    dispatch(changeActiveItem(null))
  }

  const handleHover = (item) => {
    dispatch(changeActiveItem(item.id))
  }

  const handleUnHover = () => {
    dispatch(changeActiveItem(null))
  }

  // Use effect
  useEffect(() => {
    if (headerRef.current?.offsetHeight && heightListItems)
      setHeightSizeTracker(
        height - headerRef.current?.offsetHeight - heightListItems,
      )
  }, [height, width, listItems, heightListItems])

  useEffect(() => {
    if (listItems.length) {
      dispatch(
        getImportGrowthByProduct({
          TimeFrequency: timeFrequencyType,
          TimeRange: 'AllTime',
          SeafoodIdSelected: getSeafoodIdSelected.toString(),
          LocationIdSelected: getLocationIdSelected.toString(),
        }),
      )
    }
  }, [listItems, currentDateType, currentDateTypeYearly, isValueOrGrowthChart])

  return width && height && isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="h-100">
        <div className="d-flex justify-content-end" ref={headerRef}>
          <DispatchActionTab
            data={isYearly ? TIME_RANGE.YEARLY : TIME_RANGE.MONTHLY}
            activeTab={isYearly ? currentDateTypeYearly : currentDateType}
            onChangeTab={handleChangeDateType}
          />
        </div>
        {dataChart.length ? (
          <>
            <div id="importGrowthChart">
              <ChartComponent
                data={dataChart}
                width={width}
                height={heightSizeTracker}
                keyXAxis={'time'}
                lineKeys={lineKeys}
              />
              <FooterWithScroll
                width={width}
                data={listItems}
                idKey="id"
                nameKey="name"
                formatName={(item) => getItemName(item, TYPE_STATISTIC.PRODUCT)}
                color={(item) =>
                  COLORS_LINE_CHART[
                    lineKeys.indexOf(`${item.productId}_${item.locationId}`)
                  ]
                }
                legendPanel={legendPanel}
                isFullScreen={isFullScreen}
                setHeightFooter={setHeightListItems}
                onDelete={handleDelete}
                onHover={handleHover}
                onUnHover={handleUnHover}
              />
            </div>
          </>
        ) : (
          <NoData />
        )}
      </div>
    </>
  )
}
