import { getLanguage } from './Language'

const PREFIX_LINKS = ['http://', 'https://']

const isStartsWithMatch = (url, prefixLinks) => {
  let result = false
  prefixLinks.forEach((prefixLink) => {
    if (url.startsWith(prefixLink)) {
      result = true
    }
  })

  return result
}

export const formatUrl = (url) => {
  const result = {
    href: url,
    label: url,
  }

  if (!isStartsWithMatch(url, PREFIX_LINKS)) {
    result.href = PREFIX_LINKS[PREFIX_LINKS.length - 1] + url
  }

  PREFIX_LINKS.forEach((prefixLink) => {
    if (url.startsWith(prefixLink)) {
      result.label = url.slice(prefixLink.length)
    }
  })

  return result
}

export const redirectLinkIcbPdf = () => {
  window.open(
    `https://fiingroup.vn/upload/FiinX/INDUSTRY_CLASSIFICATION_BENCHMARK_${getLanguage()}.pdf`,
    '_blank',
  )
}
