import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import GDPSpendingMethodService from '../../../../../../core/services/economy/gdp/GDPSpendingMethodService'

export const getGDPGrowthBySpendingMethod = createAsyncThunk(
  'economy/gdp/gdpBySpendingMethod/panelGDPGrowth/getGDPGrowthBySpendingMethod',
  async (params, { rejectWithValue }) => {
    try {
      const res = await GDPSpendingMethodService.getGDPGrowthBySpendingMethod(
        params,
      )
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
