import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../common/table/helper'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { getSubsidiaries } from './thunk'

const initialState = {
  ids: [],
  subsidiariesById: {},
  loading: false,
  showTable: true,
  initialIds: [],
}

export const slice = createSlice({
  name: 'corporate/ownership/subsidiaries',
  initialState,
  reducers: {
    setSubsidiariesById: (state, action) => {
      state.subsidiariesById = action.payload
    },
    setIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
    changeIds: (state, action) => {
      state.ids = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.subsidiariesById,
        action.payload,
        state.initialIds,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubsidiaries.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getSubsidiaries.fulfilled, (state, action) => {
      state.loading = false
      state.showTable = true
      state.initialIds = state.ids =
        action.payload?.map((_, index) => index) || []
      const data =
        action.payload?.map((item, index) => {
          return { ...item, index }
        }) || []
      state.subsidiariesById = keyBy(data, 'index')
    })
    builder.addCase(getSubsidiaries.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectSubsidiariesIds = (state) => state[slice.name].ids
export const selectLoading = (state) => state[slice.name].loading
export const selectShowTable = (state) => state[slice.name].showTable
export const selectSubsidiariesValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].subsidiariesById[id], attr)
}
export const { setSubsidiariesById, setIds, changeIds, sort } = slice.actions

register(slice.name, slice.reducer)
