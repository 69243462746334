import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../../common/chart/pieChart/pieChartWithFooter'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { VIEW_BY } from '../constants'
import { convertType } from '../helper'
import { selectQuarter, selectTypeFilter, selectYear } from '../store/slice'
import style from '../style.module.css'
import { getColorPieChart, getMarketShareChartName } from './helper'
import { selectLoading, selectMarketShareChartData } from './store/slice'
import { getMarketShareChartData } from './store/thunk'

const MAX_HEIGHT_CHART = 500

const BrokerageMarketShare = ({ width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const stockInfo = useSelector(selectBasicInfo)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectMarketShareChartData)
  const typeFilter = useSelector(selectTypeFilter)
  const filterYear = useSelector(selectYear)
  const filterQuarter = useSelector(selectQuarter)

  useEffect(() => {
    if (stockInfo.organizationId && filterYear)
      dispatch(
        getMarketShareChartData({
          organizationId: stockInfo.organizationId,
          BusinessActivity: convertType(typeFilter),
          Quarter: filterQuarter ? filterQuarter : undefined,
          Year: filterYear,
        }),
      )
  }, [locale, stockInfo.organizationId, typeFilter, filterYear, filterQuarter])

  const getKeyChart = () => {
    if (typeFilter === VIEW_BY.MARGIN_LENDING) {
      return 'mlms'
    } else if (typeFilter === VIEW_BY.PROPRIETARY) {
      return 'tdms'
    } else {
      return 'kdnvms'
    }
  }

  const formatDataChart = getColorPieChart(
    dataChart,
    getKeyChart(),
    stockInfo?.ticker,
  )

  return (
    <div id="brokerageMarketShareId" className="h-100">
      {isLoading ? (
        <Loading />
      ) : formatDataChart.length ? (
        <SizeTracker>
          {(size) => {
            return (
              <>
                <div className="position-relative h-100">
                  <div className={style.titleHeader}>
                    <Translate value={getMarketShareChartName(typeFilter)} />
                  </div>
                  {size.height && (
                    <PieChartWithFooter
                      data={formatDataChart}
                      height={Math.min(MAX_HEIGHT_CHART, height - size.height)}
                      width={width}
                      footerRight
                      footerProps={{
                        isOneColumn: true,
                        appendStyle: { gridGap: 8 },
                        itemStyle: { paddingRight: 8 },
                      }}
                    />
                  )}
                </div>
              </>
            )
          }}
        </SizeTracker>
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default BrokerageMarketShare
