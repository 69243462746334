import TradingInformationProxy from '../../../proxies/bond/bondSecurityProxy/tradingInformationProxy'
import { ServiceBase } from '../../ServiceBase'

export class TradingInformation extends ServiceBase {
  getDataSecurity(params) {
    return this.getData(() => TradingInformationProxy.getDataProxy(params))
  }
  getDataCompanyRating(params) {
    return this.getData(() =>
      TradingInformationProxy.getCompanyRatingProxy(params),
    )
  }
  exportFile(params) {
    return this.getFileDownload(() =>
      TradingInformationProxy.exportFileProxy(params),
    )
  }
}

export default new TradingInformation()
