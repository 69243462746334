import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../common/table/helper'
import { keyBy } from '../../../utils/Common'
import { register } from '../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../utils/Value'
import {
  SELECT_TIME_RANGE,
  SELECT_VOLUME,
  TYPE_TOP_MOVER,
  VALUE_ORDER,
} from '../constants'
import { valForeignNetTotal } from '../helper'
import { getTopMover, getWatchListThunk, refreshTopMover } from './thunk'

const initialState = {
  ids: [],
  dataById: {},
  isLoading: true,
  watchList: [],
  initialIds: [],
  filter: {
    exchange: 'Listed',
    sector: {},
    watchList: null,
    top: TYPE_TOP_MOVER.CONTRIBUTION,
    marketCapFrom: '',
    marketCapTo: '',
    timeRange: SELECT_TIME_RANGE.ONE_DAY,
    times: SELECT_VOLUME.ONE_POINT_FIVE_TIMES,
    orderTopMover: VALUE_ORDER.DESC,
  },
  loadingGetICB: true,
  loadingWatchList: true,
  reCalcWidths: false,
}

export const slice = createSlice({
  name: 'market/topMover',
  initialState,
  reducers: {
    changeFilterExchange: (state, action) => {
      state.filter.exchange = action.payload
    },
    changeFilterTopMover: (state, action) => {
      state.filter.top = action.payload
    },
    changeFilterTimeRange: (state, action) => {
      state.filter.timeRange = action.payload
    },
    changeFilterSector: (state, action) => {
      state.filter.sector = action.payload
    },
    changeMarketCapFrom: (state, action) => {
      state.filter.marketCapFrom = action.payload
    },
    changeMarketCapTo: (state, action) => {
      state.filter.marketCapTo = action.payload
    },
    setFilterToDefault: (state) => {
      state.filter = initialState.filter
    },
    changeFilterTime: (state, action) => {
      state.filter.times = action.payload
    },
    changeFilterOrder: (state, action) => {
      state.filter.orderTopMover = action.payload
    },
    changeFilterWatchList: (state, action) => {
      state.filter.watchList = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopMover.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTopMover.fulfilled, (state, action) => {
      state.isLoading = false
      const arrNew = action.payload.map((i) => ({
        ...i,
        foreignNetValueTotal: valForeignNetTotal(
          i.foreignBuyValueTotal,
          i.foreignSellValueTotal,
        ),
        foreignNetVolumeTotal: valForeignNetTotal(
          i.foreignBuyVolumeTotal,
          i.foreignSellVolumeTotal,
        ),
      }))
      state.initialIds = state.ids = arrNew.map((i) => i.organizationId)
      state.dataById = keyBy(arrNew, 'organizationId')
      state.reCalcWidths = !state.reCalcWidths
    })
    builder.addCase(getTopMover.rejected, (state, action) => {
      state.isLoading = action.payload.loading
      state.ids = state.initialIds = []
    })

    builder.addCase(refreshTopMover.fulfilled, (state, action) => {
      state.isLoading = false
      const arrNew = action.payload.map((i) => ({
        ...i,
        foreignNetValueTotal: valForeignNetTotal(
          i.foreignBuyValueTotal,
          i.foreignSellValueTotal,
        ),
        foreignNetVolumeTotal: valForeignNetTotal(
          i.foreignBuyVolumeTotal,
          i.foreignSellVolumeTotal,
        ),
      }))
      state.initialIds = state.ids = arrNew.map((i) => i.organizationId)
      state.dataById = keyBy(arrNew, 'organizationId')
      state.reCalcWidths = !state.reCalcWidths
    })

    builder.addCase(getWatchListThunk.pending, (state) => {
      state.loadingWatchList = true
    })
    builder.addCase(getWatchListThunk.fulfilled, (state, action) => {
      state.loadingWatchList = false
      state.watchList = action.payload
    })
  },
})

export const selectTopMoverIds = (state) => state[slice.name].ids
export const selectTopMoverValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectLoading = (state) => state[slice.name].isLoading
export const selectFilter = (state) => state[slice.name].filter
export const selectWatchList = (state) => state[slice.name].watchList
export const selectReCalcWidthTable = (state) => state[slice.name].reCalcWidths

export const {
  changeFilterExchange,
  changeFilterTopMover,
  changeFilterTimeRange,
  changeFilterSector,
  changeMarketCapFrom,
  changeMarketCapTo,
  setFilterToDefault,
  changeFilterTime,
  changeFilterOrder,
  changeFilterWatchList,
  sort,
} = slice.actions

register(slice.name, slice.reducer)
