import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PopupExportSheet from '../../../../common/dataExplorer/PopupExportSheet'
import PopupSaveTemplate from '../../../../common/dataExplorer/PopupSaveTemplate'
import {
  DEFAULT_PARAM_TEMPLATE,
  maxTemplate,
  typeData,
} from '../../../../common/dataExplorer/constant'
import { getSheetName } from '../../../../common/dataExplorer/helper'
import { Button } from '../../../../common/html/Button'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import {
  convertCondition,
  convertParamGetResultEconomy,
  getHeaderIndicator,
} from '../helper'
import style from '../index.module.css'
import {
  handlePopupSetting,
  resetColumn,
  resetEconomy,
  selectActiveSheet,
  selectColumnById,
  selectCondition,
  selectEconomyId,
  selectFullColumnId,
  selectIndicatorById,
  selectIsLoad,
  selectListSheet,
  selectSheet,
  selectTemplates,
} from '../store/slice'
import {
  exportExcelThunk,
  getResultThunk,
  saveTemplateThunk,
  updateTemplateThunk,
} from '../store/thunk'

const GroupButton = ({ setIsLoadingDownloadCsv }) => {
  const dispatch = useDispatch()

  const sheets = useSelector(selectSheet)
  const activeSheet = useSelector(selectActiveSheet)
  const economyId = useSelector(selectEconomyId(activeSheet))
  const columnById = useSelector(selectColumnById(activeSheet))
  const locale = useSelector((state) => state.i18n.locale)
  const columnId = useSelector(selectFullColumnId(activeSheet))
  const templates = useSelector(selectTemplates)
  const listSheet = useSelector(selectListSheet)
  const isLoad = useSelector(selectIsLoad(activeSheet))
  const conditionStore = useSelector(selectCondition(activeSheet))
  const indicatorById = useSelector(selectIndicatorById)
  const detailSheet = listSheet.find((sheet) => sheet.value === activeSheet)

  const [isShowPopupExport, setIsShowPopupExport] = useState(false)
  const [isShowPopupSaveAs, setIsShowPopupSaveAs] = useState(false)
  const [isCanExport, setIsCanExport] = useState(false)

  useEffect(() => {
    setIsCanExport(
      Object.keys(sheets).some(
        (valueSheet) => sheets[valueSheet]?.columnId.length,
      ),
    )
  }, [columnId])

  useEffect(() => {
    if (isLoad) {
      handleSubmitTable()
    }
  }, [locale])

  useEffect(() => {
    if (isLoad) {
      dispatch(resetEconomy())
      handleSubmitTable()
    }
  }, [conditionStore])

  useEffect(() => {
    if (!columnId.length) {
      dispatch(resetColumn())
    } else if (isLoad) {
      if (columnId.length) {
        const { conditionType, condition } = convertCondition(conditionStore)
        const param = convertParamGetResultEconomy(
          conditionType,
          condition,
          columnId,
          columnById,
        )
        if (param) {
          dispatch(getResultThunk(param))
        }
      }
    }
  }, [columnId.length])

  const handleSubmitTable = () => {
    if (conditionStore) {
      const { conditionType, condition } = convertCondition(conditionStore)
      const param = convertParamGetResultEconomy(
        conditionType,
        condition,
        columnId,
        columnById,
      )
      if (param) {
        dispatch(getResultThunk(param))
      }
    } else if (columnId.length) {
      dispatch(handlePopupSetting())
    }
  }

  const handleShowExport = () => {
    if (conditionStore) {
      setIsShowPopupExport(true)
    } else {
      dispatch(handlePopupSetting())
    }
  }

  const handleHideExport = () => setIsShowPopupExport(false)

  const handleSubmitExport = async (sheetIds) => {
    const param = {
      parameters: [],
    }

    sheetIds.forEach((value) => {
      const stateSheet = sheets[value]
      if (stateSheet.condition) {
        const { conditionType, condition } = convertCondition(
          stateSheet.condition,
        )
        param.parameters.push({
          sheetName: getSheetName(listSheet, value),
          header: stateSheet.columnId.map((id, index) => {
            return {
              order: index + 1,
              alias: id,
              value: getHeaderIndicator(
                stateSheet.columnById[id],
                indicatorById,
              ),
            }
          }),
          paramerters: convertParamGetResultEconomy(
            conditionType,
            condition,
            stateSheet.columnId,
            stateSheet.columnById,
          ),
        })
      }
    })

    const filterParam = {
      parameters: param.parameters.filter((sheet) => sheet.paramerters),
    }
    handleHideExport()
    if (filterParam.parameters.length > 0) {
      setIsLoadingDownloadCsv(true)
      await dispatch(exportExcelThunk(filterParam))
      setIsLoadingDownloadCsv(false)
    }
  }

  const handleShowSaveAs = () => {
    if (templates.length >= maxTemplate) {
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <Translate value="tool.dataExplorer.corporate.MAX_TEMPLATE_1" />,
        <Translate value="tool.dataExplorer.corporate.MAX_TEMPLATE_2" />,
      ])
    } else {
      setIsShowPopupSaveAs(true)
    }
  }

  const handleHideSaveAs = () => setIsShowPopupSaveAs(false)

  const handleSubmitSaveAs = (templateName) => {
    const newColumnById = Object.values(columnById).reduce(
      (obj, item) => ({
        ...obj,
        [item.indicatorId]: { indicatorId: item.indicatorId },
      }),
      {},
    )

    const param = {
      templateName,
      dataType: typeData.ECONOMY,
      parameter: {
        header: [],
        parameter: DEFAULT_PARAM_TEMPLATE,
        rawParameter: {
          condition: conditionStore,
          columnById: newColumnById,
        },
      },
    }
    dispatch(saveTemplateThunk(param))
    handleHideSaveAs()
  }

  const handleSave = () => {
    if (detailSheet?.templateId) {
      const newColumnById = Object.values(columnById).reduce(
        (obj, item) => ({
          ...obj,
          [item.indicatorId]: { indicatorId: item.indicatorId },
        }),
        {},
      )

      const param = {
        templateId: detailSheet.templateId,
        templateName: detailSheet.title,
        dataType: typeData.ECONOMY,
        parameter: {
          header: [],
          parameter: DEFAULT_PARAM_TEMPLATE,
          rawParameter: {
            condition: conditionStore,
            columnById: newColumnById,
          },
        },
      }
      dispatch(updateTemplateThunk(param))
    }
  }

  const isDisableSaveTemplate = !columnId.length
  const isDisableLoad = isLoad || !columnId.length

  return (
    <div className="d-flex j-b">
      <div className="d-flex ali-center">
        <Button
          style={{ fontSize: 11 }}
          className={`btn h-20 ${style.btnLoad} ${
            isDisableLoad ? style.disabled : ''
          }`}
          onClick={handleSubmitTable}
          disabled={isDisableLoad}
        >
          <Translate value="tool.dataExplorer.LOAD" />
        </Button>
        <div>
          <Translate value="tool.dataExplorer.corporate.NUMBER_OF_RESULT" />:{' '}
          {economyId?.length}
        </div>
      </div>
      <div className="group-btn">
        <Button
          onClick={handleShowSaveAs}
          disabled={isDisableSaveTemplate}
          style={{ fontSize: 11 }}
          className={`btn btn-blue w-100px h-20 ${
            isDisableSaveTemplate ? style.disabled : ''
          }`}
        >
          {detailSheet?.templateId ? (
            <Translate value="common.button.BUTTON_SAVE_AS" />
          ) : (
            <Translate value="common.button.BUTTON_SAVE" />
          )}
        </Button>
        {detailSheet?.templateId && (
          <Button
            onClick={handleSave}
            disabled={isDisableSaveTemplate}
            style={{ fontSize: 11, marginLeft: 16 }}
            className={`btn btn-blue w-80 h-20 ${
              isDisableSaveTemplate ? style.disabled : ''
            }`}
          >
            <Translate value="common.button.BUTTON_SAVE" />
          </Button>
        )}
        <Button
          style={{ fontSize: 10 }}
          className={`btn btn-blue h-20 btn-export ${style.btnExport} ${
            !isCanExport ? style.disabled : ''
          }`}
          onClick={handleShowExport}
          disabled={!isCanExport}
        >
          <i className={`icon-print ${style.iconPrint}`} />
          <Translate value="tool.smartScreening.EXPORT" />
        </Button>
      </div>
      {isShowPopupExport && (
        <PopupExportSheet
          sheet={listSheet}
          activeSheet={activeSheet}
          handleSubmit={handleSubmitExport}
          handleCancel={handleHideExport}
        />
      )}
      {isShowPopupSaveAs && (
        <PopupSaveTemplate
          templates={templates}
          handleHidePopupSaveAs={handleHideSaveAs}
          handleSubmit={handleSubmitSaveAs}
        />
      )}
    </div>
  )
}

export default GroupButton
