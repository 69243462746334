import { languageObjs } from '../../../../../configs/Language'
import { TYPE } from '../../../../common/chart/footer/Item'
import { getLanguage } from '../../../../utils/Language'
import { STACK_COLORS } from '../../constants'

export const getTooltipContentData = (stackKeyIds, payload, stackKeys) => {
  return stackKeyIds?.map((id) => {
    return {
      label: stackKeys[id],
      value: payload[id],
    }
  })
}

export const renderListFooter = (stackContent) => {
  const MAX_STACK = 6
  const dataLength =
    stackContent.length > MAX_STACK ? MAX_STACK : stackContent.length
  return stackContent.map((item, index) => {
    return {
      text: item,
      type: TYPE.SQUARE,
      before: {
        bgColor: STACK_COLORS?.[dataLength]?.[index],
      },
      isI18n: false,
    }
  })
}

export const handleStackedData = (payload) => {
  if (!payload.length) {
    return []
  }

  const years = getYearNotDuplicates(payload)
  return years.map((year) => {
    const dataWithYear = payload.filter((item) => item.year === year)
    const dataWithYearKey = dataWithYear.reduce((previous, current) => {
      return {
        ...previous,
        [current.nationalAccountVNTypeId]: current.percentageValue * 100,
      }
    }, {})
    const otherIndicators = handleOtherIndicators(dataWithYearKey)

    return { ...dataWithYearKey, ...otherIndicators, year }
  })
}

const getYearNotDuplicates = (payload) => {
  const yearsNotDuplicates = handleDuplicateByKey(payload, 'year')
  return yearsNotDuplicates.map((item) => item.year)
}

const handleDuplicateByKey = (payload, key) => {
  return payload.filter((item, index, array) => {
    return index === array.findIndex((element) => element[key] === item[key])
  })
}

const handleOtherIndicators = (dataWithYearKey) => {
  const ONE_HUNDRED_PERCENT = 100
  const values = Object.values(dataWithYearKey)
  const total = values.reduce((previous, current) => {
    return previous + current
  }, 0)

  return { other: ONE_HUNDRED_PERCENT - total }
}

export const handleStackKey = (payload) => {
  if (!payload.length) {
    return []
  }

  const language = getLanguage()
  const idNotDuplicate = handleDuplicateByKey(
    payload,
    'nationalAccountVNTypeId',
  )
  const idWithTypeName = idNotDuplicate.reduce((previous, current) => {
    return {
      ...previous,
      [current.nationalAccountVNTypeId]: current.nationalAccountVNTypeName,
    }
  }, {})

  return {
    ...idWithTypeName,
    other: language === languageObjs.vi ? 'Khác' : 'Others',
  }
}
