import {
  lineStyleTypes,
  lineWidthTypes,
  smoothLines,
} from './menuChartSettings/constants'

export const MAX_SECURITIES_ITEM = 300

export const SEC_GROUP = {
  COMPANY: 'companyIndicator',
  SECTOR: 'sectorIndicator',
  ECONOMY: 'Economy',
  INDEX: 'indexIndicator',
}

export const SEC_GROUP_INDICATOR = {
  COMPANY: 'Company',
  SECTOR: 'Sector',
  ECONOMY: 'Economy',
  INDEX: 'Index',
}

export const LIST_TYPE = {
  ALL: 'All',
  COMPANIES: 'companies',
  INDICES: 'indices',
  SECTOR: 'sector',
  ECONOMY: 'economy',
  WATCH_LIST: 'watchList',
}

export const LIST_TYPE_SHORT_TEXT = {
  COMPANIES: 'COMP',
  INDICES: 'INX',
  SECTOR: 'SEC',
  ECONOMY: 'ECO',
  WATCH_LIST: 'WTL',
}

export const TIME_RANGE = {
  ONE_MONTH: 'OneMonth',
  THREE_MONTHS: 'ThreeMonths',
  SIX_MONTHS: 'SixMonths',
  NINE_MONTHS: 'NineMonths',
  ONE_YEAR: 'OneYear',
  THREE_YEARS: 'ThreeYears',
  FIVE_YEARS: 'FiveYears',
  TEN_YEARS: 'TenYears',
  ALL_TIME: 'AllTime',
  MONTH_TO_DATE: 'MonthToDate',
  QUARTER_TO_DATE: 'QuarterToDate',
  YEAR_TO_DATE: 'YearToDate',
}

export const ICB_LEVELS = {
  ICB_LEVEL_1: 1,
  ICB_LEVEL_2: 2,
  ICB_LEVEL_3: 3,
  ICB_LEVEL_4: 4,
  ICB_LEVEL_5: 5,
}

export const strokeDasharrayTypes = {
  [lineStyleTypes.SOLID]: '',
  [lineStyleTypes.DASHED]: '3 3',
  [lineStyleTypes.DOTTED]: '1 5',
}

export const strokeWidthTypes = {
  [lineWidthTypes.THIN]: 1,
  [lineWidthTypes.NORMAL]: 2,
  [lineWidthTypes.THICK]: 3,
}

export const lineTypes = {
  [smoothLines.YES]: 'monotone',
  [smoothLines.NO]: 'linear',
}

export const MAX_SECURITIES_ADD = 30

export const typeTreeItem = {
  GROUP_DASHBOARD: 'groupDashboard',
  DASHBOARD: 'Dashboard',
  CHART: 'Chart',
}

export const VIEW_TYPE = {
  BY_TIME: 'ByTime',
  BY_SECURITIES: 'BySecurities',
}

export const mapKeyIndicator = {
  [SEC_GROUP.COMPANY]: LIST_TYPE.COMPANIES,
  [SEC_GROUP.INDEX]: LIST_TYPE.INDICES,
  [SEC_GROUP.SECTOR]: LIST_TYPE.SECTOR,
}

export const XAXIS_DATA_KEY = {
  DATE: 'date',
  SECURITY_NAME: 'securityName',
}
