import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import DropdownTooltipCustom from '../textEllipsis/DropdownTooltipCustom'

export const TooltipWarning = ({
  style,
  tooltip,
  isI18n,
  isHtml,
  alignItems,
  iconColor,
  iconFontSize,
  iconPaddingTop,
  styleTextTooltip,
  zIndexTooltip,
  dropdownSpace,
  maxWidthTooltip,
}) => {
  const ref = useRef()

  const [isShow, setIsShow] = useState(false)

  const onMouseEnter = () => {
    setIsShow(true)
  }

  const onMouseLeave = () => {
    setIsShow(false)
  }

  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{
          alignItems: alignItems ?? 'flex-end',
          ...style,
        }}
      >
        <i
          ref={ref}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="icon-warning-circle cursor-pointer"
          style={{
            color: iconColor,
            fontSize: iconFontSize,
            paddingTop: iconPaddingTop,
          }}
        />
      </div>
      {isShow && tooltip && (
        <DropdownTooltipCustom
          containerRef={ref}
          text={tooltip}
          isI18n={isI18n}
          isHtml={isHtml}
          styleTextTooltip={styleTextTooltip}
          zIndexTooltip={zIndexTooltip}
          dropdownSpace={dropdownSpace}
          maxWidthTooltip={maxWidthTooltip}
        />
      )}
    </>
  )
}

TooltipWarning.propTypes = {
  tooltip: PropTypes.string.isRequired,
  isI18n: PropTypes.bool,
  style: PropTypes.object,
  icon: PropTypes.any,
  alignItems: PropTypes.string,
  iconColor: PropTypes.string,
  iconFontSize: PropTypes.number,
  iconPaddingTop: PropTypes.number,
  maxWidthTooltip: PropTypes.number,
  styleTextTooltip: PropTypes.object,
  zIndexTooltip: PropTypes.number,
  dropdownSpace: PropTypes.number,
}

TooltipWarning.defaultProps = {
  isI18n: true,
  isHtml: false,
  style: {},
  iconColor: '#FACC5C',
  iconFontSize: 12,
  iconPaddingTop: 0,
  styleTextTooltip: {},
  dropdownSpace: 10,
  maxWidthTooltip: 300,
}
