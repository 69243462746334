import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import style from '../../index.module.css'
import { COLOR_BAR } from './constants'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 7,
}

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 20 }

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  barKeys,
  listCheckbox,
}) => {
  const chartTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{valueTooltip.name}</span>
        </div>
        <table>
          <tbody>
            {listCheckbox?.includes(barKeys[0]) ? (
              <tr className={style.trTable}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>
                      {I18n.t('sector.sectorSpecific.steel.PRODUCTION')}
                    </Span>
                    :
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span style={{ fontSize: 11 }}>
                    {`${formatVal(valueTooltip[barKeys[0]], 0)} ${I18n.t(
                      'sector.sectorSpecific.steel.TONS',
                    )}`}
                  </Span>
                </td>
              </tr>
            ) : (
              <></>
            )}
            {listCheckbox?.includes(barKeys[1]) ? (
              <tr className={style.trTable}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>
                      {I18n.t('sector.sectorSpecific.steel.CONSUMPTION')}
                    </Span>
                    :
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span style={{ fontSize: 11 }}>
                    {`${formatVal(valueTooltip[barKeys[1]], 0)} ${I18n.t(
                      'sector.sectorSpecific.steel.TONS',
                    )}`}
                  </Span>
                </td>
              </tr>
            ) : (
              <></>
            )}
            {listCheckbox?.includes(barKeys[2]) ? (
              <tr className={style.trTable}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>
                      {I18n.t('sector.sectorSpecific.steel.INVENTORY')}
                    </Span>
                    :
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span style={{ fontSize: 11 }}>
                    {`${formatVal(valueTooltip[barKeys[2]], 0)} ${I18n.t(
                      'sector.sectorSpecific.steel.TONS',
                    )}`}
                  </Span>
                </td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height}
      keyXAxis={keyXAxis}
      margin={MARGIN}
      xTickNum={SETTINGS.xTickNum}
      hasBarChart
      isShowXAxis
      isUseXAxisDiv
      showAllTick
      yAxis={[
        {
          id: 'left',
          orientation: 'left',
          keys: barKeys,
          isBarChart: true,
          tickNum: SETTINGS.yTickNum,
          label: `(${I18n.t('sector.sectorSpecific.steel.TONS')})`,
          labelPosition: AXIS_LABEL_POSITION.LEFT,
        },
      ]}
      renderCustomTooltip={(payload) => chartTooltip(payload)}
    >
      {listCheckbox?.includes(barKeys[0]) ? (
        <Bar
          yAxisId={'left'}
          dataKey={barKeys[0]}
          barSize={getColumnSizeInBarChart(width, data.length, 25)}
          isAnimationActive={false}
          fill={COLOR_BAR.LIGHT_BLUE}
        />
      ) : (
        <></>
      )}
      {listCheckbox?.includes(barKeys[1]) ? (
        <Bar
          yAxisId={'left'}
          dataKey={barKeys[1]}
          barSize={getColumnSizeInBarChart(width, data.length, 25)}
          isAnimationActive={false}
          fill={COLOR_BAR.BLUE}
        />
      ) : (
        <></>
      )}
      {listCheckbox?.includes(barKeys[2]) ? (
        <Bar
          yAxisId={'left'}
          dataKey={barKeys[2]}
          barSize={getColumnSizeInBarChart(width, data.length, 25)}
          isAnimationActive={false}
          fill={COLOR_BAR.YELLOW}
        />
      ) : (
        <></>
      )}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}

export default ChartComponent
