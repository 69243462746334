import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { HandleClickOutside } from '../../common/HandleClickOutside'
import { getTextWidth } from '../../common/chart/helper'
import UseFontSize from '../../common/hooks/useFontSize'
import { Input } from '../../common/html/Input'
import { Span } from '../../common/html/Span'
import { TextEllipsisStaticLine } from '../../common/textEllipsis/TextEllipsisStaticLine'
import EventEmitter, {
  FC_HANDLE_CLICK_OUTSIDE_DASHBOARD_TITLE,
} from '../../utils/EventEmitter'
import { getFontSize } from '../../utils/FontSize'

export const Title = ({
  appendStyle,
  className,
  data,
  fontSize,
  fontWeight,
  isEdit,
  isNewDashboard,
  maxWidth,
  setIsEdit,
  setIsActive,
  setIsHover,
  onSubmit,
  isI18n,
  exceptRef,
}) => {
  const inputRef = useRef()
  const typeFontSize = UseFontSize()
  const [value, setValue] = useState(data.name)
  const [inputFocus, setInputFocus] = useState(true)

  useEffect(() => {
    setValue(data.name)
  }, [data.name])

  useEffect(() => {
    if (!inputFocus && (isEdit || isNewDashboard)) {
      setInputFocus(true)
      inputRef.current && inputRef.current.focus()
    }
  }, [inputFocus, isEdit, isNewDashboard])

  useEffect(() => {
    EventEmitter.subscribe(
      FC_HANDLE_CLICK_OUTSIDE_DASHBOARD_TITLE,
      onClickOutside,
    )

    return () => {
      EventEmitter.unsubscribe(
        FC_HANDLE_CLICK_OUTSIDE_DASHBOARD_TITLE,
        onClickOutside,
      )
    }
  }, [])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClickOutside()
    }
  }

  const onClickOutside = () => {
    if (isEdit) {
      if (!isNewDashboard) {
        setIsEdit(false)
        setIsActive(false)
        setIsHover(false)
      }
      setInputFocus(false)
      onSubmit({
        ...data,
        name: value,
      })
    }
  }

  const onFocus = (e) => {
    e.target.select()
    setInputFocus(true)
  }

  return (
    <>
      {!isEdit && (
        <Span
          className={className}
          style={{ fontSize, maxWidth, fontWeight, ...appendStyle }}
        >
          <TextEllipsisStaticLine
            isI18n={isI18n}
            val={value || ''}
            lineClamp={1}
          />
        </Span>
      )}
      {isEdit && (
        <HandleClickOutside
          handleClickOutside={onClickOutside}
          exceptRef={exceptRef}
          appendStyle={{ maxWidth }}
        >
          <Input
            ref={inputRef}
            className={className}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoFocus
            onKeyDown={handleKeyDown}
            onFocus={onFocus}
            style={{
              padding: 0,
              fontSize,
              minWidth: 20,
              maxWidth: '100%',
              fontWeight,
              backgroundColor: '#3f8ec5',
              color: '#ffffff',
              width: getTextWidth(
                value,
                getFontSize(fontSize, typeFontSize),
                fontWeight,
              ),
              ...appendStyle,
            }}
          />
        </HandleClickOutside>
      )}
    </>
  )
}

Title.propTypes = {
  appendStyle: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  isEdit: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setIsEdit: PropTypes.func,
  setIsHover: PropTypes.func,
  setIsActive: PropTypes.func,
  onSubmit: PropTypes.func,
  isI18n: PropTypes.bool,
}

Title.defaultProps = {
  appendStyle: {},
  className: '',
  fontSize: 12,
  fontWeight: 500,
  isEdit: false,
  maxWidth: '100%',
  setIsEdit: () => {},
  setIsHover: () => {},
  setIsActive: () => {},
  onSubmit: () => {},
  isI18n: true,
  exceptRef: null,
}
