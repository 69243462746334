import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import style from './style.module.css'
import { selectValuationChart } from './valuationChart/store/slice'

const FooterValuation = () => {
  const dataChart = useSelector(selectValuationChart)

  return (
    <>
      <span className={style.spanTableColor}>
        <Translate value={'market.marketInDepth.valuation.TABLE_FOOTER_TEXT'} />
        &nbsp;
        {dataChart.length ? (
          <DateTimeSpan date={dataChart[dataChart.length - 1].tradingDateId} />
        ) : (
          ''
        )}
      </span>
    </>
  )
}

export default FooterValuation
