import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { getDataLatestPeriod } from '../../../store/thunk'

const initialState = {
  indicatorSelected: {
    companyIndicator: [],
    indexIndicator: [],
    sectorIndicator: [],
  },
  dataLatestPeriod: {},
}

export const slice = createSlice({
  name: 'financialChart/popup/popupAddIndicator',
  initialState,
  reducers: {
    addIndicator: (state, action) => {
      if (action.payload.typeIndicator) {
        state.indicatorSelected[action.payload.typeIndicator] = [
          ...state.indicatorSelected[action.payload.typeIndicator],
          action.payload.value,
        ]
      }
    },
    removeIndicator: (state, action) => {
      const { typeIndicator, indicatorId, indicatorTimeFrequency, uniqueID } =
        action.payload
      if (uniqueID) {
        state.indicatorSelected[typeIndicator] = state.indicatorSelected[
          typeIndicator
        ].filter(
          (i) =>
            !(
              i.id === indicatorId &&
              i.timeFrequency === indicatorTimeFrequency &&
              i.uniqueID === uniqueID
            ),
        )
      } else {
        state.indicatorSelected[typeIndicator] = state.indicatorSelected[
          typeIndicator
        ].filter(
          (i) =>
            !(
              i.id === indicatorId && i.timeFrequency === indicatorTimeFrequency
            ),
        )
      }
    },
    editIndicator: (state, action) => {
      const { value, typeIndicator } = action.payload
      state.indicatorSelected[typeIndicator] = state.indicatorSelected[
        typeIndicator
      ].map((i) =>
        i.id === value.id &&
        i.timeFrequency === value.timeFrequency &&
        i.uniqueID === value.uniqueID
          ? { ...value }
          : i,
      )
    },
    changeFullIndicator: (state, action) => {
      state.indicatorSelected = action.payload
    },
    resetIndicators: (state) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key]),
      )
    },
    resetIndicatorSelectedByKey: (state, action) => {
      state.indicatorSelected[action.payload.typeIndicator] = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataLatestPeriod.pending, (state) => {})
    builder.addCase(getDataLatestPeriod.fulfilled, (state, action) => {
      state.dataLatestPeriod = action.payload
    })
    builder.addCase(getDataLatestPeriod.rejected, (state) => {})
  },
})

export const selectIndicatorSelected = (state) =>
  state[slice.name].indicatorSelected
export const selectIndicatorByName = (name) => (state) =>
  valByKeyWithDot(state[slice.name].indicatorSelected, name)
export const selectDataLatestPeriod = (state) =>
  state[slice.name].dataLatestPeriod

export const {
  addIndicator,
  removeIndicator,
  editIndicator,
  changeFullIndicator,
  resetIndicators,
  resetIndicatorSelectedByKey,
} = slice.actions

register(slice.name, slice.reducer)
