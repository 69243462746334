import { Loading } from '../../../common/loading'
import ChartContainer from './ChartContainer'

const ChartInPanel = ({
  data,
  item,
  loading,
  schema,
  footerData,
  isEdit,
  setIsEdit,
  width,
  height,
  isHaveDataChartPreview,
}) => {
  return (
    <div className="position-relative w-100 h-100">
      {loading ? (
        <Loading isBlack />
      ) : (
        <ChartContainer
          item={item}
          data={data}
          width={width}
          height={height}
          schema={schema}
          footerData={footerData}
          isHaveDataChartPreview={isHaveDataChartPreview}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
    </div>
  )
}

export default ChartInPanel
