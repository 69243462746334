import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Z_INDEX } from '../../../../constants/Common'
import Sort from './Sort'
import { sortStateMap } from './helper'
import style from './index.module.css'
import {
  selectIdsCompany,
  selectListIdSelect,
  updateListId,
} from './store/slice'

const TableHeader = ({ tableHeaderColAttr, stateSort, sortColumn }) => {
  const dispatch = useDispatch()
  const idSelect = useSelector(selectListIdSelect)

  const ids = useSelector(selectIdsCompany)
  const renderTitle = (text, styleHeader) => {
    return (
      <Span
        className={style.theadContent}
        style={{
          fontSize: 12,
          fontWeight: 600,
          ...styleHeader,
        }}
      >
        <Translate value={text} />
      </Span>
    )
  }

  const onClickHandler = (e) => {
    e.stopPropagation()
    e.cancelBubble = true
    if (idSelect.length === ids.length && ids.length !== 0) {
      dispatch(updateListId([]))
    } else {
      dispatch(updateListId(ids))
    }
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      <tr>
        {tableHeaderColAttr.map((row, index) => {
          if (row.key === 'name') {
            return (
              <th
                key={row.name}
                {...row?.attrs}
                className={`${!row.value?.length && style.borderBottomNone} ${
                  !row.additionText && style.verticalmiddle
                }`}
                onClick={() => {
                  sortColumn({
                    [row.key]: sortStateMap[stateSort[row.key]],
                  })
                }}
              >
                <div
                  className={stateSort[row?.key] ? 'mr-5' : undefined}
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  <div>
                    <input
                      type="checkbox"
                      className={`checkbox ${style.checkbox}`}
                      checked={
                        idSelect.length === ids.length && ids.length !== 0
                          ? true
                          : false
                      }
                      onClick={(e) => onClickHandler(e)}
                    />
                  </div>
                  <div
                    style={{
                      paddingLeft: '10px',
                    }}
                  >
                    {renderTitle(row.title, row?.attrs?.styleHeader)}
                  </div>
                </div>
                {!row?.disableSort && !row?.hideIconSort && (
                  <Sort type={stateSort[row.key]} />
                )}
              </th>
            )
          } else {
            return (
              <th
                key={row.name}
                {...row?.attrs}
                className={`${!row.value?.length && style.borderBottomNone} ${
                  !row.additionText && style.verticalAlignInitial
                }`}
                onClick={() => {
                  sortColumn({
                    [row.key]: sortStateMap[stateSort[row.key]],
                  })
                }}
              >
                <div className={stateSort[row?.key] ? 'mr-5' : undefined}>
                  {renderTitle(row.title, row?.attrs?.styleHeader)}
                  {row.additionText && (
                    <Span
                      className={style.additionText}
                      style={{ fontSize: 12 }}
                    >
                      <Translate value={row.additionText} />
                    </Span>
                  )}
                </div>

                {!row?.disableSort && !row?.hideIconSort && (
                  <Sort type={stateSort[row.key]} />
                )}
              </th>
            )
          }
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
