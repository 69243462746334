import { Icon } from '../../../../common/html/Icon'
import Col from '../common/Col'
import Row from '../common/Row'
import style from './toiTable/toiTable.module.css'

const Separator = () => {
  return (
    <Row
      styleProps={{
        backgroundColor: '#1e242e',
        borderBottomColor: '#3e485a',
      }}
    >
      <Col
        colSpan={1}
        className={style.tableDataDropdown}
        isHoverOutline={false}
      >
        <Icon
          style={{ fontSize: 17, color: '#8c9194' }}
          className="icon-caret"
        />
      </Col>
      <Col
        colSpan={4}
        className={style.tableDataDropdown}
        isHoverOutline={false}
      />
    </Row>
  )
}

export default Separator
