import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { ReactComponent as IconBookMark } from '../../../../icon/icon-bookmark-white.svg'
import { ReactComponent as IconClose } from '../../../../icon/icon-close.svg'
import styles from '../../style.module.css'

export const ColorSelectOptions = ({
  isFavorite,
  options,
  onChange,
  removeFavoriteColor,
}) => {
  const [isCollapse, setIsCollapse] = useState(false)

  return (
    <div>
      <div
        className="d-flex ali-center"
        onClick={() => setIsCollapse(!isCollapse)}
      >
        <Span style={{ fontSize: 10, opacity: 0.4, marginRight: 10 }}>
          {isFavorite ? (
            <Translate value="financialChart.chartSettings.FAVORITE_COLOR" />
          ) : (
            <Translate value="financialChart.SUGGEST_COLOR" />
          )}
        </Span>
        <i
          className={isCollapse ? 'icon-arrow-down' : 'icon-arrow-up'}
          style={{ fontSize: 8, color: '#8b8b8b' }}
        />
      </div>
      {!isCollapse && (
        <div className="d-flex ali-center" style={{ marginTop: 4 }}>
          {options.map((color, index) => (
            <ColorSelectItem
              key={index}
              color={color}
              isFavorite={isFavorite}
              onChange={onChange}
              removeFavoriteColor={removeFavoriteColor}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const ColorSelectItem = ({
  color,
  isFavorite,
  onChange,
  removeFavoriteColor,
}) => {
  const [isShowCloseIcon, setIsShowCloseIcon] = useState(false)

  const handleRemoveFavoriteColor = (e) => {
    e.stopPropagation()
    if (isFavorite && removeFavoriteColor) {
      removeFavoriteColor(color)
    }
  }

  return (
    <div
      className={styles.settingBlockItemColorOption}
      style={{ backgroundColor: color }}
      onClick={() => onChange(color)}
      onMouseEnter={() => setIsShowCloseIcon(true)}
      onMouseLeave={() => setIsShowCloseIcon(false)}
    >
      {isFavorite && (
        <IconBookMark
          style={{
            position: 'absolute',
            top: 3,
            right: 3,
            width: 6,
            height: 6,
          }}
        />
      )}
      {isShowCloseIcon && (
        <IconClose
          style={{
            position: 'absolute',
            top: -12,
            right: -12,
            width: 16,
            height: 16,
          }}
          onClick={(e) => handleRemoveFavoriteColor(e)}
        />
      )}
    </div>
  )
}
