import useGetBond from '../masterData/useGetBond'
import useGetCoveredWarrant from '../masterData/useGetCoveredWarrant'
import useGetICB from '../masterData/useGetICB'
import useGetIndices from '../masterData/useGetIndices'
import useGetStock from '../masterData/useGetStock'

export const TYPE_STOCK_INDICES_SECTOR = {
  STOCK: 'stock',
  INDICES: 'indices',
  SECTOR: 'sector',
  COVERER_WARRANT: 'coveredWarrant',
  BOND: 'bond',
}

const useBasicInfo = () => {
  const { indices } = useGetIndices()
  const { ICBs } = useGetICB()
  const { stocks } = useGetStock()
  const { coveredWarrants } = useGetCoveredWarrant()
  const { bonds } = useGetBond()

  const getBasicInfo = (id, type) => {
    if (type === TYPE_STOCK_INDICES_SECTOR.STOCK) {
      return stocks.find((item) => item.organizationId === id)
    } else if (type === TYPE_STOCK_INDICES_SECTOR.INDICES) {
      return indices.find((item) => item.groupId === id)
    } else if (type === TYPE_STOCK_INDICES_SECTOR.SECTOR) {
      const sector = ICBs.find(
        (item) =>
          item.icbId === id || item.icbName + (item.icbLevel || '') === id,
      )
      return {
        ...sector,
        icbNameCustom: sector?.icbName + ' L' + sector?.icbLevel,
      }
    } else if (type === TYPE_STOCK_INDICES_SECTOR.COVERER_WARRANT) {
      return coveredWarrants.find((item) => item.coveredWarrantId === id)
    } else if (type === TYPE_STOCK_INDICES_SECTOR.BOND) {
      return bonds.find((item) => item.bondId === id)
    } else {
      return
    }
  }

  return { getBasicInfo }
}

export default useBasicInfo
