import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { getTwoColorByValue } from '../../../../utils/Color'
import { formatVal } from '../../../../utils/Value'
import { DATA_TYPE_LIST } from '../exportImportTable/constants'
import {
  selectListCategory,
  selectTypeData,
} from '../exportImportTable/store/slice'
import style from '../index.module.css'
import { COLORS_LINE_CHART } from './constants'
import { selectActiveCategory } from './store/slice'

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 40, bottom: 20 }

export const ChartComponent = ({ data, width, height, keyXAxis, lineKeys }) => {
  const listCategory = useSelector(selectListCategory)
  const activeCategory = useSelector(selectActiveCategory)
  const currentTypeData = useSelector(selectTypeData)

  const isGrowth = currentTypeData === DATA_TYPE_LIST.GROWTH
  const exportTurnOverTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{valueTooltip.time}</span>
        </div>
        <table>
          <tbody>
            {listCategory.map((item) => (
              <tr className={style.trTable} key={item.id}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>{item.name}</Span>:
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span
                    style={{
                      fontSize: 11,
                      color: getTwoColorByValue(valueTooltip[item.vnTypeId]),
                    }}
                  >
                    {valueTooltip[item.vnTypeId] === EMPTY_VALUE
                      ? valueTooltip[item.vnTypeId]
                      : `${formatVal(valueTooltip[item.vnTypeId])} ${
                          !isGrowth
                            ? I18n.t(
                                'economy.importExport.importExport.MILLION_USD',
                              )
                            : '%'
                        }`}
                  </Span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      <ChartContainer
        data={data}
        width={width}
        height={height}
        keyXAxis={keyXAxis}
        margin={MARGIN}
        yAxis={[
          {
            id: 'left',
            keys: lineKeys,
            isLineChart: true,
            orientation: 'left',
            label:
              currentTypeData === DATA_TYPE_LIST.VALUE
                ? `(${I18n.t('economy.importExport.importExport.MILLION_USD')})`
                : '',
            labelPosition: AXIS_LABEL_POSITION.LEFT,
            unitYAxis: currentTypeData === DATA_TYPE_LIST.VALUE ? '' : '%',
          },
        ]}
        renderCustomTooltip={(payload, decimalLengths) =>
          exportTurnOverTooltip(payload, decimalLengths)
        }
        timeFrame={TIME_RANGES.CUSTOM}
        isNotFormatXAxis
      >
        {lineKeys.map((item) => (
          <Line
            key={item}
            yAxisId="left"
            dataKey={item}
            type="linear"
            stroke={COLORS_LINE_CHART[lineKeys.indexOf(item)]}
            dot={false}
            activeDot={false}
            strokeOpacity={
              !activeCategory ? 1 : activeCategory === item ? 1 : 0.1
            }
            isAnimationActive={false}
            strokeWidth={
              !activeCategory ? 1.5 : activeCategory === item ? 2.5 : 1.5
            }
          />
        ))}
      </ChartContainer>
    </>
  )
}
