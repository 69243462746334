import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _, { sumBy } from 'lodash'
import Comparable from '../../../../../../../core/services/bond/comparable/comparable'
import MasterData from '../../../../../../../core/services/bond/comparable/masterData'
import MacroService from '../../../../../../../core/services/common/MacroService'
import PeerValuationService from '../../../../../../../core/services/corporate/PeerValuationService'
import { handleExport } from '../../../../../../utils/Export'

export const getMaxPeriod = createAsyncThunk(
  'corporate/peerValuation/overview/getMaxPeriod',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await PeerValuationService.getMaxPeriod(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getExchangeRates = createAsyncThunk(
  'corporate/peerValuation/overview/GET_EXCHANGE_RATES',
  async (params, { rejectWithValue }) => {
    const response = await MacroService.getExchangeRates(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getData = createAsyncThunk(
  'bond/comparable/GET_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Comparable.getDataList(params)
      if (response.success) {
        const data =
          response?.data
            .map((e, i) => ({
              ...e,
              id: i,
              bS64: e?.bS64 || 0,
              rT0372: e?.rT0372 || 0,
              rT0027: e?.rT0027 || 0,
              rT0046: e?.rT0046 || 0,
              rT0075: e?.rT0075 || 0,
              rT0093: e?.rT0093 || 0,
              rT0328: e?.rT0328 || 0,
              // rT0378: e?.rT0378 || 0,
              rT0037: e?.rT0037 || 0,
            }))
            .sort((a, b) => {
              if (a.organizationId === params.organizationId) {
                return -1
              } else if (b.organizationId === params.organizationId) {
                return 1
              } else {
                return 0
              }
            }) || []
        const dataAddTotal = [
          ...data,
          {
            organizationId: 'total',
            bS64: sumBy(data, (e) => e?.bS64 || 0) / data.length,
            organizationShortName: '',
            rT0027: sumBy(data, (e) => e?.rT0027 || 0) / data.length,
            rT0037: sumBy(data, (e) => e?.rT0037 || 0) / data.length,
            rT0046: sumBy(data, (e) => e?.rT0046 || 0) / data.length,
            rT0075: sumBy(data, (e) => e?.rT0075 || 0) / data.length,
            rT0093: sumBy(data, (e) => e?.rT0093 || 0) / data.length,
            rT0328: sumBy(data, (e) => e?.rT0328 || 0) / data.length,
            rT0372: sumBy(data, (e) => e?.rT0372 || 0) / data.length,
            // rT0378: sumBy(data, (e) => e?.rT0378 || 0) / data.length,
            ticker: '',
          },
        ]
        const fields = [
          'bS64',
          'rT0027',
          'rT0037',
          'rT0328',
          'rT0046',
          'rT0075',
          'rT0372',
          // 'rT0378',
          'rT0093',
        ]
        const dataMinMax = {}

        fields.forEach((field) => {
          dataMinMax[field] = {
            max: _.maxBy(data, (e) => e?.[field] || 0)?.[field] || 0,
            min: _.minBy(data, (e) => e?.[field] || 0)?.[field] || 0,
          }
        })

        return {
          data: data.length > 0 ? dataAddTotal : [],
          dataMinMax,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getValuationThunk = createAsyncThunk(
  'bond/comparable/GET_VALUATION',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PeerValuationService.getPeerComparisonCorpValuationFetch(params)
      if (response.success) {
        return response?.data || []
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getICBs = createAsyncThunk(
  'bond/comparable/GETICBS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MasterData.getIcbIdSv()
      if (response.success) {
        const data = response?.data || []

        return data
      }
      return rejectWithValue('Error')
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const exportFileApi = createAsyncThunk(
  'bond/comparable/exportFile',
  async (data) => {
    const response = await Comparable.exportFile(data)
    handleExport(response.data, response.filename)
  },
)
