import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import SelectCustom from '../../../common/selectCustom'
import {
  FORMAT,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../utils/Datetime'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../utils/EventEmitter'
import { typeData } from '../constant'
import { checkDaily } from '../helper'
import style from '../index.module.css'
import {
  changeCondition,
  selectItemCondition,
  selectScreenerDate,
  selectTemplate,
  selectTypeInterims,
} from '../store/slice'

const Interim = ({ indicatorId, index, valueDataType, indicatorGroupId }) => {
  const dispatch = useDispatch()

  const timezone = UseTimeZone()

  const timeout = useRef()

  const screenerDate = useSelector(selectScreenerDate)
  const indicatorInterimId = useSelector(
    selectItemCondition(`${indicatorId}.${index}.indicatorInterimId`),
  )
  const dataTypeCode = useSelector(
    selectTypeInterims(`${indicatorGroupId}.${valueDataType}.dataTypeCode`),
  )
  const interims = useSelector(
    selectTypeInterims(`${indicatorGroupId}.${valueDataType}.interims`),
  )
  const template = useSelector(selectTemplate)
  const locale = useSelector((state) => state.i18n.locale)

  const selectData = interims.map((item) => {
    return { value: item.indicatorInterimId, name: item.interimName }
  })

  useEffect(() => {
    if (template.templateId && dataTypeCode !== typeData.DAILY) {
      if (selectData.every((item) => item.value !== indicatorInterimId)) {
        timeout.current = setTimeout(
          () =>
            EventEmitter.dispatch(SHOW_POPUP_ERROR, [
              I18n.t('tool.smartScreening.INTERIM_OUT_OF_DATE'),
            ]),
          200,
        )
      } else {
        clearTimeout(timeout.current)
        EventEmitter.dispatch(SHOW_POPUP_ERROR, null)
      }
    }
  }, [valueDataType])

  useEffect(() => {
    if (dataTypeCode === typeData.DAILY) {
      dispatch(
        changeCondition({
          key: `${indicatorId}.${index}.indicatorInterimId`,
          value: getISOStartOrEndOfDay(screenerDate, timezone, true),
        }),
      )
    }
  }, [screenerDate])

  const handleChangeInterim = (value) => {
    dispatch(
      changeCondition({
        key: `${indicatorId}.${index}.indicatorInterimId`,
        value,
      }),
    )
    dispatch(
      changeCondition({
        key: `${indicatorId}.${index}.interimCode`,
        value: interims.find((item) => item.indicatorInterimId === value)
          .interimCode,
      }),
    )
  }

  return (
    <div className={style.w48}>
      {checkDaily(
        dataTypeCode,
        <SelectCustom
          value={formatDateTime(screenerDate, FORMAT.DATE, locale, timezone)}
          selectData={[
            {
              value: formatDateTime(
                screenerDate,
                FORMAT.DATE,
                locale,
                timezone,
              ),
              name: formatDateTime(screenerDate, FORMAT.DATE, locale, timezone),
            },
          ]}
          handleChange={handleChangeInterim}
          isShowArrow={false}
          isDisable={true}
        />,
        <SelectCustom
          value={indicatorInterimId || ''}
          selectData={selectData}
          handleChange={handleChangeInterim}
        />,
      )}
    </div>
  )
}

Interim.propTypes = {
  indicatorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  index: PropTypes.number.isRequired,
  valueDataType: PropTypes.number.isRequired,
  indicatorGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

export default Interim
