import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TYPE_IMPORT_EXPORT } from '../../constants'
import { TIME_FILTER_TYPE } from '../../exportImportTable/constants'
import {
  selectActiveItem,
  selectImportExportDataType,
  selectListCategory,
  selectOrigParentCategory,
  selectTypeFilter,
} from '../../exportImportTable/store/slice'
import style from '../../index.module.css'
import { COLOR_GROWTH_CHART } from '../constants'
import {
  getExportGrowthByProduct,
  getImportGrowthByProduct,
  getMaxDateDetail,
} from '../store/thunk'
import { getValueChartName } from '../structureChart/helper'
import { selectCurrentYear } from '../structureChart/store/slice'
import { ChartComponent } from './ChartComponent'
import {
  selectDataChart,
  selectIsAcc,
  selectLoading,
  selectMaxDate,
  selectYearArr,
} from './store/slice'

const PADDING_BOTTOM_X_AXIS_KEY = 20
const DIFF_ID_ACC = 2

export const GrowthChart = ({ width, height }) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)
  const yearKeys = useSelector(selectYearArr)
  const currentYear = useSelector(selectCurrentYear)
  const activeCommodity = useSelector(selectActiveItem)
  const isAcc = useSelector(selectIsAcc)
  const currentTypeFilter = useSelector(selectTypeFilter)
  const importExportDataType = useSelector(selectImportExportDataType)
  const listCategory = useSelector(selectListCategory)
  const origCategories = useSelector(selectOrigParentCategory)
  const maxDate = useSelector(selectMaxDate)
  const locale = useSelector((state) => state.i18n.locale)

  const isImportData = importExportDataType === TYPE_IMPORT_EXPORT.IMPORT

  const activeItem = activeCommodity || listCategory[0]
  const activeCategoryId = activeItem?.vnTypeId

  const activeName = origCategories?.find(
    (item) => item.vnTypeId === activeCategoryId,
  )?.name

  useEffect(() => {
    dispatch(
      getMaxDateDetail({
        ExImConstant: importExportDataType,
      }),
    )
  }, [importExportDataType])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.IMPORT &&
      currentTypeFilter !== TIME_FILTER_TYPE.YEARLY &&
      maxDate
    ) {
      dispatch(
        getImportGrowthByProduct({
          DataType: currentTypeFilter,
          VNTypeId: activeCategoryId,
          Year: currentYear ?? maxDate?.realYear,
        }),
      )
    }
  }, [activeItem, currentYear, maxDate, importExportDataType])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.IMPORT &&
      currentTypeFilter === TIME_FILTER_TYPE.YEARLY &&
      maxDate
    ) {
      dispatch(
        getImportGrowthByProduct({
          DataType: isAcc
            ? TIME_FILTER_TYPE.ACC_MONTHLY
            : TIME_FILTER_TYPE.MONTHLY,
          VNTypeId: isAcc ? activeCategoryId : activeCategoryId + DIFF_ID_ACC,
          Year: currentYear ?? maxDate?.realYear,
        }),
      )
    }
  }, [isAcc, currentYear, activeItem, maxDate, importExportDataType])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.EXPORT &&
      currentTypeFilter !== TIME_FILTER_TYPE.YEARLY &&
      maxDate
    ) {
      dispatch(
        getExportGrowthByProduct({
          DataType: currentTypeFilter,
          VNTypeId: activeCategoryId,
          Year: currentYear ?? maxDate?.realYear,
        }),
      )
    }
  }, [currentYear, activeItem, maxDate, importExportDataType])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.EXPORT &&
      currentTypeFilter === TIME_FILTER_TYPE.YEARLY &&
      maxDate
    ) {
      dispatch(
        getExportGrowthByProduct({
          DataType: isAcc
            ? TIME_FILTER_TYPE.ACC_MONTHLY
            : TIME_FILTER_TYPE.MONTHLY,
          Year: currentYear ?? maxDate?.realYear,
          VNTypeId: isAcc ? activeCategoryId : activeCategoryId + DIFF_ID_ACC,
        }),
      )
    }
  }, [currentYear, activeItem, isAcc, maxDate, importExportDataType])

  return isLoading ? (
    <Loading />
  ) : !dataChart.length ? (
    <NoData />
  ) : (
    <SizeTracker>
      {(size) => (
        <div className="h-100">
          <div className={[style.titleHeader, 'canvas-chart-title'].join(' ')}>
            {getValueChartName(locale, isImportData, activeName).toUpperCase()}
          </div>
          {size.height && (
            <div
              style={{
                height: height - size.height - PADDING_BOTTOM_X_AXIS_KEY,
              }}
            >
              <ChartComponent
                data={dataChart}
                width={width}
                height={height - size.height - PADDING_BOTTOM_X_AXIS_KEY}
                keyXAxis={'time'}
                lineKeys={yearKeys}
                activeName={activeName}
              />
            </div>
          )}
          <Footer
            key={width}
            list={yearKeys.map((item) => ({
              text: `${item}`,
              type: TYPE.LINE,
              before: {
                bgColor: COLOR_GROWTH_CHART[yearKeys.indexOf(item)],
              },
            }))}
          />
        </div>
      )}
    </SizeTracker>
  )
}
