import PropTypes from 'prop-types'
import { useGetMonths } from '../../../common/calendar/helper'
import SelectMonthYear from '../../../common/calendar/SelectMonthYear'
import { range } from '../../../utils/Common'
import style from './style.module.css'

const HeaderCalendar = ({ startYear, endYear }) => {
  const months = useGetMonths(false)

  const years = range(startYear, endYear)

  return ({ date, changeYear, changeMonth }) => {
    return (
      <div className={style.headerRangeContainer}>
        <div className={style.selectContainer}>
          <div className={style.inputMonth}>
            <SelectMonthYear
              currentValue={months[date.getMonth()]}
              selectRef={
                <div className={`${style.inputSelect} `}>
                  {months[date.getMonth()]}
                  <i className={`icon-arrow-down ${style.iconArrow}`} />
                </div>
              }
              handleChange={(value) => changeMonth(months.indexOf(value))}
              options={months}
              marginTop={12}
              themeWhite
            />
          </div>
          <SelectMonthYear
            currentValue={date.getFullYear()}
            selectRef={
              <div className={style.inputSelect}>
                {date.getFullYear()}
                <i className={`icon-arrow-down ${style.iconArrow}`} />
              </div>
            }
            handleChange={(value) => changeYear(value)}
            options={years}
            marginTop={12}
            themeWhite
          />
        </div>
      </div>
    )
  }
}

HeaderCalendar.propTypes = {
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
}

export default HeaderCalendar
