import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { COMPONENT } from '../../../configs/Layout'
import useGetStock from '../../common/masterData/useGetStock'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import Content from './Content'
import {
  getVNIndexData,
  setStockData,
} from './setting/companyNews/company/store/slice'
import { getVNIndex } from './setting/companyNews/company/store/thunk'

const MIN_WIDTH_CONTAINER = 950

const DailyReport = () => {
  const dispatch = useDispatch()
  const vnIndex = useSelector(getVNIndexData)
  const { stocks } = useGetStock()

  useEffect(() => {
    dispatch(getVNIndex())
  }, [])

  useEffect(() => {
    if (stocks.length) {
      dispatch(setStockData(stocks))
    }
  }, [stocks, vnIndex])

  return (
    <div
      style={{
        marginTop: COMPONENT.MARGIN_TOP,
        height: `calc(100% - ${COMPONENT.MARGIN_TOP}px)`,
      }}
    >
      <ScrollGroupComponent>
        <div className={`h-100`} style={{ minWidth: MIN_WIDTH_CONTAINER }}>
          <Content />
        </div>
      </ScrollGroupComponent>
    </div>
  )
}

export default DailyReport
