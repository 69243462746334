export const valueTimefrequency = {
  ISSUE: 'Issue',
  OUTSTANDING: 'Outstanding',
}

export const Timefrequency = [
  {
    label: 'bond.overview.topIssuerRelease.tab.Issue',
    value: valueTimefrequency.ISSUE,
  },
  {
    label: 'bond.overview.topIssuerRelease.tab.Outstanding',
    value: valueTimefrequency.OUTSTANDING,
  },
]

export const TABLE_ROW_LEVEL = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
}

export const list_career = [
  { name: 'bond.overview.primaryMarket.ALL_SECTOR', value: '' },
]

export const tableConstants = [
  {
    index: 'Stt',
    title: 'bond.overview.topIssuerRelease.table.STT',
    name: 'bond.overview.topIssuerRelease.table.STT',
    key: 'stt',
    attrs: {
      style: { textAlign: 'center' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    index: 'organizationShortName',
    key: 'organizationShortName',
    title: 'bond.overview.topIssuerRelease.table.organizationShortName',
    name: 'bond.overview.topIssuerRelease.table.organizationShortName',
    attrs: {
      style: {
        minWidth: '135px',
        maxWidth: '190px',
      },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    index: 'icbName',
    key: 'icbName',
    title: 'bond.overview.topIssuerRelease.table.icbName',
    name: 'bond.overview.topIssuerRelease.table.icbName',
    attrs: {
      style: {
        minWidth: '135px',
      },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    index: 'issueValue',
    key: 'issueValue',
    title: 'bond.overview.topIssuerRelease.table.issueValue',
    name: 'bond.overview.topIssuerRelease.table.issueValue',
    additionText: 'bond.overview.topIssuerRelease.table.unitMoney',
    attrs: {
      style: { textAlign: 'right' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    index: 'averageDuration',
    key: 'averageDuration',
    title: 'bond.overview.topIssuerRelease.table.averageDuration',
    name: 'bond.overview.topIssuerRelease.table.averageDuration',
    additionText: 'bond.overview.topIssuerRelease.table.unitYear',
    attrs: {
      style: { textAlign: 'right' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    index: 'averageCouponInterestRate',
    key: 'averageCouponInterestRate',
    title: 'bond.overview.topIssuerRelease.table.averageCouponInterestRate',
    name: 'bond.overview.topIssuerRelease.table.averageCouponInterestRate',
    additionText: 'bond.overview.topIssuerRelease.table.unitPercent',
    attrs: {
      style: { textAlign: 'right' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
]

export const tableCirculateConstants = [
  {
    index: 'stt',
    title: 'bond.overview.topIssuerRelease.table.STT',
    name: 'bond.overview.topIssuerRelease.table.STT',
    key: 'stt',
    attrs: {
      style: { textAlign: 'center' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    index: 'organizationShortName',
    key: 'organizationShortName',
    title: 'bond.overview.topIssuerRelease.table.organizationShortName',
    name: 'bond.overview.topIssuerRelease.table.organizationShortName',
    attrs: {
      style: {
        minWidth: '135px',
        maxWidth: '190px',
      },
    },
  },
  {
    index: 'icbName',
    key: 'icbName',
    title: 'bond.overview.topIssuerRelease.table.icbName',
    name: 'bond.overview.topIssuerRelease.table.icbName',
    attrs: {
      style: {
        minWidth: '135px',
      },
    },
  },
  {
    index: 'outstandingValue',
    key: 'outstandingValue',
    title: 'bond.overview.topIssuerRelease.table.issueValue2',
    name: 'bond.overview.topIssuerRelease.table.issueValue2',
    additionText: 'bond.overview.topIssuerRelease.table.unitMoney',
    attrs: {
      style: { textAlign: 'right' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    index: 'averageRemain',
    key: 'averageRemain',
    title: 'bond.overview.topIssuerRelease.table.averageRemain',
    name: 'bond.overview.topIssuerRelease.table.averageRemain',
    additionText: 'bond.overview.topIssuerRelease.table.unitMonth',
    attrs: {
      style: { textAlign: 'right' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    index: 'averageCouponInterestRate',
    key: 'averageCouponInterestRate',
    title: 'bond.overview.topIssuerRelease.table.averageCouponInterestRate',
    name: 'bond.overview.topIssuerRelease.table.averageCouponInterestRate',
    additionText: 'bond.overview.topIssuerRelease.table.unitPercent',
    attrs: {
      style: { textAlign: 'right' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
]

export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}

export const MONTH_LIST_FILTER_VI = [
  {
    name: 'Th01',
    value: 1,
  },
  {
    name: 'Th02',
    value: 2,
  },
  {
    name: 'Th03',
    value: 3,
  },
  {
    name: 'Th04',
    value: 4,
  },
  {
    name: 'Th05',
    value: 5,
  },
  {
    name: 'Th06',
    value: 6,
  },
  {
    name: 'Th07',
    value: 7,
  },
  {
    name: 'Th08',
    value: 8,
  },
  {
    name: 'Th09',
    value: 9,
  },
  {
    name: 'Th10',
    value: 10,
  },
  {
    name: 'Th11',
    value: 11,
  },
  {
    name: 'Th12',
    value: 12,
  },
]

export const MONTH_LIST_FILTER_EN = [
  {
    name: 'Jan',
    value: 1,
  },
  {
    name: 'Feb',
    value: 2,
  },
  {
    name: 'Mar',
    value: 3,
  },
  {
    name: 'Apr',
    value: 4,
  },
  {
    name: 'May',
    value: 5,
  },
  {
    name: 'Jun',
    value: 6,
  },
  {
    name: 'Jul',
    value: 7,
  },
  {
    name: 'Aug',
    value: 8,
  },
  {
    name: 'Sep',
    value: 9,
  },
  {
    name: 'Oct',
    value: 10,
  },
  {
    name: 'Nov',
    value: 11,
  },
  {
    name: 'Dec',
    value: 12,
  },
]
