import PropTypes from 'prop-types'
import { Panel } from '../../../common/panel'
import { withWrapper } from '../../../common/withWrapper'
import { PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { Content } from './Content'
import { Title } from './Title'

const MAP_KEY = {
  MORE: 'MORE',
}

const MAP_SIZE = {
  [MAP_KEY.MORE]: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    minWidth: 1000,
    minHeight: 200,
    minTop: 0,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const More = ({ sizes, setSizes, defaultType, detailRoute }) => {
  return (
    <>
      <Panel
        renderCustomTitle={() => <Title />}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MORE}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Content
          defaultType={defaultType}
          detailRoute={detailRoute}
          width={formatNumber(sizes[MAP_KEY.MORE].width) - PANEL_PADDING}
        />
      </Panel>
    </>
  )
}

More.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
  defaultType: PropTypes.number,
  detailRoute: PropTypes.string,
}

export default withWrapper(MAP_SIZE, panelRefs)(More)
