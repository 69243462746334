import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import LocalInstitutionService from '../../../../../../core/services/market/marketInDepth/LocalInstitutionService'
import { handleExport } from '../../../../../utils/Export'

export const getTopNetValue = createAsyncThunk(
  'market/marketInDepth/localInstitution/topNetValue/GET_TOP_NET_VALUE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await LocalInstitutionService.getTopNetValue(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadTopNetValue = createAsyncThunk(
  'market/marketInDepth/localInstitution/topNetValue/DOWNLOAD_TOP_NET_VALUE',
  async (data) => {
    const response = await LocalInstitutionService.downloadTopNetValue(data)
    handleExport(response.data, response.filename)
  },
)
