import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PriceDataService from '../../../../../core/services/common/PriceDataService'
import RankingService from '../../../../../core/services/sector/sectorConstituents/RankingService'
import { handleExport } from '../../../../utils/Export'

export const getDataTable = createAsyncThunk(
  'sector/sectorConstituents/ranking/GET_RANKING_TABLE',
  async (data, { rejectWithValue }) => {
    try {
      const response = await RankingService.getDataTable(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTooltipScoreResult = createAsyncThunk(
  'sector/sectorConstituents/ranking/GET_TOOLTIP_SCORE_RESULT',
  async (data, { rejectWithValue }) => {
    try {
      const response = await RankingService.getTooltipScoreResult(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTooltipTicker = createAsyncThunk(
  'sector/sectorConstituents/ranking/GET_TOOLTIP_TICKER',
  async (data, { rejectWithValue }) => {
    try {
      const response = await RankingService.getTooltipTicker(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTickerLatestPrice = createAsyncThunk(
  'sector/sectorConstituents/ranking/GET_TICKER_LATEST_PRICE',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PriceDataService.getLatestPrice(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadDataTable = createAsyncThunk(
  'sector/sectorConstituents/ranking/DOWNLOAD_DATA_TABLE',
  async (data) => {
    const response = await RankingService.downloadDataTable(data)
    handleExport(response.data, response.filename)
  },
)
