import { useState } from 'react'
import { useSelector } from 'react-redux'
import TextEllipsis from '../../../common/textEllipsis'
import { getItemParent } from './helper'
import { selectListCPISector } from './store/slice'
import style from './style.module.css'

export const TdSelect = ({ val, rowId, onRowCollapse, dataTable }) => {
  const GAP_ITEM = 8
  const MARGIN_LEFT = 20
  const LEVEL_YELLOW_COLOR_TEXT = 2

  const listCPISector = useSelector(selectListCPISector)

  const parentArr = getItemParent(dataTable)

  const cpiSectorDataTableItemWithCollapse = dataTable?.map((item) => ({
    ...item,
    isCollapsible:
      parentArr?.includes(item) || item.cpivnTypeLevel === 1 ? true : false,
  }))

  const cpiSectorDataTableItem = cpiSectorDataTableItemWithCollapse?.find(
    (item) => item.cpivnTypeId === rowId,
  )

  const [isCollapse, setIsCollapse] = useState(false)

  const onClickCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  const onClickHandler = () => {}

  const handleChecked = () => {
    return listCPISector?.some((cpiSector) => cpiSector.cpivnTypeId === rowId)
  }

  return (
    <div className="d-flex align-center" style={{ gap: GAP_ITEM }}>
      <div>
        <input
          type="checkbox"
          className={`checkbox ${style.checkbox}`}
          checked={handleChecked()}
          onChange={onClickHandler}
        />
      </div>
      <div
        className="d-flex"
        style={{
          marginLeft:
            (cpiSectorDataTableItem?.cpivnTypeLevel - 2) * MARGIN_LEFT || 0,
        }}
      >
        {cpiSectorDataTableItem?.isCollapsible ? (
          <button
            style={{ cursor: 'pointer' }}
            className="align-center"
            onClick={onClickCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            ></i>
          </button>
        ) : (
          <div style={{ width: '20px' }}></div>
        )}
        <div className={style.pointer}>
          <TextEllipsis
            isI18n={false}
            text={val}
            appendStyle={{
              color:
                cpiSectorDataTableItem?.cpivnTypeLevel ===
                LEVEL_YELLOW_COLOR_TEXT
                  ? '#fecf2f'
                  : '',
            }}
          />
        </div>
      </div>
    </div>
  )
}
