import PropTypes from 'prop-types'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../constants/Common'
import style from './index.module.css'

export const PanelHeader = ({ children, className }) => {
  return (
    <div
      className={`window-header ${style.header} ${className}`}
      style={{ height: HEADER_PANEL_HEIGHT - PANEL_PADDING }}
    >
      {children}
    </div>
  )
}

PanelHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

PanelHeader.defaultProps = {
  className: '',
}
