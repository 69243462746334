import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { SizeTracker } from '../../../../common/sizeTracker'
import EventEmitter, { RESET_TABLE_SORT } from '../../../../utils/EventEmitter'
import { TYPE_IMPORT_EXPORT } from '../../exportImportByCommodities/constants'
import { changeLocaleStructureChart } from '../exportStructure/store/slice'
import {
  changeLocaleForTurnOverChart,
  setDisableLoading,
} from '../exportTurnOver/store/slice'
import { LIMIT_FIRST_FETCH, TYPE_CATEGORY, TYPE_DATA } from './constants'
import { FilterList } from './FilterList'
import {
  changeLocale,
  selectCurrentDataCategory,
  selectImportExportDataType,
  selectIsChangeFilter,
  selectIsKeepRowsCollapse,
  selectLimitQuarter,
  selectOrigParentCategory,
  selectSortType,
  selectTypeData,
  selectTypeFilter,
  selectUnCollapseIds,
  setIsKeepRowsCollapse,
  setLimitQuarter,
} from './store/slice'
import {
  getExportByLocation,
  getExportByLocationAfterChangeFilter,
  getExportTradeAgreementsByLocation,
  getExportTradeAgreementsByLocationAfterChangeFilter,
  getImportByLocation,
  getImportByLocationAfterChangeFilter,
  getImportTradeAgreementsByLocation,
  getImportTradeAgreementsByLocationAfterChangeFilter,
} from './store/thunk'
import TableComponent from './TableComponent'

export const ExportImportTable = ({ width, height }) => {
  const dispatch = useDispatch()

  const currentTypeData = useSelector(selectTypeData)
  const currentTypeFilter = useSelector(selectTypeFilter)
  const currentCategoryFilter = useSelector(selectCurrentDataCategory)
  const importExportDataType = useSelector(selectImportExportDataType)
  const limitQuarter = useSelector(selectLimitQuarter)
  const isKeepRowsCollapse = useSelector(selectIsKeepRowsCollapse)
  const unCollapseIds = useSelector(selectUnCollapseIds)
  const listParentCategory = useSelector(selectOrigParentCategory)
  const sortType = useSelector(selectSortType)
  const locale = useSelector((state) => state.i18n.locale)
  const isChangeFilter = useSelector(selectIsChangeFilter)

  const getItemById = (id) => {
    return listParentCategory?.find((item) => item.id === id)
  }

  const unCollapseLocationIds = unCollapseIds?.map(
    (item) => getItemById(item)?.locationId,
  )
  const unCollapseAreaIds = unCollapseIds?.map(
    (item) => getItemById(item)?.parentId,
  )

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.IMPORT &&
      currentCategoryFilter === TYPE_CATEGORY.CONTINENT
    ) {
      if (!isKeepRowsCollapse) {
        dispatch(
          getImportByLocation({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
          }),
        )
      } else {
        dispatch(
          getImportByLocationAfterChangeFilter({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
            LocationIds: unCollapseIds.toString(),
          }),
        )
      }
    }
    EventEmitter.dispatch(RESET_TABLE_SORT)
  }, [
    currentTypeData,
    currentTypeFilter,
    currentCategoryFilter,
    importExportDataType,
    locale,
  ])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.IMPORT &&
      currentCategoryFilter === TYPE_CATEGORY.TRADE_AGREEMENTS
    ) {
      if (!isKeepRowsCollapse) {
        dispatch(
          getImportTradeAgreementsByLocation({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
          }),
        )
      } else {
        dispatch(
          getImportTradeAgreementsByLocationAfterChangeFilter({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
            LocationIds: unCollapseLocationIds.toString(),
            areaIds: unCollapseAreaIds.toString(),
          }),
        )
      }
    }
  }, [
    currentTypeData,
    currentTypeFilter,
    currentCategoryFilter,
    importExportDataType,
    locale,
  ])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.EXPORT &&
      currentCategoryFilter === TYPE_CATEGORY.CONTINENT
    ) {
      if (!isKeepRowsCollapse) {
        dispatch(
          getExportByLocation({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
          }),
        )
      } else {
        dispatch(
          getExportByLocationAfterChangeFilter({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
            LocationIds: unCollapseIds.toString(),
          }),
        )
      }
    }
  }, [
    currentTypeData,
    currentTypeFilter,
    currentCategoryFilter,
    importExportDataType,
    locale,
  ])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.EXPORT &&
      currentCategoryFilter === TYPE_CATEGORY.TRADE_AGREEMENTS
    ) {
      if (!isKeepRowsCollapse) {
        dispatch(
          getExportTradeAgreementsByLocation({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
          }),
        )
      } else {
        dispatch(
          getExportTradeAgreementsByLocationAfterChangeFilter({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
            LocationIds: unCollapseLocationIds.toString(),
            areaIds: unCollapseAreaIds.toString(),
          }),
        )
      }
    }
  }, [
    currentTypeData,
    currentTypeFilter,
    currentCategoryFilter,
    importExportDataType,
    locale,
  ])

  useEffect(() => {
    if (!sortType && unCollapseIds.length) {
      if (importExportDataType === TYPE_IMPORT_EXPORT.EXPORT) {
        if (currentCategoryFilter === TYPE_CATEGORY.CONTINENT) {
          dispatch(
            getExportByLocationAfterChangeFilter({
              DataType: currentTypeFilter,
              Limit: limitQuarter
                ? limitQuarter + LIMIT_FIRST_FETCH
                : LIMIT_FIRST_FETCH,
              LocationIds: unCollapseIds.toString(),
              isAfterSort: true,
            }),
          )
        } else {
          dispatch(
            getExportTradeAgreementsByLocationAfterChangeFilter({
              DataType: currentTypeFilter,
              Limit: limitQuarter
                ? limitQuarter + LIMIT_FIRST_FETCH
                : LIMIT_FIRST_FETCH,
              LocationIds: unCollapseLocationIds.toString(),
              areaIds: unCollapseAreaIds.toString(),
              isAfterSort: true,
            }),
          )
        }
      } else {
        if (currentCategoryFilter === TYPE_CATEGORY.CONTINENT) {
          dispatch(
            getImportByLocationAfterChangeFilter({
              DataType: currentTypeFilter,
              Limit: limitQuarter
                ? limitQuarter + LIMIT_FIRST_FETCH
                : LIMIT_FIRST_FETCH,
              LocationIds: unCollapseIds.toString(),
              isAfterSort: true,
            }),
          )
        } else {
          dispatch(
            getImportTradeAgreementsByLocationAfterChangeFilter({
              DataType: currentTypeFilter,
              Limit: limitQuarter
                ? limitQuarter + LIMIT_FIRST_FETCH
                : LIMIT_FIRST_FETCH,
              LocationIds: unCollapseLocationIds.toString(),
              areaIds: unCollapseAreaIds.toString(),
              isAfterSort: true,
            }),
          )
        }
      }
      dispatch(setDisableLoading(true))
    }
  }, [sortType])

  useEffect(() => {
    dispatch(changeLocaleForTurnOverChart(locale))
    dispatch(changeLocaleStructureChart(locale))
    dispatch(changeLocale(locale))
    dispatch(setLimitQuarter(0))
    dispatch(setIsKeepRowsCollapse(true))
  }, [locale])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <FilterList width={width} limitQuarter={limitQuarter} />
            <div className="mb-8">
              <Span style={{ color: '#75797f', fontStyle: 'italic' }}>
                {`${I18n.t('economy.importExport.importExport.UNIT')}: ${
                  currentTypeData === TYPE_DATA.VALUE
                    ? I18n.t('economy.importExport.importExport.MILLION_USD')
                    : I18n.t('economy.importExport.importExport.PERCENT')
                }`}
              </Span>
            </div>
            {size.height &&
              (isChangeFilter ? (
                <Loading />
              ) : (
                <TableComponent
                  height={height - size.height}
                  limitQuarter={limitQuarter}
                />
              ))}
          </>
        )}
      </SizeTracker>
    )
  )
}
