import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { equationType } from '../../../popup/popupAddEquation/constants'
import { VIEW_TYPE } from '../../constants'
import {
  formatEquationData,
  getEquationDataAggregateParams,
  getEquationDataCountParams,
} from '../../helpers'
import { selectIndicatorsLatestPeriod } from '../store/slice'
import { getDataAggregate, getDataCount } from '../store/thunk'

export const DataByEquations = ({
  data,
  isHaveDataChartPreview,
  loading,
  loadingIndicatorLatestPeriod,
  onFormatData,
  setLoading,
}) => {
  const dispatch = useDispatch()

  const indicatorsLatestPeriod = useSelector(selectIndicatorsLatestPeriod)

  const { viewType, equations } = data.detail

  const getEquationData = async (equation) => {
    let functionGetEquationDataParams = getEquationDataAggregateParams
    let thunkGetDataApi = getDataAggregate

    if (equation.equationType === equationType.COUNT) {
      functionGetEquationDataParams = getEquationDataCountParams
      thunkGetDataApi = getDataCount
    }

    const params = functionGetEquationDataParams(
      viewType,
      equation,
      indicatorsLatestPeriod,
    )

    await dispatch(thunkGetDataApi(params))
      .unwrap()
      .then((data) => {
        let result = data
        if (viewType === VIEW_TYPE.BY_TIME) {
          result = {
            [equation.order]: formatEquationData(data, equation.dataKey),
          }
        }
        onFormatData(result, true)
      })
      .catch(() => {})
  }

  useEffect(() => {
    const asyncFn = async () => {
      if (!loadingIndicatorLatestPeriod && !loading.equation) {
        if (
          !isHaveDataChartPreview &&
          equations &&
          Object.keys(equations).length
        ) {
          const promises = []

          setLoading('equation', true)

          Object.values(equations).forEach((equation) => {
            promises.push(getEquationData(equation))
          })

          await Promise.allSettled(promises)
        }

        setLoading('equation', false)
      }
    }
    asyncFn()
  }, [JSON.stringify(equations), loadingIndicatorLatestPeriod])

  return <></>
}
