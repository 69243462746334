import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FDIByProvincesService from '../../../../../../core/services/economy/fdi/FDIByProvincesService'

export const getInvestmentStructure = createAsyncThunk(
  'economy/fdi/fdiByProvinces/GET_DATA_GET_INVESTMENT_STRUCTURE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FDIByProvincesService.getFDIStructureByProvince(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getLocationName = createAsyncThunk(
  'economy/fdi/fdiByProvinces/GET_LOCATION_NAME',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FDIByProvincesService.getLocationName(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
