import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { keyBy } from '../../utils/Common'
import {
  loadingKey,
  selectBondById,
  selectBondByOrganizationId,
  selectBonds,
  selectLoading,
  selectLocale,
} from './store/slice'
import { getBondThunk } from './store/thunk'

const useGetBond = (
  disableCallApi = true,
  isTree = false,
  filterFunc,
  isGetTreeNotDeleteChildren = false,
) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const loading = useSelector(selectLoading(loadingKey.BOND))
  const bonds = useSelector(selectBonds)
  const bondById = useSelector(selectBondById)
  const bondByOrganizationId = useSelector(selectBondByOrganizationId)

  const [listBondType, setListBondType] = useState([])
  const [listActiveStatus, setListActiveStatus] = useState([])
  const [listIssueMethod, setListIssueMethod] = useState([])

  const treeBondTypeRef = useRef(null)
  const treeIssueMethodRef = useRef(null)
  const treeActiveStatusRef = useRef(null)

  useEffect(() => {
    if (!disableCallApi && locale) {
      dispatch(getBondThunk())
    }
    treeBondTypeRef.current = null
    treeIssueMethodRef.current = null
    treeActiveStatusRef.current = null
  }, [locale])

  useEffect(() => {
    if (bonds.length) {
      setListBondType(
        _.uniqWith(
          bonds.map((t) => ({
            bondTypeId: t.bondTypeId,
            bondTypeName: t.bondTypeName,
          })),
          _.isEqual,
        ),
      )

      setListActiveStatus(
        _.uniqWith(
          bonds.map((t) => ({
            activeStatusId: t.activeStatusId,
            activeStatusName: t.activeStatusName,
          })),
          _.isEqual,
        ),
      )

      setListIssueMethod(
        _.uniqWith(
          bonds.map((t) => ({
            issueMethodId: t.issueMethodId,
            issueMethodName: t.issueMethodName,
          })),
          _.isEqual,
        ),
      )
    }
  }, [bonds])

  const handleBondType = (isDeleteChildren = true) => {
    if (treeBondTypeRef.current && isDeleteChildren) {
      return treeBondTypeRef.current
    }
    const filteredBondType = filterFunc
      ? listBondType.filter(filterFunc)
      : listBondType
    const bondTypeById = keyBy(
      filteredBondType.map((bondType) => {
        return { ...bondType, children: {} }
      }),
      'bondTypeId',
    )

    if (isDeleteChildren) {
      treeBondTypeRef.current = bondTypeById
    }
    return bondTypeById
  }

  const handleActiveStatus = (isDeleteChildren = true) => {
    if (treeActiveStatusRef.current && isDeleteChildren) {
      return treeActiveStatusRef.current
    }
    const filteredActiveStatus = filterFunc
      ? listActiveStatus.filter(filterFunc)
      : listActiveStatus
    const activeStatusById = keyBy(
      filteredActiveStatus.map((activeStatus) => {
        return { ...activeStatus, children: {} }
      }),
      'activeStatusId',
    )

    if (isDeleteChildren) {
      treeActiveStatusRef.current = activeStatusById
    }
    return activeStatusById
  }

  const handleIssueMethod = (isDeleteChildren = true) => {
    if (treeIssueMethodRef.current && isDeleteChildren) {
      return treeIssueMethodRef.current
    }
    const filteredIssueMethod = filterFunc
      ? listIssueMethod.filter(filterFunc)
      : listIssueMethod
    const issueMethodById = keyBy(
      filteredIssueMethod.map((issueMethod) => {
        return { ...issueMethod, children: {} }
      }),
      'issueMethodId',
    )

    if (isDeleteChildren) {
      treeIssueMethodRef.current = issueMethodById
    }
    return issueMethodById
  }

  return {
    loading,
    bonds,
    bondById,
    bondByOrganizationId,
    bondTypeTree: isTree && !loading && bonds.length ? handleBondType() : {},
    issueMethodTree:
      isTree && !loading && bonds.length ? handleIssueMethod() : {},
    activeStatusTree:
      isTree && !loading && bonds.length ? handleActiveStatus() : {},
    listBondType,
    listActiveStatus,
    listIssueMethod,
  }
}

export default useGetBond
