import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import SelectCustom from '../../../../common/selectCustom'
import {
  changValuePopupFinancialStatement,
  selectPopupFinancialStatement,
} from '../store/slice'

const DropdownAuditStatus = () => {
  const dispatch = useDispatch()

  const listSelect = [
    { name: I18n.t('corporate.companySnapshot.AUDITED'), value: 0 },
    { name: I18n.t('corporate.companySnapshot.UNAUDITED'), value: 1 },
    {
      name: I18n.t('corporate.companySnapshot.BOTH_AUDITED_AND_UNAUDITED'),
      value: 2,
    },
  ]

  const popupFinancialStatement = useSelector(selectPopupFinancialStatement)

  const changeValueSelect = (val) => {
    dispatch(
      changValuePopupFinancialStatement({
        ...popupFinancialStatement,
        auditStatus: val,
      }),
    )
  }

  return (
    <div className="w-100">
      <SelectCustom
        value={popupFinancialStatement.auditStatus}
        selectData={listSelect}
        handleChange={changeValueSelect}
      />
    </div>
  )
}

export default DropdownAuditStatus
