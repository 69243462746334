import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { getSectorsReport } from './thunk'

const initialState = {
  isLoading: true,
  ids: [],
  dataById: {},
  reCalcWidths: false,
}

const slice = createSlice({
  name: 'sector/sectorStatistics/overview/pricePerformance/sectorsReport',
  initialState,
  reducers: {
    clearDataSectorsReportTable: (state) => {
      state.ids = []
      state.dataById = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSectorsReport.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSectorsReport.fulfilled, (state, action) => {
      const data = action.payload.map((item, index) => {
        return { ...item, index }
      })
      state.ids = data.map((e, index) => index)
      state.dataById = keyBy(data, 'index')
      state.isLoading = false
      state.reCalcWidths = !state.reCalcWidths
    })
    builder.addCase(getSectorsReport.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoadingSectorsReport = (state) => state[slice.name].isLoading
export const selectSectorsReportIds = (state) => state[slice.name].ids
export const selectSectorsReportValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataById[id], attr)
export const selectReCalcWidthsTable = (state) => state[slice.name].reCalcWidths

export const { clearDataSectorsReportTable } = slice.actions

register(slice.name, slice.reducer)
