import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import GDPChangeIn from './panelGDPChangeIn'
import GDPGrowth from './panelGDPGrowth'
import GDPBySpendingMethod from './panelGDPSpendingMethod'
import GDPStructure from './panelGDPStructure'

const MAP_KEY = {
  GDP_SPENDING_METHOD: 'GDP_SPENDING_METHOD',
  GDP_GROWTH: 'GDP_GROWTH',
  GDP_STRUCTURE: 'GDP_STRUCTURE',
  GDP_CHANGE_IN: 'GDP_CHANGE_IN',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.GDP_SPENDING_METHOD]: {
    minWidth: 600,
    minHeight: 300,
  },
  [MAP_KEY.GDP_GROWTH]: {
    minWidth: 350,
    minHeight: 300,
  },
  [MAP_KEY.GDP_STRUCTURE]: {
    minWidth: 600,
    minHeight: 300,
  },
  [MAP_KEY.GDP_CHANGE_IN]: {
    minWidth: 350,
    minHeight: 300,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.GDP_SPENDING_METHOD}`]: {
    width: `calc(65%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_SPENDING_METHOD],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.GDP_GROWTH,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.GDP_GROWTH,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.GDP_STRUCTURE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.GDP_CHANGE_IN,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.GDP_GROWTH}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_GROWTH],
    minTop: 0,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.GDP_SPENDING_METHOD].minWidth +
      2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.GDP_SPENDING_METHOD,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.GDP_SPENDING_METHOD,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.GDP_STRUCTURE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.GDP_CHANGE_IN,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.GDP_STRUCTURE}`]: {
    width: `calc(65%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_STRUCTURE],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.GDP_SPENDING_METHOD].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.GDP_CHANGE_IN,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.GDP_CHANGE_IN}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_CHANGE_IN],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.GDP_SPENDING_METHOD].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.GDP_SPENDING_METHOD].minWidth +
      2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.GDP_STRUCTURE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <GDPBySpendingMethod
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_SPENDING_METHOD}
          sizes={sizes}
          setSizes={setSizes}
        />
        <GDPGrowth
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_GROWTH}
          sizes={sizes}
          setSizes={setSizes}
        />
        <GDPStructure
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_STRUCTURE}
          sizes={sizes}
          setSizes={setSizes}
        />
        <GDPChangeIn
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_CHANGE_IN}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }

  return renderListPanel()
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
