import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FundProfileService from '../../../../../../core/services/fund/FundProfileService'
import { handleExport } from '../../../../../utils/Export'

export const getDistributorsThunk = createAsyncThunk(
  'fund/fundProfile/profile/GET_DISTRIBUTOR',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FundProfileService.getDistributors(params)

      if (response.success) {
        return {
          data: response.data,
        }
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadDistributors = createAsyncThunk(
  'fund/fundProfile/profile/DOWNLOAD_DISTRIBUTOR',
  async (data) => {
    const response = await FundProfileService.downloadDistributors(data)
    handleExport(response.data, response.filename)
  },
)
