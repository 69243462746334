import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Z_INDEX } from '../../constants/Common'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'
import { Span } from '../html/Span'
import style from './tooltip.module.css'

const POSITION = {
  RIGHT: 'right',
  CENTER: 'center',
  LEFT: 'left',
}

const DEFAULT_POSITION = 9999

export const ThTooltip = ({ children, tooltip }) => {
  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [isShow, setIsShow] = useState(false)
  const [el] = useState(() => {
    return document.createElement('div')
  })
  const showTooltip = () => {
    setIsShow(true)
    document.body.appendChild(el)
  }
  const hideTooltip = () => {
    setIsShow(false)
    document.body.removeChild(el)
  }
  const onMouseEnter = () => {
    showTooltip()
  }
  const onMouseLeave = () => {
    if (isShow) {
      hideTooltip()
    }
  }

  const [position, setPosition] = useState({
    top: DEFAULT_POSITION,
    left: DEFAULT_POSITION,
  })

  const rootRef = useRef()
  const tooltipRef = useRef()

  const isOuterRight = () => {
    const align = getComputedStyle(rootRef.current).textAlign
    const { left, width, right } = rootRef.current.getBoundingClientRect()
    const tableDom = rootRef.current.closest('.scrollbars')
    const { right: tableRight } = tableDom.getBoundingClientRect()
    const { width: tooltipWidth } = tooltipRef.current.getBoundingClientRect()

    switch (align) {
      case POSITION.LEFT:
        return left + tooltipWidth > tableRight
      case POSITION.CENTER:
        return left + width / 2 + tooltipWidth > tableRight
      case POSITION.RIGHT:
        return right > tableRight
      default:
        throw Error('[isOuterRight] Pls set align correctly!')
    }
  }

  const getRealLeft = (left) => {
    const { left: wrapperLeft } = rootRef.current
      .closest('.scrollbars')
      .getBoundingClientRect()

    return Math.max(left, wrapperLeft)
  }

  const getTooltipPos = useCallback(() => {
    const {
      top,
      left,
      width: rootWidth,
    } = rootRef.current.getBoundingClientRect()
    const rootTop = formatWithZoom(top),
      rootLeft = formatWithZoom(left)
    const { height: tooltipHeight, width: tooltipWidth } =
      tooltipRef.current.getBoundingClientRect()

    const align = getComputedStyle(rootRef.current).textAlign
    const offset = formatWithZoom(10)
    const tooltipTop = rootTop - tooltipHeight - offset

    if (isOuterRight()) {
      const { right: tableRight } = rootRef.current
        .closest('.scrollbars')
        .getBoundingClientRect()

      return {
        left: getRealLeft(tableRight - tooltipWidth),
        top: tooltipTop,
      }
    }

    switch (align) {
      case POSITION.RIGHT:
        return {
          left: getRealLeft(rootLeft + rootWidth - tooltipWidth),
          top: tooltipTop,
        }
      case POSITION.CENTER:
        return {
          left: getRealLeft(rootLeft + (rootWidth - tooltipWidth) / 2),
          top: tooltipTop,
        }
      case POSITION.LEFT:
        return {
          left: getRealLeft(rootLeft),
          top: tooltipTop,
        }
      default:
        throw Error('[getTooltipPos] Pls set align correctly!')
    }
  }, [])
  useEffect(() => {
    if (isShow) {
      const { top, left } = getTooltipPos()
      setPosition({ top, left })
    }
  }, [isShow, getTooltipPos])

  const [trianglePosition, setTrianglePosition] = useState({
    triangleLeft: DEFAULT_POSITION,
    triangleTop: DEFAULT_POSITION,
  })
  const getTrianglePos = () => {
    const align = getComputedStyle(rootRef.current).textAlign
    const {
      left,
      width: rootWidth,
      top,
      right,
    } = rootRef.current.getBoundingClientRect()
    const rootTop = formatWithZoom(top),
      rootLeft = formatWithZoom(left),
      rootRight = formatWithZoom(right)
    const paddingLeft = 20
    const paddingTop = 10
    const { right: wrapperRight } = rootRef.current
      .closest('.scrollbars ')
      .getBoundingClientRect()
    const rootScrollRight = formatWithZoom(wrapperRight)

    switch (align) {
      case POSITION.LEFT:
        return {
          triangleLeft: rootLeft + paddingLeft,
          triangleTop: rootTop - paddingTop,
        }
      case POSITION.RIGHT:
        return {
          triangleLeft: Math.min(rootScrollRight, rootRight) - paddingLeft,
          triangleTop: rootTop - paddingTop,
        }
      case POSITION.CENTER:
        return {
          triangleLeft: rootLeft + rootWidth / 2 - 4,
          triangleTop: rootTop - paddingTop,
        }
      default:
        throw Error('[getTrianglePos] Pls set align correctly!')
    }
  }
  useEffect(() => {
    if (isShow) {
      const { triangleTop, triangleLeft } = getTrianglePos()
      setTrianglePosition({ triangleTop, triangleLeft })
    }
  }, [isShow])

  const renderTooltip = () => {
    return (
      <div
        ref={tooltipRef}
        style={{
          top: position.top,
          left: position.left,
          zIndex: Z_INDEX.TOOLTIP_TABLE_HEAD,
        }}
        className={style.thTooltipContent}
      >
        <div className={style.thTooltipContentInner}>
          <Span style={{ fontStyle: 9 }}>{tooltip}</Span>
          <div
            className={style.triangle}
            style={{
              left: trianglePosition.triangleLeft,
              top: trianglePosition.triangleTop,
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={style.portalTooltip}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={rootRef}
      >
        {children}
      </div>
      {isShow && ReactDOM.createPortal(renderTooltip(), el)}
    </div>
  )
}

ThTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}
