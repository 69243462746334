import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import Sheet from '../../../../common/dataExplorer/Sheet'
import TableDataExplorer from '../../../../common/dataExplorer/table'
import CellOrder from '../../../../common/dataExplorer/table/CellOrder'
import { RESET_DEFAULT_SORT } from '../../../../common/dataExplorer/table/Thead'
import { FORMAT } from '../../../../utils/Datetime'
import EventEmitter, { LOADING_TABLE_DE } from '../../../../utils/EventEmitter'
import { convertCondition } from '../helper'
import {
  addSheet,
  changeActiveSheet,
  changeEconomyDisplay,
  changeListSheet,
  keys,
  removeColumn,
  removeSheet,
  selectActiveSheet,
  selectColumnById,
  selectColumnCell,
  selectColumnId,
  selectCondition,
  selectEconomyCell,
  selectEconomyDisplay,
  selectEconomyId,
  selectFullColumnId,
  selectFullEconomyDisplay,
  selectIndicatorById,
  selectListSheet,
  selectLoading,
  sort,
  swapColumns,
  swapTwoColumn,
} from '../store/slice'
import SettingEconomy from './SettingEconomy'
import ThTable from './ThTable'
import { pageSize } from '../../../../common/dataExplorer/table/constant'

const heightHeaderSettingEconomy = 76

const ResultTable = ({ width }) => {
  const dispatch = useDispatch()

  const activeSheet = useSelector(selectActiveSheet)
  const loadingTable = useSelector(selectLoading(keys.TABLE_RESULT))
  const condition = useSelector(selectCondition(activeSheet))

  const [loadingEconomy, setLoadingEconomy] = useState(false)

  useEffect(() => {
    EventEmitter.subscribe(LOADING_TABLE_DE, setLoadingEconomy)
    return () => EventEmitter.unsubscribe(LOADING_TABLE_DE, setLoadingEconomy)
  }, [])

  useEffect(() => {
    EventEmitter.dispatch(RESET_DEFAULT_SORT)
  }, [activeSheet])

  const changeIdDisplay = (ids) => dispatch(changeEconomyDisplay(ids))

  const sortColumn = (idColumn, typeSort) => {
    dispatch(sort({ idColumn, typeSort }))
  }

  const swapColumn = (column1, column2) => {
    dispatch(swapTwoColumn({ column1, column2 }))
  }

  const swapManyColumns = ({ newColumnId, columnDelete }) => {
    dispatch(removeColumn(columnDelete))
    dispatch(swapColumns(newColumnId))
  }

  const setSheet = (sheet) => {
    dispatch(changeListSheet(sheet))
  }

  const setActiveTab = (value) => {
    dispatch(changeActiveSheet(value))
  }

  const handleNewSheet = (sheet) => {
    if (sheet?.value) {
      dispatch(addSheet(sheet?.value))
    }
  }

  const handleRemoveSheet = (sheet) => {
    if (sheet?.value) {
      dispatch(removeSheet(sheet))
    }
  }

  const { conditionType } = convertCondition(condition)

  const cellInfo = [
    {
      id: 'no',
      title: 'tool.dataExplorer.NO',
      appendStyleTh: { minWidth: '65px', maxWidth: '65px', left: 0 },
      appendStyle: { minWidth: '48px', maxWidth: '48px', left: 0 },
      disableSort: true,
      render: (_, __, ___, index) => <CellOrder index={index} />,
      isSticky: true,
    },
    {
      id: 'period',
      title: 'tool.dataExplorer.economy.PERIOD',
      appendStyleTh: { minWidth: '137px', maxWidth: '137px', left: 65 },
      appendStyle: { minWidth: '120px', maxWidth: '120px', left: 65 },
      render: (_, __, value) =>
        conditionType === 'Daily' ? (
          <DateTimeSpan date={value} formatDate={FORMAT.DATE} />
        ) : (
          value
        ),
      isSticky: true,
    },
    {
      id: 'date',
      title: 'tool.dataExplorer.economy.DATE',
      appendStyleTh: { minWidth: '137px', maxWidth: '137px', left: 202 },
      appendStyle: { minWidth: '120px', maxWidth: '120px', left: 202 },
      render: (_, __, value) => (
        <DateTimeSpan date={value} formatDate={FORMAT.DATE} />
      ),
      isSticky: true,
    },
  ]

  return (
    <>
      <TableDataExplorer
        isLoading={loadingEconomy || loadingTable}
        maxColumn={250}
        selectColumnId={selectColumnId(activeSheet)}
        selectColumnCell={selectColumnCell(activeSheet)}
        maxRow={pageSize}
        selectTickerCell={selectEconomyCell(activeSheet)}
        selectId={selectEconomyDisplay(activeSheet)}
        selectFullId={selectEconomyId(activeSheet)}
        selectFullColumnId={selectFullColumnId(activeSheet)}
        changeIdDisplay={changeIdDisplay}
        selectIdDisplay={selectFullEconomyDisplay(activeSheet)}
        cellInfo={cellInfo}
        sortColumn={sortColumn}
        swapColumn={swapColumn}
        renderTh={(_, colId) => <ThTable colId={colId} />}
        renderHeaderTable={() => <SettingEconomy />}
        heightHeaderTable={condition ? heightHeaderSettingEconomy : 0}
        renderContentPagination={() => (
          <Sheet
            width={width}
            handleNewSheet={handleNewSheet}
            handleRemoveSheet={handleRemoveSheet}
            selectColumnId={selectFullColumnId(activeSheet)}
            selectColumnById={selectColumnById(activeSheet)}
            selectIndicatorById={selectIndicatorById}
            selectSheet={selectListSheet}
            setSheet={setSheet}
            selectActiveTab={selectActiveSheet}
            setActiveTab={setActiveTab}
            swapColumns={swapManyColumns}
            idKey="indicatorId"
          />
        )}
        stateResetDefaultPage={JSON.stringify({ activeSheet, condition })}
        noDataText=""
      />
    </>
  )
}

ResultTable.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default ResultTable
