import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import { selectSectorInfo } from '../../../../../../common/topInfo/sectorTopInfo/store/slice'
import { SelectType } from '../../../common/SelectType'
import { chartYearOptions } from '../../../constants'
import { getQuarterYearParams } from '../../../helps'
import { ChartComponent } from './ChartComponent'
import { indicatorMappingDisplayName, indicatorOptions } from './constants'
import {
  changeIndicatorType,
  changeRetryFlag,
  changeYearType,
  selectChartData,
  selectChartLoading,
  selectRetryFlag,
  selectYearType,
} from './store/slice'
import { getInDepthComparisonChartData } from './store/thunk'

export const InDepthComparisonChart = ({ width, height, dataType }) => {
  const dispatch = useDispatch()

  // Use selector
  const sectorInfo = useSelector(selectSectorInfo)
  const loading = useSelector(selectChartLoading)
  const data = useSelector(selectChartData)
  const retryFlag = useSelector(selectRetryFlag)
  const yearType = useSelector(selectYearType)

  // Use state
  const [indicatorType, setIndicatorType] = useState(indicatorOptions[0].value)

  // Get data
  const getData = useMemo(() => {
    if (data.length) {
      const newObj = {
        year: yearType,
        label: I18n.t(indicatorMappingDisplayName[indicatorType]),
      }
      let totalData = 0

      data.forEach((item) => {
        newObj[item.ticker] = item[indicatorType]
        totalData += item[indicatorType]
      })

      Object.keys(newObj).forEach((key) => {
        if (!['year', 'label'].includes(key) && newObj[key]) {
          newObj[key] /= totalData
        }
      })

      return newObj
    }
  }, [yearType, indicatorType, data])

  const getPieKeys = useMemo(() => {
    return data.map((item) => item.ticker)
  }, [data])

  const getMappingDisplayName = useMemo(() => {
    const newObj = {}
    data.forEach((item) => (newObj[item.ticker] = item.ticker))
    return newObj
  }, [data])

  // Actions
  const onChangeYear = (value) => {
    dispatch(changeYearType(value))
  }

  const onChangeIndicator = (value) => {
    setIndicatorType(value)
    dispatch(changeIndicatorType(value))
  }

  // Use effect
  useEffect(() => {
    if (sectorInfo && sectorInfo.icbId && retryFlag) {
      const paramsArr = getQuarterYearParams().map(({ year }) => ({
        IcbId: sectorInfo.icbId,
        ExchangeCode: dataType,
        Year: year,
      }))
      dispatch(getInDepthComparisonChartData(paramsArr))
    }
  }, [sectorInfo, retryFlag])

  useEffect(() => {
    if (!sectorInfo || !sectorInfo.icbId) return
    dispatch(
      getInDepthComparisonChartData({
        Year: yearType,
        IcbId: sectorInfo.icbId,
        ExchangeCode: dataType,
      }),
    )
  }, [yearType, sectorInfo])

  useEffect(() => {
    dispatch(changeRetryFlag(true))
  }, [dataType])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className="d-flex" style={{ justifyContent: 'space-between' }}>
            <SelectType
              options={indicatorOptions}
              activeTab={indicatorType}
              onChangeTab={onChangeIndicator}
            />
            <SelectType
              options={chartYearOptions}
              activeTab={yearType}
              onChangeTab={onChangeYear}
              style={{ width: 86 }}
            />
          </div>
          {size.height && (
            <ChartComponent
              data={getData}
              width={width}
              height={height - size.height}
              pieKeys={getPieKeys}
              mappingDisplayName={getMappingDisplayName}
              loading={loading}
            />
          )}
        </>
      )}
    </SizeTracker>
  )
}
