import GDPProvinceProxy from '../../../proxies/economy/gdp/GDPProvinceProxy'
import { ServiceBase } from '../../ServiceBase'

class GDPProvinceService extends ServiceBase {
  getGDPByProvince(params) {
    return this.applyMemoryCache(
      'GDPProvinceService.getGDPByProvince',
      params,
    )(() => GDPProvinceProxy.getGDPByProvince(params))
  }

  getGDPTypeName(params) {
    return this.applyMemoryCache(
      'GDPProvinceService.getGDPTypeName',
      params,
    )(() => GDPProvinceProxy.getGDPTypeName(params))
  }

  downloadGDPByProvince(params) {
    return this.getFileDownload(() =>
      GDPProvinceProxy.downloadGDPByProvince(params),
    )
  }

  getProvincesByGRDP(params) {
    return this.applyMemoryCache(
      'GDPProvinceService.getProvincesByGRDP',
      params,
    )(() => GDPProvinceProxy.getProvincesByGRDP(params))
  }

  getGRDPStructureInByProvince(data) {
    return this.applyMemoryCache(
      'GDPProvinceService.getGRDPStructureInByProvince',
      data,
    )(() => GDPProvinceProxy.getGRDPStructureInByProvince(data))
  }

  getChangesInGRDPStructureBySectorInProvince(params) {
    return this.applyMemoryCache(
      'GDPSpendingMethodService.getChangesInGRDPStructureBySectorInProvince',
      params,
    )(() =>
      GDPProvinceProxy.getChangesInGRDPStructureBySectorInProvince(params),
    )
  }

  getLocationName(params) {
    return this.applyMemoryCache(
      'GDPSpendingMethodService.getLocationName',
      params,
    )(() => GDPProvinceProxy.getLocationName(params))
  }

  getMaxPeriod(params) {
    return this.getData(() => GDPProvinceProxy.getMaxPeriod(params))
  }
}

export default new GDPProvinceService()
