import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { SizeTracker } from '../../../../../common/sizeTracker'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  FULL_COMPONENT,
} from '../../../../../utils/EventEmitter'
import { MAP_KEY } from '../Content'
import { selectDataType } from '../peersComparison/store/slice'
import ChartLine from './ChartLine'
import ChartRadar from './ChartRadar'
import { CHART_TYPE_HEIGHT } from './constant'
import FilterChartRadar from './filterChartRadar'
import style from './index.module.css'
import {
  selectDataChart2,
  selectDataChart3,
  selectDataChart4,
  selectLoadingChart2,
  selectLoadingChart3,
  selectLoadingChart4,
} from './store/slice'

const CHART_ITEM_PADDING = 8

const Charts = ({ width, height }) => {
  const [isFullComponent, setIsFullComponent] = useState(false)
  const dataType = useSelector(selectDataType)
  const loadingChart2 = useSelector(selectLoadingChart2)
  const dataChart2 = useSelector(selectDataChart2)

  const loadingChart3 = useSelector(selectLoadingChart3)
  const dataChart3 = useSelector(selectDataChart3)

  const loadingChart4 = useSelector(selectLoadingChart4)
  const dataChart4 = useSelector(selectDataChart4)

  const chartItemWidth = isFullComponent ? width / 2 - 2 : width / 4
  const chartItemHeight = isFullComponent ? height / 2 : height
  const chartMargin = isFullComponent ? 20 : 0

  useEffect(() => {
    EventEmitter.subscribe(FULL_COMPONENT, (key) => {
      if (key === MAP_KEY.VALUATION) setIsFullComponent(true)
    })
    EventEmitter.subscribe(CLOSE_FULL_COMPONENT, (key) => {
      if (key === MAP_KEY.VALUATION) setIsFullComponent(false)
    })
  }, [setIsFullComponent])

  return (
    <div className="d-flex" style={{ flexWrap: isFullComponent ? 'wrap' : '' }}>
      <div
        key={'1'}
        style={{
          width: chartItemWidth,
        }}
        className={style.chartItem}
      >
        <div className={style.chartTitle}>
          <Span
            style={{
              fontSize: 10,
              fontWeight: 600,
              textTransform: 'uppercase',
            }}
            className="canvas-chart-title"
          >
            <Translate value={'bond.comparable.chart.chart1.title'} />
          </Span>
        </div>
        <div
          className={style.chartContent}
          style={{
            height: chartItemHeight - CHART_TYPE_HEIGHT,
            padding: `0px ${CHART_ITEM_PADDING}px`,
          }}
        >
          <SizeTracker>
            {(size) => {
              return (
                <>
                  <FilterChartRadar />
                  {size.height && (
                    <div
                      style={{
                        height:
                          chartItemHeight -
                          CHART_TYPE_HEIGHT -
                          chartMargin -
                          size.height,
                      }}
                    >
                      <ChartRadar
                        width={chartItemWidth - 2 * CHART_ITEM_PADDING}
                        height={
                          chartItemHeight -
                          CHART_TYPE_HEIGHT -
                          chartMargin -
                          size.height
                        }
                        dataType={dataType}
                      />
                    </div>
                  )}
                </>
              )
            }}
          </SizeTracker>
        </div>
      </div>
      <div
        key={'2'}
        style={{
          width: chartItemWidth,
          borderLeft: '',
        }}
        className={style.chartItem}
      >
        <div className={style.chartTitle}>
          <Span
            style={{
              fontSize: 10,
              fontWeight: 600,
              textTransform: 'uppercase',
            }}
            className="canvas-chart-title"
          >
            <Translate
              value={`bond.comparable.chart.chart2.title${dataType}`}
            />
          </Span>
        </div>
        <div
          className={style.chartContent}
          style={{
            height: chartItemHeight - CHART_TYPE_HEIGHT,
            padding: `0px ${CHART_ITEM_PADDING}px`,
          }}
        >
          {loadingChart2 ? (
            <Loading />
          ) : (
            <ChartLine
              data={dataChart2}
              width={chartItemWidth - 2 * CHART_ITEM_PADDING}
              height={chartItemHeight - CHART_TYPE_HEIGHT - chartMargin}
              dataType={dataType}
              keyChart={'2'}
            />
          )}
        </div>
      </div>
      <div
        key={'3'}
        style={{
          width: chartItemWidth,
          borderLeft: '',
        }}
        className={style.chartItem}
      >
        <div className={style.chartTitle}>
          <Span
            style={{
              fontSize: 10,
              fontWeight: 600,
              textTransform: 'uppercase',
            }}
            className="canvas-chart-title"
          >
            <Translate
              value={`bond.comparable.chart.chart3.title${dataType}`}
            />
          </Span>
        </div>
        <div
          className={style.chartContent}
          style={{
            height: chartItemHeight - CHART_TYPE_HEIGHT,
            padding: `0px ${CHART_ITEM_PADDING}px`,
          }}
        >
          {loadingChart3 ? (
            <Loading />
          ) : (
            <ChartLine
              data={dataChart3}
              width={chartItemWidth - 2 * CHART_ITEM_PADDING}
              height={chartItemHeight - CHART_TYPE_HEIGHT - chartMargin}
              dataType={dataType}
              keyChart={'3'}
            />
          )}
        </div>
      </div>
      <div
        key={'4'}
        style={{
          width: chartItemWidth,
          borderLeft: '',
        }}
        className={style.chartItem}
      >
        <div className={style.chartTitle}>
          <Span
            style={{
              fontSize: 10,
              fontWeight: 600,
              textTransform: 'uppercase',
            }}
            className="canvas-chart-title"
          >
            <Translate
              value={`bond.comparable.chart.chart4.title${dataType}`}
            />
          </Span>
        </div>
        <div
          className={style.chartContent}
          style={{
            height: chartItemHeight - CHART_TYPE_HEIGHT,
            padding: `0px ${CHART_ITEM_PADDING}px`,
          }}
        >
          {loadingChart4 ? (
            <Loading />
          ) : (
            <ChartLine
              data={dataChart4}
              width={chartItemWidth - 2 * CHART_ITEM_PADDING}
              height={chartItemHeight - CHART_TYPE_HEIGHT - chartMargin}
              dataType={dataType}
              keyChart={'4'}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Charts)
