import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../../../common/table'
import { formatVal } from '../../../../utils/Value'
import { keyPriceDateTable } from '../../filter/constants'
import { selectDataFDF, selectPriceDataValue } from '../../filter/store/slice'

const PriceDataTable = () => {
  const data = useSelector(selectDataFDF)

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  return (
    <div style={{ height: dimensions.height }}>
      {data.priceData.ids.length ? (
        <Table
          ids={data.priceData.ids}
          columnDraggable={false}
          getDataFromRedux={selectPriceDataValue}
          isLoading={false}
          rowDraggable={false}
          schema={Object.keys(keyPriceDateTable).map((key, index) => {
            const title = `corporate.companySnapshot.${key}`
            const colId = keyPriceDateTable[key]
            let result = {
              colId,
              title,
              thStyle: {
                textAlign: 'left',
              },
              tdStyle: {
                textAlign: 'left',
              },
            }
            if (index === 1 || index === 2) {
              result = {
                ...result,
                thStyle: {
                  textAlign: 'right',
                },
                tdStyle: {
                  textAlign: 'right',
                },
                render: (val) => formatVal(val, 0),
              }
            }
            return result
          })}
          isPagination={false}
          hasFooter={false}
          resizable={false}
          dimensionsTable={setDimensions}
          hasTooltip={false}
        />
      ) : null}
    </div>
  )
}

export default PriceDataTable
