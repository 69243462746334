import { useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { formatVal } from '../../../../../utils/Value'
import style from './index.module.css'
import { selectData } from './store/slice'

const BodyBottomLeft = ({ widthTopLeft }) => {
  const data = useSelector(selectData)

  return (
    <div className={style.column}>
      <div
        style={{ width: widthTopLeft * 2, textAlign: 'center' }}
        className="h-20"
      >
        <Span style={{ fontSize: 12, fontWeight: 700 }}>
          <TextEllipsis
            text={'bond.bondSecurity.tradingInformation.bottomBody.titleLfet'}
          />
        </Span>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={'bond.bondSecurity.tradingInformation.bottomBody.ZSpread'}
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft, textAlign: 'end' }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.bondRatio?.zSpread * 100)}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={
                'bond.bondSecurity.tradingInformation.bottomBody.CreditSpread'
              }
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft, textAlign: 'end' }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.bondRatio?.creditSpread * 100)}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={
                'bond.bondSecurity.tradingInformation.bottomBody.Maculayduration'
              }
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft, textAlign: 'end' }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.bondRatio?.macaulayDuration)}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={
                'bond.bondSecurity.tradingInformation.bottomBody.ModifiedDuration'
              }
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft, textAlign: 'end' }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.bondRatio?.modifiedDuration)}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={'bond.bondSecurity.tradingInformation.bottomBody.PVBP'}
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft, textAlign: 'end' }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.bondRatio?.pvbpvnd)}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={'bond.bondSecurity.tradingInformation.bottomBody.Convexity'}
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft, textAlign: 'end' }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.bondRatio?.convexity)}
            />
          </Span>
        </div>
      </div>
    </div>
  )
}

export default BodyBottomLeft
