export const GROUP_COLUMNS_OVERVIEW = [
  {
    key: 'tradingDateId',
    title: 'market.derivative.DATE',
  },
  {
    key: 'foreignInstitutionalBuyVolume',
    title: 'market.derivative.BUY_VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignInstitutionalBuyValue',
    title: 'market.derivative.BUY_VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'foreignInstitutionalSellVolume',
    title: 'market.derivative.SELL_VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignInstitutionalSellValue',
    title: 'market.derivative.SELL_VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'netVolume',
    title: 'market.derivative.NET_VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'netValue',
    title: 'market.derivative.NET_VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'foreignIndividualBuyMatchVolume',
    title: 'market.derivative.ORDER_MATCHING_BUY_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignInstitutionalBuyMatchValue',
    title: 'market.derivative.ORDER_MATCHING_BUY_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'foreignInstitutionalSellMatchVolume',
    title: 'market.derivative.ORDER_MATCHING_SELL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignIndividualSellMatchValue',
    title: 'market.derivative.ORDER_MATCHING_SELL_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'foreignInstitutionalBuyDealVolume',
    title: 'market.derivative.PUT_THROUGH_BUY_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignInstitutionalBuyDealValue',
    title: 'market.derivative.PUT_THROUGH_BUY_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'foreignInstitutionalSellDealVolume',
    title: 'market.derivative.PUT_THROUGH_SELL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignInstitutionalSellDealValue',
    title: 'market.derivative.PUT_THROUGH_SELL_VAL',
    format: 10 ** 6,
    count: 2,
  },
]

export const GROUP_COLUMNS_DETAIL = [
  {
    key: 'tradingDateId',
    title: 'market.derivative.DATE',
  },
  {
    key: 'ticker',
    title: 'market.derivative.TICKER_TABLE',
  },
  {
    key: 'foreignInstitutionalBuyVolume',
    title: 'market.derivative.BUY_VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignInstitutionalBuyValue',
    title: 'market.derivative.BUY_VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'foreignInstitutionalSellVolume',
    title: 'market.derivative.SELL_VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignInstitutionalSellValue',
    title: 'market.derivative.SELL_VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'netVolume',
    title: 'market.derivative.NET_VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'netValue',
    title: 'market.derivative.NET_VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'foreignIndividualBuyMatchVolume',
    title: 'market.derivative.ORDER_MATCHING_BUY_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignInstitutionalBuyMatchValue',
    title: 'market.derivative.ORDER_MATCHING_BUY_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'foreignInstitutionalSellMatchVolume',
    title: 'market.derivative.ORDER_MATCHING_SELL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignIndividualSellMatchValue',
    title: 'market.derivative.ORDER_MATCHING_SELL_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'foreignInstitutionalBuyDealVolume',
    title: 'market.derivative.PUT_THROUGH_BUY_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignInstitutionalBuyDealValue',
    title: 'market.derivative.PUT_THROUGH_BUY_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'foreignInstitutionalSellDealVolume',
    title: 'market.derivative.PUT_THROUGH_SELL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'foreignInstitutionalSellDealValue',
    title: 'market.derivative.PUT_THROUGH_SELL_VAL',
    format: 10 ** 6,
    count: 2,
  },
]

export const TABLE_TYPE = {
  OVERVIEW: 'overview',
  DETAIL: 'detail',
  TICKER: 'ticker',
}

export const LIST_RADIO = [
  {
    label: 'market.derivative.OVERVIEW',
    value: TABLE_TYPE.OVERVIEW,
  },
  {
    label: 'market.derivative.DETAIL',
    value: TABLE_TYPE.DETAIL,
  },
]
