import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { Loading } from '../../../common/loading'
import useGetICB from '../../../common/masterData/useGetICB'
import { NoData } from '../../../common/noData'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../utils/EventEmitter'
import {
  changeInListYAxisCustom,
  changeTreeSecuritiesIndicator,
} from '../../chart/store/slice'
import { LIST_TYPE, MAX_SECURITIES_ADD } from '../../common/constants'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import { changeSecurities } from '../common/store/slice'
import { getSecuritiesIndicatorDataOnApply } from '../popupAddSecurities/helpers'
import ItemSecurities from './ItemSecurities'
import withWrapperData from './withWrapperData'

const ListSectors = ({
  valueSearch = '',
  securities,
  onClose,
  treeSecuritiesIndicator,
  indicatorSelected,
  listSecuritiesEconomy,
  typeChart,
  haveOrientationLeft,
  itemId,
  usedColors,
  viewType,
}) => {
  const dispatch = useDispatch()

  const { favoriteColors } = useGetUserSettings()

  const { ICBs, loading } = useGetICB(true, true)

  const [filteredSector, setFilteredSector] = useState([])

  const addSecurities = (id) => {
    const securityCount = Object.values(securities).reduce(
      (arr, ids) => arr.concat(ids),
      [],
    ).length
    if (securityCount >= MAX_SECURITIES_ADD) {
      return EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        I18n.t('financialChart.WANNING_ADD_SECURITIES'),
      ])
    }

    const arrNew = [...securities.sector, id]
    const arrValue = itemId ? arrNew.filter((i) => i !== itemId) : arrNew
    const result = getSecuritiesIndicatorDataOnApply({
      treeSecuritiesIndicator,
      securities,
      indicatorSelected,
      listSecuritiesEconomy,
      typeChart,
      haveOrientationLeft,
      securitiesState: {
        ...securities,
        [LIST_TYPE.SECTOR]: arrValue,
      },
      replaceSecurities: itemId,
      typeSecuritiesReplace: LIST_TYPE.SECTOR,
      usedColors,
      viewType,
      favoriteColors,
    })
    dispatch(
      changeInListYAxisCustom({
        replaceId: [LIST_TYPE.SECTOR, itemId].join('_'),
        updateId: [LIST_TYPE.SECTOR, id].join('_'),
      }),
    )
    dispatch(changeTreeSecuritiesIndicator(result))
    dispatch(
      changeSecurities({
        typeSecurities: LIST_TYPE.SECTOR,
        value: arrValue,
      }),
    )
    onClose()
  }

  const renderSector = () => (
    <ScrollComponent bgColor="#D9D9D9">
      {filteredSector.map((sector) => {
        return (
          <ItemSecurities
            key={sector.icbId}
            securities={securities.sector}
            id={sector.icbId}
            text={sector.icbName + ' L' + sector.icbLevel}
            onClick={() => addSecurities(sector.icbId)}
            itemId={itemId}
          />
        )
      })}
    </ScrollComponent>
  )

  useEffect(() => {
    setFilteredSector(
      ICBs.filter((sector) =>
        `${sector.icbName} L${sector.icbLevel}`
          ?.toLowerCase()
          .includes(valueSearch.toLowerCase()),
      ),
    )
  }, [ICBs, valueSearch])

  return (
    <>
      {loading ? (
        <Loading isBlack />
      ) : filteredSector.length === 0 ? (
        <NoData />
      ) : (
        <>{renderSector()}</>
      )}
    </>
  )
}

ListSectors.propTypes = {}

export default withWrapperData(ListSectors)
