import OpenMarketProxy from '../../proxies/economy/OpenMarketProxy'
import { ServiceBase } from '../ServiceBase'

class OpenMarketService extends ServiceBase {
  getOpenMarketOperations(params) {
    return this.getData(() => OpenMarketProxy.getOpenMarketOperations(params))
  }

  getOpenMarketOperationsTitle(params) {
    return this.applyMemoryCache(
      'OpenMarketService.getOpenMarketOperationsTitle',
      params,
    )(() => OpenMarketProxy.getOpenMarketOperationsTitle(params))
  }

  downloadOpenMarketOperations(params) {
    return this.getFileDownload(() =>
      OpenMarketProxy.downloadOpenMarketOperations(params),
    )
  }

  getOpenMarketDisplay(params) {
    return this.applyMemoryCache(
      'OpenMarketService.getOpenMarketDisplay',
      params,
    )(() => OpenMarketProxy.getOpenMarketDisplay(params))
  }

  getBiddingResultsOnTheOpenMarket(data) {
    return this.applyMemoryCache(
      'OpenMarketService.getBiddingResultsOnTheOpenMarket',
      data,
    )(() => OpenMarketProxy.getBiddingResultsOnTheOpenMarket(data))
  }

  downloadBiddingResults(params) {
    return this.getFileDownload(() =>
      OpenMarketProxy.downloadBiddingResults(params),
    )
  }

  getOutstandingVolume(params) {
    return this.applyMemoryCache(
      'OpenMarketService.getOutstandingVolume',
      params,
    )(() => OpenMarketProxy.getOutstandingVolume(params))
  }

  getNetPumpVolume(params) {
    return this.applyMemoryCache(
      'OpenMarketService.getNetPumpVolume',
      params,
    )(() => OpenMarketProxy.getNetPumpVolume(params))
  }

  getMaxDate(params) {
    return this.applyMemoryCache(
      'OpenMarketService.getMaxDate',
      params,
    )(() => OpenMarketProxy.getMaxDate(params))
  }

  getMaxDateOMO(params) {
    return this.getData(() => OpenMarketProxy.getMaxDateOMO(params))
  }
}

export default new OpenMarketService()
