export const chartFillColor = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#ecffdf',
]

export const chartPieKeys = ['lsafs', 'usafs', 'bafs', 'cdafs']

export const chartOtherPieKeys = 'oafs'

export const chartMappingDisplayName = {
  lsafs: 'sector.financialAnalysis.security.assetStructure.LISTED_SHARE',
  usafs: 'sector.financialAnalysis.security.assetStructure.UNLISTED_SHARE',
  bafs: 'sector.financialAnalysis.security.assetStructure.BONDS',
  cdafs: 'sector.financialAnalysis.security.assetStructure.CERTIFICATE_DEPOSIT',
  oafs: 'sector.financialAnalysis.security.assetStructure.OTHER',
}

export const chartFormat = {
  lsafs: 0.01,
  usafs: 0.01,
  bafs: 0.01,
  cdafs: 0.01,
  oafs: 0.01,
}
