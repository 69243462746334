import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadingKey,
  selectLoading,
  selectMaxMinTable,
  selectMaxMinTableById,
} from './store/slice'
import { getMaxMinTableThunk } from './store/thunk'

const useGetMaxMinTable = (disableCallApi = true) => {
  const dispatch = useDispatch()

  const loading = useSelector(selectLoading(loadingKey.MAX_MIN_TABLE))
  const maxMinTable = useSelector(selectMaxMinTable)
  const maxMinTableById = useSelector(selectMaxMinTableById)

  useEffect(() => {
    if (!disableCallApi) {
      dispatch(getMaxMinTableThunk())
    }
  }, [])

  return {
    loading,
    maxMinTable,
    maxMinTableById,
  }
}

export default useGetMaxMinTable
