import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { listViewBy } from './constants'
import {
  changeFilterStatus,
  changeViewBy,
  resetDataTable,
  selectFilterStatus,
  selectListFDITypeName,
  selectViewBy,
} from './store/slice'
import { getFDITypeName } from './store/thunk'

const Filter = () => {
  const dispatch = useDispatch()

  const viewBy = useSelector(selectViewBy)
  const filterStatus = useSelector(selectFilterStatus)
  const listFDITypeName = useSelector(selectListFDITypeName)
  const locale = useSelector((state) => state.i18n.locale)

  const onChangeViewBy = ({ value }) => {
    dispatch(resetDataTable())
    dispatch(changeViewBy(value))
  }

  const onChangeFilterStatus = (value) => {
    dispatch(resetDataTable())
    dispatch(changeFilterStatus(value))
  }

  useEffect(() => {
    dispatch(getFDITypeName({ IsCapital: viewBy }))
  }, [viewBy, locale])

  return (
    <div className="d-flex justify-content-space-between mb-8 ali-center">
      <div className="d-flex">
        <DispatchActionTab
          data={listViewBy.map((item) => {
            return {
              title: item.title,
              value: item.value,
            }
          })}
          activeTab={viewBy}
          onChangeTab={onChangeViewBy}
        />
        {listFDITypeName.length ? (
          <div className="ml-8 w-200">
            <SelectCustom
              value={filterStatus}
              selectData={listFDITypeName}
              handleChange={onChangeFilterStatus}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Filter
