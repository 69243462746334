import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { listTab } from './constants'
import { selectTabActive, updateTab } from './store/slice'

const Filter = () => {
  const dispatch = useDispatch()
  const activeTab = useSelector(selectTabActive)

  const onChangeTab = (item) => {
    dispatch(updateTab(item.value))
  }
  return (
    <div className="d-flex ali-center mb-8">
      <div>
        <DispatchActionTab
          onChangeTab={(e) => onChangeTab(e)}
          data={listTab}
          activeTab={activeTab}
          textSize={11}
        />
      </div>
    </div>
  )
}

export default Filter
