import SectorValuationProxy from '../../../proxies/sector/sectorConstituents/SectorValuationProxy'
import { ServiceBase } from '../../ServiceBase'

class SectorValuationService extends ServiceBase {
  getLineChart(params) {
    return this.getData(() => SectorValuationProxy.getLineChart(params))
  }

  getPriceChart(params) {
    return this.getData(() => SectorValuationProxy.getPriceChart(params))
  }

  getDataTable(params) {
    return this.applyMemoryCache(
      'SectorValuationService.getDataTable',
      params,
    )(() => SectorValuationProxy.getDataTable(params))
  }

  downloadDataTable(params) {
    return this.getFileDownload(() =>
      SectorValuationProxy.downloadDataTable(params),
    )
  }

  getValuationReport(params) {
    return this.applyMemoryCache(
      'SectorValuationService.getValuationReport',
      params,
    )(() => SectorValuationProxy.getValuationReport(params))
  }

  getAllOrganizationByIcbIdAndGroupId(params) {
    return this.applyMemoryCache(
      'SectorService.getAllOrganizationByIcbIdAndGroupId',
      params,
    )(() => SectorValuationProxy.getAllOrganizationByIcbIdAndGroupId(params))
  }

  getMarketCapOrganizationByIcbId(params) {
    return this.applyMemoryCache(
      'SectorService.getMarketCapOrganizationByIcbId',
      params,
    )(() => SectorValuationProxy.getMarketCapOrganizationByIcbId(params))
  }
}

export default new SectorValuationService()
