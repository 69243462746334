import { useSelector } from 'react-redux'
import { BalanceOfTrade } from '.'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import {
  selectCountPeriodNum,
  selectCurrentTimeType,
  selectMaxCurrentMonth,
  selectMaxCurrentYear,
} from './store/slice'
import { downloadBalanceOfTradeTable } from './store/thunk'

export const PanelBalanceOfTrade = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const currentTime = useSelector(selectCurrentTimeType)
  const maxCurrentMonth = useSelector(selectMaxCurrentMonth)
  const maxCurrentYear = useSelector(selectMaxCurrentYear)
  const countPeriodNum = useSelector(selectCountPeriodNum)

  const downloadCsv = () => {
    const params = {
      DataType: currentTime,
      Limit: countPeriodNum,
    }

    if (maxCurrentMonth) {
      params.Month = maxCurrentMonth
    }

    if (maxCurrentYear) {
      params.Year = maxCurrentYear
    }

    return downloadBalanceOfTradeTable(params)
  }

  return (
    <Panel
      title="economy.importExport.importExport.overview.BALANCE_OF_TRADE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={downloadCsv}
    >
      <BalanceOfTrade
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
