import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { formatVal, valDivBillion } from '../../../../utils/Value'
import { ITEM_TYPE } from '../constant'
import {
  selectLoadingDefaultItems,
  selectSelectedItems,
} from '../filter/store/slice'
import { setLevels } from '../helpers'
import { Span } from './../../../../common/html/Span'
import { INDICATORS } from './constant'
import style from './index.module.css'
import SectorName from './SectorName'
import {
  getRowIds,
  handleLevels,
  selectIsHasData,
  selectLoading,
  selectReCalcWidths,
  selectValueById,
  setRowIds,
  sortByLevel,
} from './store/slice'
import TickerName from './TickerName'

const ValuationTable = () => {
  const dispatch = useDispatch()

  const loadingDefaultItems = useSelector(selectLoadingDefaultItems)
  const loading = useSelector(selectLoading)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const selectedItems = useSelector(selectSelectedItems)
  const isHasData = useSelector(selectIsHasData)
  const rowIds = useSelector(getRowIds)

  useEffect(() => {
    const rowIdsFilter = selectedItems
      .filter((item) => !item.isDeleted)
      .map((item) =>
        item.icbId
          ? `${ITEM_TYPE.SECTOR}-${item.icbId}`
          : `${ITEM_TYPE.TICKER}-${item.organizationId}`,
      )
    dispatch(setRowIds(rowIdsFilter))
    dispatch(handleLevels(setLevels(rowIdsFilter)))
  }, [selectedItems, isHasData])

  return (
    <div className="h-100">
      {loading || loadingDefaultItems ? (
        <Loading />
      ) : rowIds?.length && isHasData ? (
        <Table
          ids={rowIds}
          getDataFromRedux={selectValueById}
          isLoading={loading}
          schema={Object.keys(INDICATORS).map((key, index) => {
            const colId = INDICATORS[key]
            const title = 'sector.sectorConstituents.valuation.dataTable.' + key

            return {
              colId,
              title,
              disableSort: !index,
              isI18n: false,
              thStyle: {
                textAlign: index === 0 ? 'left' : 'right',
                verticalAlign: 'baseline',
              },
              renderTh: () => {
                return (
                  <Span style={{ fontSize: 10 }} className={style.thCell}>
                    <Translate dangerousHTML value={title} />
                  </Span>
                )
              },
              tdStyle: {
                textAlign: index === 0 ? 'left' : 'right',
                whiteSpace: 'nowrap',
              },
              render: (val, rowId) => {
                const isSectorRow = rowId.startsWith(ITEM_TYPE.SECTOR)
                const id = rowId.split('-')[1]
                switch (colId) {
                  case INDICATORS.name:
                    return isSectorRow ? (
                      <SectorName icbId={id} />
                    ) : (
                      <TickerName organizationId={id} />
                    )
                  case INDICATORS.marketCap:
                    return (
                      <div className={isSectorRow ? style.sectorRow : ''}>
                        {formatVal(valDivBillion(val))}
                      </div>
                    )
                  case INDICATORS.pB:
                  case INDICATORS.pE:
                  case INDICATORS.bvps:
                  case INDICATORS.peForward:
                    return (
                      <div className={isSectorRow ? style.sectorRow : ''}>
                        {formatVal(val)}
                      </div>
                    )
                  default:
                    return val
                }
              },
            }
          })}
          reCalcWidths={reCalcWidths}
          stickyFirstColumn
          sort={sortByLevel}
          hasFooter={false}
          hasTooltip={false}
        />
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default ValuationTable
