import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { TABLE_ROW_LEVEL } from '../../../../corporate/overviews/financialMetric/constants'
import { selectItemData } from './store/slice'

const MARGIN_LEFT = 40

const FirstColumnCell = ({ val, rowId, style, className, onRowCollapse }) => {
  const tdRef = useRef()
  const item = useSelector(selectItemData(rowId))

  const [isCollapse, setIsCollapse] = useState(false)

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  return (
    <td ref={tdRef} className={className} style={style}>
      <div
        className="align-center"
        style={{
          marginLeft: (item.level - 1) * MARGIN_LEFT,
        }}
      >
        {item.isCollapse ? (
          <button
            style={{ cursor: 'pointer' }}
            className="align-center"
            onClick={onCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            ></i>
          </button>
        ) : (
          <></>
        )}
        <TextEllipsisStaticLine
          val={val}
          appendStyle={{
            color: item.level === TABLE_ROW_LEVEL.LEVEL_1 ? '#fecf2f' : '',
            lineHeight: 1.5,
            width: 'fit-content',
          }}
        />
      </div>
    </td>
  )
}

export default FirstColumnCell
