import { useSelector } from 'react-redux'
import { MarginGrowth } from '.'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { TOTAL_ID } from './constants'
import {
  selectCurrentCompaniesIds,
  selectFilter,
  selectMaxDate,
  selectTopCompanyId,
} from './store/slice'
import { downloadMarginGrowth } from './store/thunk'

export const PanelMarginGrowth = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const filter = useSelector(selectFilter)
  const maxDate = useSelector(selectMaxDate)
  const currentCompaniesIds = useSelector(selectCurrentCompaniesIds)
  const topCompanyId = useSelector(selectTopCompanyId)

  const currentQuarter = filter?.currentQuarter || maxDate?.quarter
  const currentYear = filter?.currentYear || maxDate?.year

  const titlePanel = 'market.marginLoans.MARGIN_GROWTH'

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={() =>
        downloadMarginGrowth({
          RealQuarter: currentQuarter,
          RealYear: currentYear,
          OrganIds: currentCompaniesIds.length
            ? currentCompaniesIds.filter((id) => id !== TOTAL_ID).toString()
            : topCompanyId.toString(),
        })
      }
    >
      <MarginGrowth
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
