import PropTypes from 'prop-types'
import { Loading } from '../../loading'
import useGetExchange from '../../masterData/useGetExchange'
import useGetICB from '../../masterData/useGetICB'
import GroupStock from './GroupStock'
import style from './index.module.css'
import MenuTab from './MenuTab'
import SearchTicker from './SearchTicker'

const exceptExchange = [65 /* OTC */, 68 /* PRIVATE */]

const Filter = ({
  loading,
  handleAddTicker,
  handleAddIndicator,
  templates,
  handleRenameTemplate,
  handleDeleteTemplate,
  handleGetTemplate,
  isIndicesSectors,
  isFilterIcbLevel5,
  isEconomy,
  indicatorTree,
  loadingIndicator,
  isHasFiinXTemplate,
  isFiinXTemplate,
  isOnlyEnableLastExchange,
  keyResetTree,
  fiinXTemplates,
  handleGetFiinXTemplate,
}) => {
  const exchange = useGetExchange(
    true,
    true,
    isIndicesSectors
      ? (exchange) => !exceptExchange.includes(exchange.groupId)
      : undefined,
  )
  const icb = useGetICB(
    true,
    true,
    isFilterIcbLevel5 ? (icb) => icb.icbLevel !== 5 : undefined,
    (icb) => {
      return { ...icb, icbName: icb.icbName + ' L' + icb.icbLevel }
    },
  )

  return (
    <div className={`h-100 ${loading ? style.filterBlur : ''}`}>
      {loading && <Loading />}
      {!isEconomy && (
        <div
          className={`${style.h50} ${
            isFiinXTemplate ? style.containerLoadingBlur : ''
          }`}
        >
          {!isIndicesSectors && (
            <SearchTicker handleAddTicker={handleAddTicker} />
          )}
          <GroupStock
            key={keyResetTree}
            handleAddTicker={handleAddTicker}
            exchange={exchange}
            icb={icb}
            isIndicesSectors={isIndicesSectors}
            isOnlyEnableLastExchange={isOnlyEnableLastExchange}
          />
        </div>
      )}
      <div className={!isEconomy ? style.h50 : 'h-100'}>
        <MenuTab
          handleAddIndicator={handleAddIndicator}
          templates={templates}
          fiinXTemplates={fiinXTemplates}
          handleRenameTemplate={handleRenameTemplate}
          handleDeleteTemplate={handleDeleteTemplate}
          handleGetTemplate={handleGetTemplate}
          handleGetFiinXTemplate={handleGetFiinXTemplate}
          indicatorTree={indicatorTree}
          loadingIndicator={loadingIndicator}
          isEconomy={isEconomy}
          isHasFiinXTemplate={isHasFiinXTemplate}
          isFiinXTemplate={isFiinXTemplate}
          keyResetTree={keyResetTree}
        />
      </div>
    </div>
  )
}

Filter.propTypes = {
  handleAddTicker: PropTypes.func,
  handleAddIndicator: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  fiinXTemplates: PropTypes.array,
  handleRenameTemplate: PropTypes.func.isRequired,
  handleDeleteTemplate: PropTypes.func.isRequired,
  handleGetTemplate: PropTypes.func.isRequired,
  handleGetFiinXTemplate: PropTypes.func,
  isIndicesSectors: PropTypes.bool,
  indicatorTree: PropTypes.object,
  loadingIndicator: PropTypes.bool,
  loading: PropTypes.bool,
  isEconomy: PropTypes.bool,
  isHasFiinXTemplate: PropTypes.bool,
  isFiinXTemplate: PropTypes.bool,
  isOnlyEnableLastExchange: PropTypes.bool,
  isFilterIcbLevel5: PropTypes.bool,
  keyResetTree: PropTypes.any,
}

Filter.defaultProps = {
  isIndicesSectors: false,
  isEconomy: false,
  indicatorTree: {},
  loading: false,
  isHasFiinXTemplate: false,
  isFiinXTemplate: false,
  isOnlyEnableLastExchange: false,
  isFilterIcbLevel5: false,
  fiinXTemplates: [],
}

export default Filter
