import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../../common/html/Span'
import DispatchActionTab from '../../../../../../common/tabs/DispatchActionTab'
import { OPTION_RELEASR_METHOD } from '../../constants'
import { latePaymentType } from '../constants'
import {
  selectActiveMethod,
  selectActiveTab,
  updateMethod,
  updateTab,
} from '../store/slice'
import style from './style.module.css'

const FilterCountry = () => {
  const dispatch = useDispatch()
  const activeTab = useSelector(selectActiveTab)
  const activeMethod = useSelector(selectActiveMethod)

  const handleMethod = (value) => {
    dispatch(updateMethod(value))
  }

  return (
    <div className="d-flex ali-center">
      <div className={`${style.financialMetricHeader}`}>
        <DispatchActionTab
          onChangeTab={(e) => dispatch(updateTab(e.value))}
          data={latePaymentType.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          activeTab={activeTab}
          textSize={12}
        />
      </div>
      <div className={`d-flex ali-center ml-8`}>
        {OPTION_RELEASR_METHOD.map(({ value, label }) => {
          return (
            <div
              key={value}
              className={`d-flex align-center ${style.wrapRadio}
              ${value === activeMethod && style.radioActive}
              `}
              onClick={() => handleMethod(value)}
            >
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={value === activeMethod}
                readOnly={true}
              />
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <Translate value={label} />
              </Span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default FilterCountry
