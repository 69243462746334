import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Divider } from '../../../../../common/divider'
import { Button } from '../../../../../common/html/Button'
import { Span } from '../../../../../common/html/Span'
import SelectCustom from '../../../../../common/selectCustom'
import { DispatchActionTabWithCalendar } from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import { TooltipInfo } from '../../../../../common/tooltipInfo'
import { FilterAllIssuerSelect } from '../../../Filter/FilterAllIssuerSelect'
import { FilterBondSelect } from '../../../Filter/FilterBondSelect'
import { FilterCreateBondSelect } from '../../../Filter/FilterCreateBondSelect'
import FilterSectorSelect from '../../../Filter/FilterSectorSelect'
import { FilterWatchlistSelect } from '../../../Filter/FilterWatchlistSelect'
import {
  BOND_TYPE_RADIOS,
  CREATE_BOND_TYPE_RADIOS,
  TABLE_FIELD_CODES,
} from '../../../Screening/constants'
import { INDICATOR_GROUPS, TAB_TYPES } from '../../../constants'
import {
  selectActiveTab,
  selectAddInformatics,
  selectColumns,
  selectCreateStatus,
  selectFilter,
  selectFilterParams,
  selectInitialOrder,
  selectInitialSortBy,
  selectParams,
  selectTemplate,
  setFilter,
  setFilterParameters,
} from '../../../store/slice'
import {
  selectFullColumnId,
  selectInitialHeaders,
  selectLoading,
} from '../../Table/store/slice'
import { FILTER_TIME_RANGE_TABS, POPUP_TYPES } from '../../constants'
import style from '../index.module.css'
import { FilterButtons } from './FilterButtons'
import { FilterPopups } from './FilterPopups'
import { withFilterActions } from './withFilterActions'

const Filter = (props) => {
  const { containerRef, setIsShow, handleChange, redirectToBondIssuer } = props

  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const { visible } = useSelector(selectFilter)
  const templates = useSelector(selectTemplate)
  const params = useSelector(selectParams)
  const { indicatorGroup, ...restParams } = useSelector(selectFilterParams)
  const addInformatics = useSelector(selectAddInformatics)
  const currentTab = useSelector(selectActiveTab)
  const columns = useSelector(selectColumns)
  const headers = useSelector(selectInitialHeaders)
  const ids = useSelector(selectFullColumnId(1))
  const loadingTable = useSelector(selectLoading)
  const initialSortBy = useSelector(selectInitialSortBy)
  const initialOrder = useSelector(selectInitialOrder)
  const isCreateBond = useSelector(selectCreateStatus)

  const [isSmallScreen, setIsSmallScreen] = useState(false)

  // Get data
  const isDisable = !templates.some((item) => item.templateId === TAB_TYPES.RAW)
  const isChangeColumn = isEqual(headers, ids)
  const isChangeHeader =
    !Object.keys(columns)?.length ||
    (!!Object.keys(columns)?.length && columns.id !== currentTab)
  const isChangeSort =
    initialSortBy === restParams.sortBy && initialOrder === restParams.order

  const bondTypeOption = useMemo(
    () =>
      addInformatics.find(
        (item) =>
          item.indicatorGroup === indicatorGroup &&
          (item.tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE ||
            item.tableFieldCode === TABLE_FIELD_CODES.EN_BOND_TYPE),
      ),
    [indicatorGroup, addInformatics, locale],
  )

  // Actions
  const handleChangeTimeRange = (item) => {
    dispatch(setFilterParameters({ key: 'timeRange', value: item.value }))
    dispatch(
      setFilterParameters({
        key: 'startDate',
        value: '',
      }),
    )
    dispatch(
      setFilterParameters({
        key: 'endDate',
        value: '',
      }),
    )
  }

  const handleChangeCalendar = (payload) => {
    dispatch(
      setFilterParameters({
        key: 'timeRange',
        value: payload.activeTab,
      }),
    )
    dispatch(
      setFilterParameters({
        key: 'startDate',
        value: payload.startDate,
      }),
    )
    dispatch(
      setFilterParameters({
        key: 'endDate',
        value: payload.endDate,
      }),
    )
  }

  // Use effect
  useEffect(() => {
    if (isCreateBond) {
      setIsShow(null)
    }
  }, [isCreateBond])

  useEffect(() => {
    const resize = () => {
      if (containerRef.current?.clientWidth < 1400) {
        setIsSmallScreen(true)
      } else {
        setIsSmallScreen(false)
      }
    }
    resize()
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [containerRef.current?.clientWidth, visible])

  return (
    <div
      className="d-flex justify-content-space-between"
      style={{ flexWrap: 'wrap', gap: 8 }}
    >
      <div className="d-flex ali-center" style={{ gap: 8 }}>
        {indicatorGroup === INDICATOR_GROUPS.BOND_CREATING ? (
          <FilterCreateBondSelect
            width={195}
            isChangeTab
            redirectToBondIssuer={redirectToBondIssuer}
          />
        ) : indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER ? (
          <FilterAllIssuerSelect
            width={200}
            isChangeTab
            redirectToBondIssuer={redirectToBondIssuer}
          />
        ) : (
          <FilterBondSelect
            width={195}
            isChangeTab
            redirectToBondIssuer={redirectToBondIssuer}
          />
        )}
        {indicatorGroup !== INDICATOR_GROUPS.ALL_ISSUER && (
          <div style={{ width: 150 }}>
            <SelectCustom
              value={
                params?.parameter?.conditions?.find(
                  (item) =>
                    item.indicatorId === (bondTypeOption?.indicatorId ?? 2),
                )?.conditionValues
              }
              selectData={
                indicatorGroup === INDICATOR_GROUPS.BOND_CREATING
                  ? CREATE_BOND_TYPE_RADIOS
                  : BOND_TYPE_RADIOS
              }
              handleChange={(value) => handleChange(value)}
              appendStyle={{ fontWeight: 340 }}
              renderTextSelect="bond.bondScreening.common.BOND_TYPE"
              isI18n
            />
          </div>
        )}
        <div style={{ width: 210 }}>
          <FilterSectorSelect redirectToBondIssuer={redirectToBondIssuer} />
        </div>
        <FilterWatchlistSelect
          width={135}
          redirectToBondIssuer={redirectToBondIssuer}
        />
        <div
          style={{ width: 135 }}
          className={style.filterOther}
          onClick={() =>
            dispatch(setFilter({ key: 'visible', value: !visible }))
          }
        >
          <Span style={{ fontWeight: 340, fontSize: 12 }}>
            <Translate value="bond.bondScreening.bondList.FILTER_OTHER" />
          </Span>
          <img src="/iconFilter.svg" alt="Filter Icon" />
        </div>
        <div>
          <Button
            className={`btn btn-blue h-20 ${
              ((isChangeHeader &&
                isDisable &&
                isChangeColumn &&
                isChangeSort) ||
                loadingTable) &&
              style.disabled
            }`}
            style={{ padding: '0 12px', boxSizing: 'border-box' }}
            onClick={() => setIsShow(POPUP_TYPES.SAVE_TEMPLATE)}
            disabled={
              (isChangeHeader && isDisable && isChangeColumn && isChangeSort) ||
              loadingTable
            }
          >
            <Translate value="bond.bondScreening.bondList.SAVE_TEMPLATE" />
          </Button>
        </div>
        {!isSmallScreen && indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER && (
          <>
            <Divider isVertical style={{ margin: 0, height: 16 }} />
            <DispatchActionTabWithCalendar
              id="bondScreeningHeader"
              tabs={FILTER_TIME_RANGE_TABS}
              activeTab={restParams.timeRange}
              startDate={restParams.startDate}
              endDate={restParams.endDate}
              datePreset={FILTER_TIME_RANGE_TABS}
              excludeDateIntervals={[]}
              onChangeTab={handleChangeTimeRange}
              onChangeCalendar={handleChangeCalendar}
            />
            <TooltipInfo tooltip="bond.bondScreening.filter.ISSUER_NOTE" />
          </>
        )}
      </div>
      <FilterButtons
        isSmallScreen={isSmallScreen}
        setIsShow={setIsShow}
        onChangeTimeRange={handleChangeTimeRange}
        onChangeCalendar={handleChangeCalendar}
      />
      <FilterPopups {...props} />
    </div>
  )
}

export default withFilterActions(Filter)
