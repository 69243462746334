import SelectCustom from '../../../../common/selectCustom'

export const SelectType = ({ options, activeTab, onChangeTab, style = {} }) => {
  return (
    <div style={{ width: 150, ...style }}>
      <SelectCustom
        value={activeTab}
        isI18n={true}
        selectData={options.map((item) => ({
          name: item.name,
          value: item.value,
        }))}
        handleChange={onChangeTab}
      />
    </div>
  )
}
