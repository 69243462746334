export const reOrderByMonthAndYear = (data) => {
  const sorted = data?.sort((quarter, year) => {
    quarter = quarter?.split('-')
    year = year?.split('-')
    return new Date(quarter[1], quarter[0], 1) - new Date(year[1], year[0], 1)
  })
  return sorted
}

export const getItemsByIndexes = (data, indexArr) => {
  return (
    data.length &&
    (indexArr.length ? indexArr?.map((index) => data[index]) : data.slice(0, 6))
  )
}
