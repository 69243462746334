import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import EventEmitter from '../../../../../utils/EventEmitter'
import { useGetUserSettings } from '../../../useGetUserSettings'
import { suggestColors } from '../../constants'
import styles from '../../style.module.css'
import { ColorGradientComponent } from './ColorGradientComponent'
import { ColorInputComponent } from './ColorInputComponent'
import { ColorPickerComponent } from './ColorPickerComponent'
import { ColorSelectOptions } from './ColorSelectOptions'

export const CLICK_OUTSIDE_SET_COLOR = 'CLICK_OUTSIDE_SET_COLOR'

export const ChartColorComponent = ({
  isHideTitle,
  isHideGradient,
  isParent,
  value,
  gradientValue,
  onChange,
  onChangeGradient,
  data,
  onClose,
}) => {
  const { favoriteColors, removeFavoriteColor } = useGetUserSettings()

  const [color, setColor] = useState('#000000')

  const handleChangeColor = (val) => {
    setColor(val)
    onChange && onChange(val)
  }

  useEffect(() => {
    if (value !== color) {
      setColor(value)
    }
  }, [value])

  useEffect(() => {
    const clickOutsideSetColor = () => {
      onClose({ ...data, color })
    }
    EventEmitter.subscribe(CLICK_OUTSIDE_SET_COLOR, clickOutsideSetColor)
    return () =>
      EventEmitter.unsubscribe(CLICK_OUTSIDE_SET_COLOR, clickOutsideSetColor)
  }, [color, data])

  return (
    <div className={styles.settingBlockItem}>
      {!isHideTitle && (
        <div className={styles.settingBlockLeft}>
          <Span
            className={
              isParent ? styles.settingBlockTitle : styles.settingBlockSubTitle
            }
            style={{ fontSize: isParent ? 12 : 10 }}
          >
            <Translate value="financialChart.chartSettings.CHART_COLOR" />
          </Span>
        </div>
      )}
      <div className={!isHideTitle ? styles.settingBlockRight : 'w-100'}>
        {!isHideGradient && (
          <>
            <ColorGradientComponent
              value={gradientValue}
              onChange={onChangeGradient}
            />
            <div className={styles.settingBlockItemColorDivider} />
          </>
        )}
        <ColorSelectOptions
          options={suggestColors}
          onChange={handleChangeColor}
        />
        <ColorPickerComponent color={color} onChange={handleChangeColor} />
        <ColorInputComponent color={color} onChange={handleChangeColor} />
        <ColorSelectOptions
          isFavorite
          options={favoriteColors}
          onChange={handleChangeColor}
          removeFavoriteColor={removeFavoriteColor}
        />
      </div>
    </div>
  )
}

ChartColorComponent.propTypes = {
  isHideTitle: PropTypes.bool,
  isHideGradient: PropTypes.bool,
  isParent: PropTypes.bool,
  value: PropTypes.string,
  gradientValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onChangeGradient: PropTypes.func,
}

ChartColorComponent.defaultProps = {
  isHideTitle: false,
  isHideGradient: false,
  isParent: false,
  onChange: () => {},
  onChangeGradient: () => {},
}
