import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import style from '../../index.module.css'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 7,
}

const BLUE_COLOR_BAR = '#40abff'

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 20 }

const ChartComponent = ({ data, width, height, keyXAxis, barKey }) => {
  const importChartTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <table>
          <tbody>
            <tr className={style.trTable}>
              <td className={style.pdLabel}>
                <div className={style.mr12}>
                  <Span style={{ fontSize: 11 }}>{valueTooltip.time}</Span>:
                </div>
              </td>
              <td
                style={{
                  textAlign: 'right',
                }}
                className={`${style.pdLabel} ${style.fontWeight500}`}
              >
                <Span style={{ fontSize: 11 }}>
                  {`${formatVal(valueTooltip.value)} ${I18n.t(
                    'sector.sectorSpecific.fishery.MILLION_USD',
                  )}`}
                </Span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      {width && height && (
        <ChartContainer
          data={data}
          width={width}
          height={height}
          keyXAxis={keyXAxis}
          margin={MARGIN}
          xTickNum={SETTINGS.xTickNum}
          hasBarChart
          isShowXAxis
          isUseXAxisDiv
          showAllTick
          yAxis={[
            {
              id: 'left',
              orientation: 'left',
              keys: [barKey],
              isBarChart: true,
              tickNum: SETTINGS.yTickNum,
              label: `(${I18n.t('sector.sectorSpecific.fishery.MILLION_USD')})`,
              labelPosition: AXIS_LABEL_POSITION.LEFT,
            },
          ]}
          renderCustomTooltip={(payload) => importChartTooltip(payload)}
        >
          <Bar
            yAxisId={'left'}
            dataKey={barKey}
            barSize={getColumnSizeInBarChart(width, data.length)}
            isAnimationActive={false}
            fill={BLUE_COLOR_BAR}
          />
        </ChartContainer>
      )}
    </>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}

export default ChartComponent
