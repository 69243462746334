import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getThreeColorByValue } from '../../../utils/Color'
import style from './style.module.css'

const CellHighLight = ({ value, rowId, keyColor, selectCell }) => {
  const colorValue = useSelector(selectCell(rowId, keyColor))

  return (
    <div
      style={{
        color: getThreeColorByValue(Number(colorValue)),
      }}
      className={`h-100 d-flex ali-center justify-content-end ${style.containerTextBlink}`}
    >
      <span className={style.textBlink}>{value}</span>
    </div>
  )
}

CellHighLight.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  keyColor: PropTypes.string.isRequired,
  selectCell: PropTypes.func.isRequired,
}

export default CellHighLight
