import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Separator } from '../../../../common/separator'
import { selectMarketNews, selectMarketNewsLoading } from '../../store/slice'
import { getMarketNews } from '../../store/thunk'
import { Item } from './Item'

const RESPONSIVE_MIN_WIDTH = 1000

export const Grid = ({ width, height }) => {
  const dispatch = useDispatch()
  const marketNews = useSelector(selectMarketNews)
  const isLoading = useSelector(selectMarketNewsLoading)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(getMarketNews({ Top: 2 }))
  }, [locale])

  return (
    <>
      {width > RESPONSIVE_MIN_WIDTH && (
        <>
          <div
            className="d-flex"
            style={{
              width,
              height: `calc(${height} / 2 - 15px)`,
              marginBottom: 15,
            }}
          >
            <Item data={marketNews[0]} isLoading={isLoading} marginRight={10} />
            <Separator />
            <Item
              data={marketNews[1]}
              isLoading={isLoading}
              marginLeft={5}
              marginRight={5}
              offsetWidth={2}
            />
            <Separator />
            <Item data={marketNews[2]} isLoading={isLoading} marginLeft={10} />
          </div>
          <div
            className="d-flex"
            style={{
              width,
              height: `calc(${height} / 2 - 15px)`,
              marginTop: 15,
            }}
          >
            <Item data={marketNews[3]} isLoading={isLoading} marginRight={10} />
            <Separator />
            <Item
              data={marketNews[4]}
              isLoading={isLoading}
              marginLeft={5}
              marginRight={5}
              offsetWidth={2}
            />
            <Separator />
            <Item data={marketNews[5]} isLoading={isLoading} marginLeft={10} />
          </div>
        </>
      )}
      {width <= RESPONSIVE_MIN_WIDTH && (
        <>
          <div
            className="d-flex"
            style={{
              width,
              height: `calc((${height}) / 3 - 10px)`,
              marginBottom: 10,
            }}
          >
            <Item
              data={marketNews[0]}
              isLoading={isLoading}
              numColumn={2}
              marginRight={10}
            />
            <Item
              data={marketNews[1]}
              isLoading={isLoading}
              numColumn={2}
              marginLeft={10}
            />
          </div>
          <Separator isVertical={false} />
          <div
            className="d-flex"
            style={{
              width,
              height: `calc((${height}) / 3 - 10px)`,
              marginBottom: 10,
            }}
          >
            <Item
              data={marketNews[2]}
              isLoading={isLoading}
              numColumn={2}
              marginRight={10}
            />
            <Item
              data={marketNews[3]}
              isLoading={isLoading}
              numColumn={2}
              marginLeft={10}
            />
          </div>
          <Separator isVertical={false} />
          <div
            className="d-flex"
            style={{
              width,
              height: `calc((${height}) / 3 - 10px)`,
              marginBottom: 10,
            }}
          >
            <Item
              data={marketNews[4]}
              isLoading={isLoading}
              numColumn={2}
              marginRight={10}
            />
            <Item
              data={marketNews[5]}
              isLoading={isLoading}
              numColumn={2}
              marginLeft={10}
            />
          </div>
        </>
      )}
    </>
  )
}

Grid.propTypes = {
  width: PropTypes.number.isRequired,
}
