import { useRef, useState } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import Dropdown from '../dropdown'
import { HandleClickOutside } from '../HandleClickOutside'
import UseI18n from '../hooks/useI18n'
import { Button } from '../html/Button'
import { Span } from '../html/Span'
import Popup from '../popup'
import { ScrollComponent } from '../ScrollComponent'
import TextEllipsis from '../textEllipsis'
import style from './index.module.css'

const heightItem = 25

const PopupExportSheet = ({
  sheet,
  activeSheet,
  handleSubmit,
  handleCancel,
}) => {
  const sheetText = UseI18n('tool.dataExplorer.SHEET')

  const parentRef = useRef()

  const [isCurrentSheet, setIsCurrentSheet] = useState(true)
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [checked, setChecked] = useState(sheet.map((item) => item.value))

  const handleCheckCurrentSheet = () => setIsCurrentSheet(true)

  const handleCheckMultiSheet = () => setIsCurrentSheet(false)

  const handleDropdown = () => setIsOpenDropdown(!isOpenDropdown)

  const closeDropdown = () => setIsOpenDropdown(false)

  const handleChange = (e, value) => {
    const newChecked = [...checked]
    if (e.target.checked) {
      newChecked.push(value)
      setChecked(newChecked)
    } else {
      newChecked.splice(checked.indexOf(value), 1)
      setChecked(newChecked)
    }
  }

  const handleChangeAll = (e) => {
    if (e.target.checked) {
      setChecked(sheet.map((item) => item.value))
    } else {
      setChecked([])
    }
  }

  const apply = () => {
    handleSubmit(sheetSubmit)
  }

  const sheetSubmit = isCurrentSheet ? [activeSheet] : checked
  const isCheckAll = sheet.length && checked.length === sheet.length

  return (
    <>
      <Popup>
        <div className={`modal tool-page ${style.fitContent}`}>
          <div className="modal-title">
            <h3>
              <Span>
                <Translate value="tool.dataExplorer.EXPORT" />
              </Span>
            </h3>
            <a className="close-modal" onClick={handleCancel}>
              <i className="icon-delete-stroke" />
            </a>
          </div>
          <div className={`modal-content ${style.modalContent}`}>
            <Translate value="tool.dataExplorer.ASK_EXPORT" />
            <div className={style.popupContent}>
              <label
                onClick={handleCheckCurrentSheet}
                className="d-flex ali-center cursor-pointer"
              >
                <input
                  type="radio"
                  className={`radiobox radiobox2 cursor-pointer ${style.radio}`}
                  checked={isCurrentSheet}
                  readOnly
                />
                <span className={isCurrentSheet ? style.activeCheck : ''}>
                  <Translate value="tool.dataExplorer.SINGLE_EXPORT" />
                </span>
              </label>
              <div className="d-flex">
                <label
                  onClick={handleCheckMultiSheet}
                  className="d-flex ali-center cursor-pointer"
                >
                  <input
                    type="radio"
                    className={`radiobox radiobox2 cursor-pointer ${style.radio}`}
                    checked={!isCurrentSheet}
                    readOnly
                  />
                  <span className={!isCurrentSheet ? style.activeCheck : ''}>
                    <Translate value="tool.dataExplorer.MULTI_EXPORT" />
                  </span>
                </label>
                <div
                  ref={parentRef}
                  onClick={handleDropdown}
                  className={`${style.selectSheet} ${
                    isCurrentSheet ? style.disabled : ''
                  } cursor-pointer`}
                >
                  <TextEllipsis
                    isI18n={false}
                    text={
                      isCheckAll
                        ? I18n.t('tool.dataExplorer.ALL')
                        : checked
                            .map((id) =>
                              sheet
                                .find((item) => item.value === id)
                                .title?.replace('SHEET', sheetText),
                            )
                            .join('; ')
                    }
                  />
                  <i
                    className={
                      !isOpenDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
                    }
                  />
                </div>
              </div>
            </div>
            <div className="group-btn justify-content-end mt-9">
              <Button
                style={{ fontSize: 11 }}
                className={`btn w-80 h-20 ${style.btnCancel}`}
                onClick={handleCancel}
              >
                <Translate value="common.button.BUTTON_CANCEL" />
              </Button>
              <Button
                onClick={apply}
                style={{ fontSize: 11 }}
                className={`btn btn-blue w-80 h-20 ${
                  !sheetSubmit.length ? style.disabled : ''
                }`}
              >
                <Translate value="tool.smartScreening.APPLY" />
              </Button>
            </div>
          </div>
        </div>
      </Popup>
      {isOpenDropdown && (
        <Dropdown isShow={true} parentRef={parentRef}>
          <HandleClickOutside
            handleClickOutside={closeDropdown}
            exceptRef={parentRef}
          >
            <div
              style={{ height: Math.min(heightItem * (sheet.length + 1), 75) }}
              className={style.dropdownContainer}
            >
              <ScrollComponent>
                <div
                  style={{ height: `${heightItem}px` }}
                  className={style.itemDropdown}
                >
                  <input
                    checked={isCheckAll}
                    onChange={handleChangeAll}
                    type="checkbox"
                    className="checkbox cursor-pointer"
                  />
                  <TextEllipsis text="tool.dataExplorer.ALL" />
                </div>
                {sheet.map((item) => (
                  <div
                    key={item.value}
                    style={{ height: `${heightItem}px` }}
                    className={style.itemDropdown}
                  >
                    <input
                      checked={checked.includes(item.value)}
                      onChange={(e) => handleChange(e, item.value)}
                      type="checkbox"
                      className="checkbox cursor-pointer"
                    />
                    <TextEllipsis
                      isI18n={false}
                      text={item.title?.replace('SHEET', sheetText)}
                    />
                  </div>
                ))}
              </ScrollComponent>
            </div>
          </HandleClickOutside>
        </Dropdown>
      )}
    </>
  )
}

export default PopupExportSheet
