import { useSelector } from 'react-redux'
import { TYPE_STOCK_INDICES_SECTOR } from '../../../../common/hooks/useBasicInfo'
import { usePackagePermission } from '../../../../common/hooks/usePackagePermission'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Panel } from '../../../../common/panel'
import { getDateWithTimezone } from '../../../../utils/Datetime'
import Content from './issuesPlan/index'
import { selectFilter } from './issuesPlan/store/slice'
import { issuePlanExport } from './issuesPlan/store/thunk'

const IssuesPlan = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeZone = UseTimeZone()
  const { isTrial } = usePackagePermission(TYPE_STOCK_INDICES_SECTOR.BOND)

  const filter = useSelector(selectFilter)

  const onChangeExport = () => {
    const params = {
      FromDate: getDateWithTimezone(filter.FromDate, timeZone),
      EndDate: getDateWithTimezone(filter.EndDate, timeZone),
    }
    return issuePlanExport(params)
  }
  return (
    <Panel
      title="bond.overview.issuesPlan.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={!isTrial}
      downloadCsv={() => !isTrial && onChangeExport()}
    >
      <Content />
    </Panel>
  )
}

export default IssuesPlan
