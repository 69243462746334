import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { BUBBLE_SIZE_OPTION } from '../constant'
import style from '../index.module.css'
import { changeBubbleSize, selectBubbleSize } from '../store/slice'

const BubbleSize = () => {
  const dispatch = useDispatch()

  const bubbleSize = useSelector(selectBubbleSize)

  const handleChange = (value) => {
    dispatch(changeBubbleSize(value))
  }

  return (
    <div className={style.mt24}>
      <Span className={style.disabled} style={{ fontSize: 11 }}>
        <Translate value="sector.sectorConstituents.segmentation.BUBBLE_SIZE" />
      </Span>
      <div className={style.mt4}>
        <SelectCustom
          isI18n={true}
          selectData={BUBBLE_SIZE_OPTION}
          value={bubbleSize}
          handleChange={handleChange}
          fontSizeItem={10}
        />
      </div>
    </div>
  )
}

export default BubbleSize
