import PropTypes from 'prop-types'
import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { TABLE_TYPE_RADIO } from '../constants'

export const TableType = ({ onChange }) => {
  const [type, setType] = useState(TABLE_TYPE_RADIO[0].value)

  const onRadioClick = (e) => {
    const value = e.target.value
    setType(value)
    onChange(value)
  }

  return (
    <ul
      style={{ width: 'auto' }}
      className="list-check d-flex align-center mt-0"
    >
      {TABLE_TYPE_RADIO.map((item, index) => (
        <li key={item.value} className="mb-0">
          <label className="fs-12">
            <input
              type="radio"
              className="radiobox radiobox2"
              name="tableType"
              value={item.value}
              defaultChecked={index === 0}
              onClick={onRadioClick}
            />
            <Span
              style={{
                color: item.value === type ? '#2f9aee' : '',
                fontSize: 12,
              }}
            >
              <Translate value={item.title} />
            </Span>
          </label>
        </li>
      ))}
    </ul>
  )
}

TableType.propTypes = {
  onChange: PropTypes.func,
}
