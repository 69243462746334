import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { selectBasicInfoOverview } from '../store/slice'

export const IndustryTh = ({ title }) => {
  const basicInfo = useSelector(selectBasicInfoOverview)
  return (
    <Span style={{ fontSize: 10 }}>
      <Translate value={basicInfo.sector || title} />
    </Span>
  )
}

IndustryTh.propTypes = {
  title: PropTypes.string.isRequired,
}

IndustryTh.defaultProps = {
  title: '',
}
