import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { INDICATOR_OPTION } from '../constant'
import style from './index.module.css'
import { changeIndicator, selectIndicator } from './store/slice'

const Indicator = () => {
  const dispatch = useDispatch()

  const indicator = useSelector(selectIndicator)

  const handleChange = (value) => dispatch(changeIndicator(value))

  return (
    <div className="mt-8">
      <Span style={{ fontSize: 11 }} className={style.selectTitle}>
        <Translate value="sector.sectorConstituents.valuation.INDICATOR" />
      </Span>
      <SelectCustom
        isI18n
        value={indicator}
        selectData={INDICATOR_OPTION}
        handleChange={handleChange}
      />
    </div>
  )
}

export default Indicator
