export const news = {
  LATEST_NEWS: 'Latest News',
  CORPORATE_ANNOUNCEMENT: 'Corporate Announcement',
  EARNING: 'Earning',
  TRANSACTIONS: 'Transactions',
  OTHER_BUSINESS_ACTIVITIES: 'Other Business Activities',
  ANALYTIC_NEWS: 'Analytic News',
  DETAIL: 'Detail',
  OTHER_COMPANY_NEWS: 'OTHER COMPANY NEWS',
  NEWS: 'News',
  SHARE: 'Share',
}
