export const CHART_DOM_ID = 'SEGMENTATION_CHART_ID'

export const MAX_TICKER = 500
export const MAX_TEMPLATE = 100

export const EXCHANGE_CODE = {
  ALL_EXCHANGE: 'All',
  HOSE: 'HOSE',
  HNX: 'HNX',
  UPCOM: 'UPCOM',
  OTC: 'OTC',
  PRIVATE: 'PRIVATE',
}

export const LIST_TYPE = {
  COMPANY: 'COMPANY',
  WATCHLIST: 'WATCHLIST',
  INDEX: 'INDEX',
  SECTOR: 'SECTOR',
}

export const ICB_LEVEL = [
  { value: 1, name: 'sector.sectorConstituents.segmentation.SECTOR_LEVEL_1' },
  { value: 2, name: 'sector.sectorConstituents.segmentation.SECTOR_LEVEL_2' },
  { value: 3, name: 'sector.sectorConstituents.segmentation.SECTOR_LEVEL_3' },
  { value: 4, name: 'sector.sectorConstituents.segmentation.SECTOR_LEVEL_4' },
  { value: 5, name: 'sector.sectorConstituents.segmentation.SECTOR_LEVEL_5' },
]

export const COLORS = [
  '#f9c105',
  '#9c54e4',
  '#f4327b',
  '#42b9e9',
  '#27699b',
  '#f57f07',
  '#35a087',
  '#4bd9be',
  '#f1ffeb',
  '#F98E30',
  '#F1C594',
]

export const DEFAULT_BUBBLE_COLOR = '#4bd9be'

export const INDICATOR_TYPE = {
  X: 'X',
  Y: 'Y',
}

export const DEFAULT_INDICATOR_CATEGORY = 1

export const PERIOD_TYPE = {
  DAILY: 'Daily',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
}

export const PLOT_TYPE = {
  POINT_IN_TIME: 'POINT_IN_TIME',
  TIME_SERIES: 'TIME_SERIES',
}

export const TIME_SERIES_TYPE = {
  RANGE: 'RANGE',
  YEAR: 'YEAR',
}

export const LASTEST_YEARS = [...Array(20).keys()].map((v) => {
  const year = new Date().getFullYear()
  return {
    name: String(year - v),
    value: year - v,
  }
})

export const QUARTERS = [
  {
    name: 'sector.sectorConstituents.segmentation.QUARTER_1',
    value: 1,
  },
  {
    name: 'sector.sectorConstituents.segmentation.QUARTER_2',
    value: 2,
  },
  {
    name: 'sector.sectorConstituents.segmentation.QUARTER_3',
    value: 3,
  },
  {
    name: 'sector.sectorConstituents.segmentation.QUARTER_4',
    value: 4,
  },
]

export const BUBBLE_SIZE_TYPE = {
  TOTAL_ASSETS: 'totalAssets',
  MARKET_CAP: 'marketCap',
  VOLUMN_1W: 'avg1WeekTotalVolume',
  VOLUMN_1M: 'avg1MonthTotalVolume',
  VOLUMN_3M: 'avg3MonthTotalVolume',
  VOLUMN_6M: 'avg6MonthTotalVolume',
  VOLUMN_1Y: 'avg1YearTotalVolume',
  CONSTRAINT: 'CONSTRAINT',
}

export const BUBBLE_SIZE_OPTION = Object.keys(BUBBLE_SIZE_TYPE).map((key) => ({
  name: 'sector.sectorConstituents.segmentation.bubbleSize.' + key,
  value: BUBBLE_SIZE_TYPE[key],
}))

export const BUBBLE_COLOR_TYPE = {
  GROUP: 'GROUP',
  COMPANY: 'COMPANY',
  CONSTRAINT: 'CONSTRAINT',
}

export const BUBBLE_COLOR_OPTION = Object.keys(BUBBLE_COLOR_TYPE).map(
  (key) => ({
    name: 'sector.sectorConstituents.segmentation.bubbleColor.' + key,
    value: BUBBLE_COLOR_TYPE[key],
  }),
)
