import { MarketStatistics } from './marketStatistics'
import { Overview } from './overview'

export const CORPORATE_BOND_TABS = {
  OVERVIEW: 'overview',
  MARKET_STATISTICS: 'marketStatistics',
}

export const NAVIGATION_CORPORATE_BOND = [
  {
    title: 'bond.corporateBond.primaryMarket.OVERVIEW',
    component: <Overview />,
    value: CORPORATE_BOND_TABS.OVERVIEW,
  },
  {
    title: 'bond.corporateBond.primaryMarket.MARKET_STATISTICS',
    component: <MarketStatistics />,
    value: CORPORATE_BOND_TABS.MARKET_STATISTICS,
  },
]
