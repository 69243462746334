import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { COMPONENT, COMPONENT_FILTER } from '../../../configs/Layout'
import { PANEL_PADDING, Z_INDEX } from '../../constants/Common'
import EventEmitter, {
  ON_MOUSE_LEAVE,
  ON_MOUSE_OVER,
} from '../../utils/EventEmitter'
import { DownloadCsv } from '../downloadCsv'
import DownloadJpg from '../downloadJpg'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'
import { Span } from '../html/Span'
import { PanelHeader } from '../panelHeader'
import TextEllipsis from '../textEllipsis'
import ZoomWindow from '../zoomWindow'
import { SizeTracker } from './../sizeTracker'
import CollapseIcon from './CollapseIcon'
import { OpenWindowIcon } from './OpenWindowIcon'
import { RESIZE_TYPE, Resize } from './Resize'
import style from './index.module.css'

const widthHideFilter = 11
const DEFAULT_POSITION = -5
let newWidth = null

export const Panel = ({
  isOnlyResize,
  children,
  title,
  sizes,
  setSizes,
  panelRefs,
  panelKey,
  windowRoute,
  windowSize,
  windowZoom,
  isDownloadCsv,
  isDownloadJpg,
  downloadCsv,
  downloadJpgParams,
  isCollapse,
  isWithFilter,
  titleFilter,
  widthFilter,
  maxWidthFilter,
  setWidthFilter,
  renderFilter,
  renderCustomHeader,
  renderCustomTitle,
  headerClass,
  windowClass,
  titleJpg,
  isLoadingDownloadCsv,
  headerActionClass,
  headerTitleClass,
  bodyClass,
  hasPanelPadding,
  isMultiOption,
  options,
  isIconChart,
  handleCustom,
}) => {
  const headerActionRef = useRef()
  const previousFilterWidth = useRef(0)

  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [size, setSize] = useState({})
  const [isShowFilter, setIsShowFilter] = useState(true)
  const [isShowResizeFilter, setIsShowResizeFilter] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [positionFilterResize, setPositionFilterResize] =
    useState(DEFAULT_POSITION)
  const [widthHeaderTitle, setWidthHeaderTitle] = useState(0)
  const [loadingDownloadCsv, setLoadingDownloadCsv] = useState(false)
  const panelPadding = hasPanelPadding ? PANEL_PADDING : 0

  const onMouseEnter = () => {
    if (!isHover) {
      setIsHover(true)
      EventEmitter.dispatch(ON_MOUSE_OVER, panelKey)
    }
  }

  const onMouseEnterFilterResize = () => {
    setIsShowResizeFilter(true)
  }

  const onMouseLeave = () => {
    if (isHover) {
      setIsHover(false)
      EventEmitter.dispatch(ON_MOUSE_LEAVE, panelKey)
    }
  }

  const onMouseLeaveFilterResize = () => {
    setIsShowResizeFilter(false)
  }

  const calcResizeSize = () => {
    const verticalResize = sizes[panelKey].verticalResize
    let height = 0
    if (verticalResize) {
      verticalResize.forEach(({ key, isResizeWithParent }) => {
        if (isResizeWithParent && panelRefs[key]) {
          height +=
            panelRefs[key].getBoundingClientRect().height + 2 * COMPONENT.MARGIN
        }
      })
    }

    const horizontalResize = sizes[panelKey].horizontalResize
    let width = 0
    if (horizontalResize) {
      horizontalResize.forEach(({ key, isResizeWithParent }) => {
        if (isResizeWithParent && panelRefs[key]) {
          width +=
            panelRefs[key].getBoundingClientRect().width + 2 * COMPONENT.MARGIN
        }
      })
    }

    if (panelRefs[panelKey]) {
      setSize({
        ...size,
        width: panelRefs[panelKey].getBoundingClientRect().width + width,
        height: panelRefs[panelKey].getBoundingClientRect().height + height,
      })
    }
  }

  useEffect(() => {
    calcResizeSize()
  }, [])

  useEffect(() => {
    const timeOut = setTimeout(calcResizeSize, 0)
    return () => clearTimeout(timeOut)
  }, [sizes])

  useEffect(() => {
    setLoadingDownloadCsv(isLoadingDownloadCsv)
  }, [isLoadingDownloadCsv])

  const getStyle = () => {
    const width = sizes[panelKey].width
    const height = sizes[panelKey].height
    const top = sizes[panelKey].top
    const left = sizes[panelKey].left

    return { width, height, top, left }
  }

  const handleShowFilter = isWithFilter
    ? () => setIsShowFilter(true)
    : undefined

  const handleHideFilter = isWithFilter
    ? () => setIsShowFilter(false)
    : undefined

  const renderHeaderAction = () => {
    return (
      <div
        className={`window-header-action align-center ${headerActionClass}`}
        ref={headerActionRef}
      >
        {windowRoute && (
          <OpenWindowIcon windowRoute={windowRoute} windowSize={windowSize} />
        )}

        {isIconChart && (
          <a style={{ marginLeft: 16 }}>
            <i className="icon-chart-none" />
          </a>
        )}
        {windowZoom && (
          <ZoomWindow
            panelRefs={panelRefs[panelKey]}
            panelKey={panelKey}
            classVerticalResize={style.resizeX}
            classHorizontalResize={style.resizeY}
            sizes={sizes}
            setSizes={setSizes}
            handleCustom={handleCustom}
          />
        )}
        {isDownloadCsv && (
          <DownloadCsv
            downloadCsv={downloadCsv}
            setLoadingDownloadCsv={setLoadingDownloadCsv}
            isMultiOption={isMultiOption}
            options={options}
          />
        )}
        {isDownloadJpg && (
          <DownloadJpg data={downloadJpgParams} titleJpg={titleJpg} />
        )}
        {isCollapse && (
          <CollapseIcon sizes={sizes} setSizes={setSizes} panelKey={panelKey} />
        )}
        {renderCustomHeader && renderCustomHeader()}
      </div>
    )
  }

  const onResizeFilter = (e) => {
    const minWidthFilter =
      sizes[panelKey].minWidthFilter || COMPONENT_FILTER.MIN_SIZE.WIDTH
    const diff =
      formatWithZoom(e.clientX || e.touches?.[0]?.clientX || 0, true) -
      previousFilterWidth.current
    newWidth = widthFilter + diff

    if (newWidth < minWidthFilter || newWidth > maxWidthFilter) {
      newWidth = newWidth < minWidthFilter ? minWidthFilter : maxWidthFilter
      setIsShowResizeFilter(true)
    } else {
      setPositionFilterResize(positionFilterResize - diff)
    }
  }

  const onStopResizeFilter = (e) => {
    setWidthFilter(newWidth)
    newWidth = null
    setIsShowResizeFilter(false)
    setPositionFilterResize(DEFAULT_POSITION)
    previousFilterWidth.current = formatWithZoom(
      e.clientX || e.touches?.[0]?.clientX || 0,
      true,
    )

    window.removeEventListener('mousemove', onResizeFilter)
    window.removeEventListener('mouseup', onStopResizeFilter)
    window.removeEventListener('touchmove', onResizeFilter)
    window.removeEventListener('touchend', onStopResizeFilter)
  }

  const startResizeFilter = (e) => {
    if (isShowFilter) {
      setIsShowResizeFilter(true)
      previousFilterWidth.current = formatWithZoom(
        e.clientX || e.touches?.[0]?.clientX || 0,
      )

      window.addEventListener('mousemove', onResizeFilter)
      window.addEventListener('mouseup', onStopResizeFilter)
      window.addEventListener('touchmove', onResizeFilter)
      window.addEventListener('touchend', onStopResizeFilter)
    }
  }

  const getFilterResizeStyle = () => {
    const styles = {
      backgroundColor: isShowResizeFilter && isShowFilter ? '#454b56' : '',
      zIndex: Z_INDEX.PANEL_RESIZE,
    }

    return {
      ...styles,
      right: positionFilterResize,
      cursor: isShowResizeFilter && isShowFilter ? 'ew-resize' : 'default',
    }
  }

  useEffect(() => {
    setWidthHeaderTitle(
      getStyle().width -
        PANEL_PADDING -
        formatWithZoom(
          headerActionRef?.current?.getBoundingClientRect()?.width || 0,
        ),
    )
  }, [headerActionRef?.current?.getBoundingClientRect()?.width, getStyle()])

  return (
    <div
      className={style.panelWrapper}
      style={getStyle()}
      ref={(el) => (panelRefs[panelKey] = el)}
    >
      {isOnlyResize && children}
      {!isOnlyResize && (
        <div className={`window h-100 ${windowClass}`}>
          <SizeTracker>
            {(size) => {
              const getWidthFilter = () => ({
                width: isShowFilter ? widthFilter : `${widthHideFilter}px`,
              })
              return (
                <>
                  <PanelHeader className={headerClass}>
                    {isWithFilter && (
                      <div
                        className={`d-flex j-b ali-center ${style.filter}`}
                        style={getWidthFilter()}
                      >
                        <div
                          className="window-header-title"
                          style={getWidthFilter()}
                        >
                          <i className="icon-filter" />
                          &nbsp;&nbsp;
                          {isShowFilter && (
                            <Span>
                              <Translate value={titleFilter || ''} />
                            </Span>
                          )}
                        </div>
                        <div className="window-header-action">
                          {isShowFilter && (
                            <a
                              onClick={handleHideFilter}
                              className={style.pointer}
                            >
                              <i className="icon-arrow-left-two" />
                            </a>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="d-flex w-100 j-b">
                      <div
                        className={`window-header-title ${headerTitleClass}`}
                      >
                        <Span>
                          {renderCustomTitle ? (
                            renderCustomTitle(title)
                          ) : (
                            <div
                              style={{
                                width: widthHeaderTitle,
                                textTransform: 'uppercase',
                              }}
                            >
                              <TextEllipsis text={title} />
                            </div>
                          )}
                        </Span>
                      </div>
                      {renderHeaderAction()}
                    </div>
                  </PanelHeader>
                  {size.height && (
                    <div
                      className={`window-body relative ${bodyClass}`}
                      style={{
                        height: `calc(100% - ${size.height + panelPadding}px)`,
                      }}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                    >
                      {loadingDownloadCsv && (
                        <div className={style.spinLoading}>
                          <div className={style.loadingBox}>
                            <div className={style.loader}></div>
                          </div>
                        </div>
                      )}
                      <div
                        className={` d-flex h-100 ${
                          loadingDownloadCsv ? style.blurPanel : ''
                        }`}
                      >
                        {isWithFilter && (
                          <div
                            className={`${style.contentFilter} h-100`}
                            style={getWidthFilter()}
                          >
                            {renderFilter && (
                              <>
                                <div
                                  className="h-100 position-relative"
                                  style={
                                    !isShowFilter
                                      ? { display: 'none' }
                                      : getWidthFilter()
                                  }
                                >
                                  {renderFilter()}
                                </div>
                                <div
                                  style={getFilterResizeStyle()}
                                  className={style.resizeFilter}
                                  onMouseDown={startResizeFilter}
                                  onMouseEnter={onMouseEnterFilterResize}
                                  onMouseLeave={onMouseLeaveFilterResize}
                                  onTouchStart={startResizeFilter}
                                />
                              </>
                            )}
                            <div
                              style={
                                isShowFilter ? { display: 'none' } : undefined
                              }
                              className="h-100 d-flex ali-center"
                            >
                              <i
                                onClick={handleShowFilter}
                                className={`icon-arrow-right-two ${style.pointer}`}
                              />
                            </div>
                          </div>
                        )}
                        <div
                          className={`w-100 h-100 ${
                            sizes[panelKey].isOpenCollapse !== false
                              ? ''
                              : style.displayNone
                          }`}
                        >
                          {children}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )
            }}
          </SizeTracker>
        </div>
      )}

      {!sizes[panelKey].disableVerticalResize && (
        <Resize
          type={RESIZE_TYPE.RESIZE_X}
          setSizes={setSizes}
          sizes={sizes}
          panelKey={panelKey}
          resizeClassName={style.resizeX}
          size={size}
        />
      )}
      {!sizes[panelKey].disableHorizontalResize && (
        <Resize
          type={RESIZE_TYPE.RESIZE_Y}
          setSizes={setSizes}
          sizes={sizes}
          panelKey={panelKey}
          resizeClassName={style.resizeY}
          size={size}
        />
      )}
    </div>
  )
}

Panel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  windowRoute: PropTypes.string,
  windowSize: PropTypes.object,
  windowZoom: PropTypes.bool,
  isDownloadJpg: PropTypes.bool,
  downloadJpgParams: PropTypes.object,
  isDownloadCsv: PropTypes.bool,
  downloadCsv: PropTypes.func,
  isOnlyResize: PropTypes.bool,
  isCollapse: PropTypes.bool,
  isWithFilter: PropTypes.bool,
  titleFilter: PropTypes.string,
  widthFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidthFilter: PropTypes.number,
  renderFilter: PropTypes.func,
  renderCustomHeader: PropTypes.func,
  renderCustomTitle: PropTypes.func,
  windowClass: PropTypes.string,
  headerClass: PropTypes.string,
  titleJpg: PropTypes.string,
  isLoadingDownloadCsv: PropTypes.bool,
  headerActionClass: PropTypes.string,
  headerTitleClass: PropTypes.string,
  bodyClass: PropTypes.string,
  hasPanelPadding: PropTypes.bool,
  isMultiOption: PropTypes.bool,
  options: PropTypes.array,
}

Panel.defaultProps = {
  windowRoute: null,
  windowSize: {},
  title: '',
  isDownloadCsv: false,
  windowZoom: false,
  isDownloadJpg: false,
  downloadCsv: () => {},
  downloadJpgParams: {},
  isOnlyResize: false,
  isCollapse: false,
  isWithFilter: false,
  widthFilter: '20%',
  windowClass: '',
  isLoadingDownloadCsv: false,
  headerActionClass: '',
  headerTitleClass: '',
  bodyClass: '',
  hasPanelPadding: true,
  maxWidthFilter: COMPONENT_FILTER.MAX_SIZE.WIDTH,
  isMultiOption: false,
  options: null,
}
