import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { selectValueById } from './store/slice'

export const OtherColumnsCell = ({ val, rowId, style, className }) => {
  const unit = useSelector(selectValueById(rowId, 'unit'))

  return (
    <td className={className} style={{ ...style, textAlign: 'right' }}>
      {val + (unit !== EMPTY_VALUE ? I18n.t(unit) : '')}
    </td>
  )
}

OtherColumnsCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
}
