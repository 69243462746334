import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { screenTypes } from '../../../constants'
import { ReactComponent as IconShareBlack } from '../../../icon/icon-share.svg'
import { selectDashboard } from '../../../store/slice'
import style from './style.module.css'

const IconShare = () => {
  const { screenType } = useSelector(selectDashboard)

  if (
    screenType === screenTypes.EDIT_CHART ||
    screenType === screenTypes.SETTING_CREATE_CHART
  ) {
    return (
      <IconShareBlack
        className={style.disable}
        title={I18n.t(
          'financialChart.PLEASE_SAVE_YOUR_CHANGES_BEFORE_SHARING_CHART',
        )}
      />
    )
  }

  return <IconShareBlack title={I18n.t('financialChart.SHARE')} />
}

IconShare.propTypes = {}

export default IconShare
