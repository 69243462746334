import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectIsAcc, selectIsYearly } from '../../balanceOfTrade/store/slice'
import style from '../../index.module.css'
import { ChartComponent } from './ChartComponent'
import { selectDataChart, selectLoading } from './store/slice'
import { getTopTradePartner } from './store/thunk'

const PADDING_BOTTOM_X_AXIS_KEY = 20

export const PartnerChart = ({
  width,
  height,
  currentMonth,
  currentYear,
  locale,
}) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)
  const isYearly = useSelector(selectIsYearly)
  const isAcc = useSelector(selectIsAcc)

  useEffect(() => {
    if (currentMonth && currentYear) {
      dispatch(
        getTopTradePartner({
          DataType: isYearly ? 'Yearly' : isAcc ? 'AccMonthly' : 'Monthly',
          Year: currentYear,
          Month: isYearly ? 12 : currentMonth,
        }),
      )
    }
  }, [currentMonth, currentYear, locale, isYearly, isAcc])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <div className="h-100">
            <div className={`${style.titleHeader}`}>
              {I18n.t(
                'economy.importExport.importExport.overview.PARTNERS_TOP_10',
              ).toUpperCase()}
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              size.height &&
              (!dataChart.length ? (
                <div
                  style={{
                    height: height - size.height - PADDING_BOTTOM_X_AXIS_KEY,
                  }}
                >
                  <NoData />
                </div>
              ) : (
                <div
                  style={{
                    height: height - size.height - PADDING_BOTTOM_X_AXIS_KEY,
                  }}
                >
                  <ChartComponent
                    data={dataChart}
                    width={width}
                    height={height - size.height - PADDING_BOTTOM_X_AXIS_KEY}
                    keyXAxis={'name'}
                    barKeys={['export', 'import']}
                    isYearly={isYearly}
                    isAcc={isAcc}
                    currentMonth={currentMonth}
                    currentYear={currentYear}
                  />
                </div>
              ))
            )}
            <Footer
              key={width}
              list={[
                {
                  text: I18n.t(
                    'economy.importExport.importExport.overview.FOOTER_EXPORT',
                  ),
                  type: TYPE.LINE,
                  before: {
                    bgColor: '#185999',
                  },
                },
                {
                  text: I18n.t(
                    'economy.importExport.importExport.overview.FOOTER_IMPORT',
                  ),
                  type: TYPE.LINE,
                  before: {
                    bgColor: '#facc5c',
                  },
                },
              ]}
            />
          </div>
        )}
      </SizeTracker>
    )
  )
}
