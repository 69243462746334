import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import InputFormatCurrency from '../../../common/input/InputFormatCurrencyCopy'
import { formatVal } from '../../../utils/Value'
import style from './index.module.css'
import { selectData, selectQty, updateQty } from './store/slice'

const Filter = () => {
  const dispatch = useDispatch()
  const qty = useSelector(selectQty)
  const data = useSelector(selectData)
  const onChange = (value) => {
    if (parseFloat(value) > 1000000) {
      dispatch(updateQty(1000000))
    } else {
      dispatch(updateQty(value ?? 0))
    }
  }

  const totalCpupon = useMemo(() => {
    return data?.[0]?.accruedInterest || 0
  }, [data])

  return (
    <>
      <div className={`${style.wrapFilter}`}>
        <div>
          <Span
            style={{
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            <Translate value="bond.valuation.history.filter.word" />
          </Span>
          <Span
            className="ml-8"
            style={{
              fontWeight: 340,
              fontSize: 12,
            }}
          >
            {formatVal(totalCpupon, 0)}
          </Span>
        </div>
        <div className="d-flex ali-center">
          <Span
            style={{
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            <Translate value="bond.valuation.history.filter.mass" />
          </Span>
          <InputFormatCurrency
            className={`${style.inputFormatCurrency}`}
            handleBlur={(e) => {
              onChange(e)
            }}
            defaultValue={qty}
            fractionDigits={0}
            decimalRender={0}
            isNumberFloat
          />
        </div>
      </div>
    </>
  )
}

export default Filter
