import { useSelector } from 'react-redux'
import { selectActionType } from '../store/slice'

const ActionName = ({ actionId }) => {
  const actionType = useSelector(selectActionType)
  return (
    <>
      {
        actionType.find((item) => item.actionTypeId === actionId)
          ?.actionTypeName
      }
    </>
  )
}

export default ActionName
