import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { TopInfo } from '../../../common/topInfo/indexTopInfo'
import Content from './Content'
import style from './index.module.css'
import { resetStore } from './store/slice'
import { getAllIndexThunk } from './store/thunk'

export const Indices = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllIndexThunk())
    return () => dispatch(resetStore())
  }, [])

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className={`d-flex ali-center ${style.ml8px}`}>
              <TopInfo title="market.marketInDepth.indices.NAME_SCREEN_UPPERCASE" />
            </div>
            {size.height && (
              <div
                className={style.relative}
                style={{ height: `calc(100% - ${size.height}px)` }}
              >
                <Content />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
