import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { getTwoColorByValue } from '../../../../../utils/Color'
import { formatVal } from '../../../../../utils/Value'
import { selecDataL2 } from '../store/slice'
import { selectIcbSelect } from './store/slice'

const SETTINGS = {
  yTickNum: 6,
  xTickNum: 5,
  fontSize: 11,
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  displayData,
  name1,
  name2,
  name3,
  name4,
  listFooter,
}) => {
  const valueL2 = useSelector(selectIcbSelect)
  const dataL2 = useSelector(selecDataL2)
  const text = dataL2.find((e) => e?.icbId === valueL2)

  const renderTooltip = (tooltipData) => {
    const tooltipContentData = {
      [name1]: {
        label: I18n.t('bond.overview.outStandingBons.chart.outstandingBond'),
        format: (val) => formatVal(val),
        unit: 'bond.overview.outStandingBons.chart.unitMoney',
      },
      [name2]: {
        label: I18n.t('bond.overview.outStandingBons.chart.issueValue'),
        format: (val) => formatVal(val),
        unit: 'bond.overview.outStandingBons.chart.unitMoney',
      },
      [name3]: {
        label: I18n.t('bond.overview.outStandingBons.chart.redemptionValue'),
        format: (val) => formatVal(val),
        unit: 'bond.overview.outStandingBons.chart.unitMoney',
      },
      [name4]: {
        label: I18n.t('bond.overview.outStandingBons.chart.valueOfCancelBond'),
        format: (val) => formatVal(val),
        unit: 'bond.overview.outStandingBons.chart.unitMoney',
      },
    }
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>
            {tooltipData.date}{' '}
            {text?.icbName
              ? text?.icbName
              : I18n.t('bond.overview.primaryMarket.ALL_SECTOR')}
          </Span>
        </div>
        {displayData.map((key, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {I18n.t(tooltipContentData[key].label)}
              </Span>
            </div>
            <div className="ml-8">
              <Span
                style={{
                  fontSize: 11,
                  fontWeight: 500,
                  color: getTwoColorByValue(tooltipData[key] || 0),
                }}
              >
                {tooltipContentData[key].format(tooltipData[key])}
              </Span>
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {' ' + I18n.t(tooltipContentData[key].unit)}
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  const getColor = (key) => {
    const item = listFooter.find((i) => i?.dataKey === key)
    return item.before.bgColor
  }

  const yAxisLeft = [
    {
      id: 'left',
      keys: displayData,
      orientation: 'left',
      tickNum: SETTINGS.yTickNum,
      label: I18n.t('bond.overview.outStandingBons.chart.labelLeft'),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
    },
  ]

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height || 0}
      keyXAxis={keyXAxis}
      xTickNum={SETTINGS.xTickNum}
      // timeFrame="1M"
      timeFrame="CUSTOM"
      tickFormatter={(d) => d}
      margin={{
        top: 20,
        bottom: 0,
        left: 0,
        right: 40,
      }}
      hasBarChart={true}
      yAxis={yAxisLeft}
      renderCustomTooltip={(dataFormat) => {
        return renderTooltip(dataFormat)
      }}
    >
      {({ chartContentWidth }) => (
        <>
          {displayData.includes(name2) && (
            <Bar
              isAnimationActive={false}
              dataKey={name2}
              yAxisId="left"
              fill={getColor(name2)}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}
          {displayData.includes(name3) && (
            <Bar
              isAnimationActive={false}
              dataKey={name3}
              yAxisId="left"
              fill={getColor(name3)}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}
          {displayData.includes(name4) && (
            <Bar
              isAnimationActive={false}
              dataKey={name4}
              yAxisId="left"
              fill={getColor(name4)}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}
          {displayData.includes(name1) && (
            <Bar
              isAnimationActive={false}
              dataKey={name1}
              yAxisId="left"
              fill={getColor(name1)}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}
        </>
      )}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}
