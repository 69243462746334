import { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderCalendarWithoutHook from '../../../../common/calendar/headers/HeaderCalendarWithoutHook'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Icon } from '../../../../common/html/Icon'
import { Z_INDEX } from '../../../../constants/Common'
import {
  getDateWithTimezone,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import { getTimeFrequencyByIndicator } from '../../../common/helpers'
import { TIME_FREQUENCY } from '../../constants'
import style from '../style.module.css'

export const IndicatorSelectDate = ({
  dataKey,
  data,
  indicatorsLatestPeriod,
  onChangeIndicator,
}) => {
  const timezone = UseTimeZone()
  const ref = useRef()

  const lang = useSelector((state) => state.i18n.locale)

  const currentDate = [new Date()]
  const currentYear = new Date().getFullYear()

  const [isOpen, setIsOpen] = useState(false)

  const getDate = useMemo(() => {
    let date =
      indicatorsLatestPeriod[
        `${data.indicatorId}_${getTimeFrequencyByIndicator(data)}`
      ]?.date

    if (data.data[dataKey]?.date) {
      date = data.data[dataKey]?.date
    }

    return date
  }, [data, dataKey, indicatorsLatestPeriod])

  useEffect(() => {
    const interval = setInterval(() => {
      const el = document.getElementById(
        `calendarContainer_${data.indicatorTypeId}_${dataKey}`,
      )
      if (el) {
        if (isOpen) {
          const { top, left } = ref.current.getBoundingClientRect()
          el.style.position = 'absolute'
          el.style.top = top + 'px'
          el.style.left = left + 'px'
          el.style.zIndex = Z_INDEX.DATE_PICKER
        }
        clearInterval(interval)
      }
    }, 1)
    return () => clearInterval(interval)
  }, [isOpen])

  return (
    <div ref={ref} className={style.equationListItemTimeDate}>
      <div className="form-control calendar mb-0">
        <CalendarPopper
          date={getDate ? getDateWithTimezone(getDate, timezone) : ''}
          handleChange={(val) =>
            onChangeIndicator(
              getISOStartOrEndOfDay(val, timezone, true),
              dataKey,
              data,
              TIME_FREQUENCY.DAILY,
            )
          }
          CustomInput={InputDateCalendar}
          hightlightDates={currentDate}
          customHeader={HeaderCalendarWithoutHook({
            startYear: currentYear - 10,
            endYear: currentYear,
            zIndex: 11,
            lang,
          })}
          customInputStyle={{
            color: '#4E4E4E',
            width: '100%',
            backgroundColor: '#F2F2F2',
          }}
          popperContainer={(props) => (
            <CalendarContainer
              id={data.indicatorTypeId}
              dataKey={dataKey}
              {...props}
            />
          )}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          calendarClassName={'calender-white-theme'}
        />
        <Icon
          className="icon-date"
          style={{ fontSize: 12, pointerEvent: 'none' }}
        />
      </div>
    </div>
  )
}

const CalendarContainer = ({ children, id, dataKey }) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    let timeout = null
    if (visible) {
      timeout = setTimeout(() => {
        setVisible(false)
        clearTimeout(timeout)
      }, 0)
    }
    return () => clearTimeout(timeout)
  }, [visible])

  return createPortal(
    <div
      id={`calendarContainer_${id}_${dataKey}`}
      style={{ visibility: !visible ? 'visible' : 'hidden' }}
    >
      {children}
    </div>,
    document.body,
  )
}
