import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import {
  selectBasicInfo,
  selectPriceData,
  selectPriceDataLoading,
} from '../../../../common/topInfo/stockTopInfo/store/slice'
import { formatVal } from '../../../../utils/Value'
import { ChartContent } from './ChartContent'
import { ChartFooter } from './ChartFooter'
import { ChartHeader } from './ChartHeader'
import { MULTIPLIER_FORMAT_DATA } from './constants'

export const PriceDepth = ({ height }) => {
  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const data = useSelector(selectPriceData)
  const isLoading = useSelector(selectPriceDataLoading)

  // Get data
  const getNumberBar = () => {
    if (basicInfo.exchange.toUpperCase() === 'HOSE') {
      return 3
    } else {
      return 10
    }
  }

  const getTotalBuyVolume = () => {
    const bestBidVol = [...Array(getNumberBar())].map(
      (_, index) => `best${index + 1}BidVolume`,
    )
    return (
      bestBidVol
        .map((key) => (data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0))
        .reduce((prev, curr) => prev + curr, 0) /
      MULTIPLIER_FORMAT_DATA.totalBuyVolume
    )
  }

  const getTotalSellVolume = () => {
    const bestAskVol = [...Array(getNumberBar())].map(
      (_, index) => `best${index + 1}OfferVolume`,
    )
    return (
      bestAskVol
        .map((key) => (data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0))
        .reduce((prev, curr) => prev + curr, 0) /
      MULTIPLIER_FORMAT_DATA.totalSellVolume
    )
  }

  const getMaxBuyVolume = () => {
    const bestBidVol = [...Array(getNumberBar())].map(
      (_, index) => `best${index + 1}BidVolume`,
    )
    return (
      Math.max(
        ...bestBidVol.map((key) =>
          data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0,
        ),
      ) / MULTIPLIER_FORMAT_DATA.totalBuyVolume
    )
  }

  const getMaxSellVolume = () => {
    const bestAskVol = [...Array(getNumberBar())].map(
      (_, index) => `best${index + 1}OfferVolume`,
    )
    return (
      Math.max(
        ...bestAskVol.map((key) =>
          data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0,
        ),
      ) / MULTIPLIER_FORMAT_DATA.totalSellVolume
    )
  }

  const getMaxBuySellVolume = useMemo(() => {
    return Math.max(getMaxBuyVolume(), getMaxSellVolume())
  }, [data])

  return (
    <div className="h-100" id="priceDepth">
      <SizeTracker>
        {(size) => (
          <>
            <ChartHeader />
            {size.height && (
              <div
                style={{
                  height: height - size.height || 0,
                }}
              >
                {isLoading ? (
                  <Loading />
                ) : Object.keys(data).length > 0 ? (
                  <ChartContent
                    totalBuyVolume={getMaxBuySellVolume}
                    totalSellVolume={getMaxBuySellVolume}
                    data={data}
                    format={MULTIPLIER_FORMAT_DATA}
                    numberBar={getNumberBar()}
                  />
                ) : (
                  <NoData />
                )}
              </div>
            )}
            <ChartFooter
              totalBuyVolume={formatVal(getTotalBuyVolume())}
              totalSellVolume={formatVal(getTotalSellVolume())}
              totalMatchVolume={
                data.priceInfo
                  ? formatVal(
                      data.priceInfo.totalMatchVolume /
                        MULTIPLIER_FORMAT_DATA.totalMatchVolume,
                    )
                  : '0.00'
              }
            />
          </>
        )}
      </SizeTracker>
    </div>
  )
}

PriceDepth.propTypes = {
  height: PropTypes.number.isRequired,
}
