import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { key, selectChecked, selectWatchList } from '../../store/slice'
import DropdownBox from './DropdownBox'
import NameChecked from './NameChecked'
import style from './index.module.css'

const MyWatchList = () => {
  const selectRef = useRef()
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const watchlistState = useSelector(selectWatchList)
  const handleHide = () => setIsShowDropdown(false)
  const handleToggle = () => setIsShowDropdown((isShow) => !isShow)
  const listChecked = useSelector(selectChecked(key.WATCHLIST))
  const listAllChecked = watchlistState.map((el) => el.watchListId)

  return (
    <>
      <Translate
        value="market.event.WATCHLIST"
        style={{ fontSize: 11, opacity: 0.4 }}
        className="mb-8 d-block"
      />
      <div className="input-dropdown mb-8">
        <span
          ref={selectRef}
          onClick={handleToggle}
          className={`cursor-pointer h-20 ${style['span-input']}`}
          style={{
            borderRadius: isShowDropdown ? '4px 4px 0px 0px' : undefined,
          }}
        >
          <NameChecked
            watchlist={watchlistState}
            listAllChecked={listAllChecked}
            listChecked={listChecked}
          />{' '}
          <i
            className={`${
              !isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
            } ${style.icon}`}
          />
        </span>
      </div>
      <DropdownBox
        isShow={isShowDropdown}
        parentRef={selectRef}
        handleHide={handleHide}
        exceptOusideRef={selectRef}
      />
    </>
  )
}

export default MyWatchList
