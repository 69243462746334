import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectICBs,
  selectStocks,
} from '../../../common/masterData/store/slice'
import { VIEW_BY } from './constant'
import {
  changeCheckedIndicatorsByLanguage,
  changeTickersByLanguage,
  selectIndicators,
  selectListCheckedIndicatorsById,
  selectListTickersById,
  selectViewBy,
} from './store/slice'

export const UpdateDataByLanguage = () => {
  const dispatch = useDispatch()

  // Use selector
  const viewBy = useSelector(selectViewBy)
  const tickersById = useSelector(selectListTickersById(viewBy))
  const checkedIndicatorsById = useSelector(
    selectListCheckedIndicatorsById(viewBy),
  )
  const indicators = useSelector(selectIndicators)
  const stocks = useSelector(selectStocks)
  const icbs = useSelector(selectICBs)

  // Use effect
  useEffect(() => {
    const checkedIndicators = Object.values(checkedIndicatorsById).map(
      (item) => {
        const indicator = indicators.find(
          (indicator) => indicator.indicatorId === item.indicatorId,
        )
        return {
          ...item,
          indicatorName: indicator
            ? indicator.indicatorName
            : item.indicatorName,
        }
      },
    )

    dispatch(changeCheckedIndicatorsByLanguage(checkedIndicators))
  }, [indicators, viewBy])

  useEffect(() => {
    if (viewBy === VIEW_BY.CORPORATE) {
      const tickers = Object.values(tickersById).map((item) => {
        const ticker = stocks.find(
          (stock) => stock.organizationId === item.organizationId,
        )
        return {
          ...item,
          organizationShortName: ticker
            ? ticker.organizationShortName
            : item.organizationShortName,
        }
      })

      dispatch(changeTickersByLanguage(tickers))
    }
  }, [stocks, viewBy])

  useEffect(() => {
    if (viewBy === VIEW_BY.INDEX_AND_SECTOR) {
      const tickers = Object.values(tickersById).map((item) => {
        if (item.icbId) {
          const ticker = icbs.find((icb) => icb.icbId === item.icbId)
          return {
            ...item,
            icbName: ticker
              ? `${ticker.icbName} L${ticker.icbLevel}`
              : item.icbName,
            indexSectorName: ticker
              ? `${ticker.icbName} L${ticker.icbLevel}`
              : item.indexSectorName,
          }
        } else {
          return item
        }
      })

      dispatch(changeTickersByLanguage(tickers))
    }
  }, [icbs, viewBy])

  return <></>
}
