import { Z_INDEX } from '../constants/Common'

export const indices = {
  index: {
    CHECKBOX: 'checkbox',
    NAME: 'watchListName',
    DESCRIPTION: 'description',
    DATE: 'updateDate',
    FAVORITE: 'favourite',
    MORE: 'more',
  },
  tickers: {
    ID: 'organizationOrder',
    CODE: 'tickerOrTaxCode',
    COMPANY_NAME: 'organizationShortName',
    CHECKBOX: 'checkbox',
  },
}

export const tableKeys = {
  CHECKBOX: 'CHECKBOX',
  MORE: 'MORE',
  NAME: 'NAME',
  DESCRIPTION: 'DESCRIPTION',
  DATE: 'DATE',
  FAVORITE: 'FAVORITE',
}

export const tickerTableKeys = {
  CHECKBOX: 'CHECKBOX',
  CODE: 'CODE',
  ID: 'ID',
}

export const maxWatchlist = 100

export const maxTicker = 10000

export const zIndexOverlayPopup = {
  watchlist: Z_INDEX.MODAL + 1,
  alertEvent: Z_INDEX.MODAL + 2,
}
