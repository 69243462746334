import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { PanelNetValue } from './netValue/PanelNetValue'
import { PanelSummary } from './summary/PanelSummary'
import { PanelValueInvestor } from './valueInvestor/PanelValueInvestor'

const MAP_KEY = {
  SUMMARY: 'SUMMARY',
  VALUE_INVESTOR: 'VALUE_INVESTOR',
  NET_VALUE: 'NET_VALUE',
}

const minWidthResponsive = COMPONENT.MIN_SIZE.WIDTH * 5 + COMPONENT.MARGIN * 2

const MAP_SIZE = {
  [`${MAP_KEY.SUMMARY}`]: {
    width: `calc(100% / 3 * 2 - ${COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 3 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.VALUE_INVESTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.NET_VALUE,
        ratioLeft: 1,
        ratioWidth: 1,
      },
    ],
    horizontalResize: [],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.VALUE_INVESTOR}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(100% / 3 * 2 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 1.5 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    horizontalResize: [
      {
        key: MAP_KEY.NET_VALUE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.NET_VALUE}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(100% / 3 * 2 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 1.5 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const MAP_SIZE_SCREEN_SMALL = {
  [`${MAP_KEY.SUMMARY}`]: {
    width: '100%',
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 3,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.VALUE_INVESTOR}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 1.5 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.NET_VALUE,
        ratioLeft: 1,
        ratioWidth: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.NET_VALUE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.SUMMARY,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.NET_VALUE}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(50% + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 1.5 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.5 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelSummary
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelValueInvestor
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        isResizeResponsive={window.innerWidth <= minWidthResponsive}
      />

      <PanelNetValue
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(
  MAP_SIZE,
  panelRefs,
  MAP_SIZE_SCREEN_SMALL,
  minWidthResponsive,
)(Content)
