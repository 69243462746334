import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import ChartType from './ChartType'
import Charts from './Charts'

const PanelValuation = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
  width,
  height,
}) => {
  const basicInfo = useSelector(selectBasicInfo)

  const nameScreen = UseI18n(
    'constants.route.corporate.peerValuation.overview.LABEL',
  )
  const chartName = UseI18n('corporate.peerValuation.overview.VALUATION')
  const tickerName = basicInfo.ticker

  return (
    <Panel
      title="corporate.peerValuation.overview.VALUATION"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'peer-valuation-chart',
        nameScreen,
        chartName,
        tickerName,
      }}
    >
      <div id="peer-valuation-chart" className="h-100">
        <SizeTracker>
          {(size) => {
            return (
              <>
                <ChartType width={width || 0} />
                {size.height && (
                  <Charts
                    width={width || 0}
                    height={height - size.height || 0}
                  />
                )}
              </>
            )
          }}
        </SizeTracker>
      </div>
    </Panel>
  )
}

export default PanelValuation
