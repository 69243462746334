import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Span } from '../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { selectItemData } from '../store/slice'
import styles from '../style.module.css'

const FirstColumnCell = ({
  rowId,
  style,
  className,
  locale,
  onRowCollapse,
}) => {
  const tdRef = useRef()
  const [isCollapse, setIsCollapse] = useState(false)

  const item = useSelector(selectItemData(rowId))
  const timeZone = UseTimeZone()

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  if (item.isCollapse) {
    return (
      <td
        ref={tdRef}
        className={`${styles.tdCollapse} ${className}`}
        style={style}
      >
        <div className={styles.itemCollapse} onClick={onCollapse}>
          <div className="d-flex align-center" style={{ whiteSpace: 'nowrap' }}>
            <Span style={{ marginLeft: 8 }}>
              <Translate
                value={`economy.event.event.MONTH_${item.month + 1}`}
              />
              -{item.year}
            </Span>
            {isCollapse ? (
              <i className={`icon-arrow-right ${styles.iconArrow}`} />
            ) : (
              <i className={`icon-arrow-down ${styles.iconArrow}`} />
            )}
          </div>
        </div>
        <div className={styles.blueColor} />
      </td>
    )
  } else {
    const date = formatDateTime(item.publicDate, FORMAT.DATE, locale, timeZone)
    return (
      <td ref={tdRef} className={className} style={style}>
        <div className={styles.textOpacity}>{date}</div>
      </td>
    )
  }
}

FirstColumnCell.propTypes = {
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  locale: PropTypes.string,
  onRowCollapse: PropTypes.func.isRequired,
}

export default FirstColumnCell
