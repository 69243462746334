import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { VALUE_release_methot } from '../../constants'
import { valueTimefrequency } from '../constants'
import { getDataValueOfBonds } from './thunk'

const initialState = {
  activeTabCheckBox: VALUE_release_methot.ALL,
  isLoading: false,
  dataChart: [],
  activeTab: valueTimefrequency.ISSUEMETHOD,
  icbSelect: valueTimefrequency.ALL,
}

const slice = createSlice({
  name: 'bond/overview/outStandingBons',
  initialState,
  reducers: {
    updateTab: (state, actions) => {
      state.activeTab = actions.payload
    },
    updateIcb: (state, actions) => {
      state.icbSelect = actions.payload
    },
    updateMethod(state, actions) {
      state.activeTabCheckBox = actions.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataValueOfBonds.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDataValueOfBonds.fulfilled, (state, action) => {
      state.dataChart = action.payload || []
      state.isLoading = false
    })
    builder.addCase(getDataValueOfBonds.rejected, (state, action) => {
      state.dataChart = []
      state.isLoading = action.payload
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].dataChart
export const selectActiveTab = (state) => state[slice.name].activeTab
export const selectIcbSelect = (state) => state[slice.name].icbSelect
export const selectMethodType = (state) => state[slice.name].activeTabCheckBox

export const { updateTab, updateIcb, updateMethod } = slice.actions

register(slice.name, slice.reducer)
