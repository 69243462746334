import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Table } from '../../../common/table'
import { TooltipInfo2 } from '../../../common/tooltipInfo/TooltipIconInfo2'
import { formatVal } from '../../../utils/Value'

import TableHeader from './TableHeader'
import { STATUE_COLOR, columns } from './constants'
import styles from './index.module.css'
import {
  selectData,
  selectDataValue,
  selectIds,
  selectLoading,
  selectQty,
  sortData,
} from './store/slice'

const getColorStatus = (status) => {
  if (status === 2) return STATUE_COLOR.paid
  if (status === 3) return STATUE_COLOR.unpaid
  if (status === 4) return STATUE_COLOR.postpone
  if (status === 5) return STATUE_COLOR.cancel
  if (status === 6) return STATUE_COLOR.payment
  if (status === 7) return STATUE_COLOR.paid_unconfirmed
  if (status === 8) return STATUE_COLOR.late_payment
}

const Content = () => {
  const isLoading = useSelector(selectLoading)
  const qty = useSelector(selectQty)

  const data = useSelector(selectData)
  const ids = useSelector(selectIds)
  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }
  if (isLoading) {
    return <Loading />
  }

  if (ids.length === 0) {
    return <NoData />
  }
  return (
    <>
      <Table
        ids={ids}
        columnDraggable={false}
        sort={sortData}
        getDataFromRedux={selectDataValue}
        isLoading={isLoading}
        rowDraggable={false}
        renderHeader={(stateSort, sortColumn) =>
          renderThead(columns, stateSort, sortColumn)
        }
        schema={columns.map((item, index) => {
          const title = I18n.t(item.title)
          const colId = item.key

          let result = {
            colId,
            title,
            canCustomTd: true,
            render: (val, rowId, props) => {
              const render = () => {
                if (rowId !== 'Total') {
                  return (
                    <td
                      {...props}
                      style={{
                        ...item?.attrs?.style,
                      }}
                    >
                      <Span style={{ fontSize: 12 }}>{val}</Span>
                    </td>
                  )
                } else {
                  return null
                }
              }
              return render()
            },
          }

          if (colId === columns[3].key) {
            result = {
              colId,
              title,
              canCustomTd: true,
              render: (val, rowId, props) => {
                const currnetRow = data[rowId]
                return (
                  <td
                    {...props}
                    style={{
                      ...props.style,
                      ...item?.attrs?.style,
                      color: getColorStatus(currnetRow?.paymentStatusId),
                      flexDirection:
                        currnetRow?.paymentStatusId === 7 && 'row-reverse',
                    }}
                  >
                    <div
                      className={
                        currnetRow?.paymentStatusId === 7
                          ? `d-flex ali-center justify-content-end ${styles.textSatus7}`
                          : ''
                      }
                    >
                      {currnetRow?.paymentStatusId === 7 && (
                        <TooltipInfo2
                          tooltip="bond.valuation.history.tooltipStatus"
                          fontSize={16}
                        />
                      )}
                      <div className="ml-8">{val}</div>
                    </div>
                  </td>
                )
              },
            }
          }
          if (colId === columns[2].key) {
            result = {
              colId,
              title,
              canCustomTd: true,
              render: (val, _, props) => {
                return (
                  <td
                    {...props}
                    style={{
                      ...props.style,
                      ...item?.attrs?.style,
                    }}
                  >
                    {formatVal(val * qty, 0)}
                  </td>
                )
              },
            }
          }
          if (colId === columns[1].key) {
            result = {
              colId,
              title,
              canCustomTd: true,
              render: (val, _, props) => {
                return (
                  <td
                    {...props}
                    style={{
                      ...props.style,
                      ...item?.attrs?.style,
                    }}
                  >
                    <Span style={{ fontSize: 12 }}>
                      {val !== '--' && formatVal(val * 100)}
                    </Span>
                  </td>
                )
              },
            }
          }
          if (index === 0) {
            result = {
              colId,
              title,
              canCustomTd: true,
              render: (val, rowId, props) => {
                return (
                  <td
                    {...props}
                    style={{
                      ...props.style,
                      ...item?.attrs?.style,
                    }}
                  >
                    <Span>{val}</Span>
                  </td>
                )
              },
            }
          }

          return result
        })}
        stickyFirstColumn={false}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        defaultScrollToRight
      />
    </>
  )
}

export default Content
