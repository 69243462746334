export const TYPE_CHART_SOCIAL_INVESTMENT = {
  TOTAL_SOCIAL: 'Total Social',
  STATE_SECTOR: 'State Sector',
  BUDGET_PROVINCES: 'Budget Provinces',
}
export const LEFT_FRAME_OPTIONS = [
  {
    name: 'economy.fiscal.stateBudget.TOTAL_SOCIAL',
    value: TYPE_CHART_SOCIAL_INVESTMENT.TOTAL_SOCIAL,
  },
  {
    name: 'economy.fiscal.stateBudget.STATE_SECTOR',
    value: TYPE_CHART_SOCIAL_INVESTMENT.STATE_SECTOR,
  },
  {
    name: 'economy.fiscal.stateBudget.BUDGET_PROVINCES',
    value: TYPE_CHART_SOCIAL_INVESTMENT.BUDGET_PROVINCES,
  },
]

export const RIGHT_FRAME_OPTIONS = [
  { title: '1Y', value: 'OneYear' },
  { title: '2Y', value: 'TwoYears' },
  { title: '3Y', value: 'ThreeYears' },
  { title: '5Y', value: 'FiveYears' },
]

export const RIGHT_FRAME_OPTIONS_YEARLY = [
  { title: '5Y', value: 'FiveYears' },
  { title: '7Y', value: 'SevenYears' },
  { title: '10Y', value: 'TenYears' },
  { title: '20Y', value: 'TwentyYears' },
]

export const QUARTER_SELECTION_MONTHLY = [
  { title: 'economy.fiscal.stateBudget.YEARLY', value: 0 },
  { title: 'Q1', value: 1 },
  { title: 'Q2', value: 2 },
  { title: 'Q3', value: 3 },
  { title: 'Q4', value: 4 },
]

export const QUARTER_SELECTION_QUARTERLY = [
  { title: 'Q1', value: 1 },
  { title: '2Q', value: 2 },
  { title: '3Q', value: 3 },
  { title: '4Q', value: 4 },
]

export const RADIO_VALUE = {
  VALUE: 'Value',
  PERCENT: 'Percent',
}

export const RADIO_LIST = [
  {
    value: RADIO_VALUE.VALUE,
    label: 'economy.fiscal.stateBudget.VALUE',
  },
  {
    value: RADIO_VALUE.PERCENT,
    label: 'economy.fiscal.stateBudget.PERCENT_VALUE',
  },
]

const currentYear = new Date().getFullYear()
const MIN_YEAR_DEFAULT = 2000
export const YEAR_OPTIONS = [
  ...Array(currentYear - MIN_YEAR_DEFAULT + 1).keys(),
].map((item) => {
  return {
    name: `${currentYear - item}`,
    value: `${currentYear - item}`,
  }
})

export const STACK_COLORS = {
  1: ['#0096eb'],
  2: ['#0096eb', '#ffe3df'],
  3: ['#176491', '#3cabeb', '#fff6df'],
}

export const FILL_COLOR = ['#1f669a', '#3cabeb', '#f1ffe7']
