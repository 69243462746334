import moment from 'moment'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { v4 as uuidv4 } from 'uuid'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import {
  selectAddInformatics,
  selectFilterParams,
  setFilterParameters,
} from '../../store/slice'
import DateFilter from '../DateFilter'
import { FILTER_FIELD_CODES, RANGE_TYPES } from '../constants'

export const FilterTypeDate = ({ data, handleChangeInputRange }) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  const params = useSelector(selectFilterParams)
  const addInformatics = useSelector(selectAddInformatics)
  const locale = useSelector((state) => state.i18n.locale)

  const [isShow, setIsShow] = useState(null)

  // Get data
  const initialParams = params.conditions.find(
    (item) => item.indicatorId === data.indicatorId,
  )

  const getDateRange = useMemo(() => {
    const type = data?.tableFieldCode ?? null
    switch (type) {
      case FILTER_FIELD_CODES.MATURITY_DATE:
        return {
          startYear: 2000,
          endYear: new Date(
            new Date().setFullYear(new Date().getFullYear() + 30),
          ).getFullYear(),
        }
      case FILTER_FIELD_CODES.TRADING_DATE:
        return {
          startYear: new Date().getFullYear(),
          endYear: new Date(
            new Date().setFullYear(new Date().getFullYear() + 30),
          ).getFullYear(),
        }

      default:
        return {
          startYear: 2000,
          endYear: new Date().getFullYear(),
        }
    }
  }, [data?.tableFieldCode])

  const placeholderFrom = useMemo(
    () => I18n.t('bond.bondScreening.filter.DATE_FROM'),
    [locale],
  )

  const placeholderTo = useMemo(
    () => I18n.t('bond.bondScreening.filter.DATE_TO'),
    [locale],
  )

  // Actions
  const handleClickDropdown = (selectedId, values) => {
    setIsShow(null)

    const filter = params?.conditions?.find(
      (item) => item.indicatorId === selectedId,
    )

    if (!filter) {
      const information = addInformatics.find(
        (item) => item.indicatorId === selectedId,
      )

      if (!information) return

      const {
        indicatorId,
        tableCode,
        conditionTableFieldCode,
        tableFieldCode,
        selectionType,
        selectionTypeId,
        multiplier,
      } = information

      const newItem = {
        id: uuidv4(),
        order: params?.conditions?.length ?? 0,
        indicatorId,
        tableCode,
        conditionTableFieldCode,
        tableFieldCode,
        selectionType,
        selectionTypeId,
        conditionValues: values,
      }

      if (multiplier) {
        newItem.multiplier = multiplier
      }

      dispatch(
        setFilterParameters({
          key: 'conditions',
          value: [...params.conditions, newItem],
        }),
      )

      const index = addInformatics.findIndex(
        (item) => item.indicatorId === selectedId,
      )

      if (index === -1) return

      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: [
            ...params.addedInformations.slice(0, index),
            ...params.addedInformations.slice(index + 1),
          ],
        }),
      )

      return
    }

    const index = params?.conditions?.findIndex(
      (item) => item.indicatorId === selectedId,
    )

    if (index === -1) return

    const newConditions = [
      ...params.conditions.slice(0, index),
      {
        ...filter,
        conditionValues: values,
      },
      ...params.conditions.slice(index + 1),
    ]

    dispatch(setFilterParameters({ key: 'conditions', value: newConditions }))
  }

  return (
    <DateFilter
      key={data.indicatorId}
      child={data}
      initialParams={initialParams}
      handleChangeFromDate={(value) => {
        handleChangeInputRange(
          !!value?.length ? getISOStartOrEndOfDay(value, timeZone, true) : null,
          RANGE_TYPES.FROM,
          initialParams?.indicatorId,
          data.indicatorId,
        )
      }}
      handleChangeToDate={(value) => {
        handleChangeInputRange(
          !!value?.length ? getISOStartOrEndOfDay(value, timeZone, true) : null,
          RANGE_TYPES.TO,
          initialParams?.indicatorId,
          data.indicatorId,
        )
      }}
      handleClick={handleClickDropdown}
      startYear={getDateRange.startYear}
      endYear={getDateRange.endYear}
      placeholderFrom={placeholderFrom}
      placeholderTo={placeholderTo}
      isShow={isShow}
      setIsShow={setIsShow}
      excludeDateIntervals={
        data?.tableFieldCode === FILTER_FIELD_CODES.TRADING_DATE
          ? [
              {
                start: new Date('1999-12-31'),
                end: new Date(
                  moment().subtract(1, 'days').format('YYYY-MM-DD'),
                ),
              },
            ]
          : undefined
      }
    />
  )
}
