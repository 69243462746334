import { SelectedIndicators } from './SelectedIndicators'
import { SelectedOther } from './SelectedOther'
import style from './style.module.css'

const SelectedItems = () => {
  return (
    <div className="h-100">
      <div className={style.divCompanies}>
        <SelectedOther />
      </div>
      <div className={style.divIndicators}>
        <SelectedIndicators />
      </div>
    </div>
  )
}

export default SelectedItems
