import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OpenMarketService from '../../../../../../../core/services/economy/OpenMarketService'

export const getOpenMarketDisplay = createAsyncThunk(
  'economy/monetary/openMarket/panelOpenMarketOperations/chartTop/getOpenMarketDisplay',
  async (params, { rejectWithValue }) => {
    try {
      const res = await OpenMarketService.getOpenMarketDisplay(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
