import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import TopInfo from './Filter'
import Content from './content'
import style from './index.module.css'
import { resetStore } from './oderBook/store/slice'

const Index = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetStore())
    }
  }, [])

  return (
    <>
      <div style={{ paddingBottom: '4px' }}>
        <TopInfo
          height="30px"
          isHidePriceData
          style={style.classNameInputSearch}
        />
      </div>
      <div
        style={{
          position: 'relative',
          height: `calc(100% - 34px)`,
        }}
      >
        <Content />
      </div>
    </>
  )
}

export default Index
