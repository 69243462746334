import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import useComGroup from '../../../../common/hooks/useComGroup'
import { Button } from '../../../../common/html/Button'
import Popup from '../../../../common/popup'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { COM_GROUP } from '../../../../constants/Group'
import style from '../style.module.css'
import DropdownAuditStatus from './DropdownAuditStatus'
import DropdownCurrency from './DropdownCurrency'
import DropdownReportType from './DropdownReportType'
import SelectReportingPeriod from './SelectReportingPeriod'

const PopupSettingFinancialStatement = () => {
  const [isShow, setIsShow] = useState(false)

  const showPopup = () => {
    setIsShow(true)
  }

  return (
    <>
      <i
        className={`${style.iconSettingHeader} icon-setting-stroke`}
        onClick={showPopup}
      />
      <SettingFinancialStatement isShow={isShow} setIsShow={setIsShow} />
    </>
  )
}

export default PopupSettingFinancialStatement

const SettingFinancialStatement = ({ isShow, setIsShow }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const comGroup = useComGroup(basicInfo.organizationId)

  const handleCancel = () => {
    setIsShow(false)
  }

  const handleApply = () => {
    setIsShow(false)
  }

  const getReportForm = () => {
    const objComGroup = {
      [COM_GROUP.BANK]: 'corporate.companySnapshot.BANK',
      [COM_GROUP.SECURITIES]: 'corporate.companySnapshot.SECURITIES',
      [COM_GROUP.INSURANCE]: 'corporate.companySnapshot.INSURANCE',
      [COM_GROUP.CORPORATE]: 'corporate.companySnapshot.COMPANY',
    }
    return objComGroup[comGroup]
  }

  return (
    <>
      {isShow ? (
        <Popup>
          <div className="modal modal-medium" style={{ width: 639 }}>
            <div className="modal-title">
              <h3 className="text-up">
                <Translate value="corporate.companySnapshot.FINANCIAL_STATEMENTS_SETTING" />
              </h3>
              <a onClick={handleCancel} className="close-modal">
                <i className="icon-delete-stroke" />
              </a>
            </div>

            <div className="modal-content" style={{ paddingBottom: 16 }}>
              <div className="d-flex mb-20">
                <div className={style.divLeft}>
                  <Translate value="corporate.companySnapshot.REPORT_TYPE" />
                </div>
                <div className={`${style.divRight} `}>
                  <DropdownReportType />
                </div>
              </div>
              <div className="d-flex mb-20">
                <div className={style.divLeft}>
                  <Translate value="corporate.companySnapshot.REPORT_FORM" />
                </div>
                <div className={`${style.divRight} ${style.opacity40}`}>
                  <Translate value={getReportForm()} />
                </div>
              </div>
              <div className="d-flex mb-20">
                <div className={style.divLeft}>
                  <Translate value="corporate.companySnapshot.AUDIT_STATUS" />
                </div>
                <div className={style.divRight}>
                  <DropdownAuditStatus />
                </div>
              </div>
              <div className="d-flex mb-20">
                <div className={style.divLeft}>
                  <Translate value="corporate.companySnapshot.CURRENCY" />
                </div>
                <div className={style.divRight}>
                  <DropdownCurrency />
                </div>
              </div>
              <div className="d-flex mb-20">
                <div className={style.divLeft}>
                  <Translate value="corporate.companySnapshot.SELECT_REPORTING_PERIOD" />
                </div>
                <SelectReportingPeriod />
              </div>
              <div className="d-flex justify-content-end mt-40">
                <Button
                  style={{ fontSize: 11 }}
                  className={`btn w-80 h-20 ${style.btnCancel} ${style.mr16}`}
                  onClick={handleCancel}
                >
                  <Translate value="common.button.BUTTON_CANCEL" />
                </Button>
                <Button
                  style={{ fontSize: 11 }}
                  className={`btn btn-blue w-80 h-20`}
                  onClick={handleApply}
                >
                  <Translate value="tool.smartScreening.APPLY" />
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      ) : (
        <></>
      )}
    </>
  )
}
