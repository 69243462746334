import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { latePaymentType } from './latePayment/constants'
import Content from './latePayment/index'
import { selectActiveTab } from './latePayment/store/slice'
import styles from './index.module.css'

const LatePayment = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const activeTab = useSelector(selectActiveTab)

  const renderTickerName = () => {
    const item = latePaymentType.find((e) => e.value === activeTab)
    return item.label
  }
  return (
    <Panel
      title="bond.overview.latePayment.TITLE"
      windowClass={styles.container}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={false}
      isDownloadJpg
      downloadJpgParams={{
        domId: 'FDILatePayment',
        nameScreen: I18n.t('bond.overview.primaryMarket.TITLE'),
        chartName: I18n.t('bond.overview.latePayment.TITLE'),
        tickerName: I18n.t(renderTickerName()),
      }}
    >
      <Content
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default LatePayment
