import { Events } from '../../sector/sectorConstituents/events'
import { FinancialAnalysis } from '../../sector/sectorConstituents/financialAnalysis'
import { NewsReport } from '../../sector/sectorConstituents/newsReport'
import { NewsReportDetail } from '../../sector/sectorConstituents/newsReport/NewsReportDetail'
import { NewsReportMore } from '../../sector/sectorConstituents/newsReport/NewsReportMore'
import { Overview } from '../../sector/sectorConstituents/overview'
import { Ranking } from '../../sector/sectorConstituents/ranking'
import { Ratio } from '../../sector/sectorConstituents/ratio'
import { Segmentation } from '../../sector/sectorConstituents/segmentation'
import { Valuation } from '../../sector/sectorConstituents/valuation'
import { Fishery } from '../../sector/sectorSpecific/fishery'
import { Steel } from '../../sector/sectorSpecific/steel'
import { Overview as OverviewSummary } from '../../sector/sectorStatistics/overview'
import { Ratios } from '../../sector/sectorStatistics/ratios'

export const SECTOR_ROUTE = {
  name: 'constants.route.sector.LABEL',
  routeDefault: '/sector/sector-statistics/overview',
  components: {
    'sector-statistics': {
      name: 'constants.route.sector.sectorStatistics.LABEL',
      hasChildren: true,
      components: {
        overview: {
          route: '/sector/sector-statistics/overview',
          name: 'constants.route.sector.sectorStatistics.overview.LABEL',
          renderMain: () => <OverviewSummary />,
        },
        ratios: {
          route: '/sector/sector-statistics/ratios',
          name: 'constants.route.sector.sectorStatistics.ratios.LABEL',
          renderMain: () => <Ratios />,
        },
      },
    },
    'sector-constituents': {
      name: 'constants.route.sector.sectorConstituents.LABEL',
      hasChildren: true,
      components: {
        overview: {
          route: '/sector/sector-constituents/overview',
          name: 'constants.route.sector.sectorConstituents.overview.LABEL',
          renderMain: () => <Overview />,
        },
        'news-report': {
          route: '/sector/sector-constituents/news-report',
          relatedRoutes: [
            '/sector/sector-constituents/news-report-detail',
            '/sector/sector-constituents/news-report-more',
          ],
          name: 'constants.route.sector.sectorConstituents.newsReport.LABEL',
          renderMain: () => <NewsReport />,
        },
        'news-report-detail': {
          route: '/sector/sector-constituents/news-report-detail',
          name: 'constants.route.sector.sectorConstituents.newsReport.LABEL',
          renderMain: () => <NewsReportDetail />,
          hiddenOnSideBar: true,
        },
        'news-report-more': {
          route: '/sector/sector-constituents/news-report-more',
          name: 'constants.route.sector.sectorConstituents.newsReport.LABEL',
          renderMain: () => <NewsReportMore />,
          hiddenOnSideBar: true,
        },
        events: {
          route: '/sector/sector-constituents/events',
          name: 'constants.route.sector.sectorConstituents.events.LABEL',
          renderMain: () => <Events />,
        },
        'financial-analysis': {
          route: '/sector/sector-constituents/financial-analysis',
          name: 'constants.route.sector.sectorConstituents.financialAnalysis.LABEL',
          renderMain: () => <FinancialAnalysis />,
        },
        segmentation: {
          route: '/sector/sector-constituents/segmentation',
          name: 'constants.route.sector.sectorConstituents.segmentation.LABEL',
          renderMain: () => <Segmentation />,
        },
        ranking: {
          route: '/sector/sector-constituents/ranking',
          name: 'constants.route.sector.sectorConstituents.ranking.LABEL',
          renderMain: () => <Ranking />,
        },
        ratio: {
          route: '/sector/sector-constituents/ratio',
          name: 'constants.route.sector.sectorConstituents.ratio.LABEL',
          renderMain: () => <Ratio />,
        },
        valuation: {
          route: '/sector/sector-constituents/valuation',
          name: 'constants.route.sector.sectorConstituents.valuation.LABEL',
          renderMain: () => <Valuation />,
        },
      },
    },
    'sector-specific': {
      name: 'constants.route.sector.sectorSpecific.LABEL',
      hasChildren: true,
      components: {
        fishery: {
          route: '/sector/sector-specific/fishery',
          name: 'constants.route.sector.sectorSpecific.fishery.LABEL',
          renderMain: () => <Fishery />,
        },
        steel: {
          route: '/sector/sector-specific/steel',
          name: 'constants.route.sector.sectorSpecific.steel.LABEL',
          renderMain: () => <Steel />,
        },
      },
    },
  },
}
