import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import {
  selectIsAcc,
  selectIsYearly,
  selectMaxDate,
} from '../balanceOfTrade/store/slice'
import style from '../index.module.css'
import { CommodityChartContent } from './commodityChart/CommodityChartContent'
import { changeTypeChartVal } from './commodityChart/store/slice'
import {
  ACC_MONTH_FILTER_EN,
  ACC_MONTH_FILTER_VI,
  MONTH_LIST_FILTER_EN,
  MONTH_LIST_FILTER_VI,
  OUTSTANDING_TARGET_FILTER,
  YEAR_LIST_FILTER,
} from './constants'
import { PartnerChart } from './partnerChart/PartnerChart'

export const OutstandingTarget = ({ width, height }) => {
  const dispatch = useDispatch()

  const rightHeaderRef = useRef()

  const isYearly = useSelector(selectIsYearly)
  const isAcc = useSelector(selectIsAcc)
  const maxDate = useSelector(selectMaxDate)
  const locale = useSelector((state) => state.i18n.locale)

  const [currentChartType, setCurrentChartType] = useState(
    OUTSTANDING_TARGET_FILTER[0].value,
  )
  const [currentMonth, setCurrentMonth] = useState(null)
  const [currentYear, setCurrentYear] = useState(null)

  const currentSelectedMonth = currentMonth || maxDate?.realMonth
  const currentSelectedYear = currentYear || maxDate?.realYear

  const getMonthFilterByLocale = (locale) => {
    if (isAcc) {
      return locale === 'en' ? ACC_MONTH_FILTER_EN : ACC_MONTH_FILTER_VI
    } else {
      return locale === 'en' ? MONTH_LIST_FILTER_EN : MONTH_LIST_FILTER_VI
    }
  }

  const handleChangeTab = (item) => {
    setCurrentChartType(item.value)
    dispatch(changeTypeChartVal(item.value))
  }

  const handleMonthChange = (item) => {
    setCurrentMonth(item)
  }
  const handleYearChange = (item) => {
    setCurrentYear(item)
  }

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <div className="d-flex j-b">
              <DispatchActionTab
                data={OUTSTANDING_TARGET_FILTER}
                activeTab={currentChartType}
                onChangeTab={handleChangeTab}
                itemStyle={{
                  fontWeight: 500,
                  fontSize: 11,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                widthComponent={
                  width - rightHeaderRef.current?.getBoundingClientRect().width
                }
              />
              <div className="row" ref={rightHeaderRef}>
                <div
                  className={`${style.inputDropdown}`}
                  style={{
                    width: '84px',
                    marginRight: '4px',
                    display: isYearly ? 'none' : '',
                  }}
                >
                  <SelectCustom
                    value={currentSelectedMonth}
                    isI18n={false}
                    selectData={getMonthFilterByLocale(locale)}
                    handleChange={handleMonthChange}
                  />
                </div>
                <div
                  className={`${style.inputDropdown}`}
                  style={{ width: '84px' }}
                >
                  <SelectCustom
                    value={currentSelectedYear}
                    isI18n={false}
                    selectData={YEAR_LIST_FILTER}
                    handleChange={handleYearChange}
                  />
                </div>
              </div>
            </div>
            {size.height && (
              <div
                style={{ height: height - size.height }}
                id="outstandingTarget"
              >
                <>
                  {currentChartType === OUTSTANDING_TARGET_FILTER[0].value ? (
                    <CommodityChartContent
                      height={height}
                      width={width}
                      currentMonth={currentSelectedMonth}
                      currentYear={currentSelectedYear}
                      locale={locale}
                    />
                  ) : (
                    <PartnerChart
                      height={height}
                      width={width}
                      currentMonth={currentSelectedMonth}
                      currentYear={currentSelectedYear}
                      locale={locale}
                    />
                  )}
                </>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    )
  )
}
