import { languageObjs } from '../../../../../configs/Language'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'

export const handleIds = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload.map((item) => ({
    id: item.sbvRateTypeId,
    title: item.sbvRateVNTypeShortName,
    level: item.sbvRateVNTypeLevel,
  }))
}

export const defineGroupColumns = (payload, prevColumns, isFirstTime) => {
  if (!payload.length) {
    return []
  }

  const dateNotDuplicate = handleDuplicateByKey(payload, [
    'realMonth',
    'realYear',
  ])
  const groupColumns = dateNotDuplicate
    .map((item) => ({
      key: `${item.realMonth}-${item.realYear}`,
      year: item.realYear,
      month: item.realMonth,
    }))
    .sort(sortByMonthYear)

  if (isFirstTime) {
    return groupColumns
  }

  return [...groupColumns, ...prevColumns]
}

export const keyById = (payload, prevData, isFirstTime) => {
  const result = {}
  payload.forEach((item) => {
    const rowId = item.sbvRateTypeId
    const colId = `${item.realMonth}-${item.realYear}`
    if (!result[rowId]) {
      result[rowId] = { [colId]: item.value * 100 }
    } else {
      result[rowId][colId] = item.value * 100
    }
  })

  return mergePrevCurrentData(prevData, result, isFirstTime)
}

const mergePrevCurrentData = (prev, current, isFirstTime) => {
  if (isFirstTime) {
    return current
  }

  const prevKeys = Object.keys(prev)
  const curKeys = Object.keys(current)
  const keys = [...new Set([...prevKeys, ...curKeys])]
  if (!curKeys.length) {
    return prev
  }

  let prevCurrentData = {}
  keys.forEach((key) => {
    prevCurrentData = {
      ...prevCurrentData,
      [key]: { ...prev[key], ...current[key] },
    }
  })
  return prevCurrentData
}

const sortByMonthYear = (a, b) => {
  if (a.year > b.year) {
    return 1
  }

  if (a.year < b.year) {
    return -1
  }

  if (a.year === b.year) {
    if (a.month > b.month) {
      return 1
    }

    if (a.month < b.month) {
      return -1
    }

    return 0
  }
}

export const handleTitleGroupColumn = (month, locale) => {
  const isViLanguage = locale === languageObjs.vi
  if (month < 10) {
    return isViLanguage ? `Th0${month}` : `0${month}`
  }

  return isViLanguage ? `Th${month}` : `${month}`
}

export const findMinMonthYear = (payload) => {
  if (!payload.length) {
    return { minYear: 0, minMonth: 0 }
  }

  const minYear = Math.min.apply(
    Math,
    payload.map((item) => item.realYear),
  )
  const monthWithMinYear = payload
    .filter((item) => item.realYear === minYear)
    .map((item) => item.realMonth)

  return {
    minYear,
    minMonth: Math.min.apply(Math, monthWithMinYear),
  }
}

export const handleLimitDate = (payload, timeZone) => {
  if (!payload.length) {
    return {
      minDate: '',
      maxDate: '',
      minYear: '',
      maxYear: '',
      minMonth: '',
      maxMonth: '',
    }
  }

  const limit = {}
  payload.forEach(({ realDay, realMonth, realYear }, index) => {
    const limitDate = getISOStartOrEndOfDay(
      new Date(realYear, realMonth - 1, realDay),
      timeZone,
      true,
    )
    if (index === 0) {
      limit.minDate = limitDate
      limit.minYear = realYear
      limit.minMonth = realMonth
    } else {
      limit.maxDate = limitDate
      limit.maxYear = realYear
      limit.maxMonth = realMonth
    }
  })

  return limit
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}
