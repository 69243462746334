import { PropTypes } from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import { PanelOverviewStatistics } from './tableStatisticsForeign/PanelOverviewStatistics'
import { PanelValueBreakdown } from './valueBreakdownForeign/PanelValueBreakdown'
import { PanelValueInvestor } from './valueInvestorForeign/PanelValueInvestor'

const MAP_KEY = {
  HISTORICAL_STATISTICS: 'HISTORICAL STATISTICS',
  VALUE_INVESTOR: 'VALUE INVESTOR',
  VALUE_BREAKDOWN: 'VALUE BREAKDOWN',
}

const MIN_WIDTH = {
  [`${MAP_KEY.HISTORICAL_STATISTICS}`]: (350 * 7) / 3,
  [`${MAP_KEY.VALUE_INVESTOR}`]: 350,
  [`${MAP_KEY.VALUE_BREAKDOWN}`]: 350,
}
const MIN_HEIGHT = COMPONENT.MIN_SIZE.HEIGHT

const MAP_SIZE = {
  [`${MAP_KEY.HISTORICAL_STATISTICS}`]: {
    width: `calc(70% - ${COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: MIN_WIDTH[[`${MAP_KEY.HISTORICAL_STATISTICS}`]],
    minHeight: MIN_HEIGHT * 2,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.VALUE_INVESTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.VALUE_BREAKDOWN,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.VALUE_INVESTOR}`]: {
    width: `calc(30% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(70% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_WIDTH[[`${MAP_KEY.VALUE_INVESTOR}`]] - COMPONENT.MARGIN,
    minHeight: MIN_HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: MIN_WIDTH[[`${MAP_KEY.HISTORICAL_STATISTICS}`]] + COMPONENT.MARGIN,
    horizontalResize: [
      {
        key: MAP_KEY.VALUE_BREAKDOWN,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.VALUE_BREAKDOWN}`]: {
    width: `calc(30% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(70% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_WIDTH[[`${MAP_KEY.VALUE_BREAKDOWN}`]] - COMPONENT.MARGIN,
    minHeight: MIN_HEIGHT - COMPONENT.MARGIN,
    minTop: MIN_HEIGHT + COMPONENT.MARGIN,
    minLeft: MIN_WIDTH[[`${MAP_KEY.HISTORICAL_STATISTICS}`]] + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelOverviewStatistics
          panelRefs={panelRefs}
          panelKey={MAP_KEY.HISTORICAL_STATISTICS}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelValueInvestor
          panelRefs={panelRefs}
          panelKey={MAP_KEY.VALUE_INVESTOR}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelValueBreakdown
          panelRefs={panelRefs}
          panelKey={MAP_KEY.VALUE_BREAKDOWN}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
