import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { changeTabType } from '../store/slice'
import { TAB_COMPONENT_DATA, TAB_TYPE } from './constants'
import style from './index.module.css'

export const Summary = ({
  width,
  onTabChange,
  onFilterChange,
  onTableTypeChange,
}) => {
  const dispatch = useDispatch()

  // Use state
  const [tabType, setTabType] = useState(TAB_TYPE.OVERVIEW)

  // Actions
  const onChangeTab = (type) => () => {
    dispatch(changeTabType(type))
    setTabType(type)
    onTabChange(type)
  }

  const renderComponent = useMemo(() => {
    const Component = TAB_COMPONENT_DATA[tabType].Component
    return (
      <Component
        width={width}
        tabType={tabType}
        onFilterChange={onFilterChange}
        onTableTypeChange={onTableTypeChange}
      />
    )
  }, [tabType, width])

  return (
    <div
      className="d-flex h-100"
      style={{
        flexFlow: 'column',
      }}
    >
      <div className={style.tabLinkContainer}>
        {Object.keys(TAB_COMPONENT_DATA).map((tab) => (
          <a
            key={tab}
            className={[
              style.tabLink,
              tabType === tab ? style.tabLinkActive : '',
            ].join(' ')}
            onClick={onChangeTab(tab)}
          >
            <Span style={{ fontSize: 12 }}>
              <Translate value={TAB_COMPONENT_DATA[tab].title} />
            </Span>
            {tabType === tab && (
              <div className={style.tabLinkActiveBorderBottom}></div>
            )}
          </a>
        ))}
      </div>
      {renderComponent}
    </div>
  )
}

Summary.propTypes = {
  width: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onTableTypeChange: PropTypes.func.isRequired,
}
