import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import style from '../index.module.css'
import ChartContainer from './ChartContainer'
import Filter from './Filter'
import { resetStore } from './store/slice'

const CHART_DOM_ID = 'RevenueBreakdown'

const PanelRevenueBreakdown = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
  width,
  height,
}) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)

  const nameScreen = UseI18n(
    'constants.route.corporate.businessModel.penetrationAnalysis.LABEL',
  )
  const chartName = UseI18n(
    'corporate.businessModel.penetrationAnalysis.REVENUE_BREAKDOWN',
  )
  const tickerName = basicInfo.ticker

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <Panel
      title="PanelRevenueBreakdown"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowClass={style.windowClass}
      headerClass={style.panelHeader}
      renderCustomTitle={() => <Filter />}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: CHART_DOM_ID,
        nameScreen,
        chartName,
        tickerName,
      }}
    >
      <div className="h-100" id={CHART_DOM_ID}>
        <ChartContainer width={width} height={height} />
      </div>
    </Panel>
  )
}

export default PanelRevenueBreakdown
