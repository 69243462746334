import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { range } from '../../../utils/Common'
import { positionDropdown } from '../../dropdown'
import SelectMonthYear from '../SelectMonthYear'
import { useGetMonths } from '../helper'
import style from '../index.module.css'

const HeaderOneCalendarRange = ({
  startMonth,
  endMonth,
  startYear,
  endYear,
  zIndex,
  monthContentDropdownRef,
  yearContentDropdownRef,
  isOptionSelectedDefaultColor,
  position,
  marginTop,
  maxHeightDropdown,
  themeWhite,
  headerRef,
}) => {
  const months = useGetMonths(true)

  const getMonthOptions = useMemo(
    () => {
      return months.slice(startMonth || 0, endMonth || months.length)
    },
    startMonth,
    endMonth,
    months,
  )

  return ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div ref={headerRef} className={style.headerOneCalendarRangeContainer}>
      <button
        className={themeWhite ? style.buttonArrowThemeWhite : style.buttonArrow}
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <i className={`icon-arrow-left ${style.iconArrow}`} />
      </button>

      <div className={style.selectContainer}>
        <div className={style.selectMonthOneCalendarRange}>
          <SelectMonthYear
            currentValue={months[date.getMonth()]}
            selectRef={
              <div
                className={`${
                  themeWhite
                    ? style.selectOneCalendarRangeThemeWhite
                    : style.selectOneCalendarRange
                } `}
              >
                {months[date.getMonth()]}
                <i className={`icon-arrow-down ${style.iconArrow}`} />
              </div>
            }
            handleChange={(value) => changeMonth(months.indexOf(value))}
            options={getMonthOptions}
            marginTop={marginTop}
            zIndex={zIndex}
            contentDropdownRef={monthContentDropdownRef}
            isOptionSelectedDefaultColor={isOptionSelectedDefaultColor}
            position={position}
            maxHeightDropdown={maxHeightDropdown}
            themeWhite={themeWhite}
          />
        </div>

        <SelectMonthYear
          currentValue={date.getFullYear()}
          selectRef={
            <div
              className={
                themeWhite
                  ? style.selectOneCalendarRangeThemeWhite
                  : style.selectOneCalendarRange
              }
            >
              {date.getFullYear()}
              <i className={`icon-arrow-down ${style.iconArrow}`} />
            </div>
          }
          handleChange={(value) => changeYear(value)}
          options={range(startYear, endYear, true)}
          marginTop={marginTop}
          zIndex={zIndex}
          contentDropdownRef={yearContentDropdownRef}
          isOptionSelectedDefaultColor={isOptionSelectedDefaultColor}
          position={position}
          maxHeightDropdown={maxHeightDropdown}
          themeWhite={themeWhite}
        />
      </div>

      <button
        className={themeWhite ? style.buttonArrowThemeWhite : style.buttonArrow}
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <i className={`icon-arrow-right ${style.iconArrow}`} />
      </button>
    </div>
  )
}

HeaderOneCalendarRange.propTypes = {
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  zIndex: PropTypes.number,
  monthContentDropdownRef: PropTypes.object,
  yearContentDropdownRef: PropTypes.object,
  isOptionSelectedDefaultColor: PropTypes.bool,
  marginTop: PropTypes.number,
  position: PropTypes.string,
  themeWhite: PropTypes.bool,
}

HeaderOneCalendarRange.defaultProps = {
  isOptionSelectedDefaultColor: true,
  marginTop: 8,
  position: positionDropdown.BOTTOM_CENTER,
  themeWhite: false,
}

export default HeaderOneCalendarRange
