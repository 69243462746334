import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valDivBillion } from '../../../../../utils/Value'
import {
  RADIO_VALUE,
  RIGHT_FRAME_OPTIONS,
  STATE_BUDGET_CHART_TYPE,
} from '../constants'
import {
  getStateBudgetBalanceChart,
  getStateBudgetExpenditureChart,
  getStateBudgetRevenueChart,
} from './thunk'

const initialState = {
  leftFrameValue: STATE_BUDGET_CHART_TYPE.BALANCE,
  rightFrameValue: RIGHT_FRAME_OPTIONS[0].value,
  stackChartData: [],
  stackChartLoading: true,
  ids: [],
  radioValue: RADIO_VALUE.VALUE,
}

const slice = createSlice({
  name: 'economy/fiscal/stateBudget/revenueStructure',
  initialState,
  reducers: {
    handleLeftFrameValue: (state, action) => {
      state.leftFrameValue = action.payload
    },
    handleRightFrameValue: (state, action) => {
      state.rightFrameValue = action.payload
    },
    handleRadioValue: (state, action) => {
      state.radioValue = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStateBudgetBalanceChart.pending, (state) => {
      state.stackChartLoading = true
    })
    builder.addCase(getStateBudgetBalanceChart.fulfilled, (state, action) => {
      state.stackChartLoading = false
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          id: item.governmentBudgetVNTypeId,
          name: item.governmentBudgetVNTypeName,
          value: valDivBillion(item.value),
        })) || []
      state.stackChartData = convertData(payloadData)
      state.nameById = Object.values(
        payloadData
          .map((item) => ({ name: item.name, id: item.id }))
          .reduce((acc, curr) => {
            acc[curr.name + '|' + curr.id] = curr
            return acc
          }, {}),
      )
      state.ids = [...new Set(payloadData.map((item) => item.id))]
    })
    builder.addCase(getStateBudgetBalanceChart.rejected, (state) => {
      state.stackChartLoading = false
    })

    builder.addCase(getStateBudgetRevenueChart.pending, (state) => {
      state.stackChartLoading = true
    })
    builder.addCase(getStateBudgetRevenueChart.fulfilled, (state, action) => {
      state.stackChartLoading = false
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          id: item.governmentBudgetVNTypeId,
          name: item.governmentBudgetVNTypeName,
          value:
            state.radioValue === RADIO_VALUE.VALUE
              ? valDivBillion(item.value)
              : item.percentage * 100,
        })) || []
      state.stackChartData = convertData(payloadData)
      state.nameById = Object.values(
        payloadData
          .map((item) => ({ name: item.name, id: item.id }))
          .reduce((acc, curr) => {
            acc[curr.name + '|' + curr.id] = curr
            return acc
          }, {}),
      )
      state.ids = [...new Set(payloadData.map((item) => item.id))]
    })
    builder.addCase(getStateBudgetRevenueChart.rejected, (state) => {
      state.stackChartLoading = false
    })

    builder.addCase(getStateBudgetExpenditureChart.pending, (state) => {
      state.stackChartLoading = true
    })
    builder.addCase(
      getStateBudgetExpenditureChart.fulfilled,
      (state, action) => {
        state.stackChartLoading = false
        const payloadData =
          action.payload?.map((item) => ({
            ...item,
            id: item.governmentBudgetVNTypeId,
            name: item.governmentBudgetVNTypeName,
            value:
              state.radioValue === RADIO_VALUE.VALUE
                ? valDivBillion(item.value)
                : item.percentage * 100,
          })) || []
        state.stackChartData = convertData(payloadData)
        state.nameById = Object.values(
          payloadData
            .map((item) => ({ name: item.name, id: item.id }))
            .reduce((acc, curr) => {
              acc[curr.name + '|' + curr.id] = curr
              return acc
            }, {}),
        )
        state.ids = [...new Set(payloadData.map((item) => item.id))]
      },
    )
    builder.addCase(getStateBudgetExpenditureChart.rejected, (state) => {
      state.stackChartLoading = false
    })
  },
})

export const {
  handleLeftFrameValue,
  handleRightFrameValue,
  changeCurrentTimeRange,
  handleRadioValue,
} = slice.actions

export const getLeftFrameValue = (state) => state[slice.name].leftFrameValue
export const getRightFrameValue = (state) => state[slice.name].rightFrameValue
export const getStackChartData = (state) => state[slice.name].stackChartData
export const getStackChartLoading = (state) =>
  state[slice.name].stackChartLoading
export const selectIds = (state) => state[slice.name].ids
export const selectNameById = (state) => state[slice.name].nameById
export const selectRadioValue = (state) => state[slice.name].radioValue

register(slice.name, slice.reducer)

const convertData = (data) => {
  const getAllYears = [...new Set(data.map((item) => item.year))].sort()

  const getDataByYear = (year) => {
    return data
      .filter((item) => item.year === year)
      .map((item) => ({
        value: item.value,
        id: item.id,
        name: item.name,
      }))
  }

  const getYearValueFromRange = getAllYears.map((item) => [
    ...getDataByYear(item),
  ])

  const getDataKeyIdValueFromRange = getYearValueFromRange.length
    ? getYearValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.id]: e.value }
        }),
      )
    : []

  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getAllYears[i],
  }))
}
