import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getColorPieChart } from '../helper'
import { getRecommendation } from './thunk'

const initialState = {
  data: [],
  loading: true,
}

export const slice = createSlice({
  name: 'corporate/peersValuation/consensus/recommendation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRecommendation.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getRecommendation.fulfilled, (state, action) => {
      state.loading = false
      state.data = getColorPieChart(action.payload[0])
    })
    builder.addCase(getRecommendation.rejected, (state) => {
      state.loading = true
    })
  },
})

export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
