import { SizeTracker } from '../../../../common/sizeTracker'
import { Header } from './Header'
import { ListItem } from './ListItem'

export const AnalyticNews = () => {
  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Header />
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <ListItem />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}
