import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { valueTab } from '../constants'
import IconGlobal from '../flagIcon/Icon_Global.svg'
import IconVNFlag from '../flagIcon/Icon_VN_Flag.svg'
import {
  getIdsWithParentId,
  getMultipleActiveRows,
  getTab,
  handleActiveRowId,
  selectItemData,
} from '../store/slice'
import style from '../style.module.css'
import { GLOBAL_ID, VN_ID } from '../upcomingEvents/config'

const { VIET_NAM, OTHER } = valueTab

const ItemClicked = ({ day, event, listTitle, id, newsId }) => {
  const dispatch = useDispatch()

  const tab = useSelector(getTab)
  const item = useSelector(selectItemData(id))
  const idsWithParentId = useSelector(getIdsWithParentId)
  const multipleActiveRowsIds = useSelector(getMultipleActiveRows)

  const removeItemClicked = () => {
    dispatch(
      handleActiveRowId(multipleActiveRowsIds.filter((item) => item !== id)),
    )
  }

  const handleRedirect = () => {
    if (newsId) {
      window.open(`/economy/news-report-detail?newsId=${newsId}`, '_blank')
    }
  }

  return (
    <div>
      <div className="d-flex align-center">
        <i
          className={`icon-delete-circle ${style.iconDelete}`}
          onClick={removeItemClicked}
        />
        <Span className={style.textOpacity}>{`${day} -`}</Span>
        <Span style={{ marginLeft: 4 }}>{event}</Span>
        {(tab === OTHER ||
          idsWithParentId[item?.parentMacroEventTypeId] === GLOBAL_ID) && (
          <img src={IconGlobal} alt={'IconGlobal'} style={{ marginLeft: 8 }} />
        )}
        {(tab === VIET_NAM ||
          idsWithParentId[item?.parentMacroEventTypeId] === VN_ID) && (
          <img src={IconVNFlag} alt={'IconVNFlag'} style={{ marginLeft: 8 }} />
        )}
      </div>
      <ul className={style.listEvent}>
        {listTitle.map((item) => {
          return (
            <li
              key={item.macroEventId}
              onClick={handleRedirect}
              style={{ cursor: 'pointer' }}
            >
              <TextEllipsis isI18n={false} text={item.title} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ItemClicked.propTypes = {
  day: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  listTitle: PropTypes.array.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default ItemClicked
