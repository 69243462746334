import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot, valDivBillion } from '../../../../../utils/Value'
import { getYearQuarter } from '../../common/helper'
import { formatDataDetailMetrics } from '../detailMetrics/helper'
import {
  getInsuChangeInMarketShare,
  getInsuClaimRatio,
  getInsuDetailMetrics,
  getInsuMarketShare,
  getInsuMaxPeriod,
  getInsuRevenue,
} from './thunk'

const { year: currentYear, quarter: currentQuarter } = getYearQuarter(
  new Date(),
)

const initialState = {
  filter: {
    year: null,
    quarter: null,
  },
  loading: {
    revenue: true,
    marketShare: true,
    changeInMarketShare: true,
    claimRatio: true,
    detailMetrics: true,
  },
  data: {
    revenue: [],
    marketShare: [],
    changeInMarketShare: [],
    claimRatio: [],
    detailMetricsData: {},
    detailMetricsTickers: [],
  },
  reCalcWiths: false,
  listCheckboxRevenue: ['iS1', 'iS73'],
  listCheckboxClaim: ['rT0339', 'rT0338'],
}

const slice = createSlice({
  name: 'corporate//businessModel/penetration/insurance',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },
    handleListCheckboxRevenue: (state, action) => {
      state.listCheckboxRevenue = action.payload
    },
    handleListCheckboxClaim: (state, action) => {
      state.listCheckboxClaim = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInsuMaxPeriod.pending, (state) => {})
    builder.addCase(getInsuMaxPeriod.fulfilled, (state, action) => {
      state.filter.year = action.payload?.year || currentYear
      state.filter.quarter = action.payload?.quarter || currentQuarter
    })
    builder.addCase(getInsuMaxPeriod.rejected, (state, action) => {
      state.filter.year = currentYear
      state.filter.quarter = currentQuarter
    })
    //Revenue
    builder.addCase(getInsuRevenue.pending, (state) => {
      state.loading.revenue = true
    })
    builder.addCase(getInsuRevenue.fulfilled, (state, action) => {
      state.data.revenue =
        action.payload?.map((item) => ({
          ...item,
          iS1: valDivBillion(item.iS1),
          iS73: valDivBillion(item.iS73),
        })) || []
      state.loading.revenue = false
    })
    builder.addCase(getInsuRevenue.rejected, (state, action) => {
      state.loading.revenue = action.payload.loading
    })
    //MarketShare
    builder.addCase(getInsuMarketShare.pending, (state) => {
      state.loading.marketShare = true
    })
    builder.addCase(getInsuMarketShare.fulfilled, (state, action) => {
      state.data.marketShare = action.payload || []
      state.loading.marketShare = false
    })
    builder.addCase(getInsuMarketShare.rejected, (state, action) => {
      state.loading.marketShare = action.payload.loading
    })
    //ChangeInMarketShare
    builder.addCase(getInsuChangeInMarketShare.pending, (state) => {
      state.loading.changeInMarketShare = true
    })
    builder.addCase(getInsuChangeInMarketShare.fulfilled, (state, action) => {
      state.data.changeInMarketShare =
        action.payload?.map((item) => ({
          ...item,
          c1: item.c1 * 100,
        })) || []
      state.loading.changeInMarketShare = false
    })
    builder.addCase(getInsuChangeInMarketShare.rejected, (state, action) => {
      state.loading.changeInMarketShare = action.payload.loading
    })
    //ClaimRatio
    builder.addCase(getInsuClaimRatio.pending, (state) => {
      state.loading.claimRatio = true
    })
    builder.addCase(getInsuClaimRatio.fulfilled, (state, action) => {
      state.data.claimRatio =
        action.payload?.map((item) => ({
          ...item,
          rT0338: item.rT0338 * 100,
          rT0339: item.rT0339 * 100,
        })) || []
      state.loading.claimRatio = false
    })
    builder.addCase(getInsuClaimRatio.rejected, (state, action) => {
      state.loading.claimRatio = action.payload.loading
    })
    //DetailMetrics
    builder.addCase(getInsuDetailMetrics.pending, (state) => {
      state.loading.detailMetrics = true
    })
    builder.addCase(getInsuDetailMetrics.fulfilled, (state, action) => {
      const { tickers, data } = formatDataDetailMetrics(action.payload || [])
      state.data.detailMetricsData = data
      state.data.detailMetricsTickers = tickers
      state.reCalcWiths = !state.reCalcWiths
      state.loading.detailMetrics = false
    })
    builder.addCase(getInsuDetailMetrics.rejected, (state, action) => {
      state.loading.detailMetrics = action.payload.loading
    })
  },
})

export const selectFilter = (state) => state[slice.name].filter

export const selectLoadingRevenue = (state) => state[slice.name].loading.revenue
export const selectLoadingMarketShare = (state) =>
  state[slice.name].loading.marketShare
export const selectLoadingChangeInMarketShare = (state) =>
  state[slice.name].loading.changeInMarketShare
export const selectLoadingClaimRatio = (state) =>
  state[slice.name].loading.claimRatio
export const selectLoadingDetailMetrics = (state) =>
  state[slice.name].loading.detailMetrics

export const selectDataRevenue = (state) => state[slice.name].data.revenue
export const selectDataMarketShare = (state) =>
  state[slice.name].data.marketShare
export const selectDataChangeInMarketShare = (state) =>
  state[slice.name].data.changeInMarketShare
export const selectDataClaimRatio = (state) => state[slice.name].data.claimRatio
export const selectDetailMetricsTickers = (state) =>
  state[slice.name].data.detailMetricsTickers
export const selectDataDetailMetrics = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.detailMetricsData[id], attr)

export const selectReCalcWiths = (state) => state[slice.name].reCalcWiths

export const selectListCheckboxRevenue = (state) =>
  state[slice.name].listCheckboxRevenue

export const selectListCheckboxClaim = (state) =>
  state[slice.name].listCheckboxClaim

export const {
  resetStore,
  changeFilter,
  handleListCheckboxRevenue,
  handleListCheckboxClaim,
} = slice.actions

register(slice.name, slice.reducer)
