import useGetICB from '../../../../common/masterData/useGetICB'
import style from '../style.module.css'

const ListSectorSelected = ({
  indexStart,
  sectorChecked,
  securitiesState,
  setSecuritiesState,
}) => {
  const { ICBs } = useGetICB(true, true)

  const getValueItem = (icbId) => {
    return ICBs.find((i) => i.icbId === +icbId)
  }

  const removeSectorInList = (icbId) => {
    const value = sectorChecked.filter((i) => i !== icbId)
    setSecuritiesState({ ...securitiesState, sector: value })
  }

  return (
    <>
      {sectorChecked.map((icbId, index) => {
        return (
          <li key={icbId}>
            <div className={style.indexCol}>{indexStart + index + 1}</div>
            <div className={style.divSecurities}>
              <div className={style.tickerInfo}>
                {getValueItem(icbId)?.icbName +
                  ' L' +
                  getValueItem(icbId)?.icbLevel}
              </div>
            </div>
            <div
              className={style.checkboxCol}
              onClick={() => removeSectorInList(icbId)}
            >
              <i
                className="icon-delete-circle cursor-pointer"
                style={{ fontSize: 12 }}
              />
            </div>
          </li>
        )
      })}
    </>
  )
}

export default ListSectorSelected
