import peerValuationProxy from '../../proxies/corporate/PeerValuationProxy'
import { ServiceBase } from '../ServiceBase'

class PeerValuationService extends ServiceBase {
  //Chart
  getColumnChart(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getColumnChart',
      params,
    )(() => peerValuationProxy.getColumnChart(params))
  }

  getLineChart(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getLineChart',
      params,
    )(() => peerValuationProxy.getLineChart(params))
  }

  //Get orgIds by Icb
  getOrgIdsByIcb(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getOrgIdsByIcb',
      params,
    )(() => peerValuationProxy.getOrgIdsByIcb(params))
  }

  //Peer Comparison
  getPeerComparisonBankPerformance(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonBankPerformance',
      params,
    )(() => peerValuationProxy.getPeerComparisonBankPerformance(params))
  }

  downloadPeerComparisonBankPerformance(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonBankPerformance(params),
    )
  }

  getPeerComparisonBankValuation(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonBankValuation',
      params,
    )(() => peerValuationProxy.getPeerComparisonBankValuation(params))
  }

  downloadPeerComparisonBankValuation(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonBankValuation(params),
    )
  }

  getPeerComparisonBankOwnership(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonBankOwnership',
      params,
    )(() => peerValuationProxy.getPeerComparisonBankOwnership(params))
  }

  downloadPeerComparisonBankOwnership(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonBankOwnership(params),
    )
  }

  getPeerComparisonBankAssetQuality(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonBankAssetQuality',
      params,
    )(() => peerValuationProxy.getPeerComparisonBankAssetQuality(params))
  }

  downloadPeerComparisonBankCredit(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonBankAssetQuality(params),
    )
  }

  getPeerComparisonBankProfitability(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonBankProfitability',
      params,
    )(() => peerValuationProxy.getPeerComparisonBankProfitability(params))
  }

  downloadPeerComparisonBankProfitability(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonBankProfitability(params),
    )
  }

  getPeerComparisonCorpPerformance(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonCorpPerformance',
      params,
    )(() => peerValuationProxy.getPeerComparisonCorpPerformance(params))
  }

  downloadPeerComparisonCorpPerformance(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonCorpPerformance(params),
    )
  }

  getPeerComparisonCorpValuation(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonCorpValuation',
      params,
    )(() => peerValuationProxy.getPeerComparisonCorpValuation(params))
  }

  getPeerComparisonCorpValuationFetch(params) {
    return this.getData(() =>
      peerValuationProxy.getPeerComparisonCorpValuation(params),
    )
  }

  downloadPeerComparisonCorpValuation(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonCorpValuation(params),
    )
  }

  getPeerComparisonCorpOwnership(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonCorpOwnership',
      params,
    )(() => peerValuationProxy.getPeerComparisonCorpOwnership(params))
  }

  downloadPeerComparisonCorpOwnership(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonCorpOwnership(params),
    )
  }

  getPeerComparisonCorpCredit(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonCorpCredit',
      params,
    )(() => peerValuationProxy.getPeerComparisonCorpCredit(params))
  }

  downloadPeerComparisonCorpCredit(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonCorpCredit(params),
    )
  }

  getPeerComparisonCorpProfitability(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonCorpProfitability',
      params,
    )(() => peerValuationProxy.getPeerComparisonCorpProfitability(params))
  }

  downloadPeerComparisonCorpProfitability(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonCorpProfitability(params),
    )
  }

  getPeerComparisonInsuPerformance(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonInsuPerformance',
      params,
    )(() => peerValuationProxy.getPeerComparisonInsuPerformance(params))
  }

  downloadPeerComparisonInsuPerformance(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonInsuPerformance(params),
    )
  }

  getPeerComparisonInsuValuation(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonInsuValuation',
      params,
    )(() => peerValuationProxy.getPeerComparisonInsuValuation(params))
  }

  downloadPeerComparisonInsuValuation(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonInsuValuation(params),
    )
  }

  getPeerComparisonInsuOwnership(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonInsuOwnership',
      params,
    )(() => peerValuationProxy.getPeerComparisonInsuOwnership(params))
  }

  downloadPeerComparisonInsuOwnership(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonInsuOwnership(params),
    )
  }

  getPeerComparisonInsuCredit(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonInsuCredit',
      params,
    )(() => peerValuationProxy.getPeerComparisonInsuCredit(params))
  }

  downloadPeerComparisonInsuCredit(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonInsuCredit(params),
    )
  }

  getPeerComparisonInsuProfitability(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonInsuProfitability',
      params,
    )(() => peerValuationProxy.getPeerComparisonInsuProfitability(params))
  }

  downloadPeerComparisonInsuProfitability(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonInsuProfitability(params),
    )
  }

  getPeerComparisonSecuPerformance(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonSecuPerformance',
      params,
    )(() => peerValuationProxy.getPeerComparisonSecuPerformance(params))
  }

  downloadPeerComparisonSecuPerformance(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonSecuPerformance(params),
    )
  }

  getPeerComparisonSecuValuation(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonSecuValuation',
      params,
    )(() => peerValuationProxy.getPeerComparisonSecuValuation(params))
  }

  downloadPeerComparisonSecuValuation(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonSecuValuation(params),
    )
  }

  getPeerComparisonSecuOwnership(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonSecuOwnership',
      params,
    )(() => peerValuationProxy.getPeerComparisonSecuOwnership(params))
  }

  downloadPeerComparisonSecuOwnership(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonSecuOwnership(params),
    )
  }

  getPeerComparisonSecuCredit(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonSecuCredit',
      params,
    )(() => peerValuationProxy.getPeerComparisonSecuCredit(params))
  }

  downloadPeerComparisonSecuCredit(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonSecuCredit(params),
    )
  }

  getPeerComparisonSecuProfitability(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonSecuProfitability',
      params,
    )(() => peerValuationProxy.getPeerComparisonSecuProfitability(params))
  }

  downloadPeerComparisonSecuProfitability(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonSecuProfitability(params),
    )
  }

  getMaxPeriod(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getMaxPeriod',
      params,
    )(() => peerValuationProxy.getMaxPeriod(params))
  }

  getPeerComparisonBalanceSheet(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonBalanceSheet',
      params,
    )(() => peerValuationProxy.getPeerComparisonBalanceSheet(params))
  }

  downloadPeerComparisonBalanceSheet(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonBalanceSheet(params),
    )
  }

  getPeerComparisonIncomeStatement(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getPeerComparisonIncomeStatement',
      params,
    )(() => peerValuationProxy.getPeerComparisonIncomeStatement(params))
  }

  downloadPeerComparisonIncomeStatement(params) {
    return this.getFileDownload(() =>
      peerValuationProxy.downloadPeerComparisonIncomeStatement(params),
    )
  }

  getRecommendation(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getRecommendation',
      params,
    )(() => peerValuationProxy.getRecommendation(params))
  }

  getHistory(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getHistory',
      params,
    )(() => peerValuationProxy.getHistory(params))
  }

  getHistoryStackColumn(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getHistoryStackColumn',
      params,
    )(() => peerValuationProxy.getHistoryStackColumn(params))
  }

  getTargetPrice(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getTargetPrice',
      params,
    )(() => peerValuationProxy.getTargetPrice(params))
  }

  getTargetPriceDetail(params) {
    return this.applyMemoryCache(
      'PeerValuationService.getTargetPriceDetail',
      params,
    )(() => peerValuationProxy.getTargetPriceDetail(params))
  }
}

export default new PeerValuationService()
