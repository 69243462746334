import { EMPTY_VALUE } from '../../../../constants/Common'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal } from '../../../../utils/Value'
import {
  BUBBLE_COLOR_TYPE,
  DEFAULT_BUBBLE_COLOR,
  PERIOD_TYPE,
  PLOT_TYPE,
} from '../constant'

export const formatChartData = ({
  filteredOrgIds,
  data,
  generalData = {},
  organizations,
  bubbleColor,
  bubbleSize,
  xMultiplier,
  xFormatPercent,
  yMultiplier,
  yFormatPercent,
}) => {
  return data
    .filter((item) => filteredOrgIds.includes(item.organizationId))
    .map((item) => {
      return {
        ...item,
        ...generalData[item.organizationId],
        xValue: item?.xValue * Number(xMultiplier) * xFormatPercent,
        yValue: item?.yValue * Number(yMultiplier) * yFormatPercent,
        color:
          bubbleColor === BUBBLE_COLOR_TYPE.GROUP
            ? organizations[item.organizationId]?.color || DEFAULT_BUBBLE_COLOR
            : bubbleColor === BUBBLE_COLOR_TYPE.COMPANY
            ? organizations[item.organizationId]?.companyColor ||
              DEFAULT_BUBBLE_COLOR
            : DEFAULT_BUBBLE_COLOR,
      }
    })
    .sort((a, b) => b[bubbleSize] - a[bubbleSize])
}

const getTimeByType = ({ data, type, locale }) => {
  switch (type) {
    case PERIOD_TYPE.YEARLY:
      return data.year
    case PERIOD_TYPE.QUARTERLY:
      return `Q${data.quarter === 5 ? 4 : data.quarter}/${data.year}`
    default:
      return data.tradingDate
        ? formatDateTime(data.tradingDate, FORMAT.DATE, locale)
        : EMPTY_VALUE
  }
}

export const getTooltipContent = ({
  data,
  plotType,
  xPeriodType,
  yPeriodType,
  xAxisDecimalLength,
  yAxisDecimalLength,
  xLabel,
  yLabel,
  locale,
}) => {
  if (plotType === PLOT_TYPE.TIME_SERIES) {
    return [
      {
        name: `${xLabel} (${getTimeByType({
          data,
          type: xPeriodType,
          locale,
        })})`,
        value: formatVal(data.xValue, xAxisDecimalLength || 2),
      },
      {
        name: `${yLabel} (${getTimeByType({
          data,
          type: yPeriodType,
          locale,
        })})`,
        value: formatVal(data.yValue, yAxisDecimalLength || 2),
      },
    ]
  } else {
    return [
      {
        name: xLabel,
        value: formatVal(data.xValue, xAxisDecimalLength || 2),
      },
      {
        name: yLabel,
        value: formatVal(data.yValue, yAxisDecimalLength || 2),
      },
    ]
  }
}
