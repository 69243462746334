import { Loading } from '../../../loading'
import { ScrollComponent } from '../../../ScrollComponent'
import { Item } from '../../item'
import style from './index.module.css'

export const Left = ({ isLoading, newsById }) => {
  return (
    <ScrollComponent>
      <div className={['h-100 position-relative', style.container].join(' ')}>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <div style={{ paddingBottom: 16 }}>
              <Item
                item={newsById}
                isShowBtnShare
                distanceBetweenItem={30}
                titleFontSize={26}
                titleLineClamp={10}
                isShowDesc={false}
                onAction={() => {}}
              />
              <div
                className={style.shortContent}
                dangerouslySetInnerHTML={{
                  __html: newsById.shortContent,
                }}
              />
              <div className="d-flex justify-content-center mb-15">
                <img
                  src={newsById.imageUrl}
                  alt={newsById.imageUrl}
                  width={'50%'}
                />
              </div>
              <div dangerouslySetInnerHTML={{ __html: newsById.content }} />
              <div className="d-flex justify-content-space-between mt-16">
                <a
                  href={newsById.sourceUrl}
                  target="__blank"
                  style={{ color: '#4bb6f4' }}
                >
                  {newsById.sourceUrl}
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </ScrollComponent>
  )
}
