import { I18n } from 'react-redux-i18n'
import { Label } from 'recharts'
import { getTextWidth } from '../../../common/chart/helper'
import TextEllipsis from '../../../common/textEllipsis'
import { getFontSize } from '../../../utils/FontSize'

const heightBarChart = 10

export const customYAxisTick = (fontSize) => (props) => {
  return (
    <g>
      <foreignObject
        x={0}
        y={props.y - heightBarChart}
        height={props.height}
        width={props.width}
        color="#ececec"
      >
        <TextEllipsis
          appendStyle={{ fontSize, textAlign: 'end' }}
          text={props.payload.value}
          isI18n={true}
        />
      </foreignObject>
    </g>
  )
}

export const xAxisLabelCustom = () => (
  <Label
    value={I18n.t('economy.fdi.overview.LABEL_MILLION_USD')}
    position="insideBottomRight"
    content={(props) => {
      return (
        <text
          style={{ fontSize: getFontSize(10), opacity: 0.4 }}
          x={props.viewBox.x + props.viewBox.width}
          y={props.viewBox.y + props.viewBox.height + 8}
          textAnchor="end"
          fill="rgba(255, 255, 255)"
        >
          {props.value}
        </text>
      )
    }}
  />
)

export const getItemTextWidth = (data, fontSize) => {
  const itemsTextWidth = data
    .map((item) => I18n.t(item.locationName))
    .map((text) => getTextWidth(text, fontSize))
  return Math.max(...itemsTextWidth)
}
