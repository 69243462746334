import { PropTypes } from 'prop-types'
import { withWrapper } from '../../../common/withWrapper'
import { PanelOverviewStatistics } from './tableStatisticsHistorical/PanelOverviewStatistics'

const MAP_KEY = {
  TABLE_DATA: 'TABLE_DATA',
}

const MAP_SIZE = {
  [`${MAP_KEY.TABLE_DATA}`]: {
    width: `100%`,
    height: '100%',
    top: 0,
    left: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <PanelOverviewStatistics
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TABLE_DATA}
        sizes={sizes}
        setSizes={setSizes}
      />
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
