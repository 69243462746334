import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { DEFAULT_CHECKED } from '../config'
import { GDP_SECTOR_VALUE } from '../constants'
import {
  defineGroupColumns,
  findMinQuarter,
  getAllKeyNotDuplicate,
  getIdByLevel,
  getYearlyQuarterNotDuplicate,
  handleLevels,
  initLineColor,
  keyByArray,
} from '../helper'
import { getGDPBySector, getGDPTypeName } from './thunk'

const { GROWTH_YOY } = GDP_SECTOR_VALUE

const initialState = {
  GDPBySectorLoading: true,
  GDPTypeNameLoading: true,
  groupColumns: [],
  checkboxChecked: [],
  lineChartColor: [],
  ids: [],
  GDPTypeName: [],
  GDPSector: [],
  GDPSectorById: {},
  yearlyQuarterlyOption: 'Quarterly',
  valueGrowthYoYOption: GROWTH_YOY,
  initialIds: [],
  levels: [],
  isFirstTime: true,
  minQuarterYear: {
    minYear: 0,
    minQuarter: 0,
    prevMinYear: 0,
    prevMinQuarter: 0,
  },
  isAllowCallApi: true,
  isShowAlert: false,
  indexChecked: DEFAULT_CHECKED,
}

const slice = createSlice({
  name: 'economy/gdp/totalGDP/panelTotalGDP',
  initialState,
  reducers: {
    handleYearlyQuarterly: (state, action) => {
      state.yearlyQuarterlyOption = action.payload
    },
    handleValueGrowthYoY: (state, action) => {
      state.valueGrowthYoYOption = action.payload
    },
    handleCheckboxChecked: (state, action) => {
      state.checkboxChecked = action.payload
    },
    handleLineChartColor: (state, action) => {
      state.lineChartColor = action.payload
    },
    handleIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload
    },
    handleIsAllowCallApi: (state, action) => {
      state.isAllowCallApi = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.GDPSectorById,
        action.payload,
        state.initialIds,
        0,
        state.levels,
      )
    },
    handleIsShowAlert: (state, action) => {
      state.isShowAlert = action.payload
    },
    handleIndexChecked: (state, action) => {
      state.indexChecked = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGDPBySector.pending, (state) => {
      state.GDPBySectorLoading = true
    })
    builder.addCase(getGDPBySector.fulfilled, (state, action) => {
      const data = action.payload || []
      state.GDPBySectorLoading = false
      const years = getYearlyQuarterNotDuplicate(data)
      state.groupColumns = defineGroupColumns(
        years,
        action.meta.arg.TimeFrequency,
        state.groupColumns,
        action.meta.arg.NumberOfPeriod,
      )
      state.GDPSector = data
      state.GDPSectorById = keyByArray(
        data,
        'nationalAccountVNTypeId',
        undefined,
        action.meta.arg.TimeFrequency,
        state.GDPSectorById,
        action.meta.arg.NumberOfPeriod,
      )
      state.minQuarterYear = {
        ...findMinQuarter(data, action.meta.arg.TimeFrequency),
        prevMinYear: state.minQuarterYear.minYear,
        prevMinQuarter: state.minQuarterYear.minQuarter,
      }
      if (!data?.length) {
        state.isAllowCallApi = false
      }
    })
    builder.addCase(getGDPBySector.rejected, (state, action) => {
      state.GDPBySectorLoading = action.payload.isLoading
    })
    builder.addCase(getGDPTypeName.pending, (state) => {
      state.GDPTypeNameLoading = true
    })
    builder.addCase(getGDPTypeName.fulfilled, (state, action) => {
      const data = action.payload || []
      state.GDPTypeNameLoading = false
      state.ids = state.initialIds = getAllKeyNotDuplicate(
        data,
        'nationalAccountVNTypeId',
      )
      state.checkboxChecked = getIdByLevel(data, state.indexChecked)
      state.lineChartColor = initLineColor(
        getIdByLevel(data, state.indexChecked, true),
      )
      state.GDPTypeName = data
      state.levels = handleLevels(data)
    })
    builder.addCase(getGDPTypeName.rejected, (state, action) => {
      state.GDPTypeNameLoading = action.payload.isLoading
    })
  },
})

export const {
  handleYearlyQuarterly,
  handleValueGrowthYoY,
  handleCheckboxChecked,
  handleLineChartColor,
  handleIsFirstTime,
  handleIsAllowCallApi,
  sort,
  handleIsShowAlert,
  handleIndexChecked,
} = slice.actions
export const getGDPBySectorLoading = (state) =>
  state[slice.name].GDPBySectorLoading
export const getGDPTypeNameLoading = (state) =>
  state[slice.name].GDPTypeNameLoading
export const getGroupColumns = (state) => state[slice.name].groupColumns
export const getYearlyQuarterly = (state) =>
  state[slice.name].yearlyQuarterlyOption
export const getValueGrowthYoY = (state) =>
  state[slice.name].valueGrowthYoYOption
export const getIds = (state) => state[slice.name].ids
export const selectGDPSectorValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].GDPSectorById[id], attr)
export const getGDPSectorById = (state) => state[slice.name].GDPSectorById
export const getGDPTypeNameData = (state) => state[slice.name].GDPTypeName
export const getGDPBySectorData = (state) => state[slice.name].GDPSector
export const getCheckboxChecked = (state) => state[slice.name].checkboxChecked
export const getLineChartColor = (state) => state[slice.name].lineChartColor
export const getIsFirsTime = (state) => state[slice.name].isFirstTime
export const getMinQuarterYear = (state) => state[slice.name].minQuarterYear
export const getIsAllowCallApi = (state) => state[slice.name].isAllowCallApi
export const getIsShowAlert = (state) => state[slice.name].isShowAlert
export const getIndexChecked = (state) => state[slice.name].indexChecked

register(slice.name, slice.reducer)
