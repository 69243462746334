import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel } from '../../../../../common/panel'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { selectFilter } from '../marketShare/store/slice'
import TableDetailMetrics from './TableDetailMetrics'
import { resetStore } from './store/slice'
import { downloadCorpDetailMetrics } from './store/thunk'

const PanelDetailMetrics = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const filter = useSelector(selectFilter)

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <Panel
      title="corporate.businessModel.penetrationAnalysis.DETAIL_METRICS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={() =>
        downloadCorpDetailMetrics({
          RevenueTypeId: filter.revenueTypeId,
          OrganizationId: basicInfo?.organizationId,
        })
      }
    >
      <TableDetailMetrics />
    </Panel>
  )
}

export default PanelDetailMetrics
