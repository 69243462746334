import { Translate } from 'react-redux-i18n'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import styles from '../style.module.css'

const MAX_DECIMAL_PLACE = 20
const MIN_DECIMAL_PLACE = 0

export const DecimalPlacesComponent = ({ isParent, value, onChange }) => {
  const handleChange = (e) => {
    let number = e.target.value
    if (typeof number !== 'number' && !number) {
      return onChange(number)
    }
    if (+number < MIN_DECIMAL_PLACE) number = MIN_DECIMAL_PLACE
    if (+number > MAX_DECIMAL_PLACE) number = MAX_DECIMAL_PLACE
    onChange(+number)
  }

  const handleIncrease = () => {
    let number = value + 1
    if (number > MAX_DECIMAL_PLACE) number = MAX_DECIMAL_PLACE
    onChange(number)
  }

  const handleDecrease = () => {
    let number = value - 1
    if (number < MIN_DECIMAL_PLACE) number = MIN_DECIMAL_PLACE
    onChange(number)
  }

  return (
    <div className={styles.settingBlockItem}>
      <div className={styles.settingBlockLeft}>
        <Span
          className={
            isParent ? styles.settingBlockTitle : styles.settingBlockSubTitle
          }
          style={{ fontSize: isParent ? 12 : 10 }}
        >
          <Translate value="financialChart.chartSettings.DECIMAL_PLACES" />
        </Span>
      </div>
      <div className={styles.settingBlockRight}>
        <div
          className="position-relative"
          style={{
            width: 58,
          }}
        >
          <Input
            value={value}
            type="number"
            className={styles.settingDecimalPlaceInput}
            onChange={handleChange}
          />
          <div
            className={[
              'flex-column',
              styles.settingDecimalPlaceIconContainer,
            ].join(' ')}
          >
            <i
              className={[
                'icon-caret-top',
                styles.settingDecimalPlaceIcon,
              ].join(' ')}
              style={{ paddingTop: 1 }}
              onClick={handleIncrease}
            />
            <i
              className={[
                'icon-caret-down',
                styles.settingDecimalPlaceIcon,
              ].join(' ')}
              style={{ paddingTop: 2 }}
              onClick={handleDecrease}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
