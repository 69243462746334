export const valuation = {
  TITLE: 'Analysis & Valuation',
  infoSec: 'BOND INFORMATION',
  infoValuation: 'VALUATION INPUT',
  sameIndusTryBond: 'BONDS OF ISSUERS AND COMPANIES OF THE SAME INDUSTRY',
  history: 'PAYMENT CALENDAR',
}

export const common = {
  nameTab: 'Adjusted %{number}',
}

export const infoSec = {
  filter: {
    tab: {
      bondDefault: 'Bonds available',
      bondCreated: 'Self-created bonds',
    },
    btnCreatedBond: 'Create bonds',
  },
  info: {
    organizationName: 'Issuer:',
    icbName: 'Industry:',
    issueDateId: 'Issue Date:',
    maturityDateId: 'Maturity Date:',
    paymentCalendarMonth: 'Coupon Period (month/time):',
    expectedInterestPaymentPeriod: 'Expected number of payment Period:',
    durationName: 'Issue period',
    couponTypeName: 'Coupon Type',
    CouponType: 'Expected Coupon (%/Year):',
    floatInterestSpread: 'Coupon Benchmark:',
    info: 'Coupon change information:',
    note: `Caution: The current coupon benchmark is different from the issuer's announced`,
    textView: 'View',
  },
  popupView: {
    title: 'Coupon Change Information',
    content: 'Coupon Change Information:',
    btnOk: 'OK',
  },
}

export const history = {
  filter: {
    word: 'Accrued Interest (VND):',
    mass: 'Volume',
  },
  table: {
    paymentDate: 'PAYMENT DATE',
    couponInterestRate: 'Coupon',
    interestAndPrincipal: 'INTEREST AND PRINCIPAL',
    paymentStatusName: 'STATUS',
    total: 'Total expected cash flow remaining',
    unitMoney: '(VND)',
    unitYear: '(%)',
  },
  tooltipStatus: 'Unconfirmed',
}

export const sameIndusTryBond = {
  tabDefault: {
    default: 'Nominal',
    created: 'Real',
  },
  title: 'Thông tin định giá',
  inputPrice: {
    title: 'Enter the value to calculate the price',
    ProfitBasis: 'Interest basis:',
    tradingDate: 'Trading date',
    paymentDate: 'Payment date',
    Exrightdate: 'Exright date',
    tradingVolume: 'Trading Volume',
    TotalParValue: 'Total par value',

    YTM: 'YTM',
    dirtyPrice: 'Dirty price (VND)',
    cleanPrice: 'Clean price (VND)',
    accurateInterest: 'Accrued Interest',

    TotalExpectedReceivedCashFlow: 'Total expected received cash flow',
    TotalInvestmentValue: 'Total investment value (buyer)',
    TotalExpectedInvestmentProfit: 'Total expected investment profit',
    YieldToMaturity: 'Yield to maturity (YTM)',
    RemainingDuration: 'Time to Maturity',
    cashLineScenels: 'CASHFLOW SCENARIO',
  },
  BondCashFlowScenario: {
    title: 'Kịch BẢN DÒNG TIỀN',
    tab: {
      Titular: 'Danh nghĩa',
      Reality: 'Thực tế',
      tab1: 'Điều chỉnh 1',
      tab2: 'Điều chỉnh 2',
      tab3: 'Điều chỉnh 3',
      tab4: 'Điều chỉnh 4',
      tab5: 'Điều chỉnh 5',
      more: 'More',
    },
    table: {
      CashFlow: 'Cash Flow',
      dateTrading: 'Payment Date',
      coupon: 'Coupon ',
      value: 'Value ',
      typeCashFlow: {
        A1: 'Coupon',
        A4: 'Par Payment',
      },
      unitMoney: '(%/year)',
      unit: '(VND)',
    },
    btnAddCashFlow: 'Add CashFlow',
    btnSave: 'Save',
    btnReset: 'Reset',
  },
  popupAddRow: {
    title: 'Add CashFlow',
    labelName: 'Cash flow',
    LabelDate: 'Payment Date',
    btnSave: 'Save',
    btnCancel: 'Cancel',
    SUCCESS: 'Cashflow "%{name}" has been saved successfully',
  },
  bottom: {
    btnAdd: 'Add Cash flow',
    btnSave: 'Save',
    btnReset: 'Reset',
  },
  popupDelete: {
    title: 'Delete Cashflow',
    message: 'Are you sure to delete cash flow ?',
  },
  tooltipInput: {
    Tradingdate: 'Trading date = Payment date -1',
    Paymentdate: 'Date used for pricing',
    Exrightdate: 'Exright date ',
    TradingVolume: 'Number of bonds assumed to buy',
    TotalParvalue: 'Total Parvalue',

    YTM: 'Yield to Maturity used for pricing',
    Dirtyprice: 'Assumption trading price',
    Cleanprice: '=Dirty Price - Accurate Interest',
    AccurateInterest:
      'The amount of interest from the bond, but not yet collected',
  },
  contentCheckPaymentDate:
    'The payment date must be greater than the issue date and less than the maturity date',
  contentComparePaymentDateTradingDate:
    'The payment date must be greater than the trading date',
  SC07_M007: 'Are you sure to delete cash flow {Namecashflow} ?',
  SC07_M006: 'At least 1 character must be entered',
  SC07_M005: 'Only maximum 5 new cash flow can be added',
  SC07_M004: 'Only a maximum of 50 characters can be entered',
  SC07_M003:
    'The past interest payment date must be greater than the issue date and less than the payment date',
  SC07_M001: 'Features in development',
  SC07_MS009: 'Are you sure to delete cash flow ?',
  SC07_MS0010:
    'The interest payment date must be greater than the payment date',
  SC07_MS0011: 'Only maximum 10 cash flow can be added',
  addTab: 'Add cash flow scenario',
  more: 'MORE',
}

export const companny = {
  table: {
    issuer: 'Issuer',
    bondTicker: 'Bond Ticker',
    couponInterestRate: 'Coupon ',
    issueDateId: 'Issue Date',
    marketPrice: 'Dirty price ',
    remainingDuration: 'Time to Maturity',
    yieldtoMaturity: 'YTM',
    unitMoney: '(%)',
    unitMonth: '(Month)',
    unit: '(VND)',
    unitCoupon: '(%)',
  },
}
