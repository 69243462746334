import { valByKeyWithDot } from '../../../../utils/Value'
import { YEAR_OPTIONS } from '../../constants'
import { GDP_PROVINCE_VALUE } from '../constants'
import { NUMBER_OF_PERIOD_FIRST_TIME } from './TableComponent'

const { GROWTH_YOY, PER_CAPITA } = GDP_PROVINCE_VALUE

export const handleIds = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload.map((item) => item.nationalAccountVNTypeId)
}

export const defineGroupColumns = (payload, numberOfPeriod, prevColumns) => {
  const arrYear = payload.map((item) => item.year)
  const yearNotDuplicates = handleYearNotDuplicates(arrYear)
  const previousColumns =
    numberOfPeriod === NUMBER_OF_PERIOD_FIRST_TIME ? [] : prevColumns
  const groupColumns = yearNotDuplicates.map((item) => ({
    key: `${item}`,
    title: `${item}`,
  }))

  return [...groupColumns, ...previousColumns]
}

const handleYearNotDuplicates = (arr) => {
  return arr.filter((year, index, array) => {
    return index === array.findIndex((element) => element === year)
  })
}

export const keyByArray = (array, key, prevData, args) => {
  const {
    NumberOfPeriod: numberOfPeriod,
    IsNominal: isNominal,
    GDPTypeCode: gdpTypeCode,
  } = args
  const result = {}

  array.forEach((item) => {
    const keyObj = valByKeyWithDot(item, key)
    const value = formatValue(item.value, gdpTypeCode, isNominal)
    if (!result[keyObj]) {
      result[keyObj] = { [item.year]: value }
    } else {
      result[keyObj][item.year] = value
    }
  })

  return mergePrevCurrentData(prevData, result, numberOfPeriod)
}

const formatValue = (value, gdpTypeCode, isNominal) => {
  if (gdpTypeCode === PER_CAPITA) {
    return isNominal ? value / 1000000 : value * 100
  }

  if (gdpTypeCode === GROWTH_YOY) {
    return value * 100
  }

  return value / 1000000000
}

const mergePrevCurrentData = (prev, current, numberOfPeriod) => {
  if (numberOfPeriod === NUMBER_OF_PERIOD_FIRST_TIME) {
    return current
  }

  const prevKeys = Object.keys(prev)
  const curKeys = Object.keys(current)
  const keys = [...new Set([...prevKeys, ...curKeys])]
  if (!curKeys.length) {
    return prev
  }

  let prevCurrentData = {}
  keys.forEach((key) => {
    prevCurrentData = {
      ...prevCurrentData,
      [key]: { ...prev[key], ...current[key] },
    }
  })

  return prevCurrentData
}

export const setMinYear = (payload) => {
  return Math.min.apply(
    Math,
    payload.map((item) => item.year),
  )
}

export const getRangeYear = ({ minYear, maxYear }) => {
  if (!minYear || !maxYear) {
    return YEAR_OPTIONS
  }

  const range = maxYear - minYear + 1

  return [...Array(range).keys()].map((item) => {
    return {
      name: String(maxYear - item),
      value: String(maxYear - item),
    }
  })
}
