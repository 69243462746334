import { createSlice } from '@reduxjs/toolkit'
import { TIME_RANGES } from '../../../../../../common/tabs/DispatchActionTab'
import { register } from '../../../../../../utils/ReducerRegistry'
import { FUND_STRUCTURES, FUND_TYPES } from '../../../../constants'

const initialState = {
  filters: {
    fund: [],
    structure: [FUND_STRUCTURES.STOCK_FUND],
    type: [FUND_TYPES.OPEN_END_FUND],
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    timeRange: TIME_RANGES.OneYear,
    startDate: '',
    endDate: '',
  },
}

export const slice = createSlice({
  name: 'fund/fundCenter/overview/performance/fundFlow',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
  },
  extraReducers: (builder) => {},
})

export const selectFilters = (state) => state[slice.name].filters

export const { changeFilter } = slice.actions

register(slice.name, slice.reducer)
