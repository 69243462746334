import { COMPONENT } from '../../configs/Layout'

// Each layout will split to 6 column.
// Ex: layout 3333 will have 2 col and 2 row,
// each col-row will equal to 3 columns of layout
export const PANEL_LAYOUTS = {
  1: (mapKeys) => mapSize1(mapKeys),
  /**
   *  ___________
   * |           |
   * |___________|
   */
  15: (mapKeys) => mapSize15(mapKeys),
  /**
   *  ___________
   * | |         |
   * |_|_________|
   */
  242: (mapKeys) => mapSize242(mapKeys),
  /**
   *  ___________
   * |___|       |
   * |___|_______|
   */
  336: (mapKeys) => mapSize336(mapKeys),
  /**
   *  ___________
   * |_____|_____|
   * |___________|
   */
  3333: (mapKeys) => mapSize3333(mapKeys),
  /**
   *  ___________
   * |_____|_____|
   * |_____|_____|
   */
  22233: (mapKeys) => mapSize22233(mapKeys),
  /**
   *  ___________
   * |___|___|___|
   * |_____|_____|
   */
}

const mapSize3333 = (mapKeys) => {
  const minWidth = (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 - COMPONENT.MARGIN
  const minHeight = COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN

  return {
    [mapKeys[0].key]: {
      width: `calc(50% - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: 0,
      left: 0,
      minWidth: minWidth,
      minHeight: minHeight,
      minTop: 0,
      minLeft: 0,
      verticalResize: [
        {
          key: mapKeys[1].key,
          ratioWidth: 1,
          ratioLeft: 1,
        },
      ],
      horizontalResize: [
        {
          isResizeWithParent: true,
          key: mapKeys[1].key,
          ratioHeight: -1,
          ratioTop: 0,
        },
        {
          key: mapKeys[2].key,
          ratioHeight: 1,
          ratioTop: 1,
        },
        {
          key: mapKeys[3].key,
          ratioHeight: 1,
          ratioTop: 1,
        },
      ],
      disableVerticalResize: false,
      disableHorizontalResize: false,
    },
    [mapKeys[1].key]: {
      width: `calc(50% - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: 0,
      left: `calc(50% + ${COMPONENT.MARGIN}px)`,
      minWidth: minWidth,
      minHeight: minHeight,
      minTop: 0,
      minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 + COMPONENT.MARGIN,
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
    [mapKeys[2].key]: {
      width: `calc(50% - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: `calc(50% + ${COMPONENT.MARGIN}px)`,
      left: 0,
      minWidth: minWidth,
      minHeight: minHeight,
      minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
      minLeft: 0,
      verticalResize: [
        {
          key: mapKeys[3].key,
          ratioWidth: 1,
          ratioLeft: 1,
        },
      ],
      disableHorizontalResize: true,
    },
    [mapKeys[3].key]: {
      width: `calc(50% - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: `calc(50% + ${COMPONENT.MARGIN}px)`,
      left: `calc(50% + ${COMPONENT.MARGIN}px)`,
      minWidth: minWidth,
      minHeight: minHeight,
      minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
      minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 + COMPONENT.MARGIN,
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
  }
}

const mapSize1 = (mapKey) => {
  const minHeight = COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN

  return {
    [mapKey]: {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      minWidth: COMPONENT.MIN_SIZE.WIDTH * 3,
      minHeight: minHeight,
      minTop: 0,
      minLeft: 0,
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
  }
}

const mapSize242 = (mapKeys) => {
  const minWidth = COMPONENT.MIN_SIZE.WIDTH * 2 * 0.75 - COMPONENT.MARGIN
  const minHeight = COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN

  return {
    [mapKeys[0].key]: {
      width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: 0,
      left: 0,
      minWidth: minWidth,
      minHeight: minHeight,
      minTop: 0,
      minLeft: 0,
      verticalResize: [
        {
          key: mapKeys[1].key,
          ratioWidth: 1,
          ratioLeft: 1,
        },
        {
          key: mapKeys[2].key,
          isResizeWithParent: true,
          ratioWidth: -1,
          ratioLeft: 0,
        },
      ],
      horizontalResize: [
        {
          key: mapKeys[2].key,
          ratioHeight: 1,
          ratioTop: 1,
        },
      ],
      disableVerticalResize: false,
      disableHorizontalResize: false,
    },
    [mapKeys[1].key]: {
      width: `calc(200% / 3 - ${COMPONENT.MARGIN}px)`,
      height: `100%`,
      top: 0,
      left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
      minWidth: minWidth * 2,
      minHeight: minHeight * 2,
      minTop: 0,
      minLeft: minWidth + 2 * COMPONENT.MARGIN,
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
    [mapKeys[2].key]: {
      width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: `calc(50% + ${COMPONENT.MARGIN}px)`,
      left: 0,
      minWidth: minWidth,
      minHeight: minHeight,
      minTop: minHeight + 2 * COMPONENT.MARGIN,
      minLeft: 0,
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
  }
}

const mapSize336 = (mapKeys) => {
  const minWidth = (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 - COMPONENT.MARGIN
  const minHeight = COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN

  return {
    [mapKeys[0].key]: {
      width: `calc(50% - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: 0,
      left: 0,
      minWidth: minWidth,
      minHeight: minHeight,
      minTop: 0,
      minLeft: 0,
      verticalResize: [
        {
          key: mapKeys[1].key,
          ratioWidth: 1,
          ratioLeft: 1,
        },
      ],
      horizontalResize: [
        {
          isResizeWithParent: true,
          key: mapKeys[1].key,
          ratioHeight: -1,
          ratioTop: 0,
        },
        {
          key: mapKeys[2].key,
          ratioHeight: 1,
          ratioTop: 1,
        },
      ],
      disableVerticalResize: false,
      disableHorizontalResize: false,
    },
    [mapKeys[1].key]: {
      width: `calc(50% - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: 0,
      left: `calc(50% + ${COMPONENT.MARGIN}px)`,
      minWidth: minWidth,
      minHeight: minHeight,
      minTop: 0,
      minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 + COMPONENT.MARGIN,
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
    [mapKeys[2].key]: {
      width: `100%`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: `calc(50% + ${COMPONENT.MARGIN}px)`,
      left: 0,
      minWidth: COMPONENT.MIN_SIZE.WIDTH * 3,
      minHeight: minHeight,
      minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
      minLeft: 0,
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
  }
}

const mapSize15 = (mapKeys) => {
  const minWidth = COMPONENT.MIN_SIZE.WIDTH * 0.8
  const minHeight = COMPONENT.MIN_SIZE.HEIGHT * 2 - COMPONENT.MARGIN

  return {
    [mapKeys[0].key]: {
      width: minWidth - COMPONENT.MARGIN,
      height: `100%`,
      top: 0,
      left: 0,
      minWidth: minWidth - COMPONENT.MARGIN,
      minHeight: minHeight,
      minTop: 0,
      minLeft: 0,
      filterResize: [
        {
          key: mapKeys[1].key,
          ratioWidth: 1,
          ratioLeft: -1,
        },
      ],
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
    [mapKeys[1].key]: {
      width: `calc(100% - ${minWidth + COMPONENT.MARGIN}px)`,
      height: `100%`,
      top: 0,
      left: minWidth + COMPONENT.MARGIN,
      minWidth: minWidth * 3,
      minHeight: minHeight,
      minTop: 0,
      minLeft: minWidth + COMPONENT.MARGIN,
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
  }
}

const mapSize22233 = (mapKeys) => {
  const minWidth = COMPONENT.MIN_SIZE.WIDTH
  const minHeight = COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN

  return {
    [mapKeys[0].key]: {
      width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: 0,
      left: 0,
      minWidth: minWidth - COMPONENT.MARGIN,
      minHeight: minHeight,
      minTop: 0,
      minLeft: 0,
      verticalResize: [
        {
          key: mapKeys[1].key,
          ratioWidth: 1,
          ratioLeft: 1,
        },
      ],
      horizontalResize: [
        {
          isResizeWithParent: true,
          key: mapKeys[1].key,
          ratioHeight: -1,
          ratioTop: 0,
        },
        {
          isResizeWithParent: true,
          key: mapKeys[2].key,
          ratioHeight: -1,
          ratioTop: 0,
        },
        {
          key: mapKeys[3].key,
          ratioHeight: 1,
          ratioTop: 1,
        },
        {
          key: mapKeys[4].key,
          ratioHeight: 1,
          ratioTop: 1,
        },
      ],
      disableVerticalResize: false,
      disableHorizontalResize: false,
    },
    [mapKeys[1].key]: {
      width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: 0,
      left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
      minWidth: minWidth - COMPONENT.MARGIN * 2,
      minHeight: minHeight,
      minTop: 0,
      minLeft: minWidth + COMPONENT.MARGIN,
      verticalResize: [
        {
          key: mapKeys[2].key,
          ratioWidth: 1,
          ratioLeft: 1,
        },
      ],
      disableVerticalResize: false,
      disableHorizontalResize: true,
    },
    [mapKeys[2].key]: {
      width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: 0,
      left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
      minWidth: minWidth,
      minHeight: minHeight,
      minTop: 0,
      minLeft: minWidth * 2 + COMPONENT.MARGIN,
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
    [mapKeys[3].key]: {
      width: `calc(50% - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: `calc(50% + ${COMPONENT.MARGIN}px)`,
      left: 0,
      minWidth: minWidth * 3 * 0.5 - COMPONENT.MARGIN,
      minHeight: minHeight,
      minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
      minLeft: 0,
      verticalResize: [
        {
          key: mapKeys[4].key,
          ratioWidth: 1,
          ratioLeft: 1,
        },
      ],
      disableHorizontalResize: true,
    },
    [mapKeys[4].key]: {
      width: `calc(50% - ${COMPONENT.MARGIN}px)`,
      height: `calc(50% - ${COMPONENT.MARGIN}px)`,
      top: `calc(50% + ${COMPONENT.MARGIN}px)`,
      left: `calc(50% + ${COMPONENT.MARGIN}px)`,
      minWidth: minWidth - COMPONENT.MARGIN,
      minHeight: minHeight,
      minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
      minLeft: minWidth * 3 * 0.5 + COMPONENT.MARGIN,
      disableVerticalResize: true,
      disableHorizontalResize: true,
    },
  }
}
