import { I18n } from 'react-redux-i18n'
import { AvgTradingValue } from '.'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'

export const PanelAvgTradingValue = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
}) => {
  const titlePanel = 'market.marginLoans.MARGIN_AVG_TRADING_VALUE'
  const nameScreen = I18n.t('market.marginLoans.MARGIN_LOANS')
  const chartName = I18n.t('market.marginLoans.MARGIN_AVG_TRADING_VALUE')

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={chartName}
      downloadJpgParams={{
        domId: 'avgTradingValue',
        nameScreen,
        chartName,
      }}
    >
      <AvgTradingValue
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
