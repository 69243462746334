import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TOTAL_VALUE_TYPE } from '../constants'
import {
  keys,
  selectFilterTopBid,
  selectLoading,
  selectTopBidData,
} from '../store/slice'
import { downloadTopInterestRateData } from '../store/thunk'
import Filter from './Filter'
import Table from './Table'

const TopBidInterest = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const { TotalValueType } = useSelector(selectFilterTopBid)
  const isLoading = useSelector(selectLoading(keys.TOP_BID_INTEREST))
  const { ids } = useSelector(selectTopBidData)

  const handleExportData = () =>
    downloadTopInterestRateData({
      IsBuy: true,
      TradingType:
        TotalValueType === TOTAL_VALUE_TYPE.TotalMatchValue
          ? 'Match'
          : TotalValueType === TOTAL_VALUE_TYPE.TotalDealValue
          ? 'Deal'
          : '',
    })

  return (
    <Panel
      title="bond.corporateBond.topBidAsk.TITLE_BID"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => handleExportData()}
    >
      <div style={{ height: `calc(100%)` }}>
        <SizeTracker>
          {(size) => (
            <>
              <Filter />
              {size.height && (
                <div
                  style={{
                    height: `calc(100% - ${+size.height}px)`,
                  }}
                >
                  {isLoading ? (
                    <Loading />
                  ) : !!ids?.length ? (
                    <Table />
                  ) : (
                    <NoData />
                  )}
                </div>
              )}
            </>
          )}
        </SizeTracker>
      </div>
    </Panel>
  )
}

TopBidInterest.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default TopBidInterest
