import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertDataTree } from '../../../common/tree/helper'
import { LOADING_KEYS, VIEW_BY_CONDITION_TYPE_CODE } from './constant'
import { selectIndicators, selectLoading, selectViewBy } from './store/slice'
import { getIndicatorThunk } from './store/thunk'

const useGetIndicator = (disabledCallApi = true) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const indicators = useSelector(selectIndicators)
  const loading = useSelector(selectLoading(LOADING_KEYS.FILTER))
  const viewBy = useSelector(selectViewBy)

  const [indicatorsTree, setIndicatorsTree] = useState({})

  useEffect(() => {
    if (!disabledCallApi) {
      dispatch(getIndicatorThunk())
    }
  }, [locale])

  useEffect(() => {
    if (indicators.length > 0) {
      const newIndicators = indicators.filter(
        (indicator) =>
          indicator.conditionType === VIEW_BY_CONDITION_TYPE_CODE[viewBy],
      )
      setIndicatorsTree(
        convertDataTree(newIndicators, 'indicatorId', 'parentIndicatorId'),
      )
    }
  }, [indicators, viewBy])

  return { indicators, indicatorsTree, loading }
}

export default useGetIndicator
