import { useSelector } from 'react-redux'
import { SteelStatistics } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import {
  selectCurrentMonth,
  selectCurrentYear,
  selectIsAcc,
  selectIsYearly,
} from './store/slice'
import { downloadSteelCoilStatistic } from './store/thunk'

export const PanelSteelStatistics = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const currentMonth = useSelector(selectCurrentMonth)
  const currentYear = useSelector(selectCurrentYear)
  const isAcc = useSelector(selectIsAcc)
  const isYearly = useSelector(selectIsYearly)

  return (
    <Panel
      title="sector.sectorSpecific.steel.STATISTICS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() =>
        downloadSteelCoilStatistic({
          Year: currentYear,
          Month: isYearly ? null : currentMonth,
          Accumulated: isAcc,
        })
      }
    >
      <SteelStatistics
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
