import { I18n } from 'react-redux-i18n'
import { getTextHeight, getTextWidth } from '../../../../common/chart/helper'
import { getFontSize } from '../../../../utils/FontSize'
import { valToPercent } from '../../../../utils/Value'

const ReferenceCoupon = ({ ctx, value }) => {
  const calcFontSize = getFontSize(10)

  const newValue = valToPercent(
    value,
    true,
    false,
    2,
    I18n.t('bond.portfolio.overviewOfPortfolioSize.COUPON_UNIT'),
  )

  const labelWidth = getTextWidth(newValue, calcFontSize, 340)
  const labelHeight = getTextHeight(newValue, calcFontSize, 340)

  return (
    <>
      {value && (
        <g>
          <foreignObject
            x={ctx.viewBox.x + ctx.viewBox.width - labelWidth - 16}
            y={ctx.viewBox.y - labelHeight - 8}
            width={labelWidth + 14}
            height={labelHeight + 4}
            color="#ECECEC"
          >
            {newValue}
          </foreignObject>
        </g>
      )}
    </>
  )
}

export default ReferenceCoupon
