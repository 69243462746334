import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Panel } from '../../../../common/panel'
import { SizeTracker } from '../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { valDivBillion } from '../../../../utils/Value'
import { BAR_COLOR_VALUE_CHART, DURATION_YTM_TABS } from '../constants'
import {
  selectDataValueChart,
  selectFilterValueChart,
  selectLoadingValueChart,
} from '../tradingStatistics/store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'

const ValueChartComponent = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoadingValueChart)
  const data = useSelector(selectDataValueChart)
  const { type } = useSelector(selectFilterValueChart)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const formatDataBarChart = useMemo(
    () =>
      data.map((item, index) => ({
        ...item,
        totalValue: valDivBillion(item.totalValue),
        averageYTM: item.averageYTM * 100,
      })),
    [data],
  )

  const renderTickerName = () => {
    const item = DURATION_YTM_TABS.find((item) => item.value === type)
    return item ? I18n.t(item.title) : ''
  }

  const renderTooltip = (dataChart) => <ChartTooltip dataChart={dataChart} />

  return (
    <Panel
      title="bond.corporateBond.secondaryMarket.VALUE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={I18n.t('bond.corporateBond.secondaryMarket.VALUE')}
      downloadJpgParams={{
        domId: 'chartValueData',
        nameScreen: I18n.t(
          'bond.corporateBond.secondaryMarket.TRADE_STATISTICS',
        ),
        chartName: I18n.t('bond.corporateBond.secondaryMarket.VALUE'),
        tickerName: renderTickerName(),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div id="chartValueData" style={{ height: height - size.height }}>
                {isLoading ? (
                  <Loading />
                ) : !!formatDataBarChart.length ? (
                  <>
                    <div style={{ height: height - size.height - 34 }}>
                      <ChartContainer
                        data={formatDataBarChart}
                        width={width}
                        height={height - size.height - 34}
                        keyXAxis="organizationShortName"
                        isUseXAxisDiv
                        showAllTick
                        yAxis={[
                          {
                            id: 'left',
                            keys: ['totalValue'],
                            orientation: 'left',
                            isStackedBar: true,
                            label: I18n.t(
                              'bond.corporateBond.interestAndPrincipalPaid.VALUE',
                            ),
                            labelPosition: AXIS_LABEL_POSITION.LEFT,
                            tickNum: 10,
                          },
                          {
                            id: 'right',
                            keys: [
                              type === 'duration'
                                ? 'averageDuration'
                                : 'averageYTM',
                            ],
                            orientation: 'right',
                            isLineChart: true,
                            label:
                              type === 'duration'
                                ? I18n.t(
                                    'bond.corporateBond.valueChart.DURATION_YEAR',
                                  )
                                : '',
                            labelPosition: AXIS_LABEL_POSITION.RIGHT,
                            tickNum: 10,
                            unitYAxis: type === 'duration' ? '' : '%',
                          },
                        ]}
                        margin={{ left: 0, right: 16, top: 20, bottom: 10 }}
                        renderCustomTooltip={renderTooltip}
                      >
                        {({ chartContentWidth }) => (
                          <>
                            <Bar
                              isAnimationActive={false}
                              yAxisId="left"
                              dataKey="totalValue"
                              fill={BAR_COLOR_VALUE_CHART[0]}
                              barSize={30}
                            />
                            <Scatter
                              yAxisId="right"
                              dataKey={
                                type === 'duration'
                                  ? 'averageDuration'
                                  : 'averageYTM'
                              }
                              isAnimationActive={false}
                              line
                              // stroke={BAR_COLOR_LIQUIDITY_CHART[2]}
                              fill={BAR_COLOR_VALUE_CHART[1]}
                              strokeWidth={1}
                              strokeDasharray="5 5"
                            />
                          </>
                        )}
                      </ChartContainer>
                    </div>
                    <Footer
                      key={width}
                      list={[
                        {
                          text: 'bond.corporateBond.valueChart.VALUE',
                          type: TYPE.SQUARE,
                          before: {
                            bgColor: BAR_COLOR_VALUE_CHART[0],
                          },
                        },
                        {
                          text:
                            type === 'duration'
                              ? 'bond.corporateBond.valueChart.AVERAGE_VALUE'
                              : 'bond.corporateBond.valueChart.AVG_YTM',
                          type: TYPE.DASHED_LINE,
                          dashedLine: {
                            bgColor: BAR_COLOR_VALUE_CHART[1],
                          },
                        },
                      ]}
                    />
                  </>
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default ValueChartComponent
