import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { LIST_RADIO_IMPORT_EXPORT } from './constants'
import {
  changeCurrentMinMonth,
  changeCurrentMinYear,
  changeImportExportDataType,
  changeKeySearch,
  handleIsFirstTime,
  selectImportExportDataType,
  setIsKeepRowsCollapse,
  setLimitQuarter,
  setUnCollapseIds,
} from './exportImportTable/store/slice'
import style from './index.module.css'

const ImportExportNavigation = () => {
  const dispatch = useDispatch()
  const importExportDataType = useSelector(selectImportExportDataType)

  const handleChangeImportExport = (item) => {
    dispatch(changeImportExportDataType(item.value))
    dispatch(handleIsFirstTime(true))
    dispatch(changeCurrentMinMonth(null))
    dispatch(changeCurrentMinYear(null))
    dispatch(setUnCollapseIds([]))
    dispatch(setLimitQuarter(0))
    dispatch(changeKeySearch(''))
    dispatch(setIsKeepRowsCollapse(false))
  }

  return (
    <div className={style.tabLinkContainer}>
      {LIST_RADIO_IMPORT_EXPORT.map((item) => (
        <a
          key={item.value}
          className={`${style.tabLink} ${
            importExportDataType === item.value ? style.tabLinkActive : ''
          }`}
          onClick={() => handleChangeImportExport(item)}
        >
          <Span style={{ fontSize: 12 }}>
            <Translate value={item.title} />
          </Span>
          {importExportDataType === item.value && (
            <div className={style.tabLinkActiveBorderBottom}></div>
          )}
        </a>
      ))}
    </div>
  )
}

export default ImportExportNavigation
