import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import InputFormatCurrency from '../../../../../common/input/InputFormatCurrencyCopy'
import PopupAlert, {
  POPUP_ICON_STATUS,
} from '../../../../../common/popup/PopupAlert'
import {
  selectIdsCompany,
  selectListIdSave,
} from '../../corporateInfo/store/slice'
import style from './index.module.css'
import {
  selectFromPercentage,
  selectToPercentage,
  updateFromPercentage,
  updateToPercentage,
} from './store/slice'

const MIN = 5
const MAX = 100

const OwnershipInput = ({ openPopup }) => {
  const dispatch = useDispatch()
  const [showPopup, setshowPopup] = useState(false)
  const pecentFrom = useSelector(selectFromPercentage)
  const pecentTo = useSelector(selectToPercentage)
  const idCompany = useSelector(selectIdsCompany)
  const idCompnaySave = useSelector(selectListIdSave)

  const changePecentFrom = (value) => {
    if (
      parseInt(value) < MIN ||
      !value ||
      parseInt(value) > parseInt(pecentTo)
    ) {
      setshowPopup(true)
    } else {
      dispatch(updateFromPercentage(value))
    }
  }

  const changePecentTo = (value) => {
    if (
      parseInt(value) > MAX ||
      !value ||
      parseInt(value) < parseInt(pecentFrom)
    ) {
      setshowPopup(true)
    } else {
      dispatch(updateToPercentage(value))
    }
  }

  const onClosePopup = () => {
    setshowPopup(false)
  }

  const checkFilter = () => {
    if (idCompany.length !== idCompnaySave.length) {
      openPopup({ value: true, cb: null })
    }
  }
  return (
    <>
      <div className="mb-8" style={{ marginTop: '4px' }}>
        <Span style={{ fontSize: 12, fontWeight: 340 }}>
          <Translate value="bond.interconnectionMap.filter.OwnershipRate.title" />
        </Span>
      </div>
      <div className="d-flex align-center mb-8">
        <div
          className="form-control mb-0"
          style={{ marginRight: '4px' }}
          onClick={() => checkFilter()}
        >
          <InputFormatCurrency
            defaultValue={pecentFrom}
            handleBlur={(value) => changePecentFrom(value)}
            className={style.inputFormatCurrency}
            decimalRender={0}
          />
        </div>
        <span>%</span>
        <span className="ml-8 mr-8">-</span>
        <div
          className="form-control mb-0"
          style={{ marginRight: '4px' }}
          onClick={() => checkFilter()}
        >
          <InputFormatCurrency
            defaultValue={pecentTo}
            handleBlur={(value) => changePecentTo(value)}
            className={style.inputFormatCurrency}
            decimalRender={0}
          />
        </div>
        <span>%</span>
      </div>
      <PopupAlert
        message={I18n.t('bond.interconnectionMap.common.alertPopup')}
        isShow={showPopup}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => onClosePopup()}
      />
    </>
  )
}

export default OwnershipInput
