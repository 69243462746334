import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../html/Span'
import DropdownTooltip from './DropdownTooltip'
import style from './textEllipsisStaticLine.module.css'

export const TextEllipsisStaticLine = ({
  val,
  isI18n,
  appendStyle,
  lineClamp,
}) => {
  const ellipsisRef = useRef()

  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const onMouseEnter = () => {
    const isConditionOverflowActive = (event) => {
      return (
        event.offsetHeight < event.scrollHeight ||
        event.offsetWidth < event.scrollWidth
      )
    }
    if (isConditionOverflowActive(ellipsisRef.current)) {
      setIsOpenTooltip(true)
    }
  }

  const onMouseLeave = () => {
    setIsOpenTooltip(false)
  }

  return (
    <div
      ref={ellipsisRef}
      className={style.ellipsisStaticLine}
      style={{
        ...appendStyle,
        lineClamp,
        WebkitLineClamp: lineClamp,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isI18n ? (
        <Translate className="canvas-chart-title" value={val || ''} />
      ) : (
        <Span className="canvas-chart-title">{val}</Span>
      )}
      <DropdownTooltip
        containerRef={ellipsisRef}
        text={val}
        isOpenTooltip={isOpenTooltip}
        isI18n={isI18n}
      />
    </div>
  )
}

TextEllipsisStaticLine.propTypes = {
  val: PropTypes.string.isRequired,
  appendStyle: PropTypes.object,
  isI18n: PropTypes.bool,
  lineClamp: PropTypes.number,
}

TextEllipsisStaticLine.defaultProps = {
  appendStyle: {},
  isI18n: true,
  lineClamp: 1,
}
