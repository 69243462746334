import { maxBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import Popup from '../../../common/popup'
import { selectPortfolioBondInformation } from '../store/slice'
import style from './index.module.css'

const PopupSavePortfolio = ({ handleClose, handleSubmit }) => {
  const portfolio = useSelector(selectPortfolioBondInformation)

  const [name, setName] = useState('')
  const [error, setError] = useState('')

  const validate = () => {
    setError('')

    if (!name?.length) {
      setError('bond.portfolio.bondInformation.MESSAGE_PORTFOLIO_1')
      return false
    }

    const isExist = portfolio.every(
      (item) => item?.portfolioName?.trim() !== name.trim(),
    )

    if (!isExist) {
      setError('bond.portfolio.bondInformation.MESSAGE_PORTFOLIO_2')
      return false
    }

    return true
  }

  const isDisableButton = useMemo(
    () => error === 'bond.portfolio.bondInformation.MESSAGE_PORTFOLIO_2',
    [error],
  )

  const onSubmit = () => {
    const check = validate()

    if (!check) return

    handleSubmit(name)
  }

  const onKeyDown = (e) => {
    if (e?.keyCode === 13) {
      e.preventDefault()
      validate()
    }
  }

  useEffect(() => {
    const defaultName = I18n.t('bond.portfolio.bondInformation.PORTFOLIO', {
      number: '',
    }).trim()

    const newPortfolio = portfolio
      .filter((item) => item?.portfolioName?.trim().includes(defaultName))
      .map((item) => ({
        ...item,
        portfolioName: !isNaN(
          parseInt(item?.portfolioName?.slice(defaultName?.length)?.trim()),
        )
          ? parseInt(item?.portfolioName?.slice(defaultName?.length)?.trim())
          : null,
      }))
      .filter((item) => item?.portfolioName && !isNaN(item?.portfolioName))

    const max = maxBy(newPortfolio, (item) => item?.portfolioName)

    if (!newPortfolio?.length || !max) {
      setName(
        I18n.t('bond.portfolio.bondInformation.PORTFOLIO', {
          number: 1,
        }),
      )

      return
    }

    setName(
      I18n.t('bond.portfolio.bondInformation.PORTFOLIO', {
        number: max?.portfolioName + 1,
      }),
    )
  }, [portfolio])

  return (
    <Popup>
      <div className="modal modal-small" style={{ width: 407 }}>
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="bond.portfolio.bondInformation.SAVE_PORTFOLIO_TITLE" />
            </Span>
          </h3>
          <a onClick={handleClose} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content" style={{ paddingBottom: 16 }}>
          <div className="content" style={{ minHeight: 80 }}>
            <div className="d-flex ali-center" style={{ gap: 8 }}>
              <Span>
                <Translate value="bond.portfolio.bondInformation.SAVE_PORTFOLIO_CONTENT" />
              </Span>
              <Input
                style={{ flex: 1 }}
                className={style.input}
                value={name}
                onChange={(e) => {
                  setName(e?.target?.value ?? '')
                }}
                onBlur={validate}
                onKeyDown={onKeyDown}
                maxLength="50"
              />
            </div>
            {!!error?.length && (
              <div style={{ marginTop: 4, textAlign: 'right' }}>
                <Span
                  style={{
                    fontStyle: 'italic',
                    fontWeight: 600,
                    color: '#ff2b66',
                  }}
                >
                  <Translate value={error} />
                </Span>
              </div>
            )}
          </div>
          <div className={style.groupBtn}>
            <div className="d-flex ali-center" style={{ gap: 8 }}>
              <Button
                onClick={handleClose}
                className={`btn normal w-80 h-20`}
                style={{ fontWeight: 600 }}
              >
                <Translate value="common.button.BUTTON_CANCEL" />
              </Button>
              <Button
                onClick={onSubmit}
                className={`btn btn-blue w-80 h-20 `}
                style={{ fontWeight: 600, opacity: isDisableButton ? 0.5 : 1 }}
                disabled={isDisableButton}
              >
                <Translate value="common.button.BUTTON_SAVE" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupSavePortfolio
