import { useDispatch, useSelector } from 'react-redux'
import useComGroup from '../../../../../common/hooks/useComGroup'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { getListTableTab } from '../helper'
import { changeTableType, selectTableType } from '../store/slice'

const TableTab = ({ width }) => {
  const dispatch = useDispatch()

  const tableType = useSelector(selectTableType)
  const basicInfo = useSelector(selectBasicInfo)
  const comGroup = useComGroup(basicInfo?.organizationId)

  const handleChangeTab = (tab) => {
    dispatch(changeTableType(tab.value))
  }
  return (
    <div>
      <DispatchActionTab
        data={getListTableTab(comGroup)}
        activeTab={tableType}
        onChangeTab={handleChangeTab}
        itemStyle={{
          textTransform: 'uppercase',
          fontWeight: 500,
          fontSize: 11,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        widthComponent={width}
      />
    </div>
  )
}

export default TableTab
