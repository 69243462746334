export const corporate = {
  ICB_LEVEL: 'Phân ngành - ICB L',
  YEAR_OF_REVIEW: 'Năm phát hành',
  EXRIGHT_DATE: 'Ngày chốt quyền',
  ISSUE_METHOD: 'Hình thức phát hành',
  STOCK_DIVIDEND: 'Trả cổ tức cổ phiếu',
  MOST_RECENT: 'Gần nhất',
  UNIT: 'Đơn vị',
  ACTION: 'Hành động',
  END_DATE: 'Ngày kết thúc',
  EVENT: 'Sự kiện',
  DATE: 'Ngày',
  SixMonthsAgo: '6 tháng trước',
  OneWeekAgo: '1 tuần trước',
  NineMonthsAgo: '9 tháng trước',
  OneMonthAgo: '1 tháng trước',
  OneYearAgo: '1 năm trước',
  ThreeMonthsAgo: '3 tháng trước',
  TwoYearsAgo: '2 năm trước',
  MostRecent: 'Gần nhất',
  DIVIDEND_YEAR: 'Năm trả cổ tức',
  INTERIM: 'Kỳ trả',
  ALL: 'Tất cả',
  All: 'Tất cả',
  YEAR: 'Năm',
  QUARTER: 'Quý',
  MOST_RECENT_YEAR: 'Năm gần nhất',
  MOST_RECENT_QUARTER: 'Quý gần nhất',
  1: 'Q1',
  2: 'Q2',
  3: 'Q3',
  4: 'Q4',
  6: '6 tháng',
  9: '9 tháng',
  5: 'Hàng năm',
  Consolidated: 'Hợp nhất',
  Unconsolidated: 'Công ty mẹ',
  TITLE: 'DỮ LIỆU DOANH NGHIỆP',
  TICKER: 'Bộ lọc',
  RESULTS: 'KẾT QUẢ',
  NUMBER_OF_RESULT: 'Số lượng kết quả',
  TICKER_TABLE: 'MÃ',
  COMPANY: 'TÊN CÔNG TY',
  EXCHANGE: 'SÀN',
  SETTING: 'Cài đặt',
  REMOVE_ONLY: 'Xoá cột',
  REMOVE_GROUP: 'Xoá nhóm cột',
  EXIST_TICKER: 'Cổ phiếu đã tồn tại.',
  MAX_SHEET_1: 'Không thể tạo hơn 10 trang tính.',
  MAX_SHEET_2: 'Hãy thử lại!',
  MAX_TICKER_1: 'Chỉ có thể lưu tối đa 4000 mã chứng khoán vào bảng dữ liệu.',
  MAX_TICKER_2: 'Hãy thử lại!',
  MAX_TEMPLATE_1: 'Chỉ có thể lưu tối đa 100 biểu mẫu cá nhân.',
  MAX_TEMPLATE_2: 'Hãy thử lại!',
  YEARLY_RATIO: 'Chỉ số năm',
  TTM_RATIO: 'Chỉ số TTM',
  Both: 'Đã kiểm toán và chưa kiểm toán',
  Audited: 'Đã kiểm toán',
  Unaudited: 'Chưa kiểm toán',
}
