import FundNavDataProxy from '../../proxies/fund/FundNavDataProxy'
import { ServiceBase } from '../ServiceBase'

export class FundNavDataService extends ServiceBase {
  getListNAVCertificate(params) {
    return this.applyMemoryCache(
      'FundNavDataProxy.getListNAVCertificate',
      params,
    )(() => FundNavDataProxy.getListNAVCertificate(params))
  }

  getListFundFlow(params) {
    return this.applyMemoryCache(
      'FundNavDataProxy.getListFundFlow',
      params,
    )(() => FundNavDataProxy.getListFundFlow(params))
  }

  getNAVData(params) {
    return this.applyMemoryCache(
      'FundNavDataProxy.getNAVData',
      params,
    )(() => FundNavDataProxy.getNAVData(params))
  }

  downloadNAVDataExport(params) {
    return this.getFileDownload(() =>
      FundNavDataProxy.downloadNAVDataExport(params),
    )
  }
}

export default new FundNavDataService()
