import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { useGetNameSecuritiesIndicator } from '../../chart/common/useGetNameSecuritiesIndicator'
import { VIEW_TYPE } from '../../chart/constants'
import { useSettingChartData } from '../../chart/settingChart/useSettingChartData'
import FinancialChartTable from '../../common/table'
import {
  selectDataChartBySecurities,
  selectDataChartByTime,
  selectDataChartEquation,
} from '../chartInPanel/store/slice'

import {
  formatChartSchemaBySecurities,
  formatChartSchemaByTime,
  formatData,
} from '../helpers'
import { getChartTimeRange } from '../helpers/chartHelpers'
import TimeRange from '../timeRange'
import ChartComponent from './ChartComponent'
import style from './style.module.css'

const ContentChart = forwardRef(
  (
    { dimensions, dimensionsWrapperChart, dataChartPreview, isShowTable },
    ref,
  ) => {
    const timeZone = UseTimeZone()

    const { getNameSecuritiesIndicator, getIndicator } =
      useGetNameSecuritiesIndicator()

    const width = Math.min(dimensions.width, dimensionsWrapperChart.width)
    const height = Math.min(dimensions.height, dimensionsWrapperChart.height)
    const detailChart =
      typeof dataChartPreview?.detail === 'string'
        ? JSON.parse(dataChartPreview?.detail)
        : dataChartPreview?.detail

    // Use selector
    const locale = useSelector((state) => state.i18n.locale)
    const dataChartBySecurities = useSelector(
      selectDataChartBySecurities(dataChartPreview?.id),
    )
    const dataChartByTime = useSelector(
      selectDataChartByTime(dataChartPreview?.id),
    )
    const dataChartEquation = useSelector(
      selectDataChartEquation(dataChartPreview?.id),
    )

    // Use state
    const [schema, setSchema] = useState()
    const [footerData, setFooterData] = useState([])
    const [timeRange, changeTimeRange] = useState(detailChart.timeRange)
    const [timeRangeDate, changeTimeRangeDate] = useState(
      detailChart.timeRangeDate,
    )

    // Get data
    const { chartData, tableData, fullDataByTimeDataKey } = useSettingChartData(
      {
        chartSettings: { ...detailChart, timeRange, timeRangeDate },
        dataChartByTime,
        dataChartBySecurities,
        dataChartEquation,
      },
    )

    const getData = useMemo(() => {
      if (!chartData.length || !schema || !schema.charts) return []
      return formatData(
        chartData,
        fullDataByTimeDataKey,
        schema.charts,
        detailChart.viewType,
        detailChart.timeFrequency,
        timeZone,
      )
    }, [JSON.stringify(schema?.charts), chartData, timeZone])

    const getTimeRangeHeight = useMemo(() => {
      return detailChart.viewType === VIEW_TYPE.BY_TIME ? 51 : 16
    }, [detailChart.viewType])

    // Use effect
    useEffect(() => {
      if (Object.keys(dataChartPreview).length) {
        if (detailChart.viewType === VIEW_TYPE.BY_SECURITIES) {
          const { chartSchema, chartFooter } = formatChartSchemaBySecurities({
            treeSecuritiesIndicator: detailChart.treeSecuritiesIndicator,
            equations: detailChart.equations,
            settings: detailChart.settings,
            getNameSecuritiesIndicator,
            listLegendCustom: detailChart.listLegendCustom,
            listYAxisCustom: detailChart.listYAxisCustom,
            getIndicator,
            locale,
          })
          setFooterData(chartFooter)
          setSchema(chartSchema)
        } else {
          const { chartSchema, chartFooter } = formatChartSchemaByTime({
            treeSecuritiesIndicator: detailChart.treeSecuritiesIndicator,
            equations: detailChart.equations,
            settings: detailChart.settings,
            getNameSecuritiesIndicator,
            listLegendCustom: detailChart.listLegendCustom,
            listYAxisCustom: detailChart.listYAxisCustom,
            getIndicator,
            data: getData,
            timeZone,
            timeRange: getChartTimeRange(timeRange, timeRangeDate),
            timeFrequency: detailChart.timeFrequency,
            locale,
          })
          setFooterData(chartFooter)
          setSchema(chartSchema)
        }
      }
    }, [getIndicator, getData, timeZone, locale])

    return (
      <div className={style.content}>
        <div
          style={{
            height: getTimeRangeHeight,
            justifyContent: 'end',
          }}
          className="align-center"
        >
          {detailChart.viewType === VIEW_TYPE.BY_TIME && (
            <TimeRange
              timeRange={timeRange}
              changeTimeRange={changeTimeRange}
              timeRangeDate={timeRangeDate}
              changeTimeRangeDate={changeTimeRangeDate}
            />
          )}
        </div>
        <div
          ref={ref}
          style={{
            height: `calc(100% - ${getTimeRangeHeight}px)`,
            alignItems: isShowTable ? 'unset' : 'center',
          }}
          className={style.chart}
        >
          <div
            id={`previewChart${dataChartPreview.id}`}
            style={{
              backgroundColor: '#FFF',
              width: width,
              height: isShowTable
                ? ((dimensionsWrapperChart.height - getTimeRangeHeight) * 2) / 3
                : height,
            }}
          >
            <ChartComponent
              width={width}
              height={
                isShowTable
                  ? ((dimensionsWrapperChart.height - getTimeRangeHeight) * 2) /
                    3
                  : height
              }
              data={getData}
              schema={schema}
              footerData={footerData}
              detailChart={detailChart}
            />
          </div>
          {isShowTable && (
            <div
              className="w-100"
              style={{
                height:
                  (dimensionsWrapperChart.height - getTimeRangeHeight) / 3,
              }}
            >
              <div className={style.contentTable}>
                <FinancialChartTable
                  data={tableData}
                  fullDataByTimeDataKey={fullDataByTimeDataKey}
                  schema={schema}
                  viewType={detailChart.viewType}
                  treeSecuritiesIndicator={detailChart?.treeSecuritiesIndicator}
                  equations={detailChart?.equations}
                  listLegendCustom={detailChart?.listLegendCustom}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  },
)

export default ContentChart
