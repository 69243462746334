export const TABLE_HEADER_COL_ATTR = [
  [
    {
      name: 'TENDER_DAY',
      value: ['economy.monetary.monetary.TENDER_DAY'],
      attrs: {
        style: {
          textAlign: 'left',
          width: '20%',
          position: 'sticky',
          zIndex: 3,
          top: 0,
          left: 0,
          backgroundColor: '#273040',
        },
      },
      isFixedBorder: true,
    },
    {
      name: 'PARTICIPANTS_WINNERS',
      value: ['economy.monetary.monetary.PARTICIPANTS_WINNERS'],
      attrs: {
        style: {
          textAlign: 'right',
          width: '20%',
          zIndex: 2,
          position: 'sticky',
        },
      },
    },
    {
      name: 'AMOUNT_WINNING_BIDS',
      value: ['economy.monetary.monetary.AMOUNT_WINNING_BIDS'],
      additionText: 'economy.monetary.monetary.BILLION_VND',
      attrs: {
        style: { textAlign: 'right', width: '20%' },
      },
    },
    {
      name: 'INTEREST_RATE',
      value: ['economy.monetary.monetary.INTEREST_RATE'],
      additionText: 'economy.monetary.monetary.PERCENT_YEAR',
      attrs: {
        style: { textAlign: 'right', width: '20%' },
      },
    },
    {
      name: 'MATURITIES',
      value: ['economy.monetary.monetary.MATURITIES'],
      additionText: 'economy.monetary.monetary.DAY',
      attrs: {
        style: { textAlign: 'right', width: '20%' },
      },
    },
  ],
]

export const GROUP_COLUMNS = [
  'auctionDateId',
  'participantWinner',
  'winningVolume',
  'value',
  'durationName',
]
