import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import {
  changeLoadingSearchBond,
  selectFilterSearchBondAnhUserBond,
} from '../../bond/portfolio/store/slice'
import {
  fetchBondAndUserBondData,
  getBondAndUserBondData,
} from '../../bond/portfolio/store/thunk'
import { HandleClickOutside } from '../../common/HandleClickOutside'
import { ScrollComponent } from '../../common/ScrollComponent'
import Dropdown from '../../common/dropdown'
import { Input } from '../../common/html/Input'
import { Span } from '../../common/html/Span'
import { Loading } from '../../common/loading'
import { Z_INDEX } from '../../constants/Common'
import style from '../index.module.css'
import {
  changeTickerIds,
  changeTickersById,
  selectTickerIds,
  selectTickersById,
} from '../store/slice'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

const FilterTaxCode = () => {
  const dispatch = useDispatch()

  const inputRef = useRef()
  const exceptOusideRef = useRef()

  const locale = useSelector((state) => state.i18n.locale)
  const { loading } = useSelector(selectFilterSearchBondAnhUserBond)

  const [valueSearch, setValueSearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [eventPressKey, setEventPressKey] = useState()
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)

  useEffect(() => {
    if (isShowDropdown) {
      dispatch(changeLoadingSearchBond(true))

      const timeout = setTimeout(() => {
        const payload = {
          Language: locale,
          Page: 1,
          PageSize: 10,
        }

        if (!!valueSearch.length) {
          payload.Term = valueSearch
        }

        dispatch(getBondAndUserBondData(payload))
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [valueSearch.length, isShowDropdown])

  useEffect(() => {
    if (!loading) {
      setIsLoadingSearch(false)
    }
  }, [loading])

  const handleChangeInput = (e) => {
    setIsLoadingSearch(true)
    setValueSearch(e.target.value)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
    setValueSearch('')
  }

  const handleShow = () => setIsShowDropdown(true)

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  return (
    <div className="filter-box">
      <p className="name">
        <Span style={{ fontSize: 10 }}>
          <Translate value="watchlistBond.watchlistDetail.TITLE_SEARCH_TICKER" />
        </Span>
      </p>
      <div className="form-search-inbox m-0">
        <div className="w-100" ref={exceptOusideRef}>
          <Input
            ref={inputRef}
            onChange={handleChangeInput}
            onFocus={handleShow}
            onKeyDown={handlePressKey}
            type="text"
            value={valueSearch}
            className="search-inbox h-20"
            placeholder={I18n.t('watchlistBond.watchlistDetail.SEARCH_PLH')}
            style={{ fontSize: 10 }}
          />
          <button onClick={() => inputRef.current.focus()} type="button">
            <i className="icon-search-2" />
          </button>
        </div>
        <DropdownContainer
          isShow={isShowDropdown}
          parentRef={inputRef}
          handleHide={handleHide}
          inputRef={inputRef}
          exceptOusideRef={exceptOusideRef}
          eventPressKey={eventPressKey}
          setValueSearch={setValueSearch}
          valueSearch={valueSearch}
          isLoadingSearch={isLoadingSearch}
        />
      </div>
    </div>
  )
}

const DropdownContainer = ({
  isShow,
  parentRef,
  handleHide,
  inputRef,
  exceptOusideRef,
  setValueSearch,
  valueSearch,
  eventPressKey,
  isLoadingSearch,
}) => {
  const dispatch = useDispatch()

  const tickerIds = useSelector(selectTickerIds)
  const tickersById = useSelector(selectTickersById)
  const locale = useSelector((state) => state.i18n.locale)

  const { loading, data, page, pageSize, enableInfinity } = useSelector(
    selectFilterSearchBondAnhUserBond,
  )

  const [companiesHandled, setCompaniesHandled] = useState([])
  const [activeIndex, setActiveIndex] = useState(null)

  const handleAddTicker = (ticker) => {
    const newTickersById = { ...tickersById }
    newTickersById[ticker.bondId] = {
      bondOrder: Object.keys(tickersById).length + 1,
      bondCode: ticker.bondTicker,
      ticker: ticker.ticker,
      organizationName: ticker.organizationName,
      isUserBond: false,
      bondId: ticker.bondId,
      organizationId: ticker?.organizationId,
    }
    dispatch(changeTickersById(newTickersById))
    dispatch(changeTickerIds([...tickerIds, ticker.bondId]))
    inputRef.current.focus()

    if (companiesHandled.length === 1) setValueSearch('')
  }

  const handleSearchMore = () => {
    if (!enableInfinity) {
      return
    }

    const payload = {
      Language: locale,
      Page: page,
      PageSize: pageSize,
    }

    if (!!valueSearch.length) {
      payload.Term = valueSearch
    }

    dispatch(fetchBondAndUserBondData(payload))
  }

  useEffect(() => {
    if (!isLoadingSearch) {
      const lengthCompanies = companiesHandled.length
      if (eventPressKey?.keyCode === keyCode.ENTER) {
        if (activeIndex || activeIndex === 0)
          handleAddTicker(companiesHandled[activeIndex])
        else if (companiesHandled[0]) {
          handleAddTicker(companiesHandled[0])
        }
      }
      if (
        eventPressKey?.keyCode === keyCode.DOWN_ARROW &&
        lengthCompanies > 0
      ) {
        if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
        else if (activeIndex < lengthCompanies - 1)
          setActiveIndex(activeIndex + 1)
      }
      if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthCompanies > 0) {
        if (!activeIndex && activeIndex !== 0)
          setActiveIndex(lengthCompanies - 1)
        else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
      }
    }
  }, [eventPressKey])

  useEffect(() => {
    setCompaniesHandled(data.filter((ticker) => !tickersById[ticker.bondId]))
  }, [data, tickersById])

  return (
    <Dropdown isShow={isShow} parentRef={parentRef} zIndex={Z_INDEX.MODAL + 1}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOusideRef}
      >
        <div
          onClick={() => inputRef.current.focus()}
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownFilter}
          id="filter-ticker-scroll"
        >
          {loading ? (
            <Loading />
          ) : companiesHandled.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="watchlistBond.watchlistDetail.NO_RESULT" />
              </Span>
            </div>
          ) : (
            <ScrollComponent
              autoHeight={true}
              autoHeightMax={200}
              scrollToBottomCallback={handleSearchMore}
            >
              {companiesHandled.map((ticker, index) => {
                return (
                  <div
                    key={ticker.bondId}
                    className={`${style.contentFilter} ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onClick={() => handleAddTicker(ticker)}
                  >
                    <CompanyItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter} ${style.pointer}`}>
                      <div className={style.textEllipsis}>
                        {ticker.bondTicker}
                      </div>

                      <div className={style.textEllipsis}>
                        {ticker?.organizationName}
                      </div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

const CompanyItemScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#filter-ticker-scroll .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}

export default FilterTaxCode
