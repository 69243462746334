import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'

const MIN_HEIGHT = 1

export const SizeTracker = ({ children, className, onSetSize }) => {
  const divRef = useRef()
  const { formatWithZoom } = useWindowDevicePixelRatio()
  const [size, setSize] = useState({})

  const calcSize = () => {
    if (divRef.current) {
      const { width, height } = divRef.current.getBoundingClientRect()
      if (size.width !== width || size.height !== height - 2 * MIN_HEIGHT) {
        setSize({ width, height: height - 2 * MIN_HEIGHT })
        onSetSize({ width, height: height - 2 * MIN_HEIGHT })
      }
    }
  }

  useEffect(() => {
    const timeoutSize = setTimeout(() => {
      calcSize()
    }, 0)

    return () => {
      clearTimeout(timeoutSize)
    }
  }, [])

  const existHeight = size.height || size.height === 0
  const existWidth = size.width || size.width === 0

  return (
    <div
      ref={divRef}
      className={className}
      style={{
        height: existHeight ? '100%' : '',
        width: existWidth ? '100%' : '',
      }}
    >
      {!existHeight && <div style={{ height: MIN_HEIGHT }} />}
      {children({
        height: formatWithZoom(size.height) || size.height,
        width: formatWithZoom(size.width) || size.width,
      })}
      {!existHeight && <div style={{ height: MIN_HEIGHT }} />}
    </div>
  )
}

SizeTracker.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  onSetSize: PropTypes.func,
}

SizeTracker.defaultProps = {
  className: '',
  onSetSize: () => {},
}
