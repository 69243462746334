import { I18n } from 'react-redux-i18n'
import { store } from '../../../../app/store'
import { ChannelConfig } from '../../../../configs/Global'
import messageHub from '../../../../core/signalr/SignalrMessageHub'
import {
  config,
  EXCHANGES,
  MAP_EXCHANGES,
  MAX_ITEM_LENGTH,
  PRICE_SCALE,
  supportedResolutions,
  SYMBOL_TYPES,
} from './constants'
import { getSymbolType } from './helper'
import historyProvider, { getPrice, isIndicator } from './historyProvider'

let subscribers = {}

const formatStockSymbol = (item, exchange, type = SYMBOL_TYPES.STOCK) => ({
  exchange,
  type,
  symbol: item.ticker,
  full_name: item.ticker,
  description: item.organizationShortName,
})

const formatFuturesSymbol = (item, exchange, type = SYMBOL_TYPES.FUTURES) => ({
  exchange,
  type,
  symbol: item.ticker,
  full_name: item.ticker,
  description: item.name,
})

const formatIndicesSymbol = (item, exchange, type = SYMBOL_TYPES.INDICES) => ({
  exchange,
  type,
  symbol: item.groupCode,
  full_name: item.groupCode,
  description: item.groupName,
})

const formatEconomySymbol = (item, exchange, type = SYMBOL_TYPES.ECONOMY) => ({
  exchange,
  type,
  symbol: item.code,
  full_name: item.code,
  description: item.name,
})

const formatCoveredWarrantSymbol = (
  item,
  exchange,
  type = SYMBOL_TYPES.COVERED_WARRANT,
) => ({
  exchange,
  type,
  symbol: item.ticker,
  full_name: item.ticker,
  description: item.name,
})

const formatList = ({
  items,
  formatItem,
  userInput,
  exchange,
  searchFields,
}) => {
  if (!Array.isArray(items)) {
    return []
  }
  const prioritizeResult = []
  const result = []
  const [firstField, ...rest] = searchFields

  items.forEach((item) => {
    const hasExchange =
      exchange === EXCHANGES.ALL ||
      (MAP_EXCHANGES[exchange] &&
        MAP_EXCHANGES[exchange].includes(item.exchangeCode))
    const hasPrioritizeField =
      item[firstField] &&
      item[firstField].toLowerCase().includes(userInput.toLowerCase())

    if (hasPrioritizeField && hasExchange) {
      prioritizeResult.push(formatItem(item, exchange))
      return
    }
    const includeChar = rest.some(
      (field) =>
        item[field] &&
        item[field].toLowerCase().includes(userInput.toLowerCase()),
    )
    if (includeChar && hasExchange) {
      return result.push(formatItem(item, exchange))
    }
  })

  return prioritizeResult.concat(result)
}

const getAllSymbols = (userInput, exchange) => {
  const promises = [
    getStockSymbols(userInput, exchange),
    getFuturesSymbols(userInput, exchange),
    getIndicesSymbols(userInput, exchange),
    getEconomySymbols(userInput, exchange),
    getCoveredWarrantSymbols(userInput, exchange),
  ]

  return Promise.all(promises).then(
    ([stocks, futures, indices, economies, coveredWarrants]) => [
      ...stocks,
      ...futures,
      ...indices,
      ...economies,
      ...coveredWarrants,
    ],
  )
}

const getStockSymbols = (userInput, exchange) => {
  const searchFields = ['ticker', 'organizationShortName']
  return Promise.resolve(
    formatList({
      items: store.getState()['common/masterData'].stocksHohaup,
      searchFields,
      userInput,
      exchange,
      formatItem: formatStockSymbol,
    }),
  )
}

const getFuturesSymbols = (userInput, exchange) => {
  const searchFields = ['ticker', 'name']
  return Promise.resolve(
    formatList({
      items: store.getState()['common/tradingView'].futures,
      searchFields,
      userInput,
      exchange,
      formatItem: formatFuturesSymbol,
    }),
  )
}

const getIndicesSymbols = (userInput, exchange) => {
  const searchFields = ['groupCode', 'groupName']
  return Promise.resolve(
    formatList({
      items: store.getState()['common/masterData'].indices,
      userInput,
      exchange,
      searchFields,
      formatItem: formatIndicesSymbol,
    }),
  )
}

const getEconomySymbols = (userInput, exchange) => {
  const searchFields = ['code', 'name']
  return Promise.resolve(
    formatList({
      items: store.getState()['common/tradingView'].economies,
      userInput,
      exchange,
      searchFields,
      formatItem: formatEconomySymbol,
    }),
  )
}

const getCoveredWarrantSymbols = (userInput, exchange) => {
  const searchFields = ['ticker', 'name']
  return Promise.resolve(
    formatList({
      items: store.getState()['common/tradingView'].coveredWarrants,
      userInput,
      exchange,
      searchFields,
      formatItem: formatCoveredWarrantSymbol,
    }),
  )
}

const isCreateNewBar = (resolution, ticker, symbolInfo, subscriberUID) => {
  const tradingDate = new Date(ticker.tradingDate)
  let minutes = resolution
  if (resolution.includes('D')) {
    minutes = 1440
  }
  if (resolution.includes('W')) {
    minutes = 10080
  }

  return (
    subscribers[subscriberUID] &&
    tradingDate.getTime() >
      subscribers[subscriberUID].lastBar.time + minutes * 60000
  )
}

const isIntraday = (resolution) => {
  return !resolution.includes('D') || !resolution.includes('W')
}

const onReceiveTickers = (
  tickers,
  symbolInfo,
  resolution,
  onRealtimeCallback,
  onResetCacheNeededCallback,
  subscriberUID,
) => {
  const ticker = tickers.find((t) => t.ticker === symbolInfo.ticker)

  if (!ticker || !subscribers[subscriberUID]) {
    return
  }

  const matchPrice = +ticker.matchPrice
  const matchVolume = +ticker.matchVolume
  const totalMatchVolume = +ticker.totalMatchVolume

  if (isCreateNewBar(resolution, ticker, symbolInfo, subscriberUID)) {
    subscribers[subscriberUID].lastBar = {
      time: new Date(ticker.tradingDate).getTime(),
      low: getPrice(symbolInfo, matchPrice),
      high: getPrice(symbolInfo, matchPrice),
      open: getPrice(symbolInfo, matchPrice),
      close: getPrice(symbolInfo, matchPrice),
      volume: matchVolume,
    }
  } else {
    const volume = isIntraday(resolution)
      ? subscribers[subscriberUID].lastBar.volume + matchVolume
      : totalMatchVolume
    subscribers[subscriberUID].lastBar = {
      ...subscribers[subscriberUID].lastBar,
      volume,
      low: Math.min(
        getPrice(symbolInfo, matchPrice),
        subscribers[subscriberUID].lastBar.low,
      ),
      high: Math.max(
        getPrice(symbolInfo, matchPrice),
        subscribers[subscriberUID].lastBar.high,
      ),
      close: getPrice(symbolInfo, matchPrice),
    }
  }
  onRealtimeCallback(subscribers[subscriberUID].lastBar)
}

const onReceiveIndex = (
  data,
  symbolInfo,
  resolution,
  onRealtimeCallback,
  onResetCacheNeededCallback,
  subscriberUID,
) => {
  const index = data.find(
    (item) => item.index.toLowerCase() === symbolInfo.ticker.toLowerCase(),
  )

  if (!index || !subscribers[subscriberUID]) {
    return
  }

  const indexValue = +index.indexValue
  const matchVolume = +index.matchVolume
  const totalMatchVolume = +index.totalMatchVolume

  if (isCreateNewBar(resolution, index, symbolInfo, subscriberUID)) {
    subscribers[subscriberUID].lastBar = {
      time: new Date(index.tradingDate).getTime(),
      low: indexValue,
      high: indexValue,
      open: indexValue,
      close: indexValue,
      volume: matchVolume,
    }
  } else {
    const volume = isIntraday(resolution)
      ? subscribers[subscriberUID].lastBar.volume + matchVolume
      : totalMatchVolume
    subscribers[subscriberUID].lastBar = {
      ...subscribers[subscriberUID].lastBar,
      volume,
      low: Math.min(indexValue, subscribers[subscriberUID].lastBar.low),
      high: Math.max(
        getPrice(symbolInfo, indexValue),
        subscribers[subscriberUID].lastBar.high,
      ),
      close: getPrice(symbolInfo, indexValue),
    }
  }
  onRealtimeCallback(subscribers[subscriberUID].lastBar)
}

const onReceiveDerivative = (
  data,
  symbolInfo,
  resolution,
  onRealtimeCallback,
  onResetCacheNeededCallback,
  subscriberUID,
) => {
  const derivative = data.find((item) => item.organCode === symbolInfo.ticker)
  if (!derivative || !subscribers[subscriberUID]) {
    return
  }
  const matchPrice = +derivative.matchPrice
  const matchVolume = +derivative.matchVolume
  const totalMatchVolume = +derivative.totalMatchVolume

  if (isCreateNewBar(resolution, derivative, symbolInfo, subscriberUID)) {
    subscribers[subscriberUID].lastBar = {
      time: new Date(derivative.tradingDate).getTime(),
      low: matchPrice,
      high: matchPrice,
      open: matchPrice,
      close: matchPrice,
      volume: matchPrice,
    }
  } else {
    const volume = isIntraday(resolution)
      ? subscribers[subscriberUID].lastBar.volume + matchVolume
      : totalMatchVolume
    subscribers[subscriberUID].lastBar = {
      ...subscribers[subscriberUID].lastBar,
      volume,
      low: Math.min(matchPrice, subscribers[subscriberUID].lastBar.low),
      high: Math.max(
        getPrice(symbolInfo, matchPrice),
        subscribers[subscriberUID].lastBar.high,
      ),
      close: getPrice(symbolInfo, matchPrice),
    }
  }
  onRealtimeCallback(subscribers[subscriberUID].lastBar)
}

export const getDataFeed = (stockEventType, PriceType, Type, Id) => {
  return {
    onReady: (cb) => {
      const symbolsTypes = [
        { name: I18n.t('common.tradingView.ALL'), value: SYMBOL_TYPES.ALL },
        { name: I18n.t('common.tradingView.STOCK'), value: SYMBOL_TYPES.STOCK },
        {
          name: I18n.t('common.tradingView.FUTURES'),
          value: SYMBOL_TYPES.FUTURES,
        },
        {
          name: I18n.t('common.tradingView.INDICES'),
          value: SYMBOL_TYPES.INDICES,
        },
        {
          name: I18n.t('common.tradingView.ECONOMY'),
          value: SYMBOL_TYPES.ECONOMY,
        },
        {
          name: I18n.t('common.tradingView.COVERED_WARRANT'),
          value: SYMBOL_TYPES.COVERED_WARRANT,
        },
      ]
      setTimeout(() => {
        cb(config(symbolsTypes))
      }, 0)
    },

    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
      const mapFunction = {
        [SYMBOL_TYPES.ALL]: getAllSymbols,
        [SYMBOL_TYPES.STOCK]: getStockSymbols,
        [SYMBOL_TYPES.FUTURES]: getFuturesSymbols,
        [SYMBOL_TYPES.INDICES]: getIndicesSymbols,
        [SYMBOL_TYPES.ECONOMY]: getEconomySymbols,
        [SYMBOL_TYPES.COVERED_WARRANT]: getCoveredWarrantSymbols,
      }
      if (
        mapFunction[symbolType] &&
        typeof mapFunction[symbolType] === 'function'
      ) {
        mapFunction[symbolType](userInput, exchange).then((symbols) => {
          symbols.length = Math.min(MAX_ITEM_LENGTH, symbols.length)
          onResultReadyCallback(symbols)
        })
      }
    },

    resolveSymbol: async (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback,
    ) => {
      const { symbolType, id } = await getSymbolType(symbolName)
      const symbolStub = {
        type: symbolType,
        minmov: 1,
        name: symbolName,
        ticker: symbolName,
        id,
        pricescale: PRICE_SCALE,
        supported_resolution: supportedResolutions,
        has_intraday: symbolType !== SYMBOL_TYPES.ECONOMY,
        session: '0900-1500',
        timezone: 'Asia/Bangkok',
        intraday_multipliers: ['1', '60'],
      }

      setTimeout(() => {
        onSymbolResolvedCallback(symbolStub)
      }, 0)
    },

    getBars: (
      symbolInfo,
      resolution,
      from,
      to,
      onHistoryCallback,
      onErrorCallback,
      firstDataRequest,
    ) => {
      historyProvider
        .getBars(
          symbolInfo,
          resolution,
          from,
          to,
          firstDataRequest,
          PriceType,
          Type,
          Id,
        )
        .then((bars) => {
          if (bars === null) {
            onHistoryCallback([], { noData: true })
            return
          }
          if (firstDataRequest && bars.length && !isIndicator(symbolInfo)) {
            const subscriberUID = `${symbolInfo.ticker}_${resolution}`
            subscribers[subscriberUID] = {
              lastBar: bars[bars.length - 1],
            }
          }
          onHistoryCallback(bars, { noData: false })
        })
        .catch((err) => {
          onErrorCallback(err)
        })
    },

    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
    ) => {
      if (!subscribers[subscriberUID]) {
        return
      }
      subscribers[subscriberUID].symbolInfo = symbolInfo
      if (symbolInfo.type === SYMBOL_TYPES.STOCK) {
        subscribers[subscriberUID].listener = (tickers) =>
          onReceiveTickers(
            tickers,
            symbolInfo,
            resolution,
            onRealtimeCallback,
            onResetCacheNeededCallback,
            subscriberUID,
          )
        messageHub.subscribe(
          ChannelConfig.StockChannel + symbolInfo.id,
          subscribers[subscriberUID].listener,
        )
      }

      if (symbolInfo.type === SYMBOL_TYPES.INDICES) {
        subscribers[subscriberUID].listener = (data) =>
          onReceiveIndex(
            data,
            symbolInfo,
            resolution,
            onRealtimeCallback,
            onResetCacheNeededCallback,
            subscriberUID,
          )
        messageHub.subscribe(
          ChannelConfig.IndexChannel + symbolInfo.id,
          subscribers[subscriberUID].listener,
        )
      }

      if (symbolInfo.type === SYMBOL_TYPES.FUTURES) {
        subscribers[subscriberUID].listener = (data) =>
          onReceiveDerivative(
            data,
            symbolInfo,
            resolution,
            onRealtimeCallback,
            onResetCacheNeededCallback,
            subscriberUID,
          )
        messageHub.subscribe(
          ChannelConfig.DerivativeChannel + symbolInfo.id,
          subscribers[subscriberUID].listener,
        )
      }
    },

    unsubscribeBars: (subscriberUID) => {
      if (!subscribers[subscriberUID]) {
        return
      }
      if (subscribers[subscriberUID].symbolInfo.type === SYMBOL_TYPES.STOCK) {
        messageHub.unsubscribe(
          ChannelConfig.StockChannel,
          subscribers[subscriberUID].listener,
        )
      }

      if (subscribers[subscriberUID].symbolInfo.type === SYMBOL_TYPES.INDICES) {
        messageHub.unsubscribe(
          ChannelConfig.IndexChannel,
          subscribers[subscriberUID].listener,
        )
      }

      if (subscribers[subscriberUID].symbolInfo.type === SYMBOL_TYPES.FUTURES) {
        messageHub.unsubscribe(
          ChannelConfig.DerivativeChannel,
          subscribers[subscriberUID].listener,
        )
      }

      const { [subscriberUID]: _, ...rest } = subscribers
      subscribers = rest
    },

    getMarks: async (
      symbolInfo,
      startDate,
      endDate,
      onDataCallback,
      resolution,
    ) => {
      if (symbolInfo.type !== SYMBOL_TYPES.STOCK) {
        return
      }
      const marks = await historyProvider.getMarks(
        symbolInfo,
        startDate,
        endDate,
        stockEventType,
      )
      onDataCallback(marks)
    },

    getTimescaleMarks: async (
      symbolInfo,
      startDate,
      endDate,
      onDataCallback,
      resolution,
    ) => {
      if (symbolInfo.type !== SYMBOL_TYPES.STOCK) {
        return
      }
      const timescaleMarks = await historyProvider.getTimescaleMarks(
        symbolInfo,
        startDate,
        endDate,
        stockEventType,
      )
      onDataCallback(timescaleMarks)
    },

    calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {},

    getServerTime: (cb) => {},
  }
}
