import { formatVal, valToPercent } from '../../../utils/Value'
import { CRITERIAS } from '../constants'

export const fontSizeFitWidth = ({
  width,
  height,
  max = 32,
  min = 8,
  mainText,
  subText,
  padding = 8,
}) => {
  const div = document.createElement('div')
  const mainTextEl = document.createElement('div')
  mainTextEl.innerText = mainText
  const subTextEl = document.createElement('div')
  subTextEl.innerText = subText
  subTextEl.style.fontSize = '0.75em'
  div.appendChild(mainTextEl)
  div.appendChild(subTextEl)
  div.style.position = 'absolute'
  div.style.left = '9999px'
  div.style.top = '9999px'
  div.style.padding = `${padding}px`
  div.style.whiteSpace = 'nowrap'
  document.body.appendChild(div)
  let fontSize = max
  while (fontSize > min) {
    div.style.fontSize = `${fontSize}px`
    if (width > div.clientWidth && height > div.clientHeight) {
      break
    }

    fontSize *= 0.6
  }
  document.body.removeChild(div)
  return fontSize > min ? fontSize : 0
}

export const setZeroWhenMinimum = (val) => {
  return Math.abs(val) * 10000 < 1 ? 0 : val
}

export const isTooTiny = (width, height) => {
  const MIN_WIDTH = 40
  const MIN_HEIGHT = 40
  return width < MIN_WIDTH || height < MIN_HEIGHT
}

export const getClassName = (mainClass, appendClass, condition) => {
  return condition ? [mainClass, appendClass].join(' ') : mainClass
}

export const getTickerBgColor = (
  { matchPrice, referencePrice, floorPrice, ceilingPrice, displayValue },
  criteria,
) => {
  if (
    [
      CRITERIAS['1_DAY_PERFORMANCE'],
      CRITERIAS['P/B'],
      CRITERIAS['P/E'],
      CRITERIAS.RELATIVE_VOLUME,
    ].includes(criteria)
  ) {
    if (matchPrice === ceilingPrice) {
      return '#8753a6'
    }

    if (matchPrice > referencePrice) {
      return '#3da967'
    }

    if (matchPrice === referencePrice) {
      return '#f3b662'
    }

    if (matchPrice === floorPrice) {
      return '#00bfbe'
    }

    if (matchPrice < referencePrice) {
      return '#e0505b'
    }
  } else {
    if (displayValue > 0) {
      return '#3da967'
    }

    if (displayValue === 0) {
      return '#f3b662'
    }

    return '#e0505b'
  }
}

export const isValToPercent = (criteria) => {
  return ![
    CRITERIAS['P/E'],
    CRITERIAS['P/B'],
    CRITERIAS.RELATIVE_VOLUME,
  ].includes(criteria)
}

export const getDisplayValue = (
  val,
  criteria,
  formatValFunc = (val) => val,
) => {
  return val || val === 0
    ? isValToPercent(criteria)
      ? valToPercent(formatValFunc(val))
      : formatVal(formatValFunc(val))
    : ''
}
