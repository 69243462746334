import PropTypes from 'prop-types'
import { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import { GROWTH_MONEY_SUPPLY_CREDIT_BALANCE } from '.'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import FooterWithScroll from '../../../../common/chart/footer/footerWithScroll'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { CASH_TOTAL_ROW_ID } from '../panelMoneySupplyCredit/OtherCellColumn'
import { handleLineColor } from '../panelMoneySupplyCredit/helper'
import {
  getCheckboxChecked,
  getIds,
  getIndexChecked,
  getLineChartColor,
  getTableMappingLoading,
  handleCheckboxChecked,
  handleIndexChecked,
  handleLineChartColor,
} from '../panelMoneySupplyCredit/store/slice'
import Tooltip from './Tooltip'
import { handleLineChartData, handleTooltipTwoColumn } from './helper'
import style from './index.module.css'
import {
  getActiveLine,
  getLineChartData,
  getLineChartLoading,
  getTimeRange,
  handleActiveLine,
} from './store/slice'

const YAXIS_LEFT = 'left'
const YAXIS_RIGHT = 'right'

const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 1.5,
  strokeActiveWidth: 2.5,
  normalOrHover: 1,
  blur: 0.1,
}

const LineChart = ({
  data,
  width,
  height,
  keyXAxis,
  keyYAxisProp,
  isValue,
  isFullScreen,
}) => {
  const dispatch = useDispatch()
  const legendPanel = useRef()

  const lineChartData = useSelector(getLineChartData)
  const activeLine = useSelector(getActiveLine)
  const lineChartLoading = useSelector(getLineChartLoading)
  const lineChartColor = useSelector(getLineChartColor)
  const ids = useSelector(getIds)
  const timeRange = useSelector(getTimeRange)
  const typeNameLoading = useSelector(getTableMappingLoading)
  const checkboxChecked = useSelector(getCheckboxChecked)
  const indexChecked = useSelector(getIndexChecked)

  const [heightListIndicator, setHeightListIndicator] = useState(0)

  // Get data
  const hasCashTotal = isValue && keyYAxisProp.includes(CASH_TOTAL_ROW_ID)
  const { leftColumn, rightColumn } = handleTooltipTwoColumn(keyYAxisProp, ids)
  const lineChartColorHasAuthor = lineChartColor.filter(
    (item) => item.author !== undefined,
  )
  const keyYAxis = hasCashTotal
    ? [
        {
          id: YAXIS_LEFT,
          keys: keyYAxisProp.filter((item) => item !== CASH_TOTAL_ROW_ID),
          orientation: YAXIS_LEFT,
          isLineChart: true,
          label: I18n.t('economy.monetary.monetary.TRILLION_VND'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          tickNum: SETTINGS.yTickNum,
        },
        {
          id: YAXIS_RIGHT,
          keys: [CASH_TOTAL_ROW_ID],
          orientation: YAXIS_RIGHT,
          isLineChart: true,
          labelPosition: AXIS_LABEL_POSITION.RIGHT,
          tickNum: SETTINGS.yTickNum,
          unitYAxis: '%',
        },
      ]
    : [
        {
          id: YAXIS_LEFT,
          keys: keyYAxisProp,
          orientation: YAXIS_LEFT,
          label: isValue
            ? I18n.t('economy.monetary.monetary.TRILLION_VND')
            : '',
          isLineChart: true,
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          tickNum: SETTINGS.yTickNum,
          unitYAxis: isValue ? '' : '%',
        },
      ]

  const chartData = useMemo(() => {
    if (!typeNameLoading) {
      return handleLineChartData(data, isValue)
    }
    return []
  }, [typeNameLoading, timeRange, lineChartLoading])

  // Actions
  const handleDelete = (item) => {
    const currentIndex = ids.findIndex((val) => val.id === item.author)
    const newIndexChecked = indexChecked.filter((val) => val !== currentIndex)
    const idCheckboxChecked = Object.keys(checkboxChecked)
    const newCheckboxChecked = idCheckboxChecked
      .filter((val) => val !== item.author)
      .reduce((prev, cur) => {
        return {
          ...prev,
          [cur]: checkboxChecked[cur],
        }
      }, {})

    dispatch(handleIndexChecked(newIndexChecked))
    dispatch(handleCheckboxChecked(newCheckboxChecked))
    dispatch(
      handleLineChartColor(
        handleLineColor(item.author, lineChartColor, true, undefined),
      ),
    )
    dispatch(handleActiveLine(undefined))
  }

  const handleHover = (item) => {
    dispatch(handleActiveLine(item.author))
  }

  const handleUnHover = () => {
    dispatch(handleActiveLine(undefined))
  }

  // Render
  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        <div className="d-flex">
          <div className={style.tooltipMaxWidth}>
            {leftColumn.length > 0 &&
              leftColumn.map((item, index) => {
                return (
                  <Tooltip
                    key={index}
                    item={item}
                    payload={payload}
                    isValue={isValue}
                  />
                )
              })}
          </div>
          <div className={`${style.tooltipMaxWidth} ml-8`}>
            {rightColumn.length > 0 &&
              rightColumn.map((item, index) => {
                return (
                  <Tooltip
                    key={index}
                    item={item}
                    payload={payload}
                    isValue={isValue}
                  />
                )
              })}
          </div>
        </div>
      </>
    )
  }

  if (lineChartLoading || typeNameLoading) {
    return <Loading />
  }

  if (!lineChartData.length) {
    return <NoData />
  }

  return (
    <div id={GROWTH_MONEY_SUPPLY_CREDIT_BALANCE}>
      {chartData.length > 0 && (
        <>
          <ChartContainer
            data={chartData}
            height={height - heightListIndicator}
            width={width}
            keyXAxis={keyXAxis}
            yAxis={keyYAxis}
            renderCustomTooltip={renderTooltip}
            margin={{
              ...MARGIN_RECHARTS,
              left: isValue ? 0 : 12,
              right: hasCashTotal ? 0 : 36,
            }}
            timeFrame={TIME_RANGES.CUSTOM}
            isNotFormatXAxis
          >
            {lineChartColorHasAuthor.map((item, index) => {
              return (
                <Line
                  key={index}
                  isAnimationActive={false}
                  yAxisId={
                    item.author === CASH_TOTAL_ROW_ID && hasCashTotal
                      ? YAXIS_RIGHT
                      : YAXIS_LEFT
                  }
                  dataKey={item.author}
                  stroke={item.color}
                  strokeWidth={
                    activeLine === item.author
                      ? SETTINGS.strokeActiveWidth
                      : SETTINGS.strokeWidth
                  }
                  dot={false}
                  strokeOpacity={
                    activeLine !== item.author && activeLine
                      ? SETTINGS.blur
                      : SETTINGS.normalOrHover
                  }
                  activeDot={false}
                  connectNulls={true}
                />
              )
            })}
          </ChartContainer>
          <FooterWithScroll
            width={width}
            data={lineChartColorHasAuthor}
            idKey="author"
            nameKey="indicatorName"
            color={(item) => item.color}
            legendPanel={legendPanel}
            isFullScreen={isFullScreen}
            setHeightFooter={setHeightListIndicator}
            onDelete={handleDelete}
            onHover={handleHover}
            onUnHover={handleUnHover}
          />
        </>
      )}
    </div>
  )
}

LineChart.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  keyXAxis: PropTypes.string.isRequired,
  keyYAxisProp: PropTypes.array.isRequired,
  isValue: PropTypes.bool.isRequired,
}

export default LineChart
