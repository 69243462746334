import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import SelectCustom from '../../../../common/selectCustom'
import { CURRENCY } from '../constants'
import {
  changValuePopupFinancialStatement,
  selectPopupFinancialStatement,
} from '../store/slice'

const DropdownCurrency = () => {
  const dispatch = useDispatch()

  const listSelect1 = [{ name: 'VND', value: 1 }]
  const listSelect2 = [
    {
      name: I18n.t('corporate.companySnapshot.BILLION_VND'),
      value: CURRENCY.BILLION_VND,
    },
    {
      name: I18n.t('corporate.companySnapshot.MILLION_VND'),
      value: CURRENCY.MILLION_VND,
    },
    { name: I18n.t('corporate.companySnapshot.VND'), value: CURRENCY.VND },
  ]

  const popupFinancialStatement = useSelector(selectPopupFinancialStatement)

  const changeValueSelect = (val) => {
    dispatch(
      changValuePopupFinancialStatement({
        ...popupFinancialStatement,
        currency: val,
      }),
    )
  }

  return (
    <div className="d-flex">
      <div className="mr-8" style={{ width: '40%' }}>
        <SelectCustom
          value={1}
          selectData={listSelect1}
          handleChange={changeValueSelect}
        />
      </div>
      <div style={{ width: '60%' }}>
        <SelectCustom
          value={popupFinancialStatement.currency}
          selectData={listSelect2}
          handleChange={changeValueSelect}
        />
      </div>
    </div>
  )
}

export default DropdownCurrency
