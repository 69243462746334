import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { TAB_LIST } from '../../constants'
import { selectActiveTab } from '../../store/slice'
import style from './index.module.css'

const TabItem = ({
  item,
  handleClickTab,
  setIsShow,
  isShow,
  handleEdit,
  handleDelete,
}) => {
  const dropdownRef = useRef()

  const currentTab = useSelector(selectActiveTab)

  return (
    <a
      className={`${style.tabLink} ${
        currentTab === item.templateId ? style.tabLinkActive : ''
      }`}
      onClick={() => handleClickTab(item.templateId, true)}
      ref={dropdownRef}
    >
      <Span style={{ overflow: 'hidden', fontSize: 12 }}>
        <TextEllipsis
          text={item.templateName}
          isI18n={TAB_LIST.includes(item.templateId)}
        />
      </Span>
      {!item.hidden && (
        <>
          <div className={style.iconMore}>
            <i
              className="icon-menu-dot-hor"
              style={{ color: '#ECECEC', fontSize: 14 }}
              onClick={(e) => {
                e.stopPropagation()
                setIsShow?.(item.templateId)
              }}
            />
          </div>
          {isShow === item.templateId && (
            <div className={`${style.dropdown}`}>
              <HandleClickOutside
                handleClickOutside={() => setIsShow?.(null)}
                exceptRef={dropdownRef}
              >
                <>
                  <div
                    className={`${style.dropdownItem} `}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleEdit?.(item)
                    }}
                  >
                    <Span style={{ fontSize: 12 }}>
                      <Translate value="common.button.BUTTON_EDIT_NAME" />
                    </Span>
                    <img src="/iconEdit.svg" alt="Icon Edit" />
                  </div>
                  <div
                    className={`${style.dropdownItem}`}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleDelete?.(item.templateId)
                      setIsShow?.(null)
                    }}
                  >
                    <Span style={{ fontSize: 12 }}>
                      <Translate value="common.button.BUTTON_DELETE" />
                    </Span>
                    <img src="/iconDelete.svg" alt="Icon Delete" />
                  </div>
                </>
              </HandleClickOutside>
            </div>
          )}
        </>
      )}
      {currentTab === item.templateId && (
        <div className={style.tabLinkActiveBorderBottom}></div>
      )}
    </a>
  )
}

TabItem.propTypes = {
  item: PropTypes.object.isRequired,
  handleClickTab: PropTypes.func.isRequired,
  setIsShow: PropTypes.func,
  isShow: PropTypes.any,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
}

export default TabItem
