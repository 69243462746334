import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { idContainerTable, idScroll, idTableAbsolute } from '.'
import { debounce, range } from '../../../utils/Common'
import EventEmitter from '../../../utils/EventEmitter'
import { EMPTY_ROW_TOP_TABLE } from './Tbody'
import { EMPTY_COLUMN_LEFT_TABLE } from './Thead'
import { heightTr, widthColumn } from './constant'
import { handleIndex } from './helper'
import {
  changeDisplayColumn,
  changeDisplayRow,
  changeIsScrolling,
  selectDisplayColumns,
  selectDisplayRows,
} from './store/slice'

let countRowPerView = 25
let countColumnPerView = 8
const defaultHeightHeader = 56
const durationHandleIsScrolling = 500
export const RESET_ROW = 'RESET_ROW'
export const ADD_ROW = 'ADD_ROW'
export const RESET_COLUMN = 'RESET_COLUMN'
export const TIMEOUT_SCROLL = 200

const ListenerScrollTable = ({
  selectFullColumnId,
  selectIdDisplay,
  heightPerView,
  widthPerView,
}) => {
  const dispatch = useDispatch()

  const fullColumnId = useSelector(selectFullColumnId)
  const displayRowId = useSelector(selectIdDisplay)
  const displayColumns = useSelector(selectDisplayColumns)
  const displayRows = useSelector(selectDisplayRows)

  const currentLengthColumn = useRef(0)
  const currentLengthRow = useRef(0)
  const firstColumn = useRef(0)
  const firstRow = useRef(0)
  const currentScrollTop = useRef(0)
  const currentScrollLeft = useRef(0)

  const widthColumnInfo = useRef(0)
  const heightHeader = useRef(defaultHeightHeader)

  useEffect(() => {
    // first index display
    firstColumn.current = +Object.keys(displayColumns)[0]
  }, [displayColumns])

  useEffect(() => {
    // first row display
    firstRow.current = +Object.keys(displayRows)[0]
  }, [displayRows])

  useEffect(() => {
    // set default width column info
    const elFitContentTable = document.querySelector(
      `#${idContainerTable} > div > div`,
    )
    const newWidthColumnInfo =
      elFitContentTable?.offsetWidth - EMPTY_COLUMN_LEFT_TABLE * widthColumn

    widthColumnInfo.current = newWidthColumnInfo

    // set default number of column per view, default object column display
    if (widthPerView) {
      countColumnPerView = widthPerView
    } else {
      countColumnPerView = Math.ceil(window.screen.width / widthColumn)
    }

    const displayColumn = {}
    range(0, countColumnPerView - 1).forEach(
      (index) => (displayColumn[index] = true),
    )
    dispatch(changeDisplayColumn(displayColumn))

    // set default number of row per view, default object row display
    if (heightPerView) {
      countRowPerView = Math.ceil(heightPerView - heightTr + 10) // fix render full data when scroll
    } else {
      countRowPerView = Math.ceil(window.screen.height / heightTr + 10) // + 10 to fix header move when scroll
    }

    const displayRow = {}
    range(0, countRowPerView - 1).forEach((index) => (displayRow[index] = true))
    dispatch(changeDisplayRow(displayRow))
  }, [heightPerView, widthPerView])

  useEffect(() => {
    // set height th sticky
    const elTh = document.querySelector(`#${idTableAbsolute} th`)
    heightHeader.current = elTh?.offsetHeight || defaultHeightHeader
  }, [displayColumns, fullColumnId])

  useEffect(() => {
    // set default top absolute table
    if (!displayRowId.length) {
      const elTableAbsolute = document.querySelector(`#${idTableAbsolute}`)
      elTableAbsolute.style.top = -(EMPTY_ROW_TOP_TABLE * heightTr) + 'px'
    }
  }, [displayRowId])

  useEffect(() => {
    const elTableAbsolute = document.querySelector(`#${idTableAbsolute}`)
    elTableAbsolute.style.top = -(EMPTY_ROW_TOP_TABLE * heightTr) + 'px'
  }, [])

  useEffect(() => {
    // set default left absolute table
    if (!fullColumnId.length) {
      const elTableAbsolute = document.querySelector(`#${idTableAbsolute}`)
      elTableAbsolute.style.left =
        -(EMPTY_COLUMN_LEFT_TABLE * widthColumn) + 'px'
    }
  }, [fullColumnId])

  useEffect(() => {
    const elTableAbsolute = document.querySelector(`#${idTableAbsolute}`)
    elTableAbsolute.style.left = -(EMPTY_COLUMN_LEFT_TABLE * widthColumn) + 'px'
  }, [])

  useEffect(() => {
    // check if delete column
    if (fullColumnId.length < currentLengthColumn.current) {
      const elScroll = document.querySelector(
        `#${idScroll} .scrollbars div:first-child`,
      )
      const elTableAbsolute = document.querySelector(`#${idTableAbsolute}`)
      const countColumnRemove =
        currentLengthColumn.current - fullColumnId.length

      // decrease scroll left table absolute
      elTableAbsolute.style.left =
        Math.min(
          Math.max(
            elScroll.scrollLeft -
              (elScroll.scrollLeft % widthColumn) -
              countColumnRemove * widthColumn,
            0,
          ),
          Math.max(
            elScroll.scrollWidth -
              countColumnPerView * widthColumn -
              widthColumnInfo.current,
            0,
          ),
        ) -
        EMPTY_COLUMN_LEFT_TABLE * widthColumn +
        'px'

      // set object column display
      const firstColumnDisplay = Math.max(
        Math.floor(
          (elScroll.scrollLeft - countColumnRemove * widthColumn) / widthColumn,
        ),
        0,
      )
      const displayColumn = {}
      handleIndex(
        [firstColumnDisplay, firstColumnDisplay + countColumnPerView - 1],
        fullColumnId.length,
        countColumnPerView,
      ).forEach((index) => (displayColumn[index] = true))
      dispatch(changeDisplayColumn(displayColumn))
    }

    // set width table relative
    if (widthColumnInfo.current) {
      document.querySelector(`#${idContainerTable}`).style.width =
        widthColumnInfo.current + fullColumnId.length * widthColumn + 'px'
    }

    currentLengthColumn.current = fullColumnId.length
  }, [fullColumnId])

  useEffect(() => {
    // check if delete row
    if (displayRowId.length < currentLengthRow.current) {
      const elScroll = document.querySelector(
        `#${idScroll} .scrollbars div:first-child`,
      )
      const elTableAbsolute = document.querySelector(`#${idTableAbsolute}`)
      const countColumnRemove = currentLengthRow.current - displayRowId.length

      // decrease scroll top table absolute
      elTableAbsolute.style.top =
        Math.min(
          Math.max(
            elScroll.scrollTop -
              (elScroll.scrollTop % heightTr) -
              countColumnRemove * heightTr,
            0,
          ),
          Math.max(
            elScroll.scrollHeight -
              countRowPerView * heightTr -
              heightHeader.current,
            0,
          ),
        ) -
        EMPTY_ROW_TOP_TABLE * heightTr +
        'px'

      // set object row display
      const firstRowDisplay = Math.max(
        Math.floor(
          (elScroll.scrollTop - countColumnRemove * heightTr) / heightTr,
        ),
        0,
      )
      if (firstRowDisplay !== firstRow.current) {
        const displayRow = {}
        handleIndex(
          [firstRowDisplay, firstRowDisplay + countRowPerView - 1],
          displayRowId.length,
          countRowPerView,
        ).forEach((index) => (displayRow[index] = true))
        dispatch(changeDisplayRow(displayRow))
      }
    }

    // set height table relative
    if (heightHeader.current) {
      if (heightPerView) {
        document.querySelector(`#${idContainerTable}`).style.height =
          heightHeader.current + (displayRowId.length - 1) * heightTr + 'px'
      } else {
        document.querySelector(`#${idContainerTable}`).style.height =
          heightHeader.current + displayRowId.length * heightTr + 'px'
      }
    }

    currentLengthRow.current = displayRowId.length
  }, [displayRowId, heightPerView])

  useEffect(() => {
    const elScroll = document.querySelector(
      `#${idScroll} .scrollbars div:first-child`,
    )
    const elTableAbsolute = document.querySelector(`#${idTableAbsolute}`)

    const eventScroll = () => {
      // check if is scroll horizontal
      if (currentScrollLeft.current !== elScroll.scrollLeft) {
        if (
          Math.abs(elScroll.scrollLeft - currentScrollLeft.current) >
          durationHandleIsScrolling
        ) {
          dispatch(changeIsScrolling(true))
        }

        currentScrollLeft.current = elScroll.scrollLeft

        // set left table absolute
        elTableAbsolute.style.left =
          Math.min(
            elScroll.scrollLeft - (elScroll.scrollLeft % widthColumn),
            Math.max(
              elScroll.scrollWidth -
                countColumnPerView * widthColumn -
                widthColumnInfo.current,
              0,
            ),
          ) -
          EMPTY_COLUMN_LEFT_TABLE * widthColumn +
          'px'

        // set object column display
        const firstColumnDisplay = Math.max(
          Math.floor(elScroll.scrollLeft / widthColumn),
          0,
        )
        if (firstColumnDisplay !== firstColumn.current) {
          const displayColumn = {}
          handleIndex(
            [firstColumnDisplay, firstColumnDisplay + countColumnPerView - 1],
            currentLengthColumn.current,
            countColumnPerView,
          ).forEach((index) => (displayColumn[index] = true))
          dispatch(changeDisplayColumn(displayColumn))
        }

        stopScroll()
      }
    }

    elScroll.addEventListener('scroll', eventScroll)
    return () => elScroll.removeEventListener('scroll', eventScroll)
  }, [])

  useEffect(() => {
    const elScroll = document.querySelector(
      `#${idScroll} .scrollbars div:first-child`,
    )
    const elTableAbsolute = document.querySelector(`#${idTableAbsolute}`)

    const eventScroll = () => {
      // check if is scroll vertical
      if (currentScrollTop.current !== elScroll.scrollTop) {
        if (
          Math.abs(elScroll.scrollTop - currentScrollTop.current) >
          durationHandleIsScrolling
        ) {
          dispatch(changeIsScrolling(true))
        }

        currentScrollTop.current = elScroll.scrollTop

        // set top table absolute

        elTableAbsolute.style.top =
          Math.min(
            elScroll.scrollTop - (elScroll.scrollTop % heightTr),
            Math.max(
              elScroll.scrollHeight -
                countRowPerView * heightTr -
                heightHeader.current,
              0,
            ),
          ) -
          EMPTY_ROW_TOP_TABLE * heightTr +
          'px'

        // set object row display
        const firstRowDisplay = Math.max(
          Math.floor(elScroll.scrollTop / heightTr),
          0,
        )
        if (firstRowDisplay !== firstRow.current) {
          const displayRow = {}
          handleIndex(
            [firstRowDisplay, firstRowDisplay + countRowPerView - 1],
            currentLengthRow.current,
            countRowPerView,
          ).forEach((index) => (displayRow[index] = true))
          dispatch(changeDisplayRow(displayRow))
        }

        stopScroll()
      }
    }

    elScroll.addEventListener('scroll', eventScroll)
    return () => elScroll.removeEventListener('scroll', eventScroll)
  }, [])

  const stopScroll = debounce(() => {
    dispatch(changeIsScrolling(false))
  }, 200)

  const resetColumn = () => {
    const elScroll = document.querySelector(
      `#${idScroll} .scrollbars div:first-child`,
    )
    setTimeout(() => elScroll?.scroll({ left: 0 }), TIMEOUT_SCROLL)
  }

  useEffect(() => {
    EventEmitter.subscribe(RESET_COLUMN, resetColumn)
    return () => EventEmitter.unsubscribe(RESET_COLUMN, resetColumn)
  }, [])

  const resetRow = () => {
    const elScroll = document.querySelector(
      `#${idScroll} .scrollbars div:first-child`,
    )
    setTimeout(() => elScroll?.scroll({ top: 0 }), TIMEOUT_SCROLL)
  }

  const addRow = () => {
    const elScroll = document.querySelector(
      `#${idScroll} .scrollbars div:first-child`,
    )
    setTimeout(
      () => elScroll.scroll({ top: elScroll.scrollHeight }),
      TIMEOUT_SCROLL,
    )
  }

  useEffect(() => {
    EventEmitter.subscribe(RESET_ROW, resetRow)
    return () => EventEmitter.unsubscribe(RESET_ROW, resetRow)
  }, [])

  useEffect(() => {
    EventEmitter.subscribe(ADD_ROW, addRow)
    return () => EventEmitter.unsubscribe(ADD_ROW, addRow)
  }, [])

  return <></>
}

export default ListenerScrollTable
