import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import {
  formatVal,
  valDivBillion,
  valDivTrillion,
  valToPercent,
} from '../../../utils/Value'
import { MULTIPLIER_TEXT, indicatorUpcomingEvent } from '../constants'
import { selectItemData } from '../store/slice'
import {
  EMPTY_CELL_BY_MACRO_ID,
  EMPTY_CELL_BY_PARENT_ID,
  NORMAL_FORMAT_ID,
  PERCENT_FORMAT_ID,
  TRILLION_FORMAT_ID,
} from './config'

const { GROWTH_YOY } = indicatorUpcomingEvent

const OtherColumnsCell = ({ val, rowId, className, style, colId }) => {
  const item = useSelector(selectItemData(rowId))
  const isPercentFormat =
    colId === GROWTH_YOY || PERCENT_FORMAT_ID.includes(item.macroEventId)

  const formatValue = () => {
    const isNumber = typeof val === 'number'
    if (
      EMPTY_CELL_BY_PARENT_ID.includes(item.parentMacroEventTypeId) ||
      EMPTY_CELL_BY_MACRO_ID.includes(item.macroEventTypeId) ||
      (isNumber && !val)
    ) {
      return ''
    }

    if (isPercentFormat) {
      return `${formatVal(val * 100)}${isNumber ? '%' : ''}`
    }

    if (item.multiplier) {
      if (!item.unit) {
        return formatVal(val)
      } else if (item.unit === '%') {
        return valToPercent(val)
      } else {
        return `${formatVal(val / item.multiplier)} ${I18n.t(
          MULTIPLIER_TEXT[item.multiplier],
        )} ${item.unit}`
      }
    }

    if (NORMAL_FORMAT_ID.includes(item.macroEventId)) {
      return `${formatVal(val)}`
    }

    if (TRILLION_FORMAT_ID.includes(item.macroEventId)) {
      return `${formatVal(valDivTrillion(val))}${isNumber ? 'T' : ''}`
    }

    return `${formatVal(valDivBillion(val))}${isNumber ? 'B' : ''}`
  }

  if (item.isCollapse) {
    return <td className={`${className} ${style['row-lv0']}`}>{''}</td>
  } else {
    return (
      <td className={className} style={style}>
        <Span
          style={{
            color:
              typeof val === 'number' && isPercentFormat && val < 0
                ? '#ff4752'
                : '',
            whiteSpace: 'nowrap',
          }}
        >
          {formatValue()}
        </Span>
      </td>
    )
  }
}

OtherColumnsCell.propTypes = {
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  locale: PropTypes.string,
  colId: PropTypes.string.isRequired,
}

export default OtherColumnsCell
