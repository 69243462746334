import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import ReactToPrint from 'react-to-print'
import { Button } from '../../../common/html/Button'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import { FORMAT, formatDateTimeLocal } from '../../../utils/Datetime'
import { selectShowPreview } from '../filter/store/slice'
import style from '../style.module.css'
import {
  changePageNumber,
  changeZoomFile,
  selectNumPages,
  selectPageNumber,
  selectZoomFile,
} from './store/slice'

const regexValue = /^(2[5-9]|[3-9][0-9]|[1-4][0-9]{2}|500)[$%]/ // percent from 25 to 500
const regexValueNumber = /^\d+$/ // integer number
const sizeZoom = [
  25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500,
]

const ToolPreview = ({ scrollbarsRef }) => {
  const dispatch = useDispatch()

  const numPages = useSelector(selectNumPages)
  const pageNumber = useSelector(selectPageNumber)
  const zoomFile = useSelector(selectZoomFile)
  const showPreview = useSelector(selectShowPreview)
  const dataTopInfo = useSelector(selectBasicInfo)

  const [page, setPage] = useState(pageNumber)
  const [zoom, setZoom] = useState(zoomFile)

  const handleChangePage = (page) => {
    let els = document.getElementsByClassName('A4')
    if (pageNumber !== page) {
      if (page > numPages || isNaN(page) || page < 1) {
        setPage(numPages)
      } else {
        dispatch(changePageNumber(page))
        scrollbarsRef?.current?.scrollTop(els[0]?.offsetHeight * (page - 1))
      }
    }
  }

  const handleKeyEnter = (e) => {
    if (e.key === 'Enter') {
      handleChangePage(parseInt(e.target.value))
      e.currentTarget.blur()
    }
  }

  const onChangeZoom = (e) => {
    setZoom(e.target.value)
  }

  const handleChangeZoom = (zoom) => {
    setZoom(zoom)
    dispatch(changeZoomFile(zoom))
  }

  const onBlurZoom = (e) => {
    const val = e.target.value
    if (val.match(regexValue)) {
      dispatch(changeZoomFile(val))
      setZoom(val)
    } else {
      if (parseFloat(val) < 25) {
        handleChangeZoom('25%')
      } else if (parseFloat(val) > 500) {
        handleChangeZoom('500%')
      } else {
        if (val.match(regexValueNumber)) {
          handleChangeZoom(val + '%')
        } else {
          handleChangeZoom(zoomFile)
        }
      }
    }
  }

  const handleKeyEnterZoom = (e) => {
    if (e.key === 'Enter') {
      const val = e.target.value
      if (val.match(regexValue)) {
        dispatch(changeZoomFile(val))
        setZoom(val)
      } else {
        if (parseFloat(val) < 25) {
          handleChangeZoom('25%')
        } else if (parseFloat(val) > 500) {
          handleChangeZoom('500%')
        } else {
          if (val.match(regexValueNumber)) {
            handleChangeZoom(val + '%')
          } else {
            handleChangeZoom(zoomFile)
          }
        }
      }
      e.currentTarget.blur()
    }
  }

  const handleZoomOut = () => {
    if (parseFloat(zoomFile) !== sizeZoom[0]) {
      const result = [...sizeZoom]
        .reverse()
        .find((element) => element < parseFloat(zoomFile))
      handleChangeZoom(result + '%')
    }
  }

  const handleZoomIn = () => {
    if (parseFloat(zoomFile) !== sizeZoom[sizeZoom.length - 1]) {
      const result = sizeZoom.find((element) => element > parseFloat(zoomFile))
      handleChangeZoom(result + '%')
    }
  }

  useEffect(() => {
    setPage(pageNumber)
  }, [pageNumber])

  return (
    <div className={`table-info ${!showPreview ? style.disable : ''}`}>
      <div className="table-info-left">
        <div className="tb-text-info align-center">
          <Span>
            <Translate value={'common.table.LB_PAGE'} />
          </Span>
          <Input
            style={{ fontSize: 12 }}
            type="number"
            value={page || ''}
            className={style.inputValue}
            onChange={(e) => setPage(parseInt(e.target.value))}
            onKeyDown={(e) => handleKeyEnter(e)}
            onBlur={(e) => handleChangePage(parseInt(e.target.value))}
          />
          <Span style={{ whiteSpace: 'nowrap' }}>
            <Translate value={'common.table.LB_OF'} /> {numPages}
          </Span>
          <div className={style.verticalLine}></div>
          <div className={style.btnZoom}>
            <span
              className={style.cursorPointer}
              style={{ fontSize: 17 }}
              onClick={handleZoomOut}
            >
              -
            </span>
          </div>
          <Input
            style={{ fontSize: 12 }}
            type="string"
            value={zoom}
            className={style.inputValue}
            onChange={onChangeZoom}
            onKeyDown={handleKeyEnterZoom}
            onBlur={onBlurZoom}
          />
          <div className={style.btnZoom}>
            <span
              className={style.cursorPointer}
              style={{ fontSize: 17 }}
              onClick={handleZoomIn}
            >
              +
            </span>
          </div>
        </div>
      </div>
      <div className="table-info-right">
        <div className="tb-text-info align-center">
          <ReactToPrint
            trigger={() => (
              <Button
                style={{ fontSize: 11, padding: '0px 8px' }}
                className={`btn btn-blue h-20`}
              >
                <Translate value="corporate.companySnapshot.EXPORT" />
              </Button>
            )}
            content={() => {
              const node = document.getElementById('downloadPDF')
              const clone = node.cloneNode(true)
              clone.style.zoom = '100%'
              return clone
            }}
            copyStyles={true}
            onBeforePrint={() =>
              (document.title = `${I18n.t(
                'corporate.companySnapshot.NAME_FILE_DOWNLOAD',
              )}${
                dataTopInfo.ticker || dataTopInfo.taxCode
              }_${formatDateTimeLocal(new Date(), FORMAT.FILE)}`)
            }
            onAfterPrint={() => (document.title = 'FiinProX')}
          />
        </div>
      </div>
    </div>
  )
}

export default ToolPreview
