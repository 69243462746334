import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { PriceDepth } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { PreOpenMarket } from '../../../../common/preOpenMarket/PreOpenMarket'
import { selectWarrantInfo } from '../../../../common/topInfo/warrantTopInfo/store/slice'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'

export const PanelPriceDepth = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const warrantInfo = useSelector(selectWarrantInfo)

  return (
    <Panel
      title="market.coveredWarrant.PRICE_DEPTH"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      isDownloadJpg
      titleJpg={UseI18n('market.coveredWarrant.PRICE_DEPTH')}
      downloadJpgParams={{
        domId: 'priceDepth',
        nameScreen: UseI18n('market.coveredWarrant.COVERED_WARRANT'),
        chartName: UseI18n('market.coveredWarrant.PRICE_DEPTH'),
        tabName: '',
        tickerName: warrantInfo.ticker,
      }}
    >
      <PreOpenMarket>
        <PriceDepth
          height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        />
      </PreOpenMarket>
    </Panel>
  )
}

PanelPriceDepth.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
