import { useSelector } from 'react-redux'
import { ValueBondPortfoliosChart } from '.'
import UseI18n from '../../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../../common/panel'
import { selectIndexSectorTickerInfo } from '../../../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../../constants/Common'
import { formatNumber } from '../../../../../../utils/Common'
import { selectTimeType } from './store/slice'

export const PanelValueBondPortfolios = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
  dataType,
}) => {
  const currentInfo = useSelector(selectIndexSectorTickerInfo)
  const timeType = useSelector(selectTimeType)

  return (
    <Panel
      title={'sector.financialAnalysis.bank.assetQuality.VALUE_BOND_PORTFOLIOS'}
      panelRefs={panelRefs}
      panelKey={mapKey.VALUE_BOND_PORTFOLIOS}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'valueBondPortfoliosChart',
        nameScreen: UseI18n('sector.financialAnalysis.bank.ASSET_QUALITY'),
        chartName: UseI18n(
          'sector.financialAnalysis.bank.assetQuality.VALUE_BOND_PORTFOLIOS',
        ),
        tabName: timeType,
        tickerName: currentInfo.ticker,
      }}
      titleJpg={UseI18n(
        'sector.financialAnalysis.bank.assetQuality.VALUE_BOND_PORTFOLIOS',
      )}
    >
      <ValueBondPortfoliosChart
        width={formatNumber(
          sizes[mapKey.VALUE_BOND_PORTFOLIOS].width - PANEL_PADDING,
        )}
        height={formatNumber(
          sizes[mapKey.VALUE_BOND_PORTFOLIOS].height - HEADER_PANEL_HEIGHT,
        )}
        dataType={dataType}
      />
    </Panel>
  )
}
