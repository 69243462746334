export const CHART_TYPE_HEIGHT = 20

export const CHART_TYPE = {
  COLUMN: 'COLUMN',
  LINE: 'LINE',
}

export const CHARTS = {
  PE: 'PE',
  PB: 'PB',
  PS: 'PS',
  EVSALE: 'EVSALE',
}
