export const GROWTH_SECTOR_DATA = [
  {
    value: 'OneYear',
    title: '1Y',
  },
  {
    value: 'ThreeYears',
    title: '3Y',
  },
  {
    value: 'FiveYears',
    title: '5Y',
  },
  {
    value: 'TenYears',
    title: '10Y',
  },
  {
    value: 'AllTime',
    title: 'economy.productionAndConsumption.production.ALL_TIME',
  },
]

export const GROWTH_SECTOR_DATA_YEARLY = [
  {
    value: 'FiveYears',
    title: '5Y',
  },
  {
    value: 'SevenYears',
    title: '7Y',
  },
  {
    value: 'TenYears',
    title: '10Y',
  },
  {
    value: 'TwentyYears',
    title: '20Y',
  },
  {
    value: 'AllTime',
    title: 'economy.productionAndConsumption.production.ALL_TIME',
  },
]

export const COLORS_LINE_CHART = [
  '#c7ff58',
  '#9c54e4',
  '#185999',
  '#ecffdf',
  '#f57f07',
  '#c52e14',
  '#975000',
  '#1a36a9',
  '#57426d',
  '#f9c105',
  '#a103fc',
  '#fc6203',
  '#03fca5',
  '#03d7fc',
  '#fc03f4',
  '#90fc03',
  '#628200',
  '#3452eb',
  '#eb3462',
  '#9b8e9e',
]

export const ID_DOM_PANEL_PRODUCTION = {
  FIRST_CHART: 'economy_production_firstChart',
  SECOND_CHART: 'economy_production_secondChart',
}

export const PRODUCTION_PRICE = 'ProducerPrice'
export const TRANSPORT_WARE_HOUSE = 'TransportWarehouse'
