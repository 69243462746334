import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import IndexStatisticService from '../../../../../../../core/services/market/marketInDepth/IndexStatisticService'
import { handleExport } from '../../../../../../utils/Export'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'

export const getIndexStatisticsOverviewOverview = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/overview/GET_INDEX_STATISTICS_OVERVIEW_OVERVIEW',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsOverviewOverview(params)
      if (response.success) {
        if (params.TimeFilter === PRICE_DATA_TIME_SELECT.CUSTOM) {
          dispatch(getIndexStatisticsOverviewTotal(params))
        }
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndexStatisticsOverviewDetail = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/overview/GET_INDEX_STATISTICS_OVERVIEW_DETAIL',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsOverviewDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadStatisticsOverviewOverview = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/overview/DOWNLOAD_STATISTICS_OVERVIEW_OVERVIEW',
  async (data) => {
    const response =
      await IndexStatisticService.downloadStatisticsOverviewOverview(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadStatisticsOverviewDetail = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/overview/DOWNLOAD_STATISTICS_OVERVIEW_DETAIL',
  async (data) => {
    const response =
      await IndexStatisticService.downloadStatisticsOverviewDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const getIndexStatisticsOverviewTotal = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/overview/GET_INDEX_STATISTICS_OVERVIEW_TOTAL',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsOverviewTotal(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
