import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialAnalysisService from '../../../../../../core/services/sector/sectorConstituents/FinancialAnalysisService'
import { handleExport } from '../../../../../utils/Export'

export const getTableData = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/corporate/GET_TABLE_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialAnalysisService.getNonFinancialCompany(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadNonFinancialCompany = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/corporate/DOWNLOAD_TABLE',
  async (data) => {
    const response = await FinancialAnalysisService.downloadNonFinancialCompany(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
