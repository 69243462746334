import { useState } from 'react'
import { useSelector } from 'react-redux'
import DownloadJpg from '../../../../../common/downloadJpg'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import ZoomWindow from '../../../../../common/zoomWindow'
import { formatNumber } from '../../../../../utils/Common'
import HeaderChart from '../HeaderChart'
import style from '../index.module.css'
import Container from './Container'

export const OPEN_MARKET_OPERATIONS = 'OPEN_MARKET_OPERATIONS'

const OpenMarketOperationsTop = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)

  const nameScreen = UseI18n('economy.monetary.monetary.OPEN_MARKET')
  const chartName = UseI18n('economy.monetary.monetary.OPEN_MARKET_OPERATIONS')
  const tickerName = basicInfo.ticker

  const [isFullScreen, setIsFullScreen] = useState(false)

  const renderCustomHeader = () => {
    return (
      <>
        <div />
        <HeaderChart
          title={'economy.monetary.monetary.OPEN_MARKET_OPERATIONS'}
        />
        <div className="window-header-action align-center">
          <ZoomWindow
            panelRefs={panelRefs[panelKey]}
            panelKey={panelKey}
            classVerticalResize={style.resizeX}
            classHorizontalResize={style.resizeY}
            sizes={sizes}
            setSizes={setSizes}
            handleCustom={setIsFullScreen}
          />
          <DownloadJpg
            data={{
              domId: OPEN_MARKET_OPERATIONS,
              nameScreen,
              chartName,
              tickerName,
            }}
            titleJpg={chartName}
          />
        </div>
      </>
    )
  }

  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      renderCustomHeader={renderCustomHeader}
      headerActionClass={style.headerActionClass}
      headerTitleClass={style.headerTitleClass}
      headerClass={style.headerClass}
      bodyClass={style.bodyClass}
      hasPanelPadding={false}
    >
      <Container
        width={formatNumber(sizes[panelKey].width)}
        height={formatNumber(sizes[panelKey].height)}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}

export default OpenMarketOperationsTop
