export const MONTH_OPTIONS = [
  { name: 'common.MONTHLY_OPTION.MONTH_1', value: 1 },
  { name: 'common.MONTHLY_OPTION.MONTH_2', value: 2 },
  { name: 'common.MONTHLY_OPTION.MONTH_3', value: 3 },
  { name: 'common.MONTHLY_OPTION.MONTH_4', value: 4 },
  { name: 'common.MONTHLY_OPTION.MONTH_5', value: 5 },
  { name: 'common.MONTHLY_OPTION.MONTH_6', value: 6 },
  { name: 'common.MONTHLY_OPTION.MONTH_7', value: 7 },
  { name: 'common.MONTHLY_OPTION.MONTH_8', value: 8 },
  { name: 'common.MONTHLY_OPTION.MONTH_9', value: 9 },
  { name: 'common.MONTHLY_OPTION.MONTH_10', value: 10 },
  { name: 'common.MONTHLY_OPTION.MONTH_11', value: 11 },
  { name: 'common.MONTHLY_OPTION.MONTH_12', value: 12 },
]

export const TIME_FREQUENCY = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
  TTM: 'TTM',
  CUSTOM: 'Custom',
}
