import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class SameIndustryBondProxy extends ProxyBase {
  getDataProxy(params) {
    return this.get('BondIssueAndCompany', params)
  }
  exportFileProxy(params) {
    return this.post('DownloadBondIssueAndCompany', params, {}, 'download')
  }
}

export default new SameIndustryBondProxy(
  ServiceProxyConfig.Bond.BondAnalysis.ServiceUrl,
)
