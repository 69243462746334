import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'

export const TICKER_OPTIONS = {
  INDEX_VN: 'closeIndexVN',
  INDEX_VN30: 'closeIndexVN30',
  // FUND_AVERAGE: 'avgnaVperShareAdjusted',
  NO_COMPARISON: 'noComparison',
}

export const VALUE_TYPES = {
  RELATIVE: 'relative',
  ABSOLUTE: 'absolute',
}

export const NAV_CERT_TIME_RANGES = [
  {
    title: '3M',
    value: TIME_RANGES.ThreeMonths,
  },
  {
    title: '6M',
    value: TIME_RANGES.SixMonths,
  },
  {
    title: 'YTD',
    value: TIME_RANGES.YearToDate,
  },
  {
    title: '1Y',
    value: TIME_RANGES.OneYear,
  },
]

export const X_AXIS_KEY = 'endDateId'

export const FUND_KEY = 'naV_CCQ'

export const INDEX_BY_FIELD = {
  naV_CCQ: 1,
  closeIndexVN: 2,
  closeIndexVN30: 3,
  avgnaVperShareAdjusted: 4,
}
