import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
// import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import BondSecurityMaster from './bondSecurity'
const BondSecurity = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="bond.bondSecurity.common.TITLE" />
            </div>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <BondSecurityMaster />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}

export default BondSecurity
