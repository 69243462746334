import { SelectSearchMultiple } from '../../../../common/selectCustom/SelectSearchMultiple'
import { TIME_FREQUENCY_OPTIONS } from '../constants'

export const Frequency = ({ value, onChange }) => {
  return (
    <SelectSearchMultiple
      values={value}
      width={160}
      isOptionAll
      isI18n
      textEmpty="common.ALL_FREQUENCY"
      textAll="common.ALL_FREQUENCY"
      options={TIME_FREQUENCY_OPTIONS}
      onChange={(value) => onChange({ frequency: value })}
    />
  )
}
