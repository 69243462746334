import { createAsyncThunk } from '@reduxjs/toolkit'
import OwnershipService from '../../../../../core/services/corporate/OwnershipService'
import { handleExport } from '../../../../utils/Export'

export const getMajorShareholder = createAsyncThunk(
  'corporate/ownership/tooltipInfo/GET_MAJORSHAREHOLDER',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getMajorOwnership(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getHistoricalTooltip = createAsyncThunk(
  'corporate/ownership/tooltipInfo/GET_HISTORICAL_TOOLTIP',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getHistoricalTooltip(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getOwnershipTooltip = createAsyncThunk(
  'corporate/ownership/tooltipInfo/GET_OWNERSHIP_TOOLTIP',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getOwnershipTooltip(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadShareHolderTooltip_Historical = createAsyncThunk(
  'corporate/ownership/tooltipInfo/DOWNLOAD_SHARE_HOLDER_TOOLTIP_HISTORICAL',
  async (data) => {
    const response =
      await OwnershipService.downloadShareHolderTooltip_Historical(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadShareHolderTooltip_Ownership = createAsyncThunk(
  'corporate/ownership/tooltipInfo/DOWNLOAD_SHARE_HOLDER_TOOLTIP_OWNERSHIP',
  async (data) => {
    const response =
      await OwnershipService.downloadShareHolderTooltip_Ownership(data)
    handleExport(response.data, response.filename)
  },
)
