export const TABLE_ROW_LEVEL = {
  LEVEL_1: 1,
  LEVEL_2: 2,
}

export const detailMetricsTableConstants = {
  marginLending: [
    {
      index: 'marginLending',
      childrenId: ['yoY_Growth', 'market_Share'],
      name: 'corporate.businessModel.penetrationAnalysis.MARGIN_LENDING',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: 'NS239',
    },
    {
      index: 'yoY_Growth',
      name: 'corporate.businessModel.penetrationAnalysis.YOY_GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'MLYG',
      isFormatValToPercent: true,
    },
    {
      index: 'market_Share',
      name: 'corporate.businessModel.penetrationAnalysis.MARKET_SHARE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'MLMS',
      isFormatValToPercent: true,
    },
    {
      index: 'change_in_Market_Share_YTD',
      name: 'corporate.businessModel.penetrationAnalysis.LABEL_CHART_CHANGE',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: 'C1',
      isFormatValToPercent: true,
    },
    {
      index: 'margin_Lending_Owners_Equity',
      name: 'corporate.businessModel.penetrationAnalysis.MARGIN_LENDING_OWNER_EQUITY',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: 'MLOE',
      isFormatValToPercent: true,
    },
    {
      index: 'margin_Lending_Total_Assets',
      name: 'corporate.businessModel.penetrationAnalysis.MARGIN_LENDING_TOTAL_ASSETS',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: 'MLTA',
      isFormatValToPercent: true,
    },
  ],
  proprietary: [
    {
      index: 'proprietary',
      childrenId: ['yoY_Growth', 'market_Share'],
      name: 'corporate.businessModel.penetrationAnalysis.PROPRIETARY',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: 'TD',
    },
    {
      index: 'yoY_Growth',
      name: 'corporate.businessModel.penetrationAnalysis.YOY_GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'TDYG',
      isFormatValToPercent: true,
    },
    {
      index: 'market_Share',
      name: 'corporate.businessModel.penetrationAnalysis.MARKET_SHARE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'TDMS',
      isFormatValToPercent: true,
    },
    {
      index: 'change_in_Market_Share_YTD',
      name: 'corporate.businessModel.penetrationAnalysis.LABEL_CHART_CHANGE',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: 'C2',
      isFormatValToPercent: true,
    },
  ],
  treasury: [
    {
      index: 'proprietary',
      childrenId: ['yoY_Growth', 'market_Share'],
      name: 'corporate.businessModel.penetrationAnalysis.TREASURY',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: 'BS8',
    },
    {
      index: 'yoY_Growth',
      name: 'corporate.businessModel.penetrationAnalysis.YOY_GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'KDNVYG',
      isFormatValToPercent: true,
    },
    {
      index: 'market_Share',
      name: 'corporate.businessModel.penetrationAnalysis.MARKET_SHARE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'KDNVMS',
      isFormatValToPercent: true,
    },
    {
      index: 'change_in_Market_Share_YTD',
      name: 'corporate.businessModel.penetrationAnalysis.LABEL_CHART_CHANGE',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: 'C3',
      isFormatValToPercent: true,
    },
  ],
}
