import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterWithScroll from '../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../common/loading'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import {
  removeSectorCPIFromList,
  selectCPIIds,
  selectIsChangeTypeCode,
  selectListCPISector,
} from '../cpiAndInflationTable/store/slice'
import { ChartComponent } from './ChartComponent'
import { COLORS_LINE_CHART, GROWTH_SECTOR_DATA } from './constants'
import {
  changeActiveCPISector,
  selectDataChart,
  selectLoading,
} from './store/slice'
import { growthSectorChart } from './store/thunk'

export const GrowthSector = ({ width, height, isFullScreen }) => {
  const dispatch = useDispatch()

  const listCPISector = useSelector(selectListCPISector)
  const idsCPI = useSelector(selectCPIIds)
  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)
  const isChangeTypeCode = useSelector(selectIsChangeTypeCode)

  const headerChart = useRef()
  const legendPanel = useRef()

  const [heightSizeTracker, setHeightSizeTracker] = useState()
  const [heightListSector, setHeightListCPISector] = useState(0)
  const [currentDateType, setCurrentDateType] = useState(
    GROWTH_SECTOR_DATA.TIME_RANGE[0].value,
  )

  const getIdsInListCPISector = listCPISector.length
    ? listCPISector?.map((item) => item.cpivnTypeId)
    : []

  // Actions
  const handleDelete = (item) => {
    dispatch(removeSectorCPIFromList(item.cpivnTypeId))
    dispatch(changeActiveCPISector(null))
  }

  const handleHover = (item) => {
    dispatch(changeActiveCPISector(item.cpivnTypeId))
  }

  const handleUnHover = () => {
    dispatch(changeActiveCPISector(null))
  }

  useEffect(() => {
    if (headerChart.current?.offsetHeight && heightListSector)
      setHeightSizeTracker(
        height - headerChart.current?.offsetHeight - heightListSector,
      )
  }, [height, width, listCPISector, heightListSector])

  useEffect(() => {
    if (idsCPI.length) {
      dispatch(
        growthSectorChart({
          cpivnTypeIds: idsCPI,
          timeRange: currentDateType,
        }),
      )
    }
  }, [idsCPI, currentDateType])

  return (
    width &&
    height && (
      <>
        <div
          ref={headerChart}
          className="d-flex justify-content-end box-price-chart"
        >
          <DispatchActionTab
            data={GROWTH_SECTOR_DATA.TIME_RANGE}
            activeTab={currentDateType}
            onChangeTab={(item) => setCurrentDateType(item.value)}
          />
        </div>
        {isLoading || isChangeTypeCode ? (
          <Loading />
        ) : (
          idsCPI.length &&
          dataChart.length && (
            <div id="growthSector">
              <ChartComponent
                data={dataChart}
                width={width}
                height={heightSizeTracker || 0}
                keyXAxis={'time'}
                lineKeys={getIdsInListCPISector}
                currentDateType={currentDateType}
                idsCPI={idsCPI}
              />
              <FooterWithScroll
                width={width}
                data={listCPISector}
                idKey="cpivnTypeId"
                nameKey="cpivnTypeName"
                color={(item) => COLORS_LINE_CHART[item.colorId]}
                legendPanel={legendPanel}
                isFullScreen={isFullScreen}
                setHeightFooter={setHeightListCPISector}
                onDelete={handleDelete}
                onHover={handleHover}
                onUnHover={handleUnHover}
              />
            </div>
          )
        )}
      </>
    )
  )
}
