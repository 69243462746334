export const COLORS = [
  '#ecffdf',
  '#c7ff58',
  '#75d4ff',
  '#930037',
  '#006d8c',
  '#ff2500',
  '#56c10a',
  '#185999',
  '#f57f07',
  '#744d23',
  '#f9c105',
  '#009fe6',
  '#f4327b',
  '#9c54e4',
  '#4bd9be',
  '#00763e',
  '#975000',
  '#c52e14',
  '#1a36a9',
  '#9d9e9e',
  '#42b9e9',
  '#27699b',
  '#35a087',
  '#f1ffeb',
  '#F98E30',
  '#F1C594',
  '#B7CADB',
  '#FAD9E6',
  '#74F4FF',
  '#FFD365',
]

export const TEXT_LIGHT = [3, 4, 7, 9, 15, 16, 17, 18]

export const INDICATORS = {
  basicPE: 'rtD0022',
  marketCap: 'rtD0005',
  ev: 'rtD0006',
  pS: 'rtD0025',
  pB: 'rtD0019',
  pCfo: 'rtD0034',
  evRevenue: 'rtD0026',
  evEbitda: 'rtD0027',
  evEbit: 'rtD0028',
  evFcff: 'rtD0042',
  evFcfe: 'rtD0041',
}

export const INDICATOR_OPTION = Object.keys(INDICATORS).map((key) => ({
  value: INDICATORS[key],
  name: 'sector.sectorConstituents.valuation.indicators.' + key,
}))

export const ITEM_TYPE = {
  SECTOR: 'SECTOR',
  TICKER: 'TICKER',
}

export const CHART_TYPE = {
  RELATIVE: 'RELATIVE',
  ABSOLUTE: 'ABSOLUTE',
}

export const CHART_TYPE_RADIO = [
  {
    title: 'sector.sectorConstituents.valuation.RELATIVE',
    value: CHART_TYPE.RELATIVE,
  },
  {
    title: 'sector.sectorConstituents.valuation.ABSOLUTE',
    value: CHART_TYPE.ABSOLUTE,
  },
]

export const TIME_FRAME = {
  THREE_MONTH: {
    title: '3M',
    value: 'ThreeMonths',
  },
  SIX_MONTH: {
    title: '6M',
    value: 'SixMonths',
  },
  NINE_MONTH: {
    title: '9M',
    value: 'NineMonths',
  },
  ONE_YEAR: {
    title: '1Y',
    value: 'OneYear',
  },
  THREE_YEAR: {
    title: '3Y',
    value: 'ThreeYears',
  },
  ALL: {
    title: 'ALL',
    value: 'Alltime',
  },
}
