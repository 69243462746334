import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../../../common/table'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'

import { keyTabletop5MajorShareHolder } from '../../filter/constants'
import {
  selectDataFDF,
  selectTop5MajorShareHolderValue,
} from '../../filter/store/slice'

const Top5MajorShareHolderTable = () => {
  const data = useSelector(selectDataFDF)
  const locale = useSelector((state) => state.i18n.locale)

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  return (
    <div style={{ height: dimensions.height }}>
      <Table
        ids={data.top5MajorShareHolder.ids}
        getDataFromRedux={selectTop5MajorShareHolderValue}
        isLoading={false}
        columnDraggable={false}
        hasFooter={false}
        hasTooltip={false}
        dimensionsTable={setDimensions}
        schema={Object.keys(keyTabletop5MajorShareHolder).map((key, index) => {
          const title = `corporate.companySnapshot.${key}`
          const colId = keyTabletop5MajorShareHolder[key]
          let result = {
            colId,
            title,
            isI18n: false,
            thStyle: {
              textAlign: 'right',
            },
            tdStyle: {
              textAlign: 'right',
            },
          }
          if (index === 0 || index === 1) {
            result = {
              ...result,
              thStyle: {
                textAlign: 'left',
              },
              tdStyle: {
                textAlign: 'left',
              },
            }
          }
          if (index === 2) {
            result = {
              ...result,
              render: (val) => formatVal(val, 0),
            }
          }
          if (index === 3) {
            result = {
              ...result,
              render: (val) => valToPercent(val),
            }
          }
          if (index === 4) {
            result = {
              ...result,
              render: (val) => formatDateTime(val, FORMAT.DATE, locale),
            }
          }
          return result
        })}
      />
    </div>
  )
}

export default Top5MajorShareHolderTable
