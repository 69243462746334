export const COMPARE_MAX_ADDED = 19

export const SEARCH_TIME_FRAME = {
  '3M': '3M',
  '6M': '6M',
  '9M': '9M',
  '1Y': '1Y',
  '3Y': '3Y',
  ALL: 'All',
}

export const SEARCH_INDICATOR = {
  priceToEarnings: 'P/E',
  priceToBook: 'P/B',
}

export const listSelectIndicator = [
  {
    value: SEARCH_INDICATOR.priceToEarnings,
    name: 'searchIndicator',
    label: 'market.marketInDepth.valuation.PRICE_TO_EARNINGS',
  },
  {
    value: SEARCH_INDICATOR.priceToBook,
    name: 'searchIndicator',
    label: 'market.marketInDepth.valuation.PRICE_TO_BOOK',
  },
]

export const SEARCH_ITEM_TYPE = {
  ALL: 'All',
  INDICES: 'Index',
  SECTOR: 'Sector',
  TICKER: 'Stock',
}

export const ICB_LEVELS = {
  ICB_LEVEL_1: 1,
  ICB_LEVEL_2: 2,
  ICB_LEVEL_3: 3,
  ICB_LEVEL_4: 4,
  ICB_LEVEL_5: 5,
}

export const arrColors = [
  { bgColor: '#f57f07', color: '#1e242e' },
  { bgColor: '#185999', color: '#ececec' },
  { bgColor: '#ff2500', color: '#ececec' },
  { bgColor: '#c7ff58', color: '#1e242e' },
  { bgColor: '#744d23', color: '#ececec' },
  { bgColor: '#f9c105', color: '#1e242e' },
  { bgColor: '#85d4ff', color: '#1e242e' },
  { bgColor: '#56c10a', color: '#1e242e' },
  { bgColor: '#9d9e9e', color: '#1e242e' },
  { bgColor: '#f4327b', color: '#ececec' },
  { bgColor: '#930037', color: '#ececec' },
  { bgColor: '#006d8c', color: '#ececec' },
  { bgColor: '#9c54e4', color: '#ececec' },
  { bgColor: '#4bd9be', color: '#1e242e' },
  { bgColor: '#c52e14', color: '#ececec' },
  { bgColor: '#ecffdf', color: '#1e242e' },
  { bgColor: '#00763e', color: '#ececec' },
  { bgColor: '#975000', color: '#ececec' },
  { bgColor: '#1a36a9', color: '#ececec' },
]
