import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { COM_GROUP } from '../../../../constants/Group'
import {
  changeFinancialRatioFilter,
  changeTypeOfRatio,
  selectFinancialRatioFilter,
  selectTypeOfRatio,
} from '../store/slice'
import style from './index.module.css'

export const FilterOptionItem = ({ data, comGroup }) => {
  const parentDropdownRef = useRef()
  const dispatch = useDispatch()

  // Use selector
  const financialRatioFilter = useSelector(selectFinancialRatioFilter)
  const typeOfRatio = useSelector(selectTypeOfRatio)

  // Actions
  const onSelectItem = (value) => {
    const item = getOptions().find((item) => item.value === value)

    if (data.field === 'typeOfRatio') {
      dispatch(changeTypeOfRatio(item))
    } else {
      dispatch(changeFinancialRatioFilter({ field: data.field, option: item }))
    }
  }

  // Get data
  const getOptions = () => {
    if (data.field === 'typeOfRatio') {
      if (comGroup === COM_GROUP.BANK) {
        return data.options[COM_GROUP.BANK]
      } else {
        return data.options.Other
      }
    } else {
      return data.options
    }
  }

  // Use effect
  useEffect(() => {
    if (data.field === 'typeOfRatio') {
      if (comGroup === COM_GROUP.BANK) {
        dispatch(changeTypeOfRatio(data.options[COM_GROUP.BANK][0]))
      } else {
        dispatch(changeTypeOfRatio(data.options.Other[0]))
      }
    }
  }, [comGroup])

  return (
    <div
      className={`input-dropdown ${style['input-dropdown']}`}
      ref={parentDropdownRef}
    >
      <Span style={{ fontSize: 10 }} className={style['title-dropdown']}>
        <Translate value={data.title} />
      </Span>
      <SelectCustom
        isI18n={true}
        selectData={getOptions()}
        value={
          data.field === 'typeOfRatio'
            ? typeOfRatio.value
            : financialRatioFilter[data.field].value
        }
        handleChange={onSelectItem}
        appendStyle={{ fontWeight: 'normal' }}
      />
    </div>
  )
}

FilterOptionItem.propTypes = {
  data: PropTypes.object.isRequired,
  comGroup: PropTypes.string.isRequired,
}
