export const fundProfile = {
  PORTFOLIO: 'DANH MỤC',
  NAV_DATA: 'DỮ LIỆU NAV',
  FINANCIAL_REPORT: 'BÁO CÁO TÀI CHÍNH',
  PERIODICAL_REPORT: 'BÁO CÁO HOẠT ĐỘNG',
  PROFILE: 'HỒ SƠ QUỸ',

  FUND_FLOW_NOTE: 'Dòng tiền quỹ được ước tính bởi FiinGroup',

  portfolio: {
    ASSET_ALLOCATION: 'PHÂN BỔ TÀI SẢN',
    ASSET_ALLOCATION_OVER_TIME: 'PHÂN BỔ TÀI SẢN THEO THỜI GIAN',
    PORTFOLIO_TABLE: 'DANH MỤC',

    ALL_TICKER: 'Tất cả mã',
    ASSETS: 'Tài sản',
    SECTORS: 'Ngành',
    STOCKS: 'Top cổ phiếu',
    STOCK: 'Cổ phiếu',
    BOND: 'Trái phiếu',
    CASH_AND_OTHER: 'Tiền và tài sản khác',
    RELATIVE: 'Tương đối',
    ABSOLUTE: 'Tuyệt đối',
    CURRENT: 'Nắm giữ hiện thời',
    HISTORY: 'Lịch sử nắm giữ',

    assetAllocation: {
      ASSET_ALLOCATION: 'Phân bổ tài sản',
      CHART_NAME_ASSET_ALLOCATION_ASSETS:
        'PHÂN BỔ TÀI SẢN THEO TÀI SẢN THÁNG %{month} - %{year}',
      CHART_NAME_ASSET_ALLOCATION_SECTORS:
        'PHÂN BỔ TÀI SẢN THEO NGÀNH THÁNG %{month} - %{year}',
      CHART_NAME_ASSET_ALLOCATION_STOCKS:
        'PHÂN BỔ TÀI SẢN THEO TOP CỔ PHIẾU THÁNG %{month} - %{year}',
      HOLDING_VALUE: 'Giá trị nắm giữ',
    },

    assetAllocationOverTime: {
      ASSET_ALLOCATION_OVER_TIME: 'Phân bổ tài sản theo thời gian',
      CHART_NAME_ASSET_ALLOCATION_OVER_TIME_ASSETS:
        'PHÂN BỔ TÀI SẢN THEO THỜI GIAN - TÀI SẢN',
      CHART_NAME_ASSET_ALLOCATION_OVER_TIME_SECTORS:
        'PHÂN BỔ TÀI SẢN THEO THỜI GIAN - NGÀNH',
    },

    portfolioTable: {
      CONTRIBUTION: 'Tỷ trọng',
      VOLUME: 'Khối lượng',
      GROUP_BY_INDUSTRY: 'Nhóm theo Ngành',
      NOTE_UNIT_THOUSAND_VND: 'Đơn vị: Nghìn cổ phiếu',
      COL_STOCK_PUBLIC_DATE: 'Ngày báo cáo',
      COL_STOCK_TICKER: 'Mã CP',
      COL_STOCK_NAME: 'Tên công ty',
      COL_STOCK_CLOSE_PRICE: 'Giá đóng cửa (VNĐ)',
      COL_STOCK_PERCENT_CHANGE: '% Biến động giá 1M',
      COL_STOCK_FUND_HOLDING: 'Quỹ nắm giữ',
      COL_STOCK_VOLUME: 'Khối lượng CP nắm giữ',
      COL_STOCK_VOLUME_CHANGE: 'Thay đổi nắm giữ CP 1M',
      COL_STOCK_OUTSTANDING: '% Số CP lưu hành',
      COL_STOCK_VALUE: 'Giá trị',
      COL_STOCK_VALUE_NOTE: 'Giá trị thị trường của lượng cổ phiếu nắm giữ',
      COL_STOCK_FUND_OWNER_CHANGE: 'Thay đổi nắm giữ 1M',
      COL_STOCK_VALUE_ICB: 'Giá trị',
      COL_STOCK_FUND_OWNER_CHANGE_ICB: 'Thay đổi nắm giữ 1M',
      COL_STOCK_FUND_NAV: '% Tỷ trọng',
    },
  },

  navData: {
    NAV_CERTIFICATE: 'NAV/CCQ',
    FUND_FLOW: 'DÒNG TIỀN CỦA QUỸ',
    NAV_DATA_TABLE: 'DỮ LIỆU NAV',

    RELATIVE: 'Tương đối',
    ABSOLUTE: 'Tuyệt đối',

    navCertificate: {
      NAV_CERTIFICATE: 'NAV/CCQ',
      CHART_NAME_NAV_CERTIFICATE: 'NAV/CCQ %{ticker}',
      NAV_CERT_Y_AXIS_NAV_CCQ_VND: 'NAV/CCQ (VNĐ)',
      NAV_CERT_Y_AXIS_NAV_CCQ: 'NAV/CCQ',
      NAV_CERT_Y_AXIS_INDEX: 'Index (Điểm)',
      UNIT_NAV_CCQ: 'NAV/CCQ',
      UNIT_POINT: 'Điểm',
      INDEX_VN: 'VNINDEX',
      INDEX_VN30: 'VN30',
      FUND_AVERAGE: 'Bình quân quỹ',
      NO_COMPARISON: 'Không so sánh',
    },

    fundFlow: {
      FUND_FLOW: 'Dòng tiền của quỹ',
      CHART_NAME_FUND_FLOW: 'DÒNG TIỀN CỦA QUỸ',
      FUND_FLOW_Y_AXIS: 'Dòng tiền của quỹ (Tr. VNĐ)',
      FUND_FLOW_ACC_Y_AXIS: 'Dòng tiền lũy kế (Tr. VNĐ)',
      FUND_FLOW_FOOTER: 'Dòng tiền của quỹ',
      FUND_FLOW_ACC_FOOTER: 'Dòng tiền lũy kế 12 tháng',
      FUND_FLOW_IN_TOOLTIP: 'Dòng tiền vào',
      FUND_FLOW_OUT_TOOLTIP: 'Dòng tiền ra',
      FUND_FLOW_NET_TOOLTIP: 'Dòng tiền ròng',
      FUND_FLOW_ACC_TOOLTIP: 'Dòng tiền lũy kế 12 tháng',
    },

    navDataTable: {
      COL_DATE: 'Ngày',
      COL_WEEK: 'Tuần',
      COL_MONTH: 'Tháng',
      COL_QUARTER: 'Quý',
      COL_YEAR: 'Năm',
      COL_FROM_DATE: 'Từ ngày',
      COL_TO_DATE: 'Đến ngày',
      COL_OUTSTANDING_FUND_CERT: 'Khối lượng CCQ đang lưu hành',
      COL_NAV_CERT: 'NAV/CCQ',
      COL_CHANGE_NAV: '% Thay đổi NAV/CCQ',
      COL_NAV: 'Tổng NAV',
      COL_FUND_FLOW: 'Dòng tiền quỹ',
      COL_FOREIGN_VOLUME: 'Khối lượng NĐTNN',
      COL_FOREIGN_RATE: 'Tỷ lệ NĐTNN',
      COL_CLOSE_PRICE: 'Giá đóng cửa',
      COL_DISCOUNT_VND: 'Discount/Premium',
      COL_DISCOUNT_PERCENT: 'Discount/Premium',
      NO_DATA_TEXT_TABLE:
        '%{ticker} không công bố dữ liệu giao dịch theo %{frequency}',
    },
  },

  report: {
    FINANCIAL_STATEMENT: 'BÁO CÁO TÀI CHÍNH',
    PERIODICAL_REPORT: 'BÁO CÁO HOẠT ĐỘNG',

    STATEMENT: 'Báo cáo',
    VIEW_BY: 'Xem theo',
    NUMBER_OF_PERIOD: 'Số kỳ',
    LATEST_YEAR: 'Năm mới nhất',
    CURRENCY: 'Tiền tệ',
    UNIT: 'Đơn vị',

    BALANCE_SHEET: 'Bảng cân đối kế toán',
    INCOME_STATEMENT: 'Báo cáo kết quả kinh doanh',
    CASH_FLOW: 'Báo cáo lưu chuyển tiền tệ',
    NOTE: 'Thuyết minh',
    ASSET_REPORT: 'Báo cáo tài sản',
    PROFIT_AND_LOSS_REPORT: 'Báo cáo kết quả hoạt động',
    PORTFOLIO_REPORT: 'Báo cáo danh mục',
    OTHER_REPORT: 'Báo cáo khác',

    YEARLY: 'Năm',
    QUARTERLY: 'Quý',
    SIX_MONTHS: '6 Tháng',

    VND: 'VNĐ',
    MILLION_VND: 'Triệu VNĐ',
    BILLION_VND: 'Tỷ VNĐ',
    USD: 'USD',
    THOUSAND_USD: 'Nghìn USD',
    MILLION_USD: 'Triệu USD',

    AUDIT_STATUS: 'Trạng thái kiểm toán',
    AUDIT_FIRM: 'Công ty kiểm toán',
    AUDIT_OPINION_TYPE_NAME: 'Loại ý kiến kiểm toán',
  },

  profile: {
    BASIC_INFORMATION: 'THÔNG TIN CƠ BẢN',
    INVESTMENT_FEE: 'BIỂU PHÍ',
    DISTRIBUTOR: 'NHÀ PHÂN PHỐI',
    INVESTMENT_STRATEGY: 'CHIẾN LƯỢC ĐẦU TƯ',
    FUND_MANAGER: 'BAN LÃNH ĐẠO QUỸ',

    basicInfo: {
      FUND_NAME: 'Tên quỹ',
      TOTAL_NAV: 'NAV',
      NAV_CCQ: 'NAV/CCQ',
      FUND_TYPE_NAME: 'Loại quỹ',
      FUND_STRUCTURE_NAME: 'Chiến lược đầu tư',
      REGISTER_DATE: 'Ngày thành lập',
      FUND_MANAGEMENT: 'Công ty quản lý quỹ',
      SUPERVISOR_BANK: 'Ngân hàng giám sát',
      SCHEDULE_NAME: 'Kỳ giao dịch',
      FOREIGN_OWNERSHIP: 'Tỷ lệ NĐTNN',
      MIN_INVEST: 'Giá trị mua tối thiểu',
      ADDRESS: 'Địa chỉ',
      WEBSITE: 'Website',
    },

    investmentStrategy: {
      DESCRIPTION_FUND: 'Mô tả quỹ',
      INVESTMENT_STRATEGY: 'Chiến lược đầu tư',
      INVESTMENT_OBJ: 'Mục tiêu đầu tư',
      INVESTOR: 'Đối tượng nhà đầu tư',
    },

    fundManagers: {
      FULLNAME: 'Họ và tên',
      POSITION: 'Chức vụ',
      QUANTITY: 'Số lượng',
      UPDATE_DATE: 'Ngày cập nhật',
    },
  },
}
