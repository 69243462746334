import { Translate } from 'react-redux-i18n'
import { CHART_TYPES } from '../../../../common/chart/financialChart/constant'
import { Span } from '../../../../common/html/Span'
import { ReactComponent as IconAreaChartChoose } from '../../../icon/icon-area-chart-grey-choose.svg'
import { ReactComponent as IconAreaChart } from '../../../icon/icon-area-chart-grey.svg'
import { ReactComponent as IconColumnChartChoose } from '../../../icon/icon-column-chart-grey-choose.svg'
import { ReactComponent as IconColumnChart } from '../../../icon/icon-column-chart-grey.svg'
import { ReactComponent as IconLineChartChoose } from '../../../icon/icon-line-chart-grey-choose.svg'
import { ReactComponent as IconLineChart } from '../../../icon/icon-line-chart-grey.svg'
import { ReactComponent as IconOvalChoose } from '../../../icon/icon-oval-active.svg'
import { ReactComponent as IconOval } from '../../../icon/icon-oval.svg'
import { ReactComponent as IconStackAreaChoose } from '../../../icon/icon-stack-area-grey-choose.svg'
import { ReactComponent as IconStackArea } from '../../../icon/icon-stack-area-grey.svg'
import { ReactComponent as IconStackColumnChoose } from '../../../icon/icon-stack-column-grey-choose.svg'
import { ReactComponent as IconStackColumn } from '../../../icon/icon-stack-column-grey.svg'
import styles from '../style.module.css'

export const ChartTypeComponent = ({ isParent, value, onChange }) => {
  return (
    <div className={styles.settingBlockItem}>
      <div className={styles.settingBlockLeft}>
        <Span
          className={
            isParent ? styles.settingBlockTitle : styles.settingBlockSubTitle
          }
          style={{ fontSize: isParent ? 12 : 10 }}
        >
          <Translate value="financialChart.CHART_TYPE" />
        </Span>
      </div>
      <div className={styles.settingBlockRight}>
        <div className={styles.settingBlockItemChartTab}>
          <div>
            {value === CHART_TYPES.AREA ? (
              <IconAreaChartChoose />
            ) : (
              <IconAreaChart onClick={() => onChange(CHART_TYPES.AREA)} />
            )}
          </div>
          <div>
            {value === CHART_TYPES.BAR ? (
              <IconColumnChartChoose />
            ) : (
              <IconColumnChart onClick={() => onChange(CHART_TYPES.BAR)} />
            )}
          </div>
          <div>
            {value === CHART_TYPES.LINE ? (
              <IconLineChartChoose />
            ) : (
              <IconLineChart onClick={() => onChange(CHART_TYPES.LINE)} />
            )}
          </div>
          <div>
            {value === CHART_TYPES.STACK_BAR ? (
              <IconStackColumnChoose />
            ) : (
              <IconStackColumn
                onClick={() => onChange(CHART_TYPES.STACK_BAR)}
              />
            )}
          </div>
          <div>
            {value === CHART_TYPES.STACK_AREA ? (
              <IconStackAreaChoose />
            ) : (
              <IconStackArea onClick={() => onChange(CHART_TYPES.STACK_AREA)} />
            )}
          </div>
          <div>
            {value === CHART_TYPES.DOT ? (
              <IconOvalChoose />
            ) : (
              <IconOval onClick={() => onChange(CHART_TYPES.DOT)} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
