import segmentalAnalysisProxy from '../../proxies/corporate/SegmentalAnalysisProxy'
import { ServiceBase } from '../ServiceBase'

class SegmentalAnalysisService extends ServiceBase {
  getGetLatestPeriodBank(params) {
    return this.applyMemoryCache(
      'SegmentalAnalysisService.getGetLatestPeriodBank',
      params,
    )(() => segmentalAnalysisProxy.getGetLatestPeriodBank(params))
  }

  getIndicatorMapping(params) {
    return this.applyMemoryCache(
      'SegmentalAnalysisService.getIndicatorMapping',
      params,
    )(() => segmentalAnalysisProxy.getIndicatorMapping(params))
  }

  getIncomeStructure(params) {
    return this.applyMemoryCache(
      'SegmentalAnalysisService.getIncomeStructure',
      params,
    )(() => segmentalAnalysisProxy.getIncomeStructure(params))
  }

  downloadIncomeStructure(params) {
    return this.getFileDownload(() =>
      segmentalAnalysisProxy.downloadIncomeStructure(params),
    )
  }

  chartBank1(params) {
    return this.applyMemoryCache(
      'SegmentalAnalysisService.chartBank1',
      params,
    )(() => segmentalAnalysisProxy.chartBank1(params))
  }

  chartBank2(params) {
    return this.applyMemoryCache(
      'SegmentalAnalysisService.chartBank2',
      params,
    )(() => segmentalAnalysisProxy.chartBank2(params))
  }
}

export default new SegmentalAnalysisService()
