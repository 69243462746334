import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SimpleSearchService from '../../../../../../core/services/common/SimpleSearchService'

export const getIssuerListData = createAsyncThunk(
  'bond/bondScreening/bondList/GET_ISSUER_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchCompanyNotSaveCache(
        params,
      )

      if (response.success) {
        return { data: response.data, hasNextPage: response.hasNextPage }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const fetchMoreIssuerListData = createAsyncThunk(
  'bond/bondScreening/bondList/FETCH_MORE_ISSUER_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchCompanyNotSaveCache(
        params,
      )

      if (response.success) {
        return { data: response.data, hasNextPage: response.hasNextPage }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
