import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { COM_GROUP } from '../../../../../constants/Group'
import { formatVal, valDivBillion } from '../../../../../utils/Value'
import DeleteOrganization from '../DeleteOrganization'
import {
  ASSET_QUALITY_KEYS,
  CREDIT_KEYS,
  MEAN_ROW_ID,
  OWNERSHIP_KEYS,
  PERFORMANCE_KEYS,
  PROFITABILITY_KEYS,
  TABLE_TYPE,
  VALUATION_KEYS,
} from '../constant'
import TdCellTornado from './TdCellTornado'
import style from './index.module.css'

const MIN_WIDTH = 120

export const getSchema = ({ type, currentOrgId, comGroup, isShowChart }) => {
  switch (type) {
    case TABLE_TYPE.PERFORMANCE:
      return Object.keys(PERFORMANCE_KEYS).map((key, index) => {
        const title = `corporate.peerValuation.overview.table.${key}`
        const colId = PERFORMANCE_KEYS[key]

        return {
          colId,
          isI18n: false,
          thStyle: {
            verticalAlign: 'baseline',
          },
          renderTh: () => {
            let textAlign
            switch (colId) {
              case PERFORMANCE_KEYS.ticker:
              case PERFORMANCE_KEYS.companyName:
                textAlign = 'left'
                break
              case PERFORMANCE_KEYS.lastPrice:
                textAlign = 'right'
                break
              case PERFORMANCE_KEYS.marketCap:
                textAlign = isShowChart ? 'left' : 'right'
                break
              default:
                textAlign = isShowChart ? 'center' : 'right'
            }
            return (
              <div style={{ textAlign }} className={style.thCell}>
                <Span style={{ fontSize: 10, fontWeight: 600 }}>
                  <Translate dangerousHTML value={title} />
                </Span>
              </div>
            )
          },
          tdStyle: {
            textAlign:
              colId === PERFORMANCE_KEYS.delete
                ? 'center'
                : [
                    PERFORMANCE_KEYS.ticker,
                    PERFORMANCE_KEYS.companyName,
                  ].includes(colId)
                ? 'left'
                : 'right',
            minWidth: colId === PERFORMANCE_KEYS.companyName ? MIN_WIDTH : 0,
          },
          render: (val, rowId) => {
            if (rowId === MEAN_ROW_ID) {
              if (colId === PERFORMANCE_KEYS.ticker) {
                return (
                  <div className={style.borderTopLight}>
                    <Translate value="corporate.peerValuation.overview.table.mean" />
                  </div>
                )
              }
              if (colId === PERFORMANCE_KEYS.marketCap) {
                return (
                  <div className={style.borderTopLight}>
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={false}
                      transformValue={(val) => valDivBillion(val)}
                      isTwoSide={false}
                    />
                  </div>
                )
              }
              return <div className={style.borderTopLight} />
            }
            switch (colId) {
              case PERFORMANCE_KEYS.delete:
                return <DeleteOrganization organizationId={rowId} />
              case PERFORMANCE_KEYS.ticker:
                return (
                  <span
                    className={
                      rowId === currentOrgId ? style.firstRowColor : ''
                    }
                  >
                    {val}
                  </span>
                )
              case PERFORMANCE_KEYS.companyName:
                return (
                  <span
                    className={`${
                      rowId === currentOrgId ? style.firstRowColor : ''
                    }`}
                  >
                    <TextEllipsis isI18n={false} text={val} />
                  </span>
                )
              case PERFORMANCE_KEYS.lastPrice:
                return (
                  <div
                    className={`${
                      rowId === currentOrgId ? style.firstRowColor : ''
                    }`}
                  >
                    {formatVal(val, 0)}
                  </div>
                )
              case PERFORMANCE_KEYS.marketCap:
                return (
                  <div
                    className={`${
                      rowId === currentOrgId ? style.firstRowColor : ''
                    }`}
                  >
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={false}
                      transformValue={(val) => valDivBillion(val)}
                      isTwoSide={false}
                    />
                  </div>
                )
              case PERFORMANCE_KEYS.percent1DPriceChange:
              case PERFORMANCE_KEYS.percent1WPriceChange:
              case PERFORMANCE_KEYS.percent1MPriceChange:
              case PERFORMANCE_KEYS.percent3MPriceChange:
              case PERFORMANCE_KEYS.percent6MPriceChange:
              case PERFORMANCE_KEYS.percent9MPriceChange:
              case PERFORMANCE_KEYS.percent1YPriceChange:
              case PERFORMANCE_KEYS.percentYTDPriceChange:
                return (
                  <TdCellTornado
                    value={val}
                    colId={colId}
                    isPercent
                    isFormatPositiveValue
                  />
                )
              default:
                return val
            }
          },
        }
      })
    case TABLE_TYPE.VALUATION:
      return Object.keys(VALUATION_KEYS)
        .filter((key) =>
          comGroup === COM_GROUP.BANK
            ? VALUATION_KEYS[key] !== VALUATION_KEYS.evEbitda
            : true,
        )
        .map((key, index) => {
          const title = `corporate.peerValuation.overview.table.${key}`
          const colId = VALUATION_KEYS[key]

          return {
            colId,
            isI18n: false,
            thStyle: {
              verticalAlign: 'baseline',
            },
            renderTh: () => {
              let textAlign
              switch (colId) {
                case VALUATION_KEYS.ticker:
                case VALUATION_KEYS.companyName:
                  textAlign = 'left'
                  break
                case VALUATION_KEYS.marketCap:
                case VALUATION_KEYS.ev:
                  textAlign = isShowChart ? 'left' : 'right'
                  break
                case VALUATION_KEYS.evEbitda:
                case VALUATION_KEYS.eps:
                case VALUATION_KEYS.pE:
                case VALUATION_KEYS.pS:
                case VALUATION_KEYS.peg:
                case VALUATION_KEYS.bvps:
                case VALUATION_KEYS.pB:
                case VALUATION_KEYS.dividendYield:
                  textAlign = isShowChart ? 'center' : 'right'
                  break
                default:
                  textAlign = 'right'
              }

              return (
                <div style={{ textAlign }} className={style.thCell}>
                  <Span style={{ fontSize: 10, fontWeight: 600 }}>
                    <Translate dangerousHTML value={title} />
                  </Span>
                </div>
              )
            },
            tdStyle: {
              textAlign:
                colId === VALUATION_KEYS.delete
                  ? 'center'
                  : [
                      VALUATION_KEYS.ticker,
                      VALUATION_KEYS.companyName,
                    ].includes(colId)
                  ? 'left'
                  : 'right',
              minWidth: colId === VALUATION_KEYS.companyName ? MIN_WIDTH : 0,
            },
            render: (val, rowId) => {
              switch (colId) {
                case VALUATION_KEYS.delete:
                  return rowId !== MEAN_ROW_ID ? (
                    <DeleteOrganization organizationId={rowId} />
                  ) : (
                    <div className={style.borderTopLight} />
                  )
                case VALUATION_KEYS.ticker:
                  return rowId !== MEAN_ROW_ID ? (
                    <span
                      className={
                        rowId === currentOrgId ? style.firstRowColor : ''
                      }
                    >
                      {val}
                    </span>
                  ) : (
                    <div className={style.borderTopLight}>
                      <Translate value="corporate.peerValuation.overview.table.mean" />
                    </div>
                  )
                case VALUATION_KEYS.companyName:
                  return rowId !== MEAN_ROW_ID ? (
                    <span
                      className={`${
                        rowId === currentOrgId ? style.firstRowColor : ''
                      }`}
                    >
                      <TextEllipsis isI18n={false} text={val} />
                    </span>
                  ) : (
                    <div className={style.borderTopLight} />
                  )
                case VALUATION_KEYS.dividendYield:
                  return (
                    <div
                      className={`${
                        rowId === MEAN_ROW_ID ? style.borderTopLight : ''
                      } ${rowId === currentOrgId ? style.firstRowColor : ''}`}
                    >
                      <TdCellTornado
                        value={val}
                        colId={colId}
                        isPercent={true}
                      />
                    </div>
                  )
                default:
                  return (
                    <div
                      className={`${
                        rowId === MEAN_ROW_ID ? style.borderTopLight : ''
                      } ${rowId === currentOrgId ? style.firstRowColor : ''}`}
                    >
                      <TdCellTornado
                        value={val}
                        colId={colId}
                        isPercent={false}
                        transformValue={(val) =>
                          [
                            VALUATION_KEYS.marketCap,
                            VALUATION_KEYS.ev,
                          ].includes(colId)
                            ? valDivBillion(val)
                            : val
                        }
                        isTwoSide={
                          ![
                            VALUATION_KEYS.marketCap,
                            VALUATION_KEYS.ev,
                          ].includes(colId)
                        }
                      />
                    </div>
                  )
              }
            },
          }
        })
    case TABLE_TYPE.OWNERSHIP:
      return Object.keys(OWNERSHIP_KEYS).map((key, index) => {
        const title = `corporate.peerValuation.overview.table.${key}`
        const colId = OWNERSHIP_KEYS[key]

        return {
          colId,
          isI18n: false,
          thStyle: {
            verticalAlign: 'baseline',
          },
          renderTh: () => {
            let textAlign
            switch (colId) {
              case OWNERSHIP_KEYS.ticker:
              case OWNERSHIP_KEYS.companyName:
                textAlign = 'left'
                break
              case OWNERSHIP_KEYS.marketCap:
                textAlign = isShowChart ? 'left' : 'right'
                break
              default:
                textAlign = isShowChart ? 'center' : 'right'
            }

            return (
              <div style={{ textAlign }} className={style.thCell}>
                <Span style={{ fontSize: 10, fontWeight: 600 }}>
                  <Translate dangerousHTML value={title} />
                </Span>
              </div>
            )
          },
          tdStyle: {
            textAlign:
              colId === OWNERSHIP_KEYS.delete
                ? 'center'
                : [OWNERSHIP_KEYS.ticker, OWNERSHIP_KEYS.companyName].includes(
                    colId,
                  )
                ? 'left'
                : 'right',
            minWidth: colId === OWNERSHIP_KEYS.companyName ? MIN_WIDTH : 0,
          },
          render: (val, rowId) => {
            if (rowId === MEAN_ROW_ID) {
              if (colId === OWNERSHIP_KEYS.ticker) {
                return (
                  <div className={style.borderTopLight}>
                    <Translate value="corporate.peerValuation.overview.table.mean" />
                  </div>
                )
              }
              if (colId === OWNERSHIP_KEYS.marketCap) {
                return (
                  <div className={style.borderTopLight}>
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={false}
                      transformValue={(val) => valDivBillion(val)}
                      isTwoSide={false}
                    />
                  </div>
                )
              }
              return <div className={style.borderTopLight} />
            }
            switch (colId) {
              case OWNERSHIP_KEYS.delete:
                return <DeleteOrganization organizationId={rowId} />
              case OWNERSHIP_KEYS.ticker:
                return (
                  <span
                    className={
                      rowId === currentOrgId ? style.firstRowColor : ''
                    }
                  >
                    {val}
                  </span>
                )
              case OWNERSHIP_KEYS.companyName:
                return (
                  <span
                    className={`${
                      rowId === currentOrgId ? style.firstRowColor : ''
                    }`}
                  >
                    <TextEllipsis isI18n={false} text={val} />
                  </span>
                )
              case OWNERSHIP_KEYS.marketCap:
                return (
                  <div
                    className={`${
                      rowId === currentOrgId ? style.firstRowColor : ''
                    }`}
                  >
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={false}
                      transformValue={(val) => valDivBillion(val)}
                      isTwoSide={false}
                    />
                  </div>
                )
              default:
                return (
                  <div
                    className={`${
                      rowId === currentOrgId ? style.firstRowColor : ''
                    }`}
                  >
                    <TdCellTornado value={val} colId={colId} isPercent={true} />
                  </div>
                )
            }
          },
        }
      })
    case TABLE_TYPE.CREDIT:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return Object.keys(ASSET_QUALITY_KEYS).map((key) => {
            const title = `corporate.peerValuation.overview.table.${key}`
            const colId = ASSET_QUALITY_KEYS[key]

            return {
              colId,
              isI18n: false,
              thStyle: {
                verticalAlign: 'baseline',
              },
              renderTh: () => {
                let textAlign
                switch (colId) {
                  case ASSET_QUALITY_KEYS.ticker:
                  case ASSET_QUALITY_KEYS.companyName:
                    textAlign = 'left'
                    break
                  case ASSET_QUALITY_KEYS.fiinRating:
                  case ASSET_QUALITY_KEYS.sPRating:
                  case ASSET_QUALITY_KEYS.moodyRating:
                  case ASSET_QUALITY_KEYS.fitchRanking:
                    textAlign = 'right'
                    break
                  default:
                    textAlign = isShowChart ? 'center' : 'right'
                }
                return (
                  <div style={{ textAlign }} className={style.thCell}>
                    <Span style={{ fontSize: 10, fontWeight: 600 }}>
                      <Translate dangerousHTML value={title} />
                    </Span>
                  </div>
                )
              },
              tdStyle: {
                textAlign:
                  colId === ASSET_QUALITY_KEYS.delete
                    ? 'center'
                    : [
                        ASSET_QUALITY_KEYS.ticker,
                        ASSET_QUALITY_KEYS.companyName,
                      ].includes(colId)
                    ? 'left'
                    : 'right',
                minWidth:
                  colId === ASSET_QUALITY_KEYS.companyName ? MIN_WIDTH : 0,
              },
              render: (val, rowId) => {
                switch (colId) {
                  case ASSET_QUALITY_KEYS.delete:
                    return rowId !== MEAN_ROW_ID ? (
                      <DeleteOrganization organizationId={rowId} />
                    ) : (
                      <div className={style.borderTopLight} />
                    )
                  case ASSET_QUALITY_KEYS.ticker:
                    return rowId !== MEAN_ROW_ID ? (
                      <span
                        className={
                          rowId === currentOrgId ? style.firstRowColor : ''
                        }
                      >
                        {val}
                      </span>
                    ) : (
                      <div className={style.borderTopLight}>
                        <Translate value="corporate.peerValuation.overview.table.mean" />
                      </div>
                    )
                  case ASSET_QUALITY_KEYS.companyName:
                    return rowId !== MEAN_ROW_ID ? (
                      <span
                        className={`${
                          rowId === currentOrgId ? style.firstRowColor : ''
                        }`}
                      >
                        <TextEllipsis isI18n={false} text={val} />
                      </span>
                    ) : (
                      <div className={style.borderTopLight} />
                    )
                  case ASSET_QUALITY_KEYS.equityLoans:
                  case ASSET_QUALITY_KEYS.liabilitiesEquity:
                  case ASSET_QUALITY_KEYS.loanDepositRatio:
                  case ASSET_QUALITY_KEYS.loanLossReservesNPLs:
                  case ASSET_QUALITY_KEYS.loanLossReservesLoans:
                  case ASSET_QUALITY_KEYS.provisionOutstandingLoans:
                    return (
                      <div
                        className={`${
                          rowId === MEAN_ROW_ID ? style.borderTopLight : ''
                        } ${rowId === currentOrgId ? style.firstRowColor : ''}`}
                      >
                        <TdCellTornado
                          value={val}
                          colId={colId}
                          isPercent={false}
                        />
                      </div>
                    )
                  case ASSET_QUALITY_KEYS.nonperformingLoanRatio:
                    return (
                      <div
                        className={`${
                          rowId === MEAN_ROW_ID ? style.borderTopLight : ''
                        } ${rowId === currentOrgId ? style.firstRowColor : ''}`}
                      >
                        <TdCellTornado
                          value={val}
                          colId={colId}
                          isPercent={true}
                        />
                      </div>
                    )
                  default:
                    return rowId !== MEAN_ROW_ID ? (
                      <span
                        className={`${
                          rowId === currentOrgId ? style.firstRowColor : ''
                        }`}
                      >
                        {val}
                      </span>
                    ) : (
                      <div className={style.borderTopLight} />
                    )
                }
              },
            }
          })

        default:
          return Object.keys(CREDIT_KEYS)
            .filter((key) =>
              comGroup === COM_GROUP.INSURANCE
                ? CREDIT_KEYS[key] !== CREDIT_KEYS.ebitdaInterestCorverage
                : true,
            )
            .map((key) => {
              const title = `corporate.peerValuation.overview.table.${key}`
              const colId = CREDIT_KEYS[key]

              return {
                colId,
                isI18n: false,
                thStyle: {
                  verticalAlign: 'baseline',
                },
                renderTh: () => {
                  let textAlign
                  switch (colId) {
                    case CREDIT_KEYS.ticker:
                    case CREDIT_KEYS.companyName:
                      textAlign = 'left'
                      break
                    case CREDIT_KEYS.fiinRating:
                    case CREDIT_KEYS.sPRating:
                    case CREDIT_KEYS.moodyRating:
                    case CREDIT_KEYS.fitchRanking:
                      textAlign = 'right'
                      break
                    case CREDIT_KEYS.ebitdaInterestCorverage:
                    case CREDIT_KEYS.financialLeverage:
                      textAlign = isShowChart ? 'center' : 'right'
                      break
                    default:
                      textAlign = isShowChart ? 'center' : 'right'
                  }
                  return (
                    <div style={{ textAlign }} className={style.thCell}>
                      <Span style={{ fontSize: 10, fontWeight: 600 }}>
                        <Translate dangerousHTML value={title} />
                      </Span>
                    </div>
                  )
                },
                tdStyle: {
                  textAlign:
                    colId === CREDIT_KEYS.delete
                      ? 'center'
                      : [CREDIT_KEYS.ticker, CREDIT_KEYS.companyName].includes(
                          colId,
                        )
                      ? 'left'
                      : 'right',
                  minWidth: colId === CREDIT_KEYS.companyName ? MIN_WIDTH : 0,
                },
                render: (val, rowId) => {
                  switch (colId) {
                    case CREDIT_KEYS.delete:
                      return rowId !== MEAN_ROW_ID ? (
                        <DeleteOrganization organizationId={rowId} />
                      ) : (
                        <div className={style.borderTopLight} />
                      )
                    case CREDIT_KEYS.ticker:
                      return rowId !== MEAN_ROW_ID ? (
                        <span
                          className={
                            rowId === currentOrgId ? style.firstRowColor : ''
                          }
                        >
                          {val}
                        </span>
                      ) : (
                        <div className={style.borderTopLight}>
                          <Translate value="corporate.peerValuation.overview.table.mean" />
                        </div>
                      )
                    case CREDIT_KEYS.companyName:
                      return rowId !== MEAN_ROW_ID ? (
                        <span
                          className={`${
                            rowId === currentOrgId ? style.firstRowColor : ''
                          }`}
                        >
                          <TextEllipsis isI18n={false} text={val} />
                        </span>
                      ) : (
                        <div className={style.borderTopLight} />
                      )
                    case CREDIT_KEYS.netDebtEquity:
                    case CREDIT_KEYS.longDebtEquity:
                    case CREDIT_KEYS.debtEquity:
                    case CREDIT_KEYS.shortDebtEquity:
                    case CREDIT_KEYS.liabilitiesEquity:
                    case CREDIT_KEYS.ebitdaInterestCorverage:
                    case CREDIT_KEYS.financialLeverage:
                      return (
                        <div
                          className={`${
                            rowId === MEAN_ROW_ID ? style.borderTopLight : ''
                          } ${
                            rowId === currentOrgId ? style.firstRowColor : ''
                          }`}
                        >
                          <TdCellTornado
                            value={val}
                            colId={colId}
                            isPercent={false}
                          />
                        </div>
                      )
                    default:
                      return rowId !== MEAN_ROW_ID ? (
                        <span
                          className={`${
                            rowId === currentOrgId ? style.firstRowColor : ''
                          }`}
                        >
                          {val}
                        </span>
                      ) : (
                        <div className={style.borderTopLight} />
                      )
                  }
                },
              }
            })
      }
    case TABLE_TYPE.PROFITABILITY:
      return Object.keys(PROFITABILITY_KEYS)
        .filter(
          (key) =>
            PROFITABILITY_KEYS[key] !==
            (comGroup === COM_GROUP.BANK
              ? PROFITABILITY_KEYS.ebitdaMargin
              : PROFITABILITY_KEYS.nim),
        )
        .map((key, index) => {
          const title = `corporate.peerValuation.overview.table.${key}`
          const colId = PROFITABILITY_KEYS[key]

          return {
            colId,
            isI18n: false,
            thStyle: {
              verticalAlign: 'baseline',
            },
            renderTh: () => {
              let textAlign
              switch (colId) {
                case PROFITABILITY_KEYS.ticker:
                case PROFITABILITY_KEYS.companyName:
                  textAlign = 'left'
                  break
                default:
                  textAlign = isShowChart ? 'center' : 'right'
              }
              return (
                <div style={{ textAlign }} className={style.thCell}>
                  <Span style={{ fontSize: 10, fontWeight: 600 }}>
                    <Translate dangerousHTML value={title} />
                  </Span>
                </div>
              )
            },
            tdStyle: {
              textAlign:
                colId === PROFITABILITY_KEYS.delete
                  ? 'center'
                  : [
                      PROFITABILITY_KEYS.ticker,
                      PROFITABILITY_KEYS.companyName,
                    ].includes(colId)
                  ? 'left'
                  : 'right',
              minWidth:
                colId === PROFITABILITY_KEYS.companyName ? MIN_WIDTH : 0,
            },
            render: (val, rowId) => {
              switch (colId) {
                case PROFITABILITY_KEYS.delete:
                  return rowId !== MEAN_ROW_ID ? (
                    <DeleteOrganization organizationId={rowId} />
                  ) : (
                    <div className={style.borderTopLight} />
                  )
                case PROFITABILITY_KEYS.ticker:
                  return rowId !== MEAN_ROW_ID ? (
                    <span
                      className={
                        rowId === currentOrgId ? style.firstRowColor : ''
                      }
                    >
                      {val}
                    </span>
                  ) : (
                    <div className={style.borderTopLight}>
                      <Translate value="corporate.peerValuation.overview.table.mean" />
                    </div>
                  )
                case PROFITABILITY_KEYS.companyName:
                  return rowId !== MEAN_ROW_ID ? (
                    <span
                      className={`${
                        rowId === currentOrgId ? style.firstRowColor : ''
                      }`}
                    >
                      <TextEllipsis isI18n={false} text={val} />
                    </span>
                  ) : (
                    <div className={style.borderTopLight} />
                  )
                default:
                  return (
                    <div
                      className={`${
                        rowId === MEAN_ROW_ID ? style.borderTopLight : ''
                      } ${rowId === currentOrgId ? style.firstRowColor : ''}`}
                    >
                      <TdCellTornado
                        value={val}
                        colId={colId}
                        isPercent={true}
                      />
                    </div>
                  )
              }
            },
          }
        })
    default:
      break
  }
}
