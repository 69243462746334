import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import { Right } from '../../../../common/news/detail/right'
import { FEATURES } from '../../../../constants/routes'
import {
  selectNewsSameCategories,
  selectNewsSameCategoriesLoading,
} from '../../store/slice'
import { getNewsSameCategory } from '../../store/thunk'

export const RightContent = ({ routeForItem }) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const isLoading = useSelector(selectNewsSameCategoriesLoading)
  const locale = useSelector((state) => state.i18n.locale)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const newsId = params.get('newsId')
  const data = useSelector(selectNewsSameCategories)

  useEffect(() => {
    if (newsId) {
      dispatch(
        getNewsSameCategory({
          NewsId: newsId,
        }),
      )
    }
  }, [locale, newsId])

  const onAction = (newsId) => () => {
    navigate(
      FEATURES[routeForItem || 'market'].components['news-report-detail']
        .route +
        '?newsId=' +
        newsId,
    )
  }

  return (
    <Right
      title={'market.newsOrReport.SAME_CATEGORY'}
      isLoading={isLoading}
      data={data}
      onAction={onAction}
    />
  )
}
