import PropTypes from 'prop-types'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'

const TableHeaderHorizontal = ({
  title,
  styleProps,
  isDropdown,
  isActive,
  level,
  isContributionToNIM,
}) => {
  let divStyle = {}
  let spanStyle = {}
  let iconStyle = { marginRight: 7, marginLeft: 6, alignSelf: 'center' }

  switch (level) {
    case 2: {
      if (isDropdown) {
        divStyle = { paddingLeft: 23 }
      } else {
        divStyle = { paddingLeft: 46 }
      }
      break
    }
    case 3: {
      if (isDropdown) {
        divStyle = { paddingLeft: 45 }
      } else {
        divStyle = { paddingLeft: 68 }
      }
      break
    }
    case 4: {
      if (isDropdown) {
        divStyle = { paddingLeft: 69 }
      } else {
        divStyle = { paddingLeft: 76 }
        spanStyle = { opacity: 0.5, fontStyle: 'italic' }
      }
      break
    }
    case 5: {
      if (isDropdown) {
        divStyle = { paddingLeft: 69 }
      } else {
        divStyle = { paddingLeft: 100 }
        spanStyle = { opacity: 0.5, fontStyle: 'italic' }
      }
      break
    }

    default: {
      if (isDropdown) {
        spanStyle = { color: '#fecf2f' }
      } else {
        divStyle = { paddingLeft: 23 }
        spanStyle = { color: isContributionToNIM ? '#ececec' : '#fecf2f' }
      }
      break
    }
  }

  return (
    <div
      style={{ ...styleProps, ...divStyle, textAlign: 'left' }}
      className="d-flex"
    >
      {isDropdown && (
        <i
          style={{
            fontSize: 10,
            color: '#8c9194',
            transform: isActive ? '' : 'rotate(-90deg)',
            ...iconStyle,
          }}
          className="icon-caret"
        />
      )}
      <TextEllipsisStaticLine
        val={title}
        appendStyle={{
          ...spanStyle,
          fontWeight: isContributionToNIM ? 600 : 'inherit',
          width: '100%',
        }}
      />
    </div>
  )
}

TableHeaderHorizontal.propTypes = {
  title: PropTypes.any,
  styleProps: PropTypes.object,
  isDropdown: PropTypes.bool,
  isActive: PropTypes.bool,
  level: PropTypes.oneOf([1, 2, 3, 4, 5]),
  isContributionToNIM: PropTypes.bool,
}

TableHeaderHorizontal.defaultProps = {
  isDropdown: false,
  styleProps: {},
  isActive: false,
  level: 1,
}

export default TableHeaderHorizontal
