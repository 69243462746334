import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { selectDataRevenueCost, selectLoadingRevenueCost } from '../store/slice'
import { getRevenueCost } from '../store/thunk'
import ChartComponent from './ChartComponent'
import { CHART_DOM_ID } from './PanelRevenueCost'

const ChartContainer = ({ width, height }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const dataChart = useSelector(selectDataRevenueCost)
  const loading = useSelector(selectLoadingRevenueCost)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getRevenueCost({
          OrganizationId: basicInfo.organizationId,
        }),
      )
    }
  }, [basicInfo.organizationId])

  return (
    <div id={CHART_DOM_ID}>
      <div style={{ position: 'relative', height: height }}>
        {loading ? (
          <Loading />
        ) : dataChart.length === 0 ? (
          <NoData />
        ) : (
          <ChartComponent
            width={width}
            height={height}
            data={dataChart}
            keyXAxis="keyXAxis"
            barKeys={['nC160', 'iS3']}
            lineKey={'cr'}
          />
        )}
      </div>
    </div>
  )
}

export default ChartContainer
