import { useDispatch, useSelector } from 'react-redux'
import { SizeTracker } from '../../common/sizeTracker'
import { uuid } from '../../utils/Common'
import { lengthChartInLayout } from '../chart/common/useGetLayoutDashboard'
import { CHART_PARAMETERS_DEFAULT } from '../chart/constants'
import {
  changeChartTabActive,
  changeChartTabsData,
  selectChartTabActive,
  selectChartTabsData,
} from '../chart/store/slice'
import {
  getChartNameBySampleName,
  getChartOrderByListChart,
} from '../common/helpers'
import { Layout } from '../common/layout'
import { MenuFooterDashboard } from '../common/menuFooterDashboard'
import { useGetUserSettings } from '../common/useGetUserSettings'
import {
  addChart,
  selectDashboard,
  selectDashboardChartData,
} from '../store/slice'
import DashboardTab from './DashboardTab'
import { EditMenu } from './EditMenu'
import { Header } from './Header'

export const LayoutDashboardTab = () => {
  const dispatch = useDispatch()

  const { defaultChartSettings } = useGetUserSettings()

  const { dashboardType, data = {} } = useSelector(selectDashboard)
  const dashboardChartData = useSelector(selectDashboardChartData)
  const activeTab = useSelector(selectChartTabActive)
  const chartTabs = useSelector(selectChartTabsData)

  const handleChangeTab = (value) => {
    dispatch(changeChartTabActive(+value))
  }

  const handleAddChart = () => {
    const newChartTabActive = Object.keys(chartTabs).length + 1
    const nameChart = getChartNameBySampleName(
      Object.values(chartTabs).map((i) => i.nameChart?.name),
      'Chart ',
    )
    const chartValue = {
      ...CHART_PARAMETERS_DEFAULT,
      nameChart: {
        ...CHART_PARAMETERS_DEFAULT.nameChart,
        name: nameChart,
      },
      settings:
        defaultChartSettings?.settings || CHART_PARAMETERS_DEFAULT.settings,
      styleSetting:
        defaultChartSettings?.styleSetting ||
        CHART_PARAMETERS_DEFAULT.styleSetting,
      order: getChartOrderByListChart(dashboardChartData),
    }

    dispatch(
      changeChartTabsData({
        [newChartTabActive]: chartValue,
      }),
    )
    dispatch(changeChartTabActive(newChartTabActive))
    dispatch(
      addChart({
        id: uuid(),
        dashboardId: data.id,
        name: nameChart,
        detail: JSON.stringify(chartValue),
        order: getChartOrderByListChart(dashboardChartData),
      }),
    )
  }

  return (
    <Layout>
      <SizeTracker>
        {(size) => (
          <>
            <div>
              <Header data={data} dashboardType={dashboardType} />
              <EditMenu />
            </div>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <DashboardTab />
              </div>
            )}
            <MenuFooterDashboard
              activeTab={activeTab}
              onChangeTab={handleChangeTab}
              onAddChart={handleAddChart}
              chartTabs={chartTabs}
              disableAddChart={
                !data ||
                dashboardChartData.length >= lengthChartInLayout[data?.layout]
              }
            />
          </>
        )}
      </SizeTracker>
    </Layout>
  )
}
