import { FORMAT, getCurrentDateTime } from '../../../utils/Datetime'

export const getLatestYearData = (yearNum = 5) => {
  const currentYear = getCurrentDateTime(FORMAT.YEAR)
  const years = []
  const startYear = +currentYear - yearNum + 1
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year)
  }

  return years
}
