import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import PanelFilter from '../../../common/panelFilter'
import { withWrapper } from '../../../common/withWrapper'
import { PanelComparisonChart } from './chart/PanelComparisonChart'
import { PanelGrowthTimelineChart } from './chart/PanelGrowthTimelineChart'
import { FinancialRatioFilter } from './filter'
import { PanelTable } from './table/PanelTable'

const MAP_KEY = {
  FINANCIAL_FILTER: 'FINANCIAL_FILTER',
  FINANCIAL_TABLE: 'FINANCIAL_TABLE',
  FINANCIAL_CHART_GROWTH_TIMELINE: 'FINANCIAL_CHART_GROWTH_TIMELINE',
  FINANCIAL_CHART_COMPARISON: 'FINANCIAL_CHART_COMPARISON',
}

const FILTER_WIDTH = 230

const MAP_SIZE = {
  [`${MAP_KEY.FINANCIAL_FILTER}`]: {
    width: FILTER_WIDTH - COMPONENT.MARGIN,
    height: `100%`,
    top: 0,
    left: 0,
    minWidth: FILTER_WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2,
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.FINANCIAL_TABLE,
        ratioWidth: 1,
        ratioLeft: -1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.FINANCIAL_TABLE}`]: {
    width: `calc(65% - ${FILTER_WIDTH * 0.65 + COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: FILTER_WIDTH + COMPONENT.MARGIN,
    minWidth:
      (COMPONENT.MIN_SIZE.WIDTH * 3.3 - FILTER_WIDTH) * 0.65 -
      COMPONENT.MARGIN * 2,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2,
    minTop: 0,
    minLeft: FILTER_WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.FINANCIAL_CHART_COMPARISON,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.FINANCIAL_CHART_GROWTH_TIMELINE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.FINANCIAL_CHART_GROWTH_TIMELINE}`]: {
    width: `calc(35% - ${FILTER_WIDTH * 0.35 + COMPONENT.MARGIN * 2}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(65% + ${FILTER_WIDTH * 0.35 + COMPONENT.MARGIN * 2}px`,
    minWidth:
      (COMPONENT.MIN_SIZE.WIDTH * 3.3 - FILTER_WIDTH) * 0.35 -
      COMPONENT.MARGIN * 2,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT,
    minTop: 0,
    minLeft:
      (COMPONENT.MIN_SIZE.WIDTH * 3.3 - FILTER_WIDTH) * 0.65 +
      FILTER_WIDTH +
      COMPONENT.MARGIN * 2,
    horizontalResize: [
      {
        key: MAP_KEY.FINANCIAL_CHART_COMPARISON,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.FINANCIAL_CHART_COMPARISON}`]: {
    width: `calc(35% - ${FILTER_WIDTH * 0.35 + COMPONENT.MARGIN * 2}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(65% + ${FILTER_WIDTH * 0.35 + COMPONENT.MARGIN * 2}px`,
    minWidth:
      (COMPONENT.MIN_SIZE.WIDTH * 3.3 - FILTER_WIDTH) * 0.35 -
      COMPONENT.MARGIN * 2,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft:
      (COMPONENT.MIN_SIZE.WIDTH * 3.3 - FILTER_WIDTH) * 0.65 +
      FILTER_WIDTH +
      COMPONENT.MARGIN * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelFilter
        title="corporate.financialData.common.FILTER"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FINANCIAL_FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <FinancialRatioFilter />
      </PanelFilter>

      <PanelTable
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelGrowthTimelineChart
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelComparisonChart
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
