export const convertData = (data, exportKey, importKey) => {
  let convertedData = []

  const exportData = data?.filter((item) => item.tType === exportKey)
  exportData.length &&
    exportData.sort((a, b) => parseFloat(b.value) - parseFloat(a.value))

  const importData = data?.filter((item) => item.tType === importKey)
  importData.length &&
    importData.sort((a, b) => parseFloat(b.value) - parseFloat(a.value))

  const convertedDataLength =
    exportData.length > importData.length
      ? exportData.length
      : importData.length

  for (let i = 0; i < convertedDataLength; i++) {
    convertedData.push({
      commodity_left: importData[i] && importData[i].name,
      price_left: importData[i] && importData[i].value,
      commodity_right: exportData[i] && exportData[i].name,
      price_right: exportData[i] && exportData[i].value,
      year: importData[i] ? importData[i].year : exportData[i].year,
      monthYearKey: importData[i]
        ? importData[i].monthYearKey
        : exportData[i].monthYearKey,
    })
  }

  return convertedData
}
