import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import { Input } from '../../common/html/Input'
import { Span } from '../../common/html/Span'
import Popup from '../../common/popup'
import { getNewDashboardData } from '../common/menuDashboard/helpers'
import { dashboardTypes, screenTypes } from '../constants'
import { changeDashboard, selectMyDashboard } from '../store/slice'
import { postDashboard, updateAndReloadDashboard } from '../store/thunk'
import style from './style.module.css'
import styles from './styleTheme.module.css'

const PopupCopyToNewDashboardGroup = ({ isShow, handleShow, dashboard }) => {
  const dispatch = useDispatch()
  const data = useSelector(selectMyDashboard)

  const [nameGroupDB, setNameGroupDB] = useState('')

  const onClose = () => {
    handleShow(false)
  }

  const onApply = async () => {
    if (!nameGroupDB.trim()) return
    await dispatch(
      postDashboard({ ...getNewDashboardData(data, true), name: nameGroupDB }),
    )
      .unwrap()
      .then(async (data) => {
        const params = {
          ...dashboard,
          parentId: data.id,
        }
        dispatch(updateAndReloadDashboard(params))
      })
    dispatch(
      changeDashboard({
        screenType: screenTypes.DASHBOARD,
        dashboardType: dashboardTypes.MY_DASHBOARD,
        data: dashboard,
      }),
    )
    onClose()
  }

  useEffect(() => {
    setNameGroupDB(getNewDashboardData(data, true)?.name || '')
  }, [])

  if (isShow) {
    return (
      <Popup overlayCustom={styles.overlayCustom}>
        <div
          className={`modal modal-small ${styles.modal}`}
          style={{ width: 470 }}
        >
          <div className={`modal-title ${styles.titleStyle}`}>
            <h3>
              <Span>
                <Translate value="financialChart.COPY_CHART_TO_NEW_DASHBOARD" />
              </Span>
            </h3>
            <a onClick={onClose} className="close-modal">
              <i className="icon-delete-stroke" style={{ color: '#646464' }} />
            </a>
          </div>
          <div className={`modal-content ${styles.modalContentStyle}`}>
            <div className={`${style.contentPopupCopy} mb-8`}>
              <Span className={styles.colorText}>
                <Translate value="financialChart.NEW_DASHBOARD_1" />
              </Span>
              <Input
                value={nameGroupDB}
                onChange={(e) => setNameGroupDB(e.target.value)}
                className={styles.inputStyle}
              />
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: 40 }}
            >
              <Button
                className={`btn w-80 h-20 ${styles.buttonGrayStyle}`}
                style={{ fontSize: 11 }}
                onClick={onClose}
              >
                <Translate value="financialChart.CANCEL" />
              </Button>
              <Button
                className={`btn w-80 h-20 ml-20 ${styles.buttonBlueStyle}`}
                style={{ fontSize: 11 }}
                onClick={onApply}
              >
                <Translate value="financialChart.OK" />
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    )
  }

  return null
}

export default PopupCopyToNewDashboardGroup
