export const VIEW_BY_CORP0RATE = {
  REVENUE: 'Revenue',
}

export const VIEW_BY_BANK = {
  LOAN: 'Loan',
  DEPOSIT: 'Deposit',
}

export const VIEW_BY_SECURITIES = {
  TOTAL_ASSET: 'TotalAsset',
  BROKERAGE_REVENUE: 'BrokerageRevenue',
}

export const MAP_KEY_CHART = {
  [VIEW_BY_CORP0RATE.REVENUE]: 'revenuePercentage',
  [VIEW_BY_BANK.DEPOSIT]: 'depositPercentage',
  [VIEW_BY_BANK.LOAN]: 'loanPercentage',
  [VIEW_BY_SECURITIES.TOTAL_ASSET]: 'totalAssetPercentage',
  [VIEW_BY_SECURITIES.BROKERAGE_REVENUE]: 'brokerageRevenuePercentage',
}

export const colorArray = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#0096eb',
  '#ecffdf',
]
