import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { COLOR_BAR } from './constants'

export const FooterPeersComparison = ({
  currentBarKeys,
  listCheckbox,
  setListCheckbox,
}) => {
  return (
    <Footer
      list={[
        {
          text: I18n.t('sector.sectorSpecific.steel.PRODUCTION'),
          type: TYPE.SQUARE,
          dataKey: currentBarKeys && currentBarKeys[0],
          before: {
            bgColor: COLOR_BAR.LIGHT_BLUE,
          },
        },
        {
          text: I18n.t('sector.sectorSpecific.steel.CONSUMPTION'),
          type: TYPE.SQUARE,
          dataKey: currentBarKeys && currentBarKeys[1],
          before: {
            bgColor: COLOR_BAR.BLUE,
          },
        },
        {
          text: I18n.t('sector.sectorSpecific.steel.INVENTORY'),
          type: TYPE.SQUARE,
          dataKey: currentBarKeys && currentBarKeys[2],
          before: {
            bgColor: COLOR_BAR.YELLOW,
          },
        },
      ]}
      listCheckbox={listCheckbox}
      setListCheckbox={setListCheckbox}
    />
  )
}
