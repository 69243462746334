import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valDivBillion } from '../../../../../../utils/Value'
import { getCorpRevenueChart } from './thunk'

const initialState = {
  data: [],
  loading: false,
}

const slice = createSlice({
  name: 'corporate/businessModel/penetration/corp/revenueChart',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeData: (state, action) => {
      state.data = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCorpRevenueChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCorpRevenueChart.fulfilled, (state, action) => {
      state.data =
        action.payload?.map((item) => ({
          ...item,
          rB1: valDivBillion(item.rB1),
          growth: item.growth * 100,
        })) || []
      state.loading = false
    })
    builder.addCase(getCorpRevenueChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

export const { resetStore, changeData } = slice.actions

register(slice.name, slice.reducer)
