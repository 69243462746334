import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { CellWithColor } from '../../../../common/table/CellWithColor'
import { calendarTabKey } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { TIME_FREQUENCY } from '../../../../constants/Datetime'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  TABLE_COL_FORMATS,
  TABLE_COL_KEYS,
  TABLE_NAV_DATA_FREQUENCY_KEYS,
  TABLE_NAV_DATA_KEYS,
} from './constants'

export const navDataSchema = ({ filter, locale }) => {
  const frequencyKeys = TABLE_NAV_DATA_FREQUENCY_KEYS.filter((item) => {
    if (
      (filter.frequency === calendarTabKey ||
        filter.frequency === TIME_FREQUENCY.DAILY) &&
      item.colId === TABLE_COL_KEYS.COL_DATE
    )
      return true

    if (
      !(
        filter.frequency === calendarTabKey ||
        filter.frequency === TIME_FREQUENCY.DAILY
      )
    ) {
      if (
        filter.frequency === TIME_FREQUENCY.WEEKLY &&
        item.colId === TABLE_COL_KEYS.COL_WEEK
      )
        return true

      if (
        filter.frequency === TIME_FREQUENCY.MONTHLY &&
        item.colId === TABLE_COL_KEYS.COL_MONTH
      )
        return true

      if (
        filter.frequency === TIME_FREQUENCY.QUARTERLY &&
        item.colId === TABLE_COL_KEYS.COL_QUARTER
      )
        return true

      if (
        [
          TABLE_COL_KEYS.COL_YEAR,
          TABLE_COL_KEYS.COL_FROM_DATE,
          TABLE_COL_KEYS.COL_TO_DATE,
        ].includes(item.colId)
      )
        return true
    }

    return false
  })

  return [...frequencyKeys, ...TABLE_NAV_DATA_KEYS].map((item) => {
    if (
      TABLE_NAV_DATA_FREQUENCY_KEYS.map((key) => key.colId).includes(item.colId)
    ) {
      return {
        ...item,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          fontSize: 12,
          fontWeight: 600,
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) =>
          [
            TABLE_COL_KEYS.COL_DATE,
            TABLE_COL_KEYS.COL_FROM_DATE,
            TABLE_COL_KEYS.COL_TO_DATE,
          ].includes(item.colId)
            ? formatDateTime(val, FORMAT.DATE, locale)
            : val,
      }
    }

    const result = {
      ...item,
      thStyle: {
        textAlign: 'right',
        fontSize: 12,
        fontWeight: 600,
      },
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (
      [
        TABLE_COL_KEYS.COL_OUTSTANDING_FUND_CERT,
        TABLE_COL_KEYS.COL_FOREIGN_VOLUME,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        thStyle: {
          textAlign: 'right',
          fontSize: 12,
          fontWeight: 600,
          maxWidth: 200,
          minWidth: 180,
          whiteSpace: 'normal',
        },
        render: (val) => formatVal(val, 0, true),
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_NAV_CERT,
        TABLE_COL_KEYS.COL_CLOSE_PRICE,
        TABLE_COL_KEYS.COL_DISCOUNT_VND,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        renderTh: () => (
          <div style={{ textAlign: 'right' }} className="flex-column">
            <Span style={{ whiteSpace: 'nowrap' }}>
              <Translate value={result.title} />
            </Span>
            <Span className="t-italic" style={{ fontWeight: 400 }}>
              (<Translate value="common.UNIT.VND" />)
            </Span>
          </div>
        ),
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) => formatVal(Math.round(val), 0)}
          />
        ),
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_CHANGE_NAV,
        TABLE_COL_KEYS.COL_FOREIGN_RATE,
        TABLE_COL_KEYS.COL_DISCOUNT_PERCENT,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        renderTh:
          item.colId !== TABLE_COL_KEYS.COL_CHANGE_NAV
            ? () => (
                <div style={{ textAlign: 'right' }} className="flex-column">
                  <Span style={{ whiteSpace: 'nowrap' }}>
                    <Translate value={result.title} />
                  </Span>
                  <Span className="t-italic" style={{ fontWeight: 400 }}>
                    (<Translate value="common.UNIT.PERCENT" />)
                  </Span>
                </div>
              )
            : undefined,
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) => valToPercent(val)}
            isPercent
          />
        ),
      }
    }

    if (
      [TABLE_COL_KEYS.COL_NAV, TABLE_COL_KEYS.COL_FUND_FLOW].includes(
        item.colId,
      )
    ) {
      return {
        ...result,
        isI18nThTooltip: true,
        thTooltipCustom:
          item.colId === TABLE_COL_KEYS.COL_FUND_FLOW &&
          'fund.fundProfile.FUND_FLOW_NOTE',
        renderTh: () => (
          <div style={{ textAlign: 'right' }} className="flex-column">
            <Span style={{ whiteSpace: 'nowrap' }}>
              <Translate value={result.title} />
            </Span>
            <Span className="t-italic" style={{ fontWeight: 400 }}>
              (<Translate value="common.UNIT.MILLION_VND" />)
            </Span>
          </div>
        ),
        render: (val) => formatVal(val / TABLE_COL_FORMATS.COL_NAV),
      }
    }

    return { ...result, render: (val) => formatVal(val, 0) }
  })
}

export const getParams = ({ organizationId, filters, isDownload = false }) => {
  let sortField =
    filters.sortField ||
    getSortFieldDefaultByFrequency({ frequency: filters.frequency })

  if (
    [
      TABLE_COL_KEYS.COL_DATE,
      TABLE_COL_KEYS.COL_WEEK,
      TABLE_COL_KEYS.COL_MONTH,
      TABLE_COL_KEYS.COL_QUARTER,
      TABLE_COL_KEYS.COL_YEAR,
    ].includes(sortField)
  ) {
    sortField = TABLE_COL_KEYS.COL_TO_DATE
  }

  let params = {
    OrganizationId: organizationId,
    SortField: sortField,
    SortOrder: filters.sortOrder,
    Frequency:
      filters.frequency === calendarTabKey
        ? TIME_FREQUENCY.CUSTOM
        : filters.frequency,
    From: filters.frequency === calendarTabKey ? filters.startDate : undefined,
    To: filters.frequency === calendarTabKey ? filters.endDate : undefined,
  }

  if (!isDownload) {
    params = {
      ...params,
      Page: filters.page,
      PageSize: 25,
    }
  }

  return params
}

export const getSortFieldDefaultByFrequency = ({ frequency }) => {
  switch (frequency) {
    case TIME_FREQUENCY.WEEKLY:
      return TABLE_COL_KEYS.COL_WEEK
    case TIME_FREQUENCY.MONTHLY:
      return TABLE_COL_KEYS.COL_MONTH
    case TIME_FREQUENCY.QUARTERLY:
      return TABLE_COL_KEYS.COL_QUARTER
    case TIME_FREQUENCY.YEARLY:
      return TABLE_COL_KEYS.COL_YEAR
    default:
      return TABLE_COL_KEYS.COL_DATE
  }
}
