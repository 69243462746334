export const titlePanel = {
  CPI_INFLATION: 'CPI & Inflation',
  CHANGES_INDICATORS: 'Changes in CPI & Inflation Indicators',
  CHANGES_OVER_YEARS: 'Growth over 5 years',
  CONTRIBUTION_OF_GOODS: 'Contributions of goods to CPI Index',
  OF: 'Of',
}

export const cpiAndInflationTable = {
  COMPARE: 'Comparison:',
  MOM: 'MoM',
  YOY: 'YoY',
  RTD: 'Base year',
  GROWTH_YTD: 'Growth YTD',
  ALL: 'All',
}
