import React from 'react'
import { useSelector } from 'react-redux'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { selectDataFDF } from '../../filter/store/slice'
import { LineChart } from '../LineChart'

const StockPerformanceVsIndexChart = () => {
  const data = useSelector(selectDataFDF)
  const dataTopInfo = useSelector(selectBasicInfo)

  return (
    <LineChart
      data={data.stockPerformanceVsIndex}
      width={350}
      height={250}
      fillColor={{
        percentPriceChange: '#97ba53',
        percentIndexChange: '#487eb9',
      }}
      margin={{ right: 25 }}
      leftKeys={['percentPriceChange', 'percentIndexChange']}
      xAxisKey="tradingDateId"
      leftLabel="(%)"
      mappingDisplayName={{
        percentPriceChange: dataTopInfo.ticker,
        percentIndexChange: 'VNINDEX',
      }}
      reversed={true}
    />
  )
}

StockPerformanceVsIndexChart.propTypes = {}

export default StockPerformanceVsIndexChart
