import { removeAccents, removeSpecialCharacter } from '../../utils/Search'

export const getOptionsBySearch = ({ search, options }) => {
  if (!search) return options

  return options.filter((item) => {
    return removeSpecialCharacter(removeAccents(item.name))
      .toLowerCase()
      .includes(
        removeSpecialCharacter(removeAccents(search)).trim().toLowerCase(),
      )
  })
}

export const compareArrayEqual = (array1, array2) => {
  return (
    array1.length === array2.length &&
    !array1.some((val) => !array2.includes(val))
  )
}
