import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CoveredWarrantService from '../../../../../../../core/services/market/CoveredWarrantService'
import { handleExport } from '../../../../../../utils/Export'

export const getProprietaryOverview = createAsyncThunk(
  'market/coveredWarrant/statistics/proprietary/getProprietaryOverview',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getProprietaryOverview(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getProprietaryDetail = createAsyncThunk(
  'market/coveredWarrant/statistics/proprietary/getProprietaryDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getProprietaryDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getProprietaryTicker = createAsyncThunk(
  'market/coveredWarrant/statistics/proprietary/getProprietaryTicker',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getProprietaryTicker(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getCoverWarrants = createAsyncThunk(
  'market/coveredWarrant/statistics/proprietary/getCoverWarrants',
  async (params, { rejectWithValue }) => {
    const response = await CoveredWarrantService.getCoverWarrants(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadProprietaryOverview = createAsyncThunk(
  'market/coveredWarrant/statistics/proprietary/downloadProprietaryOverview',
  async (data) => {
    const response = await CoveredWarrantService.downloadProprietaryOverview(
      data,
    )
    handleExport(response.data, response.filename)
  },
)

export const downloadProprietaryDetail = createAsyncThunk(
  'market/coveredWarrant/statistics/proprietary/downloadProprietaryDetail',
  async (data) => {
    const response = await CoveredWarrantService.downloadProprietaryDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadProprietaryTicker = createAsyncThunk(
  'market/coveredWarrant/statistics/proprietary/downloadProprietaryTicker',
  async (data) => {
    const response = await CoveredWarrantService.downloadProprietaryTicker(data)
    handleExport(response.data, response.filename)
  },
)
