import FinancialAnalysisProxy from '../../../proxies/sector/sectorConstituents/FinancialAnalysisProxy'
import { ServiceBase } from '../../ServiceBase'

class FinancialAnalysisService extends ServiceBase {
  // Bank
  getAssetQualityLoanStructure(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetQualityLoanStructure',
      params,
    )(() => FinancialAnalysisProxy.getAssetQualityLoanStructure(params))
  }

  getAssetQualityNplCoverage(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetQualityNplCoverage',
      params,
    )(() => FinancialAnalysisProxy.getAssetQualityNplCoverage(params))
  }

  getAssetQualityNplSml(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetQualityNplSml',
      params,
    )(() => FinancialAnalysisProxy.getAssetQualityNplSml(params))
  }

  getAssetQualityTable(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetQualityTable',
      params,
    )(() => FinancialAnalysisProxy.getAssetQualityTable(params))
  }

  getAssetQualityValueOfBondPortfolios(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetQualityValueOfBondPortfolios',
      params,
    )(() => FinancialAnalysisProxy.getAssetQualityValueOfBondPortfolios(params))
  }

  getAssetQualityLoanStructureInDepthComparison(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetQualityLoanStructureInDepthComparison',
      params,
    )(() =>
      FinancialAnalysisProxy.getAssetQualityLoanStructureInDepthComparison(
        params,
      ),
    )
  }

  downloadAssetQualityTable(params) {
    return this.getFileDownload(() =>
      FinancialAnalysisProxy.downloadAssetQualityTable(params),
    )
  }

  getCapitalStructureCasaRatio(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getCapitalStructureCasaRatio',
      params,
    )(() => FinancialAnalysisProxy.getCapitalStructureCasaRatio(params))
  }

  getCapitalStructureDepositStructure(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getCapitalStructureDepositStructure',
      params,
    )(() => FinancialAnalysisProxy.getCapitalStructureDepositStructure(params))
  }

  getCapitalStructureLdrRatio(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getCapitalStructureLdrRatio',
      params,
    )(() => FinancialAnalysisProxy.getCapitalStructureLdrRatio(params))
  }

  getCapitalStructureLoanStructure(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getCapitalStructureLoanStructure',
      params,
    )(() => FinancialAnalysisProxy.getCapitalStructureLoanStructure(params))
  }

  getCapitalStructureTable(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getCapitalStructureTable',
      params,
    )(() => FinancialAnalysisProxy.getCapitalStructureTable(params))
  }

  getCapitalStructureTier1Capital(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getCapitalStructureTier1Capital',
      params,
    )(() => FinancialAnalysisProxy.getCapitalStructureTier1Capital(params))
  }

  downloadCapitalStructureTable(params) {
    return this.getFileDownload(() =>
      FinancialAnalysisProxy.downloadCapitalStructureTable(params),
    )
  }

  getProfitabilityAndEfficiencyCostToIncomeRatio(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getProfitabilityAndEfficiencyCostToIncomeRatio',
      params,
    )(() =>
      FinancialAnalysisProxy.getProfitabilityAndEfficiencyCostToIncomeRatio(
        params,
      ),
    )
  }

  getProfitabilityAndEfficiencyLoanStructure(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getProfitabilityAndEfficiencyLoanStructure',
      params,
    )(() =>
      FinancialAnalysisProxy.getProfitabilityAndEfficiencyLoanStructure(params),
    )
  }

  getProfitabilityAndEfficiencyNetInterestMargin(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getProfitabilityAndEfficiencyNetInterestMargin',
      params,
    )(() =>
      FinancialAnalysisProxy.getProfitabilityAndEfficiencyNetInterestMargin(
        params,
      ),
    )
  }

  getProfitabilityAndEfficiencyNonInterestIncomePerformance(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getProfitabilityAndEfficiencyNonInterestIncomePerformance',
      params,
    )(() =>
      FinancialAnalysisProxy.getProfitabilityAndEfficiencyNonInterestIncomePerformance(
        params,
      ),
    )
  }

  getProfitabilityAndEfficiencyOperatingAndEarningGrowth(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getProfitabilityAndEfficiencyOperatingAndEarningGrowth',
      params,
    )(() =>
      FinancialAnalysisProxy.getProfitabilityAndEfficiencyOperatingAndEarningGrowth(
        params,
      ),
    )
  }

  getProfitabilityAndEfficiencyTable(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getProfitabilityAndEfficiencyTable',
      params,
    )(() => FinancialAnalysisProxy.getProfitabilityAndEfficiencyTable(params))
  }

  downloadProfitabilityAndEfficiencyTable(params) {
    return this.getFileDownload(() =>
      FinancialAnalysisProxy.downloadProfitabilityAndEfficiencyTable(params),
    )
  }

  // Security
  getEfficiency(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getEfficiency',
      params,
    )(() => FinancialAnalysisProxy.getEfficiency(params))
  }

  getGrowth(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getGrowth',
      params,
    )(() => FinancialAnalysisProxy.getGrowth(params))
  }

  getNonFinancialCompany(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getNonFinancialCompany',
      params,
    )(() => FinancialAnalysisProxy.getNonFinancialCompany(params))
  }

  getProfitability(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getProfitability',
      params,
    )(() => FinancialAnalysisProxy.getProfitability(params))
  }

  getStrength(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getStrength',
      params,
    )(() => FinancialAnalysisProxy.getStrength(params))
  }

  getValuation(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getValuation',
      params,
    )(() => FinancialAnalysisProxy.getValuation(params))
  }

  downloadNonFinancialCompany(params) {
    return this.getFileDownload(() =>
      FinancialAnalysisProxy.downloadNonFinancialCompany(params),
    )
  }

  getAssetsStructureAFSDecomposition(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetsStructureAFSDecomposition',
      params,
    )(() => FinancialAnalysisProxy.getAssetsStructureAFSDecomposition(params))
  }

  getAssetsStructureFVTPLSegmental(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetsStructureFVTPLSegmental',
      params,
    )(() => FinancialAnalysisProxy.getAssetsStructureFVTPLSegmental(params))
  }

  getAssetsStructureOrganizationPortfolio(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetsStructureOrganizationPortfolio',
      params,
    )(() =>
      FinancialAnalysisProxy.getAssetsStructureOrganizationPortfolio(params),
    )
  }

  getAssetsStructureFVTPLListedShareDecompositionAll(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetsStructureFVTPLListedShareDecompositionAll',
      params,
    )(() =>
      FinancialAnalysisProxy.getAssetsStructureFVTPLListedShareDecompositionAll(
        params,
      ),
    )
  }

  getAssetsStructureFVTPLListedShareDecompositionDetail(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetsStructureFVTPLListedShareDecompositionDetail',
      params,
    )(() =>
      FinancialAnalysisProxy.getAssetsStructureFVTPLListedShareDecompositionDetail(
        params,
      ),
    )
  }

  downloadAssetsStructureFVTPLListedShareDecompositionTableAll(params) {
    return this.getFileDownload(() =>
      FinancialAnalysisProxy.downloadAssetsStructureFVTPLListedShareDecompositionTableAll(
        params,
      ),
    )
  }

  downloadAssetsStructureFVTPLListedShareDecompositionTableDetail(params) {
    return this.getFileDownload(() =>
      FinancialAnalysisProxy.downloadAssetsStructureFVTPLListedShareDecompositionTableDetail(
        params,
      ),
    )
  }

  getAssetsStructureLoanAndMargin(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetsStructureLoanAndMargin',
      params,
    )(() => FinancialAnalysisProxy.getAssetsStructureLoanAndMargin(params))
  }

  getAssetsStructureTable(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getAssetsStructureTable',
      params,
    )(() => FinancialAnalysisProxy.getAssetsStructureTable(params))
  }

  downloadAssetsStructureDownloadTable(params) {
    return this.getFileDownload(() =>
      FinancialAnalysisProxy.downloadAssetsStructureDownloadTable(params),
    )
  }

  getPerformanceOperatingSale(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getPerformanceOperatingSale',
      params,
    )(() => FinancialAnalysisProxy.getPerformanceOperatingSale(params))
  }

  getPerformanceRevenueFromBrokerage(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getPerformanceRevenueFromBrokerage',
      params,
    )(() => FinancialAnalysisProxy.getPerformanceRevenueFromBrokerage(params))
  }

  getPerformanceRevenueFromInvestment(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getPerformanceRevenueFromInvestment',
      params,
    )(() => FinancialAnalysisProxy.getPerformanceRevenueFromInvestment(params))
  }

  getPerformanceRevenueFromProprietary(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getPerformanceRevenueFromProprietary',
      params,
    )(() => FinancialAnalysisProxy.getPerformanceRevenueFromProprietary(params))
  }

  getPerformanceTable(params) {
    return this.applyMemoryCache(
      'FinancialAnalysisService.getPerformanceTable',
      params,
    )(() => FinancialAnalysisProxy.getPerformanceTable(params))
  }

  downloadPerformanceDownloadTable(params) {
    return this.getFileDownload(() =>
      FinancialAnalysisProxy.downloadPerformanceDownloadTable(params),
    )
  }
}

export default new FinancialAnalysisService()
