import { createAsyncThunk } from '@reduxjs/toolkit'
import ConsumptionService from '../../../../../../core/services/economy/productionAndConsumption/ConsumptionService'
import { handleExport } from '../../../../../utils/Export'

export const getConsumptionDataTable = createAsyncThunk(
  'economy/productionAndConsumption/consumption/GET_DATA_TABLE_TOTAL',
  async (params, { rejectWithValue }) => {
    const responseTitle = await ConsumptionService.getListCriteriaByTimeRange({
      DataType: params?.DataType,
    })
    const responseTitleChartDetails =
      await ConsumptionService.getListCriteriaByTimeRange({
        ...params,
        Criteria: 'Contribution',
      })

    const response = await ConsumptionService.getCompareByTimeRange(params)
    if (
      response.success &&
      responseTitle.success &&
      responseTitleChartDetails.success
    ) {
      return {
        data: response.data,
        title: responseTitle.data,
        chartDetails: responseTitleChartDetails.data.slice(1),
      }
    }
    return rejectWithValue({ loading: false })
  },
)

export const getConsumptionDataTableNew = createAsyncThunk(
  'economy/productionAndConsumption/consumption/GET_DATA_TABLE_TOTAL_NEW',
  async (params, { rejectWithValue }) => {
    const response = await ConsumptionService.getCompareByTimeRange(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue({ loading: false })
  },
)

export const downloadConsumptionTable = createAsyncThunk(
  'economy/productionAndConsumption/consumption/DOWNLOAD_TABLE',
  async (data) => {
    const response = await ConsumptionService.downloadCompareByTimeRange(data)
    handleExport(response.data, response.filename)
  },
)
