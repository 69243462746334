import { uniq } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import TableDataExplorer from '../../../../common/dataExplorer/table'
import CellOrder from '../../../../common/dataExplorer/table/CellOrder'
import { Span } from '../../../../common/html/Span'
import useGetBond from '../../../../common/masterData/useGetBond'
import useGetStock from '../../../../common/masterData/useGetStock'
import { NoData } from '../../../../common/noData'
import ThePagination from '../../../../common/pagination/ThePagination'
import { INDICATOR_GROUPS } from '../../constants'
import {
  formatAddInformation,
  getSortFieldByIndicatorGroup,
} from '../../helper'
import {
  changeBondInformation,
  selectBondInformation,
  selectFilterParams,
  selectInitialParentSectors,
  selectInitialSectors,
  selectParentSectors,
  setFilterParameters,
} from '../../store/slice'
import {
  deleteBondData,
  getBondByIdData,
  updateBondData,
} from '../../store/thunk'
import PopupDeleteBond from '../Popup/PopupDeleteBond'
import PopupUpdateBond from '../Popup/PopupUpdateBond'
import { TdTicker } from './TdTicker'
import ThTable from './ThTable'
import style from './index.module.css'
import {
  changeLoadingTable,
  changeTickerDisplay,
  selectColumnCell,
  selectColumnId,
  selectFullColumnId,
  selectFullTickerDisplay,
  selectLoading,
  selectTickerCell,
  selectTickerDisplay,
  selectTickerId,
  selectTotal,
  swapTwoColumn,
} from './store/slice'
import { getDataBondList, getDataBondListBySort } from './store/thunk'

const activeSheet = 1

const TableContent = ({ width, redirectToBondIssuer }) => {
  const dispatch = useDispatch()
  const { stockById } = useGetStock(true)
  const { bondByOrganizationId } = useGetBond(true)

  // Use selector
  const loading = useSelector(selectLoading)
  const params = useSelector(selectFilterParams)
  const total = useSelector(selectTotal)
  const listIds = useSelector(selectFullColumnId(activeSheet))
  const listParent = useSelector(selectParentSectors)
  const initialSectors = useSelector(selectInitialSectors)
  const initialParentSectors = useSelector(selectInitialParentSectors)
  const bond = useSelector(selectBondInformation)

  const [isDelete, setIsDelete] = useState(null)

  const getOrganizationById = useCallback(
    (id) => {
      return stockById[id] || bondByOrganizationId[id]
    },
    [JSON.stringify(stockById), JSON.stringify(bondByOrganizationId)],
  )

  // Actions
  const changeIdDisplay = (ids) => dispatch(changeTickerDisplay(ids))

  const sortColumn = (idColumn, typeSort) => {
    dispatch(changeLoadingTable(true))

    const newSortBy =
      typeSort === 'default'
        ? getSortFieldByIndicatorGroup('', params?.indicatorGroup)
        : idColumn
    const newOrder = typeSort === 'default' ? 'desc' : typeSort
    const newSectors =
      uniq([...listParent, ...params.sectors])?.length ===
      uniq([...initialSectors, ...initialParentSectors])?.length
        ? []
        : uniq([...listParent, ...params.sectors])

    const data = {
      ...params,
      conditions: params.conditions,
      sectors: newSectors,
      addedInformations: formatAddInformation(
        params.addedInformations,
        listIds,
      ),
      sortBy: newSortBy,
      order: newOrder,
    }

    dispatch(getDataBondListBySort(data))
  }

  const swapColumn = (column1, column2) => {
    dispatch(swapTwoColumn({ column1, column2 }))
  }

  const changePage = (page) => {
    dispatch(changeLoadingTable(true))

    const newSectors =
      uniq([...listParent, ...params.sectors])?.length ===
      uniq([...initialSectors, ...initialParentSectors])?.length
        ? []
        : uniq([...listParent, ...params.sectors])

    dispatch(
      dispatch(
        getDataBondList({
          params: {
            ...params,
            sectors: newSectors,
            conditions: params.conditions,
            addedInformations: formatAddInformation(
              params.addedInformations,
              listIds,
            ),
            pageIndex: page,
          },
          redirectToBondIssuer,
        }),
      ),
    )
  }

  const handleEdit = (bondId) => {
    dispatch(getBondByIdData({ BondId: bondId }))
  }

  const cellInfo = useMemo(
    () => [
      {
        id: 'no',
        appendStyleTh: {
          minWidth: 0,
          maxWidth: 40,
          left: 0,
          textAlign: 'center',
          justifyContent: 'center',
          textTransform: 'uppercase',
        },
        appendStyle: {
          minWidth: 0,
          maxWidth: 40,
          left: 0,
          textAlign: 'center',
          textTransform: 'uppercase',
        },
        disableSort: true,
        renderTh: () => {
          return (
            <div className="d-flex h-100">
              <Span
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                <Translate value={'tool.dataExplorer.NO'} />
              </Span>
            </div>
          )
        },
        render: (_, __, ___, index) => <CellOrder index={index} />,
        isSticky: true,
      },
      {
        id:
          params?.indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
            ? 'Ticker'
            : 'BondTicker',
        appendStyleTh: {
          minWidth: 143,
          maxWidth: 143,
          left: 37,
          textTransform: 'uppercase',
        },
        appendStyle: {
          minWidth: 143,
          maxWidth: 143,
          left: 37,
          textTransform: 'uppercase',
        },
        renderTh: () => {
          return (
            <div className="d-flex h-100">
              <Span
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                <Translate
                  value={`bond.bondScreening.bondList.table.${
                    params?.indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
                      ? 'ticker'
                      : 'bondSticker'
                  }`}
                />
              </Span>
            </div>
          )
        },
        render: (bondId, idColumn, value) => {
          return (
            <TdTicker
              ticker={bondId}
              value={value}
              indicatorGroup={params?.indicatorGroup}
              getOrganizationById={getOrganizationById}
              handleEdit={() => handleEdit(bondId)}
              handleDelete={() =>
                setIsDelete({
                  ticker: value,
                  bondUserId: parseInt(bondId),
                })
              }
            />
          )
        },
        isSticky: true,
      },
    ],
    [params?.indicatorGroup],
  )

  const handleUpdateBond = (params) => {
    dispatch(updateBondData(params))
    dispatch(changeBondInformation(null))
  }

  const handleDeleteBond = (params) => {
    if (!params?.bondUserId) {
      setIsDelete(null)
      return
    }

    dispatch(deleteBondData(params))
    setIsDelete(null)
  }

  if (listIds === 0) {
    return <NoData />
  }

  return (
    <div style={{ marginTop: 12, height: '100%' }}>
      <TableDataExplorer
        isLoading={loading}
        maxColumn={250}
        selectColumnId={selectColumnId(activeSheet)}
        selectColumnCell={selectColumnCell(activeSheet)}
        maxRow={300}
        selectTickerCell={selectTickerCell(activeSheet)}
        selectId={selectTickerDisplay(activeSheet)}
        selectFullId={selectTickerId(activeSheet)}
        selectFullColumnId={selectFullColumnId(activeSheet)}
        changeIdDisplay={changeIdDisplay}
        selectIdDisplay={selectFullTickerDisplay(activeSheet)}
        cellInfo={cellInfo}
        sortColumn={sortColumn}
        swapColumn={swapColumn}
        renderTh={(title, colId, column) => {
          return <ThTable title={title} colId={colId} column={column} />
        }}
        renderContentPagination={() => (
          <div>
            <Translate
              value={'bond.bondScreening.bondList.table.total'}
              total={total}
            />
          </div>
        )}
        stateResetDefaultPage={activeSheet}
        noDataText=""
        customClassName={{
          thSticky: style.thSticky,
          columnSticky: style.tdSticky,
        }}
        widthColumn={'calc(100% + 10px)'}
        isCustormHeader
        isHide={false}
        pageSizePagination={300}
        changePage={(page) => changePage(page)}
        paginationComponent={
          <ThePagination
            currentPage={params?.pageIndex ?? 1}
            totalPages={total ? Math.ceil(total / 300) : 1}
            changeCurrentPage={(page) =>
              dispatch(setFilterParameters({ key: 'pageIndex', value: page }))
            }
            changePage={(page) => changePage(page)}
            loading={loading}
            renderContentPagination={() => (
              <Span
                style={{
                  color: '#85939C',
                  fontSize: 12,
                  fontStyle: 'italic',
                }}
              >
                <Translate
                  value="bond.bondScreening.bondList.table.total"
                  total={total}
                />
              </Span>
            )}
          />
        }
        heightPerView={900}
        widthPerView={params?.addedInformations?.length ?? undefined}
      />
      {bond && (
        <PopupUpdateBond
          handleClose={() => dispatch(changeBondInformation(null))}
          handleSubmit={handleUpdateBond}
          item={bond}
        />
      )}

      {isDelete && (
        <PopupDeleteBond
          data={isDelete}
          handleClose={() => setIsDelete(null)}
          handleSubmit={handleDeleteBond}
        />
      )}
    </div>
  )
}

export default TableContent
