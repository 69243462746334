import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Line } from 'recharts'
import { EXCHANGE_RATE_VOLATILITY } from '.'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import FooterWithScroll from '../../../../common/chart/footer/footerWithScroll'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import {
  getCheckboxChecked,
  getIds,
  getIndexChecked,
  getLineChartColor,
  getParentIdWithTitle,
  handleCheckboxChecked,
  handleIndexChecked,
  handleLineChartColor,
} from '../panelExchangeRate/store/slice'
import Tooltip from './Tooltip'
import {
  handleLineChartHasAuthor,
  handleLineColor,
  handleTooltipTwoColumn,
} from './helper'
import style from './index.module.css'
import { getActiveLine, handleActiveLine } from './store/slice'

const YAXIS_LEFT = 'left'

const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 1.5,
  strokeActiveWidth: 2.5,
  normalOrHover: 1,
  blur: 0.1,
}

const LineChart = ({
  data,
  width,
  height,
  keyXAxis,
  keyYAxisProps,
  dropdownValue,
  isFullScreen,
}) => {
  const dispatch = useDispatch()
  const legendPanel = useRef()

  const activeLine = useSelector(getActiveLine)
  const lineChartColor = useSelector(getLineChartColor)
  const ids = useSelector(getIds)
  const parentIdWithTitle = useSelector(getParentIdWithTitle)
  const indexChecked = useSelector(getIndexChecked)
  const checkboxChecked = useSelector(getCheckboxChecked)

  const [heightListIndicator, setHeightListIndicator] = useState(0)

  // Get data
  const idChecked = checkboxChecked.map((item) => `${item.id}`)
  const lineChartHasAuthor = handleLineChartHasAuthor(
    lineChartColor,
    ids,
    parentIdWithTitle,
    dropdownValue,
  )
  const { leftColumn, rightColumn } = handleTooltipTwoColumn(lineChartHasAuthor)

  // Actions
  const handleDelete = (item) => {
    const isChecked = idChecked.includes(item.author)
    const newCheckboxChecked = checkboxChecked.filter(
      (val) => `${val.id}` !== item.author,
    )
    const currentIndex = ids.findIndex((val) => val.id === item.author)
    const newIndexChecked = indexChecked.filter((item) => item !== currentIndex)

    dispatch(handleIndexChecked(newIndexChecked))
    dispatch(handleCheckboxChecked(newCheckboxChecked))
    dispatch(handleActiveLine(undefined))
    dispatch(
      handleLineChartColor(
        handleLineColor(item.author, lineChartColor, isChecked),
      ),
    )
  }

  const handleHover = (item) => {
    dispatch(handleActiveLine(`${item.author}-${dropdownValue}`))
  }

  const handleUnHover = () => {
    dispatch(handleActiveLine(undefined))
  }

  // Render
  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        <div className="d-flex">
          <div className={style.tooltipMaxWidth}>
            {leftColumn.length > 0 &&
              leftColumn.map((item, index) => {
                return <Tooltip key={index} item={item} payload={payload} />
              })}
          </div>
          <div className={`${style.tooltipMaxWidth} ml-8`}>
            {rightColumn.length > 0 &&
              rightColumn.map((item, index) => {
                return <Tooltip key={index} item={item} payload={payload} />
              })}
          </div>
        </div>
      </>
    )
  }

  const keyYAxis = [
    {
      id: YAXIS_LEFT,
      keys: keyYAxisProps,
      orientation: YAXIS_LEFT,
      isLineChart: true,
      label: '(VND)',
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: SETTINGS.yTickNum,
    },
  ]

  return (
    <div id={EXCHANGE_RATE_VOLATILITY}>
      <ChartContainer
        data={data}
        height={height - heightListIndicator}
        width={width}
        keyXAxis={keyXAxis}
        yAxis={keyYAxis}
        renderCustomTooltip={renderTooltip}
        timeFrame={TIME_RANGES.CUSTOM}
        isNotFormatXAxis
        margin={{ ...MARGIN_RECHARTS, right: 46 }}
      >
        {lineChartHasAuthor.map((item) => {
          return (
            <Line
              key={item.author}
              isAnimationActive={false}
              yAxisId={YAXIS_LEFT}
              dataKey={item.dataKey}
              stroke={item.color}
              strokeWidth={
                activeLine === item.dataKey && activeLine
                  ? SETTINGS.strokeActiveWidth
                  : SETTINGS.strokeWidth
              }
              dot={false}
              strokeOpacity={
                activeLine !== item.dataKey && activeLine
                  ? SETTINGS.blur
                  : SETTINGS.normalOrHover
              }
              activeDot={false}
              connectNulls={true}
            />
          )
        })}
      </ChartContainer>
      <FooterWithScroll
        width={width}
        data={lineChartHasAuthor}
        idKey="author"
        nameKey="indicatorName"
        color={(item) => item.color}
        legendPanel={legendPanel}
        isFullScreen={isFullScreen}
        setHeightFooter={setHeightListIndicator}
        onDelete={handleDelete}
        onHover={handleHover}
        onUnHover={handleUnHover}
      />
    </div>
  )
}

export default LineChart
