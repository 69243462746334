import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { SizeTracker } from '../../../common/sizeTracker'
import { Table } from '../../../common/table'
import { COM_GROUP, ICB_ID } from '../../../constants/Group'
import { getMaxDate } from '../averageTradingValue/store/thunk'
import { STICKIES_COL_ID, TOTAL_ID } from './constants'
import { FilterListTable } from './FilterListTable'
import { getSchema } from './helper'
import {
  selectCurrentCompaniesIds,
  selectData,
  selectDataById,
  selectDefaultLoading,
  selectFilter,
  selectGroupColumns,
  selectIds,
  selectListOrganizations,
  selectLoading,
  selectMaxDate,
  selectTopCompanyId,
  sort,
} from './store/slice'
import {
  getMarginGrowth,
  getOrganizationsInfo,
  getTopCompany,
} from './store/thunk'

const LIMIT_TOP_COMPANY = 20

export const MarginGrowth = ({ width, height }) => {
  const dispatch = useDispatch()

  const data = useSelector(selectData)
  const ids = useSelector(selectIds)
  const isLoading = useSelector(selectLoading)
  const listOrganizations = useSelector(selectListOrganizations)
  const groupColumns = useSelector(selectGroupColumns)
  const topCompanyId = useSelector(selectTopCompanyId)
  const filter = useSelector(selectFilter)
  const maxDate = useSelector(selectMaxDate)
  const currentCompaniesIds = useSelector(selectCurrentCompaniesIds)
  const defaultLoading = useSelector(selectDefaultLoading)
  const locale = useSelector((state) => state.i18n.locale)

  const getSchemaForTable = getSchema(groupColumns, listOrganizations)

  const currentQuarter = filter?.currentQuarter || maxDate?.quarter
  const currentYear = filter?.currentYear || maxDate?.year

  useEffect(() => {
    dispatch(getMaxDate({}))
  }, [])

  useEffect(() => {
    dispatch(
      getTopCompany({
        RealQuarter: currentQuarter,
        RealYear: currentYear,
        Limit: LIMIT_TOP_COMPANY,
      }),
    )
  }, [currentQuarter, currentYear])

  useEffect(() => {
    dispatch(getOrganizationsInfo({ ICBId: ICB_ID[COM_GROUP.SECURITIES][0] }))
  }, [locale])

  useEffect(() => {
    if (topCompanyId.length || currentCompaniesIds.length) {
      dispatch(
        getMarginGrowth({
          RealQuarter: currentQuarter,
          RealYear: currentYear,
          OrganIds: currentCompaniesIds.length
            ? currentCompaniesIds.filter((id) => id !== TOTAL_ID).toString()
            : topCompanyId.toString(),
        }),
      )
    }
  }, [topCompanyId, currentCompaniesIds, currentQuarter, currentYear, locale])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <div style={{ marginBottom: 10 }}>
              <FilterListTable
                currentQuarter={currentQuarter}
                currentYear={currentYear}
              />
            </div>
            {size.height &&
              (isLoading ||
              defaultLoading?.isListOrgLoading ||
              defaultLoading?.isTopCompanyLoading ? (
                <Loading />
              ) : !data.length || !topCompanyId.length ? (
                <div style={{ height: height - size.height - 10 }}>
                  <NoData />
                </div>
              ) : (
                listOrganizations.length &&
                topCompanyId.length && (
                  <div style={{ height: height - size.height - 10 }}>
                    <Table
                      ids={ids}
                      sort={sort}
                      getDataFromRedux={selectDataById}
                      isLoading={isLoading}
                      columnDraggable={false}
                      hasTooltip={false}
                      hasFooter={false}
                      schema={getSchemaForTable.schema}
                      rowSpanSchema={getSchemaForTable.rowSpanSchema}
                      tableHeadRowTop={getSchemaForTable.tableHeadRowTop}
                      stickyBottomRowCount={1}
                      stickies={{
                        [STICKIES_COL_ID.DELETE]: true,
                        [STICKIES_COL_ID.TICKER]: true,
                        [STICKIES_COL_ID.NAME]: true,
                      }}
                    />
                  </div>
                )
              ))}
          </>
        )}
      </SizeTracker>
    )
  )
}
