import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

class GDPSpendingMethodProxy extends ProxyBase {
  getGDPTypeName(params) {
    return this.get('GDPTypeName', params, '', true)
  }

  getGDPBySpendingMethod(params) {
    return this.get('GDPBySpendingMethod', params)
  }

  downloadGDPBySpendingMethodValue(params) {
    return this.get('DownloadGDPBySpendingMethodValue', params, 'download')
  }

  downloadGDPBySpendingMethodStructure(params) {
    return this.get('DownloadGDPBySpendingMethodStructure', params, 'download')
  }

  getGDPGrowthBySpendingMethod(params) {
    return this.post('GDPGrowthBySpendingMethod', params)
  }

  getGDPStructureBySpendingMethod(params) {
    return this.get('GDPStructureBySpendingMethod', params)
  }

  getGDPBySpendingMethodStructure(params) {
    return this.get('GDPBySpendingMethodStructure', params)
  }

  getMaxPeriod(params) {
    return this.get('MaxPeriod', params)
  }
}

export default new GDPSpendingMethodProxy(
  ServiceProxyConfig.Economy.GDP.SpendingMethod.ServiceUrl,
)
