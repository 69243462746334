import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { valToPercent } from '../../../../utils/Value'
import { selectSectorLever } from '../pricePerformance/tableSector/store/slice'
import { formatChartData } from './helper'
import style from './index.module.css'
import {
  selectLoadingMarketCapChart,
  selectMarketCapChart,
} from './store/slice'
import { getMarketCap } from './store/thunk'

export const MarketCap = ({ width, height }) => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const dataMarketCap = useSelector(selectMarketCapChart)
  const isLoading = useSelector(selectLoadingMarketCapChart)
  const levelSector = useSelector(selectSectorLever)

  useEffect(() => {
    dispatch(getMarketCap({ ICBLevel: levelSector }))
  }, [levelSector, locale])

  const dataFormat = formatChartData(
    dataMarketCap,
    'marketCapPercentage',
    'icbName',
  )

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {dataMarketCap.length ? (
            <SizeTracker>
              {(size) => {
                return (
                  <>
                    <div className="position-relative" id="marketCapId">
                      <div className={style.titleHeader}>
                        <Translate
                          dangerousHTML
                          value="sector.sectorStatistics.overview.MARKET_CAP_CHART"
                        />
                        {levelSector}
                      </div>
                      {size.height && (
                        <>
                          <PieChartWithFooter
                            data={dataFormat}
                            height={height - size.height}
                            width={width}
                            pieChartProps={{ radius: 30 }}
                            renderTooltip={() =>
                              makeToolTipChartContent(dataFormat)
                            }
                            isUseContainerFooter
                            footerProps={{ numRow: 2 }}
                          />
                        </>
                      )}
                    </div>
                  </>
                )
              }}
            </SizeTracker>
          ) : (
            <NoData />
          )}
        </>
      )}
    </>
  )
}

MarketCap.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

const makeToolTipChartContent = (data) => {
  return (
    <div className={style.chartTooltip}>
      <ul>
        {data.map((item, index) => {
          return (
            <li key={index}>
              <Span style={{ fontSize: 10 }}>
                {item.isI18n ? <Translate value={item.text} /> : item.text}:
              </Span>
              <Span style={{ fontSize: 10 }} className={style.chartTooltipText}>
                {valToPercent(item.value, true)}
              </Span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
