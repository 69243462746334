import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../common/calendar/headers/HeaderOneCalendarRange'
import { placeholderDateCalendar } from '../../../../common/calendar/helper'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import { positionDropdown } from '../../../../common/dropdown'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { getDateWithTimezone } from '../../../../utils/Datetime'
import '../../../index.css'
import { TREE_SECURITIES_INDICATOR_ID } from '../Menu'
import style from './style.module.css'

const DailySecurities = ({ disabled, value = {}, onChange }) => {
  const timeZone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)

  const handleChange = (date) =>
    onChange({ ...value, date: getDateWithTimezone(date, timeZone) } || null)

  return (
    <>
      <div
        className={`h-20 d-flex ali-center ${style.calendar}`}
        style={{ pointerEvents: disabled ? 'none' : '' }}
      >
        <CalendarPopper
          date={
            Object.keys(value).length && value.date ? new Date(value.date) : ''
          }
          handleChange={handleChange}
          customHeader={HeaderOneCalendarRange({
            startYear: 2000,
            endYear: new Date().getFullYear(),
            position: positionDropdown.TOP_CENTER,
            marginTop: 1,
            maxHeightDropdown: 160,
            themeWhite: true,
          })}
          CustomInput={InputDateCalendar}
          placeholder={placeholderDateCalendar(locale)}
          customInputStyle={{ fontSize: 10 }}
          scrollIds={[TREE_SECURITIES_INDICATOR_ID]}
          popperClassName={style.popperClassName}
          calendarClassName={'calender-white-theme'}
        />
        <i
          className="icon-date"
          style={{ pointerEvents: 'none', top: 'auto', fontSize: 11 }}
        />
      </div>
    </>
  )
}

CalendarPopper.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  handleChange: PropTypes.func,
}

CalendarPopper.defaultProps = {
  disabled: false,
  handleChange: () => {},
}

export default DailySecurities
