import { useEffect, useRef, useState } from 'react'
import { Loading } from './loading'

const withLoading = (Component) => (props) => {
  const timeout = useRef()

  const [isShowComponent, setIsShowComponent] = useState(false)

  useEffect(() => {
    timeout.current = setTimeout(() => setIsShowComponent(true), 400)
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  return (
    <div className="h-100 position-relative">
      {isShowComponent ? <Component {...props} /> : <Loading />}
    </div>
  )
}

export default withLoading
