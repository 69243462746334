import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FisheryService from '../../../../../../../core/services/sector/sectorSpecific/FisheryService'
import { handleExport } from '../../../../../../utils/Export'

export const getExporterStatistics = createAsyncThunk(
  'sector/sectorSpecific/fishery/exporter/getExporterStatistics',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FisheryService.getExporterStatistics(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getMaxDate = createAsyncThunk(
  'sector/sectorSpecific/fishery/exporter/getMaxDate',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getMaxDate(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getExporterDownloadStatistics = createAsyncThunk(
  'sector/sectorSpecific/fishery/export/getExporterDownloadStatistics',
  async (data) => {
    const response = await FisheryService.getExporterDownloadStatistics(data)
    handleExport(response.data, response.filename)
  },
)
