export const indicator = {
  TICKER: 'ticker',
  OWNER: 'owner',
  POSITION: 'position',
  TYPE: 'actionTypeName',
  TOTAL_EST_VALUE: 'totalEstValue',
  SHARE_REGISTERED: 'shareRegister',
  SHARE_ACQUIRED: 'shareAcquire',
  PERCENT_OWN: 'ownPercent',
  PUBLIC_DATE: 'publicDateId',
  END_DATE: 'endDateId',
  STATE: 'activeStatusName',
}

export const typesAll = {
  actionTypeId: 0,
  actionTypeName: 'market.marketInDepth.directorDeal.ALL_TYPE',
}

export const statusAll = {
  activeStatusId: 0,
  activeStatusName: 'market.marketInDepth.directorDeal.ALL_STATUS',
}

export const defaultSortTable = { publicDateId: 'desc' }

export const dateTypes = {
  TYPE_PUBLIC_DATE: 'publicDate',
  TYPE_END_DATE: 'endDate',
}

export const transactionTypes = {
  ALL_TRANSACTION: 'all',
  INSTITUTION: 'institution',
  INDIVIDUAL: 'individual',
  RELATED_PERSON: 'relatedPerson',
}
