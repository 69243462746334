import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Table } from '../../../../common/table'
import {
  selectDataFDF,
  selectPricePerformanceValue,
} from '../../filter/store/slice'

const PricePerformanceTable = () => {
  const data = useSelector(selectDataFDF)

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  return (
    <div style={{ height: dimensions.height }}>
      <Table
        ids={data.pricePerformance.ids}
        columnDraggable={false}
        getDataFromRedux={selectPricePerformanceValue}
        isLoading={false}
        rowDraggable={false}
        schema={data.pricePerformance.groupColumns.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
            tdStyle: {
              textAlign: 'right',
            },
            thStyle: {
              padding: 11,
            },
          }
          if (index === 0) {
            return {
              ...result,
              canCustomTd: true,
              render: (val) => (
                <td>
                  <Translate value={val} />
                </td>
              ),
            }
          }
          return result
        })}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        dimensionsTable={setDimensions}
        hasTooltip={false}
      />
    </div>
  )
}

export default PricePerformanceTable
