import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { useLocation } from 'react-router-dom'
import { FEATURES } from '../../constants/routes'
import CurrentState from '../../utils/CurrentState'
import { HandleClickOutside } from '../HandleClickOutside'
import Dropdown, { positionDropdown } from '../dropdown'
import useBasicInfo, { TYPE_STOCK_INDICES_SECTOR } from '../hooks/useBasicInfo'
import { Span } from '../html/Span'
import { HEIGHT_DROPDOWN, KEY_ACTION, WIDTH_DROPDOWN } from './constant'
import style from './index.module.css'

const defaultIcb = 2

const LinkSector = ({ children, icbId, disableActivate }) => {
  const location = useLocation()
  const { getBasicInfo } = useBasicInfo()

  const parentRef = useRef()

  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [isHoverText, setIsHoverText] = useState(false)
  const [position, setPosition] = useState()

  useEffect(() => {
    if (isOpenDropdown) {
      const table = parentRef.current.closest('.table-wrapper')
      if (table) {
        table
          .querySelector(`.scrollbars > div:first-child`)
          .addEventListener('scroll', handleCloseDropdown)
      }
      const bottom =
        window.innerHeight - parentRef.current?.getBoundingClientRect().bottom
      const right =
        window.innerWidth - parentRef.current?.getBoundingClientRect().right
      if (bottom > HEIGHT_DROPDOWN) {
        if (right > WIDTH_DROPDOWN) {
          setPosition(positionDropdown.BOTTOM_LEFT_RIGHT)
        } else {
          setPosition(positionDropdown.BOTTOM_RIGHT_LEFT)
        }
      } else {
        if (right > WIDTH_DROPDOWN) {
          setPosition(positionDropdown.TOP_LEFT_RIGHT)
        } else {
          setPosition(positionDropdown.TOP_RIGHT_LEFT)
        }
      }
    }
  }, [isOpenDropdown])

  const handleDropdown = (e) => {
    e.stopPropagation()
    setIsOpenDropdown(!isOpenDropdown)
  }

  const handleCloseDropdown = useCallback(() => {
    setIsOpenDropdown(false)
    const table = parentRef.current.closest('.table-wrapper')
    if (table) {
      table
        .querySelector(`.scrollbars > div:first-child`)
        .removeEventListener('scroll', handleCloseDropdown)
    }
  }, [])

  const overText = () => setIsHoverText(true)

  const outText = () => setIsHoverText(false)

  const handleOpenNewTab = (route, headerSearch) => {
    CurrentState.addRoute({
      key: location.pathname,
      data: {
        route: route,
        disableRecentVisited: true,
        headerSearch,
      },
    })
    window.open(route, '_blank')
    handleCloseDropdown()
  }

  const listAction = [
    {
      icon: <i className={`icon-sector ${style.icon}`} />,
      title: 'common.linkSectorTicker.SECTOR_OVERVIEW',
      action: () => {
        handleOpenNewTab(
          FEATURES.sector.components['sector-constituents'].components.overview
            .route,
          getBasicInfo(icbId, TYPE_STOCK_INDICES_SECTOR.SECTOR),
        )
      },
      key: KEY_ACTION.SECTOR_OVERVIEW,
    },
    {
      icon: <i className={`icon-chart-none ${style.icon}`} />,
      title: 'common.linkSectorTicker.FINANCIAL_ANALYSIS',
      action: () => {
        handleOpenNewTab(
          FEATURES.sector.components['sector-constituents'].components[
            'financial-analysis'
          ].route,
          getBasicInfo(icbId, TYPE_STOCK_INDICES_SECTOR.SECTOR),
        )
      },
      key: KEY_ACTION.FINANCIAL_ANALYSIS,
    },
    {
      icon: (
        <div className={`icon-segmentation1 ${style.iconSegmentation}`}>
          <i className={`icon-uniE94B ${style.iconBold}`} />
          <i className={`icon-uniE94C ${style.icon}`} />
          <i className={`icon-uniE94D ${style.iconBold}`} />
          <i className={`icon-uniE94E ${style.icon}`} />
        </div>
      ),
      title: 'common.linkSectorTicker.SEGMENTATION',
      action: () => {
        handleOpenNewTab(
          FEATURES.sector.components['sector-constituents'].components
            .segmentation.route,
          getBasicInfo(icbId, TYPE_STOCK_INDICES_SECTOR.SECTOR),
        )
      },
      key: KEY_ACTION.SEGMENTATION,
    },
    {
      icon: <i className={`icon-ranking ${style.icon}`} />,
      title: 'common.linkSectorTicker.RANKING',
      action: () => {
        handleOpenNewTab(
          FEATURES.sector.components['sector-constituents'].components.ranking
            .route,
          getBasicInfo(icbId, TYPE_STOCK_INDICES_SECTOR.SECTOR),
        )
      },
      key: KEY_ACTION.RANKING,
    },
    {
      icon: <i className={`icon-ratio ${style.icon}`} />,
      title: 'common.linkSectorTicker.RATIO',
      action: () => {
        handleOpenNewTab(
          FEATURES.sector.components['sector-constituents'].components.ratio
            .route,
          getBasicInfo(icbId, TYPE_STOCK_INDICES_SECTOR.SECTOR),
        )
      },
      key: KEY_ACTION.RATIO,
    },
    {
      icon: <i className={`icon-valuation ${style.icon}`} />,
      title: 'common.linkSectorTicker.VALUATION',
      action: () => {
        handleOpenNewTab(
          FEATURES.sector.components['sector-constituents'].components.valuation
            .route,
          getBasicInfo(icbId, TYPE_STOCK_INDICES_SECTOR.SECTOR),
        )
      },
      key: KEY_ACTION.VALUATION,
    },
  ]

  return (
    <>
      <span
        ref={parentRef}
        className={`cursor-pointer ${isHoverText ? style.active : ''}`}
        onClick={handleDropdown}
        onMouseOver={overText}
        onMouseOut={outText}
      >
        {children}
      </span>
      {isOpenDropdown && (
        <Dropdown
          parentRef={parentRef}
          isShow={isOpenDropdown}
          position={position}
        >
          <div
            className={`box-notice ${
              position === positionDropdown.BOTTOM_LEFT_RIGHT
                ? style.dropdownBottom
                : position === positionDropdown.TOP_LEFT_RIGHT
                ? style.dropdownTop
                : position === positionDropdown.BOTTOM_RIGHT_LEFT
                ? style.dropdownBottomRight
                : style.dropdownTopRight
            }`}
          >
            <HandleClickOutside
              exceptRef={parentRef}
              handleClickOutside={handleCloseDropdown}
            >
              <>
                {listAction.map((item) => (
                  <div
                    key={item.key}
                    onClick={(e) => {
                      e.stopPropagation()
                      item.action()
                    }}
                    className={`${style.dropdownItem} cursor-pointer d-flex ali-center`}
                    style={{
                      opacity: disableActivate.includes(item.key) ? 0.4 : 1,
                      pointerEvents: disableActivate.includes(item.key)
                        ? 'none'
                        : 'auto',
                    }}
                  >
                    <div className={style.itemIcon}>{item.icon}</div>
                    <Span>
                      <p className={style.itemTitle}>
                        <Translate value={item.title} />
                      </p>
                    </Span>
                  </div>
                ))}
              </>
            </HandleClickOutside>
          </div>
        </Dropdown>
      )}
    </>
  )
}

LinkSector.propTypes = {
  children: PropTypes.node.isRequired,
  icbId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disableActivate: PropTypes.array,
}

LinkSector.defaultProps = {
  icbId: defaultIcb,
  disableActivate: [],
}

export default LinkSector
