import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valDivMillion } from '../../../../../../utils/Value'
import { getTopExporters } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/fishery/corporateExport/topExport',
  initialState: {
    isLoading: true,
    dataChart: [],
    year: null,
  },
  reducers: {
    changeYear: (state, action) => {
      state.year = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopExporters.pending, (state) => {
      state.isLoading = true
      state.dataChart = []
    })
    builder.addCase(getTopExporters.fulfilled, (state, action) => {
      state.isLoading = false
      const data =
        action.payload?.map((item) => ({
          ...item,
          id: item.organizationId,
          name: item.organizationShortName,
          fullName: item.organizationName,
          month: item.realMonth,
          year: item.realYear,
          value: valDivMillion(item.accumulatedValue),
        })) || []
      state.dataChart = data
    })
    builder.addCase(getTopExporters.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].dataChart
export const selectYear = (state) => state[slice.name].year

export const { changeYear } = slice.actions

register(slice.name, slice.reducer)
