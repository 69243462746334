export const penetrationAnalysis = {
  PENETRATION_ANALYSIS: 'Phân tích thị phần',
  FILTER: 'Bộ lọc',
  MARGIN_LENDING: 'Cho vay ký quỹ',
  PROPRIETARY: 'Tự doanh',
  TREASURY: 'Kinh doanh nguồn vốn',
  QUARTER_1: 'Quý 1',
  QUARTER_2: 'Quý 2',
  QUARTER_3: 'Quý 3',
  QUARTER_4: 'Quý 4',
  ANNUAL: 'Cả năm',
  DETAIL_METRICS: 'Thông số cụ thể',
  MARKET_SHARE: 'Thị phần',
  LABEL_CHART_CHANGE: 'Thay đổi Thị phần YTD',
  TIME: 'Thời gian',
  MILLION: '(Triệu)',
  BILLION: '(Tỷ VND)',
  BILLION_TOOLTIP: 'Tỷ VND',
  YOY_GROWTH: 'Tăng trưởng YoY',
  LABEL_CHART_MARGIN_LENDING: 'Dư nợ cho vay ký quỹ & Tăng trưởng YoY',
  LABEL_CHART_PROPRIETARY: 'Giá trị danh mục tự doanh & Tăng trưởng YoY',
  LABEL_CHART_TREASURY:
    'Giá trị danh mục kinh doanh nguồn vốn & Tăng trưởng YoY',
  MARGIN_LENDING_OWNER_EQUITY: 'Cho vay ký quỹ/ Vốn chủ sở hữu',
  MARGIN_LENDING_TOTAL_ASSETS: 'Cho vay ký quỹ/Tổng tài sản',
  YOY_GROWTH_PERCENT: 'Tăng trưởng YoY (%) ',
  OTHERS: 'Khác',
  MARGIN_LENDING_MARKET_SHARE: 'Thị phần cho vay ký quỹ',
  PROPRIETARY_MARKET_SHARE: 'Thị phần tự doanh',
  TREASURY_MARKET_SHARE: 'Thị phần kinh doanh nguồn vốn',
  MARGIN_LENDING_CHANGE_IN_MARKET_SHARE: 'Thay đổi thị phần cho vay ký quỹ YTD',
  PROPRIETARY_CHANGE_IN_MARKET_SHARE: 'Thay đổi thị phần tự doanh YTD',
  TREASURY_CHANGE_IN_MARKET_SHARE: 'Thay đổi thị phần kinh doanh nguồn vốn YTD',
  billionUnit: '(Tỷ VND)',
  //Corporate
  REVENUE_BREAKDOWN: 'Cơ cấu doanh thu',
  REVENUE: 'Doanh thu',
  GROWTH: 'Tăng trưởng YoY',
  REVENUE_SECTOR: 'Doanh thu ngành',
  COMPANY: 'Công ty',
  corpTable: {
    iS3: 'Doanh thu thuần (Tỷ VND)',
    growth: 'Tăng trưởng YoY',
    rB1: 'Các mảng doanh thu',
    marketShare: 'Thị phần',
  },
  //Insurance
  INSU_REVENUE_CLAIM: 'Doanh thu & Chi phí bồi thường',
  INSU_REVENUE: 'Doanh thu phí bảo hiểm',
  INSU_CLAIM_EXPENSES: 'Chi phí bồi thường bảo hiểm',
  INSU_CLAIM_COMBINED: 'Tỷ lệ bồi thường & Tỷ lệ kết hợp',
  INSU_CLAIM_RATIO: 'Tỷ lệ bồi thường',
  INSU_COMBINED_RATIO: 'Tỷ lệ kết hợp',
  insuTable: {
    TPIS1: 'Thị phần',
    YoYGrowthIS1: 'Thay đổi thị phần YTD (%)',
    NI153: 'Thu phí bảo hiểm (Tỷ VND)',
    GWP: 'Tăng trưởng (%YoY)',
    NI154: 'Bảo hiểm nhân thọ (Tỷ VND)',
    LIG: 'Tăng trưởng (%YoY)',
    NI155: 'Bảo hiểm phi nhân thọ (Tỷ VND)',
    NLIG: 'Tăng trưởng (%YoY)',
    IS73: 'Chi phí bồi thường (Tỷ VND)',
    RT0338: 'Tỷ lệ bồi thường',
    RT0339: 'Tỷ lệ kết hợp',
  },
  //Bank
  LOAN: 'CHO VAY',
  DEPOSIT: 'TIỀN GỬI',
  LOAN_TO_CUSTOMER_TITLE: 'Cho vay khách hàng & Nợ xấu',
  LOAN_TO_CUSTOMER: 'Cho vay khách hàng',
  LOAN_TO_CUSTOMER_LABEL: 'Cho vay khách hàng (Tỷ VND)',
  LOAN_MARKET_SHARE: 'Thị phần cho vay',
  LOAN_CHART_CHANGE: 'Thay đổi thị phần cho vay YTD',
  DEPOSIT_CHART_CHANGE: 'Thay đổi thị phần tiền gửi YTD',
  LOAN_NON_PERFORMING: 'Nợ xấu',
  LOAN_NON_PERFORMING_LABEL: 'Nợ xấu (Tỷ VND)',
  LOAN_BY_SECTOR: 'Cho vay theo ngành',
  loanSector: {
    trading: 'Thương mại',
    agricultureForestry: 'Nông nghiệp và lâm nghiệp',
    manufacturing: 'Sản xuất',
    construction: 'Xây dựng',
    personalPublicServices: 'Dịch vụ cộng đồng và cá nhân',
    warehousing: 'Kho bãi, vận tải, viễn thông',
    trainingEducation: 'Giáo dục và đào tạo',
    estateAgent: 'Bất động sản và tư vấn',
    hotelRestaurants: 'Khách sạn và nhà hàng',
    financialServices: 'Dịch vụ tài chính',
    others: 'Các ngành khác',
  },
  MARKET_SHARE_BY_LOAN_SECTOR: 'Thị phần cho vay theo ngành',
  DEPOSIT_FROM_CUSTOMER_TITLE: 'Tiền gửi Khách hàng & Tỷ lệ Cho vay / Tiền gửi',
  DEPOSIT_FROM_CUSTOMER: 'Tiền gửi Khách hàng',
  LOAN_TO_DEPOSIT_RATIO: 'Tỷ lệ Cho vay / Tiền gửi',
  DEPOSIT_MARKET_SHARE: 'Thị phần huy động tiền gửi',
  CASA_RATIO_TITLE: 'CASA & Tỷ lệ CASA',
  CASA: 'CASA',
  CASA_RATIO: 'Tỷ lệ CASA',
  CASA_MARKET_SHARE: 'Thị phần CASA',
  depositRow: {
    deposit: 'Tiền gửi khách hàng',
    depositYoYGrowth: 'Tăng trưởng YoY',
    depositMarketShare: 'Thị phần huy động tiền gửi',
    depositChangeInMarketShare: 'Thay đổi Thị phần tiền gửi YTD',
    casa: 'CASA',
    casaYoYGrowth: 'Tăng trưởng YoY',
    casaMarketShare: 'Thị phần CASA',
    casaChangeInMarketShare: 'Thay đổi Thị phần CASA YTD',
  },
}

export const segmentalAnalysis = {
  SEGMENTAL_ANALYSIS: 'Phân tích kinh doanh',
  INCOME_STRUCTURE: 'Cấu trúc lợi nhuận',
  GROWTH_TIMELINE: 'Tăng trưởng theo thời gian',
  PEERS_COMPARISON: 'So sánh cùng ngành',
  OF: 'của',
  BILLION_VND: '(Tỷ VND)',
  UNIT_BILLION_VND: 'Đơn vị: Tỷ VND',
  VALUE: 'Giá trị',
  VALUE_PERCENT: '%Giá trị',
  PROPORTION: '% Tỷ trọng',
  IN: 'trong năm',
  FOR_THE_FIRST: ' ',
  MONTH_OF: 'Tháng đầu năm',
  TOTAL_OPERATING_INCOME: 'Tổng thu nhập hoạt động',
  ANNUAL: 'Hàng năm',
}

export const costOfOperation = {
  YEARLY: 'Hàng năm',
  QUARTERLY: 'Hàng quý',
  BILLION: '(Tỷ VND)',
  REVENUE_COST_TITLE: 'Doanh thu & Chi phí hoạt động',
  COST_OF_OPERATION: 'Chi phí hoạt động',
  REVENUE: 'Doanh thu',
  COST_PER_REVENUE: 'Chi phí/ Doanh thu',
  COST_STRUCTURE: 'Cấu trúc chi phí',
  DETAIL_METRICS: 'Thông số cụ thể',
  DETAIL_METRIC_UNIT: 'Đơn vị: Tỷ VND',
  PARTICULAR: 'Tăng trưởng',
  indicators: {
    rawMaterials: 'Chi phí nguyên liệu, vật liệu',
    labourCosts: 'Chi phí nhân công',
    depreciation: 'Chi phí khấu hao tài sản cố định',
    outsideService: 'Chi phí dịch vụ mua ngoài',
    otherCash: 'Chi phí khác bằng tiền',
  },
  GROWTH_PERCENT: 'Tăng trưởng YoY (%)',
  PORTION_PERCENT: 'Tỷ trọng (%)',
  GROWTH: 'Tăng trưởng YoY',
  CHART_BILLION_VND: 'Tỷ VND',
}
