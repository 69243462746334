import moment from 'moment'
import { I18n, Translate } from 'react-redux-i18n'
import {
  DEFAULT_PRE_VALUE_KEY,
  DEFAULT_XAXIS_DATA_KEY,
} from '../../../../../common/chart/basicChart/constants'
import { CHART_TYPES } from '../../../../../common/chart/constants'
import { Span } from '../../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import { VALUE_TYPES } from '../constants'

const CHART_KEYS = {
  BOND: 'faR12',
  STOCK: 'faR7',
  CASH_AND_OTHER: 'others',
}

export const formatAssetDataApi = (data) => {
  const total = (item) =>
    item[CHART_KEYS.BOND] +
    item[CHART_KEYS.STOCK] +
    item[CHART_KEYS.CASH_AND_OTHER]

  return data
    .map((item) => ({
      [DEFAULT_XAXIS_DATA_KEY]: formatDateTime(
        new Date(item.reportYear, item.reportMonth - 1).toString(),
        FORMAT.MONTH_YEAR_NUMBER,
      ),
      ...Object.values(CHART_KEYS).reduce(
        (obj, key, index) => ({
          ...obj,
          [`${DEFAULT_PRE_VALUE_KEY}${index + 1}`]: item[key] / total(item),
          [`${DEFAULT_PRE_VALUE_KEY}${index + 1}Raw`]: item[key] / 10 ** 9,
        }),
        {},
      ),
    }))
    .sort(
      (a, b) =>
        moment(a[DEFAULT_XAXIS_DATA_KEY], 'MM-YYYY').unix() -
        moment(b[DEFAULT_XAXIS_DATA_KEY], 'MM-YYYY').unix(),
    )
}

const getRenderTooltipValue = (value, percent, isRelative) => (
  <div className="d-flex ali-center">
    <Span
      style={{
        fontSize: 11,
        fontWeight: 500,
      }}
    >
      {formatVal(value)}
    </Span>
    <Span
      style={{
        fontSize: 11,
        fontWeight: 500,
      }}
      className="ml-4"
    >
      <Translate value="common.UNIT.BILLION_VND" />
    </Span>
    {isRelative && (
      <Span
        style={{
          fontSize: 11,
          fontWeight: 500,
        }}
        className="ml-4"
      >
        ({valToPercent(percent)})
      </Span>
    )}
  </div>
)

const getSchema = (valueType) => [
  {
    text: 'fund.fundProfile.portfolio.BOND',
    isI18n: true,
    dataKey:
      valueType === VALUE_TYPES.RELATIVE
        ? `${DEFAULT_PRE_VALUE_KEY}1`
        : `${DEFAULT_PRE_VALUE_KEY}1Raw`,
    color: '#FACC5C',
    chart: {
      type: CHART_TYPES.STACK_BAR,
    },
    footer: {
      before: {
        bgColor: '#FACC5C',
      },
    },
    tooltip: {
      renderTitle: () => <Translate value="fund.fundProfile.portfolio.BOND" />,
      renderValue: (value) =>
        getRenderTooltipValue(
          value[`${DEFAULT_PRE_VALUE_KEY}1Raw`],
          value[`${DEFAULT_PRE_VALUE_KEY}1`],
          valueType === VALUE_TYPES.RELATIVE,
        ),
    },
  },
  {
    text: 'fund.fundProfile.portfolio.STOCK',
    isI18n: true,
    dataKey:
      valueType === VALUE_TYPES.RELATIVE
        ? `${DEFAULT_PRE_VALUE_KEY}2`
        : `${DEFAULT_PRE_VALUE_KEY}2Raw`,
    color: '#FFF6DF',
    chart: {
      type: CHART_TYPES.STACK_BAR,
    },
    footer: {
      before: {
        bgColor: '#FFF6DF',
      },
    },
    tooltip: {
      renderTitle: () => <Translate value="fund.fundProfile.portfolio.STOCK" />,
      renderValue: (value) =>
        getRenderTooltipValue(
          value[`${DEFAULT_PRE_VALUE_KEY}2Raw`],
          value[`${DEFAULT_PRE_VALUE_KEY}2`],
          valueType === VALUE_TYPES.RELATIVE,
        ),
    },
  },
  {
    text: 'fund.fundProfile.portfolio.CASH_AND_OTHER',
    isI18n: true,
    dataKey:
      valueType === VALUE_TYPES.RELATIVE
        ? `${DEFAULT_PRE_VALUE_KEY}3`
        : `${DEFAULT_PRE_VALUE_KEY}3Raw`,
    color: '#185999',
    chart: {
      type: CHART_TYPES.STACK_BAR,
    },
    footer: {
      before: {
        bgColor: '#185999',
      },
    },
    tooltip: {
      renderTitle: () => (
        <Translate value="fund.fundProfile.portfolio.CASH_AND_OTHER" />
      ),
      renderValue: (value) =>
        getRenderTooltipValue(
          value[`${DEFAULT_PRE_VALUE_KEY}3Raw`],
          value[`${DEFAULT_PRE_VALUE_KEY}3`],
          valueType === VALUE_TYPES.RELATIVE,
        ),
    },
  },
]

export const getAssetProps = ({ valueType, isFullScreen }) => {
  const schema = getSchema(valueType)
  return {
    schema,
    chartProps: {
      chartType: 'ComposedChart',
      yAxis: [
        {
          dataKeys: schema.map(
            (_, index) =>
              `${DEFAULT_PRE_VALUE_KEY}${index + 1}${
                valueType === VALUE_TYPES.RELATIVE ? '' : 'Raw'
              }`,
          ),
          labelText:
            valueType === VALUE_TYPES.RELATIVE
              ? ''
              : I18n.t('common.UNIT.BILLION_VND'),
          tickValues:
            valueType === VALUE_TYPES.RELATIVE
              ? [0, 0.25, 0.5, 0.75, 1]
              : undefined,
          tickFormatter:
            valueType === VALUE_TYPES.RELATIVE
              ? (value) => valToPercent(value, false, false, 0)
              : undefined,
          isStackedBar: true,
        },
      ],
    },
    footerProps: { isFullScreen },
    tooltipProps: {
      title: (value) => value[DEFAULT_XAXIS_DATA_KEY],
    },
  }
}
