import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { INDICATOR_TYPE } from '../constant'
import style from '../index.module.css'
import {
  changeShowPopupIndicator,
  selectIndicatorInfoById,
  selectYIndicator,
} from '../store/slice'

const YAxis = () => {
  const dispatch = useDispatch()

  const indicatorId = useSelector(selectYIndicator)
  const indicatorName = useSelector(
    selectIndicatorInfoById(indicatorId, 'indicatorName'),
  )

  const inputText =
    indicatorName === EMPTY_VALUE
      ? I18n.t('sector.sectorConstituents.segmentation.CHOOSE_INDICATOR')
      : indicatorName

  const handleClick = () => {
    dispatch(
      changeShowPopupIndicator({
        isShow: true,
        popupType: INDICATOR_TYPE.Y,
      }),
    )
  }

  return (
    <div className={style.mt24}>
      <Span className={style.disabled} style={{ fontSize: 11 }}>
        <Translate value="sector.sectorConstituents.segmentation.Y_ASIS" />
      </Span>
      <div
        className={`form-search-inbox ${style.formSearch}`}
        onClick={handleClick}
      >
        <Input
          style={{ fontSize: 10 }}
          type="text"
          className="search-inbox h-20 cursor-pointer"
          value={inputText}
          readOnly
        />
        <button type="button">
          <i className="icon-search-2" />
        </button>
      </div>
    </div>
  )
}

export default YAxis
