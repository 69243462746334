import { createAsyncThunk } from '@reduxjs/toolkit'
import { I18n, Translate } from 'react-redux-i18n'
import CompanySnapshotService from '../../../../../core/services/corporate/CompanySnapshotService'
import UserSettingService from '../../../../../core/services/UserSettingService'
import EventEmitter, {
  BUTTON_TEXT_POPUP_ERROR,
  ICON_STATUS_POPUP_ERROR,
  IS_SHOW_BUTTON_ERROR,
  SHOW_POPUP_ERROR,
} from '../../../../utils/EventEmitter'

export const getOverview = createAsyncThunk(
  'corporate/companySnapshot/GET_OVERVIEW',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getOverview(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getRevenueBreakdown = createAsyncThunk(
  'corporate/companySnapshot/get_Revenue_Breakdown',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getRevenueBreakdown(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getCompareWithSector = createAsyncThunk(
  'corporate/companySnapshot/GET_COMPARE_WITH_SECTOR',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getCompareWithSector(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getStockData = createAsyncThunk(
  'corporate/companySnapshot/GET_STOCK_DATA',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getStockData(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getStockData1Yrs = createAsyncThunk(
  'corporate/companySnapshot/GET_STOCK_DATA_1_YRS',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getStockData1Yrs(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getFinancialRatio = createAsyncThunk(
  'corporate/companySnapshot/GET_FINANCIAL_RATIO',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getFinancialRatio(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getValuationHistory = createAsyncThunk(
  'corporate/companySnapshot/GET_VALUATION_HISTORY',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getValuationHistory(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getPricePerformanceChart = createAsyncThunk(
  'corporate/companySnapshot/GET_PRICE_PERFORMANCE_CHART',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getPricePerformanceChart(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getPeerAnalysis = createAsyncThunk(
  'corporate/companySnapshot/GET_PEER_ANALYSIS',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getPeerAnalysis(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getKeyFinancialData = createAsyncThunk(
  'corporate/companySnapshot/GET_KEY_FINANCIAL_DATA',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getKeyFinancialData(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getFinancialData = createAsyncThunk(
  'corporate/companySnapshot/GET_FINANCIAL_DATA',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getFinancialData(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getOwnershipAnalysis = createAsyncThunk(
  'corporate/companySnapshot/GET_OWNERSHIP_ANALYSIS',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getOwnershipAnalysis(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getTop5MajorShareHolder = createAsyncThunk(
  'corporate/companySnapshot/GET_TOP_5_MAJOR_SHAREHOLDER',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getTop5MajorShareHolder(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getPriceData = createAsyncThunk(
  'corporate/companySnapshot/GET_PRICE_DATA',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getPriceData(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getStockPerformanceAndIndex = createAsyncThunk(
  'corporate/companySnapshot/GET_STOCK_PERFORMANCE_AND_INDEX',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getStockPerformanceAndIndex(
      data,
    )
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getPricePerformance = createAsyncThunk(
  'corporate/companySnapshot/GET_PRICE_PERFORMANCE',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getPricePerformance(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getForeignNetTrading = createAsyncThunk(
  'corporate/companySnapshot/GET_FOREIGN_NET_TRADING',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getForeignNetTrading(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getIncomeStatement = createAsyncThunk(
  'corporate/companySnapshot/GET_INCOME_STATEMENT',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getIncomeStatement(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getBalanceStatement = createAsyncThunk(
  'corporate/companySnapshot/GET_BALANCE_STATEMENT',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getBalanceStatement(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getReportDate = createAsyncThunk(
  'corporate/companySnapshot/GET_REPORT_DATE',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getReportDate(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getSummary = createAsyncThunk(
  'corporate/companySnapshot/GET_SUMMARY',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getSummary(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getMajorShareHolder = createAsyncThunk(
  'corporate/companySnapshot/GET_MAJOR_SHARE_HOLDER',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getMajorShareHolder(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getOwnershipAnalysisChart = createAsyncThunk(
  'corporate/companySnapshot/GET_OWNERSHIP_ANALYSIS_CHART',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getOwnershipAnalysisChart(
      data,
    )
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getTemplate = createAsyncThunk(
  'corporate/companySnapshot/GET_TEMPLATE',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getTemplate(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getMapping_GetAll = createAsyncThunk(
  'corporate/companySnapshot/GET_MAPPING_GET_ALL',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getMapping_GetAll(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const updateTemplate = createAsyncThunk(
  'corporate/companySnapshot/UPDATE_TEMPLATE',
  async (data, { dispatch }) => {
    const response = await CompanySnapshotService.updateTemplate(data)
    if (response.success) {
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
      EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <div className="mb-20">
          <div className="centerItems mb-20">
            <img src="/iconLike.svg" alt="Like" width={38} height={46} />
          </div>
          <div>
            <Translate value="corporate.companySnapshot.SAVE_TEMPLATE_SUCCESS" />
          </div>
        </div>,
      ])
      dispatch(getTemplate())
    }
  },
)

export const postTemplates = createAsyncThunk(
  'corporate/companySnapshot/POST_TEMPLATES',
  async (params, { dispatch }) => {
    const response = await CompanySnapshotService.postTemplates(params)
    if (response.success) {
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
      EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <div className="mb-20">
          <div className="centerItems mb-20">
            <img src="/iconLike.svg" alt="Like" width={38} height={46} />
          </div>
          <div>
            <Translate value="corporate.companySnapshot.SAVE_TEMPLATE_SUCCESS" />
          </div>
        </div>,
      ])
      dispatch(getTemplate())
    }
  },
)

export const deleteTemplate = createAsyncThunk(
  'corporate/companySnapshot/DELETE_TEMPLATES',
  async (params, { dispatch }) => {
    const response = await CompanySnapshotService.deleteTemplate(params)
    if (response.success) {
      dispatch(getTemplate())
    }
  },
)

export const getProfile = createAsyncThunk(
  'corporate/companySnapshot/GET_PROFILE',
  async (data, { rejectWithValue }) => {
    const response = await UserSettingService.getProfile(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getMaxDateStatement = createAsyncThunk(
  'corporate/companySnapshot/GET_MAX_DATE_STATEMENT',
  async (data, { rejectWithValue }) => {
    const response = await CompanySnapshotService.getMaxDateStatement(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
