import { chain, maxBy, minBy, sortBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, ReferenceLine } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import {
  BAR_COLOR_AVERAGE_COUPON_BY_SECTOR,
  RELEASE_TERM,
  TIME_FREQUENCY,
} from '../constants.js'
import {
  renderDate,
  renderDateTime,
  renderTimeType,
  renderTitle,
  renderTitleAverage,
} from '../helper'
import {
  keys,
  selectActiveTimeType,
  selectAverageCouponBySectorData,
  selectFilterAverageCouponBySectorType,
  selectLoading,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'
import ReferencePercent from './ReferencePercent'

const AverageCouponBySector = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeType = useSelector(selectActiveTimeType)
  const isLoading = useSelector(selectLoading(keys.AVERAGE_COUPON_BY_SECTOR))
  const data = useSelector(selectAverageCouponBySectorData)
  const { duration, month, quarter, year, list, averageList } = useSelector(
    selectFilterAverageCouponBySectorType,
  )
  const locale = useSelector((state) => state.i18n.locale)

  const [listCheckbox, setListCheckbox] = useState([])

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const formatDataBarChart = useMemo(
    () =>
      chain(data.averageCouponBySector)
        .groupBy('industryName')
        .map((value, key) => ({
          industryName: key === 'Other' ? 'Others' : key,
          ...chain(value).keyBy('date').mapValues('averageCouponRate').value(),
        }))
        .value(),
    [data],
  )

  const renderTooltip = (dataChart) => (
    <ChartTooltip
      data={dataChart}
      date={renderDate(timeType, locale, { month, quarter, year })}
      lineData={data.averageCoupon}
      renderTimeType={renderTimeType}
      renderTitleAverage={renderTitleAverage}
      renderTitle={renderTitle}
    />
  )

  const renderList = () => {
    const array = []

    if (!!averageList?.length) {
      if (averageList?.[0]) {
        array.push({
          text: `${renderTitleAverage(timeType)} ${renderTitle(
            averageList[0],
            timeType,
            locale,
          )}`,
          type: TYPE.DASHED_LINE,
          dashedLine: {
            bgColor: BAR_COLOR_AVERAGE_COUPON_BY_SECTOR[0],
          },
        })
      }

      if (averageList?.[1]) {
        array.push({
          text: `${renderTitleAverage(timeType)} ${renderTitle(
            averageList[1],
            timeType,
            locale,
          )} ${renderTimeType(timeType, averageList?.[1])} `,
          type: TYPE.DASHED_LINE,
          dashedLine: {
            bgColor: BAR_COLOR_AVERAGE_COUPON_BY_SECTOR[1],
          },
        })
      }
    }

    if (!!list?.length) {
      if (list?.[0]) {
        array.push({
          text:
            locale === 'vi' && timeType === TIME_FREQUENCY.MONTHLY
              ? `${I18n.t(
                  'bond.corporateBond.primaryMarket.MONTH',
                )} ${renderTitle(list[0], timeType, locale)}`
              : renderTitle(list[0], timeType, locale),
          type: TYPE.SQUARE,
          before: {
            bgColor: BAR_COLOR_AVERAGE_COUPON_BY_SECTOR[0],
          },
        })
      }

      if (list?.[1]) {
        array.push({
          text:
            locale === 'vi' && timeType === TIME_FREQUENCY.MONTHLY
              ? `${I18n.t(
                  'bond.corporateBond.primaryMarket.MONTH',
                )} ${renderTitle(list[1], timeType, locale)} ${renderTimeType(
                  timeType,
                  list?.[1],
                )}`
              : `${renderTitle(list[1], timeType, locale)} ${renderTimeType(
                  timeType,
                  list?.[1],
                )}`,
          type: TYPE.SQUARE,
          before: {
            bgColor: BAR_COLOR_AVERAGE_COUPON_BY_SECTOR[1],
          },
        })
      }
    }

    return array
  }

  useEffect(() => {
    if (!!list?.length && !!averageList?.length) {
      setListCheckbox([...averageList, ...list])
    }
  }, [list, averageList])

  const rangeValue = useMemo(() => {
    const lineValues = data?.averageCoupon?.map(
      (item) => item.averageCouponRate,
    )
    const barValues = data?.averageCouponBySector?.map(
      (item) => item.averageCouponRate,
    )

    const maxValue = maxBy([...lineValues, ...barValues])
    const minValue = minBy([...lineValues, ...barValues])

    return {
      minValue: minValue < 0 ? minValue : 0,
      maxValue,
    }
  }, [data])

  const titleJpg = useMemo(() => {
    const item = RELEASE_TERM.find((item) => item.value === duration)

    const title = I18n.t('bond.corporateBond.averageCouponBySector.TITLE')

    if (!item) {
      return title
    }

    const time = renderDateTime({
      timeType,
      month,
      quarter,
      year,
    })

    return `${title}-${I18n.t(item.name)}-${time}`
  }, [duration, timeType, month, quarter, year, locale])

  return (
    <Panel
      title="bond.corporateBond.averageCouponBySector.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'averageCouponSectorChartId',
        nameScreen: I18n.t('bond.corporateBond.primaryMarket.TITLE'),
        chartName: I18n.t('bond.corporateBond.averageCouponBySector.TITLE'),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            <div id="averageCouponSectorChartId">
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {formatDataBarChart.length ? (
                        <>
                          <ChartContainer
                            data={formatDataBarChart}
                            width={width}
                            height={height - size.height - 28}
                            keyXAxis="industryName"
                            isUseXAxisDiv
                            showAllTick
                            yAxis={[
                              {
                                id: 'left',
                                keys: sortBy(list),
                                orientation: 'left',
                                isStackedBar: true,
                                label: I18n.t(
                                  'bond.corporateBond.averageCouponBySector.PERCENT',
                                ),
                                labelPosition: AXIS_LABEL_POSITION.LEFT,
                                unitYAxis: '%',
                                tickNum: 5,
                                min: rangeValue.minValue,
                                max: rangeValue.maxValue,
                              },
                            ]}
                            margin={{
                              left: 0,
                              right: 24,
                              top: 28,
                              bottom: 10,
                            }}
                            renderCustomTooltip={renderTooltip}
                          >
                            {({ chartContentWidth }) => (
                              <>
                                {list &&
                                  !!list.length &&
                                  list.map((item, index) => (
                                    <Bar
                                      key={index}
                                      isAnimationActive={false}
                                      yAxisId="left"
                                      dataKey={item}
                                      fill={
                                        BAR_COLOR_AVERAGE_COUPON_BY_SECTOR[
                                          index
                                        ]
                                      }
                                      barSize={20}
                                    />
                                  ))}
                                {averageList && !!averageList.length && (
                                  <>
                                    {sortBy(
                                      data.averageCoupon,
                                      'date',
                                    )?.[0] && (
                                      <ReferenceLine
                                        yAxisId="left"
                                        y={
                                          sortBy(data.averageCoupon, 'date')[0]
                                            .averageCouponRate
                                        }
                                        stroke={
                                          BAR_COLOR_AVERAGE_COUPON_BY_SECTOR[0]
                                        }
                                        strokeWidth={2}
                                        strokeDasharray="5 5"
                                        label={(ctx) => (
                                          <ReferencePercent
                                            ctx={ctx}
                                            value={
                                              sortBy(
                                                data.averageCoupon,
                                                'date',
                                              )[0].averageCouponRate
                                            }
                                          />
                                        )}
                                      />
                                    )}
                                    {sortBy(
                                      data.averageCoupon,
                                      'date',
                                    )?.[1] && (
                                      <ReferenceLine
                                        yAxisId="left"
                                        y={
                                          sortBy(data.averageCoupon, 'date')[1]
                                            .averageCouponRate
                                        }
                                        stroke={
                                          BAR_COLOR_AVERAGE_COUPON_BY_SECTOR[1]
                                        }
                                        strokeWidth={2}
                                        strokeDasharray="5 5"
                                        label={(ctx) => (
                                          <ReferencePercent
                                            ctx={ctx}
                                            value={
                                              sortBy(
                                                data.averageCoupon,
                                                'date',
                                              )[1].averageCouponRate
                                            }
                                          />
                                        )}
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </ChartContainer>
                        </>
                      ) : (
                        <NoData />
                      )}
                      <Footer
                        key={width}
                        list={renderList()}
                        listCheckbox={sortBy(listCheckbox)}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default AverageCouponBySector
