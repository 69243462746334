import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import Dropdown, { positionDropdown } from '../../../../common/dropdown'
import LinkTicker from '../../../../common/dropdownLink/LinkTicker'
import useWindowDevicePixelRatio from '../../../../common/hooks/useWindowDevicePixelRatio'
import { Span } from '../../../../common/html/Span'
import { EMPTY_VALUE } from '../../../../constants/Common'
import {
  formatVal,
  valDivMillion,
  valDivThousand,
  valToPercent,
} from '../../../../utils/Value'
import style from '../index.module.css'

const widthLabel = 50

const ItemContent = ({ item, size, isPositive, numberData, maxValue }) => {
  const parentRef = useRef()
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const openTooltip = () => setIsOpenTooltip(true)

  const closeTooltip = () => setIsOpenTooltip(false)

  return (
    <div className="d-flex" style={{ height: size.height / numberData }}>
      {!isPositive ? (
        <>
          <div
            className="d-flex ali-center"
            style={{ width: `${widthLabel}px`, justifyContent: 'left' }}
          >
            <LinkTicker organizationId={item.organizationId}>
              {item.ticker || EMPTY_VALUE}
            </LinkTicker>
          </div>
          <div
            style={{ width: `calc(100% - ${widthLabel}px)` }}
            className={`${style.itemContribution} d-flex ali-center`}
            onMouseOver={openTooltip}
            onMouseOut={closeTooltip}
          >
            <div
              className={`h-100 ${style.inlineBlock} ${
                isOpenTooltip ? style.chartLine : ''
              }`}
              style={{
                width: `calc(100% * ${
                  1 - Math.abs(item.contributionPercent) / maxValue
                })`,
              }}
            />
            <div
              ref={parentRef}
              className={`h-100 ${style.inlineBlock}`}
              style={{
                width: `calc(100% * ${
                  Math.abs(item.contributionPercent) / maxValue
                })`,
                background: '#bf5757',
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div
            style={{ width: `calc(100% - ${widthLabel}px)` }}
            className={`${style.itemContribution} d-flex ali-center`}
            onMouseOver={openTooltip}
            onMouseOut={closeTooltip}
          >
            <div
              ref={parentRef}
              className={`h-100 ${style.inlineBlock}`}
              style={{
                width: `calc(100% * ${
                  Math.abs(item.contributionPercent) / maxValue
                })`,
                background: '#3da967',
              }}
            />
            <div
              className={`h-100 ${style.inlineBlock} ${
                isOpenTooltip ? style.chartLine : ''
              }`}
              style={{
                width: `calc(100% * ${
                  1 - Math.abs(item.contributionPercent) / maxValue
                })`,
              }}
            />
          </div>
          <div
            className="d-flex ali-center"
            style={{
              width: `${widthLabel}px`,
              justifyContent: 'right',
            }}
          >
            <LinkTicker organizationId={item.organizationId}>
              {item.ticker || EMPTY_VALUE}
            </LinkTicker>
          </div>
        </>
      )}
      <Dropdown
        parentRef={parentRef}
        isShow={isOpenTooltip}
        position={positionDropdown.TOP_CENTER}
      >
        <div className={`box-notice ${style.tooltipContribution}`}>
          <div className="d-flex">
            <Span className={style.titleTooltip} style={{ fontSize: 16 }}>
              <div className={style.pt12}>
                {item.ticker ? item.ticker.toUpperCase() : EMPTY_VALUE}
              </div>
            </Span>
            <div className={style.relative}>
              <i
                className={`icon-ranking-in-tooltip ${style.iconTooltip}`}
                style={{
                  color: !isPositive ? '#e0505b' : '#3da967',
                }}
              ></i>
              <div className={style.whiteCircle} />
              <div className={style.textIcon}>
                {item.rank < 10 && <span>&nbsp;</span>}
                {item.rank}
              </div>
            </div>
          </div>
          <div className={`d-flex j-b ${style.itemTooltip}`}>
            <label className={style.textTooltip}>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                <Translate value="market.marketInDepth.indices.TOOLTIP_CONTRIBUTION" />
              </Span>
            </label>
            <div>
              <Span
                style={{
                  fontSize: 11,
                  color: !isPositive ? '#e0505b' : '#3da967',
                  fontWeight: 500,
                }}
              >
                {formatVal(item.contribution)}{' '}
                <span className={style.textTooltip}>~</span>{' '}
                {formatVal(item.contributionPercent)}%
              </Span>
            </div>
          </div>
          <div className={`d-flex j-b ${style.itemTooltip}`}>
            <label className={style.textTooltip}>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                <Translate value="market.marketInDepth.indices.TOOLTIP_PRICE" />
              </Span>
            </label>
            <div>
              <Span
                style={{
                  fontSize: 11,
                  color: !isPositive ? '#e0505b' : '#3da967',
                  fontWeight: 500,
                }}
              >
                <span className={style.textTooltip}>
                  {formatVal(valDivThousand(item.matchPrice))}
                </span>{' '}
                <span className={style.textTooltip}>(</span>
                {formatVal(valDivThousand(item.priceChange))}{' '}
                <span className={style.textTooltip}>~</span>{' '}
                {valToPercent(item.percentPriceChange)}
                <span className={style.textTooltip}>)</span>
              </Span>
            </div>
          </div>
          <div className={`d-flex j-b ${style.itemTooltip}`}>
            <label className={style.textTooltip}>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                <Translate value="market.marketInDepth.indices.TOOLTIP_VOLUME" />
              </Span>
            </label>
            <div>
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {formatVal(valDivThousand(item.totalMatchVolume))}{' '}
                <Translate value="market.marketInDepth.indices.CONTRIBUTION_VOLUMN" />
              </Span>
            </div>
          </div>
          <div className={`d-flex j-b ${style.itemTooltip}`}>
            <label className={style.textTooltip}>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                <Translate value="market.marketInDepth.indices.TOOLTIP_VALUE" />
              </Span>
            </label>
            <div>
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {formatVal(valDivMillion(item.totalMatchValue))}{' '}
                <Translate value="market.marketInDepth.indices.CONTRIBUTION_VALUE" />
              </Span>
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  )
}

const HalfContentContribution = ({
  data,
  isPositive,
  sizes,
  numberData,
  maxValue,
}) => {
  const containerRef = useRef()

  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [size, setSize] = useState(null)

  useEffect(() => {
    setSize({
      width: containerRef.current.getBoundingClientRect().width,
      height: formatWithZoom(
        containerRef.current.getBoundingClientRect().height,
      ),
    })
  }, [sizes])

  return (
    <div ref={containerRef} className={style.halfContentContainer}>
      {size && (
        <div>
          {data.map((item, index) => (
            <ItemContent
              key={index}
              size={size}
              item={item}
              isPositive={isPositive}
              numberData={numberData}
              maxValue={maxValue}
            />
          ))}
        </div>
      )}
    </div>
  )
}

HalfContentContribution.propTypes = {
  data: PropTypes.array.isRequired,
  isPositive: PropTypes.bool,
  sizes: PropTypes.string,
  numberData: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
}

HalfContentContribution.defaultProps = {
  isPositive: false,
}

export default HalfContentContribution
