import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { DEFAULT_CHECKED } from '../config'
import {
  defineGroupColumns,
  findMinYear,
  getIdByLevel,
  initLineColor,
  keyBy,
} from '../helper'
import { getGDPBySpendingMethod, getGDPTypeName } from './thunk'

const initialState = {
  tableDataLoading: true,
  tableMappingLoading: true,
  tableData: [],
  tableMapping: [],
  checkboxChecked: [],
  GDPSpendingMethodById: {},
  groupColumns: [],
  isFirstTime: true,
  minYear: 0,
  prevMinYear: 0,
  lineChartColor: [],
  isAllowCallApi: true,
  indexChecked: DEFAULT_CHECKED,
}

const slice = createSlice({
  name: 'economy/gdp/gdpBySpendingMethod/panelGDPSpendingMethod',
  initialState,
  reducers: {
    handleCheckboxChecked: (state, action) => {
      state.checkboxChecked = action.payload
    },
    handleIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload
    },
    handleIsAllowCallApi: (state, action) => {
      state.isAllowCallApi = action.payload
    },
    handleLineChartColor: (state, action) => {
      state.lineChartColor = action.payload
    },
    handleIndexChecked: (state, action) => {
      state.indexChecked = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGDPBySpendingMethod.pending, (state) => {
      state.tableDataLoading = true
    })
    builder.addCase(getGDPBySpendingMethod.fulfilled, (state, action) => {
      const data = action.payload || []
      state.tableDataLoading = false
      state.tableData = data
      state.groupColumns = defineGroupColumns(
        data,
        state.groupColumns,
        action.meta.arg.NumberOfPeriod,
      )
      state.GDPSpendingMethodById = keyBy(
        data,
        state.GDPSpendingMethodById,
        action.meta.arg.NumberOfPeriod,
      )
      state.prevMinYear = state.minYear
      state.minYear = findMinYear(data)
      if (!data?.length) {
        state.isAllowCallApi = false
      }
    })
    builder.addCase(getGDPBySpendingMethod.rejected, (state, action) => {
      state.tableDataLoading = action.payload.isLoading
    })
    builder.addCase(getGDPTypeName.pending, (state) => {
      state.tableMappingLoading = true
    })
    builder.addCase(getGDPTypeName.fulfilled, (state, action) => {
      const data = action.payload || []
      state.tableMappingLoading = false
      state.tableMapping = data
      state.checkboxChecked = getIdByLevel(data, state.indexChecked)
      state.lineChartColor = initLineColor(
        getIdByLevel(data, state.indexChecked, true),
      )
    })
    builder.addCase(getGDPTypeName.rejected, (state, action) => {
      state.tableMappingLoading = action.payload.isLoading
    })
  },
})

export const {
  handleCheckboxChecked,
  handleIsFirstTime,
  handleIsAllowCallApi,
  handleLineChartColor,
  handleIndexChecked,
} = slice.actions

export const getTableDataLoading = (state) => state[slice.name].tableDataLoading
export const getTableMappingLoading = (state) =>
  state[slice.name].tableMappingLoading
export const getTableData = (state) => state[slice.name].tableData
export const getTableMappingData = (state) => state[slice.name].tableMapping
export const getCheckboxChecked = (state) => state[slice.name].checkboxChecked
export const getGDPSpendingMethodValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].GDPSpendingMethodById[id], attr)
export const getGroupColumns = (state) => state[slice.name].groupColumns
export const getIsFirstTime = (state) => state[slice.name].isFirstTime
export const getLineChartColor = (state) => state[slice.name].lineChartColor
export const getMinYear = (state) => state[slice.name].minYear
export const getPrevMinYear = (state) => state[slice.name].prevMinYear
export const getIsAllowCallApi = (state) => state[slice.name].isAllowCallApi
export const getGDPSpendingMethodById = (state) =>
  state[slice.name].GDPSpendingMethodById
export const getIndexChecked = (state) => state[slice.name].indexChecked

register(slice.name, slice.reducer)
