import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../common/ScrollComponent'
import useStockBasicInfo from '../../../common/hooks/useStockBasicInfo'
import { Button } from '../../../common/html/Button'
import { SizeTracker } from '../../../common/sizeTracker'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import ButtonSaveTemplate from './ButtonSaveTemplate'
import PopupWarning from './PopupWarning'
import SavedReport from './SavedReport'
import Setting from './Setting'
import {
  AUDIT_STATUS,
  CURRENCY,
  DROPDOWN_COMPANY_OVERVIEW,
  REPORT_TYPE,
} from './constants'
import { getLatestQuarters, getLatestYears } from './helper'
import {
  changValuePopupFinancialStatement,
  changeDropdownCompanyOverview,
  changeLoadingPDF,
  changeReportDate,
  changeSetting,
  changeYourCommentaryOnEarnings,
  resetSetting,
  selectDropdownCompanyOverviewValue,
  selectIsReloadData,
  selectMaxDateStatement,
  selectOrganizationIdTemplate,
  selectPopupFinancialStatement,
  selectReportDate,
  selectSetting,
  selectShowPreview,
  selectYourCommentaryOnEarnings,
} from './store/slice'
import {
  getMaxDateStatement,
  getPeerAnalysis,
  getReportDate,
  getTemplate,
} from './store/thunk'
import style from './style.module.css'
import UseApplyPDF from './useApplyPDF'

const groupIdShowWarning = 68 //show warning when groupId = 68

const Filter = ({ height }) => {
  const dispatch = useDispatch()
  const titleSettingRef = useRef()
  const setting = useSelector(selectSetting)
  const dataTopInfo = useSelector(selectBasicInfo)
  const dropdownCompanyOverviewValue = useSelector(
    selectDropdownCompanyOverviewValue,
  )
  const reportDate = useSelector(selectReportDate)
  const commentaryOnEarning = useSelector(selectYourCommentaryOnEarnings)
  const isReloadData = useSelector(selectIsReloadData)
  const locale = useSelector((state) => state.i18n.locale)
  const showPreview = useSelector(selectShowPreview)
  const maxDateStatement = useSelector(selectMaxDateStatement)
  const popupFinancialStatement = useSelector(selectPopupFinancialStatement)
  const organizationIdTemplate = useSelector(selectOrganizationIdTemplate)
  const stockBasicInfo = useStockBasicInfo(dataTopInfo?.organizationId)

  const [stateSetting, setStateSetting] = useState(setting)
  const [commentaryOnEarnings, setCommentaryOnEarnings] =
    useState(commentaryOnEarning)
  const [dropdownCompanyOverview, setDropdownCompanyOverview] = useState(
    dropdownCompanyOverviewValue,
  )
  const [isShowPopupWarning, setShowPopupWarning] = useState(true)

  const handleResetSetting = () => {
    setStateSetting(setting)
    dispatch(resetSetting())
    setCommentaryOnEarnings('')
    setDropdownCompanyOverview(DROPDOWN_COMPANY_OVERVIEW.COMPARE_WITH_SECTOR)
    if (maxDateStatement.realYear && maxDateStatement.realQuarter) {
      const arrDate = getLatestQuarters(5, maxDateStatement.realYear, 5)
      const arrYear = getLatestYears(
        maxDateStatement.realYear,
        maxDateStatement.realQuarter,
      )
      dispatch(
        changValuePopupFinancialStatement({
          reportType: REPORT_TYPE.CONSOLIDATED,
          auditStatus: AUDIT_STATUS.BOTH_AUDITED_AND_UNAUDITED,
          currency: CURRENCY.BILLION_VND,
          year1: arrYear[2],
          year2: arrYear[1],
          year3: arrYear[0],
          q_quarter1: arrDate[0].quarter,
          q_year1: arrDate[0].year,
          q_quarter2: arrDate[1].quarter,
          q_year2: arrDate[1].year,
          q_quarter3: arrDate[2].quarter,
          q_year3: arrDate[2].year,
          q_quarter4: arrDate[3].quarter,
          q_year4: arrDate[3].year,
          q_quarter5: arrDate[4].quarter,
          q_year5: arrDate[4].year,
        }),
      )
    }
  }

  const { applyPDF, setIsHandle, isHandle } = UseApplyPDF()

  const apply = () => {
    if (reportDate) {
      setIsHandle(true)
      applyPDF()
      dispatch(changeYourCommentaryOnEarnings(commentaryOnEarnings))
      dispatch(changeSetting(stateSetting))
      dispatch(changeLoadingPDF(true))
      dispatch(changeDropdownCompanyOverview(dropdownCompanyOverview))
    }
  }

  useEffect(() => {
    if (dataTopInfo.organizationId) {
      dispatch(
        getReportDate({
          OrganizationId: dataTopInfo.organizationId,
        }),
      )
      dispatch(
        getPeerAnalysis({
          OrganizationId: dataTopInfo.organizationId,
        }),
      )
      dispatch(
        getMaxDateStatement({ OrganizationId: dataTopInfo.organizationId }),
      )
    }
    if (!organizationIdTemplate) {
      dispatch(resetSetting())
    }
    dispatch(changeReportDate(undefined))
  }, [dataTopInfo.organizationId])

  useEffect(() => {
    dispatch(getTemplate())
  }, [])

  useEffect(() => {
    setStateSetting(setting)
  }, [setting])

  useEffect(() => {
    setCommentaryOnEarnings(commentaryOnEarning)
  }, [commentaryOnEarning])

  useEffect(() => {
    if (isReloadData && showPreview) {
      apply()
    }
  }, [locale])

  useEffect(() => {
    if (maxDateStatement?.realYear && maxDateStatement?.realQuarter) {
      const arrDate = getLatestQuarters(5, maxDateStatement.realYear, 5)
      const arrYear = getLatestYears(
        maxDateStatement.realYear,
        maxDateStatement.realQuarter,
      )

      dispatch(
        changValuePopupFinancialStatement({
          ...popupFinancialStatement,
          year1: arrYear[2],
          year2: arrYear[1],
          year3: arrYear[0],
          q_quarter1: arrDate[0].quarter,
          q_year1: arrDate[0].year,
          q_quarter2: arrDate[1].quarter,
          q_year2: arrDate[1].year,
          q_quarter3: arrDate[2].quarter,
          q_year3: arrDate[2].year,
          q_quarter4: arrDate[3].quarter,
          q_year4: arrDate[3].year,
          q_quarter5: arrDate[4].quarter,
          q_year5: arrDate[4].year,
        }),
      )
    }
  }, [maxDateStatement])

  useEffect(() => {
    setDropdownCompanyOverview(dropdownCompanyOverviewValue)
  }, [dropdownCompanyOverviewValue])

  useEffect(() => {
    if (
      stockBasicInfo?.indexGroupId &&
      stockBasicInfo.indexGroupId === groupIdShowWarning
    ) {
      setShowPopupWarning(true)
    } else {
      setShowPopupWarning(false)
    }
  }, [stockBasicInfo])

  return (
    <div style={{ height: height || 0, padding: 8 }}>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <SavedReport isHandle={isHandle} />
              {size.height && (
                <div style={{ height: height - size.height || 0 }}>
                  <SizeTracker>
                    {(size2) => {
                      return (
                        <>
                          {size2.height && (
                            <>
                              <div
                                className={style.titleGray}
                                ref={titleSettingRef}
                              >
                                <Translate value="corporate.companySnapshot.SETTING" />
                              </div>
                              <ScrollComponent
                                autoHeight={true}
                                autoHeightMax={
                                  height -
                                    size2.height -
                                    size.height -
                                    titleSettingRef?.current?.offsetHeight || 0
                                }
                              >
                                <Setting
                                  isHandle={isHandle}
                                  stateSetting={stateSetting}
                                  setStateSetting={setStateSetting}
                                  commentaryOnEarnings={commentaryOnEarnings}
                                  setCommentaryOnEarnings={
                                    setCommentaryOnEarnings
                                  }
                                  setDropdownCompanyOverview={
                                    setDropdownCompanyOverview
                                  }
                                  dropdownCompanyOverview={
                                    dropdownCompanyOverview
                                  }
                                />
                              </ScrollComponent>
                            </>
                          )}

                          <div
                            className={`d-flex justify-content-space-between align-center ${
                              isHandle ? style.isHandle : ''
                            }`}
                            style={{ height: 60 }}
                          >
                            <Button
                              style={{
                                fontSize: 11,
                                paddingLeft: 10,
                                paddingRight: 10,
                                width: 'auto',
                                minWidth: 60,
                              }}
                              className={`btn normal h-20`}
                              onClick={handleResetSetting}
                            >
                              <Translate value="corporate.companySnapshot.RESET" />
                            </Button>
                            <div className="d-flex">
                              <ButtonSaveTemplate
                                stateSetting={stateSetting}
                                commentaryOnEarnings={commentaryOnEarnings}
                                dropdownCompanyOverview={
                                  dropdownCompanyOverview
                                }
                              />
                              <Button
                                style={{
                                  fontSize: 11,
                                  backgroundColor: '#d2932a',
                                  marginLeft: 21,
                                  marginRight: 20,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  width: 'auto',
                                  minWidth: 60,
                                }}
                                className={`btn h-20`}
                                onClick={apply}
                              >
                                <Translate value="common.button.BUTTON_APPLY" />
                              </Button>
                            </div>
                          </div>
                        </>
                      )
                    }}
                  </SizeTracker>
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
      {isShowPopupWarning ? (
        <PopupWarning
          tickerOrTaxCode={dataTopInfo.ticker || dataTopInfo.taxCode}
          organizationShortName={dataTopInfo.organizationShortName}
          closeWarning={() => setShowPopupWarning(false)}
        />
      ) : null}
    </div>
  )
}

export default Filter
