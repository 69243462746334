import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import TimeFrame from '../../common/TimeFrame'
import { OPEN_MARKET_DAILY_OPTIONS } from '../../constants'
import { getGroupColumns } from '../panelDepositRateCommercial/store/slice'
import {
  getDropdownValue,
  getTimeRange,
  handleDropdownValue,
  handleTimeRange,
} from './store/slice'

const Header = () => {
  const dispatch = useDispatch()

  const dropdownValue = useSelector(getDropdownValue)
  const timeRange = useSelector(getTimeRange)
  const groupColumns = useSelector(getGroupColumns)

  const dropDownData = groupColumns
    .map((item) => ({
      name: item.title,
      value: item.key,
    }))
    .filter((item) => item.name)

  const changeDropdownValue = (value) => {
    dispatch(handleDropdownValue(value))
  }

  const changeTimeRange = (tab) => {
    dispatch(handleTimeRange(tab.value))
  }

  return (
    <div className="d-flex align-center j-b">
      <div style={{ width: 182 }}>
        <SelectCustom
          isI18n={true}
          selectData={dropDownData}
          value={dropdownValue}
          handleChange={changeDropdownValue}
          appendStyle={{ fontWeight: 'normal' }}
        />
      </div>
      <TimeFrame
        timeRange={timeRange}
        onChangeTab={changeTimeRange}
        timeFrameOption={[
          ...OPEN_MARKET_DAILY_OPTIONS,
          { title: 'economy.monetary.monetary.ALL', value: 'AllTime' },
        ]}
      />
    </div>
  )
}

export default Header
