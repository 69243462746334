import { I18n, Translate } from 'react-redux-i18n'
import {
  DEFAULT_PRE_VALUE_KEY,
  DEFAULT_XAXIS_DATA_KEY,
} from '../../../../../common/chart/basicChart/constants'
import { Span } from '../../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../../utils/Value'

const CHART_KEYS = {
  BOND: 'faR12',
  STOCK: 'faR7',
  CASH_AND_OTHER: 'others',
}

export const formatAssetDataApi = (data) => {
  if (!data.length) return []

  const total = (item) =>
    (item?.faR12 || 0) + (item?.faR7 || 0) + (item?.others || 0)
  const chartCol1 = data[0]
  const chartCol2 = data[1] || {}
  const reportMonth = chartCol2.reportMonth || chartCol1.reportMonth
  const reportYear = chartCol2.reportYear || chartCol1.reportYear

  return Object.keys(CHART_KEYS).map((key) => ({
    [DEFAULT_XAXIS_DATA_KEY]: I18n.t('fund.fundProfile.portfolio.' + key),
    [`${DEFAULT_PRE_VALUE_KEY}1`]:
      chartCol1[CHART_KEYS[key]] / total(chartCol1),
    [`${DEFAULT_PRE_VALUE_KEY}2`]:
      chartCol2[CHART_KEYS[key]] / total(chartCol2),
    [`${DEFAULT_PRE_VALUE_KEY}1Raw`]: chartCol1[CHART_KEYS[key]] / 10 ** 9,
    [`${DEFAULT_PRE_VALUE_KEY}2Raw`]: chartCol2[CHART_KEYS[key]] / 10 ** 9,
    [`${DEFAULT_PRE_VALUE_KEY}1Title`]: formatDateTime(
      new Date(chartCol1.reportYear, chartCol1.reportMonth - 1).toString(),
      FORMAT.MONTH_YEAR_NUMBER,
    ),
    [`${DEFAULT_PRE_VALUE_KEY}2Title`]: formatDateTime(
      new Date(chartCol2.reportYear, chartCol2.reportMonth - 1).toString(),
      FORMAT.MONTH_YEAR_NUMBER,
    ),
    reportMonth,
    reportYear,
  }))
}

const getRenderTooltipValue = (value, percent) => (
  <div className="d-flex ali-center">
    <Span
      style={{
        fontSize: 11,
        fontWeight: 500,
      }}
    >
      {formatVal(value)}
    </Span>
    <Span
      style={{
        fontSize: 11,
        fontWeight: 500,
      }}
      className="ml-4"
    >
      <Translate value="common.UNIT.BILLION_VND" />
    </Span>
    <Span
      style={{
        fontSize: 11,
        fontWeight: 500,
      }}
      className="ml-4"
    >
      ({valToPercent(percent)})
    </Span>
  </div>
)

const getSchema = (data) => [
  {
    text: data[0] ? data[0][`${DEFAULT_PRE_VALUE_KEY}1Title`] : '',
    isI18n: true,
    color: '#3CABEB',
    footer: {
      before: {
        bgColor: '#3CABEB',
      },
    },
    tooltip: {
      renderTitle: (value) => value[`${DEFAULT_PRE_VALUE_KEY}1Title`],
      renderValue: (value) =>
        getRenderTooltipValue(
          value[`${DEFAULT_PRE_VALUE_KEY}1Raw`],
          value[`${DEFAULT_PRE_VALUE_KEY}1`],
        ),
    },
  },
  {
    text: data[0] ? data[0][`${DEFAULT_PRE_VALUE_KEY}2Title`] : '',
    isI18n: true,
    color: '#FFF6DF',
    footer: {
      before: {
        bgColor: '#FFF6DF',
      },
    },
    tooltip: {
      renderTitle: (value) => value[`${DEFAULT_PRE_VALUE_KEY}2Title`],
      renderValue: (value) =>
        getRenderTooltipValue(
          value[`${DEFAULT_PRE_VALUE_KEY}2Raw`],
          value[`${DEFAULT_PRE_VALUE_KEY}2`],
        ),
    },
  },
]

export const getAssetProps = ({ data }) => {
  const schema = getSchema(data)
  return {
    schema,
    chartProps: {
      chartType: 'ComposedChart',
      yAxis: [
        {
          dataKeys: schema.map(
            (_, index) => `${DEFAULT_PRE_VALUE_KEY}${index + 1}`,
          ),
          tickFormatter: (value) => valToPercent(value, false, false, 0),
          isGroupBar: true,
          isBarChart: true,
        },
      ],
    },
    tooltipProps: {
      title: (value) => value[DEFAULT_XAXIS_DATA_KEY],
    },
  }
}
