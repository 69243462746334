import { useDispatch, useSelector } from 'react-redux'
import style from '../index.module.css'
import {
  checkAllPopupTemplate,
  removeTickerPopupTemplate,
  selectTickerByIdPopupTemplate,
  selectTickerPopupTemplate,
  uncheckAllPopupTemplate,
} from '../store/slice'

const CheckAllTicker = () => {
  const dispatch = useDispatch()

  const ticker = useSelector(selectTickerPopupTemplate)
  const tickerById = useSelector(selectTickerByIdPopupTemplate)

  const handleCheckAll = () => {
    dispatch(checkAllPopupTemplate())
  }

  const handleUncheckAll = () => {
    dispatch(uncheckAllPopupTemplate())
  }

  const removeTicker = () => {
    dispatch(removeTickerPopupTemplate())
  }

  const isHaveCheck = ticker.some((id) => tickerById[id].check === true)
  return (
    <div className="group-action">
      {!isHaveCheck ? (
        <label className={`d-flex justify-content-center ${style.checkboxAll}`}>
          <input
            type="checkbox"
            className={`checkbox line ${style.inputCheckAll}`}
            onClick={handleCheckAll}
            readOnly
          />
        </label>
      ) : (
        <div className={`${style.btnMinus}`}>
          <div onClick={handleUncheckAll} className={style.pr6px}>
            <i className={`icon-minus ${style.fontSize12}`} />
          </div>
        </div>
      )}
      <button onClick={removeTicker}>
        <i className="icon-trash" />
      </button>
    </div>
  )
}

export default CheckAllTicker
