import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class ValuesOfBonsProxy extends ProxyBase {
  getDataChart(params) {
    return this.get('Overview/ValueOfIssuedBondsIssueMethod', params)
  }
  getDataChartCollateral(params) {
    return this.get('Overview/ValueOfIssuedBondsCollateral', params)
  }
}

export default new ValuesOfBonsProxy(
  ServiceProxyConfig.Bond.PrimaryMarket.ServiceUrl,
)
