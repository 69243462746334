import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import Content from './Content'
import ImportExportNavigation from './ImportExportNavigation'
import { TABLE_FILTER } from './exportImportTable/constants'
import {
  changeCurrentMinMonth,
  changeCurrentMinYear,
  changeKeySearch,
  changeTypeFilter,
  handleIsFirstTime,
  selectTypeFilter,
  setIsKeepRowsCollapse,
  setLimitQuarter,
  setUnCollapseIds,
} from './exportImportTable/store/slice'
import { changeTypeStatisticsStructureChart } from './exportStructure/structureChart/store/slice'
import { changeTypeFilterForTurnOverChart } from './exportTurnOver/store/slice'
import style from './index.module.css'

const ExportImportByCommodities = () => {
  const dispatch = useDispatch()

  const currentTypeFilter = useSelector(selectTypeFilter)

  const handleChangeFilter = (item) => {
    dispatch(handleIsFirstTime(true))
    dispatch(changeTypeFilter(item.value))
    dispatch(changeTypeFilterForTurnOverChart(item.value))
    dispatch(changeTypeStatisticsStructureChart(item.value))
    dispatch(changeCurrentMinMonth(null))
    dispatch(changeCurrentMinYear(null))
    dispatch(setLimitQuarter(0))
    dispatch(setIsKeepRowsCollapse(false))
    dispatch(changeKeySearch(''))
    dispatch(setUnCollapseIds([]))
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div className="top-info">
              <Title
                title={
                  'economy.importExport.importExport.EXPORT_IMPORT_BY_COMMODITIES'
                }
              />
              <VerticalLine mr={12} />
              <ImportExportNavigation />
              <VerticalLine ml={12} mr={12} />
              <ul className={`list-check d-flex mt-0`}>
                {TABLE_FILTER.map((item) => (
                  <li key={item.value} className="mb-0">
                    <label>
                      <input
                        type="radio"
                        className="radiobox radiobox2"
                        checked={item.value === currentTypeFilter}
                        onChange={() => handleChangeFilter(item)}
                      />
                      <Span
                        style={{ fontSize: 12 }}
                        className={
                          item.value === currentTypeFilter ? style.active : ''
                        }
                      >
                        <Translate value={item.title} />
                      </Span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            {size.height && (
              <div
                className="row w-100 position-relative"
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <Content />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ExportImportByCommodities
