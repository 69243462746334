// Chart Constants
export const chartFillColor = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#3cabeb',
  '#ecffdf',
]

// Table Constants
export const tableHeaderSchema = {
  TICKER: 'ticker',
  SECTOR: 'icbName',
  COST: 'costPrice',
  MARKET_VALUE: 'fairValue',
}

export const tableFormat = {
  COST: 10 ** 9,
  MARKET_VALUE: 10 ** 9,
}
