import { useSelector } from 'react-redux'
import { selectStockInfoById } from '../../../common/masterData/store/slice'
import TextEllipsis from '../../../common/textEllipsis'

const TickerName = ({
  organizationId,
  isEllipsis = true,
  hasCompanyName,
  tickerClassName,
  companyNameClassName,
}) => {
  const ticker = useSelector(selectStockInfoById(organizationId, 'ticker'))
  const taxCode = useSelector(selectStockInfoById(organizationId, 'taxCode'))
  const companyName = useSelector(
    selectStockInfoById(organizationId, 'organizationName'),
  )

  const text = ticker || taxCode || ''

  return (
    <>
      <div className={tickerClassName}>
        {isEllipsis ? <TextEllipsis isI18n={false} text={text} /> : text}
      </div>
      {hasCompanyName && (
        <div className={companyNameClassName}>
          <TextEllipsis isI18n={false} text={companyName || ''} />
        </div>
      )}
    </>
  )
}
export default TickerName
