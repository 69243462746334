import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Table } from '../../../../common/table'
import TextEllipsis from '../../../../common/textEllipsis'
import { valToPercent } from '../../../../utils/Value'
import { keySchemaWorldIndex, keyTableWorldIndex } from '../constans'
import style from '../index.module.css'
import {
  changeWorldIndexId,
  keys,
  selectLoading,
  selectWorldIndexCell,
  selectWorldIndexId,
  sortWorldIndex,
} from '../store/slice'
import TdColor from '../TdColor'

const TableWorldIndex = () => {
  const worldIndexId = useSelector(selectWorldIndexId)
  const loading = useSelector(selectLoading(keys.WORLD_INDEX))

  const [reCalcWidth, setReCalcWidth] = useState(true)

  useEffect(() => {
    setReCalcWidth(!reCalcWidth)
  }, [worldIndexId])

  return (
    <>
      <Table
        ids={worldIndexId}
        columnDraggable={false}
        getDataFromRedux={selectWorldIndexCell}
        isLoading={loading}
        changeIds={changeWorldIndexId}
        rowDraggable={false}
        hasFooter={false}
        stickyFirstColumn={true}
        hasTooltip={false}
        resizable={false}
        reCalcWidths={reCalcWidth}
        sort={sortWorldIndex}
        schema={Object.keys(keyTableWorldIndex).map((key) => {
          const isIndexName = key === keySchemaWorldIndex.GLOBAL_INDEX_NAME
          const title = `market.marketInDepth.indices.${key}`

          const colId = keyTableWorldIndex[key]
          const result = {
            colId,
            title,
            disableSort: false,
            thStyle: {
              textAlign: isIndexName ? 'left' : 'right',
            },
            tdStyle: {
              textAlign: !isIndexName ? 'right' : 'left',
              minWidth: isIndexName ? '140px' : undefined,
            },
          }

          return {
            ...result,
            isI18n: false,
            canCustomTd: true,
            render: !isIndexName
              ? (value, colId, { style, className }) => (
                  <TdColor
                    value={
                      key === keySchemaWorldIndex.PERCENT_PRICE_CHANGE
                        ? valToPercent(value)
                        : value
                    }
                    colId={colId}
                    keyColor="priceChange"
                    selectCell={selectWorldIndexCell}
                    isPercent={
                      key === keySchemaWorldIndex.PERCENT_PRICE_CHANGE
                        ? true
                        : false
                    }
                    style={style}
                    className={className}
                  />
                )
              : (value) => (
                  <td className={style.tdEllipsis}>
                    <TextEllipsis isI18n={false} text={value} />
                  </td>
                ),
          }
        })}
      />
    </>
  )
}

export default TableWorldIndex
