import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { HorizontalStackedBarSeries } from '../../../../common/chart/customCharts/horizontalStackedBarSeries'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../../common/chart/footer/Item'
import { getTextWidth } from '../../../../common/chart/helper'
import { SizeTracker } from '../../../../common/sizeTracker'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal } from '../../../../utils/Value'

export const StackedHorizontalBarChart = ({
  data,
  width,
  height,
  yAxisKey,
  barKeys,
  fillColor,
  mappingDisplayName,
  unit,
}) => {
  // Get data
  const getData = () => {
    return data.map((item) => {
      const objData = { ...item }
      Object.keys(item).forEach((key) => {
        if (barKeys.includes(key) && item[key]) {
          objData[key] = Math.round(item[key] * 10000) / 100
        }
      })
      return objData
    })
  }

  const fontSize12 = getFontSize(12)
  const getMarginLeft = () => {
    return Math.max(
      ...getData().map((item) => getTextWidth(item.label, fontSize12)),
    )
  }

  // Footer
  const footerData = () => {
    return [
      ...barKeys.map((i) => ({
        text: mappingDisplayName[i] ?? i,
        type: FOOTER_TYPE.SQUARE,
        color: fillColor[i],
      })),
    ]
  }

  // Render tooltip
  const fontSize10 = getFontSize(10)
  const getTooltipData = () => [
    {
      label: '',
      keyValue: yAxisKey,
      formatValue: (val) => val,
      fontStyle: 'italic',
      fontSize: fontSize10,
    },
    ...barKeys.map((key) => ({
      label: `${I18n.t(mappingDisplayName[key]) ?? key}: `,
      keyValue: key,
      formatValue: (val) => `${formatVal(val)} ${unit ? I18n.t(unit) : '%'}`,
    })),
  ]

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <div style={{ height: height - size.height }}>
              <HorizontalStackedBarSeries
                data={getData()}
                width={width}
                height={height - size.height}
                yAxisCompareKey={yAxisKey}
                barKeys={barKeys}
                is100Percent={true}
                tooltipContentData={getTooltipData()}
                fillColor={fillColor}
                margin={{ left: getMarginLeft(), bottom: 40, right: 0 }}
              />
            </div>
          )}
          <FooterContainer key={width} data={footerData()} />
        </>
      )}
    </SizeTracker>
  )
}

StackedHorizontalBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  yAxisKey: PropTypes.string.isRequired,
  barKeys: PropTypes.array.isRequired,
  fillColor: PropTypes.object.isRequired,
  mappingDisplayName: PropTypes.object.isRequired,
  unit: PropTypes.string,
}
