import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import VerticalLine from '../../../../common/topInfo/components/VerticalLine'
import SelectYear from '../../common/SelectYear'
import TimeFrame from '../../common/TimeFrame'
import {
  ACC_TIME_FRAME_OPTIONS,
  COMPOSITION_SECTOR_VALUE,
  HEADER_VALUE,
  VALUE_TIME_FRAME_OPTIONS,
} from '../../constants'
import { getYearlyQuarterly } from '../panelTotalGDP/store/slice'
import { getNominalRealValue } from '../store/slice'
import style from './index.module.css'
import {
  getRadioValue,
  getTimeRangeAccValue,
  getTimeRangeValue,
  getYear,
  handleRadioValue,
  handleTimeRangeAccValue,
  handleTimeRangeValue,
  handleYear,
} from './store/slice'
import { getMaxPeriod } from './store/thunk'

const { VALUE, ACC_VALUE } = COMPOSITION_SECTOR_VALUE
const { NOMINAL } = HEADER_VALUE

const FilterGroup = () => {
  const RADIO_LIST = [
    {
      value: VALUE,
      label: 'economy.gdp.gdp.VALUE',
    },
    {
      value: ACC_VALUE,
      label: 'economy.gdp.gdp.ACC_VALUE',
    },
  ]
  const dispatch = useDispatch()

  const year = useSelector(getYear)
  const timeRangeValue = useSelector(getTimeRangeValue)
  const timeRangeAccValue = useSelector(getTimeRangeAccValue)
  const radioValue = useSelector(getRadioValue)
  const nominalRealValue = useSelector(getNominalRealValue)
  const timeFrequency = useSelector(getYearlyQuarterly)

  const handleChangeYear = (year) => {
    dispatch(handleYear(year))
  }

  const handleChangeTimeRange = (timeRange) => {
    if (radioValue === VALUE) {
      dispatch(handleTimeRangeValue(timeRange?.value))
    } else {
      dispatch(handleTimeRangeAccValue(timeRange?.value))
    }
  }

  const handleChangeRadio = (radioValue) => {
    dispatch(handleRadioValue(radioValue))
  }

  useEffect(() => {
    dispatch(
      getMaxPeriod({
        TimeFrequency: timeFrequency,
        IsNominal: nominalRealValue === NOMINAL,
        IsValue: radioValue === VALUE,
      }),
    )
  }, [timeFrequency, nominalRealValue, radioValue])

  return (
    <div className="d-flex align-center">
      {RADIO_LIST.map(({ value, label }, index) => {
        return (
          <div
            key={value}
            className={`d-flex align-center ${style.wrapRadio}
                  ${value === radioValue && style.radioActive}
                  ${index === 0 && style.mr24}
                  `}
            onClick={() => handleChangeRadio(value)}
          >
            <input
              type="radio"
              className="radiobox radiobox2"
              checked={value === radioValue}
              onChange={() => handleChangeRadio(value)}
            />
            <Span style={{ fontSize: 12, fontWeight: 'normal' }}>
              <Translate value={label} />
            </Span>
          </div>
        )
      })}
      <VerticalLine styleProps={{ height: 20 }} mr={16} ml={16} />
      <SelectYear year={year} changeYear={handleChangeYear} />
      <TimeFrame
        timeRange={radioValue === VALUE ? timeRangeValue : timeRangeAccValue}
        onChangeTab={handleChangeTimeRange}
        timeFrameOption={
          radioValue === VALUE
            ? VALUE_TIME_FRAME_OPTIONS
            : ACC_TIME_FRAME_OPTIONS
        }
      />
    </div>
  )
}

export default FilterGroup
