export const primaryMarket = {
  TITLE: 'Thị trường sơ cấp',
  OVERVIEW: 'Tổng quan',
  PRIMARY_MARKET: 'Thị trường sơ cấp',
  MARKET_STATISTICS: 'Thống kê thị trường',
  ALL: 'Tất cả',
  PUBLIC: 'Công chúng',
  PRIVATE: 'Riêng lẻ',
  MONTHLY: 'Hàng tháng',
  QUARTERLY: 'Hàng quý',
  YEARLY: 'Hàng năm',
  MONTHLY_OPTION: {
    MONTH_1: 'Th01',
    MONTH_2: 'Th02',
    MONTH_3: 'Th03',
    MONTH_4: 'Th04',
    MONTH_5: 'Th05',
    MONTH_6: 'Th06',
    MONTH_7: 'Th07',
    MONTH_8: 'Th08',
    MONTH_9: 'Th09',
    MONTH_10: 'Th10',
    MONTH_11: 'Th11',
    MONTH_12: 'Th12',
  },
  AVERAGE_MONTH: 'BQ tháng',
  AVERAGE_QUARTER: 'BQ',
  AVERAGE_YEAR: 'BQ',
  MTD: '(MTD)',
  QTD: '(QTD)',
  YTD: '(YTD)',
  MONTH: 'Tháng',
  ONE_TWO_THREE_YEARS: 'Kỳ hạn 1-3 năm',
  THREE_TWO_FIVE_YEARS: 'Kỳ hạn 3-5 năm',
  FIVE_TO_SEVEN_YEARS: 'Kỳ hạn 5-7 năm',
  OVER_SEVEN_YEAR: 'Kỳ hạn ≥ 7 năm',
  FLOAT: 'Thả nổi',
  ZERO: 'Không trái tức',
  MIXED: 'Kết hợp',
  FIXED: 'Cố định',
  OTHERS: 'Khác',
}

export const issuanceValue = {
  TITLE: 'Giá trị phát hành',
  NOTE: 'Giá trị: Tỷ VNĐ. Kỳ hạn: Năm',
  SUMMARY: 'Tổng',
  FIXED: 'Cố định',
  FLOATING_AND_COMBINE: 'Thả nổi & kết hợp',
  OTHER: 'Khác',
  INDUSTRY: 'Ngành',
  RELEASE_VALUE: 'Giá trị',
  RELEASE_DURATION: 'Kỳ hạn BQ',
  COUPON: 'Coupon BQ',
}

export const issuanceValueBySector = {
  TITLE: 'Giá trị trái phiếu phát hành theo ngành',
  NET_VALUE: 'Giá trị (Tỷ VND)',
  MARKET: 'Toàn thị trường',
  UNIT: ' tỷ VND',
}

export const issuanceValueByCouponType = {
  TITLE: 'Cơ cấu phát hành trái phiếu',
  OTHERS: 'Khác',
  BILLION: 'tỷ',
}

export const averageCouponBySector = {
  TITLE: 'Coupon phát hành bình quân',
  PERCENT: 'Đơn vị (%)',
}

export const averageDurationBySector = {
  TITLE: 'Kỳ hạn phát hành bình quân ngành',
  DURATION: 'Kì hạn phát hành (Năm)',
  YEAR: 'năm',
}

export const issuers = {
  TITLE: 'Tổ chức phát hành',
  SYNTHETIC: 'Tổng hợp',
  MONTHLY: 'Hàng tháng',
  QUARTERLY: 'Hàng quý',
  YEARLY: 'Hàng năm',
  MTD: '(MTD)',
  QTD: '(QTD)',
  YTD: '(YTD)',
  PRIVATE: 'Riêng lẻ',
  PUBLIC: 'Công chúng',
  DEBT_LOAN: 'Nợ và vay nợ',
}

export const bondIssueInformation = {
  TITLE: 'Trái phiếu doanh nghiệp phát hành',
  BOND_STATUS: 'Tình trạng',
  BOND_EVENT: 'Sự kiện tín dụng',
  ALL: 'Tất cả',
  NORMAL: 'Bình thường',
  CANCELLATION_DUE_TO_MATURITY: 'Huỷ do đáo hạn',
  CANCELED_DUE_TO_REDEMPTION: 'Huỷ do mua lại',
  DUE_BUT_BOT_FULLY_PAID: 'Đáo hạn nhưng chưa thanh toán toàn bộ',
  COLLATERAL: 'TSĐB',
  RELATED: 'Trái phiếu của các công ty liên quan',
  YES: 'Có',
  NO: 'Không',
  BOND_TICKER: 'Mã TP',
  ORGANIZATION_NAME: 'TCPH',
  RELATIONSHIP: 'Mối quan hệ',
  TICKER: 'Mã CK',
  ISSUE_METHOD: 'Phương thức',
  NEXT_INTEREST_PAYMENT_DATE: 'Ngày trả lãi tiếp theo',
  ISSUE_DATE: 'Ngày phát hành',
  MATURITY_DATE: 'Ngày đáo hạn',
  COUPON: 'Coupon',
  PERCENT: '(%)',
  ISSUE_VALUE: 'Phát hành',
  VALUE: '(Tỷ VND)',
  OUTSTANDING_VALUE: 'Lưu hành',
  DURATION: 'KHCL',
  MONTH: '(Tháng)',
  COLLATERAL_VALUE: 'Giá trị TSĐB',
  INTEREST_PAID: 'Lãi đã TT',
  PRINCIPAL_PAID: 'Gốc đã TT',
  ATTACHED_DOCUMENT: 'Văn bản đính kèm',
  OVERDUE_COUPON: 'Lãi quá hạn',
  OVERDUE_PRINCIPAL: 'Gốc quá hạn',
  VIEW: 'Xem',
  SUMMARY: 'Tổng',
}

export const relativeToEquity = {
  TITLE: 'Giá trị trái phiếu lưu hành',
  VALUE: 'Giá trị (Tỷ VND)',
  VALUE_OF_OUTSTANDING_BOND: 'Lưu hành cuối kì',
  VALUE_OF_ISSUE_BOND: 'Phát hành',
  VALUE_OF_MATURITY_BOND: 'Đáo hạn',
  VALUE_OF_REDEEMED_BOND: 'Mua lại',
  ENDING_EQUITY: 'Vốn chủ sở hữu cuối kì',
  OUTSTANDING_BOND: 'Lưu hành/VCSH',
  ISSUE_BOND: 'Trái phiếu phát hành',
  MATURITY_BOND: 'Trái phiếu đáo hạn',
  REDEEMED_BOND: 'Trái phiếu mua lại',
  EQUITY: 'Vốn chủ sở hữu',
  UNIT: 'tỷ VND',
  TIME: 'lần',
}

export const CouponInterestRateChart = {
  OUTSTANDING_BOND_VALUE: 'Trái phiếu lưu hành:',
  AVG_COUPON: 'Kỳ hạn còn lại bình quân:',
  AVG_COUPON_ALL: 'Kỳ hạn còn lại bình quân tất cả khoảng lãi suất:',
  VALUE_OF_OUTSTANDING_BONDS_LEGEND: 'Giá trị trái phiếu lưu hành',
  AVG_COUPON_LEGEND: 'Kỳ hạn còn lại bình quân',
  AVG_COUPON_ALL_LEGEND: 'Kỳ hạn còn lại bình quân tất cả khoảng lãi suất',
  COUPON: 'Kỳ hạn (năm)',
}

export const remainingMaturities = {
  TITLE: 'Giá trị trái phiếu đang lưu hành theo kì hạn còn lại và coupon',
  REMAINING_MATURITIES: 'Giá trị Trái phiếu lưu hành theo kì hạn còn lại',
  COUPON_INTEREST_RATE: 'Giá trị Trái phiếu lưu hành theo lãi suất',
  DURATION: 'Kỳ hạn',
  COUPON: 'Coupon',
  REMAINING_DURATION: 'Kỳ hạn còn lại',
  UNDER_ONE_YEAR: '<1 năm',
  ONE_TWO_THREE_YEARS: '1-3 năm',
  THREE_TWO_FIVE_YEARS: '3-5 năm',
  FIVE_TO_SEVEN_YEARS: '5-7 năm',
  OVER_SEVEN_YEARS: '>=7 năm',
  VALUE: 'Giá trị (tỷ VND)',
  PERCENT: 'Coupon (%)',
  COUPON_AVERAGE: 'Coupon bình quân',
  COUPON_AVERAGE_DURATION: 'LS Coupon bình quân các kì hạn',
  UNIT: 'tỷ VND',
  OUTSTANDING_BONDS: 'Trái phiếu lưu hành:',
  COUPON_AVERAGE_2: 'Coupon bình quân:',
  COUPON_AVERAGE_DURATION_2: 'Coupon bình quân các kì hạn:',
  DURATION_VALUE: 'Coupon (%)',
  INTEREST_RATE: 'Lãi suất',
  UNDER_SIX_PERCENTS: '<6%',
  SIX_TO_NINE_PERCENTS: '6-9%',
  NINE_TO_TWELVE_PERCENTS: '9-12%',
  TWELVE_TO_FIFTEEN_PERCENTS: '12-15%',
  OVER_FIFTEEN_PERCENTS: '>15%',
  AVERAGE_DURATION_FOR_INTEREST_RATE:
    'Kỳ hạn bình quân ứng với khoảng lãi suất',
  AVERAGE_DURATION_OF_ALL_INTEREST_RATES:
    'Kỳ hạn bình quân tất cả khoảng lãi suất',
  DAILY: 'Hàng ngày',
  MONTHLY: 'Hàng tháng',
  WEEKLY: 'Hàng tuần',
  QUARTERLY: 'Hàng quý',
  YEARLY: 'Hàng năm',
  MATCHED: 'Phương thức khớp lệnh',
  DEAL: 'Phương thức thỏa thuận',
  AVERAGE: 'Giá trị giao dịch TB 1 ngày',
  MATCHED_1: 'Khớp lệnh:',
  DEAL_1: 'Thỏa thuận:',
  AVERAGE_1: 'Giá trị giao dịch trung bình 1 ngày:',
  VALUE_OF_OUTSTANDING_BONDS: 'Trái phiếu lưu hành:',
  AVG_COUPON: 'Coupon bình quân:',
  AVG_COUPON_ALL_PERIOD: 'Coupon bình quân các kì hạn còn lại:',
  VALUE_OF_OUTSTANDING_BONDS_LEGEND: 'Giá trị trái phiếu lưu hành',
  AVG_COUPON_LEGEND: 'Coupon bình quân',
  AVG_COUPON_ALL_PERIOD_LEGEND: 'Coupon bình quân các kì hạn',
}

export const interestAndPrincipalPaid = {
  TITLE: 'Dòng tiền phải trả từ trái phiếu dự kiến',
  PRINCIPAL_PAID: 'Nợ gốc phải trả',
  COUPON_PAID: 'Lãi coupon phải trả',
  VALUE: 'Đơn vị (tỷ VND)',
  UNIT: 'tỷ VND',
  EXPECT: 'dự kiến',
  TOOLTIP:
    'Dòng tiền phải trả dự kiến đã bao gồm nợ tái cấu trúc của các TCPH theo thông tin cập nhật tại thời điểm mới nhất.',
}
export const valueChart = {
  '3M': '3M',
  '6M': '6M',
  YTD: 'YTD',
  '1Y': '1Y',
  DURATION: 'Kỳ hạn',
  YTM: 'YTM',
  VALUE: 'Giá trị giao dịch',
  AVERAGE_VALUE: 'Kỳ hạn khớp trung bình',
  DURATION_AVERAGE: 'Kỳ hạn khớp trung bình',
  AVERAGE_YTM: 'YTM trung bình',
  MONTH: 'năm',
  UNIT: 'Tỷ VNĐ',
  DURATION_YEAR: 'Kỳ hạn (Năm)',
  AVG_YTM: 'YTM trung bình',
}

export const secondaryMarket = {
  TITLE: 'Thị trường thứ cấp',
  OVERVIEW: 'Tổng quan',
  MARKET_STATISTICS: 'Thống kê thị trường',
  BOARD: 'Bảng giá',
  TRADE_STATISTICS: 'Thống kê giao dịch',
  MONTH: '(tháng)',
  LIQUIDITY: 'Thanh khoản thị trường',
  VALUE: 'Top 5 tổ chức phát hành theo giá trị giao dịch',
}
export const tradingStatistics = {
  DATE: 'Ngày',
  TICKER: 'Mã TP',
  ORGANIZATION_NAME: 'TCPH',
  COUPON: 'Loại Coupon',
  YTM: '+/-YTM',
  OPEN_PRICE: 'Mở cửa',
  HIGHEST_PRICE: 'Cao nhất',
  LOWEST_PRICE: 'Thấp nhất',
  CLOSE_PRICE: 'Đóng cửa',
  MATCHED_VOLUME: 'KL khớp',
  MATCHED_VALUE: 'Giá trị khớp',
  DEAL_VOLUME: 'KL Thỏa thuận',
  DEAL_VALUE: 'GT Thỏa thuận',
  TOTAL_VOLUME: 'Tổng KL',
  TOTAL_VALUE: 'Tổng GT',
}

export const priceBoard = {
  filterTCPH: 'Tìm kiếm TCPH',
  TITLE: 'Bảng giá',
  ORDER_MATCHING: 'Khớp lệnh',
  PUT_THROUGH: 'Thoả thuận',
  DIRTY_PRICE: 'Giá bẩn',
  CLEAN_PRICE: 'Giá sạch',
  LISTED: 'Niêm yết',
  PUBLIC: 'Công chúng',
  PRIVATE: 'Riêng lẻ',
  BOND_WITH_TRADING: 'Trái phiếu có giao dịch',
  EXCHANGE_TRANSACTION_PRICE: 'Giá giao dịch quy đổi',
  BOND_WITH_TRADING_TOOLTIP: 'Trái phiếu có lệnh mua, bán hoặc có giao dịch',
  NOTE: 'Giá: theo mệnh giá với điểm cơ sở là 100. Khối lượng x 1 Trái phiếu. Giá trị x 1,000,000 VND',
  NOTE_2: 'Giá x 1 VND. Khối lượng x 1 Trái phiếu. Giá trị x 1,000,000 VND',
  NOTE_3: 'Giá x 1. Khối lượng x 1. Giá trị x1,000,000.',
  ALL: 'Tất cả',
  UNDER_SIX_MONTHS: '<6M',
  FROM_SIX_TO_TWELVE_MONTHS: '6-12M',
  FROM_TWELVE_TO_EIGHTEEN_MONTHS: '12-18M',
  FROM_EIGHTEEN_TO_TWENTY_FOUR_MONTHS: '18-24M',
  FROM_TWENTY_FOUR_TO_THIRTY_SIX_MONTHS: '24-36M',
  FROM_THREE_TO_FIVE_YEARS: '3-5Y',
  FROM_FIVE_TO_SEVEN_YEARS: '5-7Y',
  OVER_SEVEN_YEARS: '>7Y',
  UNDER_ONE_THOUSANDS: '<100 nghìn',
  FROM_ONE_THOUSANDS_TO_ONE_MILLION: '100 nghìn - 1 triệu',
  FROM_ONE_MILLION_TO_ONE_HUNDRED_MILLION: '1 - 100 triệu',
  FROM_ONE_HUNDRED_MILLION_TO_ONE_BILLION: '100 triệu - 1 tỷ',
  OVER_ONE_BILLION: '>1 tỷ',
  RESET: 'Đặt lại',
  SAVE: 'Lưu danh mục',
  BID: 'Đặt mua',
  MATCHED: 'Khớp lệnh',
  ASK: 'Đặt bán',
  TRADING_PRICE: 'Giá giao dịch',
  INFORMATION: 'Thông tin',
  BOND_CODE: 'Mã trái phiếu',
  TICKER: 'Mã trái phiếu',
  ISSUER: 'Tổ chức phát hành',
  COUPON: 'Coupon',
  COUPON_TYPE_2: 'Loại Coupon',
  PRICE_1: 'Giá 1',
  PRICE_2: 'Giá 2',
  PRICE_3: 'Giá 3',
  VOLUME_1: 'KL 1',
  VOLUME_2: 'KL 2',
  VOLUME_3: 'KL 3',
  YTM: '+/- YTM',
  PRICE_YTM: 'Giá',
  DURATION_TEXT: 'KHCL (tháng)',
  MATCHED_VOLUME: 'KL',
  MATCHED_VALUE: 'GT',
  HIGH: 'Cao',
  LOW: 'Thấp',
  AVG: 'Bình quân',
  TOTAL_VALUE: 'Tổng GT',
  COLLATERAL: 'TSĐB',
  REMAINING_DURATION: 'KHCL',
  ISSUE_METHOD: 'Hình thức',
  ISSUE_METHOD_2: 'phát hành',
  RANGE_YTM: 'Giá trị nhâp không đúng, xin vui lòng nhập lại giá trị',
  YES: 'Có',
  NO: 'Không',
  SORT_BY_PRICE: 'Sort by Price',
  SORT_BY_YTM: 'Sort by YTM',
  INDUSTRY: 'Ngành',
  ISSUE_DATE: 'Ngày phát hành',
  DUE_DATE: 'Ngày đáo hạn',
  INTEREST_PAYMENT_PERIOD: 'Kỳ trả lãi (tháng/lần)',
  CREDIT_EVENT: 'Sự kiện tín dụng gần nhất',
  DURATION: 'Kỳ hạn còn lại',
  COUPON_TYPE: 'Loại lãi suất',
  EXPECTED_COUPON: 'Lãi suất dự kiến',
  COUPON_BENCHMARK: 'Lãi suất tham chiếu',
  CAUTION: 'Lưu ý: Lãi tham chiến hiện đang khác với lãi suất công bố của TCPH',
  TRADING_DATE: 'Ngày giao dịch',
  PAYMENT_DATE: 'Ngày thanh toán',
  EXRIGHT_DATE: 'Ngày chốt quyền',
  VOLUME: 'KL mua bán (TP)',
  TOTAL_PAR_VALUE: 'Tổng giá trị mệnh giá',
  YIELD_TO_MATURITY: 'Lãi suất (YTM)',
  DIRTY: 'Giá bẩn (VND)',
  CLEAN: 'Giá sạch (VND)',
  PLACEHOLDER: 'Nhập giá trị',
  PLACEHOLDER_2: 'Nhập khối lượng',
  BID_2: 'Chào mua',
  MATCHED_2: 'Thực hiện',
  ASK_2: 'Chào bán',
  TRADING_PRICE_2: 'Tổng hợp giao dịch',
  PRICE_5: 'Giá mua',
  PRICE_6: 'cao nhất',
  PRICE_7: 'Giá bán',
  PRICE_8: 'thấp nhất',
  VOLUME_5: 'KL mua tại giá',
  VOLUME_6: 'cao nhất',
  VOLUME_7: 'KL bán tại giá',
  VOLUME_8: 'thấp nhất',
  HIGH_2: 'Tổng KL',
  HIGH_3: 'chào mua',
  LOW_2: 'Tổng KL',
  LOW_3: 'chào bán',
  AVG_2: 'Tổng KLGD',
  TOTAL_VALUE_2: 'Tổng GTGD',
  MATCHED_VOLUME_2: 'KL',
  MATCHED_VALUE_2: 'GT',
  PRICE_NOTE:
    'Lãi suất YTM được tính toán dựa trên dòng tiền dự kiến theo thông tin của mã Trái Phiếu, tuy nhiên kết quả có thể chênh lệch do việc cập nhật lãi suất thả nổi, hoặc các thay đổi khác của TCPH. Vui lòng kiểm tra kỹ thông tin trước khi giao dịch.',
  MY_WATCHLIST: 'Danh mục của tôi',
  SEARCH_PLACEHOLDER: 'Tìm kiếm mã TP hoặc TCPH',
  SEARCH_PLACEHOLDER_STATUS: 'Tìm kiếm',
  D: 'Mới nhất',
  WTD: 'Từ đầu tuần',
  MTD: 'Từ đầu tháng',
  QTD: 'Từ đầu quý',
  YTD: 'Từ đầu năm',
  ALL_SECTOR: 'Tất cả ngành',
  ALL_PRICE: 'Tất cả mức giá',
  SELECTED: 'Đã chọn %{number}',
  ALL_SEARCH: 'Tất cả',
}

export const benchmarkingSecondary = {
  D: 'Mới nhất',
  WTD: 'Từ đầu tuần',
  MTD: 'Từ đầu tháng',
  QTD: 'Từ đầu quý',
  YTD: 'Từ đầu năm',
  W: 'Tuần',
  M: 'Tháng',
  Q: 'Quý',
  Y: 'Năm',
}
export const sectorStatistic = {
  TITLE: 'Thống kê ngành',
  TOTAL_VALUE: 'Tổng GTGD',
  TOTAL_VALUE_OF_PUBLIC_BOND: 'GTGD TP công chúng',
  TOTAL_VALUE_OF_PRIVATE_BOND: 'GTGD TP riêng lẻ',
  AVG_REMAIN_DURATION: 'KHCL bình quân',
  AVG_YTM: 'YTM bình quân',
  YEAR: '(năm)',
  UNIT: '(Tỷ VND)',
  PERCENT: '(%)',
}
export const correlationOfSecondaryMarket = {
  TITLE: 'Tương quan lãi suất thị trường thứ cấp và kỳ hạn còn lại',
  SECTOR: 'Ngành',
  ORGANIZATION: 'Tổ chức phát hành',
  AVG_YTM: 'YTM bình quân',
  REMAINING_DURATION: 'Kỳ hạn còn lại bình quân',
  REMAINING: 'Kỳ hạn còn lại',
  TOTAL_TRADING_VALUE: 'Tổng giá trị giao dịch',
  YEAR: 'Đơn vị (năm)',
  MONTH: 'Tháng',
}
export const marketTradingValue = {
  TITLE: 'Thanh khoản thị trường',
  ISSUE_METHOD: 'Hình thức phát hành',
  TRADING_METHOD: 'Phương thức giao dịch',
  PUBLIC: 'Phát hành công chúng',
  PRIVATE: 'Phát hành riêng lẻ',
  MATCHED_VALUE: 'Giao dịch khớp lệnh',
  PUT_THROUGH_VALUE: 'Giao dịch thỏa thuận',
  TOTAL: 'Tổng',
}

export const marketTradingValueBySector = {
  TITLE: 'Giá trị giao dịch theo ngành',
  ALL: 'Tất cả',
  LessThan1Year: 'Nhỏ hơn 1 năm',
  From1To3Years: 'Từ 1 đến 3 năm',
  From3To5Years: 'Từ 3 đến 5 năm',
  From5To7Years: 'Từ 5 đến 7 năm',
  AboveThan7Years: 'Trên 7 năm',
}

export const debtRelationInBalance = {
  TITLE: 'Mối tương quan vay nợ trong cân đối kế toán',
  UNIT: 'Đơn vị (lần)',
  DEBT: 'Vay nợ',
  OWNER: 'Vốn chủ sở hữu',
  RATIO: 'Tỷ lệ nợ vay/VCSH',
  TTS: 'Tỷ lệ nợ vay/TTS',
  QUARTER: 'Quý',
  YEAR: 'Năm',
}
export const scaleOfDebtByObjects = {
  TITLE: 'Quy mô vốn nợ vay theo đối tượng',
  DEBT: 'Tổng nợ vay',
  OWNER: 'Giá trị trái phiếu phát hành',
  RATIO: 'Nợ vay ngân hàng',
  OTHER: 'Nợ vay khác',
}
export const debtStatistic = {
  TITLE: 'Quy mô nợ vay',
  BOND: 'Giá trị trái phiếu phát hành',
  OTHER: 'Khác',
}
export const creditRatio = {
  TITLE: 'Chỉ số tín dụng',
  TOTAL_DEBT: 'Tổng nợ vay / EBITDA',
  NET_DEBT: 'Nợ vay ròng / EBITDA',
  DEBT_EXPENSE: 'EBITDA / Chi phí lãi vay',
  DEBT_EXPENSE_EDIT: 'EBIT/ Chi phí Lãi vay',
}
export const debtDetails = {
  TITLE: 'Chi tiết đối tượng nợ vay',
  BOND: 'Trái phiếu',
  SHORT_TERM: 'Nợ vay ngắn hạn',
  LONG_TERM: 'Nợ vay dài hạn',
}
export const lendingCom = {
  LOAN_NAME: 'Công ty cho vay',
  NAME: 'Công ty vay',
  NAME_COM: 'Công ty cho vay',
  DURATION: 'Kỳ hạn',
  DEBT_VALUE: 'Giá trị nợ vay',
  DEBT_VALUE_1: 'Giá trị vay',
  UNIT: '(Tỷ VND)',
  INTEREST: 'Lãi suất',
  PAYMENT: 'Kỳ hạn',
  PAYMENT_1: 'Mô tả',
  PERIOD: 'Kỳ báo cáo',
  TYPE: 'Loại',
}
export const topLiquidity = {
  TITLE: 'Top thanh khoản',
  ISSUER: 'TCPH',
  TP: 'Mã TP',
  ALL: 'Tất cả',
  MATCH: 'Khớp lệnh',
  DEAL: 'Thỏa thuận',
  NOTE: 'Dữ liệu được tính toán vào ngày %{now}',
  NOTE_2: 'Dữ liệu được tính toán từ ngày %{from} đến ngày %{to}',
  TRADING_VALUE: 'GTGD',
  AVERAGE_REMAIN_LENGTH: 'KHCL',
  CHANGE_OF_YTM: 'YTM',
  CHANGE_OF_YTM_2: 'YTM BQ',
  CLEAN_PRICE: 'Giá sạch',
  CLEAN_PRICE_2: 'Giá sạch BQ',
  DIRTY_PRICE: 'Giá bẩn',
  DIRTY_PRICE_2: 'Giá bẩn BQ',
  CHANGE_CLEAN_PRICE: 'Thay đổi giá sạch',
  CHANGE_DIRTY_PRICE: 'Thay đổi giá bẩn',
  PERCENT: '(%)',
  PUBLIC: 'Công chúng',
  PRIVATE: 'Riêng lẻ',
}
export const marketLiquidity = {
  TITLE: 'Thanh khoản thị trường',
  TOTAL_TODAY: 'Tổng GTGD %{time}',
  ONE_WEEK: '1 tuần',
  ONE_MONTH: '1 tháng',
  THREE_MONTHS: '3 tháng',
  TOTAL_YESTERDAY: 'Tổng GTGD kỳ trước',
  LABEL: 'Giá trị (tỷ VND)',
  ISSUER: 'TCPH',
}
export const averageYtmInterest = {
  TITLE: 'Lãi suất (YTM)  bình quân tổ chức phát hành',
  YTM_CHANGE: 'Thay đổi +/- YTM',
  REAL: 'Lãi suất thực tế',
  NOT_REAL: 'Lãi suất nội suy',
  outstandingValue: 'GTLH',
  maturityDateId: 'YTM BQ',
  remainingLength: 'KHCL BQ',
  UNIT: '(Tỷ VND)',
  VND: '(VND)',
  PERCENT: '(%)',
  MONTH: '(Tháng)',
  ISSUER: 'TCPH',
}
export const topBidAsk = {
  TITLE_BID: 'Top lãi suất đặt mua',
  TITLE_ASK: 'Top lãi suất đặt bán',
  VND: '(VND)',
  BOND: '(Trái phiếu)',
  PERCENT: '(%)',
  MONTH: '(Tháng)',
  KHCL: 'KHCL',
  BestBid: 'Giá',
  BestAsk: 'Giá',
  VOL_ASK: 'KL',
  YTM: 'YTM',
  VOL: 'KL',
  TICKER: 'Mã TP',
  ISSUER: 'TCPH',
}
