import { createAsyncThunk } from '@reduxjs/toolkit'
import PenetrationAnalysisService from '../../../../../../../core/services/corporate/PenetrationAnalysisService'

export const getMarketShareChartData = createAsyncThunk(
  'corporate/businessModel/penetration/securities/panelBrokerageMarketShare/MARKET_SHARE_CHART_DATA',
  async (params, { rejectWithValue }) => {
    const response =
      await PenetrationAnalysisService.getSecMarketShareChartData(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
