import { EMPTY_VALUE } from '../../../../constants/Common.js'
import { ROW_ID_WITHOUT_CHILDREN } from '../panelExchangeRate/config.js'

export const handleLineChartData = (payload) => {
  if (!payload.length) {
    return []
  }

  const tradingDateIdNotDuplicate = handleDuplicate(payload)

  return tradingDateIdNotDuplicate.map((item) => {
    const dataWithTradingId = payload.filter(
      (i) => i.tradingDateId === item.tradingDateId,
    )

    return dataWithTradingId.reduce((previous, current) => {
      const key = handleKey(current, 'sourceOrganizationId', 'baseCurrencyId')
      return {
        ...previous,
        [`${key}-askRate`]: current.askRate,
        [`${key}-bidRateCash`]: current.bidRateCash,
        [`${key}-bidRateTransfer`]: current.bidRateTransfer,
        day: item.tradingDateId,
      }
    }, {})
  })
}

const handleDuplicate = (payload) => {
  return payload.filter((item, index, arr) => {
    return (
      index === arr.findIndex((i) => i.tradingDateId === item.tradingDateId)
    )
  })
}

const handleKey = (item, parentKey, childrenKey) => {
  if (item === undefined) {
    return
  }

  if (ROW_ID_WITHOUT_CHILDREN.includes(item[parentKey])) {
    return `${item[parentKey]}`
  }

  return `${item[parentKey]}-${item[childrenKey]}`
}

export const handleLineChartHasAuthor = (
  lineChartData,
  idsWithInfo,
  parentIdWithTitle,
  dropdownValue,
) => {
  return lineChartData
    .filter((item) => item.author !== undefined)
    .map((item) => {
      const itemWithId = idsWithInfo.find(
        (element) => `${element.id}` === `${item.author}`,
      )
      const subTitle = itemWithId
        ? getSubTitle(itemWithId, parentIdWithTitle)
        : ''

      return {
        ...item,
        indicatorName: `${itemWithId?.title}${subTitle}`,
        dataKey: `${item.author}-${dropdownValue}`,
      }
    })
}

export const handleTooltipTwoColumn = (idChecked) => {
  const MAX_ITEM_OF_COLUMN = 8
  if (idChecked.length < MAX_ITEM_OF_COLUMN) {
    return { leftColumn: idChecked, rightColumn: [] }
  }

  const leftColumnLength = Math.ceil(idChecked.length / 2)
  return {
    leftColumn: idChecked.concat().splice(0, leftColumnLength),
    rightColumn: idChecked.concat().splice(leftColumnLength),
  }
}

export const getSubTitle = (itemWithId, parentIdWithTitle) => {
  if (itemWithId.id === itemWithId.sourceOrganizationId) {
    return ''
  }

  const parentIds = Object.keys(parentIdWithTitle)
  if (parentIds.includes(`${itemWithId?.sourceOrganizationId}`)) {
    return ` (${parentIdWithTitle[itemWithId?.sourceOrganizationId]})`
  }

  return ''
}

export const handleLineColor = (id, lineChartColor, isChecked) => {
  if (isChecked) {
    return lineChartColor.map((color) => {
      if (color.author === `${id}`) {
        return {
          ...color,
          author: undefined,
        }
      }
      return color
    })
  } else {
    let count = 0
    return lineChartColor.map((color) => {
      if (count === 0 && color.author === undefined) {
        count++
        return {
          ...color,
          author: id,
        }
      }
      return color
    })
  }
}

export const formatMonthYear = (date) => {
  if (!date) {
    return EMPTY_VALUE
  }

  const month = new Date(date).getMonth() + 1
  const year = new Date(date).getFullYear()

  return `${month < 10 ? '0' : ''}${month}-${year}`
}
