import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import { tableChartSwitch, timeSalesDateFilter } from './constants'
import { resetState } from './store/slice'

export const ChartFilter = ({
  viewType,
  setViewType,
  dateType,
  setDateType,
}) => {
  const dispatch = useDispatch()
  const handleSetViewType = () => {
    setViewType(
      viewType === tableChartSwitch.HORIZONTAL_STACK_BAR
        ? tableChartSwitch.TABLE
        : tableChartSwitch.HORIZONTAL_STACK_BAR,
    )
    if (viewType === tableChartSwitch.TABLE) {
      dispatch(resetState())
    }
  }

  return (
    <div className="align-center j-b">
      <Button
        className="button-filter-chart"
        onClick={(_) => handleSetViewType()}
      >
        <i
          className={`${
            viewType === tableChartSwitch.TABLE ? 'icon-chart' : 'icon-dash'
          }`}
        />
      </Button>
      {viewType === tableChartSwitch.HORIZONTAL_STACK_BAR ? (
        <DispatchActionTab
          data={Object.keys(timeSalesDateFilter).map((key) => ({
            title: I18n.t(`market.derivative.${key}`),
            value: timeSalesDateFilter[key],
          }))}
          activeTab={dateType}
          onChangeTab={(item) => setDateType(item.value)}
          itemStyle={{ width: '20px', fontWeight: 500, fontSize: 10 }}
        />
      ) : null}
    </div>
  )
}

ChartFilter.propTypes = {
  viewType: PropTypes.string.isRequired,
  setViewType: PropTypes.func.isRequired,
  dateType: PropTypes.string.isRequired,
  setDateType: PropTypes.func.isRequired,
}
