import PropTypes from 'prop-types'
import { range } from '../../../../utils/Common'
import ItemSelect from './ItemSelect'

const Year = ({ keyYear, disabled, minYear, maxYear }) => {
  const selectYear = range(minYear, maxYear)
    .reverse()
    .map((year) => {
      return { value: year, name: year }
    })

  return (
    <ItemSelect
      defaultValue={maxYear}
      keySelect={keyYear}
      selectData={selectYear}
      disabled={disabled}
    />
  )
}

Year.propTypes = {
  keyYear: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

Year.defaultProps = {
  disabled: false,
}

export default Year
