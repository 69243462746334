import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { getFontSize } from '../../../../utils/FontSize'
import { ChartContainer } from '../../common/ChartContainer'
import { StackedChartWithLine } from '../../common/StackedBarLineChart'
import {
  getCompareChartMargin,
  getComparisonChartData,
  getGrowthTimelineChartData,
} from '../helps'
import {
  selectFinancialRatioFilter,
  selectIndicator,
  selectMappingLoading,
  selectTableConstants,
} from '../store/slice'
import {
  chartFillColor,
  chartSettings,
  chartTopLabelByTimeDisplay,
} from './constants'
import {
  resetChartData,
  selectGrowthTimelineChartData,
  selectGrowthTimelineChartLoading,
  selectPeerComparisonChartData,
} from './store/slice'
import { getFinancialRatioChartData } from './store/thunk'

export const GrowthTimelineChart = ({ height, width }) => {
  const calcFontSize = getFontSize(chartSettings.fontSize)
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const growthTimelineChartData = useSelector(selectGrowthTimelineChartData)
  const peerComparisonChartData = useSelector(selectPeerComparisonChartData)
  const filter = useSelector(selectFinancialRatioFilter)
  const isLoading = useSelector(selectGrowthTimelineChartLoading)
  const isMappingLoading = useSelector(selectMappingLoading)
  const indicator = useSelector(selectIndicator)
  const tableConstants = useSelector(selectTableConstants)

  const isFormatValToPercent = indicator.subValue?.isFormatValToPercent

  // Get data
  const getGrowthTimelineData = useMemo(() => {
    return getGrowthTimelineChartData(
      indicator,
      growthTimelineChartData,
      filter,
    )
  }, [
    JSON.stringify(indicator.subValue),
    growthTimelineChartData,
    filter.viewBy.value,
  ])

  const getComparisonData = useMemo(() => {
    return getComparisonChartData(indicator, peerComparisonChartData, basicInfo)
  }, [JSON.stringify(indicator.subValue), peerComparisonChartData, basicInfo])

  const getKey = useMemo(() => {
    return new Date().getTime()
  }, [indicator.subValue, growthTimelineChartData, peerComparisonChartData])

  const getTopLabel = useMemo(() => {
    return `${indicator.subValue?.name || ''} ${
      I18n.t(chartTopLabelByTimeDisplay[filter.viewBy.value]) || ''
    }`
  }, [indicator.subValue, filter.viewBy.value])

  const getLeftLabel = useMemo(() => {
    if (isFormatValToPercent) {
      return ''
    }

    switch (indicator.subValue?.isFormatValue.formatValue) {
      case 10 ** 3:
        return 'corporate.financialData.financialRatio.CHART_THOUSAND_VND'
      case 10 ** 6:
        return 'corporate.financialData.financialRatio.CHART_MILLION_VND'
      case 10 ** 9:
        return 'corporate.financialData.financialRatio.CHART_BILLION_VND'
      default:
        return indicator.subValue?.unit
    }
  }, [indicator.subValue])

  const getChartUnit = useMemo(() => {
    if (isFormatValToPercent) {
      return '%'
    }

    return indicator.subValue?.unit
  }, [indicator.subValue])

  // Use effect
  useEffect(() => {
    if (!isLoading && basicInfo.organizationId && tableConstants.length) {
      dispatch(resetChartData())
      dispatch(
        getFinancialRatioChartData({
          OrganizationId: basicInfo.organizationId,
          TimeFilter: filter.viewBy.value,
          NumberOfPeriod: filter.numberOfPeriod.value,
          LatestYear: filter.lastestYear.value,
          Consolidated: filter.type.value,
        }),
      )
    }
  }, [filter, basicInfo.organizationId, tableConstants])

  return (
    <div id="growthTimelineChart" className="h-100">
      <ChartContainer
        key={getKey}
        data={getGrowthTimelineData}
        topLabel={getTopLabel}
        height={height}
        width={width}
        isLoading={isLoading || isMappingLoading}
      >
        {(resizeSize) => (
          <StackedChartWithLine
            data={getGrowthTimelineData}
            width={resizeSize.width}
            height={resizeSize.height}
            fillColor={chartFillColor}
            xAxisKey="label"
            barKeys={['growthTimelineValue']}
            unit={getChartUnit}
            mappingDisplayName={{
              growthTimelineValue: indicator.subValue?.name || '',
            }}
            leftLabel={getLeftLabel}
            margin={{
              ...getCompareChartMargin(
                getComparisonData,
                getGrowthTimelineData,
                ['comparisonValue'],
                ['growthTimelineValue'],
                calcFontSize,
              ),
            }}
            isBreakNewLine
            isXAxisDiv
            isYAxisPercentLeft={isFormatValToPercent}
          />
        )}
      </ChartContainer>
    </div>
  )
}

GrowthTimelineChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
