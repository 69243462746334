import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { DEFAULT_CHECKED } from '../config'
import {
  handleMappingData,
  initCurrentPage,
  initLineColor,
  keyByDate,
} from '../helper'
import {
  getMaxDateOMO,
  getOpenMarketOperations,
  getOpenMarketOperationsTitle,
} from './thunk'

const previousYear = new Date()
previousYear.setFullYear(previousYear.getFullYear() - 1)

const initialState = {
  from: previousYear,
  to: new Date(),
  timeRange: 'Daily',
  tableDataLoading: true,
  tableData: {},
  tableMappingLoading: true,
  tableMapping: [],
  groupColumns: [],
  checkboxChecked: DEFAULT_CHECKED,
  lineChartColor: [],
  currentPage: undefined,
  isScrollRight: true,
  previousColumns: {
    prevFirstColumn: undefined,
    prevLastColumn: undefined,
  },
  loadingBlur: false,
  isShowAlert: false,
  maxDateOMO: undefined,
}

const slice = createSlice({
  name: 'economy/monetary/openMarket/panelOpenMarket',
  initialState,
  reducers: {
    handleFromDate: (state, action) => {
      state.from = action.payload
    },
    handleToDate: (state, action) => {
      state.to = action.payload
    },
    handleTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
    handleCheckboxChecked: (state, action) => {
      state.checkboxChecked = action.payload
    },
    handleGroupColumns: (state, action) => {
      state.groupColumns = action.payload
    },
    handleLineChartColor: (state, action) => {
      state.lineChartColor = action.payload
    },
    handleCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    handleIsScrollRight: (state, action) => {
      state.isScrollRight = action.payload
    },
    handlePreviousColumns: (state, action) => {
      state.previousColumns = action.payload
    },
    handleLoadingBlur: (state, action) => {
      state.loadingBlur = action.payload
    },
    handleIsShowAlert: (state, action) => {
      state.isShowAlert = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOpenMarketOperations.pending, (state) => {
      state.tableDataLoading = true
    })
    builder.addCase(getOpenMarketOperations.fulfilled, (state, action) => {
      state.tableDataLoading = false
      state.tableData = keyByDate(action.payload || [])
      state.currentPage = initCurrentPage(action.payload || [])
    })
    builder.addCase(getOpenMarketOperations.rejected, (state, action) => {
      state.tableDataLoading = action.payload.isLoading
    })
    builder.addCase(getOpenMarketOperationsTitle.pending, (state) => {
      state.tableMappingLoading = true
    })
    builder.addCase(getOpenMarketOperationsTitle.fulfilled, (state, action) => {
      state.tableMappingLoading = false
      state.tableMapping = handleMappingData(action.payload || [])
      state.lineChartColor = initLineColor(
        state.checkboxChecked,
        state.tableMapping,
      )
    })
    builder.addCase(getOpenMarketOperationsTitle.rejected, (state, action) => {
      state.tableMappingLoading = action.payload.isLoading
    })
    builder.addCase(getMaxDateOMO.fulfilled, (state, action) => {
      state.maxDateOMO = action.payload
    })
  },
})

export const {
  handleFromDate,
  handleToDate,
  handleTimeRange,
  handleCheckboxChecked,
  handleGroupColumns,
  handleLineChartColor,
  handleCurrentPage,
  handleIsScrollRight,
  handlePreviousColumns,
  handleLoadingBlur,
  handleIsShowAlert,
} = slice.actions

export const getFromDate = (state) => state[slice.name].from
export const getToDate = (state) => state[slice.name].to
export const getTimeRange = (state) => state[slice.name].timeRange
export const getTableDataLoading = (state) => state[slice.name].tableDataLoading
export const getTableData = (state) => state[slice.name].tableData
export const getOpenMarketValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].tableData[id], attr)
export const getTableMapping = (state) => state[slice.name].tableMapping
export const getTableMappingLoading = (state) =>
  state[slice.name].tableMappingLoading
export const getCheckboxChecked = (state) => state[slice.name].checkboxChecked
export const getLineChartColor = (state) => state[slice.name].lineChartColor
export const getGroupColumns = (state) => state[slice.name].groupColumns
export const getCurrentPage = (state) => state[slice.name].currentPage
export const getIsScrollRight = (state) => state[slice.name].isScrollRight
export const getPreviousColumns = (state) => state[slice.name].previousColumns
export const getLoadingBlur = (state) => state[slice.name].loadingBlur
export const getIsShowAlert = (state) => state[slice.name].isShowAlert
export const selectMaxDateOMO = (state) => state[slice.name].maxDateOMO

register(slice.name, slice.reducer)
