import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { handleLineColor } from '../panelExchangeRateVolatility/helper'
import { ROW_ID_WITHOUT_CHILDREN } from './config'
import style from './index.module.css'
import {
  getCheckboxChecked,
  getIds,
  getIndexChecked,
  getLineChartColor,
  handleCheckboxChecked,
  handleIndexChecked,
  handleIsShowAlert,
  handleLineChartColor,
} from './store/slice'

const MAX_TICK = 20

const FirstCellColumn = ({
  val,
  props,
  rowId,
  level,
  onRowCollapse,
  rowCollapse,
}) => {
  const dispatch = useDispatch()

  const checkboxChecked = useSelector(getCheckboxChecked)
  const ids = useSelector(getIds)
  const lineChartColor = useSelector(getLineChartColor)
  const indexChecked = useSelector(getIndexChecked)

  const idChecked = checkboxChecked.map((item) => item.id)
  const [isCollapse, setIsCollapse] = useState(false)

  const handleCheckbox = (id) => {
    const isChecked = idChecked.includes(id)
    const newItemChecked = ids.find((item) => item.id === id)
    const currentIndex = ids.findIndex((item) => item.id === id)

    let newCheckboxChecked = checkboxChecked
    let newIndexChecked = indexChecked

    if (isChecked) {
      newCheckboxChecked = checkboxChecked.filter((item) => item.id !== id)
      newIndexChecked = indexChecked.filter((item) => item.id !== id)
    } else {
      if (checkboxChecked.length > MAX_TICK) {
        dispatch(handleIsShowAlert(true))
      } else {
        newCheckboxChecked = [...checkboxChecked, newItemChecked]
        newIndexChecked = [...indexChecked, currentIndex]
      }
    }

    dispatch(handleIndexChecked(newIndexChecked))
    dispatch(handleCheckboxChecked(newCheckboxChecked))
    dispatch(
      handleLineChartColor(handleLineColor(id, lineChartColor, isChecked)),
    )
  }

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  useEffect(() => {
    if (
      level === 1 &&
      !ROW_ID_WITHOUT_CHILDREN.includes(rowId) &&
      rowCollapse.includes(rowId)
    ) {
      setIsCollapse(true)
    }
  }, [])

  return (
    <td
      {...props}
      className={style.borderDefault}
      style={{ borderRight: '1px solid #546373' }}
    >
      <div
        className={style.wrapFirstCell}
        onClick={
          level === 1 && !ROW_ID_WITHOUT_CHILDREN.includes(rowId)
            ? onCollapse
            : () => handleCheckbox(rowId)
        }
      >
        {level === 1 && !ROW_ID_WITHOUT_CHILDREN.includes(rowId) ? (
          <button
            style={{ cursor: 'pointer', paddingLeft: 2, paddingRight: 10 }}
            className="align-center"
            onClick={onCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            />
          </button>
        ) : (
          <input
            type="checkbox"
            checked={idChecked.includes(rowId)}
            onChange={() => handleCheckbox(rowId)}
            className={`checkbox ${level === 1 ? 'mr-8' : style['mr-32']}`}
            style={{ cursor: 'pointer', minWidth: 12 }}
          />
        )}
        <TextEllipsisStaticLine val={val} isI18n={false} />
      </div>
    </td>
  )
}

FirstCellColumn.propType = {
  props: PropTypes.object,
  level: PropTypes.number,
  rowId: PropTypes.string.isRequired,
  val: PropTypes.string,
  onRowCollapse: PropTypes.func,
  rowCollapse: PropTypes.array,
}

export default FirstCellColumn
