import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import TextEllipsis from '../textEllipsis'

const CHECKBOX_MARGIN = 16
const CHECKBOX_WIDTH = 12

export const Checkbox = ({
  value,
  title,
  className,
  style,
  isI18n,
  isTextEllipsis,
  onChange,
}) => {
  const [checked, setChecked] = useState(false)

  const handleChange = () => {
    setChecked(!checked)
    onChange && onChange(!checked)
  }

  useEffect(() => {
    if (value !== checked) {
      setChecked(value)
    }
  }, [value])

  return (
    <div
      style={{ ...style }}
      className={`d-flex align-center cursor-pointer ${className}`}
      onClick={handleChange}
    >
      <input
        type="checkbox"
        className="checkbox"
        style={{
          backgroundColor: '#31363e',
          border: 'unset',
          width: 12,
          height: 12,
          marginRight: 8,
          minWidth: 12,
        }}
        checked={checked}
        readOnly={true}
      />
      <div
        style={{
          maxWidth: style?.width
            ? style.width - CHECKBOX_MARGIN - CHECKBOX_WIDTH
            : undefined,
        }}
      >
        {isTextEllipsis ? (
          <TextEllipsis isI18n={isI18n} text={title} />
        ) : isI18n ? (
          <Translate value={title} />
        ) : (
          title
        )}
      </div>
    </div>
  )
}
