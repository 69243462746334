import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SegmentalAnalysisService from '../../../../../../core/services/corporate/SegmentalAnalysisService'

export const chartBank1 = createAsyncThunk(
  'corporate/businessModel/segmentalAnalysis/charts/CHART_BANK_1',
  async (params, { rejectWithValue }) => {
    try {
      const res = await SegmentalAnalysisService.chartBank1(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const chartBank2 = createAsyncThunk(
  'corporate/businessModel/segmentalAnalysis/charts/CHART_BANK_2',
  async (params, { rejectWithValue }) => {
    try {
      const res = await SegmentalAnalysisService.chartBank2(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
