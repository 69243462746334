import PropTypes from 'prop-types'
import { formatVal } from '../../../../utils/Value'
import style from '../index.module.css'
import { BlockBarItem } from './BlockBarItem'
import { ChartFooter } from './ChartFooter'
import { MULTIPLIER_FORMAT_DATA } from './tablePriceDepth/constants'

export const ChartContent = ({
  totalBuyVolume,
  totalSellVolume,
  data,
  format,
  numberBar,
}) => {
  const getColor = (price) => {
    if (
      data.priceInfo &&
      price < data.priceInfo.referencePrice / format.bestBid
    ) {
      return '#bf5757'
    } else if (
      data.priceInfo &&
      price > data.priceInfo.referencePrice / format.bestBid
    ) {
      return '#3da967'
    } else {
      return '#e59e2e'
    }
  }

  const getBidOfferData = () => {
    return [...Array(numberBar)].map((_, index) => ({
      bestBid: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}Bid`]) / format.bestBid
        : 0,
      bestBidVolume: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}BidVolume`]) /
          format.bestBidVolume
        : 0,
      bestOffer: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}Offer`]) / format.bestOffer
        : 0,
      bestOfferVolume: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}OfferVolume`]) /
          format.bestOfferVolume
        : 0,
    }))
  }

  const getWidthPercent = (bestVolume, totalVolume) => {
    if (bestVolume === 0) {
      return 0
    } else {
      return (bestVolume / totalVolume) * 100 + '%'
    }
  }
  const NUMBER_BAR = 3

  const getTotalBuyVolume = () => {
    const bestBidVol = [...Array(NUMBER_BAR)].map(
      (_, index) => `best${index + 1}BidVolume`,
    )
    return (
      bestBidVol
        .map((key) => (data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0))
        .reduce((prev, curr) => prev + curr, 0) /
      MULTIPLIER_FORMAT_DATA.totalBuyVolume
    )
  }

  const getTotalSellVolume = () => {
    const bestAskVol = [...Array(NUMBER_BAR)].map(
      (_, index) => `best${index + 1}OfferVolume`,
    )
    return (
      bestAskVol
        .map((key) => (data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0))
        .reduce((prev, curr) => prev + curr, 0) /
      MULTIPLIER_FORMAT_DATA.totalSellVolume
    )
  }
  return (
    <div>
      <div>
        {getBidOfferData().map((item, index) => (
          <div key={index} className={`${style.rowBlockBarItem} d-flex`}>
            <div className={`w-50 ${style.firstBlockBarItem}`}>
              <BlockBarItem
                leftValue={formatVal(item.bestBidVolume)}
                rightValue={formatVal(item.bestBid)}
                color={getColor(item.bestBid)}
                widthColor={getWidthPercent(item.bestBidVolume, totalBuyVolume)}
                positionColor="right"
              />
            </div>
            <div className="w-50">
              <BlockBarItem
                leftValue={formatVal(item.bestOffer)}
                rightValue={formatVal(item.bestOfferVolume)}
                color={getColor(item.bestOffer)}
                widthColor={getWidthPercent(
                  item.bestOfferVolume,
                  totalSellVolume,
                )}
                positionColor="left"
              />
            </div>
          </div>
        ))}
      </div>
      <ChartFooter
        totalBuyVolume={formatVal(getTotalBuyVolume())}
        totalSellVolume={formatVal(getTotalSellVolume())}
        totalMatchVolume={
          data.priceInfo
            ? formatVal(
                data.priceInfo.totalMatchVolume /
                  MULTIPLIER_FORMAT_DATA.totalMatchVolume,
              )
            : '0.00'
        }
      />
    </div>
  )
}

ChartContent.propTypes = {
  totalBuyVolume: PropTypes.number.isRequired,
  totalSellVolume: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  format: PropTypes.object,
  numberBar: PropTypes.number,
}
