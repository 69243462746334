import { createSlice } from '@reduxjs/toolkit'
import { EXCHANGE_CODE_OTHER_HOHAUP } from '../../../constants/Group'
import { keyBy } from '../../../utils/Common'
import { register } from '../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../utils/Value'
import { sortIcbData } from '../useGetICB'
import {
  getAllFunctionsThunk,
  getAllFundsThunk,
  getBondThunk,
  getCoveredWarrantsThunk,
  getExchangeThunk,
  getExchangeThunkV2,
  getICBThunk,
  getIndicesThunk,
  getIndicesV2Thunk,
  getMaxMinTableThunk,
  getPackageFunctionsThunk,
  getStockThunk,
} from './thunk'

export const loadingKey = {
  EXCHANGE: 'exchange',
  EXCHANGEV2: 'exchangeV2',
  ICB: 'ICB',
  STOCK: 'stock',
  STOCK_HOHAUP: 'stockHohaup',
  INDICES: 'indices',
  INDICESV2: 'indicesV2',
  COVERED_WARRANT: 'coveredWarrant',
  BOND: 'bond',
  PACKAGE_FUNCTIONS: 'packageFunctions',
  FUNCTIONS: 'functions',
  MAX_MIN_TABLE: 'maxMinTable',
  FUND: 'fund',
}

const initialState = {
  loading: {
    exchange: false,
    exchangeV2: false,
    ICB: false,
    stock: false,
    indices: false,
    stockHohaup: 'false',
    coveredWarrant: false,
    packageFunctions: false,
    functions: false,
    maxMinTable: false,
    fund: false,
  },
  exchanges: [],
  exchangesV2: [],
  ICBs: [],
  icbInfoById: {},
  stocks: [],
  stocksHohaup: [],
  stockInfoById: {},
  indices: [],
  indexInfoById: {},
  coveredWarrants: [],
  bonds: [],
  bondInfoById: [],
  bondByOrganizationId: {},
  coveredWarrantsById: {},
  packageFunctions: [],
  functions: [],
  functionsIdUrl: {},
  maxMinTable: [],
  maxMinTableById: {},
  funds: [],
  fundById: {},
}

export const slice = createSlice({
  name: 'common/masterData',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // get exchange
    builder.addCase(getExchangeThunk.pending, (state) => {
      state.loading.exchange = true
    })
    builder.addCase(getExchangeThunk.fulfilled, (state, action) => {
      state.exchanges = action.payload || []
      state.loading.exchange = false
    })
    builder.addCase(getExchangeThunk.rejected, (state) => {
      state.loading.exchange = false
    })
    // get exchange v2
    builder.addCase(getExchangeThunkV2.pending, (state) => {
      state.loading.exchangeV2 = true
    })
    builder.addCase(getExchangeThunkV2.fulfilled, (state, action) => {
      state.exchangesV2 = action.payload || []
      state.loading.exchangeV2 = false
    })
    builder.addCase(getExchangeThunkV2.rejected, (state) => {
      state.loading.exchangeV2 = false
    })
    // get ICB
    builder.addCase(getICBThunk.pending, (state) => {
      state.loading.ICB = true
    })
    builder.addCase(getICBThunk.fulfilled, (state, action) => {
      const data = action.payload || []
      state.ICBs = sortIcbData(data)
      state.icbInfoById = keyBy(data, 'icbId')
      state.loading.ICB = false
    })
    builder.addCase(getICBThunk.rejected, (state) => {
      state.loading.ICB = false
    })
    // get Stock
    builder.addCase(getStockThunk.pending, (state) => {
      state.loading[loadingKey.STOCK] = true
      state.loading[loadingKey.STOCK_HOHAUP] = true
    })
    builder.addCase(getStockThunk.fulfilled, (state, action) => {
      const stocks = action.payload || []
      state.stocks = stocks
      state.stocksHohaup = stocks.filter(
        (stock) =>
          !EXCHANGE_CODE_OTHER_HOHAUP.includes(stock.exchangeCode) &&
          stock.exchangeCode,
      )
      state.stockInfoById = keyBy(stocks, 'organizationId')
      state.loading[loadingKey.STOCK] = false
      state.loading[loadingKey.STOCK_HOHAUP] = false
    })
    builder.addCase(getStockThunk.rejected, (state) => {
      state.loading[loadingKey.STOCK] = false
      state.loading[loadingKey.STOCK_HOHAUP] = false
    })

    // get Bonds

    builder.addCase(getBondThunk.pending, (state) => {
      state.loading[loadingKey.BOND] = true
    })
    builder.addCase(getBondThunk.fulfilled, (state, action) => {
      const bonds = action.payload || []
      state.bonds = bonds
      state.bondInfoById = keyBy(bonds, 'bondId')
      state.bondByOrganizationId = keyBy(bonds, 'organizationId')
      state.loading[loadingKey.BOND] = false
    })
    builder.addCase(getBondThunk.rejected, (state) => {
      state.loading[loadingKey.BOND] = false
    })

    // get Indices
    builder.addCase(getIndicesThunk.pending, (state) => {
      state.loading[loadingKey.INDICES] = true
    })
    builder.addCase(getIndicesThunk.fulfilled, (state, action) => {
      const indices = action.payload || []
      state.indices = indices
      state.indexInfoById = keyBy(indices, 'groupId')
      state.loading[loadingKey.INDICES] = false
    })
    builder.addCase(getIndicesThunk.rejected, (state) => {
      state.loading[loadingKey.INDICES] = false
    })

    // get Indices V2
    builder.addCase(getIndicesV2Thunk.pending, (state) => {
      state.loading[loadingKey.INDICESV2] = true
    })
    builder.addCase(getIndicesV2Thunk.fulfilled, (state, action) => {
      const indices = action.payload || []
      state.indices = indices
      state.indexInfoById = keyBy(indices, 'groupId')
      state.loading[loadingKey.INDICESV2] = false
    })
    builder.addCase(getIndicesV2Thunk.rejected, (state) => {
      state.loading[loadingKey.INDICESV2] = false
    })
    // get Coverwarrant
    builder.addCase(getCoveredWarrantsThunk.pending, (state) => {
      state.loading[loadingKey.COVERED_WARRANT] = true
    })
    builder.addCase(getCoveredWarrantsThunk.fulfilled, (state, action) => {
      const data =
        action.payload?.map((item) => ({
          coveredWarrantId: item.id,
          ...item,
        })) || []
      state.coveredWarrants = data
      state.coveredWarrantsById = keyBy(data, 'coveredWarrantId')
      state.loading[loadingKey.COVERED_WARRANT] = false
    })
    builder.addCase(getCoveredWarrantsThunk.rejected, (state) => {
      state.loading[loadingKey.COVERED_WARRANT] = false
    })
    // get package functions
    builder.addCase(getPackageFunctionsThunk.pending, (state) => {
      state.loading[loadingKey.PACKAGE_FUNCTIONS] = true
    })
    builder.addCase(getPackageFunctionsThunk.fulfilled, (state, action) => {
      state.packageFunctions = action.payload.filter(
        (url, index, array) =>
          array.findIndex((value) => value === url) === index,
      )
      state.loading[loadingKey.PACKAGE_FUNCTIONS] = false
    })
    builder.addCase(getPackageFunctionsThunk.rejected, (state) => {
      state.loading[loadingKey.PACKAGE_FUNCTIONS] = false
    })
    // get package functions
    builder.addCase(getAllFunctionsThunk.pending, (state) => {
      state.loading[loadingKey.FUNCTIONS] = true
    })
    builder.addCase(getAllFunctionsThunk.fulfilled, (state, action) => {
      state.functions = action.payload
      state.functionsIdUrl = action.payload.reduce(
        (obj, item) => ({
          ...obj,
          [item.functionId]: item.functionLink?.split(';')[0] || '',
        }),
        {},
      )
      state.loading[loadingKey.FUNCTIONS] = false
    })
    builder.addCase(getAllFunctionsThunk.rejected, (state) => {
      state.loading[loadingKey.FUNCTIONS] = false
    })
    // get max min table
    builder.addCase(getMaxMinTableThunk.pending, (state) => {
      state.loading[loadingKey.MAX_MIN_TABLE] = true
    })
    builder.addCase(getMaxMinTableThunk.fulfilled, (state, action) => {
      state.maxMinTable = action.payload
      state.maxMinTableById = keyBy(action.payload, 'updateTable')
      state.loading[loadingKey.MAX_MIN_TABLE] = false
    })
    builder.addCase(getMaxMinTableThunk.rejected, (state) => {
      state.loading[loadingKey.MAX_MIN_TABLE] = false
    })
    // get all funds
    builder.addCase(getAllFundsThunk.pending, (state) => {
      state.loading[loadingKey.FUND] = true
    })
    builder.addCase(getAllFundsThunk.fulfilled, (state, action) => {
      const data = action.payload.filter(
        (item, index, array) =>
          array.findIndex((el) => el.organizationId === item.organizationId) ===
          index,
      )
      state.funds = data.sort((a, b) => a.ticker.localeCompare(b.ticker))
      state.fundById = keyBy(data, 'organizationId')
      state.loading[loadingKey.FUND] = false
    })
    builder.addCase(getAllFundsThunk.rejected, (state) => {
      state.loading[loadingKey.FUND] = false
    })
  },
})

export const selectLocale = (state) => state.i18n.locale
export const selectLoading = (key) => (state) => state[slice.name].loading[key]
export const selectExchanges = (state) => state[slice.name].exchanges
export const selectExchangesV2 = (state) => state[slice.name].exchangesV2
export const selectICBs = (state) => state[slice.name].ICBs
export const selectIcbById = (state) => state[slice.name].icbInfoById
export const selectIcbInfoById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].icbInfoById[id], attr)

export const selectStocks = (state) => state[slice.name].stocks
export const selectStocksHohaup = (state) => state[slice.name].stocksHohaup
export const selectStockById = (state) => state[slice.name].stockInfoById
export const selectStockInfoById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].stockInfoById[id], attr)

export const selectBonds = (state) => state[slice.name].bonds
export const selectBondById = (state) => state[slice.name].bondInfoById
export const selectBondByOrganizationId = (state) =>
  state[slice.name].bondByOrganizationId
export const selectIndices = (state) => state[slice.name].indices
export const selectBondInfoById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].bondInfoById[id], attr)
export const selectIndexInfoById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].indexInfoById[id], attr)

export const selectCoveredWarrants = (state) =>
  state[slice.name].coveredWarrants
export const selectCoveredWarrantInfoById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].coveredWarrantsById[id], attr)
export const selectPackageFunctions = (state) =>
  state[slice.name].packageFunctions
export const selectFunctionsIdUrl = (state) => state[slice.name].functionsIdUrl

export const selectMaxMinTable = (state) => state[slice.name].maxMinTable
export const selectMaxMinTableById = (state) =>
  state[slice.name].maxMinTableById

export const selectFunds = (state) => state[slice.name].funds
export const selectFundById = (state) => state[slice.name].fundById

register(slice.name, slice.reducer)
