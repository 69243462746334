import PropTypes from 'prop-types'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'

const Title = ({ text, appendStyle }) => {
  return (
    <Span
      className="text-up t-center d-block"
      style={{
        fontWeight: 600,
        paddingBottom: 10,
        marginTop: 8,
        ...appendStyle,
      }}
    >
      <TextEllipsis
        isI18n
        text={`corporate.businessModel.costOfOperation.${text}`}
      />
    </Span>
  )
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
  appendStyle: PropTypes.object,
}

Title.defaultProps = {
  appendStyle: {},
}

export default Title
