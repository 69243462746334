import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { COMPONENT, COMPONENT_FILTER } from '../../../configs/Layout'
import { Panel } from '../../common/panel'
import PanelFilter from '../../common/panelFilter'
import { withWrapper } from '../../common/withWrapper'
import Condition from './condition'
import Filter from './filter'
import Result from './result'
import { changeKeyUniqueTable, selectTemplate } from './store/slice'

const MAP_KEY = {
  FILTER: 'FILTER',
  CONDITION: 'CONDITION',
  RESULT: 'RESULT',
}

const MAP_SIZE = {
  [`${MAP_KEY.FILTER}`]: {
    width: `calc(16% - ${2 * COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: COMPONENT_FILTER.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT_FILTER.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.CONDITION,
        ratioLeft: -1,
        ratioWidth: 1,
      },
      {
        key: MAP_KEY.RESULT,
        ratioLeft: -1,
        ratioWidth: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CONDITION}`]: {
    width: `calc(100% - 16%)`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(16%)`,
    minWidth: 520,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT_FILTER.MIN_SIZE.WIDTH + 2 * COMPONENT.MARGIN,
    horizontalResize: [
      {
        key: MAP_KEY.RESULT,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    isOpenCollapse: true,
    isMaxSizeCollapse: false,
    groupCollapse: [MAP_KEY.CONDITION, MAP_KEY.RESULT],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.RESULT}`]: {
    width: `calc(100% - 16%)`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: 'calc(50%)',
    left: `calc(16%)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT_FILTER.MIN_SIZE.WIDTH + 2 * COMPONENT.MARGIN,
    isOpenCollapse: true,
    isMaxSizeCollapse: false,
    groupCollapse: [MAP_KEY.CONDITION, MAP_KEY.RESULT],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const PanelCondition = ({ sizes, setSizes }) => {
  const templateName = useSelector(selectTemplate)?.templateName

  return (
    <Panel
      title={'tool.smartScreening.TITLE_CONDITION'}
      panelRefs={panelRefs}
      panelKey={MAP_KEY.CONDITION}
      sizes={sizes}
      setSizes={setSizes}
      isCollapse={true}
      renderCustomTitle={(title) => {
        return (
          <>
            <Translate value={title} />
            {templateName && ` - ${templateName}`}
          </>
        )
      }}
    >
      <Condition sizes={sizes} />
    </Panel>
  )
}

const Content = ({ sizes, setSizes }) => {
  const dispatch = useDispatch()

  const [isLoadingDownloadCsv, setIsLoadingDownloadCsv] = useState(false)

  useEffect(() => {
    if (sizes[MAP_KEY.RESULT].isOpenCollapse) {
      dispatch(changeKeyUniqueTable())
    }
  }, [sizes[MAP_KEY.RESULT].isOpenCollapse])

  return (
    <div className="w-100 h-100 position-relative">
      <PanelFilter
        title={'tool.smartScreening.TITLE_FILTER'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter />
      </PanelFilter>
      <PanelCondition sizes={sizes} setSizes={setSizes} />
      <Panel
        title={'tool.smartScreening.TITLE_RESULT'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.RESULT}
        sizes={sizes}
        setSizes={setSizes}
        isCollapse={true}
        isLoadingDownloadCsv={isLoadingDownloadCsv}
      >
        <Result setIsLoadingDownloadCsv={setIsLoadingDownloadCsv} />
      </Panel>
    </div>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
