import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MarginLoansService from '../../../../../core/services/market/MarginLoansService'

export const getMarginLeverageRatio = createAsyncThunk(
  'market/marginLoans/leverageRatio',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MarginLoansService.getMarginLeverageRatio(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
