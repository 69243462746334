import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { TIME_SELECT, VALUE_TYPE } from '../constants'
import { changeDateTime, changeValueType } from './store/slice'

export const ChartFilter = ({ isIpadScreen, valueType, dateType }) => {
  const dispatch = useDispatch()

  // Actions
  const changeStateDateTime = (data) => {
    dispatch(changeDateTime(data.value))
  }

  const changeStateValueType = (data) => {
    dispatch(changeValueType(data.value))
  }

  return (
    <div className={isIpadScreen ? 'mb-8' : 'table-info'}>
      <div className={`table-info-left ${isIpadScreen && 'mb-8'}`}>
        <DispatchActionTab
          data={Object.keys(VALUE_TYPE).map((key) => ({
            title: `market.equityTrading.priceStatistics.valueInvestor.${VALUE_TYPE[key]}`,
            value: VALUE_TYPE[key],
          }))}
          itemStyle={{ padding: '0 12px', fontWeight: 600 }}
          activeTab={valueType}
          onChangeTab={changeStateValueType}
        />
      </div>
      <div className="table-info-right">
        <DispatchActionTab
          data={Object.keys(TIME_SELECT).map((key) => {
            return {
              title: `market.equityTrading.priceStatistics.${key}`,
              value: TIME_SELECT[key],
            }
          })}
          itemStyle={{ padding: '0 12px', fontWeight: 600 }}
          activeTab={dateType}
          onChangeTab={changeStateDateTime}
        />
      </div>
    </div>
  )
}

ChartFilter.propTypes = {
  valueType: PropTypes.string.isRequired,
  dateType: PropTypes.string.isRequired,
  isIpadScreen: PropTypes.bool,
}
