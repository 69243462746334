import { FORMAT, formatDateTimeLocal } from './Datetime'

export const handleExportSaveAs = async (data, filename) => {
  if (data && filename) {
    if (window.showSaveFilePicker) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const handle = await window.showSaveFilePicker({
        suggestedName: filename,
        types: [
          {
            accept: {
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
            },
          },
        ],
      })
      const writable = await handle.createWritable()
      await writable.write(blob)
      writable.close()
    } else {
      handleExport(data, filename)
    }
  }
}

export const handleExport = (data, filename) => {
  if (data && filename) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const downloadUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    if (typeof a.download === 'undefined') {
      window.location.href = downloadUrl
    } else {
      a.href = downloadUrl
      a.download = filename
      a.setAttribute('download', filename)
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }
}

const MAX_LENGTH_TICKER_NAME = 15
export const handleNameFileDownload = (
  nameScreen,
  chartName,
  tabName,
  tickerName,
) => {
  const result = ['FiinProX']
  if (tickerName?.length > MAX_LENGTH_TICKER_NAME) {
    tickerName = tickerName.slice(0, MAX_LENGTH_TICKER_NAME).concat('...')
  }

  const arrElementNameFile = [nameScreen, chartName, tabName, tickerName]
  arrElementNameFile.forEach((item) => {
    if (item) {
      result.push(item)
    }
  })

  result.push(formatDateTimeLocal(new Date(), FORMAT.FILE))

  return result.join('_')
}

export const handleNameFileDownloadApi = (contentDisposition) => {
  let filename = ''
  if (contentDisposition) {
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = regex.exec(contentDisposition)
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }
  return filename
}
