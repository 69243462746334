import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useGetMaxMinTable from '../../../../../../common/masterData/useGetMaxMinTable'
import DispatchActionTab from '../../../../../../common/tabs/DispatchActionTab'
import { getListTableTab } from '../helper'
import { changeFilter, selectFilter } from '../store/slice'

const TableTab = ({ width }) => {
  const dispatch = useDispatch()
  const { maxMinTableById } = useGetMaxMinTable()
  const commonTopIssuer = maxMinTableById['FGFB_CORA_DE_FinancialRatioTTM']

  const filter = useSelector(selectFilter)

  const handleChangeTab = (tab) => {
    dispatch(
      changeFilter({
        quarter: tab.value,
      }),
    )
  }

  useEffect(() => {
    handleChangeTab({ value: commonTopIssuer?.max_LengthReport || 4 })
  }, [])

  return (
    <div>
      <DispatchActionTab
        data={getListTableTab()}
        activeTab={filter.quarter}
        onChangeTab={handleChangeTab}
        itemStyle={{
          fontWeight: 600,
          fontSize: 12,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        // widthComponent={width}
      />
    </div>
  )
}

export default TableTab
