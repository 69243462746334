import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { selectFilterRemainingMaturities } from '../store/slice'
import CouponInterestRateChart from './CouponInterestRateChart'
import Filter from './Filter'
import RemainingMaturitiesChart from './RemainingMaturitiesChart'

const RemainingMaturities = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const { activeTab } = useSelector(selectFilterRemainingMaturities)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const renderChartName = () =>
    I18n.t(
      activeTab === 1
        ? 'bond.corporateBond.remainingMaturities.REMAINING_MATURITIES'
        : 'bond.corporateBond.remainingMaturities.COUPON_INTEREST_RATE',
    )

  return (
    <Panel
      title="bond.corporateBond.remainingMaturities.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={''}
      downloadJpgParams={{
        domId: 'remainingMaturitiesOrCouponInterestRateChartId',
        nameScreen: I18n.t('bond.corporateBond.issuers.TITLE'),
        chartName: renderChartName(),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {activeTab === 1 && (
              <RemainingMaturitiesChart
                sizeHeight={size.height}
                width={width}
                height={height}
              />
            )}
            {activeTab === 2 && (
              <CouponInterestRateChart
                sizeHeight={size.height}
                width={width}
                height={height}
              />
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default RemainingMaturities
