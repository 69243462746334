import { defaultLanguage } from '../../configs/Language'
import { LOCAL_STORAGE_KEY } from '../constants/Common'

export const getLanguage = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.KEY_LANGUAGE) || defaultLanguage
}

export const saveLanguage = (lang) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.KEY_LANGUAGE, lang)
}
