import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class FundStatisticProxy extends ProxyBase {
  getFundManagementCompany(params) {
    return this.get('GetFundManagementCompanys', params)
  }

  getKeyMetric(params) {
    return this.post('GetKeyMetric', params)
  }

  downloadKeyMetric(params) {
    return this.post('KeyMetricExport', params, {}, 'download')
  }

  getPerformance(params) {
    return this.post('GetPeformance', params)
  }

  downloadPerformance(params) {
    return this.post('PeformanceExport', params, {}, 'download')
  }

  getStatisticsFundFlow(params) {
    return this.post('GetStatisticsFundflow', params)
  }

  downloadStatisticsFundFlow(params) {
    return this.post('StatisticsFundflowExport', params, {}, 'download')
  }

  getSectorAllocation(params) {
    return this.post('GetSectorAllocation', params)
  }

  downloadSectorAllocation(params) {
    return this.post('SectorAllocationExport', params, {}, 'download')
  }

  getAssetAllocation(params) {
    return this.post('GetAssetAlocation', params)
  }

  downloadAssetAllocation(params) {
    return this.post('AssetAlocationExport', params, {}, 'download')
  }

  getFundIndex(params) {
    return this.post('GetFundIndex', params)
  }

  downloadFundIndex(params) {
    return this.post('FundIndexExport', params, {}, 'download')
  }
}

export default new FundStatisticProxy(
  ServiceProxyConfig.Fund.FundCenter.FundStatistic.ServiceUrl,
)
