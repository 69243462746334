import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { VIEW_TYPE } from '../../constants'
import {
  getFormatDataQueryBySecurities,
  getFormatDataQueryByTime,
} from '../../helpers/dashboardApiParamsAndResponseHelpers'
import { changeChartData } from '../store/slice'
import { DataByEquations } from './DataByEquations'
import { DataByLatestPeriod } from './DataByLatestPeriod'
import { DataBySecurities } from './DataBySecurities'

const withWrapperDataChart =
  (Component) =>
  ({ item, isHaveDataChartPreview, ...props }) => {
    const dispatch = useDispatch()

    const [securitiesLoading, setSecuritiesLoading] = useState(false)
    const [equationLoading, setEquationLoading] = useState(false)
    const [loadingIndicatorLatestPeriod, setLoadingIndicatorLatestPeriod] =
      useState(true)
    const [isFirstTime, setIsFirstTime] = useState(true)

    const isLoading = useMemo(() => {
      return securitiesLoading || equationLoading
    }, [securitiesLoading, equationLoading])

    const itemParse = useMemo(() => {
      return { ...item, detail: JSON.parse(item.detail) }
    }, [item])

    const handleFormatData = (data, isEquation) => {
      let result = []

      if (isEquation) {
        result = data
      } else {
        if (itemParse.detail.viewType === VIEW_TYPE.BY_SECURITIES) {
          result = getFormatDataQueryBySecurities(data)
        } else {
          result = getFormatDataQueryByTime(data)
        }
      }

      dispatch(
        changeChartData({
          id: itemParse.id,
          data: result,
          isEquation,
          viewType: itemParse.detail.viewType,
        }),
      )
    }

    const handleChangeLoading = (type, value) => {
      switch (type) {
        case 'equation':
          setEquationLoading(value)
          break
        default:
          setSecuritiesLoading(value)
          break
      }
    }

    useEffect(() => {
      if (isLoading) {
        setIsFirstTime(false)
      }
    }, [isLoading])

    const renderComponent = useMemo(() => {
      return (
        <Component
          item={itemParse}
          loading={isLoading || isFirstTime}
          isHaveDataChartPreview={isHaveDataChartPreview}
          {...props}
        />
      )
    }, [
      itemParse,
      isLoading || isFirstTime,
      isHaveDataChartPreview,
      JSON.stringify(props),
    ])

    return (
      <>
        {renderComponent}
        {!loadingIndicatorLatestPeriod && (
          <>
            <DataBySecurities
              data={itemParse}
              isHaveDataChartPreview={isHaveDataChartPreview}
              loading={securitiesLoading}
              loadingIndicatorLatestPeriod={loadingIndicatorLatestPeriod}
              onFormatData={handleFormatData}
              setLoading={handleChangeLoading}
            />
            <DataByEquations
              data={itemParse}
              isHaveDataChartPreview={isHaveDataChartPreview}
              loading={{ equation: equationLoading }}
              loadingIndicatorLatestPeriod={loadingIndicatorLatestPeriod}
              onFormatData={handleFormatData}
              setLoading={handleChangeLoading}
            />
          </>
        )}
        <DataByLatestPeriod
          data={itemParse}
          setLoadingIndicatorLatestPeriod={setLoadingIndicatorLatestPeriod}
        />
      </>
    )
  }

export default withWrapperDataChart
