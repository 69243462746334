import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import TextEllipsis from '../../../../common/textEllipsis'
import { formatVal, valDivBillion, valToPercent } from '../../../../utils/Value'
import TableHeader from './TableHeader'
import { Columns, ROW_KEY } from './helper'
import style from './index.module.css'
import {
  selectDataCompany,
  selectIdsCompany,
  selectListIdSelect,
  selectLoadingTableCompany,
  sortData,
  updateListId,
} from './store/slice'

const TableCompany = ({ width, height }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const dispatch = useDispatch()
  const ids = useSelector(selectIdsCompany)
  const isLoading = useSelector(selectLoadingTableCompany)
  const idsSelect = useSelector(selectListIdSelect)

  if (isLoading) {
    return <Loading />
  }

  if (ids.length === 0) {
    return <NoData />
  }

  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }

  const clickCheckboxRow = (id) => {
    if (idsSelect.includes(id)) {
      dispatch(updateListId([...idsSelect].filter((e) => e !== id)))
    } else {
      dispatch(updateListId([...idsSelect, id]))
    }
  }
  return (
    <Table
      ids={ids}
      columnDraggable={false}
      getDataFromRedux={selectDataCompany}
      isLoading={isLoading}
      sort={sortData}
      handleSortField={(e) =>
        dispatch(
          sortData({
            ...e,
            locale,
          }),
        )
      }
      renderHeader={(stateSort, sortColumn) =>
        renderThead(Columns, stateSort, sortColumn)
      }
      schema={Columns.map((item, index) => {
        const title = I18n.t(item.title)
        const colId = item.key
        const result = {
          colId,
          title,
        }
        return {
          ...result,
          canCustomTd: true,
          render: (value, rowId, props) => {
            if (colId === 'name') {
              return (
                <td style={{ ...item?.attrs?.style }}>
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <input
                        type="checkbox"
                        className={`checkbox ${style.checkbox}`}
                        checked={idsSelect.includes(rowId)}
                        onClick={() => clickCheckboxRow(rowId)}
                      />
                    </div>
                    <div
                      style={{
                        paddingLeft: '10px',
                        maxWidth: 110,
                      }}
                    >
                      <Span style={{ ...item?.attrs?.style }}>
                        <TextEllipsis text={value} isI18n={false} />
                      </Span>
                    </div>
                  </div>
                </td>
              )
            }
            if (colId === ROW_KEY.PERCENTAGE) {
              return (
                <td style={{ ...item?.attrs?.style }}>
                  <Span style={{ ...item?.attrs?.style }}>
                    {valToPercent(value)}
                  </Span>
                </td>
              )
            }
            return (
              <td style={{ ...item?.attrs?.style }}>
                <Span style={{ ...item?.attrs?.style }}>
                  {formatVal(valDivBillion(value))}
                </Span>
              </td>
            )
          },
        }
      })}
      stickyFirstColumn={false}
      isPagination={false}
      hasFooter={false}
      resizable={false}
    />
  )
}

export default TableCompany
