import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { PANEL_PADDING } from '../../../constants/Common'
import ChartDataPanel from './chartData/ChartDataPanel'
import FDIIndustryPanel from './fdiIndustry/FDIIndustryPanel'
import FDIStructurePanel from './fdiStructure/FDIStructurePanel'
import KeyIndicatorPanel from './keyIndicator/KeyIndicator'
import KeyIndicatorCountryPanel from './keyIndicatorCountry/KeyIndicatorCountryPanel'
import KeyIndicatorProvincePanel from './keyIndicatorProvince/KeyIndicatorProvincePanel'
import TableDataPanel from './tableData/TableDataPanel'

const HEIGHT_HEADER_WRAPPER = 60

const MAP_KEY = {
  TABLE_DATA: 'TABLE_DATA',
  CHART_DATA: 'CHART_DATA',
  KEY_INDICATOR: 'KEY_INDICATOR',
  KEY_INDICATOR_PROVINCE: 'KEY_INDICATOR_PROVINCE',
  KEY_INDICATOR_COUNTRY: 'KEY_INDICATOR_COUNTRY',
  FDI_STRUCTURE: 'FDI_STRUCTURE',
  FDI_INDUSTRY: 'FDI_INDUSTRY',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.TABLE_DATA]: {
    minWidth: 364,
    minHeight: 346,
  },
  [MAP_KEY.CHART_DATA]: {
    minWidth: 364,
    minHeight: 342,
  },
  [MAP_KEY.KEY_INDICATOR]: {
    minWidth: 731,
    minHeight: 692,
  },
  [MAP_KEY.FDI_STRUCTURE]: {
    minWidth: 362,
    minHeight: 286,
  },
  [MAP_KEY.KEY_INDICATOR_PROVINCE]: {
    minWidth: 348,
    minHeight: 286,
  },
  [MAP_KEY.FDI_INDUSTRY]: {
    minWidth: 362,
    minHeight: 342,
  },
  [MAP_KEY.KEY_INDICATOR_COUNTRY]: {
    minWidth: 348,
    minHeight: 342,
  },
}

const MAP_SIZE = {
  [MAP_KEY.TABLE_DATA]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: 0,
    minTop: 0,
    left: 0,
    minLeft: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.TABLE_DATA],
    disableVerticalResize: false,
    disableHorizontalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.CHART_DATA,
        isResizeWithParent: true,
        ratioWidth: -1,
        ratioLeft: 0,
      },
      {
        key: MAP_KEY.KEY_INDICATOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.KEY_INDICATOR_PROVINCE,
        ratioWidth: 0.5,
        ratioLeft: 0.5,
      },
      {
        key: MAP_KEY.KEY_INDICATOR_COUNTRY,
        ratioWidth: 0.5,
        ratioLeft: 0.5,
      },
      {
        key: MAP_KEY.FDI_STRUCTURE,
        ratioWidth: 0.5,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.FDI_INDUSTRY,
        ratioWidth: 0.5,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.CHART_DATA,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [MAP_KEY.CHART_DATA]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${4 * COMPONENT.MARGIN}px)`,
    top: `calc(50%)`,
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.TABLE_DATA].minHeight + 2 * COMPONENT.MARGIN,
    left: 0,
    minLeft: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.CHART_DATA],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.KEY_INDICATOR]: {
    width: `calc(200% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(100% - ${4 * COMPONENT.MARGIN}px)`,
    top: 0,
    minTop: 0,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.TABLE_DATA].minWidth + 2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.KEY_INDICATOR],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.KEY_INDICATOR_PROVINCE]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN + PANEL_PADDING}px)`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN + HEIGHT_HEADER_WRAPPER}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    minTop: HEIGHT_HEADER_WRAPPER,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN + PANEL_PADDING / 2}px)`,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.TABLE_DATA].minWidth +
      MIN_SIZE_SETTING[MAP_KEY.FDI_STRUCTURE].minWidth +
      PANEL_PADDING,
    ...MIN_SIZE_SETTING[MAP_KEY.KEY_INDICATOR_PROVINCE],
    disableVerticalResize: true,
    disableHorizontalResize: false,
    horizontalResize: [
      {
        key: MAP_KEY.KEY_INDICATOR_COUNTRY,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [MAP_KEY.FDI_STRUCTURE]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN + HEIGHT_HEADER_WRAPPER}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    minTop: HEIGHT_HEADER_WRAPPER,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN + PANEL_PADDING / 2}px)`,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.TABLE_DATA].minWidth +
      2 * COMPONENT.MARGIN +
      PANEL_PADDING / 2,
    ...MIN_SIZE_SETTING[MAP_KEY.FDI_STRUCTURE],
    disableVerticalResize: false,
    disableHorizontalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.FDI_INDUSTRY,
        isResizeWithParent: true,
        ratioWidth: -1,
        ratioLeft: 0,
      },
      {
        key: MAP_KEY.KEY_INDICATOR_PROVINCE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.KEY_INDICATOR_COUNTRY,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.FDI_INDUSTRY,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [MAP_KEY.KEY_INDICATOR_COUNTRY]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN + PANEL_PADDING}px)`,
    height: `calc(50% - ${4 * COMPONENT.MARGIN}px)`,
    top: `calc(50%)`,
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.FDI_STRUCTURE].minHeight +
      HEIGHT_HEADER_WRAPPER +
      2 * COMPONENT.MARGIN,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN + PANEL_PADDING / 2}px)`,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.CHART_DATA].minWidth +
      MIN_SIZE_SETTING[MAP_KEY.FDI_INDUSTRY].minWidth +
      PANEL_PADDING,
    ...MIN_SIZE_SETTING[MAP_KEY.KEY_INDICATOR_COUNTRY],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.FDI_INDUSTRY]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${4 * COMPONENT.MARGIN}px)`,
    top: `calc(50%)`,
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.FDI_STRUCTURE].minHeight +
      HEIGHT_HEADER_WRAPPER +
      2 * COMPONENT.MARGIN,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN + PANEL_PADDING / 2}px)`,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.CHART_DATA].minWidth +
      2 * COMPONENT.MARGIN +
      PANEL_PADDING / 2,
    ...MIN_SIZE_SETTING[MAP_KEY.FDI_INDUSTRY],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <TableDataPanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TABLE_DATA}
        sizes={sizes}
        setSizes={setSizes}
      />
      <ChartDataPanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CHART_DATA}
        sizes={sizes}
        setSizes={setSizes}
      />
      <KeyIndicatorPanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.KEY_INDICATOR}
        sizes={sizes}
        setSizes={setSizes}
      />
      <KeyIndicatorProvincePanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.KEY_INDICATOR_COUNTRY}
        sizes={sizes}
        setSizes={setSizes}
      />
      <KeyIndicatorCountryPanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.KEY_INDICATOR_PROVINCE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <FDIStructurePanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FDI_STRUCTURE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <FDIIndustryPanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FDI_INDUSTRY}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
