import SecondaryOverviewProxy from '../../../../proxies/bond/corporateBond/secondaryMarket/SecondaryOverviewProxy'
import { ServiceBase } from '../../../ServiceBase'

export class SecondaryOverviewService extends ServiceBase {
  getTopLiquidityDataByIssuer(params) {
    return this.getData(() =>
      SecondaryOverviewProxy.getTopLiquidityDataByIssuer(params),
    )
  }
  getTopLiquidityDataByBond(params) {
    return this.getData(() =>
      SecondaryOverviewProxy.getTopLiquidityDataByBond(params),
    )
  }
  getAverageYTMofIssuer(params) {
    return this.getData(() =>
      SecondaryOverviewProxy.getAverageYTMofIssuer(params),
    )
  }
  getTopInterestRate(params) {
    return this.getData(() => SecondaryOverviewProxy.getTopInterestRate(params))
  }
  getTopInterestRateAsk(params) {
    return this.getData(() =>
      SecondaryOverviewProxy.getTopInterestRateAsk(params),
    )
  }
  getMarketLiquidityChart(params) {
    return this.getData(() =>
      SecondaryOverviewProxy.getMarketLiquidityChart(params),
    )
  }

  downloadTopLiquidityByIssuer(params) {
    return this.getFileDownload(() =>
      SecondaryOverviewProxy.downloadTopLiquidityByIssuer(params),
    )
  }
  downloadTopLiquidityByBond(params) {
    return this.getFileDownload(() =>
      SecondaryOverviewProxy.downloadTopLiquidityByBond(params),
    )
  }
  downloadAverageYTMofIssuer(params) {
    return this.getFileDownload(() =>
      SecondaryOverviewProxy.downloadAverageYTMofIssuer(params),
    )
  }
  downloadTopInterestRate(params) {
    return this.getFileDownload(() =>
      SecondaryOverviewProxy.downloadTopInterestRate(params),
    )
  }
}

export default new SecondaryOverviewService()
