import PropTypes from 'prop-types'
import { useState } from 'react'
import { RootButton } from './RootButton'
import style from './RootButtonWithCollapse.module.css'

export const RootButtonWithCollapse = ({
  data,
  children,
  renderIcon,
  redirect,
}) => {
  const [collapseTemplateOpen, setCollapseTemplateOpen] = useState(true)

  return (
    <>
      <div onClick={() => setCollapseTemplateOpen(!collapseTemplateOpen)}>
        <RootButton
          title={data.name}
          renderIcon={() => {
            return collapseTemplateOpen ? (
              <i className={`icon-caret-down ${style.iconArrow}`} />
            ) : (
              <i className={`icon-caret-right ${style.iconArrow}`} />
            )
          }}
          redirect={redirect}
        />
      </div>
      {collapseTemplateOpen && data.children && children}
    </>
  )
}

RootButtonWithCollapse.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  renderIcon: PropTypes.func,
  redirect: PropTypes.func,
}

RootButtonWithCollapse.defaultProps = {
  renderIcon: () => {},
  redirect: () => {},
}
