import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { DEFAULT_CHECKED } from '../config'
import {
  defineGroupColumns,
  findMaxQuarter,
  findMinQuarter,
  handleIds,
  handleLevels,
  handleTitleData,
  initChecked,
  initLineColor,
  keyById,
} from '../helper'
import { NUMBER_OF_PERIOD_FIRST_TIME } from '../tableGeneralIndex'
import {
  getGeneralProductionIndex,
  getGeneralProductionIndexName,
} from './thunk'

const slice = createSlice({
  name: 'economy/productionAndConsumption/production/table/generalIndex',
  initialState: {
    tableDataLoading: true,
    titleNameLoading: true,
    tableData: {},
    titleData: [],
    titleWithKey: [],
    initId: [],
    ids: [],
    groupColumns: [],
    isFirstTime: true,
    isAllowCallApi: true,
    checkboxChecked: [],
    lineChartColor: [],
    isShowAlert: false,
    levels: [],
    radioValuePPI: 'PrePeriod',
    minQuarterYear: { minYear: 0, minQuarter: 0 },
    maxQuarterYear: { maxYear: 0, maxQuarter: 0 },
    indexChecked: DEFAULT_CHECKED,
  },
  reducers: {
    handleIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload
    },
    handleIsAllowCallApi: (state, action) => {
      state.isAllowCallApi = action.payload
    },
    handleCheckboxChecked: (state, action) => {
      state.checkboxChecked = action.payload
    },
    handleIsShowAlert: (state, action) => {
      state.isShowAlert = action.payload
    },
    handleLineChartColor: (state, action) => {
      state.lineChartColor = action.payload
    },
    handleRadioValuePPI: (state, action) => {
      state.radioValuePPI = action.payload
    },
    handleIndexChecked: (state, action) => {
      state.indexChecked = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.tableData,
        action.payload,
        state.initId,
        0,
        state.levels,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGeneralProductionIndexName.pending, (state) => {
      state.titleNameLoading = true
    })
    builder.addCase(
      getGeneralProductionIndexName.fulfilled,
      (state, action) => {
        const data = action.payload || []
        state.titleNameLoading = false
        state.ids = state.initId = handleIds(data)
        state.titleWithKey = handleTitleData(data)
        state.checkboxChecked = initChecked(data)
        state.lineChartColor = initLineColor(
          state.checkboxChecked,
          state.titleWithKey,
        )
        state.levels = handleLevels(data)
        state.titleData = data
      },
    )
    builder.addCase(getGeneralProductionIndexName.rejected, (state, action) => {
      state.titleNameLoading = action.payload.isLoading
    })
    builder.addCase(getGeneralProductionIndex.pending, (state) => {
      state.tableDataLoading = true
    })
    builder.addCase(getGeneralProductionIndex.fulfilled, (state, action) => {
      const data = action.payload || []
      state.tableDataLoading = false
      state.groupColumns = defineGroupColumns(
        data,
        action.meta.arg,
        state.groupColumns,
      )
      state.tableData = keyById(data, action.meta.arg, state.tableData)
      state.minQuarterYear = findMinQuarter(
        action.payload,
        action.meta.arg.DataType,
      )
      if (action.meta.arg.Limit === NUMBER_OF_PERIOD_FIRST_TIME) {
        state.maxQuarterYear = findMaxQuarter(
          action.payload,
          action.meta.arg.DataType,
        )
      }
      if (!data.length) {
        state.isAllowCallApi = false
      }
    })
    builder.addCase(getGeneralProductionIndex.rejected, (state, action) => {
      state.tableDataLoading = action.payload.isLoading
    })
  },
})

export const getTableDataLoading = (state) => state[slice.name].tableDataLoading
export const getTitleNameLoading = (state) => state[slice.name].titleNameLoading
export const getTableData = (state) => state[slice.name].tableData
export const getTitleData = (state) => state[slice.name].titleData
export const getIds = (state) => state[slice.name].ids
export const getGroupColumns = (state) => state[slice.name].groupColumns
export const getIsFirstTime = (state) => state[slice.name].isFirstTime
export const getTitleWithKey = (state) => state[slice.name].titleWithKey
export const getLineChartColor = (state) => state[slice.name].lineChartColor
export const getIsShowAlert = (state) => state[slice.name].isShowAlert
export const getCheckboxChecked = (state) => state[slice.name].checkboxChecked
export const getRadioValuePPI = (state) => state[slice.name].radioValuePPI
export const selectCellValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].tableData[id], attr)
}
export const getIsAllowCallApi = (state) => state[slice.name].isAllowCallApi
export const getMinQuarterYear = (state) => state[slice.name].minQuarterYear
export const getMaxQuarterYear = (state) => state[slice.name].maxQuarterYear

export const {
  handleIsFirstTime,
  handleIsAllowCallApi,
  handleCheckboxChecked,
  handleIsShowAlert,
  handleLineChartColor,
  handleRadioValuePPI,
  sort,
} = slice.actions

register(slice.name, slice.reducer)
