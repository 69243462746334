import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../common/selectCustom'
import { blankValue } from '../constant'
import style from '../index.module.css'
import { changeCondition, selectItemCondition } from '../store/slice'

const selectData = [
  { value: blankValue, name: 'tool.smartScreening.BLANK' },
  { value: '>', name: '>' },
  { value: '<', name: '<' },
  { value: '>=', name: '>=' },
  { value: '<=', name: '<=' },
  { value: '=', name: '=' },
]

const Operator = ({ indicatorId, index }) => {
  const dispatch = useDispatch()

  const keyOperator = `${indicatorId}.${index}.additionalCondition.operator`

  const operator = useSelector(selectItemCondition(keyOperator))

  const handleChangeOperator = (value) => {
    dispatch(
      changeCondition({
        key: keyOperator,
        value,
      }),
    )
  }

  return (
    <div className={`input-dropdown ${style.w35}`}>
      <SelectCustom
        value={operator}
        selectData={selectData}
        isI18n
        handleChange={handleChangeOperator}
      />
    </div>
  )
}

Operator.propTypes = {
  indicatorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  index: PropTypes.number.isRequired,
}

export default Operator
