export const LINE_CHART_COLORS = [
  '#fff6df',
  '#40abff',
  '#facc5c',
  '#45b29d',
  '#e61a00',
  '#ff2500',
  '#744d23',
  '#f9c105',
  '#85d4ff',
  '#56c10a',
  '#009fe6',
  '#9d9e9e',
  '#f4327b',
  '#930037',
  '#57426d',
  '#4bd9be',
  '#c52e14',
  '#00763e',
  '#975000',
  '#1a36a9',
]
