import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valueLevel } from '../../../result/Filter/constants'
import {
  ValueFurtherInformation,
  ValuePosition,
  ValueRelations,
  ValueTypeShareHolders,
} from '../constants'
import { getDataThunk } from './thunk'

const name = 'bond/interConnectionMap/filter'

const initialState = {
  data: {},
  loading: false,
  FromPercentage: 5,
  ToPercentage: 100,
  filterLevel: valueLevel.Level2,
  limitNode: 10,
  valueShareHolders: [
    ValueTypeShareHolders.IndividualOwner,
    ValueTypeShareHolders.OrganizationOwner,
  ],
  valuePosition: [ValuePosition.Chairman, ValuePosition.CEO],
  valueRelation: [
    ValueRelations.PaymentGuarantee,
    ValueRelations.SubsidiaryAffiliate,
  ],
  valueFurtherInformations: [
    ValueFurtherInformation.OutstandingBond,
    ValueFurtherInformation.OwnershipRate,
    ValueFurtherInformation.PaymentGuarantee,
  ],
}

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetFilter: (state) => {
      state.valueShareHolders = [
        ValueTypeShareHolders.IndividualOwner,
        ValueTypeShareHolders.OrganizationOwner,
      ]
      state.valuePosition = [ValuePosition.Chairman, ValuePosition.CEO]
      state.valueRelation = [
        ValueRelations.PaymentGuarantee,
        ValueRelations.SubsidiaryAffiliate,
      ]
      state.valueFurtherInformations = [
        ValueFurtherInformation.OutstandingBond,
        ValueFurtherInformation.OwnershipRate,
        ValueFurtherInformation.PaymentGuarantee,
      ]
      state.filterLevel = valueLevel.Level3
    },
    updatefilterLevel: (state, action) => {
      state.filterLevel = action.payload
    },
    updateShareHolders: (state, action) => {
      state.valueShareHolders = action.payload
    },
    updatePosition: (state, action) => {
      state.valuePosition = action.payload
    },
    updateRelation: (state, action) => {
      state.valueRelation = action.payload
    },
    updateFurtherInformation: (state, action) => {
      state.valueFurtherInformations = action.payload
    },
    updateFromPercentage: (state, action) => {
      state.FromPercentage = action.payload
    },
    updateToPercentage: (state, action) => {
      state.ToPercentage = action.payload
    },

    updateLimitNode: (state, action) => {
      state.limitNode = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataThunk.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
    })
    builder.addCase(getDataThunk.rejected, (state, action) => {
      state.data = {}
      state.loading = action.payload
    })
  },
})

export const selectFromPercentage = (state) => state[slice.name].FromPercentage
export const selectToPercentage = (state) => state[slice.name].ToPercentage
export const selectfilterLevel = (state) => state[slice.name].filterLevel
export const selectShareHolders = (state) => state[slice.name].valueShareHolders
export const selectData = (state) => state[slice.name]?.data
export const selectLoading = (state) => state[slice.name]?.loading
export const selectPosition = (state) => state[slice.name].valuePosition
export const selectRelation = (state) => state[slice.name].valueRelation
export const selectFurtherInformation = (state) =>
  state[slice.name].valueFurtherInformations
export const selectLimitNode = (state) => state[slice.name].limitNode
export const {
  updatefilterLevel,
  updateShareHolders,
  updatePosition,
  updateRelation,
  updateFurtherInformation,
  updateFromPercentage,
  updateToPercentage,
  updateLimitNode,
  resetFilter,
} = slice.actions

register(slice.name, slice.reducer)
