export const tableConstants = [
  {
    id: 'OS',
    name: 'sector.financialAnalysis.security.performance.OPERATING_SALE',
    isYoyGrowth: false,
  },
  {
    id: 'OSYG',
    name: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'RBCS',
    name: 'sector.financialAnalysis.security.performance.REVENUE_BROKERAGE_CUSTOMER_SERVICE',
    isYoyGrowth: false,
  },
  {
    id: 'RBCSYG',
    name: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'RB',
    name: 'sector.financialAnalysis.security.performance.REVENUE_FROM_BROKERAGE',
    isYoyGrowth: false,
  },
  {
    id: 'RBYG',
    name: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'RML',
    name: 'sector.financialAnalysis.security.performance.REVENUE_FROM_MARGIN',
    isYoyGrowth: false,
  },
  {
    id: 'RMLYG',
    name: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'RP',
    name: 'sector.financialAnalysis.security.performance.REVENUE_FROM_PROPRIETARY',
    isYoyGrowth: false,
  },
  {
    id: 'RPYG',
    name: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'IFVTPL',
    name: 'sector.financialAnalysis.security.performance.INCOME_FVTPL',
    isYoyGrowth: false,
  },
  {
    id: 'IFYG',
    name: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'IAFS',
    name: 'sector.financialAnalysis.security.performance.INCOME_AFS',
    isYoyGrowth: false,
  },
  {
    id: 'IFAG',
    name: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
    isYoyGrowth: true,
  },
  {
    id: 'RI',
    name: 'sector.financialAnalysis.security.performance.REVENUE_FROM_INVESTMENT',
    isYoyGrowth: false,
  },
  {
    id: 'RIYG',
    name: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
    isYoyGrowth: true,
  },
]

export const tableFormat = {
  OS: 10 ** 9,
  OSYG: 1,
  RBCS: 10 ** 9,
  RBCSYG: 1,
  RB: 10 ** 9,
  RBYG: 1,
  RML: 10 ** 9,
  RMLYG: 1,
  RP: 10 ** 9,
  RPYG: 1,
  IFVTPL: 10 ** 9,
  IFYG: 1,
  IAFS: 10 ** 9,
  IFAG: 1,
  RI: 10 ** 9,
  RIYG: 1,
}
