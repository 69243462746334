import {
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT_P,
  FONT_SIZE_CONFIG,
} from '../../configs/Layout'
import UseFontSize from '../common/hooks/useFontSize'
import { LOCAL_STORAGE_KEY } from '../constants/Common'

export const changeUIFontSize = (typeFontSize) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.KEY_FONT_SIZE, typeFontSize)
  const newFontSize = getFontSize(12, typeFontSize)
  document.documentElement.style.fontSize = `${newFontSize}px`
}

export const getFontSize = (
  fontSize = DEFAULT_FONT_SIZE,
  typeFontSize = null,
) => {
  const newTypeFontSize = typeFontSize || UseFontSize()
  return fontSize * FONT_SIZE_CONFIG[newTypeFontSize].ratio
}

export const getLineHeightP = (
  lineHeightP = DEFAULT_LINE_HEIGHT_P,
  typeFontSize = null,
) => {
  const newTypeFontSize = typeFontSize || UseFontSize()
  return lineHeightP * FONT_SIZE_CONFIG[newTypeFontSize].ratio + 'px'
}
