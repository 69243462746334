import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import {
  getAccMonthAndYearKey,
  getMonthAndYearKey,
  getYearKey,
} from '../../../../common/helper'
import { TIME_TYPE } from '../../../balanceOfTrade/constants'
import { getMaxDateOverview } from '../../../store/thunk'
import { convertData } from '../helper'
import { getTopTradeGood } from './thunk'

const slice = createSlice({
  name: 'economy/importExport/outstandingTarget/topTradeGood',
  initialState: {
    typeChartVal: 0,
    dataChart: [],
    isLoading: true,
    frequencyType: TIME_TYPE.MONTHLY,
    locale: 'en',
    maxDate: null,
  },
  reducers: {
    changeTypeChartVal: (state, action) => {
      state.typeChartVal = action.payload
    },
    changeFrequencyTypeCommodityChart: (state, action) => {
      state.frequencyType = action.payload
    },
    changeLocaleTypeCommodityChart: (state, action) => {
      state.locale = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopTradeGood.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTopTradeGood.fulfilled, (state, action) => {
      state.isLoading = false

      const isYearly = state.frequencyType === TIME_TYPE.YEARLY
      const isAcc = state.frequencyType === TIME_TYPE.ACC_MONTHLY

      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          id: item.vnTypeId,
          name: item.vnTypeName,
          value: item.value / 1000000000,
          month: item.realMonth,
          year: item.realYear,
          monthYearKey: isYearly
            ? getYearKey(item.realYear, state.maxDate, state.locale)
            : isAcc
            ? getAccMonthAndYearKey(item.realMonth, item.realYear, state.locale)
            : getMonthAndYearKey(item.realMonth, item.realYear),
        })) || []
      state.dataChart = payloadData.length
        ? convertData(payloadData, 'Export', 'Import')
        : []
    })
    builder.addCase(getTopTradeGood.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getMaxDateOverview.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxDateOverview.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxDateOverview.rejected, (state) => {
      state.maxDate = null
    })
  },
})

export const selectDataChart = (state) => state[slice.name].dataChart
export const selectLoading = (state) => state[slice.name].isLoading
export const selectTypeChartVal = (state) => state[slice.name].typeChartVal
export const selectMaxDate = (state) => state[slice.name].maxDate

export const {
  changeTypeChartVal,
  changeFrequencyTypeCommodityChart,
  changeLocaleTypeCommodityChart,
} = slice.actions

register(slice.name, slice.reducer)
