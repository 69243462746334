import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import style from '../index.module.css'
import { updateFavoriteThunk } from '../store/thunk'

const WatchlistFavorite = ({ value, id }) => {
  const dispatch = useDispatch()

  const updateFavoriteTrue = () =>
    dispatch(updateFavoriteThunk({ bondWatchListId: id, isFavourite: true }))

  const updateFavoriteFalse = () =>
    dispatch(updateFavoriteThunk({ bondWatchListId: id, isFavourite: false }))

  const handleCaptureClick = (e) => {
    e.stopPropagation()
  }

  return (
    <div className="d-flex justify-content-center">
      {value ? (
        <button
          onDoubleClick={handleCaptureClick}
          className={style.pointer}
          onClick={updateFavoriteFalse}
        >
          <i className={`icon-star ${style.iconStar} ${style.iconActive}`} />
        </button>
      ) : (
        <button
          onDoubleClick={handleCaptureClick}
          className={style.pointer}
          onClick={updateFavoriteTrue}
        >
          <i
            className={`icon-star-stroke ${style.iconStar} ${style.iconDisable}`}
          />
        </button>
      )}
    </div>
  )
}

WatchlistFavorite.propTypes = {
  value: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default WatchlistFavorite
