import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import TextEllipsis from '../../../../common/textEllipsis'
import { keyBy } from '../../../../utils/Common'
import style from '../../index.module.css'

const NameChecked = ({ eventTypes, listAllChecked, listChecked }) => {
  const [eventTypeById, setEventTypeById] = useState({})
  useEffect(() => {
    setEventTypeById(keyBy(eventTypes, 'fiinXEventTypeId'))
  }, [eventTypes])

  return (
    <div className={style.w85}>
      {listAllChecked?.length > 0 &&
      listAllChecked?.length === listChecked?.length ? (
        <Translate value="market.event.ALL_EVENT_TYPE" />
      ) : listChecked.length > 0 ? (
        <TextEllipsis
          isI18n={false}
          text={listChecked
            .map((key) => eventTypeById[key]?.fiinXEventName)
            .join('; ')}
        />
      ) : (
        <span className={style.opacity0}>
          <Translate value="market.event.ALL_EVENT_TYPE" />
        </span>
      )}
    </div>
  )
}

NameChecked.propTypes = {
  eventTypes: PropTypes.array.isRequired,
  listAllChecked: PropTypes.array.isRequired,
  listChecked: PropTypes.array.isRequired,
}

export default NameChecked
