import { I18n, Translate } from 'react-redux-i18n'
import {
  CONTAINER_CHART_TYPES,
  DEFAULT_PRE_VALUE_KEY,
  DEFAULT_XAXIS_DATA_KEY,
} from '../../../../../common/chart/basicChart/constants'
import PieChartTooltip from '../../../../../common/chart/pieChart/pieChartWithFooter/PieChartTooltip'
import { Span } from '../../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../../utils/Value'

const colors = [
  '#3CABEB',
  '#E8B714',
  '#A6CF98',
  '#DF5A49',
  '#D9D9D9',
  '#9C54E4',
  '#00E5E4',
  '#185999',
  '#FACC5C',
  '#FA9AA7',
  '#FFF6DF',
]

const roundPercent = (value) => Math.round(value * 10 ** 4) / 10 ** 4

export const formatSectorDataApi = (data) => {
  if (!data.length) return data

  const otherValue =
    data[0].totalValue_ICB -
    data.reduce((total, item) => total + item.value_ICB, 0)
  const otherPercent =
    1 - data.reduce((total, item) => total + roundPercent(item.percentICB), 0)

  return [
    ...data.map((item) => ({
      [DEFAULT_XAXIS_DATA_KEY]: item.icbName,
      [`${DEFAULT_PRE_VALUE_KEY}1`]: roundPercent(item.percentICB),
      [`${DEFAULT_PRE_VALUE_KEY}1Raw`]: item.value_ICB / 10 ** 9,
      ...item,
    })),
    {
      [DEFAULT_XAXIS_DATA_KEY]: I18n.t('common.OTHERS'),
      [`${DEFAULT_PRE_VALUE_KEY}1`]: otherPercent,
      [`${DEFAULT_PRE_VALUE_KEY}1Raw`]: otherValue / 10 ** 9,
    },
  ]
}

export const getSectorProps = () => {
  return {
    colors,
    schema: [{ dataKey: `${DEFAULT_PRE_VALUE_KEY}1` }],
    chartProps: {
      chartType: CONTAINER_CHART_TYPES.PIE,
    },
    footerProps: {
      isOneColumn: true,
      isFooterScroll: true,
    },
    tooltipProps: {
      render: (data) => (
        <PieChartTooltip
          data={data}
          title={formatDateTime(
            new Date(data[0].reportYear, data[0].reportMonth - 1).toString(),
            FORMAT.MONTH_YEAR_NUMBER,
          )}
          renderValue={(val) => (
            <>
              <Span>{formatVal(val[`${DEFAULT_PRE_VALUE_KEY}1Raw`])}</Span>
              <Span className="ml-4">
                <Translate value="common.UNIT.BILLION_VND" />
              </Span>
              <Span className="ml-4">({valToPercent(val.value)})</Span>
            </>
          )}
        />
      ),
    },
  }
}
