import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SegmentalAnalysisService from '../../../../../../core/services/corporate/SegmentalAnalysisService'

export const getIndicatorMapping = createAsyncThunk(
  'corporate/businessModel/segmentalAnalysis/panelTable/GET_INDICATOR_MAPPING',
  async (params, { rejectWithValue }) => {
    try {
      const res = await SegmentalAnalysisService.getIndicatorMapping(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
