import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../common/calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Icon } from '../../../../common/html/Icon'
import { Span } from '../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { changeDate, selectDate } from '../../store/slice'
import { ISSUE_DATE_OPTIONS } from './../../constant'
import FilterDropdown from './FilterDropdown'
import style from './index.module.css'

const DateFilter = ({
  placeholderFrom,
  placeholderTo,
  excludeDateIntervals,
  label,
  startDateKey,
  endDateKey,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const startDate = useSelector(selectDate(startDateKey))
  const endDate = useSelector(selectDate(endDateKey))
  const timeZone = UseTimeZone()
  const dispatch = useDispatch()

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE, undefined, timeZone))
  }
  const handleChangeDate = (dateKey, value) => {
    dispatch(changeDate({ dateKey, value }))
  }

  const renderDateRange = (type) => {
    return {
      startYear: 2000,
      endYear: new Date(
        new Date().setFullYear(new Date().getFullYear() + 30),
      ).getFullYear(),
    }
  }

  const handleClick = (values) => {
    dispatch(changeDate({ dateKey: startDateKey, value: values[0] }))
    dispatch(changeDate({ dateKey: endDateKey, value: values[1] }))
  }
  const date = renderDateRange()
  const customHeader = HeaderOneCalendarRange({
    startYear: date.startYear,
    endYear: date.endYear,
  })
  return (
    <div className="w-100">
      <Translate
        value={label}
        style={{ fontSize: 11, opacity: 0.4 }}
        className="mb-8 d-block"
      />

      <label className={style.labelInput}>
        <Span style={{ fontSize: 11 }}>{}</Span>
      </label>
      <div className="d-flex ali-center mb-8" style={{ gap: 4 }}>
        <div className="form-control calendar mb-0" style={{ flex: 1 }}>
          <div className={`${style.bgGrey} w-100`}>
            <CalendarPopper
              date={startDate ? getDateWithTimeZone(startDate) : null}
              handleChange={(value) => handleChangeDate(startDateKey, value)}
              customHeader={customHeader}
              CustomInput={InputDateCalendar}
              popperClassName={style.popperClassNameLeft}
              placeholder={placeholderFrom}
              excludeDateIntervals={excludeDateIntervals}
            />
          </div>
          <Icon
            className={`icon-date ${style.eventNone}`}
            style={{ fontSize: 12 }}
          />
        </div>
        <div className="form-control calendar mb-0" style={{ flex: 1 }}>
          <div className={`${style.bgGrey} w-100`}>
            <CalendarPopper
              date={endDate ? getDateWithTimeZone(endDate) : null}
              handleChange={(value) => handleChangeDate(endDateKey, value)}
              customHeader={customHeader}
              CustomInput={InputDateCalendar}
              popperClassName={style.popperClassName}
              placeholder={placeholderTo}
              excludeDateIntervals={excludeDateIntervals}
            />
          </div>
          <Icon
            className={`icon-date ${style.eventNone}`}
            style={{ fontSize: 12 }}
          />
        </div>
        <FilterDropdown
          data={ISSUE_DATE_OPTIONS}
          handleClick={handleClick}
          timeZone={timeZone}
          locale={locale}
          width={160}
        />
      </div>
    </div>
  )
}

export default DateFilter
