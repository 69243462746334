import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DerivativeService from '../../../../../core/services/market/DerivativeService'

export const getTimeAndSales = createAsyncThunk(
  'market/derivative/TIME_AND_SALES',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getTimeAndSales(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTimeAndSalesChart = createAsyncThunk(
  'market/derivative/TIME_AND_SALES_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getTimeAndSalesChart(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
