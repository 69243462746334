import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import { EMPTY_VALUE } from '../../../constants/Common'
import { formatVal, valDivBillion, valToPercent } from '../../../utils/Value'
import TooltipInfo from '../tooltipInfo/index'
import style from './index.module.css'
import { selectDirectorValue } from './store/slice'

const TableCell = ({ val, colId, additionalData, rowId }) => {
  const rowRef = useRef()
  const nameRef = useRef()

  const [isShow, setIsShow] = useState(false)
  const [isPopoverTop, setIsPopoverTop] = useState(false)
  const [delayHandler, setDelayHandler] = useState(null)

  const directorById = useSelector(selectDirectorValue(rowId, 'shareAcquire'))

  const handleMouseEnter = () => {
    setDelayHandler(
      setTimeout(() => {
        setIsShow(true)
      }, 200),
    )
    handleShowPopover()
  }

  const handleMouseLeave = () => {
    setIsShow(false)
    clearTimeout(delayHandler)
  }

  const handleShowPopover = () => {
    const heightFromFooter =
      document.querySelector('#footer-table-deal').getBoundingClientRect().top -
      rowRef?.current?.getBoundingClientRect().top

    if (heightFromFooter <= 190) setIsPopoverTop(true)
    else setIsPopoverTop(false)
  }

  const renderData = () => {
    let renderItem = <div>{val}</div>
    const isDecrease = (colId === 5 && val < 0) || directorById < 0
    const isEmpty = val === EMPTY_VALUE

    switch (colId) {
      case 0:
        renderItem = (
          <div ref={rowRef} onMouseLeave={handleMouseLeave}>
            <span onMouseEnter={handleMouseEnter} ref={nameRef}>
              {val}
            </span>
            {isShow && (
              <TooltipInfo
                additionalData={additionalData}
                boundingClientRect={nameRef?.current?.getBoundingClientRect()}
                isPopoverTop={isPopoverTop}
              />
            )}
          </div>
        )
        break
      case 1:
        renderItem = <div>{val}</div>
        break
      case 2:
        renderItem = <div>{formatVal(val, 0)}</div>
        break
      case 3:
        renderItem = <div>{valToPercent(val)}</div>
        break
      case 5:
        renderItem = (
          <div className={isDecrease ? style.indexDecrease : ''}>
            {!isEmpty ? formatVal(val, 0) : EMPTY_VALUE}
          </div>
        )
        break
      case 6:
        renderItem = (
          <div
            className={isDecrease ? style.indexDecrease : undefined}
            ref={rowRef}
          >
            {!isEmpty
              ? isDecrease
                ? formatVal(valDivBillion(-1 * val))
                : formatVal(valDivBillion(val))
              : EMPTY_VALUE}
          </div>
        )
        break
      case 7:
      case 4:
        renderItem = <DateTimeSpan date={val} />
        break
      default:
        break
    }

    return renderItem
  }
  return renderData()
}

TableCell.defaultProps = {
  additionalData: null,
}

export default TableCell
