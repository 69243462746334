import { createAsyncThunk } from '@reduxjs/toolkit'
import NewsReportsService from '../../../../../core/services/sector/sectorConstituents/NewsReportsService'

export const getListReport = createAsyncThunk(
  'sector/sectorConstituents/newsReport/GET_LIST_REPORT',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getListReport(data)
    if (response.success) {
      return response
    }
    return rejectWithValue('Error')
  },
)

export const getListNewsEarning = createAsyncThunk(
  'sector/sectorConstituents/newsReport/GET_LIST_NEWS_EARNING',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getListNews({
      ...data,
      GroupNews: 'Earning',
    })
    if (response.success) {
      return response
    }
    return rejectWithValue('Error')
  },
)

export const getListNewsTransactions = createAsyncThunk(
  'sector/sectorConstituents/newsReport/GET_LIST_NEWS_TRANSACTIONS',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getListNews({
      ...data,
      GroupNews: 'Transactions',
    })
    if (response.success) {
      return response
    }
    return rejectWithValue('Error')
  },
)

export const getListNewsBusinessActivities = createAsyncThunk(
  'sector/sectorConstituents/newsReport/GET_LIST_NEWS_BUSINESS_ACTIVITIES',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getListNews({
      ...data,
      GroupNews: 'OtherBusinessActivities',
    })
    if (response.success) {
      return response
    }
    return rejectWithValue('Error')
  },
)

export const getNewsOther = createAsyncThunk(
  'sector/sectorConstituents/newsReport/GET_NEWS_OTHER',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getListNews(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getLatestNews = createAsyncThunk(
  'sector/sectorConstituents/newsReport/GET_LATEST_NEWS',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getLatestNews(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsById = createAsyncThunk(
  'sector/sectorConstituents/newsReport/GET_NEWS_BY_ID',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getNewsById(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsSources = createAsyncThunk(
  'sector/sectorConstituents/newsReport/GET_NEWS_SOURCES',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getNewsSources(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsCategories = createAsyncThunk(
  'sector/sectorConstituents/newsReport/GET_NEWS_CATEGORIES',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getNewsCategories(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getReportTypesAndSources = createAsyncThunk(
  'sector/sectorConstituents/newsReport/GET_REPORT_TYPES_AND_SOURCES',
  async (data) => {
    const response = await Promise.allSettled([
      NewsReportsService.getReportTypes(data),
      NewsReportsService.getReportSources(data),
    ])
    const [types, sources] = response

    return {
      types: types.status === 'fulfilled' ? types.value.data : [],
      sources: sources.status === 'fulfilled' ? sources.value.data : [],
    }
  },
)
