import { createSlice } from '@reduxjs/toolkit'
import { Translate } from 'react-redux-i18n'
import { maxSheet } from '../../Sheet'
import { typeWorkSpace } from '../../constant'
import {
  checkDeleteGroup,
  funcSortAsc,
  funcSortDesc,
  getArrColumnIdByIndicatorId,
  getIgnoreColor,
  getListColumnId,
  getObjStringCheckDuplicateCondition,
  randomColor,
} from '../../helper'
import { ADD_ROW } from '../../table/ListenerScrollTable'
import { SORT_TYPES } from '../../table/constant'

import { keyBy, uuid } from '../../../../utils/Common'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import { register } from '../../../../utils/ReducerRegistry'
import { isInValidValue } from '../../../../utils/Value'
import { encodeId } from '../helper'
import {
  getIndicatorThunk,
  getMostRecentThunk,
  getResultThunk,
  getShareTemplateThunk,
  getTemplateThunk,
  getTemplatesThunk,
  getWorkSpaceThunk,
} from './thunk'

const initialSheet = {
  indexSectorById: {},
  indexSectorId: [],
  columnId: [],
  columnById: {},
  indexSectorDisplay: [],
  groupColumnById: {},
  defaultIndexSectorId: [],
  newIndexSectorId: [],
  newGroupId: [],
  isLoad: false,
}

export const keys = {
  INDICATOR: 'indicator',
  TABLE_RESULT: 'tableResult',
}

const initialState = {
  loading: { [keys.INDICATOR]: false, [keys.TABLE_RESULT]: true },
  sheets: {
    1: initialSheet,
  },
  listSheet: [{ title: 'SHEET 1', value: 1 }],
  activeSheet: 1,
  indicator: [],
  indicatorById: {},
  detailPopupIndicator: null,
  indexById: {},
  sectorById: {},
  templates: [],
  mostRecent: {},
  isLoadWorkSpace: false,
}

export const slice = createSlice({
  name: 'dataExplorer/indicesSector',
  initialState,
  reducers: {
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    // sheet
    changeActiveSheet(state, action) {
      state.activeSheet = action.payload
    },
    addSheet(state, action) {
      state.sheets[action.payload] = initialSheet
    },
    removeSheet(state, action) {
      delete state.sheets[action.payload.value]
      if (action.payload.templateId) {
        let countTemplate = 1
        state.listSheet.forEach((sheet, index) => {
          if (sheet.templateId === action.payload.templateId) {
            state.listSheet[index].title =
              action.payload.templateName +
              (countTemplate !== 1 ? ` (${countTemplate})` : '')
            countTemplate += 1
          }
        })
      }
    },
    changeListSheet(state, action) {
      state.listSheet = action.payload
    },
    // index sector
    addIndexSector(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      let everyExist = true
      action.payload.forEach((indexSector) => {
        const id = encodeId(
          indexSector.groupId || indexSector.icbId,
          indexSector?.groupId,
        )
        if (!activeSheet.indexSectorById[id]) {
          activeSheet.indexSectorId.push(id)
          activeSheet.newIndexSectorId.push(id)
          activeSheet.indexSectorById[id] = {
            check: false,
            ...indexSector,
          }
          activeSheet.defaultIndexSectorId.push(id)
          everyExist = false
        }
      })
      if (everyExist && action.payload.length) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          <Translate value="tool.dataExplorer.indicesSector.EXIST_INDEX_SECTOR" />,
        ])
      } else {
        EventEmitter.dispatch(ADD_ROW, activeSheet.indexSectorId.length)
      }
    },
    // indicator
    addIndicator(state, action) {
      state.detailPopupIndicator = action.payload
    },
    editIndicator(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const column = state.sheets[state.activeSheet].columnById[action.payload]
      state.detailPopupIndicator = {
        ...column,
        ...activeSheet.groupColumnById[column.groupId],
      }
    },
    closePopupIndicator(state) {
      state.detailPopupIndicator = null
    },
    changeIndexSectorDisplay(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      if (activeSheet) {
        activeSheet.indexSectorDisplay = action.payload
      }
    },
    addColumn(state, action) {
      const indexColumn = action.payload.indexColumn
      const activeSheet = state.sheets[state.activeSheet]
      const objCheck = getObjStringCheckDuplicateCondition(
        activeSheet.columnById,
      )
      const newIndicators = action.payload.indicators.filter((item) => {
        const isValid = !objCheck[item.stringCheckDuplicate]
        objCheck[item.stringCheckDuplicate] = true
        return isValid
      })
      const color = randomColor(
        getIgnoreColor(activeSheet.columnId, activeSheet.columnById),
      )

      if (newIndicators.length) {
        const newIndicatorId = newIndicators.map((item) => item.alias)
        const groupId = uuid()

        activeSheet.groupColumnById[groupId] = {
          ...action.payload.detail,
          indicators: newIndicatorId,
          groupId,
          isGroup: action.payload.isGroup,
        }

        activeSheet.newGroupId.push(groupId)

        if (isInValidValue(indexColumn)) {
          activeSheet.columnId = activeSheet.columnId.concat(newIndicatorId)
        } else {
          activeSheet.columnId.splice(indexColumn, 0, ...newIndicatorId)
        }

        newIndicators.forEach(
          (item) =>
            (activeSheet.columnById[item.alias] = {
              ...item,
              color,
              groupId,
            }),
        )
      }
    },
    clearGroup(state, action) {
      const groupId = action.payload
      const activeSheet = state.sheets[state.activeSheet]
      const arrColumnId = getListColumnId(activeSheet.groupColumnById[groupId])

      activeSheet.columnId = activeSheet.columnId.filter(
        (id) => !arrColumnId.includes(id),
      )
      activeSheet.indexSectorId.forEach((indexSectorId) => {
        arrColumnId.forEach((columnId) => {
          delete activeSheet.indexSectorById[indexSectorId]?.[columnId]
        })
      })
      arrColumnId.forEach((columnId) => delete activeSheet.columnById[columnId])
      activeSheet.newGroupId = activeSheet.newGroupId.filter(
        (id) => groupId !== id,
      )
      delete activeSheet.groupColumnById[groupId]
    },
    sliceSubGroup(state, action) {
      const indicatorId = action.payload.indicatorId
      const activeSheet = state.sheets[state.activeSheet]
      const groupId = action.payload.groupId
      const arrColumnId = getArrColumnIdByIndicatorId(
        activeSheet.groupColumnById[groupId],
        activeSheet.columnById,
        indicatorId,
      )
      const indexColumn = activeSheet.columnId.indexOf(arrColumnId[0])

      activeSheet.columnId = activeSheet.columnId.filter(
        (id) => !arrColumnId.includes(id),
      )
      activeSheet.indexSectorId.forEach((indexSectorId) => {
        arrColumnId.forEach((columnId) => {
          delete activeSheet.indexSectorById[indexSectorId]?.[columnId]
        })
      })
      arrColumnId.forEach((columnId) => {
        delete activeSheet.columnById[columnId]
      })
      activeSheet.groupColumnById[groupId].indicators =
        activeSheet.groupColumnById[groupId].indicators.filter(
          (id) => !arrColumnId.includes(id),
        )
      if (checkDeleteGroup(activeSheet.groupColumnById[groupId])) {
        activeSheet.newGroupId = activeSheet.newGroupId.filter(
          (id) => id !== groupId,
        )
        delete activeSheet.groupColumnById[groupId]
      }

      const objCheck = getObjStringCheckDuplicateCondition(
        activeSheet.columnById,
      )
      const newIndicators = action.payload.indicators.filter(
        (item) => !objCheck[item.stringCheckDuplicate],
      )

      if (newIndicators.length) {
        const newGroupId = uuid()
        const newIndicatorId = newIndicators.map((item) => item.alias)
        const color = randomColor(
          getIgnoreColor(activeSheet.columnId, activeSheet.columnById),
        )

        activeSheet.groupColumnById[newGroupId] = {
          ...action.payload.detail,
          indicators: newIndicatorId,
          groupId: newGroupId,
          isGroup: action.payload.isGroup,
        }
        activeSheet.newGroupId.push(newGroupId)
        activeSheet.columnId.splice(indexColumn, 0, ...newIndicatorId)
        newIndicators.forEach(
          (item) =>
            (activeSheet.columnById[item.alias] = {
              ...item,
              color,
              groupId: newGroupId,
            }),
        )
      }
    },
    swapTwoColumn(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const { column1, column2 } = action.payload
      const idColumn1 = activeSheet.columnId[column1]
      const idColumn2 = activeSheet.columnId[column2]
      activeSheet.columnId.splice(column1, 1)
      activeSheet.columnId.splice(
        activeSheet.columnId.indexOf(idColumn2),
        0,
        idColumn1,
      )
    },
    sort(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const { idColumn, typeSort } = action.payload

      switch (typeSort) {
        case SORT_TYPES.DEFAULT:
          activeSheet.indexSectorId = activeSheet.defaultIndexSectorId
          break
        case SORT_TYPES.ASC:
          if (
            activeSheet.defaultIndexSectorId.length !==
            activeSheet.indexSectorId.length
          ) {
            activeSheet.defaultIndexSectorId = [...activeSheet.indexSectorId]
          }
          activeSheet.indexSectorId = activeSheet.indexSectorId.sort(
            funcSortAsc(activeSheet.indexSectorById, idColumn),
          )
          break
        case SORT_TYPES.DESC:
          activeSheet.indexSectorId = activeSheet.indexSectorId.sort(
            funcSortDesc(activeSheet.indexSectorById, idColumn),
          )
          break
        default:
          break
      }
    },
    swapColumns(state, action) {
      state.sheets[state.activeSheet].columnId = action.payload
    },
    changeIndexById(state, action) {
      state.indexById = action.payload
    },
    changeSectorById(state, action) {
      state.sectorById = action.payload
    },
    changeCheckIndexSector(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.indexSectorById[action.payload].check =
        !activeSheet.indexSectorById[action.payload].check
    },
    checkAll(state) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.indexSectorDisplay.forEach(
        (id) => (activeSheet.indexSectorById[id].check = true),
      )
    },
    uncheckAll(state) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.indexSectorDisplay.forEach(
        (id) => (activeSheet.indexSectorById[id].check = false),
      )
    },
    deleteIndexSector(state) {
      const activeSheet = state.sheets[state.activeSheet]
      const indexSectorDeleteId = {}
      activeSheet.indexSectorDisplay.forEach((id) => {
        if (activeSheet.indexSectorById[id].check) {
          indexSectorDeleteId[id] = true
          delete activeSheet.indexSectorById[id]
        }
      })
      activeSheet.indexSectorId = activeSheet.indexSectorId.filter(
        (id) => !indexSectorDeleteId[id],
      )
      activeSheet.newIndexSectorId = activeSheet.newIndexSectorId.filter(
        (id) => !indexSectorDeleteId[id],
      )
      activeSheet.defaultIndexSectorId =
        activeSheet.defaultIndexSectorId.filter(
          (id) => !indexSectorDeleteId[id],
        )
    },
    removeColumn(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const arrColumnIdRemove = action.payload

      activeSheet.columnId = activeSheet.columnId.filter(
        (id) => !arrColumnIdRemove.includes(id),
      )
      arrColumnIdRemove.forEach((columnId) => {
        activeSheet.indexSectorId.forEach(
          (id) => delete activeSheet.indexSectorById[id]?.[columnId],
        )
        const { groupId } = activeSheet.columnById[columnId]
        delete activeSheet.columnById[columnId]

        activeSheet.groupColumnById[groupId].indicators =
          activeSheet.groupColumnById[groupId].indicators.filter(
            (id) => id !== columnId,
          )
        if (checkDeleteGroup(activeSheet.groupColumnById[groupId])) {
          activeSheet.newGroupId = activeSheet.newGroupId.filter(
            (id) => id !== groupId,
          )
          delete activeSheet.groupColumnById[groupId]
        }
      })
    },
    changeIndexSectorId(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.indexSectorId = action.payload
    },
    openTemplate(state, action) {
      if (state.listSheet.length >= maxSheet) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          <Translate value="tool.dataExplorer.corporate.MAX_SHEET_1" />,
          <Translate value="tool.dataExplorer.corporate.MAX_SHEET_2" />,
        ])
      } else {
        const countSheetOfTemplate = state.listSheet.filter(
          (sheet) => sheet?.templateId === action.payload.templateId,
        ).length
        const value = state.listSheet[state.listSheet.length - 1].value + 1
        const title =
          action.payload.templateName +
          (countSheetOfTemplate ? ` (${countSheetOfTemplate + 1})` : '')
        state.listSheet.push({
          title,
          value,
          templateId: action.payload.templateId,
          templateName: action.payload.templateName,
        })
        state.sheets[value] = {
          ...initialSheet,
          ...action.payload.parameters?.rawParameter,
          isLoad: false,
        }
        state.activeSheet = value
      }
      state.loading[keys.TABLE_RESULT] = false
    },
    changeSheetParamsWhenLoadTemplate: (state, action) => {
      const { activeSheet, columnId, columnById, indexSectorId } =
        action.payload

      state.sheets[activeSheet] = {
        ...state.sheets[activeSheet],
        indexSectorId,
        newIndexSectorId: indexSectorId,
        columnId,
        columnById,
      }
    },
  },
  extraReducers: (builder) => {
    // most recent
    builder.addCase(getMostRecentThunk.fulfilled, (state, action) => {
      action.payload?.forEach((item) => {
        state.mostRecent[item.indicatorID + (item.indicatorType || '')] = item
      })
    })
    // get indicator
    builder.addCase(getIndicatorThunk.pending, (state) => {
      state.loading[keys.INDICATOR] = true
    })
    builder.addCase(getIndicatorThunk.fulfilled, (state, action) => {
      const indicator = action.payload || []
      state.indicator = indicator
      state.indicatorById = keyBy(indicator, 'indicatorId')
      state.loading[keys.INDICATOR] = false
    })
    builder.addCase(getIndicatorThunk.rejected, (state, action) => {
      state.loading[keys.INDICATOR] = action.payload
    })
    // result table
    builder.addCase(getResultThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getResultThunk.fulfilled, (state, action) => {
      const activeSheet = state.sheets[state.activeSheet]
      action.payload?.forEach((item) => {
        const id = item.id
        const indexSector = activeSheet.indexSectorById[id]
        activeSheet.indexSectorById[id] = {
          ...indexSector,
          ...item.items,
        }
      })
      activeSheet.isLoad = true
      activeSheet.newIndexSectorId = initialSheet.newIndexSectorId
      activeSheet.newGroupId = initialSheet.newGroupId
      state.loading[keys.TABLE_RESULT] = false
    })
    builder.addCase(getResultThunk.rejected, (state) => {
      state.loading[keys.TABLE_RESULT] = false
    })
    // template
    builder.addCase(getTemplatesThunk.fulfilled, (state, action) => {
      state.templates = action.payload || []
    })
    builder.addCase(getTemplateThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getTemplateThunk.rejected, (state) => {
      state.loading[keys.TABLE_RESULT] = false
    })
    builder.addCase(getShareTemplateThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getShareTemplateThunk.rejected, (state) => {
      state.loading[keys.TABLE_RESULT] = false
    })
    // get work space
    builder.addCase(getWorkSpaceThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getWorkSpaceThunk.fulfilled, (state, action) => {
      state.loading[keys.TABLE_RESULT] = false

      const workSpaces =
        action.payload?.filter(
          (item) => item.dataType === typeWorkSpace.INDEX_SECTOR,
        ) || []

      state.activeSheet = 1

      let countSheet = 1

      workSpaces.forEach((workSpace, index) => {
        const { data, template } = workSpace.parameters.rawParameter

        if (template) {
          state.sheets[countSheet] = {
            ...initialSheet,
            ...data,
            isLoad: false,
          }

          if (index === 0) {
            state.listSheet = []
          }

          state.listSheet.push({
            ...template,
            title:
              template?.title?.slice(0, 5) === 'SHEET'
                ? 'SHEET ' + countSheet
                : template?.title,
            value: countSheet,
          })

          countSheet += 1
        }
      })

      state.isLoadWorkSpace = true
    })
    builder.addCase(getWorkSpaceThunk.rejected, (state) => {
      state.isLoadWorkSpace = true

      state.loading[keys.TABLE_RESULT] = false
    })
  },
})

export const {
  resetStore,
  changeActiveSheet,
  addSheet,
  removeSheet,
  addIndexSector,
  addIndicator,
  closePopupIndicator,
  changeIndexSectorDisplay,
  addColumn,
  swapTwoColumn,
  sort,
  swapColumns,
  changeIndexById,
  changeSectorById,
  changeCheckIndexSector,
  checkAll,
  uncheckAll,
  deleteIndexSector,
  removeColumn,
  editIndicator,
  clearGroup,
  sliceSubGroup,
  changeListSheet,
  changeIndexSectorId,
  openTemplate,
  changeSheetParamsWhenLoadTemplate,
} = slice.actions

export const selectLoading = (key) => (state) => state[slice.name].loading[key]

export const selectActiveSheet = (state) => state[slice.name].activeSheet
export const selectSheet = (state) => state[slice.name].sheets

export const selectIndexSectorById = (sheet) => (state) =>
  state[slice.name].sheets[sheet]?.indexSectorById
export const selectIndexSectorId = (sheet) => (state) =>
  state[slice.name].sheets[sheet]?.indexSectorId
export const selectIndexSectorCell = (sheet) => (id, attr) => (state) =>
  state[slice.name].sheets[sheet]?.indexSectorById[id]?.[attr]
export const selectColumnId = (sheet) => (index) => (state) =>
  state[slice.name].sheets[sheet].columnId?.[index]
export const selectColumnCell = (sheet) => (id) => (state) =>
  state[slice.name].sheets[sheet].columnById?.[id]
export const selectColumnById = (sheet) => (state) =>
  state[slice.name].sheets[sheet].columnById
export const selectFullColumnId = (sheet) => (state) =>
  state[slice.name].sheets[sheet].columnId
export const selectIndexSectorDisplay = (sheet) => (index) => (state) =>
  state[slice.name].sheets[sheet].indexSectorDisplay?.[index]
export const selectFullIndexSectorDisplay = (sheet) => (state) =>
  state[slice.name].sheets[sheet].indexSectorDisplay
export const selectGroupColumnById = (sheet) => (state) =>
  state[slice.name].sheets[sheet].groupColumnById
export const selectNewIndexSectorId = (sheet) => (state) =>
  state[slice.name].sheets[sheet].newIndexSectorId
export const selectNewGroupId = (sheet) => (state) =>
  state[slice.name].sheets[sheet].newGroupId
export const selectConditionGroup = (sheet) => (groupId) => (state) =>
  state[slice.name].sheets[sheet].groupColumnById?.[groupId]?.condition

export const selectIndicator = (state) => state[slice.name].indicator
export const selectIndicatorById = (state) => state[slice.name].indicatorById

export const selectDetailPopupIndicator = (state) =>
  state[slice.name].detailPopupIndicator

export const selectIndexById = (state) => state[slice.name].indexById

export const selectSectorById = (state) => state[slice.name].sectorById

export const selectTemplates = (state) => state[slice.name].templates

export const selectListSheet = (state) => state[slice.name].listSheet

export const selectIsLoad = (sheet) => (state) =>
  state[slice.name].sheets[sheet].isLoad

export const selectMostRecent = (indicatorId, indicatorType) => (state) =>
  state[slice.name].mostRecent[indicatorId + (indicatorType || '')]

export const selectAllMostRecent = (state) => state[slice.name].mostRecent

export const selectIsLoadWorkSpace = (state) =>
  state[slice.name].isLoadWorkSpace

register(slice.name, slice.reducer)
