import React, { useRef, useState } from 'react'
import DropdownTooltip from '../../../common/textEllipsis/DropdownTooltip'
import { ReactComponent as IconHelp } from './icon-help.svg'

const IconTooltip = () => {
  const ref = useRef()
  const [isShow, setIsShow] = useState(false)

  const onMouseEnter = () => {
    setIsShow(true)
  }

  const onMouseLeave = () => {
    setIsShow(false)
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <IconHelp
          ref={ref}
          data-tip
          data-for={'corporate.companySnapshot.TEXT_TOOLTIP'}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          height={16}
          width={16}
        />
      </div>

      {isShow && (
        <DropdownTooltip
          containerRef={ref}
          text={'corporate.companySnapshot.TEXT_TOOLTIP'}
          isOpenTooltip={true}
          isI18n={true}
        />
      )}
    </>
  )
}

export default IconTooltip
