import { createSlice, current } from '@reduxjs/toolkit'
import {
  handleDataRealtime,
  pushDataRealtime,
} from '../../../../../common/chart/helper'
import { register } from '../../../../../utils/ReducerRegistry'
import { listTab, pricePerformanceFormatValue } from '../constants'
import { getDeepTransactionPricePerformance } from './thunk'

const initialState = {
  data: [],
  timeRange: listTab[0].value,
  referencePriceOneDay: 0,
  loading: true,
}

const getFormatData = (key, data) => {
  return pricePerformanceFormatValue[key]
    ? Math.round((data / pricePerformanceFormatValue[key]) * 100) / 100
    : data
}

export const slice = createSlice({
  name: 'market/equityTrading/deepTransaction/pricePerformance',
  initialState,
  reducers: {
    changeTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
    subscribePricePerformance: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        const newData = { ...action.payload[0] }
        const newDataRealtime = pushDataRealtime(
          60 * 1000,
          current(state.data),
          {
            ...newData,
            openPrice: getFormatData('openPrice', Number(newData.openPrice)),
            closePrice: getFormatData('closePrice', Number(newData.closePrice)),
            referencePrice: getFormatData(
              'referencePrice',
              Number(newData.referencePrice),
            ),
            matchVolume: getFormatData(
              'matchVolume',
              Number(newData.matchVolume),
            ),
          },
          'tradingDate',
          null,
          true,
          ['matchVolume'],
        )
        if (state.timeRange === 'OneDay') {
          state.data = newDataRealtime.map((item) => ({ ...item, dummy: 0 }))
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDeepTransactionPricePerformance.pending, (state) => {
      state.loading = true
      state.data = []
    })
    builder.addCase(
      getDeepTransactionPricePerformance.fulfilled,
      (state, action) => {
        state.loading = false
        const timeRange = action.meta.arg.TimeRange
        const data = [...action.payload.data]
          .sort(
            (a, b) =>
              new Date(a.tradingDate).getTime() -
              new Date(b.tradingDate).getTime(),
          )
          .map((item) => ({
            matchVolume: item.matchVolume,
            closePrice: item.closePrice,
            openPrice: item.openPrice,
            referencePrice: item.referencePrice,
            tradingDate: item.tradingDate,
          }))
          .map((item) => {
            const objData = {}
            Object.keys(item).forEach((key) => {
              objData[key] = getFormatData(key, item[key])
            })
            return objData
          })
        if (timeRange === 'OneDay') {
          const dataRealtime = handleDataRealtime(
            60 * 1000,
            data,
            'tradingDate',
          )
          state.data = dataRealtime.map((item) => ({ ...item, dummy: 0 }))
          state.referencePriceOneDay =
            dataRealtime.find((item) => item.referencePrice)?.referencePrice ||
            0
        } else {
          state.data = data.map((item) => ({
            ...item,
            referencePrice: state.referencePriceOneDay
              ? state.referencePriceOneDay
              : item.referencePrice,
            dummy: 0,
          }))
        }
      },
    )
    builder.addCase(
      getDeepTransactionPricePerformance.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
  },
})

export const { changeTimeRange, subscribePricePerformance } = slice.actions
export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
