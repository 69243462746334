export const TIME_FREQUENCY = {
  MONTHLY: 'Monthly',
  QUARTER: 'Quarterly',
  YEARLY: 'Yearly',
}

export const BOND_STATUS = {
  ALL: 'All',
  NORMAL: 15,
  CANCEL: 16,
  REDEMPTION: 17,
}

export const COLLATERAL = {
  ALL: 'All',
  YES: 'Yes',
  NO: 'No',
}

export const BOND_STATUS_OPTIONS = [
  {
    name: 'bond.corporateBond.bondIssueInformation.NORMAL',
    value: BOND_STATUS.NORMAL,
  },
  {
    name: 'bond.corporateBond.bondIssueInformation.CANCELLATION_DUE_TO_MATURITY',
    value: BOND_STATUS.CANCEL,
  },
  {
    name: 'bond.corporateBond.bondIssueInformation.CANCELED_DUE_TO_REDEMPTION',
    value: BOND_STATUS.REDEMPTION,
  },
]

export const COLLATERAL_RADIO = [
  {
    name: 'bond.corporateBond.bondIssueInformation.ALL',
    value: COLLATERAL.ALL,
  },
  {
    name: 'bond.corporateBond.bondIssueInformation.YES',
    value: COLLATERAL.YES,
  },
  {
    name: 'bond.corporateBond.bondIssueInformation.NO',
    value: COLLATERAL.NO,
  },
]

export const GROUP_COLUMNS = [
  {
    key: 'bondTicker',
    title: 'bond.corporateBond.bondIssueInformation.BOND_TICKER',
    attrs: {
      style: { minWidth: 100 },
    },
    keySort: 'bondTicker',
  },
  {
    key: 'organizationShortName',
    title: 'bond.corporateBond.bondIssueInformation.ORGANIZATION_NAME',
    attrs: {
      style: { minWidth: 170 },
    },
    keySort: 'organizationShortName',
  },
  {
    key: 'roleName',
    title: 'bond.corporateBond.bondIssueInformation.RELATIONSHIP',
    attrs: {
      style: { minWidth: 120 },
    },
    keySort: 'roleName',
  },
  {
    key: 'ticker',
    title: 'bond.corporateBond.bondIssueInformation.TICKER',
    attrs: {
      style: { minWidth: 60 },
    },
    keySort: 'ticker',
  },
  {
    key: 'issueMethodName',
    title: 'bond.corporateBond.bondIssueInformation.ISSUE_METHOD',
    attrs: {
      style: { minWidth: 110, width: 110 },
    },
    keySort: 'issueMethodName',
  },
  {
    key: 'nextInterestPaymentDate',
    title: 'bond.corporateBond.bondIssueInformation.NEXT_INTEREST_PAYMENT_DATE',
    attrs: {
      style: { minWidth: 100, width: 100, textAlign: 'right' },
    },
    keySort: 'nextInterestPaymentDate',
  },
  {
    key: 'issueDateId',
    title: 'bond.corporateBond.bondIssueInformation.ISSUE_DATE',
    attrs: {
      style: { minWidth: 90, width: 90, textAlign: 'right' },
    },
    keySort: 'issueDateId',
  },
  {
    key: 'maturityDateId',
    title: 'bond.corporateBond.bondIssueInformation.MATURITY_DATE',
    attrs: {
      style: { minWidth: 90, width: 90, textAlign: 'right' },
    },
    keySort: 'maturityDateId',
  },
  {
    key: 'couponInterestRate',
    title: 'bond.corporateBond.bondIssueInformation.COUPON',
    additionText: 'bond.corporateBond.bondIssueInformation.PERCENT',
    attrs: {
      style: { minWidth: 90, width: 90, textAlign: 'right' },
    },
    keySort: 'couponInterestRate',
  },
  {
    key: 'issueValue',
    title: 'bond.corporateBond.bondIssueInformation.ISSUE_VALUE',
    additionText: 'bond.corporateBond.bondIssueInformation.VALUE',
    attrs: {
      style: { minWidth: 130, width: 130, textAlign: 'right' },
    },
    keySort: 'issueValue',
  },
  {
    key: 'outstandingValue',
    title: 'bond.corporateBond.bondIssueInformation.OUTSTANDING_VALUE',
    additionText: 'bond.corporateBond.bondIssueInformation.VALUE',
    attrs: {
      style: { minWidth: 142, width: 142, textAlign: 'right' },
    },
    keySort: 'outstandingValue',
  },
  {
    key: 'duration',
    title: 'bond.corporateBond.bondIssueInformation.DURATION',
    additionText: 'bond.corporateBond.bondIssueInformation.MONTH',
    attrs: {
      style: { minWidth: 115, width: 115, textAlign: 'right' },
    },
    keySort: 'duration',
  },
  {
    key: 'activeStatusName',
    title: 'bond.corporateBond.bondIssueInformation.BOND_STATUS',
    attrs: {
      style: { minWidth: 125, width: 125 },
    },
    keySort: 'activeStatusName',
  },
  {
    key: 'collateralTypeNames',
    title: 'bond.corporateBond.bondIssueInformation.COLLATERAL',
    attrs: {
      style: { minWidth: 100, width: 100 },
    },
    keySort: 'collateralTypeNames',
  },
  {
    key: 'collateralValue',
    title: 'bond.corporateBond.bondIssueInformation.COLLATERAL_VALUE',
    additionText: 'bond.corporateBond.bondIssueInformation.VALUE',
    attrs: {
      style: { minWidth: 135, width: 135, textAlign: 'right' },
    },
    keySort: 'collateralValue',
  },
  {
    key: 'interestPaid',
    title: 'bond.corporateBond.bondIssueInformation.INTEREST_PAID',
    additionText: 'bond.corporateBond.bondIssueInformation.VALUE',
    attrs: {
      style: { minWidth: 84, width: 84, textAlign: 'right' },
    },
    keySort: 'interestPaid',
  },
  {
    key: 'principalPaid',
    title: 'bond.corporateBond.bondIssueInformation.PRINCIPAL_PAID',
    additionText: 'bond.corporateBond.bondIssueInformation.VALUE',
    attrs: {
      style: { minWidth: 110, width: 110, textAlign: 'right' },
    },
    keySort: 'principalPaid',
  },
  {
    key: 'sourceUrl',
    title: 'bond.corporateBond.bondIssueInformation.ATTACHED_DOCUMENT',
    attrs: {
      style: { minWidth: 76, width: 76, textAlign: 'center' },
    },
    keySort: 'sourceUrl',
  },
]

export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}

export const MONTH_QUARTER_YEAR_TABS = [
  {
    title: 'bond.corporateBond.issuers.MONTHLY',
    value: TIME_FREQUENCY.MONTHLY,
  },
  {
    title: 'bond.corporateBond.issuers.QUARTERLY',
    value: TIME_FREQUENCY.QUARTER,
  },
  {
    title: 'bond.corporateBond.issuers.YEARLY',
    value: TIME_FREQUENCY.YEARLY,
  },
]

export const REMAINING_MATURITIES_TABS = [
  {
    title: 'bond.corporateBond.remainingMaturities.DURATION',
    value: 1,
  },
  {
    title: 'bond.corporateBond.remainingMaturities.COUPON',
    value: 2,
  },
]

export const colorArray = [
  '#45B29D',
  '#FACC5C',
  '#185999',
  '#ECFFDF',
  '#A6CF98',
]
export const QUARTER_TABS = [
  {
    title: 'Q1',
    value: 1,
  },
  {
    title: 'Q2',
    value: 2,
  },
  {
    title: 'Q3',
    value: 3,
  },
  {
    title: 'Q4',
    value: 4,
  },
]
export const QUARTER_YEAR_TABS = [
  {
    title: 'bond.corporateBond.debtRelationInBalance.QUARTER',
    value: TIME_FREQUENCY.QUARTER,
  },
  {
    title: 'bond.corporateBond.debtRelationInBalance.YEAR',
    value: TIME_FREQUENCY.YEARLY,
  },
]
export const BAR_COLOR_INTEREST_AND_PRINCIPAL_PAID = ['#0096EB', '#DF5A49']
export const BAR_COLOR_REMAINING_MATURITIES = ['#45B29D', '#DF5A49', '#FFFFFF']
export const BAR_COLOR_DEBT_RELATION = [
  '#FECF2F',
  '#DF5A49',
  '#3597FF',
  '#FFFFFF',
]
export const BAR_COLOR_SCALE_OF_DEBT = ['#45B29D', '#FECF2F', '#DF5A49']
export const BAR_COLOR_CREDIT_RATIO = ['#40ABFF', '#FECF2F']

export const GROUP_COLUMNS_LENDING = [
  {
    key: 'name',
    title: 'bond.corporateBond.lendingCom.LOAN_NAME',
    keySort: 'name',
  },
  {
    key: 'loanName',
    title: 'bond.corporateBond.lendingCom.NAME',
    keySort: 'loanName',
  },
  {
    key: 'loanValue',
    title: 'bond.corporateBond.lendingCom.DEBT_VALUE',
    additionText: 'bond.corporateBond.lendingCom.UNIT',
    keySort: 'loanValue',
    alignRight: true,
  },
  {
    key: 'interestDescription',
    title: 'bond.corporateBond.lendingCom.INTEREST',
    keySort: 'interestDescription',
  },
  {
    key: 'paymentCalendarDescription',
    title: 'bond.corporateBond.lendingCom.PAYMENT',
    keySort: 'paymentCalendarDescription',
  },
]
export const GROUP_COLUMNS_LOAN = [
  {
    key: 'realYear',
    title: 'bond.corporateBond.lendingCom.PERIOD',
    keySort: 'realYear',
  },
  {
    key: 'loanTermName',
    title: 'bond.corporateBond.lendingCom.TYPE',
    keySort: 'loanTermName',
  },
  {
    key: 'name',
    title: 'bond.corporateBond.lendingCom.NAME_COM',
    keySort: 'name',
  },
  {
    key: 'loanValue',
    title: 'bond.corporateBond.lendingCom.DEBT_VALUE_1',
    additionText: 'bond.corporateBond.lendingCom.UNIT',
    alignRight: true,
    keySort: 'loanValue',
  },
  {
    key: 'interestDescription',
    title: 'bond.corporateBond.lendingCom.INTEREST',
    keySort: 'interestDescription',
  },
  {
    key: 'paymentCalendarDescription',
    title: 'bond.corporateBond.lendingCom.DURATION',
    keySort: 'paymentCalendarDescription',
  },
]
