import React from 'react'
import { useSelector } from 'react-redux'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Span } from '../../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal } from '../../../../../utils/Value'
import style from '../../style.module.css'
import { convertDataHoverTooltip, NUM_ITEM_MAX_IN_COL } from './helper'

const ChartTooltip = ({
  tooltipData,
  isCompare,
  rightLineKeys,
  rightLineKeysSecond,
  rightLineKeysCompare,
}) => {
  const timeZone = UseTimeZone()
  const locale = useSelector((state) => state.i18n.locale)
  const newTooltipKeys = isCompare
    ? convertDataHoverTooltip([...rightLineKeysCompare])
    : convertDataHoverTooltip([...rightLineKeys, ...rightLineKeysSecond])

  return (
    <div>
      <div className={style.fontWeight340}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>
            {formatDateTime(
              tooltipData.tradingDateId,
              FORMAT.DATE,
              locale,
              timeZone,
            )}
          </span>
        </div>
        <table>
          <tbody>
            {newTooltipKeys.map((item) => (
              <tr className={style.trTable} key={item.idFirst}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>
                      {item.nameCompareFirst}
                    </Span>{' '}
                    :
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <div
                    className={
                      [...rightLineKeys, ...rightLineKeysSecond].length >
                      NUM_ITEM_MAX_IN_COL
                        ? style.mr32
                        : ''
                    }
                  >
                    <Span style={{ fontSize: 11 }}>
                      {formatVal(tooltipData[item.idFirst])}
                    </Span>
                  </div>
                </td>
                {item.nameCompareSecond && (
                  <>
                    <td className={style.pdLabel}>
                      <div className={style.mr12}>
                        <Span style={{ fontSize: 11 }}>
                          {item.nameCompareSecond}
                        </Span>{' '}
                        :
                      </div>
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                      }}
                      className={`${style.pdLabel} ${style.fontWeight500}`}
                    >
                      <Span style={{ fontSize: 11 }}>
                        {formatVal(tooltipData[item.idSecond])}
                      </Span>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ChartTooltip
