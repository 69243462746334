import { gradientColorTypes } from '../../constants'
import styles from '../../style.module.css'

export const ColorGradientComponent = ({ value, onChange }) => {
  return (
    <div className="d-flex ali-center">
      {Object.values(gradientColorTypes).map((val) => (
        <div
          key={val}
          className={[
            styles.settingBlockItemColorGradient,
            styles[val],
            value === val ? styles.active : '',
          ].join(' ')}
          onClick={() => onChange(val)}
        />
      ))}
    </div>
  )
}
