export const COLLAPSE_ID_4 = '4'
export const COLLAPSE_ID_5 = '5'

export const DEFAULT_COLUMN_KEY = {
  sellOutright: '1-5-1',
  reverseRepo: '2-2-1',
}
export const DEFAULT_COLUMN_COLOR = {
  sellOutright: '#e5604e',
  reverseRepo: '#185999',
}

export const MONEY_MARKET_SYNC_ID = 'MONEY_MARKET_SYNC_ID'
