import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { useLocation, useNavigate } from 'react-router-dom'
import { ScrollComponent } from '../common/ScrollComponent'
import { Button } from '../common/html/Button'
import { Span } from '../common/html/Span'
import { SEARCH_TYPE } from './constants'
import style from './index.module.css'
import { SearchNew } from './new'
import { SearchSecurityFunction } from './securityFunction'
import { selectTotalRecords } from './store/slice'
import { getFullSearchData } from './store/thunk'

export const Search = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const locale = useSelector((state) => state.i18n.locale)
  const totalRecords = useSelector(selectTotalRecords)
  const [searchText, setSearchText] = useState('')
  const [searchType, setSearchType] = useState(SEARCH_TYPE.ALL)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setSearchText(params.get('text') || '')
    setSearchType(params.get('type') || SEARCH_TYPE.ALL)
  }, [location.search])

  const getMode = useMemo(() => {
    if (
      [
        SEARCH_TYPE.COMPANY,
        SEARCH_TYPE.DERIVATIVE,
        SEARCH_TYPE.FUNCTION,
        SEARCH_TYPE.SECTOR,
        SEARCH_TYPE.INDICE,
        SEARCH_TYPE.BOND,
      ].includes(searchType)
    ) {
      return 'Full'
    } else {
      return 'Lite'
    }
  }, [searchType])

  useEffect(() => {
    dispatch(
      getFullSearchData({
        Term: searchText,
        Mode: getMode,
      }),
    )
  }, [searchText, searchType, locale])

  const goBack = () => {
    navigate(-1, {})
  }

  const renderHeaderFullText = useMemo(() => {
    return (
      <Span style={{ fontSize: 16 }}>
        <span>{totalRecords} </span>
        <Translate value="search.fullSearch.RESULT_FOR" />
        <span> "</span>
        <span style={{ color: '#2f9aee' }}>{searchText}</span>
        <span>"</span>
      </Span>
    )
  }, [totalRecords, searchText])

  return (
    <ScrollComponent>
      <div className={`full-search ${style['full-search']}`}>
        <div className={`header-full ${style['header-full']}`}>
          <Button
            style={{ fontSize: 16 }}
            className={style['go-back']}
            onClick={goBack}
          >
            <i
              style={{ marginRight: '8px' }}
              className="icon-arrow-left-bold"
            />
          </Button>
          {renderHeaderFullText}
        </div>
        <div className={`content-full-search ${style['content-full-search']}`}>
          <div className={`search-new ${style['search-new']}`}>
            {searchType === SEARCH_TYPE.NEW ? (
              <SearchNew searchStr={searchText} searchType={searchType} />
            ) : (
              <SearchSecurityFunction
                searchStr={searchText}
                searchType={searchType}
              />
            )}
          </div>
          <div
            className={`search-new search-securities ${style['search-new']}`}
          >
            {searchType !== SEARCH_TYPE.NEW ? (
              <SearchNew searchStr={searchText} searchType={searchType} />
            ) : (
              <SearchSecurityFunction
                searchStr={searchText}
                searchType={searchType}
              />
            )}
          </div>
        </div>
      </div>
    </ScrollComponent>
  )
}
