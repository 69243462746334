import { SizeTracker } from '../../../common/sizeTracker'
import { dashboardTypes } from '../../constants'
import ChangeLayoutBtn from './ChangeLayoutBtn'
import { DeleteBtn } from './DeleteBtn'
import DownloadBtn from './DownloadBtn'
import style from './index.module.css'
import InputSearchLinkChart from './InputSearchLinkChart'
import { Left } from './Left'
import ShareBtn from './ShareBtn'

export const Header = ({ data, dashboardType }) => {
  return (
    <div className={style.container}>
      <SizeTracker className={style.content}>
        {(size) => {
          return (
            <>
              {size.width && (
                <div style={{ width: `calc(100% - ${size.width}px)` }}>
                  <Left data={data} dashboardType={dashboardType} />
                </div>
              )}

              <div className={style.right}>
                <InputSearchLinkChart />
                {!data.isTemplate && <ChangeLayoutBtn />}
                {/* <LinkBtn /> */}
                <DownloadBtn dashboardID={data.id} />
                <ShareBtn dashboardID={data.id} />
                {!data.isTemplate &&
                  dashboardType !== dashboardTypes.CHART_OF_THE_DAY && (
                    <DeleteBtn data={data} dashboardType={dashboardType} />
                  )}
              </div>
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}
