import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SizeTracker } from '../../common/sizeTracker'
import { PanelContainer } from '../common/panel/PanelContainer'
import ChartPanel from '../dashboard/ChartPanel'
import { PreviewChart } from '../dashboard/PreviewChart'

import {
  downloadAllChart,
  downloadChart,
  selectDownloadingDashboardChart,
} from '../common/chartInPanel/store/slice'
import useDownloadAllChart from '../common/chartInPanel/useDownloadAllChart'
import { FCLoading } from '../common/loading'
import { enableLinkChart, selectDashboardChartData } from '../store/slice'
import { getIndicators } from '../store/thunk'
import { Header } from './Header'

const SharedDashboardChart = ({ id, name }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const dashboardChartData = useSelector(selectDashboardChartData)
  const downloadingDashboardID = useSelector(selectDownloadingDashboardChart)
  const [isDownloading] = useDownloadAllChart(
    downloadingDashboardID,
    name,
    dashboardChartData,
  )

  useEffect(() => {
    dispatch(getIndicators())
  }, [locale])

  useEffect(() => {
    dispatch(enableLinkChart(false))
  }, [])

  return (
    <div className="flex-column h-100">
      <div className="position-relative w-100 h-100">
        <FCLoading isLoading={isDownloading} />
        <PreviewChartContent id={id} />
      </div>
    </div>
  )
}

export default SharedDashboardChart

const PreviewChartContent = memo(({ id }) => {
  const dispatch = useDispatch()

  const dashboardChartData = useSelector(selectDashboardChartData)

  const downloadAllCharts = (event, dashboardID) => {
    event.preventDefault()
    dispatch(downloadChart({ id: -1 }))
    dispatch(downloadAllChart({ id: dashboardID }))
  }

  return (
    <PreviewChart>
      {(handlePreviewChart, isHaveDataChartPreview) => {
        return (
          <SizeTracker>
            {(size) => (
              <>
                <Header
                  onDownloadChart={(event) => downloadAllCharts(event, id)}
                  typeDuplicate={'duplicateDashboard'}
                />
                {(size.height || size.height === 0) && (
                  <div
                    className="position-relative"
                    style={{ height: `calc(100% - ${size.height}px)` }}
                  >
                    <PanelContainer
                      id={`dashboardChart${id}`}
                      hasAddNewChart={false}
                    >
                      {dashboardChartData.map((item, index) => (
                        <ChartPanel
                          data={{}}
                          key={index}
                          index={index}
                          item={item}
                          isHaveDataChartPreview={isHaveDataChartPreview}
                          handlePreviewChart={handlePreviewChart}
                          isShowSetting={false}
                        />
                      ))}
                    </PanelContainer>
                  </div>
                )}
              </>
            )}
          </SizeTracker>
        )
      }}
    </PreviewChart>
  )
})
