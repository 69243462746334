import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { useLocation } from 'react-router-dom'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import Dropdown from '../../../common/dropdown'
import UseI18n from '../../../common/hooks/useI18n'
import { Button } from '../../../common/html/Button'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import CurrentState from '../../../utils/CurrentState'
import {
  FORMAT,
  getCurrentDateTime,
  getDateTimeFrom,
} from '../../../utils/Datetime'
import PopupCreateBond from '../../bondScreening/BondList/Popup/PopupCreateBond'
import {
  valueCheckBox,
  valueInterrest,
  valueTab as valueTabActive,
} from '../infoValuation/constants'
import {
  resetFormWhenChangeTab,
  selectStateSameIndustry,
  updateActiveTab,
  updateForm,
} from '../infoValuation/store/slice'
import { tabOption, valueTab } from './constants'
import style from './index.module.css'
import {
  changeActiveTab,
  selecBondIds,
  selectActiveTab,
  selectCreateBondId,
  selectCreateBondList,
  selectCreateBondStatus,
  selectCreateBondTicker,
  selectListBond,
  selectStatueDefaultId,
  updateBondIds,
  updateBondTicker,
  updateCreateBondId,
  updateStatusGetDefaultId,
} from './store/slice'
import {
  createBondThunk,
  getCreateBondListData,
  getDataListBond,
} from './store/thunk'

const limitScroll = 20

const Filter = () => {
  const { data } = CurrentState.getCurrentState()
  const dispatch = useDispatch()
  const form = useSelector(selectStateSameIndustry)
  const activeTab = useSelector(selectActiveTab)
  const listBond = useSelector(selectListBond)
  const bondId = useSelector(selecBondIds)
  const createBondId = useSelector(selectCreateBondId)
  const parentDropdownRef = useRef()
  const statusGetID = useSelector(selectStatueDefaultId)
  const [widthDropdown, setWidthDropdown] = useState(0)
  const createBondList = useSelector(selectCreateBondList)
  const createStatus = useSelector(selectCreateBondStatus)
  const createBondTicker = useSelector(selectCreateBondTicker)
  const location = useLocation()
  const basicInfo = useSelector(selectBasicInfo)

  const [isShow, setIsShow] = useState(false)
  const [isShowCreate, setIsShowCreate] = useState(false)
  const [searchString, setSearchString] = useState('')

  const [prevInputValue, setPrevInputValue] = useState('')
  const [prevCreateInputValue, setCreatePrevInputValue] = useState('')
  const [showPopupCreate, setIsShowPopupCreate] = useState(false)
  const [keySearch, setKeySearch] = useState('')

  const optionData = useMemo(() => {
    const data = listBond.map((e) => ({
      label: `${e.ticker}-${e.organizationShortName}`,
      value: e.bondId,
      ticker: e.ticker,
    }))
    if (searchString) {
      return data.filter((e) =>
        e.label.toLowerCase().includes(searchString.toLowerCase()),
      )
    }
    return data
  }, [listBond, searchString])

  const optionCreateBondData = useMemo(() => {
    const data = createBondList.map((e) => ({
      value: e.bondId,
      ticker: e?.bondTicker ?? '',
    }))

    if (keySearch) {
      return data.filter((e) =>
        e.ticker.toLowerCase().includes(keySearch.toLowerCase()),
      )
    }
    return data
  }, [createBondList, keySearch])

  useEffect(() => {
    if (statusGetID && !!optionData?.length) {
      const item = optionData.find((e) => {
        return e?.value === bondId
      })

      setSearchString(item?.ticker || '')
      dispatch(updateStatusGetDefaultId(false))
    }
  }, [statusGetID, optionData])

  const onSearchFocus = () => {
    setIsShow(true)
  }

  const onSearchCreateBondFocus = () => {
    setIsShowCreate(true)
  }

  const onSearchChange = (value) => {
    setSearchString(value)
  }

  const onSearchBlur = () => {
    setIsShow(false)
  }

  const onSearchCreateBondBlur = () => {
    setIsShowCreate(false)
  }

  useEffect(() => {
    dispatch(getDataListBond({}))
    dispatch(getCreateBondListData())
  }, [])

  useEffect(() => {
    if (data?.headerSearch?.bondId && !bondId) {
      setSearchString(data?.headerSearch?.ticker)
      dispatch(updateBondIds(data?.headerSearch?.bondId))
    }
  }, [data, bondId])

  useEffect(() => {
    return () => {
      dispatch(updateStatusGetDefaultId(true))
    }
  }, [])

  useEffect(() => {
    const resize = () => {
      setWidthDropdown(parentDropdownRef.current?.offsetWidth || 'auto')
    }
    resize()
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [parentDropdownRef.current?.offsetWidth])

  const onChangeDropdow = (item) => {
    dispatch(
      updateForm({
        ...form,
        tradingDate: getCurrentDateTime(FORMAT.DATE),
        paymentDate: getDateTimeFrom(new Date(), +1, 'day', FORMAT.DATE),
        TradingVolume: 0,
        interestBasis: valueInterrest.ACT,
        checkBox: valueCheckBox.YTM,
        ytm: '',
        dirtyprice: '',
        cleanPrice: '',
        ytmRender: '',
        accInterest: 0,
      }),
    )
    const key = CurrentState.getCurrentState().key
    CurrentState.delete(key)
    CurrentState.addRoute({
      key,
      data: {
        route: location.pathname,
        disableRecentVisited: true,
        headerSearch: {
          organizationId: basicInfo?.organizationId,
          bondId: item.value,
          ticker: item.ticker,
        },
      },
    })
    dispatch(updateActiveTab(valueTabActive.BOND_DEFAULT))
    dispatch(updateBondIds(item.value))
    setSearchString(item.ticker)
    setIsShow(false)
  }

  const onChangeDropdown = (item) => {
    dispatch(
      updateForm({
        ...form,
        tradingDate: getCurrentDateTime(FORMAT.DATE),
        paymentDate: getDateTimeFrom(new Date(), +1, 'day', FORMAT.DATE),
        TradingVolume: 0,
        interestBasis: valueInterrest.ACT,
        checkBox: valueCheckBox.YTM,
        ytm: '',
        dirtyprice: '',
        cleanPrice: '',
        ytmRender: '',
        accInterest: 0,
      }),
    )
    dispatch(updateActiveTab(valueTabActive.BOND_CREATED))
    dispatch(updateCreateBondId(item.value))
    setKeySearch(item.ticker)
    setIsShowCreate(false)
  }

  const changeTab = (item) => {
    if (item.value === valueTab.BOND_DEFAULT) {
      const bondItem = optionData.find((e) => {
        return e?.value === bondId
      })

      if (bondItem) {
        onChangeDropdow(bondItem)
      }
    } else {
      const bondItem = optionCreateBondData.find((e) => {
        return e?.value === createBondId
      })
      if (bondItem) {
        onChangeDropdown(bondItem)
      }
      dispatch(resetFormWhenChangeTab())
    }

    dispatch(changeActiveTab(item.value))
  }

  const handleFocus = () => {
    setPrevInputValue(searchString)
    setSearchString('')
  }

  const handleCreateBondFocus = () => {
    setCreatePrevInputValue(keySearch)
    setKeySearch('')
  }

  const handleBlur = () => {
    if (!isShow) {
      if (!searchString) {
        setSearchString(prevInputValue)
      }
    }
  }

  const handleSearchCreateBondBlur = () => {
    if (!isShowCreate) {
      if (!keySearch) {
        setKeySearch(prevCreateInputValue)
      }
    }
  }

  const handleCreateBond = (params) => {
    dispatch(createBondThunk(params))
  }

  useEffect(() => {
    if (createStatus && !!createBondTicker?.length) {
      setKeySearch(createBondTicker)
      updateBondTicker('')
      setIsShowPopupCreate(false)
    }
  }, [createStatus])

  return (
    <>
      <div className={`${style.wrapFilter}`}>
        <DispatchActionTab
          data={tabOption.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          item={{
            height: '20px',
          }}
          itemStyle={{
            fontWeight: 600,
          }}
          activeTab={activeTab}
          textSize={12}
          onChangeTab={changeTab}
        />
        <Button
          className="btn btn-blue"
          style={{
            fontSize: 12,
            paddingLeft: 10,
            paddingRight: 10,
            width: 'auto',
            minWidth: 93,
            height: '20px',
            fontWeight: 600,
            // opacity: 0.5,
          }}
          onClick={() => setIsShowPopupCreate(true)}
          // disabled
        >
          <Translate value="bond.valuation.infoSec.filter.btnCreatedBond" />
        </Button>
      </div>

      <div
        className={`search-bar ${style['search-bar']} ${style.borderInput} w-50 mb-8`}
        ref={parentDropdownRef}
      >
        {activeTab === valueTab.BOND_DEFAULT ? (
          <Input
            className="ml-8"
            type="text"
            placeholder={UseI18n('layout.header.ADVANCED_SEARCH_PLACE_HOLDER')}
            value={searchString}
            onChange={(e) => onSearchChange(e.target.value)}
            onClick={() => onSearchFocus()}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{
              fontSize: 12,
              fontWeight: 400,
              lineHeight: '20px',
              color: '#FFF',
              flex: 3,
              fontStyle: 'italic',
            }}
          />
        ) : (
          <Input
            className="ml-8"
            type="text"
            placeholder={UseI18n('layout.header.ADVANCED_SEARCH_PLACE_HOLDER')}
            value={keySearch?.toUpperCase()}
            onChange={(e) => setKeySearch(e?.target?.value ?? '')}
            onClick={() => onSearchCreateBondFocus()}
            onFocus={handleCreateBondFocus}
            onBlur={handleSearchCreateBondBlur}
            style={{
              fontSize: 12,
              fontWeight: 400,
              lineHeight: '20px',
              color: '#FFF',
              flex: 3,
              fontStyle: 'italic',
            }}
          />
        )}
        <i
          className="icon-search-2 mr-4"
          style={{ opacity: 0.5, fontSize: '10px' }}
        />
      </div>
      <Dropdown parentRef={parentDropdownRef} isShow={isShow}>
        <HandleClickOutside
          handleClickOutside={onSearchBlur}
          exceptRef={parentDropdownRef}
        >
          <div
            className={`live-search `}
            style={{
              height:
                optionData.length > limitScroll
                  ? `${16 * limitScroll}px`
                  : `unset`,
              overflow: 'auto',
              width: widthDropdown,
            }}
          >
            <div className="list-search w-100">
              {optionData.map((e, index) => (
                <div
                  // className={`${style['search-result']} `}
                  key={index}
                  onClick={() => onChangeDropdow(e)}
                  style={{
                    padding: '4px 8px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Span
                    style={{
                      fontSize: 12,
                      fontWeight: 340,
                      fontStyle: 'normal',
                    }}
                  >
                    {e.label}
                  </Span>
                </div>
              ))}
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
      <Dropdown parentRef={parentDropdownRef} isShow={isShowCreate}>
        <HandleClickOutside
          handleClickOutside={onSearchCreateBondBlur}
          exceptRef={parentDropdownRef}
        >
          <div
            className={`live-search `}
            style={{
              height:
                optionCreateBondData.length > limitScroll
                  ? `${16 * limitScroll}px`
                  : `unset`,
              overflow: 'auto',
              width: widthDropdown,
            }}
          >
            <div className="list-search w-100">
              {optionCreateBondData.map((e, index) => (
                <div
                  // className={`${style['search-result']} `}
                  key={index}
                  onClick={() => onChangeDropdown(e)}
                  style={{
                    padding: '4px 8px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Span
                    style={{
                      fontSize: 12,
                      fontWeight: 340,
                      fontStyle: 'normal',
                    }}
                  >
                    {e?.ticker?.toUpperCase() ?? ''}
                  </Span>
                </div>
              ))}
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
      {showPopupCreate && (
        <PopupCreateBond
          handleClose={() => setIsShowPopupCreate(false)}
          handleSubmit={handleCreateBond}
        />
      )}
    </>
  )
}

export default Filter
