import { chain, orderBy } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter'
import { NoData } from '../../../../common/noData'
import { BOND_TYPES, COLORS } from '../../constants'
import ChartTooltip from './ChartTooltip'

const ContributionChart = ({ data, width, height }) => {
  const locale = useSelector((state) => state.i18n.locale)

  const formatDataChart = useMemo(() => {
    if (!data?.length) return []

    const newDataIsHaveBuyValue = data.filter((item) => !isNaN(item?.buyValue))

    if (!newDataIsHaveBuyValue?.length) return []

    const dataByGovernment = newDataIsHaveBuyValue.filter((item) =>
      BOND_TYPES.GOVERNMENT_BOND.includes(item.bondTypeId),
    )

    const totalBuyValue = newDataIsHaveBuyValue.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    if (!dataByGovernment.length) {
      const newData = orderBy(
        chain(newDataIsHaveBuyValue)
          .groupBy('organizationId')
          .map((value, key) => ({
            organizationId: key,
            organizationShortName:
              locale === 'vi'
                ? value?.[0]?.organizationShortName
                : value?.[0]?.en_OrganizationShortName ?? '',
            ticker: value?.[0]?.ticker,
            buyValue: value.reduce(
              (total, item) => (total += item?.buyValue ?? 0),
              0,
            ),
          }))
          .value(),
        ['buyValue'],
        ['desc'],
      )

      const topData = newData.slice(0, 10)

      const totalTopBuyValue = topData.reduce(
        (total, item) => (total += item?.buyValue ?? 0),
        0,
      )

      const result = topData.map((item, index) => ({
        text: item?.organizationShortName ?? item?.ticker ?? '',
        value: totalBuyValue !== 0 ? (item?.buyValue ?? 0) / totalBuyValue : 0,
        color: COLORS[index],
        isI18n: false,
      }))

      if (topData.length === newData.length) return result

      const otherBuyValue = totalBuyValue - totalTopBuyValue

      return [
        ...result,
        {
          text: 'common.OTHERS',
          value: totalBuyValue !== 0 ? otherBuyValue / totalBuyValue : 0,
          color: COLORS[COLORS.length - 1],
          isI18n: true,
        },
      ]
    }

    const totalGovernmentBuyValue = dataByGovernment.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const newData = newDataIsHaveBuyValue.filter((item) =>
      BOND_TYPES.CORPORATE_BOND.includes(item.bondTypeId),
    )

    const dataByCorporate = orderBy(
      chain(newData)
        .groupBy('organizationId')
        .map((value, key) => ({
          organizationId: key,
          organizationShortName: value?.[0]?.organizationShortName,
          ticker: value?.[0]?.ticker,
          buyValue: value.reduce(
            (total, item) => (total += item?.buyValue ?? 0),
            0,
          ),
        }))
        .value(),
      ['buyValue'],
      ['desc'],
    )

    const governmentData = {
      text: 'bond.portfolio.common.GOVERNMENT_BOND',
      value: totalBuyValue !== 0 ? totalGovernmentBuyValue / totalBuyValue : 0,
      color: COLORS[0],
      isI18n: true,
    }

    const topCorporateData = dataByCorporate.slice(0, 9)

    const totalTopBuyValue = topCorporateData.reduce(
      (total, item) => total + (item?.buyValue ?? 0),
      0,
    )

    const corporateData = topCorporateData.map((item, index) => ({
      text: item?.organizationShortName ?? item?.ticker ?? '',
      value: totalBuyValue !== 0 ? (item?.buyValue ?? 0) / totalBuyValue : 0,
      color: COLORS[index + 1],
      isI18n: false,
    }))

    if (topCorporateData.length === dataByCorporate.length)
      return [governmentData, ...corporateData]

    const otherBuyValue =
      totalBuyValue - totalTopBuyValue - totalGovernmentBuyValue

    return [
      governmentData,
      ...corporateData,
      {
        text: 'common.OTHERS',
        value: totalBuyValue !== 0 ? otherBuyValue / totalBuyValue : 0,
        color: COLORS[COLORS.length - 1],
        isI18n: true,
      },
    ]
  }, [data, locale])

  const renderTooltip = (dataChart) => <ChartTooltip data={dataChart} />

  return (
    <>
      {!!formatDataChart.length ? (
        <PieChartWithFooter
          data={formatDataChart}
          height={height}
          width={width}
          renderTooltip={renderTooltip}
          footerRight
          footerProps={{
            isOneColumn: true,
            appendStyle: { gridGap: 10 },
            itemStyle: {
              padding: 0,
              alignItems: 'center',
            },
          }}
          isScroll={true}
        />
      ) : (
        <NoData />
      )}
    </>
  )
}

export default ContributionChart
