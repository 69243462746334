import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SegmentalAnalysisService from '../../../../../core/services/corporate/SegmentalAnalysisService'
import { handleExport } from '../../../../utils/Export'

export const getIncomeStructure = createAsyncThunk(
  'corporate/businessModel/segmentalAnalysis/GET_INCOME_STRUCTURE',
  async (params, { rejectWithValue }) => {
    try {
      const res = await SegmentalAnalysisService.getIncomeStructure(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getGetLatestPeriodBank = createAsyncThunk(
  'corporate/businessModel/segmentalAnalysis/getGetLatestPeriodBank',
  async (params, { rejectWithValue }) => {
    try {
      const res = await SegmentalAnalysisService.getGetLatestPeriodBank(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadIncomeStructure = createAsyncThunk(
  'corporate/businessModel/segmentalAnalysis/downloadIncomeStructure',
  async (data) => {
    const response = await SegmentalAnalysisService.downloadIncomeStructure(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
