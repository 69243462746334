import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../common/ScrollComponent'
import Dropdown, { positionDropdown } from '../../../common/dropdown'
import TextEllipsis from '../../../common/textEllipsis'
import { Z_INDEX } from '../../../constants/Common'
import { lengthChartInLayout } from '../../chart/common/useGetLayoutDashboard'
import {
  selectChartByDashboardId,
  selectListDashboard,
} from '../../store/slice'
import style from './index.module.css'

export const PopupCopyTo = ({
  parentRef,
  isShow,
  copyToNewDB,
  copyToDB,
  copyToRef,
  positionCopyToLeft,
}) => {
  const listDashboard = useSelector(selectListDashboard)
  const chartByDashboardId = useSelector(selectChartByDashboardId)

  return (
    <>
      <Dropdown
        parentRef={parentRef}
        isShow={isShow}
        position={
          positionCopyToLeft
            ? positionDropdown.LEFT_TOP
            : positionDropdown.RIGHT_TOP
        }
      >
        <div ref={copyToRef} className={style.dropdownContainer}>
          <ScrollComponent autoHeight autoHeightMax={279} bgColor="#D9D9D9">
            <div className={style.dropdownItem} onClick={copyToNewDB}>
              <Translate value="financialChart.NEW_DASHBOARD" />
            </div>
            {listDashboard.map((item, index) => (
              <Item
                key={index}
                item={item}
                charts={chartByDashboardId[item.id]}
                copyToDB={() => copyToDB(item)}
              />
            ))}
          </ScrollComponent>
        </div>
      </Dropdown>
    </>
  )
}

const Item = ({ item, charts, copyToDB }) => {
  return (
    <>
      {lengthChartInLayout[item.layout] === charts?.length ? (
        <div
          className={`${style.disable} ${style.dropdownItem}`}
          title={I18n.t('financialChart.WARNING_DB_IS_OUT_OF_SPACE')}
        >
          <TextEllipsis zIndexTooltip={Z_INDEX.DROPDOWN} text={item.name} />
        </div>
      ) : (
        <div className={style.dropdownItem} onClick={copyToDB}>
          <TextEllipsis zIndexTooltip={Z_INDEX.DROPDOWN} text={item.name} />
        </div>
      )}
    </>
  )
}
