import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Search } from '../../../common/search'
import { Separator } from '../../../common/separator'
import { SizeTracker } from '../../../common/sizeTracker'
import { changeMoreFilter } from '../store/slice'
import { Grid } from './Grid'
import { Header } from './Header'
import style from './index.module.css'
import { MoreFilter } from './MoreFilter'

const RESPONSIVE_MIN_WIDTH = 1440
const PADDING_LEFT_RIGHT = 400

export const Content = ({ width, defaultType, detailRoute }) => {
  const dispatch = useDispatch()

  return (
    <div className="d-flex justify-content-center h-100">
      <div
        className="h-100"
        style={{
          width:
            width < RESPONSIVE_MIN_WIDTH ? '100%' : width - PADDING_LEFT_RIGHT,
        }}
      >
        <SizeTracker>
          {(size) => {
            return (
              <>
                <div className={style.header}>
                  <Search
                    onChange={(search) => {
                      dispatch(changeMoreFilter({ search }))
                    }}
                  />
                  <MoreFilter />
                </div>
                <div className="mb-15">
                  <Header height={250} detailRoute={detailRoute} />
                </div>
                <div className="mb-15">
                  <Separator isVertical={false} />
                </div>
                {size.height && (
                  <div
                    className="w-100"
                    style={{ height: `calc(100% - ${size.height}px)` }}
                  >
                    <Grid defaultType={defaultType} detailRoute={detailRoute} />
                  </div>
                )}
              </>
            )
          }}
        </SizeTracker>
      </div>
    </div>
  )
}

Content.propTypes = {
  width: PropTypes.number.isRequired,
  defaultType: PropTypes.number,
  detailRoute: PropTypes.string,
}
