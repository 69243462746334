import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import useGetICB from '../../../../common/masterData/useGetICB'
import useGetStock from '../../../../common/masterData/useGetStock'
import { NoData } from '../../../../common/noData'
import SectorName from '../SectorName'
import TickerName from '../TickerName'
import { COLORS, ITEM_TYPE } from '../constant'
import { changePage } from '../report/store/slice'
import style from './index.module.css'
import {
  deleteTickerOrSector,
  selectLoadingDefaultItems,
  selectSelectedItems,
} from './store/slice'

const ListItem = () => {
  const dispatch = useDispatch()

  const { loading: loadingIcb } = useGetICB()
  const { loading: loadingStock } = useGetStock()
  const selectedItems = useSelector(selectSelectedItems)
  const loadingDefaultItems = useSelector(selectLoadingDefaultItems)

  const handleDelete = ({ organizationId, icbId }) => {
    dispatch(deleteTickerOrSector({ organizationId, icbId }))
    dispatch(changePage(0)) // Set page = 0 to recall api when current page equal to 1
  }

  const renderItems = () => {
    return (
      <ScrollComponent>
        {selectedItems.map((item, index) => {
          const id = item.icbId
            ? `${ITEM_TYPE.SECTOR}-${item.icbId}`
            : `${ITEM_TYPE.TICKER}-${item.organizationId}`
          return (
            <Fragment key={id}>
              {!!item.icbId && <div className={style.divider} />}
              {!item.isDeleted && (
                <div className={style.listItem}>
                  <div className="cursor-pointer">
                    <Span
                      onClick={() =>
                        handleDelete({
                          organizationId: item?.organizationId,
                          icbId: item?.icbId,
                        })
                      }
                      style={{ fontSize: 7, color: '#7d7d7d' }}
                    >
                      <i className="icon-delete-stroke" />
                    </Span>
                  </div>
                  <div
                    className={style.circle}
                    style={{ backgroundColor: COLORS[item.color] }}
                  />
                  {item.icbId ? (
                    <SectorName icbId={item.icbId} className={style.icbName} />
                  ) : (
                    <TickerName
                      organizationId={item.organizationId}
                      hasCompanyName
                      tickerClassName={style.ticker}
                      companyNameClassName={style.companyName}
                    />
                  )}
                </div>
              )}
            </Fragment>
          )
        })}
      </ScrollComponent>
    )
  }

  return (
    <div className={style.listWrapper}>
      {loadingIcb || loadingStock || loadingDefaultItems ? (
        <Loading />
      ) : selectedItems.length ? (
        renderItems()
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default ListItem
