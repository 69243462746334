import { createAsyncThunk } from '@reduxjs/toolkit'
import WatchlistService from '../../../core/services/watchlist/WatchlistService'
import EventEmitter from '../../utils/EventEmitter'

export const CREATE_WATCHLIST = 'CREATE_WATCHLIST'

export const getWatchListThunk = createAsyncThunk(
  'watchlist/GET_WATCH_LIST',
  async (data, { rejectWithValue }) => {
    const response = await WatchlistService.getWatchlist(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const updateMasterWatchListThunk = createAsyncThunk(
  'watchlist/UPDATE_MASTER_WATCH_LIST',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await WatchlistService.updateMasterWatchlist(data)
    dispatch(getWatchListThunk())
    if (!response.success) return rejectWithValue(response.errors)
  },
)

export const copyWatchlistThunk = createAsyncThunk(
  'watchlist/COPY_WATCH_LIST',
  async (data, { dispatch }) => {
    await WatchlistService.copyWatchlist(data)
    dispatch(getWatchListThunk())
  },
)

export const deleteWatchlistThunk = createAsyncThunk(
  'watchlist/DELETE_WATCH_LIST',
  async (data, { dispatch }) => {
    await WatchlistService.deleteWatchlist(data)
    dispatch(getWatchListThunk())
  },
)

export const getWatchlistDetailThunk = createAsyncThunk(
  'watchlist/GET_WATCHLIST_DETAIL',
  async (data, { rejectWithValue }) => {
    const response = await WatchlistService.getWatchlistDetail(data)
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getTickerByExchangeThunk = createAsyncThunk(
  'watchlist/GET_TICKER_BY_EXCHANGE',
  async (data, { rejectWithValue }) => {
    const response = await WatchlistService.getTickerByExchange(data)
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getTickerByICBThunk = createAsyncThunk(
  'watchlist/GET_TICKER_BY_ICB',
  async (data, { rejectWithValue }) => {
    const response = await WatchlistService.getTickerByICB(data)
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const updateWatchlistThunk = createAsyncThunk(
  'watchlist/UPDATE_WATCH_LIST',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await WatchlistService.updateWatchlist(data)
    dispatch(getWatchListThunk())
    if (!response.success) return rejectWithValue(response.errors)
  },
)

export const createWatchlistThunk = createAsyncThunk(
  'watchlist/CREATE_WATCH_LIST',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await WatchlistService.createWatchlist(data)
    EventEmitter.dispatch(CREATE_WATCHLIST)
    dispatch(getWatchListThunk())
    if (!response.success) rejectWithValue(response.errors)
  },
)

export const updateFavoriteThunk = createAsyncThunk(
  'watchlist/UPDATE_FAVORITE',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await WatchlistService.updateFavorite(data)
    dispatch(getWatchListThunk())
    if (!response.success) return rejectWithValue(response.errors)
  },
)
