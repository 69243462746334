import TextEllipsis from '../../../../../../common/textEllipsis'

const FirstColumnLevelTwo = ({ text, existStyle, className }) => {
  return (
    <td
      style={{ ...existStyle, width: '100%', maxWidth: 0 }}
      className={className}
    >
      <div
        style={{
          paddingLeft: 24,
          fontStyle: 'italic',
          opacity: 0.4,
        }}
      >
        <TextEllipsis
          text={'corporate.businessModel.penetrationAnalysis.' + text}
        />
      </div>
    </td>
  )
}

export default FirstColumnLevelTwo
