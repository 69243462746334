import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import { Table as TableComponent } from '../../../../common/table'
import { FEATURES } from '../../../../constants/routes'
import {
  convertSortKeyToNumber,
  convertSortKeyToString,
  keyBy,
} from '../../../../utils/Common'
import { TABLE_COL_KEYS, TABLE_TABS } from '../constants'
import {
  getSortFieldDefaultByTableTab,
  getTableSchema,
  getTableThCellTopById,
  getTableThSchema,
} from '../helpers'
import {
  changeFilter,
  changeTableTab,
  selectAssets,
  selectDataById,
  selectDataCellById,
  selectFilters,
  selectIds,
  selectLoading,
  selectReCalcWidth,
  selectTableTab,
  sortTableData,
} from '../store/slice'
import { withGetData } from './withGetData'

const Table = () => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  // Selector
  const locale = useSelector((state) => state.i18n.locale)
  const ids = useSelector(selectIds)
  const loading = useSelector(selectLoading)
  const tableTab = useSelector(selectTableTab)
  const filters = useSelector(selectFilters)
  const reCalcWidth = useSelector(selectReCalcWidth)
  const dataById = useSelector(selectDataById)
  const assets = useSelector(selectAssets)
  const assetByTicker = keyBy(assets, 'ticker')

  const [valueSort, setValueSort] = useState('')

  // Actions
  const handleChangePage = (page) => {
    if (page) {
      dispatch(changeFilter({ page }))
    }
  }

  const handleChangeSortField = (data) => {
    if (Object.values(data)[0]) {
      dispatch(
        changeFilter({
          sortField: Object.keys(data)[0],
          sortOrder: convertSortKeyToNumber(Object.values(data)[0]),
        }),
      )
    } else {
      dispatch(changeFilter({ sortField: 'tradingDate', sortOrder: 1 }))
    }
  }

  const handleClickTicker = (val, rowId) => {
    if (tableTab === TABLE_TABS.ALL) {
      dispatch(changeTableTab(TABLE_TABS.DETAIL))
      dispatch(changeFilter({ assetId: assetByTicker[val].value }))
    } else {
      const organizationId = dataById[rowId]?.organizationId || ''
      navigate(
        FEATURES.fund.components['fund-profile'].components.navData.route,
        { organizationId },
      )
    }
  }

  useEffect(() => {
    const sortField =
      filters.sortField || getSortFieldDefaultByTableTab(tableTab)
    setValueSort({ [sortField]: convertSortKeyToString(filters.sortOrder) })
  }, [tableTab])

  return (
    <TableComponent
      ids={ids}
      schema={getTableSchema({
        tableTab,
        locale,
        onClickTicker: handleClickTicker,
      })}
      rowSpanSchema={getTableThSchema(tableTab)}
      tableHeadRowTop={getTableThCellTopById(tableTab)}
      getDataFromRedux={selectDataCellById}
      isLoading={loading}
      isLargeHead={true}
      resizable={false}
      hasFooter={false}
      hasTooltip={false}
      hasPagination={tableTab === TABLE_TABS.ALL}
      currentPage={filters.page}
      totalPage={filters.totalPage}
      reCalcWidths={reCalcWidth}
      stickies={{
        [TABLE_COL_KEYS.COL_ALL_TICKER]: true,
        [TABLE_COL_KEYS.COL_ALL_NAME]: true,
        [TABLE_COL_KEYS.COL_DETAIL_TICKER]: true,
        [TABLE_COL_KEYS.COL_DETAIL_NAME]: true,
        [TABLE_COL_KEYS.COL_DETAIL_INVESTMENT_STRATEGY]: true,
      }}
      valueSort={valueSort}
      sort={sortTableData}
      handleChangePage={handleChangePage}
      handleSortField={handleChangeSortField}
    />
  )
}

export default withGetData(Table)
