import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ItemRadio from '../popupIndicator/common/ItemRadio'
import RadioCalendar from '../popupIndicator/common/RadioCalendar'
import { deepCopy } from '../popupIndicator/helper'
import style from '../popupIndicator/index.module.css'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import TableTicker from './common/TableTicker'
import { resetPopupTemplate } from './store/slice'

const Performance = ({ handleSubmit, handleCancel, minDate, maxDate }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(resetPopupTemplate())
  }, [])

  const convertData = (data) => {
    const detail = deepCopy(data.detail)
    if (detail.mostRecent) {
      delete detail.date
    }
    handleSubmit({ ...data, detail })
  }

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="tool.dataExplorer.PERFORMANCE" />
            </Span>
          </h3>
          <a className="close-modal" onClick={handleCancel}>
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className={`d-flex ali-center ${style.isContent}`}>
            <ItemRadio
              defaultCheck={true}
              keyRadio="mostRecent"
              valueRadio={true}
              textRadio={I18n.t('common.popup.MOST_RECENT_2')}
            />
            <ItemRadio
              keyRadio="mostRecent"
              valueRadio={false}
              textRadio={I18n.t('common.popup.DATE')}
              renderContent={(isDisabled) => (
                <RadioCalendar
                  keySelect="date"
                  disabled={isDisabled}
                  maxDate={maxDate}
                  minDate={minDate}
                  defaultValue={maxDate}
                />
              )}
            />
          </div>
          <div className="content">
            <TableTicker />
          </div>
          <Count />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit isHasDetailIndicator={true} submit={convertData} />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default Performance
