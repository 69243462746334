import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { FOUR_LINES, LINE_FOUR_COLORS, LINE_THREE_COLORS } from './constants'

export const FooterChartCompanyGrowth = ({ titleLineKeys }) => {
  return (
    <Footer
      list={
        titleLineKeys.length === FOUR_LINES
          ? titleLineKeys.map((item) => ({
              text: I18n.t(item),
              type: TYPE.LINE,
              before: {
                bgColor: LINE_FOUR_COLORS[titleLineKeys.indexOf(item)],
              },
            }))
          : titleLineKeys.map((item) => ({
              text: I18n.t(item),
              type: TYPE.LINE,
              before: {
                bgColor: LINE_THREE_COLORS[titleLineKeys.indexOf(item)],
              },
            }))
      }
    />
  )
}
