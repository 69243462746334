import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OverviewService from '../../../../../core/services/corporate/OverviewService'
import { handleExport } from '../../../../utils/Export'

export const getFinancialMetricsDataArea = createAsyncThunk(
  'corporate/overview/financialMetric/GET_FINANCIAL_METRIC',
  async (data, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getFinancialMetricsDataArea(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ isLoading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadFinancialMetrics = createAsyncThunk(
  'corporate/overview/financialMetric/DOWNLOAD_FINANCIAL_METRICS',
  async (data) => {
    const response = await OverviewService.downloadFinancialMetrics(data)
    handleExport(response.data, response.filename)
  },
)
