import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../common/ScrollComponent'
import Dropdown from '../../../common/dropdown'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { removeAccents } from '../../../utils/Search'
import { TYPE_IMPORT_EXPORT } from './constants'
import { LIMIT_FIRST_FETCH, TYPE_CATEGORY } from './exportImportTable/constants'
import {
  changeActiveItemTable,
  changeKeySearch,
  handleIsFirstTime,
  handleMultipleActiveRowsIds,
  selectActiveItemTable,
  selectCurrentDataCategory,
  selectDataTable,
  selectImportExportDataType,
  selectIsFirstTime,
  selectKeySearch,
  selectRowsCollapse,
  selectTypeFilter,
  selectUnCollapseIds,
  setRowsCollapse,
  setUnCollapseIds,
} from './exportImportTable/store/slice'
import {
  getExportByLocationChildren,
  getExportTradeAgreementsByLocationChildren,
  getImportByLocationChildren,
  getImportTradeAgreementsByLocationChildren,
} from './exportImportTable/store/thunk'
import {
  changeActiveIdStructureChart,
  selectCurrentActiveName,
} from './exportStructure/store/slice'
import style from './index.module.css'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}
const TOP_PARENT_COLLAPSE_LEVEL = 1
const PARENT_COLLAPSE_LEVEL = 2
const CHILDREN_LEVEL = 3

export const CountriesSearch = ({ isInTable, limitQuarter }) => {
  const inputRef = useRef()
  const exceptOutsideRef = useRef()

  const dispatch = useDispatch()

  const data = useSelector(selectDataTable)
  const activeCountry = useSelector(selectActiveItemTable)
  const activeLocationName = useSelector(selectCurrentActiveName)
  const rowsCollapse = useSelector(selectRowsCollapse)
  const keySearch = useSelector(selectKeySearch)
  const isFirstTime = useSelector(selectIsFirstTime)

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [eventPressKey, setEventPressKey] = useState()

  // const listAllCategory = isInTable
  //   ? data?.filter((item) => item.level !== CHILDREN_LEVEL)
  //   : data?.filter((item) => item.level === PARENT_COLLAPSE_LEVEL)

  const listAllCategory = data?.filter(
    (item) => item.level === PARENT_COLLAPSE_LEVEL,
  )

  const getUniqueNames = (data) => {
    const uniqueNames = [...new Set(data.map((item) => item.name))]
    return uniqueNames.map((uniqueName) =>
      data?.find((item) => item.name === uniqueName),
    )
  }

  const listCategory = getUniqueNames(listAllCategory).map((item) => ({
    ...item,
    locationNameWithoutAccent: removeAccents(item.name),
  }))

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  const setKeySearch = (val) => {
    dispatch(changeKeySearch(val))
  }

  const handleChangeSearch = (e) => {
    setKeySearch(e.target.value)
    if (!e.target.value && (activeCountry || activeLocationName)) {
      dispatch(
        setRowsCollapse(
          data
            ?.filter((item) => item.level === PARENT_COLLAPSE_LEVEL)
            .map((item) => item.id),
        ),
      )
    }
  }

  const filterListCategory = (list) => {
    const keySearchWithoutAccent = removeAccents(keySearch)
    return list.filter((item) =>
      item.locationNameWithoutAccent
        ?.toLowerCase()
        .includes(keySearchWithoutAccent?.toLowerCase()),
    )
  }

  useEffect(() => {
    if (isFirstTime) {
      dispatch(changeKeySearch(''))
    } else {
      dispatch(changeKeySearch(activeLocationName))
    }
  }, [activeLocationName])

  return (
    <>
      <div
        ref={exceptOutsideRef}
        className={`form-search-inbox ${style.inputSearch}`}
      >
        <Input
          style={{
            fontSize: 11,
            backgroundColor: '#3c424b',
          }}
          type="text"
          className="search-inbox bg-grey"
          placeholder={activeCountry?.name || listCategory[0]?.name}
          onChange={handleChangeSearch}
          onFocus={handleShow}
          onKeyDown={handlePressKey}
          ref={inputRef}
          value={keySearch}
        />
        <button type="button">
          <i className={`icon-search-2 ${style.colorIcon}`} />
        </button>
        <DropdownCountries
          isShowDropdown={isShowDropdown}
          parentRef={inputRef}
          handleHide={handleHide}
          exceptOutsideRef={exceptOutsideRef}
          listCountries={filterListCategory(listCategory)}
          setKeySearch={setKeySearch}
          eventPressKey={eventPressKey}
          rowsCollapse={rowsCollapse}
          limitQuarter={limitQuarter}
        />
      </div>
    </>
  )
}

const DropdownCountries = ({
  isShowDropdown,
  parentRef,
  handleHide,
  eventPressKey,
  listCountries,
  exceptOutsideRef,
  setKeySearch,
  rowsCollapse,
  limitQuarter,
}) => {
  const dispatch = useDispatch()

  const unCollapseIds = useSelector(selectUnCollapseIds)
  const currentTypeFilter = useSelector(selectTypeFilter)
  const currentCategoryFilter = useSelector(selectCurrentDataCategory)
  const importExportDataType = useSelector(selectImportExportDataType)
  const data = useSelector(selectDataTable)

  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    const lengthCountries = listCountries.length
    if (eventPressKey?.keyCode === keyCode.ENTER) {
      if (activeIndex || activeIndex === 0) {
        handleClickItem(listCountries[activeIndex])
      } else if (listCountries[0]) {
        handleClickItem(listCountries[0])
      }
      eventPressKey.preventDefault()
      eventPressKey.target.blur()
    }
    if (eventPressKey?.keyCode === keyCode.DOWN_ARROW && lengthCountries > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
      else if (activeIndex < lengthCountries - 1)
        setActiveIndex(activeIndex + 1)
    }
    if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthCountries > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(lengthCountries - 1)
      else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
    }
  }, [eventPressKey])

  const handleClickItem = (value) => {
    dispatch(changeActiveItemTable(value.id))
    if (value.level !== 1) {
      dispatch(changeActiveIdStructureChart(value.locationId))
    }

    const parentUncollapsedId = data
      ?.filter(
        (item) =>
          item.level === TOP_PARENT_COLLAPSE_LEVEL &&
          item.childrenLocationIds.includes(value.locationId),
      )
      .map((item) => item.id)
    dispatch(
      setRowsCollapse(
        rowsCollapse.filter(
          (item) => item !== value.id && !parentUncollapsedId.includes(item),
        ),
      ),
    )

    const multipleActiveRowsIds = data
      ?.filter(
        (item) =>
          item.level === PARENT_COLLAPSE_LEVEL &&
          item.locationId === value.locationId,
      )
      .map((item) => item.id)

    dispatch(handleMultipleActiveRowsIds(multipleActiveRowsIds))

    const scrollTable = document.querySelector(
      `#exportImportTable .scrollbars div:first-child`,
    )
    const headerTable = scrollTable?.querySelector('thead')
    const activeRow = scrollTable?.querySelector(`[id="${value.id}"]`)

    if (scrollTable && headerTable && activeRow) {
      dispatch(handleIsFirstTime(false))
      setTimeout(() => {
        scrollTable.scrollTo({
          top: activeRow.offsetTop - headerTable.offsetHeight,
          behavior: 'smooth',
        })
      })
    }

    const getChildrenDataByType = (value) => {
      if (currentCategoryFilter === TYPE_CATEGORY.CONTINENT) {
        if (importExportDataType === TYPE_IMPORT_EXPORT.IMPORT) {
          dispatch(
            getImportByLocationChildren({
              DataType: currentTypeFilter,
              Limit: limitQuarter
                ? limitQuarter + LIMIT_FIRST_FETCH
                : LIMIT_FIRST_FETCH,
              LocationIds: value?.id,
            }),
          )
        } else {
          dispatch(
            getExportByLocationChildren({
              DataType: currentTypeFilter,
              Limit: limitQuarter
                ? limitQuarter + LIMIT_FIRST_FETCH
                : LIMIT_FIRST_FETCH,
              LocationIds: value?.id,
            }),
          )
        }
      } else {
        if (importExportDataType === TYPE_IMPORT_EXPORT.IMPORT) {
          dispatch(
            getImportTradeAgreementsByLocationChildren({
              DataType: currentTypeFilter,
              Limit: limitQuarter
                ? limitQuarter + LIMIT_FIRST_FETCH
                : LIMIT_FIRST_FETCH,
              LocationIds: value?.locationId,
              areaIds: value?.parentId,
            }),
          )
        } else {
          dispatch(
            getExportTradeAgreementsByLocationChildren({
              DataType: currentTypeFilter,
              Limit: limitQuarter
                ? limitQuarter + LIMIT_FIRST_FETCH
                : LIMIT_FIRST_FETCH,
              LocationIds: value?.locationId,
              areaIds: value?.parentId,
            }),
          )
        }
      }
    }

    if (!unCollapseIds.includes(value.id)) {
      dispatch(setUnCollapseIds([value.id, ...unCollapseIds]))
      getChildrenDataByType(value)
    }

    handleHide()
    setKeySearch(value.name)
  }

  return (
    <Dropdown isShow={isShowDropdown} parentRef={parentRef}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOutsideRef}
      >
        <div
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownFilter}
          id="search-ticker"
        >
          {listCountries.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="common.NO_DATA" />
              </Span>
            </div>
          ) : (
            <ScrollComponent>
              {listCountries.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={`${style.contentFilter} ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onMouseDown={() => handleClickItem(item)}
                  >
                    <ItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter}`}>
                      <div className={style.textEllipsis}>{item.name}</div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

const ItemScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#search-ticker .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}
