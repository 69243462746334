import MonetaryProxy from '../../proxies/economy/MonetaryProxy'
import { ServiceBase } from '../ServiceBase'

class MonetaryService extends ServiceBase {
  // Monetary
  getMoneySupplyOutstandingLoan(params) {
    return this.applyMemoryCache(
      'MonetaryService.getMoneySupplyOutstandingLoan',
      params,
    )(() => MonetaryProxy.getMoneySupplyOutstandingLoan(params))
  }

  downloadMoneySupplyOutstandingLoan(params) {
    return this.getFileDownload(() =>
      MonetaryProxy.downloadMoneySupplyOutstandingLoan(params),
    )
  }

  getMonetaryTypeName(params) {
    return this.applyMemoryCache(
      'MonetaryService.getMonetaryTypeName',
      params,
    )(() => MonetaryProxy.getMonetaryTypeName(params))
  }

  getMoneySupplyOutstandingLoanGrowth(data) {
    return this.applyMemoryCache(
      'MonetaryService.getMoneySupplyOutstandingLoanGrowth',
      data,
    )(() => MonetaryProxy.getMoneySupplyOutstandingLoanGrowth(data))
  }

  getContributionToCreditGrowth(params) {
    return this.applyMemoryCache(
      'MonetaryService.getContributionToCreditGrowth',
      params,
    )(() => MonetaryProxy.getContributionToCreditGrowth(params))
  }

  getGrowthOverYears(params) {
    return this.applyMemoryCache(
      'MonetaryService.getGrowthOverYears',
      params,
    )(() => MonetaryProxy.getGrowthOverYears(params))
  }

  // Exchange Rate
  getCurrencyExchangeRate(params) {
    return this.applyMemoryCache(
      'MonetaryService.getCurrencyExchangeRate',
      params,
    )(() => MonetaryProxy.getCurrencyExchangeRate(params))
  }

  downloadCurrencyExchangeRate(params) {
    return this.getFileDownload(() =>
      MonetaryProxy.downloadCurrencyExchangeRate(params),
    )
  }

  getChangeOfExchangeRate(params) {
    return this.applyMemoryCache(
      'MonetaryService.getChangeOfExchangeRate',
      params,
    )(() => MonetaryProxy.getChangeOfExchangeRate(params))
  }
}

export default new MonetaryService()
