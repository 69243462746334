import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Span } from '../../../../common/html/Span'
import Popup from '../../../../common/popup'
import TextEllipsis from '../../../../common/textEllipsis'
import {
  selectBubbleColor,
  selectBubbleSize,
  selectFilteredOrganizationIds,
  selectFilterExchanges,
  selectFilterMarketCap,
  selectIsShowTicker,
  selectPlotType,
  selectPointInTime,
  selectSelectedOrganizationGroups,
  selectSelectedOrganizations,
  selectTemplates,
  selectTimeSeries,
  selectTimeSeriesType,
  selectXIndicator,
  selectXIndicatorCategory,
  selectYIndicator,
  selectYIndicatorCategory,
} from '../store/slice'
import { addTemplate } from '../store/thunk'
import style from './index.module.css'

const PopupSaveTemplate = ({ handleHideSaveAs }) => {
  const dispatch = useDispatch()

  const templates = useSelector(selectTemplates)
  const selectedOrganizationGroups = useSelector(
    selectSelectedOrganizationGroups,
  )
  const selectedOrganizations = useSelector(selectSelectedOrganizations)
  const filteredOrganizationIds = useSelector(selectFilteredOrganizationIds)
  const filterExchanges = useSelector(selectFilterExchanges)
  const filterMarketCap = useSelector(selectFilterMarketCap)
  const xIndicatorCategory = useSelector(selectXIndicatorCategory)
  const xIndicator = useSelector(selectXIndicator)
  const yIndicatorCategory = useSelector(selectYIndicatorCategory)
  const yIndicator = useSelector(selectYIndicator)
  const plotType = useSelector(selectPlotType)
  const timeSeriesType = useSelector(selectTimeSeriesType)
  const pointInTime = useSelector(selectPointInTime)
  const timeSeries = useSelector(selectTimeSeries)
  const bubbleSize = useSelector(selectBubbleSize)
  const bubbleColor = useSelector(selectBubbleColor)
  const isShowTicker = useSelector(selectIsShowTicker)

  const [templateName, setTemplateName] = useState('')
  const [error, setError] = useState()

  const handleChangeInput = (e) => {
    const value = e.target.value
    setTemplateName(value)
    if (value === '') {
      setError('sector.sectorConstituents.segmentation.TEMPLATE_REQUIRE')
    } else if (value.trim().length < 3) {
      setError('sector.sectorConstituents.segmentation.TEMPLATE_NAME_TOO_SHORT')
    } else if (
      templates.some((template) => template.templateName === value.trim())
    ) {
      setError('sector.sectorConstituents.segmentation.TEMPLATE_EXIST')
    } else {
      setError(null)
    }
  }

  const handleSaveTemplate = () => {
    const params = {
      templateName,
      templateParameters: JSON.stringify({
        selectedOrganizationGroups,
        selectedOrganizations,
        filteredOrganizationIds,
        filterExchanges,
        filterMarketCap,
        xIndicatorCategory,
        xIndicator,
        yIndicatorCategory,
        yIndicator,
        plotType,
        timeSeriesType,
        pointInTime,
        timeSeries,
        bubbleSize,
        bubbleColor,
        isShowTicker,
      }),
    }

    dispatch(addTemplate(params))
    handleHideSaveAs()
  }

  const disabledButton = error !== null

  return (
    <Popup>
      <div className="modal modal-small w-450">
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="sector.sectorConstituents.segmentation.BTN_SAVE_AS" />
            </Span>
          </h3>
          <a onClick={handleHideSaveAs} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content">
          <div className="content">
            <div className="form-control form-vertical ali-center j-b">
              <label className={`mb-0 ${style.labelPopup}`}>
                <Span>
                  <TextEllipsis text="sector.sectorConstituents.segmentation.LABEL_NAME_TEMPLATE" />
                </Span>
              </label>
              <input
                value={templateName}
                type="text"
                className={`w-300 bg-grey-4 ${style.inputPopup}`}
                onChange={handleChangeInput}
                autoFocus
              />
            </div>
            {error && (
              <div className="text-error t-right">
                <Span>
                  <Translate value={error} />
                </Span>
              </div>
            )}
          </div>
          <div className="mt-40 group-btn justify-content-end">
            <Button
              onClick={handleHideSaveAs}
              className={`btn normal h-20 ${style.fontWeightNormal}`}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <Button
              onClick={handleSaveTemplate}
              disabled={disabledButton}
              className={`btn btn-blue ${
                disabledButton ? style.disabled : ''
              } h-20 ${style.fontWeightNormal}`}
            >
              <Translate value="common.button.BUTTON_SAVE" />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupSaveTemplate
