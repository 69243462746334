import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Bar, Line, Scatter } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { Span } from '../../../../common/html/Span'
import { COM_GROUP } from '../../../../constants/Group'
import { formatVal, valToPercent } from '../../../../utils/Value'
import { formatData } from './helper'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  lineKey,
  barKey,
  scatterDiamondKey,
  typeSector,
}) => {
  const dataFormat = formatData(data, barKey, lineKey, scatterDiamondKey)

  const renderTooltip = (tooltipData) => {
    const contentTooltip = [
      {
        label:
          typeSector === COM_GROUP.BANK
            ? I18n.t(
                'sector.sectorConstituents.overview.TOTAL_OPERATING_INCOME',
              )
            : I18n.t(`sector.sectorConstituents.overview.NET_REVENUE`),
        value:
          formatVal(tooltipData[barKey]) +
          I18n.t(`sector.sectorConstituents.overview.TRILLION_VND_TOOLTIP`),
      },
      {
        label: I18n.t(`sector.sectorConstituents.overview.NET_MARGIN`),
        value: valToPercent(tooltipData[scatterDiamondKey], true),
      },
      {
        label: I18n.t(`sector.sectorConstituents.overview.PEER_NET_MARGIN`),
        value: valToPercent(tooltipData[lineKey], true),
      },
    ]
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData.shortName}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>{el.value}</Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ChartContainer
      data={dataFormat}
      width={width}
      height={height || 0}
      margin={{ ...MARGIN_RECHARTS, right: 14 }}
      keyXAxis={keyXAxis}
      yAxis={[
        {
          id: 'left',
          keys: [barKey],
          orientation: 'left',
          isBarChart: true,
          tickNum: SETTINGS.yTickNum,
          label: I18n.t('sector.sectorConstituents.overview.TRILLION_VND'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
        },
        {
          id: 'right',
          keys: [lineKey, scatterDiamondKey],
          orientation: 'right',
          isLineChart: true,
          tickNum: SETTINGS.yTickNum,
          labelPosition: AXIS_LABEL_POSITION.RIGHT,
          unitYAxis: '%',
        },
      ]}
      renderCustomTooltip={renderTooltip}
    >
      <Bar
        isAnimationActive={false}
        yAxisId="left"
        dataKey={barKey}
        barSize={getColumnSizeInBarChart(width, data.length, 35)}
        fill="#0096eb"
      />
      <Scatter
        yAxisId="right"
        dataKey={scatterDiamondKey}
        isAnimationActive={false}
        shape={
          <ScatterDiamondShape dataKey={scatterDiamondKey} color="#f7959c" />
        }
      />
      <Line
        yAxisId="right"
        dataKey={lineKey}
        type="linear"
        stroke="#f7b80c"
        dot={false}
        activeDot={false}
        isAnimationActive={false}
        strokeDasharray="5 3"
        strokeWidth={2}
      />
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}
