import { useSelector } from 'react-redux'
import TextEllipsis from '../../../../common/textEllipsis'
import styles from './index.module.css'

const MARGIN_LEFT = 40

export const FirstColumnCell = ({
  val,
  rowId,
  style,
  className,
  selectDataByIds,
}) => {
  const isYoyGrowth = useSelector(selectDataByIds(rowId, 'isYoyGrowth'))

  return (
    <td className={[className, styles.customTd].join(' ')} style={style}>
      <TextEllipsis
        isI18n={true}
        text={val}
        appendStyle={{
          marginLeft: isYoyGrowth ? MARGIN_LEFT : 0,
        }}
        className={isYoyGrowth ? styles.customTdText : ''}
      />
    </td>
  )
}
