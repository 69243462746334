import PropTypes from 'prop-types'
import { memo, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Line, ReferenceLine } from 'recharts'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import ContentTooltip from '../../../../../common/chart/rechart/ContentTooltip'
import ReferencePrice from '../../../../../common/chart/rechart/ReferencePrice'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import {
  selectIndexInfo,
  selectPriceData,
} from '../../../../../common/topInfo/indexTopInfo/store/slice'
import { PANEL_PADDING } from '../../../../../constants/Common'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valDivMillion } from '../../../../../utils/Value'
import { decodeSizePanel } from '../../helper'
import style from '../../index.module.css'
import { selectChartTimeRange, selectIndexSeries } from '../../store/slice'

const ChartComponent = memo(
  ({ chartContainerRef, sizes, chartMargin, setChartMargin }) => {
    const timezone = UseTimeZone()

    const locale = useSelector((state) => state.i18n.locale)
    const indexSeries = useSelector(selectIndexSeries)
    const timeRange = useSelector(selectChartTimeRange)
    const indexInfo = useSelector(selectIndexInfo)
    const priceData = useSelector(selectPriceData)

    const [sizeChart, setSizeChart] = useState({
      width: decodeSizePanel(sizes).width - PANEL_PADDING,
      height: 0,
    })

    useEffect(() => {
      setSizeChart({
        width: chartContainerRef.current.offsetWidth,
        height: chartContainerRef.current.offsetHeight,
      })
    }, [sizes])

    useEffect(() => {
      setChartMargin({
        ...chartMargin,
        left: Math.max(55, chartMargin.left),
        right: Math.max(40, chartMargin.right),
      })
    }, [sizeChart, indexSeries])

    const openIndex = priceData.priceInfo?.referencePrice

    return (
      <div className="position-relative h-100">
        {sizeChart.width && sizeChart.height && (
          <>
            {!indexSeries.length ? (
              <NoData />
            ) : (
              <ChartContainer
                data={indexSeries}
                width={decodeSizePanel(sizes).width - PANEL_PADDING}
                height={sizeChart.height}
                keyXAxis="tradingDate"
                yAxis={[
                  {
                    id: 'left',
                    keys: ['indexValue'],
                    isLineChart: true,
                    orientation: 'left',
                    tickFormatter: () => '',
                    yAxisWidth: chartMargin.left,
                    customDomain: ([min, max]) => [
                      Math.min(openIndex, min) / 1.02,
                      Math.max(openIndex * 1.01, max),
                    ],
                  },
                  {
                    id: 'right',
                    keys: ['matchVolume'],
                    isBarChart: true,
                    orientation: 'right',
                    tickFormatter: () => '',
                    yAxisWidth: chartMargin.right,
                    customDomain: ([min, max]) => [min, max * 2],
                  },
                ]}
                timeFrame={timeRange}
                tickFormatter={
                  timeRange === TIME_RANGES['3M']
                    ? (val) => formatDateTime(val, FORMAT.DATE, locale)
                    : undefined
                }
                isHasCartesianGrid={false}
                isShowOriginalXAxis={false}
                renderCustomTooltip={(data) => {
                  const contentTooltip = [
                    {
                      label:
                        formatDateTime(
                          data.tradingDate,
                          FORMAT.DATE,
                          locale,
                          timezone,
                        ) +
                        ' ' +
                        ([TIME_RANGES['1Y']].includes(timeRange)
                          ? ''
                          : formatDateTime(
                              data.tradingDate,
                              FORMAT.TIME_HOUR_MINUTE,
                              locale,
                              timezone,
                            )),
                      value: '',
                      styleLabel: { fontStyle: 'italic', fontSize: 10 },
                      styleValue: { fontStyle: 'italic', fontSize: 10 },
                    },
                    {
                      label: indexInfo.groupName + ':',
                      value: formatVal(data.indexValue),
                      styleLabel: { fontSize: 11, fontWeight: 400 },
                      styleValue: { fontSize: 11, fontWeight: 500 },
                    },
                    {
                      label: I18n.t(
                        'market.marketInDepth.indices.TOOLTIP_VOLUME',
                      ),
                      value:
                        formatVal(valDivMillion(data.matchVolume)) +
                        I18n.t('market.marketInDepth.indices.MILLION_PRICE'),
                      styleLabel: { fontSize: 11, fontWeight: 400 },
                      styleValue: { fontSize: 11, fontWeight: 500 },
                    },
                  ]
                  return <ContentTooltip contentTooltip={contentTooltip} />
                }}
              >
                <ReferenceLine
                  yAxisId="right"
                  y={0}
                  stroke="#555555"
                  label=""
                />
                <Bar
                  isAnimationActive={false}
                  yAxisId="right"
                  dataKey="matchVolume"
                  fill="#376691"
                />
                <Line
                  yAxisId="left"
                  dataKey="indexValue"
                  stroke="#007cff"
                  dot={false}
                  isAnimationActive={false}
                  activeDot={false}
                />
                <ReferenceLine
                  yAxisId="left"
                  y={openIndex}
                  stroke="#ffcc24"
                  label={(ctx) => (
                    <ReferencePrice
                      ctx={ctx}
                      value={openIndex}
                      yAxisWidth={chartMargin.left}
                    />
                  )}
                />
              </ChartContainer>
            )}
          </>
        )}
      </div>
    )
  },
)

ChartComponent.propTypes = {
  sizes: PropTypes.string.isRequired,
  chartContainerRef: PropTypes.object.isRequired,
  chartMargin: PropTypes.object.isRequired,
  setChartMargin: PropTypes.func.isRequired,
}

const PriceChart = ({ sizes, chartMargin, setChartMargin }) => {
  const chartContainerRef = useRef()

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <div className="h-100">
              <div
                ref={chartContainerRef}
                style={{ height: `calc(100% - ${size.height}px)` }}
              >
                <ChartComponent
                  chartContainerRef={chartContainerRef}
                  sizes={sizes}
                  chartMargin={chartMargin}
                  setChartMargin={setChartMargin}
                />
              </div>
            </div>
          )}
          <div className={style.footerPriceChart} />
        </>
      )}
    </SizeTracker>
  )
}

PriceChart.propTypes = {
  sizes: PropTypes.string.isRequired,
  chartMargin: PropTypes.object.isRequired,
  setChartMargin: PropTypes.func.isRequired,
}

export default PriceChart
