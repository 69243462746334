import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Table } from '../../../../common/table'
import { indicatorBalanceSheetIns1 } from '../../filter/constants'
import {
  selectBalanceSheetValue,
  selectDataFDF,
  selectItemBalanceSheetData,
} from '../../filter/store/slice'
import { FirstColumnCell } from '../FirstColumnCell'
import { OtherColumnsCell } from '../OtherColumnsCell'
import style from './style.module.css'

const BalanceSheetNextTable = () => {
  const data = useSelector(selectDataFDF)

  return (
    <div style={{ height: 1000 }} className={style.balanceSheetNextTable}>
      <Table
        ids={indicatorBalanceSheetIns1.map((item) => item.index)}
        columnDraggable={false}
        getDataFromRedux={selectBalanceSheetValue}
        isLoading={false}
        rowDraggable={false}
        renderHeader={() => <></>}
        schema={data.balanceSheet.groupColumns.map((item, index) => {
          const title = I18n.t(item.title.toString())
          const colId = item.key
          const result = {
            colId,
            title,
          }
          if (index === 0) {
            return {
              ...result,
              isI18n: true,
              thStyle: {
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: 9,
              },
              canCustomTd: true,
              render: (val, rowId, { className, style }) => {
                return (
                  <FirstColumnCell
                    val={val}
                    rowId={rowId}
                    className={className}
                    selectItem={selectItemBalanceSheetData}
                    style={{
                      ...style,
                      width: '33%',
                    }}
                  />
                )
              },
            }
          }
          return {
            ...result,
            isI18n: true,
            thStyle: {
              textAlign: 'right',
              fontWeight: 'bold',
              fontSize: 9,
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              return (
                <OtherColumnsCell
                  val={val}
                  rowId={rowId}
                  colIndex={index}
                  numCol={data.inComeStatement.groupColumns.length}
                  props={props}
                />
              )
            },
          }
        })}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        hasTooltip={false}
      />
    </div>
  )
}

BalanceSheetNextTable.propTypes = {}

export default BalanceSheetNextTable
