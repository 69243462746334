export const MONTH_OPTIONS = [
  { name: 'economy.monetary.monetary.TH1', value: 1 },
  { name: 'economy.monetary.monetary.TH2', value: 2 },
  { name: 'economy.monetary.monetary.TH3', value: 3 },
  { name: 'economy.monetary.monetary.TH4', value: 4 },
  { name: 'economy.monetary.monetary.TH5', value: 5 },
  { name: 'economy.monetary.monetary.TH6', value: 6 },
  { name: 'economy.monetary.monetary.TH7', value: 7 },
  { name: 'economy.monetary.monetary.TH8', value: 8 },
  { name: 'economy.monetary.monetary.TH9', value: 9 },
  { name: 'economy.monetary.monetary.TH10', value: 10 },
  { name: 'economy.monetary.monetary.TH11', value: 11 },
  { name: 'economy.monetary.monetary.TH12', value: 12 },
]

const currentYear = new Date().getFullYear()
const MIN_YEAR_DEFAULT = 2008
export const YEAR_OPTIONS = [
  ...Array(currentYear - MIN_YEAR_DEFAULT + 1).keys(),
].map((item) => {
  return {
    name: `${currentYear - item}`,
    value: currentYear - item,
  }
})

export const EMPTY_ROW = [13, 26]
