import { useDispatch, useSelector } from 'react-redux'
import { selectListDashboard, selectMyChartLibrary } from '../store/slice'
import { getChartByDashboardId, getMyLibraryData } from '../store/thunk'

export const useCallChartAllDB = () => {
  const dispatch = useDispatch()
  const listDashboard = useSelector(selectListDashboard)
  const myChartLibrary = useSelector(selectMyChartLibrary)

  const callData = () => {
    if (listDashboard.length) {
      const payload = listDashboard.map((item) => ({
        id: item.id,
        chartIncluded: true,
      }))
      dispatch(getChartByDashboardId(payload))
    }

    if (Object.values(myChartLibrary).length) {
      dispatch(
        getMyLibraryData({ dashboardId: myChartLibrary.id, isTemplate: false }),
      )
    }
  }

  return { callData }
}
