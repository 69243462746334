import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { LIST_COLOR } from '../../retailService/constants'
import { LIMIT_FIRST_RENDER, LIMIT_RENDER, tabConstants } from '../constants'
import {
  convertDataTable,
  getMonthAndYearInDataRange,
  getTableColumnTitle,
} from '../helpers'
import { getConsumptionDataTable, getConsumptionDataTableNew } from './thunk'

const initialState = {
  data: [],
  prevData: [],
  dataById: {},
  ids: [],
  groupColumns: [],
  loading: {
    loadingTable: false,
    loadingScroll: false,
  },
  timeType: tabConstants.MONTHLY,
  minTimeCallApi: {},
  maxTimeCallApi: {},
  listSelectOption: [],
  activeSelectOption: 0,
  dataIDs: [],
  listTitleChartDetails: [],
  countLimit: 0,
}

export const slice = createSlice({
  name: 'economy/productionAndConsumption/consumption/table',
  initialState,
  reducers: {
    changeTimeType: (state, action) => {
      state.timeType = action.payload
    },
    changeMinTimeCallApi: (state, action) => {
      state.minTimeCallApi = action.payload
    },
    resetData: (state, action) => {
      state.minTimeCallApi = {}
      state.maxTimeCallApi = {}
      state.data = []
      state.prevData = []
      state.groupColumns = []
      state.ids = []
      state.dataById = {}
      state.countLimit = 0
    },
    changeDataType: (state, action) => {
      state.activeSelectOption = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConsumptionDataTable.pending, (state) => {
      state.loading.loadingTable = true
    })

    builder.addCase(getConsumptionDataTable.fulfilled, (state, action) => {
      const totalData = [...action.payload?.data, ...state.prevData]
      const sortRawData = [...totalData].sort((a, b) => {
        return (
          moment().set({ year: a.realYear, month: a.realMonth }).unix() -
          moment().set({ year: b.realYear, month: b.realMonth }).unix()
        )
      })
      const listIdTitle = action.payload.title
        .map((item, index) => [
          item.retailVNTypeId,
          `growth${item.retailVNTypeId}`,
        ])
        .flat()

      state.ids = listIdTitle
      const data = convertDataTable(
        sortRawData,
        action.payload.title,
        listIdTitle,
      )
      state.data = data
      state.dataById = keyBy(data, 'id')
      const groupColumns = [{ key: 'name', title: '' }]
      getMonthAndYearInDataRange(totalData)?.forEach((item) => {
        groupColumns.push({
          key: getTableColumnTitle(item),
          title: getTableColumnTitle(item),
        })
      })
      state.groupColumns = groupColumns
      const monthYearDataRange = getMonthAndYearInDataRange(totalData)
      state.minTimeCallApi = monthYearDataRange[0]

      state.countLimit = LIMIT_FIRST_RENDER
      state.maxTimeCallApi = monthYearDataRange[monthYearDataRange.length - 1]

      state.prevData = [...action.payload.data, ...state.prevData]
      state.listSelectOption = action.payload?.title.map((item) => ({
        value: item.retailVNTypeId,
        name: item.retailVNTypeName,
      }))
      state.activeSelectOption = state.listSelectOption[0].value
      state.dataIDs = action.payload.title

      const titleChart = action.payload.chartDetails.map((item, index) => ({
        id: item.retailVNTypeId,
        color: LIST_COLOR[index],
        name: item.retailVNTypeName,
      }))
      state.listTitleChartDetails = titleChart
      state.loading.loadingTable = false
    })

    builder.addCase(getConsumptionDataTable.rejected, (state, action) => {
      state.loading.loadingTable = false
    })
    // Scroll Left
    builder.addCase(getConsumptionDataTableNew.pending, (state) => {
      state.loading.loadingScroll = true
    })

    builder.addCase(getConsumptionDataTableNew.fulfilled, (state, action) => {
      const totalData = [...(action.payload || []), ...state.prevData]
      const sortRawData = [...totalData].sort((a, b) => {
        return (
          moment().set({ year: a.realYear, month: a.realMonth }).unix() -
          moment().set({ year: b.realYear, month: b.realMonth }).unix()
        )
      })
      const data = convertDataTable(sortRawData, state.dataIDs, state.ids)
      state.data = data
      state.dataById = keyBy(data, 'id')
      const groupColumns = [{ key: 'name', title: '' }]
      getMonthAndYearInDataRange(totalData)?.forEach((item) => {
        groupColumns.push({
          key: getTableColumnTitle(item),
          title: getTableColumnTitle(item),
        })
      })
      state.groupColumns = groupColumns
      state.minTimeCallApi = getMonthAndYearInDataRange(totalData)[0]
      state.prevData = [...(action.payload || []), ...state.prevData]
      state.countLimit += LIMIT_RENDER
      state.loading.loadingScroll = false
    })

    builder.addCase(getConsumptionDataTableNew.rejected, (state, action) => {
      state.loading.loadingScroll = false
    })
  },
})

export const selectData = (state) => state[slice.name].data
export const selectDataIds = (state) => state[slice.name].ids
export const selectDataByIdValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectLoading = (state) => state[slice.name].loading.loadingTable
export const selectLoadingScroll = (state) =>
  state[slice.name].loading.loadingScroll
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectDataById = (state) => state[slice.name].dataById
export const selectTimeType = (state) => state[slice.name].timeType
export const selectMinTimeCallApi = (state) => state[slice.name].minTimeCallApi
export const selectMaxTimeCallApi = (state) => state[slice.name].maxTimeCallApi
export const selectListSelectOption = (state) =>
  state[slice.name].listSelectOption
export const selectActiveSelectOption = (state) =>
  state[slice.name].activeSelectOption
export const selectListTitleChartDetails = (state) =>
  state[slice.name].listTitleChartDetails
export const selectCountLimit = (state) => state[slice.name].countLimit

export const {
  changeTimeType,
  changeMinTimeCallApi,
  resetData,
  changeDataType,
} = slice.actions

register(slice.name, slice.reducer)
