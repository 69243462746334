import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import PopupEvent from '../../../../../common/popup/PopupEvent'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  selectIdsCompany,
  updaetListIdSave,
  updateListId,
  updateListNodeHide,
} from '../../corporateInfo/store/slice'
import FurtherInformation from './FurtherInformation'
import OwnershipInput from './OwnershipInput'
import Position from './Position'
import Relations from './Relations'
import ShareHolders from './ShareHolders'
import { selectFromPercentage, selectToPercentage } from './store/slice'
import { getDataThunk } from './store/thunk'

const FilterOption = () => {
  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)
  const locale = useSelector((state) => state.i18n.locale)
  const FromPercentage = useSelector(selectFromPercentage)
  const ToPercentage = useSelector(selectToPercentage)
  const [visible, setvisible] = useState()
  const idCompany = useSelector(selectIdsCompany)

  useEffect(() => {
    if (basicInfo?.organizationId) {
      dispatch(
        getDataThunk({
          OrganizationId: basicInfo.organizationId,
          FromPercentage: FromPercentage / 100,
          ToPercentage: ToPercentage / 100,
        }),
      )
    }
  }, [basicInfo.organizationId, FromPercentage, ToPercentage, locale])

  const openPopup = (value) => {
    setvisible(value)
  }

  const changeConfirmPopup = () => {
    dispatch(updaetListIdSave(idCompany))
    dispatch(updateListId(idCompany))
    dispatch(updateListNodeHide([]))
    const { cb } = visible
    if (cb) cb()
    setvisible({
      value: false,
    })
  }

  return (
    <div>
      <ShareHolders openPopup={openPopup} />
      <OwnershipInput openPopup={openPopup} />
      <Position openPopup={openPopup} />
      <Relations openPopup={openPopup} />
      <FurtherInformation openPopup={openPopup} />
      <PopupEvent
        isShow={visible?.value || false}
        title={I18n.t('bond.interconnectionMap.common.titlePopup')}
        message={I18n.t('bond.interconnectionMap.common.contentPopup')}
        handleClose={() =>
          setvisible({
            value: false,
            cb: null,
          })
        }
        btnCancel={{
          btnName: I18n.t('bond.interconnectionMap.common.btnCancel'),
          event: () => {
            setvisible({
              value: false,
              cb: null,
            })
          },
        }}
        btnSubmit={{
          btnName: I18n.t('bond.interconnectionMap.common.btnConfirm'),
          event: () => changeConfirmPopup(),
        }}
      />
    </div>
  )
}

export default FilterOption
