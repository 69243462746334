import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { uuid } from '../../../../utils/Common'
import { maxCondition } from '../../constant'
import style from '../../index.module.css'
import {
  selectAddedPeriod,
  selectAddMoreConditionByIds,
  selectAddMoreConditionIds,
  selectConditions,
  selectLastDataApply,
} from '../../store/slice'
import { getResultThunk } from '../../store/thunk'

const ButtonSubmit = ({ handleHidePopup }) => {
  const dispatch = useDispatch()

  const lastDataApply = useSelector(selectLastDataApply)
  const addMoreConditionIds = useSelector(selectAddMoreConditionIds)
  const addMoreConditionByIds = useSelector(selectAddMoreConditionByIds)
  const addedPeriod = useSelector(selectAddedPeriod)
  const conditions = useSelector(selectConditions)

  const handleApplyCondition = () => {
    const addedInformations = []
    addMoreConditionIds.forEach((id, index) => {
      if (addMoreConditionByIds[id].checked) {
        addedInformations.push({
          indicatorId: addMoreConditionByIds[id].indicatorId,
          tableCode: addMoreConditionByIds[id].tableCode,
          tableFieldCode: addMoreConditionByIds[id].tableFieldCode,
          id: uuid(),
          order: index + 1,
        })
      }
    })
    handleHidePopup()
    dispatch(
      getResultThunk({
        ...lastDataApply,
        addedInformations,
        addedPeriod: addedInformations.length > 0 ? addedPeriod : {},
      }),
    )
  }

  const lengthConditions = Object.keys(conditions).reduce((result, key) => {
    return (result += conditions[key].length)
  }, 0)
  const lengthAddMoreCondition = addMoreConditionIds.filter(
    (indicatorId) => addMoreConditionByIds[indicatorId].checked,
  ).length
  const totalCondition = lengthConditions + lengthAddMoreCondition
  const isDisableApply = totalCondition > maxCondition

  return (
    <Button
      style={{ fontSize: 11 }}
      onClick={handleApplyCondition}
      disabled={isDisableApply}
      className={`btn btn-blue w-80 h-20 ${
        isDisableApply ? style.disabled : ''
      }`}
    >
      <Translate value="tool.smartScreening.APPLY" />
    </Button>
  )
}

ButtonSubmit.propTypes = {
  handleHidePopup: PropTypes.func.isRequired,
}

export default ButtonSubmit
