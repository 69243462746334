import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { MARGIN_RECHARTS } from '../../../../../common/chart/constants'
import { StackedBarLineChart } from '../../../../../common/chart/customCharts/StackedBarLineChart'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { TYPE_IMPORT_EXPORT } from '../../constants'
import {
  selectActiveItem,
  selectImportExportDataType,
  selectLoadingTable,
  selectOrigParentCategory,
  selectTypeFilter,
} from '../../exportImportTable/store/slice'
import style from '../../index.module.css'
import { COLOR_STRUCTURE_CHART } from '../constants'
import {
  getExportContributeByProduct,
  getImportContributeByProduct,
  getMaxDateDetail,
} from '../store/thunk'
import { getCountryNameById, getStructureChartName } from './helper'
import {
  selectCurrentMonth,
  selectCurrentYear,
  selectDataChart,
  selectIds,
  selectLoading,
  selectMaxDate,
  selectOrigData,
} from './store/slice'

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 80, right: 20 }
const PADDING_BOTTOM_X_AXIS_KEY = 20

export const StructureChart = ({ width, height }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading)
  const tableLoading = useSelector(selectLoadingTable)
  const dataChart = useSelector(selectDataChart)
  const origData = useSelector(selectOrigData)
  const ids = useSelector(selectIds)
  const currentMonth = useSelector(selectCurrentMonth)
  const currentYear = useSelector(selectCurrentYear)
  const currentTypeFilter = useSelector(selectTypeFilter)
  const importExportDataType = useSelector(selectImportExportDataType)
  const activeCommodity = useSelector(selectActiveItem)
  const origCategories = useSelector(selectOrigParentCategory)
  const maxDate = useSelector(selectMaxDate)
  const locale = useSelector((state) => state.i18n.locale)

  const isImportData = importExportDataType === TYPE_IMPORT_EXPORT.IMPORT

  const activeCategoryId =
    activeCommodity?.vnTypeId ?? origCategories[0]?.vnTypeId

  const activeName = origCategories?.find(
    (item) => item.vnTypeId === activeCategoryId,
  )?.name

  const fillColor = {}
  const mappingDisplayName = {}
  for (const id of ids) {
    fillColor[id] = COLOR_STRUCTURE_CHART[ids.indexOf(id)]
    mappingDisplayName[id] = getCountryNameById(origData, id)
  }

  useEffect(() => {
    dispatch(
      getMaxDateDetail({
        ExImConstant: importExportDataType,
      }),
    )
  }, [importExportDataType])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.IMPORT &&
      activeCategoryId &&
      maxDate
    ) {
      dispatch(
        getImportContributeByProduct({
          DataType: currentTypeFilter,
          Year: currentYear ?? maxDate?.realYear,
          Month: currentMonth ?? maxDate?.realMonth,
          VNTypeId: activeCategoryId,
        }),
      )
    }
  }, [
    currentMonth,
    currentYear,
    activeCategoryId,
    currentTypeFilter,
    maxDate,
    locale,
  ])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.EXPORT &&
      activeCategoryId &&
      maxDate
    ) {
      dispatch(
        getExportContributeByProduct({
          DataType: currentTypeFilter,
          Year: currentYear ?? maxDate?.realYear,
          Month: currentMonth ?? maxDate?.realMonth,
          VNTypeId: activeCategoryId,
        }),
      )
    }
  }, [
    currentMonth,
    currentYear,
    activeCategoryId,
    currentTypeFilter,
    maxDate,
    locale,
  ])

  if (isLoading || tableLoading) {
    return <Loading />
  }

  if (!dataChart.length) {
    return <NoData />
  }

  return (
    <SizeTracker>
      {(size) => (
        <div className="h-100">
          <div className={[style.titleHeader, 'canvas-chart-title'].join(' ')}>
            {getStructureChartName(
              locale,
              isImportData,
              activeName,
            ).toUpperCase()}
          </div>
          {size.height && (
            <div
              style={{
                height: height - size.height - PADDING_BOTTOM_X_AXIS_KEY,
              }}
            >
              <StackedBarLineChart
                data={dataChart}
                width={width}
                height={height - size.height - PADDING_BOTTOM_X_AXIS_KEY}
                keyXAxis="time"
                barKeys={ids}
                lineKeys={[]}
                decimalLength={2}
                leftLabel={`(${I18n.t(
                  'economy.importExport.importExport.MILLION_USD',
                )})`}
                fillColor={fillColor}
                mappingDisplayName={mappingDisplayName}
                margin={MARGIN}
                timeFrame={TIME_RANGES.CUSTOM}
                isNotFormatXAxis={true}
                isFooterResize
                unit={I18n.t('economy.importExport.importExport.MILLION_USD')}
                listCheckbox={ids}
              />
            </div>
          )}
        </div>
      )}
    </SizeTracker>
  )
}
