export const heatmap = {
  FILTER: 'Lọc',
  EXCHANGE: 'Sàn',
  SIZE_BY: 'Kích thước',
  CRITERIA: 'Tiêu chí',
  exchanges: {
    ALL: 'Toàn thị trường',
    HOSE: 'HOSE',
    HNX: 'HNX',
    UPCOM: 'UPCOM',
  },
  sizeByes: {
    MARKET_CAPITALIZATION: 'Vốn hóa',
    TOTAL_VOLUME: 'Tổng khối lượng',
    TOTAL_VALUE: 'Tổng giá trị',
    FOREIGN_SELL_VOLUME: 'Khối lượng NN bán',
    FOREIGN_BUY_VOLUME: 'Khối lượng NN mua',
    FOREIGN_SELL_VALUE: 'Giá trị NN bán',
    FOREIGN_BUY_VALUE: 'Giá trị NN mua',
  },
  criterias: {
    '1_DAY_PERFORMANCE': 'Biến động 1 ngày',
    '1_WEEK_PERFORMANCE': 'Biến động 1 tuần',
    '1_MONTH_PERFORMANCE': 'Biến động 1 tháng',
    '3_MONTHS_PERFORMANCE': 'Biến động 3 tháng',
    '6_MONTHS_PERFORMANCE': 'Biến động 6 tháng',
    '1_YEAR_PERFORMANCE': 'Biến động 1 năm',
    YTD_PERFORMANCE: 'Biến động YTD',
    RELATIVE_VOLUME: 'Khối lượng tương đối',
    'P/E': 'P/E',
    'P/B': 'P/B',
  },
  listTickerHeaderTable: {
    TICKER: 'MÃ',
    PRICE: 'GIÁ',
    MarketCap: 'VỐN HÓA (Tỷ)',
    TotalVolume: 'KHỐI LƯỢNG (Nghìn CP)',
    TotalValue: 'GIÁ TRỊ (Tr.)',
    ForeignTotalSellVolume: 'KLNN BÁN (Nghìn CP)',
    ForeignTotalBuyVolume: 'KLNN MUA (Nghìn CP)',
    ForeignTotalSellValue: 'GTNN BÁN (Tr.)',
    ForeignTotalBuyValue: 'GTNN MUA (Tr.)',
    OneDayPerformance: '% THAY ĐỔI 1D',
    OneWeekPerformance: '% THAY ĐỔI 1W',
    OneMonthPerformance: '% THAY ĐỔI 1M',
    ThreeMonthsPerformance: '% THAY ĐỔI 3M',
    SixMonthsPerformance: '% THAY ĐỔI 6M',
    OneYearPerformance: '% THAY ĐỔI 1Y',
    YTDPerformance: '% THAY ĐỔI YTD',
    RelativeVolume: 'KHỐI LƯỢNG TƯƠNG',
    PE: 'P/E',
    PB: 'P/B',
  },
}
