import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import SelectCustom from '../../../common/selectCustom'
import { SELECT_TYPE_TOP_MOVER } from '../constants'
import { changeFilterTopMover, selectFilter } from '../store/slice'

const FilterTop = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilter)
  const locale = useSelector((state) => state.i18n.locale)

  const handleChangeTopTypes = (val) => {
    dispatch(changeFilterTopMover(val))
  }

  return (
    <div className="mb-40">
      <span style={{ fontSize: 11, opacity: 0.4 }}>
        <Translate value="market.topMover.TOP" />
      </span>
      <div className="mt-8">
        <SelectCustom
          key={locale}
          value={filter.top}
          isI18n={true}
          selectData={Object.keys(SELECT_TYPE_TOP_MOVER).map((key) => ({
            name: SELECT_TYPE_TOP_MOVER[key].text,
            value: key,
          }))}
          handleChange={handleChangeTopTypes}
        />
      </div>
    </div>
  )
}

export default FilterTop
