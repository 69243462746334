import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getNews, getReports } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/steel/overviewSteel/newsAndReport',
  initialState: {
    isLoading: false,
    dataNews: [],
    dataReports: [],
    isNews: true,
  },
  reducers: {
    changeIsNewsOrReports: (state, action) => {
      state.isNews = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNews.pending, (state) => {
      state.isLoading = true
      state.dataNews = []
    })
    builder.addCase(getNews.fulfilled, (state, action) => {
      state.isLoading = false
      const data = action.payload || []
      state.dataNews = data
    })
    builder.addCase(getNews.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getReports.pending, (state) => {
      state.isLoading = true
      state.dataReports = []
    })
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.isLoading = false
      const data = action.payload || []
      state.dataReports = data
    })
    builder.addCase(getReports.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataNews = (state) => state[slice.name].dataNews
export const selectDataReports = (state) => state[slice.name].dataReports
export const selectIsNews = (state) => state[slice.name].isNews

export const { changeIsNewsOrReports } = slice.actions

register(slice.name, slice.reducer)
