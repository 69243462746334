import { Logout } from './logout'
import PopupCompare from './market/marketInDepth/valuation/valuationChart/popupValuation'
import MyWatchlistPopup from './myWatchlist'
import MyWatchlistBondPopup from './myWatchlistBond'

import { Setting } from './setting'

const RootPopup = () => {
  return (
    <>
      <Setting />
      <MyWatchlistPopup />
      <MyWatchlistBondPopup />
      <Logout />
      <PopupCompare />
    </>
  )
}

export default RootPopup
