import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class CreateBondProxy extends ProxyBase {
  getBondById(params) {
    return this.get('GetUserBondInformation', params)
  }
  getAllBond(params) {
    return this.get('GetAllUserBondInformation', params)
  }
  createBond(params) {
    return this.post('InsertUserBondInformation', params)
  }
  updateBond(params) {
    return this.post('UpdateUserBondInformation', params)
  }
  deleteBond(params) {
    return this.post('DeleteUserBondInformation', params)
  }
}

export default new CreateBondProxy(ServiceProxyConfig.Bond.UserBond.ServiceUrl)
