import style from './index.module.css'
import PropTypes from 'prop-types'

export const Separator = ({ isVertical }) => {
  const getStyle = () => {
    return isVertical
      ? {
          width: 1,
          height: '100%',
        }
      : {
          width: '100%',
          height: 1,
        }
  }
  return <div className={style.container} style={getStyle()} />
}

Separator.propTypes = {
  isVertical: PropTypes.bool,
}

Separator.defaultProps = {
  isVertical: true,
}
