import { createAsyncThunk } from '@reduxjs/toolkit'
import PriceDataService from '../../../../../core/services/common/PriceDataService'
import DerivativeService from '../../../../../core/services/market/DerivativeService'
import { handleExport } from '../../../../utils/Export'

export const getSummary = createAsyncThunk(
  'market/derivation/GET_SUMMARY',
  async (params, { rejectWithValue }) => {
    const response = await DerivativeService.getSummary(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadSummary = createAsyncThunk(
  'market/derivation/DOWNLOAD_SUMMARY',
  async (data) => {
    const response = await DerivativeService.downloadSummary(data)
    handleExport(response.data, response.filename)
  },
)

export const getLatestPrice = createAsyncThunk(
  'market/derivation/GET_LATEST_PRICE',
  async (params, { rejectWithValue }) => {
    const response = await PriceDataService.getLatestPrice(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
