import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Collapse } from '../common/collapse'
import { Button } from '../common/html/Button'
import { Span } from '../common/html/Span'
import { Loading } from '../common/loading'
import style from './index.module.css'

export const SearchCollapseBlock = (props) => {
  const {
    children,
    id,
    title,
    blockData,
    open,
    onClickCollapse,
    onClickNextPage,
    onClickPrevPage,
  } = props

  return (
    <Collapse id={id} title={title} open={open} onToggle={onClickCollapse}>
      {children}
      {blockData.data.totalPage > 1 && (
        <div className="paging">
          <Button
            style={{ fontSize: 10 }}
            className={style['paging-button']}
            disabled={blockData.data.page === 1}
            onClick={onClickPrevPage}
          >
            <i className="icon-arrow-left" />
          </Button>
          <Span style={{ fontSize: 10 }} className={style['paging-text']}>
            <Translate value="search.fullSearch.PAGE" />
            <span>
              {' '}
              {blockData.data.page}/{blockData.data.totalPage}
            </span>
          </Span>
          <Button
            style={{ fontSize: 10 }}
            className={style['paging-button']}
            disabled={blockData.data.page === blockData.data.totalPage}
            onClick={onClickNextPage}
          >
            <i className="icon-arrow-right" />
          </Button>
        </div>
      )}
      {blockData.loading && (
        <>
          {!!blockData.data.items.length ? (
            <Loading />
          ) : (
            <div className="search-content">
              <Loading />
            </div>
          )}
        </>
      )}
    </Collapse>
  )
}

SearchCollapseBlock.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  blockData: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClickCollapse: PropTypes.func.isRequired,
  onClickNextPage: PropTypes.func.isRequired,
  onClickPrevPage: PropTypes.func.isRequired,
}
