import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'

export const OVERVIEW_TABS = {
  // ASSET_ALLOCATION: 'assetAllocation',
  PERFORMANCE: 'performance',
}

export const PERFORMANCE_FUND_FLOW_TIME_RANGES = [
  {
    title: '3M',
    value: TIME_RANGES.ThreeMonths,
  },
  {
    title: '6M',
    value: TIME_RANGES.SixMonths,
  },
  {
    title: 'YTD',
    value: TIME_RANGES.YearToDate,
  },
  {
    title: '1Y',
    value: TIME_RANGES.OneYear,
  },
]

export const PERFORMANCE_NAV_CCQ_TIME_RANGES = [
  {
    title: '6M',
    value: TIME_RANGES.SixMonths,
  },
  {
    title: 'YTD',
    value: TIME_RANGES.YearToDate,
  },
  {
    title: '1Y',
    value: TIME_RANGES.OneYear,
  },
  {
    title: '3Y',
    value: TIME_RANGES.ThreeYears,
  },
]

export const VALUE_TYPES = {
  RELATIVE: 'relative',
  ABSOLUTE: 'absolute',
}

export const X_AXIS_KEY = 'endDateId'
