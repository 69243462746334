import ValuationProxy from '../../../proxies/market/marketInDepth/ValuationProxy'
import { ServiceBase } from '../../ServiceBase'

export class ValuationService extends ServiceBase {
  getValuation(params) {
    return this.getData(() => ValuationProxy.getValuation(params))
  }

  getCompareValuation(params) {
    return this.getData(() => ValuationProxy.getCompareValuation(params))
  }
}

export default new ValuationService()
