import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoading } from '../panelPreview/store/slice'
import style from './style.module.css'

export const CheckboxAll = ({
  dataId,
  dataById,
  onRemoveItem,
  onCheckAllItem,
  isMaxCheck,
}) => {
  const dispatch = useDispatch()

  // Use selector
  const loading = useSelector(selectLoading)

  // Get data
  const haveCheckedItems = useMemo(() => {
    if (!dataById) {
      return false
    }
    return Object.values(dataById).some((item) => item.isCheck)
  }, [dataById])

  // Actions
  const handleDelete = () => {
    if (!loading) {
      dispatch(onRemoveItem())
    }
  }

  const handleCheckAll = () => {
    if (!loading && !isMaxCheck) {
      dispatch(onCheckAllItem(true))
    }
  }

  const handleUncheckAll = () => {
    if (!loading) {
      dispatch(onCheckAllItem(false))
    }
  }

  return (
    <div>
      <div className="group-btn-action d-flex justify-content-center ali-center">
        {!haveCheckedItems ? (
          <label className="d-flex justify-content-center">
            <input
              type="checkbox"
              className={`checkbox line ${style.checkboxAll}`}
              readOnly
              disabled={!dataId?.length || loading || isMaxCheck}
              onClick={handleCheckAll}
            />
          </label>
        ) : (
          <div className="p-0 centerItems">
            <i
              className={`icon-minus-stroke ${style.btnUncheck}`}
              onClick={handleUncheckAll}
            />
          </div>
        )}
        <div className={style.verticalLine}></div>
        <button type="button" onClick={handleDelete}>
          <i className="icon-trash" />
        </button>
      </div>
    </div>
  )
}
