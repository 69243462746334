import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MacroService from '../../../../../core/services/common/MacroService'
import FinancialDataService from '../../../../../core/services/corporate/FinancialDataService'
import { handleExport } from '../../../../utils/Export'

export const getFinancialDataBalanceSheet = createAsyncThunk(
  'corporate/financialData/financialStatement/GET_FINANCIAL_DATA_BALANCE_SHEET',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getFinancialDataBalanceSheet(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFinancialDataIncomeStatement = createAsyncThunk(
  'corporate/financialData/financialStatement/GET_FINANCIAL_DATA_INCOME_STATEMENT',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialDataService.getFinancialDataIncomeStatement(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFinancialDataCashFlow = createAsyncThunk(
  'corporate/financialData/financialStatement/GET_FINANCIAL_DATA_CASH_FLOW',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getFinancialDataCashFlow(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFinancialDataNote = createAsyncThunk(
  'corporate/financialData/financialStatement/GET_FINANCIAL_DATA_NOTE',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getFinancialDataNote(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndicatorMapping = createAsyncThunk(
  'corporate/financialData/financialStatement/GET_INDICATOR_MAPPING',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getIndicatorMapping(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadFinancialData = createAsyncThunk(
  'corporate/financialData/financialStatement/DOWNLOAD_FINANCIAL_DATA',
  async (data) => {
    const response = await FinancialDataService.downloadFinancialData(data)
    handleExport(response.data, response.filename)
  },
)

export const getExchangeRates = createAsyncThunk(
  'corporate/financialData/financialStatement/GET_EXCHANGE_RATES',
  async (params, { rejectWithValue }) => {
    const response = await MacroService.getExchangeRates(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
