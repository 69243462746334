import GeneralSettingProxy from '../../../proxies/bond/bondSecurityProxy/generalSettingProxy'
import { ServiceBase } from '../../ServiceBase'

export class GeneralSetting extends ServiceBase {
  getDataSecurity(params) {
    return this.getData(() => GeneralSettingProxy.getDataProxy(params))
  }
  exportFile(params) {
    return this.getFileDownload(() =>
      GeneralSettingProxy.exportFileProxy(params),
    )
  }
}

export default new GeneralSetting()
