export const common = {
  MORE_SETTING: 'Cài đặt khác',
  label: {
    SETTING: 'Cài đặt',
    GENERAL: 'Cài đặt chung',
    PROFILE: 'Cài đặt hồ sơ',
  },
  validate: {
    VALIDATION_EMAIL_FORMAT: 'Vui lòng nhập email hợp lệ',
    VALIDATION_EMAIL_REQUIRED: 'Trường Email là bắt buộc.',
    VALIDATION_PASSWORD_INCORRECT: 'Mật khẩu không chính xác',
    VALIDATE_RECENT_PASSWORD_REQUIRED: 'Trường Mật khẩu gần đây là bắt buộc.',
    VALIDATE_NEW_PASSWORD_REQUIRED: 'Các trường Yêu cầu mật khẩu mới.',
    VALIDATE_CONFIRM_NEW_PASSWORD_REQUIRED:
      'Trường Xác nhận mật khẩu mới là bắt buộc.',
    VALIDATE_PASSWORD_DOES_NOT_MATCH: 'Mật khẩu không khớp',
    VALIDATION_PHONE_REQUIRED: 'Số điện thoại là trường bắt buộc.',
    VALIDATION_NAME_REQUIRED: 'Tên là trường bắt buộc.',
    VALIDATION_CHARACTER_MINIUM_PASSWORD: 'Tối thiểu 8 ký tự',
    VALIDATION_NO_SPECIAL_CHARACTERS: 'Không có ký tự đặc biệt',
    VALIDATION_AT_LEAST_1_NUMBER: 'Ít nhất 1 số',
  },
}
