import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import Popup from '../../../../common/popup'
import { SizeTracker } from '../../../../common/sizeTracker'
import Filter from './Filter'
import Footer from './FooterPopup'
import TableCompany from './TableCompany'
import {
  filterByText,
  selectListIdSave,
  selectListIdSelect,
  updateListId,
  updateVisible,
} from './store/slice'

const height = 542

const BusinessInformation = () => {
  const dispatch = useDispatch()
  const idsSelect = useSelector(selectListIdSelect)
  const idsSave = useSelector(selectListIdSave)

  const handleClosePopup = () => {
    dispatch(filterByText(''))
    const check = idsSelect.some((e) => idsSave.includes(e))
    if (
      idsSelect.length !== idsSave.length ||
      (idsSelect.length === idsSave.length && !check)
    ) {
      dispatch(updateListId(idsSave))
    }
    dispatch(updateVisible(false))
  }
  return (
    <Popup>
      <div className="modal modal-medium" style={{ width: 584 }}>
        <div className="modal-title">
          <h3 className="text-up">
            <Translate value="bond.interconnectionMap.businessInformation.title" />
          </h3>
          <a onClick={() => handleClosePopup()} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div
          className="modal-content"
          style={{ height: height, padding: '8px 0px 4px 0px' }}
        >
          <SizeTracker>
            {(size) => {
              return (
                <>
                  <Filter />
                  {size.height && (
                    <div
                      style={{
                        height: `calc(100% - ${size.height}px)`,
                        width: size.width,
                      }}
                    >
                      <TableCompany />
                    </div>
                  )}
                  <Footer />
                </>
              )
            }}
          </SizeTracker>
        </div>
      </div>
    </Popup>
  )
}

export default BusinessInformation
