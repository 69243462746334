import { useDispatch, useSelector } from 'react-redux'
import iconTable from '../../icons/iconTable.svg'
import iconTornadoChartChoosed from '../../icons/iconTornadoChartChoosed.svg'
import { changeShowChart, selectIsShowChart } from './store/slice'

const ToggleChart = () => {
  const dispatch = useDispatch()
  const isShowChart = useSelector(selectIsShowChart)
  const handleClick = () => {
    dispatch(changeShowChart(!isShowChart))
  }

  return (
    <div
      style={{ marginRight: 8 }}
      className="cursor-pointer"
      onClick={handleClick}
    >
      <img
        src={isShowChart ? iconTable : iconTornadoChartChoosed}
        alt="iconTornadoChart"
        height={20}
      />
    </div>
  )
}

export default ToggleChart
