export const LASTEST_YEARS = [...Array(20).keys()].map((v) => {
  const year = new Date().getFullYear()
  return {
    name: String(year - v),
    value: String(year - v),
  }
})

export const QUARTERS = [
  {
    name: 'corporate.businessModel.penetrationAnalysis.QUARTER_1',
    value: 1,
  },
  {
    name: 'corporate.businessModel.penetrationAnalysis.QUARTER_2',
    value: 2,
  },
  {
    name: 'corporate.businessModel.penetrationAnalysis.QUARTER_3',
    value: 3,
  },
  {
    name: 'corporate.businessModel.penetrationAnalysis.QUARTER_4',
    value: 4,
  },
  {
    name: 'corporate.businessModel.penetrationAnalysis.ANNUAL',
    value: 5,
  },
]
