import React from 'react'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import { SizeTracker } from '../../common/sizeTracker'
import TopInfo from '../../common/topInfo/stockTopInfo'
import Content from './Content'

const CompanySnapshot = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <TopInfo />
            {(size.height || size.height === 0) && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <Content />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}

export default CompanySnapshot
