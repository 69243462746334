import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterWithScroll from '../../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import {
  removeCategoryFromList,
  selectListCategory,
} from '../balanceOfTrade/store/slice'
import { ChartComponent } from './ChartComponent'
import { Filter } from './Filter'
import { COLORS_LINE_CHART } from './constants'
import {
  changeActiveCategory,
  selectDataChart,
  selectLoading,
  selectTypeCode,
} from './store/slice'

export const GrowthCategory = ({ width, height, isFullScreen }) => {
  const dispatch = useDispatch()
  const headerRef = useRef()
  const legendPanel = useRef()

  // Use selector
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)
  const listCategory = useSelector(selectListCategory)
  const typeCode = useSelector(selectTypeCode)

  const [heightSizeTracker, setHeightSizeTracker] = useState()
  const [heightListCategory, setHeightListCategory] = useState(0)

  const getIdsInListCategory = listCategory?.map((item) => item.id)

  // Actions
  const handleDelete = (item) => {
    dispatch(removeCategoryFromList(item.id))
    dispatch(changeActiveCategory(null))
  }

  const handleHover = (item) => {
    dispatch(changeActiveCategory(item.id))
  }

  const handleUnHover = () => {
    dispatch(changeActiveCategory(null))
  }

  // Use effect
  useEffect(() => {
    if (headerRef.current?.offsetHeight && heightListCategory)
      setHeightSizeTracker(
        height - headerRef.current?.offsetHeight - heightListCategory,
      )
  }, [height, width, listCategory, heightListCategory])

  return (
    width &&
    height && (
      <>
        <div className="h-100">
          <Filter headerRef={headerRef} />
          {isLoading ? (
            <Loading />
          ) : !dataChart.length ? (
            <NoData />
          ) : (
            <div id="balanceOfTradeChart">
              <div>
                <ChartComponent
                  data={dataChart}
                  width={width}
                  height={heightSizeTracker || 0}
                  keyXAxis={'time'}
                  lineKeys={getIdsInListCategory}
                  typeCode={typeCode}
                />
              </div>
              <FooterWithScroll
                width={width}
                data={listCategory}
                idKey="id"
                nameKey="name"
                color={(item) => COLORS_LINE_CHART[item.colorId]}
                legendPanel={legendPanel}
                isFullScreen={isFullScreen}
                setHeightFooter={setHeightListCategory}
                onDelete={handleDelete}
                onHover={handleHover}
                onUnHover={handleUnHover}
              />
            </div>
          )}
        </div>
      </>
    )
  )
}
