import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { EXCHANGE_CODE } from '../filter/constant'
import { selectSelectedItems } from '../filter/store/slice'
import { downloadValuationDataTable } from './store/thunk'
import ValuationTable from './ValuationTable'

const PanelValuationTable = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const selectedItems = useSelector(selectSelectedItems)

  const downloadCsv = () => {
    return downloadValuationDataTable({
      exchangeCode: EXCHANGE_CODE.ALL_EXCHANGE,
      icbIds: selectedItems
        .filter((item) => !!item.icbId && !item.isDeleted)
        .map((item) => item.icbId),
      organizationIds: selectedItems
        .filter((item) => !!item.organizationId)
        .map((item) => item.organizationId),
    })
  }

  return (
    <Panel
      title="sector.sectorConstituents.valuation.DATA_TABLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <ValuationTable />
    </Panel>
  )
}

export default PanelValuationTable
