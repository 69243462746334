import { useMemo } from 'react'

export const lengthChartInLayout = {
  layout_1: 10,
  layout_2: 13,
  layout_3: 12,
  layout_4: 15,
}

const PANEL_BORDER = 4

const useGetLayoutDashboard = (layout, margin = 2, padding = 8) => {
  const widthCard = {
    '1/2_layout': `calc(100%/2 - ${2 * margin + 2 * padding + PANEL_BORDER}px)`,
    '1/3_layout': `calc(100%/3 - ${2 * margin + 2 * padding + PANEL_BORDER}px)`,
  }

  const LAYOUT_4 = [...new Array(15)].map((_, index) => ({
    index: index + 1,
    width: widthCard['1/3_layout'],
    positionCopyToLeft: (index + 1) % 3 === 0 ? true : false,
  }))

  const LAYOUT_2 = [
    { index: 1, width: widthCard['1/2_layout'] },
    { index: 2, width: widthCard['1/2_layout'] },
    { index: 3, width: widthCard['1/3_layout'], positionCopyToLeft: true },
    { index: 4, width: widthCard['1/3_layout'] },
    { index: 5, width: widthCard['1/3_layout'], positionCopyToLeft: true },
    { index: 6, width: widthCard['1/2_layout'] },
    { index: 7, width: widthCard['1/2_layout'], positionCopyToLeft: true },
    { index: 8, width: widthCard['1/3_layout'] },
    { index: 9, width: widthCard['1/3_layout'] },
    { index: 10, width: widthCard['1/3_layout'], positionCopyToLeft: true },
    { index: 11, width: widthCard['1/3_layout'] },
    { index: 12, width: widthCard['1/3_layout'] },
    { index: 13, width: widthCard['1/3_layout'], positionCopyToLeft: true },
  ]

  const LAYOUT_3 = [
    { index: 1, width: widthCard['1/2_layout'] },
    { index: 2, width: widthCard['1/2_layout'], positionCopyToLeft: true },
    { index: 3, width: widthCard['1/3_layout'] },
    { index: 4, width: widthCard['1/3_layout'] },
    { index: 5, width: widthCard['1/3_layout'], positionCopyToLeft: true },
    { index: 6, width: widthCard['1/2_layout'] },
    { index: 7, width: widthCard['1/2_layout'], positionCopyToLeft: true },
    { index: 8, width: widthCard['1/2_layout'] },
    { index: 9, width: widthCard['1/2_layout'], positionCopyToLeft: true },
    { index: 10, width: widthCard['1/3_layout'] },
    { index: 11, width: widthCard['1/3_layout'] },
    { index: 12, width: widthCard['1/3_layout'], positionCopyToLeft: true },
  ]

  const LAYOUT_1 = [
    { index: 1, width: widthCard['1/2_layout'] },
    { index: 2, width: widthCard['1/2_layout'], positionCopyToLeft: true },
    { index: 3, width: widthCard['1/2_layout'] },
    { index: 4, width: widthCard['1/2_layout'], positionCopyToLeft: true },
    { index: 5, width: widthCard['1/2_layout'] },
    { index: 6, width: widthCard['1/2_layout'], positionCopyToLeft: true },
    { index: 7, width: widthCard['1/2_layout'] },
    { index: 8, width: widthCard['1/2_layout'], positionCopyToLeft: true },
    { index: 9, width: widthCard['1/2_layout'] },
    { index: 10, width: widthCard['1/2_layout'], positionCopyToLeft: true },
  ]

  const mapLayout = {
    layout_1: LAYOUT_1,
    layout_2: LAYOUT_2,
    layout_3: LAYOUT_3,
    layout_4: LAYOUT_4,
  }

  const layoutDisplay = useMemo(() => {
    return mapLayout[layout] || LAYOUT_1
  }, [layout])

  return { layoutDisplay }
}

useGetLayoutDashboard.propTypes = {}

export default useGetLayoutDashboard
