import { createSlice, current } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { allIndicators, NUMBER_OF_PERIOD, VIEW_TYPE } from '../constants'
import { formatFinancialData, formatIndicatorMapping } from '../helper'
import { getTableData } from './thunk'

const { ids, tableConstants } = formatIndicatorMapping(allIndicators)

const initialState = {
  filter: {
    viewType: VIEW_TYPE.YEAR,
    period: NUMBER_OF_PERIOD[5].value,
    checkedIndicators: [],
  },
  indicators: allIndicators,
  ids: ids,
  valuesById: {},
  data: [],
  groupColumns: [],
  tableConstants: tableConstants,
  checkedTableConstants: tableConstants.filter((item) => item.tableFieldCode),
  levelCollapse: keyBy(
    tableConstants.map((v) => ({
      index: v.indicatorId,
      level: v.level,
      isRowCollapse: v.isCollapse,
    })),
    'index',
  ),
  isLoadingTable: false,
  isLoadingIndicators: false,
}

export const slice = createSlice({
  name: 'sector/sectorConstituents/financialAnalysis/corporate',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },
    changeCheckedTableConstants: (state, action) => {
      state.checkedTableConstants = action.payload
    },
  },
  extraReducers: (builder) => {
    //Table
    builder.addCase(getTableData.pending, (state) => {
      state.isLoadingTable = true
      state.groupColumns = []
    })
    builder.addCase(getTableData.fulfilled, (state, action) => {
      const tableConstants = current(state.tableConstants)
      const { groupColumns, valuesById } = formatFinancialData(
        tableConstants,
        action,
      )

      state.data = action.payload
      state.groupColumns = groupColumns
      state.valuesById = valuesById
      state.isLoadingTable = false
    })
    builder.addCase(getTableData.rejected, (state, action) => {
      state.isLoadingTable = action.payload.loading
    })
  },
})

export const selectViewType = (state) => state[slice.name].filter.viewType
export const selectPeriod = (state) => state[slice.name].filter.period
export const selectCheckedIndicators = (state) =>
  state[slice.name].filter.checkedIndicators
export const selectIndicators = (state) => state[slice.name].indicators
export const selectLoadingIndicators = (state) =>
  state[slice.name].isLoadingIndicators
export const selectIds = (state) => state[slice.name].ids
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectLevelCollapse = (state) => state[slice.name].levelCollapse
export const selectTableConstants = (state) => state[slice.name].tableConstants
export const selectValueById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].valuesById[id], attr)
export const selectData = (state) => state[slice.name].data
export const selectLoadingTable = (state) => state[slice.name].isLoadingTable
export const selectCheckedTableConstants = (state) =>
  state[slice.name].checkedTableConstants

export const { changeFilter, changeCheckedTableConstants } = slice.actions

register(slice.name, slice.reducer)
