import moment from 'moment'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../common/calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import { Icon } from '../../../../common/html/Icon'
import { getDateWithTimezone } from '../../helper'
import style from './index.module.css'

const DateTd = ({ value, handleChange, handleClose, rowId }) => {
  const year = new Date().getFullYear()

  const customHeader = HeaderOneCalendarRange({
    startYear: new Date(new Date().setFullYear(year - 10)).getFullYear(),
    endYear: new Date(new Date().setFullYear(year + 3)).getFullYear(),
  })

  return (
    <div className="form-control calendar mb-0">
      <div className={`${style.bgGrey} w-100`}>
        <CalendarPopper
          date={moment(value).isValid() ? getDateWithTimezone(value) : null}
          handleChange={(value) => handleChange(value, rowId)}
          customHeader={customHeader}
          CustomInput={InputDateCalendar}
          onClose={handleClose}
        />
        <Icon
          className="icon-date"
          style={{
            fontSize: 12,
            pointerEvents: 'none',
          }}
        />
      </div>
    </div>
  )
}

export default DateTd
