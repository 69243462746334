import { defaultLanguage, listLanguage } from '../../configs/Language'
import { FONT_SIZE_CONFIG } from '../../configs/Layout'
import { LOCAL_STORAGE_KEY } from '../constants/Common'

export const convertTimeZoneIdToOffset = (timeZoneSelect, listTimeZone) => {
  let offsetTimeZone = ''
  listTimeZone.forEach((element) => {
    if (element.timeZoneId === parseInt(timeZoneSelect)) {
      offsetTimeZone = element.offset
    }
  })
  return offsetTimeZone
}

export const convertFontSize = (fontSizeId) => {
  return Object.values(FONT_SIZE_CONFIG).reduce((result, el) => {
    if (el.value === parseInt(fontSizeId)) {
      result = el.key
    }
    return result
  }, getDefaultFontSize().key)
}

export const convertLanguage = (languageId) => {
  return Object.values(listLanguage).reduce((result, el) => {
    if (el.value === parseInt(languageId)) {
      result = el.key
    }
    return result
  }, defaultLanguage)
}

export const getDefaultFontSize = () => {
  return (
    FONT_SIZE_CONFIG[localStorage.getItem(LOCAL_STORAGE_KEY.KEY_FONT_SIZE)] ||
    FONT_SIZE_CONFIG.Large
  )
}
