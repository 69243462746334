import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  getAccMonthAndYearKey,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../../../common/helper'
import {
  DATA_TYPE_LIST,
  TIME_FILTER_TYPE,
} from '../../exportImportTable/constants'
import {
  TURN_OVER_MONTHLY_DATE_FILTER,
  TURN_OVER_YEARLY_DATE_FILTER,
} from '../constants'
import {
  getExportChangeTurnoverByProduct,
  getImportChangeTurnoverByProduct,
  getMaxDateDetail,
} from './thunk'

const slice = createSlice({
  name: 'economy/importExport/exportImportByCommodities/exportTurnOver',
  initialState: {
    isLoading: true,
    data: [],
    activeCategory: null,
    currentDateType: TURN_OVER_MONTHLY_DATE_FILTER[0].value,
    currentDateTypeYearly: TURN_OVER_YEARLY_DATE_FILTER[0].value,
    typeFilter: TIME_FILTER_TYPE.MONTHLY,
    locale: null,
    typeData: DATA_TYPE_LIST.VALUE,
    maxDate: null,
    disableLoading: false,
  },
  reducers: {
    changeActiveCategory: (state, action) => {
      state.activeCategory = action.payload
      state.disableLoading = false
    },
    handleCurrentDateType: (state, action) => {
      state.currentDateType = action.payload
      state.disableLoading = false
    },
    handleCurrentDateTypeYearly: (state, action) => {
      state.currentDateTypeYearly = action.payload
      state.disableLoading = false
    },
    changeTypeFilterForTurnOverChart: (state, action) => {
      state.typeFilter = action.payload
      state.disableLoading = false
    },
    changeLocaleForTurnOverChart: (state, action) => {
      state.locale = action.payload
      state.disableLoading = false
    },
    changeTypeDataForTurnOverChart: (state, action) => {
      state.typeData = action.payload
      state.disableLoading = false
    },
    setDisableLoading: (state, action) => {
      state.disableLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImportChangeTurnoverByProduct.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getImportChangeTurnoverByProduct.fulfilled,
      (state, action) => {
        const payloadData =
          action.payload?.map((item) => ({
            ...item,
            id: item.vnTypeId,
            name: item.vnTypeName,
            month: item.realMonth,
            year: item.realYear,
            value:
              state.typeData === DATA_TYPE_LIST.GROWTH
                ? item.growth
                  ? item.growth * 100
                  : null
                : item.value / 1000000,
          })) || []
        state.data = convertData(
          payloadData,
          state.typeFilter,
          state.locale,
          state.maxDate,
        ).reverse()
        state.isLoading = false
      },
    )
    builder.addCase(
      getImportChangeTurnoverByProduct.rejected,
      (state, action) => {
        state.isLoading = action.payload.loading
      },
    )
    builder.addCase(getExportChangeTurnoverByProduct.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getExportChangeTurnoverByProduct.fulfilled,
      (state, action) => {
        const payloadData =
          action.payload?.map((item) => ({
            ...item,
            id: item.vnTypeId,
            name: item.vnTypeName,
            month: item.realMonth,
            year: item.realYear,
            value:
              state.typeData === DATA_TYPE_LIST.GROWTH
                ? item.growth
                  ? item.growth * 100
                  : null
                : item.value / 1000000,
          })) || []
        state.data = convertData(
          payloadData,
          state.typeFilter,
          state.locale,
          state.maxDate,
        ).reverse()
        state.isLoading = false
      },
    )
    builder.addCase(
      getExportChangeTurnoverByProduct.rejected,
      (state, action) => {
        state.isLoading = action.payload.loading
      },
    )

    builder.addCase(getMaxDateDetail.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxDateDetail.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxDateDetail.rejected, (state) => {
      state.maxDate = null
    })
  },
})

export const selectCurrentDateType = (state) =>
  state[slice.name].currentDateType
export const selectCurrentDateTypeYearly = (state) =>
  state[slice.name].currentDateTypeYearly
export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectActiveCategory = (state) => state[slice.name].activeCategory
export const selectMaxYear = (state) => state[slice.name].maxDate
export const selectDisableLoading = (state) => state[slice.name].disableLoading

export const {
  changeActiveCategory,
  changeTypeFilterForTurnOverChart,
  handleCurrentDateType,
  handleCurrentDateTypeYearly,
  changeLocaleForTurnOverChart,
  changeTypeDataForTurnOverChart,
  setDisableLoading,
} = slice.actions

register(slice.name, slice.reducer)

const convertData = (data, typeFilter, locale, maxDate) => {
  //convert months and years to date keys
  const getDataKeyFromRange =
    typeFilter === TIME_FILTER_TYPE.MONTHLY
      ? getMonthAndYearInDataRange(data).map((item) =>
          getMonthAndYearKey(item.month, item.year),
        )
      : typeFilter === TIME_FILTER_TYPE.ACC_MONTHLY
      ? getMonthAndYearInDataRange(data).map((item) =>
          getAccMonthAndYearKey(item.month, item.year, locale),
        )
      : getYearInDataRange(data).map((item) =>
          getYearKey(item.year, maxDate, locale),
        )

  //get all cpiVNId and corresponding value from specified data range
  const dataWithMonthAndYear = (month, year) => {
    return data
      .filter((item) => item.month === month && item.year === year)
      .map((item) => ({
        value: item.value ?? EMPTY_VALUE,
        id: item.id,
      }))
  }

  const dataWithYear = (year) => {
    return data
      .filter((item) => item.year === year)
      .map((item) => ({
        value: item.value ?? EMPTY_VALUE,
        id: item.id,
      }))
  }

  const getDataValueFromRange =
    typeFilter !== TIME_FILTER_TYPE.YEARLY
      ? getMonthAndYearInDataRange(data).map((item) => [
          ...dataWithMonthAndYear(item.month, item.year),
        ])
      : getYearInDataRange(data).map((item) => [...dataWithYear(item.year)])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.id]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}
