export const STATE_BUDGET_CHART_TYPE = {
  BALANCE: 'Balance',
  REVENUE: 'Revenue',
  EXPENDITURE: 'Expenditure',
}

export const LEFT_FRAME_OPTIONS = [
  {
    name: 'economy.fiscal.stateBudget.BALANCE',
    value: STATE_BUDGET_CHART_TYPE.BALANCE,
  },
  {
    name: 'economy.fiscal.stateBudget.REVENUE',
    value: STATE_BUDGET_CHART_TYPE.REVENUE,
  },
  {
    name: 'economy.fiscal.stateBudget.EXPENDITURE',
    value: STATE_BUDGET_CHART_TYPE.EXPENDITURE,
  },
]

export const RADIO_VALUE = {
  VALUE: 'Value',
  PERCENT: 'Percent',
}

export const LIST_RADIO = [
  {
    value: RADIO_VALUE.VALUE,
    label: 'economy.fiscal.stateBudget.VALUE',
  },
  {
    value: RADIO_VALUE.PERCENT,
    label: 'economy.fiscal.stateBudget.PERCENT_VALUE',
  },
]

export const RIGHT_FRAME_OPTIONS = [
  { title: '5Y', value: 'FiveYears' },
  { title: '7Y', value: 'SevenYears' },
  { title: '10Y', value: 'TenYears' },
  { title: '20Y', value: 'TwentyYears' },
]

export const STACK_COLORS = {
  2: ['#185999', '#fff6df'],
  3: ['#185999', '#fff6df', '#fe7e6d'],
  4: ['#185999', '#3cabeb', '#fff6df', '#fe7e6d'],
  5: ['#185999', '#40abff', '#f6ad5c', '#a6cf98', '#fff6df'],
  6: ['#45b29d', '#facc5c', '#df5a49', '#185999', '#0096eb', '#ecffdf'],
}

export const LINE_COLOR = ['#228a9f', '#df5a49']
