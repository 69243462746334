import { useRef } from 'react'
import { useSelector } from 'react-redux'
import LinkSector from '../../../../../common/dropdownLink/LinkSector'
import TextEllipsis from '../../../../../common/textEllipsis'
import { LIMIT_SELECT_SECTOR } from '../../constants'
import style from '../style.module.css'
import { selectListSector } from './store/slice'
const TdSelectSector = ({ val, rowId }) => {
  const refInput = useRef()
  const listSector = useSelector(selectListSector)
  const PADDING_CELL = 8
  const GAP_ITEM = 8

  const handleChecked = () => {
    return listSector.some((sector) => sector.icbId === rowId)
  }

  const handleDisableCheckbox = () => {
    return !handleChecked() && listSector.length === LIMIT_SELECT_SECTOR
  }

  return (
    <td style={{ width: '100%', maxWidth: 0 }}>
      <div className={'d-flex align-center'} style={{ gap: GAP_ITEM }}>
        <div ref={refInput}>
          <input
            type="checkbox"
            className={`checkbox ${style.checkbox} ${
              handleDisableCheckbox() ? style.disabled : ''
            }`}
            readOnly
            checked={handleChecked()}
            disabled={handleDisableCheckbox()}
          />
        </div>
        <div
          className={`${style.pointer}`}
          style={{
            width: `calc(100% - ${
              refInput.current?.offsetWidth + GAP_ITEM + PADDING_CELL
            }px)`,
          }}
        >
          <LinkSector icbId={rowId}>
            <TextEllipsis
              isI18n={false}
              text={val}
              appendStyle={{ padding: '1px 0px' }}
            />
          </LinkSector>
        </div>
      </div>
    </td>
  )
}

export default TdSelectSector
