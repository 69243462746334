export const fullSearch = {
  RESULT_FOR: 'result for',
  SECURITIES_FUNCTIONS: 'Securities & Functions',
  NEWS: 'News',
  PAGE: 'Page',
  COR_OVERVIEW: 'COR - Overview',
  FUNC_FUNCTIONS: 'FUNC - Functions',
  MAR_DERIVATIVES: 'MAR - Derivatives',
  MAR_INDICES: 'MAR - Indices',
  // BOND_OVERVIEW: 'BOND - Overview',
  BO_BOND: 'BO - Bond',
  SEC_OVERVIEW: 'SEC - Overview',
  COR_NEWS_EVENTS: 'COR - News & Events',
  SEC_NEWS_REPORT: 'SEC - News & Report',
  MAR_NEWS_REPORT: 'MAR - News & Report',
  SOURCE: 'Source',
  TRADING_DATA: 'Trading Data',
  OWNERSHIP: 'Ownership',
  SUBSIDIARIES_ASSOCIATES: 'Subsidiaries & Associates',
  SEGMENTAL_ANALYSIS: 'Segmental Analysis',
  PENETRATION_ANALYSIS: 'Penetration Analysis',
  COST_OF_OPERATION: 'Cost of Operation',
  FINANCIAL_RATIOS: 'Financial Ratios',
  DEBT_CREDIT_RISK: 'Debt & Credit Risk',
  EARNING_QUALITY: 'Earning Quality',
  FINANCIAL_STATEMENT: 'Financial Statement',
  DIVIDEND: 'Dividend',
  PEERS_VALUATION: 'Peers & Valuation',
  DEEP_TRANSACTION: 'Deep Transaction',
  PRICE_STATISTICS: 'Price Statistics',
  TECHNICAL_CHART: 'Technical Chart',
  FINANCIAL_ANALYSIS: 'Financial Analysis',
  VALUE_CHAIN: 'Value Chain',
  SEGMENTATION: 'Segmentation',
  RANKING: 'Ranking',
  RATIO: 'Ratio',
  VALUATION: 'Valuation',
  FOREIGN: 'Foreign',
  DIRECTOR_DEAL: 'Director Deal',
  INDEX_STATISTICS: 'Index Statistics',
  SECTOR_STATISTICS: 'Sector Statistics',
  LOCAL_INSTITUTION: 'Local Institution',
}
