import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { TYPE_POPUP } from '.'
import { Button } from '../../../common/html/Button'
import { CHART_PARAMETERS_DEFAULT } from '../../chart/constants'
import {
  changeChartTabActive,
  changeChartTabsData,
  selectChartTabsData,
} from '../../chart/store/slice'
import { postCharts } from '../../chart/store/thunk'
import {
  selectChartReplaceChecked,
  selectChartSelected,
} from '../../common/dropdownMoreDashboard/store/slice'
import { getChartNameBySampleName } from '../../common/helpers'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import { screenTypes } from '../../constants'
import {
  changeDashboard,
  replaceChart,
  selectDashboard,
} from '../../store/slice'
import { updateAndReloadDashboard, updateCharts } from '../../store/thunk'
import style from '../styleTheme.module.css'

const PopupGroupButton = ({
  onClose,
  typePopup,
  itemActive,
  chartTab,
  disableBtnSubmit,
}) => {
  const dispatch = useDispatch()

  const { defaultChartSettings } = useGetUserSettings()

  const { screenType, data = {} } = useSelector(selectDashboard)
  const chartTabs = useSelector(selectChartTabsData)
  const chartReplaceChecked = useSelector(selectChartReplaceChecked)
  const chartsSelected = useSelector(selectChartSelected)

  const redirectEditDB = () => {
    if (screenType === screenTypes.DASHBOARD)
      dispatch(changeDashboard({ screenType: screenTypes.EDIT_CHART }))
  }

  const handleAddNewChart = () => {
    onClose()
    redirectEditDB()
    dispatch(
      changeChartTabsData({
        [chartTab]: {
          ...CHART_PARAMETERS_DEFAULT,
          id: itemActive?.id,
          order: itemActive?.order,
          nameChart: {
            ...CHART_PARAMETERS_DEFAULT.nameChart,
            name: getChartNameBySampleName(
              Object.values(chartTabs).map((i) => i.nameChart?.name),
              'Chart ',
            ),
          },
          settings:
            defaultChartSettings?.settings || CHART_PARAMETERS_DEFAULT.settings,
          styleSetting:
            defaultChartSettings?.styleSetting ||
            CHART_PARAMETERS_DEFAULT.styleSetting,
        },
      }),
    )
    dispatch(changeChartTabActive(chartTab))
  }

  const handleReplaceChart = async () => {
    const detail = JSON.parse(chartReplaceChecked.detail)
    const param = {
      ...chartReplaceChecked,
      id: itemActive.id,
      dashboardId: itemActive.dashboardId,
      order: itemActive.order,
      detail: JSON.stringify({ ...detail, order: itemActive.order }),
    }
    dispatch(updateCharts(param))
      .unwrap()
      .then(async (dataChart) => {
        dispatch(
          replaceChart({
            itemReplace: itemActive,
            valueReplace: param,
          }),
        )
      })
    onClose()
  }

  const handleAddChart = () => {
    onClose()
    chartsSelected
      .map((item, index) => ({
        ...item,
        order: Object.values(chartTabs).length + index,
        dashboardId: data.id,
      }))
      .forEach((element) => {
        dispatch(postCharts(element))
      })
    dispatch(
      updateAndReloadDashboard({
        ...data,
      }),
    )
  }

  return (
    <div className="j-b d-flex w-100" style={{ marginTop: 24 }}>
      <Button
        className={`btn h-20 ${style.buttonGrayStyle}`}
        style={{
          fontSize: 11,
          width: 'fit-content',
          padding: '0 16px',
        }}
        onClick={handleAddNewChart}
      >
        <Translate value="financialChart.NEW_CHART" />
      </Button>
      <div className="d-flex justify-content-end">
        <Button
          className={`btn w-80 h-20 ${style.buttonGrayStyle}`}
          style={{ fontSize: 11 }}
          onClick={onClose}
        >
          <Translate value="financialChart.CANCEL" />
        </Button>
        {typePopup === TYPE_POPUP.ADD_CHART ? (
          <Button
            className={`btn h-20 ml-20 ${style.buttonBlueStyle}`}
            style={{
              fontSize: 11,
              opacity: disableBtnSubmit ? 0.4 : 1,
              pointerEvents: disableBtnSubmit ? 'none' : 'auto',
            }}
            onClick={handleAddChart}
            disable={disableBtnSubmit}
          >
            <Translate value={'financialChart.ADD_CHART'} />
          </Button>
        ) : (
          <Button
            className={`btn h-20 ml-20 ${style.buttonBlueStyle}`}
            style={{ fontSize: 11 }}
            onClick={handleReplaceChart}
          >
            <Translate value={'financialChart.REPLACE'} />
          </Button>
        )}
      </div>
    </div>
  )
}

PopupGroupButton.propTypes = {}

export default PopupGroupButton
