export const PEERS_COMPARISON_KEYS = [
  'totalProduction',
  'totalConsumption',
  'totalInventory',

  'productionBar',
  'consumptionBar',
  'inventoryBar',

  'productionShape',
  'consumptionShape',
  'inventoryShape',

  'productionWireRod',
  'consumptionWireRod',
  'inventoryWireRod',
]

export const PEERS_COMPARISON_FILTER = [
  {
    name: 'sector.sectorSpecific.construction.TOTAL',
    value: 0,
  },
  {
    name: 'sector.sectorSpecific.construction.STEEL_BAR',
    value: 1,
  },
  {
    name: 'sector.sectorSpecific.construction.STEEL_SHAPE',
    value: 2,
  },
  {
    name: 'sector.sectorSpecific.construction.STEEL_WIRE_ROD',
    value: 3,
  },
]
