import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialAnalysisService from '../../../../../../../../core/services/sector/sectorConstituents/FinancialAnalysisService'
import { handleExport } from '../../../../../../../utils/Export'

export const getDataTable = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/bank/assetQuality/dataTable/GET_DATA_TABLE',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialAnalysisService.getAssetQualityTable(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadAssetQualityTable = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/bank/assetQuality/dataTable/DOWNLOAD_TABLE',
  async (data) => {
    const response = await FinancialAnalysisService.downloadAssetQualityTable(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
