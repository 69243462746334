import { useEffect, useMemo } from 'react'
// import Radio from '../../../../common/html/Radio'
import useGetMaxMinTable from '../../../../common/masterData/useGetMaxMinTable'
import SelectCustom from '../../../../common/selectCustom'
import { MONTH_OPTIONS } from '../../../../constants/Datetime'
import { PERIOD_TYPES } from '../constants'

export const TimeRange = ({ timeRange, month, year, onChange }) => {
  const { maxMinTableById } = useGetMaxMinTable()
  const maxMinTable = maxMinTableById['FGFB_COFS_DR_FundStockHolding']

  const getYears = useMemo(() => {
    return new Array(new Date().getFullYear() - 2000 + 1)
      .fill(null)
      .map((_, index) => ({
        name: String(new Date().getFullYear() - index),
        value: new Date().getFullYear() - index,
      }))
  }, [])

  const getMonths = useMemo(() => {
    return MONTH_OPTIONS
  }, [])

  const handleChangeTimeRange = (value) => {
    onChange({
      period: value,
      month:
        value === PERIOD_TYPES.SELECT_TIME_PERIOD
          ? month || maxMinTable?.max_MonthReport || new Date().getMonth() + 1
          : '',
      year:
        value === PERIOD_TYPES.SELECT_TIME_PERIOD
          ? year ||
            maxMinTable?.max_YearOfMonthReport ||
            new Date().getFullYear()
          : '',
    })
  }

  useEffect(() => {
    if (!month && !year && maxMinTable) {
      handleChangeTimeRange(PERIOD_TYPES.SELECT_TIME_PERIOD)
    }
  }, [month, year, maxMinTable])

  return (
    <div className="d-flex ali-center mr-8">
      {/* <div className="mr-16">
        <Radio
          data={Object.keys(PERIOD_TYPES).map((key) => ({
            title: 'fund.fundCenter.' + key,
            value: PERIOD_TYPES[key],
          }))}
          value={timeRange}
          onChange={handleChangeTimeRange}
          containerClass="w-auto d-flex"
        />
      </div> */}
      {timeRange === PERIOD_TYPES.SELECT_TIME_PERIOD && (
        <div className="d-flex ali-center">
          <div style={{ width: 85 }} className="mr-8">
            <SelectCustom
              value={month}
              selectData={getMonths}
              handleChange={(value) => onChange({ month: value })}
              isI18n
            />
          </div>
          <div style={{ width: 85 }}>
            <SelectCustom
              value={year}
              selectData={getYears}
              handleChange={(value) => onChange({ year: value })}
              isI18n
            />
          </div>
        </div>
      )}
    </div>
  )
}
