import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterWithScroll from '../../../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { TIME_RANGE, arrColors } from '../../constants'
import style from '../style.module.css'
import {
  changeIsGetData,
  removeSectorToList,
  selectKeyGetData,
  selectListSector,
} from '../tableSector/store/slice'
import ChartComponent from './ChartComponent'
import { Filter } from './filter'
import {
  changeDataChart,
  changeSectorActive,
  changeShowLabelExactly,
  selectLoading,
  selectOrderValue,
  selectPricePerformanceChart,
  selectShowLabelExactly,
  selectTimeRange,
} from './store/slice'
import { pricePerformanceAbsoluteChart } from './store/thunk'

export const PricePerformanceChart = ({ width, height, isFullScreen }) => {
  const dispatch = useDispatch()
  const headPanel = useRef()
  const legendPanel = useRef()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading)
  const timeRange = useSelector(selectTimeRange)
  const orderValue = useSelector(selectOrderValue)
  const dataChart = useSelector(selectPricePerformanceChart)
  const isShowLabelExactly = useSelector(selectShowLabelExactly)
  const isGetData = useSelector(selectKeyGetData)
  const listSector = useSelector(selectListSector)

  const [heightListSector, setHeightListSector] = useState(0)
  const [heightSizeTracker, setHeightSizeTracker] = useState()

  const timeFrame = Object.entries(TIME_RANGE).find(
    (i) => i[1] === timeRange,
  )[0]

  // Actions
  const handleDelete = (item) => {
    dispatch(removeSectorToList(item.icbId))
    dispatch(changeSectorActive(null))
    dispatch(changeDataChart(item.icbId))
  }

  const handleHover = (item) => {
    dispatch(changeSectorActive(item.icbId))
    dispatch(changeShowLabelExactly(true))
  }

  const handleUnHover = () => {
    dispatch(changeSectorActive(null))
    dispatch(changeShowLabelExactly(false))
  }

  // Use effect
  useEffect(() => {
    if (headPanel.current?.offsetHeight && heightListSector)
      setHeightSizeTracker(
        height - headPanel.current?.offsetHeight - heightListSector,
      )
  }, [height, width, isLoading, listSector, heightListSector])

  useEffect(() => {
    dispatch(changeIsGetData(true))
  }, [locale])

  useEffect(() => {
    if (listSector.length && isGetData) {
      const params = {
        icbIds: listSector.map((sector) => sector.icbId),
        period: timeRange,
      }
      dispatch(pricePerformanceAbsoluteChart(params))
    }
  }, [
    JSON.stringify(listSector.map((item) => item.icbId)),
    timeRange,
    locale,
    isGetData,
  ])

  return (
    <div className={`${style.pricePerformance}`}>
      <Filter headPanel={headPanel} />
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {listSector.length && dataChart.length ? (
            <div id="sectorStatisticsPricePerformanceChartId">
              <ChartComponent
                key={listSector.length}
                data={dataChart}
                width={width || 0}
                height={heightSizeTracker || 0}
                lineKeys={listSector.map((item) => ({
                  ...item,
                  textColor: arrColors[item.colorIndex].color,
                  bgColor: arrColors[item.colorIndex].bgColor,
                }))}
                keyXAxis={'tradingDateId'}
                timeFrame={timeFrame}
                showLabelExactly={isShowLabelExactly}
                locale={locale}
                orderValue={orderValue}
              />
              <FooterWithScroll
                width={width}
                data={listSector}
                idKey="icbId"
                nameKey="icbName"
                color={(item) => arrColors[item.colorIndex].bgColor}
                legendPanel={legendPanel}
                isFullScreen={isFullScreen}
                setHeightFooter={setHeightListSector}
                onDelete={handleDelete}
                onHover={handleHover}
                onUnHover={handleUnHover}
              />
            </div>
          ) : (
            <div className="h-100">
              <NoData />
            </div>
          )}
        </>
      )}
    </div>
  )
}

PricePerformanceChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
