import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Table } from '../../../common/table'
import { TextEllipsisStaticLine } from '../../../common/textEllipsis/TextEllipsisStaticLine'
import { OVERVIEW_GROUP_ID } from '../../../common/topInfo/stockTopInfo/constants'
import { keyBy } from '../../../utils/Common'
import { selectBasicInfoOverview } from '../store/slice'
import { IndustryTh } from './IndustryTh'
import { TickerTh } from './TickerTh'
import { IndicatorTableConstant, indicator } from './constants'
import {
  changeIds,
  selectIndicatorIds,
  selectIndicatorValue,
  selectLoading,
  selectShowTable,
  setIds,
  setIndicatorById,
} from './store/slice'
import { getKeyIndicators } from './store/thunk'
const KeyIndicator = () => {
  const dispatch = useDispatch()

  const indicatorIds = useSelector(selectIndicatorIds)
  const isLoading = useSelector(selectLoading)
  const basicInfo = useSelector(selectBasicInfoOverview)
  const showTable = useSelector(selectShowTable)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(getKeyIndicators({ OrganizationId: basicInfo.organizationId }))
    }
  }, [basicInfo.organizationId])

  useEffect(() => {
    if (!basicInfo.groupId) {
      dispatch(setIds(IndicatorTableConstant.corporate))
      dispatch(
        setIndicatorById(keyBy(IndicatorTableConstant.corporate, 'index')),
      )
    } else {
      switch (basicInfo.groupId) {
        case OVERVIEW_GROUP_ID.INSURANCE:
          dispatch(setIds(IndicatorTableConstant.insurance))
          dispatch(
            setIndicatorById(keyBy(IndicatorTableConstant.insurance, 'index')),
          )
          return
        case OVERVIEW_GROUP_ID.BANK:
          dispatch(setIds(IndicatorTableConstant.bank))
          dispatch(
            setIndicatorById(keyBy(IndicatorTableConstant.bank, 'index')),
          )
          return
        case OVERVIEW_GROUP_ID.SECURITIES:
          dispatch(setIds(IndicatorTableConstant.securities))
          dispatch(
            setIndicatorById(keyBy(IndicatorTableConstant.securities, 'index')),
          )
          return
        default:
          dispatch(setIds(IndicatorTableConstant.corporate))
          dispatch(
            setIndicatorById(keyBy(IndicatorTableConstant.corporate, 'index')),
          )
          return
      }
    }
  }, [basicInfo?.groupId])

  if (isLoading) {
    return <Loading />
  }

  if (!showTable && basicInfo?.organizationId) {
    return <NoData />
  }

  if (showTable) {
    return (
      <Table
        ids={indicatorIds}
        columnDraggable={false}
        getDataFromRedux={selectIndicatorValue}
        isLoading={isLoading}
        rowDraggable
        changeIds={changeIds}
        schema={Object.keys(indicator).map((key, index) => {
          const title = `corporate.overview.keyIndicators.${key}`
          const colId = indicator[key]
          const result = {
            colId,
            title,
          }

          if (index === 0) {
            return {
              ...result,
              isI18n: false,
              thStyle: {
                textAlign: 'left',
                fontSize: 10,
              },
              tdStyle: {
                textAlign: 'left',
              },
              render: (val) => {
                return <TextEllipsisStaticLine val={val} />
              },
            }
          }

          if (index === 1) {
            return {
              ...result,
              isI18n: false,
              thStyle: { textAlign: 'right' },
              renderTh: () => {
                return <TickerTh title={title} />
              },
              tdStyle: {
                textAlign: 'right',
              },
            }
          }

          if (index === 2) {
            return {
              ...result,
              isI18n: false,
              thStyle: { textAlign: 'right' },
              renderTh: () => {
                return <IndustryTh title={title} />
              },
              tdStyle: {
                textAlign: 'right',
              },
            }
          }

          return {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'right',
              fontSize: 10,
            },
            tdStyle: {
              textAlign: 'right',
            },
          }
        })}
        stickyFirstColumn
        isLargeHead
        hasFooter={false}
        resizable={false}
      />
    )
  }

  return <></>
}

export default KeyIndicator
