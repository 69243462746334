import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Table } from '../../../../../common/table'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { selectIndexInfo } from '../../../../../common/topInfo/indexTopInfo/store/slice'
import { getISOStartOrEndOfDay } from '../../../../../utils/Datetime'
import {
  changeTableReCalcWidths,
  changeTableStateWhenScrollToBottom,
  changeTableStateWhenScrollToTop,
  changeTimeZone,
  keys,
  resetTableState,
  selectLoading,
  selectTableDataCell,
  selectTableDataId,
  selectTableDataReCalcWidth,
  selectTableFilter,
  selectTableIsPushDataRealTime,
  selectTableScrollId,
} from '../../store/slice'
import { getIndexIntradayThunk } from '../../store/thunk'
import { getTableSchema } from './helpers'

export const TableRealtime = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()
  const ref = useRef({ timeout: null })

  const locale = useSelector((state) => state.i18n.locale)
  const ids = useSelector(selectTableDataId)
  const loading = useSelector(selectLoading(keys.TABLE_INTRADAY))
  const tableFilter = useSelector(selectTableFilter)
  const isPushDataRealTime = useSelector(selectTableIsPushDataRealTime)
  const scrollId = useSelector(selectTableScrollId)
  const indexInfo = useSelector(selectIndexInfo)
  const reCalcWidth = useSelector(selectTableDataReCalcWidth)

  const [rowTimes, setRowTimes] = useState(1)

  // Actions
  const scrollToBottomCallback = () => {
    if (!loading && tableFilter.timeRange === TIME_RANGES.OneTick) {
      if (isPushDataRealTime) {
        dispatch(changeTableStateWhenScrollToBottom())
      }
      if (scrollId) {
        setRowTimes(rowTimes + 1)
      }
    }
  }

  const scrollToTopCallback = () => {
    if (
      !loading &&
      !isPushDataRealTime &&
      tableFilter.timeRange === TIME_RANGES.OneTick
    ) {
      dispatch(changeTableStateWhenScrollToTop())
      setRowTimes(1)
    }
  }

  const getTableRealtime = () => {
    if (indexInfo.groupId && !loading) {
      let params = {
        GroupId: indexInfo.groupId,
        PageSize: tableFilter.pageSize,
        TimeBucket: tableFilter.timeRange,
        StartDate: tableFilter.dateRealTime
          ? getISOStartOrEndOfDay(tableFilter.dateRealTime, timeZone, true)
          : '',
        EndDate: tableFilter.dateRealTime
          ? getISOStartOrEndOfDay(tableFilter.dateRealTime, timeZone, false)
          : '',
      }

      if (rowTimes > 1) {
        params = {
          ...params,
          ScrollId: scrollId,
        }
      }

      if (ref.current.timeout) {
        clearTimeout(ref.current.timeout)
      }

      ref.current.timeout = setTimeout(() => {
        dispatch(getIndexIntradayThunk(params))
        clearTimeout(ref.current.timeout)
      }, 500)
    }
  }

  // Use effect
  useEffect(() => {
    getTableRealtime()
    return () => {
      clearTimeout(ref.current.timeout)
    }
  }, [
    indexInfo.groupId,
    tableFilter.timeRange,
    tableFilter.dateRealTime,
    rowTimes,
  ])

  useEffect(() => {
    if (indexInfo.groupId) {
      dispatch(resetTableState())
    }
  }, [indexInfo.groupId, tableFilter.timeRange, tableFilter.dateRealTime])

  useEffect(() => {
    dispatch(changeTableReCalcWidths())
  }, [ids])

  useEffect(() => {
    if (timeZone) {
      dispatch(changeTimeZone(timeZone))
    }
  }, [timeZone])

  return (
    <Table
      ids={ids}
      getDataFromRedux={selectTableDataCell}
      schema={getTableSchema(
        tableFilter,
        locale,
        timeZone,
        selectTableDataCell,
        !tableFilter.dateRealTime,
        ids,
      )}
      isLargeHead={true}
      resizable={false}
      hasFooter={false}
      isPagination={false}
      scrollToBottomCallback={scrollToBottomCallback}
      scrollToTopCallback={scrollToTopCallback}
      reCalcWidths={reCalcWidth}
    />
  )
}
