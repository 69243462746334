import { useState } from 'react'
import { Setting } from '.'
import { positionDropdown } from '../../../common/dropdown'
import { ReactComponent as IconNewDashboard } from '../../icon/icon-dashboard-template.svg'
import { ReactComponent as IconRename } from '../../icon/icon-rename.svg'
import PopupDeleteDashboard from '../../popup/PopupDeleteDashboard'
import { SCROLL_ID } from '../menuDashboard'

const getData = (showPopupDelete, renameAction, newDashboardAction) => [
  {
    icon: <IconRename />,
    title: 'financialChart.RENAME',
    action: renameAction,
  },
  // {
  //   icon: <IconDuplicate />,
  //   title: 'financialChart.DUPLICATE',
  //   action: () => {},
  // },
  {
    icon: <IconNewDashboard style={{ height: 11 }} />,
    title: 'financialChart.NEW_DASHBOARD',
    action: newDashboardAction,
  },
  {
    icon: <i className="icon-trash" />,
    title: 'financialChart.DELETE',
    action: showPopupDelete,
  },
]

export const GroupDashboardSetting = ({
  data,
  isHover,
  setIsHover,
  setIsEdit,
  setIsActive,
  onAddNewDashboard,
}) => {
  const [isShowSetting, setIsShowSetting] = useState(false)
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false)

  const showPopupDelete = () => {
    setIsShowSetting(false)
    setIsActive(false)
    setIsHover(false)
    setIsShowPopupDelete(true)
  }

  const renameAction = () => {
    setIsShowSetting(false)
    setIsActive(true)
    setIsHover(false)
    setIsEdit(true)
  }

  const newDashboardAction = () => {
    onAddNewDashboard()
  }

  return (
    <>
      <Setting
        data={getData(showPopupDelete, renameAction, newDashboardAction)}
        isShowIcon={!data.isTemplate && (isHover || isShowSetting)}
        isShow={!data.isTemplate && isShowSetting}
        setIsShow={setIsShowSetting}
        setIsActive={setIsActive}
        position={positionDropdown.BOTTOM_RIGHT_LEFT}
        scrollId={SCROLL_ID}
      />

      {isShowPopupDelete && (
        <PopupDeleteDashboard
          handleShow={setIsShowPopupDelete}
          dashboard={data}
        />
      )}
    </>
  )
}
