import { Fragment, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import { MARGIN_RECHARTS } from '../../../../common/chart/constants'
import { ChartContainerWithZoom } from '../../../../common/chart/rechart/ChartContainerWithZoom'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal } from '../../../../utils/Value'
import { COLORS } from '../constant'
import style from '../index.module.css'
import SectorName from '../SectorName'
import TickerName from '../TickerName'
import { AXIS_LABEL_POSITION } from './../../../../common/chart/constants'
import ChartTooltip from './ChartTooltip'
import { changeHoveredItem, selectHoveredItem } from './store/slice'

const SETTINGS = {
  yTickNum: 7,
  xTickNum: 5,
}

const MARGIN = { ...MARGIN_RECHARTS, top: 28, left: 48 }
const MARGIN_ZOOM_X = { left: 10, right: 0, top: 25, bottom: -8 }
const MARGIN_ZOOM_Y = { left: 30, right: 0, top: 25, bottom: 8 }

const YAXIS_ID = 'right'

export const WIDTH_LABEL = 94

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  items,
  hasYAxisLabel,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()
  const heightLabel = getFontSize(18)

  const hoveredItem = useSelector(selectHoveredItem)

  const renderTooltip = useCallback(
    (data, decimalLengths) => {
      return (
        <ChartTooltip
          data={data}
          items={items}
          keyXAxis={keyXAxis}
          decimalLength={decimalLengths[YAXIS_ID]}
          hasYAxisLabel={hasYAxisLabel}
        />
      )
    },
    [data, keyXAxis, items],
  )

  return (
    <>
      <ChartContainerWithZoom
        data={data}
        width={width}
        height={height}
        keyXAxis={keyXAxis}
        xTickNum={SETTINGS.xTickNum}
        timeFrame="1M"
        tickFormatter={(value) =>
          formatDateTime(value, FORMAT.DATE, locale, timeZone)
        }
        margin={MARGIN}
        yAxis={[
          {
            id: YAXIS_ID,
            keys: items.map((item) => item.id),
            isLineChart: true,
            orientation: 'right',
            label: hasYAxisLabel
              ? I18n.t('sector.sectorConstituents.valuation.TRILLION_VND')
              : undefined,
            labelPosition: AXIS_LABEL_POSITION.TOP_RIGHT,
            hasReferenceLabel: true,
            referenceLabelWidth: WIDTH_LABEL,
            referenceLabelHeight: heightLabel,
            tickNum: SETTINGS.yTickNum,
            lineKeys: items,
            lineKey: 'id',
            renderReferenceLabel: ({ data, key, item }) => {
              return <CustomLabel value={data[key]} item={item} />
            },
          },
        ]}
        zoomRightYBarPosition="left"
        marginZoomX={MARGIN_ZOOM_X}
        marginZoomY={MARGIN_ZOOM_Y}
        renderCustomTooltip={renderTooltip}
        yTickNum={SETTINGS.yTickNum}
      >
        {items.map((item, index) => (
          <Fragment key={item.id}>
            <Line
              yAxisId="right"
              dataKey={item.id}
              type="linear"
              stroke={COLORS[item.color]}
              strokeOpacity={
                !hoveredItem ? 1 : hoveredItem === item.id ? 1 : 0.2
              }
              dot={false}
              activeDot={false}
              isAnimationActive={false}
              strokeWidth={
                !hoveredItem ? 1.5 : hoveredItem === item.id ? 2.5 : 1.5
              }
            />
          </Fragment>
        ))}
      </ChartContainerWithZoom>
    </>
  )
}

const CustomLabel = ({ value, item }) => {
  const dispatch = useDispatch()
  const hoveredItem = useSelector(selectHoveredItem)

  const onHover = () => {
    dispatch(changeHoveredItem(item.id))
  }

  const onLeave = () => {
    dispatch(changeHoveredItem(null))
  }

  return (
    <div
      className={style.textNormal}
      style={{
        backgroundColor: item.bgColor,
        color: item.textColor,
        opacity: !hoveredItem ? 1 : hoveredItem === item.id ? 1 : 0.2,
      }}
      onMouseOver={onHover}
      onMouseLeave={onLeave}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="flex-1" style={{ minWidth: 0 }}>
          {item.icbId ? (
            <SectorName icbId={item.icbId} />
          ) : (
            <TickerName organizationId={item.organizationId} />
          )}
        </div>
        <div>{formatVal(value)}</div>
      </div>
    </div>
  )
}

export default ChartComponent
