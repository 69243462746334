export const MARGIN_CHART = { top: 32, right: 16, bottom: 0, left: 16 }
export const HEIGHT_XAXIS = 30
export const TICK_MARGIN_XAXIS = 15
export const TICK_MARGIN_YAXIS = 8
export const MAX_BAR_SIZE = 35
export const REF_LABEL_WIDTH = 140
export const SPACE_BETWEEN_REF_AND_YAXIS_LABEL = 10

export const CHART_TYPES = {
  LINE: 'LINE',
  BAR: 'BAR',
  AREA: 'AREA',
  DOT: 'DOT',
  STACK_BAR: 'STACK_BAR',
  STACK_AREA: 'STACK_AREA',
}

// Priority higher will show on top of charts
export const CHART_TYPE_PRIORITY = {
  [CHART_TYPES.DOT]: 4,
  [CHART_TYPES.LINE]: 3,
  [CHART_TYPES.AREA]: 2,
  [CHART_TYPES.STACK_AREA]: 2,
  [CHART_TYPES.BAR]: 1,
  [CHART_TYPES.STACK_BAR]: 1,
}

export const DOT_TYPES = {
  OVAL: 'OVAL',
  RHOMBUS: 'RHOMBUS',
  RECTANGLE: 'RECTANGLE',
}

export const TIME_FREQUENCY = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
  TTM: 'TTM',
}
