import SelectCustom from '../../../../../common/selectCustom'
import { HOLDING_OPTIONS } from '../constants'

export const FundHoldingOption = ({ value, onChange }) => {
  return (
    <div style={{ width: 180 }} className="mr-16">
      <SelectCustom
        value={value}
        selectData={Object.keys(HOLDING_OPTIONS).map((key) => ({
          name: 'fund.fundProfile.portfolio.portfolioTable.' + key,
          value: HOLDING_OPTIONS[key],
        }))}
        handleChange={(value) => onChange({ holdingOption: value })}
        isI18n
      />
    </div>
  )
}
