export const fundCenter = {
  STATISTIC: 'STATISTIC',
  OVERVIEW: 'OVERVIEW',
  HOLDING: 'HOLDING',

  ALL_FUND: 'All Funds',
  SEARCH_FUND: 'Search Funds',
  ALL_STRUCTURE: 'All Structures',
  STOCK_FUND: 'Stock Fund',
  BOND_FUND: 'Bond Fund',
  BALANCE_FUND: 'Balance Fund',
  ALL_TYPE: 'All Types',
  OPEN_END_FUND: 'Open End Fund',
  CLOSED_END_FUND: 'Closed End Fund',
  ETF_FUND: 'ETF Fund',
  SPECIALTY_FUND: 'Specialty Fund',
  MEMBER_FUND: 'Member Fund',
  PENSION_FUND: 'Pension Fund',
  ALL_MANAGEMENT: 'All Management Funds',
  ALL_STATUS: 'All Status',
  ACTIVE: 'Active',
  STOP: 'Terminated',
  PAUSE: 'Suspended',
  OTHER: 'Other',

  MOST_RECENT: 'Most recent',
  SELECT_TIME_PERIOD: 'Select period',

  FUND_FLOW_NOTE: 'FiinGroup provides estimates for the Fund Flow statistics',

  statistic: {
    TABLE: 'Table',
    KEY_METRICS: 'Key metrics',
    PERFORMANCE: 'Performance',
    FUND_FLOW: 'Fund flow',
    SECTOR_ALLOCATION: 'Sector allocation',
    ASSET_ALLOCATION: 'Asset allocation',
    FUND_INDEX: 'Fund index',
    RELATIVE: 'Contribution',
    ABSOLUTE: 'Absolute',
    MOST_RECENT: 'Most recent',
    SELECT_TIME_PERIOD: 'Select time period',
    NOTE_UNIT_BILLION_VND: 'Unit: Billion VND',

    COL_INDEX: 'No.',
    COL_FUND_TICKER: 'Fund Ticker',
    COL_FUND_NAME: 'Fund Name',
    COL_FUND_STRUCTURE: 'Fund Structure',
    COL_FUND_TYPE: 'Fund Type',
    COL_FUND_MANAGEMENT: 'Fund Management',
    COL_FUND_FUND_STATUS: 'Fund Status',
    COL_YEAR_IN_OPERATION: 'Years in Operation',
    COL_FREQUENCY: 'Frequency',
    COL_FREQUENCY_NOTE:
      "The frequency of updating the fund's latest data at the selected time",
    COL_UPDATE_DATE_NAV: 'Update Date',
    COL_PERFORMANCE_UPDATE_DATE_NAV: 'Update Date',
    COL_TOTAL_NAV: 'Total NAV',
    COL_CHANGE_NAV_1M: '% Change NAV/CCQ 1M',
    COL_CHANGE_NAV_YTD: '% Change NAV/CCQ YTD',
    COL_FUND_FLOW_1M: 'Fund Flow 1M',
    COL_STD_CHANGE_1Y: 'Std Change 1Y',
    COL_NAV_CCQ: 'NAV/CCQ',
    COL_NAV_CCQ_PERFORMANCE: 'NAV/CCQ Performance',
    COL_NAV_CCQ_PERFORMANCE_MOST_RECENT: 'Most Recent',
    COL_NAV_CCQ_PERFORMANCE_YTD: 'YTD',
    COL_NAV_CCQ_PERFORMANCE_1M: '1M',
    COL_NAV_CCQ_PERFORMANCE_6M: '6M',
    COL_NAV_CCQ_PERFORMANCE_1Y: '1Y',
    COL_NAV_CCQ_PERFORMANCE_3Y: '3Y',
    COL_NAV_CCQ_PERFORMANCE_5Y: '5Y',
    COL_TOTAL_FUND_FLOW: 'Total Fundflow',
    COL_TOTAL_FUND_FLOW_MOST_RECENT: 'Most Recent',
    COL_TOTAL_FUND_FLOW_3M: '3M',
    COL_TOTAL_FUND_FLOW_YTD: 'YTD',
    COL_TOTAL_FUND_FLOW_1Y: '1Y',
    COL_SECTOR: 'Sector',
    COL_SECTOR_ABSOLUTE: 'Sector. Absolute',
    COL_SECTOR_RELATIVE: 'Sector. Relative',
    COL_STOCK: 'Stock',
    COL_STOCK_VALUE: 'Value',
    COL_STOCK_MOST_RECENT_CHANGE: 'Most Recent Change',
    COL_BOND: 'Bond',
    COL_BOND_VALUE: 'Value',
    COL_BOND_MOST_RECENT_CHANGE: 'Most Recent Change',
    COL_CASH_AND_ORDER: 'Cash and Others',
    COL_CASH_AND_ORDER_VALUE: 'Value',
    COL_CASH_AND_ORDER_MOST_RECENT_CHANGE: 'Most Recent Change',
    COL_VOLATILITY: 'Volatility',
    COL_SHARPE_RATIO: 'Sharpe Ratio',
    COL_CORRELATION: 'Correlation',
    COL_FOREIGN_OWNED: 'Foreign Owned (%)',
    COL_MAX_DRAWDOWN: 'Max Drawdown',
    COL_TREYNOR_RATIO: 'Treynor Ratio',
    COL_STD_1Y: 'Std (1y)',
    COL_STD_3Y: 'Std (3y)',
    COL_SHARPE_1Y: 'Sharpe (1y)',
    COL_SHARPE_3Y: 'Sharpe (3y)',
    COL_TRACKING_ERROR_1Y: 'Tracking Error (1y)',
    TABLE_NOTE:
      '* Performance of Foreign Funds can be affected by exchange rate issues since the NAV (Net Asset Value) is listed in foreign currency.',
  },

  overview: {
    ASSET_ALLOCATION: 'Asset Allocation',
    PERFORMANCE: 'Performance',

    performance: {
      TOP_10_NET_ASSET_VALUE: 'Top 10 net asset value',
      FUND_OVERVIEW_12_MONTHS: 'Funds overview 12 months',
      NAV_CCQ: 'NAV/Certificate growth',
      FUND_FLOW: 'Fund flow',
      CHART_NAME_TOP_10_NET_ASSET_VALUE:
        'TOP 10 NET ASSET VALUE %{month} - %{year}',
      CHART_NAME_FUND_OVERVIEW_12_MONTHS:
        'FUNDS OVERVIEW 12 MONTHS %{month} - %{year}',
      CHART_NAME_NAV_CCQ: 'NAV/ CERTIFICATE GROWTH',
      CHART_NAME_FUND_FLOW: 'FUND FLOW',
      FUND_STRUCTURE: 'Fund structure',
      STOCK_FUND: 'Stock Fund',
      BOND_FUND: 'Bond Fund',
      BALANCE_FUND: 'Balance Fund',
      FUND_TYPE: 'Fund type',
      OPEN_END_FUND: 'Open End Fund',
      CLOSED_END_FUND: 'Closed End Fund',
      ETF_FUND: 'ETF Fund',
      SOURCE_FUND: 'Source: %{total} Funds',
      FUND_OVERVIEW_X_AXIS: '% change NAV/Certificate 1Y',
      FUND_OVERVIEW_Y_AXIS: 'Standard deviation 1Y',
      FUND_OVERVIEW_Z_AXIS: 'Total NAV',
      FUND_OVERVIEW_NOTE: 'Bubble size = Total NAV',
      FUND_FLOW_Y_AXIS: 'Fund flow (Bil. VND)',
      FUND_FLOW_ACC_Y_AXIS: 'Acc. fund flow (Bil. VND)',
      FUND_FLOW_FOOTER: 'Fund flow',
      FUND_FLOW_ACC_FOOTER: 'Acc. fund flow 12M',
      FUND_FLOW_NET_TOOLTIP: 'Net fund flow',
      FUND_FLOW_IN_TOOLTIP: 'Fund in flow',
      FUND_FLOW_OUT_TOOLTIP: 'Fund out flow',
      FUND_FLOW_ACC_TOOLTIP: 'Acc. fund flow 12M',
      NAV_CCQ_FUND_AVERAGE: 'Funds Average',
      NAV_CCQ_STOCK_FUND_AVERAGE: 'Stock Funds Average',
      NAV_CCQ_BOND_FUND_AVERAGE: 'Bond Funds Average',
      NAV_CCQ_BALANCE_FUND_AVERAGE: 'Balance Funds Average',
    },
  },

  holding: {
    TABLE: 'Table',
    ALL: 'All',
    DETAIL: 'Detail',

    COL_ALL_TICKER: 'Ticker',
    COL_ALL_NAME: 'Name',
    COL_ALL_CLOSE_PRICE: 'Close Price',
    COL_ALL_PRICE_CHANGE_1M: '% Price Change Per Month',
    COL_ALL_VOLUME: 'Volume',
    COL_ALL_OWNERSHIP_TOTAL_SHARE: 'Ownership/Total Outstanding Shares',
    COL_ALL_TOTAL_VALUE: 'Total Value',
    COL_ALL_OWNERSHIP_CHANGE_VOLUME: 'Ownership Change (Volume)',
    COL_ALL_OWNERSHIP_CHANGE_VOLUME_1M: '1M',
    COL_ALL_OWNERSHIP_CHANGE_VOLUME_YTD: 'YTD',
    COL_ALL_OWNERSHIP_CHANGE_VOLUME_1Y: '1Y',
    COL_ALL_OWNERSHIP_CHANGE_VALUE: 'Ownership Change',
    COL_ALL_OWNERSHIP_CHANGE_VALUE_1M: '1M',
    COL_ALL_OWNERSHIP_CHANGE_VALUE_YTD: 'YTD',
    COL_ALL_OWNERSHIP_CHANGE_VALUE_1Y: '1Y',
    COL_ALL_NUMBER_FUND_HOLDING: 'Number of Funds Holding',
    COL_DETAIL_TICKER: 'Fund Ticker',
    COL_DETAIL_NAME: 'Fund Name',
    COL_DETAIL_INVESTMENT_STRATEGY: 'Investment Strategy',
    COL_DETAIL_NAV_CCQ: 'NAV/Certificate',
    COL_DETAIL_UPDATE_DATE: 'Update Date NAV',
    COL_DETAIL_NAV_CCQ_PERFORMANCE: 'NAV/Certificate Performance',
    COL_DETAIL_NAV_CCQ_PERFORMANCE_1M: '1M',
    COL_DETAIL_NAV_CCQ_PERFORMANCE_1Y: '1Y',
    COL_DETAIL_NAV_CCQ_PERFORMANCE_3Y: '3Y',
    COL_DETAIL_FUND_HOLDING: 'Fund Holding',
    COL_DETAIL_FUND_HOLDING_VOLUME: 'Volume (Shares)',
    COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_1M: 'Volume Change 1M',
    COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_3M: 'Volume Change 3M',
    COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_6M: 'Volume Change 6M',
    COL_DETAIL_FUND_HOLDING_PRICE_CHANGE_1M: '(+/-%) 1M price change',
    COL_DETAIL_FUND_HOLDING_TOTAL_VALUE: 'Total value',
    COL_DETAIL_FUND_HOLDING_PERCENT_TOTAL_ASSET: '% Contribution',
    COL_DETAIL_FUND_HOLDING_OWNERSHIP_TOTAL_SHARE:
      'Ownership/Total Outstanding Shares',
    TABLE_NOTE:
      '*The total number of shares held in the market may decrease because the FiinPro-X system has not fully updated the ownership reports from some fund management companies for the month.',
  },
}
