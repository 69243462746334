import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { formatVal } from '../../../utils/Value'
import { selectResultKeyTableByIds } from '../store/slice'

const TdTable = ({ value, keyTh }) => {
  const unit = useSelector(selectResultKeyTableByIds(keyTh))?.unit

  const style = {
    color: value < 0 ? '#ff4752' : undefined,
  }

  return (
    <div style={style}>
      {formatVal(value)}
      {unit === '%' && unit}
    </div>
  )
}

TdTable.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  keyTh: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default TdTable
