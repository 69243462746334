import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { formatVal } from '../../../../../utils/Value'
// import { CHARTS } from './constant'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import TextEllipsis from '../../../../../common/textEllipsis'
import { DATA_TYPE } from '../peersComparison/constant'
import { COLOR } from './constant'
import {
  selectDataChart1,
  selectFilterChart1,
  selectLoadingChart1,
} from './store/slice'

const RESPONSIVE_WIDTH = 900

const CustomPolarAngleAxisTick = (props) => {
  const { x, y, payload, textAnchor, width } = props

  let dx = 0
  let dy = 0
  if (textAnchor === 'middle') {
    dx = width > RESPONSIVE_WIDTH ? 150 : 50
    dy = 15
  }
  if (textAnchor === 'start') {
    dx = 0
    dy = 5
  }
  if (textAnchor === 'end') {
    dx = width > RESPONSIVE_WIDTH ? 300 : 100
    dy = 5
  }

  const textColor = '#FFF'
  return (
    <foreignObject
      width={width > RESPONSIVE_WIDTH ? 300 : 100}
      height={20}
      x={x - dx}
      y={y - dy}
    >
      <Span
        style={{
          fontSize: 12,
          color: textColor,
          textAlign: textAnchor === 'middle' ? 'center' : textAnchor,
        }}
      >
        <TextEllipsis text={payload.value} />
      </Span>
    </foreignObject>
  )
}

const CustomRadarDot = (props) => {
  const { cx, cy, fill } = props
  return <circle cx={cx} cy={cy} r={3} fill={fill} />
}

const dataValue = [
  'debtToEBITDAScore',
  'ffOcToInterestExpenseScore',
  'ebitdaInterestCoverageScore',
  'cfoToLiabilitiesScore',
  'ffOcToDebtScore',
]

const dataValueScore = [
  'debtToEBITDA',
  'ffOcToInterestExpense',
  'ebitdaInterestCoverage',
  'cfoToLiabilities',
  'ffOcToDebt',
]
const dataKey = ['enterprise', 'industry']

const ChartRadar = ({ width, height, dataType }) => {
  const filter = useSelector(selectFilterChart1)
  const data = useSelector(selectDataChart1)

  const getTitle = (value) => {
    switch (value) {
      case dataValue[0]:
        return data?.debtToEBITDAName
      case dataValue[1]:
        return data?.ffOcToInterestExpenseName
      case dataValue[2]:
        return data?.ebitdaInterestCoverageName
      case dataValue[3]:
        return data?.cfoToLiabilitiesName
      case dataValue[4]:
        return data?.ffOcToDebtName
      default:
        return ''
    }
  }

  const dataChart = useMemo(() => {
    const dataFormart = []
    dataValue.forEach((e, index) => {
      let item = {
        subject: getTitle(e),
      }
      dataKey.forEach((z) => {
        item[z] = data[z]?.[e] || 0
        item[`${z}value`] = data[z]?.[dataValueScore[index]] || 0
        item['keyValue'] = dataValueScore[index]
      })
      dataFormart.push(item)
    })
    return dataFormart
  }, [data])

  const loading = useSelector(selectLoadingChart1)

  const CustomTooltip = (props) => {
    const { active, payload } = props

    if (active) {
      return (
        <div
          style={{
            background: '#344053cc',
            padding: 10,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
            fontWeight: 500,
            position: 'relative',
          }}
        >
          <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
            <Span style={{ fontSize: 10 }}>{filter}</Span>
          </div>
          {dataKey.map((key, index) => {
            const item = payload.find((e) => e?.name === key)
            return (
              <div key={index} className="d-flex j-b">
                <div>
                  <Span style={{ fontSize: 11, fontWeight: 400 }}>
                    {I18n.t(`bond.comparable.chart.lengend.${key}`) + ': '}
                  </Span>
                </div>
                <div className="ml-8">
                  <Span style={{ fontSize: 11, fontWeight: 500 }}>
                    {`${
                      dataType === DATA_TYPE.BANK &&
                      (item?.payload?.keyValue === dataValueScore[1] ||
                        item?.payload?.keyValue === dataValueScore[3])
                        ? formatVal(item?.payload?.[`${key}value`] * 100)
                        : formatVal(item?.payload?.[`${key}value`])
                    } ${
                      dataType === DATA_TYPE.BANK &&
                      (item?.payload?.keyValue === dataValueScore[1] ||
                        item?.payload?.keyValue === dataValueScore[3])
                        ? '%'
                        : I18n.t('bond.comparable.common.times')
                    }`}
                  </Span>
                </div>
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  if (loading) {
    return <Loading />
  }

  if (data.length === 0) {
    return <NoData />
  }

  const renderRadar = () => {
    return (
      <>
        {dataKey.map((key, index) => {
          return (
            <Radar
              key={index}
              dot={<CustomRadarDot />}
              legendType={'square'}
              name={key}
              dataKey={key}
              stroke={COLOR[index]}
              fill={COLOR[index]}
              fillOpacity={0}
            />
          )
        })}
      </>
    )
  }

  return (
    <ResponsiveContainer width={width} height={height}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={dataChart}>
        <PolarGrid stroke="#485660" />
        <PolarAngleAxis
          dataKey="subject"
          tick={<CustomPolarAngleAxisTick width={width} />}
        />
        <PolarRadiusAxis
          tickCount={10}
          tickLine={false}
          axisLine={false}
          tick={false}
        />

        {renderRadar()}
        <Tooltip content={<CustomTooltip />} />
      </RadarChart>
    </ResponsiveContainer>
  )
}

export default ChartRadar
