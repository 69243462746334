import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { checkDaily } from '../helper'
import style from '../index.module.css'
import {
  addCondition,
  removeCondition,
  resetScreeningCount,
  selectItemConditionByIds,
  selectTypeInterims,
} from '../store/slice'
import CriteriaValue from './CriteriaValue'
import Interim from './Interim'
import Operator from './Operator'
import Type from './Type'
import Values from './Values'

const SubItemCondition = ({ indicatorId, index, lastIndex, totalRow }) => {
  const dispatch = useDispatch()

  const [valueDataType, setValueDataType] = useState(0)

  const indicator = useSelector(selectItemConditionByIds(indicatorId))
  const indicatorGroupId = indicator?.indicatorGroupId
  const dataTypeCode = useSelector(
    selectTypeInterims(`${indicatorGroupId}.${valueDataType}.dataTypeCode`),
  )

  const handleAddCondition = () => dispatch(addCondition(indicator))

  const handleRemoveCondition = () => {
    dispatch(removeCondition({ indicatorId, index }))
    if (totalRow === 1) {
      dispatch(resetScreeningCount())
    }
  }

  const isFirstSubItem = index === 0
  const isLastItem = index === lastIndex

  return (
    <tr>
      <td>
        <div className={`${style.tdContainer} ${style.lineHeight20}`}>
          {isFirstSubItem && (
            <TextEllipsis isI18n={false} text={indicator.indicatorName} />
          )}
        </div>
      </td>
      <td>
        <div className={style.tdContainer}>
          <div className="d-flex">
            <Type
              indicatorId={indicatorId}
              indicatorGroupId={indicatorGroupId}
              index={index}
              valueDataType={valueDataType}
              setValueDataType={setValueDataType}
            />
            <Interim
              indicatorId={indicatorId}
              index={index}
              valueDataType={valueDataType}
              indicatorGroupId={indicatorGroupId}
            />
          </div>
          {isLastItem &&
            checkDaily(
              dataTypeCode,
              '',
              <div className={`btn-add mb-10 ${style.mt8}`}>
                <button
                  onClick={handleAddCondition}
                  type="button"
                  className="btn h-20"
                >
                  <i className="icon-plus" />
                </button>
              </div>,
            )}
        </div>
      </td>
      <td>
        <div className={style.tdContainer}>
          <Values
            indicatorId={indicatorId}
            index={index}
            valueDataType={valueDataType}
          />
        </div>
      </td>
      <td>
        <div className={`${style.tdContainer} ${style.lineHeight20}`}>
          {isFirstSubItem && (
            <TextEllipsis isI18n={false} text={indicator.unit} />
          )}
        </div>
      </td>
      <td>
        <div className={style.tdContainer}>
          <div className="d-flex j-b ali-center">
            <div className={`d-flex ${style.criteria}`}>
              {checkDaily(
                dataTypeCode,
                '',
                <>
                  <div className={`${style.widthAnd} ${style.pt4px}`}>
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="tool.smartScreening.AND" />
                    </Span>
                  </div>
                  <Operator indicatorId={indicatorId} index={index} />
                  <div
                    className={`${style.widthThan} ${style.pt4px} text-center`}
                  >
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="tool.smartScreening.THAN" />
                    </Span>
                  </div>
                  <CriteriaValue
                    indicatorId={indicatorId}
                    index={index}
                    valueDataType={valueDataType}
                    indicatorGroupId={indicatorGroupId}
                  />
                </>,
              )}
            </div>
            <button
              onClick={handleRemoveCondition}
              className={`cursor-pointer ${style.btnDeleteCondition}`}
            >
              <i className="icon-trash" />
            </button>
          </div>
        </div>
      </td>
    </tr>
  )
}

SubItemCondition.propTypes = {
  indicatorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  index: PropTypes.number.isRequired,
  lastIndex: PropTypes.number.isRequired,
  totalRow: PropTypes.number.isRequired,
}

export default SubItemCondition
