import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../utils/EventEmitter'
import {
  changeChartTabSecurities,
  changeTreeSecuritiesIndicator,
} from '../../chart/store/slice'
import {
  LIST_TYPE,
  MAX_SECURITIES_ADD,
  MAX_SECURITIES_ITEM,
} from '../../common/constants'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import { selectLoadingIndicators } from '../../store/slice'
import { changeSecurities } from '../common/store/slice'
import { getDeepestIndicatorsWithTimeFrequency } from '../helper'
import { getSecuritiesIndicatorDataOnApply } from '../popupAddSecurities/helpers'
import ItemSecurities from './ItemSecurities'
import withWrapperData from './withWrapperData'

const ListEconomy = ({
  valueSearch,
  securities,
  onClose,
  treeSecuritiesIndicator,
  indicatorSelected,
  listSecuritiesEconomy,
  typeChart,
  haveOrientationLeft,
  itemId,
  usedColors,
}) => {
  const dispatch = useDispatch()

  const { favoriteColors } = useGetUserSettings()

  const loading = useSelector(selectLoadingIndicators)

  const [filteredEconomy, setFilteredEconomy] = useState([])

  const getEconomies = useMemo(() => {
    return getDeepestIndicatorsWithTimeFrequency(listSecuritiesEconomy)
  }, [listSecuritiesEconomy])

  const addSecurities = (securitiesItem, securitiesReplace) => {
    const securityCount = Object.values(securities).reduce(
      (arr, ids) => arr.concat(ids),
      [],
    ).length
    if (securityCount >= MAX_SECURITIES_ADD) {
      return EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        I18n.t('financialChart.WANNING_ADD_SECURITIES'),
      ])
    }

    const arrNew = [...securities.economy, securitiesItem]
    const arrValue = itemId
      ? arrNew.filter((item) => `${item.id}_${item.timeFrequency}` !== itemId)
      : arrNew
    const result = getSecuritiesIndicatorDataOnApply({
      treeSecuritiesIndicator,
      securities,
      indicatorSelected,
      listSecuritiesEconomy,
      typeChart,
      haveOrientationLeft,
      securitiesState: {
        ...securities,
        [LIST_TYPE.ECONOMY]: arrValue,
      },
      replaceSecurities: itemId,
      replaceSecuritiesValue: securitiesReplace,
      typeSecuritiesReplace: LIST_TYPE.ECONOMY,
      usedColors,
      favoriteColors,
    })

    dispatch(changeTreeSecuritiesIndicator(result))
    dispatch(changeChartTabSecurities({ [LIST_TYPE.ECONOMY]: arrValue }))
    dispatch(
      changeSecurities({
        typeSecurities: LIST_TYPE.ECONOMY,
        value: arrValue,
      }),
    )
    onClose()
  }

  const renderTickers = () => (
    <ScrollComponent bgColor="#D9D9D9">
      {filteredEconomy.map((item) => {
        return (
          <ItemSecurities
            key={item.id}
            securities={securities.economy.map((item) =>
              [item.id, item.timeFrequency].join('_'),
            )}
            id={item.id}
            text={item.secNameDisplay}
            onClick={() =>
              addSecurities(
                {
                  id: item.parentId,
                  timeFrequency: item.timeFrequency,
                },
                item,
              )
            }
            itemId={itemId}
          />
        )
      })}
    </ScrollComponent>
  )

  useEffect(() => {
    setFilteredEconomy(
      getEconomies
        .filter((index) =>
          valueSearch
            ? index.secNameDisplay
                ?.toLowerCase()
                .includes(valueSearch.toLowerCase())
            : true,
        )
        .slice(0, MAX_SECURITIES_ITEM),
    )
  }, [getEconomies, valueSearch])

  return (
    <div className="h-100 w-100">
      {loading ? (
        <Loading isBlack />
      ) : filteredEconomy.length ? (
        renderTickers()
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default withWrapperData(ListEconomy)
