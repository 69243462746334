import SimpleSearchProxy from '../../proxies/common/SimpleSearchProxy'
import { ServiceBase } from '../ServiceBase'

class SimpleSearchService extends ServiceBase {
  searchCompany(params) {
    return this.applyMemoryCache(
      'SimpleSearchService.searchCompany',
      params,
    )(() => SimpleSearchProxy.searchCompany(params))
  }
  searchBondAndUserBond(params) {
    return this.applyMemoryCache(
      'SimpleSearchService.searchBondAndUserBond',
      params,
    )(() => SimpleSearchProxy.searchBondAndUserBond(params))
  }
  searchIndies(params) {
    return this.applyMemoryCache(
      'SimpleSearchService.searchIndies',
      params,
    )(() => SimpleSearchProxy.searchIndies(params))
  }

  searchCompanyOrIndex(params) {
    return this.applyMemoryCache(
      'SimpleSearchService.searchCompanyOrIndex',
      params,
    )(() => SimpleSearchProxy.searchCompanyOrIndex(params))
  }

  searchHOHAUPCompany(params) {
    return this.applyMemoryCache(
      'SimpleSearchService.searchHOHAUPCompany',
      params,
    )(() => SimpleSearchProxy.searchHOHAUPCompany(params))
  }
  searchBond(params) {
    return this.applyMemoryCache(
      'SimpleSearchService.searchBond',
      params,
    )(() => SimpleSearchProxy.searchBond(params))
  }
  searchCompanyNotSaveCache(params) {
    return this.getData(() => SimpleSearchProxy.searchCompany(params))
  }
  searchBondAndUserBondNotCache(params) {
    return this.getData(() => SimpleSearchProxy.searchBondAndUserBond(params))
  }
}

export default new SimpleSearchService()
