import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal } from '../../../../utils/Value'
import { CASH_TOTAL_ROW_ID } from '../panelMoneySupplyCredit/OtherCellColumn'
import {
  getDropdownOptions,
  getDropDownValue,
  getTableMappingLoading,
} from '../panelMoneySupplyCredit/store/slice'
import { LINE_CHART_COLORS } from './constants'
import {
  getTooltipContentData,
  handleLineChartData,
  renderListFooter,
} from './helper'
import style from './index.module.css'
import { getLineChartData, getLineChartLoading } from './store/slice'

const YAXIS_LEFT = 'left'
const HEADER_TOOLTIP_MAX_WIDTH = 150

const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 1.5,
}

const LineChart = ({
  data,
  width,
  height,
  keyXAxis,
  keyYAxisProps,
  isValue,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const typeNameLoading = useSelector(getTableMappingLoading)
  const lineChartData = useSelector(getLineChartData)
  const lineChartLoading = useSelector(getLineChartLoading)
  const headerTooltipFontSize = getFontSize(10)
  const dropdownValue = useSelector(getDropDownValue)
  const dropdownOptions = useSelector(getDropdownOptions)
  const headerTooltip = dropdownOptions.find(
    (item) => item.value === dropdownValue,
  )

  const dataLineChart = useMemo(() => {
    if (!typeNameLoading) {
      return handleLineChartData(data, isValue, locale, dropdownValue)
    }
    return []
  }, [typeNameLoading, locale, lineChartLoading])

  const renderTooltip = (payload) => {
    return (
      <>
        <div
          style={{ fontStyle: 'italic', maxWidth: HEADER_TOOLTIP_MAX_WIDTH }}
        >
          <TextEllipsisStaticLine
            appendStyle={{ fontSize: headerTooltipFontSize }}
            val={headerTooltip?.name || ''}
            isI18n={false}
            lineClamp={2}
          />
        </div>
        {getTooltipContentData(payload, keyYAxisProps).map((item, index) => {
          return (
            <div
              key={index}
              className="d-flex j-b"
              style={{
                marginTop: 10,
              }}
            >
              <Span
                style={{ fontSize: 11 }}
                className={style.tooltipContentKey}
              >
                {payload[keyXAxis]} - {item.label}:
              </Span>
              <Span
                style={{ fontSize: 11 }}
                className={`${style.toolTipContentValue} ${
                  typeof item.value === 'number' &&
                  item.value < 0 &&
                  style.redColor
                }`}
              >
                {formatVal(item.value)}
                {renderUnit(item.value)}
              </Span>
            </div>
          )
        })}
      </>
    )
  }

  const renderUnit = (value) => {
    if (typeof value !== 'number') {
      return ''
    }

    return isValue && dropdownValue !== CASH_TOTAL_ROW_ID ? (
      <Translate value={'economy.monetary.monetary.TRILLION_TOOLTIP'} />
    ) : (
      '%'
    )
  }

  const keyYAxis = [
    {
      id: YAXIS_LEFT,
      keys: keyYAxisProps,
      orientation: YAXIS_LEFT,
      isLineChart: true,
      label:
        isValue && dropdownValue !== CASH_TOTAL_ROW_ID
          ? I18n.t('economy.monetary.monetary.TRILLION_VND')
          : '',
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: SETTINGS.yTickNum,
      unitYAxis: isValue && dropdownValue !== CASH_TOTAL_ROW_ID ? '' : '%',
    },
  ]

  if (lineChartLoading || typeNameLoading) {
    return <Loading />
  }

  if (!lineChartData.length) {
    return <NoData />
  }

  return (
    <>
      {dataLineChart.length > 0 && (
        <SizeTracker className={style.heightAuto}>
          {(sizes) => {
            return (
              <>
                {typeof sizes.height === 'number' && (
                  <ChartContainer
                    data={dataLineChart}
                    height={height - sizes.height}
                    width={width}
                    keyXAxis={keyXAxis}
                    yAxis={keyYAxis}
                    renderCustomTooltip={renderTooltip}
                    showAllTick
                    margin={{
                      ...MARGIN_RECHARTS,
                      left: !isValue ? 12 : 0,
                      right: 36,
                    }}
                  >
                    {keyYAxisProps.map((item, index) => {
                      return (
                        <Line
                          key={index}
                          isAnimationActive={false}
                          yAxisId={YAXIS_LEFT}
                          dataKey={item}
                          stroke={LINE_CHART_COLORS[index]}
                          dot={false}
                          activeDot={false}
                          strokeWidth={SETTINGS.strokeWidth}
                        />
                      )
                    })}
                  </ChartContainer>
                )}
                <Footer key={width} list={renderListFooter(keyYAxisProps)} />
              </>
            )
          }}
        </SizeTracker>
      )}
    </>
  )
}

LineChart.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.number,
  keyXAxis: PropTypes.string.isRequired,
  keyYAxisProps: PropTypes.array.isRequired,
  isValue: PropTypes.bool,
}

export default LineChart
