import { useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import TopLabel from '../../common/TopLabel'
import useChartAPI from '../../common/useChartAPI'
import { CHART_ORDER } from '../../constants'
import style from '../../index.module.css'
import { BOTTOM_CHART_ID } from '../../panelChartBottom/PanelChartBottom'
import { TOP_CHART_ID } from '../../panelChartTop/PanelChartTop'
import {
  selectIncomeStructure,
  selectIncomeStructureLoading,
} from '../../store/slice'
import ChartComponent from './ChartComponent'
import ValueFrame from './ValueFrame'

const StackedBarChart = ({ height, width, activeTab, chartType }) => {
  const { CHART_1 } = CHART_ORDER

  const incomeStructureData = useSelector(selectIncomeStructure)
  const incomeStructureLoading = useSelector(selectIncomeStructureLoading)

  const [data, loading] = useChartAPI(chartType)

  return (
    <div className="h-100">
      {(loading || incomeStructureLoading) && <Loading />}
      {!loading && !incomeStructureLoading && (
        <>
          {(data?.length === 0 || incomeStructureData.length === 0) && (
            <NoData />
          )}
          {data?.length > 0 && incomeStructureData.length > 0 && (
            <SizeTracker className={style.heightAuto}>
              {(size) => {
                return (
                  <>
                    <ValueFrame activeTab={activeTab} chartType={chartType} />
                    <div
                      id={
                        chartType === CHART_1 ? TOP_CHART_ID : BOTTOM_CHART_ID
                      }
                    >
                      <TopLabel isChart1={chartType === CHART_1} />
                      {(size.height || size.height === 0) && (
                        <ChartComponent
                          data={data}
                          width={width}
                          height={height - size.height}
                          keyXAxis={chartType === CHART_1 ? 'year' : 'ticker'}
                          activeTab={activeTab}
                        />
                      )}
                    </div>
                  </>
                )
              }}
            </SizeTracker>
          )}
        </>
      )}
    </div>
  )
}

export default StackedBarChart
