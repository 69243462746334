import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FisheryService from '../../../../../../../core/services/sector/sectorSpecific/FisheryService'

export const getImportGrowthByProduct = createAsyncThunk(
  'sector/sectorSpecific/fishery/importByMarket/getImportGrowthByProduct',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FisheryService.getImportGrowthByProduct(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getImportGrowthByMarket = createAsyncThunk(
  'sector/sectorSpecific/fishery/importByMarket/getImportGrowthByMarket',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FisheryService.getImportGrowthByMarket(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
