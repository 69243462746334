import { createSlice } from '@reduxjs/toolkit'
import { screens } from '../../../constants/Common'
import { register } from '../../../utils/ReducerRegistry'
import { searchCompanyOrIndexThunk, searchCompanyThunk } from './thunk'

export const loadingKey = {
  SEARCH_COMPANY: 'searchCompany',
  SEARCH_COMPANY_OR_INDEX: 'searchCompanyOrIndex',
}

const initialState = {}

Object.keys(screens).forEach((key) => {
  initialState[screens[key]] = {
    loading: {
      searchCompany: false,
      searchCompanyOrIndex: false,
    },
    companies: [],
    companyOrIndex: [],
  }
})

export const slice = createSlice({
  name: 'common/simpleSearch',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    clearCompanies(state, action) {
      state[action.payload].companies = []
    },
  },

  extraReducers: (builder) => {
    // search company
    builder.addCase(searchCompanyThunk.pending, (state, action) => {
      state[action.meta.arg.screen].loading.searchCompany = true
    })
    builder.addCase(searchCompanyThunk.fulfilled, (state, action) => {
      state[action.meta.arg.screen].companies = action.payload || []
      state[action.meta.arg.screen].loading.searchCompany = false
    })
    builder.addCase(searchCompanyThunk.rejected, (state, action) => {
      state[action.meta.arg.screen].companies = []
      state[action.meta.arg.screen].loading.searchCompany = false
    })

    // search company or index
    builder.addCase(searchCompanyOrIndexThunk.pending, (state, action) => {
      state[action.meta.arg.screen].loading.searchCompanyOrIndex = true
    })
    builder.addCase(searchCompanyOrIndexThunk.fulfilled, (state, action) => {
      state[action.meta.arg.screen].companyOrIndex = action.payload || []
      state[action.meta.arg.screen].loading.searchCompanyOrIndex = false
    })
    builder.addCase(searchCompanyOrIndexThunk.rejected, (state, action) => {
      state[action.meta.arg.screen].companyOrIndex = []
      state[action.meta.arg.screen].loading.searchCompanyOrIndex = false
    })
  },
})

export const { resetStore, clearCompanies } = slice.actions

export const selectLoading = (screen) => (key) => (state) =>
  state.simpleSearch[screen].loading[key]
export const selectCompanies = (screen) => (state) =>
  state.simpleSearch[screen].companies
export const selectCompanyOrIndex = (screen) => (state) =>
  state.simpleSearch[screen].companyOrIndex

register('simpleSearch', slice.reducer)
