import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { ITEM_TYPE } from '../../constant'
import { getReportData } from './thunk'

const initialState = {
  loading: true,
  valueById: {},
  rowIds: [],
  reCalcWidths: false,
  page: 1,
  totalPage: 1,
  pageSize: 25,
}

const slice = createSlice({
  name: 'sector/sectorConstituents/valuation/report',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changePage: (state, action) => {
      state.page = action.payload
    },
    changeDataEmpty: (state) => {
      state.valueById = {}
      state.rowIds = []
      state.page = 1
      state.totalPage = 1
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReportData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getReportData.fulfilled, (state, action) => {
      const { page = 1, totalPage = 1, data = [] } = action.payload
      const newData = JSON.parse(JSON.stringify(data))
      newData.sort(
        (a, b) => new Date(b.publicDateId) - new Date(a.publicDateId),
      )
      newData.forEach((item, index) => {
        item.rowId = `${index}-${
          item.organizationId ? ITEM_TYPE.TICKER : ITEM_TYPE.SECTOR
        }-${item.organizationId || item.icbId}`
      })
      state.rowIds = newData.map((item) => item.rowId)
      state.valueById = keyBy(newData, 'rowId')
      state.loading = false
      state.reCalcWidths = !state.reCalcWidths
      state.page = page
      state.totalPage = totalPage
    })
    builder.addCase(getReportData.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectRowIds = (state) => state[slice.name].rowIds
export const selectLoading = (state) => state[slice.name].loading
export const selectValueById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].valueById[id], attr)
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths
export const selectPage = (state) => state[slice.name].page
export const selectTotalPage = (state) => state[slice.name].totalPage
export const selectPageSize = (state) => state[slice.name].pageSize

export const { resetStore, changePage, changeDataEmpty } = slice.actions

register(slice.name, slice.reducer)
