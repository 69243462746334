export const genFooterData = (chartData, start, end) => {
  if (chartData.length > 0) {
    return chartData
      .filter((_, index) => index >= start && index <= end)
      .map((item) => {
        const data = {
          text: item.text,
          type: item.type,
          isI18n: item.isI18n,
          before: {
            bgColor: item.color,
          },
          after: {
            bgColor: item.color,
          },
          ...item,
        }
        if (item.dataKey) {
          data.dataKey = item.dataKey
        }
        return data
      })
  }

  return []
}

export const chunkArray = (myArray, chunk_size) => {
  const results = []
  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size))
  }
  return results
}
