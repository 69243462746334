import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { I18n, Translate } from 'react-redux-i18n'
import DataExplorerService from '../../../../../core/services/tool/dataExplorer/DataExplorerService'
import IndicesSectorServices from '../../../../../core/services/tool/dataExplorer/IndicesSectorServices'
import EventEmitter, {
  BUTTON_TEXT_POPUP_ERROR,
  ICON_STATUS_POPUP_ERROR,
  IS_SHOW_BUTTON_ERROR,
  SHOW_POPUP_ERROR,
} from '../../../../utils/EventEmitter'
import { handleExport } from '../../../../utils/Export'
import { typeData, typeWorkSpace } from '../../constant'
import { convertParamGetMostRecent } from '../helper'
import { openTemplate } from './slice'

const maxPageSize = 500

export const getIndicatorThunk = createAsyncThunk(
  'dataExplorer/indicesSector/GET_INDICATOR',
  async (data, { rejectWithValue }) => {
    try {
      const response = await IndicesSectorServices.getIndicator(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getResultThunk = createAsyncThunk(
  'dataExplorer/indicesSector/GET_RESULT',
  async (data, { rejectWithValue }) => {
    const arrResponse = await Promise.all(
      data.map((params) => IndicesSectorServices.getResult(params)),
    )
    if (
      arrResponse.length &&
      arrResponse.every((response) => response.success)
    ) {
      return arrResponse.reduce((data, response) => {
        return data.concat(response.data)
      }, [])
    }
    return rejectWithValue('err')
  },
)

export const exportExcelThunk = createAsyncThunk(
  'dataExplorer/indicesSector/EXPORT_EXCEL',
  async (data) => {
    const response = await IndicesSectorServices.exportExcel(data)
    if (response) {
      handleExport(response.data, response.filename)
    }
  },
)

export const getTemplatesThunk = createAsyncThunk(
  'dataExplorer/indicesSector/GET_TEMPLATES',
  async (_, { rejectWithValue }) => {
    const response = await DataExplorerService.getTemplates({
      DataType: typeData.INDEX_SECTOR,
      Page: 1,
      PageSize: maxPageSize,
    })
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const saveTemplateThunk = createAsyncThunk(
  'dataExplorer/indicesSector/SAVE_TEMPLATE',
  async (data, { dispatch }) => {
    const response = await DataExplorerService.saveTemplate(data)
    if (response.success) {
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
      EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <div className="mb-20">
          <div className="centerItems mb-20">
            <img src="/iconLike.svg" alt="Like" width={38} height={46} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.CREATE_TEMPLATE_SUCCESS" />
          </div>
        </div>,
      ])
      dispatch(getTemplatesThunk())
    }
  },
)

export const updateTemplateThunk = createAsyncThunk(
  'dataExplorer/indicesSector/UPDATE_TEMPLATE',
  async (data, { dispatch }) => {
    const response = await DataExplorerService.updateTemplate(data)
    if (response.success) {
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
      EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <div className="mb-20">
          <div className="centerItems mb-20">
            <img src="/iconLike.svg" alt="Like" width={38} height={46} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.UPDATE_TEMPLATE_SUCCESS" />
          </div>
        </div>,
      ])
      dispatch(getTemplatesThunk())
    }
  },
)

export const renameTemplateThunk = createAsyncThunk(
  'dataExplorer/indicesSector/RENAME_TEMPLATE',
  async (data, { dispatch }) => {
    const response = await DataExplorerService.renameTemplate(data)
    if (response.success) {
      dispatch(getTemplatesThunk())
    }
  },
)

export const deleteTemplateThunk = createAsyncThunk(
  'dataExplorer/indicesSector/DELETE_TEMPLATE',
  async (data, { dispatch }) => {
    const response = await DataExplorerService.deleteTemplate(data)
    if (response.success) {
      dispatch(getTemplatesThunk())
    }
  },
)

export const getTemplateThunk = createAsyncThunk(
  'dataExplorer/indicesSector/GET_TEMPLATE',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await DataExplorerService.getTemplate(data)
    if (response.success) {
      const paramMostRecentDate = Object.values(
        response.data.parameters.rawParameter.columnById,
      )
      if (paramMostRecentDate.length) {
        dispatch(
          getMostRecentThunk(convertParamGetMostRecent(paramMostRecentDate)),
        )
      }
      dispatch(openTemplate(response.data))
    }
    return rejectWithValue(response.errors)
  },
)

export const getShareTemplateThunk = createAsyncThunk(
  'dataExplorer/indicesSector/GET_SHARE_TEMPLATE',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await DataExplorerService.getShareTemplate(data)
    if (response.success) {
      const paramMostRecentDate = Object.values(
        response.data.parameters.rawParameter.columnById,
      )
      if (paramMostRecentDate.length) {
        dispatch(
          getMostRecentThunk(convertParamGetMostRecent(paramMostRecentDate)),
        )
      }
      dispatch(openTemplate(response.data))
    }
    return rejectWithValue(response.errors)
  },
)

export const getMostRecentThunk = createAsyncThunk(
  'dataExplorer/indicesSector/GET_MOST_RECENT',
  async (data, { rejectWithValue }) => {
    const response = await DataExplorerService.getMostRecent(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getWorkSpaceThunk = createAsyncThunk(
  'dataExplorer/indicesSector/GET_WORK_SPACE',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await DataExplorerService.getWorkSpace(data)
    if (response.success) {
      const data = response.data
      const workSpaces = data.filter(
        (item) => item.dataType === typeWorkSpace.INDEX_SECTOR,
      )

      workSpaces.forEach((workSpace) => {
        const { data } = workSpace.parameters.rawParameter

        const paramMostRecentDate = Object.values(data.columnById)

        if (paramMostRecentDate.length) {
          dispatch(
            getMostRecentThunk(convertParamGetMostRecent(paramMostRecentDate)),
          )
        }
      })
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
