import ConsumptionProxy from '../../../proxies/economy/productionAndConsumption/ConsumptionProxy'
import { ServiceBase } from '../../ServiceBase'

export class ConsumptionService extends ServiceBase {
  getCompareByTimeRange(params) {
    return this.getData(() => ConsumptionProxy.getCompareByTimeRange(params))
  }

  getListCriteriaByTimeRange(params) {
    return this.getData(() =>
      ConsumptionProxy.getListCriteriaByTimeRange(params),
    )
  }

  downloadCompareByTimeRange(params) {
    return this.getFileDownload(() =>
      ConsumptionProxy.downloadCompareByTimeRange(params),
    )
  }

  getGrowthByTimeRange(params) {
    return this.getData(() => ConsumptionProxy.getGrowthByTimeRange(params))
  }

  getRetailGrowthYbY(params) {
    return this.getData(() => ConsumptionProxy.getRetailGrowthYbY(params))
  }

  getChangesInRetailSalesComposition(params) {
    return this.getData(() =>
      ConsumptionProxy.getChangesInRetailSalesComposition(params),
    )
  }

  getMaxDate(params) {
    return this.getData(() => ConsumptionProxy.getMaxDate(params))
  }
}

export default new ConsumptionService()
