import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class CompanyProxy extends ProxyBase {
  postTemplates(params) {
    return this.post('Templates', params, {}, 'post', true)
  }

  updateTemplate(params) {
    return this.put('Templates', params, 'get', true)
  }

  getTemplate(params) {
    return this.get('Templates', params)
  }

  deleteTemplate(params) {
    return this.delete('Templates', params)
  }

  getMapping_GetAll(params) {
    return this.get('Mapping/GetAll', params)
  }
}

export default new CompanyProxy(ServiceProxyConfig.Corporate.Company.ServiceUrl)
