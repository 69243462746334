import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PriceStatisticService from '../../../../../../../core/services/market/equityTrading/PriceStatisticService'
import { handleExport } from '../../../../../../utils/Export'

export const getByInvestor = createAsyncThunk(
  'market/equityTrading/priceStatistics/summary/statisticInvest/GET_BY_INVESTOR',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PriceStatisticService.getByInvestor(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadByInvestor = createAsyncThunk(
  'market/equityTrading/priceStatistics/summary/statisticInvest/DOWNLOAD_BY_INVESTOR',
  async (data) => {
    const response = await PriceStatisticService.downloadByInvestor(data)
    handleExport(response.data, response.filename)
  },
)
