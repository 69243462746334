import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import PopupEvent from '../../../common/popup/PopupEvent'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import TextEllipsis from '../../../common/textEllipsis'
import { debounce } from '../../../utils/Common'
import { maxTemplate } from '../constant'
import style from '../index.module.css'
import {
  key,
  selectLoading,
  selectTemplate,
  selectTemplates,
} from '../store/slice'
import {
  deleteTemplateThunk,
  getTemplatesThunk,
  getTemplateThunk,
  renameTemplateThunk,
} from '../store/thunk'

const idDivFooterScroll = 'div-footer-scroll'

const enterKey = 13

const ItemTemplate = ({ item, index, listFullTemplate, valueSearch }) => {
  const dispatch = useDispatch()

  const inputRef = useRef()
  const templateRef = useRef()

  const templateIdActive = useSelector(selectTemplate)?.templateId

  const [isHover, setIsHover] = useState(false)
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [isDropdownTop, setIsDropdownTop] = useState(false)
  const [isShowEdit, setIsShowEdit] = useState(false)
  const [valueInput, setValueInput] = useState(item.templateName)
  const [error, setError] = useState(null)
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false)

  const handleMouseOver = () => setIsHover(true)

  const handleMouseOut = () => setIsHover(false)

  const handleOpenDropdown = () => {
    const heightFromFooter =
      document.querySelector(`#${idDivFooterScroll}`).getBoundingClientRect()
        .top - templateRef.current.getBoundingClientRect().top
    if (heightFromFooter <= 100) setIsDropdownTop(true)
    else setIsDropdownTop(false)

    setIsShowDropdown(true)
  }

  const handleCloseDropdown = () => setIsShowDropdown(false)

  const handleShowEdit = () => {
    setIsShowEdit(true)
    setTimeout(() => inputRef.current?.focus(), 100)
  }

  const handleChangeInput = (e) => {
    const value = e.target.value
    setValueInput(value)
    if (
      listFullTemplate.current.some(
        (template) =>
          template.templateName === value.trim() &&
          template.templateId !== item.templateId,
      )
    )
      setError('tool.smartScreening.TEMPLATE_EXIST')
    else setError(null)
  }

  const handlePressEnter = (e) => {
    if (e.keyCode === enterKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    if (
      valueInput.trim() &&
      !error &&
      valueInput.trim() !== item.templateName
    ) {
      dispatch(
        renameTemplateThunk({
          params: {
            templateId: item.templateId,
            oldTemplateName: item.templateName,
            newTemplateName: valueInput.trim(),
          },
          valueSearch,
        }),
      )
    } else {
      setValueInput(item.templateName)
      setError(null)
    }
    setIsShowEdit(false)
    setIsHover(false)
    setIsShowDropdown(false)
  }

  const getTemplate = () =>
    dispatch(getTemplateThunk({ TemplateId: item.templateId }))

  const deleteTemplate = () =>
    dispatch(
      deleteTemplateThunk({
        params: { TemplateId: item.templateId },
        valueSearch,
      }),
    )

  const openPopupDelete = () => setIsShowPopupDelete(true)

  const hidePopupDelete = () => setIsShowPopupDelete(false)

  return (
    <>
      <div
        ref={templateRef}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={`item-temp d-flex ${isHover ? 'hover' : ''} ${
          style.itemTemplate
        } ${templateIdActive === item.templateId ? 'active' : ''}`}
      >
        <span>{index + 1}.</span>
        {isShowEdit ? (
          <>
            <div className={`${style.w88} ${style.relative}`}>
              <Input
                onBlur={handleSubmit}
                onKeyDown={handlePressEnter}
                ref={inputRef}
                type="text"
                value={valueInput}
                onChange={handleChangeInput}
                className={`${style.inputTemplate} w-100`}
              />
              {error && (
                <div
                  style={{ maxWidth: `${inputRef.current?.offsetWidth}px` }}
                  className={`box-notice ${
                    ![0, 1].includes(index)
                      ? style.tooltipError
                      : style.tooltipErrorBottom
                  }`}
                >
                  <Span style={{ fontSize: 9 }}>
                    {<Translate value={error} dangerousHTML />}
                  </Span>
                </div>
              )}
            </div>
            <button
              onClick={handleSubmit}
              className={`btn-ok ${style.pointer} ${style.btnOk}`}
            >
              <i className="icon-true" />
            </button>
          </>
        ) : (
          <div
            className={`${style.nameTemplate} ${style.w88} d-flex ali-center`}
          >
            <div
              onDoubleClick={getTemplate}
              className={`cursor-pointer ${style.textTemplate}`}
            >
              <TextEllipsis isI18n={false} text={item.templateName} />
            </div>
            <div
              onMouseOver={handleOpenDropdown}
              onMouseOut={handleCloseDropdown}
              className="hover-dropdown d-flex justify-content-center"
            >
              <button className="btn-more">
                <i className="icon-menu-dot-hor" />
              </button>
              <div
                className={`dropdown-container ${
                  isShowDropdown ? 'active' : ''
                } ${isDropdownTop ? style.dropdownTop : ''}`}
              >
                <ul>
                  <li>
                    <button onClick={handleShowEdit} className="btn-watchlist">
                      <i className="icon-edit" />
                      <Translate value="tool.smartScreening.RENAME" />
                    </button>
                  </li>
                  <li>
                    <button onClick={openPopupDelete} className="btn-watchlist">
                      <i className="icon-trash" />
                      <Translate value="tool.smartScreening.DELETE" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      {isShowPopupDelete && (
        <PopupEvent
          title={
            <Translate value="tool.smartScreening.TITLE_DELETE_TEMPLATE" />
          }
          message={
            <>
              <Translate value="tool.smartScreening.ASK_DELETE_TEMPLATE" />
              {item.templateName}?
            </>
          }
          isShow={isShowPopupDelete}
          handleClose={hidePopupDelete}
          btnCancel={{
            btnName: <Translate value="common.button.BUTTON_CANCEL" />,
            event: hidePopupDelete,
          }}
          btnSubmit={{
            btnName: <Translate value="common.button.BUTTON_OK" />,
            event: deleteTemplate,
          }}
        />
      )}
    </>
  )
}

ItemTemplate.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  listFullTemplate: PropTypes.object.isRequired,
  valueSearch: PropTypes.string.isRequired,
}

const Template = ({ loadingRatio, loadingFinancialStatement }) => {
  const dispatch = useDispatch()

  const templates = useSelector(selectTemplates)
  const loading = useSelector(selectLoading(key.TEMPLATES))

  const listFullTemplate = useRef([])

  const [valueSearch, setValueSearch] = useState('')

  useEffect(() => {
    if (listFullTemplate.current.length === 0) {
      listFullTemplate.current = templates
    }
  }, [templates])

  useEffect(() => {
    handleGetTemplate(valueSearch)
  }, [valueSearch])

  const handleGetTemplate = useCallback(
    debounce(
      (value) =>
        dispatch(
          getTemplatesThunk({
            PageSize: maxTemplate,
            TemplateName: value,
          }),
        ),
      500,
    ),
    [],
  )

  const handleChangeInput = (e) => setValueSearch(e.target.value)

  return (
    <SizeTracker>
      {(size) => {
        return (
          <div className="h-100">
            <div className="form-search-inbox mb-10">
              <Input
                type="text"
                value={valueSearch}
                onChange={handleChangeInput}
                className={`search-inbox bg-grey h-20 ${style.search}`}
                placeholder={I18n.t('tool.smartScreening.SEARCH')}
              />
              <button type="button" className={style.btnSearch}>
                <i className="icon-search-2" />
              </button>
            </div>
            {size.height && (
              <div
                className="box_template"
                style={{ height: `calc(100% - ${size.height}px)` }}
              >
                <ScrollComponent>
                  {loading || loadingRatio || loadingFinancialStatement ? (
                    <Loading />
                  ) : (
                    <div className="lst-temp">
                      {templates.map((item, index) => {
                        return (
                          <ItemTemplate
                            key={item.templateId}
                            item={item}
                            index={index}
                            valueSearch={valueSearch}
                            listFullTemplate={listFullTemplate}
                          />
                        )
                      })}
                    </div>
                  )}
                </ScrollComponent>
                <div id={idDivFooterScroll} />
              </div>
            )}
          </div>
        )
      }}
    </SizeTracker>
  )
}

Template.propTypes = {
  loadingRatio: PropTypes.bool.isRequired,
  loadingFinancialStatement: PropTypes.bool.isRequired,
}

export default Template
