import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  i18nReducer,
  loadTranslations,
  syncTranslationWithStore,
} from 'react-redux-i18n'
import * as i18n from '../components/lang/i18n'
import {
  reducers,
  setChangeListener,
} from '../components/utils/ReducerRegistry'

export const store = configureStore({
  reducer: {
    i18n: i18nReducer,
    ...reducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
})

setChangeListener((reducers) => {
  store.replaceReducer(
    combineReducers({
      i18n: i18nReducer,
      ...reducers,
    }),
  )
})

syncTranslationWithStore(store)
store.dispatch(loadTranslations(i18n))
