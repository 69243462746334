import FundStatisticsProxy from '../../proxies/fund/FundStatisticsProxy'
import { ServiceBase } from '../ServiceBase'

export class FundStatisticsService extends ServiceBase {
  getFundManagementCompany(params) {
    return this.applyMemoryCache(
      'FundStatisticsProxy.getFundManagementCompany',
      params,
    )(() => FundStatisticsProxy.getFundManagementCompany(params))
  }

  getKeyMetric(params) {
    return this.applyMemoryCache(
      'FundStatisticsProxy.getKeyMetric',
      params,
    )(() => FundStatisticsProxy.getKeyMetric(params))
  }

  downloadKeyMetric(params) {
    return this.getFileDownload(() =>
      FundStatisticsProxy.downloadKeyMetric(params),
    )
  }

  getPerformance(params) {
    return this.applyMemoryCache(
      'FundStatisticsProxy.getPerformance',
      params,
    )(() => FundStatisticsProxy.getPerformance(params))
  }

  downloadPerformance(params) {
    return this.getFileDownload(() =>
      FundStatisticsProxy.downloadPerformance(params),
    )
  }

  getStatisticsFundFlow(params) {
    return this.applyMemoryCache(
      'FundStatisticsProxy.getStatisticsFundFlow',
      params,
    )(() => FundStatisticsProxy.getStatisticsFundFlow(params))
  }

  downloadStatisticsFundFlow(params) {
    return this.getFileDownload(() =>
      FundStatisticsProxy.downloadStatisticsFundFlow(params),
    )
  }

  getSectorAllocation(params) {
    return this.applyMemoryCache(
      'FundStatisticsProxy.getSectorAllocation',
      params,
    )(() => FundStatisticsProxy.getSectorAllocation(params))
  }

  downloadSectorAllocation(params) {
    return this.getFileDownload(() =>
      FundStatisticsProxy.downloadSectorAllocation(params),
    )
  }

  getAssetAllocation(params) {
    return this.applyMemoryCache(
      'FundStatisticsProxy.getAssetAllocation',
      params,
    )(() => FundStatisticsProxy.getAssetAllocation(params))
  }

  downloadAssetAllocation(params) {
    return this.getFileDownload(() =>
      FundStatisticsProxy.downloadAssetAllocation(params),
    )
  }

  getFundIndex(params) {
    return this.applyMemoryCache(
      'FundStatisticsProxy.getFundIndex',
      params,
    )(() => FundStatisticsProxy.getFundIndex(params))
  }

  downloadFundIndex(params) {
    return this.getFileDownload(() =>
      FundStatisticsProxy.downloadFundIndex(params),
    )
  }
}

export default new FundStatisticsService()
