import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

class RankingProxy extends ProxyBase {
  getDataTable(params) {
    return this.get('DataTable', params)
  }

  getTooltipScoreResult(params) {
    return this.get('TooltipScoreResult', params)
  }

  getTooltipTicker(params) {
    return this.get('TooltipTicker', params)
  }

  downloadDataTable(params) {
    return this.get('DownloadDataTable', params, 'download')
  }
}

export default new RankingProxy(
  ServiceProxyConfig.Sector.SectorConstituents.Ranking.ServiceUrl,
)
