import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

export const ReportIssuer = ({ data, rowId, selectReport }) => {
  const organizationShortName = useSelector(
    selectReport(rowId, 'organizationShortName'),
  )
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {data || organizationShortName}
    </span>
  )
}

ReportIssuer.propTypes = {
  data: PropTypes.string,
  rowId: PropTypes.number.isRequired,
  selectReport: PropTypes.func.isRequired,
}
