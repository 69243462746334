import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, ReferenceLine, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { valDivBillion } from '../../../../utils/Value'
import { BAR_COLOR_LIQUIDITY_CHART } from '../constants'
import {
  selectDataLiquidity,
  selectFilterLiquidity,
  selectLoadingLiquidity,
} from '../tradingStatistics/store/slice'
import RemainingMaturitiesChartTooltip, {
  renderDate,
} from './LiquidityChartTooltip'

const LiquidityChart = ({ sizeHeight, width, height }) => {
  const isLoading = useSelector(selectLoadingLiquidity)
  const data = useSelector(selectDataLiquidity)
  const locale = useSelector((state) => state.i18n.locale)
  const activeTab = useSelector(selectFilterLiquidity)
  const timeZone = UseTimeZone()

  const formatDataBarChart = useMemo(
    () =>
      data
        .map((item) => ({
          ...item,
          date: renderDate(activeTab, locale, item.date, timeZone),
          avg1Day:
            item.avg1Day === 0 ? item.avg1Day : valDivBillion(item.avg1Day),
          totalDealValue:
            item.totalDealValue === 0
              ? item.totalDealValue
              : valDivBillion(item.totalDealValue),
          totalMatchValue:
            item.totalMatchValue === 0
              ? item.totalMatchValue
              : valDivBillion(item.totalMatchValue),
        }))
        .reverse(),
    [data],
  )

  const renderTooltip = (dataChart) => (
    <RemainingMaturitiesChartTooltip dataChart={dataChart} />
  )

  return (
    <>
      {sizeHeight && (
        <div id="liquidityChartData" style={{ height: height - sizeHeight }}>
          {isLoading ? (
            <Loading />
          ) : !!formatDataBarChart.length ? (
            <>
              <div style={{ height: height - sizeHeight - 34 }}>
                <ChartContainer
                  data={formatDataBarChart}
                  width={width}
                  height={height - sizeHeight - 20}
                  keyXAxis="date"
                  yAxis={[
                    {
                      id: 'left',
                      keys: ['totalDealValue', 'totalDealValue'],
                      orientation: 'left',
                      label: I18n.t(
                        'bond.corporateBond.remainingMaturities.VALUE',
                      ),
                      labelPosition: AXIS_LABEL_POSITION.LEFT,
                      tickNum: 10,
                      isBarChart: true,
                      min: 0,
                    },
                    {
                      id: 'right',
                      keys: ['avg1Day'],
                      orientation: 'right',
                      isLineChart: true,
                      label: I18n.t(
                        'bond.corporateBond.remainingMaturities.VALUE',
                      ),
                      labelPosition: AXIS_LABEL_POSITION.RIGHT,
                      tickNum: 10,

                      min: 0,
                    },
                  ]}
                  margin={{ left: 0, right: 16, top: 20, bottom: 10 }}
                  renderCustomTooltip={renderTooltip}
                >
                  {({ chartContentWidth }) => (
                    <>
                      <Bar
                        stackId="stackId2"
                        isAnimationActive={false}
                        yAxisId="left"
                        dataKey="totalMatchValue"
                        fill={BAR_COLOR_LIQUIDITY_CHART[0]}
                        barSize={30}
                      />
                      <Bar
                        stackId="stackId2"
                        isAnimationActive={false}
                        yAxisId="left"
                        dataKey="totalDealValue"
                        fill={BAR_COLOR_LIQUIDITY_CHART[1]}
                        barSize={30}
                      />
                      <Scatter
                        yAxisId="right"
                        dataKey="avg1Day"
                        isAnimationActive={false}
                        line
                        // stroke={BAR_COLOR_LIQUIDITY_CHART[2]}
                        fill={BAR_COLOR_LIQUIDITY_CHART[2]}
                        strokeWidth={1}
                        strokeDasharray="5 5"
                      />
                      <ReferenceLine
                        yAxisId="right"
                        y={
                          !!data.length
                            ? data[0].averageCouponInterestRateAllDuration * 100
                            : 0
                        }
                        stroke={BAR_COLOR_LIQUIDITY_CHART[1]}
                        strokeWidth={1}
                        strokeDasharray="5 5"
                      />
                    </>
                  )}
                </ChartContainer>
              </div>
              <Footer
                key={width}
                list={[
                  {
                    text: 'bond.corporateBond.remainingMaturities.MATCHED',
                    type: TYPE.SQUARE,
                    before: {
                      bgColor: BAR_COLOR_LIQUIDITY_CHART[0],
                    },
                  },
                  {
                    text: 'bond.corporateBond.remainingMaturities.DEAL',
                    type: TYPE.SQUARE,
                    before: {
                      bgColor: BAR_COLOR_LIQUIDITY_CHART[1],
                    },
                  },
                  {
                    text: 'bond.corporateBond.remainingMaturities.AVERAGE',
                    type: TYPE.DASHED_LINE,
                    dashedLine: {
                      bgColor: BAR_COLOR_LIQUIDITY_CHART[2],
                    },
                  },
                ]}
              />
            </>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </>
  )
}

LiquidityChart.propTypes = {
  sizeHeight: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default LiquidityChart
