import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { NoData } from '../../../../common/noData'
import Popup from '../../../../common/popup'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { Search } from '../../../../common/search'
import SelectCustom from '../../../../common/selectCustom'
import TextEllipsis from '../../../../common/textEllipsis'
import { Z_INDEX } from '../../../../constants/Common'
import {
  COMPARE_MAX_ADDED,
  constantExchangeCode,
  listExchangeCode,
} from '../constants'
import {
  changePopupPeerAnalysis,
  selectDataFDF,
  selectPopupPeerAnalysis,
} from '../store/slice'
import style from '../style.module.css'
import ItemCompare from './ItemCompare'

const PopupSettingPeerAnalysis = () => {
  const data = useSelector(selectDataFDF)

  const [isShow, setIsShow] = useState(false)

  const showPopup = () => {
    setIsShow(true)
  }

  return (
    <>
      <i
        className={`${style.iconSettingHeader} icon-setting-stroke`}
        onClick={showPopup}
      />
      <SettingPeerAnalysis
        isShow={isShow}
        setIsShow={setIsShow}
        peerAnalysisData={data.peerAnalysis}
      />
    </>
  )
}

export default PopupSettingPeerAnalysis

const SettingPeerAnalysis = ({ isShow, setIsShow, peerAnalysisData }) => {
  const dispatch = useDispatch()
  const listPeerAnalysisChecked = useSelector(selectPopupPeerAnalysis)
  const [keySearch, setSearch] = useState('')
  const [listTicker, setListTicker] = useState(listPeerAnalysisChecked)
  const [valueDropdown, setValueDropdown] = useState(
    constantExchangeCode.ALL_EXCHANGE,
  )

  const handleCancel = () => {
    setIsShow(false)
    setSearch('')
    setValueDropdown(constantExchangeCode.ALL_EXCHANGE)
  }

  const handleApply = () => {
    handleCancel()
    dispatch(changePopupPeerAnalysis(listTicker))
  }

  const filterDataCompareList = () => {
    return peerAnalysisData.filter(
      (item) =>
        item.ticker?.toLowerCase().includes(keySearch) &&
        (valueDropdown === 'ALL_EXCHANGE'
          ? true
          : item.exchangeCode === valueDropdown),
    )
  }

  const onRowClick = (val) => {
    let newArray = listTicker
    const condition = !newArray.some(
      (i) => i.organizationId === val.organizationId,
    )
    if (condition) {
      newArray = [...newArray, val]
    } else {
      newArray = newArray.filter((i) => i.organizationId !== val.organizationId)
    }
    setListTicker(newArray)
  }

  useEffect(() => {
    if (listPeerAnalysisChecked.length) {
      setListTicker(listPeerAnalysisChecked)
    } else {
      setListTicker(peerAnalysisData.slice(0, 3))
    }
  }, [listPeerAnalysisChecked])

  return (
    <>
      {isShow ? (
        <Popup>
          <div className="modal modal-medium" style={{ minWidth: 594 }}>
            <div className="modal-title">
              <h3 className="text-up">
                <Translate value="corporate.companySnapshot.PEER_ANALYSIS_SETTING" />
              </h3>
              <a onClick={handleCancel} className="close-modal">
                <i className="icon-delete-stroke" />
              </a>
            </div>

            <div className="modal-content">
              <div className="mb-8">
                <Search onChange={setSearch} margin={'0px'} width={246} />
              </div>
              <DropdownFilter changeValueDropdown={setValueDropdown} />
              <>
                <ScrollComponent
                  autoHeight={true}
                  autoHeightMax={440}
                  appendStyle={{
                    backgroundColor: '#151b25',
                    borderRadius: '0px 0px 4px 4px',
                  }}
                >
                  <div style={{ height: 440 }}>
                    <table
                      style={{ width: '100%' }}
                      className={style.customTable}
                    >
                      <tbody>
                        {filterDataCompareList()?.length > 0
                          ? filterDataCompareList().map((item, index) => {
                              return (
                                <ItemCompare
                                  item={item}
                                  key={index}
                                  onRowClick={onRowClick}
                                  listTicker={listTicker}
                                />
                              )
                            })
                          : null}
                      </tbody>
                    </table>
                    {filterDataCompareList()?.length === 0 && <NoData />}
                  </div>
                </ScrollComponent>
              </>
              <div
                className={style.warning}
                style={{
                  opacity: listTicker.length >= COMPARE_MAX_ADDED ? 1 : 0,
                }}
              >
                <Translate value="corporate.companySnapshot.TEXT_WARNING_ADD_ITEM" />
              </div>
              <div className="d-flex justify-content-end mt-9">
                <Button
                  style={{ fontSize: 11 }}
                  className={`btn w-80 h-20 ${style.btnCancel} ${style.mr16}`}
                  onClick={handleCancel}
                >
                  <Translate value="corporate.companySnapshot.CANCEL" />
                </Button>
                <Button
                  style={{ fontSize: 11 }}
                  className={`btn btn-blue w-80 h-20`}
                  onClick={handleApply}
                >
                  <Translate value="corporate.companySnapshot.APPLY" />
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      ) : (
        <></>
      )}
    </>
  )
}

const DropdownFilter = ({ changeValueDropdown }) => {
  const [valueDropdown, setValueDropdown] = useState(
    constantExchangeCode.ALL_EXCHANGE,
  )

  const onChange = (val) => {
    changeValueDropdown(val)
    setValueDropdown(val)
  }

  return (
    <div className={style.dropdownFilter}>
      <div className="ml-8" style={{ width: '150px', padding: '8px 0px' }}>
        <SelectCustom
          value={valueDropdown}
          isI18n={true}
          selectData={listExchangeCode}
          handleChange={onChange}
          renderSelect={({ isShowDropdown, styleComponent }) => {
            return (
              <span className={style.select}>
                <span
                  className={`${styleComponent.text}`}
                  style={{ marginLeft: 0 }}
                >
                  <TextEllipsis
                    isI18n={true}
                    text={
                      listExchangeCode?.find(
                        (item) => item.value === valueDropdown,
                      )?.name
                    }
                    zIndexTooltip={Z_INDEX.DROPDOWN + 1}
                  />
                </span>
                <i
                  className={`${
                    !isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
                  } ${styleComponent.icon}`}
                />
              </span>
            )
          }}
        />
      </div>
    </div>
  )
}
