import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { VIEW_TYPE } from '../../../chart/constants'
import { equationIndicatorColWidth } from '../constants'
import { changeEquationIndicators } from '../store/slice'
import style from '../style.module.css'

export const IndicatorSelectTitle = ({ viewType, colNameWidth }) => {
  const dispatch = useDispatch()

  const handleDeleteAllIndicators = () => {
    dispatch(changeEquationIndicators())
  }

  return (
    <div className={style.equationListItem}>
      <div style={{ width: equationIndicatorColWidth.alias }} />
      <div style={{ width: colNameWidth }} />
      <div style={{ width: equationIndicatorColWidth.valueFrom }}>
        <Translate value="financialChart.EQUATION_FROM_VALUE" />
      </div>
      <div style={{ width: equationIndicatorColWidth.divider }}></div>
      <div style={{ width: equationIndicatorColWidth.valueTo }}>
        <Translate value="financialChart.EQUATION_TO_VALUE" />
      </div>
      <div style={{ width: equationIndicatorColWidth.unit }}>
        <div className={style.equationListItemColContentUnit}>
          <Translate value="financialChart.EQUATION_UNIT" />
        </div>
      </div>
      {viewType === VIEW_TYPE.BY_SECURITIES && (
        <div style={{ width: equationIndicatorColWidth.timeGroup }}>
          <Translate value="financialChart.EQUATION_DATE" />
        </div>
      )}
      <div style={{ width: equationIndicatorColWidth.iconDelete }}>
        <div
          className={style.equationListIndicatorItemDelete}
          onClick={handleDeleteAllIndicators}
        >
          <i
            style={{ fontSize: 12, color: '#8B8B8B' }}
            className="icon-trash"
          />
          <div style={{ borderBottom: '1px solid #464a4e', marginTop: 4 }} />
        </div>
      </div>
    </div>
  )
}
