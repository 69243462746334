import InterconnectionMapProxy from '../../../proxies/bond/interconnectionMapProxy'
import { ServiceBase } from '../../ServiceBase'

export class InterconnectionMapSv extends ServiceBase {
  getDataList(params) {
    return this.getData(() => InterconnectionMapProxy.getDataProxy(params))
  }
  getInterConnectionMapSv(params) {
    return this.getData(() =>
      InterconnectionMapProxy.getInterConnectionMapProxy(params),
    )
  }
}

export default new InterconnectionMapSv()
