import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Input } from '../../../../common/html/Input'
import style from './index.module.css'
import { filterByText, selectFilterName } from './store/slice'

const Filter = () => {
  const dispatch = useDispatch()
  const valueFilter = useSelector(selectFilterName)
  const onSearchChange = (text) => {
    dispatch(filterByText(text))
  }

  return (
    <div style={{ paddingLeft: '8px' }}>
      <div
        className={`form-search-inbox ${style.searchColumn}`}
        style={{
          margin: '0px 0px 8px 0px',
        }}
      >
        <Input
          onChange={(e) => onSearchChange(e.target.value)}
          type="text"
          className="search-inbox"
          placeholder={UseI18n(
            'bond.interconnectionMap.businessInformation.filter.placeholerBusiness',
          )}
          value={valueFilter}
        />
        <button type="button">
          <i className="icon-search-2" />
        </button>
      </div>
    </div>
  )
}

export default Filter
