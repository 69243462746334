import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import style from './index.module.css'
import {
  getAllPageSorted,
  getFromISODateView,
  getFromISOMaxDateParam,
  getFromISOMinDateParam,
  getHeaderDate,
  getLimitTime,
  getSliceValue,
  getTimeRange,
  handleIsDecrease,
  handleIsRecalculateSliceValue,
  handleSliceValue,
} from './store/slice'
import { getCurrencyExchangeRate } from './store/thunk'
import { PAGE_NUMBER } from './TableComponent'

const DIFFERENT_INDEX_COL1_AND_3 = 2

const IconLeftRight = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  const sliceValue = useSelector(getSliceValue)
  const timeRange = useSelector(getTimeRange)
  const fromISOMinDateParam = useSelector(getFromISOMinDateParam)
  const fromISODateView = useSelector(getFromISODateView)
  const fromISOMaxDateParam = useSelector(getFromISOMaxDateParam)
  const headerDate = useSelector(getHeaderDate)
  const allPageSorted = useSelector(getAllPageSorted)
  const { minDate, maxDate } = useSelector(getLimitTime)
  const locale = useSelector((state) => state.i18n.locale)

  const formatMaxDate = formatDateTime(maxDate, FORMAT.DATE, locale, timeZone)
  const formatMinDate = formatDateTime(minDate, FORMAT.DATE, locale, timeZone)
  const isCancelDecreasePage = !!minDate && fromISODateView === formatMinDate

  const handleDecreasePage = () => {
    if (sliceValue < 2 * PAGE_NUMBER) {
      if (headerDate.includes(minDate)) {
        dispatch(handleSliceValue(PAGE_NUMBER + 1))
      } else {
        dispatch(
          getCurrencyExchangeRate({
            TimeFrequency: timeRange,
            From: fromISOMinDateParam,
          }),
        )
        dispatch(handleIsRecalculateSliceValue(true))
        dispatch(handleIsDecrease(true))
      }
    } else {
      dispatch(handleSliceValue(sliceValue - PAGE_NUMBER))
    }
  }

  const isCancelIncreasePage = () => {
    if (maxDate && allPageSorted.length) {
      const firstColumnCurrentPageIndex = allPageSorted.findIndex(
        (item) => item.dateAfterFormat === fromISODateView,
      )
      const lastColumnCurrentPage =
        allPageSorted[firstColumnCurrentPageIndex + DIFFERENT_INDEX_COL1_AND_3]

      if (lastColumnCurrentPage?.dateAfterFormat === formatMaxDate) {
        return true
      }
    }

    return false
  }

  const handleIncreasePage = () => {
    if (sliceValue > headerDate.length - PAGE_NUMBER) {
      if (headerDate.includes(maxDate)) {
        dispatch(handleSliceValue(headerDate.length))
      } else {
        dispatch(
          getCurrencyExchangeRate({
            TimeFrequency: timeRange,
            From: fromISOMaxDateParam,
          }),
        )
        dispatch(handleIsRecalculateSliceValue(true))
        dispatch(handleIsDecrease(false))
      }
    } else {
      dispatch(handleSliceValue(sliceValue + PAGE_NUMBER))
    }
  }

  return (
    <div className={`d-flex align-center j-b ${style.groupButton}`}>
      <div
        className={style.buttonLeftRight}
        style={{
          cursor: isCancelDecreasePage ? 'default' : 'pointer',
          opacity: isCancelDecreasePage ? '40%' : '100%',
        }}
        onClick={isCancelDecreasePage ? () => {} : handleDecreasePage}
      >
        <i className={`icon-arrow-left ${style.iconArrow}`} />
      </div>

      <div
        className={style.buttonLeftRight}
        style={{
          cursor: isCancelIncreasePage() ? 'default' : 'pointer',
          opacity: isCancelIncreasePage() ? '40%' : '100%',
        }}
        onClick={isCancelIncreasePage() ? () => {} : handleIncreasePage}
      >
        <i className={`icon-arrow-right ${style.iconArrow}`} />
      </div>
    </div>
  )
}

export default IconLeftRight
