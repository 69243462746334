import moment from 'moment'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { DEFAULT_ORGANIZATION_ID } from '../../../../../../configs/Common'
import { ChannelConfig } from '../../../../../../configs/Global'
import { BondRealtime } from '../../../../../common/BondRealtime'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { Span } from '../../../../../common/html/Span'
import PopupAlert, {
  POPUP_ICON_STATUS,
} from '../../../../../common/popup/PopupAlert'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { Table as TableContent } from '../../../../../common/table'
import { TextBlink } from '../../../../../common/table/TextBlink'
import TextEllipsis from '../../../../../common/textEllipsis'
import { COM_GROUP } from '../../../../../constants/Group'
import { selectReCalcWidths } from '../../../../../market/equityTrading/priceStatistics/summary/overview/store/slice'
import { getColorOverview } from '../../../../../utils/Color'
import { FORMAT } from '../../../../../utils/Datetime'
import {
  formatVal,
  valDivBillion,
  valToPercent,
} from '../../../../../utils/Value'
import {
  changeBasicInfo,
  changeBondTicker,
} from '../../../bondSecurity/bondSecurity/Filter/store/slice'
import { DATE_TYPES, TOTAL_VALUE_TYPE } from '../constants'
import {
  keys,
  selectDataTableByIdTopLiquidity,
  selectFilterTopLiquidity,
  selectLoading,
  selectTopLiquidityData,
  sortTopLiquidity,
  subscribeBondId,
  subscribeOrganizationId,
} from '../store/slice'
import TableHeader from './TableHeader'
import { GROUP_COLUMNS } from './constants'
import style from './index.module.css'

const Table = () => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const isLoading = useSelector(selectLoading(keys.TOP_LIQUIDITY))
  const { data, ids, tickers, organizationIds } = useSelector(
    selectTopLiquidityData,
  )
  const reCalcWidths = useSelector(selectReCalcWidths)
  const { TCPH_TP, TimeRange, StartDate, EndDate, TotalValueType } =
    useSelector(selectFilterTopLiquidity)
  const locale = useSelector((state) => state.i18n.locale)

  const [isShow, setIsShow] = useState(false)

  const columns = useMemo(
    () =>
      TCPH_TP === 'TCPH'
        ? GROUP_COLUMNS(TimeRange)
        : [
            {
              key: 'ticker',
              title: 'bond.corporateBond.topLiquidity.TP',
              attrs: {
                style: { minWidth: 100 },
              },
              keySort: 'ticker',
              alignRight: false,
            },
            ...GROUP_COLUMNS(TimeRange),
          ],
    [TCPH_TP, TimeRange],
  )

  // Get value
  const getRowHighlights = () => {
    const itemIndex = {}
    data.forEach((item, index) => (itemIndex[item.date] = index))
    return Object.keys(itemIndex).map((key) => itemIndex[key])
  }

  const note = useMemo(() => {
    const date =
      data?.[0]?.tradingDateId && moment(data?.[0]?.tradingDateId).isValid()
        ? moment(data?.[0]?.tradingDateId).format(FORMAT.DATE[locale])
        : ''

    switch (TimeRange) {
      case DATE_TYPES.ONE_DAY:
        return I18n.t('bond.corporateBond.topLiquidity.NOTE', {
          now: date,
        })
      case DATE_TYPES.WEEK_DAY:
        const firstDayOfWeek = moment(data?.[0]?.tradingDateId)
          .clone()
          .startOf('week')
          .format(FORMAT.DATE[locale])

        return I18n.t('bond.corporateBond.topLiquidity.NOTE_2', {
          from: firstDayOfWeek,
          to: date,
        })
      case DATE_TYPES.MONTH_DAY:
        const firstDayOfMonth = moment(data?.[0]?.tradingDateId)
          .startOf('month')
          .format(FORMAT.DATE[locale])

        return I18n.t('bond.corporateBond.topLiquidity.NOTE_2', {
          from: firstDayOfMonth,
          to: date,
        })
      case DATE_TYPES.QUARTER_DAY:
        const firstDayOfQuarter = moment(data?.[0]?.tradingDateId)
          .startOf('quarter')
          .format(FORMAT.DATE[locale])

        return I18n.t('bond.corporateBond.topLiquidity.NOTE_2', {
          from: firstDayOfQuarter,
          to: date,
        })
      case DATE_TYPES.YEAR_DAY:
        const firstDayOfYear = moment(data?.[0]?.tradingDateId)
          .startOf('year')
          .format(FORMAT.DATE[locale])

        return I18n.t('bond.corporateBond.topLiquidity.NOTE_2', {
          from: firstDayOfYear,
          to: date,
        })
      case 'Calendar':
        const fromDate = moment(StartDate).format(FORMAT.DATE[locale])
        const toDate = moment(EndDate).format(FORMAT.DATE[locale])

        return I18n.t('bond.corporateBond.topLiquidity.NOTE_2', {
          from: fromDate,
          to: toDate,
        })
      default:
        return ''
    }
  }, [TimeRange, locale, data, StartDate, EndDate])

  const redirectToSecurityMaster = (bond) => {
    dispatch(changeBondTicker(bond))
    dispatch(changeBasicInfo(null))
    navigate('/bond/corporate-bond/security-master')
  }

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  return (
    <>
      <SizeTracker>
        {(size) => (
          <>
            <div
              style={{
                height: `calc(100% - ${size.height}px)`,
              }}
            >
              <TableContent
                isLoading={isLoading}
                ids={ids}
                getDataFromRedux={selectDataTableByIdTopLiquidity}
                renderHeader={(stateSort, sortColumn) => (
                  <TableHeader
                    tableHeaderColAttr={columns}
                    stateSort={stateSort}
                    sortColumn={sortColumn}
                  />
                )}
                schema={columns.map((item, index) => {
                  const title = item.title
                  const colId = item.key
                  const result = {
                    colId,
                    title,
                  }

                  switch (item.key) {
                    case 'ticker':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => (
                          <td
                            {...props}
                            className={style.firstCellColumn}
                            style={{
                              height: 'auto',
                              padding: 0,
                              minWidth: 100,
                              position: 'sticky',
                              left: 0,
                              backgroundColor: '#1e242e',
                            }}
                          >
                            <div
                              style={{
                                height: 28,
                                padding: '4px 8px',
                                boxSizing: 'border-box',
                              }}
                              className="d-flex ali-center"
                            >
                              <Span>
                                <a
                                  className="cursor-pointer"
                                  onClick={() =>
                                    redirectToSecurityMaster(rowId)
                                  }
                                >
                                  {value}
                                </a>
                              </Span>
                            </div>
                          </td>
                        ),
                      }
                    case 'name':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const row = data.find((item) => item.bondId)

                          return (
                            <td
                              {...props}
                              className={
                                TCPH_TP === 'TCPH' ? style.firstCellColumn : ''
                              }
                              style={{
                                height: 'auto',
                                padding: 0,
                                minWidth: 140,
                                maxWidth: 140,
                                position:
                                  TCPH_TP === 'TCPH' ? 'sticky' : 'static',
                                left: 0,
                                backgroundColor: '#1e242e',
                              }}
                            >
                              <div
                                style={{
                                  height: 28,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                  minWidth: '100%',
                                  maxWidth: '100%',
                                }}
                                className="d-flex ali-center"
                              >
                                <Span style={{ overflow: 'hidden' }}>
                                  <a
                                    className="cursor-pointer"
                                    onClick={() =>
                                      redirectToBondIssuer(
                                        TCPH_TP === 'TCPH'
                                          ? rowId
                                          : row?.organizationId ??
                                              DEFAULT_ORGANIZATION_ID[
                                                COM_GROUP.CORPORATE
                                              ],
                                      )
                                    }
                                  >
                                    <TextEllipsis text={value} isI18n={false} />
                                  </a>
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }

                    case 'totalValue':
                      return {
                        ...result,
                        tdStyle: {
                          minWidth: 65,
                          textAlign: 'right',
                        },
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td {...props}>
                              <TextBlink value={value}>
                                <Span>
                                  {formatVal(valDivBillion(value, 2))}
                                </Span>
                              </TextBlink>
                            </td>
                          )
                        },
                      }
                    case 'averageRemainLength':
                      return {
                        ...result,
                        tdStyle: {
                          textAlign: 'right',
                        },
                        canCustomTd: true,
                        render: (value, rowId, props) => (
                          <td {...props}>
                            <Span>{formatVal(value, 2)}</Span>
                          </td>
                        ),
                      }
                    case 'averageCleanPrice':
                    case 'averageDirtyPrice':
                      return {
                        ...result,
                        tdStyle: {
                          textAlign: 'right',
                        },
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td {...props}>
                              <TextBlink value={value}>
                                <Span>{formatVal(value, 0)}</Span>
                              </TextBlink>
                            </td>
                          )
                        },
                      }

                    case 'averageYTM':
                      return {
                        ...result,
                        tdStyle: {
                          textAlign: 'right',
                        },
                        canCustomTd: true,
                        render: (value, rowId, props) => (
                          <td {...props}>
                            <TextBlink value={value}>
                              <Span>{valToPercent(value)}</Span>
                            </TextBlink>
                          </td>
                        ),
                      }

                    case 'changeofCleanPrice':
                    case 'changeofDirtyPrice':
                      return {
                        ...result,
                        tdStyle: {
                          textAlign: 'right',
                        },
                        canCustomTd: true,
                        render: (value, rowId, props) => (
                          <td {...props}>
                            <TextBlink value={value}>
                              <Span
                                style={{
                                  color: getColorOverview(value, 0),
                                }}
                              >
                                {valToPercent(value)}
                              </Span>
                            </TextBlink>
                          </td>
                        ),
                      }

                    default:
                      return {
                        ...result,
                        tdStyle: {
                          textAlign: 'right',
                        },
                        canCustomTd: true,
                        render: (value, rowId, props) => (
                          <td {...props}>
                            <Span>{value}</Span>
                          </td>
                        ),
                      }
                  }
                })}
                stickies={{
                  bondCode: true,
                  tradingDateId: true,
                }}
                sort={sortTopLiquidity}
                defaultSort={{ tradingDateId: 'desc' }}
                isLargeHead={true}
                hasFooter={false}
                resizable={false}
                isHighlightLine={true}
                rowHighlights={getRowHighlights()}
                reCalcWidths={reCalcWidths}
              />
            </div>
            {!!note?.length && (
              <div style={{ marginTop: 8 }}>
                <Span style={{ fontStyle: 'italic', color: '#85939C' }}>
                  {note}
                </Span>
              </div>
            )}
          </>
        )}
      </SizeTracker>
      <PopupAlert
        message={I18n.t('common.FUNCTION_COMING_SOON')}
        isShow={isShow}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setIsShow(false)}
      />

      {!!tickers.length &&
        tickers.map((ticker) => (
          <BondRealtime
            channel={ChannelConfig.BondChannel + ticker}
            action={subscribeBondId}
            key={ticker}
          >
            <></>
          </BondRealtime>
        ))}
      {!!organizationIds.length &&
        organizationIds.map((organizationId) => (
          <BondRealtime
            channel={
              (TotalValueType === TOTAL_VALUE_TYPE.TotalValue
                ? ChannelConfig.BondIssuerChannel
                : TotalValueType === TOTAL_VALUE_TYPE.TotalMatchValue
                ? ChannelConfig.BondIssuerMatchChannel
                : ChannelConfig.BondIssuerDealChannel) + organizationId
            }
            action={subscribeOrganizationId}
            key={organizationId}
          >
            <></>
          </BondRealtime>
        ))}
    </>
  )
}

export default Table
