import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Bar, Cell, Line, Scatter } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import {
  getColumnSizeInBarChart,
  getRadiusOfScatter,
} from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { Span } from '../../../../../common/html/Span'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { formatVal } from '../../../../../utils/Value'
import { CHART_ORDER } from '../../constants'
import style from '../../index.module.css'
import { selectChartName } from '../../store/slice'
import { formatDataChart } from './helper'

const SETTINGS = {
  yTickNum: 5,
}
const YAXIS_LEFT = 'left'
const YAXIS_RIGHT = 'right'
const SECTOR_AVERAGE_VI = 'TB ngành'
const SECTOR_AVERAGE_EN = 'Peer Avg.'

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  barKey,
  chartType,
  lineKey,
  pointKey,
}) => {
  const { CHART_1, CHART_2 } = CHART_ORDER
  const renderIcon = chartType === CHART_2 ? 'renderIcon' : ''

  const { value, relativeValue, isPercentFormat } = useSelector(selectChartName)
  const formatData = formatDataChart(data, isPercentFormat)

  const footer = [
    {
      text: value || 'TOI',
      type: TYPE.SQUARE,
      before: {
        bgColor: chartType === CHART_1 ? '#0096eb' : '#facc5c',
      },
    },
    {
      text: relativeValue || '%YoY',
      type: TYPE.CIRCLE_MARKER_LINE,
      before: {
        bgColor: '#f7b80c',
      },
      after: {
        bgColor: '#f7b80c',
      },
      [renderIcon]: (
        <div className="d-flex align-center mr-8">
          <ScatterDiamondShape color={'#228a9f'} />
        </div>
      ),
    },
  ]

  const keyYAxis = [
    {
      id: YAXIS_LEFT,
      keys: [barKey],
      orientation: 'left',
      isBarChart: true,
      label: isPercentFormat
        ? `${value}`
        : I18n.t('corporate.businessModel.segmentalAnalysis.BILLION_VND'),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: SETTINGS.yTickNum,
      unitYAxis: isPercentFormat ? `%` : '',
    },
    {
      id: YAXIS_RIGHT,
      keys: [chartType === CHART_1 ? lineKey : pointKey],
      orientation: 'right',
      isLineChart: chartType === CHART_1,
      labelPosition: AXIS_LABEL_POSITION.RIGHT,
      tickNum: SETTINGS.yTickNum,
      unitYAxis: '%',
    },
  ]

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        <div className={style.chartTooltip}>
          <Span className={style.tooltipContentKey}>{value || 'TOI'}:</Span>
          <Span
            style={{ marginLeft: 25 }}
            className={style.toolTipContentValue}
          >
            {formatVal(payload.value1)}
            {isPercentFormat ? (
              '(%)'
            ) : (
              <Translate value="corporate.businessModel.segmentalAnalysis.BILLION_VND" />
            )}
          </Span>
        </div>
        {relativeValue?.trim() && (
          <div className={style.chartTooltip}>
            <Span className={style.tooltipContentKey}>
              {relativeValue || '%YoY'}:
            </Span>
            <Span
              style={{ marginLeft: 25 }}
              className={style.toolTipContentValue}
            >
              {formatVal(payload.value2)}(%)
            </Span>
          </div>
        )}
      </>
    )
  }

  return (
    <SizeTracker className={style.heightAuto}>
      {(size) => {
        return (
          <>
            {size.height && keyYAxis && (
              <ChartContainer
                data={formatData}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                yAxis={keyYAxis}
                renderCustomTooltip={renderTooltip}
                isUseXAxisDiv
                showAllTick
                margin={{
                  ...MARGIN_RECHARTS,
                  left: isPercentFormat ? 12 : 0,
                  right: 12,
                }}
              >
                {({ chartContentWidth }) => {
                  return (
                    <>
                      {barKey && (
                        <Bar
                          isAnimationActive={false}
                          yAxisId={YAXIS_LEFT}
                          dataKey={barKey}
                          barSize={getColumnSizeInBarChart(
                            chartContentWidth,
                            formatData.length,
                          )}
                        >
                          {data.map((item, index) => {
                            return (
                              <Cell
                                key={index}
                                fill={
                                  chartType === CHART_1
                                    ? '#0096eb'
                                    : item?.ticker === SECTOR_AVERAGE_VI ||
                                      item?.ticker === SECTOR_AVERAGE_EN
                                    ? '#FFDADA'
                                    : '#facc5c'
                                }
                              />
                            )
                          })}
                        </Bar>
                      )}

                      {pointKey && (
                        <>
                          <Scatter
                            yAxisId={YAXIS_RIGHT}
                            dataKey={pointKey}
                            isAnimationActive={false}
                            shape={
                              <ScatterDiamondShape
                                dataKey={pointKey}
                                color={
                                  chartType === CHART_1 ? '#f7b80c' : '#228a9f'
                                }
                              />
                            }
                          />
                        </>
                      )}

                      {lineKey && (
                        <>
                          <Line
                            isAnimationActive={false}
                            yAxisId={YAXIS_RIGHT}
                            dataKey={lineKey}
                            stroke={'#f7b80c'}
                            strokeWidth={2}
                            dot={false}
                            activeDot={false}
                          />
                          <Scatter
                            yAxisId={YAXIS_RIGHT}
                            dataKey={lineKey}
                            fill="#f7b80c"
                            radius={getRadiusOfScatter(
                              width,
                              formatData.length,
                            )}
                            isAnimationActive={false}
                          />
                        </>
                      )}
                    </>
                  )
                }}
              </ChartContainer>
            )}

            <Footer key={width} list={footer} />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
