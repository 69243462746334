import { dataExplorer } from './dataExplorer'
import smartScreening from './smartScreening'
import { technicalChart } from './technicalChart'

const tool = {
  smartScreening,
  technicalChart,
  dataExplorer,
}

export default tool
