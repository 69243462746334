import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import TimeAndSalesPanel from '.'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { selectIndicator } from '../summary/store/slice'
import { tableChartSwitch, timeSalesDateFilter } from './constants'

const PanelTimeAndSales = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const [viewType, setViewType] = useState(
    tableChartSwitch.HORIZONTAL_STACK_BAR,
  )
  const [dateType, setDateType] = useState(timeSalesDateFilter.ONE_DAY)

  const derivativeActive = useSelector(selectIndicator)
  const nameScreen = UseI18n('constants.route.market.derivative.LABEL')
  const chartName = UseI18n('market.derivative.TIME_AND_SALES')
  const tabName = UseI18n(`market.derivative.${dateType}`)

  return (
    <Panel
      title={'market.derivative.TIME_AND_SALES'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg={viewType === tableChartSwitch.HORIZONTAL_STACK_BAR}
      titleJpg={UseI18n('market.derivative.TIME_AND_SALES')}
      downloadJpgParams={{
        domId: 'derivativeTimeAndSalesChart',
        nameScreen,
        chartName,
        tabName,
        tickerName: derivativeActive?.ticker,
      }}
    >
      <TimeAndSalesPanel
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        viewType={viewType}
        setViewType={setViewType}
        dateType={dateType}
        setDateType={setDateType}
      />
    </Panel>
  )
}

PanelTimeAndSales.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default PanelTimeAndSales
