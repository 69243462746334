import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { EMPTY_VALUE } from '../../../../../../constants/Common'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import {
  addColorIdToData,
  getDataByMonthAndYear,
  getDataByYear,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getMonthInDataRange,
  getUniqueSteelTypes,
  getYearInDataRange,
  getYearKey,
} from '../../helper'
import {
  NUM_OF_PERIOD_FIRST_FETCH,
  TYPE_DATA,
  TYPE_STATISTIC,
  getDefaultCheckedItems,
} from '../constants'
import { getPriceStatistics } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/steel/price/priceStatistics',
  initialState: {
    ids: [],
    dataById: {},
    isLoading: false,
    listCheckedItems: [],
    isGetData: true,
    data: [],
    groupColumns: [],
    currentTypeStatistic: TYPE_STATISTIC.MONTHLY,
    periodNum: NUM_OF_PERIOD_FIRST_FETCH,
    typeData: TYPE_DATA.PRICE,
    listSteelStypes: [],
    activeItem: null,
    isChangeFilter: false,
    currentMinMonth: null,
    currentMinYear: null,
    reCalcWidths: false,
    isChangeLocale: false,
    currentAddedItems: [],
    isAfterChangeAndLoading: false,
    levels: [],
    initialIds: [],
    rowsCollapse: [],
    isFirstRender: true,
  },
  reducers: {
    addItemToList: (state, action) => {
      state.isGetData = true
      state.listCheckedItems = [...state.listCheckedItems, action.payload]
    },
    removeItemFromList: (state, action) => {
      state.isGetData = false
      state.listCheckedItems = state.listCheckedItems.filter(
        (item) => item.id !== action.payload,
      )
    },
    changePeriodNum: (state, action) => {
      state.periodNum = action.payload
    },
    changeCurrentTypeStatistic: (state, action) => {
      if (state.currentTypeStatistic !== action.payload) {
        state.isChangeFilter = true
        state.isLoading = true
      }
      state.currentTypeStatistic = action.payload
    },
    changeTypeData: (state, action) => {
      if (state.typeData !== action.payload) {
        state.isAfterChangeAndLoading = true
      }
      state.typeData = action.payload
    },
    changeActiveItem: (state, action) => {
      const rowId = action.payload
      state.activeItem = state.dataById[rowId]
    },
    changeLocale: (state) => {
      state.isChangeLocale = true
      state.currentMinMonth = null
    },
    changeCurrentMinMonth: (state, action) => {
      state.currentMinMonth = action.payload
    },
    keepCurrentItems: (state, action) => {
      state.currentAddedItems = action.payload
    },
    setRowsCollapse: (state, action) => {
      state.rowsCollapse = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
        0,
        state.levels,
      )
    },
    setIsFirstRender: (state, action) => {
      state.isFirstRender = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPriceStatistics.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPriceStatistics.fulfilled, (state, action) => {
      state.isLoading = false
      state.isChangeFilter = false
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          id: !item.organizationId
            ? item.steelProductTypeId
            : `${item.steelProductTypeId}_${item.organizationId}`,
          value:
            state.typeData === TYPE_DATA.PRICE ? item.price : item.priceGrowth,
        })) || []
      const data = payloadData.length
        ? fullDataWithVal(
            payloadData,
            state.data,
            state.currentMinMonth,
            state.currentTypeStatistic,
            state.isChangeLocale,
            state.typeData,
          )
        : [...state.data]
      const oldData = [...state.data]

      state.data = data
      state.ids = state.initialIds = data.map((item) => item.id)
      state.dataById = keyBy(data, 'id')

      state.levels = data.map((item) => ({
        id: item.id,
        parentId: item.steelProductTypeId,
        level: item.isGroupRow ? 1 : 2,
      }))

      const getGroupColumns = data?.map((item) => Object.keys(item))[0]
      const filterGroupColumns =
        state.currentTypeStatistic === TYPE_STATISTIC.YEARLY
          ? getGroupColumns?.filter((item) => /\d/.test(item))
          : reOrderByMonthAndYear(
              getGroupColumns?.filter((item) => item.includes('-')),
            )

      const groupColumns = [
        { key: 'name', title: 'sector.sectorSpecific.price.PRODUCT' },
        { key: 'ticker', title: 'sector.sectorSpecific.price.TICKER' },
      ]
      filterGroupColumns?.forEach((item) => {
        groupColumns.push({
          key: item,
          title: item,
        })
      })
      state.groupColumns = groupColumns
      state.listSteelStypes = data.filter((item) => item.isGroupRow)
      state.listCheckedItems =
        !oldData.length && !state.listCheckedItems.length
          ? addColorIdToData(getDefaultCheckedItems(data))
          : [...state.listCheckedItems].map((item) => ({
              ...item,
              ...state.dataById[item.id],
            }))

      state.isChangeLocale = false

      state.rowsCollapse = !state.activeItem
        ? state.ids.slice(1, state.ids.length)
        : [...state.rowsCollapse]

      state.isAfterChangeAndLoading = false

      state.currentMinMonth = getMonthInDataRange(payloadData).map(
        (item) => item.month,
      )[0]
      state.currentMinYear = getYearInDataRange(payloadData).map(
        (item) => item.year,
      )[0]

      state.reCalcWidths = !state.reCalcWidths
    })
    builder.addCase(getPriceStatistics.rejected, (state, action) => {
      state.isLoading = action.payload.isLoading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectAllIds = (state) => state[slice.name].ids
export const selectDataTable = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectData = (state) => state[slice.name].data
export const selectListCheckedItems = (state) =>
  state[slice.name].listCheckedItems
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectPeriodNum = (state) => state[slice.name].periodNum
export const selectTypeData = (state) => state[slice.name].typeData
export const selectCurrentTypeStatistic = (state) =>
  state[slice.name].currentTypeStatistic
export const selectListSteelStypes = (state) =>
  state[slice.name].listSteelStypes
export const selectActiveItem = (state) => state[slice.name].activeItem
export const selectIsChangeFilter = (state) => state[slice.name].isChangeFilter
export const selectMinCurrentMonth = (state) =>
  state[slice.name].currentMinMonth
export const selectMinCurrentYear = (state) => state[slice.name].currentMinYear
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths
export const selectRowsCollapse = (state) => state[slice.name].rowsCollapse
export const selectIsFirstRender = (state) => state[slice.name].isFirstRender

export const {
  addItemToList,
  removeItemFromList,
  getPreviousData,
  changePeriodNum,
  changeCurrentTypeStatistic,
  changeTypeData,
  changeActiveItem,
  changeLocale,
  changeCurrentMinMonth,
  keepCurrentItems,
  sort,
  setRowsCollapse,
  setIsFirstRender,
} = slice.actions

register(slice.name, slice.reducer)

const fullDataWithVal = (
  dataContainVal,
  dataWithoutVal,
  currentMinMonth,
  currentTypeStatistic,
  isChangeLocale,
  typeData,
) => {
  //create keys by months and years in data range (ex: 01-2022) (2)
  const getDataKeyFromRange =
    currentTypeStatistic === TYPE_STATISTIC.YEARLY
      ? getYearInDataRange(dataContainVal).map((item) => getYearKey(item.year))
      : getMonthAndYearInDataRange(dataContainVal).map((item) =>
          getMonthAndYearKey(item.month, item.year),
        )

  //get all values belong to months and years in data range
  const getDataValueFromRange =
    currentTypeStatistic === TYPE_STATISTIC.YEARLY
      ? getYearInDataRange(dataContainVal).map((item) => [
          ...getDataByYear(dataContainVal, item.year),
        ])
      : getMonthAndYearInDataRange(dataContainVal).map((item) => [
          ...getDataByMonthAndYear(dataContainVal, item.month, item.year),
        ])

  //get all values corresponding to key in (2)
  const getCorrespondingDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.id === item.id))]?.value ??
      EMPTY_VALUE
    )
  }

  //put values of all date keys into original data
  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  return !currentMinMonth || isChangeLocale
    ? getUniqueSteelTypes(dataContainVal).map((item) => ({
        ...item,
        ...getKeyAndValRange(item),
        typeData: typeData,
      }))
    : dataWithoutVal.map((item) => ({
        ...item,
        ...getKeyAndValRange(item),
        typeData: typeData,
      }))
}

const reOrderByMonthAndYear = (data) => {
  const sorted = data?.sort((month, year) => {
    month = month?.split('-')
    year = year?.split('-')
    return new Date(month[1], month[0], 1) - new Date(year[1], year[0], 1)
  })
  return sorted
}
