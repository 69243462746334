import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import DispatchActionTab from '../../../../../../common/tabs/DispatchActionTab'
import { TIME_RANGE } from '../../../constants'
import style from '../../style.module.css'
import { changeIsGetData } from '../../tableSector/store/slice'
import { changeTimeRange, selectTimeRange } from '../store/slice'
import { RadioGroup } from './RadioGroup'

export const Filter = ({ headPanel }) => {
  const dispatch = useDispatch()

  const timeRange = useSelector(selectTimeRange)

  const onChange = (val) => {
    dispatch(changeTimeRange(val.value))
    dispatch(changeIsGetData(true))
  }

  return (
    <div ref={headPanel} className={`d-flex j-b box-price-chart ${style.ml12}`}>
      <RadioGroup />
      <div className="ml-20">
        <DispatchActionTab
          data={Object.keys(TIME_RANGE).map((key) => {
            return {
              title: I18n.t(`sector.sectorStatistics.overview.${key}`),
              value: TIME_RANGE[key],
            }
          })}
          activeTab={timeRange}
          onChangeTab={onChange}
        />
      </div>
    </div>
  )
}
