import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

class TotalGDPProxy extends ProxyBase {
  getGDPBySector(params) {
    return this.get('GDPBySector', params)
  }

  getGDPGrowthBySector(data) {
    return this.post('GDPGrowthBySector', data)
  }

  getGDPCompositionBySector(params) {
    return this.get('GDPCompositionBySector', params)
  }

  changesInGDPComposition(data) {
    return this.post('ChangesInGDPComposition', data)
  }

  getGDPTypeName(params) {
    return this.get('GDPTypeName', params, undefined, true)
  }

  downloadGDPBySector(params) {
    return this.get('DownloadGDPBySector', params, 'download')
  }

  getGDPGrowthForecast(params) {
    return this.get('GDPGrowthForecast', params)
  }

  downloadGDPGrowthForecast(params) {
    return this.get('DownloadGDPGrowthForecast', params, 'download')
  }

  getMaxPeriod(params) {
    return this.get('MaxPeriod', params)
  }
}

export default new TotalGDPProxy(
  ServiceProxyConfig.Economy.GDP.Sector.ServiceUrl,
)
