import PropTypes from 'prop-types'
import { Separator } from '../../../../common/separator'
import { LatestNews } from './LatestNews'
import { Grid } from './Grid'

export const MarketNews = ({ width }) => {
  return (
    <>
      <LatestNews height="calc(100%/3 - 10px)" />
      <div className="mt-10 mb-10">
        <Separator isVertical={false} />
      </div>
      <Grid width={width} height="calc(200%/3 - 10px)" />
    </>
  )
}

MarketNews.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
