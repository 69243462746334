import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { Span } from '../html/Span'
import DropdownTooltip from '../textEllipsis/DropdownTooltip'

export const TooltipInfo2 = ({ style, tooltip, isI18n, fontSize }) => {
  const ref = useRef()
  const [isShow, setIsShow] = useState(false)

  const onMouseEnter = () => {
    setIsShow(true)
  }

  const onMouseLeave = () => {
    setIsShow(false)
  }

  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{ alignItems: 'flex-end', ...style }}
      >
        <Span style={{ fontSize }}>
          <i
            style={{ color: '#FACC5C' }}
            className="icon-warning-circle"
            data-tip
            data-for={tooltip}
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </Span>
      </div>

      {isShow && (
        <DropdownTooltip
          containerRef={ref}
          text={tooltip}
          isOpenTooltip={true}
          isI18n={isI18n}
        />
      )}
    </>
  )
}

TooltipInfo2.propTypes = {
  tooltip: PropTypes.string.isRequired,
  isI18n: PropTypes.bool,
  style: PropTypes.object,
}

TooltipInfo2.defaultProps = {
  isI18n: true,
  style: {},
  fontSize: 10,
}
