export const chartFillColor = {
  iS30: '#1f669a',
  iS31: '#3cabeb',
  nglfs: '#f1ffe7',
  niig: '#f7b80c',
}

export const chartBarKeys = ['iS30', 'iS31', 'nglfs']

export const chartLineKeys = ['niig']

export const chartMappingDisplayName = {
  iS30: 'sector.financialAnalysis.bank.profitability.NET_AND_COMMISSION',
  iS31: 'sector.financialAnalysis.bank.profitability.NET_GAIN_FX',
  nglfs: 'sector.financialAnalysis.bank.profitability.NET_GAIN_SECURITIES',
  niig: 'sector.financialAnalysis.bank.profitability.NON_INTEREST_INCOME_GROWTH',
}

export const chartFormat = {
  iS30: 10 ** 9,
  iS31: 10 ** 9,
  nglfs: 10 ** 9,
  niig: 0.01,
}
