export const indicator = {
  TICKER: 'ticker',
  EXPIRY_DATE: 'lastTradingDateId',
  REFERENCE: 'referencePrice',
  PRICE: 'matchPrice',
  CHANGE: 'priceChange',
  PERCENT_CHANGE: 'percentPriceChange',
  VOLUME: 'totalMatchVolume',
  BASIC: 'basic',
  OI: 'openInterest',
}
