import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../../common/chart/footer/Item'
import { getRadiusOfScatter } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'

export const LineChart = ({
  width,
  height,
  data,
  xAxisKey,
  leftKeys,
  rightKeys,
  margin,
  fillColor,
  leftUnit,
  rightUnit,
  mappingDisplayName,
  leftLabel,
  rightLabel,
  isDisplayDot,
  isYAxisPercentLeft,
  isYAxisPercentRight,
}) => {
  // Footer
  const footerData = () => {
    let keys = []
    if (leftKeys && leftKeys.length) {
      keys = [...leftKeys]
    }
    if (rightKeys && rightKeys.length) {
      keys = [...keys, ...rightKeys]
    }
    return keys.map((i) => ({
      text: mappingDisplayName[i] ?? i,
      type: FOOTER_TYPE.CIRCLE_MARKER_LINE,
      color: fillColor[i],
    }))
  }

  // Render tooltip
  const getContent = () => {
    return [
      {
        keys: leftKeys,
        yAxisId: 'leftChart',
        unit: leftUnit,
        decimalLength: 2,
      },
      {
        keys: rightKeys,
        yAxisId: 'rightChart',
        unit: rightUnit,
        decimalLength: 2,
      },
    ]
  }

  // Render
  const renderLineChart = (keys, chartContentWidth, yAxisId) => {
    return keys.map((key) => (
      <Line
        key={key}
        yAxisId={yAxisId}
        dataKey={key}
        type="linear"
        stroke={fillColor[key]}
        fill={fillColor[key]}
        strokeWidth={2}
        dot={
          isDisplayDot
            ? {
                strokeWidth: getRadiusOfScatter(chartContentWidth, data.length),
              }
            : false
        }
        activeDot={false}
        isAnimationActive={false}
      />
    ))
  }

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={data}
              width={width}
              height={height - size.height}
              keyXAxis={xAxisKey}
              margin={{
                ...MARGIN_RECHARTS,
                left: isYAxisPercentLeft ? 12 : 0,
                right: isYAxisPercentRight ? 14 : 0,
              }}
              yAxis={[
                {
                  id: 'leftChart',
                  keys: leftKeys,
                  orientation: 'left',
                  isLineChart: true,
                  yAxisWidth: margin?.left,
                  label: leftLabel ? I18n.t(leftLabel) : leftLabel,
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  unitYAxis: isYAxisPercentLeft ? '%' : '',
                },
                {
                  id: 'rightChart',
                  keys: rightKeys,
                  orientation: 'right',
                  isLineChart: true,
                  yAxisWidth: margin?.right,
                  label: rightLabel ? I18n.t(rightLabel) : rightLabel,
                  labelPosition: AXIS_LABEL_POSITION.RIGHT,
                  unitYAxis: isYAxisPercentRight ? '%' : '',
                },
              ]}
              timeFrame={TIME_RANGES.CUSTOM}
              tickFormatter={(val) => val}
              tooltipSchema={{
                content: getContent(),
                mappingDisplayName: mappingDisplayName,
              }}
            >
              {({ chartContentWidth }) => (
                <>
                  {renderLineChart(leftKeys, chartContentWidth, 'leftChart')}
                  {renderLineChart(rightKeys, chartContentWidth, 'rightChart')}
                </>
              )}
            </ChartContainer>
          )}
          <FooterContainer key={width} data={footerData()} />
        </>
      )}
    </SizeTracker>
  )
}

LineChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  xAxisKey: PropTypes.string.isRequired,
  leftKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  rightKeys: PropTypes.arrayOf(PropTypes.string),
  fillColor: PropTypes.object,
  leftUnit: PropTypes.string,
  rightUnit: PropTypes.string,
  mappingDisplayName: PropTypes.objectOf(PropTypes.string),
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  margin: PropTypes.object,
  isDisplayDot: PropTypes.bool,
  isYAxisPercentLeft: PropTypes.bool,
  isYAxisPercentRight: PropTypes.bool,
}

LineChart.defaultProps = {
  fillColor: {},
  leftUnit: '',
  rightUnit: '',
  mappingDisplayName: {},
  leftKeys: [],
  rightKeys: [],
  isDisplayDot: true,
}
