export let reducers = {}
let emitChange = null

export const register = (name, reducer) => {
  reducers = { ...reducers, [name]: reducer }
  if (emitChange) {
    emitChange(reducers)
  }
}

export const setChangeListener = (listener) => {
  emitChange = listener
}
