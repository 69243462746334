import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import Table from './Table'

import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import {
  keys,
  selectAverageYtmData,
  selectFilterAverageYtm,
  selectLoading,
} from '../store/slice'
import { downloadAverageYTMofIssuer } from '../store/thunk'
import Filter from './Filter'

const AverageYtm = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.AVERAGE_YTM))
  const { interest, ytmChange, TimeRange, StartDate, EndDate } = useSelector(
    selectFilterAverageYtm,
  )
  const { data } = useSelector(selectAverageYtmData)

  const handleExportData = () => {
    const payload = {
      IsReal: interest === 'real',
      IsYTM: interest === 'real' ? ytmChange : false,
    }

    if (TimeRange !== 'Calendar') {
      if (interest === 'real' && ytmChange) {
        payload.TimeRange = TimeRange
      }
    } else {
      payload.StartDate = StartDate
      payload.EndDate = EndDate
    }

    return downloadAverageYTMofIssuer(payload)
  }

  return (
    <Panel
      title="bond.corporateBond.averageYtmInterest.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => handleExportData()}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${+size.height}px)`,
                  position: 'relative',
                }}
              >
                {isLoading ? (
                  <Loading />
                ) : !!data.length ? (
                  <Table />
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

AverageYtm.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default AverageYtm
