import { useSelector } from 'react-redux'
import { DataTable } from '.'
import { Panel } from '../../../../../../common/panel'
import { selectSectorInfo } from '../../../../../../common/topInfo/sectorTopInfo/store/slice'
import { selectTimeFrequency } from './store/slice'
import { downloadAssetQualityTable } from './store/thunk'

export const PanelDataTable = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
  dataType,
}) => {
  // Use selector
  const sectorInfo = useSelector(selectSectorInfo)
  const timeFrequency = useSelector(selectTimeFrequency)

  // Actions
  const downloadTable = () => {
    return downloadAssetQualityTable({
      TimeFrequency: timeFrequency,
      IcbId: sectorInfo.icbId,
      ExchangeCode: dataType,
    })
  }

  return (
    <Panel
      title={'sector.financialAnalysis.bank.assetQuality.LOAN_BY_SECTOR'}
      panelRefs={panelRefs}
      panelKey={mapKey.DATA_TABLE}
      sizes={sizes}
      setSizes={setSizes}
      dataType={dataType}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadTable}
    >
      <DataTable dataType={dataType} />
    </Panel>
  )
}
