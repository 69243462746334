import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { changeFilter, selectFilters } from '../store/slice'
import style from './index.module.css'

export const FilterInputRatioItem = ({ data }) => {
  const dispatch = useDispatch()

  const filters = useSelector(selectFilters)

  const getOptions = useMemo(() => {
    if (Array.isArray(data.options)) {
      return data.options
    }
    return Object.keys(data.options).map((key) => ({
      name: `fund.fundProfile.report.${key}`,
      value: data.options[key],
    }))
  }, [data.options])

  const onRadioClick = (value) => {
    dispatch(changeFilter({ [data.field]: value }))
  }

  return (
    <div className={`input-dropdown ${style.inputDropdown}`}>
      <Span style={{ fontSize: 10 }} className={style.titleDropdown}>
        <Translate value={data.title} />
      </Span>
      <div className="pl-8">
        <ul
          className={`list-check ${
            data.layout === 'vertical' && 'align-center'
          }`}
        >
          {getOptions.map((item) => (
            <li
              key={item.value}
              className={`mb-0 ${data.layout === 'vertical' && 'mt-0 w-50'}`}
            >
              <label className="fs-12">
                <div>
                  <input
                    id={`${data.field}-${item.value}`}
                    type="radio"
                    className="radiobox radiobox2"
                    name={data.field}
                    value={item.value}
                    defaultChecked={item.value === filters[data.field]}
                    onClick={(_) => onRadioClick(item.value)}
                  />
                </div>
                <Span
                  style={{
                    fontSize: 12,
                    color: item.value === filters[data.field] ? '#2f9aee' : '',
                  }}
                >
                  <Translate value={item.name} />
                </Span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

FilterInputRatioItem.propTypes = {
  data: PropTypes.object.isRequired,
}
