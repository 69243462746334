import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PopupAlert from '../../../../../common/popup/PopupAlert'
import { Search } from '../../../../../common/search'
import SelectCustom from '../../../../../common/selectCustom'
import TextEllipsis from '../../../../../common/textEllipsis'
import { Z_INDEX } from '../../../../../constants/Common'
import { debounce } from '../../../../../utils/Common'
import { ICB_LEVEL, LIST_TYPE } from '../../constant'
import { changeIcbLevel, selectFilterIcbLevel } from '../../store/slice'
import style from './company.module.css'
import CompanyList from './CompanyList'
import IndexList from './IndexList'
import SectorList from './SectorList'
import WatchList from './WatchList'

const ChooseTicker = ({
  organizations,
  setOrganizations,
  filteredOrgIds,
  setFilteredOrgIds,
  groups,
  setGroups,
  exchanges,
  marketCap,
}) => {
  const dispatch = useDispatch()

  const icbLevel = useSelector(selectFilterIcbLevel)

  const [listType, setListType] = useState(LIST_TYPE.COMPANY)
  const [keyword, setKeyword] = useState('')
  const [showAlert, setShowAlert] = useState(false)

  const handleChangeKeyword = debounce((val) => {
    setKeyword(val.trim())
  })

  const handleOpenAlert = () => {
    setShowAlert(true)
  }

  const handleCloseAlert = () => {
    setShowAlert(false)
  }

  const handleChangeIcbLevel = (val) => {
    dispatch(changeIcbLevel(val))
  }

  const renderItemList = () => {
    const props = {
      organizations,
      setOrganizations,
      filteredOrgIds,
      setFilteredOrgIds,
      groups,
      setGroups,
      exchanges,
      marketCap,
      keyword,
      handleOpenAlert,
    }

    switch (listType) {
      case LIST_TYPE.WATCHLIST:
        return <WatchList {...props} />
      case LIST_TYPE.INDEX:
        return <IndexList {...props} />
      case LIST_TYPE.SECTOR:
        return <SectorList {...props} />
      default:
        return <CompanyList {...props} />
    }
  }

  return (
    <div className={style.chooseTicker}>
      <div className={style.header}>
        {Object.keys(LIST_TYPE).map((key) => {
          return (
            <div
              key={key}
              onClick={() => setListType(LIST_TYPE[key])}
              className={`${style.listTab} ${
                listType === LIST_TYPE[key] ? style.active : ''
              }`}
            >
              <Translate
                value={`sector.sectorConstituents.segmentation.listType.${key}`}
              />
            </div>
          )
        })}
      </div>
      <div className={style.searchFilter}>
        <Search
          onChange={handleChangeKeyword}
          appendStyle={{
            margin: 0,
            width: 212,
          }}
        />
        {listType === LIST_TYPE.SECTOR ? (
          <div className={style.selectSector}>
            <SelectCustom
              value={icbLevel}
              isI18n={true}
              selectData={ICB_LEVEL}
              handleChange={handleChangeIcbLevel}
              dropdownSpace={4}
              renderSelect={() => {
                return (
                  <div className="form-date-select">
                    <div className="date-select w-100px">
                      <TextEllipsis
                        isI18n
                        text={ICB_LEVEL.find((e) => e.value === icbLevel)?.name}
                        zIndexTooltip={Z_INDEX.DROPDOWN + 1}
                      />
                      <span
                        className="btn-select-date"
                        style={{ top: 6, right: -4 }}
                      >
                        <i className={`icon-caret-down`} />
                      </span>
                    </div>
                  </div>
                )
              }}
            />
          </div>
        ) : null}
      </div>
      <div className={style.itemList}>{renderItemList()}</div>
      <PopupAlert
        message={
          <Translate value="sector.sectorConstituents.segmentation.NOT_VALID_TICKER" />
        }
        isShow={showAlert}
        handleClose={handleCloseAlert}
      />
    </div>
  )
}

export default ChooseTicker
