import { I18n } from 'react-redux-i18n'
import { COMPONENT, COMPONENT_FILTER } from '../../../../configs/Layout'
import { Panel } from '../../../common/panel'
import PanelFilter from '../../../common/panelFilter'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import Filter from './filter'
import Result from './result'

const MAP_KEY = {
  FILTER: 'FILTER',
  RESULT: 'RESULT',
  TABLE: 'TABLE',
}

const MAP_SIZE = {
  [`${MAP_KEY.FILTER}`]: {
    width: `calc(16% - ${2 * COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: COMPONENT_FILTER.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT_FILTER.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.RESULT,
        ratioLeft: -1,
        ratioWidth: 1,
      },
    ],

    disableVerticalResize: true,
    disableHorizontalResize: true,
  },

  [`${MAP_KEY.RESULT}`]: {
    width: `calc(100% - 16%)`,
    height: '100%',
    top: 0,
    left: `calc(16%)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT_FILTER.MIN_SIZE.WIDTH + 2 * COMPONENT.MARGIN,
    isOpenCollapse: true,
    isMaxSizeCollapse: false,
    groupCollapse: [MAP_KEY.RESULT],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <div
      className="w-100 h-100 position-relative"
      style={{ overflow: 'hidden' }}
    >
      <PanelFilter
        title={'bond.interconnectionMap.filter.title'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter />
      </PanelFilter>

      <Panel
        title={'bond.interconnectionMap.chart.title'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.RESULT}
        sizes={sizes}
        setSizes={setSizes}
        isCollapse={false}
        isDownloadJpg
        windowZoom={false}
        isDownloadCsv={false}
        titleJpg={I18n.t('bond.corporateBond.creditRatio.TITLE')}
        downloadJpgParams={{
          domId: 'orceGraph2Dcustorm',
          nameScreen: I18n.t('bond.corporateBond.issuers.TITLE'),
          chartName: I18n.t('bond.corporateBond.creditRatio.TITLE'),
          tickerName: '3333',
          isUseOriginHtml2Canvas: true,
        }}
      >
        <Result
          width={formatNumber(sizes[MAP_KEY.RESULT].width) - PANEL_PADDING}
          height={
            formatNumber(sizes[MAP_KEY.RESULT].height) - HEADER_PANEL_HEIGHT
          }
        />
      </Panel>
    </div>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
