import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PriceBoardService from '../../../../../core/services/bond/corporateBond/secondaryMarket/PriceBoardService'
import InfoSec from '../../../../../core/services/bond/valuation/infoSec'
import InfoValuation from '../../../../../core/services/bond/valuation/infoValuation'
import SimpleSearchService from '../../../../../core/services/common/SimpleSearchService'
import { MARKET_STATISTIC } from '../constants'

export const getPriceBoardData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_PRICE_BOARD',
  async (params, { rejectWithValue }) => {
    try {
      const { type, ...restParams } = params

      const response =
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? await PriceBoardService.getPriceBoardMatched(restParams)
          : await PriceBoardService.getPriceBoardDeal(restParams)

      if (response.success) {
        return { ...response, type }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getPriceBoardWithBookmarkData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_PRICE_BOARD_WITH_BOOKMARK',
  async (params, { rejectWithValue }) => {
    try {
      const { type, ...restParams } = params

      const response =
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? await PriceBoardService.getPriceBoardMatched(restParams)
          : await PriceBoardService.getPriceBoardDeal(restParams)

      if (response.success) {
        return { ...response, type }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const fetchMorePriceBoardData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/FETCH_MORE_PRICE_BOARD',
  async (params, { rejectWithValue }) => {
    try {
      const { type, ...restParams } = params

      const response =
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? await PriceBoardService.getPriceBoardMatched(restParams)
          : await PriceBoardService.getPriceBoardDeal(restParams)

      if (response.success) {
        return { ...response, type }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondListData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_BOND_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchBond(params)

      if (response.success) {
        return {
          data: response.data,
          hasNextPage: response.hasNextPage,
          isDefault: !params.Term,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const fetchMoreBondListData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/FETCH_MORE_BOND_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchBond(params)

      if (response.success) {
        return { data: response.data, hasNextPage: response.hasNextPage }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondDetailData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_BOND_DETAIL',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InfoSec.getBondInfo(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondCashFlowScenarioData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_BOND_CASH_FLOW_SCENARIO',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InfoSec.getBondCashFlowScenarioApi(params)

      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getPriceYtmData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/GET_PRICE_YTM',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InfoValuation.getPriceBond(params)

      if (response.success) {
        return { data: response.data }
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const updateBookmarkData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/priceBoard/UPDATE_BOOKMARK_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PriceBoardService.updateBookmark(params)

      if (response.success) {
        return response.success
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
