import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { handleDuplicateArray } from '../../utils/Common'
import TextEllipsis from '../textEllipsis'
import { getAllIdItem, handleName } from './helper'
import style from './index.module.css'

const timeoutCheckDoubleClick = 350

const TreeItem = ({
  item,
  itemDetail,
  nameKey,
  levelKey,
  isParentOpen,
  doubleClickItem,
  isSearchAbsolute,
  idKey,
  listChecked,
  setListChecked,
  isActiveSelected,
  isHaveCheckbox,
  listHightlight,
  isCheckedWhenClickText,
  listDisabled,
  isSortKey,
  sortKey,
  isReopen,
  itemDefaultOpen,
  isTextEllipsis,
  disabledCheckDbClick,
  onClickItem,
  appendStyle,
  isInDropdown,
  zIndexTextEllipsis,
  classItemStyle,
  classCheckboxStyle,
}) => {
  const pRef = useRef()
  const isDoubleClick = useRef(false)

  const [isOpen, setIsOpen] = useState(false)
  const [idsItem, setIdsItem] = useState([])
  const [isChecked, setIsCheck] = useState(false)
  const [keys, setKeys] = useState([])
  const [isHightlight, setIsHightlight] = useState(false)

  useEffect(() => {
    setIsHightlight(listHightlight.includes(item[idKey]))
  }, [listHightlight])

  useEffect(() => {
    setKeys(
      isSortKey
        ? Object.keys(item.children).sort()
        : sortKey
        ? Object.keys(item.children).sort((id1, id2) => {
            return item.children[id1][sortKey] - item.children[id2][sortKey]
          })
        : Object.keys(item.children),
    )
  }, [])

  useEffect(() => {
    if (isHaveCheckbox) {
      setIdsItem(getAllIdItem(item, idKey, item?.exceptChildren))
    }
  }, [])

  useEffect(() => {
    if (isHaveCheckbox) {
      if (
        idsItem.length > 0 &&
        idsItem.every((id) => listChecked.includes(id))
      ) {
        setIsCheck(true)
      } else {
        setIsCheck(false)
      }
    }
  }, [listChecked, idsItem])

  useEffect(() => {
    if (
      itemDefaultOpen &&
      getAllIdItem(item, idKey, item?.exceptChildren)?.some((id) =>
        itemDefaultOpen.includes(id),
      )
    ) {
      setIsOpen(true)
    } else if (itemDetail.isOpen) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [isReopen])

  const handleClick = () => {
    if (disabledCheckDbClick) {
      if (onClickItem) {
        onClickItem(item)
      }
      if (!isInDropdown) {
        setIsOpen(!isOpen)
      }
    } else {
      setTimeout(() => {
        if (!isDoubleClick.current) setIsOpen(!isOpen)
      }, timeoutCheckDoubleClick)
    }
    if (isCheckedWhenClickText && Object.keys(item.children)?.length === 0) {
      handleChangeCheckbox({ target: { checked: !isChecked } })
    }
  }

  const handleClickArrow = (e) => {
    if (isInDropdown) {
      setIsOpen(!isOpen)
      e.stopPropagation()
    }
  }

  const handleDoubleClick = () => {
    if (doubleClickItem) {
      isDoubleClick.current = true
      doubleClickItem(item)
      setTimeout(() => {
        isDoubleClick.current = false
      }, timeoutCheckDoubleClick + 50)
    }
  }

  const handleChangeCheckbox = (e) => {
    if (isHaveCheckbox) {
      if (e.target.checked) {
        setListChecked(handleDuplicateArray(listChecked.concat(idsItem)))
      } else {
        setListChecked(
          handleDuplicateArray(
            listChecked.filter((id) => !idsItem.includes(id)),
          ),
        )
      }
    }
  }

  const handleClickCheckbox = (e) => e.stopPropagation()

  if (!isParentOpen || !itemDetail.isDisplay) {
    return <></>
  }
  return (
    <li className={style.li} id={item[idKey]}>
      <div
        ref={pRef}
        className={[
          !isInDropdown
            ? `${style.p} ${
                isHightlight || (isActiveSelected && isChecked)
                  ? style.pActive
                  : ''
              }`
            : style.pDropdown,
          classItemStyle,
        ].join(' ')}
        onDoubleClick={
          !listDisabled[item?.[idKey]] ? handleDoubleClick : undefined
        }
        style={{ ...appendStyle }}
        onClick={handleClick}
      >
        <div className="d-flex">
          {isHaveCheckbox && (
            <label className={style.labelCheckbox}>
              <input
                onClick={handleClickCheckbox}
                onChange={handleChangeCheckbox}
                checked={isChecked}
                type="checkbox"
                className={`checkbox line cursor-pointer ${style.checkbox} ${classCheckboxStyle}`}
              />
            </label>
          )}
          <div className={`d-flex ali-center ${isTextEllipsis ? 'w-100' : ''}`}>
            <i
              className={`${style.i} ${
                keys.length === 0
                  ? style.displayNone
                  : isOpen
                  ? 'icon-caret-down'
                  : 'icon-caret-right'
              }`}
              onClick={handleClickArrow}
            />
            <div
              className={`align-center ${style.textName} ${
                listDisabled[item[idKey]] ? style.disabled : ''
              } ${keys.length === 0 ? style.ml18 : ''}`}
              style={isTextEllipsis ? { width: `calc(100% - 18px)` } : {}}
            >
              {isTextEllipsis ? (
                <TextEllipsis
                  isI18n={false}
                  text={handleName(item, nameKey, levelKey)}
                  zIndexTooltip={zIndexTextEllipsis}
                />
              ) : (
                handleName(item, nameKey, levelKey)
              )}
            </div>
          </div>
        </div>
      </div>
      {keys.length > 0 && (
        <ul className={style.ul}>
          {keys.map((key) => (
            <TreeItem
              item={item.children[key]}
              itemDetail={itemDetail.children[key]}
              nameKey={nameKey}
              levelKey={levelKey}
              isParentOpen={isOpen}
              key={key}
              doubleClickItem={doubleClickItem}
              isSearchAbsolute={isSearchAbsolute}
              idKey={idKey}
              listChecked={listChecked}
              setListChecked={setListChecked}
              isActiveSelected={isActiveSelected}
              isHaveCheckbox={isHaveCheckbox}
              listHightlight={listHightlight}
              isCheckedWhenClickText={isCheckedWhenClickText}
              listDisabled={listDisabled}
              isSortKey={isSortKey}
              sortKey={sortKey}
              isReopen={isReopen}
              itemDefaultOpen={itemDefaultOpen}
              isTextEllipsis={isTextEllipsis}
              disabledCheckDbClick={disabledCheckDbClick}
              onClickItem={onClickItem}
              isInDropdown={isInDropdown}
              zIndexTextEllipsis={zIndexTextEllipsis}
              classItemStyle={classItemStyle}
              classCheckboxStyle={classCheckboxStyle}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

TreeItem.propTypes = {
  item: PropTypes.object.isRequired,
  itemDetail: PropTypes.object.isRequired,
  nameKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isParentOpen: PropTypes.bool.isRequired,
  doubleClickItem: PropTypes.func,
  isSearchAbsolute: PropTypes.bool,
  idKey: PropTypes.string,
  levelKey: PropTypes.string,
  listChecked: PropTypes.array,
  setListChecked: PropTypes.func,
  isActiveSelected: PropTypes.bool,
  isHaveCheckbox: PropTypes.bool,
  listHightlight: PropTypes.array,
  isCheckedWhenClickText: PropTypes.bool,
  sortKey: PropTypes.string,
  isReopen: PropTypes.bool.isRequired,
  isTextEllipsis: PropTypes.bool,
  appendStyleCheckbox: PropTypes.object,
}

TreeItem.defaultProps = {
  isActiveSelected: false,
  isHaveCheckbox: false,
  isCheckedWhenClickText: false,
  isTextEllipsis: false,
  appendStyleCheckbox: {},
}

export default TreeItem
