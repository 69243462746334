import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import TextEllipsis from '../../../../../../../common/textEllipsis'
import { keyBy } from '../../../../../../../utils/Common'
import style from './index.module.css'

const NameChecked = ({ ICBs, listParentChecked, icbTreeLength }) => {
  const [ICBsById, setICBsById] = useState({})

  useEffect(() => {
    setICBsById(keyBy(ICBs, 'icbId'))
  }, [JSON.stringify(ICBs)])

  return (
    <div className={style.selectTitle}>
      {listParentChecked.length > 0 &&
      icbTreeLength === listParentChecked.length ? (
        <Translate value="corporate.peerValuation.overview.ALL_SECTOR" />
      ) : listParentChecked.length > 0 ? (
        <TextEllipsis
          isI18n={false}
          text={listParentChecked
            .map((key) => ICBsById[key]?.icbName)
            .join('; ')}
        />
      ) : (
        <Translate value="corporate.peerValuation.overview.SELECT_SECTOR" />
      )}
    </div>
  )
}

export default NameChecked
