import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Span } from '../../../../common/html/Span'
import Popup from '../../../../common/popup'
import style from '../../index.module.css'
import { addTicker, resetWatchlist } from '../../store/slice'
import { getWatchListThunk } from '../../store/thunk'
import ButtonSubmit from './ButtonSubmit'
import Count from './Count'
import TableTicker from './TableTicker'
import WatchlistInformation from './WatchlistInformation'

const PopupWatchlist = ({ handleHidePopup }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWatchListThunk())
    dispatch(addTicker())
  }, [])

  useEffect(() => {
    return () => dispatch(resetWatchlist())
  }, [])

  return (
    <Popup>
      <div className="modal modal-large tool-page">
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="tool.smartScreening.CREATE_WATCHLIST_2" />
            </Span>
          </h3>
          <a onClick={handleHidePopup} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content">
          <WatchlistInformation />
          <div className="content">
            <div className="col w-100">
              <div
                className={`col-content ${style.colContent} ${style.h350px}`}
              >
                <TableTicker />
              </div>
            </div>
          </div>
          <Count />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              onClick={handleHidePopup}
              className={`btn w-80 h-20 ${style.btnCancel}`}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit handleHidePopup={handleHidePopup} />
          </div>
        </div>
      </div>
    </Popup>
  )
}

PopupWatchlist.propTypes = {
  handleHidePopup: PropTypes.func.isRequired,
}

export default PopupWatchlist
