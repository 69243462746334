import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CompanyBondService from '../../../../../../core/services/bond/corporateBond/issuers/CompanyBondService'
import { handleExport } from '../../../../../utils/Export'

export const getBondIssueInformationData = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_BOND_ISSUE_INFORMATION',
  async (params, { rejectWithValue }) => {
    try {
      const { locale, ...rest } = params

      const response = await CompanyBondService.getBondIssueInformation({
        ...rest,
        CompanyStatus: rest.CompanyStatus.toString().replace('All,', ''),
      })

      if (response.success) {
        return {
          locale,
          data: response.data,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getRelativeToEquityData = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_RELATIVE_TO_EQUITY_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CompanyBondService.getRelativeToEquity(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getRemainingMaturitiesData = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_REMAIN_MATURITIES_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CompanyBondService.getRemainingMaturities(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getCouponInterestRateData = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_COUPON_INTEREST_RATE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CompanyBondService.getCouponInterestRate(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getInterestAndPrincipalPaidData = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_INTEREST_AND_PRINCIPAL_PAID',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CompanyBondService.getInterestAndPrincipalPaid(
        params,
      )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const downloadBondIssueInformationData = createAsyncThunk(
  'bond/corporateBond/companyBond/DOWNLOAD_BOND_ISSUE_INFORMATION',
  async (data) => {
    const response = await CompanyBondService.downloadBondIssueInformation(data)
    handleExport(response.data, response.filename)
  },
)
