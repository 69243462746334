import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { TABLE_ROW_LEVEL } from './constants'
import { selectItemData } from './store/slice'

const MARGIN_LEFT = 16

export const FirstColumnCell = ({ val, rowId, className, style }) => {
  const tdRef = useRef()
  const item = useSelector(selectItemData(rowId))

  if (item.level === TABLE_ROW_LEVEL.LEVEL_1) {
    return (
      <td className={className} style={style} ref={tdRef}>
        <TextEllipsisStaticLine val={val} appendStyle={{ color: '#fecf2f' }} />
      </td>
    )
  } else if (item.level === TABLE_ROW_LEVEL.LEVEL_3) {
    return (
      <td className={className} style={style} ref={tdRef}>
        <TextEllipsisStaticLine
          val={val}
          appendStyle={{
            marginLeft: 2 * MARGIN_LEFT,
            color: '#ececec',
          }}
        />
      </td>
    )
  }

  return (
    <td className={className} style={style} ref={tdRef}>
      <TextEllipsisStaticLine
        val={val}
        appendStyle={{ marginLeft: MARGIN_LEFT }}
      />
    </td>
  )
}

FirstColumnCell.propTypes = {
  val: PropTypes.string.isRequired,
  rowId: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
}
