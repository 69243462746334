import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { NAVIGATION_ISSUERS } from './constants'
import style from './index.module.css'
import { resetTab, selectCurrentTab, updateCurrentTab } from './store/slice'
import { resetStore } from './synthetic/store/slice'

export const Issuers = () => {
  const dispatch = useDispatch()
  const currentTab = useSelector(selectCurrentTab)
  const currentComponent = NAVIGATION_ISSUERS.find(
    (e) => e.value === currentTab,
  )

  const handleClickTab = (item) => () => {
    dispatch(updateCurrentTab(item.value))
  }

  useEffect(() => {
    return () => {
      dispatch(resetStore())
      dispatch(resetTab())
    }
  }, [])

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="bond.corporateBond.issuers.TITLE" />
              <VerticalLine mr={28} />
              <div className={style.tabLinkContainer}>
                {NAVIGATION_ISSUERS.map((item) => (
                  <a
                    key={item.value}
                    className={`${style.tabLink} ${
                      currentTab === item.value ? style.tabLinkActive : ''
                    }`}
                    onClick={handleClickTab(item)}
                  >
                    <Span style={{ fontSize: 12 }}>
                      <Translate value={item.title} />
                    </Span>
                    {currentTab === item.value && (
                      <div className={style.tabLinkActiveBorderBottom}></div>
                    )}
                  </a>
                ))}
              </div>
            </div>
            {size.height && (
              <div
                className={style.relative}
                style={{ height: `calc(100% - ${size.height}px)` }}
              >
                {currentComponent.component}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
