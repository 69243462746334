import { createSlice } from '@reduxjs/toolkit'
import { typeWorkSpace } from '../../../common/dataExplorer/constant'
import { getIdsFromProps } from '../../../common/table/helper'
import { getUnique, keyBy } from '../../../utils/Common'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { register } from '../../../utils/ReducerRegistry'
import { blankValue, maxCondition, typeData } from '../constant'
import { checkMaxCondition, handleDataInitCondition } from '../helper'
import {
  getFinancialStatementIndicatorsThunk,
  getQuickSelectIndicatorsThunk,
  getRatioIndicatorsThunk,
  getResultThunk,
  getScreeningDateThunk,
  getTemplatesThunk,
  getTemplateThunk,
  getTotalRecord,
  getTypeInterimThunk,
  getWatchListThunk,
  getWorkSpaceThunk,
} from './thunk'

const valByKeyWithDot = (data, keyWithDot) => {
  return keyWithDot.split('.').reduce((result, key) => {
    return result?.[key] === undefined || result?.[key] === null
      ? ''
      : result?.[key]
  }, data)
}

const changeValueWithDot = (value, key, newValue) => {
  const deepCloneValue = JSON.parse(JSON.stringify(value))
  const arrKey = key.split('.')

  return arrKey.reduce((result, key, index) => {
    if (index === arrKey.length - 1) {
      result[key] = newValue
      return deepCloneValue
    }

    return result[key]
  }, deepCloneValue)
}

const handleAddCondition = (state, payload) => {
  const indicatorId = payload.indicatorId
  const indicator = payload
  const firstTypeInterim =
    state.data.typeInterims[indicator.indicatorGroupId][0]

  if (!state.data.conditionIds.includes(indicatorId)) {
    state.data.conditionIds.push(indicatorId)
    state.data.conditions[indicatorId] = [
      handleDataInitCondition({ ...indicator, ...firstTypeInterim }),
    ]
  } else if (firstTypeInterim.dataTypeCode !== typeData.DAILY) {
    state.data.conditions[indicatorId].push(
      handleDataInitCondition({ ...indicator, ...firstTypeInterim }),
    )
  }

  const index = state.data.addMoreConditionIds.indexOf(indicatorId)
  if (index !== -1) {
    state.data.addMoreConditionIds.splice(index, 1)
    delete state.data.addMoreConditionByIds[indicatorId]
  }
}

export const key = {
  EXCHANGE: 'exchange',
  SECTOR: 'sector',
  QUICK_SELECT_INDICATORS: 'quickSelectIndicators',
  RATIO_INDICATORS: 'ratioIndicators',
  FINANCIAL_STATEMENT_INDICATORS: 'financialStatementIndicators',
  TEMPLATES: 'templates',
  CONDITIONS: 'conditions',
  RESULT: 'result',
  WATCHLIST: 'watchlist',
}

const initialState = {
  loading: {
    [key.QUICK_SELECT_INDICATORS]: false,
    [key.RATIO_INDICATORS]: false,
    [key.FINANCIAL_STATEMENT_INDICATORS]: false,
    [key.TEMPLATES]: false,
    [key.CONDITIONS]: false,
    [key.RESULT]: false,
    [key.WATCHLIST]: false,
  },
  isFirstMount: {
    [key.EXCHANGE]: true,
    [key.SECTOR]: true,
  },
  parentChecked: { [key.EXCHANGE]: [], [key.SECTOR]: [] },
  filters: {
    [key.EXCHANGE]: [],
    [key.SECTOR]: [],
    screenerDate: formatDateTime(new Date(), FORMAT.YEAR_MONTH_DAY_2),
  },
  data: {
    typeInterims: {},
    allTypeInterims: [],
    templates: [],
    template: {},
    sectorsTemplate: [],
    exchangesTemplate: [],
    conditionIds: [],
    conditionByIds: {},
    conditions: {},
    results: {
      tickerByIds: {},
      tickerIds: [],
      keyTable: {},
      keyTableByIds: {},
      keyUnique: getUnique(),
      initialTickerIds: [],
    },
    quickSelectIndicators: [],
    ratioIndicators: [],
    financialStatementIndicators: [],
    maxScreenerDate: null,
    lastDataApply: {
      sectors: [],
      exchanges: [],
      screenerDate: '',
      conditions: [],
      addedInformations: [],
      addedPeriod: {},
    },
    checkConditionLastApply: {},
    listCheckIndicatorPopup: [],
    addMoreConditionIds: [],
    addMoreConditionByIds: {},
    //watchlist
    watchlist: {
      watchlists: [],
      tickerIds: [],
      tickerByIds: {},
      watchlistInformation: {},
    },
    addedPeriod: {
      quarter: blankValue,
      year: new Date().getFullYear(),
    },
    screeningCount: 0,
  },
  isLoadWorkSpace: false,
}

export const slice = createSlice({
  name: 'tools/smartScreening',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    // action change is first mount
    changeIsFirstMount(state, action) {
      state.isFirstMount[action.payload] = false
    },
    // action handle checkbox exchange, sector
    changeCheckedSector(state, action) {
      state.filters.sector = action.payload
    },
    changeCheckedExchange(state, action) {
      state.filters.exchange = action.payload
    },
    changeParentCheckedSector(state, action) {
      state.parentChecked.sector = action.payload
    },
    changeParentCheckedExchange(state, action) {
      state.parentChecked.exchange = action.payload
    },
    // action filter screener date
    changeScreenerDate(state, action) {
      state.filters.screenerDate = action.payload
    },
    // action result table
    changeTickerIds(state, action) {
      state.data.results.tickerIds = action.payload
    },
    changeKeyUniqueTable(state) {
      state.data.results.keyUnique = getUnique()
    },
    sortResult: (state, action) => {
      state.data.results.tickerIds = getIdsFromProps(
        state.data.results.tickerIds,
        state.data.results.tickerByIds,
        action.payload,
        state.data.results.initialTickerIds,
      )
    },
    resetResult(state) {
      state.data.results = {
        ...initialState.data.results,
        keyUnique: getUnique(),
      }
      state.data.lastDataApply = initialState.data.lastDataApply
      state.data.checkConditionLastApply =
        initialState.data.checkConditionLastApply
    },
    // condition
    addCondition(state, action) {
      const lengthConditions =
        Object.keys(state.data.conditions).reduce((result, key) => {
          return (result += state.data.conditions[key].length)
        }, 0) + 1
      checkMaxCondition(lengthConditions, () =>
        handleAddCondition(state, action.payload),
      )
    },
    addArrayCondition(state, action) {
      const lengthConditions =
        Object.keys(state.data.conditions).reduce((result, key) => {
          return (result += state.data.conditions[key].length)
        }, 0) + action.payload.length
      checkMaxCondition(
        lengthConditions,
        () =>
          action.payload.forEach((indicator) =>
            handleAddCondition(state, indicator),
          ),
        action.payload,
      )
    },
    callbackErrorAddCondition(state, action) {
      let length = Object.keys(state.data.conditions).reduce((result, key) => {
        return (result += state.data.conditions[key].length)
      }, 0)
      action.payload.forEach((indicator) => {
        if (length < maxCondition) {
          handleAddCondition(state, indicator)
        }
        length += 1
      })
    },
    removeCondition(state, action) {
      const { indicatorId, index } = action.payload
      const dataCondition = state.data.conditions[indicatorId]
      if (dataCondition.length > 1) {
        state.data.conditions[indicatorId].splice(index, 1)
      } else {
        delete state.data.conditions[indicatorId]
        state.data.conditionIds.splice(
          state.data.conditionIds.indexOf(indicatorId),
          1,
        )
      }
    },
    resetCondition(state) {
      state.data.conditionIds = initialState.data.conditionIds
      state.data.conditions = initialState.data.conditions
    },
    changeCondition(state, action) {
      const { key, value } = action.payload
      state.data.conditions = changeValueWithDot(
        state.data.conditions,
        key,
        value,
      )
    },
    // more condition
    changeListCheckIndicatorPopup(state, action) {
      state.data.listCheckIndicatorPopup = action.payload
    },
    pushAddMoreCondition(state, action) {
      const arrPush = action.payload
      const newAddMoreConditionIds = [...state.data.addMoreConditionIds]
      arrPush?.forEach((itemIndicator) => {
        if (
          !newAddMoreConditionIds.includes(itemIndicator.indicatorId) &&
          !state.data.checkConditionLastApply[itemIndicator.indicatorId]
        ) {
          newAddMoreConditionIds.push(itemIndicator.indicatorId)
          state.data.addMoreConditionByIds[itemIndicator.indicatorId] = {
            ...itemIndicator,
            checked: true,
          }
        } else if (newAddMoreConditionIds.includes(itemIndicator.indicatorId)) {
          state.data.addMoreConditionByIds[
            itemIndicator.indicatorId
          ].checked = true
        }
      })
      state.data.addMoreConditionIds = newAddMoreConditionIds
    },
    sliceAddMoreCondition(state, action) {
      const arrSlice = action.payload?.map(
        (itemIndicator) => itemIndicator.indicatorId,
      )
      const newAddMoreConditionIds = state.data.addMoreConditionIds.filter(
        (id) => !arrSlice?.includes(id),
      )
      state.data.addMoreConditionIds = newAddMoreConditionIds
      arrSlice?.forEach((indicatorId) => {
        delete state.data.addMoreConditionByIds[indicatorId]
      })
    },
    changeCheckedItemMoreCondition(state, action) {
      const { id, value } = action.payload
      state.data.addMoreConditionByIds[id].checked = value
    },
    checkedArrayItemAddMoreCondition(state, action) {
      action.payload.forEach((indicator) => {
        if (state.data.addMoreConditionByIds[indicator.indicatorId]) {
          state.data.addMoreConditionByIds[indicator.indicatorId].checked = true
        }
      })
    },
    checkAllAddMoreCondition(state) {
      Object.keys(state.data.addMoreConditionByIds).forEach((id) => {
        state.data.addMoreConditionByIds[id].checked = true
      })
    },
    uncheckAllAddMoreCondition(state) {
      Object.keys(state.data.addMoreConditionByIds).forEach((id) => {
        state.data.addMoreConditionByIds[id].checked = false
      })
    },
    removeAddMoreCondition(state) {
      const idsRemove = []
      Object.keys(state.data.addMoreConditionByIds).forEach((id) => {
        if (state.data.addMoreConditionByIds[id].checked) {
          idsRemove.push(+id)
          delete state.data.addMoreConditionByIds[id]
        }
      })
      state.data.addMoreConditionIds = state.data.addMoreConditionIds.filter(
        (id) => !idsRemove.includes(+id),
      )
    },
    resetAddMoreCondition(state) {
      state.data.addMoreConditionIds = initialState.data.addMoreConditionIds
      state.data.addMoreConditionByIds = initialState.data.addMoreConditionByIds
      state.data.addedPeriod = initialState.data.addedPeriod
      state.data.listCheckIndicatorPopup =
        initialState.data.listCheckIndicatorPopup
    },
    //watchlist
    resetWatchlist(state) {
      state.data.watchlist = initialState.data.watchlist
    },
    addTicker(state) {
      state.data.watchlist.tickerIds = state.data.results.tickerIds

      const watchlistTickerByIds = {}
      Object.keys(state.data.results.tickerByIds).forEach((id) => {
        watchlistTickerByIds[id] = {
          ...state.data.results.tickerByIds[id],
          checked: true,
        }
      })
      state.data.watchlist.tickerByIds = watchlistTickerByIds
    },
    changeTickerChecked(state, action) {
      const { organizationId, value } = action.payload
      state.data.watchlist.tickerByIds[organizationId].checked = value
    },
    changeWatchlistInformation(state, action) {
      const { key, value } = action.payload
      state.data.watchlist.watchlistInformation[key] = value
    },
    // added period
    changeAddedPeriod(state, action) {
      const { key, value } = action.payload
      state.data.addedPeriod = changeValueWithDot(
        state.data.addedPeriod,
        key,
        value,
      )
    },
    // template
    resetTemplate(state) {
      state.data.template = initialState.data.template
    },
    resetScreeningCount(state) {
      state.data.screeningCount = initialState.data.screeningCount
    },
  },

  extraReducers: (builder) => {
    // quick select indicators
    builder.addCase(getQuickSelectIndicatorsThunk.pending, (state) => {
      state.loading[key.QUICK_SELECT_INDICATORS] = true
    })
    builder.addCase(
      getQuickSelectIndicatorsThunk.fulfilled,
      (state, action) => {
        state.data.conditionByIds = {
          ...state.data.conditionByIds,
          ...keyBy(action.payload, 'indicatorId'),
        }

        state.data.quickSelectIndicators = action.payload
        state.loading[key.QUICK_SELECT_INDICATORS] = false
      },
    )
    builder.addCase(getQuickSelectIndicatorsThunk.rejected, (state, action) => {
      state.loading[key.QUICK_SELECT_INDICATORS] = action.payload
    })
    // ratio indicators
    builder.addCase(getRatioIndicatorsThunk.pending, (state) => {
      state.loading[key.RATIO_INDICATORS] = true
    })
    builder.addCase(getRatioIndicatorsThunk.fulfilled, (state, action) => {
      state.data.conditionByIds = {
        ...state.data.conditionByIds,
        ...keyBy(action.payload, 'indicatorId'),
      }

      state.data.ratioIndicators = action.payload
      state.loading[key.RATIO_INDICATORS] = false
    })
    builder.addCase(getRatioIndicatorsThunk.rejected, (state, action) => {
      state.loading[key.RATIO_INDICATORS] = action.payload
    })
    // financial statement indicators
    builder.addCase(getFinancialStatementIndicatorsThunk.pending, (state) => {
      state.loading[key.FINANCIAL_STATEMENT_INDICATORS] = true
    })
    builder.addCase(
      getFinancialStatementIndicatorsThunk.fulfilled,
      (state, action) => {
        state.data.conditionByIds = {
          ...state.data.conditionByIds,
          ...keyBy(action.payload, 'indicatorId'),
        }

        state.data.financialStatementIndicators = action.payload
        state.loading[key.FINANCIAL_STATEMENT_INDICATORS] = false
      },
    )
    builder.addCase(
      getFinancialStatementIndicatorsThunk.rejected,
      (state, action) => {
        state.loading[key.FINANCIAL_STATEMENT_INDICATORS] = action.payload
      },
    )
    // templates
    builder.addCase(getTemplatesThunk.pending, (state) => {
      state.loading[key.TEMPLATES] = true
    })
    builder.addCase(getTemplatesThunk.fulfilled, (state, action) => {
      state.data.templates = action.payload
      state.loading[key.TEMPLATES] = false
    })
    builder.addCase(getTemplatesThunk.rejected, (state) => {
      state.loading[key.TEMPLATES] = false
    })
    // screening date
    builder.addCase(getScreeningDateThunk.fulfilled, (state, action) => {
      state.data.maxScreenerDate = action.payload
    })
    // template
    builder.addCase(getTemplateThunk.pending, (state) => {
      state.loading[key.CONDITIONS] = true
    })
    builder.addCase(getTemplateThunk.fulfilled, (state, action) => {
      state.data.listCheckIndicatorPopup =
        initialState.data.listCheckIndicatorPopup
      const {
        conditions,
        screenerDate,
        sectors,
        exchanges,
        addedPeriod,
        addedInformations,
      } = action.payload.parameters
      state.filters.screenerDate = screenerDate
      state.data.addedPeriod.quarter = addedPeriod.quarter || blankValue
      state.data.addedPeriod.year = addedPeriod.year || new Date().getFullYear()
      state.data.addMoreConditionIds = addedInformations.map(
        (item) => item.indicatorId,
      )
      state.data.addMoreConditionByIds = keyBy(
        addedInformations.map((item) => {
          return { ...item, checked: false }
        }),
        'indicatorId',
      )
      state.data.sectorsTemplate = sectors
      state.data.exchangesTemplate = exchanges

      const conditionIds = []
      const newConditions = {}
      conditions.forEach((condition) => {
        const indicatorId = condition.indicatorId
        if (!conditionIds.includes(indicatorId)) {
          conditionIds.push(indicatorId)
        }
        if (!newConditions[indicatorId]) {
          newConditions[indicatorId] = [condition]
        } else {
          newConditions[indicatorId].push(condition)
        }
      })
      state.data.conditionIds = conditionIds
      state.data.conditions = newConditions

      state.data.template = action.payload

      state.loading[key.CONDITIONS] = false
    })
    builder.addCase(getTemplateThunk.rejected, (state) => {
      state.loading[key.CONDITIONS] = false
    })
    // type interim
    builder.addCase(getTypeInterimThunk.pending, (state) => {
      state.loading[key.RATIO_INDICATORS] = true
    })
    builder.addCase(getTypeInterimThunk.fulfilled, (state, action) => {
      state.data.allTypeInterims = action.payload
      state.data.typeInterims = keyBy(action.payload, 'indicatorGroupId')
      Object.keys(state.data.typeInterims).forEach((id) => {
        state.data.typeInterims[id] = state.data.typeInterims[id].typeInterims
      })

      state.loading[key.RATIO_INDICATORS] = false
    })
    builder.addCase(getTypeInterimThunk.rejected, (state) => {
      state.loading[key.RATIO_INDICATORS] = false
    })
    // table result
    builder.addCase(getResultThunk.pending, (state) => {
      state.loading[key.RESULT] = true
    })
    builder.addCase(getResultThunk.fulfilled, (state, action) => {
      const { body, header } = action.payload

      const newKeyTable = {}
      newKeyTable[0] = 'no'
      header.forEach((item) => {
        newKeyTable[item.order] = `items.${item.order - 1}.value`
      })
      state.data.results.keyTable = newKeyTable

      state.data.results.keyTableByIds = keyBy(header, 'order')
      state.data.results.tickerIds = body.map((item) => item.organizationId)
      state.data.results.initialTickerIds = state.data.results.tickerIds
      state.data.results.tickerByIds = keyBy(
        body.map((item, index) => {
          return { no: index + 1, ...item }
        }),
        'organizationId',
      )
      state.data.results.keyUnique = getUnique()

      state.data.lastDataApply = action.meta.arg
      state.data.checkConditionLastApply = {}
      state.data.lastDataApply.conditions.forEach((indicator) => {
        state.data.checkConditionLastApply[indicator.indicatorId] = true
      })

      state.loading[key.RESULT] = false
    })
    builder.addCase(getResultThunk.rejected, (state) => {
      state.loading[key.RESULT] = false
    })
    // watchlist
    builder.addCase(getWatchListThunk.pending, (state) => {
      state.loading[key.WATCHLIST] = true
    })
    builder.addCase(getWatchListThunk.fulfilled, (state, action) => {
      state.data.watchlist.watchlists = action.payload
      state.loading[key.WATCHLIST] = false
    })
    builder.addCase(getWatchListThunk.rejected, (state) => {
      state.loading[key.WATCHLIST] = false
    })
    builder.addCase(getTotalRecord.fulfilled, (state, action) => {
      state.data.screeningCount = action.payload
    })
    // get work space
    builder.addCase(getWorkSpaceThunk.fulfilled, (state, action) => {
      const workSpace = action.payload.find(
        (item) => item.dataType === typeWorkSpace.SCREENING,
      )

      if (workSpace) {
        const dataWorkSpace = workSpace.parameters.rawParameter

        state.filters = dataWorkSpace.filters

        state.data = { ...state.data, ...dataWorkSpace.data }
      }

      state.isLoadWorkSpace = true
    })
    builder.addCase(getWorkSpaceThunk.rejected, (state) => {
      state.isLoadWorkSpace = true
    })
  },
})

export const {
  resetStore,
  changeIsFirstMount,
  changeScreenerDate,
  changeTickerIds,
  changeKeyUniqueTable,
  resetResult,
  changeCheckedSector,
  changeCheckedExchange,
  addCondition,
  addArrayCondition,
  removeCondition,
  changeCondition,
  resetCondition,
  changeParentCheckedSector,
  changeParentCheckedExchange,
  changeListCheckIndicatorPopup,
  pushAddMoreCondition,
  sliceAddMoreCondition,
  changeCheckedItemMoreCondition,
  checkedArrayItemAddMoreCondition,
  checkAllAddMoreCondition,
  uncheckAllAddMoreCondition,
  removeAddMoreCondition,
  resetAddMoreCondition,
  resetWatchlist,
  addTicker,
  changeTickerChecked,
  changeWatchlistInformation,
  changeAddedPeriod,
  resetTemplate,
  sortResult,
  callbackErrorAddCondition,
  resetScreeningCount,
} = slice.actions

export const selectLocale = (state) => state.i18n.locale

export const selectLoading = (key) => (state) =>
  state[slice.name].loading?.[key]

export const selectChecked = (key) => (state) =>
  state[slice.name].filters?.[key]
export const selectParentChecked = (key) => (state) =>
  state[slice.name].parentChecked?.[key]

export const selectIsFirstMount = (key) => (state) =>
  state[slice.name]?.isFirstMount[key]

export const selectScreenerDate = (state) =>
  state[slice.name].filters.screenerDate
export const selectMaxScreenerDate = (state) =>
  state[slice.name].data.maxScreenerDate

export const selectFilters = (state) => state[slice.name].filters

export const selectTemplates = (state) => state[slice.name].data.templates

export const selectTemplate = (state) => state[slice.name].data.template
export const selectSectorsTemplate = (state) =>
  state[slice.name].data.sectorsTemplate
export const selectExchangesTemplate = (state) =>
  state[slice.name].data.exchangesTemplate

export const selectResultTickerIds = (state) =>
  state[slice.name].data.results.tickerIds
export const selectResultTickerCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.results.tickerByIds[id], attr)
export const selectResultKeyTable = (state) =>
  state[slice.name].data.results.keyTable
export const selectResultKeyTableByIds = (id) => (state) =>
  state[slice.name].data.results.keyTableByIds[id]
export const selectResultKeyUnique = (state) =>
  state[slice.name].data.results.keyUnique

export const selectQuickSelectIndicators = (state) =>
  state[slice.name].data.quickSelectIndicators
export const selectRatioIndicators = (state) =>
  state[slice.name].data.ratioIndicators
export const selectFinancialStatementIndicators = (state) =>
  state[slice.name].data.financialStatementIndicators

export const selectTypeInterims = (attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.typeInterims, attr)
export const selectAllTypeInterims = (state) =>
  state[slice.name].data.allTypeInterims

export const selectConditionIds = (state) => state[slice.name].data.conditionIds
export const selectConditions = (state) => state[slice.name].data.conditions
export const selectItemCondition = (attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.conditions, attr)
export const selectItemConditionByIds = (id) => (state) =>
  state[slice.name].data.conditionByIds[id]

export const selectListCheckIndicatorPopup = (state) =>
  state[slice.name].data.listCheckIndicatorPopup
export const selectAddMoreConditionIds = (state) =>
  state[slice.name].data.addMoreConditionIds
export const selectAddMoreConditionByIds = (state) =>
  state[slice.name].data.addMoreConditionByIds
export const selectItemAddMoreCondition = (id) => (state) =>
  state[slice.name].data.addMoreConditionByIds[id]

export const selectWatchlists = (state) =>
  state[slice.name].data.watchlist.watchlists
export const selectWatchlistTickerIds = (state) =>
  state[slice.name].data.watchlist.tickerIds
export const selectWatchlistTickerByIds = (state) =>
  state[slice.name].data.watchlist.tickerByIds
export const selectWatchlistItemTicker = (id) => (state) =>
  state[slice.name].data.watchlist.tickerByIds[id]
export const selectWatchlistInformation = (state) =>
  state[slice.name].data.watchlist.watchlistInformation

export const selectAddedPeriod = (state) => state[slice.name].data.addedPeriod

export const selectLastDataApply = (state) =>
  state[slice.name].data.lastDataApply

export const selectCheckConditionLastApply = (state) =>
  state[slice.name].data.checkConditionLastApply
export const selectScreeningCount = (state) =>
  state[slice.name].data.screeningCount

export const selectIsLoadWorkSpace = (state) =>
  state[slice.name].isLoadWorkSpace

register(slice.name, slice.reducer)
