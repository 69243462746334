import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Layout } from '../common/layout'
import { changePositionChart } from '../store/slice'
import { getSharedCharts, getSharedDashboard } from '../store/thunk'
import SharedChart from './SharedChart'
import SharedDashboardChart from './SharedDashboardChart'

export const ShareChart = ({ type }) => {
  let { key } = useParams()
  const [dataChart, setDataChart] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const asyncFn = async () => {
      if (!key) return
      if (type === 'chart') {
        const { payload } = await dispatch(getSharedCharts({ ShareId: key }))
        setDataChart(payload)
      } else if (type === 'dashboard') {
        const { payload } = await dispatch(getSharedDashboard({ ShareId: key }))
        setDataChart(payload)
        dispatch(changePositionChart(payload.charts))
      }
    }

    asyncFn()
  }, [key])

  return (
    <Layout>
      {dataChart && type === 'chart' && <SharedChart item={dataChart} />}
      {dataChart && type === 'dashboard' && (
        <SharedDashboardChart id={dataChart.id} name={dataChart.name} />
      )}
    </Layout>
  )
}
