import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { formatVal } from '../../../../utils/Value'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  barKey,
  lineKeys,
}) => {
  const renderTooltip = (tooltipData) => {
    const tooltipContentData = [
      {
        label: I18n.t('economy.fdi.overview.NEW_APPROVAL_PROJECT'),
        value: barKey,
        format: (val) => val,
        unit: 'economy.fdi.overview.PROJECTS',
      },
      {
        label: I18n.t('economy.fdi.overview.DISBURSED_CAPITAL'),
        value: lineKeys[0],
        format: (val) => formatVal(val),
        unit: 'economy.fdi.overview.MILLION_USD',
      },
      {
        label: I18n.t('economy.fdi.overview.NEW_AND_ADDITIONAL_REGISTRATION'),
        value: lineKeys[1],
        format: (val) => formatVal(val),
        unit: 'economy.fdi.overview.MILLION_USD',
      },
    ]

    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{tooltipData.year}</Span>
        </div>
        {tooltipContentData.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {I18n.t(el.label) + ':'}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {el.format(tooltipData[el.value]) + ' ' + I18n.t(el.unit)}
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height || 0}
      keyXAxis={keyXAxis}
      hasBarChart
      yAxis={[
        {
          id: 'left',
          keys: lineKeys,
          orientation: 'left',
          isLineChart: true,
          tickNum: SETTINGS.yTickNum,
          label: I18n.t('economy.fdi.overview.LABEL_MILLION_USD'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
        },
        {
          id: 'right',
          keys: [barKey],
          orientation: 'right',
          isBarChart: true,
          tickNum: SETTINGS.yTickNum,
          label: I18n.t('economy.fdi.overview.LABEL_PROJECTS_CHART'),
          labelPosition: AXIS_LABEL_POSITION.RIGHT,
        },
      ]}
      margin={{ left: 0, right: 0, top: 30, bottom: 10 }}
      renderCustomTooltip={renderTooltip}
    >
      <Bar
        isAnimationActive={false}
        yAxisId="right"
        dataKey={barKey}
        barSize={getColumnSizeInBarChart(width, data.length, 35)}
        fill="#0096eb"
      />

      {lineKeys.map((lineKey, index) => (
        <React.Fragment key={index}>
          <Line
            yAxisId="left"
            dataKey={lineKey}
            type="linear"
            stroke={index === 0 ? '#ecffdf' : '#facc5c'}
            dot={{
              stroke: index === 0 ? '#ecffdf' : '#facc5c',
              strokeWidth: 5,
            }}
            strokeWidth={2}
            activeDot={false}
            isAnimationActive={false}
          />
        </React.Fragment>
      ))}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}
