import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import IndexStatisticService from '../../../../../../core/services/market/marketInDepth/IndexStatisticService'

export const getStatisticsNetTradingValueForeignChart = createAsyncThunk(
  'market/marketInDepth/indexStatistics/netValue/GET_STATISTICS_NET_TRADING_VALUE_FOREIGN_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getStatisticsNetTradingValueForeignChart(
          params,
        )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getStatisticsNetTradingValueIndividualChart = createAsyncThunk(
  'market/marketInDepth/indexStatistics/netValue/GET_STATISTICS_NET_TRADING_VALUE_INDIVIDUAL_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getStatisticsNetTradingValueIndividualChart(
          params,
        )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getStatisticsNetTradingValueInstitutionChart = createAsyncThunk(
  'market/marketInDepth/indexStatistics/netValue/GET_STATISTICS_NET_TRADING_VALUE_INSTITUTION_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getStatisticsNetTradingValueInstitutionChart(
          params,
        )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getStatisticsNetTradingValueProprietaryChart = createAsyncThunk(
  'market/marketInDepth/indexStatistics/netValue/GET_STATISTICS_NET_TRADING_VALUE_PROPRIETARY_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getStatisticsNetTradingValueProprietaryChart(
          params,
        )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
