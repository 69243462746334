import { keyBy } from 'lodash'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import TextEllipsis from '../../../../common/textEllipsis'
import style from './index.module.css'

const NameChecked = ({ exchanges, listParentChecked, exchangeTreeLength }) => {
  const [exchangeById, setExchangeById] = useState({})

  useEffect(() => {
    setExchangeById(keyBy(exchanges, 'groupId'))
  }, [JSON.stringify(exchanges)])

  return (
    <div className={style.selectTitle}>
      {listParentChecked.length > 0 &&
      exchangeTreeLength === listParentChecked.length ? (
        <Translate value="financialChart.ALL_EXCHANGE" />
      ) : listParentChecked.length > 0 ? (
        <TextEllipsis
          isI18n={false}
          text={listParentChecked
            .map((key) => exchangeById[key]?.groupName)
            .join('; ')}
        />
      ) : (
        <Translate value="financialChart.EXCHANGE" />
      )}
    </div>
  )
}

export default NameChecked
