import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import useGetICB from '../../../../../common/masterData/useGetICB'
import useGetStock from '../../../../../common/masterData/useGetStock'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { PANEL_PADDING } from '../../../../../constants/Common'
import { formatNumber, keyBy } from '../../../../../utils/Common'
import { DATA_TYPE } from './constant'
import Header from './header'
import { getIcbByOrganization } from './helper'
import {
  selectFilter,
  selectIsFirst,
  selectLoading,
  selectRowIds,
  selectTableType,
  selectValuation,
  updateDataIcbLv2,
  updateDataType,
  updateIsSuccessCallValuation,
} from './store/slice'
import { exportFileApi, getData, getValuationThunk } from './store/thunk'
import TableChart from './tableChart'

const PanelPeersComparison = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()
  const organizationIdOld = useRef('')
  const { ICBs, ICBById } = useGetICB()
  const { stockById } = useGetStock()
  const icbByCode = keyBy(ICBs, 'icbCode')

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const type = useSelector(selectTableType)
  const basicInfo = useSelector(selectBasicInfo)
  const loading = useSelector(selectLoading)
  const ids = useSelector(selectRowIds)
  const valuation = useSelector(selectValuation)
  const filter = useSelector(selectFilter)
  const isFirst = useSelector(selectIsFirst)

  // Actions
  const handleCallData = () => {
    dispatch(
      getData({
        organizationId: basicInfo.organizationId,
        organizationIds: isFirst
          ? []
          : valuation
              .map((e) => e?.organizationId)
              .filter((item) => item !== basicInfo?.organizationId),
        realYear: filter.year,
        realQuarter: filter.quarter,
      }),
    )
    dispatch(updateIsSuccessCallValuation(false))
  }

  const getValuation = () => {
    dispatch(
      getValuationThunk({
        organizationId: basicInfo.organizationId,
        peerOrganizationIds: [],
      }),
    )
  }

  const exportFile = () => {
    return exportFileApi({
      organizationId: basicInfo.organizationId,
      organizationIds: isFirst ? [] : valuation.map((e) => e?.organizationId),
      realYear: filter.year,
      realQuarter: filter.quarter,
    })
  }

  // Use effect
  useEffect(() => {
    const icbLv2 = getIcbByOrganization({
      stock: basicInfo,
      stockById,
      ICBById,
      icbByCode,
    })

    let type = DATA_TYPE.ENTER_PRISE
    if (icbLv2?.icbId === 154) {
      type = DATA_TYPE.STOCK
    }
    if (icbLv2?.icbId === 136) {
      type = DATA_TYPE.BANK
    }
    if (icbLv2?.icbId === 148) {
      type = DATA_TYPE.REAL_ESTATE
    }
    dispatch(updateDataIcbLv2(icbLv2?.icbId || ''))
    dispatch(updateDataType(type))
  }, [basicInfo.organizationId])

  useEffect(() => {
    if (
      basicInfo?.organizationId &&
      organizationIdOld.current !== basicInfo?.organizationId
    ) {
      getValuation()
      organizationIdOld.current = basicInfo?.organizationId
    }
  }, [basicInfo?.organizationId])

  useEffect(() => {
    if (valuation.length > 0 && basicInfo.organizationId) {
      handleCallData()
    }
  }, [valuation, filter, locale, basicInfo.organizationId])

  return (
    <Panel
      title="corporate.peerValuation.overview.PEERS_COMPARISON"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={() => exportFile()}
    >
      <SizeTracker>
        {(size) => {
          return (
            <>
              <Header
                width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
              />
              {size.height && (
                <div
                  style={{
                    height: `calc(100% - ${size.height}px)`,
                  }}
                >
                  {loading ? (
                    <Loading />
                  ) : ids.length === 0 ? (
                    <NoData />
                  ) : (
                    <TableChart tableType={type} />
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </Panel>
  )
}

export default PanelPeersComparison
