import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Z_INDEX } from '../../constants/Common'
import { Span } from '../html/Span'
import { IconClose } from '../icons/iconClose'
import style from './PopupHeaderCommon.module.css'
import Popup from './index'

export const PopupHeaderCommon = ({
  title,
  setIsShow,
  zIndexOverlay,
  zIndex,
  children,
  styleEditpopupContent,
  isHidePadding,
}) => {
  return (
    <>
      <Popup zIndex={zIndex} zIndexOverlay={zIndexOverlay}>
        <div className={style.popupWrapper}>
          <div className={style.popup}>
            <div className={style.popupHeader}>
              <Span className={style.popupHeaderTitle}>
                <Translate value={title} />
              </Span>
              <IconClose setIsShow={setIsShow} />
            </div>

            <div
              className={!isHidePadding ? style.popupContent : ''}
              style={styleEditpopupContent}
            >
              {children}
            </div>
          </div>
        </div>
      </Popup>
    </>
  )
}

PopupHeaderCommon.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  setIsShow: PropTypes.func.isRequired,
  zIndex: PropTypes.number,
  zIndexOverlay: PropTypes.number,
}

PopupHeaderCommon.defaultProps = {
  zIndex: Z_INDEX.MODAL,
  zIndexOverlay: Z_INDEX.MODAL + 1,
}
