import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { TAB_TYPE } from '../table/constants'
import { GROWTH_SECTOR_DATA, GROWTH_SECTOR_DATA_YEARLY } from './constants'
import {
  getTimeRangeAcc,
  getTimeRangeYearly,
  handleTimeRangeAcc,
  handleTimeRangeYearly,
} from './store/slice'

const TimeRange = ({ headerTab, yearlyQuarterValue, typeDataTime }) => {
  const dispatch = useDispatch()
  const { GENERAL_INDEX } = TAB_TYPE
  const isYearlyData =
    (yearlyQuarterValue === 'Yearly' && headerTab === GENERAL_INDEX) ||
    (typeDataTime === 'Yearly' && headerTab !== GENERAL_INDEX)

  const timeRangeYearly = useSelector(getTimeRangeYearly)
  const timeRangeAcc = useSelector(getTimeRangeAcc)

  const activeTab = isYearlyData ? timeRangeYearly : timeRangeAcc

  const onChangeTab = (item) => {
    if (isYearlyData) {
      dispatch(handleTimeRangeYearly(item.value))
    } else {
      dispatch(handleTimeRangeAcc(item.value))
    }
  }

  return (
    <div className="d-flex justify-content-end box-price-chart">
      <DispatchActionTab
        data={isYearlyData ? GROWTH_SECTOR_DATA_YEARLY : GROWTH_SECTOR_DATA}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
      />
    </div>
  )
}

TimeRange.propTypes = {
  headerTab: PropTypes.string.isRequired,
  yearlyQuarterValue: PropTypes.string.isRequired,
  typeDataTime: PropTypes.string.isRequired,
}

export default TimeRange
