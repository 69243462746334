export const latePaymentTypeValue = {
  ALL: 'ALL',
  Origin: 'Origin',
  Interest: 'Interest',
}

export const latePaymentType = [
  {
    label: 'bond.overview.latePayment.filter.latePaymentType.all',
    value: latePaymentTypeValue.ALL,
  },
  {
    label: 'bond.overview.latePayment.filter.latePaymentType.origin',
    value: latePaymentTypeValue.Origin,
  },
  {
    label: 'bond.overview.latePayment.filter.latePaymentType.interest',
    value: latePaymentTypeValue.Interest,
  },
]
