import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FundStatisticsService from '../../../../../core/services/fund/FundStatisticsService'
import { handleExport } from '../../../../utils/Export'
import { TABLE_TABS } from '../constants'

export const getFundManagementCompanyThunk = createAsyncThunk(
  'fund/fundCenter/statistic/GET_FUND_MANAGEMENT_COMPANY',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FundStatisticsService.getFundManagementCompany(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTableDataThunk = createAsyncThunk(
  'fund/fundCenter/statistic/GET_TABLE_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const { params, tableTab } = data
      let response = {}
      switch (tableTab) {
        case TABLE_TABS.KEY_METRICS:
          response = await FundStatisticsService.getKeyMetric(params)
          break
        case TABLE_TABS.PERFORMANCE:
          response = await FundStatisticsService.getPerformance(params)
          break
        case TABLE_TABS.FUND_FLOW:
          response = await FundStatisticsService.getStatisticsFundFlow(params)
          break
        case TABLE_TABS.SECTOR_ALLOCATION:
          response = await FundStatisticsService.getSectorAllocation(params)
          break
        case TABLE_TABS.ASSET_ALLOCATION:
          response = await FundStatisticsService.getAssetAllocation(params)
          break
        case TABLE_TABS.FUND_INDEX:
          response = await FundStatisticsService.getFundIndex(params)
          break
        default:
          break
      }

      if (response.success) {
        return {
          data: response.data,
          totalPage: response.data.length
            ? Math.ceil(response.data[0].totalRow / params.PageSize)
            : 1,
          page: params.Page,
          pageSize: params.PageSize,
        }
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadKeyMetric = createAsyncThunk(
  'fund/fundCenter/statistic/DOWNLOAD_KEY_METRIC',
  async (data) => {
    const response = await FundStatisticsService.downloadKeyMetric(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadPerformance = createAsyncThunk(
  'fund/fundCenter/statistic/DOWNLOAD_PERFORMANCE',
  async (data) => {
    const response = await FundStatisticsService.downloadPerformance(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadStatisticsFundFlow = createAsyncThunk(
  'fund/fundCenter/statistic/DOWNLOAD_STATISTICS_FUND_FLOW',
  async (data) => {
    const response = await FundStatisticsService.downloadStatisticsFundFlow(
      data,
    )
    handleExport(response.data, response.filename)
  },
)

export const downloadSectorAllocation = createAsyncThunk(
  'fund/fundCenter/statistic/DOWNLOAD_SECTOR_ALLOCATION',
  async (data) => {
    const response = await FundStatisticsService.downloadSectorAllocation(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadAssetAllocation = createAsyncThunk(
  'fund/fundCenter/statistic/DOWNLOAD_ASSET_ALLOCATION',
  async (data) => {
    const response = await FundStatisticsService.downloadAssetAllocation(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadFundIndex = createAsyncThunk(
  'fund/fundCenter/statistic/DOWNLOAD_FUND_INDEX',
  async (data) => {
    const response = await FundStatisticsService.downloadFundIndex(data)
    handleExport(response.data, response.filename)
  },
)
