import { COM_GROUP } from '../../../../constants/Group'
import { GroupBarLineChart } from '../../common/GroupBarLineChart'
import { GroupStackedBarChart } from '../../common/GroupStackedBarChart'
import { HorizontalBarChart } from '../../common/HorizontalBarChart'
import { PieLineLabelChart } from '../../common/PieLineLabelChart'
import { StackedChartWithLine } from '../../common/StackedBarLineChart'
import { STATEMENT_CONSTANTS } from '../constants'
import {
  getAssetBreakdownChartData,
  getBalanceSheetStructureChartData,
  getCashFlowStructureChartData,
  getDGAndCGChartData,
  getFinancialAssetsStructureChartData,
  getFinancialExpensesChartData,
  getFinancialIncomeChartData,
  getInsurancePremiumBreakdownChartData,
  getInventoryStructureChartData,
  getLoansByIndustryChartData,
  getLoansByTermChartData,
  getMarginLendingChartData,
  getOperatingCashFlowChartData,
  getOperatingExpensesStructureChartData,
  getOperatingSalesStructureChartData,
  getProfitabilityChartData,
  getProfitAndLossChartData,
  getReservesChartData,
  getRevenueAndProfitChartData,
  getSTAndLTBorrowingChartData,
  getTICSEChartData,
  getTOIStructureChartData,
} from './store/thunk'

export const CHART_SETTINGS = {
  barWidth: 35,
  fontSize: 12,
}

export const FINANCIAL_STATEMENT_CHARTS_OPTIONS = {
  [STATEMENT_CONSTANTS.INCOME_STATEMENT]: {
    [COM_GROUP.BANK]: {
      chart1: {
        thunkFuncName: 'getTOIStructureChartData',
        thunkFunc: (params) => getTOIStructureChartData(params),
        dataFormat: {
          iS27: 10 ** 9,
          iS30: 10 ** 9,
          iS38_A: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel: 'corporate.financialData.financialStatement.TOI_STRUCTURE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            iS27: '#176491',
            iS30: '#fff6df',
            iS38_A: '#df5a49',
          },
          xAxisKey: 'label',
          barKeys: ['iS27', 'iS30', 'iS38_A'],
          mappingDisplayName: {
            iS27: 'corporate.financialData.financialStatement.NII',
            iS30: 'corporate.financialData.financialStatement.NFI',
            iS38_A: 'corporate.financialData.financialStatement.OTHER_INCOME',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getProfitAndLossChartData',
        thunkFunc: (params) => getProfitAndLossChartData(params),
        dataFormat: {
          iS38: 10 ** 9,
          iS40: 10 ** 9,
          iS16: 10 ** 9,
        },
        component: GroupBarLineChart,
        topLabel: 'corporate.financialData.financialStatement.PROFIT_LOSS',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            iS38: '#185999',
            iS40: '#fff6df',
            iS16: '#a6cf98',
          },
          xAxisKey: 'label',
          barKeys: ['iS38', 'iS40', 'iS16'],
          mappingDisplayName: {
            iS38: 'corporate.financialData.financialStatement.TOI',
            iS40: 'corporate.financialData.financialStatement.PREPROVISON_PROFIT',
            iS16: 'corporate.financialData.financialStatement.PBT',
          },
        },
      },
    },
    [COM_GROUP.INSURANCE]: {
      chart1: {
        thunkFuncName: 'getRevenueAndProfitChartData',
        thunkFunc: (params) => getRevenueAndProfitChartData(params),
        dataFormat: {
          iS3: 10 ** 9,
          iS94: 10 ** 9,
          iS20: 10 ** 9,
          rT0048: 10 ** 9,
        },
        component: GroupBarLineChart,
        topLabel: 'corporate.financialData.financialStatement.REVENUE_PROFIT',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            iS3: '#176491',
            iS94: '#fff6df',
            iS20: '#df5a49',
          },
          xAxisKey: 'label',
          barKeys: ['iS3', 'iS94', 'iS20'],
          mappingDisplayName: {
            iS3: 'corporate.financialData.financialStatement.NET_REVENUE',
            iS94: 'corporate.financialData.financialStatement.PROFIT_FINANCIAL_ACTIVITIES',
            iS20: 'corporate.financialData.financialStatement.NET_PROFIT',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getTICSEChartData',
        thunkFunc: (params) => getTICSEChartData(params),
        dataFormat: {
          iS73: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel: 'corporate.financialData.financialStatement.TOTAL_INSURANCE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            iS73: '#185999',
          },
          xAxisKey: 'label',
          barKeys: ['iS73'],
          mappingDisplayName: {
            iS73: 'corporate.financialData.financialStatement.CLAIM_RATIO',
          },
        },
      },
    },
    [COM_GROUP.SECURITIES]: {
      chart1: {
        thunkFuncName: 'getOperatingSalesStructureChartData',
        thunkFunc: (params) => getOperatingSalesStructureChartData(params),
        dataFormat: {
          iS114: 10 ** 9,
          iS111: 10 ** 9,
          iS106_A: 10 ** 9,
          iS3_A: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel: 'corporate.financialData.financialStatement.OPERATING_SALE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            iS114: '#185999',
            iS111: '#3cabeb',
            iS106_A: '#fff6df',
            iS3_A: '#fe7e6d',
          },
          xAxisKey: 'label',
          barKeys: ['iS114', 'iS111', 'iS106_A', 'iS3_A'],
          mappingDisplayName: {
            iS114: 'corporate.financialData.financialStatement.BROKERAGE',
            iS111: 'corporate.financialData.financialStatement.MARGIN_LENDING',
            iS106_A:
              'corporate.financialData.financialStatement.FINANCIAL_ASSET',
            iS3_A: 'corporate.financialData.financialStatement.OTHER',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getOperatingExpensesStructureChartData',
        thunkFunc: (params) => getOperatingExpensesStructureChartData(params),
        dataFormat: {
          iS134: 10 ** 9,
          iS124_A: 10 ** 9,
          iS4_A: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel:
          'corporate.financialData.financialStatement.OPERATING_EXPENSES_STRUCTURE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            iS134: '#185999',
            iS124_A: '#fff6df',
            iS4_A: '#fe7e6d',
          },
          xAxisKey: 'label',
          barKeys: ['iS134', 'iS124_A', 'iS4_A'],
          mappingDisplayName: {
            iS134: 'corporate.financialData.financialStatement.BROKERAGE',
            iS124_A:
              'corporate.financialData.financialStatement.FINANCIAL_ASSET',
            iS4_A: 'corporate.financialData.financialStatement.OTHER',
          },
        },
      },
    },
    [COM_GROUP.CORPORATE]: {
      chart1: {
        thunkFuncName: 'getRevenueAndProfitChartData',
        thunkFunc: (params) => getRevenueAndProfitChartData(params),
        dataFormat: {
          iS3: 10 ** 9,
          rT0048: 10 ** 9,
          iS20: 10 ** 9,
        },
        component: GroupBarLineChart,
        topLabel: 'corporate.financialData.financialStatement.REVENUE_PROFIT',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            iS3: '#176491',
            rT0048: '#fff6df',
            iS20: '#a6cf98',
          },
          xAxisKey: 'label',
          barKeys: ['iS3', 'rT0048', 'iS20'],
          mappingDisplayName: {
            iS3: 'corporate.financialData.financialStatement.REVENUE',
            rT0048: 'corporate.financialData.financialStatement.EBIT',
            iS20: 'corporate.financialData.financialStatement.NET_PROFIT',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getProfitabilityChartData',
        thunkFunc: (params) => getProfitabilityChartData(params),
        dataFormat: {
          iS20: 10 ** 9,
          iS5_A: 0.01,
          iS16_A: 0.01,
          iS20_A: 0.01,
        },
        component: StackedChartWithLine,
        topLabel: 'corporate.financialData.financialStatement.PROFITABILITY',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        rightLabel: 'corporate.financialData.financialStatement.CHART_PERCENT',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            iS20: '#0096eb',
            iS5_A: '#f7b80c',
            iS16_A: '#ecffdf',
            iS20_A: '#eb84ad',
          },
          xAxisKey: 'label',
          barKeys: ['iS20'],
          lineKeys: ['iS5_A', 'iS16_A', 'iS20_A'],
          lineUnit: '%',
          mappingDisplayName: {
            iS20: 'corporate.financialData.financialStatement.NET_PROFIT',
            iS5_A: 'corporate.financialData.financialStatement.GROSS_MARGIN',
            iS16_A: 'corporate.financialData.financialStatement.EBIT_MARGIN',
            iS20_A: 'corporate.financialData.financialStatement.NET_MARGIN',
          },
        },
      },
    },
  },
  [STATEMENT_CONSTANTS.BALANCE_SHEET]: {
    [COM_GROUP.BANK]: {
      chart1: {
        thunkFuncName: 'getBalanceSheetStructureChartData',
        thunkFunc: (params) => getBalanceSheetStructureChartData(params),
        dataFormat: {
          nB27: 10 ** 9,
          nB3_A: 10 ** 9,
          nB6_A: 10 ** 9,
          bS64_A: 10 ** 9,
          nB121: 10 ** 9,
          nB122: 10 ** 9,
          bS65_A: 10 ** 9,
          bS95: 10 ** 9,
        },
        component: GroupStackedBarChart,
        topLabel:
          'corporate.financialData.financialStatement.BALANCE_SHEET_STRUCTURE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            nB27: '#176491',
            nB3_A: '#a6cf98',
            nB6_A: '#45b29d',
            bS64_A: '#ecffdc',
            nB121: '#fe7e6d',
            nB122: '#ffe0c9',
            bS65_A: '#906251',
            bS95: '#f6ad5c',
          },
          xAxisKey: 'label',
          barKeys: [
            ['nB27', 'nB3_A', 'nB6_A', 'bS64_A'],
            ['nB122', 'nB121', 'bS65_A', 'bS95'],
          ],
          mappingDisplayName: {
            nB27: 'corporate.financialData.financialStatement.LOAN',
            nB3_A: 'corporate.financialData.financialStatement.G_BOND',
            nB6_A: 'corporate.financialData.financialStatement.C_BOND',
            bS64_A: 'corporate.financialData.financialStatement.OTHER_ASSET',
            nB121: 'corporate.financialData.financialStatement.CASA',
            nB122: 'corporate.financialData.financialStatement.TERM_DEPOSIT',
            bS65_A:
              'corporate.financialData.financialStatement.OTHER_LIABILITIES',
            bS95: 'corporate.financialData.financialStatement.SHAREHOLDER_EQUITY',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getDGAndCGChartData',
        thunkFunc: (params) => getDGAndCGChartData(params),
        dataFormat: {
          bS158_A: 0.01,
          bS158_B: 0.01,
          bS178_A: 0.01,
        },
        component: StackedChartWithLine,
        topLabel:
          'corporate.financialData.financialStatement.DEPOSIT_CREDIT_GROWTH',
        leftLabel: 'corporate.financialData.financialStatement.CHART_PERCENT',
        rightLabel: 'corporate.financialData.financialStatement.CHART_PERCENT',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            bS158_A: '#0096eb',
            bS158_B: '#f7b80c',
            bS178_A: '#ecffdf',
          },
          xAxisKey: 'label',
          barKeys: ['bS158_A'],
          lineKeys: ['bS158_B', 'bS178_A'],
          unit: '%',
          lineUnit: '%',
          mappingDisplayName: {
            bS158_A: 'corporate.financialData.financialStatement.LDR',
            bS158_B:
              'corporate.financialData.financialStatement.DEPOSIT_GROWTH',
            bS178_A: 'corporate.financialData.financialStatement.CREDIT_GROWTH',
          },
        },
      },
    },
    [COM_GROUP.INSURANCE]: {
      chart1: {
        thunkFuncName: 'getBalanceSheetStructureChartData',
        thunkFunc: (params) => getBalanceSheetStructureChartData(params),
        dataFormat: {
          bS2: 10 ** 9,
          bS9_A: 10 ** 9,
          bS5_A: 10 ** 9,
          bS64_A: 10 ** 9,
          bS229: 10 ** 9,
          bS65_A: 10 ** 9,
          bS95: 10 ** 9,
        },
        component: GroupStackedBarChart,
        topLabel:
          'corporate.financialData.financialStatement.BALANCE_SHEET_STRUCTURE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            bS2: '#176491',
            bS9_A: '#a6cf98',
            bS5_A: '#45b29d',
            bS64_A: '#ecffdc',
            bS229: '#fe7e6d',
            bS65_A: '#ffe0c9',
            bS95: '#906251',
          },
          xAxisKey: 'label',
          barKeys: [
            ['bS2', 'bS9_A', 'bS5_A', 'bS64_A'],
            ['bS229', 'bS65_A', 'bS95'],
          ],
          mappingDisplayName: {
            bS2: 'corporate.financialData.financialStatement.CASH_AND_EQUIVALENTS',
            bS9_A: 'corporate.financialData.financialStatement.RECEIVABLES',
            bS5_A: 'corporate.financialData.financialStatement.INVESTMENT',
            bS64_A: 'corporate.financialData.financialStatement.OTHER_ASSET',
            bS229: 'corporate.financialData.financialStatement.RESERVES',
            bS65_A:
              'corporate.financialData.financialStatement.OTHER_LIABILITIES',
            bS95: 'corporate.financialData.financialStatement.OWNER_EQUITY',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getReservesChartData',
        thunkFunc: (params) => getReservesChartData(params),
        dataFormat: {
          bS230: 10 ** 9,
          bS233: 10 ** 9,
          bS232: 10 ** 9,
          bS229_A: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel: 'corporate.financialData.financialStatement.RESERVES',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            bS230: '#0096eb',
            bS233: '#f7b80c',
            bS232: '#ecffdf',
            bS229_A: '#fe7e6d',
          },
          xAxisKey: 'label',
          barKeys: ['bS230', 'bS233', 'bS232', 'bS229_A'],
          mappingDisplayName: {
            bS230:
              'corporate.financialData.financialStatement.UNEARNED_PREMIUM_RESERVE',
            bS233:
              'corporate.financialData.financialStatement.CATASHTROPHE_RESERVE',
            bS232: 'corporate.financialData.financialStatement.CLAIM_RESERVE',
            bS229_A: 'corporate.financialData.financialStatement.OTHER_RESERVE',
          },
        },
      },
    },
    [COM_GROUP.SECURITIES]: {
      chart1: {
        thunkFuncName: 'getBalanceSheetStructureChartData',
        thunkFunc: (params) => getBalanceSheetStructureChartData(params),
        dataFormat: {
          nS239_A: 10 ** 9,
          bS249_A: 10 ** 9,
          bS64_A: 10 ** 9,
          bS76_A: 10 ** 9,
          bS65_A: 10 ** 9,
          bS95_A: 10 ** 9,
        },
        component: GroupStackedBarChart,
        topLabel:
          'corporate.financialData.financialStatement.BALANCE_SHEET_STRUCTURE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            nS239_A: '#185999',
            bS249_A: '#45b29d',
            bS64_A: '#ecffdc',
            bS76_A: '#a9576f',
            bS65_A: '#ffe0c9',
            bS95_A: '#906251',
          },
          xAxisKey: 'label',
          barKeys: [
            ['nS239_A', 'bS249_A', 'bS64_A'],
            ['bS76_A', 'bS65_A', 'bS95_A'],
          ],
          mappingDisplayName: {
            nS239_A:
              'corporate.financialData.financialStatement.MARGIN_LENDING',
            bS249_A: 'corporate.financialData.financialStatement.INVESTMENT',
            bS64_A: 'corporate.financialData.financialStatement.OTHER_ASSET',
            bS76_A: 'corporate.financialData.financialStatement.BORROWING',
            bS65_A:
              'corporate.financialData.financialStatement.OTHER_LIABILITIES',
            bS95_A: 'corporate.financialData.financialStatement.OWNER_EQUITY',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getAssetBreakdownChartData',
        thunkFunc: (params) => getAssetBreakdownChartData(params),
        dataFormat: {
          bS249_A: 1,
          bS8_A: 1,
          nS239_A: 1,
          bS249_B: 1,
        },
        component: HorizontalBarChart,
        topLabel:
          'corporate.financialData.financialStatement.MARGIN_LENDING_GROWTH',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            bS249_A: '#185999',
            bS8_A: '#facc5c',
            nS239_A: '#fff6df',
            bS249_B: '#e5604e',
          },
          yAxisKey: 'label',
          barKeys: ['bS249_A', 'bS8_A', 'nS239_A', 'bS249_B'],
          mappingDisplayName: {
            bS249_A: 'corporate.financialData.financialStatement.FVTPL',
            bS8_A: 'corporate.financialData.financialStatement.HTM',
            nS239_A:
              'corporate.financialData.financialStatement.MARGIN_LENDING',
            bS249_B: 'corporate.financialData.financialStatement.OTHER_ASSET',
          },
        },
      },
    },
    [COM_GROUP.CORPORATE]: {
      chart1: {
        thunkFuncName: 'getBalanceSheetStructureChartData',
        thunkFunc: (params) => getBalanceSheetStructureChartData(params),
        dataFormat: {
          bS18: 10 ** 9,
          bS36: 10 ** 9,
          bS9_A: 10 ** 9,
          bS28_A: 10 ** 9,
          bS67_A: 10 ** 9,
          bS76_A: 10 ** 9,
          bS64_B: 10 ** 9,
          bS95: 10 ** 9,
        },
        component: GroupStackedBarChart,
        topLabel:
          'corporate.financialData.financialStatement.BALANCE_SHEET_STRUCTURE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            bS18: '#176491',
            bS36: '#6daee8',
            bS9_A: '#b5f2ff',
            bS28_A: '#3dabeb',
            bS67_A: '#d25768',
            bS76_A: '#f78595',
            bS64_B: '#f9e5e5',
            bS95: '#f8bf44',
          },
          xAxisKey: 'label',
          barKeys: [
            ['bS18', 'bS36', 'bS9_A', 'bS28_A'],
            ['bS67_A', 'bS76_A', 'bS64_B', 'bS95'],
          ],
          mappingDisplayName: {
            bS18: 'corporate.financialData.financialStatement.INVENTORY',
            bS36: 'corporate.financialData.financialStatement.FIXED_ASSET',
            bS9_A: 'corporate.financialData.financialStatement.RECEIVABLES',
            bS28_A: 'corporate.financialData.financialStatement.OTHER_ASSET',
            bS67_A: 'corporate.financialData.financialStatement.TRADE_PAYABLES',
            bS76_A: 'corporate.financialData.financialStatement.BORROWING',
            bS64_B:
              'corporate.financialData.financialStatement.OTHER_LIABILITIES',
            bS95: 'corporate.financialData.financialStatement.OWNER_EQUITY',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getSTAndLTBorrowingChartData',
        thunkFunc: (params) => getSTAndLTBorrowingChartData(params),
        dataFormat: {
          bS76: 10 ** 9,
          bS89: 10 ** 9,
          bS95: 10 ** 9,
        },
        component: GroupStackedBarChart,
        topLabel: 'corporate.financialData.financialStatement.SHORT_LONG_TERM',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            bS76: '#185999',
            bS89: '#3cabeb',
            bS95: '#ffe3df',
          },
          xAxisKey: 'label',
          barKeys: [['bS76', 'bS89'], ['bS95']],
          mappingDisplayName: {
            bS76: 'corporate.financialData.financialStatement.ST_BORROWING',
            bS89: 'corporate.financialData.financialStatement.LT_BORROWING',
            bS95: 'corporate.financialData.financialStatement.OWNER_EQUITY',
          },
        },
      },
    },
  },
  [STATEMENT_CONSTANTS.CASH_FLOW]: {
    [COM_GROUP.BANK]: {
      chart1: {
        thunkFuncName: 'getCashFlowStructureChartData',
        thunkFunc: (params) => getCashFlowStructureChartData(params),
        dataFormat: {
          cF19: 10 ** 9,
          cF30: 10 ** 9,
          cF37: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel:
          'corporate.financialData.financialStatement.CASH_FLOW_STRUCTURE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            cF19: '#185999',
            cF30: '#fff6df',
            cF37: '#a6cf98',
          },
          xAxisKey: 'label',
          barKeys: ['cF19', 'cF30', 'cF37'],
          mappingDisplayName: {
            cF19: 'corporate.financialData.financialStatement.CFO',
            cF30: 'corporate.financialData.financialStatement.CFI',
            cF37: 'corporate.financialData.financialStatement.CFF',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getOperatingCashFlowChartData',
        thunkFunc: (params) => getOperatingCashFlowChartData(params),
        dataFormat: {
          cF19: 10 ** 9,
          cF49: 10 ** 9,
          cF54: 10 ** 9,
          cF14: 10 ** 9,
          cF19_A: 10 ** 9,
        },
        component: GroupStackedBarChart,
        topLabel:
          'corporate.financialData.financialStatement.OPERATING_CASH_FLOW_BREAKDOWN',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            cF19: '#176491',
            cF49: '#fe7e6d',
            cF54: '#ffe0c9',
            cF14: '#906251',
            cF19_A: '#f6ad5c',
          },
          xAxisKey: 'label',
          barKeys: [['cF19'], ['cF49', 'cF54', 'cF14', 'cF19_A']],
          mappingDisplayName: {
            cF19: 'corporate.financialData.financialStatement.CFO',
            cF49: 'corporate.financialData.financialStatement.PLUS_LOAN',
            cF54: 'corporate.financialData.financialStatement.PLUS_CUSTOMER_DEPOSIT',
            cF14: 'corporate.financialData.financialStatement.PLUS_TRADING_SECURITIES',
            cF19_A: 'corporate.financialData.financialStatement.OTHER_CHANGES',
          },
        },
      },
    },
    [COM_GROUP.INSURANCE]: {
      chart1: {
        thunkFuncName: 'getCashFlowStructureChartData',
        thunkFunc: (params) => getCashFlowStructureChartData(params),
        dataFormat: {
          cF19: 10 ** 9,
          cF30: 10 ** 9,
          cF37: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel:
          'corporate.financialData.financialStatement.CASH_FLOW_STRUCTURE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            cF19: '#176491',
            cF30: '#fff6df',
            cF37: '#a6cf98',
          },
          xAxisKey: 'label',
          barKeys: ['cF19', 'cF30', 'cF37'],
          mappingDisplayName: {
            cF19: 'corporate.financialData.financialStatement.CFO',
            cF30: 'corporate.financialData.financialStatement.CFI',
            cF37: 'corporate.financialData.financialStatement.CFF',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getOperatingCashFlowChartData',
        thunkFunc: (params) => getOperatingCashFlowChartData(params),
        dataFormat: {
          cF19: 10 ** 9,
          cF10: 10 ** 9,
          cF11: 10 ** 9,
          cF12: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel:
          'corporate.financialData.financialStatement.OPERATING_CASH_FLOW',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        rightLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            cF19: '#0096eb',
            cF10: '#f7b80c',
            cF11: '#ecffdf',
            cF12: '#eb84ad',
          },
          xAxisKey: 'label',
          barKeys: ['cF19'],
          lineKeys: ['cF10', 'cF11', 'cF12'],
          mappingDisplayName: {
            cF19: 'corporate.financialData.financialStatement.CFO',
            cF10: 'corporate.financialData.financialStatement.PLUS_RECEIVABLE',
            cF11: 'corporate.financialData.financialStatement.PLUS_PAYABLE',
            cF12: 'corporate.financialData.financialStatement.PLUS_INVENTORY',
          },
        },
      },
    },
    [COM_GROUP.SECURITIES]: {
      chart1: {
        thunkFuncName: 'getCashFlowStructureChartData',
        thunkFunc: (params) => getCashFlowStructureChartData(params),
        dataFormat: {
          cF19: 10 ** 9,
          cF30: 10 ** 9,
          cF37: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel:
          'corporate.financialData.financialStatement.CASH_FLOW_STRUCTURE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            cF19: '#185999',
            cF30: '#fff6df',
            cF37: '#a6cf98',
          },
          xAxisKey: 'label',
          barKeys: ['cF19', 'cF30', 'cF37'],
          mappingDisplayName: {
            cF19: 'corporate.financialData.financialStatement.CFO',
            cF30: 'corporate.financialData.financialStatement.CFI',
            cF37: 'corporate.financialData.financialStatement.CFF',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getOperatingCashFlowChartData',
        thunkFunc: (params) => getOperatingCashFlowChartData(params),
        dataFormat: {
          cF19: 10 ** 9,
          cF119: 10 ** 9,
          cF120: 10 ** 9,
          cF121: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel:
          'corporate.financialData.financialStatement.OPERATING_CASH_FLOW',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        rightLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            cF19: '#0096eb',
            cF119: '#f7b80c',
            cF120: '#ecffdf',
            cF121: '#eb84ad',
          },
          xAxisKey: 'label',
          barKeys: ['cF19'],
          lineKeys: ['cF119', 'cF120', 'cF121'],
          mappingDisplayName: {
            cF19: 'corporate.financialData.financialStatement.CFO',
            cF119: 'corporate.financialData.financialStatement.PLUS_FVPL',
            cF120: 'corporate.financialData.financialStatement.PLUS_HTM',
            cF121: 'corporate.financialData.financialStatement.PLUS_LENDING',
          },
        },
      },
    },
    [COM_GROUP.CORPORATE]: {
      chart1: {
        thunkFuncName: 'getCashFlowStructureChartData',
        thunkFunc: (params) => getCashFlowStructureChartData(params),
        dataFormat: {
          cF19: 10 ** 9,
          cF30: 10 ** 9,
          cF37: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel:
          'corporate.financialData.financialStatement.CASH_FLOW_STRUCTURE',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            cF19: '#185999',
            cF30: '#fff6df',
            cF37: '#a6cf98',
          },
          xAxisKey: 'label',
          barKeys: ['cF19', 'cF30', 'cF37'],
          mappingDisplayName: {
            cF19: 'corporate.financialData.financialStatement.CFO',
            cF30: 'corporate.financialData.financialStatement.CFI',
            cF37: 'corporate.financialData.financialStatement.CFF',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getOperatingCashFlowChartData',
        thunkFunc: (params) => getOperatingCashFlowChartData(params),
        dataFormat: {
          cF19: 10 ** 9,
          cF10: 10 ** 9,
          cF11: 10 ** 9,
          cF12: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel:
          'corporate.financialData.financialStatement.OPERATING_CASH_FLOW',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        rightLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            cF19: '#0096eb',
            cF10: '#f7b80c',
            cF11: '#ecffdf',
            cF12: '#eb84ad',
          },
          xAxisKey: 'label',
          barKeys: ['cF19'],
          lineKeys: ['cF10', 'cF11', 'cF12'],
          mappingDisplayName: {
            cF19: 'corporate.financialData.financialStatement.CFO',
            cF10: 'corporate.financialData.financialStatement.PLUS_RECEIVABLE',
            cF11: 'corporate.financialData.financialStatement.PLUS_PAYABLE',
            cF12: 'corporate.financialData.financialStatement.PLUS_INVENTORY',
          },
        },
      },
    },
  },
  [STATEMENT_CONSTANTS.NOTE]: {
    [COM_GROUP.BANK]: {
      chart1: {
        thunkFuncName: 'getLoansByIndustryChartData',
        thunkFunc: (params) => getLoansByIndustryChartData(params),
        dataFormat: {
          nB38_A: 1,
          nB39_A: 1,
          nB40_A: 1,
          nB45_A: 1,
          nB46_A: 1,
          nB55_A: 1,
          nB58_A: 1,
          nB61_A: 1,
          nB62_A: 1,
          nB63_A: 1,
          nB37_A: 1,
        },
        component: PieLineLabelChart,
        topLabel: 'corporate.financialData.financialStatement.LOAN_INDUSTRY',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          fillColor: [
            '#45b29d',
            '#facc5c',
            '#e5604e',
            '#185999',
            '#0096eb',
            '#ecffdf',
          ],
          pieKeys: [
            'nB38_A',
            'nB39_A',
            'nB40_A',
            'nB45_A',
            'nB46_A',
            'nB55_A',
            'nB58_A',
            'nB61_A',
            'nB62_A',
            'nB63_A',
            'nB37_A',
          ],
          mappingDisplayName: {
            nB38_A: 'corporate.financialData.financialStatement.TRADING',
            nB39_A:
              'corporate.financialData.financialStatement.AGRICULTURE_FORESTRY',
            nB40_A: 'corporate.financialData.financialStatement.MANUFACTURING',
            nB45_A: 'corporate.financialData.financialStatement.CONSTRUCTION',
            nB46_A:
              'corporate.financialData.financialStatement.PERSONAL_PUBLIC_SERVICE',
            nB55_A:
              'corporate.financialData.financialStatement.WAREHOUSING_TRANSPORTATION',
            nB58_A:
              'corporate.financialData.financialStatement.TRAINING_EDUCATION',
            nB61_A:
              'corporate.financialData.financialStatement.ESTATE_AGENT_CONSULTANT',
            nB62_A:
              'corporate.financialData.financialStatement.HOTEL_RESTAURANT',
            nB63_A:
              'corporate.financialData.financialStatement.FINANCIAL_SERVICE',
            nB37_A: 'corporate.financialData.financialStatement.OTHER',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getLoansByTermChartData',
        thunkFunc: (params) => getLoansByTermChartData(params),
        dataFormat: {
          nB72_A: 1,
          nB73_A: 1,
          nB74_A: 1,
        },
        component: HorizontalBarChart,
        topLabel: 'corporate.financialData.financialStatement.LOAN_TERM',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            nB72_A: '#185999',
            nB73_A: '#fff6df',
            nB74_A: '#a6cf98',
          },
          yAxisKey: 'label',
          barKeys: ['nB72_A', 'nB73_A', 'nB74_A'],
          mappingDisplayName: {
            nB72_A:
              'corporate.financialData.financialStatement.SHORT_TERM_LOAN',
            nB73_A:
              'corporate.financialData.financialStatement.MEDIUM_TERM_LOAN',
            nB74_A: 'corporate.financialData.financialStatement.LONG_TERM_LOAN',
          },
        },
      },
    },
    [COM_GROUP.INSURANCE]: {
      chart1: {
        thunkFuncName: 'getInsurancePremiumBreakdownChartData',
        thunkFunc: (params) => getInsurancePremiumBreakdownChartData(params),
        dataFormat: {
          nI154_A: 1,
          nI307_A: 1,
          nI156_A: 1,
          nI157_A: 1,
          nI158_A: 1,
          nI159_A: 1,
          nI160_A: 1,
          nI161_A: 1,
          nI162_A: 1,
          nI163_A: 1,
          nI164_A: 1,
          nI165_A: 1,
          nI166_A: 1,
        },
        component: PieLineLabelChart,
        topLabel:
          'corporate.financialData.financialStatement.INSURANCE_PREMIUM_BREAKDOWN',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          fillColor: [
            '#45b29d',
            '#facc5c',
            '#e5604e',
            '#185999',
            '#0096eb',
            '#ecffdf',
          ],
          pieKeys: [
            'nI154_A',
            'nI307_A',
            'nI156_A',
            'nI157_A',
            'nI158_A',
            'nI159_A',
            'nI160_A',
            'nI161_A',
            'nI162_A',
            'nI163_A',
            'nI164_A',
            'nI165_A',
            'nI166_A',
          ],
          mappingDisplayName: {
            nI154_A:
              'corporate.financialData.financialStatement.LIFE_INSURANCE',
            nI307_A:
              'corporate.financialData.financialStatement.HEALTH_INSURANCE',
            nI156_A:
              'corporate.financialData.financialStatement.PROPERTY_DAMAGE_INSURANCE',
            nI157_A:
              'corporate.financialData.financialStatement.CARGO_INSURANCE',
            nI158_A:
              'corporate.financialData.financialStatement.AVIATION_INSURANCE',
            nI159_A:
              'corporate.financialData.financialStatement.MOTOR_VEHICLE_INSURANCE',
            nI160_A:
              'corporate.financialData.financialStatement.FIRE_INSURANCE',
            nI161_A:
              'corporate.financialData.financialStatement.MARINE_INSURANCE',
            nI162_A:
              'corporate.financialData.financialStatement.LIABILITY_INSURANCE',
            nI163_A:
              'corporate.financialData.financialStatement.CREDIT_FINANCIAL_INSURANCE',
            nI164_A:
              'corporate.financialData.financialStatement.BUSINESS_DAMAGE_INSURANCE',
            nI165_A:
              'corporate.financialData.financialStatement.AGRICULTURAL_INSURANCE',
            nI166_A:
              'corporate.financialData.financialStatement.OTHER_INSURANCE',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getFinancialIncomeChartData',
        thunkFunc: (params) => getFinancialIncomeChartData(params),
        dataFormat: {
          nI234: 10 ** 9,
          nI235: 10 ** 9,
          nI233_A: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel: 'corporate.financialData.financialStatement.FINANCIAL_INCOME',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            nI234: '#185999',
            nI235: '#fff6df',
            nI233_A: '#fe7e6d',
          },
          xAxisKey: 'label',
          barKeys: ['nI234', 'nI235', 'nI233_A'],
          mappingDisplayName: {
            nI234:
              'corporate.financialData.financialStatement.INTEREST_DEPOSIT',
            nI235: 'corporate.financialData.financialStatement.INTEREST_BOND',
            nI233_A:
              'corporate.financialData.financialStatement.OTHER_INTEREST',
          },
        },
      },
    },
    [COM_GROUP.SECURITIES]: {
      chart1: {
        thunkFuncName: 'getMarginLendingChartData',
        thunkFunc: (params) => getMarginLendingChartData(params),
        dataFormat: {
          nS239: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel: 'corporate.financialData.financialStatement.MARGIN_LENDING',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            nS239: '#3fbcb7',
          },
          xAxisKey: 'label',
          barKeys: ['nS239'],
          mappingDisplayName: {
            nS239: 'corporate.financialData.financialStatement.MARGIN_LENDING',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getFinancialAssetsStructureChartData',
        thunkFunc: (params) => getFinancialAssetsStructureChartData(params),
        dataFormat: {
          nS268_A: 1,
          nS269_A: 1,
          nS271_A: 1,
          nS272_B: 1,
        },
        component: HorizontalBarChart,
        topLabel:
          'corporate.financialData.financialStatement.FINANCIAL_ASSET_STRUCTURE',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            nS268_A: '#185999',
            nS269_A: '#facc5c',
            nS271_A: '#fff6df',
            nS272_B: '#e5604e',
          },
          yAxisKey: 'label',
          barKeys: ['nS268_A', 'nS269_A', 'nS271_A', 'nS272_B'],
          mappingDisplayName: {
            nS268_A:
              'corporate.financialData.financialStatement.LISTED_SECURITIES',
            nS269_A:
              'corporate.financialData.financialStatement.NON_LISTED_SECURITIES',
            nS271_A: 'corporate.financialData.financialStatement.BOND',
            nS272_B: 'corporate.financialData.financialStatement.OTHER',
          },
        },
      },
    },
    [COM_GROUP.CORPORATE]: {
      chart1: {
        thunkFuncName: 'getInventoryStructureChartData',
        thunkFunc: (params) => getInventoryStructureChartData(params),
        dataFormat: {
          nC19_A: 1,
          nC20_A: 1,
          nC21_A: 1,
          nC23_A: 1,
          nC24_A: 1,
          nC18_A: 1,
        },
        component: PieLineLabelChart,
        topLabel:
          'corporate.financialData.financialStatement.INVENTORY_STRUCTURE',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          fillColor: [
            '#45b29d',
            '#facc5c',
            '#e5604e',
            '#185999',
            '#0096eb',
            '#ecffdf',
          ],
          pieKeys: ['nC19_A', 'nC20_A', 'nC21_A', 'nC23_A', 'nC24_A'],
          mappingDisplayName: {
            nC19_A: 'corporate.financialData.financialStatement.GOOD_TRANSIT',
            nC20_A: 'corporate.financialData.financialStatement.RAW_MATERIAL',
            nC21_A: 'corporate.financialData.financialStatement.TOOL_SUPPLIES',
            nC23_A: 'corporate.financialData.financialStatement.FINISHED_GOOD',
            nC24_A:
              'corporate.financialData.financialStatement.MERCHANDISE_INVENTORIES',
            nC18_A: 'corporate.financialData.financialStatement.OTHER',
          },
        },
      },
      chart2: {
        thunkFuncName: 'getFinancialExpensesChartData',
        thunkFunc: (params) => getFinancialExpensesChartData(params),
        dataFormat: {
          nC152: 10 ** 9,
          nC151_A: 10 ** 9,
        },
        component: StackedChartWithLine,
        topLabel:
          'corporate.financialData.financialStatement.FINANCIAL_EXPENSES',
        leftLabel:
          'corporate.financialData.financialStatement.CHART_BILLION_VND',
        size: (resizeSize) => ({
          width: resizeSize.width,
          height: resizeSize.height,
        }),
        props: {
          barWidth: CHART_SETTINGS.barWidth,
          fillColor: {
            nC152: '#185999',
            nC151_A: '#fff6df',
          },
          xAxisKey: 'label',
          barKeys: ['nC152', 'nC151_A'],
          mappingDisplayName: {
            nC152:
              'corporate.financialData.financialStatement.INTEREST_EXPENSES',
            nC151_A:
              'corporate.financialData.financialStatement.OTHER_FINANCIAL_EXPENSES',
          },
        },
      },
    },
  },
}
