import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { register } from '../../../utils/ReducerRegistry'
import { FILTER_FIELD_CODES } from '../Screening/constants'
import { INDICATOR_GROUPS, TAB_TYPES } from '../constants'
import { renderAddedInformaticsParams, renderConditionParams } from '../helper'
import {
  createBondData,
  deleteBondData,
  deleteTemplateByIdData,
  fetchMoreBondListData,
  getBondAndUserBondData,
  getBondByIdData,
  getBondListCount,
  getBondListData,
  getCreateBondListData,
  getIndicatorData,
  getTableDataByFilter,
  getTemplateByIdData,
  getTemplatesData,
  getTemplatesDataByDelete,
  getTemplatesDataBySave,
  getTemplatesDataByUpdate,
  getWatchListByUserData,
  getWatchListData,
  renameTemplateByIdData,
  saveTemplateData,
  updateBondData,
  updateTemplateByIdData,
} from './thunk'

export const paramsDefault = {
  templateName: '',
  templateType: '',
  parameter: {
    sectors: [],
    ticker: [],
    watchListId: [],
    indicatorGroup: INDICATOR_GROUPS.BOND_AGGREGATOR,
    conditions: [],
    addedInformations: [],
    sortBy: FILTER_FIELD_CODES.ISSUE_DATE,
    order: 'desc',
    pageIndex: 1,
    timeRange: TIME_RANGES.All,
    startDate: '',
    endDate: '',
  },
}

const initialState = {
  filter: {
    loading: false,
    visible: false,
  },
  params: paramsDefault,
  list: {
    loading: false,
    zoom: false,
  },
  search: {
    data: [],
    page: 1,
    pageSize: 10,
    enableInfinity: true,
    loading: false,
  },
  initialTemplates: [],
  templates: [],
  templatesMore: [],
  activeTab: TAB_TYPES.ALL,
  template: null,
  initialAddedInformatics: [],
  initialSectors: [],
  initialConditions: [],
  templateId: null,
  isSave: null,
  isUpdate: null,
  isDelete: false,
  isRename: null,
  raw: null,
  count: 0,
  parentSectors: [],
  initialParentSectors: [],
  watchList: [],
  columns: {},
  initialSortBy: FILTER_FIELD_CODES.ISSUE_DATE,
  initialOrder: 'desc',
  isCreateBond: false,
  isUpdateBond: '',
  isDeleteBond: '',
  createBond: [],
  bond: null,
  initialFilter: [],
  initialTable: [],
  isLoadingTable: false,
  isLoadingTemplates: false,
}

export const slice = createSlice({
  name: 'bond/bondScreening/bondAggregator',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    setFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      }
    },
    setFilterParams: (state, action) => {
      state.params = {
        ...state.params,
        [action.payload.key]: action.payload.value,
      }
    },
    setFilterParameters: (state, action) => {
      state.params = {
        ...state.params,
        parameter: {
          ...state.params.parameter,
          [action.payload.key]: action.payload.value,
        },
      }
    },
    setIsRename: (state, action) => {
      state.isRename = action.payload
    },
    setIsSave: (state, action) => {
      state.isSave = action.payload
    },
    setIsUpdate: (state, action) => {
      state.isUpdate = action.payload
    },
    setInitialSectors: (state, action) => {
      state.initialSectors = action.payload
    },
    setBondList: (state, action) => {
      state.list = {
        ...state.list,
        [action.payload.key]: action.payload.value,
      }
    },
    setParentSector: (state, action) => {
      state.parentSectors = action.payload
    },
    setInitialParentSector: (state, action) => {
      state.initialParentSectors = action.payload
    },
    changeLoadingSearchBond: (state, action) => {
      state.search = {
        ...state.search,
        loading: action.payload,
      }
    },
    changeActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    changeTemplateList: (state, action) => {
      state.templates = action.payload
    },
    changeTemplateMoreList: (state, action) => {
      state.templatesMore = action.payload
    },
    changeTemplate: (state, action) => {
      state.raw = action.payload
      state.activeTab = TAB_TYPES.RAW
    },
    changeTemplateOnSearch: (state, action) => {
      state.raw = action.payload
      state.activeTab = TAB_TYPES.RAW
    },
    changeDataRaw: (state, action) => {
      state.raw = {
        ...state.raw,
        parameter: {
          ...state.raw.parameter,
          [action.payload.key]: action.payload.value,
        },
      }
      state.activeTab = TAB_TYPES.RAW
    },
    resetFilter: (state) => {
      const params = cloneDeep(paramsDefault)

      state.params = {
        ...params,
        parameter: {
          ...state.params.parameter,
          ticker: [],
          watchListId: [],
          conditions: renderConditionParams(
            state.initialConditions,
            state.initialAddedInformatics,
          ),
          addedInformations: renderAddedInformaticsParams(
            state.initialAddedInformatics,
          ),
          sectors: state.initialSectors,
        },
      }
    },
    changeTemplateId: (state, action) => {
      state.templateId = action.payload
    },
    changeStatus: (state, action) => {
      state.status = action.payload
    },
    changeParameters: (state, action) => {
      state.params = action.payload
    },
    changeColumns: (state, action) => {
      state.columns = action.payload
    },
    resetDataTemplate: (state, action) => {
      state.template = null
      state.raw = null
    },
    setInitialSort: (state, action) => {
      const { sortBy, order } = action.payload

      state.initialSortBy = sortBy
      state.initialOrder = order
    },
    changeIsCreateBond: (state, action) => {
      state.isCreateBond = action.payload
    },
    changeIsUpdateBond: (state, action) => {
      state.isUpdateBond = action.payload
    },
    changeIsDeleteBond: (state, action) => {
      state.isDeleteBond = action.payload
    },
    changeBondInformation: (state, action) => {
      state.bond = action.payload
    },
    changeIsLoadingTable: (state, action) => {
      state.isLoadingTable = action.payload
    },
    changeIsLoadingTemplates: (state, action) => {
      state.isLoadingTemplates = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getBondListData.pending, (state, action) => {})
    builder.addCase(getBondListData.fulfilled, (state, action) => {
      const { data, hasNextPage } = action.payload

      state.search = {
        ...state.search,
        data,
        page: state.search.page + 1,
        enableInfinity: hasNextPage,
        loading: false,
      }
    })
    builder.addCase(getBondListData.rejected, (state, action) => {
      state.search = {
        ...state.search,
        loading: action.payload,
      }
    })
    builder.addCase(fetchMoreBondListData.pending, (state, action) => {})
    builder.addCase(fetchMoreBondListData.fulfilled, (state, action) => {
      const { data, hasNextPage } = action.payload

      const tickers = state.search.data
        .map((item) => item?.ticker ?? null)
        .filter((item) => item)

      const newData = data.filter((item) => !tickers.includes(item?.ticker))

      state.search = {
        ...state.search,
        data: [...state.search.data, ...newData],
        page: state.search.page + 1,
        enableInfinity: hasNextPage,
      }
    })
    builder.addCase(fetchMoreBondListData.rejected, (state, action) => {})
    builder.addCase(getIndicatorData.pending, (state, action) => {})
    builder.addCase(getIndicatorData.fulfilled, (state, action) => {
      const { filter, table } = action.payload

      state.initialFilter = filter
      state.initialTable = table
      state.isLoadingTable = true
    })
    builder.addCase(getIndicatorData.rejected, (state, action) => {
      state.isLoadingTable = action.payload
    })
    builder.addCase(getTableDataByFilter.pending, (state, action) => {})
    builder.addCase(getTableDataByFilter.fulfilled, (state, action) => {
      const { filterData, tableData } = action.payload

      state.initialConditions = filterData
      state.initialAddedInformatics = tableData
      state.filter.loading = false
    })
    builder.addCase(getTableDataByFilter.rejected, (state, action) => {
      state.filter.loading = action.payload
    })
    builder.addCase(getTemplatesData.pending, (state, action) => {})
    builder.addCase(getTemplatesData.fulfilled, (state, action) => {
      state.initialTemplates = action.payload
      state.isLoadingTemplates = true
    })
    builder.addCase(getTemplatesData.rejected, (state, action) => {
      state.isLoadingTemplates = action.payload
    })
    builder.addCase(getTemplatesDataBySave.pending, (state, action) => {})
    builder.addCase(getTemplatesDataBySave.fulfilled, (state, action) => {
      const { data, isSave, templates } = action.payload

      state.activeTab = isSave.templateId
      state.templateId = isSave.templateId
      state.templates = data
      state.templatesMore = templates
      state.isSave = null
    })
    builder.addCase(getTemplatesDataBySave.rejected, (state, action) => {})
    builder.addCase(getTemplatesDataByUpdate.pending, (state, action) => {})
    builder.addCase(getTemplatesDataByUpdate.fulfilled, (state, action) => {
      const { data, isUpdate, templates } = action.payload

      state.activeTab = isUpdate.templateId
      state.templateId = isUpdate.templateId
      state.templates = data
      state.templatesMore = templates
      state.isUpdate = null
    })
    builder.addCase(getTemplatesDataByUpdate.rejected, (state, action) => {})
    builder.addCase(getTemplatesDataByDelete.pending, (state, action) => {})
    builder.addCase(getTemplatesDataByDelete.fulfilled, (state, action) => {
      state.activeTab = TAB_TYPES.ALL
      state.templateId = TAB_TYPES.ALL
      state.templates = action.payload
      state.templatesMore = action.payload
      state.isDelete = false
    })
    builder.addCase(getTemplatesDataByDelete.rejected, (state, action) => {})
    builder.addCase(getTemplateByIdData.pending, (state, action) => {})
    builder.addCase(getTemplateByIdData.fulfilled, (state, action) => {
      if (!action.payload) {
        state.template = null
        return
      }

      const { parameters, ...restParams } = action.payload

      state.template = {
        ...restParams,
        parameter: parameters,
      }
    })
    builder.addCase(getTemplateByIdData.rejected, (state, action) => {})
    builder.addCase(saveTemplateData.pending, (state, action) => {})
    builder.addCase(saveTemplateData.fulfilled, (state, action) => {
      state.isSave = action.payload
      state.template = action.payload
    })
    builder.addCase(saveTemplateData.rejected, (state, action) => {
      state.status = false
    })
    builder.addCase(updateTemplateByIdData.pending, (state, action) => {})
    builder.addCase(updateTemplateByIdData.fulfilled, (state, action) => {
      state.isUpdate = action.payload
      state.template = action.payload
    })
    builder.addCase(updateTemplateByIdData.rejected, (state, action) => {
      state.status = false
    })
    builder.addCase(renameTemplateByIdData.pending, (state, action) => {})
    builder.addCase(renameTemplateByIdData.fulfilled, (state, action) => {
      state.isRename = action.payload
    })
    builder.addCase(renameTemplateByIdData.rejected, (state, action) => {})
    builder.addCase(deleteTemplateByIdData.pending, (state, action) => {})
    builder.addCase(deleteTemplateByIdData.fulfilled, (state, action) => {
      state.status = action.payload
      state.isDelete = action.payload
    })
    builder.addCase(deleteTemplateByIdData.rejected, (state, action) => {
      state.status = false
    })
    builder.addCase(getBondListCount.pending, (state, action) => {})
    builder.addCase(getBondListCount.fulfilled, (state, action) => {
      state.count = action.payload
    })
    builder.addCase(getBondListCount.rejected, (state, action) => {})
    builder.addCase(getWatchListData.pending, (state, action) => {})
    builder.addCase(getWatchListData.fulfilled, (state, action) => {
      state.watchList = action.payload
    })
    builder.addCase(getWatchListData.rejected, (state, action) => {})
    builder.addCase(getWatchListByUserData.pending, (state, action) => {})
    builder.addCase(getWatchListByUserData.fulfilled, (state, action) => {
      state.watchList = action.payload
    })
    builder.addCase(getWatchListByUserData.rejected, (state, action) => {})
    builder.addCase(createBondData.pending, (state, action) => {})
    builder.addCase(createBondData.fulfilled, (state, action) => {
      state.isCreateBond = action.payload
    })
    builder.addCase(createBondData.rejected, (state, action) => {})
    builder.addCase(updateBondData.pending, (state, action) => {})
    builder.addCase(updateBondData.fulfilled, (state, action) => {
      state.isUpdateBond = action.payload
    })
    builder.addCase(updateBondData.rejected, (state, action) => {})
    builder.addCase(deleteBondData.pending, (state, action) => {})
    builder.addCase(deleteBondData.fulfilled, (state, action) => {
      state.isDeleteBond = action.payload
    })
    builder.addCase(deleteBondData.rejected, (state, action) => {})
    builder.addCase(getCreateBondListData.pending, (state, action) => {})
    builder.addCase(getCreateBondListData.fulfilled, (state, action) => {
      state.createBond = action.payload
    })
    builder.addCase(getCreateBondListData.rejected, (state, action) => {})
    builder.addCase(getBondByIdData.pending, (state, action) => {})
    builder.addCase(getBondByIdData.fulfilled, (state, action) => {
      state.bond = action.payload
    })
    builder.addCase(getBondByIdData.rejected, (state, action) => {})
    builder.addCase(getBondAndUserBondData.pending, (state, action) => {
      state.search = {
        ...state.search,
        loading: true,
      }
    })
    builder.addCase(getBondAndUserBondData.fulfilled, (state, action) => {
      const { data, hasNextPage, page } = action.payload

      state.search = {
        ...state.search,
        data,
        page: page + 1,
        enableInfinity: hasNextPage,
        loading: false,
      }
    })
    builder.addCase(getBondAndUserBondData.rejected, (state, action) => {
      state.search = {
        ...state.search,
        loading: action.payload,
      }
    })
  },
})

// Filter
export const selectFilter = (state) => state[slice.name].filter
export const selectFilterParams = (state) => state[slice.name].params.parameter
export const selectFilterSearchBond = (state) => state[slice.name].search
export const selectConditions = (state) => state[slice.name].initialConditions
export const selectAddInformatics = (state) =>
  state[slice.name].initialAddedInformatics
export const selectParams = (state) => state[slice.name].params
export const selectLoadingFilter = (state) => state[slice.name].filter.loading
export const selectDataRaw = (state) => state[slice.name].raw
export const selectCountData = (state) => state[slice.name].count
export const selectTemplateId = (state) => state[slice.name].templateId
export const selectInitialSectors = (state) => state[slice.name].initialSectors
export const selectCreateStatus = (state) => state[slice.name].isCreateBond
export const selectUpdateStatus = (state) => state[slice.name].isUpdateBond
export const selectDeleteStatus = (state) => state[slice.name].isDeleteBond
export const selectBondInformation = (state) => state[slice.name].bond
export const selectIsLoadingTable = (state) => state[slice.name].isLoadingTable
export const selectIsLoadingTemplates = (state) =>
  state[slice.name].isLoadingTemplates
export const selectInitialTemplates = (state) =>
  state[slice.name].initialTemplates

// Bond List
export const selectBondList = (state) => state[slice.name].list
export const selectActiveTab = (state) => state[slice.name].activeTab
export const selectTemplate = (state) => state[slice.name].templates
export const selectTemplateMore = (state) => state[slice.name].templatesMore
export const selectTemplateById = (state) => state[slice.name].template
export const selectIsSave = (state) => state[slice.name].isSave
export const selectIsUpdate = (state) => state[slice.name].isUpdate
export const selectIsDelete = (state) => state[slice.name].isDelete
export const selectIsRename = (state) => state[slice.name].isRename
export const selectParentSectors = (state) => state[slice.name].parentSectors
export const selectInitialParentSectors = (state) =>
  state[slice.name].initialParentSectors
export const selectWatchlist = (state) => state[slice.name].watchList
export const selectColumns = (state) => state[slice.name].columns
export const selectInformatics = (state) => state[slice.name].addedInformatics
export const selectInitialSortBy = (state) => state[slice.name].initialSortBy
export const selectInitialOrder = (state) => state[slice.name].initialOrder
export const selectInitialCreateBondList = (state) =>
  state[slice.name].createBond

export const {
  resetStore,
  setFilter,
  setFilterParams,
  setFilterParameters,
  setBondList,
  changeLoadingSearchBond,
  changeActiveTab,
  resetFilter,
  setInitialSectors,
  changeTemplateList,
  changeTemplateMoreList,
  changeTemplate,
  changeTemplateId,
  changeStatus,
  changeParameters,
  changeTemplateOnSearch,
  changeDataRaw,
  setParentSector,
  setInitialParentSector,
  changeColumns,
  setIsRename,
  setIsSave,
  setIsUpdate,
  resetDataTemplate,
  setInitialSort,
  changeIsCreateBond,
  changeIsUpdateBond,
  changeIsDeleteBond,
  changeBondInformation,
  changeIsLoadingTable,
  changeIsLoadingTemplates,
} = slice.actions

register(slice.name, slice.reducer)
