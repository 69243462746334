import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import ProductionService from '../../../../../core/services/economy/productionAndConsumption/ProductionService'
import {
  changeMonthActive,
  changeYearActive,
  getQuarterActive,
  INIT_QUARTER,
} from '../chartSecond/topGrowthIndustry/store/slice'
import { handleYearHasData } from '../table/helper'
import { PRODUCTION_SLICE_NAME } from '../table/store/slice'

export const getMaxDate = createAsyncThunk(
  'economy/productionAndConsumption/productionStore/getMaxDate',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const allState = getState()
      const typeDataTime = allState[PRODUCTION_SLICE_NAME].typeDataTime
      const response = await ProductionService.getMaxDate(params)
      const yearHasData = handleYearHasData(
        typeDataTime,
        response.data?.realMonth,
        response.data?.realYear,
      )
      const monthHasData = response.data?.realMonth || new Date().getMonth() + 1
      const quarterHasData = response.data?.realMonth || INIT_QUARTER

      dispatch(changeYearActive(yearHasData))
      dispatch(changeMonthActive(monthHasData))
      dispatch(getQuarterActive(quarterHasData))

      return response.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
