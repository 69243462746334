import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useGetExchange from '../../../../common/masterData/useGetExchange'
import useGetICB from '../../../../common/masterData/useGetICB'
import { keyBy } from '../../../../utils/Common'
import style from '../index.module.css'
import {
  changeIndexById,
  changeSectorById,
  keys,
  selectLoading,
} from '../store/slice'
import GroupButton from './GroupButton'
import ResultTable from './ResultTable'

const Result = ({
  width,
  setIsLoadingDownloadCsv,
  isSectorRatio,
  isFullScreen,
}) => {
  const dispatch = useDispatch()

  const { exchanges } = useGetExchange()
  const { ICBs } = useGetICB()

  const loadingTable = useSelector(selectLoading(keys.TABLE_RESULT))

  useEffect(() => {
    dispatch(changeIndexById(keyBy(exchanges, 'groupId')))
  }, [exchanges])

  useEffect(() => {
    dispatch(changeSectorById(keyBy(ICBs, 'icbId')))
  }, [ICBs])

  return (
    <div
      className={`h-100 ${style.flexColumn} ${
        loadingTable ? style.containerLoadingBlur : ''
      }`}
    >
      <div className={style.pb16px}>
        <GroupButton
          setIsLoadingDownloadCsv={setIsLoadingDownloadCsv}
          isSectorRatio={isSectorRatio}
          isFullScreen={isFullScreen}
        />
      </div>
      <div className="h-100">
        <ResultTable width={width} />
      </div>
    </div>
  )
}

Result.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setIsLoadingDownloadCsv: PropTypes.func.isRequired,
  isSectorRatio: PropTypes.bool,
  isFullScreen: PropTypes.bool,
}

Result.defaultProps = {
  isFullScreen: false,
}

export default Result
