import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class OverviewProxy extends ProxyBase {
  getBalanceOfTrade(params) {
    return this.get('TradeBalance', params, null, true)
  }

  getImportExportAndTradeBalance(params) {
    return this.get('ExImAndTradeBalance', params)
  }

  getGrowthTradeBalance(params) {
    return this.get('GrowthTradeBalance', params)
  }

  getTopTradeGood(params) {
    return this.get('TopTradeGood', params)
  }

  getTopTradePartner(params) {
    return this.get('TopTradePartner', params)
  }

  getDownloadTradeBalance(params) {
    return this.get('DownloadTradeBalance', params, 'download')
  }

  getLocationName(params) {
    return this.get('LocationName', params)
  }

  getTradeAgreementLocationName(params) {
    return this.get('TradeAgreementLocationName', params)
  }

  getIdsMapping(params) {
    return this.get('IdsMapping', params)
  }

  getImportByProduct(params) {
    return this.post('ImportByProduct', params, {}, null, true)
  }

  getImportDownloadByProduct(params) {
    return this.post('ImportDownloadByProduct', params, {}, 'download')
  }

  getImportChangeTurnoverByProduct(params) {
    return this.post('ImportChangeTurnoverByProduct', params)
  }

  getImportContributeByProduct(params) {
    return this.post('ImportContributeByProduct', params)
  }

  getImportGrowthByProduct(params) {
    return this.post('ImportGrowthByProduct', params)
  }

  getExportByProduct(params) {
    return this.post('ExportByProduct', params, {}, null, true)
  }

  getExportChangeTurnoverByProduct(params) {
    return this.post('ExportChangeTurnoverByProduct', params)
  }

  getExportContributeByProduct(params) {
    return this.post('ExportContributeByProduct', params)
  }

  getExportGrowthByProduct(params) {
    return this.post('ExportGrowthByProduct', params)
  }

  getExportDownloadByProduct(params) {
    return this.post('ExportDownloadByProduct', params, {}, 'download')
  }

  getImportByLocation(params) {
    return this.post('ImportByLocation', params, {}, null, true)
  }

  getImportTradeAgreementsByLocation(params) {
    return this.post('ImportTradeAgreementsByLocation', params, {}, null, true)
  }

  getImportChangeTurnoverByLocation(params) {
    return this.post('ImportChangeTurnoverByLocation', params)
  }

  getImportTradeAgreementsChangeTurnoverByLocation(params) {
    return this.post('ImportTradeAgreementsChangeTurnoverByLocation', params)
  }

  getImportContributeByLocation(params) {
    return this.get('ImportContributeByLocation', params)
  }

  getImportDownloadByLocation(params) {
    return this.post('ImportDownloadByLocation', params, {}, 'download')
  }

  getImportTradeAgreementsDownloadByLocation(params) {
    return this.post(
      'ImportTradeAgreementsDownloadByLocation',
      params,
      {},
      'download',
    )
  }

  getExportByLocation(params) {
    return this.post('ExportByLocation', params, {}, null, true)
  }

  getExportTradeAgreementsByLocation(params) {
    return this.post('ExportTradeAgreementsByLocation', params)
  }

  getExportChangeTurnoverByLocation(params) {
    return this.post('ExportChangeTurnoverByLocation', params)
  }

  getExportTradeAgreementsChangeTurnoverByLocation(params) {
    return this.post('ExportTradeAgreementsChangeTurnoverByLocation', params)
  }

  getExportContributeByLocation(params) {
    return this.get('ExportContributeByLocation', params)
  }

  getExportDownloadByLocation(params) {
    return this.post('ExportDownloadByLocation', params, {}, 'download')
  }

  getExportTradeAgreementsDownloadByLocation(params) {
    return this.post(
      'ExportTradeAgreementsDownloadByLocation',
      params,
      {},
      'download',
    )
  }

  getMaxDateOverview(params) {
    return this.get('MaxDateOverview', params, null, true)
  }

  getMaxDateDetail(params) {
    return this.get('MaxDateDetail', params, null, true)
  }
}

export default new OverviewProxy(
  ServiceProxyConfig.Economy.ImportExport.ServiceUrl,
)
