import { createAsyncThunk } from '@reduxjs/toolkit'
import ProductionService from '../../../../../../../core/services/economy/productionAndConsumption/ProductionService'
import { handleExport } from '../../../../../../utils/Export'

export const getDataTableIIC = createAsyncThunk(
  'economy/productionAndConsumption/production/table/iic/getDataCompareByTimeRangeIIC',
  async (params, { rejectWithValue }) => {
    const responseTitle = await ProductionService.getVNTypeIds(params)
    const response = await ProductionService.getDataIICCompareByTimeRange(
      params,
    )
    if (response.success && responseTitle.success) {
      return {
        dataIIC: response.data,
        titleIIC: responseTitle.data,
      }
    }
    return rejectWithValue({ isLoading: false })
  },
)

export const downloadCompareByTimeRangeIIC = createAsyncThunk(
  'economy/productionAndConsumption/production/table/iic/downloadCompareByTimeRangeIIC',
  async (params) => {
    const response = await ProductionService.downloadCompareByTimeRangeIIC(
      params,
    )
    handleExport(response.data, response.filename)
  },
)

export const downloadCompareByTimeRangeIII = createAsyncThunk(
  'economy/productionAndConsumption/production/table/iii/downloadCompareByTimeRangeIII',
  async (params) => {
    const response = await ProductionService.downloadCompareByTimeRangeIII(
      params,
    )
    handleExport(response.data, response.filename)
  },
)

export const downloadGeneralProductionIndex = createAsyncThunk(
  'economy/productionAndConsumption/production/table/iii/downloadGeneralProductionIndex',
  async (params) => {
    const response = await ProductionService.downloadGeneralProductionIndex(
      params,
    )
    handleExport(response.data, response.filename)
  },
)
