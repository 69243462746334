import PropTypes from 'prop-types'
import { Span } from '../../html/Span'

const ContentTooltip = ({ contentTooltip }) => {
  return (
    <>
      {contentTooltip.map((item, index) => (
        <div key={index} className="d-flex j-b">
          <div>
            <Span style={item.styleLabel}>{item.label}</Span>
          </div>
          <div className="ml-8">
            <Span style={item.styleValue}>{item.value}</Span>
          </div>
        </div>
      ))}
    </>
  )
}

ContentTooltip.propTypes = {
  contentTooltip: PropTypes.array.isRequired,
}

export default ContentTooltip
