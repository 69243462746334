export const settingGeneral = {
  LANGUAGE: 'Ngôn ngữ',
  FONT_SIZE: 'Cỡ Chữ',
  LANGUAGE_ENGLISH: 'English',
  LANGUAGE_VIETNAMESE: 'Tiếng Việt',
  FONT_SIZE_SMALL: 'Nhỏ',
  FONT_SIZE_MEDIUM: 'Vừa',
  FONT_SIZE_LARGE: 'Lớn',
  TIME_ZONE: 'Múi giờ',
}
