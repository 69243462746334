import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { getTwoColorByValue } from '../../../../../utils/Color'
import { formatVal } from '../../../../../utils/Value'
import { latePaymentTypeValue } from './constants'
import { selectCouponTypeTab } from './store/slice'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 10,
  fontSize: 11,
}

export const ChartCashAll = ({
  data,
  width,
  height,
  keyXAxis,
  displayData,
  num1,
  num2,
}) => {
  const couponTypeTab = useSelector(selectCouponTypeTab)

  const textContentTooltip = (timeType) => {
    switch (timeType) {
      case latePaymentTypeValue.ALL:
        return I18n.t('bond.overview.expectedCash.chart.valueCashAll')
      case latePaymentTypeValue.Origin:
        return I18n.t('bond.overview.expectedCash.chart.valueCashCoupon')
      default:
        return I18n.t('bond.overview.expectedCash.chart.valueCashPrincipal')
    }
  }

  const textContentTooltip2 = (timeType) => {
    switch (timeType) {
      case latePaymentTypeValue.ALL:
        return I18n.t('bond.overview.expectedCash.chart.debtDueAll') + ':'
      case latePaymentTypeValue.Origin:
        return I18n.t('bond.overview.expectedCash.chart.debtDueCoupon') + ':'
      default:
        return I18n.t('bond.overview.expectedCash.chart.debtDuePrincipal') + ':'
    }
  }

  const renderTooltip = (tooltipData) => {
    const tooltipContentData = {
      [num1]: {
        label: textContentTooltip(couponTypeTab),
        format: (val) => formatVal(val),
        unit: 'bond.overview.expectedCash.chart.unitMoney',
      },
      [num2]: {
        label: textContentTooltip2(couponTypeTab),
        format: (val) => formatVal(val),
        unit: 'bond.overview.expectedCash.chart.unitMoney',
      },
    }

    const total =
      (!isNaN(tooltipData[num1])
        ? parseFloat(tooltipData[num1].toFixed(2))
        : 0) +
      (!isNaN(tooltipData[num2]) ? parseFloat(tooltipData[num2].toFixed(2)) : 0)

    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{tooltipData.date}</Span>
        </div>
        {displayData
          .filter((e) => !e.includes('Hide'))
          .map((key, index) => (
            <div key={index} className="d-flex j-b" style={{ gap: 16 }}>
              <div style={{ flex: 1 }}>
                <Span style={{ fontSize: 11, fontWeight: 400 }}>
                  {I18n.t(tooltipContentData[key].label)}
                </Span>
              </div>
              <div>
                <Span
                  style={{
                    fontSize: 11,
                    fontWeight: 500,
                    color: getTwoColorByValue(tooltipData[key] || 0),
                  }}
                >
                  {tooltipContentData[key].format(tooltipData[key])}
                </Span>
                <Span style={{ fontSize: 11, fontWeight: 500 }}>
                  {' ' + I18n.t(tooltipContentData[key].unit)}
                </Span>
              </div>
            </div>
          ))}
        <div className="d-flex j-b" style={{ gap: 16 }}>
          <div style={{ flex: 1 }}>
            <Span style={{ fontSize: 11, fontWeight: 400 }}>
              {I18n.t('bond.overview.expectedCash.chart.total')}
            </Span>
          </div>
          <div>
            <Span
              style={{
                fontSize: 11,
                fontWeight: 500,
                color: getTwoColorByValue(total),
              }}
            >
              {formatVal(total)}
            </Span>
            <Span style={{ fontSize: 11, fontWeight: 500 }}>
              {' ' + I18n.t('bond.overview.expectedCash.chart.unitMoney')}
            </Span>
          </div>
        </div>
      </>
    )
  }

  const yAxisLeft = [
    {
      id: 'left',
      keys: [num1, num2],
      orientation: 'left',
      tickNum: SETTINGS.yTickNum,
      label: I18n.t('bond.overview.expectedCash.chart.labelLeft'),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      isStackedBar: true,
      // isLineChart: true,
    },
  ]

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height || 0}
      keyXAxis={keyXAxis}
      xTickNum={SETTINGS.xTickNum}
      timeFrame="CUSTOM"
      tickFormatter={(d) => d}
      margin={{
        top: 20,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      hasBarChart={displayData.length ? true : false}
      yAxis={yAxisLeft}
      renderCustomTooltip={(dataFormat, decimalLengths, xAxisDecimalLength) => {
        return renderTooltip(dataFormat)
      }}
    >
      {({ chartContentWidth }) => (
        <>
          {displayData.includes(num1) && (
            <Bar
              isAnimationActive={false}
              dataKey={num1}
              yAxisId="left"
              stackId="left"
              fill={'#0096EB'}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}
          {displayData.includes(num2) && (
            <Bar
              isAnimationActive={false}
              dataKey={num2}
              stackId="left"
              yAxisId="left"
              fill={'#FACC5C'}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}
        </>
      )}
    </ChartContainer>
  )
}
