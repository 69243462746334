export const overview = {
  OVERVIEW: 'Tổng quan',
  LB_LISTED: 'Niêm yết',
  LB_PRIVATE: 'Tư nhân',
  LB_ALL: 'Tất cả',
  NEWS_AND_EVENTS: 'Tin tức & Báo cáo phân tích',
  TOP_REVENUE: 'Top Doanh thu',
  SECTOR_EARNING_GROWTH: 'Tăng trưởng doanh thu ngành',
  KEY_RATIOS: 'Chỉ số chính',
  TOP_COMPANIES_PERFORMANCE: 'Đánh giá doanh nghiệp',
  SEGMENTATION: 'Đóng góp theo công ty',
  NEWS: 'Tin tức',
  RESEARCH: 'Báo cáo và phân tích',
  EVENT: 'Sự kiện',
  YEARLY: 'Hàng năm',
  QUARTERLY: 'Hàng quý',
  REVENUE: 'Doanh thu',
  PROFIT: 'Lợi nhuận',
  PROFITABILITY: 'Hiệu suất sinh lời',
  FINANCIAL_STRENGTH: 'Sức khỏe tài chính',
  MANAGEMENT_EFFICIENCY: 'Hiệu quả quản lý',
  ASSETS_QUALITY: 'Chất lượng tài sản',
  GROWTH: 'Tăng trưởng',
  VALUATION: 'Định giá',
  NET_REVENUE: 'Doanh thu thuần',
  NET_MARGIN: 'Biên LN ròng',
  PEER_NET_MARGIN: 'Biên LN ròng của ngành',
  REVENUE_GROWTH: 'Tăng trưởng doanh thu',
  UNIT_PRICE: '(Tỷ VND)',
  LDR: 'LDR',
  LOAN_GROWTH: 'Tăng trưởng cho vay',
  DEPOSIT_GROWTH: 'Tăng trưởng tiền gửi',
  LB_CHART_BANK: 'Tín dụng và tiền gửi ngân hàng',
  CREDIT_DEPOSIT: 'Tín dụng & Tiền gửi',
  MARGIN_LENDING: 'Cho vay ký quỹ',
  YOY_GROWTH: 'Tăng trưởng YoY',
  BILLION: ' Tỷ',
  TOP_TOTAL_OPERATING_INCOME: 'Top tổng thu nhập hoạt động',
  ASSET_QUALITY: 'Chất lượng tài sản',
  NPL_RATIO: 'Tỷ lệ NPL',
  COVERAGE_RATIO: 'Dự phòng cho vay/ Nợ xấu',
  LOAN_LOSS_PROVISIONS: 'Dự phòng rủi ro/ Cho vay khách hàng',
  LDR_RATIO: 'Tỷ lệ LDR',
  CASA_RATIO: 'Tỷ lệ CASA',
  ST_FUNDING_TO_MLT_LOANS: 'Cho vay trung & dài hạn/Tiền gửi không kỳ hạn (%)',
  EFFICIENCY: 'Hiệu quả hoạt động',
  INTEREST_AND_SIMILAR_INCOME: 'Thu nhập lãi/ Tổng thu nhập (%)',
  FEES_AND_COMMISSION_INCOME: 'Thu nhập phí/ Tổng thu nhập (%)',
  COST_TO_INCOME_RATIO: 'Tỷ lệ CIR',
  GENERAL_AND_ADMINISTRATIVE_EXPENSES: 'Chi phí SGA/ Tổng chi phí',
  GENERAL_AND_ADMINISTRATIVE_EXPENSES_TABLE: 'Chi phí SGA/<br/> Tổng chi phí',
  YIELD_ON_EARNING_ASSETS: 'Thu nhập lãi thuần/ Tài sản sinh lời',
  COST_OF_FUNDS: 'Lãi suất đầu vào bình quân',
  NIM: 'NIM',
  AVERAGE_LOANS_GROWTH: 'Tăng trưởng cho vay khách hàng (%YoY)',
  AVERAGE_DEPOSIT_GROWTH: 'Tăng trưởng tiền gửi (%YoY)',
  INTEREST_INCOME_GROWTH: 'Tăng trưởng thu nhập lãi (%YoY)',
  NON_INTEREST_INCOME_GROWTH: 'Tăng trưởng thu nhập ngoài lãi (%YoY)',
  PREPROVISION_INCOME_GROWTH: 'Tăng trưởng thu nhập trước DPRR (%YoY)',
  AVERAGE_LOANS_GROWTH_TABLE:
    'Tăng trưởng cho vay<br/>khách hàng <i>(%YoY)</i>',
  AVERAGE_DEPOSIT_GROWTH_TABLE: 'Tăng trưởng tiền gửi <br/><i>(%YoY)</i>',
  INTEREST_INCOME_GROWTH_TABLE: 'Tăng trưởng thu nhập <br/>lãi <i>(%YoY)</i>',
  NON_INTEREST_INCOME_GROWTH_TABLE:
    'Tăng trưởng thu nhập <br/>ngoài lãi <i>(%YoY)</i>',
  PREPROVISION_INCOME_GROWTH_TABLE:
    'Tăng trưởng thu nhập <br/>trước DPRR <i>(%YoY)</i>',
  PE: 'P/E',
  PB: 'P/B',
  DIVIDEND_YIELD: 'Tỷ suất cổ tức',
  GROSS_PROFIT_MARGIN: 'Biên LN Gộp',
  MARKET_CAP: 'Vốn hóa (Tỷ VND)',
  YOEA: 'Thu nhập lãi thuần/<br/> Tài sản sinh lời',
  COF: 'Lãi suất đầu vào<br/> bình quân',
  ROA: 'ROA',
  ROE: 'ROE',
  NII_TOI: 'Thu nhập lãi/<br/> Tổng thu nhập',
  NFI_TOI: 'Thu nhập phí/<br/> Tổng thu nhập',
  CIR: 'Tỷ lệ CIR',
  COMPANY: 'Mã CK',
  EV: 'EV (Tỷ VND)',
  EV_TABLE: 'EV <i>(Tỷ VND)</i>',
  PS: 'P/S',
  EBITDA_MARGIN: 'Biên EBITDA',
  NET_PROFIT_MARGIN: 'Biên LN ròng',
  CURRENT_RATIO: 'Hệ số thanh toán hiện thời',
  CURRENT_RATIO_TABLE: 'Hệ số thanh toán<br/> hiện thời',
  TOTAL_DEBT_OR_TOTAL_ASSETS: 'Tổng nợ/ Tổng tài sản',
  TOTAL_DEBT_OR_EQUITY: 'Tổng nợ/ VCSH',
  TOTAL_DEBT_OR_EQUITY_TABLE: 'Tổng nợ/<br/> VCSH',
  INTEREST_COVERAGE: 'Khả năng chi trả lãi vay',
  INTEREST_COVERAGE_TABLE: 'Khả năng chi trả<br/> lãi vay',
  ROIC: 'ROIC',
  CAPEX_OR_SALES: 'Capex/ Doanh thu',
  ASSET_TURNOVER: 'Vòng quay tài sản',
  EV_EBITDA: 'EV/EBITDA',
  NET_REVENUE_GROWTH_1_YEAR: 'Tăng trưởng DTT (1 năm)',
  TOTAL_ASSETS_GROWTH_1_YEAR: 'Tăng trưởng TTS (1 năm)',
  OWNERS_EQUITY_GROWTH_1_YEAR: 'Tăng trưởng VCSH (1 năm)',
  NET_REVENUE_GROWTH_3_YEAR: 'Tăng trưởng DTT (3 năm)',
  TOTAL_ASSETS_GROWTH_3_YEAR: 'Tăng trưởng TTS (3 năm)',
  OWNERS_EQUITY_GROWTH_3_YEAR: 'Tăng trưởng VCSH (3 năm)',
  NET_REVENUE_GROWTH_1_YEAR_TABLE: 'Tăng trưởng DTT',
  TOTAL_ASSETS_GROWTH_1_YEAR_TABLE: 'Tăng trưởng TTS',
  MARGIN_LENDING_OR_OWNERS_EQUITY: 'Cho vay ký quỹ/ VCSH',
  MARGIN_LENDING_OR_OWNERS_EQUITY_TABLE: 'Cho vay ký quỹ/ <br/> VCSH',
  MARGIN_LENDING_OR_TOTAL_ASSETS: 'Cho vay ký quỹ/TTS',
  REVENUE_PER_EMPLOYEE: 'Vòng quay VCSH',
  NET_INCOME_PER_EMPLOYEE: 'Vòng quay tài sản',
  LOAN: 'Cho vay',
  DEPOSIT: 'Tiền gửi',
  TOTAL_ASSET: 'Tổng tài sản',
  BROKERAGE_REVENUE: 'Doanh thu môi giới',
  EBIT_MARGIN: 'Biên EBIT',
  PRETAX_MARGIN: 'Biên LN trước thuế',
  TOTAL_DEBT_EQUITY: 'Tổng nợ vay/ Vốn chủ sở hữu',
  MARGIN_LENDING_OWNER: 'Cho vay ký quỹ/ VCSH',
  REVENUE_PER_EMPLOYEE_M: 'Vòng quay VCSH',
  NET_INCOME_PER_EMPLOYEE_M: 'Vòng quay tài sản',
  NET_REVENUE_GROWTH_YOY: 'Tăng trưởng doanh thu thuần YoY',
  EBITDA_GROWTH_YOY: 'Tăng trưởng EBITDA YoY',
  EBIT_GROWTH_YOY: 'Tăng trưởng EBIT YoY',
  TOTAL_ASSETS_GROWTH_YOY: 'Tăng trưởng Tổng tài sản YoY',
  OWNERS_EQUITY_GROWTH_YOY: 'Tăng trưởng Vốn chủ sở hữu YoY',
  EV_EBIT: 'EV/EBIT',
  CASH_CONVERSION_CYCLEL: 'Chu kỳ tiền mặt',
  TOTAL_ASSET_TURNOVER: 'Vòng quay tài sản',
  ACCOUNT_RECEIVABLE_TURNOVER: 'Vòng quay khoản phải thu',
  ACCOUNT_RECEIVABLE_TURNOVER_TABLE: 'Vòng quay khoản<br/> phải thu',
  INVENTORY_TURNOVER: 'Vòng quay HTK',
  ACCOUNT_PAYABLE_TURNOVER: 'Vòng quay khoản phải trả',
  ACCOUNT_PAYABLE_TURNOVER_TABLE: 'Vòng quay khoản <br/> phải trả',
  CASH_CONVERSION_CYCLE: 'Vòng quay tiền mặt',
  CASH_CONVERSION_CYCLE_TABLE: 'Vòng quay tiền mặt',
  WORKING_CAPITAL_TURNOVER: 'Vòng quay VLĐ',
  MEDIAN: 'Trung Vị',
  MEAN: 'Trung Bình',
  SECTOR: 'Ngành',
  OTC: 'OTC',
  ANNUAL: 'Cả năm',
  ST_FUNDING_TABLE: 'Cho vay trung & dài hạn/<br/>Tiền gửi không kỳ hạn (%)',
  CASH_RATIO: 'Hệ số thanh toán tiền mặt',
  QUICK_RATIO: 'Hệ số thanh toán nhanh',
  EBIT_GROWTH: 'Tăng trưởng EBIT',
  OWNERS_EQUITY_GROWTH: 'Tăng trưởng VCSH',
  TOTAL_ASSETS_GROWTH: 'Tăng trưởng TTS',
  NET_REVENUE_GROWTH: 'Tăng trưởng DTT',
  EBITDA_GROWTH: 'Tăng trưởng EBITDA',
  TOTAL_OPERATING_INCOME: 'Tổng thu nhập hoạt động',
  TRILLION_VND: '(Nghìn tỷ VND)',
  SEGMENTATION_REVENUE: 'Đóng góp doanh thu theo công ty',
  COVERAGE_RATIO_TABLE: 'Dự phòng cho vay/<br/> Nợ xấu',
  LOAN_LOSS_PROVISIONS_TABLE: 'Dự phòng rủi ro/<br/> Cho vay khách hàng',
  CAPEX_OR_SALES_TABLE: 'Capex/<br/> Doanh thu',
  CASH_RATIO_TABLE: 'Hệ số thanh toán<br/> tiền mặt',
  QUICK_RATIO_TABLE: 'Hệ số thanh toán<br/> nhanh',
  COMPANY_NAME: 'Tên công ty',
  PE_FORWARD: 'P/E kế hoạch',
  BVPS: 'BVPS',
  TRILLION_VND_TOOLTIP: ' Nghìn tỷ',
  PERCENT_LDR: 'LDR',
  PERCENT_GROWTH: 'Tăng trưởng',
}

export const newsReport = {
  SECTOR_NEWS: 'Tin Tức Ngành',
  SECTOR_REPORTS: 'Báo Cáo Ngành',
  LATEST_NEWS: 'Tin Mới Nhất',
  DATE: 'NGÀY',
  ISSUER: 'CÔNG TY PH',
  TITLE: 'TIÊU ĐỀ',
  ACTION: ' ',
  SAME_CATEGORY: 'Tin cùng chuyên mục',
  DETAIL: 'Chi tiết',
  EARNING: 'Kết Quả Kinh Doanh',
  TRANSACTIONS: 'Giao dịch cổ phiếu',
  OTHER_BUSINESS_ACTIVITIES: 'Tin khác',
  ISSUER_TOOLTIP: 'CÔNG TY PHÁT HÀNH',
}

export const ranking = {
  RANKING: 'Xếp hạng cổ phiếu trong ngành',
  RANK_VALUE: 'Thứ hạng',
  TICKER: 'Mã',
  COMPANY_NAME: 'Tên công ty',
  EXCHANGE: 'Sàn',
  SECTOR: 'Ngành',
  MARKET_CAP: 'Vốn hóa (Tỷ VND)',
  VALUE: 'Giá trị',
  GROWTH: 'Tăng trưởng',
  MOMENTUM: 'Động lượng',
  VGM: 'VGM',
  RANKING_STATUS: 'Xếp hạng cổ phiếu trong ngành',
  ALL_EXCHANGE: 'Tất cả sàn',
  VALUE_SCORE: 'Điểm giá trị',
  GROWTH_SCORE: 'Điểm tăng trưởng',
  MOMENTUM_SCORE: 'Điểm động lượng',
  VGM_SCORE: 'Điểm VGM',
  FIINX_RANKING: 'Xếp hạng FiinProX',
  BANK: 'Ngân hàng',
  VN30: 'VN30',
  NOTE_CHART_RANKING: 'Lịch sử xếp hạng 5 kỳ gần nhất (tính cả kì hiện tại)',
  NOTE_TABLE_RANKING:
    'Để thêm thông tin về cách tối ưu hóa Đánh Giá Đầu Tư của FiinProX, ',
  CLICK_HERE: 'click tại đây',
}

export const valuation = {
  NAME: 'Định giá',
  FILTER: 'Bộ lọc',
  EXCHANGE: 'Sàn',
  ALL_EXCHANGE: 'Tất cả',
  ALL: 'Tất cả',
  HOSE: 'HOSE',
  HNX: 'HNX',
  UPCOM: 'UPCoM',
  INDICATOR: 'Bộ chỉ số',
  indicators: {
    basicPE: 'P/E cơ bản',
    marketCap: 'Vốn hóa',
    ev: 'EV',
    pS: 'P/S',
    pB: 'P/B',
    pCfo: 'P/CFO',
    evRevenue: 'EV/Revenue',
    evEbitda: 'EV/EBITDA',
    evEbit: 'EV/EBIT',
    evFcff: 'EV/FCFF',
    evFcfe: 'EV/FCFE',
  },
  COMPARISION: 'So sánh',
  ADD_COMPARISION: 'Thêm Ngành, Công ty',
  COMPARE: 'So sánh Ngành, Công ty',
  SECTOR: 'Ngành',
  SECTOR_LEVEL_1: 'Cấp 1',
  SECTOR_LEVEL_2: 'Cấp 2',
  SECTOR_LEVEL_3: 'Cấp 3',
  SECTOR_LEVEL_4: 'Cấp 4',
  SECTOR_LEVEL_5: 'Cấp 5',
  SELECTED_ITEMS: 'Đã chọn',
  SELECTED_COUNT: 'Đã chọn:',
  MAX_ITEMS_TEXT: 'Số lượng chỉ tiêu lựa chọn không vượt quá 20.',
  RESET: 'Reset',
  VALUATION_CHART: 'Đồ Thị Định Giá',
  VALUATION_CHART_TITLE: 'Chỉ Số',
  PRICE_CHART: 'Đồ Thị Giá',
  PRICE_CHART_TITLE: 'So sánh Chỉ số Ngành & Giá cổ phiếu',
  RELATIVE: 'Tương đối',
  ABSOLUTE: 'Tuyệt đối',
  THOUSAND_VND: '(Nghìn VND)',
  THOUSAND_VND_TOOLTIP: 'Nghìn',
  TRILLION_VND: '(Nghìn tỷ VND)',
  TRILLION_VND_TOOLTIP: 'Nghìn tỷ',
  POINT: '(Điểm)',
  POINT_TOOLTIP: 'Điểm',
  DATA_TABLE: 'Bảng dữ liệu',
  dataTable: {
    name: 'Ngành/Mã',
    marketCap: 'Vốn hóa <p>(Tỷ VND)</p>',
    pE: 'P/E',
    pB: 'P/B',
    bvps: 'BVPS',
    peForward: 'P/E kế hoạch',
  },
  VALUATION_REPORT: 'Báo cáo',
  valuationReport: {
    date: 'Ngày',
    name: 'Ngành/Mã',
    issuer: 'Công ty phát hành',
    title: 'Tên báo cáo',
    download: ' ',
  },
}

export const ratio = {
  FILTER: 'Bộ lọc',
  EXCHANGE: 'Sàn',
  MARKET_CAP: 'Vốn hóa (Triệu VND)',
  COMPANY: 'Công ty',
  SEARCH: 'Tìm kiếm',
  FROM: 'Từ',
  TO: 'Đến',
  ALL_EXCHANGE: 'Tất cả',
  RATIO: 'CHỈ SỐ NGÀNH',
}

export const segmentation = {
  FILTER: 'Bộ lọc',
  SCATTER_PLOT: 'Biểu đồ phân tán',
  MAX_TEMPLATE_1: 'Chỉ có thể lưu tối đa 100 biểu mẫu cá nhân.',
  MAX_TEMPLATE_2: 'Hãy thử lại!',
  TEMPLATE_NAME_TOO_SHORT: 'Tên biểu mẫu quá ngắn.',
  TEMPLATE_EXIST: 'Tên biểu mẫu đã tồn tại. Hãy thử lại!',
  TEMPLATE_REQUIRE: 'Nhập tên biểu mẫu.',
  BTN_SAVE_AS: 'Lưu mới',
  LABEL_NAME_TEMPLATE: 'Tên của biểu mẫu:',
  SEARCH: 'Tìm kiếm',
  CHOOSE_COMPANY: 'Công ty & Nhóm công ty',
  SELECTED_COUNT: 'Đã chọn:',
  MAX_ITEMS_TEXT:
    'Số lượng mã khi xem loại biểu đồ <i>Chuỗi thời gian</i> không vượt quá 500.',
  listType: {
    COMPANY: 'Công ty',
    WATCHLIST: 'Danh mục',
    INDEX: 'Chỉ số',
    SECTOR: 'Ngành',
  },
  SECTOR_LEVEL_1: 'Cấp 1',
  SECTOR_LEVEL_2: 'Cấp 2',
  SECTOR_LEVEL_3: 'Cấp 3',
  SECTOR_LEVEL_4: 'Cấp 4',
  SECTOR_LEVEL_5: 'Cấp 5',
  QUARTER_1: 'Quý 1',
  QUARTER_2: 'Quý 2',
  QUARTER_3: 'Quý 3',
  QUARTER_4: 'Quý 4',
  BUBBLE_SIZE: 'Kích thước',
  bubbleSize: {
    TOTAL_ASSETS: 'Tổng tài sản',
    MARKET_CAP: 'Vốn hóa',
    VOLUMN_1W: 'Khối lượng TB 1W',
    VOLUMN_1M: 'Khối lượng TB 1M',
    VOLUMN_3M: 'Khối lượng TB 3M',
    VOLUMN_6M: 'Khối lượng TB 6M',
    VOLUMN_1Y: 'Khối lượng TB 1Y',
    CONSTRAINT: 'Đồng nhất',
  },
  BUBBLE_COLOR: 'Màu',
  bubbleColor: {
    GROUP: 'Theo nhóm',
    COMPANY: 'Theo công ty',
    CONSTRAINT: 'Đồng nhất',
  },
  NOT_VALID_TICKER:
    'Không có công ty nào nằm trong Sàn hoặc khoảng Vốn hóa đã chọn. Quý vị có thể thay đổi công ty hoặc bộ lọc và thực hiện lại.',
  SELECTED_ITEMS: 'Đã chọn',
  EXCHANGES: 'Sàn',
  exchanges: {
    ALL_EXCHANGE: 'Tất cả',
    HOSE: 'HOSE',
    HNX: 'HNX',
    UPCOM: 'UPCOM',
    OTC: 'OTC',
    PRIVATE: 'Private',
  },
  NO_EXCHANGE: 'Chọn sàn',
  MARKET_CAP_LABEL: 'Vốn hóa (Tỷ VND)',
  TEMPLATE_LABEL: 'Biểu mẫu',
  CHOOSE_TEMPLATE: 'Chọn biểu mẫu',
  RENAME: 'Đổi tên',
  COPPY_LINK: 'Sao chép',
  DELETE: 'Xóa',
  TITLE_DELETE_TEMPLATE: 'Xóa biểu mẫu',
  ASK_DELETE_TEMPLATE: 'Bạn chắc chắn muốn xóa ',
  SCATTER_PLOT_TYPE: 'Loại biểu đồ phân tán',
  scatterPlotType: {
    POINT_IN_TIME: 'Mốc thời gian',
    TIME_SERIES: 'Chuỗi thời gian',
  },
  timeSeriesType: {
    RANGE: 'Chuỗi ngày',
    YEAR: 'Cả năm',
  },
  TIME_RANGE: 'Chuỗi thời gian',
  X_ASIS: 'Trục X',
  Y_ASIS: 'Trục Y',
  CHOOSE_INDICATOR: 'Chọn chỉ số',
  FROM: 'Từ',
  TO: 'Đến',
  YEAR: 'Năm',
  CATEGORY: 'Danh mục',
  METRIC: 'Chỉ số',
  NO_TEMPLATE: 'Không có biểu mẫu.',
  INVALID_TEMPLATE:
    'Lỗi: Biểu mẫu không tồn tại hoặc đã bị xóa. Vui lòng chọn biểu mẫu khác.',
  SHOW_TICKER: 'Hiện Mã/Mã số thuế',
  ALL: 'Tất cả',
}
