import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { NetValue } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { LIST_TYPE } from '../constants'
import { selectDateTime, selectType, selectTypeName } from './store/slice'

export const PanelNetValue = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const type = useSelector(selectType)
  const dateType = useSelector(selectDateTime)
  const typeName = useSelector(selectTypeName)

  const locale = useSelector((state) => state.i18n.locale)

  const getTitleJpg = (locale) => {
    return locale === 'en'
      ? `${I18n.t(
          'market.marketInDepth.indexStatistics.netValue.NET',
        )} ${I18n.t(typeName)} ${I18n.t(
          'market.marketInDepth.indexStatistics.netValue.VALUE_BREAKDOWN',
        )}`
      : `${I18n.t(
          'market.marketInDepth.indexStatistics.netValue.NET_VALUE_BREAKDOWN',
        )} ${I18n.t(typeName)}`
  }

  return (
    <Panel
      title="market.equityTrading.priceStatistics.NET_VALUE"
      panelRefs={panelRefs}
      panelKey={mapKey.NET_VALUE}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      downloadJpgParams={{
        domId: 'netValue',
        nameScreen: UseI18n(
          'market.equityTrading.priceStatistics.SCREEN_LABEL',
        ),
        chartName: UseI18n('market.equityTrading.priceStatistics.NET_VALUE'),
        tabName:
          UseI18n(LIST_TYPE[type].display) +
          '_' +
          UseI18n(`market.equityTrading.priceStatistics.${dateType}`),
        tickerName: basicInfo.ticker,
      }}
      titleJpg={getTitleJpg(locale)}
    >
      <NetValue
        width={sizes[mapKey.NET_VALUE].width - PANEL_PADDING}
        height={sizes[mapKey.NET_VALUE].height - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

PanelNetValue.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
