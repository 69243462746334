import { useMemo } from 'react'
import useGetPackageFunctions from '../masterData/useGetPackageFunctions'
import { TYPE_STOCK_INDICES_SECTOR } from './useBasicInfo'

const URL_TYPES = {
  BOND: '/bond/',
  CORPORATE: '/corporate/',
  ECONOMY: '/economy/',
  MARKET: '/market/',
  SECTOR: '/sector/',
}

export const usePackagePermission = (type) => {
  const { packageFunctions } = useGetPackageFunctions(true)

  const bondPackageUrls = packageFunctions.filter((url) =>
    url.includes(URL_TYPES.BOND),
  )
  const corporatePackageUrls = packageFunctions.filter((url) =>
    url.includes(URL_TYPES.CORPORATE),
  )
  const economyPackageUrls = packageFunctions.filter((url) =>
    url.includes(URL_TYPES.ECONOMY),
  )
  const marketPackageUrls = packageFunctions.filter((url) =>
    url.includes(URL_TYPES.MARKET),
  )
  const sectorPackageUrls = packageFunctions.filter((url) =>
    url.includes(URL_TYPES.SECTOR),
  )

  const isTrial = useMemo(() => {
    switch (type) {
      case TYPE_STOCK_INDICES_SECTOR.BOND:
        return bondPackageUrls.length <= 2
      default:
        return true
    }
  }, [])

  return {
    isTrial,
  }
}
