import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { TIME_FRAME_OWNERSHIP } from '../constants'
import { getOwnershipChartData } from './thunk'

const initialState = {
  timeFrame: TIME_FRAME_OWNERSHIP.ONE_MONTH,
  loading: true,
  data: [],
}

const slice = createSlice({
  name: 'market/marketInDepth/foreign/ownership',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeTimeFrame: (state, action) => {
      state.timeFrame = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOwnershipChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getOwnershipChartData.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.reverse().map((item) => ({
        ...item,
        foreignOwnedPercentage: item.foreignOwnedPercentage * 100,
      }))
    })
    builder.addCase(getOwnershipChartData.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectTimeFrame = (state) => state[slice.name].timeFrame
export const selectLoading = (state) => state[slice.name].loading
export const selectData = (state) => state[slice.name].data

export const { resetStore, changeTimeFrame } = slice.actions

register(slice.name, slice.reducer)
