import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import TimeAndSalesPanel from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectWarrantInfo } from '../../../../common/topInfo/warrantTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { tableChartSwitch, timeSalesDateFilter } from './constants'
import { selectPageSize, selectScrollId } from './store/slice'
import { downloadTimeAndSales } from './store/thunk'

const PanelTimeAndSales = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const [viewType, setViewType] = useState(
    tableChartSwitch.HORIZONTAL_STACK_BAR,
  )

  const warrantInfo = useSelector(selectWarrantInfo)
  const [dateType, setDateType] = useState(timeSalesDateFilter[0].value)
  const pageSize = useSelector(selectPageSize)
  const scrollId = useSelector(selectScrollId)
  const nameScreen = UseI18n('constants.route.market.coveredWarrant.LABEL')
  const chartName = UseI18n('market.coveredWarrant.TIME_AND_SALES')
  const tabName = UseI18n(`market.coveredWarrant.${dateType}`)

  const handleDownloadCsv = () => {
    return downloadTimeAndSales({
      coveredWarrantId: warrantInfo.coveredWarrantId,
      PageSize: pageSize,
      ScrollId: scrollId,
    })
  }

  return (
    <Panel
      title={'market.coveredWarrant.TIME_AND_SALES'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv={viewType === tableChartSwitch.TABLE}
      isDownloadJpg={viewType === tableChartSwitch.HORIZONTAL_STACK_BAR}
      titleJpg={UseI18n('market.coveredWarrant.TIME_AND_SALES')}
      downloadCsv={handleDownloadCsv}
      downloadJpgParams={{
        domId: 'coveredWarrantTimeAndSalesChart',
        nameScreen,
        chartName,
        tabName,
        tickerName: warrantInfo?.ticker,
      }}
    >
      <TimeAndSalesPanel
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        viewType={viewType}
        setViewType={setViewType}
        dateType={dateType}
        setDateType={setDateType}
      />
    </Panel>
  )
}

PanelTimeAndSales.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default PanelTimeAndSales
