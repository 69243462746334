import LinkTicker from '../../../../../common/dropdownLink/LinkTicker'
import { TextBlink } from '../../../../../common/table/TextBlink'
import TextEllipsis from '../../../../../common/textEllipsis'
import style from './index.module.css'

export const LightBorderColumnCell = ({
  val,
  isAlignTextLeft,
  styleProps,
  isTicker,
  organizationId,
  isTextBlink,
}) => {
  return (
    <td
      className={`${style.borderRightColumnCellTd} ${
        isAlignTextLeft ? style.alignTextLeft : style.alignTextRight
      }`}
      style={{ ...styleProps }}
    >
      {isTicker ? (
        <LinkTicker organizationId={organizationId}>
          <TextEllipsis text={val} />
        </LinkTicker>
      ) : isTextBlink ? (
        <TextBlink value={val}>{val}</TextBlink>
      ) : (
        val
      )}
    </td>
  )
}
