import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import ChartPieMajorOwnership from './ChartPieMajorOwnership'
import ChartPieOverview from './ChartPieOverview'
import style from './index.module.css'
import {
  selectMajorOwnershipChartData,
  selectMajorOwnershipLoading,
  selectOverviewChartData,
  selectOverviewLoading,
} from './store/slice'
import {
  getMajorOwnershipChart,
  getOverviewOwnershipChart,
} from './store/thunk'

const PADDING_BOTTOM = 16

const Charts = ({ width, height }) => {
  height = height - PADDING_BOTTOM

  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)
  const overviewLoading = useSelector(selectOverviewLoading)
  const majorOwnershipLoading = useSelector(selectMajorOwnershipLoading)
  const overviewChartData = useSelector(selectOverviewChartData)
  const majorOwnershipChartData = useSelector(selectMajorOwnershipChartData)

  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getOverviewOwnershipChart({ OrganizationId: basicInfo.organizationId }),
      )
      dispatch(
        getMajorOwnershipChart({ OrganizationId: basicInfo.organizationId }),
      )
    }
  }, [basicInfo.organizationId, locale])

  return (
    <ScrollComponent>
      <div id="structureOwnershipChartId">
        <div className={style.chartContainer}>
          <div style={{ height: height / 2, position: 'relative' }}>
            {overviewLoading ? (
              <Loading />
            ) : overviewChartData.length ? (
              <ChartPieOverview
                width={width}
                height={height}
                data={overviewChartData}
              />
            ) : (
              <NoData />
            )}
          </div>
          <div className={style.divider} />
          <div style={{ height: height / 2, position: 'relative' }}>
            {majorOwnershipLoading ? (
              <Loading />
            ) : majorOwnershipChartData.length ? (
              <ChartPieMajorOwnership
                width={width}
                height={height}
                data={majorOwnershipChartData}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>
    </ScrollComponent>
  )
}

export default Charts
