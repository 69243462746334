export const CHART_TYPE_HEIGHT = 20

export const COLOR = ['#40ABFF', '#FECF2F']

export const YEAR_OPTIONS = (function () {
  const MIN_YEAR_OPTION = 2000
  const year = new Date().getFullYear()
  const range = year - MIN_YEAR_OPTION + 1
  return [...Array(range).keys()].map((item) => {
    return {
      name: String(year - item),
      value: String(year - item),
    }
  })
})()
