const validate = {
  NAME_REQUIRE: 'Nhập tên danh mục.',
  NAME_MAX: 'Tên danh mục quá 100 kí tự.',
  NAME_DUPLICATE: 'Tên danh mục đã tồn tại.',
}

const indices = {
  ID: 'STT',
  CODE: 'Mã Trái phiếu',
  CODE_TCPH: 'Mã TCPH',
  COMPANY_NAME: 'Tên Tổ chức phát hành',
}

const watchlistDetail = {
  indices,
  validate,
  TITLE_CREATE: 'TẠO DANH MỤC',
  NAME: 'Tên:',
  DESCRIPTION: 'Mô tả:',
  BTN_ADD_TICKER: 'Thêm mã',
  NOTE: 'Kéo thả ticker về vị trí mong muốn hoặc click đúp chuột vào số thứ tự và nhập số mới để sắp xếp lại thứ tự của ticker.',
  FILTER: 'Bộ lọc',
  TITLE_SEARCH_TICKER: 'Mã trái phiếu',
  NO_RESULT: 'Không có kết quả phù hợp.',
  SEARCH_PLH: 'Tìm kiếm',
  TITLE_SEARCH_GROUP: 'Tổ chức phát hành',
  EXCHANGE: 'Sàn',
  SECTOR: 'Ngành',
  TOTAL: 'Tổng',
  BTN_PREVIOUS: 'Quay lại',
  ASK_1: 'Bạn có muốn lưu danh mục này?',
  ASK_2: 'Nếu click vào "Không lưu", bạn sẽ quay trở về trang trước.',
  BTN_NOT_SAVE: 'Không lưu',
  BTN_SAVE_AS: 'Lưu mới',
  EXCEEDED_WL:
    'Đã tạo đủ số lượng danh mục cho phép. Không thể tạo thêm danh mục mới.',
  BTN_CREATE: 'Tạo',
  ASK_CREATE: 'Bạn có muốn tạo danh mục này?',
  BTN_OK: 'Ok',
  TITLE_CREATE_2: 'Tạo danh mục',
  LABEL_NAME_WL: 'Tên danh mục:',
  ASK_DELETE: 'Bạn có muốn xóa',
  TITLE_DELETE_TICKER: 'Xóa ticker',
  DELETE: 'Xóa',
  TICKERS: 'ticker',
  MAX_COMPANY: 'Số công ty tối đa bạn có thể thêm vào danh mục là 300.',
}

export default watchlistDetail
