import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import style from '../style.module.css'

const ChartFooter = ({ totalMatchVolume, totalBuyVolume, totalSellVolume }) => {
  return (
    <div className={`${style.blockFooter}`}>
      <div className="align-center j-b">
        <Span className="canvas-chart-title">
          <Translate value="market.derivative.TOTAL_BUY_VOL" />
        </Span>
        <Span className="canvas-chart-title">
          <Translate value="market.derivative.TOTAL_MATCHED_VOL" />
        </Span>
        <Span className="canvas-chart-title">
          <Translate value="market.derivative.TOTAL_SELL_VOL" />
        </Span>
      </div>
      <div className={`align-center j-b ${style.mt4px}`}>
        <Span className="canvas-chart-title" style={{ fontSize: 12 }}>
          {totalBuyVolume}
        </Span>
        <Span className="canvas-chart-title" style={{ fontSize: 12 }}>
          {totalMatchVolume}
        </Span>
        <Span className="canvas-chart-title" style={{ fontSize: 12 }}>
          {totalSellVolume}
        </Span>
      </div>
    </div>
  )
}

export default ChartFooter
