import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Filter } from '../../../../common/news/filter'
import { ANNOUNCEMENT_TYPE } from '../constants'
import {
  changeMoreFilter,
  selectAnnouncementCategories,
  selectAnnouncementSources,
  selectMoreFilterData,
} from '../store/slice'
import { getNewsCategories, getNewsSources } from '../store/thunk'

export const MoreFilter = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const sources = useSelector(selectAnnouncementSources)
  const categories = useSelector(selectAnnouncementCategories)
  const filterData = useSelector(selectMoreFilterData)
  const locale = useSelector((state) => state.i18n.locale)
  const params = new URLSearchParams(location.search)
  const announcementType =
    params.get('announcementType') || ANNOUNCEMENT_TYPE.EARNING

  useEffect(() => {
    dispatch(getNewsSources())
    dispatch(getNewsCategories({ AnnouncementType: announcementType }))
  }, [locale])

  const changeFilter = (data) => {
    dispatch(changeMoreFilter(data))
  }

  return (
    <Filter
      sources={sources}
      categories={categories}
      sourceValue={filterData.source}
      categoryValue={filterData.category}
      filterStartDate={filterData.startDate}
      filterEndDate={filterData.endDate}
      changeFilter={changeFilter}
      time={filterData.time}
      newsTypeValue={filterData.newsType}
    />
  )
}
