import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class OverviewProxy extends ProxyBase {
  getTotalRegisteredFDICapital(params) {
    return this.get('TotalRegisteredFDICapital', params)
  }

  downloadTotalRegisteredFDICapital(params) {
    return this.get('DownloadTotalRegisteredFDICapital', params, 'download')
  }

  getTotalRegisteredFDICapitalChart(params) {
    return this.get('TotalRegisteredFDICapitalChart', params)
  }

  getKeyIndicatorCountry(params) {
    return this.get('KeyIndicatorCountry', params)
  }

  getKeyIndicatorProvince(params) {
    return this.get('KeyIndicatorProvince', params)
  }

  getFDIIndustryRequest(params) {
    return this.get('FDIIndustry', params)
  }

  getFDIStructureRequest(params) {
    return this.get('FDIStructure', params)
  }
}

export default new OverviewProxy(
  ServiceProxyConfig.Economy.FDI.Overview.ServiceUrl,
)
