import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { exportToImgURL } from '../../../common/downloadJpg/helper'
import UseI18n from '../../../common/hooks/useI18n'
import useWindowDevicePixelRatio from '../../../common/hooks/useWindowDevicePixelRatio'
import { handleNameFileDownload } from '../../../utils/Export'
import { getFontSize } from '../../../utils/FontSize'
import { downloadAllChart, downloadChart } from './store/slice'

const useDownloadAllChart = (
  dashboardID,
  dashboardName,
  dashboardChartData,
) => {
  const dispatch = useDispatch()
  const { formatWithZoom, reverseFormatWithZoom } = useWindowDevicePixelRatio()

  const nameScreen = UseI18n('financialChart.FINANCIAL_CHART')
  const fontSize = getFontSize(11)

  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    let promises = []
    let interval = null
    const zip = new JSZip()
    const img = zip.folder('charts')

    if (dashboardID) {
      setIsDownloading(true)
      interval = setInterval(() => {
        if (!document.getElementById(`dashboardChart${dashboardID}`)) {
          return
        }
        for (let i = 0; i < dashboardChartData.length; i++) {
          if (!document.getElementById(`chart${dashboardChartData[i].id}`)) {
            return
          }
        }
        const existFileName = []
        dashboardChartData.forEach((item) => {
          const { nameChart } = JSON.parse(item.detail)
          let fileName = handleNameFileDownload(
            nameScreen,
            nameChart.name.replace(/[./:*?"<>|]/g, ''),
          )
          const countFileName = existFileName.filter((name) =>
            name.includes(fileName),
          )

          if (countFileName.length) {
            fileName += ` (${countFileName.length})`
          }

          existFileName.push(fileName)

          promises.push(
            new Promise((resolve, reject) => {
              try {
                exportToImgURL({
                  domId: `chart${item.id}`,
                  fontSize,
                  formatWithZoom,
                  reverseFormatWithZoom,
                }).then((imageData) =>
                  resolve({
                    name: `${fileName}.jpg`,
                    image: imageData.split(';base64,')[1],
                  }),
                )
              } catch (e) {
                reject(e)
              }
            }),
          )
        })
        // promises.push(
        //   new Promise((resolve, reject) => {
        //     try {
        //       exportToImgURL(`dashboardChart${dashboardID}`).then((imageData) =>
        //         resolve({
        //           name: `${handleNameFileDownload(
        //             nameScreen,
        //             dashboardName.replace(/[./:*?"<>|]/g, ''),
        //           )}.jpg`,
        //           image: imageData.split(';base64,')[1],
        //         }),
        //       )
        //     } catch (e) {
        //       reject(e)
        //     }
        //   }),
        // )
        Promise.allSettled(promises).then((results) => {
          results.forEach((result) => {
            if (result.status === 'fulfilled') {
              img.file(result.value.name, result.value.image, { base64: true })
            }
          })
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(
              content,
              `${handleNameFileDownload(nameScreen, dashboardName)}.zip`,
            )
            dispatch(downloadChart({ id: null }))
            dispatch(downloadAllChart({ id: null }))
            setIsDownloading(false)
          })
        })
        clearInterval(interval)
      }, 2000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [dashboardID])

  return [isDownloading, setIsDownloading]
}

export default useDownloadAllChart
