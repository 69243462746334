import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { TYPE_TOP_MOVER, VALUE_ORDER } from '../constants'
import { changeFilterOrder, selectFilter } from '../store/slice'
import style from './style.module.css'

const RadioOrderTopMover = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilter)

  const listRadio = [
    {
      value: VALUE_ORDER.DESC,
      label:
        filter.top === TYPE_TOP_MOVER.CONTRIBUTION
          ? I18n.t('market.topMover.POSITIVE')
          : I18n.t('market.topMover.BUY'),
    },
    {
      value: VALUE_ORDER.ASC,
      label:
        filter.top === TYPE_TOP_MOVER.CONTRIBUTION
          ? I18n.t('market.topMover.NEGATIVE')
          : I18n.t('market.topMover.SELL'),
    },
  ]

  const handleChangeRadio = (e) => {
    dispatch(changeFilterOrder(e))
  }

  return (
    <ul className={`list-check d-flex align-center mt-0`}>
      {listRadio.map((item) => (
        <li key={item.value} className="mb-0">
          <label>
            <input
              type="radio"
              className="radiobox radiobox2"
              checked={item.value === filter.orderTopMover}
              onChange={() => handleChangeRadio(item.value)}
            />
            <Span
              style={{ fontSize: 12 }}
              className={
                item.value === filter.orderTopMover ? style.active : ''
              }
            >
              {item.label}
            </Span>
          </label>
        </li>
      ))}
    </ul>
  )
}

export default RadioOrderTopMover
