import { COM_GROUP, ICB_ID, MAP_INDUSTRY } from '../../constants/Group'
import useStockBasicInfo from './useStockBasicInfo'

const useComGroup = (organizationId) => {
  const stockBasicInfo = useStockBasicInfo(organizationId)

  if (!stockBasicInfo) return null

  if (
    ICB_ID[COM_GROUP.BANK].includes(stockBasicInfo.icbId) ||
    MAP_INDUSTRY[stockBasicInfo.groupId] === COM_GROUP.BANK
  ) {
    return COM_GROUP.BANK
  } else if (
    ICB_ID[COM_GROUP.SECURITIES].includes(stockBasicInfo.icbId) ||
    MAP_INDUSTRY[stockBasicInfo.groupId] === COM_GROUP.SECURITIES
  ) {
    return COM_GROUP.SECURITIES
  } else if (
    ICB_ID[COM_GROUP.INSURANCE].includes(stockBasicInfo.icbId) ||
    MAP_INDUSTRY[stockBasicInfo.groupId] === COM_GROUP.INSURANCE
  ) {
    return COM_GROUP.INSURANCE
  } else {
    return COM_GROUP.CORPORATE
  }
}

export default useComGroup
