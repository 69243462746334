export const STATEMENT_CONSTANTS = {
  BALANCE_SHEET: 'BalanceSheet',
  INCOME_STATEMENT: 'IncomeStatement',
  CASH_FLOW: 'CashFlow',
  NOTE: 'Notes',
}

export const VIEW_BY_CONSTANTS = {
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  SIX_MONTHS: '6 Months',
}

export const NUMBER_OF_PERIOD = [...new Array(11)].map((_, index) =>
  !index
    ? { name: 'common.ALL', value: 1000 }
    : {
        name: String(index),
        value: String(index),
      },
)

export const LATEST_YEAR = [...Array(20).keys()].map((v) => {
  const year = new Date().getFullYear()
  return {
    name: String(year - v),
    value: String(year - v),
  }
})

export const UNIT_CONSTANTS = {
  VND: 'VND',
  MILLION_VND: 'millionVND',
  BILLION_VND: 'billionVND',
  USD: 'USD',
  THOUSAND_USD: 'thousandUSD',
  MILLION_USD: 'millionUSD',
}

export const CURRENCY = {
  VND: 'VND',
  USD: 'USD',
}

export const UNIT = {
  VND: {
    VND: 'VND',
    MILLION_VND: 'millionVND',
    BILLION_VND: 'billionVND',
  },
  USD: {
    USD: 'USD',
    THOUSAND_USD: 'thousandUSD',
    MILLION_USD: 'millionUSD',
  },
}

export const FILTER_OPTIONS = [
  {
    title: 'fund.fundProfile.report.STATEMENT',
    options: STATEMENT_CONSTANTS,
    field: 'statement',
    type: 'ratio',
  },
  {
    title: 'fund.fundProfile.report.VIEW_BY',
    options: VIEW_BY_CONSTANTS,
    field: 'viewBy',
    type: 'select',
  },
  {
    title: 'fund.fundProfile.report.NUMBER_OF_PERIOD',
    options: NUMBER_OF_PERIOD,
    field: 'numberOfPeriod',
    type: 'select',
  },
  {
    title: 'fund.fundProfile.report.LATEST_YEAR',
    options: LATEST_YEAR,
    field: 'latestYear',
    type: 'select',
  },
  {
    title: 'fund.fundProfile.report.CURRENCY',
    options: CURRENCY,
    field: 'currency',
    type: 'ratio',
    layout: 'vertical',
  },
  {
    title: 'fund.fundProfile.report.UNIT',
    options: UNIT,
    field: 'unit',
    type: 'select',
  },
]

export const AUDIT_CONSTANTS = {
  AUDIT_STATUS: 'auditStatus',
  AUDIT_FIRM: 'organizationShortName',
  AUDIT_OPINION_TYPE_NAME: 'auditOpinionTypeName',
}

export const AUDIT_DATA = Object.keys(AUDIT_CONSTANTS).map((key) => ({
  index: AUDIT_CONSTANTS[key],
  name: 'fund.fundProfile.report.' + key,
  key: AUDIT_CONSTANTS[key],
  en_key: 'en_' + AUDIT_CONSTANTS[key],
  level: 0,
  style: '',
}))

export const TABLE_ROW_LEVEL = {
  LEVEL_0: 0,
  LEVEL_1: 1,
}

export const FORMAT_DATA_BY_UNIT = {
  [UNIT_CONSTANTS.VND]: 1,
  [UNIT_CONSTANTS.MILLION_VND]: 10 ** 6,
  [UNIT_CONSTANTS.BILLION_VND]: 10 ** 9,
  [UNIT_CONSTANTS.USD]: 1,
  [UNIT_CONSTANTS.THOUSAND_USD]: 10 ** 3,
  [UNIT_CONSTANTS.MILLION_USD]: 10 ** 6,
}

export const INDICATOR_FIX_UNIT_INT = []
