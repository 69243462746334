import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import { SizeTracker } from '../../common/sizeTracker'
import TopInfo from '../../common/topInfo/stockTopInfo'
import { selectBasicInfo } from '../../common/topInfo/stockTopInfo/store/slice'
import Content from './Content'
import { getBasicInfoThunk } from './store/thunk'

export const Overview = () => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(getBasicInfoThunk({ OrganizationId: basicInfo.organizationId }))
    }
  }, [basicInfo.organizationId, locale])

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <TopInfo />
              {size.height && (
                <div
                  className="row summary-page"
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: `calc(100% - ${size.height}px)`,
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
