import { createAsyncThunk } from '@reduxjs/toolkit'
import CPIAndInflationService from '../../../../../core/services/economy/CPIAndInflationService'

export const contributionChart = createAsyncThunk(
  'economy/cpiAndInflation/contribution',
  async (params, { rejectWithValue }) => {
    const response = await CPIAndInflationService.getContribution(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
