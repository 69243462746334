import moment from 'moment'
import { Translate } from 'react-redux-i18n'
import { Bar, Cell } from 'recharts'
import {
  DEFAULT_PRE_VALUE_KEY,
  DEFAULT_XAXIS_DATA_KEY,
} from '../../../../common/chart/basicChart/constants'
import { CHART_TYPES } from '../../../../common/chart/constants'
import { TYPE } from '../../../../common/chart/footer/Item'
import { Span } from '../../../../common/html/Span'
import { getColorByValueChart } from '../../../../utils/Color'
import { formatVal, isInValidValue } from '../../../../utils/Value'

const POSITIVE_BAR_COLOR = '#3da967'
const NEGATIVE_BAR_COLOR = '#e0505b'
const LINE_COLOR = '#facc5c'

export const getSchema = () => {
  return [
    {
      text: 'fund.fundProfile.navData.fundFlow.FUND_FLOW_FOOTER',
      yAxisId: 'fundFlow',
      unit: 'common.UNIT.MILLION_VND',
      isI18n: true,
      chart: {
        render: (data, props) => (
          <Bar {...props}>
            {data.map((entry, index) => (
              <Cell
                key={index}
                fill={
                  entry[props.dataKey] >= 0
                    ? POSITIVE_BAR_COLOR
                    : NEGATIVE_BAR_COLOR
                }
              />
            ))}
          </Bar>
        ),
      },
      footer: {
        type: TYPE.SQUARE_TWO_COLOR,
        before: {
          bgColor: POSITIVE_BAR_COLOR,
        },
        after: {
          bgColor: NEGATIVE_BAR_COLOR,
        },
      },
    },
    {
      text: 'fund.fundProfile.navData.fundFlow.FUND_FLOW_ACC_FOOTER',
      yAxisId: 'accFundFlow',
      unit: 'common.UNIT.MILLION_VND',
      isI18n: true,
      color: LINE_COLOR,
      chart: {
        type: CHART_TYPES.LINE,
        dot: true,
      },
      footer: {
        type: TYPE.CIRCLE_MARKER_LINE,
        before: {
          bgColor: LINE_COLOR,
        },
        after: {
          bgColor: LINE_COLOR,
        },
      },
    },
  ]
}

export const formatDataApi = ({
  data,
  filters,
  getTimeRangeCheckValid,
  formatDateTimeByTimeRange,
}) => {
  const { isLessThanSixMonth } = getTimeRangeCheckValid({
    timeRange: filters.timeRange,
    startDate: filters.startDate,
    endDate: filters.endDate,
  })
  const dataByDate = {}

  data.forEach((item) => {
    const endDateId = isLessThanSixMonth
      ? moment(item.endDateId).endOf('week').format('MM-DD-YYYY')
      : item.endDateId

    if (dataByDate[endDateId]) {
      const existedValue = dataByDate[endDateId]
      const getValue = (oldVal, newVal, key, isReplaceByLatest) => {
        if (isInValidValue(oldVal[key]) && isInValidValue(newVal[key]))
          return null
        if (isReplaceByLatest) {
          if (
            moment(oldVal.realEndDateId).unix() <
              moment(newVal.realEndDateId).unix() &&
            newVal[key]
          ) {
            return newVal[key]
          } else {
            return oldVal[key]
          }
        } else {
          return (oldVal[key] || 0) + (newVal[key] || 0)
        }
      }

      dataByDate[endDateId] = {
        ...dataByDate[endDateId],
        ff: getValue(existedValue, item, 'ff'),
        fif: getValue(existedValue, item, 'fif'),
        fof: getValue(existedValue, item, 'fof'),
        accFF: getValue(existedValue, item, 'accFF', true),
      }
    } else {
      dataByDate[endDateId] = {
        ...item,
        endDateId,
        realEndDateId: item.endDateId,
      }
    }
  })

  return Object.values(dataByDate)
    .sort((a, b) => moment(a.endDateId).unix() - moment(b.endDateId).unix())
    .map((item) => ({
      ...item,
      [DEFAULT_XAXIS_DATA_KEY]: formatDateTimeByTimeRange({
        date: item.endDateId,
        timeRange: filters.timeRange,
        startDate: filters.startDate,
        endDate: filters.endDate,
      }),
      [DEFAULT_PRE_VALUE_KEY + 1]: item.ff / 10 ** 6,
      [DEFAULT_PRE_VALUE_KEY + 2]: item.accFF / 10 ** 6 || null,
    }))
}

export const renderTooltipContent = (data) => {
  const dataTooltip = [
    {
      text: 'fund.fundProfile.navData.fundFlow.FUND_FLOW_IN_TOOLTIP',
      value: formatVal(data.fif / 10 ** 6),
      color: getColorByValueChart(data.fif),
    },
    {
      text: 'fund.fundProfile.navData.fundFlow.FUND_FLOW_OUT_TOOLTIP',
      value: formatVal(data.fof / 10 ** 6),
      color: getColorByValueChart(data.fof),
    },
    {
      text: 'fund.fundProfile.navData.fundFlow.FUND_FLOW_NET_TOOLTIP',
      value: formatVal(data.ff / 10 ** 6),
      color: getColorByValueChart(data.ff),
    },
    {
      text: 'fund.fundProfile.navData.fundFlow.FUND_FLOW_ACC_TOOLTIP',
      value: formatVal(data.accFF / 10 ** 6),
      color: getColorByValueChart(data.accFF),
    },
  ]

  return dataTooltip.map((item) => (
    <div key={item.dataKey} className="d-flex j-b">
      <div>
        <Span style={{ fontSize: 11, fontWeight: 400 }}>
          <Translate value={item.text} />
        </Span>
      </div>
      <div className="ml-8">
        <Span
          style={{
            fontSize: 11,
            fontWeight: 500,
            color: item.color,
          }}
        >
          {item.value}
        </Span>
        <Span style={{ fontSize: 11, fontWeight: 500, marginLeft: 4 }}>
          <Translate value="common.UNIT.MILLION_VND" />
        </Span>
      </div>
    </div>
  ))
}
