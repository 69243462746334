import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Information from '../../../../../../../core/services/bond/bondSecurity/information'

export const getDataThunk = createAsyncThunk(
  'bond/bondSecurity/information/GET_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Information.getDataSecurity(params)
      if (response.success) {
        const data = response?.data || []
        const dataSort = data.sort(
          (a, b) => new Date(b?.publicDate) - new Date(a?.publicDate),
        )

        return dataSort
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getDataFileThunk = createAsyncThunk(
  'bond/bondSecurity/information/GET_DATA_FILE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Information.getDataFile(params)
      if (response.success) {
        const data = response?.data || []
        const dataFormat = data.map((e) => ({
          publicDate: e.publicDateId,
          title: e.eventTitle,
          sourceUrl: e.sourceUrl,
        }))

        const dataSort = dataFormat.sort(
          (a, b) => new Date(b?.publicDate) - new Date(a?.publicDate),
        )

        return dataSort
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
