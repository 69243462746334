import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../common/loading'
import useGetLayoutDashboard from '../chart/common/useGetLayoutDashboard'
import { changeChartTabsData } from '../chart/store/slice'
import ChartContainer from '../common/chartInPanel/ChartContainer'
import useFeatureChart from '../common/chartInPanel/useFeatureChart'
import withWrapperDataChart from '../common/chartInPanel/withWrapperDataChart'
import { Panel } from '../common/panel'
import { LinkButton } from '../linkChart/LinkButton'
import {
  selectDashboard,
  selectEnableLinkChart,
  updateChart,
} from '../store/slice'
import { KEY_DASHBOARD_TABS } from './constants'

const PanelChart = ({ item, index, loading }) => {
  const dispatch = useDispatch()

  const { data = {} } = useSelector(selectDashboard)
  const enableLinkChart = useSelector(selectEnableLinkChart)
  const { layoutDisplay } = useGetLayoutDashboard(data.layout)
  const [isEdit, setIsEdit] = useState(false)

  const updateTitle = (data) => {
    dispatch(updateChart({ id: item.id, value: JSON.stringify(data) }))
    dispatch(
      changeChartTabsData({
        [index + 1 + KEY_DASHBOARD_TABS]: data,
      }),
    )
  }

  const {
    formatData,
    footerData,
    schema,
    loading: chartLoading,
  } = useFeatureChart(item, true)

  return (
    <Panel
      index={index}
      item={item}
      width={layoutDisplay[index]?.width}
      isShowSetting={false}
    >
      <div className="position-relative w-100 h-100">
        {loading || chartLoading ? (
          <Loading isBlack />
        ) : (
          <ChartContainer
            key={`${data.layout}_${index}`}
            item={item}
            width="100%"
            height={388}
            isHaveDataChartPreview={true}
            footerData={footerData}
            data={formatData}
            schema={schema}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            updateTitle={updateTitle}
          />
        )}
      </div>
      {enableLinkChart && !loading && !chartLoading && (
        <LinkButton chartData={item} />
      )}
    </Panel>
  )
}

PanelChart.propTypes = {}

export default withWrapperDataChart(PanelChart)
